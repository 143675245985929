import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import CaseManagersStore from './CaseManagersStore';
import dashboardStore from '../../../stores/dashboard';
import _ from 'lodash';
import * as api from '../../../stores/api';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Recruiting`, value: `IsRecruiting` },
  { text: `Onboarding`, value: `IsOnboarding` },
  { text: `Onboarded`, value: `IsOnboarded` },
  { text: `Inactive`, value: `IsExcluding` },
];
const State = [
  { id: '', label: 'All' },
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

const toVeifiedLabels = [
  { text: `All`, value: `All` },
  { text: `Yes`, value: `Yes` },
  { text: `No`, value: `No` },
];

const subcriptionOptions = [
  { text: `All`, value: `All` },
  { text: `Yes`, value: `Yes` },
  { text: `No`, value: `No` },
];

const Type = ['All', 'Typist', 'Case Manager'];
@observer
class CaseManagersToolbar extends React.Component {
  componentDidMount() {
    CaseManagersStore.fetchCMGroup(true);
    CaseManagersStore.fetchStaff();
    CaseManagersStore.fetchAccountManager();
    CaseManagersStore.fetchToVerified();
  }

  renderState = () => {
    return State.map(i => ({
      value: i.label,
      text: i.label,
    }));
  };
  renderStateText = value => {
    return value ? value : 'All';
  };

  handleSelectState = (_, { value }) => {
    CaseManagersStore.refetch(
      {
        cmState: `${value === 'All' ? '' : value}`,
      },
      true,
    );
  };

  renderType = () => {
    return Type.map(i => ({
      value: i,
      text: i,
    }));
  };
  renderTypeText = value => {
    return value ? value : 'All';
  };

  handleSelectType = (_, { value }) => {
    CaseManagersStore.refetch(
      {
        filterType: `${value === 'All' ? '' : value}`,
      },
      true,
    );
  };
  openModal = options => {
    return CaseManagersStore.toggleModal(true, options);
  };

  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  renderFilterSubcriptionOptions = () => {
    return subcriptionOptions.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterSubcriptionText = value => {
    let labels = subcriptionOptions.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  renderVerifiedFilterOptions = () => {
    const { dataToVerified } = CaseManagersStore;
    if (dataToVerified && dataToVerified.length > 0) {
      return dataToVerified.map(opt => ({
        text: opt.Text + (opt.Count ? ` (` + opt.Count + `)` : ``),
        value: opt.Value,
      }));
    }
    // return toVeifiedLabels.map(opt => ({
    //   text: opt.text,
    //   value: opt.value,
    // }));
  };

  renderVerifiedFilterText = value => {
    const { dataToVerified } = CaseManagersStore;
    if (value && dataToVerified && dataToVerified.length > 0) {
      let opt = dataToVerified.filter(opt => opt.Value === value);
      return opt[0].Text + (opt[0].Count ? ` (` + opt[0].Count + `)` : ``);
    }
    return 'All';
    // let labels = toVeifiedLabels
    //   .filter(opt => opt.value === value)
    //   .map(opt => opt.text);
    // return labels[0];
  };

  renderCallerFilterOptions = () => {
    const { dataStaff } = CaseManagersStore;
    if (dataStaff && dataStaff.length > 0) {
      return dataStaff.map(opt => ({
        //text: opt.FullName,
        text: opt.FullName + (opt.Count ? ` (` + opt.Count + `)` : ``),
        value: opt.Id,
      }));
    }
  };

  renderCallerFilterText = value => {
    const { dataStaff } = CaseManagersStore;
    if (value && dataStaff && dataStaff.length > 0) {
      let staff = dataStaff.filter(opt => opt.Id === value);
      return staff[0].FullName + (staff[0].Count ? ` (` + staff[0].Count + `)` : ``);
      //return staff[0].FullName;
    }
    return 'All';
  };

  renderAccountManagerOptions = () => {
    const { dataAccountManager } = CaseManagersStore;
    if (dataAccountManager && dataAccountManager.length > 0) {
      return dataAccountManager.map(opt => ({
        text: opt.FullName + (opt.Count ? ` (` + opt.Count + `)` : ``),
        value: opt.Id,
      }));
    }
  };

  renderAccountManagerText = value => {
    const { dataAccountManager } = CaseManagersStore;
    if (value && dataAccountManager && dataAccountManager.length > 0) {
      let staff = dataAccountManager.filter(opt => opt.Id === value);
      //console.log(staff);
      return staff[0].FullName + (staff[0].Count ? ` (` + staff[0].Count + `)` : ``);
    }
    return 'All';
  };

  handleChangeFromToSaleDate = (_, { value }) => {
    CaseManagersStore.refetch({ fromToSaleDate: value, curPage: 1 }, true);
  };

  handleFilter = (_, { value }) => {
    CaseManagersStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleFilterSubcription = (_, { value }) => {
    CaseManagersStore.refetch({ filterSubcription: value, curPage: 1 }, true);
  };

  handleVerifiedFilter = (_, { value }) => {
    CaseManagersStore.refetch({ verified: value, curPage: 1 }, true);
  };

  handleCallerFilter = (_, { value }) => {
    CaseManagersStore.refetch({ filterCaller: value, curPage: 1 }, true);
  };

  handleScroll = event => {
    const target = event.target;
    const number = CaseManagersStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      CaseManagersStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  handleReset = () => {
    CaseManagersStore.resetStore();
    CaseManagersStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    CaseManagersStore.setSearchOnlyId(false);
    CaseManagersStore.refetch({ curPage: 1 }, true);
  };

  handleAssignAM = () => {
    return this.openModal({
      modalType: 'assignAM',
    });
  };

  openCaseCreate = () => () => {
    // var id = 0;
    // iframe.forceOpenTab('ViewCM', `?id=${id}&view=ViewCM`, {
    //   id,
    //   view: 'ViewCM',
    // });
    const { companyId } = CaseManagersStore;
    dashboardStore.close('/view/add-edit-case-manager-2', true);
    localStorage.removeItem('CmName');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-case-manager-2?companyId=${companyId}`);
    });
    // const id = 0;
    // iframe.forceOpenTab('ViewCM', `?id=${id}&view=ViewCM`, {
    //   id,
    //   view: 'ViewCM',
    // });
  };

  handleChangeSearchDropdown = e => {
    CaseManagersStore.refetchSearch({
      keywordSearch: e.target.value,
      keyword: e.target.value,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(CaseManagersStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      Name: ``,
      Email: ``,
    });
    return array.map(opt => ({
      text: `${
        opt.Id === `All`
          ? `All`
          : `${opt.Id || ''} ${opt.FirstName ? `- ${opt.FirstName}` : ''} ${opt.LastName || ''} ${opt.Email}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FirstName || ''} ${opt.LastName || ''}`,
        key: `${opt.FirstName || ''} ${opt.LastName}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const cm = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (cm.id === `All` || value === `All`) {
      CaseManagersStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      CaseManagersStore.setSearchOnlyId(false);
      CaseManagersStore.resetSearch();
    } else {
      CaseManagersStore.setSearchOnlyId(true);
      CaseManagersStore.refetch(
        {
          keyword: cm.id,
          curPage: 1,
          numberSearch: 30,
          keywordSearch: `${cm.id}`,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(CaseManagersStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', Name: ``, Email: `` });
    let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FirstName || ''} ${opt.LastName || ''}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleExportFreeBookingOffers = () => {
    window.open('/Staff/ExportFreeBookingOffers', '_blank');
  };

  handleExportSalesCall = () => {
    window.open('/Staff/ExportSalesCall', '_blank');
  };

  handleRefresh = () => {
    CaseManagersStore.refetch(
      {
        dataSearch: null,
        data: null,
      },
      true,
    );
  };

  renderClientOfficer = () => {
    let clients = toJS(CaseManagersStore.dataClient.itemList);
    clients.unshift({
      Id: '',
      FullName: 'All',
    });
    return clients.map(client => ({
      value: client.Id,
      text: `${client.FullName}`,
    }));
  };

  handleScrollClient = event => {
    const target = event.target;
    const number = CaseManagersStore.numberClientPage;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      CaseManagersStore.refetchClient({ numberClientPage: number + 20 });
    }
  };

  handleClientSearch = (_, { searchQuery }) => {
    CaseManagersStore.refetchClient({ keywordClient: searchQuery });
  };

  handleFilterClient = (_, { value }) => {
    CaseManagersStore.refetch(
      {
        clientOfficerId: value,
      },
      true,
    );
  };
  renderClientOfficerText = value => {
    let clients = toJS(CaseManagersStore.dataClient.itemList);
    clients.unshift({
      Id: '',
      FullName: 'All',
    });
    return clients.filter(client => client.Id === value).map(client => client.FullName)[0];
  };

  renderActionPICOptions = () => {
    const array = [];
    const data = toJS(CaseManagersStore.dataActionPIC.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: `${opt.FullName} (${opt.Count})`,
          value: opt.Id,
        };
      }
    });
  };

  renderActionPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(CaseManagersStore.dataActionPIC.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return `${opt.FullName} (${opt.Count})`;
          }
        });
      return labels[0];
    }
  };

  handleFilterActionPIC = (_, { value }) => {
    CaseManagersStore.refetch({ ActionRequiredById: value, curPage: 1 }, true);
  };

  handleChangeFromToRequestingDate = (_, { value }) => {
    CaseManagersStore.refetch({ fromToRqtingDate: value, curPage: 1 }, true);
  };

  render() {
    const {
      keyword,
      loading,
      filter,
      verified,
      loadingSearch,
      loadingClient,
      keywordClient,
      clientOfficerId,
      cmState,
      filterSubcription,
      ActionRequiredById,
      fromToSaleDate,
      filterCaller,
      fromToRqtingDate,
      // filterType,
      totalItems,
    } = CaseManagersStore;
    return (
      <>
        <TableToolbar.Container>
          <TableToolbar.Left onSubmit={this.handleSubmit}>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              allowAdditions
              disabled={loading}
              additionLabel=""
              onScroll={this.handleScroll}
              selectOnNavigation={false}
              value={this.renderSearchText(keyword)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: '5px' }}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.handleAssignAM()}
              style={{ marginLeft: '5px' }}
            >
              Assign Account Manager
            </TableToolbar.HighLightButton>}
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openCaseCreate()}
              style={{ marginLeft: '5px' }}
            >
              Add New CM
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Account Status`}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Subscription`}
            value={this.renderFilterSubcriptionText(filterSubcription)}
            options={this.renderFilterSubcriptionOptions()}
            onChange={this.handleFilterSubcription}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`State`}
            value={this.renderStateText(cmState)}
            options={this.renderState()}
            onChange={this.handleSelectState}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Account Manager`}
            value={this.renderAccountManagerText(clientOfficerId)}
            options={this.renderAccountManagerOptions()}
            onChange={this.handleFilterClient}
            className="Toolbar-input"
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            label="Action PIC"
            value={this.renderActionPICText(ActionRequiredById)}
            options={this.renderActionPICOptions()}
            onChange={this.handleFilterActionPIC}
            disabled={loading}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`To Verify Info`}
            value={this.renderVerifiedFilterText(verified)}
            options={this.renderVerifiedFilterOptions()}
            onChange={this.handleVerifiedFilter}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`Relationship PIC`}
            value={this.renderCallerFilterText(filterCaller)}
            options={this.renderCallerFilterOptions()}
            onChange={this.handleCallerFilter}
          />
          <TableToolbar.RangePicker
            style={{ marginBottom: '1em' }}
            value={fromToSaleDate}
            onChange={this.handleChangeFromToSaleDate}
            className="Toolbar-range-picker"
            placeholder="Relationship Follow-up Due"
            disabled={loading}
          />
          <TableToolbar.RangePicker
            style={{ marginBottom: '1em' }}
            value={fromToRqtingDate}
            onChange={this.handleChangeFromToRequestingDate}
            className="Toolbar-range-picker"
            placeholder="Dates of Specialist Requested"
            disabled={loading}
          />
        </TableToolbar.Container>
      </>
    );
  }
}

export default CaseManagersToolbar;
