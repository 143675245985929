import './Tab.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import ui from '../../../stores/dashboard';
import router from '../../../stores/router';
import { preventDefault } from '../../../utils/events';
import routes from '../../dashboard-routes';
import Icon8 from '../../shared/Icon8';

const TabCloseIcon = () => (
  <svg viewBox="0 0 14 14">
    <path strokeWidth="1.125" d="M4 4 L10 10 M10 4 L4 10" />
  </svg>
);

const tabTitles = [
  {
    title: 'View Assessment',
    key: 'AssessmentCaseId',
    tabName: 'View Assessment',
  },
  {
    title: 'Video Session',
    key: 'VideoSessionCaseId',
    tabName: 'Video Session',
  },
  {
    title: 'View Supplementary Report',
    key: 'SupplementaryCaseId',
    tabName: 'View Supplementary Report',
  },
  {
    title: 'View File Review',
    key: 'FileReviewCaseId',
    tabName: 'View File Review',
  },
  {
    title: 'Add Edit Staff',
    key: 'StaffName',
    tabName: 'Add Edit Staff',
  },
  {
    title: 'View Med Neg',
    key: 'MedNegCaseId',
    tabName: 'View Med Neg',
  },
  {
    title: 'View Clinical Record',
    key: 'ClinicalRecordCaseId',
    tabName: 'View Clinical Record',
  },
  {
    title: 'View Imaging Service',
    key: 'ImagingCaseId',
    tabName: 'View Imaging Service',
  },
  {
    title: 'New Factual Investigation',
    key: 'FactualInvestigationCaseId',
    tabName: 'New Factual Investigation',
  },
  {
    title: 'New Med Neg',
    key: 'MedNegCaseId',
    tabName: 'New Med Neg',
  },
  { title: 'View Invoice', key: 'InvoiceCaseId', tabName: 'New Invoice' },
  { title: 'New Client', key: 'CompanyName', tabName: 'New Client' },
  { title: 'New Case Manager', key: 'CmName', tabName: 'New Case Manager' },
  { title: 'Add New Marketing Campaign', key: 'CampaignName', tabName: 'Add New Marketing Campaign' },
  { title: 'New Typist', key: 'TypistName', tabName: 'New Typist' },
  { title: 'New Specialist', key: 'DoctorName', tabName: 'New Specialist' },
  { title: 'New Clinic', key: 'ClinicName', tabName: 'New Clinic' },
  { title: 'New Claimant', key: 'ClaimantName', tabName: 'New Claimant' },
  { title: 'Add/Edit Job Title', key: 'JobTitle', tabName: 'Add Job Title' },
  {
    title: 'Add/Edit Type Of Claim',
    key: 'TypeOfClaims',
    tabName: 'Add Claim Type',
  },
  {
    title: 'Add/Edit Type Of Report',
    key: 'TypeOfReport',
    tabName: 'Add Report Type',
  },
  {
    title: 'Add/Edit IMS Approval Code',
    key: 'ImsApproval',
    tabName: 'Add IRO Code',
  },
  {
    title: 'Case Managers',
    key: 'ManageCmName',
    tabName: 'Case Managers',
  },
  {
    title: 'Search Cases',
    key: 'FindCases',
    tabName: 'Case Search',
  },
  {
    title: 'New Agreement',
    key: 'FundingAgreement',
    tabName: 'New Agreement',
  },
  {
    title: 'Add Funding Request',
    key: 'FundingRequest',
    tabName: 'Add Funding Request',
  },
  {
    title: 'New Transaction',
    key: 'FacilityReport',
    tabName: 'New Transaction',
  },
];
@observer
class Tab extends React.Component {
  render() {
    const { i, tabTitle } = this.props;
    const { tabs, tabDragdropState, closureOnTabMouseDown, closureCloseTab } = ui;
    const { dragging, index, x } = tabDragdropState;

    const { path, absUrl } = tabs[i];
    const style = { zIndex: tabs.length - i, left: x[i] };
    const active = path === router.location.pathname;
    const animation = dragging && index !== i;
    let { title, icontab } = routes[path];
    const foundTab = tabTitles.find(t => t.title === title);
    if (!tabTitle) {
      if (foundTab) {
        const titleFromLS = localStorage.getItem(foundTab.key);
        if (!!titleFromLS) {
          title = titleFromLS;
        } else {
          title = foundTab.tabName;
        }
      }
    } else {
      title = tabTitle;
    }

    return (
      <Link to={absUrl} style={style} className={cx('Tab', { active, animation })}>
        <div className="Tab-Background" />
        <div className="Tab-Content" onMouseDown={closureOnTabMouseDown(i)} title={title}>
          <Icon8 name={icontab ? icontab : 'dashboard-tab'} title={title} materialIcon />
          {title}
        </div>
        <div className="Tab-Close" onClick={closureCloseTab(path)} onMouseDown={preventDefault}>
          <TabCloseIcon />
        </div>
      </Link>
    );
  }
}

export { TabCloseIcon };
export default Tab;
