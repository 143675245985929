import { observer } from 'mobx-react';
import React from 'react';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';

const TextArea = ({ name, maxLength, ...props }) => {
  const { setFieldValue } = DoctorDetailsStore;
  const { [name]: value } = DoctorDetailsStore.dataDoctor;

  return (
    <div>
      <SemanticTextArea
        style={{ minHeight: '109px' }}
        name={name}
        maxLength={maxLength}
        value={value || ''}
        onChange={setFieldValue}
        {...props}
      />
      {maxLength && (
        <span className="float-right">
          {(value || '').length}/{maxLength}
        </span>
      )}
    </div>
  );
};

export default observer(TextArea);
