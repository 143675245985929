import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class MedicalCentresStore {
  @observable loading = true;
  @observable loadingModuleType = true;
  @observable loadingReceiver = true;
  @observable loadingCaseStage = true;
  @observable dataCaseStage = null;
  @observable dataModuleType = null;
  @observable dataReceiver = null;
  @observable data = null;

  @observable template = '';

  @observable keyword = '';

  @observable sortKey = 'TemplateKey';
  @observable sortColumn = 'TemplateKey';
  @observable sortDirection = 'ASC';

  @observable moduleType = -1;
  @observable status = -1;
  @observable receiverAlias = -1;
  @observable caseStages = -1;

  @observable curPage = 1;

  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable pageSize = 20;
  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchTemplate();
  };

  @action fetchReceiver = () => {
    this.loadingReceiver = true;
    customFetch(`/MailTemplate/GetListLookupConfig?type=mailreceivertype&keyword=`, {
      method: 'POST',
      // headers: { 'Content-type': 'application/json' },
      // body: JSON.stringify({
      //   type: 'mailmoduletype',
      // }),
    }).then(
      action(data => {
        this.dataReceiver = data;
        this.loadingReceiver = false;
      }),
    );
  };

  @action fetchMailModule = () => {
    this.loadingModuleType = true;
    customFetch(`/MailTemplate/GetListLookupConfig?type=mailmoduletype&keyword=`, {
      method: 'POST',
      // headers: { 'Content-type': 'application/json' },
      // body: JSON.stringify({
      //   type: 'mailmoduletype',
      // }),
    }).then(
      action(data => {
        this.dataModuleType = data;
        this.loadingModuleType = false;
      }),
    );
  };

  @action fetchCaseStage = () => {
    this.loadingCaseStage = true;
    customFetch(`/MailTemplate/GetListLookupConfig?type=casestages&keyword=`, {
      method: 'POST',
      // headers: { 'Content-type': 'application/json' },
      // body: JSON.stringify({
      //   type: 'mailmoduletype',
      // }),
    }).then(
      action(data => {
        this.dataCaseStage = data;
        this.loadingCaseStage = false;
      }),
    );
  };

  @action fetchTemplate = (setTotalPage = false) => {
    this.loading = true;
    const bodyParams = {
      Keyword: this.keyword,
      CurPage: this.curPage,
      Status: this.status,
      ReceiverAlias: this.receiverAlias,
      ServiceType: this.moduleType,
      NumItemPerPage: this.pageSize,
      SortBy: this.sortKey,
      CaseStage: this.caseStages,
      SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
    };
    customFetch('/MailTemplate/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyParams),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchTemplate(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'TemplateKey';
    this.sortKey = 'TemplateKey';
    this.sortDirection = 'ASC';
    this.moduleType = -1;
    this.status = -1;
    this.receiverAlias = -1;

    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new MedicalCentresStore();
