import { notification } from 'antd';
import { action, autorun, computed, observable, reaction, toJS } from 'mobx';
import moment from 'moment';
import customFetch from '../../../utils/customFetch';
import Validator from 'validatorjs';

import { formatDate, formatDate2 } from '../../../utils/functions';
import * as api from '../../../stores/api';
import { FILE_EXTENSION } from '@utils/constants';
import {
  getFileReviewInfo,
  getAssignHistory,
  getStaffs,
  getDoctors,
  getCompanies,
  getCMs,
  getClaimants,
  getClaimTypes,
  getReportTypes,
  getIMSCodes,
  getLawyers,
  getSendPaperMethods,
  getTypists,
  getConversations,
  submitFileReviewInfo,
  getBillingCodes,
  getActionRequired,
  getListInvoiceID,
  getFileReviewStatusLogs,
  updateFileReviewStatus,
  checkExcludeTypeOfService,
  getCompanyRecentChangeHistory,
  resendPaperworkReadiness,
  getUserUploadInfo,
  getCompanyInsuer,
  getLookUpConfig,
  getLookupIcareReport,
} from './service';
import { WIRO_INVOICE_TYPE, VIEW_INVOICE_GLOBAL, INIT_FILE_REVIEW, LOCAL_CONSTANT, CONSTANTS } from './type';

class FileReviewStore {
  // Data
  @observable loading = true;
  @observable loadingConversation = false;
  @observable fileReviewInfo = null;
  @observable claimDetailsErrorList = [];
  @observable IsExpectedCompletionDate = true;
  @observable currentStep = 2;
  @observable isReady = false;
  @observable fileReviewStatusLogs = null;
  @observable errorsForm = [];

  @observable listInvoiceID = [];

  // Select
  @observable staffs = [];
  @observable companyCMs = [];
  @observable companySolicitor = [];
  @observable pCompanyCMs = [];
  @observable subCMs = [];
  @observable claimTypes = [];
  @observable reportTypes = [];
  @observable imsCodes = [];
  @observable lawyers = [];
  @observable sendPaperMethods = [];
  @observable typists = [];
  @observable conversations = [];
  @observable doctors = [];
  @observable suppliers = [];
  @observable doctorsKeyword = '';
  @observable supplierKeyword = '';
  @observable doctorsPerPage = 30;
  @observable supplierPerPage = 30;
  @observable companies = [];
  @observable companiesKeyword = '';
  @observable companiesPerPage = 30;
  @observable subCompaniesKeyword = '';
  @observable subCompaniesPerPage = 30;
  @observable CMs = [];
  @observable CMsKeyword = '';
  @observable CMsPerPage = 1000;
  @observable claimants = [];
  @observable claimantsKeyword = '';
  @observable claimantsPerPage = 30;
  @observable pCustomer = null;
  @observable pCMs = [];
  @observable CMsKeyword = '';
  @observable CMsPerPage = 1000;
  @observable billingCodes = [];
  @observable numberBillingCodePerPage = 30;

  @observable listCompanyInsurer = null;
  @observable keywordCompanyInsurer = '';
  @observable numberCompanyInsurerPerPage = 30;
  @observable listReasonReferral = null;
  @observable icareLookupList = null;
  //Modal
  @observable isOpenModalConfirm = false;
  @observable modalParams = {};
  @observable isOpenModalHistory = false;
  @observable isOpenModalCompanyHistory = false;
  @observable assignHistory = [];
  @observable assignCompanyHistory = [];
  @observable open = false;
  @observable modalParams = {};
  @observable isOpenModalAction = false;
  @observable actionRequiredList = [];
  @observable modalUploadParams = {};
  @observable isOpenModalUpload = false;
  @observable isOpenModalDisbursementFunding = false;
  @observable modalDisbursementFundingParams = {};
  //--------------Lock report rule ------------------------------
  @observable IsLockedCorrespondence = false;
  @observable isLockReportCompleted_CM = false;
  @observable isLockReportCompleted = false;
  @observable isLockReturnedReport = false;
  @observable isLockDictation = false;
  @observable IsExpectedCompletionDate = true;
  @observable isLockedPaperword = false;
  @observable isLockedReport = false;
  @observable companyCMsKeyword = '';
  @observable companyCMsPerPage = 1000;
  @observable companySolicitorKeyword = '';
  @observable companySolicitorPerPage = 5000;
  @observable totalPages = 0;
  @observable AdditionalServiceSupplierObj = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleRemoveFile = key => {
    const attachments = this.fileReviewInfo.FileReviewAttachment;
    const newAttachments = attachments.filter(i => i.key !== key);
    this.fileReviewInfo = {
      ...this.fileReviewInfo,
      FileReviewAttachment: newAttachments,
    };
  };

  @action toggleModalModalUpload = (isOpenModalUpload, modalUploadParams = {}) => {
    this.isOpenModalUpload = isOpenModalUpload;
    this.modalUploadParams = modalUploadParams;
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.isOpenModalConfirm = isOpen;
    this.modalParams = modalParams;
  };

  @action toggleModalAction = isOpen => {
    this.isOpenModalAction = isOpen;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  compareDate = (dateF, dateS) => {
    if (!!dateF && !dateS) {
      return true;
    } else if (!dateF && !!dateS) {
      return false;
    } else {
      return moment(dateF).isSameOrAfter(dateS);
    }
  };

  handleRefetchActionNote = () => {
    const { fileReviewInfo } = this;
    getActionRequired(fileReviewInfo.Id)
      .then(
        action(res => {
          if (res.status === 'success') {
            this.actionRequiredList = res.itemList || [];
          }
        }),
      )
      .catch(() => {});
  };

  @action handleChangeValue = (fieldName, value) => {
    const { fileReviewInfo } = this;
    this.fileReviewInfo = { ...fileReviewInfo, [fieldName]: value };
  };

  @action handleChangeFileReviewValue = (obj = {}) => {
    if (obj == {} || typeof obj !== 'object') {
      return;
    } else {
      Object.keys(obj).forEach(key => {
        this.fileReviewInfo[key] = obj[key];
      });
    }
  };

  @action openModalHistory = actionName => {
    getAssignHistory({
      id: this.fileReviewInfo.Id,
      actionName,
    }).then(
      action(res => {
        this.assignHistory = res.itemList;
        this.isOpenModalHistory = true;
      }),
    );
  };

  @action openModalCompanyHistory = () => {
    getCompanyRecentChangeHistory(this.fileReviewInfo.Id, 'fr').then(
      action(res => {
        this.assignCompanyHistory = res.itemList;
        this.isOpenModalCompanyHistory = true;
      }),
    );
  };

  handleCheckNCIPeriod = (specialistId, oldId) => {
    const specialist = this.doctors.find(i => i.Id === specialistId) ?? {};
    const { IsNotCurrentlyInterested, NotCurrentlyInterestedDate, NotCurrentlyInterestedEndDate } = specialist;
    if (!!IsNotCurrentlyInterested) {
      if (
        moment().isSameOrAfter(moment(NotCurrentlyInterestedDate), 'day') &&
        moment().isSameOrBefore(moment(NotCurrentlyInterestedEndDate), 'day')
      ) {
        setTimeout(() => {
          this.toggleModalConfirm(true, {
            modalType: 'confirm',
            message: 'This specialist indicates NCI during this period, do you want to continue with this booking?',
            functionCallbackCancel: () => this.handleChangeValue('DoctorId', oldId),
          });
        }, 500);
      }
    }
    this.handleCheckICare();
  };

  handleDoctorChanged = (oldId, newId) => {
    const newItem = this.selectedDoctor;
    this.handleChangeValue('DoctorId', newId);
    if (newId && api.isAdminOrMagStaffOrAccounting() & !this.fileReviewInfo.Id) {
      customFetch('/FileReview/GetAttachContractDoctor', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          doctorId: newId,
        }),
      }).then(response => {
        if (response.status === 'success') {
          var contract = response.contract;
          let isOnboarded =
            contract.HasContractWithMag &&
            moment(contract.ContractWithMagDate).isBefore(moment()) &&
            (!contract.ContractWithMagEndDate || moment(contract.ContractWithMagEndDate).isAfter(moment()));

          if (!isOnboarded) {
            this.toggleModalConfirm(true, {
              message: 'This specialist does not have a contract with us. Do you want to continue?',
              onOk: () => {
                this.handleChangeValue('DoctorId', newId);
                this.handleChangeValue('SelectedDoctorProviderNoIdx', null);
                this.handleCheckNCIPeriod(newId, oldId);
              },
              functionCallbackCancel: () => {
                this.handleChangeValue('DoctorId', oldId);
                this.handleCheckICare();
              },
            });
          } else {
            this.handleCheckNCIPeriod(newId, oldId);
          }
        }
      });
    }
    if (!this.fileReviewInfo.Id && newItem?.DictationOptionStartDate) {
      const sDate = newItem.DictationOptionStartDate ? moment(newItem.DictationOptionStartDate).toDate() : null;
      if (
        newItem?.DictationOptionEndDate &&
        this.inRangeDate(new Date(), sDate, moment(newItem?.DictationOptionEndDate).toDate())
      )
        this.handleChangeValue('DictationOption', newItem.DictationOption);
      else if (this.inRangeDate2(new Date(), sDate)) this.handleChangeValue('DictationOption', newItem.DictationOption);
      else this.handleChangeValue('DictationOption', 1);
    } else {
      this.handleChangeValue('DictationOption', 1);
    }
  };

  @action handleChangeStatusToCancelled = statusToCancelled => {
    var findARIncompleted = this.actionRequiredList ? this.actionRequiredList.filter(i => !i.Completed) : 0;
    if (!api.isCaseManager() && findARIncompleted && findARIncompleted.length > 0) {
      notification.destroy();
      notification.info({
        message: 'Action Required Reminder',
        description: 'Please check and clear pending required actions as needed.',
        duration: 15,
      });
    }
    this.fileReviewInfo.NotifyCancellationToCM = true;
    if (statusToCancelled !== CONSTANTS.CaseStatus_None) {
      if (
        ((statusToCancelled === CONSTANTS.CaseStatus_CancelledNoCharge ||
          statusToCancelled === CONSTANTS.CaseStatus_CancelledWithFee) &&
          this.fileReviewInfo.IsPaperworkReceived) ||
        !api.isCaseManager()
      ) {
        this.fileReviewInfo.NotifyCancellationToDoctor = true;
      } else {
        this.fileReviewInfo.NotifyCancellationToDoctor = false;
      }
    }
    if (this.fileReviewInfo.BookingSolicitorId && this.fileReviewInfo.BookingSolicitorId > 0) {
      this.fileReviewInfo.NotifyCancellationToSolictor = true;
    }
    this.fileReviewInfo.ReasonCancellation = 1;
  };

  setFieldValue = key =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (key === 'DoctorId' && value) {
        let findDoctor = this.doctors.find(i => i.Id === value);
        if (findDoctor) {
          this.fileReviewInfo.SpecialistPaperworkNotes = findDoctor.PaperworkNotes;
        }
        var dId = value;
        if (dId && (this.fileReviewInfo.ReportTypeId || this.fileReviewInfo.ClaimType?.Id)) {
          notification.destroy();
          checkExcludeTypeOfService(dId, this.fileReviewInfo.ClaimType?.Id, this.fileReviewInfo.ReportTypeId).then(
            resp => {
              if (resp.status === 'success' && resp.isExcludeService) {
                notification.warning({
                  message: 'Error',
                  description: `This specialist does not accept performing the required service. Please check with our staff`,
                  duration: 15,
                });
              }
            },
          );
        }
      }

      if (key === 'ReportTypeId') {
        var dId = this.fileReviewInfo.DoctorId;
        var tor = 0;
        if (key === 'ReportTypeId') tor = value;
        else tor = this.fileReviewInfo.ReportTypeId;
        if (dId && tor) {
          notification.destroy();
          checkExcludeTypeOfService(dId, this.fileReviewInfo.ClaimType?.Id, tor).then(resp => {
            if (resp.status === 'success' && resp.isExcludeService) {
              notification.warning({
                message: 'Error',
                description: `This specialist does not accept performing the required service. Please check with our staff`,
                duration: 15,
              });
            }
          });
        }
      }

      if (key === 'SubCaseManagerIds' && value.length > 3) {
        return;
      }

      if (key === 'DoctorId') {
        // this.fileReviewInfo[key] = value;
        return this.handleDoctorChanged(this.fileReviewInfo.DoctorId, value);
      }

      if (key === 'PatientId') {
        return (this.fileReviewInfo[key] = value);
        // return (this.fileReviewInfo.patientInfo = this.claimants.find(
        //   ({ Id }) => {
        //     return Id === value;
        //   },
        // ));
      }
      if (key === 'CaseStatus' && !!value) {
        this.handleChangeValue('CancelledDate', moment());
        if (value === 1 || value === 2) {
          this.toggleModalConfirm(true, {
            message: 'Once a case is cancelled, the case status can not be changed. Do you want to proceed?',
            onOk: () => {
              this.handleChangeStatusToCancelled(value);
            },
            functionCallbackCancel: action(() => {
              this.fileReviewInfo.CaseStatus = 0;
            }),
          });
        }
      }

      if (key === 'CustomerId') {
        if (this.fileReviewInfo.Id > 0) {
          this.toggleModalConfirm(true, {
            message: 'Changing Booking Client can affect billing information later. Do you want to proceed?',
            onOk: action(() => {
              this.fileReviewInfo.CaseManagerId = null;
              this.fileReviewInfo.BookingSolicitorId = null;
              this.fileReviewInfo.F_RDCCaseManagerId = null;
              this.fileReviewInfo.F_RDCEmail = null;
              getCMs({ companyId: value }).then(
                action(res => {
                  if (value) {
                    this.companyCMs = res.itemList;
                    this.companySolicitor = res.itemList;
                  } else {
                    this.companyCMs = [];
                    this.companySolicitor = [];
                  }
                }),
              );
              if (!this.fileReviewInfo.Id || this.fileReviewInfo.Id <= 0) {
                var customer = this.companies.find(i => i.Id === value);
                if (customer.ReportDeliveryContact) {
                  this.fileReviewInfo.S_RDCCaseManagerId = customer.ReportDeliveryContact;
                }
              }
              const data_ = this.companies.find(i => i.Id === value);
              if (data_ && data_.CreditTerm === 1) {
                //ClientPrepaymentRequired
                this.fileReviewInfo.ClientPrepaymentRequired = true;
                this.fileReviewInfo.ClientPrepaymentRequiredDate = moment();
                if (!this.fileReviewInfo.DelayPrepaymentRequired) {
                  this.fileReviewInfo.DelayPrepaymentRequired = true;
                }
              }
              this.fileReviewInfo[key] = value;
            }),
          });
          return;
        } else {
          this.fileReviewInfo.CaseManagerId = null;
          this.fileReviewInfo.BookingSolicitorId = null;
          this.fileReviewInfo.F_RDCCaseManagerId = null;
          this.fileReviewInfo.F_RDCEmail = null;
          getCMs({ companyId: value }).then(
            action(res => {
              if (value) {
                this.companyCMs = res.itemList;
                this.companySolicitor = res.itemList;
              } else {
                this.companyCMs = [];
                this.companySolicitor = [];
              }
            }),
          );
          if (!this.fileReviewInfo.Id || this.fileReviewInfo.Id <= 0) {
            var customer = this.companies.find(i => i.Id === value);
            if (customer.ReportDeliveryContact) {
              this.fileReviewInfo.S_RDCCaseManagerId = customer.ReportDeliveryContact;
            }
          }
          const data_ = this.companies.find(i => i.Id === value);
          if (data_ && data_.CreditTerm === 1) {
            //ClientPrepaymentRequired
            this.fileReviewInfo.ClientPrepaymentRequired = true;
            this.fileReviewInfo.ClientPrepaymentRequiredDate = moment();
            if (!this.fileReviewInfo.DelayPrepaymentRequired) {
              this.fileReviewInfo.DelayPrepaymentRequired = true;
            }
          }
        }
        this.handleCheckICare();
      }
      if (key === 'SubCustomerId') {
        getCMs({ companyId: value }).then(
          action(res => {
            if (value) {
              this.subCMs = res.itemList;
              this.fileReviewInfo.SubCaseManagerIds = [];
            } else {
              this.subCMs = [];
            }
          }),
        );
      }

      if (key === 'PCustomerId') {
        this.fileReviewInfo.PCaseManagerId = null;
        getCMs({ companyId: value }).then(
          action(res => {
            if (value) {
              this.pCompanyCMs = res.itemList;
            } else {
              this.pCompanyCMs = [];
            }
          }),
        );
      }
      if (key === 'PaperworkByOtherMethodDate') {
        var momentBusinessDays = require('moment-business-days');
        this.fileReviewInfo.RequestExpectedCompletionDate = momentBusinessDays(value).businessAdd(7);
      }

      this.fileReviewInfo[key] = value;
      if (key === 'SelectedPaperworkToDoctorBies') {
        if (!!value.length) {
          this.handleChangeValue('IsPaperworkReceived', true);
        } else {
          this.handleChangeValue('IsPaperworkReceived', false);
        }
        // if (this.fileReviewInfo.PaperworkByOtherMethod) {
        //   this.handleChangeValue('IsPaperworkReceived', true);
        //   this.handleChangeValue('PaperworkByOtherMethodDate', moment());
        // } else {
        //   this.handleChangeValue('PaperworkByOtherMethodDate', null);
        // }
        this.onIsPaperworkReceivedChanged();
      }
    });
  functionCallbackAfterUploadCorrectedReport = () => {
    this.handleChangeValue('ReportReturnedFromDoctor', true);
    if (this.fileReviewInfo.ReportCompletedDate) {
      this.handleChangeValue('ReportCompletedDate', moment());
    }
  };
  checkDelayPrepaymentRequired = () => {
    const { fileReviewInfo } = this;
    if (fileReviewInfo.DelayPrepaymentRequired && !fileReviewInfo.ClientPrepaymentPaidDate) {
      this.showError(
        'This case has delayed sending report to client. Please check under Client & Specialist Payments.',
      );
      [
        'ReportCompleted',
        'IsAllowSolicitorViewReport',
        'IsAllowCMViewReport',
        'IsAllowSolicitorViewAmendedReport',
        'IsAllowCMViewAmendedReport',
      ].map(i => this.handleChangeValue(i, false));
      return false;
    }
    return true;
  };
  AllowSolicitorViewReportChanged = (fieldName, value) => {
    if (value) {
      if (!this.checkDelayPrepaymentRequired()) {
        return;
      }
      const ReportCompletedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 4);
      if (!!ReportCompletedAttachment.length) {
        if (!!this.fileReviewInfo.F_RDCCaseManagerId) {
          if (value) {
            this.toggleModalConfirm(true, {
              message: 'Are you sure to allow Client Receiving Contact 1 to view completed reports?',
              onOk: () => this.handleChangeValue('IsAllowSolicitorViewReport', true),
            });
          } else {
            this.fileReviewInfo[fieldName] = value;
          }
        } else {
          this.showError('You need select Client Receiving Contact 1 reports first!');
          this.handleChangeValue('IsAllowSolicitorViewReport', false);
        }
      } else {
        this.handleChangeValue('IsAllowSolicitorViewReport', false);
        this.showError('You need to attach completed reports first!');
      }
    } else {
      this.fileReviewInfo[fieldName] = value;
    }
  };
  AllowSolicitorViewAmendedReportChanged = checked => {
    if (!this.checkDelayPrepaymentRequired()) {
      return;
    }
    const ReportCompletedAmendedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 7);
    if (!!ReportCompletedAmendedAttachment.length) {
      if (!!this.fileReviewInfo.F_RDCCaseManagerId) {
        if (checked) {
          this.toggleModalConfirm(true, {
            message: 'Are you sure to allow Client Receiving Contact 1 to view amended reports?',
            onOk: () => this.handleChangeValue('IsAllowSolicitorViewAmendedReport', true),
          });
        } else {
          this.handleChangeValue('IsAllowSolicitorViewAmendedReport', checked);
        }
      } else {
        this.showError('You need select Client Receiving Contact 1 reports first!');
        this.handleChangeValue('IsAllowSolicitorViewAmendedReport', false);
      }
    } else if (checked) {
      this.handleChangeValue('IsAllowSolicitorViewAmendedReport', false);
      this.showError('You need to attach amended reports first!');
    }
  };
  AllowCMViewReportChanged = checked => {
    if (!this.checkDelayPrepaymentRequired()) {
      return;
    }
    const ReportCompletedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 4);
    if (!!ReportCompletedAttachment.length) {
      if (checked) {
        if (!this.fileReviewInfo.F_RDCEmail && !this.fileReviewInfo.S_RDCEmail) {
          this.showError('At least one Report Delivery Contact needs to be completed');
          this.handleChangeValue('IsAllowCMViewReport', false);
        } else {
          this.toggleModalConfirm(true, {
            message: 'Are you sure to allow Client Receiving Contact 2 to view completed reports?',
            onOk: () => {
              this.handleChangeValue('IsAllowCMViewReport', true);
            },
          });
        }
      } else {
        this.handleChangeValue('IsAllowCMViewReport', checked);
      }
    } else if (checked) {
      this.handleChangeValue('IsAllowCMViewReport', false);
      this.showError('You need to attach completed reports first!');
    }
  };
  AllowSpecialistViewReportChanged = checked => {
    if (!this.checkDelayPrepaymentRequired()) {
      return;
    }
    const ReportCompletedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 4);
    if (!!ReportCompletedAttachment.length) {
      this.handleChangeValue('IsAllowSpecialistViewReport', checked);
    } else if (checked) {
      this.handleChangeValue('IsAllowSpecialistViewReport', false);
      this.showError('You need to attach completed reports first!');
    }
  };

  AllowCMViewAmendedReportChanged = checked => {
    if (!this.checkDelayPrepaymentRequired()) {
      return;
    }
    const ReportCompletedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 7);
    if (!!ReportCompletedAttachment.length) {
      if (checked) {
        if (!this.fileReviewInfo.F_RDCEmail && !this.fileReviewInfo.S_RDCEmail) {
          this.showError('At least one Report Delivery Contact needs to be completed');
          this.handleChangeValue('IsAllowCMViewAmendedReport', false);
        } else {
          this.toggleModalConfirm(true, {
            message: 'Are you sure to allow Client Receiving Contact 2 to view amended reports?',
            onOk: () => {
              this.handleChangeValue('IsAllowCMViewAmendedReport', true);
            },
          });
        }
      } else {
        this.handleChangeValue('IsAllowCMViewAmendedReport', checked);
      }
    } else if (checked) {
      this.handleChangeValue('IsAllowCMViewAmendedReport', false);
      this.showError('You need to attach amended reports first!');
    }
  };

  CheckWiRo = () => {
    let bRet = false;

    if (
      this.fileReviewInfo.WifroIlars &&
      (!this.fileReviewInfo.IlarsRef || !this.fileReviewInfo.IMSApprovalCode || !this.fileReviewInfo.IlarsWiroSolicitor)
    ) {
      bRet = true;
    }
    if (!bRet) {
      if (
        !this.fileReviewInfo.WifroIlars &&
        this.selectedCustomer &&
        this.selectedCustomer.ClientType &&
        this.selectedCustomer.ClientType === 3 &&
        this.selectedCustomer &&
        this.selectedCustomer.StateName &&
        this.selectedCustomer.StateName === 'NSW' &&
        this.selectedPatient.selected &&
        this.selectedPatient.selected.Id &&
        this.selectedPatient.selected.Id === 13 &&
        this.fileReviewInfo.ReportTypeId === 31
      ) {
        bRet = true;
      }
    }
    //}
    return bRet;
  };

  sendWarningWiro = () => {
    var objSend = {
      RecordId: this.fileReviewInfo.Id,
      IlarsRef: this.fileReviewInfo.IlarsRef,
      IMSApprovalCode: this.fileReviewInfo.IMSApprovalCode,
      IlarsWiroSolicitorName: this.fileReviewInfo.IlarsWiroSolicitor,
    };
    customFetch('/FileReview/SendWiroMissing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...objSend }),
    }).then(res => {
      if (res.status === 'success') {
        notification.success({
          message: 'Success',
          description: 'IRO Reminders',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'IRO Reminders',
        });
      }
    });
  };

  ProcessWiroWarning = type => {
    let msg =
      'IRO information for this case has not been provided and payment may not be approved. Do you want to send IRO reminder now?';
    if (type === 2) {
      msg =
        'IRO information for this case has not been provided and payment may not be approved. Do you still want to send the report?';
    }
    return this.toggleModalConfirm(true, {
      message: msg,
      onOk: () => {
        if (type === 1) {
          this.handleChangeValue('ReportReturnedFromDoctor', false);
          this.handleChangeValue('ReportCompletedDate', null);
          this.sendWarningWiro();
        }
        // if (type === 2) {
        //   this.toggleModalConfirm(true, {
        //     message: 'Do you want to send IRO reminder to booking CM now?',
        //     onOk: () => {
        //       this.sendWarningWiro();
        //     },
        //   });
        // }
      },
      functionCallbackCancel: () => {
        if (type === 2) {
          this.handleChangeValue('ReportCompleted', false);
          this.handleChangeValue('ReportCompletedSentDate', null);
          this.toggleModalConfirm(true, {
            message: 'Do you want to send IRO reminder to booking CM now?',
            onOk: () => {
              this.sendWarningWiro();
            },
          });
        }
      },
    });
  };

  reactionCalculateInvoice = null;
  reactionReportCompleted = null;
  reactionReportDraftReceived = null;
  reactionListServiceItems = null;
  reactionPCSameBookingClient = null;
  reactionPMSameBookingCM = null;

  disposeReaction = () => {
    this.reactionCalculateInvoice();
    this.reactionReportCompleted();
    this.reactionReportDraftReceived();
    this.reactionListServiceItems();
    this.reactionPCSameBookingClient();
    this.reactionPMSameBookingCM();
  };

  autoChangeFunction = () => {
    this.reactionCalculateInvoice = reaction(
      () => this.isReady,
      () => {
        if (this.isReady) {
          this.calculateInvoice();
        } else {
          return;
        }
      },
    );
    this.reactionDictationReceived = reaction(
      () => this.fileReviewInfo.DictationReceived,
      () => this.handleChangeValue('DictationReceivedDate', this.fileReviewInfo.DictationReceived ? moment() : null),
    );
    autorun(() => {
      const { ReportDraftReceived } = this.fileReviewInfo;
      if (ReportDraftReceived) {
        return action(() => {
          this.fileReviewInfo.ReportDraftReceivedDate = moment();
          this.fileReviewInfo.ReportDraftSentDate = moment();
        })();
      } else {
        return action(() => {
          this.fileReviewInfo.ReportDraftReceivedDate = null;
          this.fileReviewInfo.ReportDraftSentDate = null;
        })();
      }
    });
    autorun(() => {
      const { CaseManagerId } = this.fileReviewInfo;
      return action(() => {
        if (this.fileReviewInfo.Id === 0) {
          this.fileReviewInfo.F_RDCSameBookingCM = !!CaseManagerId;
          this.fileReviewInfo.F_RDCCaseManagerId = CaseManagerId;
          this.fileReviewInfo.F_RDCEmail = this.selectedCM?.Email || '';
        }
      })();
    });
    autorun(() => {
      const { F_RDCSameBookingCM, CaseManagerId, CaseManager, PMSameBookingCM } = this.fileReviewInfo;
      return action(() => {
        if (!!F_RDCSameBookingCM) {
          this.fileReviewInfo.F_RDCCaseManagerId = CaseManagerId;
          this.fileReviewInfo.F_RDCEmail = this.selectedCM?.Email || CaseManager?.Email || '';
        }

        if (!!PMSameBookingCM) {
          this.fileReviewInfo.PCaseManagerId = this.selectedCM?.Id ?? null;
          this.fileReviewInfo.PCaseManager = { ...this.selectedCM };
        }
      })();
    });
    autorun(() => {
      const { ClientPrepaymentReceived } = this.fileReviewInfo;
      return action(() => {
        if (!ClientPrepaymentReceived) {
          this.fileReviewInfo.ClientPrepaymentPaidDate = null;
        } else {
          this.fileReviewInfo.ClientPrepaymentPaidDate = moment();
        }
      })();
    });
    this.reactionReportCompleted = reaction(
      () => this.fileReviewInfo.ReportCompleted,
      () => {
        const { ReportCompleted } = this.fileReviewInfo;
        if (ReportCompleted) {
          if (!this.checkDelayPrepaymentRequired()) {
            return;
          }
          if (this.CheckWiRo()) {
            this.ProcessWiroWarning(2);
          }
          action(() => {
            this.fileReviewInfo.ReportCompletedSentDate = moment();
          })();
        } else {
          action(() => {
            this.fileReviewInfo.ReportCompletedSentDate = null;
          })();
        }
      },
    );
    autorun(() => {
      const { PaperworkExLink } = this.fileReviewInfo;
      return this.handleAddOrRemove(this.validateField(PaperworkExLink, 'url'), 'PaperworkExLink');
    });
    autorun(() => {
      const { PaperworkOtherExLink } = this.fileReviewInfo;
      return this.handleAddOrRemove(this.validateField(PaperworkOtherExLink, 'url'), 'PaperworkOtherExLink');
    });
    autorun(() => {
      const { ReportReturnedFromDoctor, ReportCompletedDate } = this.fileReviewInfo;
      if (ReportReturnedFromDoctor) {
        if (!ReportCompletedDate) {
          action(() => {
            this.fileReviewInfo.ReportCompletedDate = moment();
          })();
        }
      }
    });
    autorun(() => {
      const { SendInvoice2 } = this.fileReviewInfo.ServiceNotesandFeeObj;
      const isPassed = this.validateField(SendInvoice2, 'email');
      return this.handleAddOrRemove(isPassed, 'SendInvoice2');
    });
    autorun(() => {
      const { SendInvoice } = this.fileReviewInfo.ServiceNotesandFeeObj;
      const isPassed = this.validateField(SendInvoice, 'email');
      return this.handleAddOrRemove(isPassed, 'SendInvoice');
    });
    autorun(() => {
      const { SpecialistPrepaymentPaid, PrepaymentPaidDate } = this.fileReviewInfo;
      const isPassed = SpecialistPrepaymentPaid && !PrepaymentPaidDate;
      return this.handleAddOrRemove(!isPassed, 'PrepaymentPaidDate');
    });

    autorun(() => {
      const { ClientPrepaymentRequired, ClientPrepaymentRequiredDate } = this.fileReviewInfo;
      const isPassed = ClientPrepaymentRequired && !ClientPrepaymentRequiredDate;
      return this.handleAddOrRemove(!isPassed, 'ClientPrepaymentRequiredDate');
    });
    autorun(() => {
      const { ClientPrepaymentReceived, ClientPrepaymentPaidDate } = this.fileReviewInfo;
      const isPassed = ClientPrepaymentReceived && !ClientPrepaymentPaidDate;
      return this.handleAddOrRemove(!isPassed, 'ClientPrepaymentPaidDate');
    });
    autorun(() => {
      const { PrepaymentRequired, PaymentRequestedDate } = this.fileReviewInfo;
      const isPassed = PrepaymentRequired && !PaymentRequestedDate;
      return this.handleAddOrRemove(!isPassed, 'PaymentRequestedDate');
    });
    autorun(() => {
      const { DoctorPrepaymentRequired, PrepaymentRequiredDate } = this.fileReviewInfo;
      const isPassed = DoctorPrepaymentRequired && !PrepaymentRequiredDate;
      return this.handleAddOrRemove(!isPassed, 'PrepaymentRequiredDate');
    });
    this.reactionReportDraftReceived = reaction(
      () => this.fileReviewInfo.ReportDraftReceived,
      () => {
        this.ReportDraftReceivedChanged();
      },
    );

    this.reactionListServiceItems = reaction(
      () => this.fileReviewInfo.ServiceNotesandFeeObj.ListServiceItems,
      () => {
        this.calculateInvoice();
      },
    );

    this.reactionPCSameBookingClient = reaction(
      () => this.fileReviewInfo.PCSameBookingClient,
      () =>
        action(() => {
          this.fileReviewInfo.PCustomerId = this.selectedCustomer?.Id ?? null;
          this.fileReviewInfo.PCustomer = { ...this.selectedCustomer };
        })(),
    );

    this.reactionPMSameBookingCM = reaction(
      () => this.fileReviewInfo.PMSameBookingCM,
      () =>
        action(() => {
          this.fileReviewInfo.PCaseManagerId = this.selectedCM?.Id ?? null;
          this.fileReviewInfo.PCaseManager = { ...this.selectedCM };
        })(),
    );
  };

  @action ReportDraftReceivedChanged = function() {
    const reportDraftReceivedAttachment = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 3);
    if (this.fileReviewInfo.ReportDraftReceived) {
      this.fileReviewInfo.ReportDraftReceivedDate = moment();
      this.fileReviewInfo.ReportDraftSentDate = moment();
      if (
        (!this.fileReviewInfo.EmailReportDraftToDoctorBE || !this.fileReviewInfo.EmailReportDraftToDoctor) &&
        !!reportDraftReceivedAttachment.length
      ) {
        this.toggleModalConfirm(true, {
          message: 'Do you want to email report draft to provider/specialist?',
          onOk: () => {
            this.handleChangeValue('EmailReportDraftToDoctorBE', true);
            this.handleChangeValue('EmailReportDraftToDoctor', true);
          },
        });
      }
    } else {
      this.fileReviewInfo.EmailReportDraftToDoctorBE = !!this.fileReviewInfo.EmailReportDraftToDoctorDateSent;
      this.fileReviewInfo.EmailReportDraftToDoctorPE = !!this.fileReviewInfo.EmailReportDraftToDoctorDateSent;
      this.fileReviewInfo.EmailReportDraftToDoctor = !!this.fileReviewInfo.EmailReportDraftToDoctorDateSent;
    }
  };

  handleAddOrRemove = (isPassed, fieldName) => {
    if (isPassed) {
      action(() => {
        this.errorsForm = this.errorsForm.filter(i => i !== fieldName);
      })();
    } else {
      action(() => {
        const exists = this.errorsForm.filter(i => i === fieldName);
        if (!!exists.length) return;
        return (this.errorsForm = [...this.errorsForm, fieldName]);
      })();
    }
  };

  reportDraftUploadCompleted = () => {
    const itemModel = toJS(this.fileReviewInfo);
    const specialist = toJS(this.selectedDoctor);
    this.handleChangeValue('ReportDraftSentDate', moment());
    if (api.isAdminOrMagStaffOrAccounting()) {
      this.handleChangeValue('ReportDraftReceived', true);
      this.handleChangeValue('ReportDraftSentDate', moment());

      if (!itemModel.EmailReportDraftToDoctorBE || !itemModel.EmailReportDraftToDoctor) {
        this.toggleModalConfirm(true, {
          message: 'Do you want to attach report draft to notification email to provider?',
          onOk: () => {
            this.handleChangeValue('EmailReportDraftToDoctor', true);
            this.handleChangeValue('EmailReportDraftToDoctorBE', true);
          },
        });
      }
    }
  };

  toggleCheckbox = key =>
    action(event => {
      const { checked } = event.target;
      if (key === 'IsAllowSolicitorViewReport') {
        return this.AllowSolicitorViewReportChanged('IsAllowSolicitorViewReport', checked);
      }
      if (key === 'ReportReturnedFromDoctor') {
        if (checked) {
          if (this.CheckWiRo()) {
            this.ProcessWiroWarning(1);
          }
        }
      }
      if (key === 'IsAllowCMViewReport') {
        return this.AllowCMViewReportChanged(checked);
      }
      if (key === 'IsAllowSpecialistViewReport') {
        return this.AllowSpecialistViewReportChanged(checked);
      }
      if (key === 'IsAllowSolicitorViewAmendedReport') {
        return this.AllowSolicitorViewAmendedReportChanged(checked);
      }
      if (key === 'IsAllowCMViewAmendedReport') {
        return this.AllowCMViewAmendedReportChanged(checked);
      }
      if (key === 'NotifyPaperworkReadinessToStaff' && checked && api.isCaseManager()) {
        const attachmentsRequest = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 1);
        if (!attachmentsRequest.length && !this.fileReviewInfo.PaperworkExLink) {
          this.showError('You need to attach LOI, paperwork or share download links before sending to specialist.');
          this.handleChangeValue('AllowDoctorToViewPaperwork', false);
        } else {
          this.fileReviewInfo[key] = checked;
        }
        return;
      }
      if (key === 'EmailReportDraftToDoctor') {
        const reportDraftAttachments = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 3);
        if (!reportDraftAttachments.length && checked) {
          return this.showError('You must attach report draft to send to Doctor.');
        } else {
          if (checked) {
            this.fileReviewInfo.ReportDraftReceived = checked;
          }
          return (this.fileReviewInfo[key] = checked);
        }
      }

      if (key === 'ReportCompleted') {
        const reportCompletedAttachments = this.fileReviewInfo.FileReviewAttachment.filter(i => i.ReportType === 4);
        if (!reportCompletedAttachments.length && checked) {
          notification.info({
            message: 'Information',
            description: 'Please remember to attach reports',
            duration: 5,
          });
        }
      }

      if (key === 'DictationSentToTypist') {
        return this.onDictationToTypistChanged(checked);
      }
      if (key === 'SentToTypist' && checked) {
        this.fileReviewInfo.DictationSentToTypist = true;
      }
      if ((api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && checked && key === 'DictationReceived') {
        this.fileReviewInfo.DictationOption = 0;
        return (this.fileReviewInfo[key] = checked);
      }

      if (key === 'WifroIlars') {
        if (checked && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
          notification.info({
            message: 'Information',
            description: 'Funding approval must be provided before paperwork can be sent to specialist',
            duration: 15,
          });
        }
      }
      if (key === 'IROFundingApproved') {
        if (checked) this.fileReviewInfo.IROFundingApprovalDate = moment();
        return (this.fileReviewInfo[key] = checked);
      }

      this.fileReviewInfo[key] = checked;
      if (key === 'F_RDCSameBookingCM' && checked) {
        this.fileReviewInfo.F_RDCCaseManagerId = this.fileReviewInfo.CaseManagerId;
        this.fileReviewInfo.F_RDCEmail = this.selectedCM?.Email || this.fileReviewInfo.CaseManager?.Email || '';
      }
      if (key === 'AllowDoctorToViewPaperwork') {
        this.onAllowDoctorToViewPaperworkChange();
      }
      if (key === 'IsPaperworkReceived') {
        this.onIsPaperworkReceivedChanged();
      }
    });

  showError = message => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 6,
    });
  };

  onIsPaperworkReceivedChanged = () => {
    const itemModel = this.fileReviewInfo;
    if (itemModel.IsPaperworkReceived) {
      // let showMessage = true;
      // if (itemModel.AllowDoctorToViewPaperwork) {
      //   showMessage = false;
      // }
      // const selectedItems = itemModel.SelectedPaperworkToDoctorBies;
      // if (showMessage && selectedItems.length > 0) {
      //   showMessage = false;
      // }
      if (!this.selectedDoctor || !this.selectedDoctor.Id) {
        this.showError('Assessing Specialist in Claim Details must be updated first!');
        this.handleChangeValue('IsPaperworkReceived', false);
        this.handleChangeValue('AllowDoctorToViewPaperwork', false);
        return;
      }

      const attachmentsRequest = itemModel.FileReviewAttachment.filter(i => i.ReportType === 1);

      if (!api.isAdminOrMagStaffOrAccounting() && !attachmentsRequest.length && !itemModel.PaperworkExLink) {
        notification.info({
          message: 'Info',
          description: 'Please send paperwork to Specialist',
          duration: 5,
        });
      }
      // this.handleChangeValue('PaperworkReceivedDate', moment());
      this.handleChangeValue('PaperworkByOtherMethodDate', moment());
      var momentBusinessDays = require('moment-business-days');
      this.handleChangeValue('RequestExpectedCompletionDate', momentBusinessDays(moment()).businessAdd(7));
    } else {
      // this.handleChangeValue('PaperworkReceivedDate', null);
      this.handleChangeValue('PaperworkByOtherMethodDate', null);
    }
  };

  onAllowDoctorToViewPaperworkChange = () => {
    const itemModel = this.fileReviewInfo;
    const attachmentsRequest = itemModel.FileReviewAttachment.filter(i => i.ReportType === 1);
    if (itemModel.AllowDoctorToViewPaperwork) {
      if (!attachmentsRequest.length && !itemModel.PaperworkExLink) {
        this.showError('You need to attach LOI, paperwork or share download links before sending to specialist.');
        this.handleChangeValue('AllowDoctorToViewPaperwork', false);
      } else {
        if (!this.selectedDoctor || !this.selectedDoctor?.Id) {
          this.showError('Report assessing doctor under Claim Details must be selected first.');
          this.handleChangeValue('AllowDoctorToViewPaperwork', false);
          return;
        }

        if (!!!this.selectedDoctor.BusinessEmail) {
          this.showError('Email of report assessing doctor is required');
          this.handleChangeValue('AllowDoctorToViewPaperwork', false);
          return;
        }

        if (itemModel.AllowDoctorToViewPaperwork) {
          this.handleChangeValue('IsPaperworkReceived', true);
          this.onIsPaperworkReceivedChanged();
        }
        if (api.isAdminOrMagStaffOrAccounting()) {
          const paperworkToDoctorBiesEmaillMethod = this.sendPaperMethods.find(i => i.Label === 'Email');
          if (paperworkToDoctorBiesEmaillMethod) {
            const selectedPaperworkToDoctorBies = [
              ...itemModel.SelectedPaperworkToDoctorBies,
              paperworkToDoctorBiesEmaillMethod.Id,
            ];

            this.handleChangeValue('SelectedPaperworkToDoctorBies', selectedPaperworkToDoctorBies);
          }
        }
      }
    }
  };

  onCheckReportSampleAndTemplate = async () => {
    const itemModel = toJS(this.fileReviewInfo);
    const frDoctorTemplate = itemModel.FileReviewAttachment.filter(i => i.ReportType === 13);
    const frDoctorSample = itemModel.FileReviewAttachment.filter(i => i.ReportType === 12);
    if (!!frDoctorTemplate.length && !!frDoctorSample.length) {
      notification.error({
        message: 'Error',
        description:
          'Please remove current attached report templates and samples before copying from specialist profile.',
      });
      return;
    }
    const response = await customFetch('/FileReview/GetDoctorTemplateAndSample', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: this.fileReviewInfo.Id }),
    });
    if (response.status === 'success') {
      this.toggleModalConfirm(true, {
        message: 'Matching report samples are found. Do you want to send to typist? ',
        onOk: () => {
          const { doctorSample, doctorTemplate } = response;

          const doctorSample_ = doctorSample
            ? doctorSample.map(i => ({
                Id: 0,
                Title: i.Title,
                FileName: i.FileName,
                ReportType: 12,
              }))
            : [];
          const DoctorSampleAttachment = [...doctorSample_];
          const doctorTemplate_ = doctorTemplate
            ? doctorTemplate.map(i => ({
                Id: 0,
                Title: i.Title,
                FileName: i.FileName,
                ReportType: 13,
              }))
            : [];
          const DoctorReportTemplateAttachment = [...doctorTemplate_];

          if (!frDoctorTemplate.length) {
            const fileReviewAttachments = this.fileReviewInfo.FileReviewAttachment
              ? toJS(this.fileReviewInfo.FileReviewAttachment)
              : [];
            this.handleChangeValue(
              'FileReviewAttachment',
              [...fileReviewAttachments, ...DoctorReportTemplateAttachment].map((i, idx) => ({
                ...i,
                key: idx,
              })),
            );
          }
          if (!frDoctorSample.length) {
            const fileReviewAttachments = this.fileReviewInfo.FileReviewAttachment
              ? toJS(this.fileReviewInfo.FileReviewAttachment)
              : [];
            this.handleChangeValue(
              'FileReviewAttachment',
              [...fileReviewAttachments, ...DoctorSampleAttachment].map((i, idx) => ({
                ...i,
                key: idx,
              })),
            );
          }
        },
      });
    } else {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'There is no report template or sample in specialist profile.',
      });
    }
  };

  onDictationToTypistChanged = async checked => {
    const itemModel = toJS(this.fileReviewInfo);
    if (!itemModel.DictationTypistID) {
      return this.showError('Please select a typist.');
    }

    this.handleChangeValue('DictationSentToTypist', checked);

    if (checked && !itemModel.SentToTypist) {
      this.toggleModalConfirm(true, {
        message: 'Do you want to send dictation files, report templates and previous reports to the typist?',
        onOk: () => {
          this.handleChangeValue('SentToTypist', true);
        },
      });
    }
  };

  functionCallbackAfterUploadPaperwork = (fileList = [], reportType) => {
    const attachments = this.fileReviewInfo.FileReviewAttachment;
    const itemModel = toJS(this.fileReviewInfo);

    const paperworkAndPaperworkOtherAttachment = !!attachments.length
      ? attachments.filter(i => i.ReportType === 1 || i.ReportType === 6)
      : [];

    this.handleChangeValue('PaperworkReceivedDate', moment());

    //check total pages > 20
    if (!!paperworkAndPaperworkOtherAttachment.length) {
      const totalPage = attachments.map(i => i.NumberOfPages).reduce((a, b) => a + b);
      if (totalPage > 20) {
        this.handleChangeValue('IsLongPaperwork', true);
      }
      this.totalPages = totalPage;
      this.handleChangeValue('IsUpdatedPaperwork', true);
    }
    if (!!fileList.length && !itemModel.NotifyPaperworkReadinessToStaff && api.isCaseManager() && reportType === 1) {
      this.toggleModalConfirm(true, {
        message: 'Do you want to send paperwork readiness to MLP Staff?',
        onOk: () => {
          if (!itemModel.NotifyPaperworkReadinessToStaff) {
            this.handleChangeValue('NotifyPaperworkReadinessToStaff', true);
          }
        },
      });
    }
    // if (
    //   !!fileList.length &&
    //   !itemModel.AllowDoctorToViewPaperwork &&
    //   api.isCaseManager() &&
    //   (reportType === 1 || (reportType === 6 && api.isAdminOrMagStaffOrAccounting()))
    // ) {
    //   if (!itemModel.WifroIlars || (itemModel.WifroIlars && itemModel.IROFundingApproved)) {
    //     this.toggleModalConfirm(true, {
    //       message: 'Do you want to send paperwork to specialist?',
    //       onOk: () => {
    //         if (!itemModel.IsPaperworkReceived) {
    //           itemModel.IsPaperworkReceived = true;
    //           this.handleChangeValue('IsPaperworkReceived', true);
    //         }
    //         if (!itemModel.AllowDoctorToViewPaperwork) {
    //           this.handleChangeValue('AllowDoctorToViewPaperwork', true);
    //           this.onAllowDoctorToViewPaperworkChange();
    //         }
    //         this.handleChangeValue('PaperworkReceivedDate', moment());
    //         this.handleChangeValue('PaperworkByOtherMethodDate', moment());
    //         setTimeout(() => {
    //           if (api.isCaseManager()) {
    //             this.toggleModalConfirm(true, {
    //               message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
    //             });
    //           }
    //         }, 1000);
    //       },
    //     });
    //   }
    // }
  };

  functionCallbackAfterUploadUrgentReportCMAuthorisation = (fileList = [], reportType) => {};

  validateFile = (file, options = {}) => {
    const maxFileSize = options.maxFileSize || 83886080;
    const validExtensions = options.allowedExtensions || FILE_EXTENSION;

    if (file.size > maxFileSize) {
      this.showError(`File size too large. File should be <= ${maxFileSize / 1048576}MB.`);
      return false;
    }

    const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    const isValidExt = validExtensions.includes(extension);
    if (!isValidExt) {
      this.showError(`Sorry, ${file.name} is invalid.
          Allowed extensions are: ${validExtensions.join(', ')}.`);
      return false;
    }

    return true;
  };

  handleCheckICare = () => {
    const { selectedCustomer, selectedDoctor } = this;
    if (!!selectedCustomer && !!selectedDoctor) {
      if ((selectedCustomer.IcareInsurer || this.fileReviewInfo?.IcareClaim) && !selectedDoctor.AcceptICareMatter) {
        this.setFieldsValue({
          open: true,
          modalParams: {
            modalType: 'confirm',
            message: 'This specialist may not accept iCare matters, are you sure to book this report?',
            onOk: () => {},
            functionCallBack: () => {},
          },
        });
      }
    }
  };

  uploadFile = (reportType, options, functionCallback) => e => {
    const { files } = e.target;

    const formData = new FormData();
    files.forEach(file => {
      if (!this.validateFile(file, options)) {
        return;
      }
      formData.append(file.name, file);
    });

    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        if (data.status === 'success') {
          functionCallback && functionCallback();
          this.fileReviewInfo.FileReviewAttachment = [
            ...this.fileReviewInfo.FileReviewAttachment,
            ...data.fileList.map(file => ({
              Id: 0,
              Title: file.title,
              FileName: file.name,
              ReportType: reportType,
              NumberOfPages: file.numberOfPages,
            })),
          ];
        }
      }),
    );
  };

  @action removeFile = fileName => {
    this.fileReviewInfo.FileReviewAttachment.splice(
      this.fileReviewInfo.FileReviewAttachment.findIndex(item => item.FileName === fileName),
      1,
    );
  };

  @computed get selectedDoctor() {
    const { Doctor, DoctorId } = this.fileReviewInfo;
    const doctors = [Doctor, ...this.doctors];
    return doctors.find(doctor => doctor?.Id === DoctorId);
  }

  @computed get selectedCustomer() {
    const { Customer, CustomerId } = this.fileReviewInfo;
    const customers = [Customer, ...this.companies];
    return customers.find(customer => customer?.Id === CustomerId);
  }

  @computed get selectedSubCustomer() {
    const { SubCustomer, SubCustomerId } = this.fileReviewInfo;
    const customers = [SubCustomer, ...this.companies];
    return customers.find(customer => customer?.Id === SubCustomerId);
  }

  @computed get selectedCM() {
    const { CaseManager, CaseManagerId } = this.fileReviewInfo;
    const cms = [CaseManager, ...this.companyCMs];
    return cms.find(cm => cm?.Id === CaseManagerId);
  }

  @computed get selectedPCustomer() {
    const { PCustomer, PCustomerId } = this.fileReviewInfo;
    const customers = [PCustomer, ...this.companies];
    return customers.find(customer => customer?.Id === PCustomerId);
  }

  @computed get selectedPCM() {
    const { PCaseManager, PCaseManagerId } = this.fileReviewInfo;
    const cms = [PCaseManager, ...this.pCompanyCMs];

    return cms.find(cm => cm?.Id === PCaseManagerId);
  }

  @computed get selectedPatient() {
    const { Patient, PatientId } = this.fileReviewInfo;
    const claimants = [Patient, ...this.claimants];
    return claimants.find(claimant => claimant?.Id === PatientId);
  }

  formatResDate = date => {
    if (!date || typeof date !== 'string') {
      return null;
    }
    return moment(formatDate(date, true), 'DD MMM YYYY HH:mm');
  };

  @observable utcServerTime = {};

  renderDateTimeForCC = (datestr, showTime) => {
    const utcServerTime = this.utcServerTime;
    const utcLocalMinutes = new Date().getTimezoneOffset();
    const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr, true);
        return showTime
          ? moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY - HH:mm')
          : moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY');
      }
      return datestr;
    }
    return datestr;
  };

  @action fetchConversations = (searchKey = '') => {
    this.loadingConversation = true;
    getConversations(this.fileReviewInfo.Id)
      .then(
        action(res => {
          if (searchKey !== '' && res?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            res.itemList = res.itemList.filter(t => t.Content.toLowerCase().indexOf(searchKey) > -1);
          }

          const utcServerTime = res.utcServerTime;
          const utcLocalMinutes = new Date().getTimezoneOffset();
          const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
          this.conversations = res.itemList.map(c => ({
            ...c,
            SentDate: c.SentDate ? moment(c.SentDate).add(addminutes, 'minutes') : null,
          }));
          this.utcServerTime = res.utcServerTime;
          this.loadingConversation = false;
        }),
      )
      .catch(() => (this.loadingConversation = false));
  };

  renderAttachment = (attachments, reportType) => {
    return attachments ? attachments.filter(attachment => attachment.ReportType === reportType) : [];
  };
  renderAttachments = (attachments, reportTypes) => {
    return attachments ? attachments.filter(attachment => reportTypes.includes(attachment.ReportType)) : [];
  };
  validateField = (value, type) => {
    if (type && value) {
      const v = new Validator({ value }, { value: type });
      return v.passes();
    }
    return true;
  };

  renderStaffName = id => {
    const { staffs } = this;
    const rs = staffs.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      const arr_ = arr.filter(i => i[key] !== item[key]);
      const item_ = toJS(item);
      return [...arr_, item_];
    } else if (key && item && item[key] && !arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  @observable isServicePrepaymentLocked = false;

  @action fetchAll = (id = 0, mType, mId) => {
    if (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor()) {
      this.isServicePrepaymentLocked = true;
    }
    this.loading = true;
    autorun(() => {
      getDoctors({
        keyword: this.doctorsKeyword,
        numItemPerPage: this.doctorsPerPage,
      }).then(
        action(res => {
          this.doctors = res.itemList;
        }),
      );
    });
    autorun(() => {
      getDoctors({
        keyword: this.supplierKeyword,
        numItemPerPage: this.supplierPerPage,
      }).then(
        action(res => {
          this.suppliers = res.itemList;
        }),
      );
    });
    autorun(() => {
      getBillingCodes(this.numberBillingCodePerPage).then(action(res => (this.billingCodes = res.itemList)));
    });
    autorun(() => {
      getCompanies({
        keyword: this.companiesKeyword,
        numItemPerPage: this.companiesPerPage,
      }).then(
        action(res => {
          this.companies = res.itemList;
        }),
      );
    });
    autorun(() => {
      getCompanyInsuer({
        keyword: this.keywordCompanyInsurer,
        numItemPerPage: this.numberCompanyInsurerPerPage,
      }).then(
        action(res => {
          this.listCompanyInsurer = res.itemList;
        }),
      );
    });
    autorun(() => {
      getLookUpConfig('ReasonReferral').then(
        action(res => {
          this.listReasonReferral = res.reasonReferralItemList;
        }),
      );
    });
    autorun(() => {
      getLookupIcareReport().then(
        action(res => {
          this.icareLookupList = res;
        }),
      );
    });
    autorun(() => {
      getCompanies({
        keyword: this.subCompaniesKeyword,
        numItemPerPage: this.subCompaniesPerPage,
      }).then(
        action(res => {
          this.companies = res.itemList;
        }),
      );
    });
    autorun(() => {
      getCMs({
        keyword: this.companyCMsKeyword,
        numItemPerPage: this.companyCMsPerPage,
        companyId: this.fileReviewInfo?.CustomerId,
      }).then(
        action(res => {
          if (!!this.fileReviewInfo?.CustomerId) {
            this.companyCMs = res.itemList;
          } else {
            this.setFieldValue({ companyCMs: [] });
          }
        }),
      );
    });
    autorun(() => {
      if (api.isAdminOrMagStaffOrAccounting()) {
        getCMs({
          keyword: this.CMsKeyword,
          numItemPerPage: this.CMsPerPage,
        }).then(
          action(res => {
            this.CMs = res.itemList;
          }),
        );
      } else {
        getCMs({
          keyword: this.companyCMsKeyword,
          numItemPerPage: this.companyCMsPerPage,
          companyId: this.fileReviewInfo?.CustomerId,
        }).then(
          action(res => {
            if (!!this.fileReviewInfo?.CustomerId) {
              this.CMs = res.itemList;
            } else {
              this.setFieldValue({ CMs: [] });
            }
          }),
        );
      }
    });
    autorun(() => {
      getCMs({
        keyword: this.companySolicitorKeyword,
        numItemPerPage: this.companySolicitorPerPage,
        companyId: this.fileReviewInfo?.CustomerId,
      }).then(
        action(res => {
          if (!!this.fileReviewInfo?.CustomerId) {
            this.companySolicitor = res.itemList;
          } else {
            this.setFieldValue({ companySolicitor: [] });
          }
        }),
      );
    });

    autorun(() => {
      getClaimants({
        keyword: this.claimantsKeyword,
        numItemPerPage: this.claimantsPerPage,
      }).then(
        action(res => {
          this.claimants = res.itemList.map(item => ({
            ...item,
            DOB: this.formatResDate(item.DOB),
          }));
        }),
      );
    });
    Promise.all([
      getFileReviewInfo(id, mType, mId),
      getStaffs(),
      getCMs(0),
      getClaimTypes(),
      getReportTypes(),
      getIMSCodes(),
      getLawyers(),
      getSendPaperMethods(),
      getTypists(),
      getActionRequired(id),
      getFileReviewStatusLogs(id),
    ])
      .then(
        action(
          ([
            dataFileReviewInfo,
            dataStaffs,
            dataSubCMs,
            dataClaimTypes,
            dataReportTypes,
            dataIMSCodes,
            dataLawyers,
            dataSendPaperMethods,
            dataTypists,
            actionRequiredList,
            statusLogs,
          ]) => {
            const { model } = dataFileReviewInfo;
            if (api.isDoctor()) {
              // Doctor is not allow modify paperwork
              this.isLockedPaperword = true;
              this.isLockReportCompleted_CM = true;
              //locked nếu đã check ReportCompleted
              this.isLockReturnedReport = model.ReportCompleted;
              this.isLockDictation = model.ReportCompleted;

              // nếu ko phải direct thì locked
              this.isLockReportCompleted = !model.isDirect;
            }

            if (api.isCaseManager()) {
              // CaseManage is not allow modify Report
              this.isLockedReport = true;
              this.isLockDictation = true;
              this.isLockReturnedReport = true;
              //RDC - locked nếu đã check report completed
              this.isLockReportCompleted_CM = model.ReportCompleted;
            }
            if (!api.isAdminOrMagStaffOrAccounting()) {
              this.IsLockedCorrespondence = true;
            }

            if (model.AdditionalServiceSupplierObj) {
              this.AdditionalServiceSupplierObj = model.AdditionalServiceSupplierObj;
            }

            if (api.isTypist()) {
              this.isLockReportCompleted_CM = true;
              this.isLockReportCompleted = true;
              this.isLockReturnedReport = true;
              this.isLockDictation = true;
              this.IsExpectedCompletionDate = true;
              this.isLockedPaperword = true;
              this.isLockedReport = true;
            }
            if (api.isCaseManager()) {
              this.isLockedPaperword = model.IsPaperworkReceived;
              if (model.ReportCompleted) {
                this.isLockedPaperword = true;
              }
            }
            if (api.isDoctor()) {
              this.isLockReportCompleted = false;
            }
            this.pCustomer = model.PCustomer;
            if (!!id) {
              this.actionRequiredList = actionRequiredList.itemList || [];
              this.fileReviewStatusLogs = statusLogs;
            }

            if (!!id) {
              this.fileReviewInfo = {
                ...model,
                isLocked: !api.isAdminOrMagStaffOrAccounting(),
                RequestDate: this.formatResDate(model.RequestDate),
                InjuriedDate: this.formatResDate(model.InjuriedDate),
                ServiceNotesandFeeObj: model.ServiceNotesandFeeObj || {},
                FileReviewAttachment: model.FileReviewAttachment.map((i, idx) => ({
                  ...i,
                  key: idx,
                })),
                RequestExpectedCompletionDate: this.formatResDate(model.RequestExpectedCompletionDate),
                // ? this.formatResDate(model.RequestExpectedCompletionDate)
                // : moment().add(7, 'day'),
                PaperworkReceivedDate: this.formatResDate(model.PaperworkReceivedDate),
                PaperworkByOtherMethodDate: this.formatResDate(model.PaperworkByOtherMethodDate),
                DictationReceivedDate: this.formatResDate(model.DictationReceivedDate),
                TypistSubmittingDate: this.formatResDate(model.TypistSubmittingDate),
                TypistApproverdOn: this.formatResDate(model.TypistApproverdOn),
                ReportDraftReceivedDate: this.formatResDate(model.ReportDraftReceivedDate),
                ReportDraftSentDate: this.formatResDate(model.ReportDraftSentDate),
                ReportCompletedDate: this.formatResDate(model.ReportCompletedDate),
                ReportCompletedSentDate: this.formatResDate(model.ReportCompletedSentDate),
                IROFundingApprovalDate: this.formatResDate(model.IROFundingApprovalDate),
                IROFundingApprovedDate: this.formatResDate(model.IROFundingApprovedDate),
                UrgentReportReportCompletionDate: this.formatResDate(model.UrgentReportReportCompletionDate),
                ClaimantStatus: model.ClaimantStatus ? '1' : '0',
                SubCaseManagerIds: [model.SubCaseManager1Id, model.SubCaseManager2Id, model.SubCaseManager3Id].filter(
                  i => i,
                ),
                Patient: model.Patient
                  ? {
                      ...model.Patient,
                      DOB: this.formatResDate(model.Patient.DOB),
                    }
                  : null,
                PatientId: model.Patient?.Id || null,
                patientInfo: model.Patient
                  ? {
                      ...model.Patient,
                      DOB: this.formatResDate(model.Patient.DOB),
                    }
                  : null,
                ReportId: model.ClaimType?.Id,
                ActionRequired: true,
                SelectedDoctorProviderNoIdx: model.Doctor?.Id ? model.SelectedDoctorProviderNoIdx : null,
              };
              var countPage = 0;
              var attachPaperwor = this.fileReviewInfo.FileReviewAttachment.filter(
                i => i.ReportType === 1 || i.ReportType === 6,
              );
              if (attachPaperwor.length > 0) {
                attachPaperwor.forEach(element => {
                  countPage += element.NumberOfPages;
                });
                this.totalPages = countPage;
              }
            } else {
              this.fileReviewInfo = {
                ...model,
                isLocked: !api.isAdminOrMagStaffOrAccounting(),
                RequestDate: moment(),
                InjuriedDate: this.formatResDate(model.InjuriedDate),
                FileReviewAttachment: model.FileReviewAttachment.map((i, idx) => ({
                  ...i,
                  key: idx,
                })),
                ClaimantStatus: '1',
                DictationOption: 1,
                RequestExpectedCompletionDate: this.formatResDate(model.RequestExpectedCompletionDate), // moment().add(7, 'day'),
                PaperworkReceivedDate: this.formatResDate(model.PaperworkReceivedDate),
                PaperworkByOtherMethodDate: this.formatResDate(model.PaperworkByOtherMethodDate),
                DictationReceivedDate: this.formatResDate(model.DictationReceivedDate),
                TypistSubmittingDate: this.formatResDate(model.TypistSubmittingDate),
                TypistApproverdOn: this.formatResDate(model.TypistApproverdOn),
                ReportDraftReceivedDate: this.formatResDate(model.ReportDraftReceivedDate),
                ReportDraftSentDate: this.formatResDate(model.ReportDraftSentDate),
                ReportCompletedDate: this.formatResDate(model.ReportCompletedDate),
                ReportCompletedSentDate: this.formatResDate(model.ReportCompletedSentDate),
                IROFundingApprovalDate: this.formatResDate(model.IROFundingApprovalDate),
                SubCaseManagerIds: [model.SubCaseManager1Id, model.SubCaseManager2Id, model.SubCaseManager3Id].filter(
                  i => i,
                ),
                ActionRequired: true,
                Patient: model.Patient
                  ? {
                      ...model.Patient,
                      DOB: this.formatResDate(model.Patient.DOB),
                    }
                  : null,
                PatientId: model.Patient?.Id || null,
                patientInfo: model.Patient
                  ? {
                      ...model.Patient,
                      DOB: this.formatResDate(model.Patient.DOB),
                    }
                  : null,
                ReportId: model.ClaimType?.Id,
                ServiceNotesandFeeObj: {
                  AdminExtraFeeAmount: null,
                  AdminExtraFeeTaxPercentage: null,
                  CReference: null,
                  CReferenceId: null,
                  CommissionAmount: 0,
                  CommissionOnTopRate: null,
                  CommissionRate: null,
                  CommissionRateType: 0,
                  Description: null,
                  HasOtherFee: false,
                  IncludeAdminExtraFeeToDoctorCommission: false,
                  IncludeManualFee1ToDoctorCommission: false,
                  IncludeServiceItemFeeToDoctorCommission: false,
                  InvoiceAttachment: null,
                  ListServiceItems: null,
                  OtherFee1Description: null,
                  OtherFee1Label: null,
                  OtherFee1TaxPercentage: null,
                  OtherFee1Value: null,
                  OtherFee2Description: null,
                  OtherFee2Label: null,
                  OtherFee2TaxPercentage: null,
                  OtherFee2Value: null,
                  PayingCaseManager: null,
                  PayingCaseManagerId: null,
                  PayingClient: null,
                  PayingClientId: null,
                  SameBookingCM: false,
                  SameClientAccEmail: false,
                  SendInvoice: null,
                  SendInvoice2: null,
                },
                CustomerId: api.currentUser.data.ClientId,
                CaseManagerId: api.currentUser.data.CaseManagerId,
                SelectedDoctorProviderNoIdx: null,
              };
            }

            if (!api.isAdminOrMagStaffOrAccounting()) {
              this.handleChangeValue('isLocked', true);
            }

            if (!!model.CustomerId) {
              getCMs({ companyId: model.CustomerId }).then(
                action(res => {
                  this.companyCMs = res.itemList;
                }),
              );
            } else {
              this.companyCMs = [];
            }

            if (!!model.PCustomerId) {
              getCMs({ companyId: model.PCustomerId }).then(
                action(res => {
                  this.pCompanyCMs = res.itemList;
                }),
              );
            } else {
              this.pCompanyCMs = [];
            }

            if (api.isCaseManager() && !model.Id) {
              getCMs({ companyId: api.currentUser.data.ClientId, keyword: api.currentUser.data.CaseManagerId }).then(
                action(res => {
                  this.companyCMs = res.itemList;
                }),
              );
            }
            if (!!model.Id) {
              getListInvoiceID(model.Id).then(
                action(res => {
                  this.listInvoiceID = res.itemList;
                }),
              );
            }
            this.fetchConversations();
            this.staffs = dataStaffs.itemList;
            if (!!model.SubCustomerId) {
              getCMs({ companyId: model.SubCustomerId }).then(
                action(res => {
                  this.subCMs = res.itemList;
                }),
              );
            } else {
              this.subCMs = [];
            }
            //this.subCMs = dataSubCMs.itemList;
            this.claimTypes = dataClaimTypes.itemList;
            this.reportTypes = dataReportTypes.itemList;
            this.imsCodes = dataIMSCodes.itemList;
            this.lawyers = dataLawyers.itemList;
            this.sendPaperMethods = dataSendPaperMethods.itemList;
            this.typists = dataTypists.itemList;

            this.loading = false;
            this.isReady = true;
            this.autoChangeFunction();
          },
        ),
      )
      .catch(() => {});
  };

  formatReqDate = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date).toISOString();
    }
    return date.toISOString();
  };

  updateStatus = newStatus => {
    return updateFileReviewStatus(this.fileReviewInfo.Id, newStatus).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully',
        });

        action(() => {
          this.fileReviewInfo.CaseFlow = newStatus;
        })();
        getFileReviewStatusLogs(this.fileReviewInfo.Id).then(logs => {
          action(() => {
            this.fileReviewStatusLogs = logs;
          })();
        });
      }
    });
  };

  handleSubmit = () => {
    const { SubCaseManagerIds, ...model } = toJS(this.fileReviewInfo);

    const [SubCaseManager1Id, SubCaseManager2Id, SubCaseManager3Id] = SubCaseManagerIds;
    let fileReviewInfo = {
      ...model,
      UpdatedDate: this.formatReqDate(model.UpdatedDate),
      RequestDate: this.formatReqDate(model.RequestDate),
      InjuriedDate: this.formatReqDate(model.InjuriedDate),
      RequestExpectedCompletionDate: this.formatReqDate(model.RequestExpectedCompletionDate),
      PaperworkReceivedDate: this.formatReqDate(model.PaperworkReceivedDate),
      PaperworkByOtherMethodDate: this.formatReqDate(model.PaperworkByOtherMethodDate),
      DictationReceivedDate: this.formatReqDate(model.DictationReceivedDate),
      TypistSubmittingDate: this.formatReqDate(model.TypistSubmittingDate),
      TypistApproverdOn: this.formatReqDate(model.TypistApproverdOn),
      ReportDraftReceivedDate: this.formatReqDate(model.ReportDraftReceivedDate),
      ReportDraftSentDate: this.formatReqDate(model.ReportDraftSentDate),
      ReportCompletedDate: this.formatReqDate(model.ReportCompletedDate),
      ReportCompletedSentDate: this.formatReqDate(model.ReportCompletedSentDate),
      UrgentReportReportCompletionDate: this.formatReqDate(model.UrgentReportReportCompletionDate),
      ClaimantStatus: model.ClaimantStatus === '0' ? false : true,
      SubCaseManager1Id,
      SubCaseManager2Id,
      SubCaseManager3Id,
      Patient: this.selectedPatient
        ? {
            ...this.selectedPatient,
            DOB: this.formatReqDate(this.selectedPatient.DOB),
          }
        : null,
      patientInfo: this.selectedPatient
        ? {
            ...this.selectedPatient,
            DOB: this.formatReqDate(this.selectedPatient.DOB),
          }
        : null,
      ReportId: model.ClaimType?.Id,

      ClientPrepaymentRequiredDate: this.formatReqDate(model.ClientPrepaymentRequiredDate),
      ClientPrepaymentPaidDate: this.formatReqDate(model.ClientPrepaymentPaidDate),
      ServiceNotesandFeeObj: {
        ...model.ServiceNotesandFeeObj,
        SelectedServiceItemIds: model.ServiceNotesandFeeObj.ListServiceItems
          ? JSON.stringify(model.ServiceNotesandFeeObj.ListServiceItems.map(i => i.Id))
          : null,
        ListServiceItems: null,
      },
      ServiceNotesandFee: {
        ...model.ServiceNotesandFeeObj,
        SelectedServiceItemIds: model.ServiceNotesandFeeObj.ListServiceItems
          ? JSON.stringify(model.ServiceNotesandFeeObj.ListServiceItems.map(i => i.Id))
          : null,
        ListServiceItems: null,
      },
      PrepaymentRequiredDate: this.formatReqDate(model.PrepaymentRequiredDate),
    };
    fileReviewInfo.ServiceNotesandFee = {
      CReferenceId: model.ServiceNotesandFeeObj.CReferenceId,
      CommissionAmount: parseFloat(model.ServiceNotesandFeeObj.CommissionAmount),
      CommissionOnTopAmount: model.ServiceNotesandFeeObj.CommissionOnTopAmount,
      CommissionRateType: model.ServiceNotesandFeeObj.CommissionRateType,
      PayingCaseManagerId: this.selectedPCM?.Id || null,
      PayingClientId: this.selectedPCustomer?.Id || null,
      SelectedServiceItemIds: model.ServiceNotesandFeeObj.ListServiceItems
        ? JSON.stringify(model.ServiceNotesandFeeObj.ListServiceItems.map(i => i.Id))
        : null,
      TaxTotal: model.ServiceNotesandFeeObj.TaxTotal,
      Total: model.ServiceNotesandFeeObj.Total,
      TotalAmountForCommissionCalculation: model.ServiceNotesandFeeObj.TotalAmountForCommissionCalculation,
      TotalAmountForCommissionCalculationTax: model.ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax,
      TotalWithoutTax: parseFloat(model.ServiceNotesandFeeObj.TotalWithoutTax),
      SameBookingCM: model.ServiceNotesandFeeObj.SameBookingCM,
      SameClientAccEmail: model.ServiceNotesandFeeObj.SameClientAccEmail,
      SendInvoice: model.ServiceNotesandFeeObj.SendInvoice,
      SendInvoice2: model.ServiceNotesandFeeObj.SendInvoice2,
      //--------------------------------------------------------------------------
      AdminExtraFeeAmount: model.ServiceNotesandFeeObj.AdminExtraFeeAmount,
      AdminExtraFeeTaxPercentage: model.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage,
      Description: model.ServiceNotesandFeeObj.Description,
      HasOtherFee: model.ServiceNotesandFeeObj.HasOtherFee,
      IncludeAdminExtraFeeToDoctorCommission: model.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission,
      IncludeManualFee1ToDoctorCommission: model.ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission,
      IncludeServiceItemFeeToDoctorCommission: model.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission,
      InvoiceAttachment: model.ServiceNotesandFeeObj.InvoiceAttachment,
      OtherFee1Description: model.ServiceNotesandFeeObj.OtherFee1Description,
      OtherFee1Label: model.ServiceNotesandFeeObj.OtherFee1Label,
      OtherFee1TaxPercentage: model.ServiceNotesandFeeObj.OtherFee1TaxPercentage,
      OtherFee1Value: model.ServiceNotesandFeeObj.OtherFee1Value,
      OtherFee2Description: model.ServiceNotesandFeeObj.OtherFee2Description,
      OtherFee2Label: model.ServiceNotesandFeeObj.OtherFee2Label,
      OtherFee2TaxPercentage: model.ServiceNotesandFeeObj.OtherFee2TaxPercentage,
      OtherFee2Value: model.ServiceNotesandFeeObj.OtherFee2Value,
    };
    delete fileReviewInfo.ServiceNotesandFeeObj;
    if (this.compareDate(fileReviewInfo.InjuriedDate, !!fileReviewInfo.InjuriedDate ? moment() : null)) {
      return this.showError('Date of Injury cannot be a future date');
    }
    if (!!this.validateSectionClaimDetail().length) {
      const errorsList = this.validateSectionClaimDetail(fileReviewInfo);
      return errorsList.map(i => this.showError(i));
    } else if (!!this.validateServicePrepayment(fileReviewInfo).length) {
      const errorsList = this.validateServicePrepayment(fileReviewInfo);
      return errorsList.map(i => this.showError(i));
    } else {
      const { errorsForm } = this;
      if (!!errorsForm.length) {
        return this.showError('Some fields are invalid, please check the form again');
      } else {
        const paperworks = this.renderAttachments(fileReviewInfo.FileReviewAttachment, [
          LOCAL_CONSTANT.ReportType_Paperwork,
          LOCAL_CONSTANT.ReportType_PaperworkOther,
        ]);
        Promise.all([getUserUploadInfo(paperworks[0]?.UploadedById)]).then(([uP]) => {
          let isAdminOrStaffUpload = false;
          if (uP.status === 'success') {
            if (uP?.User?.Roles.some(r => r === 'admin' || r === 'magstaff')) {
              isAdminOrStaffUpload = true;
            }
          }
          if (
            api.isCaseManager() &&
            ((!fileReviewInfo.NotifyPaperworkReadinessToStaff && !isAdminOrStaffUpload) ||
              fileReviewInfo.IsUpdatedPaperwork)
          ) {
            if (paperworks.length) {
              this.toggleModalConfirm(true, {
                message: `Are these all the documents you would like the specialist to review?`,
                onOk: () => {
                  if (fileReviewInfo.NotifyPaperworkReadinessToStaff === true) {
                    resendPaperworkReadiness(fileReviewInfo.Id);
                  }
                  fileReviewInfo.NotifyPaperworkReadinessToStaff = true;
                  this.doSaveFR(fileReviewInfo);
                },
                functionCallbackCancel: () => {
                  this.doSaveFR(fileReviewInfo);
                },
              });
            } else {
              this.doSaveFR(fileReviewInfo);
            }
          } else {
            this.doSaveFR(fileReviewInfo);
          }
        });
      }
    }
  };

  doSaveFR(fileReviewInfo) {
    this.setFieldsValue({ loading: true });
    submitFileReviewInfo(fileReviewInfo)
      .then(res => {
        if (res.status === 'success') {
          // if (
          //   api.isCaseManager() &&
          //   fileReviewInfo.Id === 0 &&
          //   fileReviewInfo.CustomerId &&
          //   !fileReviewInfo.WifroIlars
          // ) {
          //   return Promise.all([getFileReviewInfo(res.Id)]).then(([resAssessment]) => {
          //     // ClientType === 3 -> LawPlaintiff
          //     if (resAssessment && resAssessment.model.Customer.ClientType === 3) {
          //       return this.setFieldsValue({
          //         isOpenModalDisbursementFunding: true,
          //         modalDisbursementFundingParams: {
          //           id: res.Id,
          //           caseNo: resAssessment.model.FileReviewNumber,
          //           caseId: resAssessment.model.Id,
          //           fromService: 'F',
          //           claimant: `${resAssessment.model.Patient?.Title} ${resAssessment.model.Patient?.FullName}`,
          //           claimantId: resAssessment.model.Patient?.Id,
          //           functionCallbackCancel: () => {
          //             this.fetchAll(res.Id);
          //             this.disposeReaction();
          //           },
          //           clientId: resAssessment.model.CustomerId,
          //         },
          //         isManual: false,
          //       });
          //     } else {
          //       notification.destroy();
          //       notification.success({
          //         message: 'Success',
          //         description: 'Data has been saved successfully',
          //       });
          //       this.disposeReaction();
          //       this.fetchAll(res.Id);
          //     }
          //   });
          // }
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully',
          });
          this.disposeReaction();
          this.fetchAll(res.Id);
        } else {
          if (res.status === 'fail' && res.error.RecordChange) {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'This record has been modified by another user. Please refresh and update again.',
            });
            this.setFieldsValue({ loading: false });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Can not update.',
            });
            this.setFieldsValue({ loading: false });
          }
        }
      })
      .catch(() => {
        this.setFieldsValue({ loading: false });
      });
  }
  handleRequestFunding = () => {
    if (!!this.fileReviewInfo.Id) {
      return this.setFieldsValue({
        isOpenModalDisbursementFunding: true,
        modalDisbursementFundingParams: {
          caseNo: this.fileReviewInfo.FileReviewNumber,
          caseId: this.fileReviewInfo.Id,
          fromService: 'F',
          claimant: `${this.fileReviewInfo.Patient?.Title} ${this.fileReviewInfo.Patient?.FullName}`,
          claimantId: this.fileReviewInfo.Patient?.Id,
          clientId: this.fileReviewInfo.CustomerId,
          isManual: true,
        },
      });
    }
    return this.handleSubmit();
  };

  @action validateSectionClaimDetail = () => {
    let errors = [];
    let errorClaimDetails = [];
    const itemModel = toJS(this.fileReviewInfo);
    const step2Section = api.isCaseManager() ? 'Paperwork and Report' : 'Paperwork, Dictations & Report';
    if (!itemModel.DoctorId) {
      errorClaimDetails.push('Claim Details, Assessing Specialist - Please select a specialist');
    }
    if (!itemModel.CustomerId) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a booking client');
    }
    if (!itemModel.ClaimantNo) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please input reference/claim no');
    }
    if (!itemModel.CaseManagerId) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a Booking Case Manager / Solicitor');
    }
    // if (!itemModel.RequestExpectedCompletionDate) {
    //   errorClaimDetails.push('Claim Details - Please input Expected Completion Date');
    // }
    if (!itemModel.PatientId) {
      errorClaimDetails.push('Claim Details, Claimant Info - Please select a claimant');
    }

    if (!itemModel.ClaimType?.Id) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please select a type of claim');
    }
    if (!itemModel.ReportTypeId) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please select a type of report');
    }
    if (itemModel.IROFundingApproved && !itemModel.IROFundingApprovalDate) {
      errorClaimDetails.push('Claim Details - IRO Funding Approval Date is required.');
    }
    // if (!itemModel.RequestExpectedCompletionDate && this.IsExpectedCompletionDate) {
    //   errorClaimDetails.push('Claim Details - Please input Expected Completion Date');
    // }
    let errorCaseDocuments = [];
    // if (itemModel.IsPaperworkReceived && !itemModel.PaperworkReceivedDate) {
    //   errorCaseDocuments.push(
    //     `Paperwork, Dictations & Report Information - Please select the paperwork ${
    //       api.isAdminOrMagStaffOrAccounting() ? 'received date' : api.isCaseManager() ? 'uploaded Date' : 'sent Date'
    //     }.`,
    //   );
    // }
    if (!api.isDoctor() && itemModel.IsPaperworkReceived && !itemModel.PaperworkByOtherMethodDate) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Paperwork - Please select a paperwork sent date',
      );
    }
    if (
      itemModel.IsInvoiceUrgentReport &&
      !!itemModel.PaperworkByOtherMethodDate &&
      !!itemModel.UrgentReportReportCompletionDate
    ) {
      const dfCompletionDate = moment(itemModel.UrgentReportReportCompletionDate).diff(
        moment(itemModel.PaperworkByOtherMethodDate),
        'day',
      );
      //console.log(dfCompletionDate);
      if (dfCompletionDate < 2 || dfCompletionDate > 7) {
        errorCaseDocuments.push(
          'Paperwork - Urgent report completion date must be between 2-7 business days of paperwork sent date.',
        );
      }
    }
    // if(itemModel.IsInvoiceUrgentReport && !itemModel.UrgentReportReportCompletionDate){
    //   errorCaseDocuments.push('Paperwork - Requested Completion Date Before is required.');
    // }
    if (
      itemModel.AllowDoctorToViewPaperwork &&
      api.isAdminOrMagStaffOrAccounting() &&
      (!this.selectedDoctor || !this.selectedDoctor.Id)
    ) {
      errorCaseDocuments.push(`${step2Section} - Please select a specialist`);
    }
    if (itemModel.ReportCompleted && itemModel.ReportCompletedSentDate === null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Final Report - You should specify date of report completed sent date.',
      );
    }
    if (itemModel.DictationSentToTypist && !itemModel.DictationTypistID) {
      errorCaseDocuments.push('Paperwork, Dictations & Report Information, Dictation - Please select a typist.');
    }
    if (itemModel.ReportDraftReceived && itemModel.ReportDraftSentDate === null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Report Draft - You should specify date of report draft sent date.',
      );
    }
    if (itemModel.DictationReceived && itemModel.DictationReceivedDate === null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Dictation - You should specify date of dictation received.',
      );
    }
    if (
      itemModel.EmailReportDraftToDoctor &&
      !itemModel.EmailReportDraftToDoctorBE &&
      !itemModel.EmailReportDraftToDoctorPE &&
      api.isAdminOrMagStaffOrAccounting()
    ) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Report Draft - At least one doctor email should be selected.',
      );
    }
    // const selectedItems = itemModel.SelectedPaperworkToDoctorBies.filter(i => i);
    // if ((itemModel.AllowDoctorToViewPaperwork || selectedItems.length > 0) && !itemModel.IsPaperworkReceived) {
    //   errorCaseDocuments.push(
    //     'Paperwork, Dictations & Report Information - Please check Request Sent to Provider.',
    //   );
    // }
    // if (selectedItems.length > 0 && !itemModel.PaperworkByOtherMethodDate) {
    //   errorCaseDocuments.push(
    //     'Paperwork, Dictations & Report Information - Please input Other Method Sending Date.',
    //   );
    // }
    if (!!errorClaimDetails.length) {
      errors = errors.concat(errorClaimDetails);
    }
    if (!!errorCaseDocuments.length) {
      errors = errors.concat(errorCaseDocuments);
    }
    return errors.filter((value, idx, self) => {
      return self.indexOf(value) === idx;
    });
  };

  validateServicePrepayment = () => {
    let errors = [];
    const itemModel = toJS(this.fileReviewInfo);
    let errorServicePrepayment = [];
    if (itemModel.PrepaymentRequired && !itemModel.PrepaymentRequiredDate) {
      errorServicePrepayment.push('Provider Payment Information - Prepayment Required Date is requried.');
    }
    if (!itemModel.PaymentRequestedDate && itemModel.PrepaymentRequired) {
      errorServicePrepayment.push('Provider Payment Information - Invoice Date is required.');
    }
    if (itemModel.IsPrepaymentPaid && !itemModel.PrepaymentPaidDate) {
      errorServicePrepayment.push('Provider Payment Information - Prepayment Paid Date is requried.');
    }
    if (!!errorServicePrepayment.length) {
      errors = errors.concat(errorServicePrepayment);
    }
    if (errors.length > 0) {
      errors.map(i => this.showError(i));
      return false;
    }
    return true;
  };

  @action sectionClickOpen = step => {
    if (this.currentStep !== step) {
      let isOpenSection = false;
      switch (this.currentStep) {
        case 'ActionRequired':
          isOpenSection = true;
          break;
        case 0:
          const vClaimDetail = this.validateSectionClaimDetail();
          if (!!!vClaimDetail.length) {
            isOpenSection = true;
          } else {
            this.validateSectionClaimDetail().map(i => this.showError(i));
          }
          break;
        case 'ServicePrepaymentFR':
          const vServicePrepayment = this.validateServicePrepayment();
          if (vServicePrepayment) {
            isOpenSection = true;
          }
          break;
        default:
          isOpenSection = true;
          break;
      }
      if (isOpenSection) {
        this.currentStep = step;
      } else {
        return;
      }
    } else {
      return;
    }
  };
  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };
  @observable serviceItemTotalFee = 0;
  @observable serviceItemTotalTax = 0;

  @action calculateTotalServiceFee = () => {
    let serviceItemTotalFee = 0;
    let serviceItemTotalTax = 0;
    const { ListServiceItems } = this.fileReviewInfo.ServiceNotesandFeeObj;
    if (ListServiceItems && ListServiceItems.length) {
      ListServiceItems.forEach(item => {
        const itemPrice = item.ApplicablePrice;
        let itemTax = 0;
        if (item.Tax > 0) {
          itemTax = (itemPrice * item.Tax) / 100;
        }
        serviceItemTotalFee += itemPrice;
        serviceItemTotalTax += itemTax;
      });
    }

    this.serviceItemTotalFee = serviceItemTotalFee;
    this.serviceItemTotalTax = serviceItemTotalTax;
  };
  calculateTotalOtherFee = () => {
    let totalOtherFee = 0;
    let otherFeeTax = 0;
    if (this.fileReviewInfo.ServiceNotesandFeeObj.HasOtherFee) {
      let fee1 = parseFloat(this.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Value);
      let tax1 = parseFloat(this.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage);
      if (!isNaN(fee1) && fee1 !== 0) {
        totalOtherFee += fee1;
        if (!isNaN(tax1) && tax1 > 0) {
          otherFeeTax += tax1;
        }
      }
    }

    return {
      totalOtherFee,
      otherFeeTax,
    };
  };
  @action calculateAmountForCommissionCalculation = () => {
    let amountForCommissionCalculation = 0;
    const serviceFee = parseFloat(
      this.fileReviewInfo.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission
        ? this.serviceItemTotalFee || 0
        : 0,
    );
    const adminExtraFee = parseFloat(
      this.fileReviewInfo.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission
        ? toJS(this.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount) || 0
        : 0,
    );
    const otherFee1 = parseFloat(
      this.fileReviewInfo.ServiceNotesandFeeObj.HasOtherFee &&
        this.fileReviewInfo.ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission
        ? this.fileReviewInfo.ServiceNotesandFeeObj.OtherFee1Value || 0
        : 0,
    );
    amountForCommissionCalculation = serviceFee + adminExtraFee + otherFee1;

    return amountForCommissionCalculation;
  };
  calculateAmountForCommissionCalculationTax = () => {
    return 0.1 * this.calculateAmountForCommissionCalculation();
  };

  inRangeDate = (d, start, end) => {
    let dD = moment(d);
    let cD1 = moment(start);
    let cD2 = moment(end);
    return isFinite(d) && isFinite(start) && isFinite(end) ? cD1 <= dD && dD <= cD2 : false;
  };

  inRangeDate2 = (d, start) => {
    let dD = moment(d);
    let cD1 = moment(start);
    return isFinite(d) && isFinite(start) ? cD1 <= dD : false;
    // return false;
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return new Date(result.toISOString());
      }
    }
  };
  @action calculateInvoice = () => {
    this.calculateTotalServiceFee();
    const wiroType = 0;
    const commissionRateType = this.fileReviewInfo.ServiceNotesandFeeObj.CommissionRateType;
    const otherFeeAndTax = this.calculateTotalOtherFee();
    const totalOtherFee = otherFeeAndTax.totalOtherFee;
    const otherFeeTax = otherFeeAndTax.otherFeeTax;
    const serviceTax = this.serviceItemTotalTax;
    const serviceFee = this.serviceItemTotalFee;
    const adminExtraFee = this.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount || 0;
    const adminExtraFeeTax = this.fileReviewInfo.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage;
    const WiroSolicitor = WIRO_INVOICE_TYPE.indexOf('WiroSolicitor');
    const doctorServiceAmount = wiroType === WiroSolicitor ? 0 : this.calculateAmountForCommissionCalculation();
    const doctorServiceTax = wiroType === WiroSolicitor ? 0 : this.calculateAmountForCommissionCalculationTax();
    const commissionRate = parseFloat(this.fileReviewInfo.ServiceNotesandFeeObj.CommissionRate) || 0;
    const commissionAmount = (doctorServiceAmount * commissionRate) / 100;
    let cOnTopRate = 0;
    if (this.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate !== null) {
      cOnTopRate = this.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopRate;
    }
    const cotRate = commissionRateType === VIEW_INVOICE_GLOBAL.CommissionType.COT ? cOnTopRate : 0;
    const cotAmount = (this.calculateAmountForCommissionCalculation() * cotRate) / 100;
    const cotTax = cotAmount * 0.1 || 0; // 10%
    //Total
    let totalWithoutTax = serviceFee + totalOtherFee + adminExtraFee + cotAmount;
    let totalTax = otherFeeTax + serviceTax + adminExtraFeeTax + cotTax;
    let totalWithTax = 0;
    if (wiroType === WiroSolicitor) {
      const reimbursement = totalWithoutTax;
      this.fileReviewInfo.Reimbursement = reimbursement;
      totalWithTax = totalWithoutTax - reimbursement + totalTax;
    } else if (wiroType === WIRO_INVOICE_TYPE.indexOf('Wiro')) {
      totalTax = 0; // wiro invoice has tax = 0
      totalWithTax = totalWithoutTax + totalTax;
    } else {
      totalWithTax = totalWithoutTax + totalTax;
    }
    this.fileReviewInfo.ServiceNotesandFeeObj.TotalWithoutTax = totalWithoutTax.toFixed(2);
    this.fileReviewInfo.ServiceNotesandFeeObj.TaxTotal = totalTax;
    this.fileReviewInfo.ServiceNotesandFeeObj.Total = totalWithTax;
    this.fileReviewInfo.ServiceNotesandFeeObj.CommissionOnTopAmount = cotAmount;
    this.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculation = doctorServiceAmount;
    this.fileReviewInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax = doctorServiceTax;
    if (commissionRateType !== 2) {
      this.fileReviewInfo.ServiceNotesandFeeObj.CommissionAmount = commissionAmount.toFixed(2);
    }
  };

  @action getCaseNo = () => {
    return this.fileReviewInfo.Id ? `F-${this.fileReviewInfo.Id.toString().padStart(6, '0')}` : '';
  };

  showNotification = (message = '', description, typeNotification = 'error') => {
    return notification[typeNotification]({
      message: message,
      description: description,
      duration: 3,
    });
  };

  handleResendNotifyCancellation = (sendtype = 1) => {
    this.toggleModalConfirm(true, {
      message: 'Do you want to resend cancellation confirmation?',
      onOk: () => {
        this.doResendNotifyCancellation(sendtype);
      },
    });
  };

  doResendNotifyCancellation = (sendtype = 1) => {
    const { fileReviewInfo } = this;
    customFetch('/FileReview/ResendEmailCancelled', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ frId: fileReviewInfo.Id, sendtype: sendtype }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend Cancellation Confirmation',
          });
          if (sendtype == 0) {
            this.handleChangeFileReviewValue({ SentDateNotifyCancellationToSolictor: response.sentDate });
          } else if (sendtype == 1) {
            this.handleChangeFileReviewValue({ SentDateNotifyBookingCMCancellation: response.sentDate });
          } else {
            this.handleChangeFileReviewValue({ SentDateNotifyDoctorCancellation: response.sentDate });
          }
          //store.handleChangeValue('EmailPaymentReceiptToClientDateSent', moment());
          //store.handleChangeValue('EmailPaymentReceiptToClientBy', response.sentBy);
        } else {
          notification.destroy();
          notification.Error({
            message: 'Error',
            description: 'Resend Cancellation Confirmation',
          });
        }
      })
      .catch(() => {});
  };

  @observable isLoadingAudioTranscribe = false;
  @action getAudioTranscription = (attachmentFile, language) => {
    this.isLoadingAudioTranscribe = true;
    customFetch(`/FileReview/GenerateAudioTranscription?fileId=${attachmentFile.Id}&language=${language}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 'success') {
          notification.destroy();

          if (!attachmentFile.TranscriptId) {
            notification.success({
              message: 'Success',
              description: 'Generate audio transcription successfully!',
            });
          }
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          // description: '',
        });
      })
      .finally(() => {
        action(() => {
          this.isLoadingAudioTranscribe = false;
        })();

        this.fetchAll(this.fileReviewInfo?.Id);
      });
  };

  @action handleResetStore = () => {
    this.isReady = false;
    this.currentStep = 0;
    this.loading = true;
    this.fileReviewInfo = {
      ...INIT_FILE_REVIEW,
      ServiceNotesandFeeObj: {
        AdminExtraFeeAmount: null,
        AdminExtraFeeTaxPercentage: null,
        CReference: null,
        CReferenceId: null,
        CommissionAmount: 0,
        CommissionOnTopRate: null,
        CommissionRate: null,
        CommissionRateType: 0,
        Description: null,
        HasOtherFee: false,
        IncludeAdminExtraFeeToDoctorCommission: false,
        IncludeManualFee1ToDoctorCommission: false,
        IncludeServiceItemFeeToDoctorCommission: false,
        InvoiceAttachment: null,
        ListServiceItems: null,
        OtherFee1Description: null,
        OtherFee1Label: null,
        OtherFee1TaxPercentage: null,
        OtherFee1Value: null,
        OtherFee2Description: null,
        OtherFee2Label: null,
        OtherFee2TaxPercentage: null,
        OtherFee2Value: null,
        PayingCaseManager: null,
        PayingCaseManagerId: null,
        PayingClient: null,
        PayingClientId: null,
        SameBookingCM: false,
        SameClientAccEmail: false,
        SendInvoice: null,
        SendInvoice2: null,
      },
    };
    this.doctorsKeyword = '';
    this.supplierKeyword = '';
    this.doctorsPerPage = 30;
    this.supplierPerPage = 30;
    this.companiesKeyword = '';
    this.companiesPerPage = 30;
    this.subCompaniesKeyword = '';
    this.subCompaniesPerPage = 30;
    this.CMsKeyword = '';
    this.CMsPerPage = 30;
    this.claimantsKeyword = '';
    this.claimantsPerPage = 30;
    this.pCustomer = null;
    this.CMsKeyword = '';
    this.CMsPerPage = 30;
    this.numberBillingCodePerPage = 30;
    this.isServicePrepaymentLocked = false;
    this.serviceItemTotalFee = 0;
    this.serviceItemTotalTax = 0;
  };
}

export default new FileReviewStore();
