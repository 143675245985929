import React from 'react';
import { Form, Input, Radio, DatePicker } from 'antd';
import { Message } from 'semantic-ui-react';

class ReportStatus extends React.Component {
  render() {
    return (
      <Form name="report-status">
        <Form.Item label="Report Completed" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Completion Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Attachment" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Report Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default ReportStatus;
