import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class SuppStore {
  @observable loading = true;
  @observable data = null;

  @observable fromTo = '';
  @observable keyword = '';
  @observable filterNextStatus = 'All';
  @observable filter = 'All';
  @observable filterTypes = null;
  @observable sortColumn = 'Status Date';
  @observable sortKey = 'StatusDate';
  @observable sortDirection = 'descending';
  @observable includeAttachment = false;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};
  @observable dynamicStatus = [];
  @observable dynamicNextStatus = [];

  getFromToDate = () => {
    const [from, to] = this.fromTo.split(' - ');
    const [fromDay, fromMonth, fromYear] = from ? from.split('/') : [];
    const [toDay, toMonth, toYear] = to ? to.split('/') : [];

    return {
      from: from ? new Date([fromMonth, fromDay, fromYear].join('-')).toISOString() : null,
      to: to ? new Date([toMonth, toDay, toYear].join('-')).toISOString() : null,
    };
  };

  @action fetchSupp = (setTotalPage = false) => {
    this.loading = true;
    const { from, to } = this.getFromToDate();

    return customFetch('/Supplementary/Supplementary_LoadList', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        FilterType: this.filter,
        FilterNextStatus: this.filterNextStatus,
        FilterTypes: this.filterTypes,
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: false,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
        From: from,
        To: to,
        IncludeAttachment: this.includeAttachment,
      }),
    }).then(
      action(data => {
        this.data = data;
        this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
        this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchSupp();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSupp(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action onSearchChange = keyword => {
    this.keyword = keyword;
    return this.fetchSupp();
  };

  @action handleDateChange = (_, { value }) => {
    this.fromTo = value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.fromTo = '';
    this.keyword = '';
    this.filter = 'All';
    this.filterNextStatus = 'All';
    this.filterTypes = null;
    this.sortColumn = 'Status Date';
    this.sortKey = 'StatusDate';
    this.sortDirection = 'descending';

    this.curPage = 1;
    this.totalPage = 0;
  };

  @action getSUPPStatus = () => {
    return customFetch('/Supplementary/GetDynamicSupplementaryStatus', { method: 'POST' }).then(
      action(data => {
        if (data.filter(x => x.Count > 0).length > 0) {
          var All = {
            key: 'All',
            text: 'All',
            value: 'All',
          };
          this.dynamicStatus = [
            All,
            ...data
              .filter(x => x.Count > 0)
              .map(opt => ({
                key: opt.Status,
                text: this.GetCurrentStatusText(opt.Status) + ' - (' + opt.Count + ')',
                value: opt.Status,
              })),
          ];
        }
      }),
    );
  };

  @action getSUPPNextStatus = () => {
    return customFetch('/Supplementary/GetDynamicSupplementaryNextStatus', { method: 'POST' }).then(
      action(data => {
        if (data.filter(x => x.Count > 0).length > 0) {
          var All = {
            key: 'All',
            text: 'All',
            value: 'All',
          };
          this.dynamicNextStatus = [
            All,
            ...data
              .filter(x => x.Count > 0)
              .map(opt => ({
                key: opt.Status,
                text: opt.Status + ' - (' + opt.Count + ')',
                value: opt.Status,
              })),
          ];
        }
      }),
    );
  };

  GetCurrentStatusText = status => {
    switch (status) {
      case 'Corrected Report Returned from Provider':
        return 'Corrected Report Sent to MAG';
      case 'Dictation Received':
        return 'Dictations Sent to MAG';
      default:
        return status;
    }
  };
}

export default new SuppStore();
