import { observer } from 'mobx-react';
import { Badge, notification, Tooltip, Select, Input } from 'antd';
import { saveAs } from 'file-saver';
// import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import React, { useMemo, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
// import store from '../Store';
import './AudioTranscript.scss';
import { TRANSCRIPT_AUDIO_FILE_EXTENSION } from '@utils/constants';

const withPunctuateConditions = [
  {
    from: [
      '. Stop. This paragraph.',
      'Stop this paragraph. ',
      '. Stop. Ms. Paragraph. ',
      '. Next paragraph(\\.)* ',
      '.ed. Paragraph. ',
      '. Ms paragraph. ',
      '.ates. Paragraph. ',
      '. Paragraph. ',
    ],
    to: '.\n\n',
  },
  {
    from: ['. Answer question ((\\d)+\\.(\\d)+)', '. Answer ((\\d)+\\.(\\d)+)', '. Answer to ((\\d)+\\.(\\d)+)'],
    to: '\n\nAnswer question $1',
  },
  {
    from: ['. Full stop.', '. Stop.'],
    to: '.',
  },
  {
    from: ['. Stop\n'],
    to: '\n',
  },
];
const withoutPunctuateConditions = [
  {
    from: [
      ' New paragraph ',
      ' (stop )*next paragraph ',
      'Stop this paragraph. ',
      ' stopped paragraph ',
      ' stop ms paragraph ',
      '(dot )*ms paragraph ',
      ' stop paragraph ',
    ],
    to: '.\n\n',
  },
];

const wordReplacements = [
  {
    from: ['Close brackets'],
    to: ')',
  },
  {
    from: ['In brackets', 'Brackets'],
    to: '(',
  },
  {
    from: ['comma'],
    to: ',',
  },
  ...withPunctuateConditions,
];

function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

function formatParagraph(content) {
  var paragraphs = content.split('\n');

  for (var i = 0; i < paragraphs.length; i++) {
    paragraphs[i] = capitalizeFirstLetter(paragraphs[i].trim());
  }

  content = paragraphs.join('\n');

  return content;
}

function formatText(content) {
  if (!content) return '';
  wordReplacements.forEach(item => {
    item.from.forEach(patternString => {
      content = content.replace(new RegExp(patternString, 'ig'), item.to);
    });
  });

  content = formatParagraph(content);

  // // Remove extra comma after (
  // content = content.replace(/\((\s)*(\.)*/gi, '(')

  // // Remove extra dot, comma after )
  // content = content.replace(/[. ,]*\)[,]*/gi, ')')

  // // Remove duplicate dot
  // content = content.replace(/\.(\s)*\./gi, '.')

  // // Remove extra dot, comma before (
  // content = content.replace(/[.,](\s)*\(/gi, ' (')

  // // Remove extra space, comma after (
  // content = content.replace(/\(([\s,])*/gi, '(')

  // // Remove extra space before comma
  // content = content.replace(/(\s)+,/gi, ',')

  // Remove extra space after "
  // content = content.replace(/"(\s)+/gi, "\"");

  return content;
}

const saveAsDocxFile = (text, title) => {
  const paragraphs = text
    .split('\n\n')
    .filter(i => i.trim() != '')
    .map(paragraph => {
      return new Paragraph({
        children: [
          new TextRun({
            text: paragraph,
            size: 22,
            font: {
              name: 'Calibri (Body)',
            },
          }),
        ],
        spacing: {
          before: 200,
        },
      });
    });
  const section = {
    children: paragraphs,
  };

  const doc = new Document({ sections: [section] });

  Packer.toBlob(doc).then(blob => {
    saveAs(blob, `${title}.docx`);
  });
};

const AudioTranscripts = ({ files = [], getAudioTranscription, isLoading = false }) => {
  const validFiles = useMemo(() => {
    return files.filter(file => {
      const fileName = file.FileName || file.name;
      return (
        file.Id &&
        TRANSCRIPT_AUDIO_FILE_EXTENSION.includes(
          fileName
            .toLowerCase()
            .split('.')
            ?.pop(),
        )
      );
    });
  }, [files]);

  if (!validFiles || validFiles.length === 0) {
    return null;
  }

  return (
    <div className="fluid-field">
      <div className="dictation-transcription-header">Dictation Transcriptions</div>
      <div className="dictation-transcription-wrapper fluid-field">
        {validFiles.map(file => {
          return (
            <FileTranscript
              key={file.id}
              file={file}
              getAudioTranscription={getAudioTranscription}
              isLoading={isLoading}
            />
          );
        })}
      </div>
    </div>
  );
};

function removeFileExtension(filename) {
  var lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return filename.substring(0, lastDotIndex);
  }
  return filename;
}

const saveAsTextFile = (text, fileName) => {
  // Create a Blob object with the text content
  const blob = new Blob([text], { type: 'text/plain' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = `${removeFileExtension(fileName)}.txt`; // Specify the filename with .txt extension

  // Simulate a click on the link element to trigger the download
  link.click();

  // Clean up the temporary URL object
  URL.revokeObjectURL(url);
};

const copyToClipboard = text => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch(error => {
      console.error('Error copying text to clipboard:', error);
    });
};

const FileTranscript = observer(({ file, getAudioTranscription, isLoading = false }) => {
  const [language, setLanguage] = useState(file.TranscriptLanguage ?? 'en_uk');

  const handleSaveTranscription = file => {
    saveAsDocxFile(formatText(file.TranscriptText), file.Title);
    // saveAsTextFile(formatText(file.transcriptText), file.fileName)
  };

  const handleCopyTranscription = file => {
    copyToClipboard(formatText(file.TranscriptText));
    notification.success({
      message: 'Transcript copied to clipboard',
    });
  };

  const handeChangeLanguage = value => {
    setLanguage(value);
  };

  return (
    <div className="">
      <div className="dictation-transcription-item">
        <div className="fluid-field">
          <span>File: </span>
          <strong>{file.Title}</strong>
        </div>

        <Select
          className="language-selector"
          size="large"
          value={language}
          allowClear
          onChange={handeChangeLanguage}
          disabled={!!file.TranscriptId}
        >
          {[
            { id: 'en_uk', displayName: 'English (United Kingdom)' },
            { id: 'en_us', displayName: 'English (United States)' },
          ].map((i, idx) => (
            <Select.Option key={idx} value={i.id}>
              {i.displayName}
            </Select.Option>
          ))}
        </Select>
        {!file.TranscriptText && (
          <Button
            size="small"
            onClick={() => getAudioTranscription(file, language)}
            disabled={!!file.TranscriptText || isLoading}
            loading={isLoading}
          >
            {file.TranscriptId ? 'Refresh' : 'Transcribe'}
          </Button>
        )}
        {file.TranscriptId && !file.TranscriptText && (
          <div className="flex">
            <Badge>Transcribe Processing...</Badge>
            <Tooltip title="Transcribing may take a few minutes. Please continue to use other functions as needed." />
          </div>
        )}

        {file.TranscriptText && (
          <>
            <Button size="small" color="blue" onClick={() => handleSaveTranscription(file)} title="Download Transcript">
              <Icon name="download" />
            </Button>

            <Button size="small" onClick={() => handleCopyTranscription(file)} title="Copy">
              <Icon name="copy outline" />
            </Button>
          </>
        )}
      </div>
      <TranscriptionContent rawText={file.TranscriptText} />
    </div>
  );
});

const TranscriptionContent = observer(({ rawText }) => {
  const formatedText = useMemo(() => {
    return formatText(rawText);
  }, [rawText]);

  if (!rawText) return null;
  return (
    <div className="transcription-content">
      <Input.TextArea rows={5} value={formatedText} disabled={true} />
    </div>
  );
});

export default observer(AudioTranscripts);
