import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import Store from './Store';
import ModalAddEdit from './ModalAddEdit';
import ModalImport from './ModalImport';

function MBSCodeModal() {
  const { modalType } = Store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...Store} onCancel={Store.toggleModal(false)} />;
  }
  if (modalType === 'addOrEdit') {
    return <ModalAddEdit {...Store} onCancel={Store.toggleModal(false)} />;
  }
  if (modalType === 'import') {
    return <ModalImport {...Store} onCancel={Store.toggleModal(false)} />;
  }
}

export default MBSCodeModal;
