import { action, observable, toJS } from 'mobx';

import customFetch from '../../../utils/customFetch';
import { searchAMACaculation, getStateList } from './Service';

class ServiceFeeStore {
  @observable loading = true;
  @observable data = null;
  @observable amaSelectionList = [];

  @observable keyword = '';
  @observable filter = 1;
  @observable filterStatus = 'All';

  @observable sortKey = 'AMACode';
  @observable sortColumn = 'AMACode';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};
  @observable selectedExcluded = [];

  @observable dataServiceType = [];
  @observable dataTypeProcedure = [];
  @observable filterServiceType = '';
  @observable filterTypeProcedure = '';
  @observable filterStatus = 'all';
  @observable searchExcludedText = '';
  @observable arrExcludedId = [];

  @observable numberPageSearch = 20;
  @observable isSelectionList = false;

  @observable listTreatment = [];
  @observable loadingCheckAmaTreatment = false;
  @observable lstExclusionRange = [];
  @observable loadingUpload = false;
  @observable lstAMACodeFee = [];

  //Add caculationRule
  @observable lstAMACodeFrom = [];
  @observable lstAMACodeTo = [];
  @observable lstCalculationRule = [];
  @observable lstStates = [];

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action exportSearchResults = () => {
    var query = 'keyword=' + this.keyword;
    query += '&filterServiceType=' + this.filterServiceType;
    query += '&filterTypeProcedure=' + this.filterTypeProcedure;
    query += '&filterType=' + this.filterStatus;

    window.open('/MAGService/ExportAMACodes?' + query, '_blank');
  };

  @action fetchFiles = (setTotalPage = false) => {
    this.loading = this.isSelectionList ? false : true;
    this.loadingSearch = this.isSelectionList ? true : false;
    customFetch('/MAGService/AMAGetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        Keyword: !this.isSelectionList ? this.keyword : this.searchExcludedText,
        CurPage: this.curPage,
        NumItemPerPage: this.numberPageSearch,
        FilterServiceType: this.filterServiceType,
        FilterTypeProcedure: this.filterTypeProcedure,
        IsSelectionList: this.isSelectionList,
        FilterType: this.filterStatus,
        sortBy: this.sortKey,
        sortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
      }),
    }).then(
      action(data => {
        if (this.isSelectionList) {
          this.loadingSearch = false;
          this.amaSelectionList =
            toJS(
              data.itemList.map(t => ({
                value: t.Id,
                text: t.AMACode,
                key: t.Id,
              })),
            ) || [];
        } else {
          this.data = data;
        }
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action setListItems = (name, arr) => {
    this[name] = arr;
  };


  @action setListAMACodeFrom = arr => {
    this.lstAMACodeFrom = arr;
  };

  @action setListAMACodeTo = arr => {
    this.lstAMACodeTo = arr;
  };

  @action setListCalculationRule = arr => {
    this.lstCalculationRule = arr;
  };

  @action setListExclusionRange = arr => {
    this.lstExclusionRange = arr;
  };

  @action setListAMACodeFee = arr => {
    this.lstAMACodeFee = arr;
  };

  @action setLoadingUpload = status => {
    this.loadingUpload = status;
  };

  @action fetchExcludedAMACodeFiles = (id = 0) => {
    customFetch('/MAGService/GetExcludedAMACode', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Id: id,
      }),
    }).then(
      action(data => {
        this.loadingAMA = false;
        this.arrExcludedId = [];
        if (data) {
          this.arrExcludedId = data.itemList.map(a => a.ExcludedAMAId);
          const amaSelected = data.itemList.map(amacode => ({
            value: amacode.ExcludedAMAId,
            text: amacode.ExcludedAMACode,
            key: amacode.ExcludedAMAId,
          }));
          this.selectedExcluded = amaSelected;
        }
      }),
    );
  };

  @action fetchStates = () => {
    getStateList().then(resp => {
      if (resp.status === 'success') {
        this.setListItems('lstStates', resp.itemList);
      }
    });
  };

  @action fetchCaluationRule = () => {
    searchAMACaculation({}).then(resp => {
      if (resp.status === 'success') {
        resp.itemList.unshift({ Id: -1, CalculationName: 'None' });
        this.setListCalculationRule(resp.itemList);
      } else {
        this.setListCalculationRule([]);
      }
    });
  };

  @action fetchServiceTypeAMACodeFiles = () => {
    customFetch('/MAGService/GetServiceTypeAMAAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordServiceType,
        CurPage: 1,
        NumItemPerPage: this.numberPageServiceTypeSearch,
      }),
    }).then(
      action(data => {
        this.dataServiceType = data.itemList.map(t => ({
          value: t,
          text: t,
        }));
      }),
    );
  };

  @action fetchTypeProcedureAMACodeFiles = () => {
    customFetch('/MAGService/GetTypeProcedureAMAAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordTypeProcedure,
        CurPage: 1,
        NumItemPerPage: this.numberPageTypeProcedureSearch,
        FilterServiceType: this.filterServiceType,
      }),
    }).then(
      action(data => {
        this.dataTypeProcedure = data.itemList.map(t => ({
          value: t,
          text: t,
        }));
      }),
    );
  };

  @action fetchCheckTreatmentCaculations = listTreatment => {
    this.loadingCheckAmaTreatment = true;
    customFetch('/MAGService/CheckTreatmentCaculations', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        ListAmaCode: listTreatment.map(i => i.treatment),
      }),
    })
      .then(
        action(response => {
          this.listTreatment = response?.itemList || [];
          this.loadingCheckAmaTreatment = false;
        }),
      )
      .catch(
        action(() => {
          this.loadingCheckAmaTreatment = false;
          this.listTreatment = [];
        }),
      );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchFiles(setTotalPage);
  };

  @action refetchGroup = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchService();
  };

  @action resetRefresh = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'AMACode';
    this.sortKey = 'AMACode';
    this.sortDirection = 'ascending';
    this.filterServiceType = '';
    this.filterTypeProcedure = '';
    this.refetch({}, true);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'AMACode';
    this.sortKey = 'AMACode';
    this.sortDirection = 'ascending';
    this.filterServiceType = '';
    this.filterTypeProcedure = '';
    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new ServiceFeeStore();
