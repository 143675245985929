import { Divider, Spin } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { toJS, action } from 'mobx';

import '../AddEditClinic/index.scss';
import './index.scss';

import ProgressStatus from './ProgressStatus';
import ModalConfirm from './ModalConfirm';
import ClaimDetails from './ClaimDetails';
import CaseDocuments from './CaseDocuments';
import CaseCancellation from './CaseCancellation';
import ClientInvoicing from './ClientInvoicing';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';
import ModalHistory from './ModalHistory';
import ModalCompanyHistory from './ModalCompanyHistory';
import store from './store';
import ActionRequiredForm from './ActionRequiredForm';
import ModalUpload from './UploadComponent';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';
import ModalDisbursementFunding from '@components/shared/ModalDisbursementFunding';
import ui from '../../../stores/dashboard';
import * as api from '../../../stores/api';

const momentBusinessDays = require('moment-business-days');

const STEPS = [
  'Claim Details',
  'Paperwork, Dictations & Report',
  'Cancellation',
  'Client & Specialist Payments',
  'Case Correspondence',
  'Action Required',
];

const FIELD_NAME_FOR_NOTIFICATION = [
  {
    key: 'bookingClient',
    text: 'client',
  },
  {
    key: 'bookingCaseManager',
    text: 'case manager',
  },
  {
    key: 'payingClient',
    text: 'client',
  },
  {
    key: 'payingCaseManger',
    text: 'case manager',
  },
  {
    key: 'claimant',
    text: 'claimant',
  },

  {
    key: 'specialist',
    text: 'specialist',
  },
];

@observer
class AddEditFileReview extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  @action handleChangeValue = (field, value) => {
    store.fileReviewInfo[field] = value;
  };

  getDataTransfer = (fieldName, value) => {
    if (fieldName === 'claimant') {
      const existClaimant = store.claimants.find(i => i.Id === value.Id);
      const newClaimants = existClaimant
        ? [...store.claimants.filter(i => i.Id !== value.Id), value]
        : [...store.claimants, value];
      store.setFieldsValue({
        claimants: newClaimants,
      });
      this.handleChangeValue('PatientId', value.Id);
      this.handleChangeValue('Patient', value);
      return;
    }
    if (fieldName === 'specialist') {
      this.handleChangeValue('DoctorId', value.Id);
      this.handleChangeValue('Doctor', value);
      const existItems = store.claimants.find(i => i.Id === value.Id);
      const newItems = existItems
        ? [...store.doctors.filter(i => i.Id !== value.Id), value]
        : [...store.doctors, value];
      store.setFieldsValue({
        doctors: newItems,
      });
      return;
    }
    if (fieldName === 'payingClient') {
      this.handleChangeValue('PCustomerId', value.Id);
      this.handleChangeValue('PCustomer', value);

      const existItems = store.companies.find(i => i.Id === value.Id);
      const newItems = existItems
        ? [...store.companies.filter(i => i.Id !== value.Id), value]
        : [...store.companies, value];
      store.setFieldsValue({
        companies: newItems,
      });
      return;
    }
    if (fieldName === 'bookingClient') {
      this.handleChangeValue('CustomerId', value.Id);
      this.handleChangeValue('Customer', value);
      const existItems = store.companies.find(i => i.Id === value.Id);
      const newItems = existItems
        ? [...store.companies.filter(i => i.Id !== value.Id), value]
        : [...store.companies, value];
      store.setFieldsValue({
        companies: newItems,
      });
    }
    if (fieldName === 'bookingCaseManager') {
      this.handleChangeValue('CaseManagerId', value.Id);
      this.handleChangeValue('CaseManager', value);
      const existItems = store.companyCMs.find(i => i.Id === value.Id);
      const newItems = existItems
        ? [...store.companyCMs.filter(i => i.Id !== value.Id), value]
        : [...store.companyCMs, value];
      store.setFieldsValue({
        companyCMs: newItems,
      });
      return;
    }
    if (fieldName === 'payingCaseManager') {
      this.handleChangeValue('PCaseManagerId', value.Id);
      this.handleChangeValue('PCaseManager', value);
      const existItems = store.pCompanyCMs.find(i => i.Id === value.Id);
      const newItems = existItems
        ? [...store.pCompanyCMs.filter(i => i.Id !== value.Id), value]
        : [...store.pCompanyCMs, value];
      store.setFieldsValue({
        pCompanyCMs: newItems,
      });
      return;
    }
  };

  _reloadPageToGetNewInfo = debounce(
    action(() => {
      const { pageGetter, fieldName, dataTransfer, listPageGetters } = dashboardStore;
      const page = listPageGetters.find(i => i.pathname === router.location.pathname);
      if (page && page.pathname) {
        if (pageGetter === page.key) {
          if (fieldName && dataTransfer) {
            const fieldNotified = FIELD_NAME_FOR_NOTIFICATION.find(i => i.key === fieldName);
            store.toggleModalConfirm(true, {
              message: `Do you want to add this ${fieldNotified?.text} (${dataTransfer?.FullName ||
                dataTransfer?.Name}) to the current booking?`,
              onOk: () => {
                this.getDataTransfer(fieldName, dataTransfer);
              },
            });
            return dashboardStore.transferData({
              dataTransfer: null,
              fieldName: null,
              pageGetter: null,
            });
          }
          return;
        } else {
          return;
        }
      }
    }),
    500,
  );
  componentDidMount() {
    const { id = 0, step = 0, mType, mId } = this.getRouteParams();

    store.setFieldsValue({ currentStep: +step });
    store.fetchAll(parseInt(id), mType, mId);
    ui.on('open(/view/add-edit-file-review-2)', this._reloadPageToGetNewInfo);
  }

  componentWillUnmount() {
    store.disposeReaction();
    store.handleResetStore();
  }

  handleStepChange = step => () => {
    store.setFieldsValue({ currentStep: step });
    // store.sectionClickOpen(step);
  };

  renderForm = () => {
    switch (toJS(store.currentStep)) {
      case 1:
        return <CaseDocuments />;
      case 2:
        return <CaseCancellation />;
      // case 3:
      //   return <SpecialistPayment />;
      case 3:
        return <ClientInvoicing />;
      case 4:
        return <CaseCorrespondence />;
      case 5:
        return <ActionRequired />;
      default:
        return <ClaimDetails />;
    }
  };

  renderStep = currentStep => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return STEPS.map((step, idx) => (
        <Button
          key={step}
          fluid
          size="large"
          className={cx('step', {
            active: toJS(currentStep) === idx,
          })}
          onClick={this.handleStepChange(idx)}
        >
          {idx + 1}. {step}
        </Button>
      ));
    }
    if (api.isTypist()) {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 &&
          idx !== 4 &&
          idx !== 3 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ),
      );
    } else if (api.isCaseManager()) {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: toJS(currentStep) === idx,
              })}
              disabled={!store.fileReviewInfo.Id && (idx === 2 || idx === 3 || idx === 4)}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}.{' '}
              {idx === 1 && api.isCaseManager()
                ? 'Paperwork and Report'
                : idx === 3 && api.isCaseManager()
                ? 'Invoices'
                : idx === 3 && api.isDoctor()
                ? 'Specialist Payment'
                : step}
            </Button>
          ),
      );
    } else {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: toJS(currentStep) === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}.{' '}
              {idx === 1 && api.isCaseManager()
                ? 'Paperwork and Report'
                : idx === 3 && api.isCaseManager()
                ? 'Invoices'
                : idx === 3 && api.isDoctor()
                ? 'Specialist Payment'
                : step}
            </Button>
          ),
      );
    }
  };

  render() {
    const { loading, handleSubmit, currentStep } = store;
    return (
      <div className={cx(`page-${loading ? 'loading' : 'container'}`)}>
        {loading ? (
          <div className="page-loading">
            <Spin size="large" />
          </div>
        ) : (!api.isCMInvoiceCheck() && 
          <React.Fragment>
            {!loading && <ProgressStatus />}
            <div className="form-container">
              <h1 className="form-title">
                {currentStep === 1 && api.isCaseManager()
                  ? 'Paperwork and Report'
                  : currentStep === 3 && api.isCaseManager()
                  ? 'Invoices'
                  : currentStep === 3 && api.isDoctor()
                  ? 'Specialist Payment'
                  : STEPS[toJS(currentStep)]}
              </h1>
              {this.renderForm()}
            </div>
            <div className="step-container step-container-sticky">{this.renderStep(currentStep)}</div>
            <div className="form-footer">
              <Divider />
              {/* {api.isAdminOrMagStaffOrAccounting() && !!store.fileReviewInfo.Id && (
                <Button
                  primary
                  onClick={() => window.open('/FileReview/FileReviewPrint?id=' + store.fileReviewInfo.Id, '_blank')}
                >
                  Print File Review
                </Button>
              )} */}
              {!!store.fileReviewInfo.Id && api.isCaseManager() && store.selectedCustomer?.ClientType === 3 && (
                <Button primary onClick={() => store.handleRequestFunding()}>
                  Request Funding for this Report
                </Button>
              )}
              {!api.isOnlySearch() && <Button primary disabled={loading} onClick={handleSubmit}>
                Save
              </Button>}
              <Button className="negative" onClick={() => dashboardStore.close(window.location.pathname)}>
                Close
              </Button>
            </div>
            <ModalHistory />
            <ModalCompanyHistory />
            <ModalConfirm
              open={store.isOpenModalConfirm}
              onCancel={() => store.toggleModalConfirm(false)}
              {...store.modalParams}
              modalParams={toJS(store.modalParams)}
              cancelText={store.modalParams.cancelText}
              hideWarningIcon={store.modalParams.hideWarningIcon}
            />
            {store.isOpenModalDisbursementFunding && (
              <ModalDisbursementFunding
                {...store.modalDisbursementFundingParams}
                open={store.isOpenModalDisbursementFunding}
                onCancel={() =>
                  store.setFieldsValue({
                    isOpenModalDisbursementFunding: false,
                    modalDisbursementFundingParams: {},
                  })
                }
              />
            )}
            {store.isOpenModalAction && <ActionRequiredForm />}
            {store.isOpenModalUpload && <ModalUpload />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default AddEditFileReview;
