import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import TypesOfReportModal from './TypesOfReportModal';
import typesOfReportStore from './typesOfReportStore';
import TypesOfReportTable from './TypesOfReportTable';
import TypesOfReportToolbar from './TypesOfReportToolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class TypesOfReport extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/types-of-report-2', true);
      return;
    }

    typesOfReportStore.fetchFiles(true);
    ui.on('open(/view/types-of-report-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    typesOfReportStore.fetchFiles(true);
  }, 500);

  render() {
    const { loading, data, open } = typesOfReportStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <TypesOfReportToolbar />
            <TypesOfReportTable />
            {open && <TypesOfReportModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TypesOfReport;
