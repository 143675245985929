import './index.scss';

import React from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import TemplateTable from './MessageTempalteTable';
import TableToolbar from './MessageTemplateToolbar';
import MessageTemplateStore from './MessageTemplateStore';
import MessageTemplateModal from './MessageTemplateModal';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class MessageTemplate extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/message-template', true);
      return;
    }
    this._searchDebounced();
    ui.on('open(/view/message-template)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    MessageTemplateStore.fetchTemplate(true);
    MessageTemplateStore.fetchMailModule();
    MessageTemplateStore.fetchReceiver();
    MessageTemplateStore.fetchCaseStage();
  }, 1000);
  render() {
    const {
      open,
      loading,
      data,
      loadingModuleType,
      loadingCaseStage,
      loadingReceiver,
      dataReceiver,
      dataModuleType,
      dataCaseStage,
    } = MessageTemplateStore;

    return (
      <div
        className={`Table-${
          (loading && !data) ||
          (loadingModuleType && !dataModuleType) ||
          (loadingReceiver && !dataReceiver) ||
          (loadingCaseStage && !dataCaseStage)
            ? 'loading'
            : 'container'
        }`}
      >
        {(loading && !data) ||
        (loadingModuleType && !dataModuleType) ||
        (loadingReceiver && !dataReceiver) ||
        (loadingCaseStage && !dataCaseStage) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <TableToolbar />
            <TemplateTable />
            {open && <MessageTemplateModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default MessageTemplate;
