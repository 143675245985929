import React from 'react';
import cx from 'classnames';
import { Divider, Spin } from 'antd';
import { action } from 'mobx';
import debounce from 'lodash/debounce';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import '../AddEditClinic/index.scss';
import './index.scss';

import ClaimDetails from './ClaimDetails';
import CaseDocuments from './CaseDocuments';
import CaseCancellation from './CaseCancellation';
import ProviderPayment from './ProviderPayment';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';
import store from './store';
import ModalActionNotes from './ActionRequiredForm';
import ModalConfirm from './ModalConfirm';
import ModalUpload from './UploadComponent';
import ModalHistory from './ModalHistory';
import ModalCompanyHistory from './ModalCompanyHistory';
import ProgressStatus from './ProgressStatus';
import { FIELD_NAME_FOR_ADD_EDIT } from './type';
import ModalDisbursementFunding from '@components/shared/ModalDisbursementFunding';

import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';
import router from '@stores/router';
import customFetch from '@utils/customFetch';

const STEPS = [
  'Claim Details',
  'Paperwork and CR Retrieval',
  'Cancellation & On-Hold Requests',
  'Provider Payments',
  'Case Correspondence',
  'Action Required',
];

@observer
class AddEditClinicalRecord extends React.Component {
  state = {
    currentStep: 0,
  };

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  _reloadPageToGetNewInfo = debounce(
    action(() => {
      const { pageGetter, fieldName, dataTransfer, listPageGetters } = dashboardStore;
      const page = listPageGetters.find(i => i.pathname === router.location.pathname);

      if (page && page.pathname) {
        if (pageGetter === page.key) {
          if (fieldName && dataTransfer) {
            const fieldNotified = FIELD_NAME_FOR_ADD_EDIT.find(i => i.fieldName === fieldName);
            store.toggleModalConfirm(true, {
              message: `Do you want to add this ${fieldNotified?.name} (${dataTransfer?.FullName ||
                dataTransfer?.Name}) to the current booking?`,
              onOk: () => {
                if (fieldName === 'ClinicId') {
                  const newArr = store.itemToArray(dataTransfer, store[fieldNotified.listName], 'ID');
                  store.setFieldsValue({ [fieldNotified.listName]: newArr });
                  store.handleChangeCRValue({
                    [fieldName]: dataTransfer?.Id || dataTransfer?.ID,
                  });
                } else {
                  const newArr = store.itemToArray(dataTransfer, store[fieldNotified.listName], 'Id');
                  store.setFieldsValue({ [fieldNotified.listName]: newArr });
                  store.handleChangeCRValue({
                    [fieldName]: dataTransfer?.Id || dataTransfer?.ID,
                  });
                }
              },
            });
            return dashboardStore.transferData({
              dataTransfer: null,
              fieldName: null,
              pageGetter: null,
            });
          }
          return;
        } else {
          return;
        }
      }
    }),
    500,
  );

  componentDidMount() {
    const { id = 0, step = 0 } = this.getRouteParams();
    dashboardStore.on('open(/view/add-edit-clinical-notes-2)', this._reloadPageToGetNewInfo);
    this.setState({ currentStep: +step });
    store.handleFetchClinicalNoteData(+id);
  }

  componentWillUnmount() {
    if (store.isReady) {
      store.handleDisposeReaction();
    }
    store.handleResetStore();
  }

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <CaseDocuments />;
      case 2:
        return <CaseCancellation />;
      case 3:
        return <ProviderPayment />;
      case 4:
        return <CaseCorrespondence />;
      case 5:
        return <ActionRequired />;
      default:
        return <ClaimDetails />;
    }
  };

  renderStep = () => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return STEPS.map((step, idx) => (
        <Button
          key={step}
          fluid
          size="large"
          className={cx('step', {
            active: this.state.currentStep === idx,
          })}
          onClick={this.handleStepChange(idx)}
        >
          {idx + 1}. {step}
        </Button>
      ));
    } else if (api.isCaseManager()) {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: this.state.currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
              disabled={!store.clinicalNoteInfo.Id && (idx === 2 || idx === 3 || idx === 4)}
            >
              {idx + 1}. {idx !== 3 ? step : 'Invoices'}
            </Button>
          ),
      );
    } else {
      return STEPS.map(
        (step, idx) =>
          idx !== 5 && (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', {
                active: this.state.currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {idx !== 3 ? step : 'Invoices'}
            </Button>
          ),
      );
    }
  };

  duplicateThisCase = () => {
    store.toggleModalConfirm(true, {
      message: 'This will add a new booking with the same Client and Claimant information. Do you want to proceed?',
      onOk: () => {
        customFetch('/ClinicalNote/DuplicateRecord', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ clinicalNoteId: store.clinicalNoteInfo.Id }),
        }).then(res => {
          if (res.status === 'success') {
            store.showNotification('Success', 'Data has been saved successfully', 'success');
          } else {
            store.showNotification('Error', 'Error occured while saving data.', 'error');
          }
        });
      },
    });
  };

  render() {
    const { loading } = store;
    return (
      <div className={loading ? 'page-loading' : 'page-container clinical-note-container'}>
        {loading ? (
          <Spin size="large" />
        ) : (!api.isCMInvoiceCheck() &&
          <>
            <ProgressStatus />
            <div className="form-container">
              <h1 className="form-title">
                {this.state.currentStep === 3 && api.isCaseManager() ? 'Invoices' : STEPS[this.state.currentStep]}
              </h1>
              {this.renderForm()}
              {store.isOpenModalAction && <ModalActionNotes />}
              <ModalConfirm
                open={store.isOpenModalConfirm}
                onCancel={() => store.toggleModalConfirm(false)}
                {...store.modalParams}
                cancelText={store.modalParams.cancelText}
                hideWarningIcon={store.modalParams.hideWarningIcon}
              />{' '}
              <ModalHistory />
              <ModalCompanyHistory />
              {store.isOpenModalUpload && <ModalUpload />}
              {store.isOpenModalDisbursementFunding && (
                <ModalDisbursementFunding
                  {...store.modalDisbursementFundingParams}
                  open={store.isOpenModalDisbursementFunding}
                  onCancel={() =>
                    store.setFieldsValue({
                      isOpenModalDisbursementFunding: false,
                      modalDisbursementFundingParams: {},
                    })
                  }
                />
              )}
            </div>
            <div className="step-container step-container-sticky">{this.renderStep(this.state.currentStep)}</div>
            <div className="form-footer">
              <Divider />
              {!api.isOnlySearch() && (api.isCaseManager() || api.isAdminOrMagStaffOrAccounting()) && !!store.clinicalNoteInfo.Id && (
                <Button primary onClick={() => this.duplicateThisCase()}>
                  Request Additional Records
                </Button>
              )}
              {/* {api.isAdminOrMagStaffOrAccounting() && !!store.clinicalNoteInfo.Id && (
                <Button
                  primary
                  onClick={() =>
                    window.open(
                      `/ClinicalNote/PrintLetterOfConfirmation?clinicalNoteId=${store.clinicalNoteInfo.Id}&printNow=true`,
                      '_blank',
                    )
                  }
                >
                  Print Case Request
                </Button>
              )} */}

              {!api.isOnlySearch() &&  !!store.clinicalNoteInfo.Id && api.isCaseManager() && store.bookingClientSelected?.ClientType === 3 && (
                <Button primary onClick={() => store.handleRequestFunding()}>
                  Request Funding for this Report
                </Button>
              )}
              {!api.isOnlySearch() && <Button primary onClick={() => store.handleSave()}>
                Save
              </Button>}
              <Button
                className="negative"
                onClick={() => {
                  dashboardStore.close(window.location.pathname);
                }}
              >
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AddEditClinicalRecord;
