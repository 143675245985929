import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import './Progress.scss';
import * as api from '@stores/api';

import FileReviewStore from './store';
import { FR_CASE_PROGRESS } from './type';
import ProgressStatus from '../../shared/ProgressStatus/ProgressStatus';
import _ from 'lodash';

import moment from 'moment';

const statusGroups = [
  {
    name: 'Booking & Paperwork',
  },
  {
    name: 'Reporting',
  },
  {
    name: 'Billing',
  },
  {
    name: 'Cancellation',
    type: 'radio',
  },
];

@observer
class Progress extends React.Component {
  handleUpdateStatus = newStatus => {
    if (FileReviewStore.fileReviewInfo.Id !== 0) {
      FileReviewStore.toggleModalConfirm(true, {
        modalType: 'confirm',
        onOk: () => FileReviewStore.updateStatus(newStatus),
        message: 'Are you sure to change case status?',
      });
    }
  };

  onClickStatus = (statusItem, itemModel) => {
    if (api.isDoctor() || api.isCaseManager()) {
      return;
    }
    if (api.isAdminOrMagStaffOrAccounting() && itemModel.CaseStatus !== 1 && itemModel.CaseStatus !== 2) {
      this.handleUpdateStatus(statusItem.id);
    } else {
      if (statusItem.id === 7 || statusItem.id === 8) {
        this.handleUpdateStatus(statusItem.id);
      }
    }
  };

  render() {
    const itemModel = toJS(FileReviewStore.fileReviewInfo);

    let statusLog = toJS(FileReviewStore.fileReviewStatusLogs)?.itemList || [];

    const caseStatuses = FR_CASE_PROGRESS.map(item => {
      var status = _.last(statusLog.filter(i => i.Status === item.key));
      return {
        ...item,
        changeByUser: status?.UserName,
        statusDate: status?.StatusDate,
        createdDate: status?.CreatedDate,
        createdDateUnix: moment(status?.CreatedDate).unix(),
        label: api.GetFRStatusText(item.key),
        checked: status != null,
        isCurrent: itemModel.CaseFlow === item.id,
      };
    });

    let groups = statusGroups.map(g => {
      var group = { ...g };

      var groupTasks = caseStatuses.filter(i => i.group === group.name);
      group.completed = false;
      group.inprogress = false;
      group.totalTask = group.type === 'radio' ? 1 : groupTasks.length;
      group.completedTaskCount = 0;
      group.active = groupTasks.some(i => i.id === itemModel.CaseFlow);
      group.inprogress = groupTasks.some(i => i.checked) || group.active;

      if (group.type === 'radio') {
        var checkedTasks = groupTasks.filter(i => i.checked);

        if (checkedTasks.length > 1) {
          checkedTasks.map(item => {
            item.checked = false;
            return item;
          });
          var latestStatus = _.maxBy(checkedTasks, i => i.createdDate);
          latestStatus.checked = true;
        }

        if (groupTasks.some(i => i.checked)) {
          group.completed = true;
          group.completedTaskCount = 1;
        }
      } else {
        if (groupTasks.every(i => i.checked)) {
          group.completed = true;
        }
        group.completedTaskCount = groupTasks.filter(i => i.checked).length;
      }

      return group;
    });

    return (
      <ProgressStatus
        statusGroups={groups}
        statuses={caseStatuses}
        onClickStatus={itemStatus => this.onClickStatus(itemStatus, itemModel)}
        description={() => (
          <div className="case-info">
            Case Info: <b>{`${FileReviewStore.fileReviewInfo.FileReviewNumber} `}</b>
            {FileReviewStore.fileReviewInfo.Customer && (
              <span>
                - Client: <b>{`${FileReviewStore.fileReviewInfo.Customer.Name} `}</b>
              </span>
            )}
            {FileReviewStore.fileReviewInfo.CaseManager && (
              <span>
                - Booking CM: <b>{`${FileReviewStore.fileReviewInfo.CaseManager.FullName} `}</b>
              </span>
            )}
            {FileReviewStore.fileReviewInfo.Patient ? (
              <span>
                - Claimant:{' '}
                <b>{`${FileReviewStore.fileReviewInfo.Patient.FirstName} ${FileReviewStore.fileReviewInfo.Patient.LastName} `}</b>
              </span>
            ) : (
              ''
            )}
            {FileReviewStore.fileReviewInfo.Doctor ? (
              <span>
                - Specialist: <b>{`${FileReviewStore.fileReviewInfo.Doctor.FullName}`}</b>
              </span>
            ) : (
              ''
            )}
          </div>
        )}
      />
    );
  }
}

export default Progress;
