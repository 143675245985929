import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { formatDate } from '../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../shared/table';

const columns = [
  // { title: '' },
  { title: 'Case No' },
  { title: 'Claim No' },
  { title: 'Status ' },
  { title: 'Status Date' },
];

class ReportTable extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Table striped celled style={{ marginLeft: '-25%', width: '150%' }}>
        <TableHeader columns={columns} />
        <Table.Body>
          {data.length ? (
            data.map(record => (
              <Table.Row key={record.CaseNo}>
                <Table.Cell>{record.CaseNo}</Table.Cell>
                <Table.Cell>{record.ClaimNo}</Table.Cell>
                <Table.Cell>{record.Status}</Table.Cell>
                <Table.Cell>
                  {record.StatusDate
                    ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default ReportTable;
