import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import JobTitleStore from './JobTitleStore';
import * as api from '../../../stores/api';
import router from '../../../stores/router';

const columns = [
  { title: '' },
  { title: 'Name', sortKey: 'Label' },
  { title: 'Description', sortKey: 'Description' },
  { title: 'Status', sortKey: 'IsActive' },
];

@observer
class JobTitleTable extends React.Component {
  openModal = options => {
    return JobTitleStore.toggleModal(true, options);
  };

  openCaseDetail = (id, label) => () => {
    localStorage.setItem('JobTitle', label);
    iframe.forceOpenTab('AddOrEditJobTitle', `?id=${id}&view=AddOrEditJobTitle`, {
      id,
      view: 'AddOrEditJobTitle',
    });
  };

  handleDelete = id => async () => {
    JobTitleStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/JobTitle/DeleteRecord', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      await JobTitleStore.refetch({}, true);
    } finally {
      JobTitleStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = (id, record) => async () => {
    JobTitleStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/JobTitle/EditItemStatus', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });

      await JobTitleStore.refetch({}, true);
    } finally {
      JobTitleStore.setFieldsValue({ loading: false });
    }
  };

  handlePageClick = page => {
    JobTitleStore.refetch({ curPage: page });
  };

  onOpenCaseDetail = (record, id) => () => {
    localStorage.setItem('JobTitle', record.Label);
    router.history.push({
      pathname: '/view/add-edit-job-title-2',
      state: {
        type: 'jobTitle',
        record,
      },
      search: `?id=${id}`,
    });
  };

  renderTableBody = () => {
    return JobTitleStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell
          selectable
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
          onClick={this.onOpenCaseDetail(record, Id)}
        >
          <Link
            onClick={this.onOpenCaseDetail(record, Id)}
            to={{
              pathname: '/view/add-edit-job-title-2',
              state: {
                record,
                type: 'jobTitle',
              },
              search: `?id=${Id}`,
            }}
          >
            {record.Label}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.onOpenCaseDetail(record, id)}>Edit</Menu.Item>}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data, totalItems } = JobTitleStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={JobTitleStore.sortColumn}
          sortDirection={JobTitleStore.sortDirection}
          onSort={JobTitleStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={JobTitleStore.curPage}
          totalPage={JobTitleStore.totalPage}
          onPageClick={this.handlePageClick}
          totalItems={totalItems}
        />
      </Table>
    );
  }
}

export default JobTitleTable;
