import fscreen from 'fscreen';
import { action, observable } from 'mobx';

class DomStore {
  @observable isFullscreen = false;

  constructor() {
    fscreen.addEventListener('fullscreenchange', this.onFullscreenChange);
    this.onFullscreenChange();
  }

  toggleFullscreen = () => {
    if (!fscreen.fullscreenElement) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
  };
  @action onFullscreenChange = () => {
    this.isFullscreen = !!fscreen.fullscreenElement;
  };
}

export default new DomStore();
