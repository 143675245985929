import FileUpload from '@components/shared/FileUpload';
import Modal from '@components/shared/Modal/';
import { CKEditorConfig } from '@utils/constants';
import { notification } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import React, { useState } from 'react';
import { Button, Form, Input, Label } from 'semantic-ui-react';

import customFetch from '@utils/customFetch';

import Store from './Store';

function ReplyModal({ selectedMail, isOpen }) {
  const [content, setContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [subject, setSubject] = useState(selectedMail.subject);

  const handleOnChangeContent = e => {
    var html = e.editor.getData();
    setContent(html);
  };

  const handleOnSubmit = async () => {
    const data = {
      EntryId: selectedMail.outlookEntryId,
      Sender: selectedMail.receiver,
      Content: content,
      Subject: subject,
      Attachments: fileList
        .filter(item => item.response && item.response.name)
        .map(item => {
          return item.response.name;
        }),
    };

    try {
      const res = await customFetch('/OutlookMail/Reply', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      });

      handleOnClose();

      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          message: 'Email Sent Successfully',
        });
      } else {
        notification.error({
          message: 'Email Failed to Send',
          description: 'Please check logs for details.',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Email Failed to Send',
        description: 'Please check logs for details.',
      });
    }
  };

  const handleOnClose = () => {
    setContent('');
    Store.setFieldsValue({ isReplyModalOpen: false });
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };

  const handleFileAttachChange = fileList => {
    setFileList(fileList);
  };

  return (
    <Modal
      visible={isOpen}
      width={600}
      onCancel={handleOnClose}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={handleOnSubmit}>
            Send
          </Button>
          <Button className="negative" onClick={handleOnClose}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      <Form size={'small'}>
        <Form.Field>
          <label>
            To: <Label>{selectedMail.sender}</Label>
          </label>
        </Form.Field>
        <Form.Field required>
          <label>Subject</label>
          <Input value={subject} onChange={handleChangeSubject} />
        </Form.Field>
        <Form.Field required>
          <label>Content</label>
          <CKEditor config={CKEditorConfig} initData={content} onChange={handleOnChangeContent} />
        </Form.Field>
        <Form.Field>
          <FileUpload label="Attach files" multiUpload fileList={fileList} onFileChange={handleFileAttachChange} />
        </Form.Field>
      </Form>
    </Modal>
  );
}

export default ReplyModal;
