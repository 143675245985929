import cx from 'classnames';
import React, { useState } from 'react';
import { DatesRangeInput, DateInput } from 'semantic-ui-calendar-react';
import { Button, Dropdown, Input as SemanticInput } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
// import { Tab } from 'bootstrap';
import './index.scss';

export function Input({ icon, placeholder, className, ...props }) {
  return (
    <SemanticInput
      {...props}
      // className={cx('Toolbar-input input--overridden-style', className)}
      className={cx('Toolbar-input', className)}
      icon={icon || 'search'}
      placeholder={placeholder || 'Search'}
    />
  );
}

export function RangePicker({ className, dateFormat, placeholder, ...props }) {
  return (
    <DatesRangeInput
      clearable
      animation=""
      duration={0}
      allowSameEndDate
      placeholder={placeholder || 'From request date - To request date'}
      dateFormat={dateFormat || 'DD/MM/YYYY'}
      {...props}
      className={cx('Toolbar-range-picker', className)}
    />
  );
}

export function DatePicker({ className, dateFormat, placeholder, ...props }) {
  return (
    <DateInput
      clearable
      animation=""
      duration={0}
      placeholder={placeholder || 'Date'}
      dateFormat={dateFormat || 'DD/MM/YYYY'}
      {...props}
      className={cx('Toolbar-range-picker', className)}
      // className={cx('Toolbar-range-picker input--overridden-style', className)}
    />
  );
}

export function SubmitButton({ className, children, type, ...props }) {
  type = type || 'submit';

  return (
    <Button
      {...props}
      type={type}
      className={cx(
        'button--overridden-style',
        { blue: type === 'submit', 'Toolbar-submit': type === 'submit' },
        className,
      )}
    >
      {children || 'Submit'}
    </Button>
  );
}

export function Filter({ className, ...props }) {
  return (
    <Dropdown
      selection
      text={`${props.label || 'Showing'} : ${props.value}`}
      {...props}
      className={cx('Toolbar-filter', className)}
    />
  );
}

export function SearchDropdown({ className, searchPlaceHolder, ...props }) {
  return (
    <Dropdown
      selection
      search
      text={props.value || searchPlaceHolder || `Search`}
      {...props}
      className={cx('Toolbar-filter', className)}
    />
  );
}

export function SearchDropdownSpecialist({ className, searchPlaceHolder, ...props }) {
  return (
    <Dropdown
      selection
      search
      text={props.value || searchPlaceHolder || `Select specialist`}
      {...props}
      className={cx('Toolbar-filter', className)}
    />
  );
}

export function HighLightButton({ className, children, ...props }) {
  return (
    <SubmitButton type="button" {...props} className={cx('Toolbar-highlight-button px-2', className)}>
      {children}
    </SubmitButton>
  );
}

export function FloatingDropdown({ className, groupName, groupIconName, children, ...props }) {
  // States
  const [active, setActive] = useState(false);

  // Dropdown Trigger
  groupName = groupName || 'Dropdown';
  groupIconName = groupIconName || 'add';
  // const trigger = <span><Icon name={groupIconName}/>{groupName}</span>;

  // Handle Events
  const handleActiveDropdown = () => {
    setActive(currentActive => !currentActive);
  };

  const handleCloseDropdown = () => {
    if (active) setActive(false);
  };

  return (
    <Button.Group icon className="positive">
      <Dropdown
        {...props}
        button
        floating
        text={groupName}
        className={cx('icon', className)}
        icon={null}
        // trigger={trigger}
        onClick={handleActiveDropdown}
        onClose={handleCloseDropdown}
      >
        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
      {active && <Button icon="close" onClick={handleCloseDropdown} />}
    </Button.Group>
  );
}

export function FloatingDropdownItem({ className, iconName, textDescription, href, ...props }) {
  iconName = iconName || 'circle';
  textDescription = textDescription || 'item';

  if (href) {
    return (
      <Dropdown.Item as={Link} to={`${href}`} {...props} className={className} icon={iconName} text={textDescription} />
    );
  } else {
    return <Dropdown.Item {...props} className={className} icon={iconName} text={textDescription} />;
  }
}
