import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import dom from '../../../stores/dom';
import Tooltip from '../../shared/Tooltip';

@observer
class FullscreenIcon extends React.Component {
  render() {
    const { isFullscreen, toggleFullscreen } = dom;
    return (
      <Tooltip appear={(isFullscreen ? 'Exit' : 'Enter') + ' full screen mode'}>
        <div onClick={toggleFullscreen} className="DashboardHeaderIcon r-0">
          <Icon name={isFullscreen ? 'compress' : 'expand'} fitted />
        </div>
      </Tooltip>
    );
  }
}

export default FullscreenIcon;
