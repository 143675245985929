import dashboardStore from '@stores/dashboard';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import moment from 'moment';
import TableToolbar from '../../shared/tableToolbar';
import MarketingCampaignStore from './store';
import * as api from '../../../stores/api';

const optionsStatus = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Planning',
    value: '0',
  },
  {
    text: 'In progress',
    value: '1',
  },
  {
    text: 'Completed',
    value: '2',
  },
  {
    text: 'Cancelled',
    value: '3',
  },
];

@observer
class MarketingCampaignToolbar extends React.Component {
  handleChange = (_, { name, value }) => {
    MarketingCampaignStore.setFieldsValue({ [name]: value });
  };
  handleChangeFilter = (_, { name, value }) => {
    MarketingCampaignStore.refetch({ [name]: value, curPage: 1 });
  };

  renderChannelOptions = () => {
    const { channels } = MarketingCampaignStore;
    const options = [{ value: 0, text: 'All' }];
    return channels && channels.itemList
      ? [
          ...options,
          ...toJS(channels.itemList).map(i => ({
            value: i.Id,
            text: i.Label,
          })),
        ]
      : [{ value: 0, text: 'All' }];
  };

  renderChannelOptionsText = value => {
    const { channels } = MarketingCampaignStore;
    const options = [{ value: 0, text: 'All' }];
    const options_ =
      channels && channels.itemList
        ? [
            ...options,
            ...toJS(channels.itemList).map(i => ({
              value: i.Id,
              text: i.Label,
            })),
          ]
        : [{ value: 0, text: 'All' }];
    return options_.filter(i => i.value === value).map(i => i.text)[0];
  };

  renderFilterOptions = type => {
    const { targetAudience, targetStatus } = MarketingCampaignStore;

    if (type === 'audience') {
      const options = [{ value: 0, text: 'All' }];
      return targetAudience && targetAudience.itemList
        ? [
            ...options,
            ...toJS(targetAudience.itemList).map(i => ({
              value: i.Id,
              text: i.Label,
            })),
          ]
        : [{ value: 0, text: 'All' }];
    } else if (type === 'MarketingStatus') {
      return optionsStatus.map(i => ({
        text: i.text,
        value: i.value,
      }));
    } else {
      const options = [{ value: 0, text: 'All' }];
      return targetStatus && targetStatus.itemList
        ? [
            ...options,
            ...toJS(targetStatus.itemList).map(i => ({
              value: i.Id,
              text: i.Label,
            })),
          ]
        : [{ value: 0, text: 'All' }];
    }
  };
  renderValueFilterText = (value, type) => {
    const { targetAudience, targetStatus } = MarketingCampaignStore;
    if (type === 'audience') {
      const options = [{ value: 0, text: 'All' }];
      const options_ =
        targetAudience && targetAudience.itemList
          ? [
              ...options,
              ...toJS(targetAudience.itemList).map(i => ({
                value: i.Id,
                text: i.Label,
              })),
            ]
          : [{ value: 0, text: 'All' }];
      return options_.filter(i => i.value === value).map(i => i.text)[0];
    } else if (type === 'MarketingStatus') {
      return optionsStatus.filter(i => i.value === value).map(i => i.text)[0];
    } else {
      const options = [{ value: 0, text: 'All' }];
      const options_ =
        targetStatus && targetStatus.itemList
          ? [
              ...options,
              ...toJS(targetStatus.itemList).map(i => ({
                value: i.Id,
                text: i.Label,
              })),
            ]
          : [{ value: 0, text: 'All' }];
      return options_.filter(i => i.value === value).map(i => i.text)[0];
    }
  };

  handleAdd = () => {
    localStorage.removeItem('CampaignName');
    if (window.__newui.enable) {
      dashboardStore.close('/view/add-edit-marketing-campaign');
      return setTimeout(() => {
        dashboardStore.open('/view/add-edit-marketing-campaign');
      });
    }
    MarketingCampaignStore.toggleModal(true, { modalType: 'addEdit', id: 0 })();
  };
  handleSubmit = e => {
    e.preventDefault();
    MarketingCampaignStore.refetch({});
  };

  handleExportSearch = () => {
    const { keyword, filterChannel, filterAudience, filterStatus, filterMarketingStatus } = MarketingCampaignStore;
    const { fromDate, toDate } = MarketingCampaignStore.getDateRange();
    var searchOption = {
      Keyword: keyword,
      From: moment(fromDate)
        .startOf('day')
        .toISOString(),
      To: moment(toDate)
        .endOf('day')
        .toISOString(),
      Channel: filterChannel,
      TargetAudience: filterAudience,
      TargetStatus: filterStatus,
      CampaignStatus: filterMarketingStatus,
    };
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    window.open('/ManagerCampaign/ExportSearchResult?' + qs, '_blank');
  };

  render() {
    const { keyword, loading } = MarketingCampaignStore;
    return (
      <React.Fragment>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input disabled={loading} value={keyword} onChange={this.handleChange} name="keyword" />
            <TableToolbar.RangePicker
              value={MarketingCampaignStore.fromTo}
              onChange={this.handleChange}
              name="fromTo"
              disabled={loading}
            />
            <TableToolbar.Filter
              disabled={loading}
              label="Channel"
              options={this.renderChannelOptions()}
              value={this.renderChannelOptionsText(MarketingCampaignStore.filterChannel)}
              onChange={this.handleChangeFilter}
              name="filterChannel"
            />
            <TableToolbar.Filter
              disabled={loading}
              label="Target Audience"
              options={this.renderFilterOptions('audience')}
              value={this.renderValueFilterText(MarketingCampaignStore.filterAudience, 'audience')}
              onChange={this.handleChangeFilter}
              name="filterAudience"
            />
            <TableToolbar.Filter
              disabled={loading}
              label="Target Status"
              options={this.renderFilterOptions()}
              value={this.renderValueFilterText(MarketingCampaignStore.filterStatus)}
              onChange={this.handleChangeFilter}
              name="filterStatus"
            />
            <TableToolbar.Filter
              disabled={loading}
              label="Status"
              options={this.renderFilterOptions('MarketingStatus')}
              value={this.renderValueFilterText(MarketingCampaignStore.filterMarketingStatus, 'MarketingStatus')}
              onChange={this.handleChangeFilter}
              name="filterMarketingStatus"
            />
          </TableToolbar.Left>
          <TableToolbar.Right>
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={MarketingCampaignStore.resetStore}
            >
              Clear
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              onClick={this.handleAdd}
              disabled={loading}
              className="positive"
              style={{ marginLeft: '5px' }}
            >
              Add Marketing Campaign
            </TableToolbar.HighLightButton>}
            <TableToolbar.HighLightButton
              onClick={this.handleExportSearch}
              disabled={loading}
              className="positive"
              style={{ marginLeft: '5px' }}
            >
              Export Searches
            </TableToolbar.HighLightButton>
          </TableToolbar.Right>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default MarketingCampaignToolbar;
