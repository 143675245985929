import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ModalAddEdit from './ModalAddEdit';
import ModuleStore from './ModuleStore';

function ModuleModal() {
  const { modalType } = ModuleStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ModuleStore} onCancel={ModuleStore.toggleModal(false)} />;
  }
  if (modalType === 'addOrEdit') {
    return <ModalAddEdit {...ModuleStore} onCancel={ModuleStore.toggleModal(false)} />;
  }
}

export default ModuleModal;
