import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ServiceFeeStore from './ServiceFeeStore';
import ModalCreate from './ModalCreate';
import ModalCreateEditServiceFee from './ModalCreatEditServiceFee';

function ServiceFeeModal() {
  const { modalType } = ServiceFeeStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
  if (modalType === 'createServiceFee') {
    return <ModalCreateEditServiceFee {...ServiceFeeStore} onCancel={ServiceFeeStore.toggleModal(false)} />;
  }
}

export default ServiceFeeModal;
