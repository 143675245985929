import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';

import * as api from '../../../stores/api';
import dashboardStore from '../../../stores/dashboard';
import router from '../../../stores/router';
import { messages, showUnexpectedError } from '../../../utils/errors';
import {
  buildFullUrl,
  isIframePath,
  pathDetailToPath,
  pathToPathDetail,
  pathToTab,
  tabConfig,
  tabToPathDetail,
} from './iframeMap';

import appStore from 'stores/app';

const tabObjs = [
  { tab: 'PostAssessment', key: 'AssessmentCaseId' },
  { tab: 'PostSupplementary', key: 'SupplementaryCaseId' },
  { tab: 'PostFileReview', key: 'FileReviewCaseId' },
  { tab: 'PostMedNeg', key: 'MedNegCaseId' },
  { tab: 'AddEditClinicalNote', key: 'ClinicalRecordCaseId' },
  { tab: 'PostMRI', key: 'ImagingCaseId' },
  { tab: 'ViewInvoice', key: 'InvoiceCaseId' },
  { tab: 'ViewCompany', key: 'CompanyName' },
  { tab: 'ViewCM', key: 'CmName' },
  { tab: 'ViewDoctor', key: 'DoctorName' },
  { tab: 'ViewClinic', key: 'ClinicName' },
  { tab: 'ViewClaimant', key: 'ClaimantName' },
];

function getUrlParam(url, key, fallbackValue) {
  if (!url.includes(key)) return fallbackValue;
  const newUrl = url.startsWith('http') ? url : new URL(`http://${url}`);
  const urlParams = Object.fromEntries(newUrl.searchParams);
  return urlParams[key] || fallbackValue;
}
const isDetail = (u, tab, data) =>
  ((u.indexOf('id=') > 0 && u.indexOf('id=0') < 0) || data?.id) && tab in tabToPathDetail;

class IframeStore {
  @observable loading = false;
  frameLoading = false; // To not bootstrap app twice
  f = null;

  @action createIframe = url => {
    if (this.f) {
      return false;
    }
    const cont = this.clear();
    this.loading = true;
    this.f = document.createElement('iframe');
    this.f.onload = this.onIframeLoad;
    this.f.src = url;
    cont.appendChild(this.f);
    return true;
  };

  clear = () => {
    this.f = null;
    const cont = document.getElementById('cirrus-iframe');
    while (cont.firstChild) {
      cont.removeChild(cont.firstChild);
    }
    return cont;
  };

  onIframeLoad = () => {
    if (!this.f) {
      return;
    }
    //
    const w = this.f.contentWindow;
    if (!w.renderPartialViewfunction) {
      runInAction(() => {
        this.loading = false;
      });
      this.clear();
      showUnexpectedError(messages.Unauthorized);
      return;
    }
    //
    const link = document.createElement('link');
    link.href = `${process.env.PUBLIC_URL}/override-old-cirrus.css`;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.id = 'override-old-cirrus-css';
    const head = w.document.getElementsByTagName('head')[0];
    head.appendChild(link);
    setTimeout(
      action(() => {
        this.loading = false;
      }),
      1000,
    );
    //
    const h = w.history;
    const oldPushState = h.pushState;
    const oldReplaceState = h.replaceState;
    h.pushState = (...args) => {
      setTimeout(() => this.onIframeHrefChange(), 17);
      return oldPushState.apply(h, args);
    };
    h.replaceState = (...args) => {
      setTimeout(() => this.onIframeHrefChange(), 17);
      return oldReplaceState.apply(h, args);
    };
    //
    const oldCloseTab = w.CloseTab;
    w.CloseTab = (...args) => {
      setTimeout(() => this.onIframeHrefChange(), 17);
      return oldCloseTab(...args);
    };
    //
    const oldShowLoading = w.ShowLoading;
    w.ShowLoading = isShow => {
      if (isShow === undefined || isShow) {
        this.frameLoading = true;
      } else {
        this.frameLoading = false;
      }
      return oldShowLoading(isShow);
    };
  };

  onIframeHrefChange = inInterval => {
    if (!this.f) {
      return;
    }
    //
    const tabs = [];
    let totalRemoved = 0;
    //
    const lis = this.f.contentWindow.document.querySelectorAll('#root-tab-bar li');

    Array.prototype.forEach.call(lis, li => {
      const a = li.querySelector('a');
      if (!a) {
        return;
      }
      const itab = a.id.replace('-Tab', '');
      if (itab in tabConfig) {
        //
        let { path } = tabConfig[itab];
        const fullUrl = a.getAttribute('data-full-url');
        if (isDetail(fullUrl, itab)) {
          path = tabToPathDetail[itab];
        }
        tabs.push({ path, absUrl: path });
        //
        const active = li.classList.contains('active');
        const currentTab = pathToTab[router.location.pathname];
        if (!inInterval && active && itab !== currentTab) {
          dashboardStore.open(path);
        }
      } else {
        a.querySelector('i').click();
        totalRemoved += 1;
      }
    });
    //
    const tabsMap = tabs.reduce((m, t) => {
      m[t.path] = t;
      return m;
    }, {});
    let filtered = dashboardStore.tabs.filter(t => {
      if (!isIframePath(t.path)) {
        return true;
      }
      if (tabsMap[t.path]) {
        delete tabsMap[t.path];
        return true;
      }
      return false;
    });
    filtered = filtered.concat(tabs.filter(t => tabsMap[t.path]));
    if (filtered.length === 0) {
      const dashboard = '/view/dashboard';
      filtered = [
        {
          tab: dashboard,
          absUrl: dashboard,
        },
      ];
    }
    //
    const prevPath = router.location.pathname;
    if (inInterval && dashboardStore.tabs.findIndex(t => t.path === prevPath) < 0) {
      if (prevPath in pathToPathDetail) {
        dashboardStore.open(pathToPathDetail[prevPath]);
      } else if (prevPath in pathDetailToPath) {
        dashboardStore.open(pathDetailToPath[prevPath]);
      }
    }
    if (!this.frameLoading && !this.loading) {
      runInAction(() => {
        dashboardStore.tabs = filtered;
      });
      if (!inInterval && totalRemoved === lis.length) {
        dashboardStore.open('/view/dashboard');
        this.clear();
      }
    }
  };

  forceOpenTab = (itab, query, data) => {
    if (!this._openTab(itab, query, data)) {
      return;
    }

    let path = tabConfig[itab].path;

    if (appStore.iframeMode) {
      window.parent.postMessage({
        action: 'OPEN_TAB',
        data: {
          pathname: path,
        },
      });
      return;
    }

    if (isDetail(query, itab, data)) {
      path = pathToPathDetail[path];
    } else if (path in pathDetailToPath) {
      path = pathDetailToPath[path];
    }

    dashboardStore.open(path);
  };

  openTab = (itab, query, data) => {
    if (!itab || this.loading) {
      return;
    }

    // Check if the tab is already opened
    //    then reopen it again
    if (this.f) {
      const { document: fDoc } = this.f.contentWindow;
      const qrSelector = fDoc.querySelector.bind(fDoc);
      const a = qrSelector(`#${itab}-Tab`);

      if (a) {
        const fullUrl = a.getAttribute('data-full-url');
        const caseId = +getUrlParam(fullUrl, 'id', 0);
        const tabName = a.querySelector('.ui-tab-name').textContent;

        for (let { itab, key } of tabObjs) {
          if (fullUrl.includes(itab)) {
            if (key !== 'ClinicalRecordCaseId' && key !== 'MedNegCaseId') {
              setTimeout(() => localStorage.setItem(key, !!caseId ? tabName : ''));
            }
            break;
          }
        }

        if (fullUrl.includes('PostFactualInvestigation')) {
          const fiTab = qrSelector('#fiClaimantDetail label.ng-binding');
          localStorage.setItem('FactualInvestigationCaseId', !!caseId ? `Case ${fiTab.textContent}` : '');
        }

        if (fullUrl.includes('AddOrEditJobTitle')) {
          const jobTitle = qrSelector('#AddOrEditJobTitle input[ng-model="itemModel.Label"]').value;
          localStorage.setItem('JobTitle', jobTitle || '');
        }

        if (fullUrl.includes('PostTypesOfClaims')) {
          const typeOfClaims = qrSelector('#PostTypesOfClaims input[ng-model="itemModel.Name"]').value;
          localStorage.setItem('TypeOfClaims', typeOfClaims || '');
        }

        if (fullUrl.includes('PostTypesOfReport')) {
          const typeOfReport = qrSelector('#PostTypesOfReport input[ng-model="itemModel.Name"]').value;
          localStorage.setItem('TypeOfReport', typeOfReport || '');
        }

        if (fullUrl.includes('AddOrEditIMSApprovalCodeWIRO')) {
          const imsApproval = qrSelector(
            '#AddOrEditIMSApprovalCodeWIRO input[ng-model="itemModel.PaymentClassificationCode"]',
          ).value;
          localStorage.setItem('ImsApproval', imsApproval || '');
        }

        if (fullUrl.includes('ManageCaseManagers') && !this.f.src.includes('companyId')) {
          localStorage.setItem('ManageCmName', '');
        }

        a.click();
        this.showIframe();
        return;
      }
    }
    //
    this._openTab(itab, query, data);
  };

  _openTab = (itab, query, data) => {
    this.showIframe();
    //
    const c = tabConfig[itab];
    if (c.authorize && !c.authorize()) {
      this.hideIframe();
      return false;
    }
    //
    const fullUrl = buildFullUrl(itab, query);
    if (this.createIframe(fullUrl) || this.loading || this.frameLoading) {
      return true;
    }

    //
    const fn = this.f.contentWindow.renderPartialViewfunction;
    if (!fn) {
      showUnexpectedError(messages.Unauthorized);
      return false;
    }
    //
    const obj = data ? { fullUrl, data } : {};
    let partialView = c.partialView;

    if (itab === 'ManageFileReview' && (api.isAdminOrMagStaffOrAccounting() || api.isTypist())) {
      partialView = '_RenderedView';
    }

    fn(c.controller, partialView, itab, obj);
    return true;
  };

  hideIframe = () => {
    if (!this.f) {
      return;
    }
    this.f.classList.add('visibilityHidden');
  };

  showIframe = () => {
    if (!this.f) {
      return;
    }
    this.f.classList.remove('visibilityHidden');
  };
}

const iframe = new IframeStore();
setInterval(() => iframe.onIframeHrefChange(true), 1000);

const viewNameToUrl = viewName => {
  switch (viewName) {
    case 'PostFileReview':
      return '/view/add-edit-file-review-2';
    case 'AddEditClinicalNote':
      return '/view/add-edit-clinical-notes-2';
    case 'PostAssessment':
      return '/view/add-edit-ime-assessment-2';
    default:
      return viewName;
  }
};

window.openTabNewUI = (isNewUI = false, viewNameOrURL, id, caseNo, stogrageName) => {
  if (isNewUI) {
    localStorage.setItem(stogrageName, caseNo);
    dashboardStore.close(viewNameToUrl(viewNameOrURL));
    if (!!id) {
      return setTimeout(() => {
        dashboardStore.open(`${viewNameToUrl(viewNameOrURL)}?id=${id}`);
      });
    } else {
      localStorage.removeItem(stogrageName);
      return setTimeout(() => {
        dashboardStore.open(`${viewNameToUrl(viewNameOrURL)}?id=${0}`);
      });
    }
  } else {
    if (viewNameOrURL && caseNo && id) {
      localStorage.setItem(stogrageName, caseNo);
      return iframe.forceOpenTab(viewNameOrURL, `?id=${id}&view=${viewNameOrURL}`, {
        id,
        view: viewNameOrURL,
      });
    } else {
      localStorage.removeItem(stogrageName);
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    }
  }
};

// Hook dashboard close with iframe close
const oldDashboardClose = dashboardStore.close;
dashboardStore.close = path => {
  oldDashboardClose(path);
  if (!iframe.f) {
    return;
  }
  if (!dashboardStore.tabs.some(t => isIframePath(t.path))) {
    iframe.f = null;
  } else if (isIframePath(path)) {
    const i = iframe.f.contentWindow.document.querySelector(`#${pathToTab[path]}-Tab i`);
    if (i) {
      i.click();
    }
  }
};

export default iframe;
