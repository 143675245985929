import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Grid, Form, Segment, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import * as api from '@stores/api';
import iframe from '../../../dashboard-routes/Iframe/iframeData';
import DoctorDetailsStore from './DoctorDetailsStore';
import { Upload, File, NoFile } from './Upload';
import './DoctorDetails.scss';

@observer
class RecruitmentLoginInformationTab extends React.Component {
  renderClientType = type => {
    switch (type) {
      case 0:
        return 'MAG Ultimate';
      case 1:
        return 'MAG Direct-Essential';
      case 2:
        return 'MAG Direct-Extra';
      default:
        return 'None';
    }
  };

  @action uploadContract = data => {
    const contract = data.fileList[0];
    if (contract) {
      DoctorDetailsStore.dataDoctor.ContractAttachmentDisplayName = contract.name;
      DoctorDetailsStore.dataDoctor.ContractAttachmentPhysicalFileName = contract.title;
    }
  };

  @action removeContract = () => {
    DoctorDetailsStore.dataDoctor.ContractAttachmentDisplayName = null;
    DoctorDetailsStore.dataDoctor.ContractAttachmentPhysicalFileName = null;
  };

  openClinicCalendar = () => {
    const { dataDoctor } = DoctorDetailsStore;
    iframe.forceOpenTab('ManageCalendar', `?doctorId=${dataDoctor.Id}&view=ManageCalendar`, {
      doctorId: dataDoctor.Id,
      view: 'ManageCalendar',
    });
  };

  render() {
    const { isEditing, dataDoctor, setFieldValue } = DoctorDetailsStore;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Recruitment Information</label>
                  </Form.Field>
                </Form.Group>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Field inline>
                    <label className="Form-Field-label">Service Type: </label>
                    {!isEditing ? (
                      <span>{this.renderClientType(dataDoctor.ClientType)}</span>
                    ) : (
                      <Dropdown
                        selection
                        name="ClientType"
                        disabled
                        options={[
                          {
                            value: 0,
                            text: 'MAG Ultimate',
                          },
                          {
                            value: 1,
                            text: 'MAG Direct-Essential',
                          },
                          {
                            value: 2,
                            text: 'MAG Direct-Extra',
                          },
                        ]}
                        value={dataDoctor.ClientType}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Onboarded Date (Contract Date)</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.ContractWithMagDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="ContractWithMagDate"
                        animation=""
                        duration={0}
                        disabled
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ContractWithMagDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Contract End Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.ContractWithMagEndDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="ContractWithMagEndDate"
                        animation=""
                        duration={0}
                        disabled
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ContractWithMagEndDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field width="8">
                  <label className="Form-Field-label">Attach Contract</label>
                  {!isEditing ? (
                    dataDoctor.ContractAttachmentPhysicalFileName === null ? (
                      <NoFile />
                    ) : (
                      <File
                        name={dataDoctor.ContractAttachmentDisplayName}
                        downloadLink={`/UserFile/DoctorContract/${dataDoctor.ContractAttachmentPhysicalFileName}`}
                      />
                    )
                  ) : dataDoctor.ContractAttachmentPhysicalFileName === null ? (
                    <Upload onComplete={this.uploadContract} placeholder="Upload your contract" />
                  ) : (
                    <File
                      name={dataDoctor.ContractAttachmentDisplayName}
                      downloadLink={`/UserFile/DoctorContract/${dataDoctor.ContractAttachmentPhysicalFileName}`}
                      // onRemove={this.removeContract}
                    />
                  )}
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
          {this.props.children}
        </Grid.Row>
      </Grid>
    );
  }
}
export default RecruitmentLoginInformationTab;
