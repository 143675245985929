import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

function TableHeader({ columns, sortColumn, sortDirection, onSort }) {
  const handleSort = col => () => {
    const sortObj = {
      column: col.title,
      direction: sortColumn === col.title ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'descending',
    };
    onSort && onSort(col, sortObj);
  };
  return (
    <Table.Header>
      <Table.Row>
        {columns.map((col, idx) => {
          const { title, sortKey } = col;

          return (
            <Table.HeaderCell
              key={title || idx}
              className={cx({ 'cursor-default': !sortKey })}
              sorted={sortKey && title === sortColumn ? sortDirection : null}
              onClick={sortKey ? handleSort(col) : null}
              style={col.style}
              textAlign={col.align}
            >
              {title}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  onSort: PropTypes.func,
};

export default TableHeader;
