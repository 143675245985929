import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import AddEditStore from './store';
import Modal from '../../shared/Modal';
import { TableHeader } from '../../shared/table';
import { toJS } from 'mobx';

const columns = [{ title: 'Full Name' }, { title: 'Email' }, { title: 'Client' }];

@observer
class ModalDuplicate extends React.Component {
  renderModalTitle = dataDuplicate => {
    if (dataDuplicate) {
      if (!dataDuplicate.length) {
        return 'Duplicate message';
      }
      return 'List of similar case manager';
    }
    return 'Duplicate message';
  };

  renderModalBody = dataDuplicate => {
    if (Array.isArray(dataDuplicate)) {
      if (dataDuplicate.length === 0) {
        return 'No similar case managers are found';
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataDuplicate.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{record.FullName}</Table.Cell>
                <Table.Cell>{record.Email}</Table.Cell>
                <Table.Cell>{record.ClientName}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return 'No similar case manager are found';
    }
  };

  render() {
    const { open } = AddEditStore;
    const { dataDuplicate } = AddEditStore.modalParams;
    return (
      <Modal
        visible={open}
        width={700}
        onCancel={AddEditStore.toggleModal(false)}
        title={this.renderModalTitle(dataDuplicate)}
      >
        {this.renderModalBody(toJS(dataDuplicate))}
      </Modal>
    );
  }
}

export default ModalDuplicate;
