import React from 'react';

import { observer } from 'mobx-react';
import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ModalAddEdit from './ModalAddEdit';
import store from './store';
import ModalExport from './ModalExport';

function CalendarModal() {
  const { modalType, hideWarningIcon = false, onOk = null } = store.modalParams;

  const footerCloseButton = !onOk;

  if (modalType === 'confirm') {
    return (
      <ModalConfirm
        {...store}
        onCancel={store.toggleModal(false)}
        footerCloseButton={footerCloseButton}
        hideWarningIcon={hideWarningIcon}
      />
    );
  }
  if (modalType === 'editSession') {
    return <ModalAddEdit {...store} onCancel={store.toggleModal(false)} />;
  }
  if (modalType === 'export') {
    return <ModalExport />;
  }
}

export default observer(CalendarModal);
