import { Spin } from 'antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Icon } from 'semantic-ui-react';

import { getNewClient, getNewClinic, getNewSpecialist } from './service';

const ACCORDION_ITEM = ['New Specialists Onboard', 'New Clients', 'New Clinics'];

class NavigateSection extends React.Component {
  state = {
    loading: true,
    active: [],
    dataClinic: null,
    dataClient: null,
    dataSpecialist: null,
    totalClinic: 0,
    totalSpecialist: 0,
    totalClient: 0,
  };

  componentDidMount() {
    Promise.all([getNewClient(), getNewClinic(), getNewSpecialist()])
      .then(([newClient, newClinic, newSpecialist]) => {
        this.setState({
          dataClient: newClient,
          loading: false,
          active: [],
          dataClinic: newClinic,
          dataSpecialist: newSpecialist,
          totalClient: newClient.sumItem,
          totalClinic: newClinic.sumItem,
          totalSpecialist: newSpecialist.sumItem,
        });
      })
      .catch(() => {
        this.setState({
          dataClient: null,
          loading: false,
          active: [],
          dataClinic: null,
          dataSpecialist: null,
          totalClinic: 0,
          totalSpecialist: 0,
          totalClient: 0,
        });
      });
  }

  handleClick = idx => {
    const { active } = this.state;

    if (active.includes(idx)) {
      this.setState({ active: active.filter(i => i !== idx) });
    } else {
      this.setState({ active: [...active, idx] });
    }
  };

  renderAccordionContent = idx => {
    const { dataSpecialist, dataClient, dataClinic } = this.state;
    const data = idx === 0 ? dataSpecialist.data : idx === 1 ? dataClient.data : dataClinic.data;
    return (
      <Accordion.Content active={this.state.active.includes(idx)}>
        {data.map((i, index) => this.renderContent(i, idx, index))}
      </Accordion.Content>
    );
  };

  renderContent = (data, key, index) => {
    if (key === 0) {
      return (
        <div key={index} className="content-item">
          <span className="" title={data.title}>
            {`${data.Title} ${data.FullName}${data.Specialty ? ` - ${data.Specialty}` : ''}${
              data.CreatedBy ? ` - By ${data.CreatedBy}` : ''
            }`}
          </span>
        </div>
      );
    } else if (key === 1) {
      return (
        <div key={index} className="content-item">
          <span className="" title={data.title}>
            {`${data.ClientName}${data.ServiceType ? ` - ${data.ServiceType}` : ''}${
              data.CreatedBy ? ` - By ${data.CreatedBy}` : ''
            }`}
          </span>
        </div>
      );
    } else if (key === 2) {
      return (
        <div key={index} className="content-item">
          <span className="" title={data.title}>
            {`${data.ClinicName}${data.State ? ` - ${data.State}` : ''}${
              data.CreatedBy ? ` - By ${data.CreatedBy}` : ''
            }`}
          </span>
        </div>
      );
    }
    return null;
  };

  renderAccordion = () => {
    return (
      <Accordion>
        {ACCORDION_ITEM.map((i, idx) => (
          <React.Fragment key={idx}>
            <Accordion.Title index={idx} active={this.state.active.includes(idx)} onClick={() => this.handleClick(idx)}>
              <div>
                <Icon name="dropdown" />
                {`${i} (${
                  idx === 0 ? this.state.totalSpecialist : idx === 1 ? this.state.totalClient : this.state.totalClinic
                })`}
              </div>
              <Link
                to={
                  idx === 0
                    ? '/view/specialists-2?sortBy=Onboarded&sortDirection=descending'
                    : idx === 1
                    ? '/view/clients-2?sortBy=Created&sortDirection=descending'
                    : '/view/medical-centres-2?sortBy=Created&sortDirection=descending'
                }
              >
                View
              </Link>
            </Accordion.Title>
            {this.renderAccordionContent(idx)}
          </React.Fragment>
        ))}
      </Accordion>
    );
  };
  renderEmptyMessage = () => {
    return (
      <div className="section-empty-message" key="empty-message">
        Empty
      </div>
    );
  };
  render() {
    return (
      <Fragment>
        <section className="action-required-section">
          <h3 className="section-title">{"today's new specialists, clients, clinics".toUpperCase()}</h3>
          {this.state.loading ? <Spin /> : this.renderAccordion()}
        </section>
      </Fragment>
    );
  }
}

export default NavigateSection;
