import { action, observable, toJS } from 'mobx';
import customFetch from '@utils/customFetch';
import { BaseProfileUploadStore } from '../../shared/ProfileUpload';
import { getActionRequired } from './service';
import * as api from '@stores/api';

class PersonalDetailStore extends BaseProfileUploadStore {
  constructor() {
    super();
    this.toggleModal = this.toggleModal.bind(this);
  }

  @observable Id = '';
  @observable isEditing = false;
  @observable modalParams = {};
  @observable actionRequiredList = [];
  @observable openModalAction = false;
  @observable campaignMergeAttachments = [];
  @observable actionRequiredCount = 0;
  @observable activeIndex = 0;

  @action changeField = (fieldName, value) => {
    this[fieldName] = value;
  };

  refetchActionRequired = () => {
    this.countActionRequiredIncomplete();
    getActionRequired(this.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };

  countActionRequiredIncomplete = () => {
    customFetch(`/Account/ActionRequired_CountIncompleteTask?staffId=${api.currentUser.data.id}`).then(
      action(res => {
        if (res.status === 'success') {
          this.actionRequiredCount = res.count;
        }
      }),
    );
  };

  @action toggleModalAction = isOpen => {
    this.openModalAction = isOpen;
  };

  @action setListActionRequired = data => {
    this.actionRequiredList = data;
  };

  toggleModal(isOpen, params = {}) {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  }

  @action setIsEditing = value => {
    if (value) {
      this.oldProfilePictureAttachments = toJS(this.profilePictureAttachments);
    } else {
      if (!this.Id) {
        this.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => window.closeCurrentTab(),
          message: `Any changes you haven't save will be lost. Are you sure to not save changes?`,
        })();
        return;
      } else {
        this.profilePictureAttachments = toJS(this.oldProfilePictureAttachments);
      }
    }
    this.isEditing = value;
  };
}

export default new PersonalDetailStore();
