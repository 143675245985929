import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import ClaimantsStore from './ClaimantsStore';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import moment from 'moment';

const columnsA = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsF = [
  { title: 'Case No.' },
  { title: 'Claimant Name' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsS = [
  { title: 'Case No.' },
  { title: 'Claimant Name' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];
@observer
class TableHistoryModal extends React.Component {
  componentDidMount() {
    ClaimantsStore.fetchHistory(true);
  }

  openCaseDetail = (caseNo, id) => () => {
    const type = caseNo.slice(0, 1);
    const tabDetail = type === 'A' ? 'PostAssessment' : type === 'F' ? 'PostFileReview' : 'PostSupplementary';
    const storageName = type === 'A' ? 'AssessmentCaseId' : type === 'F' ? 'FileReviewCaseId' : 'SupplementaryCaseId';
    localStorage.setItem(`${storageName}`, `Case ${caseNo}`);
    iframe.forceOpenTab(`${tabDetail}`, `?id=${id}&view=${tabDetail}`, {
      id,
      view: `${tabDetail}`,
    });
  };

  renderTableBodyA = () => {
    return ClaimantsStore.dataHistory.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.AssessmentNumber, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.AssessmentNumber, Id)}>
            {record.AssessmentNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.Doctor}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyF = () => {
    return ClaimantsStore.dataHistory.fileReviews.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.FileReviewNumber, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.FileReviewNumber, Id)}>
            {record.FileReviewNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Claimant}</Table.Cell>
        <Table.Cell>{record.DoctorName}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyS = () => {
    return ClaimantsStore.dataHistory.supps.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.SuppNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.SuppNo, Id)}>
            {record.SuppNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Claimant}</Table.Cell>
        <Table.Cell>{record.DoctorName}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loadingHistory, dataHistory, toggleModal, open } = ClaimantsStore;
    const colSpanA = columnsA.length;
    const colSpanF = columnsF.length;
    const colSpanS = columnsS.length;
    const { record } = this.props.modalParams;

    const renderTabpane = [
      {
        menuItem: 'IME Reports',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsA} />
              <Table.Body>
                {dataHistory.itemList.length ? this.renderTableBodyA() : <TableEmptyMessage colSpan={colSpanA} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'File Reviews',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsF} />
              <Table.Body>
                {dataHistory.fileReviews.length ? this.renderTableBodyF() : <TableEmptyMessage colSpan={colSpanF} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Supplementary Reports',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsF} />
              <Table.Body>
                {dataHistory.supps.length ? this.renderTableBodyS() : <TableEmptyMessage colSpan={colSpanS} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Modal
          width="900px"
          visible={open}
          onCancel={toggleModal(false)}
          title={`Assesment History for ${record.FirstName} ${record.LastName}`}
        >
          {loadingHistory && !dataHistory ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Tab menu={{ pointing: true }} panes={renderTabpane} />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default TableHistoryModal;
