import './SelectSearch.scss';

import cx from 'classnames';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

function SelectSearch({ className, ...props }) {
  return (
    <Dropdown
      className={cx(className, 'input--overridden-style')}
      fluid
      search
      selection
      clearable
      selectOnBlur={false}
      selectOnNavigation={false}
      {...props}
    />
  );
}

export default SelectSearch;
