import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import router from '@stores/router';
import Modal from './Modal';
import Store from './Store';
import MainTable from './MainTable';
import Toolbar from './Toolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class TalkingPoint extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/talking-point', true);
      return;
    }

    const { clientId = 0 } = this.getRouteParams();
    console.log(clientId);
    if(clientId){
      Store.setFieldsValue({clientId: clientId});
    }
    Store.fetchAll(true);
    Store.loadStates();
    Store.loadCategories();
  }
  render() {
    const { data, loading, open } = Store;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            <MainTable />
            {open && <Modal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default observer(TalkingPoint);
