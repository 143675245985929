import React from 'react';
import { Form, Spin } from 'antd';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import AddEditStore from './Store';
// import { uploadFile } from './service';

export const Upload = ({
  multiple = false,
  placeholder,
  reportType,
  attachments,
  disabled = false,
  functionCallback,
  disabledDelete,
  uploadTitle,
  disableDragDrop,
}) => {
  const handleOpenModalUpload = () => {
    AddEditStore.toggleModal(true, {
      modalType: 'upload',
      reportType,
      multiple,
      attachments,
      placeholder,
      disabled,
      functionCallback,
      disabledDelete,
      uploadTitle,
      disableDragDrop,
    })();
  };
  const styleBtnDownload = attachments => {
    return !!attachments.length ? { cursor: 'pointer', width: 150 } : { cursor: 'not-allowed', width: 150 };
  };
  const handleDownloadFiles = () => {
    return window.open(
      `/MedicalService/ExportPaperworkAttachToZip?service=A&itemId=${AddEditStore.assessmentInfo.Id}&type=${reportType}`,
    );
  };
  return (
    <div className="Upload-File">
      <label
        className="Button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleOpenModalUpload();
        }}
      >
        <span>{disabled ? 'View' : 'Upload/View'}</span>
      </label>
      <label
        className="Button"
        style={styleBtnDownload(attachments)}
        onClick={() => {
          !!attachments.length && handleDownloadFiles();
        }}
      >
        <Icon name="download" />
        <span>Download All</span>
      </label>
      <p>{placeholder}</p>
    </div>
  );
};

@observer
class FiledUpload extends React.Component {
  render() {
    const {
      uploadLoading,
      // attachments,
      label,
      multiUpload,
      reportType,
      className,
      required,
      disabled,
      functionCallback,
      disabledDelete,
      uploadTitle,
      disableDragDrop,
    } = this.props;

    const attachments = AddEditStore.assessmentInfo.AssessmentAttachment.filter(
      ({ ReportType }) => ReportType === reportType,
    );
    const placeholder =
      attachments && !!attachments.length
        ? `${attachments.length} ${attachments.length > 1 ? 'attachments' : 'attachment'} ${
            attachments.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;
    return (
      <Form.Item
        label={label}
        className={className || 'add-file-container'}
        style={{ marginBottom: '-10px' }}
        required={required}
      >
        <React.Fragment>
          {uploadLoading ? (
            <Spin />
          ) : (
            <Upload
              placeholder={placeholder}
              multiple={multiUpload}
              reportType={reportType}
              attachments={attachments}
              disabled={disabled}
              functionCallback={functionCallback}
              disabledDelete={disabledDelete}
              uploadTitle={uploadTitle}
              disableDragDrop={disableDragDrop}
            />
          )}
        </React.Fragment>
      </Form.Item>
    );
  }
}

export default FiledUpload;
