import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import LoginUserModal from './LoginUserModal';
import LoginUserStore from './LoginUserStore';
import LoginUserTable from './LoginUserTable';
import LoginUserToolbar from './LoginUserToolbar';
import ui from '../../../stores/dashboard';

class LoginUser extends React.Component {
  componentDidMount() {
    LoginUserStore.fetchLoginUser(true);
    ui.on('open(/view/login-users-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    LoginUserStore.fetchLoginUser(true);
  }, 500);

  render() {
    const { loading, data, open } = LoginUserStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <LoginUserToolbar />
            <LoginUserTable />
            {open && <LoginUserModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(LoginUser);
