import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import moment from 'moment';

import SalesCallReportStore from './SalesCallReportStore';
import TableToolbar from '../../shared/tableToolbar';
import { ActivityOutcomeOptions, ActivityTypeOptions, CCServicesType } from '@utils/constants';
import _ from 'lodash';

@observer
class SalesCallReportToolbar extends React.Component {
  onChangeDateRange = (_, { value }) => {
    action(() => {
      SalesCallReportStore.dateRange = value;
    })();

    // SalesCallReportStore.refetch(
    //   {
    //     dateRange: value,
    //   },
    //   true,
    // );
  };
  renderServiceType = () => {
    let array = [...CCServicesType];
    array.unshift({
      Id: '',
      Name: 'None',
    });
    return array.map(i => ({
      value: i.Id,
      text: i.Name,
    }));
  };
  renderClientOfficer = () => {
    let array = _.sortBy(toJS(SalesCallReportStore.dataClient?.itemList) ?? [], 'FullName');
    array.unshift({
      Id: '',
      FullName: 'All',
      Count: SalesCallReportStore.totalItem,
    });
    return array.map(i => ({
      value: i.Id,
      text: `${i.FullName} ${i.Count ? '(' + i.Count + ')' : ''}`,
    }));
  };
  renderOfficer = () => {
    let array = toJS(SalesCallReportStore.dataOfficer?.itemList) ?? [];
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.map(i => ({
      value: i.Id,
      text: `${i.FullName} ${i.Count ? '(' + i.Count + ')' : ''}`,
    }));
  };
  renderCampaign = () => {
    let array = toJS(SalesCallReportStore.dataCampaign?.listItem) ?? [];
    array.unshift({
      Id: '',
      CampaignName: 'All',
    });
    return array.map(i => ({
      value: i.Id,
      text: `${i.CampaignName} ${i.Count ? '(' + i.Count + ')' : ''}`,
    }));
  };
  handleFilterServiceType = (_, { value, name }) => {
    SalesCallReportStore.refetch(
      {
        serviceTypeId: value !== '' ? value : '',
        curPage: 0,
      },
      true,
    );
  }
  handleFilterClient = (_, { value, name }) => {
    if (name === 'clientOfficer') {
      SalesCallReportStore.refetch(
        {
          staffId: value ? value : '',
          curPage: 0,
        },
        true,
      );
    }
    if (name === 'callById') {
      SalesCallReportStore.refetch(
        {
          callById: value ? value : '',
          curPage: 0,
        },
        true,
      );
    }
    if (name === 'campaign') {
      SalesCallReportStore.refetch(
        {
          campaignId: value ? value : '',
        },
        true,
      );
    }

    if (name === 'activityType') {
      SalesCallReportStore.refetch(
        {
          activityType: value,
        },
        true,
      );
    }

    if (name === 'activityOutcome') {
      SalesCallReportStore.refetch(
        {
          activityOutcome: value,
        },
        true,
      );
    }
  };
  renderServiceTypeText = value => {
    let array = [...CCServicesType];
    array.unshift({
      Id: '',
      Name: 'None',
    });
    return array.filter(i => i.Id === value).map(i => i.Name)[0];
  };
  renderClientOfficerText = value => {
    let array = toJS(SalesCallReportStore.dataClient?.itemList || []);
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderOfficerText = value => {
    let array = toJS(SalesCallReportStore.dataOfficer?.itemList || []);
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderCampaignText = value => {
    let array = toJS(SalesCallReportStore.dataCampaign?.listItem || []);
    array.unshift({
      Id: '',
      CampaignName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.CampaignName)[0];
  };

  renderActivityTypeText = value => {
    return ActivityTypeOptions.find(i => i.value === value)?.text;
  };

  renderActivityOutcomeText = value => {
    return ActivityOutcomeOptions.find(i => i.value === value)?.text;
  };
  renderOptDropdown = () => {
    const options = SalesCallReportStore.dataStaff;
    return options.map((i, idx) => ({
      value: i.Id,
      text: `${i.FirstName} ${i.LastName} - ${i.Email}`,
      key: idx,
    }));
  };
  handleSearchChange = (_, { searchQuery }) => {
    SalesCallReportStore.refetchSearch({ keywordSearch: searchQuery });
  };
  handleChangeDropdown = (_, { value }) => {
    SalesCallReportStore.refetch(
      {
        id: value,
        keywordSearch: '',
        curPage: 0,
      },
      true,
    );
  };
  handleScroll = event => {
    const target = event.target;
    const number = SalesCallReportStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      SalesCallReportStore.refetchSearch({ numberSearch: number + 20 });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    SalesCallReportStore.fetchReport(true, true);
  };
  handleRefresh = () => {
    SalesCallReportStore.refetch({ data: null }, true);
  };

  handleClear = () => {
    SalesCallReportStore.resetStore();
  };
  handleExportSalesCall = () => {
    const { fromDate, toDate } = SalesCallReportStore.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const searchResult = SalesCallReportStore.data.itemList;
    if (searchResult) {
      if (searchResult.length !== 0) {
        window.open(
          `/Staff/ExportSalesCall?fromDate=${from}&toDate=${to}&cmEmail=${SalesCallReportStore.cmEmail}&cmName=${
            SalesCallReportStore.cmName
          }&sortBy=${SalesCallReportStore.sortKey}&callById=${
            SalesCallReportStore.callById
          }&sortDirection=${SalesCallReportStore.sortDirection.replace('ending', '').toUpperCase()}&staffId=${
            SalesCallReportStore.staffId
          }&curPage=${SalesCallReportStore.curPage}&numItemPerPage=${SalesCallReportStore.pageSize}`,
          '_blank',
        );
      } else {
        SalesCallReportStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Error in export result. Data does not exist!',
        })();
      }
    } else {
      SalesCallReportStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Error in export result. Data does not exist!',
      })();
    }
  };
  render() {
    const {
      dateRange,
      loadingStaff,
      keywordSearch,
      loading,
      loadingClient,
      staffId,
      serviceTypeId,
      callById,
      totalItem,
      searchValue,
      campaignId,
      activityType,
      activityOutcome,
    } = SalesCallReportStore;
    return (
      <form>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <TableToolbar.SearchDropdown
              loading={loadingStaff}
              searchQuery={keywordSearch}
              closeOnChange
              onScroll={this.handleScroll}
              allowAdditions
              additionLabel=""
              selectOnNavigation={false}
              className="Toolbar-input"
              disabled={loadingStaff}
              onSearchChange={this.handleSearchChange}
              options={this.renderOptDropdown()}
              value={searchValue ? `${searchValue.FirstName} ${searchValue.LastName} - ${searchValue.Email}` : ''}
              onChange={this.handleChangeDropdown}
              clearable
              icon={!searchValue ? <Icon name="dropdown" /> : <Icon name="delete" />}
            />
            <TableToolbar.RangePicker
              placeholder="From Activity Date - To Date"
              value={dateRange}
              onChange={this.onChangeDateRange}
            />
            <TableToolbar.SubmitButton className="px-2" onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleClear}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefresh}
            >
              Refresh
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.handleExportSalesCall}
              style={{ marginLeft: '5px' }}
            >
              Export Relationship Activities
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <span style={{ marginRight: `10px` }} className="nowrap">
              <p>
                <b>Total Cases: {totalItem}</b>
              </p>
            </span>
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={serviceTypeId}
              name="callById"
              options={this.renderServiceType()}
              text={`Service Type: ${this.renderServiceTypeText(serviceTypeId)}`}
              onChange={this.handleFilterServiceType}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={callById}
              name="callById"
              options={this.renderClientOfficer()}
              text={`Activity PIC: ${this.renderClientOfficerText(callById)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={staffId}
              name="clientOfficer"
              options={this.renderOfficer()}
              text={`Account Manager: ${this.renderOfficerText(staffId)}`}
              onChange={this.handleFilterClient}
            />
            {/* <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={campaignId}
              name="campaign"
              options={this.renderCampaign()}
              text={`Campaign: ${this.renderCampaignText(campaignId)}`}
              onChange={this.handleFilterClient}
            /> */}
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={activityType}
              name="activityType"
              options={ActivityTypeOptions}
              text={`Activity Type: ${this.renderActivityTypeText(activityType)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={activityOutcome}
              name="activityOutcome"
              options={ActivityOutcomeOptions}
              text={`Activity Outcome: ${this.renderActivityOutcomeText(activityOutcome)}`}
              onChange={this.handleFilterClient}
            />
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
      </form>
    );
  }
}

export default SalesCallReportToolbar;
