import customFetch from '../../../utils/customFetch';

export const getStaffInfo = id => {
  return customFetch('/Manager/GetUserById', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: id }),
  });
};

export const saveInfo = body => {
  return customFetch('/Manager/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ modUserParam: body }),
  });
};

export const getActionRequired = ItemId => {
  return customFetch('/Manager/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ItemId }),
  });
};
