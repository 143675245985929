import dashboardStore from '@stores/dashboard';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
// import { Link } from 'react-router-dom';
import { Checkbox, Icon } from 'semantic-ui-react';
import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import IMEStore from './IMEStore';
import ModalAlert from './ModalAlert';
import appStore from 'stores/app';

const IMEType = [
  { text: 'All', value: 'All' },
  { text: 'In-person', value: 'In-person' },
  { text: 'Video', value: 'Video' },
];

const IterpreterType = [
  { text: 'All', value: 'All' },
  { text: 'Provider Confirmed', value: 'Yes' },
  { text: 'Provider Not Confirmed', value: 'No' },
];

@observer
class IMEToolbar extends React.Component {
  state = {
    openModalAlert: false,
    message: `You need to search or filter first before exporting results.`,
    checkedClaimtOnly: true,
  };

  openModal = options => {
    return IMEStore.toggleModal(true, options);
  };

  openCaseCreateSmartSearch = () => () => {
    var win = window.open('/view/smart-search', '_blank');
    win.focus();
  };

  openCaseCreateClinicCalendar = () => () => {
    if (appStore.iframeMode) {
      window.parent.postMessage({ action: 'OPEN_CALENDAR' }, '*');
      return;
    }

    dashboardStore.close('/view/calendar-2');
    setTimeout(() => {
      dashboardStore.open('/view/calendar-2');
    });
  };

  openCaseCreateDirect = () => () => {
    localStorage.setItem('AssessmentCaseId', 'Book Assessment');
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2`);
    });
  };

  openSmartSearchView = () => {
    if (appStore.iframeMode) {
      window.parent.postMessage({ action: 'OPEN_SEARCH_SESSION' }, '*');
      return;
    }

    dashboardStore.close('/view/smart-search');
    dashboardStore.open(`/view/smart-search`);
  };

  renderPICOptions = () => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      const array = [];
      const data = toJS(IMEStore.dataStaff?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: 'All', FullName: `All`, Count: 0 });
      return array.map(opt => {
        if (opt.FullName === 'All') {
          return {
            text: opt.FullName,
            value: opt.Id,
          };
        } else {
          return {
            text: `${opt.FullName} (${opt.Count})`,
            value: opt.Id,
          };
        }
      });
    }
    return [];
  };

  renderPICText = value => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      if (value === 'All') {
        return 'All';
      } else {
        const array = [];
        const data = toJS(IMEStore.dataStaff?.itemList || []);
        for (var i = 0; i < data.length; i++) {
          array.push(data[i]);
        }
        array.unshift({ Id: 'All', FullName: `All`, Count: 0 });
        let labels = array
          .filter(opt => opt.Id === value)
          .map(opt => {
            if (opt.FullName === 'All') {
              return opt.FullName;
            } else {
              return `${opt.FullName} (${opt.Count})`;
            }
          });
        return labels[0];
      }
    }
    return `All`;
  };

  handleFilterPIC = (_, { value }) => {
    IMEStore.setFieldsValue({ FilterStaff: value });
  };

  notInTaskCM = [`Old-Billed`, api.imeStatus.FULLY_PAID];

  renderCaseOptions = () => {
    const array = [];
    const data = toJS(IMEStore.dataCase?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      if (api.isAdminOrMagStaffOrAccounting()) {
        array.push(data[i]);
      } else if (api.isCaseManager()) {
        if (this.notInTaskCM.indexOf(data[i].Status) === -1) {
          array.push(data[i]);
        }
      }
    }
    array.unshift({
      Status: 'All',
      Count: 0,
      statusChangeTodayCount: 0,
      statusChangeYesterdayCount: 0,
    });
    return array.map(opt => {
      if (opt.Status === 'All') {
        return {
          text: opt.Status,
          value: opt.Status,
        };
      } else {
        return {
          text: `${opt.Status} (${opt.Count})`,
          value: opt.Status,
        };
      }
    });
  };

  renderCaseText = value => {
    if (value === 'All') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(IMEStore.dataCase?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        if (api.isAdminOrMagStaffOrAccounting()) {
          array.push(data[i]);
        } else if (api.isCaseManager()) {
          if (this.notInTaskCM.indexOf(data[i].Status) === -1) {
            array.push(data[i]);
          }
        }
      }
      array.unshift({
        Status: 'All',
        Count: 0,
        statusChangeTodayCount: 0,
        statusChangeYesterdayCount: 0,
      });
      let labels = array
        .filter(opt => opt.Status === value)
        .map(opt => {
          if (opt.Status === 'All') {
            return opt.Status;
          } else {
            return `${opt.Status} (${opt.Count})`;
          }
        });
      return labels[0] ?? value;
    }
  };

  handleFilterCase = (_, { value }) => {
    IMEStore.setFieldsValue({ FilterType: value });
  };
  handleFilterCaseOverDue = (_, { value }) => {
    IMEStore.setFieldsValue({ FilterOverdue: value });
  };

  renderFilterIMETypeOptions = () => {
    return IMEType.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };
  renderFilterIMETypeText = value => {
    let labels = IMEType.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilterIMEType = (_, { value }) => {
    IMEStore.setFieldsValue({ IMEType: value });
  };

  renderFilterPrepaymentOptions = () => {
    const array = [];
    const data = toJS(IMEStore.dataPrepayment?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Value: 0, Text: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.Text === 'All') {
        return {
          text: opt.Text,
          value: opt.Value,
        };
      } else {
        return {
          text: `${opt.Text} (${opt.Count})`,
          value: opt.Value,
        };
      }
    });
  };
  renderFilterPrepaymentText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(IMEStore.dataPrepayment?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Value: 0, Text: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Value === value)
        .map(opt => {
          return opt.Text;
        });
      return labels[0];
    }
  };

  renderFilterInterpreterOptions = () => {
    return IterpreterType.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterInterpreterText = value => {
    let labels = IterpreterType.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilterPrepayment = (_, { value }) => {
    IMEStore.setFieldsValue({ PrepaymentType: value });
  };

  handleFilterInterpreter = (_, { value }) => {
    IMEStore.setFieldsValue({ InterpreterRequired: value });
  };

  handleFilterTypeOfReport = (_, { value }) => {
    IMEStore.setFieldsValue({ FilterTypeOfReport: value });
  };

  handleFilterTypeOfClaim = (_, { value }) => {
    IMEStore.setFieldsValue({ FilterTypeOfClaim: value });
  };

  renderFilterTypeText = (lstItems, value) => {
    let labels = lstItems.filter(opt => opt.Id === value).map(opt => opt.Name);
    return labels[0];
  };

  renderFilterTypeOptions = lstItems => {
    return lstItems.map(opt => ({
      text: opt.Name,
      value: opt.Id,
    }));
  };

  notInNextTask = [
    api.GetIMEStatusText(api.imeStatus.FULLY_PAID),
    api.GetIMEStatusText(api.imeStatus.CANCELLED),
    api.imeStatus.OLD_BILLED,
    api.GetIMEStatusText(api.imeStatus.CLIENT_INVOICE),
    api.GetIMEStatusText(api.imeStatus.DOCTOR_INVOICE),
  ];
  InTaskDueCM = [`Appointment Requested`, `Appointment Confirmed`, `Service Billed`];

  renderNextTaskLabel = value => {
    if (value === `All`) {
      return `All`;
    } else {
      let array = [];
      const data = toJS(IMEStore.dataCase?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        if (api.isAdminOrMagStaffOrAccounting()) {
          if (this.notInNextTask.indexOf(data[i].Status) === -1) {
            array.push(data[i]);
          }
        } else if (api.isCaseManager()) {
          if (this.InTaskDueCM.indexOf(data[i].Status) !== -1) {
            array.push(data[i]);
          }
        }
      }
      array.unshift({ Status: `All`, Count: `` });
      let labels = array.filter(opt => opt.Status === value).map(opt => opt);
      if (labels[0]) {
        return `${labels[0].NextStatus} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
      }
      return `All`;
    }
  };

  renderNextTask = () => {
    let array = [];
    const data = toJS(IMEStore.dataCase?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      if (api.isAdminOrMagStaffOrAccounting()) {
        if (this.notInNextTask.indexOf(data[i].Status) === -1) {
          array.push(data[i]);
        }
      } else if (api.isCaseManager()) {
        if (this.InTaskDueCM.indexOf(data[i].Status) !== -1) {
          array.push(data[i]);
        }
      }
    }
    array.unshift({ NextStatus: `All`, Count: `` });
    return array.map((opt, idx) => ({
      text: `${opt.NextStatus} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Status,
      key: idx,
    }));
  };

  renderNextTaskLabelOverDue = value => {
    if (value === `All`) {
      return `All`;
    } else {
      let array = [];
      const data = toJS(IMEStore.dataOverdue?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        if (this.notInNextTask.indexOf(data[i].Status) === -1) {
          array.push(data[i]);
        }
      }
      array.unshift({ Status: `All`, Count: `` });
      let labels = array.filter(opt => opt.Status === value).map(opt => opt);
      if (labels[0]) return `${labels[0].Status} ${labels[0].Count ? `(${labels[0].Count})` : ``}`;
      return `All`;
    }
  };

  renderNextTaskOverDue = () => {
    let array = [];
    const data = toJS(IMEStore.dataOverdue?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      if (this.notInNextTask.indexOf(data[i].Status) === -1) {
        array.push(data[i]);
      }
    }
    array.unshift({ Status: `All`, Count: `` });
    return array.map(opt => ({
      text: `${opt.Status} ${opt.Count ? `(${opt.Count})` : ''}`,
      value: opt.Status,
    }));
  };

  renderActionPICOptions = () => {
    const array = [];
    const data = toJS(IMEStore.dataActionPIC?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: `${opt.FullName} (${opt.Count})`,
          value: opt.Id,
        };
      }
    });
  };

  renderActionPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(IMEStore.dataActionPIC?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return `${opt.FullName} (${opt.Count})`;
          }
        });
      return labels[0];
    }
  };

  handleFilterActionPIC = (_, { value }) => {
    IMEStore.setFieldsValue({ ActionRequiredById: value });
  };

  onChecked = () => {
    const { searchClaimantOnly } = IMEStore;
    IMEStore.setFieldsValue({
      searchClaimantOnly: !searchClaimantOnly,
    });
  };

  handleChange = (_, { value }) => {
    IMEStore.setFieldsValue({ keyword: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = IMEStore;
    IMEStore.refetch({ claimant: { isFreeText: true, FullName: keyword }, curPage: 1 }, true);
  };

  renderActionOptions = () => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      const array = [];
      const data = toJS(IMEStore.dataSuggestClaimant?.itemList || []);
      for (var i = 0; i < data.length; i++) {
        if (data[i].FullName !== null) {
          array.push(data[i]);
        }
      }
      array.unshift({ Id: '', FullName: `All` });
      return array.map(opt => ({
        text: `${opt.Id} - ${opt.FullName || ''}`,
        value: JSON.stringify({ Id: opt.Id, FullName: opt.FullName }),
      }));
    } else {
      return [];
    }
  };

  renderActionText = value => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      if (value.Id === '') {
        return 'Search a Claimant';
      } else {
        const array = [];
        const data = toJS(IMEStore.dataSuggestClaimant?.itemList || []);
        for (var i = 0; i < data.length; i++) {
          if (data[i].FullName !== null) {
            array.push(data[i]);
          }
        }
        array.unshift({ Id: '', FullName: `All` });
        let labels = array.filter(opt => opt.Id === value.Id).map(opt => `${opt.FullName || ''}`);
        return labels[0];
      }
    } else {
      return `All`;
    }
  };

  handleChangeSearchDropdown = e => {
    IMEStore.refetchClaimant({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const claimant = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (claimant.Id === `` || value === `All`) {
      IMEStore.refetch({
        claimant: { Id: ``, FullName: `` },
        keyword: ``,
        curPage: 1,
      });
      IMEStore.resetSearch({}, true);
    } else {
      IMEStore.refetch({
        claimant,
        keyword: claimant.FullName,
        curPage: 1,
        numberSearch: 30,
      });
    }
  };

  handleReset = () => {
    IMEStore.resetStore();
    IMEStore.refetch({}, true);
  };

  handleRefresh = () => {
    IMEStore.refetch({}, true);
    IMEStore.fetchPrepayment();
  };

  handleChangeFromTo = (_, { value }) => {
    IMEStore.setFieldsValue({ fromToRequest: value });
  };

  handleChangeStatusFromTo = (_, { value }) => {
    IMEStore.setFieldsValue({ fromToStatus: value });
  };

  handleChangeFromToAppointment = (_, { value }) => {
    IMEStore.setFieldsValue({
      fromToAppointmentDate: value,
      sortDirection: 'asc',
      sortKey: 'DateFrom',
    });
  };

  handleShowBookingForToday = e => {
    e.preventDefault();
    IMEStore.refetch(
      {
        fromToRequest: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
      },
      true,
    );
  };

  handleExport = () => {
    const { fromDate, toDate } = IMEStore.getDateRange();
    const { fromAppointmentDate, toAppointmentDate } = IMEStore.getAppointmentDateRange();
    const {
      FilterType,
      FilterOverdue,
      IMEType,
      claimant,
      NextPicId,
      FilterStaff,
      ActionRequiredById,
      keyword,
    } = IMEStore;
    if (
      claimant.Id ||
      fromDate ||
      toDate ||
      fromAppointmentDate ||
      toAppointmentDate ||
      NextPicId !== null ||
      ActionRequiredById ||
      FilterType !== `All` ||
      FilterOverdue !== `All` ||
      IMEType !== `All` ||
      FilterStaff !== `All` ||
      keyword
    ) {
      this.openDownloadSearchResultsUrl();
    } else {
      this.setState({
        openModalAlert: true,
      });
    }
  };

  openDownloadSearchResultsUrl = () => {
    const { fromDate, toDate } = IMEStore.getDateRange();
    const { fromAppointmentDate, toAppointmentDate } = IMEStore.getAppointmentDateRange();
    const { FilterType, FilterOverdue, ActionRequiredById, IMEType, claimant } = IMEStore;
    const searchOption = {
      ActionRequired: false,
      ActionRequiredById: ActionRequiredById,
      ActionType: 'NONE',
      Claimant: Object.entries(IMEStore.claimant)
        .map(([key, val]) => `${key}=${val}`)
        .join('&'),
      ClaimantId: IMEStore.claimant.Id,
      CurPage: IMEStore.curPage,
      FilterOverdue: FilterOverdue,
      FilterType: FilterType.search(/\s/) ? FilterType.replace(/\s+/g, '+') : FilterType,
      From: moment(fromDate).format('YYYY-MM-DD'),
      FromAppointmentDate: moment(fromAppointmentDate).format('YYYY-MM-DD'),
      IMEType: IMEType,
      Keyword: IMEStore.keyword,
      NextPicId: IMEStore.NextPicId,
      NumItemPerPage: 10000,
      SearchClaimantOnly: IMEStore.searchClaimantOnly,
      SortBy: IMEStore.sortKey,
      SortDirection: `DESC`,
      To: moment(toDate).format('YYYY-MM-DD'),
      ToAppointmentDate: moment(toAppointmentDate).format('YYYY-MM-DD'),
      UrgentReport: false,
    };
    if (!claimant.Id) {
      delete searchOption.Claimant;
      delete searchOption.ClaimantId;
    }
    if (!fromDate) {
      delete searchOption.From;
    }
    if (!toDate) {
      delete searchOption.To;
    }
    if (!fromAppointmentDate) {
      delete searchOption.FromAppointmentDate;
    }
    if (!toAppointmentDate) {
      delete searchOption.ToAppointmentDate;
    }
    if (!IMEStore.NextPicId) {
      delete searchOption.NextPicId;
    }
    if (!FilterType) {
      delete searchOption.FilterType;
    }
    if (!FilterOverdue) {
      delete searchOption.FilterOverdue;
    }
    if (!IMEType) {
      delete searchOption.IMEType;
    }
    var qs = Object.entries(searchOption)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    window.open('/MedicalService/DownloadSearchResult?' + qs, '_blank');
  };

  renderModal = () => {
    const { message, openModalAlert } = this.state;
    return (
      <ModalAlert
        open={openModalAlert}
        modalParams={{ message }}
        onCancel={() => this.setState({ openModalAlert: false })}
      />
    );
  };

  handleScroll = event => {
    const target = event.target;
    const number = IMEStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      IMEStore.refetchClaimant({ numberSearch: number + 20 });
    }
  };

  render() {
    const {
      keyword,
      FilterType,
      IMEType,
      FilterStaff,
      ActionRequiredById,
      searchClaimantOnly,
      FilterOverdue,
      fromToRequest,
      fromToAppointmentDate,
      loadingClaimant,
      totalItems,
      loading,
      PrepaymentType,
      InterpreterRequired,
      dataTypeOfReports,
      FilterTypeOfReport,
      dataTypeOfClaims,
      FilterTypeOfClaim,
      fromToStatus,
    } = IMEStore;
    return (
      <div>
        {this.renderModal()}
        <TableToolbar.Container className="toolbar-container" style={{ justifyContent: 'flex-start' }}>
          <TableToolbar.Left>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Checkbox
                label="Search Claimant Only"
                onChange={this.onChecked}
                checked={searchClaimantOnly}
                style={{ marginRight: `10px`, marginTop: '1%' }}
                disabled={loading}
              />
            )}
            {searchClaimantOnly && api.isAdminOrMagStaffOrAccounting() ? (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                onScroll={this.handleScroll}
                allowAdditions
                additionLabel=""
                selectOnNavigation={false}
                value={keyword}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                className="Toolbar-input"
                disabled={loading}
                clearable
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
            ) : (
              <TableToolbar.Input value={keyword} onChange={this.handleChange} disabled={loading} />
            )}
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
              className="negative"
              disabled={loading}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefresh}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Left>
            {api.isCaseManager() && !api.isTypist() && !api.isCMInvoiceCheck() && !api.isOnlySearch() && (
              <div style={{ marginLeft: '5px' }}>
                <TableToolbar.FloatingDropdown
                  groupName="New Case"
                  groupIconName="add"
                  className="Toolbar-dropdown-button"
                  style={{ marginLeft: '5px' }}
                >
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="search"
                    textDescription="Via Appointment Search"
                    onClick={this.openSmartSearchView}
                  />
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="calendar alternate outline"
                    textDescription="Via Clinic Calendar"
                    onClick={this.openCaseCreateClinicCalendar()}
                  />
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="write"
                    textDescription="Single & Multi-Appointments"
                    onClick={this.openCaseCreateDirect()}
                  />
                </TableToolbar.FloatingDropdown>
              </div>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <div style={{ marginLeft: '5px' }}>
                {!api.isOnlySearch() &&  <TableToolbar.FloatingDropdown
                  groupName="New Case"
                  groupIconName="add"
                  className="Toolbar-dropdown-button"
                  style={{ minWidth: '150px' }}
                >
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="search"
                    textDescription="Via Appointment Search"
                    href="/view/smart-search"
                  />
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="calendar alternate outline"
                    textDescription="Via Clinic Calendar"
                    onClick={this.openCaseCreateClinicCalendar()}
                  />
                  <TableToolbar.FloatingDropdownItem
                    className="Toolbar-dropdown-button-item"
                    iconName="write"
                    textDescription="Single & Multi-Appointments"
                    onClick={this.openCaseCreateDirect()}
                  />
                </TableToolbar.FloatingDropdown>}

                <TableToolbar.HighLightButton
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleExport}
                  className="positive"
                  disabled={loading}
                >
                  Export Searches
                </TableToolbar.HighLightButton>

                <TableToolbar.SubmitButton
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleShowBookingForToday}
                  disabled={loading}
                >
                  Show Today's Booking
                </TableToolbar.SubmitButton>
              </div>
            )}
          </TableToolbar.Left>{' '}
          <TableToolbar.Left>
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container
          className="toolbar-container"
          style={{ marginBottom: '0', justifyContent: 'flex-start' }}
        >
          <TableToolbar.RangePicker
            value={fromToRequest}
            onChange={this.handleChangeFromTo}
            className="Toolbar-range-picker"
            placeholder="Request Date From - To"
            disabled={loading}
          />
          <TableToolbar.RangePicker
            value={fromToAppointmentDate}
            onChange={this.handleChangeFromToAppointment}
            className="Toolbar-range-picker"
            placeholder="Appointment Date From - To"
            disabled={loading}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              label="Next Task PIC"
              value={this.renderPICText(FilterStaff)}
              options={this.renderPICOptions()}
              onChange={this.handleFilterPIC}
              disabled={loading}
              style={{ marginBottom: '5px' }}
            />
          )}
          <TableToolbar.Filter
            label={api.isAdminOrMagStaffOrAccounting() ? 'Next Task' : 'Tasks Due'}
            value={this.renderNextTaskLabel(FilterType)}
            options={this.renderNextTask()}
            onChange={this.handleFilterCase}
            disabled={loading}
            style={{ marginBottom: '5px' }}
          />
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              label="Next Task Overdue"
              value={this.renderNextTaskLabelOverDue(FilterOverdue)}
              options={this.renderNextTaskOverDue()}
              onChange={this.handleFilterCaseOverDue}
              disabled={loading}
              style={{ marginBottom: '5px' }}
            />
          )}
          <TableToolbar.Filter
            label="Case Status"
            value={this.renderCaseText(FilterType)}
            options={this.renderCaseOptions()}
            onChange={this.handleFilterCase}
            disabled={loading}
            style={{ marginBottom: '5px' }}
          />
          {FilterType && FilterType !== 'All' && (
            <TableToolbar.RangePicker
              value={fromToStatus}
              onChange={this.handleChangeStatusFromTo}
              className="Toolbar-range-picker"
              placeholder="Status Date From - To"
              disabled={loading}
            />
          )}
          {/* </TableToolbar.Left> */}

          {/* <TableToolbar.Left style={{ marginBottom: '0' }}> */}
          {api.isAdminOrMagStaffOrAccounting() && (
            <TableToolbar.Filter
              label="Action PIC"
              value={this.renderActionPICText(ActionRequiredById)}
              options={this.renderActionPICOptions()}
              onChange={this.handleFilterActionPIC}
              disabled={loading}
              style={{ marginBottom: '5px' }}
            />
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.Filter
                label="Appointment Type"
                value={this.renderFilterIMETypeText(IMEType)}
                options={this.renderFilterIMETypeOptions()}
                onChange={this.handleFilterIMEType}
                disabled={loading}
                style={{ marginBottom: '5px' }}
              />
            ) && (
              <TableToolbar.Filter
                label="Prepayment Type"
                value={this.renderFilterPrepaymentText(PrepaymentType)}
                options={this.renderFilterPrepaymentOptions()}
                onChange={this.handleFilterPrepayment}
                disabled={loading}
                style={{ marginBottom: '5px' }}
              />
            )}
          <TableToolbar.Filter
            label="Interpreter Required"
            value={this.renderFilterInterpreterText(InterpreterRequired)}
            options={this.renderFilterInterpreterOptions()}
            onChange={this.handleFilterInterpreter}
            disabled={loading}
            style={{ marginBottom: '5px' }}
          />
          <TableToolbar.Filter
            label="Type Of Claim"
            value={this.renderFilterTypeText(dataTypeOfClaims, FilterTypeOfClaim)}
            options={this.renderFilterTypeOptions(dataTypeOfClaims)}
            onChange={this.handleFilterTypeOfClaim}
            disabled={loading}
            style={{ marginBottom: '5px' }}
          />
          <TableToolbar.Filter
            label="Type Of Report"
            value={this.renderFilterTypeText(dataTypeOfReports, FilterTypeOfReport)}
            options={this.renderFilterTypeOptions(dataTypeOfReports)}
            onChange={this.handleFilterTypeOfReport}
            disabled={loading}
            style={{ marginBottom: '5px' }}
          />
        </TableToolbar.Container>
      </div>
    );
  }
}

export default IMEToolbar;
