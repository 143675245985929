import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Select, Checkbox, Radio, notification } from 'antd';
import { Button, Table, Label, Popup, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { uniq } from 'lodash';
import { renderDate } from '../../../components/case-details-routes/Shared';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import AddEditStore from './store';
import dashboardStore from '@stores/dashboard';
import { Link } from 'react-router-dom';
import { sendOnboardedNoticeToCMs } from './service';

const avatarPlaceholder = '';

@observer
class RegisteredSpecialists extends React.Component {
  state = {
    checkedAll: !!AddEditStore.registeredSpecialistIds.length,
  };

  detectIsNumeric = numberStr => {
    if (typeof numberStr != 'string') return false;
    return !isNaN(numberStr) && !isNaN(parseFloat(numberStr));
  };

  handleFilterSelectedDoctor = doctor => {
    const { searchSpecialist } = AddEditStore;
    if (searchSpecialist) {
      const fullname = doctor.FullName.toUpperCase();
      if (this.detectIsNumeric(searchSpecialist)) {
        if (
          fullname.search(searchSpecialist.toUpperCase()) !== -1 ||
          doctor.Id.toString().search(searchSpecialist) !== -1
        ) {
          return doctor;
        }
        return null;
      } else {
        if (fullname.search(searchSpecialist.toUpperCase()) !== -1) {
          return doctor;
        }
        return null;
      }
    } else {
      return doctor;
    }
  };

  openDoctorDetail = (id, name) => {
    localStorage.setItem('DoctorName', name);
    dashboardStore.close('/view/add-edit-doctor-2', true);
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-doctor-2?id=${id}&action=edit&step=0`);
    });
  };

  sendOnboardedNoticeToCMs = doctorId => {
    sendOnboardedNoticeToCMs(doctorId).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Send Onboarded Notice to CMs successfully.`,
          duration: 5,
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Send Onboarded Notice to CMs error.`,
          duration: 5,
        });
      }
    });
  };

  renderTableBody = () => {
    if (AddEditStore.selectedSpecialist === 'selected') {
      return AddEditStore.specialists
        .filter(i => this.handleFilterSelectedDoctor(i))
        .map(record => (
          <Table.Row key={record.Id}>
            <Table.Cell textAlign="center">
              <Checkbox checked={this.isSpecialSelected(record.Id)} onChange={this.handleCheckSpecialist(record.Id)} />
            </Table.Cell>
            <Table.Cell textAlign="center">
              {record.FirstRequestingDate ? renderDate(record.FirstRequestingDate).format('DD/MM/YYYY') : ''}
            </Table.Cell>
            <Table.Cell>{record.RequestingCMs || ''}</Table.Cell>
            <Table.Cell className="Cell-nowrap Cell-link doctor-info">
              <div
                className="avatar"
                style={{
                  background: `url(${record.Avartar || avatarPlaceholder})`,
                }}
              />
              <div className="doctor-name">
                <Link to="#" onClick={() => this.openDoctorDetail(record.Id, record.Title + ' ' + record.FullName)}>
                  {record.Title} {record.FullName}
                </Link>
              </div>
            </Table.Cell>
            <Table.Cell>
              {record.Specialty &&
                record.Specialty.split(', ').map(tag => (
                  <Label className="label-tag" key={tag}>
                    {tag}
                  </Label>
                ))}
            </Table.Cell>
            {/* <Table.Cell>{record?.ClientType || null}</Table.Cell> */}
            <Table.Cell>{record.RecruitmentStatus || ''}</Table.Cell>
            <Table.Cell style={{ minWidth: '200px' }}>
              <Button
                disabled={record.RecruitmentStatus !== 'Onboarded' || !record.RequestingCMs}
                className="blue"
                onClick={() => this.sendOnboardedNoticeToCMs(record.Id)}
              >
                Send Onboarded Notice{' '}
                <Popup
                  trigger={<Icon name="info circle" style={{ marginLeft: '10px', color: 'white', marginTop: 5 }} />}
                  content="Send Onboarded Notice to Requesting CMs (Mail-159)"
                  position="top center"
                />
              </Button>
            </Table.Cell>
            <Table.Cell textAlign="center">{record?.CountIME || ''}</Table.Cell>
            <Table.Cell textAlign="center">{record?.CountFR || ''}</Table.Cell>
            <Table.Cell textAlign="center">{record?.CountSUPP || ''}</Table.Cell>
            <Table.Cell textAlign="center">{record?.CountMedNeg || ''}</Table.Cell>
          </Table.Row>
        ));
    }
    return AddEditStore.specialists.map(record => (
      <Table.Row key={record.Id}>
        <Table.Cell textAlign="center">
          <Checkbox checked={this.isSpecialSelected(record.Id)} onChange={this.handleCheckSpecialist(record.Id)} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link doctor-info">
          <div
            className="avatar"
            style={{
              background: `url(${record.Avartar || avatarPlaceholder})`,
            }}
          />
          <div className="doctor-name">
            <Link to="#" onClick={() => this.openDoctorDetail(record.Id, record.Title + ' ' + record.FullName)}>
              {record.Title} {record.FullName}
            </Link>
          </div>
        </Table.Cell>
        <Table.Cell>
          {record.Specialty &&
            record.Specialty.split(', ').map(tag => (
              <Label className="label-tag" key={tag}>
                {tag}
              </Label>
            ))}
        </Table.Cell>
        <Table.Cell>{record.RecruitmentStatus || ''}</Table.Cell>
        {/* <Table.Cell>{record?.ClientType || null}</Table.Cell> */}
      </Table.Row>
    ));
  };

  isSpecialSelected = id => {
    return !!AddEditStore.registeredSpecialistIds.find(sId => sId === id);
  };

  handleCheckAllSpecialist = ({ target: { checked } }) => {
    this.setState(
      { checkedAll: checked },
      action(() => {
        if (checked) {
          AddEditStore.registeredSpecialistIds = uniq([
            ...AddEditStore.registeredSpecialistIds,
            ...AddEditStore.specialists.map(s => s.Id),
          ]);
        } else {
          AddEditStore.registeredSpecialistIds = AddEditStore.registeredSpecialistIds.reduce((acc, id) => {
            const index = AddEditStore.specialists.findIndex(s => s.Id === id);
            if (index === -1) acc.push(id);
            return acc;
          }, []);
        }
      }),
    );
  };

  handleCheckSpecialist = id =>
    action(({ target: { checked } }) => {
      if (checked) {
        AddEditStore.registeredSpecialistIds = [...AddEditStore.registeredSpecialistIds, id];
      } else {
        AddEditStore.registeredSpecialistIds = AddEditStore.registeredSpecialistIds.filter(sId => sId !== id);
      }
    });

  render() {
    var columns = [
      {
        title: (
          <React.Fragment>
            <div style={{ textAlign: 'center' }}>
              <div>Set to Favourite</div>
              <Checkbox checked={this.state.checkedAll} onChange={this.handleCheckAllSpecialist} />
            </div>
          </React.Fragment>
        ),
      },
      { title: 'First Requesting Date' },
      { title: 'Requesting CMs' },
      { title: 'Specialist' },
      { title: 'Specialty' },
      { title: 'Recruitment Status' },
      { title: '' },
      { title: 'IME Bookings' },
      { title: 'File Review Bookings' },
      { title: 'Supplementary Bookings' },
      { title: 'Med Neg Bookings' },
      // { title: 'Service Type' },
    ];

    if (AddEditStore.selectedSpecialist === 'All') {
      columns = [
        {
          title: (
            <React.Fragment>
              <div style={{ textAlign: 'center' }}>
                <div>Set to Favourite</div>
                <Checkbox checked={this.state.checkedAll} onChange={this.handleCheckAllSpecialist} />
              </div>
            </React.Fragment>
          ),
        },
        { title: 'Specialist' },
        { title: 'Specialty' },
        { title: 'Recruitment Status' },
        // { title: 'Service Type' },
      ];
    }

    return (
      <div className={cx({ disabled: AddEditStore.specialistLoading })}>
        <div className="registered-specialists-searchbox">
          <div style={{ float: 'left', width: '100%', marginBottom: '6px' }}>
            {/* <Select
              style={{ marginRight: '1%', width: '25%' }}
              size="large"
              value={AddEditStore.selectedSpecialist}
              onChange={AddEditStore.handleSelectChangeSpecialist}
            >
              <Select.Option value="All">All specialists</Select.Option>
              {AddEditStore.clientInfo.Id !== 0 && (
                <Select.Option value="selected">Favourite specialists</Select.Option>
              )}
            </Select> */}
            <Input.Search
              placeholder="Search"
              style={{ marginRight: '5px', width: '300px', float: 'left' }}
              size="large"
              value={AddEditStore.searchSpecialist}
              onChange={AddEditStore.handleSearchChange}
            />
            <div style={{ marginRight: '5px', width: '300px', float: 'left', paddingTop: '7px' }}>
              {/* <Radio.Group value={AddEditStore.selectedSearchSpecialistValue} onChange={AddEditStore.handleChangeSearchSpecialist}>
                <Radio value={0}>All specialists</Radio>
                <Radio value={1}>Favourite specialists</Radio>
              </Radio.Group> */}
              <Select
                style={{ width: '100%', marginTop: '-7px' }}
                size="large"
                value={AddEditStore.selectedSpecialist}
                onChange={AddEditStore.handleSelectChangeSpecialist}
              >
                <Select.Option value="All">All specialists</Select.Option>
                {AddEditStore.clientInfo.Id !== 0 && (
                  <Select.Option value="selected">Favourite specialists</Select.Option>
                )}
              </Select>
            </div>
            <div style={{ marginTop: '0px', float: 'left' }}>
              <Button primary onClick={AddEditStore.handleSearchSpecialist}>
                Search
              </Button>
              <Button negative onClick={AddEditStore.handleClearSearchSpecialist}>
                Clear
              </Button>
            </div>
          </div>
        </div>
        <Table sortable striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {AddEditStore.specialists.length ? this.renderTableBody() : <TableEmptyMessage colSpan={columns.length} />}
          </Table.Body>
          {AddEditStore.selectedSpecialist === 'All' && (
            <TableFooterPagination
              colSpan={columns.length}
              totalItems={AddEditStore.sumItemSpecialist}
              currentPage={AddEditStore.curPageSpecialist}
              totalPage={AddEditStore.sumPageSpecialist}
              onPageClick={AddEditStore.handlePageClickSpecialist}
            />
          )}
        </Table>
      </div>
    );
  }
}

export default RegisteredSpecialists;
