import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteRecord } from './Service';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: '' },
  { title: 'Facility Agreement No', sortKey: 'AgreementNo' },
  { title: 'Facility Request No', sortKey: 'FundingRqNo' },
  { title: 'Facility Lender' },
  { title: 'Borrower' },
  { title: 'Signing Date' },
  { title: 'Facility Period' },
  { title: 'Facility Limit' },
  { title: 'Facility Review Date' },
  { title: 'Available Amount' },
];

@observer
class AMAAftercareRuleTable extends React.Component {
  openModal = options => {
    return store.toggleModal(true, options);
  };
  handleDelete = id => {
    store.setFieldsValue({ loading: true });
    Promise.all([deleteRecord(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          store.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    localStorage.setItem('FundingAgreement', record.AgreementNo + ' - Agreement');
    dashboardStore.close('/view/add-edit-funding-agreement');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-agreement?id=${id}`);
    });
    // return store.toggleModal(true, {
    //   modalType: 'update',
    //   id,
    //   record,
    // })();
  };

  handleOpenFundingRequest = (rqId, rqNo) => {
    localStorage.setItem('FundingRequest', rqNo);
    dashboardStore.close('/view/add-edit-funding-request');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-request?id=${rqId}`);
    });
  };

  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Deleting this rule can affect current invoices. Are you sure to continue??',
                onOk: () => this.handleDelete(id),
              })}
              //onClick={() => this.handleDelete(id)}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  numberDecimalFormatter = value => {
    return !!value ? '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ 0';
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>}
        {api.isAdmin() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record)}>
              {record.AgreementNo}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.AgreementNo}</Table.Cell>
        )}
        <Table.Cell className="Cell-link">
          {record.RequestId && (
            <Link to="#" onClick={() => this.handleOpenFundingRequest(record.RequestId, record.FundingRqNo)}>
              {record.FundingRqNo}
            </Link>
          )}
          {!record.RequestId && 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.FundingProviderObj ? record.FundingProviderObj.Id + ' - ' + record.FundingProviderObj.Name : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.FundedCompanyObj ? record.FundedCompanyObj.Id + ' - ' + record.FundedCompanyObj.Name : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.SigningDate ? moment(record.SigningDate).format('DD/MM/YYYY') : 'N/A'}</Table.Cell>
        <Table.Cell>
          {record.FundingPeriodFrom ? moment(record.FundingPeriodFrom).format('DD/MM/YYYY') : 'N/A'}
          {record.FundingPeriodTo ? ' - ' + moment(record.FundingPeriodTo).format('DD/MM/YYYY') : ' - N/A'}
        </Table.Cell>
        <Table.Cell>{record.FundingAmount ? this.numberDecimalFormatter(record.FundingAmount, 0) : 'N/A'}</Table.Cell>
        <Table.Cell>
          {record.FacilityExpiryDate ? (
            <span style={{ color: `${moment(record.FacilityExpiryDate).isBefore(moment()) ? 'red' : ''}` }}>
              {moment(record.FacilityExpiryDate).format('DD/MM/YYYY')}
            </span>
          ) : (
            'N/A'
          )}
        </Table.Cell>
        <Table.Cell>
          {record.FundingAmountRemaining ? this.numberDecimalFormatter(record.FundingAmountRemaining, 0) : 'N/A'}
        </Table.Cell>
        {/* <Table.Cell>
          {record.FundingRequestStatus === 0
            ? 'None'
            : record.FundingRequestStatus === 1
            ? 'Peding'
            : record.FundingRequestStatus === 2
            ? 'Sent'
            : record.FundingRequestStatus === 3
            ? 'Reviewed'
            : record.FundingRequestStatus === 4
            ? 'Approved'
            : 'Rejected'}
        </Table.Cell> */}
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    store.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = store;
    const colummn_ = api.isAdmin() ? columns : columns.filter(i => i.title);
    const colSpan = colummn_.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={colummn_}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={store.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage}
          totalPage={store.totalPage}
          totalItems={store.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default AMAAftercareRuleTable;
