import './SidebarHeader.scss';

import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ui from '../../../stores/dashboard';
import HideSidebarIcon from './HideSidebarIcon';
import ToggleAutohideIcon from './ToggleAutohideIcon';

@observer
class SidebarHeader extends React.Component {
  render() {
    const { autohide } = ui.sidebar;
    return (
      <React.Fragment>
        <div className={cx('SidebarHeader', { autohide })}>
          {autohide && <HideSidebarIcon />}
          <ToggleAutohideIcon />
        </div>
      </React.Fragment>
    );
  }
}

export default SidebarHeader;
