import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import ModalCaseProgress from './ModalCaseProgress';
import SuppStore from './SuppStore';
import SuppTable from './SuppTable';
import SuppToolbar from './SuppToolbar';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';

class SupplementaryReports extends React.Component {
  renderCase = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case `Corrected Report Sent to MAG`:
        return `Corrected Report Returned from Provider`;
      default:
        return caseStatus;
    }
  };
  renderNextTask = task => {
    switch (task) {
      case 'Submit Case Documents':
        return 'Request Received';
      case 'Pay Invoices':
        return 'Service Billed';
      default:
        return task;
    }
  };

  componentDidMount() {
    this._searchDebounced();
    SuppStore.getSUPPNextStatus();
    SuppStore.getSUPPStatus();
    ui.on('open(/view/doctor-supplementary)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?nextTaskDue=') !== -1) {
        const id = search.replace('?nextTaskDue=', '').replaceAll('%20', ' ');
        SuppStore.refetch({ filterNextStatus: this.renderCase(id) }, true);
      } else {
        const id = search.replace('?caseStatus=', '').replaceAll('%20', ' ');
        SuppStore.refetch({ filter: this.renderCase(id) }, true);
      }
    } else {
      SuppStore.refetch({}, true);
    }
  }, 1000);

  render() {
    const { loading, data, open } = SuppStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <SuppToolbar />
            <SuppTable />
            {open && <ModalCaseProgress />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(SupplementaryReports);
