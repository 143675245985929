import { action, observable } from 'mobx';
import customFetch from '../../../utils/customFetch';

class ClinicalNote {
  @observable loading = true;
  @observable data = null;

  @observable doctorId = 9186;
  @observable open = false;

  @observable filter = 'All';
  @observable filterTypes = null;
  @observable keyword = '';
  @observable fromTo = '';
  @observable includeAttachment = false;

  @observable sortColumn = 'Status Date';
  @observable sortColumnKey = 'StatusDate';
  @observable sortDirection = 'descending';

  @observable currentPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @action handleSearchChange = event => {
    this.keyword = event.target.value;
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    const [fromDay, fromMonth, fromYear] = fromDate ? fromDate.split('-') : [];
    const [toDay, toMonth, toYear] = toDate ? toDate.split('-') : [];

    const from = fromDate && new Date([fromMonth, fromDay, fromYear].join('-'));
    const to = toDate && new Date([toMonth, toDay, toYear].join('-'));

    return {
      fromDate: fromDate ? new Date([fromMonth, fromDay, fromYear].join('-')) : '',
      toDate: toDate ? new Date([toMonth, toDay, toYear].join('-')) : '',
      from: from ? new Date(from.setDate(from.getDate() - 1)).toGMTString() : '',
      to: to ? new Date(to.setDate(to.getDate() - 1)).toGMTString() : '',
    };
  };

  @action fetchCR = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate, from, to } = this.getDateRange();

    let types = `FilterTypes=null`;
    if (this.filterTypes) {
      types = this.filterTypes.map(t => `FilterTypes=${t}`).join('&');
    }

    return customFetch(
      `/api/ClinicalNote?keyword=${this.keyword}&curPage=${this.currentPage}&numItemPerPage=${this.pageSize}&sortBy=${
        this.sortColumnKey
      }&sortDirection=${this.sortDirection.replace('ending', '').toUpperCase()}&doctorId=${
        this.doctorId
      }&From=${from}&To=${to}&IncludeAttachment=${
        this.includeAttachment
      }&FromDate=${fromDate}&ToDate=${toDate}&FilterType=${this.filter}&${types}`,
    ).then(
      action(data => {
        this.data = data;
        this.totalPage = setTotalPage ? data.totalPage : this.totalPage;
        this.loading = false;
      }),
    );
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    return this.fetchCR(setTotalPage);
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortColumnKey = sortKey;
    this.fetchCR();
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  ///
  @observable totalSuppRequest = 0;
  @observable dataSuppRequest = null;
  @observable loadingSuppRequest = true;
  @action fetchSuppRequestFR = caseId => {
    this.loadingSuppRequest = true;
    customFetch(`/FileReview/GetSuppRequests?caseId=${caseId}&type=FR`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        this.totalSuppRequest = data.itemList.length;
        this.dataSuppRequest = data;
        this.loadingSuppRequest = false;
      }),
    );
  };
  @action fetchSuppRequestIME = caseId => {
    this.loadingSuppRequest = true;
    customFetch(`/MedicalService/GetSuppRequests?caseId=${caseId}&type=IME`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        this.totalSuppRequest = data.itemList.length;
        this.dataSuppRequest = data;
        this.loadingSuppRequest = false;
      }),
    );
  };
}

export default new ClinicalNote();
