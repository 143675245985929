import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

function ModalConfirm({ message, open, onCancel, onOk }) {
  const renderConfirmMessage = () => (
    <div className="modal-confirm-message">
      <Icon name="warning sign" color="yellow" />
      {message}
    </div>
  );

  const handleOnOkClick = () => {
    onOk && onOk();
    onCancel();
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={handleOnOkClick}>
            <Icon name="check" /> Yes
          </Button>
          <Button color="red" onClick={onCancel}>
            <Icon name="close" /> No
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalConfirm;
