import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { observer } from 'mobx-react';

import Modal from '../../shared/Modal';
import { TableHeader, TableEmptyMessage } from '../../shared/table';

import AddEditStore from './Store';
import { toJS } from 'mobx';

const columns = [
  { title: '#' },
  { title: 'Receiver' },
  { title: 'Email/SMS' },
  { title: 'Sent Date - Time' },
  { title: 'Sent By' },
];

@observer
class AppointmentReminderHistory extends React.Component {
  renderTableEmpty = columns => {
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          <TableEmptyMessage colSpan={columns.length} />
        </Table.Body>
      </Table>
    );
  };

  renderTableCell = (record, idx) => {
    if (this.checkIsDateString(record) !== 'Invalid date') {
      return <Table.Cell key={idx}>{this.checkIsDateString(record)}</Table.Cell>;
    } else {
      return <Table.Cell key={idx}>{record}</Table.Cell>;
    }
  };

  renderTableData = dataHistory => {
    if (Array.isArray(dataHistory)) {
      if (dataHistory.length === 0) {
        return this.renderTableEmpty(columns);
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataHistory.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{idx + 1}</Table.Cell>
                <Table.Cell>{record.Receivers}</Table.Cell>
                <Table.Cell>{record.ServiceType === 'sms' ? 'SMS' : 'Email'}</Table.Cell>
                <Table.Cell>
                  {record.SentDate ? moment(record.SentDate, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY - HH:mm') : ''}
                </Table.Cell>
                <Table.Cell>{record.StaffSent}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return this.renderTableEmpty(columns);
    }
  };
  render() {
    const { dataHistory } = AddEditStore.modalParams;
    const { open } = AddEditStore;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={AddEditStore.toggleModal(false)}
        title="Appointment Reminder History"
      >
        {this.renderTableData(toJS(dataHistory))}
      </Modal>
    );
  }
}
export default AppointmentReminderHistory;
