import { Modal } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { Button, Icon } from 'semantic-ui-react';
import * as api from '@stores/api';
import 'cropperjs/dist/cropper.css';

const FILE_OPTS = {
  allowedExtensions: ['jpg', 'jpeg', 'gif', 'png'],
  maxFileSize: 16 * 1024 * 1024, // 16 MB
};

const ModalProfilePicture = observer(({ open, attachmentType, uploadFile, toggleModal, uploadFileFromBase64 }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const cropRef = useRef(null);
  const canvasRef = useRef(null);

  const getRoundedCanvas = sourceCanvas => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { width, height } = sourceCanvas;
    canvas.width = width;
    canvas.height = height;
    ctx.imageSmoothingEnabled = true;
    ctx.drawImage(sourceCanvas, 0, 0, width, height);
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    ctx.fill();
    return canvas;
  };

  const handleCancel = action(() => {
    setUploadedImage(null);
    toggleModal(false)();
  });

  const handleSave = () => {
    if (!cropRef) {
      return;
    }
    const { cropper } = cropRef.current;
    let imageType = uploadedImage?.split('.').pop();
    if (imageType === 'jpg') {
      imageType = 'jpeg';
    }
    const base64String = getRoundedCanvas(
      cropper.getCroppedCanvas({
        width: 180,
        height: 180,
      }),
    )
      .toDataURL(`image/${imageType}`, 1.0)
      .split('base64,')[1];
    setUploadedImage(null);
    uploadFileFromBase64(base64String, attachmentType);
  };

  const handleImage = data => {
    const avatar = data.fileList[0];
    if (!avatar) {
      return;
    }
    setUploadedImage(`/UserFile/UploadFileTemp/${avatar.name}`);
  };

  const handleError = errors => {
    const [err] = errors;
    toggleModal(true, err)();
  };

  return (
    <Modal
      title="Upload Profile Picture"
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      centered
      width={1000}
      visible={open}
      footer={
        <React.Fragment>
          {!api.isOnlySearch() && <Button color="blue" onClick={handleSave}>
            Save
          </Button>}
          <Button className="negative" onClick={handleCancel}>
            Cancel
          </Button>
        </React.Fragment>
      }
    >
      <div className="Modal-UploadProfilePicture">
        <div className="mb-1">
          <p>You can upload a JPG, JPEG, GIF, or PNG file. Maximum file size is 16 MB.</p>
          <label className="Button">
            <Icon name="upload" />
            <span>Upload</span>
            <input hidden type="file" onChange={uploadFile(handleImage, FILE_OPTS, handleError)} />
          </label>
        </div>
        {uploadedImage && <Cropper ref={cropRef} src={uploadedImage} aspectRatio={1} viewMode={2} />}
        <canvas ref={canvasRef} />
      </div>
    </Modal>
  );
});

export default ModalProfilePicture;
