import { observer } from 'mobx-react';
import React from 'react';
import router from '../../stores/router';
import ModelUploadFile from './../shared/UniversalSearch/UploadFile/ModelUploadFile';
import { action } from 'mobx';
import uploadStore from './../shared/UniversalSearch/UploadFile/UploadStore';
import customFetch from '../../utils/customFetch';
import ModalConfirm from '../shared/Modal/ModalConfirm';
import { notification } from 'antd';

@observer
class OneTimeAccess extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const state = router.location.state;
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return {
      ...params,
      ...state,
    };
  };

  componentWillMount() {
    var { token, type } = this.getRouteParams();
    customFetch('/OneTimeAccess/OneTimeAccessInfo', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ token, type }),
    }).then(
      action(data => {
        if (data.status === 'success') {
          if (type === 'unSubscribe') {
            uploadStore.toggleModal(true, {
              modalType: 'confirm',
              message: `Do you want to unsubscribe to future newsletters from MAG?`,
              onOk: async () => {
                try {
                  await customFetch(`/OneTimeAccess/UnsubcribeDoctorAndCMSubmit`, {
                    headers: { 'Content-Type': 'application/json' },
                    method: 'POST',
                    body: JSON.stringify(data.model),
                  }).then(resp => {
                    if (resp.status === 'success') {
                      notification.success({
                        message: 'Success',
                        description: 'Unsubscribe successfully',
                        duration: 3,
                      });
                    }
                  });
                } catch (e) {}
              },
            })();
          } else {
            uploadStore.toggleModal(true, {
              modalType: 'openUploadModel',
              id: data.model.Id,
              caseNo: data.model.CaseNo,
              status: data.model.Status,
              claimant: data.model.ClaimantFullName,
              serviceType: type === 'uploadpaperwork' ? 'Assessment' : 'Clinical Record',
              label: type === 'uploadpaperwork' ? 'IME' : 'CR',
              token: token,
              type: type,
            })();
          }
        }
      }),
    );
  }

  render() {
    var { type } = this.getRouteParams();
    return (
      <React.Fragment>
        {type === 'unSubscribe' && (
          <ModalConfirm
            open={uploadStore.open}
            modalParams={uploadStore.modalParams}
            onCancel={uploadStore.toggleModal(false)}
          />
        )}
        <ModelUploadFile />
      </React.Fragment>
    );
  }
}

export default OneTimeAccess;
