import React from 'react';
import { Form, Select, Radio, Input, Checkbox, DatePicker, Dropdown, Menu, Icon } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';

import store from './store';

import * as api from '@stores/api';
import customFetch from '@utils/customFetch';
import moment from 'moment';
import { renderDate } from '../Shared';
import { CONSTANTS } from './type';
import { renderDate2 } from '../Shared/utils';

@observer
class CaseCancellationForm extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  renderCheckBoxName = (caseStatus, type) => {
    switch (caseStatus) {
      case 1:
      case 2:
        return type === 'cm' ? 'NotifyCancellationToCM' : 'NotifyDoctorCancelled';
      case 3:
        return type === 'cm' ? 'NotifyOnHoldToCM' : 'NotifyDoctorOnHold';
      default:
        return '';
    }
  };
  renderDisabledCheckBox = (caseStatus, type) => {
    const { clinicalNoteInfo } = store;
    const itemModel = toJS(clinicalNoteInfo);
    switch (caseStatus) {
      case 1:
      case 2:
        return type === 'cm'
          ? itemModel.isLocked || !!itemModel.SentDateNotifyBookingCMCancellation
          : itemModel.isLocked || !!itemModel.SentDateNotifyDoctorCancellation;
      case 3:
        return type === 'cm'
          ? itemModel.isLocked || !!itemModel.SentDateNotifyBookingCMOnHold || !api.isAdminOrMagStaffOrAccounting()
          : itemModel.isLocked || !!itemModel.SentDateNotifyDoctorOnHold || !api.isAdminOrMagStaffOrAccounting();
      default:
        return '';
    }
  };

  renderDateSentNotify = (caseStatus, type) => {
    const { clinicalNoteInfo } = store;
    const itemModel = toJS(clinicalNoteInfo);
    switch (caseStatus) {
      case 1:
      case 2:
        return type === 'cm'
          ? renderDate2(itemModel.SentDateNotifyBookingCMCancellation, false, true)
          : renderDate2(itemModel.SentDateNotifyDoctorCancellation, false, true);
      case 3:
        return type === 'cm'
          ? renderDate2(itemModel.SentDateNotifyBookingCMOnHold, false, true)
          : renderDate2(itemModel.SentDateNotifyDoctorOnHold, false, true);
      default:
        return '';
    }
  };
  renderPopupTxt = (caseStatus, type) => {
    switch (caseStatus) {
      case 1:
      case 2:
        return type === 'cm'
          ? 'CR retrieval cancellation will be sent to CMs (Mail-70A)'
          : 'CR retrieval cancellation will be sent to CR providers (Mail-83)';
      case 3:
        return type === 'cm'
          ? 'CR retrieval on-hold will be sent to CMs (Mail-128)'
          : 'CR retrieval on-hold will be sent to CR provider (Mail-127)';
      default:
        return '';
    }
  };

  onclickResendCancellationToCM = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend cancellation notice to booking CM?',
      onOk: () => {
        customFetch('/ClinicalNote/ResendCancellation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.clinicalNoteInfo.Id, type: 1 }),
        }).then(res => {
          if (res.status === 'success') {
            store.handleChangeCRValue({
              SentDateNotifyBookingCMCancellation: moment(res.ResendDatetime),
            });
            store.showNotification('Success', 'Resend cancellation notice to booking CM', 'success');
          } else {
            store.showNotification('Error', 'Resend cancellation notice to booking CM', 'error');
          }
        });
      },
    });
  };

  onclickResendCancellationToCRProvider = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend cancellation notice to CR provider?',
      onOk: () => {
        customFetch('/ClinicalNote/ResendCancellation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.clinicalNoteInfo.Id, type: 2 }),
        }).then(res => {
          if (res.status === 'success') {
            store.handleChangeCRValue({
              SentDateNotifyDoctorCancellation: moment(res.ResendDatetime),
            });
            store.showNotification('Success', 'Resend cancellation notice to booking CM', 'success');
          } else {
            store.showNotification('Error', 'Resend cancellation notice to booking CM', 'error');
          }
        });
      },
    });
  };

  render() {
    const { clinicalNoteInfo, staffs } = store; // data
    const itemModel = toJS(clinicalNoteInfo);
    const {
      handleChangeFieldRadio,
      handleChangeDatePicker,
      handleChangeFieldSelect,
      handleChangeFieldInput,
      handleChangeFieldCheckbox,
      openModalHistory,
    } = store; // function

    return (
      <Form name="case-cancellation">
        {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 4
                <button className="field-actions" onClick={openModalHistory}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              size="large"
              optionFilterProp="children"
              value={store.checkStaffIsExist(itemModel.PICCancelled) || undefined}
              onChange={handleChangeFieldSelect('PICCancelled')}
              showSearch
            >
              {staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div className="fluid-field" />
        <Form.Item label="Case Cancellation Status">
          <Radio.Group
            value={itemModel.CancellationStatus}
            onChange={event => {
              handleChangeFieldRadio('CancellationStatus')(event);
              if (event.target.value !== 0) {
                let value = { target: { checked: null } };
                if ((event.target.value === 1 && itemModel.IsPaperworkReceived) || event.target.value === 2) {
                  value.target.checked = true;
                } else {
                  value.target.checked = false;
                }
                handleChangeFieldCheckbox(this.renderCheckBoxName(event.target.value, 'doctor'))(value);
              }
            }}
            disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()}
          >
            <Radio value={CONSTANTS.CancellationStatus.None} disabled={!api.isAdminOrMagStaffOrAccounting()}>
              None
            </Radio>
            <Radio
              value={CONSTANTS.CancellationStatus.CancelledNoCharge}
              disabled={
                (api.isCaseManager() && itemModel.CaseStatus === api.crStatusEnum.CN_Request_Docs_Sent_To_Provider) ||
                itemModel.CancellationStatus !== 0
              }
            >
              Cancelled No Fee {this.renderPopup('Applies when the request is cancelled before being sent to provider')}
            </Radio>
            <Radio
              value={CONSTANTS.CancellationStatus.CancelledWithFee}
              disabled={
                (api.isCaseManager() && itemModel.CaseStatus === api.crStatusEnum.CN_Request_Received) ||
                itemModel.CancellationStatus !== 0
              }
            >
              Cancelled With Fee{' '}
              {this.renderPopup(
                'Cancelling request after it has been sent to provider will incur a fee of $35 even the provider has no record of the patient',
              )}
            </Radio>
            <Radio value={CONSTANTS.CancellationStatus.OnHold} disabled={!api.isAdminOrMagStaffOrAccounting()}>
              On-Hold{' '}
              {this.renderPopup(
                'Putting on-hold requests will stop all auto reminders to both CMs and clinic/doctor. Change to None if you want to resume the CR retrieval process',
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {!!itemModel.CancellationStatus && (
          <>
            <Form.Item label={itemModel.CancellationStatus !== 3 ? 'Date of Case Cancellation' : 'Date of On-Hold'}>
              <DatePicker
                size="large"
                value={renderDate(itemModel.CancelledDate)}
                onChange={handleChangeDatePicker('CancelledDate')}
                disabled={!api.isAdminOrMagStaffOrAccounting()}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Reasons">
              <Radio.Group
                disabled={!api.isAdminOrMagStaffOrAccounting()}
                value={itemModel.ReasonCancellation}
                onChange={handleChangeFieldRadio('ReasonCancellation')}
              >
                <Radio value={1}>The request of the Case Manager</Radio>
                <br />
                <Radio value={2}>Specialist/Clinic can't find patient records</Radio>
              </Radio.Group>
            </Form.Item>
            <br />
            <Form.Item label="Send Cancellation Confirmation to" className="cancellation-label">
              <div style={{ marginBottom: 5 }}>
                <Checkbox
                  checked={itemModel[this.renderCheckBoxName(itemModel.CancellationStatus, 'cm')]}
                  onChange={handleChangeFieldCheckbox(this.renderCheckBoxName(itemModel.CancellationStatus, 'cm'))}
                  disabled={
                    api.isCaseManager()
                      ? itemModel.SentByNotifyBookingCMCancellation || false
                      : this.renderDisabledCheckBox(itemModel.CancellationStatus, 'cm') ||
                        !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                        itemModel.SentByNotifyBookingCMCancellation
                  }
                >
                  Booking CM
                  {this.renderPopup(this.renderPopupTxt(itemModel.CancellationStatus, 'cm'))}
                </Checkbox>{' '}
                <span className="field-actions">
                  {this.renderDateSentNotify(itemModel.CancellationStatus, 'cm')}
                  {itemModel.SentByNotifyBookingCMCancellation
                    ? ` - By ${itemModel.SentByNotifyBookingCMCancellation}`
                    : null}
                </span>
              </div>
              <div>
                <Checkbox
                  checked={
                    itemModel[this.renderCheckBoxName(itemModel.CancellationStatus, 'doctor')] ||
                    itemModel.SentByNotifyDoctorCancellation
                  }
                  onChange={handleChangeFieldCheckbox(this.renderCheckBoxName(itemModel.CancellationStatus, 'doctor'))}
                  disabled={
                    this.renderDisabledCheckBox(itemModel.CancellationStatus, 'doctor') ||
                    !api.isAdminOrMagStaffOrAccounting() ||
                    itemModel.SentByNotifyDoctorCancellation
                  }
                >
                  CR Provider
                  {this.renderPopup(this.renderPopupTxt(itemModel.CancellationStatus, 'doctor'))}
                </Checkbox>{' '}
                <span className="field-actions">
                  {this.renderDateSentNotify(itemModel.CancellationStatus, 'doctor')}
                  {itemModel.SentByNotifyDoctorCancellation
                    ? ` - By ${itemModel.SentByNotifyDoctorCancellation}`
                    : null}
                </span>
              </div>
            </Form.Item>
            <Form.Item className="fluid-field">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={this.onclickResendCancellationToCM}
                      disabled={!api.isAdminOrMagStaffOrAccounting() || !itemModel.SentByNotifyBookingCMCancellation}
                    >
                      Resend to Booking CM{' '}
                    </Menu.Item>
                    <Menu.Item
                      onClick={this.onclickResendCancellationToCRProvider}
                      disabled={!api.isAdminOrMagStaffOrAccounting() || !itemModel.SentByNotifyDoctorCancellation}
                    >
                      Resend to CR Provider{' '}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className="blue">
                  Resend Cancellation Confirmation <Icon type="down" />
                </Button>
              </Dropdown>
            </Form.Item>
          </>
        )}

        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Comments" className="fluid-field">
            <Input.TextArea
              rows={3}
              value={itemModel.CancelledComments}
              onChange={handleChangeFieldInput('CancelledComments')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default CaseCancellationForm;
