import { Spin } from 'antd';
import React from 'react';
import Chart from 'react-apexcharts';
import { Icon, Pagination } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import store from './store';
import { toJS } from 'mobx';

@observer
class ChartSession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'basic-bar',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    };
  }

  handleClose = () => {
    store.setFieldsValue({ isShowChart: false });
    store.resetChart();
  };

  handlePaginationChange = (_, { activePage }) => {
    store.refetchDataChart({ curPageChart: activePage });
  };

  render() {
    const { curPageChart, totalPageChart, totalItemChart, loadingChart, dataChart, seriesChart } = store;
    return (
      <React.Fragment>
        {loadingChart ? (
          <div className="Chart-loading">
            <Spin size="large" />
          </div>
        ) : (
          <div className="Chart-container">
            <div className="Toolbar">
              <Icon name="close" className="Icon-close" onClick={this.handleClose} />
            </div>
            <div className="Body">
              <Chart options={toJS(dataChart)} series={toJS(seriesChart)} type="bar" />
            </div>
            <div className="Pagination">
              <div className="Wrap-count-page">
                <p>
                  <b>{totalItemChart}</b> specialists
                </p>
                <p>
                  <b>{totalPageChart}</b> pages
                </p>
              </div>
              <Pagination
                totalPages={toJS(totalPageChart)}
                activePage={toJS(curPageChart)}
                onPageChange={this.handlePaginationChange}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ChartSession;
