import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class Store {
  @observable loading = true;
  @observable data = [];

  @observable userId = '';
  @observable filter = 0;
  @observable sortDirection = '';
  @observable keyword = '';
  @observable sortColumn = '';
  @observable sortKey = '';
  @observable totalItem = 0;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchData = () => {
    this.loading = true;
    customFetch('/Manager/GetLoginHistories', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        UserId: this.userId,
        Type: this.filter,
        Keyword: this.keyword,
        CurPage: this.curPage,
        NumItemPerPage: this.pageSize,
      }),
    }).then(
      action(data => {
        this.data = data.itemList;
        this.totalPage = data.sumPage;
        this.totalItems = data.sumItem;
        this.loading = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData();
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 0;
    this.sortColumn = '';
    this.sortKey = '';
    this.sortDirection = '';

    this.curPage = 1;
    this.totalPage = 0;
    this.fetchData();
  };
}

export default new Store();
