import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../shared/Modal';
import { Button, Dimmer, Loader, Form, Icon, Checkbox } from 'semantic-ui-react';
import './../../DragDrop.scss';
import './Upload.scss';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import DragDropFile from './../../DragDropFile';
import ModalConfirm from '../../Modal/ModalConfirm';
import uploadStore from './UploadStore';
import * as api from '@stores/api';

function UploadReportDrafts(props) {
  const {
    open,
    modalParams,
    emailInfo,
    submitDocumentType,
    attachmentType_Draft,
    toggleModal,
    submitFileHandler,
  } = uploadStore;
  const { id, serviceType } = modalParams;
  const [uploadFile, setUploadFile] = useState([]);
  const [sendAttachment, setSendAttachment] = useState(false);
  const [sendDraftBE, setsendDraftBE] = useState(false);
  const [sendDraftPE, setsendDraftPE] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [doctorPE, setDoctorPE] = useState(null);
  const [doctorBE, setDoctorBE] = useState(null);
  const prevEmailInfo = useRef(emailInfo);

  const title = () => {
    return 'Upload Report Drafts: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant;
  };

  const onSubmitFile = action(() => {
    uploadStore.loading = true;
    if (!uploadFile || uploadFile.length <= 0) {
      return;
    }

    if (!sendDraftBE && !sendDraftPE) {
      uploadStore.loading = false;
      return setOpenModalConfirm(true);
    }

    var uploadAttachment = [];
    uploadFile.forEach(element => {
      uploadAttachment.push({
        [`${serviceType}Id`]: id,
        FileName: element.name,
        Title: element.title,
        ReportType: element.reportType,
        Type: element.reportType,
        NumberOfPages: element.numberOfPages,
      });
    });
    const uploadData = {
      [`${serviceType}Id`]: id,
      ReportType: attachmentType_Draft[serviceType],
      [`${serviceType}Attachment`]: uploadAttachment,
      SendDraftAttachment: sendAttachment,
      SendDraftBE: sendDraftBE,
      SendDraftPE: sendDraftPE,
    };
    submitFileHandler(submitDocumentType[serviceType], uploadData, props.refetchTable);
  });

  const onUploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
      });
      const data = [...uploadFile, ...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      setUploadFile(data);
    }
  };

  const onDeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      const data = [...uploadFile].filter(x => x.name !== deletedFiles.name);
      setUploadFile(data);
    }
  };

  useEffect(() => {
    uploadStore.fetchInfoEmail(modalParams.id);
  }, [modalParams]);

  useEffect(
    action(() => {
      if (prevEmailInfo.current !== emailInfo) {
        if (emailInfo) {
          uploadStore.loading = false;
          setDoctorBE(emailInfo.DoctorBE);
          setDoctorPE(emailInfo.DoctorPE);
          emailInfo.DoctorBE && setsendDraftBE(true);
          emailInfo.DoctorPE && setsendDraftPE(true);
        }
      } else {
        uploadStore.loading = true;
      }
    }),
    [emailInfo],
  );

  const emailLabel = (labelType, doctorEmail) => {
    const label = `To Specialist's ${labelType} Email `;
    const email = doctorEmail ? `(${doctorEmail})` : '';
    return label.concat(email);
  };

  return (
    <React.Fragment>
      <Modal
        visible={open}
        onCancel={toggleModal(false)}
        width={600}
        className="custom-modal"
        title={title()}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button
              color="blue"
              disabled={!uploadFile || uploadFile.length === 0}
              onClick={onSubmitFile}
              loading={uploadStore.loading}
            >
              <Icon name="upload" /> Upload
            </Button>}
            <Button color="red" onClick={toggleModal(false)}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
      >
        <Dimmer active={uploadStore.loading}>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Group inline className="send-notice">
            <Form.Field>
              <label>Send Notice To</label>
            </Form.Field>
            <Form.Group grouped>
              <Form.Field>
                <Checkbox
                  label={emailLabel('Business', doctorBE)}
                  disabled={doctorBE === null}
                  checked={sendDraftBE}
                  onChange={() => setsendDraftBE(!sendDraftBE)}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label={emailLabel('Personal', doctorPE)}
                  disabled={doctorPE === null}
                  checked={sendDraftPE}
                  onChange={() => setsendDraftPE(!sendDraftPE)}
                />
              </Form.Field>
            </Form.Group>
          </Form.Group>
          <Form.Group inline>
            <Form.Field>
              <label>Send Report Draft as Attachment</label>
            </Form.Field>
            <Form.Field>
              <Checkbox checked={sendAttachment} onChange={() => setSendAttachment(!sendAttachment)} />
            </Form.Field>
          </Form.Group>
        </Form>
        <div style={{ marginTop: '10px' }}>
          <DragDropFile
            multiple={true}
            disableDragDrop={false}
            onUploadFile={onUploadFile}
            onDeleteFile={onDeleteFile}
            type={attachmentType_Draft[serviceType]}
            title="Report Draft"
            maxSize="50"
          />
          <hr />
        </div>
      </Modal>
      <ModalConfirm
        modalParams={{
          message: 'Please select at least one option to send report draft notice to',
        }}
        open={openModalConfirm}
        onCancel={() => {
          setOpenModalConfirm(false);
        }}
        footerCloseButton
      />
    </React.Fragment>
  );
}

export default observer(UploadReportDrafts);
