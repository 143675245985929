import { observer } from 'mobx-react';
import React from 'react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import typesOfReportStore from './typesOfReportStore';
import * as api from '../../../stores/api';
import router from '../../../stores/router';

const statusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class TypesOfReportToolbar extends React.Component {
  renderFilterOptions = () => {
    return statusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = statusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleReset = () => {
    typesOfReportStore.resetStore();
    typesOfReportStore.refetch({}, true);
  };

  handleFilter = (_, { value }) => {
    typesOfReportStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    typesOfReportStore.refetch({ curPage: 1 }, true);
  };

  handExport = e => {
    const { keyword, filter } = typesOfReportStore;
    window.open(`/TypesOfReport/ExportSearchResult?keyword=${keyword}&status=${filter}`, '_blank');
  };

  openCaseCreate = () => () => {
    var id = 0;
    if (localStorage.getItem(`TypeOfReport`)) {
      localStorage.removeItem(`TypeOfReport`);
    }
    iframe.forceOpenTab('PostTypesOfReport', `?id=${id}&view=PostTypesOfReport`, {
      id,
      view: 'PostTypesOfReport',
    });
  };

  onOpenCaseCreateNew = () => {
    localStorage.setItem('TypeOfReport', 'Add/Edit Type of Report');
    router.history.push({
      pathname: '/view/add-edit-type-of-report-2',
      state: {
        type: 'typeOfReport',
      },
      search: `?id=${0}`,
    });
  };

  render() {
    const { keyword, loading, filter } = typesOfReportStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left>
          <TableToolbar.Input
            placeholder={'Search type of report by name'}
            value={keyword}
            onChange={typesOfReportStore.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            value={this.renderFilterText(filter)}
            label={`Status`}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.SubmitButton onClick={this.handleSubmit}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            className="negative"
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            className="positive"
            onClick={this.handExport}
            style={{ marginLeft: '5px' }}
          >
            Export Search Result
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.onOpenCaseCreateNew}>
              Add Type of report
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default TypesOfReportToolbar;
