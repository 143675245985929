import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import ImagingStore from './ImagingStore';

const progressList = [
  {
    key: 'CaseCreated',
    label: 'Case Created',
    checked: true,
  },
  {
    key: 'Imaging Requested',
    label: 'Imaging Requested',
    checked: false,
  },
  {
    key: 'Request Sent to Provider',
    label: 'Request Sent to Provider',
    checked: false,
  },
  {
    key: 'Report Completed',
    label: 'Report Completed',
    checked: false,
  },
  {
    key: 'Cancelled No Fee',
    label: 'Cancelled No Fee',
    checked: false,
  },
  {
    key: 'Cancelled With Fee',
    label: 'Cancelled With Fee',
    checked: false,
  },
  {
    key: 'Service Billed',
    label: 'Service Billed',
    checked: false,
  },
  {
    key: 'ServicePaid',
    label: 'Service Paid',
    checked: false,
  },
];

function ModalCaseProgress() {
  const { open, modalParams, toggleModal } = ImagingStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/MRI/GetMRILogs';
  const body = { mriId: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1050}
      title={title}
      footer={
        <React.Fragment>
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress progressList={progressList} url={url} body={body} showHistory />
    </Modal>
  );
}

export default ModalCaseProgress;
