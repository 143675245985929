import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AMAAftercareRuleStore from './Store';
import ModalCreate from './ModalCreate';

function AMAAftercareRuleModal() {
  const { modalType } = AMAAftercareRuleStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AMAAftercareRuleStore} onCancel={AMAAftercareRuleStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...AMAAftercareRuleStore} onCancel={AMAAftercareRuleStore.toggleModal(false)} />;
  }
}

export default AMAAftercareRuleModal;
