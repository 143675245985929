import React, { useState, useMemo, useCallback } from 'react';
import { uploadFileBase } from '@utils/uploadFileBase';

import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import { Dimmer, Loader, Icon } from 'semantic-ui-react';
import './DragDrop.scss';
import { Link } from 'react-router-dom';
import { GetFileExtention } from '@utils/functions';
import { notification } from 'antd';
import { renderDate } from '../index';
import ModalConfirm from '@components/shared/Modal/ModalConfirm';
import { AllowedFileExtensions } from '@utils/constants';

const DragDropFile = ({
  renderAttachment,
  toggleModalConfirm,
  toggleModalModalUpload,
  Attachments,
  baseUrl,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const uploadFile = renderAttachment && renderAttachment(Attachments, props.type);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmParam, setParam] = useState({});

  const onDrop = async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLoading(true);
      const res = await uploadFileBase(acceptedFiles, props.maxSize);
      if (res != null) {
        props.onUploadFile && props.onUploadFile(res.fileList, props.type);
        setLoading(false);
      }
    } else {
      const { accept } = props;
      if (accept === 'application/pdf') {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'Only pdf files are accepted. Please choose a different file.',
        });
      } else {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'File type is not accepted. Please choose a different file.',
        });
      }
    }
  };
  const removeAtt = file => {
    setOpenConfirm(true);
    setParam({
      message: 'Do you want to delete this file?',
      onOk: () => {
        return props.onDeleteFile && props.onDeleteFile(file);
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: props.accept ? props.accept : AllowedFileExtensions,
    maxSize: (props?.maxSize || 50) * 1021000,
  });
  // const handleOpenAttachment = fileName => () => {
  //   return window.open(`${baseUrl}/${fileName}`, '_blank');
  // };

  const files = () => {
    if (uploadFile && uploadFile.length > 0) {
      return uploadFile.map((file, idx) => {
        return (
          <li className="list-file" key={idx}>
            <a href={`${baseUrl}/${file.FileName}`} download={file.Title + GetFileExtention(file.FileName)}>
              {`${file.Title}${GetFileExtention(file.FileName)} ${
                file.NumberOfPages ? ` - ${file.NumberOfPages} ${file.NumberOfPages > 1 ? 'pages' : 'page'}` : ''
              } ${file.CreatedDate ? ` - ${renderDate(file.CreatedDate, false, true)}` : ''}`}
            </a>
            {!props.disabledDelete && (
              <Link to="#" onClick={() => removeAtt(file.key)} style={{ color: `red`, marginLeft: '5px' }}>
                Delete
              </Link>
            )}
          </li>
        );
      });
    }
    return <></>;
  };

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 10px',
        fontSize: '15px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#1db4cd',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        fontWeight: 'bold',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: props.disabled ? 'not-allowed' : 'pointer',
      },
      ...(isDragActive ? { borderColor: '#2196f3', backgroundColor: 'rgb(194, 243, 194)' } : {}),
      ...(isDragAccept ? { borderColor: '#00e676' } : {}),
      ...(isDragReject ? { backgroundColor: 'rgb(255 192 192)' } : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, props.disabled],
  );

  const getProps = useCallback(() => {
    if (props.disableDragDrop)
      return { ...getRootProps({ style }), onDragEnter: null, onDragOver: null, onDragLeave: null, onDrop: null };
    return getRootProps({ style });
  }, [props.disableDragDrop, style, getRootProps]);

  const textboxTitle = useMemo(
    () => (props.disableDragDrop ? 'Click to browse' : 'Drag-n-drop here or Click to browse'),
    [props.disableDragDrop],
  );

  return (
    <section>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      {!(!!uploadFile.length && !props.multiple) && !props.disabled && (
        <div {...getProps()}>
          <input {...getInputProps()} multiple={props.multiple} disabled={props.disabled} />
          <Icon name="upload" className="upload-icon"></Icon>
          <span style={{ color: '#1db4cd' }}>{textboxTitle}</span>
          <span>
            ({props.uploadTitle ? props.uploadTitle + ', ' : ''}
            {props.maxSize > 0 && `${props.uploadTitle ? 'max' : 'Max'} ${props.maxSize}mb`})
          </span>
        </div>
      )}
      {openConfirm && (
        <ModalConfirm
          open={openConfirm}
          modalParams={confirmParam}
          onCancel={() => {
            setOpenConfirm(false);
            setParam({});
          }}
        />
      )}
      <aside>
        <ul className="list-file-ul">{files()}</ul>
      </aside>
    </section>
  );
};

DragDropFile.defaultProps = {
  disableDragDrop: false,
  baseUrl: '/UserFile/SupplementaryAttachment',
};

export default observer(DragDropFile);
