import customFetch from '../../../utils/customFetch';

export const getClaimInfo = id => {
  return customFetch('/PatientInfo/ClaimantInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const checkDuplicateLastName = (id, lastName) => {
  return customFetch('/PatientInfo/CheckDuplicateName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, lastName }),
  });
};

export const checkDuplicateEmail = (id, email) => {
  return customFetch('/PatientInfo/CheckDuplicateEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, email }),
  });
};

export const saveClaimant = body => {
  return customFetch('/PatientInfo/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const getAssessmentHistory = id => {
  return customFetch('/PatientInfo/GetPatientAppointmentHistory', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      id: id,
    }),
  });
};

export const fetchGreetingTitlesByType = () => {
  return customFetch('/GreetingTitle/GetAllByType?type=claimant', {
    method: 'GET',
  });
};

//Case Correspondence
export const getStaff = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const getStaffAll = (keyword = '', numberStaff = 30) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberStaff,
    }),
  });
};

export const getConversationLog = itemId => {
  return customFetch('/PatientInfo/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ claimantId: itemId }),
  });
};

export const saveConversationLog = body => {
  return customFetch('/PatientInfo/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const allowCMViewConversationLog = (id, type = 0) => {
  return customFetch('/PatientInfo/UpdateDoctorAndCMsView', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, type }),
  });
};

export const deleteConversationLog = id => {
  return customFetch('/PatientInfo/DeleteConversationLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};
//End Case Correspondence

export const getCityList = (id = 16) => {
  return customFetch('/Company/City_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ countryId: id }),
  });
};
