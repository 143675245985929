import React from 'react';

import AnonymousLoading from '../anonymous/AnonymousLoading';

const Loading = () => (
  <div className="loading-container">
    <AnonymousLoading />
  </div>
);

export default Loading;
