export const splitSortJoin = str => {
  return (str || '')
    .split(/,\s*/g)
    .sort()
    .join(', ');
};

/**
 *
 * @param {string} locations
 */
export const stringifyDoctorConsultingLocations = locations => {
  if (!locations || !locations.length) {
    return '';
  }

  return locations
    .split(',')
    .map(x => {
      const arr = x.split('|');
      return `${arr[1]} (Postcode: ${arr[2]})`;
    })
    .join(', ');
};
