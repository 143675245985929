import './Search.scss';

import cx from 'classnames';
import omit from 'lodash/omit';
import React from 'react';

import Portal from './Portal';
import TextInput from './TextInput';

class Search extends React.Component {
  static defaultProps = {
    showNoResults: true,
    noResultsMessage: 'No results found',
    defaultOption: '',
  };

  constructor(props) {
    super(props);

    this.formikCtx = null;
    this.searchRef = React.createRef();
    this.resultContainer = React.createRef();
    this.state = {
      open: false,
      ready: false,
      position: {},
      q: '',
    };
  }

  componentDidMount() {
    const { defaultOption, name } = this.props;
    this.formikCtx.form.values[name] = defaultOption || '';
    window.addEventListener('click', this.handleClosePortal);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  assignFormikCtx = ctx => {
    this.formikCtx = ctx;
  };

  getPosition = () => {
    const { current } = this.searchRef;
    const { bottom, left, width } = current.getBoundingClientRect();
    return {
      top: bottom + (window.scrollY || window.pageYOffset) - 3,
      left,
      width,
    };
  };

  handleResize = () => {
    this.setState({ position: this.getPosition() });
  };

  handleClosePortal = event => {
    const { open, ready } = this.state;
    if (open && ready) {
      const { current } = this.searchRef;
      const { clientX, clientY } = event;
      const { top, left, bottom, right } = current.getBoundingClientRect();

      if (!(clientX >= left && clientX <= right && clientY >= top && clientY <= bottom)) {
        this.onInputBlur();
      }
    }
  };

  onInputChange = event => {
    event.persist();
    const { value } = event.target;
    const { onSearchChange } = this.props;
    !onSearchChange ? this.setState({ q: value }) : onSearchChange(value);
  };

  onInputFocus = event => {
    event.persist();
    this.setState({ open: true, position: this.getPosition() }, () => {
      setTimeout(() => this.setState({ ready: true }), 100);
      const { onFocus } = this.props;
      onFocus && onFocus(event);
    });
  };

  onInputBlur = () => {
    this.setState({ open: false, ready: false, position: {} }, () => {
      const { onBlur } = this.props;
      onBlur && onBlur();
    });
  };

  onResultSelect = item => () => {
    const { onBlur, onResultSelect, name } = this.props;
    this.formikCtx.form.setFieldValue(name, item.title);

    this.setState({ open: false, ready: false }, () => {
      onBlur && onBlur();
      onResultSelect && onResultSelect(item);
    });
  };

  renderResults = () => {
    const { q } = this.state;
    const { results, showNoResults, noResultsMessage } = this.props;
    const filteredResults = !!q ? results.filter(re => re.title.toLowerCase().includes(q.toLowerCase())) : results;

    if (filteredResults.length) {
      return this.renderResult(filteredResults);
    }

    return showNoResults ? (
      <div className="no-results" onClick={this.onInputBlur}>
        {noResultsMessage}
      </div>
    ) : null;
  };

  renderResult = results => {
    const { resultRenderer } = this.props;
    return results.map((item, idx) => (
      <div className="item" key={item.id || idx} onClick={this.onResultSelect(item)}>
        {resultRenderer ? resultRenderer(item) : item.title}
      </div>
    ));
  };

  handleScroll = () => {
    const container = this.resultContainer.current;
    const scrollY = container.scrollHeight - container.scrollTop;
    if (container.offsetHeight - scrollY >= -20) {
      const { onLoadMore } = this.props;
      onLoadMore && onLoadMore(this.state.q);
    }
  };

  render() {
    const { loading, className, value, icon, ...props } = this.props;
    const { open, position } = this.state;

    return (
      <div ref={this.searchRef} className={cx('Search', className)}>
        <TextInput
          {...omit(props, [
            'onSearchChange',
            'onFocus',
            'onBlur',
            'results',
            'resultRenderer',
            'showNoResults',
            'noResultsMessage',
            'onResultSelect',
            'defaultOption',
            'onLoadMore',
          ])}
          type="text"
          className={cx('input--overridden-style', { loading })}
          icon={icon || 'search'}
          autoComplete="off"
          tabIndex="0"
          value={value}
          onInput={this.onInputChange}
          onFocus={this.onInputFocus}
          children={this.assignFormikCtx}
        />
        {open && (
          <Portal>
            <div
              ref={this.resultContainer}
              style={position}
              className="Search-results scrollable"
              onScroll={this.handleScroll}
            >
              {this.renderResults()}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

export default Search;
