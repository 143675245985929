import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import LoginHistoryReportStore from './LoginHistoryReportStore';
import { TableHeader, TableEmptyMessage } from '../../shared/table';

@observer
class DoctorReportsTable extends React.Component {
  filterUser = user => {
    const { keywordLogin } = LoginHistoryReportStore;
    if (keywordLogin) {
      const username = user.username.toUpperCase();
      const fullName = user.fullName.toUpperCase();
      if (username.search(keywordLogin.toUpperCase()) !== -1 || fullName.search(keywordLogin.toUpperCase()) !== -1) {
        return user;
      }
      return null;
    } else {
      return user;
    }
  };
  renderTableBody = (data, colSpan) => {
    const { userCount } = LoginHistoryReportStore;
    if (!data || !data.length || userCount === 0) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data
      .filter(i => this.filterUser(i))
      .map(({ Id, ...record }, index) => (
        <Table.Row key={index}>
          <Table.Cell>{record.username}</Table.Cell>
          <Table.Cell>{record.fullName}</Table.Cell>
          <Table.Cell>{record.role}</Table.Cell>
          <Table.Cell className="Cell-nowrap">
            {record.lastLoginTime ? moment(record.lastLoginTime).format('DD/MM/YYYY - HH:mm:ss') : ''}
          </Table.Cell>
        </Table.Row>
      ));
  };

  render() {
    const { dataLogin, loadingLogin, userCount } = LoginHistoryReportStore;
    const columns = [
      {
        title: `Username (${userCount})`,
      },
      { title: 'Full name' },
      { title: 'Role' },
      { title: 'Login time (UTC+07:00)' },
    ];
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <Table sortable striped celled className={cx({ disabled: loadingLogin })}>
          <TableHeader columns={columns} />
          <Table.Body>{this.renderTableBody(dataLogin, colSpan)}</Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default DoctorReportsTable;
