import cx from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import TableToolbar from '../../shared/tableToolbar';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import CMReportStore from './CMReportStore';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { toJS } from 'mobx';
import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'ID' },
  { title: 'First Name' },
  { title: 'Last Name' },
  { title: 'Email' },
  { title: 'Phone Number' },
  { title: 'State' },
  { title: 'Client' },
  { title: 'Last Booking Date', sortKey: 'LastBookingDate' },
];

const StateLabel = [
  { text: `All`, value: `` },
  { text: `ACT`, value: `ACT` },
  { text: `NSW`, value: `NSW` },
  { text: `VIC`, value: `VIC` },
  { text: `QLD`, value: `QLD` },
  { text: `SA`, value: `SA` },
  { text: `WA`, value: `WA` },
  { text: `TAS`, value: `TAS` },
  { text: `NT`, value: `NT` },
];

@observer
class ModalBookingHistory extends React.Component {
  renderStateText = val => {
    return val ? val : 'All';
  };
  handleViewCMBookingHistory = record => {
    CMReportStore.setFieldsValue({
      cmIDBooking: record.Id,
      dataBookingCM: null,
      totalBookingCM: 0,
    });
    CMReportStore.fetchBookingCM(record.Id);
    return CMReportStore.toggleModal(true, {
      modalType: 'history',
      record,
    })();
  };
  openCaseDetails = record => e => {
    e.preventDefault();
    const id = record.Id;
    localStorage.setItem('CmName', `${record.FirstName} ${record.LastName}`);
    iframe.forceOpenTab('ViewCM', `?id=${id}&view=ViewCM`, {
      id,
      view: 'ViewCM',
    });
  };
  handleExportCMLastBooking = () => {
    window.open('/Staff/ExportCMsNotBookedLast6Week');
  };
  renderTableActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => this.handleViewCMBookingHistory(record)}>View Booking History</Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );
  renderTableHistory = () =>
    CMReportStore.dataCMNotBooked.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{this.renderTableActions(record)}</Table.Cell>
        <Table.Cell className="Cell-link" selectable onClick={this.openCaseDetails(record)}>
          <Link to="#" onClick={this.openCaseDetails(record)}>
            {record.Id}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.FirstName}</Table.Cell>
        <Table.Cell>{record.LastName}</Table.Cell>
        <Table.Cell>{record.Email}</Table.Cell>
        <Table.Cell>
          <FormatPhoneNumber officePhoneNumber={record.OfficePhone} mobilePhoneNumber={record.Telephone} />
        </Table.Cell>
        <Table.Cell>{record.CMState}</Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
        <Table.Cell>
          {record.LastBookingDate
            ? moment(formatDate(record.LastBookingDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));

  handlePageClick = page => {
    CMReportStore.refetchCMNotBooked({
      curPageCMNotBooked: page - 1,
    });
  };

  handleChange = (_, { name, value }) => {
    CMReportStore.refetchCMNotBooked({
      [name]: value,
    });
  };

  handleScroll = event => {
    const target = event.target;
    const number = CMReportStore.numberClientSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      CMReportStore.refetchClientSearch({
        numberClientSearch: number + 20,
      });
    }
  };
  handleChangeSearchDropdown = (_, { searchQuery }) => {
    CMReportStore.refetchClientSearch({
      keywordClient: searchQuery,
    });
  };

  renderActionOptions = () => {
    const data = toJS(CMReportStore.dataClientSearch.itemList);
    return data.map(i => ({
      value: i.Id,
      text: `${i.Name} - ${i.FullAddress}`,
    }));
  };
  renderSearchText = value => {
    const data = toJS(CMReportStore.dataClientSearch.itemList);
    return data.filter(i => i.Id === value).map(i => `${i.Name} - ${i.FullAddress}`)[0];
  };
  handleFilterAction = (_, { value }) => {
    CMReportStore.refetchCMNotBooked({
      companyId: value ? value : 0,
      keywordClient: '',
    });
  };

  handleClear = () => {
    CMReportStore.refetchCMNotBooked({
      companyId: 0,
      keywordClient: '',
      stateCMNotBooked: '',
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    CMReportStore.fetchLastBooking();
  };
  handleRefresh = () => {
    CMReportStore.refetchCMNotBooked({
      dataCMNotBooked: null,
      totalItemCMNotBooked: 0,
      totalPageCMNotBooked: 0,
    });
  };
  renderOptionCM = () => {
    const { dataCM } = CMReportStore;
    return dataCM.map(i => ({
      value: i.Id,
      text: `${i.Id} - ${i.Title} ${i.FirstName} ${i.LastName} - ${i.Email}`,
    }));
  };
  onScrollCM = event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    const number = CMReportStore.numberSearch;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      CMReportStore.refetchCM({ numberSearch: number + 20 });
    }
  };
  handleSearchChange = (_, { searchQuery }) => {
    CMReportStore.refetchCM({ keyword: searchQuery });
  };
  renderOptionCMText = val => {
    const { dataCM } = CMReportStore;
    return dataCM.filter(i => i.Id === val).map(i => `${i.Title} ${i.FirstName} ${i.LastName} - ${i.Email}`)[0];
  };
  handleFilterActionCM = (_, { value }) => {
    CMReportStore.refetchCMNotBooked({
      cmIdNotBooking: value ? value : 0,
      keyword: '',
    });
  };
  render() {
    const {
      dataCMNotBooked,
      stateCMNotBooked,
      loadingClientSearch,
      keywordClient,
      companyId,
      cmIdNotBooking,
      keyword,
      loadingCM,
      loadingCMNotBooked,
    } = CMReportStore;
    const colSpan = columns.length;

    return (
      <React.Fragment>
        <form className="CM-report-toolbar">
          <TableToolbar.Container>
            <TableToolbar.Left as="div">
              <TableToolbar.SearchDropdown
                onScroll={this.onScrollCM}
                options={this.renderOptionCM()}
                loading={loadingCM}
                selectOnNavigation={false}
                onSearchChange={this.handleSearchChange}
                disabled={loadingCMNotBooked}
                searchQuery={keyword}
                value={this.renderOptionCMText(cmIdNotBooking)}
                onChange={this.handleFilterActionCM}
                className="CM-Toolbar-input"
                selectOnBlur={false}
                clearable
                placeholder="Search CM last booking"
                icon={!cmIdNotBooking ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
              <TableToolbar.SearchDropdown
                className="Toolbar-input"
                loading={loadingClientSearch}
                searchPlaceHolder="Select Clients"
                onScroll={this.handleScroll}
                search
                icon={!companyId ? <Icon name="dropdown" /> : <Icon name="delete" />}
                selectOnNavigation={false}
                searchQuery={keywordClient}
                clearable
                value={this.renderSearchText(companyId)}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderActionOptions()}
                onChange={this.handleFilterAction}
                disabled={loadingCMNotBooked}
              />
              <TableToolbar.Filter
                options={StateLabel}
                value={this.renderStateText(stateCMNotBooked)}
                label="State"
                name="stateCMNotBooked"
                onChange={this.handleChange}
                disabled={loadingCMNotBooked}
              />
              <TableToolbar.SubmitButton
                style={{ marginLeft: '5px' }}
                className="positive"
                onClick={this.handleSubmit}
                disabled={loadingCMNotBooked}
              >
                Search
              </TableToolbar.SubmitButton>

              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className="negative"
                onClick={this.handleClear}
                disabled={loadingCMNotBooked}
              >
                Clear
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className="blue"
                disabled={loadingCMNotBooked}
                onClick={this.handleRefresh}
              >
                Refresh
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                style={{ marginLeft: '5px' }}
                className="positive"
                onClick={this.handleExportCMLastBooking}
                disabled={loadingCMNotBooked}
              >
                Export CM Last Bookings
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          </TableToolbar.Container>
        </form>
        <Table sortable striped celled className={cx({ disabled: loadingCMNotBooked })}>
          <TableHeader
            columns={columns}
            sortColumn={CMReportStore.sortColumnCMNotBooked}
            sortDirection={CMReportStore.sortDirectionCMNotBooked}
            onSort={CMReportStore.handleSortCMNotBooked}
          />
          <Table.Body>
            {dataCMNotBooked === null ? (
              <TableEmptyMessage colSpan={colSpan} />
            ) : dataCMNotBooked.itemList.length ? (
              this.renderTableHistory()
            ) : (
              <TableEmptyMessage colSpan={colSpan} />
            )}
          </Table.Body>
          <TableFooterPagination
            colSpan={colSpan}
            currentPage={CMReportStore.curPageCMNotBooked + 1}
            totalPage={CMReportStore.totalPageCMNotBooked}
            totalItems={CMReportStore.totalItemCMNotBooked}
            onPageClick={this.handlePageClick}
          />
        </Table>
      </React.Fragment>
    );
  }
}

export default ModalBookingHistory;
