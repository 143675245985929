import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import dashboardStore from '@stores/dashboard';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import InvoicesStore from './InvoicesStore';

const columns = [
  { title: '' },
  { title: 'Invoice No', sortKey: 'InvoiceNo' },
  { title: 'Invoice Date', sortKey: 'DateOfInvoice' },
  { title: 'Payment Status', sortKey: 'PaymentStatusString' },
  { title: 'Invoice Status', sortKey: 'Status' },
  { title: 'Action Required' },
  { title: 'Amount (incl GST)', sortKey: 'Total' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Assessing Specialist', sortKey: 'Doctor' },
  { title: 'Provider No', sortKey: 'DoctorProviderNo' },
  { title: 'Commission Rate', sortKey: 'CommissionRate' },
  { title: 'Case No', sortKey: 'MagRefNo' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Service Date', sortKey: 'ServiceDate' },
  { title: 'Paying Client', sortKey: 'Client' },
  { title: 'Booking Client', sortKey: 'BookingClient' },
  { title: 'Booking CM' },
  { title: 'IV Status Date', sortKey: 'SentDate' },
  { title: 'Xero Status' },
  { title: 'Last Update', sortKey: 'UpdatedDate' },
  { title: 'Created By', sortKey: 'CreatedBy' },
  { title: 'Created Date' },
];

@observer
class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalCancel: false,
      message: ``,
    };
  }

  openNotification = message => {
    return InvoicesStore.toggleModal(true, {
      modalType: 'notification',
      message,
    });
  };

  renderXeroStatus = status => {
    switch (status) {
      case 0:
        return `Not Uploaded`;
      case 2:
        return `Uploaded-Client/Doctor`;
      case 5:
        return `Uploaded-Doctor`;
      case 6:
        return `Uploaded-Client`;
      default:
        return `All`;
    }
  };

  openModal = options => {
    return InvoicesStore.toggleModal(true, options);
  };

  openNotification = message => {
    return InvoicesStore.toggleModal(true, {
      modalType: 'notification',
      message,
    });
  };

  duplicateCaseDetail = (id, label) => () => {
    localStorage.setItem('InvoiceCaseId', `INV-${label}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&type=I&view=ViewInvoice`, {
      id,
      type: 'I',
      view: 'ViewInvoice',
    });
  };

  openCaseDetail = (id, label, type) => () => {
    localStorage.setItem('InvoiceCaseId', `INV-${label}`);
    if (!type) {
      iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
        id,
        view: 'ViewInvoice',
      });
    } else {
      iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice#` + type, {
        id,
        view: 'ViewInvoice',
      });
    }
  };

  openModalDelete = (userId, record) => {
    return InvoicesStore.toggleModal(true, {
      modalType: 'cancel',
      userId,
      record,
    });
  };

  handlePageClick = page => {
    InvoicesStore.refetch({ curPage: page });
  };

  renderActionRequired = record => {
    if (!record.IsActionRequired) {
      return;
    }
    if (record.IsActionRequired) {
      return `Action required - Due Date: ${
        record.ActionRequiredDueDate
          ? moment(formatDate(record.ActionRequiredDueDate), 'DD MMM YYYY').format('DD/MM/YYYY')
          : 'N/A'
      }`;
    }
  };

  handlehandleSend = id => () => {
    customFetch('/Invoice/SendInvoices', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Email has been sent!!`,
        duration: 2,
        placement: `bottomRight`,
      });
    });
  };

  handleOpenCaseDetai = record => {
    const { AssessmentId, ClinicalNoteId, FileReviewId, SupplementaryId, MedNegId } = record;
    const localStorageName = !!AssessmentId
      ? 'AssessmentCaseId'
      : !!ClinicalNoteId
      ? 'ClinicalRecordCaseId'
      : !!FileReviewId
      ? 'FileReviewCaseId'
      : !!MedNegId
      ? 'MedNegCaseId'
      : !!SupplementaryId && 'SupplementaryCaseId';
    const viewName = !!AssessmentId
      ? '/view/add-edit-ime-assessment-2'
      : !!ClinicalNoteId
      ? '/view/add-edit-clinical-notes-2'
      : !!FileReviewId
      ? '/view/add-edit-file-review-2'
      : !!MedNegId
      ? 'PostMedNeg'
      : !!SupplementaryId && '/view/add-edit-supplementary-report-2';
    const id = !!AssessmentId
      ? AssessmentId
      : !!ClinicalNoteId
      ? ClinicalNoteId
      : !!FileReviewId
      ? FileReviewId
      : !!MedNegId
      ? MedNegId
      : !!SupplementaryId && SupplementaryId;
    if (!!!AssessmentId && !!!ClinicalNoteId && !!!FileReviewId && !!!SupplementaryId && !!!MedNegId) {
      return;
    } else {
      localStorage.setItem(localStorageName, record.MagRefNo);
      if (!!MedNegId) {
        iframe.forceOpenTab(`${viewName}`, `?id=${id}&view=${viewName}`, {
          id,
          view: `${viewName}`,
        });
      } else {
        dashboardStore.close(viewName);
        setTimeout(() => {
          dashboardStore.open(`${viewName}?id=${id}`);
        });
      }
    }
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  renderTableBody = () => {
    return InvoicesStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id, record.InvoiceNo)}>
            {record.InvoiceNo}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.DateOfInvoice ? moment(formatDate(record.DateOfInvoice), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.PaymentStatusString}</Table.Cell>
        <Table.Cell>
          {record.Deleted
            ? 'Cancelled'
            : record.SentDate !== null ||
              record.PaymentStatusString === 'Partially Paid' ||
              record.PaymentStatusString === 'Fully Paid'
            ? 'Sent'
            : 'Created'}
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              invoiceNo: record.InvoiceNo,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          {record.ActionOverview && (
            <div>
              {record.ActionOverview} - {this.convertEmailCreatedAt(record.ActionRequiredUserName)}
            </div>
          )}
        </Table.Cell>
        <Table.Cell>${record.Total}</Table.Cell>
        <Table.Cell>{record.Claimant}</Table.Cell>
        <Table.Cell>{record.Doctor}</Table.Cell>
        <Table.Cell>{record.DoctorProviderNo}</Table.Cell>
        <Table.Cell>{record.CommissionRate || 0}%</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={() => this.handleOpenCaseDetai(record)}>
            {record.MagRefNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>
          {record.ServiceDate ? moment(formatDate(record.ServiceDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          {record.Client}
          <br />
          {record.ClientAccountEmail}
        </Table.Cell>
        <Table.Cell className={cx(`${record.BookingClient && `Cell-Client-with-mail`}`)}>
          {record.BookingClient}
          <br />
          {record.BookingCMAccountEmail}
        </Table.Cell>
        <Table.Cell className={cx(`${record.CaseManager && `Cell-Client-with-mail`}`)}>{record.CaseManager}</Table.Cell>

        <Table.Cell>
          {formatDate(record.SentDate, true) === null
            ? `${
                record.CreatedDate
                  ? moment(formatDate(record.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                  : 'N/A'
              }`
            : formatDate(record.SentDate) === '0001'
            ? ''
            : `${
                record.SentDate
                  ? moment(formatDate(record.SentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                  : 'N/A'
              }`}
        </Table.Cell>
        <Table.Cell>{this.renderXeroStatus(record.XeroUploadStatus)}</Table.Cell>
        <Table.Cell>
          {record.UpdatedDate
            ? moment(formatDate(record.UpdatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
        <Table.Cell>
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(id, record.InvoiceNo)}>Edit Invoice</Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message:
                'Duplicate an invoice will copy client, CMs and service provider to a new invoice. Do you want to continue?',
              onOk: this.duplicateCaseDetail(id, record.InvoiceNo),
            })}
          >
            Duplicate this Invoice
          </Menu.Item>
          <Menu.Item onClick={this.openModalDelete(id, record)}>Cancel this Invoice</Menu.Item>
          <Menu.Divider fitted />
          <Menu.Item
            onClick={this.openModal({
              modalType: 'history',
              objectId: id,
              record,
            })}
          >
            View Reminder History
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'uploadClient',
              record,
              id,
            })}
          >
            Upload Invoice & Bill to Xero
          </Menu.Item>
          <Menu.Divider fitted />
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'There is no accounting emails updated. Do you want to send to booking CM?',
              onOk: this.handlehandleSend(id),
            })}
          >
            Send Invoice to CMs
          </Menu.Item>
          <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${id}`)}>Print Invoice</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.InvoiceNo, 'Correspondence')}>
            Update Correspondence
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data } = InvoicesStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={InvoicesStore.sortColumn}
          sortDirection={InvoicesStore.sortDirection}
          onSort={InvoicesStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={InvoicesStore.curPage}
          totalPage={InvoicesStore.totalPage}
          totalItems={InvoicesStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default InvoicesTable;
