import './TextArea.scss';

import cx from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import configureInput from './configureInput';

class TextArea extends React.Component {
  render() {
    const { disabled, error, ...props } = this.props;
    return (
      <SemanticTextArea
        {...omit(props, ['fluid', 'ctx'])}
        className={cx('TextArea', { disabled, error }, props.className)}
      />
    );
  }
}

TextArea.defaultProps = {
  rows: 4,
};

export default configureInput(TextArea);
