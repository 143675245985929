import './Dashboard.scss';

import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import * as api from '../../stores/api';
import ui from '../../stores/dashboard';
import router from '../../stores/router';
import routes from '../dashboard-routes';
import IframeContainer from '../dashboard-routes/Iframe/IframeContainer';
import DashboardContent from './content/DashboardContent';
import DashboardHeader from './header/DashboardHeader';
import DashboardSidebar from './sidebar/DashboardSidebar';

const { $, fcWidget } = window;

@observer
class Dashboard extends React.Component {
  componentDidMount() {
    ui.didmount('a.Tab', '.Tabs-Inner');
    window.addEventListener('beforeunload', this.cleanUp);

    if (!api.isAdminOrMagStaffOrAccounting()) {
      // const user = api.currentUser.data;

      // if (fcWidget) {
      //   fcWidget.init({
      //     token: '413a4ee1-2686-48ef-87b5-4a43b299bd15',
      //     host: 'https://wchat.freshchat.com',
      //     externalId: user.id,
      //     firstName: user.username,
      //     email: user.email,
      //   });
      // }

      if ($ && $.connection && $.connection.onlineUserHub) {
        const startHub = () => {
          // $.connection.hub.start({ pingInterval: null }).done(() => {
          //   $.connection.onlineUserHub.server.refreshOnlineUserList();
          // });
        };

        $.connection.onlineUserHub.client.refreshOnlineUserList = () => {
          // TODO
        };

        $.connection.hub.disconnected(stoppedNicely => {
          return !stoppedNicely && setTimeout(startHub, 3000);
        });

        $.connection.notificationHub.client.notify = (message, messageTitle, messageType) => {
          notification.destroy();
          if (messageType === 'success') {
            notification.success({
              message: messageTitle,
              description: message,
            });
          } else {
            notification.error({
              message: messageTitle,
              description: message,
            });
          }
        };

        startHub();
      }
    }

    this.componentDidUpdate();
  }

  getSnapshotBeforeUpdate() {
    return document.documentElement.scrollTop;
  }

  componentDidUpdate(prevProps, prevState, scrollTop) {
    const { pathname, search } = router.location;
    const { prevPath, open } = ui;
    if (pathname !== prevPath && pathname in routes) {
      open(pathname + search, scrollTop);
    }
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  cleanUp = () => {
    ui.unmount();
    if (fcWidget) {
      fcWidget.destroy();
    }
    if ($ && $.connection) {
      $.connection.hub.stop();
    }
    window.removeEventListener('beforeunload', this.cleanUp);
  };

  render() {
    // Manually trigger the observer on pathname
    // We can not use the MobX built-in intercept / observe
    //    because we need to get the scrollTop from getSnapshotBeforeUpdate
    void router.location.pathname;

    const {
      tabDragdropState: { dragging },
    } = ui;
    return (
      <React.Fragment>
        <DashboardContent />
        <IframeContainer />
        <DashboardHeader />
        <DashboardSidebar />
        {dragging && <div className="Tabs-DraggingBackdrop" />}
      </React.Fragment>
    );
  }
}

export default Dashboard;
