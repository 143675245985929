import dashboardStore from '@stores/dashboard';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import fileReviewStore from './fileReviewStore';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = fileReviewStore;

    const caseNo = `Case F-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('FileReviewCaseId', caseNo);
    dashboardStore.close('/view/add-edit-file-review-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-file-review-2?id=${id}&step=4`);
    });

    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = fileReviewStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/FileReview/GetFileReviewLogs';
  const body = { fileReviewId: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1250}
      title={title}
      footer={
        <React.Fragment>
          {!api.isTypist() && (
            <Button color="blue" onClick={openCaseCorrespondence}>
              <Icon name="table" /> View Case Correspondence
            </Button>
          )}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress
        progressList={api.FR_CaseProgress.data.itemList}
        currentStatus={modalParams.status}
        url={url}
        body={body}
        showHistory
        showPrepaymentStatuses={true}
      />
    </Modal>
  );
}

export default ModalCaseProgress;
