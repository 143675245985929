import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import AMATimeUnitSettingStore from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteAMATimeUnitSetting } from './Service';
import * as api from '../../../stores/api';

const columns = [
  { title: '' },
  { title: 'Total Time Units', sortKey: 'TimeUnit' },
  { title: 'Time Unit Rule', sortKey: 'TimeUnitRule' },
  { title: 'Total time (minutes)' },
];

@observer
class AMATimeUnitSettingTable extends React.Component {
  openModal = options => {
    return AMATimeUnitSettingStore.toggleModal(true, options);
  };
  handleDelete = id => {
    AMATimeUnitSettingStore.setFieldsValue({ loading: true });
    Promise.all([deleteAMATimeUnitSetting(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          AMATimeUnitSettingStore.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    return AMATimeUnitSettingStore.toggleModal(true, {
      modalType: 'create',
      id,
      record,
    })();
  };
  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to delete this item?',
                onOk: () => this.handleDelete(id),
              })}
              //onClick={() => this.handleDelete(id)}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>}
        {api.isAdmin() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record.TimeUnit)}>
              {record.TimeUnit}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.TimeUnit}</Table.Cell>
        )}
        <Table.Cell>{record.TimeUnitRule}</Table.Cell>
        <Table.Cell>
          {record.StartTime} - {record.EndTime}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    AMATimeUnitSettingStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = AMATimeUnitSettingStore;
    const colummn_ = api.isAdmin() ? columns : columns.filter(i => i.title);
    const colSpan = colummn_.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={colummn_}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={AMATimeUnitSettingStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={AMATimeUnitSettingStore.curPage}
          totalPage={AMATimeUnitSettingStore.totalPage}
          totalItems={AMATimeUnitSettingStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default AMATimeUnitSettingTable;
