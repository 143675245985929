import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { action } from 'mobx';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/functions';

import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
// import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
//import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import ModalResetPassword from './ModalResetPassword';
import CaseManagersStore from './CaseManagersStore';
import dashboardStore from '../../../stores/dashboard';
import moment from 'moment';
import router from '../../../stores/router';
import * as api from '@stores/api';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  // { title: 'Title', sortKey: 'Title' },
  { title: 'First Name', sortKey: 'FirstName' },
  { title: 'Last Name', sortKey: 'LastName' },
  { title: 'Action Required' },
  //{ title: 'Relationship PIC' },
  { title: 'Relationship Follow-Up' },
  { title: 'Relationship History' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Office Phone', sortKey: 'OfficePhone' },
  { title: 'Mobile Phone', sortKey: 'Telephone' },
  { title: 'Email', sortKey: 'Email' },
  // { title: 'Username Login' },
  { title: 'State', sortKey: 'State' },
  { title: 'Account Manager' },
  { title: 'Account Status', sortKey: 'IsActive' },
  { title: 'Date Of Status', sortKey: 'StatusChangedDate' },
  { title: 'Created At', sortKey: 'CreatedDate' },
  { title: 'Created By', sortKey: 'CreatedByUsername' },
];

@observer
class CaseManagersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }

  openModal = options => {
    return CaseManagersStore.toggleModal(true, options);
  };

  handlePageClick = page => {
    CaseManagersStore.refetch({ curPage: page });
  };

  openCaseDetail = (id, fName, lName, userId, type, followUpNeeded = false) => () => {
    console.log(userId);
    var fullName = fName;
    if (lName) {
      fullName = fullName + ' ' + lName;
    }
    localStorage.setItem('CmName', `${fullName}`);
    dashboardStore.close(`/view/add-edit-case-manager-2`, true);
    let step = 0;
    switch (type) {
      case 'Login':
        step = 1;
        break;
      case 'Correspondence':
        step = 4;
        break;
      default:
        break;
    }
    setTimeout(() => {
      router.history.push({
        pathname: '/view/add-edit-case-manager-2',
        state: { userId },
        search: `?id=${id}&action=edit&step=${step}&followUpNeeded=${followUpNeeded}`,
      });
    });
    // iframe.forceOpenTab('ViewCM', `?id=${id}&view=ViewCM#${type}`, {
    //   id,
    //   view: 'ViewCM',
    // });
  };

  handleDelete = id => async () => {
    CaseManagersStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Staff/Delete', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      await CaseManagersStore.fetchCase({}, true);
    } finally {
      CaseManagersStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = id => async () => {
    CaseManagersStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/Staff/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });

      await CaseManagersStore.fetchCase({}, true);
    } finally {
      CaseManagersStore.setFieldsValue({ loading: false });
    }
  };

  handleImpersonateUser = userId => () => {
    CaseManagersStore.setFieldsValue({ loading: true });
    customFetch('/Manager/ImpersonateUser', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(() => {
      var url = window.location.protocol + '//' + window.location.host + '/view/dashboard';
      window.location = url;
    });
  };

  openNotification = message => {
    return CaseManagersStore.toggleModal(true, {
      modalType: 'notification',
      message,
    });
  };

  handleUnlockUser = record => async () => {
    CaseManagersStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/manager/unlockUser', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId: record.UserId }),
      }).then(this.openNotification('User has been unlocked'));
      await CaseManagersStore.fetchCase({}, true);
    } finally {
      CaseManagersStore.setFieldsValue({ loading: false });
    }
  };

  handleResetPassword = (id, record) => () => {
    customFetch('/Manager/ResetPassword', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ userId: record.UserId, cmId: id }),
    }).then(res => {
      this.setState({
        openModalNotification: true,
        message: `Generated password: ${res.result}`,
      });
    });
  };

  handleResendLoginInformation = (id, record) => () => {
    if (!record.UserId) {
      this.setState({
        openModalNotification: true,
        message: 'This user is not yet enabled for login. Open the user details and enable login first',
      });
    } else {
      customFetch('/Manager/ResendLoginInformation', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ uId: record.UserId, cmId: id }),
      }).then(() => {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Resend login Information: Successfully!!`,
          duration: 2,
          placement: `bottomRight`,
        });
      });
    }
  };

  handleEditUser = (Id, record) => () => {
    customFetch('/Manager/GetUserById', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: record.UserId }),
    })
      .then(
        action(data => {
          CaseManagersStore.dataUser = data;
        }),
      )
      .then(
        this.openModal({
          modalType: 'editUser',
          Id,
          record,
        }),
      );
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  convertPhoneNunber = phone => {
    if (phone) {
      let fmPhone = phone
        .replace(' ', '')
        .replace(/\D/g, '')
        .replace(/[^\d]/g, '');
      if (fmPhone.length === 10) {
        return (
          <span>
            <span>{fmPhone.slice(0, 2)}</span>
            <span style={{ marginLeft: 5 }}>
              {fmPhone.slice(2, 6)} {fmPhone.slice(6, 10)}
            </span>
          </span>
        );
      } else {
        return phone;
      }
    } else {
      return '';
    }
  };

  rowColor = (isActive, isVerified) => {
    if (!isActive) {
      return 'negative';
    } else if (!isVerified) {
      return 'row-orange-cls';
    } else {
      return '';
    }
  };

  renderTableBody = () => {
    return CaseManagersStore.data.itemList.map(({ Id, ...record }, idx) => (
      <Table.Row key={idx} className={this.rowColor(record.IsActive, record.IsVerified)}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          style={{ cursor: 'pointer' }}
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
        >
          <Link to="#" onClick={this.openCaseDetail(Id, record.FirstName, record.LastName, record.UserId, `General`)}>
            {Id}
          </Link>
        </Table.Cell>
        {/* <Table.Cell>{record.Title}</Table.Cell> */}
        <Table.Cell>{record.FirstName}</Table.Cell>
        <Table.Cell>{record.LastName}</Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              modalType: 'notes',
              modalTitle: `${record.FullName}`,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          <br />
          <div>
            {record.ActionRequiredType > 0 && record.ActionOverview
              ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
              : ''}
          </div>
        </Table.Cell>
        {/* <Table.Cell className="Cell-link">
          {record.NextDueDate && (
            <Link
              to="#"
              onClick={this.openCaseDetail(
                Id,
                record.FirstName,
                record.LastName,
                record.UserId,
                `Correspondence`,
                true,
              )}
            >
              {record.NextDueDate ? moment(formatDate(record.NextDueDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
            </Link>
          )}
        </Table.Cell> */}
        <Table.Cell>
          {record.NextDueDate
            ? moment(formatDate(record.NextDueDate)).format('DD/MM/YYYY') + ' - ' + record.RelationshipPIC
            : ''}
        </Table.Cell>
        <Table.Cell>
          {record?.ActivityPICs?.map(i => (
            <div>{i}</div>
          ))}
        </Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {this.convertPhoneNunber(record.OfficePhone)}
          {/* <FormatPhoneNumber officePhoneNumber={record.OfficePhone} /> */}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {this.convertPhoneNunber(record.Telephone)}
          {/* <FormatPhoneNumber mobilePhoneNumber={record.Telephone} /> */}
        </Table.Cell>
        <Table.Cell>{record.Email}</Table.Cell>
        {/* <Table.Cell>{record.UserName}</Table.Cell> */}
        <Table.Cell>{record.State}</Table.Cell>
        <Table.Cell>{record.AssignedStaff}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': record.Status === 'Inactive' })}>{record.Status}</p>
          {record.Status === 'Inactive' && (
            <div>{record.InActiveDate ? moment(formatDate(record.InActiveDate)).format('DD/MM/YYYY') : ''}</div>
          )}
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedByUsername}</Table.Cell>
      </Table.Row>
    ));
  };

  openViewBookingHistory = (id, record) => {
    CaseManagersStore.setFieldsValue({
      cmId: id,
      dataBookingHistory: null,
      totalBooking: 0,
    });
    CaseManagersStore.fetchBookingHistory(id);
    return CaseManagersStore.toggleModal(true, {
      modalType: 'bookingHistory',
      record,
    })();
  };

  sendTouchBaseReminders = id => async () => {
    CaseManagersStore.setFieldsValue({ loading: true });
    customFetch('/Staff/SendTouchBaseReminder', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(() => {
      CaseManagersStore.setFieldsValue({ loading: false });
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Send Touch Base Reminder Successfully!!`,
        duration: 2,
        placement: `bottomRight`,
      });
    });
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {/* {record.IsActive && (
            <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId)}>
              Update Detail
            </Menu.Item>
          )} */}
          <Menu.Item onClick={() => this.openViewBookingHistory(id, record)}>View Booking History</Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'history',
              id: id,
              record,
            })}
          >
            View Status History
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to send touch base reminder to this CM?',
              onOk: this.sendTouchBaseReminders(id),
            })}
          >
            Send Touch Base Reminders (MAIL-158)
          </Menu.Item>
          {/* <Menu.Item onClick={() => this.openModal({
              modalType: 'confirm',
              message: 'Do you want to send touch base reminder to this CM?',
              onOk: this.sendTouchBaseReminders(id),
            })}>
            Send Touch Base Reminders (MAIL-158)
          </Menu.Item> */}
          {/* <Menu.Item
            onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Correspondence`)}
          >
            Update Correspondence
          </Menu.Item> */}
          <Menu.Divider fitted />
          {/* <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Edit Username
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Unlock User
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Reset Password
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.FirstName, record.LastName, record.UserId, `Login`)}>
            Resend Login Information
          </Menu.Item> */}
          <Menu.Item
            onClick={
              !record.UserId
                ? this.openModal({
                    modalType: 'notification',
                    message: 'This user is not yet enabled for login. Open the user details and enable login first',
                  })
                : this.openModal({
                    modalType: 'showLoginHistory',
                    uId: id,
                    record,
                  })
            }
          >
            Login History
          </Menu.Item>
          {/* <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this staff?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item> */}
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to enable case manager '${record.FirstName} ${record.LastName}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              // onClick={this.openModal({
              //   modalType: 'confirm',
              //   message: `${record.LastName}`
              //     ? `Do you want to disable case manager '${record.FirstName} ${record.LastName}' ?`
              //     : `Do you want to disable case manager '${record.FirstName}' ?`,
              //   onOk: this.handleEditItemStatus(id),
              // })}
              onClick={this.openModal({ modalType: 'disable', modalParams: { Id: id, Record: record } })}
            >
              Disable
            </Menu.Item>
          )}
          {record.UserId && (api.isAdmin() || api.isMagStaff()) && (
            <Menu.Item onClick={this.handleImpersonateUser(record.UserId)}>Impersonate</Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalResetPassword
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { loading, data } = CaseManagersStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: '0' }}>
        {this.renderModal()}
        <TableHeader
          columns={columns}
          sortColumn={CaseManagersStore.sortColumn}
          sortDirection={CaseManagersStore.sortDirection}
          onSort={CaseManagersStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={CaseManagersStore.curPage}
          totalPage={CaseManagersStore.totalPage}
          totalItems={CaseManagersStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default CaseManagersTable;
