import React, { PureComponent } from 'react';
import { Button, Checkbox, Form, Icon, Modal } from 'semantic-ui-react';

export default class RequestDoctorInfoModal extends PureComponent {
  state = {
    isRequestDoctorCvChecked: false,
    isRequestDoctorSampleReportChecked: false,
  };

  onIsRequestDoctorCvCheckedChange = () => {
    this.setState(prevState => ({
      isRequestDoctorCvChecked: !prevState.isRequestDoctorCvChecked,
    }));
  };

  onIsRequestDoctorSampleReportCheckedChange = () => {
    this.setState(prevState => ({
      isRequestDoctorSampleReportChecked: !prevState.isRequestDoctorSampleReportChecked,
    }));
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleOk = () => {
    this.props.handleOk({
      doctorId: this.props.doctorId,
      isRequestDoctorCvChecked: this.state.isRequestDoctorCvChecked,
      isRequestDoctorSampleReportChecked: this.state.isRequestDoctorSampleReportChecked,
    });
  };

  handleViewCV = () => {
    window.open('/UserFile/DoctorCV/' + this.props.doctor.DoctorCV, '_blank');
  };

  handleViewSampleReport = () => {
    window.open('/UserFile/DoctorAttachment/' + this.props.doctor.SampleReport, '_blank');
  };

  render() {
    const title = `${this.props.doctor?.Title} ${this.props.doctor?.FirstName} ${this.props.doctor?.LastName}`;
    return (
      <Modal open={this.props.isOpen} size="small">
        <Modal.Header content={title} />
        <Modal.Content>
          {!this.props.doctor.DoctorCV && (
            <Form.Field>
              <Checkbox
                style={{ marginBottom: '10px' }}
                label="Request CV"
                checked={this.state.isRequestDoctorCvChecked}
                onChange={this.onIsRequestDoctorCvCheckedChange}
              />
            </Form.Field>
          )}
          {!this.props.doctor.SampleReport && (
            <Form.Field>
              <Checkbox
                style={{ marginBottom: '10px' }}
                label="Request Sample Reports"
                checked={this.state.isRequestDoctorSampleReportChecked}
                onChange={this.onIsRequestDoctorSampleReportCheckedChange}
              />
            </Form.Field>
          )}
          {this.props.doctor.DoctorCV && (
            <Button color="green" onClick={this.handleViewCV}>
              <Icon name="file alternate" />
              <span>View CV</span>
            </Button>
          )}
          {this.props.doctor.SampleReport && (
            <Button color="green" onClick={this.handleViewSampleReport}>
              <Icon name="file alternate" />
              <span>View Sample Report</span>
            </Button>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.handleOk}
            disabled={!this.state.isRequestDoctorCvChecked && !this.state.isRequestDoctorSampleReportChecked}
          >
            <Icon name="paper plane" />
            <span>Send</span>
          </Button>
          <Button color="red" onClick={this.handleClose}>
            <Icon name="close" />
            <span>Close</span>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
