import { action, observable } from 'mobx';

import customFetch from './customFetch';

class MutationResult {
  @observable loading = false;

  constructor(url) {
    this.url = url;
  }

  @action post = (data, success, error, always, ...urlArgs) => {
    if (this.loading) {
      return;
    }
    // Build url in case of function
    let { url } = this;
    if (typeof url === 'function') {
      url = url(...urlArgs);
    }
    // Reset mutation state
    this.loading = true;
    // Call fetch and handle promise
    customFetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(
        action(res => {
          if (success) {
            success(res);
          }
        }),
      )
      .catch(
        action(err => {
          if (error) {
            error(err);
          }
        }),
      )
      .then(
        action(() => {
          this.loading = false;
          if (always) {
            always();
          }
        }),
      );
  };
}

const createMutation = url => new MutationResult(url);

export default createMutation;
