import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import medNegStore from './MedNegStore';
import * as api from '../../../stores/api';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = medNegStore;

    const caseNo = `Case M-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('MedNegCaseId', caseNo);
    iframe.forceOpenTab('PostMedNeg', `?id=${id}&view=PostMedNeg#MednegCorespondence`, {
      id,
      view: 'PostMedNeg',
    });

    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = medNegStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/MedNeg/GetMedNegLogs';
  const body = { mednegId: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1050}
      title={title}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={openCaseCorrespondence}>
            <Icon name="table" /> View Case Correspondence
          </Button>
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress
        progressList={api.MN_CaseProgress.data.itemList}
        url={url}
        body={body}
        currentStatus={modalParams.status}
        showHistory
      />
    </Modal>
  );
}

export default ModalCaseProgress;
