import { action, observable } from 'mobx';
import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';

class IMEStore {
  @observable loading = true;
  @observable data = null;

  @observable doctorId = 9186;
  @observable open = false;
  @observable filterNextStatus = 'All';
  @observable filter = 'All';
  @observable filterTypes = null;
  @observable keyword = '';
  @observable fromTo = '';
  @observable toAppointmentDate = '';
  @observable includeAttachment = false;

  @observable sortColumn = 'Request Date';
  @observable sortColumnKey = 'DateFrom';
  @observable sortDirection = 'descending';

  @observable currentPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable currentAttPage = 1;
  @observable pageAttSize = 20;
  @observable totalAttPage = 0;
  @observable totalAttItems = 0;
  @observable dataAtt = null;

  @observable open = false;
  @observable modalParams = {};
  @observable dynamicStatus = [];
  @observable dynamicNextStatus = [];

  @action handleSearchChange = event => {
    this.keyword = event.target.value;
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    const [fromDay, fromMonth, fromYear] = fromDate ? fromDate.split('/') : [];
    const [toDay, toMonth, toYear] = toDate ? toDate.split('/') : [];

    const from = fromDate && new Date([fromMonth, fromDay, fromYear].join('-')).toISOString();
    const to = toDate && new Date([toMonth, toDay, toYear].join('-')).toISOString();

    return {
      fromDate: fromDate ? new Date([fromMonth, fromDay, fromYear].join('-')) : '',
      toDate: toDate ? new Date([toMonth, toDay, toYear].join('-')) : '',
      from: from ? from : '',
      to: to ? to : '',
    };
  };

  @action fetchReports = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate, from, to } = this.getDateRange();
    if (api.isDoctor()) {
      return customFetch(
        `/api/OnlinedoctorReportSearch?keyword=${this.keyword}&currentPage=${this.currentPage}&pageSize=${
          this.pageSize
        }&sortBy=${this.sortColumnKey}&sortDirection=${this.sortDirection
          .replace('ending', '')
          .toUpperCase()}&doctorId=${
          this.doctorId
        }&From=${from}&To=${to}&FromDate=${fromDate}&ToDate=${toDate}&FilterType=${this.filter}&IncludeAttachment=${
          this.includeAttachment
        }&FilterNextStatus=${this.filterNextStatus}&ToAppointmentDate=${this.toAppointmentDate}`,
      ).then(
        action(data => {
          this.data = data;
          this.totalPage = setTotalPage ? data.totalPage : this.totalPage;
          this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
          this.loading = false;
        }),
      );
    } else if (api.isCaseManager()) {
      const { CaseManagerId, ClientId } = api.currentUser.data;
      return customFetch('/CMReports/GetCMReports', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          Keyword: this.keyword,
          CurPage: this.currentPage,
          SortBy: this.sortColumnKey,
          SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
          SearchClaimantOnly: false,
          CaseMngId: CaseManagerId,
          ClientId: ClientId,
          FilterType: this.filter,
          FilterTypes: this.filterTypes,
          FilterOverdue: this.filterNextStatus,
          IncludeAttachment: this.includeAttachment,
          To: '',
          From: '',
        }),
      }).then(
        action(data => {
          this.data = data;
          this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
          this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
          this.loading = false;
        }),
      );
    } else {
      return customFetch('/MedicalService/Assessment_LoadList', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          Keyword: this.keyword,
          CurPage: this.currentPage,
          NumItemPerPage: this.pageSize,
          SortBy: this.sortColumnKey,
          SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
          SearchClaimantOnly: false,
          FilterType: this.filter,
          FilterTypes: this.filterTypes,
          FilterOverdue: this.filterNextStatus,
          IncludeAttachment: this.includeAttachment,
          IMEType: 'All',
          ToAppointmentDate: this.toAppointmentDate,
        }),
      }).then(
        action(data => {
          this.data = data;
          this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
          this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
          this.loading = false;
        }),
      );
    }
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    return this.fetchReports(setTotalPage);
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortColumnKey = sortKey;
    this.fetchReports();
  };

  @action getIMEStatus = () => {
    return customFetch('/MedicalService/GetDynamicAssessmentStatus', { method: 'POST' }).then(
      action(data => {
        if (data.itemList.filter(x => x.Count > 0).length > 0) {
          var All = {
            key: 'All',
            text: 'All',
            value: 'All',
          };
          this.dynamicStatus = [
            All,
            ...data.itemList
              .filter(x => x.Count > 0)
              .map(opt => ({
                key: opt.Status,
                text: opt.Status + ' - (' + opt.Count + ')',
                value: opt.Status,
              })),
          ];
        }
      }),
    );
  };

  @action getIMENextStatus = () => {
    return customFetch('/MedicalService/GetDynamicAssessmentNextStatus', { method: 'POST' }).then(
      action(data => {
        if (data.itemList.filter(x => x.Count > 0).length > 0) {
          var All = {
            key: 'All',
            text: 'All',
            value: 'All',
          };
          this.dynamicNextStatus = [
            All,
            ...data.itemList
              .filter(x => x.Count > 0)
              .map(opt => ({
                key: opt.Status,
                text: opt.Status + ' - (' + opt.Count + ')',
                value: opt.Status,
              })),
          ];
        }
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };
}

export default new IMEStore();
