import './index.scss';

import { observer } from 'mobx-react';
import React from 'react';
import { Spin } from 'antd';
import store from './Store';
import Form from './Form';
import Modal from './Modal';
import router from '../../../stores/router';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class AddEditFundingRquest extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};
    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return params;
  };

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/add-edit-funding-request', true);
      return;
    }
    const { id } = this.getRouteParams();
    if (!!parseInt(id)) {
      store.fetchData(id);
    } else {
      store.initData();
    }
  }

  render() {
    const { open } = store;
    return store.loading ? (
      <div className="page-loading">
        <Spin size="large" />
      </div>
    ) : (
      <div className={`form-add-edit-container`}>
        <React.Fragment>
          <Form />
          {open && <Modal />}
        </React.Fragment>
      </div>
    );
  }
}

export default AddEditFundingRquest;
