import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Table } from 'semantic-ui-react';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import { toJS } from 'mobx';
import moment from 'moment';

class TablesMN extends React.Component {
  openCaseDetail = (id, type, record) => () => {
    localStorage.setItem('MedNegCaseId', `Case ${record.MedNegNumber}`);
    iframe.forceOpenTab('PostMedNeg', `?id=${id}&view=PostMedNeg#${type}`, {
      id,
      view: 'PostMedNeg',
    });
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  render() {
    const { data, curPage, totalPage, sumItem, searchAttachment } = this.props;
    var columns = [
      { title: 'Case No', sortKey: 'MedNegNumber' },
      { title: 'Request Date', sortKey: 'RequestReceivedDate' },
      { title: 'Case Status' },
      { title: 'Specialist 1' },
      { title: 'Status 1' },
      { title: 'Specialist 2' },
      { title: 'Status 2' },
      { title: 'Specialist 3' },
      { title: 'Status 3' },
      { title: 'Claim No', sortKey: 'ClaimantNo' },
      { title: 'Type of Claim/Report', sortKey: 'TypeOfClaimnt' },
      { title: 'Claimant Name', sortKey: 'Claimant' },
      { title: 'Client', sortKey: 'Client' },
      { title: 'Case Manager', sortKey: 'CaseManager' },
      { title: 'Created By', sortKey: 'CreatedBy' },
    ];
    if (searchAttachment) {
      columns = [
        ...columns,
        {
          title: 'Case Documents',
          style: { minWidth: '500px', maxWidth: '500px' },
        },
        {
          title: 'Attachment Type',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded By',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded Date',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
      ];
    }
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {data.length ? (
            data.map(record => (
              <Table.Row key={record.Id}>
                <Table.Cell className="Cell-nowrap Cell-link">
                  <Link to="#" onClick={this.openCaseDetail(record.Id, ``, record)}>
                    {record.MedNegNumber}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {record.RequestReceivedDate
                    ? moment(formatDate(record.RequestReceivedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                <Table.Cell>{record.CaseFlowText}</Table.Cell>
                <Table.Cell style={{ minWidth: 250 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Specialty1 }} />
                </Table.Cell>
                <Table.Cell style={{ minWidth: 150 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Status1 }} />
                </Table.Cell>
                <Table.Cell style={{ minWidth: 250 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Specialty2 }} />
                </Table.Cell>
                <Table.Cell style={{ minWidth: 150 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Status2 }} />
                </Table.Cell>
                <Table.Cell style={{ minWidth: 250 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Specialty3 }} />
                </Table.Cell>
                <Table.Cell style={{ minWidth: 150 }}>
                  <div dangerouslySetInnerHTML={{ __html: record.Status3 }} />
                </Table.Cell>
                <Table.Cell>{record.ClaimantNo}</Table.Cell>
                <Table.Cell>
                  <div>{record.TypeOfClaimnt}</div>
                  <div>{record.TypeOfReport}</div>
                </Table.Cell>
                <Table.Cell>{record.Claimant}</Table.Cell>
                <Table.Cell style={{ minWidth: 200 }}>{record.Client}</Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">
                    {record.CaseManagerId > 0 ? `(${record.CaseManagerId})` : ``} {record.CaseManager}
                  </h5>
                  <div>{record.CMEmail}</div>
                  <div>{record.CMMobilePhone}</div>
                </Table.Cell>
                <Table.Cell>{record.CreatedBy}</Table.Cell>

                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell className="Cell-link">
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx} style={{ minWidth: '500px', maxWidth: '500px' }}>
                        <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                          {item.FileTitle}
                        </a>
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.ReportTypeName}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedBy}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedDate
                          ? moment(formatDate(item.UplodatedDate, true), 'DD MMM YYYY, HH:mm').format(
                              'DD/MM/YYYY - HH:mm',
                            )
                          : 'N/A'}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage}
          totalPage={totalPage}
          onPageClick={this.handlePageClick}
          totalItems={sumItem}
        />
      </Table>
    );
  }
}

export default TablesMN;
