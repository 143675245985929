import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import MarketingCampaignStore from './store';
import ModalAddEdit from './CampaignForm';

function MarketingCampaignModal() {
  const { modalType } = MarketingCampaignStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...MarketingCampaignStore} onCancel={MarketingCampaignStore.toggleModal(false)} />;
  }
  if (modalType === 'addEdit') {
    return <ModalAddEdit {...MarketingCampaignStore} onCancel={MarketingCampaignStore.toggleModal(false)} />;
  }
}

export default MarketingCampaignModal;
