import { observer } from 'mobx-react';
import React from 'react';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import ModuleStore from './ModuleStore';
import { toJS } from 'mobx';

const options = [
  {
    text: 'Mail Module',
    value: 'mailmoduletype',
  },
  {
    text: 'Mail Receiver',
    value: 'mailreceivertype',
  },
  {
    text: 'Case Stage',
    value: 'casestages',
  },
];

@observer
class JobTitleToolbar extends React.Component {
  renderTypeLabel = () => {
    return options.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderModuleType = () => {
    const array = [];
    const data = toJS(ModuleStore.dataModule.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderModuleTypeText = value => {
    const array = [];
    const data = toJS(ModuleStore.dataModule.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === value).map(opt => opt.Label)[0];
  };

  renderTypeLabelText = value => {
    return options.filter(i => i.value === value).map(i => i.text)[0];
  };

  handleReset = () => {
    ModuleStore.resetStore();
    ModuleStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    ModuleStore.refetch({ curPage: 1 }, true);
  };

  handleSearchChange = (_, { value }) => {
    ModuleStore.setFieldsValue({ keyword: value });
  };

  handleRefresh = () => {
    ModuleStore.refetch({ data: null }, true);
  };

  handleChangeDropdown = (_, { name, value }) => {
    ModuleStore.refetch({ [name]: value }, true);
  };

  openCaseCreate = () => () => {
    return ModuleStore.toggleModal(true, {
      modalType: 'addOrEdit',
      id: 0,
      record: null,
    })();
  };

  render() {
    const { keyword, loading, filter } = ModuleStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            label="Filter by"
            name="filter"
            value={this.renderTypeLabelText(filter)}
            onChange={this.handleChangeDropdown}
            options={this.renderTypeLabel()}
          />
          {/* <TableToolbar.Filter
            label="Case Stage"
            name="filterCaseStage"
            value={this.renderModuleTypeText(filterCaseStage)}
            onChange={this.handleChangeDropdown}
            options={this.renderModuleType()}
          /> */}
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            className="blue"
            disabled={loading}
            onClick={this.handleRefresh}
            style={{ marginLeft: '5px' }}
          >
            Refresh
          </TableToolbar.HighLightButton>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openCaseCreate()}
              style={{ marginLeft: '5px' }}
            >
              Add module
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Left>
        <TableToolbar.Right />
      </TableToolbar.Container>
    );
  }
}

export default JobTitleToolbar;
