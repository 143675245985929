import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import DocAndConsStore from './DocAndConsStore';
import ModalActionNotes from './ModalActionNotes';
import ModalDoctorClinic from './ModalDoctorClinic';
import ModalEditUser from './ModalEditUser';
import ModalExportDoctor from './ModalExportDoctor';
import ModalLoginHistory from './ModalLoginHistory';
import ModalUserAlert from './ModalUserAlert';
import ModalDisableUser from './ModalDisableUser';
import ModalStatusHistory from './ModalStatusHistory';

function DocAndConsModal() {
  const { modalType } = DocAndConsStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'doctorAndClinic') {
    return <ModalDoctorClinic {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'exportDoctor') {
    return <ModalExportDoctor {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'editUser') {
    return <ModalEditUser {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'alert') {
    return <ModalUserAlert {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'history') {
    return <ModalLoginHistory {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'disable') {
    return <ModalDisableUser {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  } else if (modalType === 'statusLog') {
    return <ModalStatusHistory {...DocAndConsStore} onCancel={DocAndConsStore.toggleModal(false)} />;
  }

  if (modalType === 'notes') {
    return <ModalActionNotes />;
  }
}

export default DocAndConsModal;
