import { notification } from 'antd';
import { action, computed, observable, toJS, reaction } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';

import customFetch from '../../../../utils/customFetch';
import { formatDate, numberParser } from '../../../../utils/functions';
import * as api from '../../../../stores/api';
import { BaseProfileUploadStore } from '../../../shared/ProfileUpload';

export const DoctorAttachmentType = {
  SampleReport: 0,
  Signature: 1,
  Qualification: 2,
  Accreditation: 3,
  Preference: 4,
  LetterHead: 5,
  ProfilePicture: 6,
  AHRPAAccreditation: 8,
  RequiredConsentForm: 9,
};

class DoctorDetailsStore extends BaseProfileUploadStore {
  @observable loading = true;
  @observable loadingClinic = true;
  @observable dataDoctor = null;
  @observable dataConversationLogLoadList = null;
  @observable dataLookupConfigByParents = null;
  @observable dataActiveReportTypeList = null;
  @observable dataCaseManager = null;
  @observable dataStaff = null;
  @observable dataState = null;
  @observable dataCountry = null;
  @observable dataCity = [];
  @observable dataMailingCity = [];
  @observable dataLookupConfig = null;
  @observable clinicList = null;
  @observable clinicListId = null;
  @observable dataClinicList = null;
  @observable locationList = [];
  @observable dataChart = null;

  @observable isEditing = false;
  @observable oldDataDoctor = null;
  @observable sampleReports = [];
  @observable attRequiredConsentForm = [];
  @observable oldSampleReports = [];
  @observable signatures = [];
  @observable oldSignatures = [];
  @observable letterHeadAttachments = [];
  @observable oldLetterHeadAttachments = [];
  @observable aHRPAAccreditationsAttachments = [];
  // @observable profilePictureAttachments = [];
  // @observable oldProfilePictureAttachments = [];
  @observable selectedAcceptedReportTypes = [];
  @observable oldSelectedAcceptedReportTypes = [];
  @observable selectedLocationsId = [];
  @observable oldSelectedLocationsId = [];
  @observable selectedClinicsId = [];

  @observable keyword = '';
  @observable filter = 'All';

  @observable curPageClinic = 1;
  @observable curPageClinicSelected = 1;
  @observable clinicKeyword = '';
  @observable totalPageClinic = 0;
  @observable totalPageClinicSelected = 0;

  // @observable open = false;
  @observable modalParams = {};
  @observable totalItemsClinic = 0;
  @observable totalItemsClinicSelected = 0;

  @action setIsEditing = value => {
    if (value) {
      this.oldDataDoctor = toJS(this.dataDoctor);
      this.oldSelectedAcceptedReportTypes = toJS(this.selectedAcceptedReportTypes);
      this.oldLetterHeadAttachments = toJS(this.letterHeadAttachments);
      this.oldProfilePictureAttachments = toJS(this.profilePictureAttachments);
      this.oldSampleReports = toJS(this.sampleReports);
      this.oldSignatures = toJS(this.signatures);
      this.oldSelectedLocationsId = toJS(this.selectedLocationsId);
    } else {
      if (!this.dataDoctor.Id) {
        this.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => window.closeCurrentTab(),
          message: `Any changes you haven't save will be lost. Are you sure to not save changes?`,
        })();
        return;
      } else {
        this.dataDoctor = toJS(this.oldDataDoctor);
        this.selectedAcceptedReportTypes = toJS(this.oldSelectedAcceptedReportTypes);
        this.letterHeadAttachments = toJS(this.oldLetterHeadAttachments);
        this.aHRPAAccreditationsAttachments = toJS(this.aHRPAAccreditationsAttachments);
        this.profilePictureAttachments = toJS(this.oldProfilePictureAttachments);
        this.sampleReports = toJS(this.oldSampleReports);
        this.signatures = toJS(this.oldSignatures);
        this.selectedLocationsId = toJS(this.oldSelectedLocationsId);
        this.fetchSubSpecialties();
      }
    }
    this.isEditing = value;
  };

  validatePhone = (phone, sixValid) => {
    if (!phone) {
      return true;
    }
    const value = phone.replace(/-/g, '').replace(/ /g, '');
    if (value.match(/[^0-9]/)) {
      return false;
    }
    switch (value.length) {
      case 6:
        return sixValid;
      case 10:
        return true;
      default:
        return false;
    }
  };

  compareDate = (date1, date2) => {
    return moment(date1, 'DD/MM/YYYY').isSameOrBefore(moment(date2, 'DD/MM/YYYY'), 'day');
  };

  validateEmail = email => {
    const v = new Validator({ email }, { email: 'email' });
    return v.passes();
  };

  validateDoctor = doctorParam => {
    const errors = [];
    if (!doctorParam.IsRecruiting && !doctorParam.IsOnboarding && !doctorParam.HasContractWithMag) {
      errors.push(`Please select at least one recruitment status.`);
    }
    if (doctorParam.EnableLogin && !doctorParam.Email2) {
      errors.push('Please input business email address.');
    }
    if (doctorParam.IsRecruiting && !doctorParam.RecruitingDate) {
      errors.push('Please input date of recruiting.');
    }
    if (doctorParam.IsNotCurrentlyInterested && !doctorParam.NotCurrentlyInterestedDate) {
      errors.push('Please input start date of NCI.');
    }
    if (doctorParam.IsOnboarding && !doctorParam.OnboardingDate) {
      errors.push('Please input date of onboarding.');
    }
    if (!doctorParam.FirstName) {
      errors.push(`Please input first name.`);
    }
    if (!doctorParam.LastName) {
      errors.push(`Please input surname.`);
    }
    if (
      !doctorParam.IsSendBookingConfToEmail2 &&
      !doctorParam.IsSendBookingConfToEmail &&
      !doctorParam.IsSendBookingConfToSecretaryEmail
    ) {
      errors.push('Please select at least one email for sending booking-related emails.');
    }
    if (!this.validatePhone(numberParser(doctorParam.Telephone), false)) {
      errors.push('Valid mobile numbers should have 10 digits.');
    }
    if (!this.validatePhone(numberParser(doctorParam.OfficePhone), true)) {
      errors.push('Valid phone numbers should have 6 or 10 digits (including area code).');
    }
    if (!this.validatePhone(doctorParam.SecretaryTelephone, true)) {
      errors.push('Valid secretary/practice manager phone numbers should have 6 or 10 digits (including area code).');
    }
    if (!this.validatePhone(doctorParam.Fax, true)) {
      errors.push('Valid fax numbers should have 6 or 10 digits (including area code).');
    }
    if (!!doctorParam.MAGChargeRate && !doctorParam.MAGChargeRateFrom) {
      errors.push('Please input effective from of MAG Commission Rate 1.');
    }
    if (!!doctorParam.MAGChargeRate2 && !doctorParam.MAGChargeRate2From) {
      errors.push('Please input effective from of MAG Commission Rate 2.');
    }

    if (!!doctorParam.MAGChargeRateFrom && !!doctorParam.MAGChargeRate2From) {
      if (
        !!doctorParam.MAGChargeRateTo &&
        this.compareDate(doctorParam.MAGChargeRate2From, doctorParam.MAGChargeRateTo)
      ) {
        errors.push('Effective date of MAG Commission Rate 2 must be after effective period of MAG Commission Rate 1.');
      }
      if (
        !doctorParam.MAGChargeRateTo &&
        this.compareDate(doctorParam.MAGChargeRate2From, doctorParam.MAGChargeRateFrom)
      ) {
        errors.push('Effective date of MAG Commission Rate 2 must be after effective period of MAG Commission Rate 1.');
      }
    }
    if (!!doctorParam.ChargeRate && !doctorParam.ChargeRateFrom) {
      errors.push('Please input effective from of Non-MAG Commission Rate 1.');
    }
    if (!!doctorParam.ChargeRate2 && !doctorParam.ChargeRate2From) {
      errors.push('Please input effective from of Non-MAG Commission Rate 2.');
    }
    if (!!doctorParam.ChargeRateFrom && !!doctorParam.ChargeRate2From) {
      if (!!doctorParam.ChargeRateTo && this.compareDate(doctorParam.ChargeRate2From, doctorParam.ChargeRateTo)) {
        errors.push(
          'Effective date of Non-MAG Commission Rate 2 must be after effective period of Non-MAG Commission Rate 1.',
        );
      }
      if (!doctorParam.ChargeRateTo && this.compareDate(doctorParam.ChargeRate2From, doctorParam.ChargeRateFrom)) {
        errors.push(
          'Effective date of Non-MAG Commission Rate 2 must be after effective period of Non-MAG Commission Rate 1.',
        );
      }
    }
    if (!!doctorParam.CommissionOnTop && !doctorParam.CommissionOnTopFrom) {
      errors.push('Please input effective from of Gross Margin COT 1.');
    }
    if (!!doctorParam.CommissionOnTop2 && !doctorParam.CommissionOnTop2From) {
      errors.push('Please input effective from of Gross Margin COT 2.');
    }
    if (!!doctorParam.CommissionOnTopFrom && !!doctorParam.CommissionOnTop2From) {
      if (
        !!doctorParam.CommissionOnTopTo &&
        this.compareDate(doctorParam.CommissionOnTop2From, doctorParam.CommissionOnTopTo)
      ) {
        errors.push('Effective date of Gross Margin COT 2 must be after effective period of Gross Margin COT 1.');
      }
      if (
        !doctorParam.CommissionOnTopTo &&
        this.compareDate(doctorParam.CommissionOnTop2From, doctorParam.CommissionOnTopFrom)
      ) {
        errors.push('Effective date of Gross Margin COT 2 must be after effective period of Gross Margin COT 1.');
      }
    }
    //Comment
    if (!!doctorParam.Comment && doctorParam.Comment.length > 750) {
      errors.push('Doctor’s brief profile exceeds word counts. Shorten it before doctor information can be saved.');
    }
    if (!!doctorParam.ConsultingLocations && doctorParam.ConsultingLocations.length > 2000) {
      errors.push('Too many consulting locations are selected. Could you please narrow down the list?');
    }

    if (!this.validateEmail(doctorParam.Email)) {
      errors.push('Personal email is invalid');
    }
    if (!this.validateEmail(doctorParam.Email2)) {
      errors.push('Business email is invalid');
    }
    if (!this.validateEmail(doctorParam.SecretaryEmail)) {
      errors.push('Secretary email is invalid');
    }
    if (!this.validateEmail(doctorParam.AccountingEmail1)) {
      errors.push('Accounting email 1 is invalid');
    }
    if (!this.validateEmail(doctorParam.AccountingEmail2)) {
      errors.push('Accounting email 2 is invalid is invalid');
    }
    if (errors.length) {
      this.toggleModal(true, {
        modalType: 'alert',
        message: errors,
      })();
      return false;
    }
    return true;
  };

  formatReqDate = date => {
    return date ? moment(date, 'DD/MM/YYYY').toISOString() : null;
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.destroy();
    notification.error({
      message: 'Error',
      description: message,
    });
  };

  @action saveEdit = () => {
    const doctorParam = toJS(this.dataDoctor);
    if (!this.validateDoctor(doctorParam)) {
      return;
    }

    const selectedIndustryServiceTypes = doctorParam.IndustryExpertTypes.map(indId => {
      return toJS(this.dataLookupConfig.indItemList.find(ind => ind.Id === indId));
    });
    const selectedSpecialties = doctorParam.Specialties.map(speId => {
      return toJS(this.dataLookupConfig.speItemList.find(spe => spe.Id === speId));
    });
    const selectedSubSpecialties = doctorParam.SubSpecialties.map(speId => {
      return toJS((this.dataLookupConfigByParents?.itemList || []).find(spe => spe.Id === speId));
    });
    doctorParam.AccreditationList = doctorParam.AccreditationList.map(accId => {
      return toJS(this.dataLookupConfig.accreditationList.find(acc => acc.Id === accId));
    });
    this.loading = true;
    customFetch('/MedicalDoctor/Save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorParam: {
          ...doctorParam,
          selectedIndustryServiceTypes,
          selectedSpecialties,
          selectedSubSpecialties,
          RegisterOption: doctorParam.RegisterOption || null,
          Qualifications: doctorParam.Qualifications?.toString() || '',
          ConsultingLocationStates: doctorParam.ConsultingLocationStates?.toString() || '',
          ConsultingLocations: doctorParam.ConsultingLocations.map(loc => {
            return [loc.id, loc.location, loc.postcode].join('|');
          }).toString(),
          ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
          ContractWithMagDate: this.formatReqDate(doctorParam.ContractWithMagDate),
          ContractWithMagEndDate: this.formatReqDate(doctorParam.ContractWithMagEndDate),
          NotCurrentlyInterestedDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedDate),
          NotCurrentlyInterestedEndDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedEndDate),
          RecruitingDate: this.formatReqDate(doctorParam.RecruitingDate),
          OnboardingDate: this.formatReqDate(doctorParam.OnboardingDate),
          QualificationReceivedDate: this.formatReqDate(doctorParam.QualificationReceivedDate),
          AccreditationReceivedDate: this.formatReqDate(doctorParam.AccreditationReceivedDate),
          SubmittedForReviewDate: this.formatReqDate(doctorParam.SubmittedForReviewDate),
          ActionRequiredDate: this.formatReqDate(doctorParam.ActionRequiredDate),
          SignatureReceivedDate: this.formatReqDate(doctorParam.SignatureReceivedDate),
          PreferenceReceivedDate: this.formatReqDate(doctorParam.PreferenceReceivedDate),
          CvReceivedDate: this.formatReqDate(doctorParam.CvReceivedDate),
          ActionRequiredDueDate: this.formatReqDate(doctorParam.ActionRequiredDueDate),
          ActionRequiredCompletedDate: this.formatReqDate(doctorParam.ActionRequiredCompletedDate),
          ApprovedDate: this.formatReqDate(doctorParam.ApprovedDate),
          MAGChargeRateFrom: this.formatReqDate(doctorParam.MAGChargeRateFrom),
          MAGChargeRateTo: this.formatReqDate(doctorParam.MAGChargeRateTo),
          MAGChargeRate2From: this.formatReqDate(doctorParam.MAGChargeRate2From),
          MAGChargeRate2To: this.formatReqDate(doctorParam.MAGChargeRate2To),
          ChargeRateFrom: this.formatReqDate(doctorParam.ChargeRateFrom),
          ChargeRateTo: this.formatReqDate(doctorParam.ChargeRateTo),
          ChargeRate2From: this.formatReqDate(doctorParam.ChargeRate2From),
          ChargeRate2To: this.formatReqDate(doctorParam.ChargeRate2To),
          CommissionOnTopFrom: this.formatReqDate(doctorParam.CommissionOnTopFrom),
          CommissionOnTopTo: this.formatReqDate(doctorParam.CommissionOnTopTo),
          CommissionOnTop2From: this.formatReqDate(doctorParam.CommissionOnTop2From),
          CommissionOnTop2To: this.formatReqDate(doctorParam.CommissionOnTop2To),
          DictationOptionStartDate: this.formatReqDate(doctorParam.DictationOptionStartDate),
          DictationOptionEndDate: this.formatReqDate(doctorParam.DictationOptionEndDate),
        },
        isRateChange: false,
        letterHeadAttachments: toJS(this.letterHeadAttachments),
        aHRPAAccreditationsAttachments: toJS(this.aHRPAAccreditationsAttachments),
        oldChargeRate: null,
        oldMAGChargeRate: null,
        profilePictureAttachments: toJS(this.profilePictureAttachments),
        rateChangePassword: '',
        ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
        sampleReports: toJS(this.sampleReports),
        selectedAcceptedReportTypes: this.selectedAcceptedReportTypes?.map(a => a) || [],
        selectedAccreditations: doctorParam.AccreditationList?.map(a => a.Id) || [],
        selectedIndustryServiceTypes,
        selectedSpecialties,
        selectedSubSpecialties,
        signatures: toJS(this.signatures),
      }),
    })
      .then(
        action(async res => {
          this.loading = false;
          this.isEditing = false;
          if (res.status === 'success') {
            if (!doctorParam.Id && !!this.selectedClinicsId.length) {
              await customFetch('/MedicalDoctor/UpdateDoctorClinic', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                  clinics: toJS(this.selectedClinicsId),
                  doctorId: res.newId,
                }),
              });
            }
            this.fetchDoctorProfile();
            return;
          }
          return;
        }),
      )
      .catch(
        action(errors => {
          const errorList = Object.entries(errors);
          errorList.map(([key, value]) => {
            if (key === 'Email2' && value === 'exists') {
              this.showFormErrorNoti(
                `The registered email has been used in another account. Please check for duplicate account under Login Accounts section`,
              );
            } else {
              this.showFormErrorNoti(`${key} is ${value}`);
            }
          });
          this.loading = false;
        }),
      );
  };

  @action setFieldValue = (_, { name, value }) => {
    this.dataDoctor[name] = value;

    if (name === 'AssignedToId') {
      const assignedStaff = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.AssignedToId);
      this.dataDoctor.AssignedToUserFullName = assignedStaff.FullName;
    } else if (name === 'ActionRequiredStaffId') {
      const assignedStaff = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.ActionRequiredStaffId);
      this.dataDoctor.ActionRequiredStaffName = assignedStaff.FullName;
    } else if (name === 'ApprovedBy') {
      const approvedBy = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.ApprovedBy);
      this.dataDoctor.ApprovedName = approvedBy.FullName;
    } else if (name === 'OfficePhone' || name === 'Telephone') {
      this.dataDoctor[name] = value.replace(/[^\d]/g, '');
    }
  };

  @action toggleCheckbox = (_, { name, checked }) => {
    if (this.isEditing) {
      if (name === 'IsRecruiting' && checked) {
        this.dataDoctor.RecruitingDate = moment().format('DD/MM/YYYY');
      }
      if (name === 'IsOnboarding' && checked) {
        this.dataDoctor.OnboardingDate = moment().format('DD/MM/YYYY');
      }
      if (name === 'HasContractWithMag' && checked) {
        this.dataDoctor.ContractWithMagDate = moment().format('DD/MM/YYYY');
      }

      if (name === 'IsNotCurrentlyInterested' && checked) {
        this.dataDoctor.AvailableForCMView = false;
        this.dataDoctor.SubscribeToPromotionCampaign = false;
        this.dataDoctor.NotCurrentlyInterestedDate = moment().format('DD/MM/YYYY');
      } else if ((name === 'AvailableForCMView' || name === 'SubscribeToPromotionCampaign') && checked) {
        this.dataDoctor.IsNotCurrentlyInterested = false;
      }
      this.dataDoctor[name] = checked;
    }
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action refetchClinic = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClinic(setTotalPage);
  };

  @action fetchSelectedClinic = list => {
    customFetch('/Clinic/GetClinicByIdList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(list),
    }).then(
      action(data => {
        this.clinicList = data;
        this.totalItemsClinicSelected = data.sumItem;
        this.totalPageClinicSelected = data.sumPage;
      }),
    );
  };

  @action fetchClinic = (setTotalPage = false) => {
    this.loadingClinic = true;
    customFetch('/Clinic/GetClinicList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: '',
        CurPage: this.curPageClinic,
        NumItemPerPage: this.pageSize,
        IsSelectionList: true,
      }),
    }).then(
      action(data => {
        this.dataClinicList = data;
        this.totalPageClinic = setTotalPage ? data.sumPage : this.totalPageClinic;
        this.totalItemsClinic = setTotalPage ? data.sumItem : this.totalItemsClinic;
        this.loadingClinic = false;
      }),
    );
  };

  @action fetchListClinicDoctor = id => {
    customFetch('/MedicalDoctor/GetDoctorClinics', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorId: id,
      }),
    }).then(action(data => (this.clinicListId = data)));
  };

  @action fetchSubSpecialties = () => {
    customFetch('/MedicalDoctor/GetLookupConfigByParents', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        parents: this.dataDoctor.Specialties.map(s => {
          return this.dataLookupConfig.speItemList.find(i => i.Id === s);
        }),
      }),
    }).then(
      action(data => {
        this.dataLookupConfigByParents = data;
        this.dataDoctor.SubSpecialties = this.dataDoctor.SubSpecialties.filter(sId => {
          return data.itemList.find(i => i.Id === sId);
        });
      }),
    );
  };

  @action fetchLocations = (_, { searchQuery }) => {
    if (searchQuery.length < 3) {
      this.locationList = [];
      return;
    }

    customFetch(`/api/auspostSuburb?q=${searchQuery}&excludePostBoxFlag=true`, {
      method: 'GET',
    }).then(
      action(data => {
        this.locationList = data;
      }),
    );
  };

  @action fetchConversations = () => {
    customFetch('/MedicalDoctor/ConversationLog_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorId: this.dataDoctor.Id,
      }),
    }).then(
      action(data => {
        this.dataConversationLogLoadList = data;
      }),
    );
  };

  @action fetchCaseManager = (keyword = '', numItemPerPage = 30) => {
    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        Showing: 'active',
        curPage: 1,
        keyword,
        numItemPerPage,
      }),
    }).then(
      action(data => {
        this.dataCaseManager = data.itemList;
      }),
    );
  };

  formatResDate = date => {
    return date ? moment(formatDate(date), 'DD MMM YYYY').format('DD/MM/YYYY') : '';
  };

  fetchCity = (countryId = 16) => {
    return customFetch('/MedicalDoctor/City_GetList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        countryId,
      }),
    });
  };

  @action fetchDoctorProfile = async () => {
    this.loading = true;
    try {
      await customFetch('/MedicalDoctor/DoctorAccountInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }).then(
        action(data => {
          this.dataDoctor = {
            ...data,
            RegisterOption: data.RegisterOption || 0,
            ConsultingLocationStates: data.ConsultingLocationStates?.split(',') || [],
            Qualifications: data.Qualifications?.split(',') || [],
            Specialties: data.Specialties.map(a => a.Id),
            SubSpecialties: data.SubSpecialties.map(a => a.Id),
            AccreditationList: data.AccreditationList.map(a => a.Id).filter(i => i),
            ReportTemplatesAttachments: JSON.parse(data.ReportTemplatesAttachments) || [],
            ContractWithMagDate: this.formatResDate(data.ContractWithMagDate),
            ContractWithMagEndDate: this.formatResDate(data.ContractWithMagEndDate),
            NotCurrentlyInterestedDate: this.formatResDate(data.NotCurrentlyInterestedDate),
            NotCurrentlyInterestedEndDate: this.formatResDate(data.NotCurrentlyInterestedEndDate),
            RecruitingDate: this.formatResDate(data.RecruitingDate),
            OnboardingDate: this.formatResDate(data.OnboardingDate),
            QualificationReceivedDate: this.formatResDate(data.QualificationReceivedDate),
            AccreditationReceivedDate: this.formatResDate(data.AccreditationReceivedDate),
            SubmittedForReviewDate: this.formatResDate(data.SubmittedForReviewDate),
            ActionRequiredDate: this.formatResDate(data.ActionRequiredDate),
            ActionRequiredDueDate: this.formatResDate(data.ActionRequiredDueDate),
            ActionRequiredCompletedDate: this.formatResDate(data.ActionRequiredCompletedDate),
            ApprovedDate: this.formatResDate(data.ApprovedDate),
            MAGChargeRateFrom: this.formatResDate(data.MAGChargeRateFrom),
            MAGChargeRateTo: this.formatResDate(data.MAGChargeRateTo),
            MAGChargeRate2From: this.formatResDate(data.MAGChargeRate2From),
            MAGChargeRate2To: this.formatResDate(data.MAGChargeRate2To),
            ChargeRateFrom: this.formatResDate(data.ChargeRateFrom),
            ChargeRateTo: this.formatResDate(data.ChargeRateTo),
            ChargeRate2From: this.formatResDate(data.ChargeRate2From),
            ChargeRate2To: this.formatResDate(data.ChargeRate2To),
            CommissionOnTopFrom: this.formatResDate(data.CommissionOnTopFrom),
            CommissionOnTopTo: this.formatResDate(data.CommissionOnTopTo),
            CommissionOnTop2From: this.formatResDate(data.CommissionOnTop2From),
            CommissionOnTop2To: this.formatResDate(data.CommissionOnTop2To),
            DictationOptionStartDate: this.formatResDate(data.DictationOptionStartDate),
            DictationOptionEndDate: this.formatResDate(data.DictationOptionEndDate),
          };
          this.handleReactionProvider();
          this.selectedAcceptedReportTypes = data.AcceptedReportTypes.map(a => a.Id);

          const consultingLocations = data.ConsultingLocations?.split(',') || [];
          this.dataDoctor.ConsultingLocations = consultingLocations.map(loc => {
            const [locId, location, postcode] = loc.split('|');
            return { id: locId, location, postcode };
          });

          this.selectedLocationsId = consultingLocations.map(l => l.split('|')[0]);

          if (data.Attachments.length) {
            this.sampleReports = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.SampleReport;
            });
            this.signatures = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.Signature;
            });
            this.letterHeadAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.LetterHead;
            });
            this.aHRPAAccreditationsAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.AHRPAAccreditation;
            });
            this.profilePictureAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.ProfilePicture;
            });
          } else {
            this.sampleReports = [];
            this.signatures = [];
            this.letterHeadAttachments = [];
            this.aHRPAAccreditationsAttachments = [];
            this.profilePictureAttachments = [];
          }
          this.selectedClinicsId = [];
        }),
      );
      await customFetch('/MedicalDoctor/GetDoctorClinics', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          doctorId: this.dataDoctor.Id,
        }),
      }).then(
        action(async list => {
          this.clinicListId = list;
          await customFetch('/Clinic/GetClinicByIdList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(list.itemList),
          }).then(
            action(data => {
              this.clinicList = data;
              this.totalItemsClinicSelected = data.sumItem;
              this.totalPageClinicSelected = data.sumPage;
            }),
          );
        }),
      );
      await customFetch('/MedicalDoctor/ConversationLog_LoadList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          doctorId: this.dataDoctor.Id,
        }),
      }).then(
        action(data => {
          this.dataConversationLogLoadList = data;
        }),
      );
      await customFetch('/Default/GetActiveReportTypeList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataActiveReportTypeList = data;
        }),
      );
      await customFetch('/MedicalDoctor/GetLookUpConfig', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'doctor',
        }),
      }).then(
        action(data => {
          this.dataLookupConfig = data;
        }),
      );
      this.fetchCaseManager();
      await customFetch('/MedicalDoctor/GetStaffs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataStaff = data;
        }),
      );
      await customFetch('/MedicalDoctor/GetStates', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataState = data;
        }),
      );
      await customFetch('/Clinic/Country_GetList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }).then(
        action(data => {
          this.dataCountry = data.itemList;
        }),
      );
      await customFetch(`/MedicalDoctor/GetTimeOfProcessComplete?doctorId=${api.currentUser.data.DoctorId}`, {}).then(
        action(res => (this.dataChart = res)),
      );

      await customFetch('/MedicalDoctor/GetLookupConfigByParents', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          parents: this.dataDoctor.Specialties.map(s => {
            return this.dataLookupConfig.speItemList.find(i => i.Id === s);
          }),
        }),
      }).then(
        action(data => {
          this.dataLookupConfigByParents = data;
          this.loading = false;
        }),
      );
    } catch (e) {}
  };

  @computed get dataIndustryExpertTypes() {
    return this.dataLookupConfig.indItemList.map(ind => ({
      value: ind.Id,
      text: ind.Label,
    }));
  }

  @computed get dataReportTypes() {
    return this.dataActiveReportTypeList.itemList.map(t => ({
      value: t.Id,
      text: t.Name,
    }));
  }

  @computed get dataLocations() {
    return [...this.locationList, ...this.dataDoctor.ConsultingLocations].map(l => ({
      value: l.id,
      text: `${l.location} - Postcode: ${l.postcode}`,
    }));
  }

  @computed get dataStates() {
    return this.dataState.itemList.map(s => ({
      value: s,
      text: s,
    }));
  }

  @computed get dataSpecialties() {
    return this.dataLookupConfig.speItemList.map(s => ({
      value: s.Id,
      text: s.Label,
    }));
  }

  @computed get dataSubSpecialties() {
    return (this.dataLookupConfigByParents?.itemList || []).map(s => ({
      value: s.Id,
      text: s.Label,
    }));
  }

  @computed get dataQualifications() {
    return this.dataLookupConfig.quaItemList.map(q => ({
      value: q,
      text: q,
    }));
  }

  @computed get dataAccreditations() {
    return this.dataLookupConfig.accreditationList.map(a => ({
      value: a.Id,
      text: a.Label,
    }));
  }

  @computed get dataStaffs() {
    return this.dataStaff.itemList.map(s => ({
      value: s.Id,
      text: s.FullName,
    }));
  }

  @computed get dataCMs() {
    return this.dataCaseManager.map(s => ({
      value: s.Id,
      text: s.FullName,
    }));
  }

  @computed get getCountryList() {
    return this.dataCountry.map(s => ({
      value: s.CountryId,
      text: s.CountryName,
    }));
  }

  @computed get getCityList() {
    return this.dataCity.map(s => ({
      value: s.CityId,
      text: s.CityName,
    }));
  }

  @computed get getMailingCityList() {
    return this.dataMailingCity.map(s => ({
      value: s.CityId,
      text: s.CityName,
    }));
  }

  @computed get selectedReportTypes() {
    return this.selectedAcceptedReportTypes.map(typeId => {
      return this.dataActiveReportTypeList.itemList.find(type => type.Id === typeId)?.Name;
    });
  }

  @computed get selectedLocations() {
    return this.dataDoctor.ConsultingLocations.map(l => `${l.location} - Postcode: ${l.postcode}`);
  }

  @computed get selectedSpecialties() {
    return this.dataDoctor.Specialties.map(speId => {
      return this.dataLookupConfig.speItemList.find(spe => spe.Id === speId)?.Label;
    });
  }

  @computed get selectedSubSpecialties() {
    return this.dataDoctor.SubSpecialties.map(speId => {
      return (this.dataLookupConfigByParents?.itemList || []).find(spe => spe.Id === speId)?.Label;
    });
  }

  @computed get selectedAccreditations() {
    return this.dataDoctor.AccreditationList.map(accId => {
      return this.dataLookupConfig.accreditationList.find(acc => acc.Id === accId)?.Label;
    });
  }

  @computed get selectedCountry() {
    return this.dataCountry.find(c => c.CountryId === this.dataDoctor.CountryId)?.CountryName;
  }

  @computed get selectedMailingCountry() {
    return this.dataCountry.find(c => c.CountryId === this.dataDoctor.CorrespondenceCountryId)?.CountryName;
  }

  @computed get selectedCity() {
    return this.dataCity.find(c => c.CityId === this.dataDoctor.CityId)?.CityName;
  }

  @computed get selectedMailingCity() {
    return this.dataMailingCity.find(c => c.CityId === this.dataDoctor.CorrespondenceCityId)?.CityName;
  }

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  formatPhoneNumber = (pn, type) => {
    if (pn) {
      const phone = pn.replace(/[^\d]/g, '');
      const phoneLength = phone.length;
      if (type === 'tele') {
        if (phoneLength < 4) return phone;
        return `${phone.slice(0, 4)} ${phone.slice(4)}`;
      } else {
        if (phoneLength < 2) return phone;
        return `${phone.slice(0, 2)} ${phone.slice(2)}`;
      }
    }
    return pn;
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };
  @action resetStore = () => {
    this.clinicKeyword = '';
    this.totalPage = 0;
    this.totalItemsClinic = 0;
    this.handleDisposeReaction();
  };

  reactionProvider = null;
  reactionProvider2 = null;
  reactionProvider3 = null;
  reactionProvider4 = null;
  handleDisposeReaction = () => {
    this.reactionProvider && this.reactionProvider();
    this.reactionProvider2 && this.reactionProvider2();
    this.reactionProvider3 && this.reactionProvider3();
    this.reactionProvider4 && this.reactionProvider4();
  };

  handleReactionProvider = () => {
    this.reactionProvider = reaction(
      () => this.dataDoctor.ProviderNumber,
      () => {
        if (!this.dataDoctor.ProviderNumber) {
          // this.dataDoctor.ProviderNumberNote = '';
          // this.dataDoctor.ProviderNumber2 = '';
          // this.dataDoctor.ProviderNumber2Note = '';
        }
      },
    );

    this.reactionProvider2 = reaction(
      () => this.dataDoctor.ProviderNumber2,
      () => {
        if (!this.dataDoctor.ProviderNumber2) {
          // this.dataDoctor.ProviderNumber2Note = '';
          // this.dataDoctor.ProviderNumber3 = '';
          // this.dataDoctor.ProviderNumber3Note = '';
        }
      },
    );

    this.reactionProvider3 = reaction(
      () => this.dataDoctor.ProviderNumber3,
      () => {
        if (!this.dataDoctor.ProviderNumber3) {
          // this.dataDoctor.ProviderNumber3Note = '';
          // this.dataDoctor.ProviderNumber4 = '';
          // this.dataDoctor.ProviderNumber4Note = '';
        }
      },
    );
    this.reactionProvider4 = reaction(
      () => this.dataDoctor.ProviderNumber4,
      () => {
        if (!this.dataDoctor.ProviderNumber4) {
          // this.dataDoctor.ProviderNumber4Note = '';
          // this.dataDoctor.ProviderNumber4 = '';
          // this.dataDoctor.ProviderNumber4Note = '';
        }
      },
    );
  };
}

export default new DoctorDetailsStore();
