import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import * as api from '../../../stores/api';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import ServiceFeeStore from './ServiceFeeStore';

const columns = [
  { title: '' },
  { title: 'Item Code', sortKey: 'ItemCode' },
  { title: 'Item Name', sortKey: 'Name' },
  { title: 'Description' },
  { title: 'Service Group', sortKey: 'ServiceGroupName' },
  { title: 'Service Rate', sortKey: 'ApplicablePrice' },
  { title: 'GST', sortKey: 'Tax' },
];

@observer
class ServiceFeeTable extends React.Component {
  openModal = options => {
    return ServiceFeeStore.toggleModal(true, options);
  };

  openModalEdit = (id, record) => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'createServiceFee',
      id,
      record,
    });
  };

  handleDelete = id => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MAGService/DeleteServiceItem', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await ServiceFeeStore.refetch({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = (id, record) => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MAGService/ChangeStatusServiceItem', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.Status }),
      });
      await ServiceFeeStore.refetch({}, true);
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false });
    }
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.Status}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell>{record.ItemCode}</Table.Cell>
        <Table.Cell selectable className={cx({ 'Cell-nowrap Cell-link': record.Status })}>
          <Link to="#" onClick={this.openModalEdit(Id, record)} style={{ color: `#9f3a38` }}>
            {record.Name}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
        <Table.Cell>{record.ServiceGroupName}</Table.Cell>
        <Table.Cell>${record.ApplicablePrice === null ? 0 : record.ApplicablePrice}</Table.Cell>
        <Table.Cell>{record.Tax}%</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openModalEdit(id, record)}>Edit</Menu.Item>
          {!record.Status && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to active this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Active
            </Menu.Item>
          )}
          {record.Status && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to inactive this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Inactive
            </Menu.Item>
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    ServiceFeeStore.refetch({ curPage: page });
  };
  render() {
    const { data, loading, sortColumn, sortDirection } = ServiceFeeStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;
    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={ServiceFeeStore.handleSort}
        />
        <TableBody>{this.renderTableBody(data, colSpan)}</TableBody>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ServiceFeeStore.curPage}
          totalPage={ServiceFeeStore.totalPage}
          totalItems={ServiceFeeStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ServiceFeeTable;
