import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Icon, TextArea, Input, Select, Checkbox } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { InputNumber, DatePicker, Input as InputText, Select as InputSelect } from 'antd';
import moment from 'moment';
import Modal from '../../shared/Modal';
import Store from './Store';
import customFetch from '../../../utils/customFetch';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/functions';
import { uniqBy } from 'lodash';
import { saveData } from './Service';

const statusOptions = [
  {
    text: 'Active',
    value: 0,
  },
  {
    text: 'Inactive',
    value: 1,
  },
];

@observer
class ModalAddEdit extends React.Component {
  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  constructor(props) {
    Store.fetchCaluationRule();
    super(props);
    this.state = {
      Id: Store.modalParams.id,
      MBSCode:
        Store.modalParams.record && Store.modalParams.record.MBSCode
          ? Store.modalParams.record.MBSCode
          : '',
      Category:
        Store.modalParams.record && Store.modalParams.record.Category
          ? Store.modalParams.record.Category
          : '',
      Group:
        Store.modalParams.record && Store.modalParams.record.Group
          ? Store.modalParams.record.Group
          : '',
      SubGroup:
        Store.modalParams.record && Store.modalParams.record.SubGroup
          ? Store.modalParams.record.SubGroup
          : '',
      Description:
        Store.modalParams.record && Store.modalParams.record.Description
          ? Store.modalParams.record.Description
          : '',
      Anaes:
        Store.modalParams.record && Store.modalParams.record.Anaes
          ? Store.modalParams.record.Anaes
          : false,
      Assist:
        Store.modalParams.record && Store.modalParams.record.Assist
          ? Store.modalParams.record.Assist
          : false,
      ClinicalIndication:
        Store.modalParams.record && Store.modalParams.record.ClinicalIndication
          ? Store.modalParams.record.ClinicalIndication
          : '',
      AdditionalNotes:
        Store.modalParams.record && Store.modalParams.record.AdditionalNotes
          ? Store.modalParams.record.AdditionalNotes
          : '',
      Exclutions:
        Store.modalParams.record && Store.modalParams.record.Exclutions
          ? Store.modalParams.record.Exclutions
          : '',
      ExclutionRange:
        Store.modalParams.record && Store.modalParams.record.ExclutionRange
          ? Store.modalParams.record.ExclutionRange
          : '',
      BaseUnit:
        Store.modalParams.record && Store.modalParams.record.BaseUnit
          ? Store.modalParams.record.BaseUnit
          : '',
      PublishedYear:
        Store.modalParams.record && Store.modalParams.record.PublishedYear
          ? Store.modalParams.record.PublishedYear
          : '',
      DefaultCanculationRule:
      Store.modalParams.record && Store.modalParams.record.DefaultCanculationRule
          ? Store.modalParams.record.DefaultCanculationRule
          : null,
    };

    if( Store.modalParams.record && Store.modalParams.record.Exclutions
      ){
        Store.setArrExclutionId(JSON.parse(Store.modalParams.record.Exclutions));
        Store.setSelectedExclutions(Store.modalParams.record.ListExclutions);
        this.renderExclutions();
      }else{
        Store.setArrExclutionId([]);
        Store.setSelectedExclutions([]);
      }

    if (
      Store.modalParams.record &&
      Store.modalParams.record.ListMBSCodeFee &&
      Store.modalParams.record.ListMBSCodeFee.length > 0
    ) {
      var idxCount = 1;
      var arrFees = [];
      Store.modalParams.record.ListMBSCodeFee.forEach(f => {
        arrFees.push({
          idx: idxCount,
          Id: f.Id,
          MBSCodeId: f.MBSCodeId,
          Fee: f.Fee,
          FromDate: this.momentToJS(f.FromDate),
          EndDate: this.momentToJS(f.EndDate),
          Description: f.Description,
          State: f.State,
        });
        idxCount++;
      });
      Store.setListFee(arrFees);
    } else {
      Store.setListFee([]);
    }
  }

  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  handleResetDataModal = () => {
    Store.setFieldsValue({ isSelectionList: false, arrExclutionId: [], searchExclutionText: '' }, true);
  };

  componentWillUnmount() {
    this.handleResetDataModal();
  }

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  @action onChangeExcludedAMA = (_, { value }) => {
    Store.listExcludedAMA = value;
  };

  handleSave = () => async () => {
    if (!this.state.MBSCode) {
      return;
    }
    Store.setFieldsValue({ loading: true });
    try {
      var request = { ...this.state };
      request.Exclutions = JSON.stringify(Store.arrExclutionId);
      request.ListMBSCodeFee = Store.lstMBSCodeFee;

      await saveData(request).then(respone => {
        if (respone.status === 'success') {
          this.props.onCancel();
        }
      });
      await Store.refetch({}, true);
    } finally {
      Store.setFieldsValue({ loading: false });
    }
  };

  handleChangeDropdown = (_, { name, value }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onChange = event => {
    const { name, value } = event.target;
    if (name === 'Fee') {
      const value_ = (value || '').replace(/\D/g, '').replace(/[^\d]/g, '');
      return this.setState({ ...this.state, [name]: +value_ || 0 });
    }
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  handleChangeSearchDropdown = (_, { searchQuery }) => {
    return Store.refetch({
      searchExclutionText: searchQuery,
      numberPageSearch: 100,
      loadingSearch: true,
      isSelectionList: true,
    });
  };

  onAddExclusionRange = () => {
    var newArr = toJS(Store.lstExclusionRange);
    newArr.push({ idx: newArr.length + 1, Id: 0, AmaCodeId: this.state.Id, ExclusionRange: null });
    Store.setListExclusionRange(newArr);
  };

  handleOnChangeDropDown = name => (e, { value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  onDeleteExclusionRange = idx => {
    var oldArr = toJS(Store.lstExclusionRange);
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(i => {
      if (i.idx !== idx) {
        newArr.push({ idx: countIdx, Id: i.Id, AmaCodeId: i.AmaCodeId, ExclusionRange: i.ExclusionRange });
        countIdx++;
      }
    });
    Store.setListExclusionRange(newArr);
  };

  handleExclusionRange = idx => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var newArray = toJS(Store.lstExclusionRange);
    newArray.forEach(i => {
      if (i.idx === idx) {
        i.ExclusionRange = value;
      }
    });
    Store.setListExclusionRange(newArray);
  };

  @action onExclutionSelectChange = (_, { value, options }) => {
    Store.arrExclutionId = value;
    Store.selectedExclutions = options.filter(o => !!value.find(v => v === o.value));
    Store.searchExclutionText = '';
  };

  renderExclutions = () => {
    var selectedOpts = Store.selectedExclutions || [];
    const mbsSelectionList = uniqBy(
      [...toJS(Store.mbsSelectionList || []), selectedOpts],
      'key',
    );
    
    const options = orderBy(mbsSelectionList || []).map(r => ({
      key: r.value,
      text: `${r.text}`,
      value: r.value,
      content: (
        <div>
          <div className="text-bold">{r.text}</div>
        </div>
      ),
    }));
    return (
      <React.Fragment>
        <Form.Field
          control={Select}
          label="Exclusion Codes"
          placeholder="Exclusion Codes"
          name="ExcludedAMACodes"
          multiple
          search
          allowAdditions
          additionLabel=""
          disabled={Store.loadingSearch}
          selectOnNavigation={false}
          options={[...selectedOpts, ...options]}
          value={toJS(Store.arrExclutionId)}
          onChange={this.onExclutionSelectChange}
          onSearchChange={this.handleChangeSearchDropdown}
          searchQuery={Store.searchExclutionText}
        />
      </React.Fragment>
    );
  };

  onAddFee = () => {
    var newArr = toJS(Store.lstMBSCodeFee);
    newArr.push({
      idx: newArr.length + 1,
      Id: 0,
      AMACodeId: this.state.Id,
      Fee: null,
      FromDate: null,
      EndDate: null,
      Description: null,
      State: null
    });
    //console.log(newArr);
    Store.setListFee(newArr);
  };

  handleChangeFee = (idx, fieldName) => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var newArray = toJS(Store.lstMBSCodeFee);
    newArray.forEach(i => {
      if (i.idx === idx) {
        if (fieldName === 'Fee') i.Fee = value;
        if (fieldName === 'Description') i.Description = value;
      }
    });
    Store.setListFee(newArray);
  };

  handleChangeUnitRate = () => event => {
    const value = event ? (event.target ? event.target.value : event) : null;
    var name = 'UnitRate';
    this.setState({ ...this.state, [name]: value });
  };

  onDeleteFee = idx => {
    var oldArr = toJS(Store.lstMBSCodeFee);
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(i => {
      if (i.idx !== idx) {
        newArr.push({
          idx: countIdx,
          Id: i.Id,
          AMACodeId: i.AMACodeId,
          Fee: i.Fee,
          FromDate: i.FromDate,
          EndDate: i.EndDate,
          Description: i.Description,
          State: i.State
        });
        countIdx++;
      }
    });
    Store.setListFee(newArr);
  };

  @action setFeeDateValue = (idx, name) =>
    action(event => {
      const value = event;
      var newArray = toJS(Store.lstMBSCodeFee);
      newArray.forEach(i => {
        if (i.idx === idx) {
          if (name === 'FromDate') i.FromDate = value;
          if (name === 'EndDate') i.EndDate = value;
        }
      });
      Store.setListFee(newArray);
    });

  handleChecked = (_, { value }) => {
    if (value === 'Anaes') {
      this.setState({ ...this.state, Anaes: !this.state.Anaes });
    }
    if (value === 'Assist') {
      this.setState({ ...this.state, Assist: !this.state.Assist });
    }
  }

  changeFeeStae = (idx) => (_, { value }) => {
    var newArray = toJS(Store.lstMBSCodeFee);
    newArray.forEach(i => {
      if (i.idx === idx) {
        i.State = value;
      }
    });
    Store.setListFee(newArray);
  }

  render() {
    const { open, lstExclusionRange, lstMBSCodeFee, stateList } = Store;
    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave()}>
              <Icon name="save" /> Save
            </Button>
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title="MBS Code"
      >
        <Form onChange={this.onChange}>
          <Form.Field
            control={Input}
            label="MBS Code"
            placeholder="MBS Code"
            value={this.state.MBSCode}
            required
            error={
              this.state.MBSCode === '' || !this.state.MBSCode
                ? {
                    content: 'This field is required',
                  }
                : null
            }
            name="MBSCode"
          />
          <Form.Field
            control={Input}
            label="Category"
            placeholder="Category"
            value={this.state.Category}
            name="Category"
          />
          <Form.Field
            control={Input}
            label="Group"
            placeholder="Group"
            value={this.state.Group}
            name="Group"
          />
          <Form.Field
            control={Input}
            label="Sub Group"
            placeholder="Sub Group"
            value={this.state.SubGroup}
            name="SubGroup"
          />
          <Form.Field
            control={Checkbox}
            label="Anaes"
            value={`Anaes`}
            style={{ marginBottom: 15, marginLeft: 2 }}
            width={5}
            onChange={this.handleChecked}
            checked={this.state.Anaes}
          />
          <Form.Field
            control={Checkbox}
            label="Assist"
            value={`Assist`}
            style={{ marginBottom: 15, marginLeft: 2 }}
            width={5}
            onChange={this.handleChecked}
            checked={this.state.Assist}
          />
          <Form.Field
            control={Select}
            label="Calculation Rule"
            placeholder="Calculation Rule"
            name="CalculationName"
            options={Store.lstCalculationRule.map(opt => ({
              text: opt.CalculationName,
              value: opt.Id,
            }))}
            value={this.state.DefaultCanculationRule}
            onChange={this.handleOnChangeDropDown('DefaultCanculationRule')}
          />
          {this.renderExclutions()}
          <Form.Field
            control={TextArea}
            label="Description"
            placeholder="Description"
            value={this.state.Description}
            name="Description"
          />

          <Form.Field
            control={TextArea}
            label="Clinical Indication"
            placeholder="Clinical Indication"
            value={this.state.ClinicalIndication}
            name="ClinicalIndication"
          />
          <Form.Field
            control={TextArea}
            label="Additional Notes"
            placeholder="Additional Notes"
            value={this.state.AdditionalNotes}
            name="AdditionalNotes"
          />
          <Form.Field
            control={Input}
            type="number"
            label="Base Unit"
            placeholder="Base Unit"
            value={this.state.BaseUnit}
            name="BaseUnit"
          />
          {/* <div className="field">
            <label>Unit Rate</label>
            <InputNumber
                addonBefore="$"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={this.state.UnitRate}
                onChange={this.handleChangeUnitRate()}
              />
          </div> */}
          <div className="field" style={{ marginBottom: '0px' }}>
            <label>Fee Schedule</label>
          </div>
          {!!toJS(lstMBSCodeFee) &&
            toJS(lstMBSCodeFee).map(item => {
              return (
                <React.Fragment>
                  <div style={{height: '40px'}}>
                    <div style={{ width: '100px', float: 'left' }}>
                      <label>
                        Fee {item.idx}{' '}
                        <Link key={'fee-' + item.idx} to="#" onClick={() => this.onDeleteFee(item.idx)}>
                          {' '}
                          Delete
                        </Link>
                      </label>
                      :{' '}
                      <Form.Field
                        control={Input}
                        type="number"
                        placeholder="Fee"
                        value={item.Fee}
                        name="Fee"
                        onChange={this.handleChangeFee(item.idx, 'Fee')}
                      />
                    </div>
                    <div style={{ float: 'left', marginLeft: '1%' }}>
                      <label>State :</label>
                        <Form.Field style={{width: '100px'}}
                          control={Select}
                          placeholder="State"
                          name="State"
                          options={stateList}
                          value={item.State}
                          onChange={this.changeFeeStae(item.idx)}
                        />
                    </div>
                    <div style={{ width: '22%', float: 'left', marginLeft: '1%' }}>
                      <label>Description :</label>
                      <Form.Field
                        control={Input}
                        placeholder="Description"
                        value={item.Description}
                        name="Description"
                        onChange={this.handleChangeFee(item.idx, 'Description')}
                      />
                    </div>
                    <div style={{ width: '20%', float: 'left', marginLeft: '1%' }}>
                      <label>From Date <span style={{color: 'red'}}>(*)</span> :</label>
                      <Form.Field
                        control={DatePicker}
                        format="DD/MM/YYYY"
                        value={this.convertDate(item.FromDate)}
                        name="FromDate"
                        onChange={this.setFeeDateValue(item.idx, 'FromDate')}
                      />
                    </div>
                    <div style={{ width: '20%', float: 'left', marginLeft: '1%' }}>
                      <label>To Date <span style={{color: 'red'}}>(*) :</span></label>
                      <Form.Field
                        control={DatePicker}
                        format="DD/MM/YYYY"
                        value={this.convertDate(item.EndDate)}
                        name="EndDate"
                        onChange={this.setFeeDateValue(item.idx, 'EndDate')}
                      />
                      {/* <DatePicker style={{height: '40px'}}
                        value={this.convertDate(item.EndDate)}
                        format="DD/MM/YYYY"
                        onChange={this.setFeeDateValue(item.idx, 'EndDate')}
                      /> */}
                    </div>
                  </div>
                  <div style={{width: '100%', overflow: 'hidden'}}></div>
                </React.Fragment>
              );
            })}
            
          <Button color="green" onClick={this.onAddFee} style={{ marginTop: '8px' }}>
            <Icon name="plus" />
            Add Fee
          </Button>
          {/* <Form.Field
            control={Input}
            label="Fee"
            placeholder="Scheduled Fee (excl GST)"
            value={`$ ${this.state.Fee}`}
            name="Fee"
            required
            error={
              this.state.Fee === '' || !this.state.Fee
                ? {
                    content: 'This field is required',
                  }
                : null
            }
          /> */}
          <Form.Field
            control={Select}
            label="Status"
            placeholder="Status"
            name="Status"
            value={this.state.Status}
            options={this.renderStatusLabel()}
            onChange={this.handleChangeDropdown}
          />
          <Form.Field
            control={Input}
            label="Published Year"
            placeholder="Published Year"
            value={this.state.PublishedYear}
            name="PublishedYear"
          />
        </Form>
      </Modal>
    );
  }
}

export default ModalAddEdit;
