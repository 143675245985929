import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import Store from './HistoryReportStore';
import HistoryTable from './HistoryTable';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class ExportHistoryReport extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/export-history', true);
      return;
    }
    Store.fetchLoginHistory(true);
  }

  render() {
    const { loadingLogin, dataLogin } = Store;

    return (
      <div className={`Table-${loadingLogin && !dataLogin ? 'loading' : 'container'}`}>
        {loadingLogin && !dataLogin ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <HistoryTable />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(ExportHistoryReport);
