export default [
  {
    AvailableSession: 2,
    TotalSession: 2,
    Month: '/Date(1572526800000)/',
    MonthText: 'November 2019',
    Doctors: [
      {
        ClinicId: 3235,
        ClinicName: 'Sydney - George',
        ClinicAddress: 'Suite 3, 102 Gloucester St, Sydney NSW 2000',
        Id: 2980,
        FirstName: 'Graham',
        LastName: 'George',
        Specialties: 'Psychiatrist',
        Sessions: [
          {
            Appointments: [
              {
                Id: 9748,
                Booked: false,
                Confirmed: true,
                From: '16:45',
                To: '17:45',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1573650000000)/',
            DateText: '14 Nov',
            HasConfirmedSession: true,
            TotalSession: 1,
          },
          {
            Appointments: [
              {
                Id: 9749,
                Booked: false,
                Confirmed: true,
                From: '16:45',
                To: '17:45',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1574859600000)/',
            DateText: '28 Nov',
            HasConfirmedSession: true,
            TotalSession: 1,
          },
        ],
      },
    ],
  },
  {
    AvailableSession: 5,
    TotalSession: 5,
    Month: '/Date(1575118800000)/',
    MonthText: 'December 2019',
    Doctors: [
      {
        ClinicId: 3285,
        ClinicName: 'Sydney',
        ClinicAddress: 'Level 10, Suite 12, 229 Macquarie St, Sydney, 2000, NSW',
        Id: 3016,
        FirstName: 'Bhas',
        LastName: 'Bhattacharyya',
        Specialties: 'Psychiatrist',
        Sessions: [
          {
            Appointments: [
              {
                Id: 9668,
                Booked: false,
                Confirmed: true,
                From: '16:00',
                To: '17:00',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1575291600000)/',
            DateText: '03 Dec',
            HasConfirmedSession: true,
            TotalSession: 1,
          },
          {
            Appointments: [
              {
                Id: 9669,
                Booked: false,
                Confirmed: true,
                From: '16:00',
                To: '17:00',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1575896400000)/',
            DateText: '10 Dec',
            HasConfirmedSession: true,
            TotalSession: 1,
          },
          {
            Appointments: [
              {
                Id: 9670,
                Booked: false,
                Confirmed: true,
                From: '16:00',
                To: '17:00',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1576501200000)/',
            DateText: '17 Dec',
            HasConfirmedSession: true,
            TotalSession: 1,
          },
        ],
      },
      {
        ClinicId: 3122,
        ClinicAddress: 'Suite 214, Level 2 Town Centre. 30 CAMPBELL ST, BLACKTOWN, 2148, NSW',
        Id: 2888,
        ClinicName: 'Blacktown',
        FirstName: 'Anthony',
        LastName: 'Smith',
        Specialties: 'Orthopaedic Surgeon',
        Sessions: [
          {
            Appointments: [
              {
                Id: 4450,
                Booked: false,
                Confirmed: false,
                From: '09:00',
                To: '10:00',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1575378000000)/',
            DateText: '04 Dec',
            HasConfirmedSession: false,
            TotalSession: 1,
          },
          {
            Appointments: [
              {
                Id: 4451,
                Booked: false,
                Confirmed: false,
                From: '09:00',
                To: '10:00',
              },
            ],
            AvailableSession: 1,
            Date: '/Date(1575982800000)/',
            DateText: '11 Dec',
            HasConfirmedSession: false,
            TotalSession: 1,
          },
        ],
      },
    ],
  },
];
