import { action, observable } from 'mobx';
import { uniqueId } from 'lodash';
import Validator from 'validatorjs';
import { notification } from 'antd';
import * as api from '../../../stores/api';
import moment from 'moment';

import {
  getClinicInfo,
  getClinicServices,
  getCountryList,
  getCityList,
  checkDuplicate,
  uploadFile,
  getClinicRooms,
  getRegisteredSpecialists,
  getRegisteredSpecialistDetails,
  saveClinic,
  getAllSpecialist,
  saveSpecialists,
  getStaffAll,
  getStaff,
  getConversationLog,
  deleteConversationLog,
  allowCMViewConversationLog,
  saveConversationLog,
} from './services';

const INIT_CLINIC = {
  AccountingEmail: null,
  Address: null,
  CityId: 0,
  ClinicServiceTypes: [],
  CorrespondenceAddress: null,
  CorrespondenceCityId: 0,
  CorrespondenceCountryId: null,
  CorrespondenceDistrict: null,
  CorrespondencePostcode: null,
  CountryId: null,
  Description: null,
  District: null,
  DoctorId: 0,
  Email: null,
  ID: null,
  IsCNViewLOATemplate: false,
  LOAFileName: null,
  LOAFileTitle: null,
  LOANumberOfPages: null,
  Name: null,
  Postcode: null,
  ProviderNo: null,
  Telephone: null,
  Fax: null,
  Type: 'non-mag',
};

const VALIDATE_RULES = {
  Name: 'required',
  Telephone: 'max:10',
  Email: 'email',
  AccountingEmail: 'email',
  EmailReceiveDailyBookings: 'email',
  CityId: 'required',
  Fax: ['max:10', 'min:6'],
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  EmailReceiveDailyBookings: 'Invalid email address',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
  min: {
    string: 'Must be more than or equal to :min digits',
  },
};

const BODY_CONVERSATIONLOG = {
  CallerSender: '',
  CallerSenderName: '',
  CallerSenderType: 1,
  ClinicId: 0,
  Content: '',
  ConversationType: 1,
  Id: 0,
  Receiver: '',
  ReceiverName: '',
  ReceiverType: 1,
  SentDate: moment(),
  Subject: '',
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: '',
  hour: 0,
  minute: 0,
  receiverId: 0,
};

class ClinicStore {
  @observable loading = false;
  @observable uploadLoading = false;
  @observable duplicateLoading = false;
  @observable cityLoading = false;
  @observable correspondenceCityLoading = false;
  @observable saveLoading = false;
  @observable specialistLoading = false;

  @observable duplicatedData = null;
  @observable countryList = [];
  @observable cityList = [[], []];

  @observable clinicInfo = INIT_CLINIC;
  @observable clinicServices = [];
  @observable rooms = [{ ClinicId: undefined, Id: 'room-1', Name: 'Room 1' }];

  @observable registeredSpecialistIds = [];
  @observable specialists = [];
  @observable sumItem = 0;
  @observable sumPage = 0;
  @observable curPage = 1;
  @observable search = '';
  @observable selected = 'All';

  //Modal
  @observable open = false;
  @observable modalParams = {};

  //Correspondence
  @observable staffList = null;
  @observable staffAllList = null;
  @observable loadingConversation = false;
  @observable conversationParam = BODY_CONVERSATIONLOG;
  @observable conversationList = null;

  @observable errors = {};
  @observable pageGetter = '';

  @action fetchClinicInfo = id => {
    this.loading = true;
    Promise.all([
      getClinicInfo(id),
      getClinicServices(),
      getCountryList(),
      getClinicRooms(id),
      this.fetchSpecialists(id),
      getStaff(),
      getStaffAll(),
      getConversationLog(id),
    ])
      .then(
        action(([info, services, countries, rooms, specialists, staffList, staffAllList, conversation]) => {
          this.clinicInfo = info;
          const updatedClinicInfo = { ...this.clinicInfo };
          updatedClinicInfo['EmailReceiveDailyBookings'] = updatedClinicInfo['Email'];
          this.clinicInfo = updatedClinicInfo;
          this.clinicServices = services.itemList;
          this.countryList = countries.itemList;
          this.rooms = rooms.itemList;

          this.specialists = specialists.itemList;
          this.sumItem = specialists.sumItem;
          this.sumPage = specialists.sumPage;
          this.staffList = staffList;
          this.staffAllList = staffAllList;
          this.conversationList = conversation;
        }),
      )
      .then(() =>
        Promise.all([
          getCityList(this.clinicInfo.CountryId === 0 ? 16 : this.clinicInfo.CountryId),
          getCityList(this.clinicInfo.CorrespondenceCountryId === 0 ? 16 : this.clinicInfo.CorrespondenceCountryId),
        ]),
      )
      .then(
        action(([first, second]) => {
          this.cityList = [first.itemList, second.itemList];
          this.loading = false;
        }),
      );
  };

  @action initClinicData = () => {
    this.loading = true;
    Promise.all([getClinicServices(), getCountryList(), getStaff(), getStaffAll()])
      .then(
        action(([services, countries, staffList, staffAllList]) => {
          this.clinicServices = services.itemList;
          this.countryList = countries.itemList;
          this.staffList = staffList;
          this.staffAllList = staffAllList;
          return countries.itemList.find(c => c.CountryName === 'Australia');
        }),
      )
      .then(({ CountryId }) =>
        Promise.all([
          this.handleCountryChange('CountryId')(CountryId),
          this.handleCountryChange('CorrespondenceCountryId')(CountryId),
        ]),
      )
      .then(() =>
        getAllSpecialist({
          CurPage: 1,
          IsSelectionList: true,
          Keyword: '',
        }),
      )
      .then(
        action(({ itemList, sumItem, sumPage }) => {
          this.curPage = 1;
          this.specialists = itemList;
          this.sumItem = sumItem;
          this.sumPage = sumPage;

          this.rooms = [{ ClinicId: undefined, Id: 'room-1', Name: 'Room 1' }];
          this.registeredSpecialistIds = [];
          if (this.pageGetter === 'addEditCR') {
            this.clinicInfo = { ...INIT_CLINIC, ClinicServiceTypes: [1518] };
          } else if (this.pageGetter === 'addEditIME') {
            this.clinicInfo = { ...INIT_CLINIC, ClinicServiceTypes: [1622] };
          } else {
            this.clinicInfo = { ...INIT_CLINIC };
          }

          this.loading = false;
        }),
      );
  };

  fetchSpecialists = id => {
    return getRegisteredSpecialists(id).then(
      action(({ itemList }) => {
        if (itemList.length) {
          this.registeredSpecialistIds = itemList;
          this.selected = 'selected';
          return getRegisteredSpecialistDetails(itemList);
        }

        this.selected = 'All';
        return getAllSpecialist({
          CurPage: 1,
          IsSelectionList: true,
          Keyword: '',
        });
      }),
    );
  };

  handleFieldChange = fieldName =>
    action(event => {
      let value = event.target ? event.target.value : event;
      if (fieldName === 'IsCNViewLOATemplate' || fieldName === 'EnableReceving') {
        value = event.target.checked;
        if (value && !this.clinicInfo['EmailReceiveDailyBookings']) {
          this.errors = { ...this.errors, EmailReceiveDailyBookings: 'This field is required' };
        } else {
          delete this.errors.EmailReceiveDailyBookings;
        }
      }

      if (fieldName === 'EmailReceiveDailyBookings') {
        if (this.clinicInfo['EnableReceving'] && value === '') {
          this.clinicInfo[fieldName] = value;
          return (this.errors = { ...this.errors, EmailReceiveDailyBookings: 'This field is required' });
        } else {
          delete this.errors.EmailReceiveDailyBookings;
        }
      }
      this.clinicInfo[fieldName] = value;
      if (fieldName === 'Telephone' || fieldName === 'Fax') {
        this.clinicInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      }
      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.clinicInfo[fieldName]);
      }
    });

  @action handleValidate = (field, value) => {
    const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);

    if (validation.passes()) {
      delete this.errors[field];
    } else {
      this.errors[field] = validation.errors.first(field);
    }
  };

  handleCheckDuplicate = type =>
    action(() => {
      const value = this.clinicInfo[type];
      if (!value) return;

      this.duplicateLoading = true;
      checkDuplicate(`CheckDuplicate${type}`, {
        id: this.clinicInfo.ID,
        [type.toLowerCase()]: value,
      }).then(
        action(({ lstObjs }) => {
          this.duplicateLoading = false;
          this.duplicatedData = !lstObjs ? [] : lstObjs;
        }),
      );
    });

  @action handleUpdateClinicInfo = (key, value) => {
    const updatedClinicInfo = { ...this.clinicInfo };
    updatedClinicInfo[key] = value;
    this.clinicInfo = updatedClinicInfo;
  };

  @action handleUploadFile = file => {
    this.uploadLoading = true;

    const formData = new FormData();
    formData.append(file.name, file);

    uploadFile(formData).then(
      action(({ fileList }) => {
        this.clinicInfo.LOAFileName = fileList[0].name;
        this.clinicInfo.LOAFileTitle = fileList[0].title;
        this.clinicInfo.LOANumberOfPages = fileList[0].numberOfPages;
        this.uploadLoading = false;
      }),
    );
  };

  @action handleDeleteFile = () => {
    this.clinicInfo.LOAFileName = null;
    this.clinicInfo.LOAFileTitle = null;
    this.clinicInfo.LOANumberOfPages = null;
  };

  handleCountryChange = type =>
    action(id => {
      const index = type === 'CountryId' ? 0 : 1;
      const cityType = index === 0 ? 'CityId' : 'CorrespondenceCityId';
      const loadingType = index === 0 ? 'cityLoading' : 'correspondenceCityLoading';

      this.clinicInfo[type] = id;
      this.clinicInfo[cityType] = null;
      this.cityList[index] = [];

      this[loadingType] = true;

      return getCityList(id).then(
        action(data => {
          this.cityList[index] = data.itemList;
          this[loadingType] = false;
        }),
      );
    });

  @action handleMailingChange = event => {
    if (event.target.checked) {
      this.clinicInfo.CorrespondenceAddress = this.clinicInfo.Address;
      this.clinicInfo.CorrespondenceCityId = this.clinicInfo.CityId;
      this.clinicInfo.CorrespondenceCountryId = this.clinicInfo.CountryId;
      this.clinicInfo.CorrespondenceDistrict = this.clinicInfo.District;
      this.clinicInfo.CorrespondencePostcode = this.clinicInfo.Postcode;
      this.cityList[1] = this.cityList[0];
    }
  };

  @action handleAddRoom = room => {
    this.rooms = [...this.rooms, { Id: uniqueId('room-'), Name: room, ClinicId: this.clinicInfo.Id }];
  };

  @action handleRemoveRoom = id => {
    this.rooms = this.rooms.filter(room => room.Id !== id);
  };

  @action handleCloseModal = () => {
    this.duplicateLoading = false;
    this.duplicatedData = null;
  };

  @action handleSelectChange = value => {
    this.selected = value;
    this.specialistLoading = true;

    if (value === 'All') {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.search,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          this.specialists = itemList;
          this.sumItem = sumItem;
          this.sumPage = sumPage;
          this.curPage = 1;
          this.specialistLoading = false;
        }),
      );
    } else {
      getRegisteredSpecialists(this.clinicInfo.ID)
        .then(({ itemList }) => getRegisteredSpecialistDetails(itemList))
        .then(
          action(({ itemList, sumPage, sumItem }) => {
            this.specialists = itemList;
            this.sumItem = sumItem;
            this.sumPage = sumPage;
            this.curPage = 1;
            this.specialistLoading = false;
          }),
        );
    }
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handlePageClick = page => {
    if (this.selected === 'All') {
      this.curPage = page;
      this.specialistLoading = true;

      getAllSpecialist({
        CurPage: page,
        IsSelectionList: true,
        Keyword: this.search,
        NumItemPerPage: 10,
      }).then(
        action(({ itemList }) => {
          this.specialists = itemList;
          this.specialistLoading = false;
        }),
      );
    }
  };

  @action handleSearchChange = event => {
    this.search = event.target.value;
  };

  @action handleSearchSpecialist = () => {
    this.specialistLoading = true;

    if (this.selected === 'selected') {
      setTimeout(
        action(() => {
          this.specialistLoading = false;
        }),
        300,
      );
    } else {
      getAllSpecialist({
        CurPage: 1,
        IsSelectionList: true,
        Keyword: this.search,
        NumItemPerPage: 10,
      }).then(
        action(({ itemList, sumItem, sumPage }) => {
          this.specialists = itemList;
          this.sumItem = sumItem;
          this.sumPage = sumPage;
          this.curPage = 1;
          this.specialistLoading = false;
        }),
      );
    }
  };

  @action handleClearSearch = () => {
    this.search = '';
    this.handleSearchSpecialist();
  };

  @action handleSaveSpecialists = id => {
    return saveSpecialists({
      clinicId: id,
      doctors: this.registeredSpecialistIds,
    }).then(
      action(data => {
        this.search = '';
        this.registeredSpecialistIds = [];
        this.saveLoading = false;
        return data;
      }),
    );
  };

  @action handleSave = () => {
    this.saveLoading = true;

    const body = {
      ...this.clinicInfo,
      DoctorId: 0,
      newRoom: '',
      listRooms: this.rooms,
      loaAttachment: this.clinicInfo.LOAFileName
        ? [
            {
              Id: 0,
              FileName: this.clinicInfo.LOAFileName,
              FileType: 'LOLTemplate',
              NumberOfPages: this.clinicInfo.LOANumberOfPages,
              Title: this.clinicInfo.LOAFileTitle,
            },
          ]
        : [],
      selectedClinicServiceTypes: this.clinicServices.reduce((acc, cur) => {
        if (this.clinicInfo.ClinicServiceTypes.find(id => id === cur.Id)) {
          acc.push({ ...cur, Selected: true });
        }
        return acc;
      }, []),
    };

    return saveClinic(body);
  };

  //Case Correspondence
  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleReloadConversation = clinicId => {
    this.loadingConversation = true;
    Promise.all([getConversationLog(clinicId)]).then(
      action(([list]) => {
        this.conversationList = list;
        this.loadingConversation = false;
      }),
    );
  };

  @action handleDeleteConversation = id => {
    this.loadingConversation = true;
    deleteConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.clinicInfo.ID);
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAllowConversation = id => {
    this.loadingConversation = true;
    allowCMViewConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.clinicInfo.ID);
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleResetBodyConversation = () => {
    this.conversationParam = {
      ...BODY_CONVERSATIONLOG,
      ClinicId: this.clinicInfo.ID,
      CallerSender: this.clinicInfo.ID,
      CallerSenderName: this.clinicInfo.FirstName + ' ' + this.clinicInfo.LastName,
      Receiver: this.clinicInfo.ID,
      ReceiverName: this.clinicInfo.FirstName + ' ' + this.clinicInfo.LastName,
    };
  };

  @action handleAddConversation = body => {
    this.loadingConversation = true;
    saveConversationLog(body).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.clinicInfo.ID);
        notification.destroy();
        notification.success({
          description: body.Id === 0 ? 'Add Conversation Log successfully!' : 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.conversationParam[fieldName] = value;
    });

  handleFieldChangeConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'ConversationType') {
        this.conversationParam[fieldName] = event;
      } else if (fieldName === 'Receiver') {
        this.conversationParam.Receiver = value.key;
        this.conversationParam.ReceiverName = value.label;
      } else if (fieldName === 'CallerSender') {
        this.conversationParam.CallerSender = value.key;
        this.conversationParam.CallerSenderName = value.label;
      } else {
        if (fieldName === 'CallerSenderType') {
          if (value === 1) {
            //this.conversationParam.CallerSender = this.CMInfo.Id;
            //this.conversationParam.CallerSenderName =
            //this.CMInfo.FirstName + ' ' + this.CMInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
            this.conversationParam.CallerSender = api.currentUser.data.id;
          }
        }
        if (fieldName === 'ReceiverType') {
          if (value === 1) {
            //this.conversationParam.Receiver = this.CMInfo.Id;
            //this.conversationParam.ReceiverName =
            //this.CMInfo.FirstName + ' ' + this.CMInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.ReceiverName = api.currentUser.data.FullName;
            this.conversationParam.Receiver = api.currentUser.data.id;
          }
        }
        this.conversationParam[fieldName] = value;
      }
    });
}

export default new ClinicStore();
