import React from 'react';
import AdminFileReview from '../admin-routes/FileReview';
import AdminMedNeg from '../admin-routes/MedNeg';
import AddEditMedNeg from '../case-details-routes/AddEditMedNeg';
import AdminIMSApprovalCode from '../admin-routes/IMSApprovalCode';
import AdminIMEAssessments from '../admin-routes/IMEAssessments';
import VideoSession from '../admin-routes/VideoSession';
import AddEditImeAssessments from '../case-details-routes/AddEditImeAssessments';
import AddEditFactualInvestigation from '../case-details-routes/AddEditFactualInvestigation';
import AdminIndustrySegments from '../admin-routes/IndustrySegments';
import JobTitle from '../admin-routes/JobTitle';
import AdminServiceFee from '../admin-routes/ServiceFee';
import AMACalculationRule from '../admin-routes/AMACalculationRule';
import AMAAftercareRule from '../admin-routes/AMAAftercareRule';
import AMATimeUnitSetting from '../admin-routes/AMATimeUnitSetting';
import AMATimeUnitModify from '../admin-routes/AMATimeUnitModify';
import M4ModifierExclusion from '../admin-routes/M4ModifierExclusion';
import AMAFeeCodes from '../admin-routes/AMAFeeCodes';
import MBSCodes from '../admin-routes/MBSCodes';
import ServiceEstimate from '../admin-routes/ServiceEstimate';
import AdminDocAndCons from '../admin-routes/Doctors-Consultants';
import AdminLoginUser from '../admin-routes/LoginUser';
import AddEditStaff from '../case-details-routes/AddEditStaff';
import AdminTypeOfClaims from '../admin-routes/TypeOfClaims';
import DomainExclusion from '../admin-routes/DomainExclusion';
import AdminTypesOfReport from '../admin-routes/TypesOfReport';
import Dashboard from '../DashboardComponent';
import AddEditMySessions from '../doctor-routes/AddEditMySessions';
import Appointments from '../doctor-routes/Appointments';
import DoctorFileReview from '../doctor-routes/FileReview';
import AddEditFileReview from '../case-details-routes/AddEditFileReview';
import IMEReports from '../doctor-routes/IMEReports';
import SupplementaryReports from '../doctor-routes/SupplementaryReports';
import AddEditSupplementaryReport from '../case-details-routes/AddEditSupplementaryReport';
import AdminSupplementaryReports from '../admin-routes/SupplementaryReports';
import Invoices from '../admin-routes/Invoices';
import DoctorReports from '../admin-routes/DoctorReports';
import ClinicalReport from '../admin-routes/ClinicalRecordsRetrievals';
import AddEditClinicalRecord from '../case-details-routes/AddEditClinicalRecord';
import UniversalSearch from '../shared/UniversalSearch';
import DocSearch from '../shared/DocSearch';
import ChatGPT from '../shared/ChatGPT';
import ActionRequiredSearch from '../shared/ActionRequiredSearch';
import DashboardRedirect from './DashboardRedirect';
import MedicalCentres from '../admin-routes/MedicalCentres';
import CaseManagers from '../admin-routes/CaseManagers';
import AddEditCaseManager from '../case-details-routes/AddEditCaseManager';
import AddEditTypist from '../case-details-routes/AddEditTypist';
import Typist from '../admin-routes/Typist';
import Clients from '../admin-routes/Clients';
import AddEditClient from '../case-details-routes/AddEditClient';
import Claimants from '../admin-routes/Claimants';
import AddEditClaimant from '../case-details-routes/AddEditClaimant';
import ImagingServices from '../admin-routes/ImagingServices';
import AddEditImagingService from '../case-details-routes/AddEditImagingService';
import Iframe from './Iframe/Iframe';
import SmartSearch from './SmartSearch/SmartSearch';
import AdminLoginHistoryRP from '../admin-routes/LoginHistoryReport';
import ExportHistoryRP from '../admin-routes/ExportHistoryReport';
import AdminClientReport from '../admin-routes/ClientReport';
import DoctorRemittance from '../admin-routes/DoctorRemittance';
import MessageTemplate from '../admin-routes/MessageTemplate';
import ModuleTypeTemplate from '../admin-routes/ModuleType';
import MyDetails from '../admin-routes/MyDetail';
import CmProifle from '../caseManager-routes/CmProfile';
import AddEditDoctor from '../case-details-routes/AddEditDoctor';
import SystemConfig from '../admin-routes/SystemConfig';
import AddEdditJobTitle from '../case-details-routes/AddEditJobTitle';
import AddEdditTypeClaim from '../case-details-routes/AddEditTypeOfClaim';
import AddEdditTypeReport from '../case-details-routes/AddEditTypeOfReport';
import AddEditClinic from '../case-details-routes/AddEditClinic';
import CMReport from '../admin-routes/CaseManagerReport';
import SendFeedBack from '../admin-routes/SendFeedBack';
import DrProfile from '../doctor-routes/DoctorProfile';
import MarketingCampaign from '../admin-routes/MarketingCampaign';
import CampaignScheduleHistory from '../admin-routes/CampaignScheduleHistory';
import ManageSpeAcc from '../admin-routes/ManageSpeAcc';
import SaleTarget from '../admin-routes/SalesTarget';
import Notification from '../admin-routes/Notification';
import GreetingTitle from '../admin-routes/GreetingTitles';
import PasswordResetHistory from '../admin-routes/PasswordResetHistory';
import BodyParts from '../admin-routes/BodyParts';
import TypeOfWork from '../admin-routes/TypeOfWork';
import IMS from '../admin-routes/IMS';
import TargetAudience from '../admin-routes/TargetAudiences';
import AddEditMarketingCampaign from '../case-details-routes/AddEditMarketingCampaign';
import FundingRequest from '../admin-routes/FundingRequest';
import AddEditFundingRequest from '../case-details-routes/AddEditFundingRequest';
import FundingAgreement from '../admin-routes/FundingAgreement';
import AddEditFundingAgreement from '../case-details-routes/AddEditFundingAgreement';
import FundingReport from '../admin-routes/FundingReport';
import AddEditFundingReport from '../case-details-routes/AddEditFundingReport';
import AddEditLMQCriteria from '../case-details-routes/AddEditLMQCriteria';
import AdminLMQ from '../admin-routes/LMQCriteria';
// import PowerBIReport from '../admin-routes/PowerBIReport';
import PowerBIReport from '../admin-routes/reports/PowerBIReport';
import TalkingPoint from '../admin-routes/TalkingPoint';
import TalkingPointCategory from '../admin-routes/TalkingPointCategory';
import Language from '../admin-routes/Language';
import ExclusionDoctor from '../admin-routes/ExclusionDoctor';
import MZ900Calculations from '../admin-routes/MZ900Calculations';

const routes = [
  // DASHBOARD - REDIRECT
  {
    path: '/',
    Component: DashboardRedirect,
    title: 'Dashboard',
    icon: 'web-design',
  },
  {
    path: '/view/dashboard',
    Component: Dashboard,
    title: 'Dashboard',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  // {
  //   path: '/view/bi-report',
  //   Component: PowerBIReport,
  //   title: 'Key KPIs',
  //   icon: 'dashboard',
  //   icontab: 'dashboard-tab',
  // },
  {
    path: '/view/casemanager-recruitment-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_CASE_MANAGER_RECRUITMENT_REPORT} />,
    title: 'Client & CM Recruitment',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/ime-operational-analysis-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_IME_OPERATIONAL_ANALYSIS_REPORT} />,
    title: 'IME Operational Analysis',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/ime-forecast-billing',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_REVENUE_FORECAST_BILLING_REPORT} />,
    title: 'Revenue Forecast & Billing',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/booking-account-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_REVENUE_BY_ACCOUNT_REPORT} />,
    title: 'Booking by Account',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/specialist-recruitment-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_SPECIALIST_RECRUITMENT_REPORT} />,
    title: 'Specialist Recruitment',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/correspondence-analysis-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_BOOKING_ANALYSIS_REPORT} />,
    title: 'Correspondence Analysis',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/bam-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_BAM_REPORT} />,
    title: 'Staff KPI Report',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  {
    path: '/view/staff-dashboard-report',
    Component: () => <PowerBIReport reportId={process.env.REACT_APP_STAFF_DASHBOARD_REPORT} />,
    title: 'Billing KPI',
    icon: 'dashboard',
    icontab: 'dashboard-tab',
  },
  // PROFILE
  {
    path: '/view/admin-account',
    Component: Iframe,
    title: 'My Details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/admin-account-2',
    Component: MyDetails,
    title: 'My Details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/cm-account',
    Component: Iframe,
    title: 'My Details',
    icon: 'password',
  },
  {
    path: '/view/cm-account-2',
    Component: CmProifle,
    title: 'My Details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/dr-account',
    Component: Iframe,
    title: 'My Details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/dr-account-2',
    Component: DrProfile,
    title: 'My Details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/add-edit-doctor-2',
    Component: AddEditDoctor,
    title: 'New Specialist',
    icon: 'guest-male',
  },

  // LOGOUT
  {
    path: '/view/logout',
    Component: null, // Already render in anonymous routes
    title: 'Logout',
    icon: 'log-out',
  },
  {
    path: '/account/RevertImpersonation',
    title: 'Revoke',
    icon: 'revoke',
    icontab: 'revoke-tab',
  },

  // COMMON ROUTES
  {
    path: '/view/supplementary-reports',
    Component: Iframe,
    title: 'Supp Report Cases',
    icon: 'note',
  },
  {
    path: '/view/supplementary-reports-2',
    Component: AdminSupplementaryReports,
    title: 'Supp Report Cases',
    icon: 'note',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/file-reviews',
    Component: Iframe,
    title: 'File Review Cases',
    icon: 'folder-invoices',
  },
  {
    path: '/view/view-file-review',
    Component: Iframe,
    title: 'View File Review',
    icon: 'note',
  },
  {
    path: '/view/view-assessment',
    Component: Iframe,
    title: 'View Assessment',
    icon: 'folder-invoices',
  },
  {
    path: '/view/view-med-neg',
    Component: Iframe,
    title: 'View Med Neg',
    icon: 'folder-invoices',
  },
  {
    path: '/view/view-supplementary-report',
    Component: Iframe,
    title: 'View Supplementary Report',
    icon: 'note',
  },
  {
    path: '/view/clinical-notes',
    Component: Iframe,
    title: 'Clinical Record Cases',
    icon: 'note',
  },
  {
    path: '/view/clinical-notes-2',
    Component: ClinicalReport,
    title: 'Clinical Record Cases',
    icon: 'note',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-clinical-notes-2',
    Component: AddEditClinicalRecord,
    title: 'View Clinical Record',
    icon: 'note',
  },
  {
    path: '/view/mri-cases',
    Component: Iframe,
    title: 'Imaging Cases',
    icon: 'photo-reel',
  },
  {
    path: '/view/mri-cases-2',
    Component: ImagingServices,
    title: 'Imaging Cases',
    icon: 'photo-reel',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-mri-case-2',
    Component: AddEditImagingService,
    title: 'Add Edit Imaging Service 2',
    icon: 'photo-reel',
  },
  {
    path: '/view/add-edit-claimant',
    Component: Iframe,
    title: 'New Claimant',
    icon: 'guest-male',
  },
  {
    path: '/view/add-edit-clinic',
    Component: Iframe,
    title: 'New Clinic',
    icon: 'guest-male',
  },
  {
    path: '/view/add-edit-clinic-2',
    Component: AddEditClinic,
    title: 'New Clinic',
    icon: 'guest-male',
  },
  {
    path: '/view/add-edit-doctor',
    Component: Iframe,
    title: 'New Specialist',
    icon: 'guest-male',
  },
  {
    path: '/view/calendar',
    Component: Iframe,
    title: 'Clinic Calendar',
    icon: 'calendar',
    icontab: 'calendar-tab',
  },
  {
    path: '/view/book-assessment',
    Component: Iframe,
    title: 'Book Assessment',
    icon: 'add-folder',
  },
  {
    path: '/view/new-file-review',
    Component: Iframe,
    title: 'New File Review',
    icon: 'note',
  },
  {
    path: '/view/new-med-neg',
    Component: Iframe,
    title: 'New Med Neg',
    icon: 'note',
  },
  {
    path: '/view/new-supplementary-report',
    Component: Iframe,
    title: 'New Supplementary Report',
    icon: 'note',
  },
  {
    path: '/view/new-clinical-note',
    Component: Iframe,
    title: 'New CR Retrieval',
    icon: 'note',
  },
  {
    path: '/view/new-mri-case',
    Component: Iframe,
    title: 'New Imaging Case',
    icon: 'photo-reel',
  },
  {
    path: '/view/view-mri',
    Component: Iframe,
    title: 'View Imaging Service',
    icon: 'photo-reel',
  },

  // DOCTOR ROUTES
  {
    path: '/view/universal-search',
    Component: UniversalSearch,
    title: 'Search Cases',
    icon: 'search',
    icontab: 'search-tab',
  },
  {
    path: '/view/docs-search',
    Component: DocSearch,
    title: 'Document Search',
    icon: 'search',
    icontab: 'search-tab',
  },
  {
    path: '/view/chatgpt',
    Component: ChatGPT,
    title: 'Ask ChatGPT',
    icon: 'help',
    icontab: 'help',
  },
  {
    path: '/view/action-required-search',
    Component: ActionRequiredSearch,
    title: 'Search Action Notes & Tasks',
    icon: 'search',
    icontab: 'search-tab',
  },
  {
    path: '/view/appointments',
    Component: Appointments,
    title: 'Appointments',
    icon: 'calendar',
    icontab: 'calendar-tab',
  },
  {
    path: '/view/message-template',
    Component: MessageTemplate,
    title: 'Mail Templates',
    icon: 'email',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/sale-target',
    Component: SaleTarget,
    title: 'Sale Targets',
    icon: 'folder-invoices',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/calendar-2',
    Component: AddEditMySessions,
    title: 'Clinic Calendar',
    icon: 'calendar',
    icontab: 'calendar-tab',
  },
  {
    path: '/view/doctor-ime',
    Component: IMEReports,
    title: 'IME Reports (A)',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/doctor-ime-2',
    Component: IMEReports,
    title: 'Fitness for Duty (A)',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/doctor-supplementary',
    Component: SupplementaryReports,
    title: 'Supplementary Reports (S)',
    icon: 'note',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-supplementary-report-2',
    Component: AddEditSupplementaryReport,
    title: 'View Supplementary Report',
    icon: 'note',
  },
  {
    path: '/view/doctor-file-reviews',
    Component: DoctorFileReview,
    title: 'File Reviews (F)',
    icon: 'folder-invoices',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-file-review-2',
    Component: AddEditFileReview,
    title: 'View File Review',
    icon: 'folder-invoices',
  },
  {
    path: 'https://eassess.magcirrus.com.au/',
    title: 'Eassess',
    icon: 'video-call',
  },

  // CASE MANAGER ROUTES
  {
    path: '/view/smart-search',
    Component: SmartSearch,
    title: 'Appointment Searches',
    icon: 'search',
    icontab: 'search-tab',
  },
  {
    path: '/view/ime-cases',
    Component: Iframe,
    title: 'IME Cases',
    icon: 'medical-doctor',
  },
  {
    path: '/view/view-clinical-note',
    Component: Iframe,
    title: 'View Clinical Record',
    icon: 'note',
  },
  {
    path: '/view/send-feedback',
    Component: Iframe,
    title: 'Send Feedback',
    icon: 'feedback',
  },
  {
    path: '/view/send-feedback-2',
    Component: SendFeedBack,
    title: 'Send Feedback',
    icon: 'feedback',
    icontab: 'feedback-tab',
  },
  {
    path: '/FunctionalCapacityAssessments',
    Component: Iframe,
    title: 'Functional Capacity Assessments',
    icon: 'feedback',
  },
  {
    path: '/Content/documents/MAGDirectory.pdf',
    title: 'MAG SPECIALIST PANEL',
    icon: 'mag-directory',
  },
  {
    path: 'https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos',
    title: 'MAG SEMINARS',
    icon: 'mag-directory',
  },
  {
    path: 'https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7',
    title: 'EDUCATION CHANNEL',
    icon: 'mag-directory',
  },
  {
    path: 'https://cmsupport.kawaconn.com/',
    title: 'Help By Kawaconn',
    icon: 'kawaconn',
  },
  {
    path: 'http://specialistsupport.kawaconn.com/',
    title: 'Help By Kawaconn',
    icon: 'kawaconn',
  },
  // ADMIN ROUTES
  {
    path: '/view/admin-file-reviews',
    Component: AdminFileReview,
    title: 'File Review Cases',
    icon: 'folder-invoices',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/admin-med-neg',
    Component: AdminMedNeg,
    title: 'Medical Negligence Triage (MN)',
    icon: 'folder-invoices',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-med-neg-2',
    Component: AddEditMedNeg,
    title: 'Add Edit Medical Negligence Triage',
    icon: 'folder-invoices',
  },
  {
    path: '/view/login-users',
    Component: Iframe,
    title: 'Staff Login Only',
    icon: 'user-group-man-man',
  },
  {
    path: '/view/login-users-2',
    Component: AdminLoginUser,
    title: 'Login Accounts',
    icon: 'user-group-man-man',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/add-edit-staff-2',
    Component: AddEditStaff,
    title: 'Add Edit Staff',
    icon: 'user-group-man-man',
  },
  {
    path: '/view/clients',
    Component: Iframe,
    title: 'Clients',
    icon: 'administrator-male',
  },
  {
    path: '/view/clients-2',
    Component: Clients,
    title: 'Clients',
    icon: 'administrator-male',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/add-edit-client-2',
    Component: AddEditClient,
    title: 'New Client',
    icon: 'administrator-male',
  },
  {
    path: '/view/case-managers',
    Component: Iframe,
    title: 'Case Managers',
    icon: 'manager',
  },
  {
    path: '/view/case-managers-2',
    Component: CaseManagers,
    title: 'Case Managers',
    icon: 'manager',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/add-edit-case-manager-2',
    Component: AddEditCaseManager,
    title: 'New Case Manager',
    icon: 'manager',
  },
  {
    path: '/view/typist',
    Component: Typist,
    title: 'Typists',
    icon: 'manager',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/add-edit-typist',
    Component: AddEditTypist,
    title: 'New Typist',
    icon: 'manager',
  },
  {
    path: '/view/tp-detail',
    Component: AddEditTypist,
    title: 'My details',
    icon: 'my-details',
    icontab: 'my-details-tab',
  },
  {
    path: '/view/specialists',
    Component: Iframe,
    title: 'Specialists',
    icon: 'medical-doctor',
  },
  {
    path: '/view/specialists-2',
    Component: AdminDocAndCons,
    title: 'Specialists',
    icon: 'medical-doctor',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/medical-centres',
    Component: Iframe,
    title: 'Medical/Service Centres',
    icon: 'hospital',
  },
  {
    path: '/view/medical-centres-2',
    Component: MedicalCentres,
    title: 'Medical/Service Centres',
    icon: 'hospital',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/marketing-campaign',
    Component: MarketingCampaign,
    title: 'Marketing Campaigns',
    icon: 'treatment-plan',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/campaign-schedule-history',
    Component: CampaignScheduleHistory,
    title: 'Campaign Schedule Histories',
    icon: 'treatment-plan',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/add-edit-marketing-campaign',
    Component: AddEditMarketingCampaign,
    title: 'Add New Marketing Campaign',
    icon: 'treatment-plan',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/claimants',
    Component: Iframe,
    title: 'Claimants',
    icon: 'treatment-plan',
  },
  {
    path: '/view/claimants-2',
    Component: Claimants,
    title: 'Claimants',
    icon: 'treatment-plan',
    icontab: 'account-center-tab',
  },
  {
    path: '/view/add-edit-claimant-2',
    Component: AddEditClaimant,
    title: 'New Claimant',
    icon: 'treatment-plan',
  },
  {
    path: '/view/job-title',
    Component: Iframe,
    title: 'Job Title',
    icon: 'new-job',
  },
  {
    path: '/view/job-title-2',
    Component: JobTitle,
    title: 'Job Titles',
    icon: '',
  },
  {
    path: '/view/greeting-title',
    Component: GreetingTitle,
    title: 'Greeting Titles',
    icon: 'new-job',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/manage-spe-acc',
    Component: ManageSpeAcc,
    title: 'Spec-Qual-Acred',
    icon: 'new-job',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/types-of-claims',
    Component: Iframe,
    title: 'Types Of Claims',
    icon: 'invoice',
  },
  {
    path: '/view/types-of-claims-2',
    Component: AdminTypeOfClaims,
    title: 'Types Of Claims',
    icon: 'invoice',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/domain-exclusion',
    Component: DomainExclusion,
    title: 'Domain Exclusion',
    icon: 'invoice',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/lmq-criteria',
    Component: AdminLMQ,
    title: 'LMQ',
    icon: 'invoice',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/types-of-report',
    Component: Iframe,
    title: 'Types Of Report',
    icon: 'report-card',
  },
  {
    path: '/view/types-of-report-2',
    Component: AdminTypesOfReport,
    title: 'Types Of Report',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/module-type-template',
    Component: ModuleTypeTemplate,
    title: 'Mail Modules',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/body-part',
    Component: BodyParts,
    title: 'AMA Body Parts',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/talking-point',
    Component: TalkingPoint,
    title: 'Talking Points',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/talking-point-category',
    Component: TalkingPointCategory,
    title: 'Talking Point Categories',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/language',
    Component: Language,
    title: 'Languages',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/target-audience',
    Component: TargetAudience,
    title: 'Target Audience',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/type-of-work',
    Component: TypeOfWork,
    title: 'AMA Type Of Work',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },

  {
    path: '/view/exclusion-specialist',
    Component: ExclusionDoctor,
    title: 'Exclusion Specialist/Domain',
    icon: 'report-card',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/industry-segments',
    Component: Iframe,
    title: 'Industry Segments',
    icon: 'manufacturing',
  },
  {
    path: '/view/industry-segments-2',
    Component: AdminIndustrySegments,
    title: 'Industry Segments',
    icon: 'manufacturing',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/ims-approval',
    Component: Iframe,
    title: 'IMS Approval Codes (IRO)',
    icon: 'code',
  },
  {
    path: '/view/ims-approval-2',
    Component: AdminIMSApprovalCode,
    title: 'IMS Approval Codes (IRO)',
    icon: 'code',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/rate-change-passwords',
    Component: Iframe,
    title: 'Doctor Rate Passwords',
    icon: 'password',
  },
  {
    path: '/view/system-config',
    Component: SystemConfig,
    title: 'System Configurations',
    icon: 'dashboard',
    icontab: 'value-settings-tab',
  },
  {
    path: '/hangfire',
    title: 'Hangfire Dashboard',
    icon: 'dashboard',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/request-disbursement-funding',
    title: 'REQUEST DISBURSEMENT FUNDING',
    icon: 'requestDisbursementFunding',
  },
  {
    path: '/view/smart-clinic-search',
    Component: Iframe,
    title: 'Smart Clinic Search',
    icon: 'search',
    icontab: 'search-tab',
  },
  {
    path: '/view/ime-assessments',
    Component: Iframe,
    title: 'IME Assessments (A)',
    icon: 'inspection',
  },
  {
    path: '/view/ime-assessments-2',
    Component: AdminIMEAssessments,
    title: 'IME Assessments (A)',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/ime-assessments-3',
    Component: AdminIMEAssessments,
    title: 'Fitness for Duty (A)',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-ime-assessment-2',
    Component: AddEditImeAssessments,
    title: 'View Assessment',
    icon: 'inspection',
  },
  {
    path: '/view/factual-investigations',
    Component: Iframe,
    title: 'Factual Investigations (FI)',
    icon: 'feedback',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/add-edit-factual-investigation-2',
    Component: AddEditFactualInvestigation,
    title: 'Add Edit Factual Investigation 2',
    icon: 'feedback',
  },
  {
    path: 'https://scopevideo.com.au',
    title: 'Open Scope Video',
    icon: 'documentary',
    icontab: 'service-center-tab',
  },
  {
    path: '/view/invoices',
    Component: Iframe,
    title: 'Billing',
    icon: 'invoice',
  },
  {
    path: '/view/invoices-2',
    Component: Invoices,
    title: 'Billing',
    icon: 'billing',
    icontab: 'billing-tab',
  },
  {
    path: '/view/service-fee',
    Component: Iframe,
    title: 'Service Fee Schedule',
    icon: 'overtime',
  },
  {
    path: '/view/ama-service-fee',
    Component: AMAFeeCodes,
    title: 'AMA/MBS Codes',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/mbs-code',
    Component: MBSCodes,
    title: 'MBS Codes',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/estimate',
    Component: ServiceEstimate,
    title: 'Specialist Quote/Invoice Check',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/funding-request',
    Component: FundingRequest,
    title: 'Facility Requests',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/add-edit-funding-request',
    Component: AddEditFundingRequest,
    title: 'Add Funding Request',
    icon: 'overtime',
  },
  {
    path: '/view/funding-agreement',
    Component: FundingAgreement,
    title: 'Facility Agreements',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/add-edit-funding-agreement',
    Component: AddEditFundingAgreement,
    title: 'New Agreement',
    icon: 'overtime',
  },
  {
    path: '/view/funding-report',
    Component: FundingReport,
    title: 'Facility Transactions',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/add-edit-funding-report',
    Component: AddEditFundingReport,
    title: 'New Transaction',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/ama-calculation-rule',
    Component: AMACalculationRule,
    title: 'AMA Calculation Rules',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/atercare-rule',
    Component: AMAAftercareRule,
    title: 'AMA Aftercare Rules',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/time-unit',
    Component: AMATimeUnitSetting,
    title: 'AMA Anaesthesia Time Units',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/modify-unit',
    Component: AMATimeUnitModify,
    title: 'AMA Anaesthesia Modifying Units',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/m4-modifier',
    Component: M4ModifierExclusion,
    title: 'AMA M4 Modifier',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/service-fee-2',
    Component: AdminServiceFee,
    title: 'Service Fee Schedule',
    icon: 'overtime',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/doctor-reports',
    Component: Iframe,
    title: 'Doctor Reports',
    icon: 'medical-doctor',
  },
  {
    path: '/view/doctor-reports-2',
    Component: DoctorReports,
    title: 'Specialist Reports',
    icon: 'medical-doctor',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/password-reset-history',
    Component: PasswordResetHistory,
    title: 'Password Reset History',
    icon: '',
    icontab: '',
  },
  {
    path: '/view/cm-reports',
    Component: CMReport,
    title: 'CM Reports',
    icon: 'manager',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/client-reports',
    Component: Iframe,
    title: 'Client Reports',
    icon: 'check',
  },
  {
    path: '/view/client-reports-2',
    Component: AdminClientReport,
    title: 'Client Reports',
    icon: 'check',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/login-history',
    Component: Iframe,
    title: 'Login History Report',
    icon: 'login-rounded-right',
  },
  {
    path: '/view/export-history',
    Component: ExportHistoryRP,
    title: 'Export History Report',
    icon: 'login-rounded-right',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/login-history-2',
    Component: AdminLoginHistoryRP,
    title: 'Login History Report',
    icon: 'login-rounded-right',
    icontab: 'report-center-tab',
  },
  {
    path: '/view/add-edit-client',
    Component: Iframe,
    title: 'New Client',
    icon: 'administrator-male',
  },
  {
    path: '/view/add-edit-case-manager',
    Component: Iframe,
    title: 'New Case Manager',
    icon: 'manager',
  },
  {
    path: '/view/add-edit-job-title',
    Component: Iframe,
    title: 'Add/Edit Job Title',
    icon: 'new-job',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/add-edit-type-of-claim',
    Component: Iframe,
    title: 'Add/Edit Type Of Claim',
    icon: 'invoice',
  },
  {
    path: '/view/add-edit-type-of-report',
    Component: Iframe,
    title: 'Add/Edit Type Of Report',
    icon: 'report-card',
  },
  {
    path: '/view/add-edit-ims-approval',
    Component: Iframe,
    title: 'Add/Edit IMS Approval Code',
    icon: 'code',
  },
  {
    path: '/view/view-factual-investigation',
    Component: Iframe,
    title: 'New Factual Investigation',
    icon: 'feedback',
  },
  {
    path: '/view/view-invoice',
    Component: Iframe,
    title: 'View Invoice',
    icon: 'invoice',
  },
  {
    path: '/view/generate-doctor-payment',
    Component: Iframe,
    title: 'Doctor Remittances',
    icon: 'medical-doctor',
  },
  {
    path: '/view/generate-doctor-payment-2',
    Component: DoctorRemittance,
    title: 'Doctor Remittances',
    icon: 'medical-doctor',
  },
  {
    path: '/view/business-units',
    Component: Iframe,
    title: 'Business Units',
    icon: 'business',
  },
  {
    path: '/view/add-edit-business-unit',
    Component: Iframe,
    title: 'Add/Edit Business Unit',
    icon: 'business',
  },

  //Value setting
  {
    path: '/view/add-edit-job-title-2',
    Component: AddEdditJobTitle,
    title: 'Add/Edit Job Title',
    icon: 'new-job',
    icontab: 'value-settings-tab',
  },
  {
    path: '/view/add-edit-types-of-claims-2',
    Component: AddEdditTypeClaim,
    title: 'Add/Edit Type Of Claim',
    icon: 'invoice',
  },
  {
    path: '/view/add-edit-lmq',
    Component: AddEditLMQCriteria,
    title: 'Add/Edit LMQ',
    icon: 'invoice',
  },
  {
    path: '/view/add-edit-type-of-report-2',
    Component: AddEdditTypeReport,
    title: 'Add/Edit Type Of Report',
    icon: 'report-card',
  },
  {
    path: '/view/notification',
    Component: Notification,
    title: 'Notifications',
    icon: 'notification',
    icontab: 'notification-tab',
  },
  {
    path: '/view/video-session',
    Component: VideoSession,
    title: 'Video Session',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },

  {
    path: '/view/MZ900-calculations-settings',
    Component: MZ900Calculations,
    title: 'MZ900 Calculations',
    icon: 'inspection',
    icontab: 'service-center-tab',
  },

  // IMS
  {
    path: '/view/ims',
    Component: IMS,
    title: 'Inquiry Centre',
    icon: 'inquiry-centre',
  },
].reduce((map, route) => {
  map[route.path] = route;
  return map;
}, {});

export default routes;
