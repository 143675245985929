import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import Store from './Store';
import * as api from '../../../stores/api';

const columns = [
  { title: '' },
  // { title: 'Id' },
  { title: 'Name' },
  { title: 'Description' },
  { title: 'Status' },
];

@observer
class MainTable extends React.Component {
  openModal = (id, record) => () => {
    return Store.toggleModal(true, {
      modalType: 'addOrEdit',
      id,
      record,
    })();
  };

  openModalConfirm = opt => () => {
    return Store.toggleModal(true, opt)();
  };

  handlePageClick = page => {
    Store.refetch({ curPage: page });
  };

  renderTableBody = () => {
    return Store.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        {/* <Table.Cell selectable className={cx('Cell-nowrap Cell-link')}>
          <Link to="#" onClick={this.openModal(Id, record)}>
            {Id}
          </Link>
        </Table.Cell> */}
        <Table.Cell className={cx('Cell-nowrap Cell-link')}>
          <Link to="#" onClick={this.openModal(Id, record)}>
            {record.Label}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
        <Table.Cell>{record.IsActive ? 'Active' : 'Inactive'}</Table.Cell>
      </Table.Row>
    ));
  };

  handleDeleteItem = async id => {
    Store.setFieldsValue({ loading: true });

    try {
      await customFetch(`/TypeOfWork/Delete?id=${id}`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      await Store.refetch({}, true);
    } catch (e) {
    } finally {
      Store.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openModal(id, record)}>Edit</Menu.Item>
          <Menu.Item
            onClick={this.openModalConfirm({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: () => this.handleDeleteItem(id),
            })}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data, totalItems } = Store;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={column} sortColumn={Store.sortColumn} sortDirection={Store.sortDirection} />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={Store.curPage}
          totalPage={Store.totalPage || 0}
          totalItems={totalItems || 0}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default MainTable;
