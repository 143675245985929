import React from 'react';
import { Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { TableEmptyMessage, TableHeader } from '../../shared/table';

import AddEditStore from './Store';
import { Link } from 'react-router-dom';
import { renderDate } from '../Shared';

const columns = [{ title: 'Invoice No' }, { title: 'Received Date' }];

@observer
class ListInvoice extends React.Component {
  render() {
    const data = toJS(AddEditStore.listInvoiceID?.itemList ?? []);
    return (
      <div className="fluid-field">
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={columns} />
            <Table.Body>
              {data?.length > 0 ? (
                data.map(i => (
                  <Table.Row key={i.Id}>
                    <Table.Cell className="cell-correspondence Cell-link">
                      <Link onClick={() => window.open(`/Invoice/DownloadBilling?invoiceId=${i.InvoiceNo}`, '_blank')}>
                        {i.InvoiceNo}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{renderDate(i.SentDateCMViewInvoice, false, true)}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <TableEmptyMessage colSpan={columns.length} />
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

export default ListInvoice;
