import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Message } from 'semantic-ui-react';

import store from './store';

export const Upload = ({
  multiple = false,
  placeholder,
  reportType,
  attachments,
  type,
  option,
  functionCallback,
  disabled,
  disabledDelete,
  uploadTitle,
  disableDragDrop,
}) => {
  const handleOpenModalUpload = () => {
    store.toggleModalModalUpload(true, {
      reportType,
      multiple,
      attachments,
      placeholder,
      type,
      option,
      functionCallback,
      disabled,
      disabledDelete,
      uploadTitle,
      disableDragDrop,
    });
  };
  const styleBtnDownload = attachments => {
    return !!attachments.length ? { cursor: 'pointer', width: 150 } : { cursor: 'not-allowed', width: 150 };
  };
  const handleDownloadFiles = () => {
    return window.open(
      `/Supplementary/ExportPaperworkAttachToZip?service=S&itemId=${store.supplementaryInfo.Id}&type=${type}`,
    );
  };
  return (
    <div className="Upload-File">
      <label
        className="Button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleOpenModalUpload();
        }}
      >
        <span>{disabled ? 'View' : 'Upload/View'}</span>
      </label>
      <label
        className="Button"
        style={styleBtnDownload(attachments)}
        onClick={() => {
          !!attachments.length && handleDownloadFiles();
        }}
      >
        <Icon name="download" />
        <span>Download All</span>
      </label>
      <p>{placeholder}</p>
    </div>
  );
};

export const File = ({ name, downloadLink, onRemove }) => {
  return (
    <Message className="upload-box">
      <div className="file-info">
        <div className="file-info-inner">
          <Icon name="file alternate" />
          <div className="file-name">{name}</div>
        </div>
        <div className="file-actions">
          <Icon name="download" onClick={() => window.open(downloadLink, '_blank')} />
          <Icon name="delete" color="red" onClick={onRemove} />
        </div>
      </div>
    </Message>
  );
};

export default observer(
  ({ type, option, multiple = false, functionCallback, disabled, disabledDelete, uploadTitle, disableDragDrop }) => {
    const { Attachments } = store.supplementaryInfo;

    const attachments = Attachments.filter(({ Type }) => Type === type);

    const placeholder =
      attachments && !!attachments.length
        ? `${attachments.length} ${attachments.length > 1 ? 'attachments' : 'attachment'} ${
            attachments.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;

    return (
      <React.Fragment>
        <Upload
          multiple={multiple}
          placeholder={placeholder}
          reportType={type}
          attachments={attachments}
          type={type}
          option={option}
          functionCallback={functionCallback}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          disableDragDrop={disableDragDrop}
        />
      </React.Fragment>
    );
  },
);
