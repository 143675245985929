import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class CMReportsStore {
  //Tab active
  @observable tabIsActive = 'saleCall';

  //Modal booking history
  @observable dataBookingCM = null;
  @observable loadingBookingCM = true;
  @observable totalBookingCM = 0;
  @observable sortDirectionCMBooking = '';
  @observable sortKeyCMBooking = '';
  @observable sortColumnCMBooking = '';
  @observable cmIDBooking = 0;

  @observable loadingUpload = false;
  @observable loadingCM = true;
  @observable loadingCMReports = true;

  @observable loadingCMNotBooked = true;
  @observable loadingClientSearch = true;
  @observable dataClientSearch = null;
  @observable numberClientSearch = 30;
  @observable stateCMNotBooked = '';
  @observable companyId = 0;
  @observable cmIdNotBooking = 0;
  @observable keywordClient = '';
  @observable dataCMNotBooked = null;
  @observable totalPageCMNotBooked = 0;
  @observable totalItemCMNotBooked = 0;
  @observable curPageCMNotBooked = 0;
  @observable sortKeyCMNotBooked = 'LastBookingDate';
  @observable sortDirectionCMNotBooked = 'descending';
  @observable sortColumnCMNotBooked = 'Last Booking Date';

  @observable dataCM = null;
  @observable dataCMReports = null;
  @observable CMId = 0;
  @observable keyword = '';
  @observable fromTo = '';

  @observable sortDirectionBooking = '';
  @observable sortKeyBooking = '';
  @observable sortColumnBooking = '';
  @observable totalBooking = 0;

  @observable numberSearch = 20;

  @observable open = false;
  @observable modalParams = {};

  //IME Specialist searches

  @observable loadingIMESearch = true;
  @observable dataIMESearch = null;

  @observable keywordIMESearch = '';
  @observable fromToIMESearch = '';

  @observable verifyAccessCode = '';
  @observable exportUrl = '';
  @observable exportQueryParams = '';

  getDateRangeIMESearch = () => {
    const [fromDate, toDate] = this.fromToIMESearch.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
    };
  };

  @action setLoadingUpload = status => {
    this.loadingUpload = status;
  };

  @action fetchIMESearch = () => {
    this.loadingIMESearch = true;
    const { fromDate, toDate } = this.getDateRangeIMESearch();
    const fromDate_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const toDate_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const paramObj = {
      searcherName: this.keywordIMESearch,
      searchedFrom: encodeURIComponent(fromDate_),
      searchedTo: encodeURIComponent(toDate_),
      orderBy: `SearchedAtUtc`,
      orderAsc: false,
      searchForReport: true,
    };
    var qs = Object.entries(paramObj)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    customFetch('/api/SmartSearchHistory?' + qs, {
      method: 'GET',
    }).then(
      action(data => {
        this.dataIMESearch = data.smartSearchHistoryDataModel;
        this.loadingIMESearch = false;
      }),
    );
  };
  @action refetchIMESearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchIMESearch();
  };
  //

  @action refetchClientSearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClientSearch();
  };

  @action fetchClientSearch = () => {
    this.loadingClientSearch = true;
    customFetch('/Company/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        NumItemPerPage: this.numberClientSearch,
        Showing: `active`,
        curPage: 1,
        keyword: this.keywordClient,
      }),
    }).then(
      action(data => {
        this.dataClientSearch = data;
        this.loadingClientSearch = false;
      }),
    );
  };

  @action fetchBookingCM = cmId => {
    this.loadingBookingCM = true;
    customFetch(
      `/Staff/GetBookingHistory?caseManagerId=${cmId}&sortBy=${
        this.sortKeyCMBooking
      }&sortDirection=${this.sortDirectionCMBooking.replace('ending', '').toUpperCase()}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataBookingCM = data;
        this.loadingBookingCM = false;
        if (data.curPage <= 1) {
          this.totalBookingCM = data.sumItem;
        }
      }),
    );
  };

  @action handleSortCMBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnCMBooking = column;
    this.sortDirectionCMBooking = direction;
    this.sortKeyCMBooking = sortKey;
    this.fetchBookingCM(this.cmIDBooking);
  };

  @action fetchCM = () => {
    this.loading = true;
    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        Showing: `active`,
        Keyword: this.keyword,
        CurPage: 1,
        NumItemPerPage: this.numberSearch,
      }),
    }).then(
      action(data => {
        this.loadingCM = false;
        this.dataCM = data.itemList;
      }),
    );
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    return {
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
  };

  @action fetchBookingHistory = () => {
    this.loadingCMReports = true;
    this.tableType = 0;
    const { fromDate, toDate } = this.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    customFetch(
      `/Staff/GetBookingHistory?caseManagerId=${this.CMId}&sortBy=${
        this.sortKeyBooking
      }&sortDirection=${this.sortDirectionBooking.replace('ending', '').toUpperCase()}&fromDate=${from}&toDate=${to}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataCMReports = data;
        this.loadingCMReports = false;
        this.totalBooking = !!data.sumItem ? data.sumItem : this.totalBooking;
      }),
    );
  };

  @action fetchLastBooking = () => {
    this.loadingCMNotBooked = true;
    customFetch(
      `/Staff/GetCMsNotBookedLast6Week?curPage=${this.curPageCMNotBooked}&numItemPerPage=20&sortBy=${
        this.sortKeyCMNotBooked
      }&sortDirection=${this.sortDirectionCMNotBooked.replace('ending', '').toUpperCase()}&state=${
        this.stateCMNotBooked
      }&companyId=${this.companyId}&caseManagerId=${this.cmIdNotBooking}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.loadingCMNotBooked = false;
        this.dataCMNotBooked = data;
        if (data.curPage <= 1) {
          this.totalItemCMNotBooked = data.sumPage;
          this.totalItemCMNotBooked = data.sumItem;
        }
      }),
    );
  };

  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBooking = column;
    this.sortDirectionBooking = direction;
    this.sortKeyBooking = sortKey;
    this.fetchBookingHistory();
  };

  @action handleSortCMNotBooked = ({ sortKey }, { column, direction }) => {
    this.sortColumnCMNotBooked = column;
    this.sortDirectionCMNotBooked = direction;
    this.sortKeyCMNotBooked = sortKey;
    this.fetchLastBooking();
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action refetch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchBookingHistory();
  };

  @action refetchCMNotBooked = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchLastBooking();
  };

  @action refetchCM = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchCM();
  };

  @action resetStore = () => {
    this.CMId = 0;
    this.keyword = '';
    this.numberSearch = 20;
    this.fromTo = '';
    this.dataCMReports = null;

    this.fetchCM();
  };
}

export default new CMReportsStore();
