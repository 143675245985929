import dashboardStore from '@stores/dashboard';
import customFetch from '@utils/customFetch';
import { notification } from 'antd';
import { action, observable, toJS, when } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import * as api from '../../../stores/api';
import { uniqBy } from 'lodash';
//import { momentBusinessDays } from 'moment-business-days';
import { ReplaceFileNameUpload } from '@utils/functions';

import {
  getAssessmentInfo,
  getActionRequired,
  getActiveClaimTypeList,
  getAllByStatus,
  getAllWithoutNCI,
  getAssessmentStatus,
  getClinicList,
  getCompanyList,
  getCompanyInsuer,
  getConversationList,
  getIMSApprovalCode,
  getLanguageList,
  getListInvoiceID,
  getLookupConfigByTypes,
  getPatientList,
  getRooms,
  getSpecificLookupConfigOrderNoByType,
  getStaffGetAll,
  getStaffList,
  getTypists,
  getWIROPrincipleLawyer,
  getActiveReportTypeList,
  getAssessmentLogs,
  getLookupConfigByParents,
  getLookUpConfig,
  getAssessmentData,
  checkCurrentRate,
  getDoctorInfo,
  getInterProvider,
  getInterProviderCMs,
  getWaitingList,
  getAllBlockedClients,
  getLookupIcareReport,
  sendICareCompletionRequest,
  resendMultiAppointmentRequest,
  getClinicListByType,
} from './service';
import {
  UPLOAD_FILE_RULE,
  AssessmentReportType,
  ASSESSMENT_INFO,
  ASSESSMENT_STATUS,
  ASSESSMENT_PIC_IN_CHARGE_OF,
  BODY_CONVERSATIONLOG,
  WIRO_INVOICE_TYPE,
  VIEW_INVOICE_GLOBAL,
  LOCAL_CONSTANT,
  CONSTANTS,
} from './type';

class AddEditStore {
  //Step
  @observable currentStep = 0;
  //Data
  @observable loading = true;
  @observable loadingUpload = false;
  @observable loadingConversation = false;
  @observable assessmentInfo = ASSESSMENT_INFO;
  @observable caseStatus = ASSESSMENT_STATUS;
  @observable statusLogs = null;
  @observable listStaff = null;
  @observable listTypist = null;
  @observable listClaimType = null;
  @observable listReasonReferral = null;
  @observable icareLookupList = null;
  @observable listClaimant = null;
  @observable listReportType = null;
  @observable listConfigParents = null;
  @observable listConfigChild = null;
  @observable listIMSApprovalCode = null;
  @observable listWiroPrincipleLawyer = null;
  @observable listActionRequired = null;
  @observable conversationList = null;
  @observable languageList = null;
  @observable caseStatus = null;
  @observable listBillingItemCode = null;
  @observable listSpecialist = null;
  @observable listSupplier = null;
  @observable listClinic = null;
  @observable listHomeClinic = null;
  @observable listspecificLookupConfigOrderNoByType = null;
  @observable clinicInterpretationServiceList = null;
  @observable invalidForm = [];
  @observable listInvoiceID = null;
  @observable timeZone = moment.tz.guess();

  @observable interpreterProvider = [];
  @observable interpreterProviderCMs = [];

  @observable testFieldValue = 'This is a test field';

  @observable listClinicForAddress = null;
  @observable listClientForAddress = null;

  @observable cmSelected = null;

  //initial data
  @observable hourList = null;
  @observable minuteList = null;
  @observable assessmentMethodDisable = false;
  @observable clinicConfirmed = false; //Session Availability Type

  //--- Modal
  @observable open = false;
  @observable modalParams = {};
  //--- Conversation
  @observable conversationParam = BODY_CONVERSATIONLOG;
  @observable totalPages = 0;

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  //--- Modal
  @observable open = false;
  @observable modalParams = {};

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @observable submitData = {
    sendEassessUrlToClaimantLocation: false,
    sendEassessUrlToDoctorLocation: false,
    notifyCmAboutAppointmentInquiry: false,
    notifyCmAboutAppointmentConfirmed: false,
    sendDaysheetToDoctor: false,
    notifyCmAboutDidNotAttendCase: false,
    notifyCmAboutAppointmentCancellation: false,
    showInquiryReceivedNextStepInfo: false,
    showAppointmentConfirmedNextStepInfo: false,
    showPaperworkReceivedNextStepInfo: false,
    copy: false,
  };

  //Assessment Status
  @observable numberStatusPerPage = 30;
  @observable showingStatus = 'active';

  //All Without NCI
  @observable keywordAllWithoutNCI = '';
  @observable numItemPerPageAllWithoutNCI = 30;

  //Clinic List
  @observable doctorIdClinicList = 0;
  @observable numItemPerPageClinicList = 30;
  @observable typeClinicList = 'mag';

  //Lookup Config By Types
  @observable typeLookupConfig = ['spe'];

  //Patient List
  @observable keywordPatient = '';
  @observable numberPatientPerPage = 30;

  //Rooms
  @observable clinicIdRooms = 0;

  //Specific Lookup Config Order No By Type
  @observable typeLookupConfigOrderNo = 'PapToDoc';

  @observable uploadRule = UPLOAD_FILE_RULE;

  @observable steps = [
    { key: 0, step: 'claimDetails', active: false, title: 'Claim Details' },
    {
      key: 1,
      step: 'appointmentDetails',
      active: false,
      title: 'Appointment Details',
    },
    {
      key: 2,
      step: 'paperwork',
      active: false,
      title: 'Paperwork Status',
    },
    {
      key: 3,
      step: 'appointmentAttendance',
      active: false,
      title: 'Attendance & Cancellation',
    },
    {
      key: 4,
      step: 'dictations',
      active: false,
      title: 'Dictations & Report Information',
    },

    {
      key: 5,
      step: 'clientInvoice',
      active: false,
      title: 'Client & Specialist Payments',
    },
    {
      key: 6,
      step: 'caseCorrespondence',
      active: false,
      title: 'Case Correspondence',
    },
    {
      key: 7,
      step: 'actionRequired',
      active: false,
      title: 'Action Required',
    },
  ];

  @observable upLoadType = [
    {
      key: 0,
      fileName: 'LOI',
      active: false,
      title: 'Letter of Instruction',
      belongToStep: 2,
      reportType: 1,
    },
    {
      key: 1,
      fileName: 'Other Document',
      active: false,
      title: 'Other Document',
      belongToStep: 2,
      reportType: 9,
    },
    {
      key: 2,
      fileName: 'Equipment Checklist',
      active: false,
      title: 'Equipment Check',
      belongToStep: 1,
      reportType: 7,
    },
    {
      key: 3,
      fileName: 'Signed Consent',
      active: false,
      title: 'Consent Form',
      belongToStep: 1,
      reportType: 6,
    },
    {
      key: 4,
      fileName: 'Equipment Checklist',
      active: false,
      title: 'Specialist Video Assessment Details',
      belongToStep: 1,
      reportType: 8,
    },
    {
      key: 5,
      fileName: 'Dictation',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 2,
    },
    {
      key: 6,
      fileName: 'Dictation Instruction',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 13,
    },
    {
      key: 7,
      fileName: 'Report Sample',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 14,
    },
    {
      key: 8,
      fileName: 'Report Template',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 15,
    },
    {
      key: 9,
      fileName: 'Typed Report',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 19,
    },
    {
      key: 10,
      fileName: 'Report Draft',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 3,
    },
    {
      key: 11,
      fileName: 'Corrected Report',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 18,
    },
    {
      key: 12,
      fileName: 'Final Report',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 4,
    },
    {
      key: 13,
      fileName: 'Amended Report',
      active: false,
      title: '',
      belongToStep: 4,
      reportType: 5,
    },
    {
      key: 14,
      fileName: 'Specialist Payment Receipt',
      active: false,
      title: '',
      belongToStep: 5,
      reportType: 12,
    },
    {
      key: 15,
      fileName: 'Client Payment Receipt',
      active: false,
      title: '',
      belongToStep: 5,
      reportType: 21,
    },
    {
      key: 16,
      fileName: 'Client File',
      active: false,
      title: '',
      belongToStep: 5,
      reportType: 10,
    },
    {
      key: 17,
      fileName: 'Funding Approval',
      active: false,
      title: 'IRO Funding Approval',
      belongToStep: 0,
      reportType: 20,
    },
    {
      key: 18,
      fileName: 'Specialist Invoice',
      active: false,
      title: '',
      belongToStep: 5,
      reportType: 11,
    },
  ];

  //Submit data
  @observable bookingClient = {};
  @observable bookingCaseManager = {};
  @observable subBookingClientCM = {};
  @observable companyInsurer = {};
  @observable subBookingSolicitor = [];
  @observable waitingList = [];
  @observable claimant = {};
  @observable bodyParts = [];
  @observable subBodyParts = [];
  @observable claimReqSpecialty = [];
  @observable PIC_1 = {};
  @observable PIC_2 = {};
  @observable PIC_3 = {};
  @observable PIC_4 = {};
  @observable PIC_5 = {};
  @observable PIC_6 = {};
  @observable PIC_7 = {};
  @observable PIC_8 = {};
  @observable PIC_9 = {};
  @observable Report_Delivery_Contact_1 = {};
  @observable Report_Delivery_Contact_2 = {};
  @observable bookingSolicitor = {};
  @observable claimType = {};
  @observable reasonReferral = {};
  @observable icareServiceType = {};
  @observable reportType = {};
  @observable subBookingClient = {};
  @observable principleLawyer = {};
  @observable payingClient = {};
  @observable referenceClient = {};
  @observable payingCasemanger = {};
  @observable doctorSampleAttachment = [];
  @observable doctorReportTemplateAttachment = [];
  @observable specialist = {};
  @observable specialistAcceptedTypes = [];
  @observable specialistAcceptedTypesText = '';
  @observable iMSApprovalCode = {};
  @observable clinic = {};
  @observable SelectedDoctorProviderNoIdx = 1;
  @observable room = [];
  @observable AdditionalServiceSupplierObj = {};
  //
  @observable blockedClients = [];

  //Data belong to a specific field
  //--- Booking CM
  @observable listBookingCM = null;
  @observable bookingCMKeyword = '';
  @observable bookingCMNumItemPerPage = 1000;
  @observable bookingCMCompanyId = 0;
  //--- Report Receiving Contact 1
  @observable listRDC_1_CM = null;
  @observable listRDC_1_CMKeyword = '';
  @observable listRDC_1_CMNumItemPerPage = 1000;
  @observable listRDC_1_CMCompanyId = 0;
  //--- Report Receiving Contact 2
  @observable listRDC_2_CM = null;
  @observable listRDC_2_CMKeyword = '';
  @observable listRDC_2_CMNumItemPerPage = 5000;
  @observable listRDC_2_CMCompanyId = 0;
  //-- Booking Solicitor
  @observable listBookingSolicitor = null;
  @observable bookingSolicitorKeyword = '';
  @observable bookingSolicitorNumItemPerPage = 1000;
  @observable bookingSolicitorCompanyId = 0;
  //-- Booking Sub-Solicitor
  @observable listBookingSubSolicitor = null;
  @observable bookingSubSolicitorKeyword = '';
  @observable bookingSubSolicitorNumItemPerPage = 1000;
  @observable bookingSubSolicitorCompanyId = 0;
  //-- Waiting List
  @observable listWaitingList = null;
  @observable waitingListKeyword = '';
  @observable waitingListNumItemPerPage = 30;
  //-- Booking Client
  @observable listCompany = null;
  @observable keywordCompany = '';
  @observable numberCompanyPerPage = 30;
  //-- Booking Sub Client
  @observable listSubCompany = null;
  @observable keywordSubCompany = '';
  @observable numberSubCompanyPerPage = 30;
  //-- Booking Sub booking Client
  @observable listSubBookingCM = [];
  //-- Paying Client
  @observable listPayingCompany = null;
  @observable keywordPayingCompany = '';
  @observable numberPerPagePayingCompany = 30;
  //-- Reference Client
  @observable listReferenceCompany = null;
  @observable keywordReferenceCompany = '';
  @observable numberPerPageReferenceCompany = 30;
  //-- Paying Case Manager
  @observable listPayingCaseManager = null;

  @observable keywordPayingCaseManager = '';
  @observable numberPerPagePayingCaseManager = 1000;
  @observable payingCasemangerCompanyId = 0;
  //-- Specialist
  @observable lstInvoiceItemFee = [];

  @observable listSubBookingClientCM = null;
  @observable listCompanyInsurer = null;
  @observable keywordCompanyInsurer = '';
  @observable numberCompanyInsurerPerPage = 30;

  @action setListItem = (name, lst) => {
    if (name === 'InvoiceServiceItem') {
      this.lstInvoiceItemFee = lst;
    } else {
      this[name] = lst;
    }
  };

  @action handleRefetchBookingCM = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });

    if (!!this.bookingCMCompanyId) {
      getStaffGetAll(this.bookingCMKeyword, this.bookingCMNumItemPerPage, this.bookingCMCompanyId)
        .then(
          action(res => {
            this.listBookingCM = res;
            this.listBookingCMCC = res;
          }),
        )
        .catch(() => {});
    } else {
      this.listBookingCM = {};
    }
  };

  @action handleRefetchBookingSolicitor = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    if (!!this.bookingSolicitorCompanyId) {
      getStaffGetAll(this.bookingSolicitorKeyword, this.bookingSolicitorNumItemPerPage, this.bookingSolicitorCompanyId)
        .then(action(res => (this.listBookingSolicitor = res)))
        .catch(() => {});
    } else {
      this.listBookingSolicitor = {};
    }
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  @action handleRefetchBookingSubSolicitor = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getStaffGetAll(
      this.bookingSubSolicitorKeyword,
      this.bookingSubSolicitorNumItemPerPage,
      this.bookingSubSolicitorCompanyId,
    )
      .then(action(res => (this.listSubBookingCMs = res)))
      .catch(() => {});
  };

  @action handleRefetchWaitingList = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getStaffGetAll(this.waitingListKeyword, this.waitingListNumItemPerPage)
      .then(action(res => (this.listWaitingList = res)))
      .catch(() => {});
  };

  handleCheckICare = () => {
    const { bookingClient, specialist } = this;
    if (!!bookingClient && !!specialist) {
      if ((bookingClient.IcareInsurer || this.assessmentInfo?.IcareClaim) && !specialist.AcceptICareMatter) {
        this.setFieldsValue({
          open: true,
          modalParams: {
            modalType: 'confirm',
            message: 'This specialist may not accept iCare matters, are you sure to book this report?',
            onOk: () => {},
            functionCallBack: () => {},
          },
        });
      }
    }
  };

  handleAssessmentMethodChanged = () => {
    const itemModel = toJS(this.assessmentInfo);
    if (itemModel.AssessmentMethod) {
      if (!!this.claimant) {
        this.assessmentInfo = {
          ...toJS(this.assessmentInfo),
          EassessClaimantEmail: this.claimant.Email,
          EassessClaimantPhone: this.claimant.Telephone,
        };
      }

      if (!this.assessmentInfo.EassessClaimantEmail && this.bookingCaseManager) {
        this.assessmentInfo = {
          ...toJS(this.assessmentInfo),
          EassessClaimantEmail: this.bookingCaseManager.Email,
        };
      }

      if (!!this.specialist) {
        this.assessmentInfo = {
          ...toJS(this.assessmentInfo),
          EassessDoctorEmail: this.specialist.Email2,
          EassessDoctorPhone: this.specialist.Telephone,
        };
      }

      if (this.clinic) {
        this.assessmentInfo = {
          ...toJS(this.assessmentInfo),
          EassessDoctorAddress: this.clinic.FullAddress,
        };
      }

      this.assessmentInfo = {
        ...toJS(this.assessmentInfo),
        AssessmentMethodType: 'MAGVideo',
        SendEassessUrlToClaimantLocation: itemModel.IsAppointmentConfirmed ? true : false,
        SendEassessUrlToDoctorLocation: itemModel.IsAppointmentConfirmed ? true : false,
      };
    } else {
      this.assessmentInfo = {
        ...toJS(this.assessmentInfo),
        SendEassessUrlToClaimantLocation: false,
        SendEassessUrlToDoctorLocation: false,
      };
    }
  };

  @action handleRefetchlistRDC_1_CM = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    if (!!this.listRDC_1_CMCompanyId)
      return getStaffGetAll(this.listRDC_1_CMKeyword, this.listRDC_1_CMNumItemPerPage, this.listRDC_1_CMCompanyId)
        .then(action(res => (this.listRDC_1_CM = res)))
        .catch(() => {});
    return (this.listRDC_1_CM = {});
  };

  @action handleRefetchlistRDC_2_CM = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getStaffGetAll(this.listRDC_2_CMKeyword, 1000, this.listRDC_2_CMCompanyId)
      .then(action(res => (this.listRDC_2_CM = res)))
      .catch(() => {});
  };

  @action getLookupConfigChild = parents => {
    Promise.all([getLookupConfigByParents(parents ? parents : [])])
      .then(
        action(([childs]) => {
          this.listConfigChild = childs;
          var newListSurgeons = [];
          let orthopaedicSurgeonObj = parents.find(i => i.Label === 'Orthopaedic Surgeon');
          if (orthopaedicSurgeonObj) {
            toJS(childs.itemList).forEach(el => {
              if (el.ParentId === orthopaedicSurgeonObj.Id) {
                newListSurgeons.push(el);
              }
            });
          } else {
            newListSurgeons = [];
          }
          this.listConfigChildOrthopaedicSurgeon = newListSurgeons;
          //console.log(this.listConfigChildOrthopaedicSurgeon);
        }),
      )
      .catch(() => {});
  };

  @action handleRefetchBookingClient = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyList(this.keywordCompany, this.numberCompanyPerPage, true)
      .then(action(res => (this.listCompany = res)))
      .catch(() => {});
  };

  //-- Client Invoice
  @action handleRefetchPayingClient = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyList(this.keywordPayingCompany, this.numberPerPagePayingCompany)
      .then(action(res => (this.listPayingCompany = res)))
      .catch(() => {});
  };

  @action handleRefetchReferenceClient = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyList(this.keywordReferenceCompany, this.numberPerPageReferenceCompany)
      .then(action(res => (this.listReferenceCompany = res)))
      .catch(() => {});
  };

  @action handleRefetchPayingCasemanager = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    if (!!this.payingCasemangerCompanyId)
      return getStaffGetAll(
        this.keywordPayingCaseManager,
        this.numberPerPagePayingCaseManager,
        this.payingCasemangerCompanyId,
      )
        .then(action(res => (this.listPayingCaseManager = res)))
        .catch(() => {});
    return (this.listPayingCaseManager = {});
  };

  @action handleRefetchBookingSubClient = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyList(this.keywordSubCompany, this.numberSubCompanyPerPage)
      .then(action(res => (this.listSubCompany = res)))
      .catch(() => {});
  };
  @action handleRefetchBookingSubClientCM = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyList(this.keywordSubBookingClientCM, this.numberSubBookingClientCMPerPage)
      .then(
        action(res => {
          return (this.listSubBookingClientCM = res);
        }),
      )
      .catch(() => {});
  };
  @action handleRefetchCompanyInsurer = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getCompanyInsuer(this.keywordCompanyInsurer, this.numberCompanyInsurerPerPage)
      .then(
        action(res => {
          return (this.listCompanyInsurer = res);
        }),
      )
      .catch(() => {});
  };
  @action handleRefetchSubBookingCM = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getStaffGetAll('', 1000, this.bookingClientCMId)
      .then(action(res => (this.listSubBookingCM = res)))
      .catch(() => {});
  };
  @action handleRefetchClaimant = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getPatientList(this.keywordPatient, this.numberPatientPerPage)
      .then(action(res => (this.listClaimant = res)))
      .catch(() => {});
  };

  @action handleRefetchSpecialist = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getAllWithoutNCI(this.keywordAllWithoutNCI, this.numItemPerPageAllWithoutNCI)
      .then(action(res => (this.listSpecialist = res)))
      .catch(() => {});
  };

  @action handleRefetchSupplier = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getAllWithoutNCI(this.keywordAllWithoutNCI, this.numItemPerPageAllWithoutNCI)
      .then(action(res => (this.listSupplier = res)))
      .catch(() => {});
  };

  @action handleRefetchClinic = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getClinicList(
      this.doctorIdClinicList,
      this.numItemPerPageClinicList,

      this.keywordClinicList,
    )
      .then(action(res => (this.listClinic = res)))
      .catch(() => {});
  };

  @action handleRefetchClinicByType = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    getClinicListByType(this.typeClinicList, this.numItemPerPageClinicList, this.keywordClinicList)
      .then(action(res => (this.listHomeClinic = res)))
      .catch(() => {});
  };

  @action handleRefetchClinicAddress = (keyword = '') => {
    const doctorId = this.assessmentInfo.EassessDoctorLocationType === 'DoctorOffice' ? this.specialist?.Id : 0;
    getClinicList(doctorId, this.numItemPerPageClinicList, keyword)
      .then(action(res => (this.listClinicForAddress = res)))
      .catch(() => {});
  };

  @action handleRefetchClientAddress = (keyword = '') => {
    getCompanyList(keyword, this.numberSubCompanyPerPage)
      .then(action(res => (this.listClientForAddress = res)))
      .catch(() => {});
  };

  @action handleRemoveFile = key => {
    const attachments = this.assessmentInfo.AssessmentAttachment;
    const assessmentInfo_ = this.assessmentInfo;
    const newAttachments = attachments.filter(i => i.key !== key);
    this.assessmentInfo = {
      ...assessmentInfo_,
      AssessmentAttachment: newAttachments,
    };
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  parseTimeRangeItem = time => {
    if (time < 10) {
      return { label: '0' + time, value: time };
    } else {
      return { label: time, value: time };
    }
  };

  @action handleChangeValue = (fieldName, value) => {
    const itemModel = toJS(this.assessmentInfo);
    if (value && fieldName === 'DictationReceived') {
      if (itemModel.IsAppointmentConfirmed && itemModel.AppointmentConfirmedDate && itemModel.Attendance === 0) {
        return (this.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          Attendance: 1,
          AttendedDate: itemModel.AppointmentConfirmedDate,
        });
      }
    }
    if (value && fieldName === 'ReportCompleted') {
      if (itemModel.IsAppointmentConfirmed && itemModel.AppointmentConfirmedDate && itemModel.Attendance === 0) {
        return (this.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          Attendance: 1,
          AttendedDate: itemModel.AppointmentConfirmedDate,
        });
      }
    }
    if (fieldName === 'DictationOption') {
      this.bindCommisionRate();
    }
    return (this.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  @action handleChangeStatusIME = (assessmentId, imeStatus) => {
    const itemModel = toJS(this.assessmentInfo);
    if(itemModel.Interpreter && (imeStatus === 'Cancelled-No Fee' || imeStatus === 'Late Cancellation Fee')){
      this.assessmentInfo = {
        ...itemModel,
        InterpretationStatus: imeStatus === 'Cancelled-No Fee' ? 1 : 2,
        InterpreterStatusCancelledDate: moment()
      };
    }
    return customFetch('/MedicalService/ChangeStatusIME', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ assessmentId, imeStatus }),
    }).then(
      action(res => {
        if (res.status === 'success') {
          this.assessmentInfo.Status = imeStatus;
          notification.destroy();
          notification.success({
            message: 'Successfully',
            description: 'Case status update',
          });

          getAssessmentLogs(assessmentId).then(logs => {
            action(() => {
              this.statusLogs = logs;
            })();
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: '',
          });
        }
      }),
    );
  };

  sendWarningWiro = () => {
    const itemModel = toJS(this.assessmentInfo);
    this.handleChangeValue('WifroIlars', true);
    const wiroPrincipleLawyers = toJS(this.listWiroPrincipleLawyer);
    const casemanager = wiroPrincipleLawyers.itemList.find(i => i.Id === itemModel.IlarsWiroSolicitor);
    var objSend = {
      RecordId: itemModel.Id,
      IlarsRef: itemModel.IlarsRef,
      IMSApprovalCode: itemModel.IMSApprovalCode,
      IlarsWiroSolicitorName: casemanager?.FullName || '',
    };
    customFetch('/MedicalService/SendWiroMissing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objSend),
    })
      .then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'IRO Reminders',
            description: 'IRO reminders to Booking CM sucessfully!',
          });
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'IRO Reminders',
          description: 'IRO reminders to Booking CM error!',
        });
      });
  };

  handleProcessWiroWarning = type => {
    let msg =
      'IRO information for this case has not been provided and payment may not be approved. Do you want to send IRO reminder now?';
    if (type === 2) {
      msg =
        'IRO information for this case has not been provided and payment may not be approved. Do you still want to send the report?';
    }
    this.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => {
        if (type === 1) {
          this.handleChangeValue('WifroIlars', true);
          this.sendWarningWiro();
        }
        // if (type === 2) {
        //   setTimeout(() => {
        //     this.toggleModal(true, {
        //       modalType: 'confirm',
        //       message: 'Do you want to send IRO reminder to booking CM now?',
        //       onOk: () => this.sendWarningWiro(),
        //     })();
        //   }, 1000);
        // }
      },
      functionCallbackCancel: () => {
        // if(type === 1){
        //   this.handleChangeValue('ReportReturnedFromDoctor', false);
        //   this.handleChangeValue('ReportCompletedDate', null);
        // }
      },
      // onCancel: () => {
      //   if(type === 1){
      //     this.handleChangeValue('ReportReturnedFromDoctor', false);
      //     this.handleChangeValue('ReportCompletedDate', null);
      //   }
      //   if (type === 2) {
      //     this.handleChangeValue('ReportCompleted', false);
      //     this.handleChangeValue('ReportCompletedSentDate', null);
      //     setTimeout(() => {
      //       this.toggleModal(true, {
      //         modalType: 'confirm',
      //         message: 'Do you want to send IRO reminder to booking CM now?',
      //         onOk: () => this.sendWarningWiro(),
      //       })();
      //     }, 1000);
      //   }
      // },
    })();
  };

  checkDelayPrepaymentRequired = () => {
    const itemModel = toJS(this.assessmentInfo);
    const { handleChangeValue, showNotificationError } = this;
    if (itemModel.DelayPrepaymentRequired && !itemModel.ClientPrepaymentPaidDate) {
      showNotificationError(
        'This case has delayed sending report to client. Please check under Client & Specialist Payments.',
      );
      [
        'ReportCompleted',
        'IsAllowCMSolicitorViewReport',
        'IsAllowCMViewReport',
        'IsAllowCMSolicitorViewAmendedReport',
        'IsAllowCMViewAmendedReport',
      ].map(i => handleChangeValue(i, false));
      return false;
    } else {
      return true;
    }
  };

  CheckWiRo = () => {
    var bRet = false;
    const itemModel = toJS(this.assessmentInfo);
    const { bookingClient = {}, clinic = {}, claimType = {}, reportType = {} } = this;
    if (itemModel.WifroIlars && (!itemModel.IlarsRef || !itemModel.IMSApprovalCode || !itemModel.IlarsWiroSolicitor)) {
      bRet = true;
    }
    if (!bRet) {
      if (
        !itemModel.WifroIlars &&
        bookingClient &&
        bookingClient.ClientType &&
        bookingClient.ClientType === 3 &&
        bookingClient &&
        bookingClient.CityName &&
        bookingClient.CityName === 'NSW' &&
        clinic &&
        clinic.City &&
        (clinic.City === 'NSW' || (clinic.City.CityName && clinic.City.CityName === 'NSW')) &&
        !itemModel.AssessmentMethod &&
        claimType &&
        claimType.Id &&
        claimType.Id === 13 &&
        reportType &&
        reportType.Id &&
        reportType.Id === 31
      ) {
        bRet = true;
      }
    }
    //}
    return bRet;
  };
  renderStaffName = id => {
    const { listStaff } = this;
    const rs = listStaff.itemList.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };

  handleReportCompletedChange = () => {
    const itemModel = toJS(this.assessmentInfo);
    const { handleChangeValue } = this;
    const attachments = this.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Completed);
    if (!attachments.length && itemModel.ReportCompleted) {
      notification.info({
        message: 'Information',
        description: 'Please remember to attach reports',
        duration: 5,
      });
      //this.showMessage('Please remember to attach reports');
      // return (this.assessmentInfo = {
      //   ...itemModel,
      //   ReportCompleted: false,
      // });
    }

    if (!this.checkDelayPrepaymentRequired()) {
      return;
    }

    if (!!attachments.length && itemModel.ReportCompleted) {
      if (itemModel.F_RDCSameBookingCM) {
        this.handleChangeValue('IsAllowCMSolicitorViewReport', true);
      }
      if (itemModel.S_RDCCaseManagerId) {
        this.handleChangeValue('IsAllowCMViewReport', true);
      }
    }

    // if (!!attachments.length && itemModel.ReportCompleted && !itemModel.IsAllowCMViewReport) {
    //   setTimeout(() => {
    //     this.toggleModal(true, {
    //       modalType: 'confirm',
    //       message: 'Do you want to attach completed report to notification email to CMs?',
    //       onOk: () => {
    //         this.handleChangeValue('IsAllowCMViewReport', true);
    //       },
    //     })();
    //   }, 500);
    // }
    if (itemModel.ReportCompleted) {
      handleChangeValue('ReportCompletedSentDate', moment());
    } else {
      handleChangeValue('ReportCompletedSentDate', null);
      handleChangeValue('IsAllowCMViewReport', false);
    }
    if (this.CheckWiRo() && itemModel.ReportCompleted) {
      //this.handleProcessWiroWarning(2);
      //Bo sung logic delay prepayment
    } // New Update 2 week ago
  };

  inRangeDate = (d, start, end) => {
    let dD = moment(d);
    let cD1 = moment(start);
    let cD2 = moment(end);
    return isFinite(d) && isFinite(start) && isFinite(end) ? cD1 <= dD && dD <= cD2 : false;
  };

  inRangeDate2 = (d, start) => {
    let dD = moment(d);
    let cD1 = moment(start);
    return isFinite(d) && isFinite(start) ? cD1 <= dD : false;
  };

  calculateTotalOtherFee = () => {
    const itemModel = toJS(this.assessmentInfo);
    let totalOtherFee = 0;
    let otherFeeTax = 0;
    if (itemModel.HasOtherFee) {
      let fee1 = parseFloat(itemModel.OtherFee1Value);
      let tax1 = parseFloat(itemModel.OtherFee1TaxPercentage);
      //let fee2 = parseFloat(itemModel.ManualFeeValue2);
      //let tax2 = parseFloat(itemModel.ManualFeeTax2);

      if (!isNaN(fee1) && fee1 !== 0) {
        totalOtherFee += fee1;
        if (!isNaN(tax1) && tax1 > 0) {
          //otherFeeTax += fee1 * tax1 / 100;
          otherFeeTax += tax1;
        }
      }
      //if (!isNaN(fee2) && fee2 != 0) {
      //    totalOtherFee += fee2;
      //    if (!isNaN(tax2) && tax2 > 0) {
      //        otherFeeTax += fee2 * tax2 / 100;
      //    }
      //}
    }
    return {
      totalOtherFee,
      otherFeeTax,
    };
  };

  @observable serviceItemTotalFee = 0;
  @observable serviceItemTotalTax = 0;

  @action calculateTotalServiceFee = () => {
    const itemModel = toJS(this.assessmentInfo);
    if (Array.isArray(itemModel.AssessmentServiceItems)) {
      let serviceItemTotalFee = 0;
      let serviceItemTotalTax = 0;
      if (this.lstInvoiceItemFee && this.lstInvoiceItemFee.length > 0) {
        this.lstInvoiceItemFee.forEach(el => {
          const itemPrice = el.TotalItemFeeAmount;
          const itemTax = el.ItemFeesGST;
          serviceItemTotalFee += itemPrice;
          serviceItemTotalTax += itemTax;
        });
      }
      // itemModel.AssessmentServiceItems.forEach(item => {
      //   const itemPrice = item.ApplicablePrice;
      //   let itemTax = 0;
      //   if (item.Tax > 0) {
      //     itemTax = (itemPrice * item.Tax) / 100;
      //   }
      //   serviceItemTotalFee += itemPrice;
      //   serviceItemTotalTax += itemTax;
      // });
      this.serviceItemTotalFee = serviceItemTotalFee;
      this.serviceItemTotalTax = serviceItemTotalTax;
    }
  };

  calculateAmountForCommissionCalculation = () => {
    const itemModel = toJS(this.assessmentInfo);
    let amountForCommissionCalculation = 0;
    const serviceFee = parseFloat(
      itemModel.IncludeServiceItemFeeToDoctorCommission ? this.serviceItemTotalFee || 0 : 0,
    );
    const adminExtraFee = parseFloat(
      itemModel.IncludeAdminExtraFeeToDoctorCommission ? itemModel.AdminExtraFeeAmount || 0 : 0,
    );
    const otherFee1 = parseFloat(
      itemModel.HasOtherFee && itemModel.IncludeManualFee1ToDoctorCommission ? itemModel.OtherFee1Value || 0 : 0,
    );
    // const otherFee2 = parseFloat(
    //   itemModel.HasManualFee &&
    //     itemModel.IncludeManualFee2ToDoctorCommission
    //     ? itemModel.ManualFeeValue2 || 0
    //     : 0,
    // );
    amountForCommissionCalculation = serviceFee + adminExtraFee + otherFee1;
    //+ otherFee2
    return amountForCommissionCalculation;
  };

  calculateAmountForCommissionCalculationTax = () => {
    return 0.1 * this.calculateAmountForCommissionCalculation(); // commission tax should always be 10%
  };

  @action calculateInvoice = () => {
    const itemModel = toJS(this.assessmentInfo);
    this.calculateTotalServiceFee();
    const commissionRateType = itemModel.CommissionRateType;

    const otherFeeAndTax = this.calculateTotalOtherFee();
    const totalOtherFee = otherFeeAndTax.totalOtherFee;
    const otherFeeTax = otherFeeAndTax.otherFeeTax;
    const serviceTax = this.serviceItemTotalTax;
    const serviceFee = this.serviceItemTotalFee;
    let reimbursement = itemModel.Reimbursement;

    const wiroType = WIRO_INVOICE_TYPE.indexOf('wiroType');
    const WiroSolicitor = WIRO_INVOICE_TYPE.indexOf('WiroSolicitor');
    const Wiro = WIRO_INVOICE_TYPE.indexOf('Wiro');

    const adminExtraFee = itemModel.AdminExtraFeeAmount || 0;
    //const adminExtraFeeTax = (adminExtraFee * (itemModel.AdminExtraFeeTaxPercentage || 0) / 100);
    const adminExtraFeeTax = itemModel.AdminExtraFeeTaxPercentage;

    const doctorServiceAmount = wiroType === WiroSolicitor ? 0 : this.calculateAmountForCommissionCalculation();
    const doctorServiceTax = wiroType === WiroSolicitor ? 0 : this.calculateAmountForCommissionCalculationTax();
    const commissionRate = parseFloat(itemModel.CommissionRate) || 0;
    const commissionAmount = (doctorServiceAmount * commissionRate) / 100;

    const cotRate = commissionRateType === VIEW_INVOICE_GLOBAL.CommissionType.COT ? itemModel.CommissionOnTopRate : 0;
    const cotAmount = (this.calculateAmountForCommissionCalculation() * cotRate) / 100;
    const cotTax = cotAmount * 0.1 || 0; // 10%
    let totalWithoutTax = serviceFee + totalOtherFee + adminExtraFee + cotAmount;
    let totalTax = otherFeeTax + serviceTax + adminExtraFeeTax + cotTax;
    let totalWithTax = 0;
    if (wiroType === WiroSolicitor) {
      reimbursement = totalWithoutTax;
      totalWithTax = totalWithoutTax - reimbursement + totalTax;
    } else if (wiroType === Wiro) {
      totalTax = 0; // wiro invoice has tax = 0
      totalWithTax = totalWithoutTax + totalTax;
    } else {
      totalWithTax = totalWithoutTax + totalTax;
    }

    this.assessmentInfo = {
      ...itemModel,
      TotalWithoutTax: totalWithoutTax.toFixed(2),
      TaxTotal: totalTax.toFixed(2),
      Total: totalWithTax,
      CommissionOnTopAmount: cotAmount,
      TotalAmountForCommissionCalculation: doctorServiceAmount,
      TotalAmountForCommissionCalculationTax: doctorServiceTax,
      Reimbursement: reimbursement,
    };

    if (commissionRateType !== 2) {
      this.handleChangeValue('CommissionAmount', commissionAmount.toFixed(2));
    }
  };

  handleCommissionRateTypeChange = () => {
    const commissionRateType = toJS(this.assessmentInfo).CommissionRateType;
    const itemModel = toJS(this.assessmentInfo);
    switch (commissionRateType) {
      case 0: //ViewInvoiceGlobal.CommissionType.Mag
        if (this.specialist && this.specialist?.Id) {
          let dObj = this.specialist;
          let itemAppointmentDate = itemModel.DateFrom;
          if (!!dObj.MAGChargeRate && !!dObj.MAGChargeRate2) {
            let isSetValue = false;
            if (
              !!dObj.MAGChargeRateTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.MAGChargeRateFrom),
                this.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              itemModel.CommissionRate = dObj.MAGChargeRate;
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRateTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              itemModel.CommissionRate = dObj.MAGChargeRate;
              isSetValue = true;
            }
            if (
              !!dObj.MAGChargeRate2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.MAGChargeRate2From),
                this.momentToJS(dObj.MAGChargeRate2To),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate2);
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRate2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.MAGChargeRate2From))
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate2);
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.MAGChargeRateTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.MAGChargeRateFrom),
                this.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate);
            } else if (
              !dObj.MAGChargeRateTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate);
            } else {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.MAGChargeRate2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.MAGChargeRate2From),
                this.momentToJS(dObj.MAGChargeRate2To),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate2);
            } else if (
              !dObj.MAGChargeRate2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.MAGChargeRate2From))
            ) {
              this.handleChangeValue('CommissionRate', dObj.MAGChargeRate2);
            } else {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else {
            this.handleChangeValue('CommissionRate', 0);
          }
        } else {
          this.handleChangeValue('CommissionRate', 0);
        }
        break;
      case 1: //ViewInvoiceGlobal.CommissionType.NonMag
        if (this.specialist && this.specialist?.Id) {
          let dObj = this.specialist;
          let itemAppointmentDate = itemModel.DateFrom;
          if (!!dObj.ChargeRate && !!dObj.ChargeRate2) {
            let isSetValue = false;
            if (
              !!dObj.ChargeRateTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.ChargeRateFrom),
                this.momentToJS(dObj.ChargeRateTo),
              )
            ) {
              isSetValue = true;
              this.handleChangeValue('CommissionRate', dObj.ChargeRate);
            } else if (
              !dObj.ChargeRateTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.ChargeRateFrom))
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate);
              isSetValue = true;
            }
            if (
              !!dObj.ChargeRate2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.ChargeRate2From),
                this.momentToJS(dObj.ChargeRate2To),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate2);
              isSetValue = true;
            } else if (
              !dObj.ChargeRate2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.ChargeRate2From))
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate2);
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.ChargeRateTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.ChargeRateFrom),
                this.momentToJS(dObj.ChargeRateTo),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate);
            } else if (
              !dObj.ChargeRateTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.ChargeRateFrom))
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate);
            } else {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.ChargeRate2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.ChargeRate2From),
                this.momentToJS(dObj.ChargeRate2To),
              )
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate2);
            } else if (
              !dObj.ChargeRate2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.ChargeRate2From))
            ) {
              this.handleChangeValue('CommissionRate', dObj.ChargeRate2);
            } else {
              this.handleChangeValue('CommissionRate', 0);
            }
          } else {
            this.handleChangeValue('CommissionRate', 0);
          }
        } else {
          this.handleChangeValue('CommissionRate', 0);
        }
        break;

      case 2: //ViewInvoiceGlobal.CommissionType.FixedAmount:
        this.handleChangeValue('CommissionAmount', this.specialist?.MAGChargeRateFixedAmount || 0);
        break;

      case 3: //ViewInvoiceGlobal.CommissionType.COT:
        itemModel.CommissionRate = 100;
        this.handleChangeValue('CommissionRate', 100);
        if (this.specialist && this.specialist?.Id) {
          let dObj = this.specialist;
          let itemAppointmentDate = itemModel.DateFrom;
          if (!!dObj.CommissionOnTop && !!dObj.CommissionOnTop2) {
            let isSetValue = false;
            if (
              !!dObj.CommissionOnTopTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.CommissionOnTopFrom),
                this.momentToJS(dObj.CommissionOnTopTo),
              )
            ) {
              isSetValue = true;
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop);
            } else if (
              !dObj.CommissionOnTopTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.CommissionOnTopFrom))
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop);
              isSetValue = true;
            }
            if (
              !!dObj.CommissionOnTop2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.CommissionOnTop2From),
                this.momentToJS(dObj.CommissionOnTop2To),
              )
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop2);
              isSetValue = true;
            } else if (
              !dObj.CommissionOnTop2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.CommissionOnTop2From))
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop2);
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeValue('CommissionOnTopRate', 0);
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.CommissionOnTopTo &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.CommissionOnTopFrom),
                this.momentToJS(dObj.CommissionOnTopTo),
              )
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop);
            } else if (
              !dObj.CommissionOnTopTo &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.CommissionOnTopFrom))
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop);
            } else {
              this.handleChangeValue('CommissionOnTopRate', 0);
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.CommissionOnTop2To &&
              this.inRangeDate(
                itemAppointmentDate,
                this.momentToJS(dObj.CommissionOnTop2From),
                this.momentToJS(dObj.CommissionOnTop2To),
              )
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop2);
            } else if (
              !dObj.CommissionOnTop2To &&
              this.inRangeDate2(itemAppointmentDate, this.momentToJS(dObj.CommissionOnTop2From))
            ) {
              this.handleChangeValue('CommissionOnTopRate', dObj.CommissionOnTop2);
            } else {
              this.handleChangeValue('CommissionOnTopRate', 0);
            }
          } else {
            this.handleChangeValue('CommissionOnTopRate', 0);
          }
        } else {
          this.handleChangeValue('CommissionOnTopRate', 0);
        }
        break;
      default:
        break;
    }
    this.calculateInvoice();
  };

  @action BindAppointmentData = item => {
    if (!!item.DateFrom) {
      this.handleChangeValue('DateFrom', this.momentToJS(item.DateFrom));
      this.handleChangeValue('DateFromHour', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateFrom.hour()));
      this.handleChangeValue('DateFromMinute', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateFrom.minutes()));

      this.handleChangeValue(
        'RequestExpectedCompletionDate',
        moment(toJS(this.assessmentInfo).DateFrom, 'DD MMM YYYY, HH:mm').add(7, 'days'),
      );
      // const day = new Date(moment(toJS(this.assessmentInfo).RequestExpectedCompletionDate)).getDay();
      // if (day === 6) {
      //   this.handleChangeValue(
      //     'RequestExpectedCompletionDate',
      //     moment(toJS(this.assessmentInfo).DateFrom, 'DD MMM YYYY, HH:mm').add(5, 'days'),
      //   );
      // } else if (day === 0) {
      //   this.handleChangeValue(
      //     'RequestExpectedCompletionDate',
      //     moment(toJS(this.assessmentInfo).DateFrom, 'DD MMM YYYY, HH:mm').add(4, 'days'),
      //   );
      // }
    }
    if (!!item.DateTo) {
      this.handleChangeValue('DateTo', this.momentToJS(item.DateTo));
      this.handleChangeValue('DateToHour', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateTo.hour()));
      this.handleChangeValue('DateToMinute', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateTo.minutes()));
    }

    if (!!item.SpecialistAcceptedTypes && item.SpecialistAcceptedTypes.length > 0) {
      this.specialistAcceptedTypes = item.SpecialistAcceptedTypes;
      var acceptedTypeText = '';
      item.SpecialistAcceptedTypes.forEach(t => {
        acceptedTypeText = acceptedTypeText + t.Name + ', ';
      });
      acceptedTypeText = acceptedTypeText.substring(0, acceptedTypeText.length - 2);
      this.specialistAcceptedTypesText = acceptedTypeText;
    } else {
      this.specialistAcceptedTypes = [];
      this.specialistAcceptedTypesText = '';
    }

    if (!!item.Doctor) {
      this.specialist = item.Doctor;
      if (!!toJS(this.specialist).ValidUntil) {
        this.specialist = {
          ...toJS(this.specialist),
          ValidUntil: moment(toJS(this.specialist.ValidUntil)),
        };
      }
      if (this.specialist && this.specialist.Specialty && this.specialist.Specialty.includes('Orthopaedic Surgeon')) {
        var orthopaedicSurgeonItem = this.listConfigParents.itemList[0].find(i => i.Label === 'Orthopaedic Surgeon');
        var itemAdded = this.bodyParts.find(b => b.Label === 'Orthopaedic Surgeon');
        if (!!orthopaedicSurgeonItem && !itemAdded) {
          this.bodyParts.push(orthopaedicSurgeonItem);
          this.getLookupConfigChild([orthopaedicSurgeonItem]);
          this.setListItem('subBodyParts', []);
          // if(this.specialist && this.specialist.LstSubSpecialty){
          //   this.setListItem('subBodyParts', [])
          // }
        }
      }
      this.handleChangeValue('SelectedDoctorProviderNoIdx', item.SelectedDoctorProviderNoIdx);
      this.handleChangeValue('CommissionRateType', 0);
      this.handleCommissionRateTypeChange();
      if (
        this.specialist &&
        this.specialist.SubscribeToAppointmentReminders &&
        this.assessmentInfo.IsAppointmentConfirmed
      ) {
        this.handleChangeValue('NotifyDoctorAppointmentConfirmed', true);
      }
      getAllBlockedClients(this.specialist.Id).then(
        action(res => {
          const { itemList } = res;
          if (!!itemList.length && itemList[0] && itemList[0].ClientList && itemList[0].ClientList.length > 0) {
            this.blockedClients = itemList[0].ClientList;
          } else {
            this.blockedClients = [];
          }
        }),
      );
    }

    if (!!item.Clinic) {
      if (item.Clinic.Type === 'mag') {
        this.handleChangeValue('ClinicOption', 'mag');
      } else {
        if (item.Confirmed) {
          this.handleChangeValue('NotifyDoctorAppointmentConfirmed', true);
        }
        this.handleChangeValue('ClinicOption', 'doctor');
      }
      this.clinic = item.Clinic;
      this.room = item.ClinicRoom;
      this.handleChangeValue('ClinicId', item.Clinic.ID);
      this.handleChangeValue('ClinicRoomId', item.ClinicRoom.Id);

      // this.GetClinics(this.itemModel.ClinicOption, this.doctorModel.selected.Id);
    }
    this.handleChangeValue('IsAppointmentConfirmed', item.Confirmed);
    this.handleChangeValue('AppointmentTimeZone', item.TimeZone);
    if (item.Confirmed) {
      this.handleChangeValue('AppointmentConfirmedDate', moment());
      this.submitData = {
        ...toJS(this.submitData),
        notifyCmAboutAppointmentConfirmed: toJS(this.assessmentInfo).NotifyCmAppointmentConfirmed,
        sendDaysheetToDoctor: false,
      };
      this.handleChangeValue('NotifyCmAppointmentConfirmed', item.Confirmed);
      this.handleChangeValue('NotifyCmAppointmentInquiry', false);
      this.handleChangeValue('NotifyDoctorAppointmentInquiry', false);
      this.handleChangeValue('NotifyStaffPICAppointmentInquiry', false);
    }
    this.submitData = {
      ...toJS(this.submitData),
      notifyCmAboutAppointmentInquiry: false,
    };
    this.handleChangeValue('SentDateNotifyDoctorAppointmentInquiry', false);
    console.log(item);
    if (item.Confirmed) {
      this.handleChangeValue('NotifyStaffPICAppointmentConfirmed', true);
    }
    this.handleChangeValue('DictationOption', item.DictationOption);
    switch (item.SessionAcceptsType) {
      case 0:
        this.handleChangeValue('SessionAcceptsType', 0);
        this.handleChangeValue('AssessmentMethod', false);
        this.assessmentMethodDisable = true;
        break;
      case 1:
        this.handleChangeValue('SessionAcceptsType', 1);
        this.handleChangeValue('AssessmentMethod', true);
        this.assessmentMethodDisable = true;
        break;
      case 2:
        this.handleChangeValue('SessionAcceptsType', 2);
        this.assessmentMethodDisable = false;
        break;
      default:
        break;
    }
    this.clinicConfirmed = item.Confirmed;
    this.handleAssessmentMethodChanged();
    //this.assessmentInfo.NotifyCmAppointmentConfirmed = true;
  };

  filterClinic = () => {
    const itemModel = toJS(this.assessmentInfo);
    const doctorModel = toJS(this.specialist);
    if (itemModel.ClinicOption === 'doctor') {
      if (doctorModel?.Id > 0) {
        this.handleRefetchClinic({
          typeClinicList: itemModel.ClinicOption,
          doctorIdClinicList: this.specialist.Id,
          keywordClinicList: '',
        });
      } else {
        this.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select a specialist first!',
        })();
        this.listClinic = [];
        this.clinic = {
          ID: 0,
        };
      }
    } else {
      this.handleRefetchClinic({
        typeClinicList: itemModel.ClinicOption,
        doctorIdClinicList: 0,
        keywordClinicList: '',
      });
    }
  };

  onClinicOptionChange = () => {
    const itemModel = toJS(this.assessmentInfo);
    const doctorModel = toJS(this.specialist);
    switch (itemModel.ClinicOption) {
      case 'home':
        this.handleRefetchClinicByType({
          typeClinicList: 'home',
          doctorIdClinicList: null,
          keywordClinicList: '',
        });
        // getClinicListByType('home').then(resp => {
        //   this.listClinic = resp;
        // });
        //console.log('home');
        if (!!this.claimant) {
          this.handleChangeValue('HomeVisitAddress', this.claimant.FullAddress);
        }
        break;
      default:
        this.filterClinic();
        break;
    }
    if (itemModel.ClinicOption === 'mag') {
      this.handleChangeValue('NotifyDoctorAppointmentConfirmed', false);
    } else if (doctorModel?.Id > 0) {
      this.handleChangeValue('NotifyDoctorAppointmentConfirmed', true);
    }
    this.bindCommisionRate();
  };

  formatReqDate = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date).toISOString();
    }
    return date.toISOString();
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      if (Object.keys(item).length > 1) {
        const arr_ = arr.filter(i => i[key] !== item[key]);
        const item_ = toJS(item);
        return [...arr_, item_];
      } else {
        return arr;
      }
    } else if (key && item && item[key] && !!!arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  handleCheckCurrentRate = () => {
    if (this.specialist && this.specialist.Id) {
      Promise.all([checkCurrentRate()])
        .then(
          action(response => {
            if (response.status === 'success') {
              const specialist = toJS(this.specialist);
              const d = response.data;
              this.specialist = {
                ...specialist,
                MAGChargeRate: d.MAGChargeRate,
                MAGChargeRateFrom: d.MAGChargeRateFrom,
                MAGChargeRateTo: d.MAGChargeRateTo,
                MAGChargeRate2: d.MAGChargeRate2,
                MAGChargeRate2From: d.MAGChargeRate2From,
                MAGChargeRate2To: d.MAGChargeRate2To,
                ChargeRate: d.ChargeRate,
                ChargeRateFrom: d.ChargeRateFrom,
                ChargeRateTo: d.ChargeRateTo,
                ChargeRate2: d.ChargeRate2,
                ChargeRate2From: d.ChargeRate2From,
                ChargeRate2To: d.ChargeRate2To,
                CommissionOnTop: d.CommissionOnTop,
                CommissionOnTopFrom: d.CommissionOnTopFrom,
                CommissionOnTopTo: d.CommissionOnTopTo,
                CommissionOnTop2: d.CommissionOnTop2,
                CommissionOnTop2From: d.CommissionOnTop2From,
                CommissionOnTop2To: d.CommissionOnTop2To,
              };
              this.handleCommissionRateTypeChange();
            } else {
              notification.destroy();
              notification.error({
                message: 'Error',
                description:
                  'Error occured while contacting server. Please report this error to system administrator and try again later.',
              });
            }
          }),
        )
        .catch(() => {
          notification.destroy();
          notification.error({
            message: 'Error',
            description:
              'Error occured while contacting server. Please report this error to system administrator and try again later.',
          });
        });
    }
  };

  bindCommisionRate = () => {
    const itemModel = toJS(this.assessmentInfo);
    if (itemModel.ClinicOption === 'mag' && itemModel.DictationOption === '0') {
      this.handleChangeValue('CommissionRateType', 0);
      this.handleChangeValue('CommissionRate', toJS(this.specialist?.MAGChargeRate) || 0);
      this.handleCommissionRateTypeChange();
    }

    if (itemModel.ClinicOption === 'doctor' && itemModel.DictationOption === '1') {
      this.handleChangeValue('CommissionRateType', 1);
      this.handleChangeValue('CommissionRate', toJS(this.specialist?.ChargeRate) || 0);
      this.handleCommissionRateTypeChange();
    }
  };

  getAppointmentData = async (SessionId, functionCallBack) => {
    try {
      const result = await getAssessmentData(SessionId);
      //console.log(result);
      if (result.status === 'success') {
        if (functionCallBack && result.item) {
          if (!this.loading) {
            notification.destroy();
            notification.success({
              message: 'Get appointment data.',
              description: 'Successfully',
            });
          }
          return functionCallBack(result.item);
        }
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: result.error ? result.error : 'Error occured while getting appointment data.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Error occured while getting appointment data.',
      });
    }
  };

  handleConfirmSession = async (item, functionCallBack) => {
    try {
      const result = await getAssessmentData(item.SessionId, true);
      if (result.status === 'success') {
        this.handleChangeValue('IsAppointmentConfirmed', true);
        this.handleChangeValue('AppointmentConfirmedDate', moment());
        this.handleChangeValue('Appointments', []);
        this.handleChangeValue('SessionId', item.SessionId);
        if (functionCallBack && result.item) {
          if (!this.loading) {
            notification.destroy();
            notification.success({
              message: 'Get appointment data.',
              description: 'Successfully',
            });
          }
          return functionCallBack(result.item);
        }
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description:
            result?.error === 'This session is already booked'
              ? 'This session has been booked in another case. Please select another session.'
              : result?.error || 'Error occured while getting appointment data.',
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'Error occured while getting appointment data.',
      });
    }
  };

  generateTimeRange = total => {
    let result = [];
    for (let i = 0; i < total; i++) {
      if (i < 10) {
        result.push({ label: '0' + i, value: i });
      } else result.push({ label: i.toString(), value: i });
    }
    return result;
  };

  @action loadTimeList = () => {
    this.hourList = this.generateTimeRange(24);
    this.minuteList = this.generateTimeRange(60);
  };

  renderAssessmentPIC = (typeName, caseStatuses) => {
    if (caseStatuses) {
      const typePIC = ASSESSMENT_PIC_IN_CHARGE_OF[typeName];
      const PICS = caseStatuses.pics && caseStatuses.pics.length ? caseStatuses.pics : [];
      if (PICS.length !== 0) {
        return PICS.filter(i => i.picId !== null)
          .filter(i => i.inChargeOf === typePIC)
          .map(i => ({
            picId: i.picId,
            picName: i.picName,
            inChargeOf: i.inChargeOf,
          }))[0];
      }
      return {
        picId: null,
        picName: null,
        inChargeOf: null,
      };
    }
    return {
      picId: null,
      picName: null,
      inChargeOf: null,
    };
  };

  handleLockReportCompleteCM = itemModel => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return {
        lockDictationReceived: false,
        lockReportDraftReceived: false,
        lockDoctor: false,
        lockDoctorDirect: false,
        lockReportComplete_CM: false,
        oldPaperworkReceived: !itemModel.Id ? false : itemModel.PaperWorkReceived,
      };
    } else if (api.isCaseManager()) {
      return {
        lockDictationReceived: true,
        lockReportDraftReceived: true,
        lockDoctor: true,
        lockDoctorDirect: true,
        lockReportComplete_CM: itemModel.ReportCompleted || true,
        lockTypist: false,
        oldPaperworkReceived: !itemModel.Id ? false : itemModel.PaperWorkReceived,
      };
    } else if (api.isDoctor()) {
      return {
        LockCMDoctor: true,
        oldPaperworkReceived: !itemModel.Id ? true : itemModel.PaperWorkReceived,
      };
    } else if (api.isTypist()) {
      return {
        oldPaperworkReceived: true,
        LockCMDoctor: true,
        oldCancell: true,
        lockDictationReceived: true,
        lockDoctor: true,
        lockDoctorDirect: true,
        lockReportComplete_CM: true,
        lockReportDraftReceived: true,
        lockTypist: true,
      };
    } else {
      if (itemModel.DictationReceived && itemModel.ReportReturnedFromDoctor) {
        return { lockReportComplete_CM: true, lockDoctor: true };
      }
      if (itemModel.ReportCompleted) {
        return { lockReportComplete_CM: true, lockDoctorDirect: true };
      }
    }
  };

  renderAttachment = (attachments, reportType) => {
    return attachments ? attachments.filter(attachment => attachment.ReportType === reportType) : [];
  };
  renderAttachments = (attachments, reportTypes) => {
    return attachments ? attachments.filter(attachment => reportTypes.includes(attachment.ReportType)) : [];
  };

  @action initDataAssessment = async (dId = 0, sId = 0) => {
    this.submitData.notifyCmAboutAppointmentInquiry = true;
    let doctorInfo = null;
    if (!!dId) {
      this.keywordAllWithoutNCI = dId;
      doctorInfo = await getDoctorInfo(dId);
    }
    this.loadTimeList();
    Promise.all([
      getAssessmentInfo(0),
      getActionRequired(0),
      getActiveClaimTypeList(),
      getAllByStatus(this.numberStatusPerPage, this.showingStatus),
      getAllWithoutNCI(this.keywordAllWithoutNCI, this.numItemPerPageAllWithoutNCI),
      getAllWithoutNCI('', this.numItemPerPageAllWithoutNCI),
      getInterProvider(),
      getClinicList(this.doctorIdClinicList, this.numItemPerPageClinicList, this.keywordClinicList),
      getConversationList(0),
      getIMSApprovalCode(),
      getLanguageList(),
      getLookupConfigByTypes(this.typeLookupConfig),
      getPatientList(this.keywordPatient, this.numberPatientPerPage),
      getRooms(this.clinicIdRooms),
      getSpecificLookupConfigOrderNoByType(this.typeLookupConfigOrderNo),
      getStaffList(),
      getTypists(),
      getWIROPrincipleLawyer(),
      getActiveReportTypeList(),
      getLookUpConfig('ReasonReferral'),
      getLookupIcareReport(),
    ])
      .then(
        action(
          ([
            assessmentInfo,
            actionRequired,
            activeClaimTypeList,
            allByStatus,
            allWithoutNCI,
            allWithoutNCISupplierResp,
            clinicInterpretationServiceList,
            clinicList,
            conversationList,
            iMSApprovalCode,
            languageList,
            lookupConfigParents,
            patientList,
            rooms,
            specificLookupConfigOrderNoByType,
            staffList,
            typists,
            wiroPrincipleLawyer,
            activeReportType,
            activeReasonReferralList,
            icareLookupList,
          ]) => {
            //Data
            this.clinicInterpretationServiceList = clinicInterpretationServiceList.itemList;
            this.listspecificLookupConfigOrderNoByType = specificLookupConfigOrderNoByType;
            this.listSpecialist = allWithoutNCI;
            this.listSupplier = allWithoutNCISupplierResp;
            this.caseStatus = ASSESSMENT_STATUS;
            this.listBillingItemCode = allByStatus;
            this.listActionRequired = actionRequired;
            this.listStaff = staffList;
            this.assessmentInfo = {
              ...assessmentInfo.model,
              AssessmentAttachment: assessmentInfo.model.AssessmentAttachment
                ? assessmentInfo.model.AssessmentAttachment.map((i, idx) => ({
                    ...i,
                    key: idx,
                  }))
                : [],
              InterpretationStatus: -1,
              ActionRequired: true,
              DictationOption: 1,
            };
            this.listTypist = typists;
            this.listClaimType = activeClaimTypeList;
            this.listReasonReferral = activeReasonReferralList;
            this.listReportType = activeReportType;
            this.listClaimant = patientList;
            this.listConfigParents = lookupConfigParents;
            this.listIMSApprovalCode = iMSApprovalCode;
            this.listWiroPrincipleLawyer = wiroPrincipleLawyer;
            this.conversationList = conversationList;
            this.languageList = languageList;
            this.caseStatus = [];
            this.listClinic = clinicList;
            this.icareLookupList = icareLookupList;
            if (
              this.assessmentInfo.BlockedClients &&
              this.assessmentInfo.BlockedClients.ClientList &&
              this.assessmentInfo.BlockedClients.ClientList.length > 0
            ) {
              this.blockedClients = this.assessmentInfo.BlockedClients.ClientList;
            }
            //initial data
            const assessmentObj = Object.entries(assessmentInfo.model);
            assessmentObj.map(
              ([key, value]) =>
                key.toLowerCase().search('date') !== -1 &&
                value &&
                value.startsWith('/Date') &&
                this.handleChangeValue(key, this.momentToJS(assessmentInfo.model.DateFrom, null, true)),
            );
            //Submit Data
            //-- Claim details
            this.handleChangeValue('EassessClaimantLocationType', 'ClaimantHome');
            this.handleChangeValue('EassessDoctorLocationType', 'DoctorOffice');
            this.claimant = assessmentInfo.model.Patient;
            const subBookingSolicitor = [
              assessmentInfo.model.SubCaseManager1,
              assessmentInfo.model.SubCaseManager2,
              assessmentInfo.model.SubCaseManager3,
            ].filter(i => i);
            this.subBookingSolicitor = subBookingSolicitor;
            this.bodyParts = assessmentInfo.model.BodyParts || [];
            this.subBodyParts = assessmentInfo.model.SubBodyParts || [];
            this.claimReqSpecialty = assessmentInfo.model.ClaimReqSpecialty
              ? this.checkIsJSON(assessmentInfo.model.ClaimReqSpecialty)
                ? JSON.parse(assessmentInfo.model.ClaimReqSpecialty)
                : []
              : [];
            this.listRDC_2_CMKeyword = assessmentInfo.model.S_RDCCaseManager?.FullName || '';
            // this.listRDC_2_CMCompanyId = this.bookingClient.Id || 0;
            this.PIC_1 = this.renderAssessmentPIC('REQUEST_RECEIVED', null);
            this.PIC_2 = this.renderAssessmentPIC('APPOINTMENT_CONFIRMED', null);
            this.PIC_3 = this.renderAssessmentPIC('PAPERWORK_RECEIVED', null);
            this.PIC_4 = this.renderAssessmentPIC('ATTENDED', null);
            this.PIC_5 = this.renderAssessmentPIC('DICTATION_RECEIVED', null);
            this.PIC_6 = this.renderAssessmentPIC('REPORT_DRAFT_RECEIVED', null);
            this.PIC_7 = this.renderAssessmentPIC('CORRECTED_REPORT', null);
            this.PIC_8 = this.renderAssessmentPIC('REPORT_COMPLETED', null);
            this.PIC_9 = this.renderAssessmentPIC('CANCELLED_SPECIALIST_TBP', null);
            this.clinic = assessmentInfo.model.Clinic;
            this.specialist = assessmentInfo.model.Doctor;
            this.iMSApprovalCode = assessmentInfo.model.IMSApprovalCode;
            this.Report_Delivery_Contact_1 = assessmentInfo.model.F_RDCCaseManagerId
              ? {
                  Id: assessmentInfo.model.F_RDCCaseManagerId,
                  FirstName: assessmentInfo.model.F_RDCfirstName,
                  LastName: assessmentInfo.model.F_RDClastName,
                  Email: assessmentInfo.model.F_RDCEmail,
                  FullName: assessmentInfo.model.F_RDCfirstName + ' ' + assessmentInfo.model.F_RDClastName,
                }
              : {};
            this.Report_Delivery_Contact_2 = assessmentInfo.model.S_RDCCaseManagerId
              ? {
                  Id: assessmentInfo.model.S_RDCCaseManagerId,
                  FirstName: assessmentInfo.model.S_RDCfirstName,
                  LastName: assessmentInfo.model.S_RDClastName,
                  Email: assessmentInfo.model.S_RDCEmail,
                  FullName: assessmentInfo.model.S_RDCfirstName + ' ' + assessmentInfo.model.S_RDClastName,
                }
              : {};
            this.bookingSolicitorKeyword = assessmentInfo.model.BookingSolicitor?.Id || '';
            this.bookingSolicitorCompanyId = this.bookingClient.Id || 0;
            this.bookingSolicitor = assessmentInfo.model.BookingSolicitor;
            this.subBookingClient = assessmentInfo.model.MasterClient;
            this.subBookingClientCM = assessmentInfo.model.SubBookingClientCM;
            this.companyInsurer = assessmentInfo.model.CompanyInsurer;
            this.claimType = assessmentInfo.model.ClaimType;
            this.reasonReferral = assessmentInfo.model.ReasonReferral;
            this.icareServiceType = assessmentInfo.model.IcareServiceType;
            this.reportType = assessmentInfo.model.ReportType;
            this.keywordSubCompany = assessmentInfo.model.MasterClient?.Id || '';
            //-- Client Invoice
            this.payingClient = assessmentInfo.model.PCustomer;
            this.keywordPayingCompany = assessmentInfo.model.PCustomer?.Id;
            this.referenceClient = assessmentInfo.model.CReference;
            this.keywordReferenceCompany = assessmentInfo.model.CReference?.Id;
            this.payingCasemanger = assessmentInfo.model.PCaseManager;
            this.keywordPayingCaseManager = assessmentInfo.model.PCaseManager?.Id;
            //
            if (!!sId) {
              this.getAppointmentData(sId, this.BindAppointmentData);
              this.handleChangeValue('SessionId', sId);
            } else {
              getLookupConfigByParents(assessmentInfo.model.BodyParts ? assessmentInfo.model.BodyParts : []).then(
                getLookupResp => {
                  if (getLookupResp.status === 'success') {
                    this.lookupConfigsChild = getLookupResp;
                  }
                },
              );
            }
            if (!assessmentInfo.model.copy) {
              if (!!sId) {
                this.handleChangeValue('SessionId', sId);
                this.getAppointmentData(sId, this.BindAppointmentData);
              } else {
                this.handleChangeValue('ClinicOption', 'mag');
                this.handleChangeValue('DateFrom', null);
                this.handleChangeValue('DateTo', null);
                this.handleChangeValue('DateFromHour', this.hourList[9]);
                this.handleChangeValue('DateFromMinute', this.minuteList[0]);
                this.handleChangeValue('DateToHour', this.hourList[17]);
                this.handleChangeValue('DateToMinute', this.minuteList[0]);
                // GetClinics(itemModel.ClinicOption, 0);
              }
            }
            if (api.isCaseManager()) {
              const caseManagerInfo = api.currentUser.data;
              this.bookingClient = { Id: caseManagerInfo.ClientId };
              this.bookingCaseManager = {
                Id: caseManagerInfo.CaseManagerId,
                FullName: caseManagerInfo.FullName,
                Email: caseManagerInfo.email,
              };

              //this.bookingCMKeyword = caseManagerInfo.CaseManagerId || '';
              this.bookingCMCompanyId = caseManagerInfo.ClientId || 0;
              //this.listRDC_1_CMKeyword = caseManagerInfo.CaseManagerId || '';
              this.listRDC_1_CMCompanyId = caseManagerInfo.ClientId || 0;
              this.bookingSolicitorCompanyId = caseManagerInfo.ClientId || 0;
              this.listRDC_2_CMCompanyId = caseManagerInfo.ClientId || 0;
              this.keywordCompany = caseManagerInfo.ClientId || '';
              this.Report_Delivery_Contact_1 = {
                Id: caseManagerInfo.CaseManagerId,
                FullName: caseManagerInfo.FullName,
                Email: caseManagerInfo.email,
              };
              this.handleChangeValue('F_RDCSameBookingCM', true);
            } else {
              this.bookingClient = assessmentInfo.model.Customer || {};
              this.bookingCaseManager = assessmentInfo.model.CaseManager || {};
              this.bookingCMKeyword = assessmentInfo.model.CaseManager?.Id || '';
              this.bookingCMCompanyId = this.bookingClient.Id || 0;
              this.listRDC_1_CMKeyword = assessmentInfo.model.F_RDCCaseManagerId || '';
              this.listRDC_1_CMCompanyId = this.bookingClient.Id || 0;
              this.keywordCompany = assessmentInfo.model.Customer?.Id || '';
            }

            if (!!doctorInfo) {
              const doctorSelected = allWithoutNCI.itemList.find(i => i.Id === dId);
              this.specialist = {
                ...doctorSelected,
              };
              this.handleChangeValue('SelectedDoctorProviderNoIdx', 1);
            }
            Promise.all([
              //-- Claim Details
              getStaffGetAll(this.listRDC_2_CMKeyword, 1000, this.listRDC_2_CMCompanyId), //data for RDC_2
              getStaffGetAll(
                this.bookingSubSolicitorKeyword,
                this.bookingSubSolicitorNumItemPerPage,
                this.bookingSubSolicitorCompanyId,
              ), //data for booking sub solicitor
              getCompanyList(this.keywordCompany, this.numberCompanyPerPage, true), //data for booking company
              getCompanyList(this.keywordSubCompany, this.numberSubCompanyPerPage), //data for booking sub company
              getCompanyList(this.keywordSubBookingClientCM, this.numberSubBookingClientCMPerPage), //data for subBookingClientCM
              getCompanyInsuer(this.keywordCompanyInsurer, this.numberCompanyInsurerPerPage), //data for Insurer
              //-- Client Invoice
              getCompanyList(this.keywordPayingCompany, this.numberPerPagePayingCompany), //data for paying client
              getCompanyList(
                assessmentInfo.model.CReferenceId || this.keywordReferenceCompany,
                this.numberPerPageReferenceCompany,
              ), //data for reference client
              getStaffGetAll(this.bookingCMKeyword, this.bookingCMNumItemPerPage, this.bookingCMCompanyId), //data for booking cm
              getStaffGetAll(this.listRDC_1_CMKeyword, this.listRDC_1_CMNumItemPerPage, this.listRDC_1_CMCompanyId), //data for RDC_1
            ])
              .then(
                action(
                  ([
                    //lookupconfigChild,
                    rdc2CM,
                    bookingSubSolicitor,
                    bookingClient,
                    subBookingClient,
                    subBookingClientCM,
                    companyInsurer,
                    payingClient,
                    referenceClient,
                    bookingCMs,
                    rdc1CM,
                  ]) => {
                    this.listBookingSolicitor = this.bookingCMCompanyId ? bookingCMs : null;
                    //this.listConfigChild = lookupconfigChild;
                    this.listBookingCM = this.bookingCMCompanyId ? bookingCMs : null;
                    this.listRDC_1_CM = this.listRDC_1_CMCompanyId ? rdc1CM : null;
                    this.listRDC_2_CM = rdc2CM;
                    this.listWaitingList = bookingSubSolicitor;
                    this.listBookingSubSolicitor = bookingSubSolicitor;
                    this.listCompany = bookingClient;
                    this.listSubCompany = subBookingClient;
                    this.listSubBookingClientCM = subBookingClientCM;
                    this.listCompanyInsurer = companyInsurer;
                    this.handleActiveUploadType(this.currentStep);
                    //-- Client Invoice
                    this.listPayingCompany = payingClient;
                    this.listReferenceCompany = referenceClient;
                    this.listPayingCaseManager = {};
                    this.loading = false;
                  },
                ),
              )
              .catch(() => {
                dashboardStore.close(window.location.pathname);
              });
          },
        ),
      )
      .catch(() => {
        dashboardStore.close(window.location.pathname);
      });
  };

  @action fetchAssessmentInfo = (id = 0, dId = 0, sId = 0) => {
    this.loadTimeList();
    if (!id || !!dId || !!sId) {
      return this.initDataAssessment(dId, sId);
    }
    return Promise.all([
      getAssessmentInfo(id),
      getActionRequired(id),
      getActiveClaimTypeList(),
      getAllByStatus(this.numberStatusPerPage, this.showingStatus),
      getAssessmentStatus(id),
      getInterProvider(),
      getConversationList(id),
      getIMSApprovalCode(),
      getLanguageList(),
      getListInvoiceID(id),
      getLookupConfigByTypes(this.typeLookupConfig),
      getRooms(this.clinicIdRooms),
      getSpecificLookupConfigOrderNoByType(this.typeLookupConfigOrderNo),
      getStaffList(),
      getTypists(),
      getWIROPrincipleLawyer(),
      getActiveReportTypeList(),
      getAssessmentLogs(id),
      getLookUpConfig('ReasonReferral'),
      getLookupIcareReport(),
    ]).then(
      action(
        ([
          assessmentInfo,
          actionRequired,
          activeClaimTypeList,
          allByStatus,
          assessmentStatus,
          clinicInterpretationServiceList,
          conversationList,
          iMSApprovalCode,
          languageList,
          listInvoiceID,
          lookupConfigParents,
          rooms,
          specificLookupConfigOrderNoByType,
          staffList,
          typists,
          wiroPrincipleLawyer,
          activeReportType,
          // interpreterProvider,
          assessmentStatusLog,
          activeReasonReferralList,
          icareLookupList,
        ]) => {
          //Data
          this.clinicInterpretationServiceList = clinicInterpretationServiceList.itemList;
          this.listspecificLookupConfigOrderNoByType = specificLookupConfigOrderNoByType;
          this.listBillingItemCode = allByStatus;
          this.listActionRequired = actionRequired;
          this.assessmentInfo = {
            ...assessmentInfo.model,
            AssessmentAttachment: assessmentInfo?.model?.AssessmentAttachment?.map((i, idx) => ({ ...i, key: idx })),
            ActionRequired: true,
          };
          if (this.assessmentInfo.LstInvoiceItemFee) {
            this.lstInvoiceItemFee = this.assessmentInfo.LstInvoiceItemFee;
          } else {
            this.lstInvoiceItemFee = [];
          }
          const caseNo = `Case ${assessmentInfo.model.AssessmentNumber}`;

          dashboardStore.setTabTitle('/view/add-edit-ime-assessment-2', caseNo);
          this.AdditionalServiceSupplierObj = this.assessmentInfo.AdditionalServiceSupplierObj;
          // this.interpreterProvider = interpreterProvider.itemList;
          this.caseStatus = assessmentStatus;
          this.statusLogs = assessmentStatusLog;
          this.listTypist = typists;
          this.listClaimType = activeClaimTypeList;
          this.listReasonReferral = activeReasonReferralList;
          this.listReportType = activeReportType;
          this.listConfigParents = lookupConfigParents;
          this.listIMSApprovalCode = iMSApprovalCode;
          this.listWiroPrincipleLawyer = wiroPrincipleLawyer;
          this.conversationList = conversationList;
          this.languageList = languageList;
          this.listInvoiceID = listInvoiceID;
          this.icareLookupList = icareLookupList;
          //initial data
          if (!!assessmentInfo.model?.SessionId) {
            getWaitingList(assessmentInfo.model.SessionId).then(
              action(res => {
                this.waitingList = res.waitingList;
              }),
            );

            if (
              !!assessmentInfo.model?.SpecialistAcceptedTypes &&
              assessmentInfo.model?.SpecialistAcceptedTypes.length > 0
            ) {
              this.specialistAcceptedTypes = assessmentInfo.model.SpecialistAcceptedTypes;
              var acceptedTypeText = '';
              assessmentInfo.model.SpecialistAcceptedTypes.forEach(t => {
                acceptedTypeText = acceptedTypeText + t.Name + ', ';
              });
              acceptedTypeText = acceptedTypeText.substring(0, acceptedTypeText.length - 2);
              this.specialistAcceptedTypesText = acceptedTypeText;
            } else {
              this.specialistAcceptedTypes = [];
              this.specialistAcceptedTypesText = '';
            }
          }

          const assessmentObj = Object.entries(assessmentInfo.model);
          assessmentObj.map(
            ([key, value]) =>
              key.toLowerCase().search('date') !== -1 &&
              value &&
              value.startsWith('/Date') &&
              this.handleChangeValue(key, this.momentToJS(value, null, true)),
          );
          if (!api.isAdminOrMagStaffOrAccounting()) {
            this.handleChangeValue('isLocked', true);
          }
          if (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor()) {
            this.handleChangeValue('servicePrepaymentLocked', true);
          }
          if (!assessmentInfo.model.ClinicOption) {
            this.handleChangeValue('ClinicOption', 'doctor');
          }
          // GetClinics(itemModel.ClinicOption, 0);
          if (toJS(this.assessmentInfo).DateFrom != null) {
            this.handleChangeValue('DateFromHour', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateFrom.hour()));
            this.handleChangeValue(
              'DateFromMinute',
              this.parseTimeRangeItem(toJS(this.assessmentInfo).DateFrom.minutes()),
            );
          }

          if (toJS(this.assessmentInfo).DateTo != null) {
            this.handleChangeValue('DateToHour', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateTo.hour()));
            this.handleChangeValue('DateToMinute', this.parseTimeRangeItem(toJS(this.assessmentInfo).DateTo.minutes()));
          }

          if (toJS(this.assessmentInfo).ActualDateFrom != null) {
            this.handleChangeValue(
              'ActualDateFromHour',
              this.parseTimeRangeItem(toJS(this.assessmentInfo).ActualDateFrom.hour()),
            );
            this.handleChangeValue(
              'ActualDateFromMinute',
              this.parseTimeRangeItem(toJS(this.assessmentInfo).ActualDateFrom.minutes()),
            );
          }
          if (toJS(this.assessmentInfo).ActualDateTo != null) {
            this.handleChangeValue(
              'ActualDateToHour',
              this.parseTimeRangeItem(toJS(this.assessmentInfo).ActualDateTo.hour()),
            );
            this.handleChangeValue(
              'ActualDateToMinute',
              this.parseTimeRangeItem(toJS(this.assessmentInfo).ActualDateTo.minutes()),
            );
          }
          if (toJS(this.assessmentInfo).NotifyCmAppointmentConfirmed != null) {
            this.submitData.notifyCmAboutAppointmentConfirmed = toJS(this.assessmentInfo).NotifyCmAppointmentConfirmed;
          }

          var countPage = 0;
          var attachPaperwor = this.assessmentInfo.AssessmentAttachment.filter(
            i => i.ReportType === 1 || i.ReportType === 9,
          );
          if (attachPaperwor.length > 0) {
            attachPaperwor.forEach(element => {
              countPage += element.NumberOfPages;
            });
            this.totalPages = countPage;
          }
          //Submit Data
          //-- Claim details
          this.bookingClient = assessmentInfo.model.Customer || {};
          this.bookingCaseManager = assessmentInfo.model.CaseManager || {};
          this.claimant = assessmentInfo.model.Patient;
          this.keywordPatient = assessmentInfo.model.Patient?.Id || '';
          const subBookingSolicitor = [
            assessmentInfo.model.SubCaseManager1,
            assessmentInfo.model.SubCaseManager2,
            assessmentInfo.model.SubCaseManager3,
          ].filter(i => i);
          // this.bookingSubSolicitorCompanyId = this.bookingClient.Id || 0;
          this.subBookingSolicitor = subBookingSolicitor;
          this.bodyParts = assessmentInfo.model.BodyParts || [];
          this.subBodyParts = assessmentInfo.model.SubBodyParts || [];
          this.claimReqSpecialty = assessmentInfo.model.ClaimReqSpecialty
            ? this.checkIsJSON(assessmentInfo.model.ClaimReqSpecialty)
              ? JSON.parse(assessmentInfo.model.ClaimReqSpecialty)
              : []
            : [];
          this.bookingCMKeyword = assessmentInfo.model.CaseManager?.Id || '';
          this.bookingCMCompanyId = this.bookingClient.Id || 0;
          this.listRDC_1_CMKeyword = assessmentInfo.model.F_RDCCaseManagerId || '';
          this.listRDC_1_CMCompanyId = this.bookingClient.Id || 0;
          this.listRDC_2_CMKeyword = assessmentInfo.model.S_RDCCaseManager?.FullName || '';
          // this.listRDC_2_CMCompanyId = this.bookingClient.Id || 0;
          this.PIC_1 = this.renderAssessmentPIC('REQUEST_RECEIVED', assessmentStatus);
          this.PIC_2 = this.renderAssessmentPIC('APPOINTMENT_CONFIRMED', assessmentStatus);
          this.PIC_3 = this.renderAssessmentPIC('PAPERWORK_RECEIVED', assessmentStatus);
          this.PIC_4 = this.renderAssessmentPIC('ATTENDED', assessmentStatus);
          this.PIC_5 = this.renderAssessmentPIC('DICTATION_RECEIVED', assessmentStatus);
          this.PIC_6 = this.renderAssessmentPIC('REPORT_DRAFT_RECEIVED', assessmentStatus);
          this.PIC_7 = this.renderAssessmentPIC('CORRECTED_REPORT', assessmentStatus);
          this.PIC_8 = this.renderAssessmentPIC('REPORT_COMPLETED', assessmentStatus);
          this.PIC_9 = this.renderAssessmentPIC('CANCELLED_SPECIALIST_TBP', assessmentStatus);
          this.listStaff = {
            ...staffList,
            itemList: uniqBy(
              [
                ...(staffList.itemList || []),
                ...assessmentStatus.pics.map(i => ({ FullName: i.picName, Id: i.picId })),
              ],
              'Id',
            ),
          };
          this.clinic = assessmentInfo.model.Clinic;
          this.specialist = assessmentInfo.model.Doctor;
          this.iMSApprovalCode = assessmentInfo.model.IMSApprovalCode;
          this.Report_Delivery_Contact_1 = assessmentInfo.model.F_RDCCaseManagerId
            ? {
                Id: assessmentInfo.model.F_RDCCaseManagerId,
                FirstName: assessmentInfo.model.F_RDCfirstName,
                LastName: assessmentInfo.model.F_RDClastName,
                Email: assessmentInfo.model.F_RDCEmail,
                FullName: assessmentInfo.model.F_RDCfirstName + ' ' + assessmentInfo.model.F_RDClastName,
              }
            : {};
          this.Report_Delivery_Contact_2 = assessmentInfo.model.S_RDCCaseManagerId
            ? {
                Id: assessmentInfo.model.S_RDCCaseManagerId,
                FirstName: assessmentInfo.model.S_RDCfirstName,
                LastName: assessmentInfo.model.S_RDClastName,
                Email: assessmentInfo.model.S_RDCEmail,
                FullName: assessmentInfo.model.S_RDCfirstName + ' ' + assessmentInfo.model.S_RDClastName,
              }
            : {};
          this.bookingSolicitorKeyword = assessmentInfo.model.BookingSolicitor?.Id || '';
          this.bookingSolicitorCompanyId = this.bookingClient.Id || 0;
          this.bookingSolicitor = assessmentInfo.model.BookingSolicitor;
          this.subBookingClient = assessmentInfo.model.MasterClient;
          this.subBookingClientCM = assessmentInfo.model.SubBookingClientCM;
          this.companyInsurer = assessmentInfo.model.CompanyInsurer;
          this.claimType = assessmentInfo.model.ClaimType;
          this.reasonReferral = assessmentInfo.model.ReasonReferral;
          this.icareServiceType = assessmentInfo.model.IcareServiceType;
          this.reportType = assessmentInfo.model.ReportType;
          this.keywordCompany = assessmentInfo.model.Customer?.Id || '';
          this.keywordSubCompany = assessmentInfo.model.MasterClient?.Id || '';
          this.keywordSubBookingClientCM = assessmentInfo.model.SubBookingClientCM?.Id || '';
          this.keywordCompanyInsurer = assessmentInfo.model.CompanyInsurer?.Id || '';
          //-- Client Invoice
          this.payingClient = assessmentInfo.model.PCustomer;
          this.keywordPayingCompany = assessmentInfo.model.PCustomer?.Id;
          this.referenceClient = assessmentInfo.model.CReference;
          this.keywordReferenceCompany = assessmentInfo.model.CReference?.Id;
          this.payingCasemanger = assessmentInfo.model.PCaseManager;
          this.keywordPayingCaseManager = assessmentInfo.model.PCaseManager?.Id;
          this.keywordAllWithoutNCI = assessmentInfo.model.Doctor?.Id || '';
          this.keywordClinicList = assessmentInfo.model.Clinic?.ID || '';
          this.clinic = assessmentInfo.model.Clinic;
          Promise.all([
            //-- Claim Details
            getLookupConfigByParents(assessmentInfo.model.BodyParts ? assessmentInfo.model.BodyParts : []),
            getPatientList(this.keywordPatient, this.numberPatientPerPage),
            getStaffGetAll(this.bookingCMKeyword, this.bookingCMNumItemPerPage, this.bookingCMCompanyId), //data for booking cm
            getStaffGetAll(this.listRDC_1_CMKeyword, this.listRDC_1_CMNumItemPerPage, this.listRDC_1_CMCompanyId), //data for RDC_1
            getStaffGetAll(
              this.bookingSolicitorKeyword,
              this.bookingSolicitorNumItemPerPage,
              this.bookingSolicitorCompanyId,
            ), //data for booking solicitor
            getStaffGetAll(this.listRDC_2_CMKeyword, 1000, this.listRDC_2_CMCompanyId), //data for RDC_2
            getStaffGetAll(
              this.bookingSubSolicitorKeyword,
              this.bookingSubSolicitorNumItemPerPage,
              this.bookingSubSolicitorCompanyId,
            ), //data for booking sub solicitor
            getCompanyList(this.keywordCompany, this.numberCompanyPerPage), //data for booking company
            getCompanyList(this.keywordSubCompany, this.numberSubCompanyPerPage), //data for booking sub company
            //-- Client Invoice
            getCompanyList(this.keywordPayingCompany, this.numberPerPagePayingCompany), //data for paying client
            getCompanyList(
              assessmentInfo.model.CReferenceId || this.keywordReferenceCompany,
              this.numberPerPageReferenceCompany,
            ), //data for reference client
            getStaffGetAll(
              this.keywordPayingCaseManager,
              this.numberPerPagePayingCaseManager,
              assessmentInfo.model.PCustomerId || 0,
            ), //data for paying case manager
            getAllWithoutNCI(this.keywordAllWithoutNCI, this.numItemPerPageAllWithoutNCI),
            getAllWithoutNCI('', this.numItemPerPageAllWithoutNCI),
            getClinicList(
              this.doctorIdClinicList,
              this.numItemPerPageClinicList,

              this.keywordClinicList,
            ),
            getInterProviderCMs(assessmentInfo.model.InterpretationServiceClientId || 0),
            getStaffGetAll('', 1000, assessmentInfo.model.SubBookingClientCMId),
            getStaffGetAll('', 1000, this.bookingClient.Id),
            getCompanyInsuer(this.keywordCompanyInsurer, this.numberCompanyInsurerPerPage), //data for company insurer
          ]).then(
            action(
              ([
                lookupconfigChild,
                patientList,
                bookingCM,
                rdc1CM,
                bookingSolicitor,
                rdc2CM,
                bookingSubSolicitor,
                bookingClient,
                subBookingClient,
                payingClient,
                referenceClient,
                payingCasemanger,
                allWithoutNCI,
                allWithoutNCISupplierResp,
                clinicList,
                interpreterProviderCMs,
                subBookingClientCMs,
                bookingCMCC,
                companyInsurer,
              ]) => {
                this.listClinic = clinicList;
                this.listBookingSolicitor = bookingSolicitor;
                this.listSpecialist = allWithoutNCI;
                this.listSupplier = allWithoutNCISupplierResp;
                this.listConfigChild = lookupconfigChild;
                this.listBookingCM = bookingCM;
                this.listBookingCMCC = api.isAdminOrMagStaffOrAccounting() ? bookingCMCC : bookingCM;
                this.listRDC_1_CM = rdc1CM;
                this.listRDC_2_CM = rdc2CM;
                this.listWaitingList = bookingSubSolicitor;
                this.listBookingSubSolicitor = bookingSubSolicitor;
                this.listCompany = bookingClient;
                this.listSubCompany = subBookingClient;
                this.listSubBookingClientCM = subBookingClient;
                this.listCompanyInsurer = companyInsurer;
                this.handleActiveUploadType(this.currentStep);
                this.interpreterProviderCMs = interpreterProviderCMs.itemList;
                //-- Client Invoice
                this.listPayingCompany = payingClient;
                this.listReferenceCompany = referenceClient;
                this.listPayingCaseManager = payingCasemanger;
                this.loading = false;
                this.listClaimant = patientList;
                this.listSubBookingCM = subBookingClientCMs;
              },
            ),
          );
        },
      ),
    );
    // .catch(() => {
    //   dashboardStore.close(window.location.pathname);
    // });
  };

  @action handleRefetchActionNote = () => {
    Promise.all([getActionRequired(this.assessmentInfo.Id)]).then(
      action(([actionRequired]) => {
        this.listActionRequired = actionRequired;
      }),
    );
  };

  @action handleRefetchConversation = (searchKey = '') => {
    this.loadingConversation = true;
    Promise.all([getConversationList(this.assessmentInfo.Id)])
      .then(
        action(([conversationList]) => {
          if (searchKey !== '' && conversationList?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            conversationList.itemList = conversationList.itemList.filter(
              t => t.Content.toLowerCase().indexOf(searchKey) > -1,
            );
          }
          this.conversationList = conversationList;
          this.loadingConversation = false;
        }),
      )
      .catch(() => (this.loadingConversation = false));
  };

  checkActiveOrInactiveUpload = uploadKey => {
    const itemModel = toJS(this.assessmentInfo);
    if (uploadKey === 17) {
      if (itemModel.WifroIlars) {
        return true;
      } else {
        return false;
      }
    } else if (uploadKey === 4) {
      if (itemModel.AssessmentMethod && itemModel.EassessDoctorLocationChecklistReceived) {
        return true;
      } else {
        return false;
      }
    } else if (uploadKey === 2) {
      if (itemModel.AssessmentMethod && itemModel.EassessClaimantLocationChecklistReceived) {
        return true;
      } else {
        return false;
      }
    } else if (uploadKey === 3) {
      if (itemModel.AssessmentMethod && itemModel.ConsentFormReceived) {
        return true;
      } else {
        return false;
      }
    } else return true;
  };

  @action handleActiveUploadType = stepKey => {
    const uploadTypesActive = this.upLoadType.map(i => {
      if (i.belongToStep === stepKey) {
        return {
          ...i,
          active: this.checkActiveOrInactiveUpload(i.key),
        };
      } else return { ...i, active: false };
    });
    this.upLoadType = uploadTypesActive;
    return AssessmentReportType;
  };

  @action validateField = (value, type) => {
    if (type && value) {
      const v = new Validator({ value }, { value: type });
      return v.passes();
    }
    return true;
  };

  @action validateInput = (value, type, fieldName) => {
    const v = new Validator({ value }, { value: type });
    if (v.passes()) {
      const invalidForm = this.invalidForm.filter(i => i !== fieldName);
      return (this.invalidForm = invalidForm);
    } else {
      const invalidForm = this.invalidForm.filter(i => i === fieldName);
      if (!!invalidForm.length) {
        return;
      }
      return (this.invalidForm = [...this.invalidForm, fieldName]);
    }
  };

  validateForm = step => {
    let errors = [];
    let errorClaimDetails = [];
    let errorCaseDocuments = [];
    let errorDictations = [];
    let errorAppointmentDetail = [];
    let errorClientInvoice = [];
    //----- list errors
    const clinicOptionHome = 'home';
    const itemModel = toJS(this.assessmentInfo);
    const customer = toJS(this.bookingClient);
    const caseManager = toJS(this.bookingCaseManager);
    const patient = toJS(this.claimant);
    const reasonReferral = toJS(this.reasonReferral);
    const reportTypeModel = toJS(this.reportType);
    const clinicModel = toJS(this.clinic);
    const claimTypeModel = toJS(this.claimType);
    const CompletedAttachment = this.renderAttachment(itemModel.AssessmentAttachment, 4);
    const PaperworkAttachment = this.renderAttachment(itemModel.AssessmentAttachment, 1);
    const OtherPaperworkAttachment = this.renderAttachment(itemModel.AssessmentAttachment, 9);
    const DictationAttachment = this.renderAttachment(itemModel.AssessmentAttachment, 2);
    const DraftAttachment = this.renderAttachment(itemModel.AssessmentAttachment, 3);
    const attachmentModel = {
      CompletedAttachment,
      PaperworkAttachment,
      OtherPaperworkAttachment,
      DictationAttachment,
      DraftAttachment,
    };
    if (step !== 0) {
      if (!customer || !customer?.Id) {
        errorClaimDetails.push('Claim Details - Please select a booking client.');
      }
      if (!caseManager || !caseManager.Id) {
        errorClaimDetails.push('Claim Details - Please select a booking CM.');
      }
      if (!patient || !patient.Id) {
        errorClaimDetails.push('Claim Details - Please select a claimant.');
      }
      // if (itemModel.IcareClaim && (!reasonReferral || !reasonReferral.Id)) {
      //   errorClaimDetails.push('Claim Details - Please select a reason of referral.');
      // }
      if (!itemModel.ClaimNo) {
        errorClaimDetails.push('Claim Details - Please enter reference/claim no.');
      }
      if (!claimTypeModel || !claimTypeModel.Id) {
        errorClaimDetails.push('Claim Details - Please select a type of claim.');
      }
      if (itemModel.IROFundingApproved && !itemModel.IROFundingApprovalDate) {
        errorClaimDetails.push('Claim Details - IRO Funding Approval Date is required.');
      }
      if (!reportTypeModel || !reportTypeModel.Id) {
        errorClaimDetails.push('Claim Details - Please select a type of report.');
      }
      if (
        this.bodyParts.find(i => i.Label === 'Orthopaedic Surgeon') &&
        (!this.subBodyParts || this.subBodyParts.length <= 0)
      ) {
        errorClaimDetails.push('Claim Details - Please select required sub-specialty');
      }
      if (
        this.bodyParts.find(i => i.Label === 'Orthopaedic Surgeon') &&
        toJS(this.listConfigChildOrthopaedicSurgeon) &&
        toJS(this.listConfigChildOrthopaedicSurgeon).length > 0 &&
        this.subBodyParts &&
        this.subBodyParts.length > 0
      ) {
        let checkSelectInList = false;
        this.subBodyParts.forEach(subCheck => {
          if (!checkSelectInList) {
            let checkObj = toJS(this.listConfigChildOrthopaedicSurgeon).find(i => i.Id === subCheck.Id);
            if (checkObj) {
              checkSelectInList = true;
            }
          }
        });
        if (!checkSelectInList) {
          errorClaimDetails.push(
            'Claim Details - The selected specialist does not assess this sub-specialty. Please choose another specialist or contact our staff.',
          );
        }
      }
      // if (itemModel.F_RDCEmail && !this.validateEmail(itemModel.F_RDCEmail)) {
      //   errorClaimDetails.push('Claim Details - Please enter a valid email for Report Receiving Contact 1.');
      // }
      // if (itemModel.S_RDCEmail && !this.validateEmail(itemModel.S_RDCEmail)) {
      //   errorClaimDetails.push('Claim Details - Please enter a valid email for Report Receiving Contact 2.');
      // }
    }

    if (step !== 5) {
      if (itemModel.SendInvoice && !this.validateEmail(itemModel.SendInvoice)) {
        errorClientInvoice.push(
          'Client & Specialist Payments - Please enter a valid email for Email For Sending Invoices 1.',
        );
      }
      if (itemModel.SendInvoice2 && !this.validateEmail(itemModel.SendInvoice2)) {
        errorClientInvoice.push(
          'Client & Specialist Payments - Please enter a valid email for Email For Sending Invoices 2.',
        );
      }
    }
    if (step !== 1 && step !== 2) {
      if (itemModel.IsAppointmentConfirmed) {
        if (api.isAdminOrMagStaffOrAccounting() && !itemModel.DateFrom) {
          errorCaseDocuments.push('Appointment Details - Please select a date for appointment.');
        }
        if (itemModel.Interpreter && !itemModel.Language) {
          errorCaseDocuments.push('Appointment Details - Please select a language.');
        }
        // if (
        //   itemModel.Attendance === LOCAL_CONSTANT.Attendance_Attended ||
        //   itemModel.Attendance === LOCAL_CONSTANT.Attendance_NotAttended
        // ) {
        //   errorCaseDocuments.push(
        //     'Appointment Details & Case Documents - Appointment needs to be confirmed before Attended/Did not attend can be updated.',
        //   );
        // }
        if (itemModel.ClinicOption !== clinicOptionHome) {
          if (!itemModel.AssessmentMethod && !clinicModel?.ID) {
            errorCaseDocuments.push('Appointment Details - Please select a clinic.');
          }
        }
      }
      if (
        itemModel.ClinicOption !== clinicOptionHome &&
        itemModel.SessionAcceptsType === 0 &&
        this.specialist &&
        itemModel.DateFrom &&
        !this.clinic?.ID
      ) {
        errorCaseDocuments.push('Appointment Details - Please select a clinic.');
      }
      if (itemModel.Id === 0 && !!this.specialistAcceptedTypes && this.specialistAcceptedTypes.length > 0) {
        var findRT = this.specialistAcceptedTypes.find(r => r.Id === this.reportType?.Id);
        if (!!findRT) {
          errorCaseDocuments.push(
            'Appointment Details - This session does not accept this report type. Please check with our staff.',
          );
        }
      }

      if (itemModel.AssessmentMethod && itemModel.Attendance === CONSTANTS.Attendance_None) {
        if (!itemModel.EassessClaimantEmail) {
          errorCaseDocuments.push('Appointment Details - Claimant Arrangement Please input claimant email.');
        }
        if (itemModel.IsAppointmentConfirmed && !itemModel.AppointmentConfirmedDate) {
          errorCaseDocuments.push('Appointment Details -Please input Appointment Confirmation Date.');
        }
        if (
          itemModel.EassessClaimantLocationChecklistReceived &&
          !itemModel.EassessClaimantLocationChecklistReceivedDate
        ) {
          errorCaseDocuments.push('Appointment Details - Claimant Arrangement Please input checked date.');
        }
        if (
          itemModel.ConsentFormReceived &&
          !itemModel.ConsentFormReceivedDate &&
          api.isAdminOrMagStaffOrAccounting()
        ) {
          errorCaseDocuments.push('Appointment Details - Claimant Arrangement Please input received date.');
        }
        if (itemModel.EassessClaimantLocationConfirmed && !itemModel.EassessClaimantLocationConfirmedDate) {
          errorCaseDocuments.push('Appointment Details - Claimant Arrangement Please input confirmed date.');
        }
        if (!itemModel.EassessDoctorEmail) {
          errorCaseDocuments.push('Appointment Details - Specialist Arrangement Please input specialist email.');
        }
        if (!itemModel.EassessDoctorLocationConfirmedDate && itemModel.EassessDoctorLocationConfirmed) {
          errorCaseDocuments.push('Appointment Details - Specialist Arrangement Please input confirmed date.');
        }
        if (
          itemModel.EassessDoctorLocationChecklistReceived &&
          !itemModel.EassessDoctorLocationChecklistReceivedDate &&
          api.isAdminOrMagStaffOrAccounting()
        ) {
          errorCaseDocuments.push('Appointment Details - Specialist Arrangement Please input received date.');
        }
      }
      if (
        itemModel.PaperWorkReceived &&
        itemModel.AllowDoctorToViewPaperwork &&
        attachmentModel.PaperworkAttachment &&
        attachmentModel.OtherPaperworkAttachment &&
        attachmentModel.PaperworkAttachment.length === 0 &&
        attachmentModel.OtherPaperworkAttachment.length === 0 &&
        !itemModel.PaperworkExLink
      ) {
        errorCaseDocuments.push('Paperwork - Attach LOI and other documents is required');
      }
      // if(itemModel.PaperWorkReceived && !itemModel.OtherMethodSendingDate){
      //   errorCaseDocuments.push('Paperwork - Paperwork Sent Date is required.');
      // }
      // if(itemModel.IsInvoiceUrgentReport && !itemModel.UrgentReportReportCompletionDate){
      //   errorCaseDocuments.push('Paperwork - Requested Completion Date Before is required.');
      // }
      if (itemModel.IsInvoiceUrgentReport && (itemModel.UrgentReportType === 1 || itemModel.UrgentReportType === 2)) {
        const momentBusinessDays = require('moment-business-days');
        var dfCompletionDate = itemModel.DateFrom.businessDiff(itemModel.UrgentReportReportCompletionDate);
        if (itemModel.UrgentReportReportCompletionDate) {
          if (dfCompletionDate < 2 || dfCompletionDate > 7) {
            errorCaseDocuments.push(
              'Paperwork - Urgent report completion date must be between 2-7 business days of appointment date.',
            );
          }
        } else {
          errorCaseDocuments.push(
            'Paperwork - Urgent report completion date must be between 2-7 business days of appointment date.',
          );
        }
      }
      // if(itemModel.IsInvoiceUrgentReport && !!itemModel.DateFrom && !!itemModel.UrgentReportReportCompletionDate){
      //   var dayRCDBs = (itemModel.UrgentReportReportCompletionDate.startOf('day')).diff((itemModel.DateFrom.startOf('day')), 'days');
      //   console.log(dayRCDBs);
      //   //console.log(moment.duration(itemModel.UrgentReportReportCompletionDate.diff(itemModel.DateFrom)).asDays());
      //   if(dayRCDBs < 2){
      //      errorCaseDocuments.push('Paperwork - Urgent Report Completion Date must be at least 48 hours after appointment.');
      //   }
      // }
      if (
        itemModel.DateFrom &&
        !this.compareDate(
          itemModel.DateFrom ? moment(itemModel.DateFrom).format('DD/MM/YYYY HH:mm') : null,
          itemModel.DateTo ? moment(itemModel.DateTo).format('DD/MM/YYYY HH:mm') : null,
          true,
        )
      ) {
        errorCaseDocuments.push(
          'Appointment Details - Appointment time is not valid. Finish time must be after start time.',
        );
      }

      // if (itemModel.PaperWorkReceived && itemModel.PaperworkReceivedDate === null) {
      //   errorCaseDocuments.push('Paperwork - You should specify paperwork received date.');
      // }
      // if (itemModel.PaperWorkReceived && !itemModel.OtherMethodSendingDate) {
      //   errorCaseDocuments.push('Paperwork - Please select a paperwork sent date.');
      // }
      if (itemModel.IsAppointmentConfirmed && !itemModel.DateFrom) {
        errorCaseDocuments.push('Appointment Details - Appointment date/time must be entered.');
      }
      if (itemModel.AssessmentMethod && itemModel.EassessClaimantLocationType === 0) {
        errorCaseDocuments.push('Appointment Details - Please select a location of Claimant.');
      }
      if (itemModel.Interpreter === '1' && itemModel.HasInterpretationService === '1') {
        if (itemModel.InterpretationStatus === '0' && !itemModel.InterpretationServiceConfirmedDateTime) {
          errorCaseDocuments.push('Appointment Details - Please select date of Interpreter Confirmation Date.');
        }
        if (
          (itemModel.InterpretationStatus === '1' || itemModel.InterpretationStatus === '2') &&
          !itemModel.InterpreterStatusCancelledDate
        ) {
          errorCaseDocuments.push('Appointment Details - Please select date of Interpreter Cancellation.');
        }
      }
      // if (
      //   itemModel.DateFrom &&
      //   !itemModel.RequestExpectedCompletionDate &&
      //   itemModel.Attendance !== CONSTANTS.Attendance_LCF &&
      //   itemModel.Attendance !== CONSTANTS.Attendance_Cancelled
      // ) {
      //   errorCaseDocuments.push('Appointment Details - Please input Expected Completion Date');
      // }
    }

    if (step !== 3) {
      if (itemModel.Attendance === CONSTANTS.Attendance_Attended && itemModel.AttendedDate === null) {
        errorAppointmentDetail.push(
          'Appointment Attendance & Case Cancellation - You should specify date of attendance.',
        );
      }
      if (itemModel.Attendance === CONSTANTS.Attendance_NotAttended && itemModel.NonAttendedDate === null) {
        errorAppointmentDetail.push(
          'Appointment Attendance & Case Cancellation - You should specify date of non-attendance.',
        );
      }
      if (itemModel.Attendance === CONSTANTS.Attendance_Cancelled && itemModel.CancelledDate === null) {
        errorAppointmentDetail.push(
          'Appointment Attendance & Case Cancellation - You should specify date of cancellation.',
        );
      }
      if (itemModel.Attendance === CONSTANTS.Attendance_LCF && itemModel.CancelledDate === null) {
        errorAppointmentDetail.push(
          'Appointment Attendance & Case Cancellation - You should specify date of cancellation.',
        );
      }
      if (itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP && itemModel.CancelledDate === null) {
        errorAppointmentDetail.push(
          'Appointment Attendance & Case Cancellation - You should specify date of cancellation.',
        );
      }

      if (itemModel.Attendance === CONSTANTS.Attendance_Attended) {
        if (
          itemModel.ActualDateFrom &&
          !moment(itemModel.ActualDateFrom).isSameOrBefore(moment(itemModel.ActualDateTo))
        ) {
          errorAppointmentDetail.push(
            'Appointment Attendance & Case Cancellation - Appointment actual time is not valid. Finish time must be after start time.',
          );
        }
      }
      // if (
      //   api.isCaseManager() &&
      //   (itemModel.Attendance === CONSTANTS.Attendance_LCF ||
      //     itemModel.Attendance === CONSTANTS.Attendance_Cancelled) &&
      //   !itemModel.AttendanceComment
      // ) {
      //   errorAppointmentDetail.push(
      //     'Appointment Attendance & Case Cancellation - Please input Comment of Cancellation.',
      //   );
      // }
    }

    if (step !== 4) {
      if (itemModel.ReportReturnedFromDoctor && !itemModel.ReportCompletedDate) {
        errorDictations.push('Dictations & Report Information - You should specify date of corrected report returned.');
      }
      if (itemModel.IsAllowCMViewReport && itemModel.ReportCompleted) {
        if (attachmentModel.CompletedAttachment.length === 0) {
          errorDictations.push('Dictations & Report Information - You must attach reports for CMs to view.');
        }
      }
      if (itemModel.DictationReceived && itemModel.DictationReceivedDate === null) {
        errorDictations.push('Dictations & Report Information - You should specify date of dictation received date.');
      }
      if (itemModel.ReportDraftReceived && itemModel.ReportDraftReceivedDate === null) {
        errorDictations.push(
          'Dictations & Report Information - You should specify date of report draft received date.',
        );
      }
      if (itemModel.DictationSentToTypist && !itemModel.DictationTypistID) {
        errorDictations.push('Dictations & Report Information - Please select a typist.');
      }
      if (itemModel.ReportDraftReceived && itemModel.ReportDraftSentDate === null) {
        errorDictations.push('Dictations & Report Information - You should specify date of report draft sent date.');
      }
      if (
        itemModel.ReportCompleted &&
        itemModel.ReportCompletedSentDate === null &&
        api.isAdminOrMagStaffOrAccounting()
      ) {
        errorDictations.push('Dictations & Report Information - You should specify date of report completed date.');
      }

      if (
        itemModel.ReportCompleted &&
        itemModel.ReportCompletedSentDate === null &&
        api.isAdminOrMagStaffOrAccounting()
      ) {
        errorDictations.push(
          'Dictations & Report Information - You should specify date of report completed sent date.',
        );
      }
      // if (itemModel.DoctorId > 0 && itemModel.DictationReceived && attachmentModel.DictationAttachment.length === 0) {
      //   errorDictations.push('Dictations & Report Information - Please attach dictation files.');
      //   itemModel.DictationReceived = false;
      // }
      // if (
      //   !!itemModel.DoctorId &&
      //   itemModel.ReportDraftReceived &&
      //   !attachmentModel.DraftAttachment.length &&
      //   !api.isDoctor()
      // ) {
      //   errorDictations.push('Dictations & Report Information - Please attach report drafts.');
      // }
    }
    let countNumberStepError = 0;
    if (errorClaimDetails.length !== 0) {
      errors = errors.concat(errorClaimDetails);
      countNumberStepError += 1;
    }
    if (errorCaseDocuments.length !== 0) {
      errors = errors.concat(errorCaseDocuments);
      countNumberStepError += 1;
    }
    if (errorAppointmentDetail.length !== 0) {
      errors = errors.concat(errorAppointmentDetail);
      countNumberStepError += 1;
    }
    if (errorDictations.length !== 0) {
      errors = errors.concat(errorDictations);
      countNumberStepError += 1;
    }
    if (errorClientInvoice.length !== 0) {
      errors = errors.concat(errorClientInvoice);
      countNumberStepError += 1;
    }

    if (step !== undefined && step !== null && !!countNumberStepError) {
      return [];
    }
    return errors;
  };

  @action onAllowDoctorToViewPaperworkChange = () => {
    const itemModel = toJS(this.assessmentInfo);
    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      itemModel.AllowDoctorToViewPaperwork = false;
      notification.error({
        message: 'Error',
        description:
          "Paperwork can't be sent to doctor before appointment is confirmed. Please check appointment status.",
      });
      return (this.assessmentInfo = {
        ...itemModel,
        AllowDoctorToViewPaperwork: false,
      });
    }
    if (itemModel.AllowDoctorToViewPaperwork) {
      if (
        !this.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork).length &&
        !this.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_PaperworkOther).length &&
        !itemModel.PaperworkExLink
      ) {
        notification.error({
          message: 'Error',
          description: 'You need to attach LOI, paperwork or share download links before sending to Specialist.',
        });
        return (this.assessmentInfo = {
          ...itemModel,
          AllowDoctorToViewPaperwork: false,
        });
      } else {
        const momentBusinessDays = require('moment-business-days');
        var dfCompletionDate = !!itemModel.DateFrom
          ? momentBusinessDays(itemModel.DateFrom.clone()).businessAdd(7)
          : null;
        this.handleChangeValue('PaperWorkReceived', true);
        this.handleChangeValue('OtherMethodSendingDate', moment());
        this.toggleModal(true, {
          modalType: 'confirm',
          message:
            'Is Urgent Report required for this booking (less than 8 business days from appointment date? If Yes, remember to complete Urgent Report Completion information',
          onOk: () => {
            this.handleChangeValue('IsInvoiceUrgentReport', true);
            if (!itemModel.RequestExpectedCompletionDate) {
              this.handleChangeValue('RequestExpectedCompletionDate', dfCompletionDate);
            }
          },
          functionCallBack: () => {
            if (!itemModel.RequestExpectedCompletionDate) {
              this.handleChangeValue('RequestExpectedCompletionDate', dfCompletionDate);
            }
          },
        })();
        //this.assessmentInfo = { ...itemModel, OtherMethodSendingDate: moment(), PaperWorkReceived: true };
      }
    }
  };

  checkSelectOptionSend = () => {
    const itemModel = toJS(this.assessmentInfo);
    if (itemModel.PaperWorkReceived) {
      let showMessage = true;
      if (itemModel.AllowDoctorToViewPaperwork) {
        showMessage = false;
      }
      const selectedItems = itemModel.SelectedPaperworkToDoctorBies.filter(function(item) {
        return item;
      });
      if (showMessage && selectedItems.length > 0) {
        showMessage = false;
      }
      return showMessage;
    }
  };

  handleConfirmAppointment = item => {
    this.toggleModal(true, {
      modalType: 'confirm',
      message:
        'Changing confirmed status for this appointment will drop other appointment options. Are you sure to proceed?',
      onOk: () => {
        this.handleConfirmSession(item, this.BindAppointmentData);
      },
    })();
  };

  handleRemoveAppointment = item => {
    const appointments = this.assessmentInfo.Appointments;
    const newAppointments = appointments.filter(i => i.SessionId !== item.SessionId);
    this.handleChangeValue('Appointments', newAppointments);
  };

  handleResendMultiAppointmentRequest = () => {
    const itemModel = toJS(this.assessmentInfo);
    this.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want resend Multi-Appointment Request?',
      onOk: () => {
        resendMultiAppointmentRequest(itemModel.Id).then(resp => {
          if (resp.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Resend Multi-Appointment Request successfully',
            });
          }
        });
      },
    })();
  };

  @action onPaperworkReceivedChange = () => {
    let itemModel = toJS(this.assessmentInfo);
    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      notification.error({
        message: 'Error',
        description:
          "Paperwork can't be sent to doctor before appointment is confirmed. Please check appointment status.",
      });
      return (this.assessmentInfo = {
        ...itemModel,
        PaperWorkReceived: false,
        AllowDoctorToViewPaperwork: false,
      });
    }
    // let showMessage = this.checkSelectOptionSend();
    // if (!api.isAdminOrMagStaffOrAccounting()) {
    //   itemModel.AllowDoctorToViewPaperwork = true;
    //   showMessage = false;
    // }
    // if (showMessage) {
    //   notification.error({
    //     message: 'Error',
    //     description: 'Please select a request sending option first',
    //   });
    //   return (this.assessmentInfo = {
    //     ...itemModel,
    //     PaperWorkReceived: false,
    //   });
    // } else {
    // }
    if (!itemModel.IsAppointmentConfirmed && itemModel.PaperWorkReceived) {
      return this.toggleModal(true, {
        modalType: 'confirm',
        message: 'Appointment is not yet confirmed. Do you want to continue?',
        onOk: () =>
          action(() => {
            this.assessmentInfo = {
              ...itemModel,
              PaperWorkReceived: false,
              AllowDoctorToViewPaperwork: false,
              OtherMethodSendingDate: itemModel.PaperWorkReceived ? moment() : null,
              // PaperworkReceivedDate: itemModel.PaperWorkReceived ? moment() : null,
            };
          }),
      })();
    } else {
      // this.assessmentInfo = {
      //   ...itemModel,
      //   PaperworkReceivedDate: itemModel.PaperWorkReceived ? moment() : null,
      //   OtherMethodSendingDate: itemModel.PaperWorkReceived ? moment() : null,
      // }
      itemModel.OtherMethodSendingDate = itemModel.PaperWorkReceived ? moment() : null;
      // itemModel.PaperworkReceivedDate = itemModel.PaperWorkReceived ? moment() : null;
      if (!itemModel.PaperWorkReceived && !itemModel.AllowDoctorToViewPaperwork) {
        this.assessmentInfo = {
          ...itemModel,
          AllowDoctorToViewPaperwork: false,
        };
      }
    }
    // if (
    //   itemModel.PaperWorkReceived &&
    //   (!itemModel.AssessmentServiceItems ||
    //     itemModel.AssessmentServiceItems.length === 0)
    // ) {
    //   notification.error({
    //     message: 'Error',
    //     description:
    //       'Billing item codes are still missing in the Services Notes and Fees. Click close to continue.',
    //   });
    // }
  };

  showNotificationError = (errorMessage = 'Invalid Form') => {
    return notification.error({
      message: 'Error',
      description: errorMessage,
      duration: 5,
    });
  };

  showNotification = (type, errorMessage = 'Invalid Form') => {
    return notification.error({
      message: type,
      description: errorMessage,
      duration: 5,
    });
  };

  compareDate = (date1, date2, compareDateTime = false) => {
    return moment(date1, compareDateTime ? 'DD/MM/YYYY' : 'DD/MM/YY HH:mm').isSameOrBefore(
      moment(date2, compareDateTime ? 'DD/MM/YYYY' : 'DD/MM/YY HH:mm'),
      'day',
    );
  };

  validatePhone = (phone, sixValid) => {
    if (!phone) {
      return true;
    }
    const value = phone.replace(/-/g, '').replace(/ /g, '');
    if (value.match(/[^0-9]/)) {
      return false;
    }
    switch (value.length) {
      case 6:
        return sixValid;
      case 10:
        return true;
      default:
        return false;
    }
  };
  validateEmail = email => {
    const v = new Validator({ email }, { email: 'email' });
    return v.passes();
  };

  @action handleChangeStep = step => {
    const errors = this.validateForm(step);
    if (errors.length !== 0) {
      errors.map(i => this.showNotificationError(i));
      return;
    } else {
      this.currentStep = step;
    }
  };

  handleReaction = () => {
    when(
      () => !this.loading,
      () => this.calculateInvoice(),
    );
  };

  refetchInterproviderCMs = ClientId => {
    getInterProviderCMs(ClientId || 0).then(
      action(res => {
        if (!!ClientId) {
          this.interpreterProviderCMs = res.itemList;
        } else {
          this.interpreterProviderCMs = [];
        }
      }),
    );
  };

  arrayToArray = (arrayF = [], arrayS = [], key) => {
    if (
      !!arrayF.length &&
      !!arrayS.length &&
      key &&
      !!arrayF.filter(i => i[key]).length &&
      !!arrayS.filter(i => i[key]).length
    ) {
      return arrayS.filter(s => {
        const existValue = arrayF.find(f => f[key] === s[key]);
        if (existValue) {
          return existValue;
        }
        return s;
      });
    }
    return arrayS;
  };

  @action handleResetStore = () => {
    this.submitData = {
      sendEassessUrlToClaimantLocation: false,
      sendEassessUrlToDoctorLocation: false,
      notifyCmAboutAppointmentInquiry: false,
      notifyCmAboutAppointmentConfirmed: false,
      sendDaysheetToDoctor: false,
      notifyCmAboutDidNotAttendCase: false,
      notifyCmAboutAppointmentCancellation: false,
      showInquiryReceivedNextStepInfo: false,
      showAppointmentConfirmedNextStepInfo: false,
      showPaperworkReceivedNextStepInfo: false,
      copy: false,
      clientPrepaymentReceived: false,
      specialistPrepaymentPaid: false,
    };
    this.currentStep = 0;
    this.bookingClient = {};
    this.bookingCaseManager = {};
    this.subBookingSolicitor = [];
    this.waitingList = [];
    this.claimant = {};
    this.bodyParts = [];
    this.subBodyParts = [];
    this.claimReqSpecialty = [];
    this.PIC_1 = {};
    this.PIC_2 = {};
    this.PIC_3 = {};
    this.PIC_4 = {};
    this.PIC_5 = {};
    this.PIC_6 = {};
    this.PIC_7 = {};
    this.PIC_8 = {};
    this.PIC_9 = {};
    this.Report_Delivery_Contact_1 = {};
    this.Report_Delivery_Contact_2 = {};
    this.bookingSolicitor = {};
    this.claimType = {};
    this.reasonReferral = {};
    this.icareServiceType = {};
    this.reportType = {};
    this.subBookingClient = {};
    this.subBookingClientCM = {};
    this.companyInsurer = {};
    this.principleLawyer = {};
    this.payingClient = {};
    this.referenceClient = {};
    this.payingCasemanger = {};
    this.doctorSampleAttachment = [];
    this.doctorReportTemplateAttachment = [];
    this.specialist = {};
    this.iMSApprovalCode = {};
    this.clinic = {};
    this.SelectedDoctorProviderNoIdx = 1;
    this.room = [];
    //
    this.timeZone = moment.tz.guess();

    //Data belong to a specific field
    //--- Booking CM
    this.listBookingCM = null;
    this.listBookingCMCC = null;
    this.bookingCMKeyword = '';
    this.bookingCMNumItemPerPage = 30;
    this.bookingCMCompanyId = 0;
    //--- Report Receiving Contact 1
    this.listRDC_1_CM = null;
    this.listRDC_1_CMKeyword = '';
    this.listRDC_1_CMNumItemPerPage = 30;
    this.listRDC_1_CMCompanyId = 0;
    //--- Report Receiving Contact 2
    this.listRDC_2_CM = null;
    this.listRDC_2_CMKeyword = '';
    this.listRDC_2_CMNumItemPerPage = 30;
    this.listRDC_2_CMCompanyId = 0;
    //-- Booking Solicitor
    this.listBookingSolicitor = null;
    this.bookingSolicitorKeyword = '';
    this.bookingSolicitorNumItemPerPage = 30;
    this.bookingSolicitorCompanyId = 0;
    //-- Booking Sub-Solicitor
    this.listBookingSubSolicitor = null;
    this.bookingSubSolicitorKeyword = '';
    this.bookingSubSolicitorNumItemPerPage = 30;
    this.bookingSubSolicitorCompanyId = 0;
    //-- Booking Client
    this.listCompany = null;
    this.keywordCompany = '';
    this.numberCompanyPerPage = 30;
    //-- Booking Sub Client
    this.listSubCompany = null;
    this.keywordSubCompany = '';
    this.numberSubCompanyPerPage = 30;
    //-- Booking Sub Client CM
    this.bookingClientCMId = 0;
    this.listSubBookingClientCM = null;
    this.keywordSubBookingClientCM = '';
    this.numberSubBookingClientCMPerPage = 30;

    this.listCompanyInsurer = null;
    this.keywordCompanyInsurer = null;
    this.numberCompanyInsurerPerPage = 30;
    //-- Paying Client
    this.listPayingCompany = null;
    this.keywordPayingCompany = '';
    this.numberPerPagePayingCompany = 30;
    //-- Reference Client
    this.listReferenceCompany = null;
    this.keywordReferenceCompany = '';
    this.numberPerPageReferenceCompany = 30;
    //-- Paying Case Manager
    this.listPayingCaseManager = null;
    this.keywordPayingCaseManager = '';
    this.numberPerPagePayingCaseManager = 30;

    this.loading = true;
    this.loadingUpload = false;
    this.assessmentInfo = ASSESSMENT_INFO;
    this.caseStatus = ASSESSMENT_STATUS;
    this.listStaff = null;
    this.listTypist = null;
    this.listClaimType = null;
    this.listReasonReferral = null;
    this.listReasonReferral = null;
    this.listClaimant = null;
    this.listReportType = null;
    this.listConfigParents = null;
    this.listConfigChild = null;
    this.listConfigChildOrthopaedicSurgeon = [];
    this.listIMSApprovalCode = null;
    this.listWiroPrincipleLawyer = null;
    this.listActionRequired = null;
    this.conversationList = null;
    this.languageList = null;
    this.caseStatus = null;
    this.listBillingItemCode = null;
    this.listSpecialist = null;
    this.listClinic = null;
    this.listspecificLookupConfigOrderNoByType = null;
    this.listClinicForAddress = null;
    this.listClientForAddress = null;
    //initial data
    this.hourList = null;
    this.minuteList = null;
    this.assessmentMethodDisable = false;
    this.clinicConfirmed = false; //Session Availability Type
    //--- Modal
    this.open = false;
    this.modalParams = {};
    //--- Conversation
    this.conversationParam = BODY_CONVERSATIONLOG;
    this.numberStatusPerPage = 30;
    this.showingStatus = 'active';
    //All Without NCI
    this.keywordAllWithoutNCI = '';
    this.numItemPerPageAllWithoutNCI = 30;

    //Clinic List
    this.doctorIdClinicList = 0;
    this.numItemPerPageClinicList = 30;

    //Lookup Config By Types
    this.typeLookupConfig = ['spe'];

    //Patient List
    this.isSelectionListPatient = true;
    this.curPagePatient = 1;
    this.showingPatient = 'active';
    this.keywordPatient = '';
    this.numberPatientPerPage = 30;

    //Rooms
    this.clinicIdRooms = 0;

    //Specific Lookup Config Order No By Type
    this.typeLookupConfigOrderNo = 'PapToDoc';
  };

  getMomentDateTime(date, timezoneParam = null, { ignoreDST = false } = {}) {
    if (!date) {
      return null;
    }

    const timezoneValue = timezoneParam ?? this.timeZone;
    if (typeof date === 'string') {
      if (ignoreDST) {
        const defaultOffset = moment.tz(timezoneValue).utcOffset();
        return moment(date).utcOffset(defaultOffset);
      } else {
        return moment(date).tz(timezoneValue);
      }
    }
    return moment(date).tz(timezoneValue);
  }

  handleResendPaperworkReadiness = () => {
    const itemModel = toJS(this.assessmentInfo);

    customFetch('/MedicalService/ResendPaperworkReadiness', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(res => {
        if (res.status === 'success') {
          this.handleChangeValue('SentDateNotifyPaperworkReadinessToStaff', moment());
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend Paperwork Readiness',
          });
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Resend Paperwork Readiness',
        });
      });
  };

  handlSendICareCompletionRequest = (sendtype = 1) => {
    const itemModel = toJS(this.assessmentInfo);

    let msg = 'Do you want to send iCare report reminder to Specialist?';
    if (sendtype === 2) msg = 'Do you want to send iCare report reminder to Staff?';
    this.toggleModal(true, {
      modalType: 'confirm',
      message: msg,
      onOk: () => {
        sendICareCompletionRequest(itemModel.Id, sendtype).then(resp => {
          if (resp.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Sent iCare report reminder successfully!',
            });
          }
        });
      },
    })();
  };

  updateFileNameAttachDictation = () => {
    let attachments = this.assessmentInfo?.AssessmentAttachment ? toJS(this.assessmentInfo.AssessmentAttachment) : [];
    let dictationIndex = 1;
    attachments.forEach(el => {
      if (el.ReportType === LOCAL_CONSTANT.ReportType_Dictation) {
        el.Title = ReplaceFileNameUpload(
          'Assessment',
          this.assessmentInfo.AssessmentNumber,
          this.claimant?.FullName || '',
          this.specialist?.Title || '',
          this.specialist?.FullName || '',
          this.assessmentInfo.DateFrom ? moment(this.assessmentInfo.DateFrom).format('DD/MM/YYYY') : '',
          this.assessmentInfo.AssessmentMethod,
          this.clinic?.Name || '',
          dictationIndex,
        );
      }
      dictationIndex = dictationIndex + 1;
    });

    // this.toggleModal(true, {
    //   modalType: 'confirm',
    //   message:
    //     'Do you want dictation transcription and report typing to be performed by MLP? This will incur extra service fees.',
    //   onOk: () => {
    //     this.handleChangeValue('DictationOption', 0);
    //   },
    //   functionCallbackCancel: () => {
    //     this.handleChangeValue('DictationOption', 1);
    //   },
    // })();

    this.assessmentInfo = {
      ...toJS(this.assessmentInfo),
      loadingUpload: false,
      DictationOption: 0,
      AssessmentAttachment: attachments,
    };
  };

  checkExclusionReportType = value => {
    //console.log(1234);
    const itemModel = toJS(this.assessmentInfo);
    if (!!this.specialistAcceptedTypes && this.specialistAcceptedTypes.length > 0) {
      var findRT = this.specialistAcceptedTypes.find(r => r.Id === value);
      if (!!findRT) {
        notification.error({
          message: 'Error',
          description:
            'Appointment Details - This session does not accept this report type. Please check with our staff.',
          duration: 5,
        });
      }
    }
  };

  @observable isLoadingAudioTranscribe = false;
  @action getAudioTranscription = (attachmentFile, language) => {
    this.isLoadingAudioTranscribe = true;
    customFetch(`/MedicalService/GenerateAudioTranscription?fileId=${attachmentFile.Id}&language=${language}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 'success') {
          notification.destroy();

          if (!attachmentFile.TranscriptId) {
            notification.success({
              message: 'Success',
              description: 'Generate audio transcription successfully!',
            });
          }
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          // description: '',
        });
      })
      .finally(() => {
        action(() => {
          this.isLoadingAudioTranscribe = false;
        })();

        this.fetchAssessmentInfo(this.assessmentInfo?.Id);
      });
  };
}

export default new AddEditStore();
