import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import uploadStore from './UploadStore';
import { Button, Dimmer, Loader, Icon, Popup, Form } from 'semantic-ui-react';
import './../../DragDrop.scss';
import { action } from 'mobx';
import DragDropFile from './../../DragDropFile';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import * as api from '@stores/api';

function UploadCorrectedReport(props) {
  const {
    open,
    modalParams,
    submitDocumentType,
    attachmentType_CorrectedReport,
    icareLookupList,
    toggleModal,
    submitFileHandler,
  } = uploadStore;
  const { serviceType, id } = modalParams;
  const [uploadFile, setUploadFile] = useState([]);
  const [icareReportCapacityId, setIcareReportCapacityId] = useState(null);
  const [icareReportDiagnosisId, setIcareReportDiagnosisId] = useState(null);
  const [icareReportLiabilityId, setIcareReportLiabilityId] = useState(null);
  const [icareReportTreatmentId, setIcareReportTreatmentId] = useState(null);
  const [icareReportWPIId, setIcareReportWPIId] = useState(null);
  const [icareReportPsychologicalWPIId, setIcareReportPsychologicalWPIId] = useState(null);
  const [icareReportIMCId, setIcareReportIMCId] = useState(null);

  const title = () => {
    return 'Upload Completed Report - Case No: ' + modalParams.caseNo + ' - Claimant: ' + modalParams.claimant;
  };

  useEffect(() => {
    uploadStore.fetchInfoEmail(modalParams.id);
    uploadStore.getLookupIcareReport();
  }, [modalParams]);

  const onSubmitFile = action(() => {
    uploadStore.loading = true;
    if (!uploadFile || uploadFile.length <= 0) {
      return;
    }

    var uploadAttachment = [];
    uploadFile.forEach(element => {
      uploadAttachment.push({
        [`${serviceType}Id`]: id,
        FileName: element.name,
        Title: element.title,
        ReportType: element.reportType,
        Type: element.reportType,
        NumberOfPages: element.numberOfPages,
      });
    });
    const uploadData = {
      [`${serviceType}Id`]: id,
      ReportType: attachmentType_CorrectedReport[serviceType],
      [`${serviceType}Attachment`]: uploadAttachment,
      icareReportCapacityId,
      icareReportDiagnosisId,
      icareReportLiabilityId,
      icareReportTreatmentId,
      icareReportWPIId,
      icareReportPsychologicalWPIId,
      icareReportIMCId,
    };
    submitFileHandler(submitDocumentType[serviceType], uploadData, props.refetchTable);
  });

  const onUploadFile = (acceptedFiles, reportType) => {
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element, index) {
        element.reportType = reportType;
        element.title = uploadStore.ReplaceFileNameUpload(
          serviceType,
          uploadStore.emailInfo.CaseNo,
          uploadStore.emailInfo.ClaimantFullName,
          uploadStore.emailInfo.DoctorTitle,
          uploadStore.emailInfo.DoctorName,
          uploadStore.emailInfo.DateRequest,
          uploadStore.emailInfo.AssessmentMethod,
          uploadStore.emailInfo.ClinicName,
          index,
        );
      });
      const data = [...uploadFile, ...acceptedFiles].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
      setUploadFile(data);
    }
  };

  const onDeleteFile = deletedFiles => {
    if (deletedFiles != null) {
      const data = [...uploadFile].filter(x => x.name !== deletedFiles.name);
      setUploadFile(data);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={600}
      className="custom-modal"
      title={title()}
      footer={
        <React.Fragment>
          <Button
            color="blue"
            disabled={!uploadFile || uploadFile.length === 0}
            onClick={onSubmitFile}
            loading={uploadStore.loading}
          >
            <Icon name="upload" /> Upload
          </Button>
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <Dimmer active={uploadStore.loading}>
        <Loader />
      </Dimmer>
      <div style={{ marginTop: '10px' }}>
        {serviceType === 'Assessment' && (
          <Form>
            <Form.Field>
              <label>Answer the following questions if relevant to your report</label>
            </Form.Field>

            <Form.Field>
              <label>Report Outcome - Capacity</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportCapacityId}
                onChange={value => setIcareReportCapacityId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[0] &&
                  icareLookupList.itemList[0].length > 0 &&
                  icareLookupList.itemList[0].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field>
              <label>Report Outcome - Diagnosis</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportDiagnosisId}
                onChange={value => setIcareReportDiagnosisId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[1] &&
                  icareLookupList.itemList[1].length > 0 &&
                  icareLookupList.itemList[1].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field>
              <label>Report Outcome - Liability</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportLiabilityId}
                onChange={value => setIcareReportLiabilityId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[2] &&
                  icareLookupList.itemList[2].length > 0 &&
                  icareLookupList.itemList[2].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field>
              <label>Report Outcome - Treatment</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportTreatmentId}
                onChange={value => setIcareReportTreatmentId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[3] &&
                  icareLookupList.itemList[3].length > 0 &&
                  icareLookupList.itemList[3].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field>
              <label>Report Outcome - WPI %</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportWPIId}
                onChange={value => setIcareReportWPIId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[4] &&
                  icareLookupList.itemList[4].length > 0 &&
                  icareLookupList.itemList[4].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field>
              <label>
                For Psychological WPI{' '}
                <Popup
                  className="icon-msg-to"
                  trigger={<Icon name="info circle" />}
                  content="For Psychological WPI Assessments only - was the PIRS form completed and included within the report?"
                  position="top center"
                />
              </label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportPsychologicalWPIId}
                onChange={value => setIcareReportPsychologicalWPIId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[5] &&
                  icareLookupList.itemList[5].length > 0 &&
                  icareLookupList.itemList[5].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>

            <Form.Field style={{ marginBottom: '30px' }}>
              <label>IMC Outcome</label>
              <Select
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                showSearch
                optionFilterProp="children"
                value={icareReportIMCId}
                onChange={value => setIcareReportIMCId(value)}
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[6] &&
                  icareLookupList.itemList[6].length > 0 &&
                  icareLookupList.itemList[6].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Field>
          </Form>
        )}

        <DragDropFile
          multiple={true}
          disableDragDrop={false}
          onUploadFile={onUploadFile}
          onDeleteFile={onDeleteFile}
          type={attachmentType_CorrectedReport[serviceType]}
          title="Completed Report Files"
          maxSize="50"
        />
        <hr></hr>
      </div>
    </Modal>
  );
}

export default observer(UploadCorrectedReport);
