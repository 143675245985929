export const State = [
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];
