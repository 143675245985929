import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { action, toJS } from 'mobx';

import { Table, Checkbox } from 'semantic-ui-react';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import DRStore from './DRStore';

const { $ } = window;
@observer
class ClaimantsTable extends React.Component {
  state = {
    doctorList: [],
  };
  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      let arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      let i = +arr[0] + 'e' + sig + (+arr[1] + scale);
      let j = Math.round(i);
      let k = +(j + 'e-' + scale);
      return k;
    }
  }

  renderCheckedAll = () => {
    const data = DRStore.data.filter(i => i.accountEmailList);
    const doctors = DRStore.doctorIdList;
    let isCheckedAll = false;
    if (doctors.length < data.length) {
      isCheckedAll = false;
    } else {
      for (let i of data) {
        const matchedItem = doctors.indexOf(i.id);
        if (matchedItem !== -1) {
          isCheckedAll = true;
        } else {
          isCheckedAll = false;
        }
      }
    }
    var amount = 0;
    if (doctors && doctors.length > 0) {
      const data = toJS(DRStore.data);
      doctors.forEach(sId => {
        var dSeleted = data.filter(i => i.id === sId)[0];
        if (dSeleted) {
          amount += dSeleted.commissionAmountWithTax;
        }
      });
      amount = parseFloat(this.roundNumber(amount, 2));
    }
    DRStore.changeTotalRemittanceAmout(amount);
    return isCheckedAll;
  };
  handleCheckedAll = () => {
    let doctors = DRStore.doctorIdList;
    const data = DRStore.data;
    const isCheckedAll = this.renderCheckedAll();
    if (!isCheckedAll) {
      for (let item of data) {
        if (doctors.indexOf(item.id) === -1 && item.accountEmailList) {
          action(() => doctors.push(item.id))();
        }
      }
      return DRStore.setFieldsValue({ doctorIdList: doctors });
    } else {
      return DRStore.setFieldsValue({ doctorIdList: [] });
    }
  };
  renderChecked = id => {
    let doctors = DRStore.doctorIdList;
    if (doctors.indexOf(id) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  handleSelectDoctor = id => {
    let doctors = DRStore.doctorIdList;
    if (doctors.indexOf(id) === -1) {
      action(() => doctors.push(id))();
    } else {
      action(() => (doctors = doctors.filter(i => i !== id)))();
    }
    DRStore.setFieldsValue({ doctorIdList: doctors });
  };
  handleViewOrExportTaxInvoice = (id, type) => {
    const { monthPeriod, yearPeriod, includeXeroUploadedInvoiceOnly } = DRStore;
    const { fromDate, toDate } = DRStore.getDateRange();
    const from_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const to_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const from = encodeURI(from_);
    const to = encodeURI(to_);

    const params = {
      doctorId: id,
      dateTypeToFilter: 'InvoiceDate',
      invoiceDateFrom: from,
      invoiceDateTo: to,
      paymentPeriod: monthPeriod + ' ' + yearPeriod,
      includeXeroUploadedInvoiceOnly,
    };
    if (type === `view`) {
      window.open(`/Report/ExportDoctorCommissionRemittance?${$.param(params)}`, '_blank');
    } else if (type === `export`) {
      window.open(`/Report/ExportDoctorCommissionRemittanceToExcel?${$.param(params)}`, '_blank');
    }
  };

  handleViewRemittance = id => {
    DRStore.setFieldsValue({ doctorIdList: [id] });
    DRStore.setFieldsValue({ isView: true });
    return DRStore.toggleModal(true, { modalType: 'paymentDate' })();
  };

  formatTax = value => {
    const notNullableValue = value || 0;
    return `$${notNullableValue.toFixed(2)}`;
  };

  breakLineEmailList = emailList => {
    const emails = emailList.split(', ');
    return emails.map((email, index) => (
      <div key={index}>
        <span>{email}</span>
        {index < email.length - 1 && <br />}
      </div>
    ));
  };

  openModal = options => {
    return DRStore.toggleModal(true, options);
  };

  renderTableBody = data => {
    return data.map(({ id, ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>{record.firstName}</Table.Cell>
        <Table.Cell>{record.lastName}</Table.Cell>
        <Table.Cell
          className={cx(`${record.actionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              id: id,
              modalType: 'notes',
              modalTitle: `${record.firstName + ' ' + record.lastName}`,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.actionRequiredType === 2 ? '' : 'text-success'}`}>
                {record.actionRequiredType === 0 ? (
                  '(Add Action)'
                ) : record.actionRequiredType === 1 ? (
                  `(View Actions)`
                ) : (
                  <span>
                    <span className="text-danger">(Action Required)</span>
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.87)', fontWeight: 'normal' }}>{record.actionRequiredText}</span>
                  </span>
                )}
              </span>
            )}
          </Link>
        </Table.Cell>
        <Table.Cell>{this.breakLineEmailList(record.accountEmailList)}</Table.Cell>
        <Table.Cell>{this.formatTax(record.commissionAmountWithTax)}</Table.Cell>
        <Table.Cell>{this.formatTax(record.commissionAmountTax)}</Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={() => this.handleViewRemittance(id)}>
            View
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={() => this.handleViewOrExportTaxInvoice(id, `export`)}>
            Export
          </Link>
        </Table.Cell>
        <Table.Cell>
          <div>
            <Checkbox
              onChange={() => this.handleSelectDoctor(id)}
              checked={this.renderChecked(id)}
              disabled={!record.accountEmailList}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, data } = DRStore;
    const columns = [
      { title: 'Id' },
      { title: 'First Name' },
      { title: 'Last Name' },
      { title: 'Action Required' },
      { title: 'Accounting Emails' },
      { title: 'Total Payment Amt (inc GST)' },
      { title: 'Total GST' },
      { title: 'View' },
      { title: 'Export' },
      {
        title: <Checkbox onChange={this.handleCheckedAll} checked={this.renderCheckedAll()} label="Payment Checked" />,
      },
    ];
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={DRStore.sortColumn}
          sortDirection={DRStore.sortDirection}
          onSort={DRStore.handleSort}
        />
        <Table.Body>
          {data.length !== 0 ? this.renderTableBody(data) : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
      </Table>
    );
  }
}

export default ClaimantsTable;
