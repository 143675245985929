import { observable, action, computed, toJS, autorun, reaction } from 'mobx';
import moment from 'moment';
import { uniqBy } from 'lodash';
import Validator from 'validatorjs';
import { notification } from 'antd';

import {
  getDataClinicalNote,
  getClient,
  getCaseManagerOnboarded,
  getClaimant,
  getTypeOfClaim,
  getIMSCode,
  getWiroPrincipleLawyer,
  getStaff,
  getCRRetriever,
  getClinic,
  getActionRequired,
  getCaseCorrespondence,
  getAssignHistory,
  getClinicalNoteRequest,
  saveCRNote,
  getInvoice,
  updateClinicalRecordStatus,
  getClinicalRecordLogs,
  getCompanyRecentChangeHistory,
  resendPaperworkReadiness,
  getUserUploadInfo,
} from './service';
import { INIT_CLINICAL_NOTE, FIELD_NAME_FOR_ADD_EDIT } from './type';

import * as api from '@stores/api';
import { formatDate } from '@utils/functions';
import customFetch from '@utils/customFetch';
import dashboardStore from '@stores/dashboard';
import { SERVER_TIME_ZONE } from '@utils/constants';

const FIELD_NAME_FOR_NOTIFICATION = {
  EmailCNDocReq1: 'Email for sending CR Request',
  EmailCNDocReq2: 'Email 2 for sending CR request',
  // PaperworkReceivedDate: 'Paperwork received date',
  // RequestExpectedCompletionDate: 'Requested completion date',
  RequestSentToGpDate: 'Paperwork sent date',
  PaymentRequestedDate: 'Payment requested date',
  ClinicalNotesSentDate: 'Clinical notes sent date',
  PrepaymentRequiredDate: 'Prepayment required date',
  InvoicePaidDate: 'Payment Date',
};

class ClinicalNoteAddEditStore {
  @observable loading = true;
  @observable clinicalNoteInfo = INIT_CLINICAL_NOTE;
  @observable invalidForm = [];
  @observable ClinicalNotesReceivedAttachmentUploadNotSaved = false;
  @observable oldPaperwordReceived = false;
  @observable LOATemplate = {
    IsDisplay: '',
    FilePath: '/UserFile/ClinicAttachment/',
    FileTitle: '',
  };
  @observable isReady = false;
  @observable listInvoice = [];

  @observable bookingClients = [];
  @observable bookingCMs = [];
  @observable deliveryContactFirst = [];
  @observable deliveryContactsSecond = [];
  @observable subBookingCMs = [];
  @observable payingClients = [];
  @observable payingCMs = [];
  @observable claimants = [];
  @observable claims = [];
  @observable imsCodes = [];
  @observable wiroPrincipleLawyers = [];
  @observable staffs = [];
  @observable crProviderFirst = [];
  @observable crProviderSecond = [];
  @observable actionRequiredList = [];
  @observable caseCorrespondences = [];
  @observable loadingConversation = false;
  @observable assignHistory = [];
  @observable assignCompanyHistory = [];
  @observable utcServerTime = {};
  @observable clinicalNoteRequest = [];
  @observable clinicalLogs = null;

  @observable isOpenModalDisbursementFunding = false;
  @observable modalDisbursementFundingParams = {};

  //------Search Options------
  @observable bookingClientsSearchOpt = {
    keyword: '',
    numberItem: 30,
  };
  @observable bookingCMsSearchOpt = {
    keyword: '',
    numberItem: 30,
    companyId: 0,
  };
  @observable deliveryContactsFirstSearchOpt = {
    keyword: '',
    numberItem: 30,
    companyId: 0,
  };
  @observable deliveryContactsSecondSearchOpt = {
    keyword: '',
    numberItem: 30,
  };
  @observable subBookingCMsSearchOpt = {
    keyword: '',
    numberItem: 30,
  };
  @observable payingClientsSearchOpt = {
    keyword: '',
    numberItem: 30,
  };
  @observable payingCMsSearchOpt = {
    keyword: '',
    numberItem: 30,
    companyId: 0,
  };
  @observable claimantsSearchOpt = {
    keyword: '',
    numberItem: 30,
  };
  @observable crProviderFirstSearchOpt = {
    keyword: '',
    numberItem: 30,
    doctorId: 0,
  };
  @observable crProviderSecondSearchOpt = {
    keyword: '',
    numberItem: 30,
  };

  @computed get bookingClientSelected() {
    const { CustomerId, Customer } = this.clinicalNoteInfo;
    const customers = [Customer || {}, ...this.bookingClients];
    return customers.find(i => i.Id === CustomerId);
  }

  @computed get bookingCMSelected() {
    const { CaseManagerId, CaseManager } = this.clinicalNoteInfo;
    const cms = [CaseManager || {}, ...this.bookingCMs];
    return cms.find(i => i.Id === CaseManagerId);
  }

  @computed get deliveryContactFirstSelected() {
    const { CaseManager, F_RDCCaseManagerId } = this.clinicalNoteInfo;
    const cms = [this.bookingCMSelected || {}, CaseManager || {}, ...this.deliveryContactFirst];
    return cms.find(i => i.Id === F_RDCCaseManagerId);
  }

  @computed get deliveryContactSecondSelected() {
    const { S_RDCCaseManagerId } = this.clinicalNoteInfo;
    return (api.isAdminOrMagStaffOrAccounting()
      ? this.deliveryContactsSecond
      : this.itemToArray(this.bookingCMSelected, this.bookingCMs, 'Id')
    ).find(i => i.Id === S_RDCCaseManagerId);
  }

  @computed get payingClientSelected() {
    const { PCustomerId, PCustomer } = this.clinicalNoteInfo;
    return [PCustomer || {}, ...this.payingClients].find(i => i.Id === PCustomerId);
  }

  @computed get payingCMSelected() {
    const { PCaseManagerId, PCaseManager } = this.clinicalNoteInfo;
    return [PCaseManager || {}, ...this.payingCMs].find(i => i.Id === PCaseManagerId);
  }

  @computed get claimantSelected() {
    const { PatientId, Patient } = this.clinicalNoteInfo;
    return [Patient || {}, ...this.claimants].find(i => i.Id === PatientId);
  }
  @computed get crProviderSecondSelected() {
    const { DoctorId, Doctor } = this.clinicalNoteInfo;
    return [Doctor || {}, ...this.crProviderSecond].find(i => i.Id === DoctorId);
  }

  @computed get crProviderFirstSelected() {
    const { ClinicId, Clinic } = this.clinicalNoteInfo;
    return [Clinic || {}, ...this.crProviderFirst].find(i => i.ID === ClinicId);
  }

  @observable isOpenModalConfirm = false;
  @observable modalParams = {};
  @observable isOpenModalHistory = false;
  @observable isOpenModalCompanyHistory = false;
  @observable open = false;
  @observable isOpenModalAction = false;
  @observable actionRequiredList = [];
  @observable modalUploadParams = {};
  @observable isOpenModalUpload = false;

  @action toggleModalModalUpload = (isOpenModalUpload, modalUploadParams = {}) => {
    this.isOpenModalUpload = isOpenModalUpload;
    this.modalUploadParams = modalUploadParams;
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.isOpenModalConfirm = isOpen;
    this.modalParams = modalParams;
  };

  @action toggleModalAction = isOpen => {
    this.isOpenModalAction = isOpen;
  };

  @action setFieldsValue = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleChangeCRValue = (data = {}) => {
    Object.keys(data).forEach(key => {
      this.clinicalNoteInfo[key] = data[key];
    });
  };

  handleOpenAddEdit = (type = '', fieldName = '') => {
    const result = FIELD_NAME_FOR_ADD_EDIT.find(i => i.fieldName === fieldName);
    if (result) {
      const { pathname, computedName, storageName } = result;
      dashboardStore.close(pathname);
      if (type === 'add') {
        localStorage.removeItem(storageName);
        if (fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
          const companyId =
            fieldName === 'CaseManagerId'
              ? this.bookingClientSelected?.Id
              : fieldName === 'PCaseManagerId'
              ? this.payingClientSelected?.Id
              : 0;
          setTimeout(() =>
            dashboardStore.open(`${pathname}?companyId=${companyId || 0}&pageGetter=addEditCR&fieldName=${fieldName}`),
          );
        } else {
          setTimeout(() =>
            dashboardStore.open(`${pathname}?action=edit?id=null&pageGetter=addEditCR&fieldName=${fieldName}`),
          );
        }
      }
      if (type === 'edit') {
        const value = this[computedName];
        if (value) {
          localStorage.setItem(storageName, value.FullName || value.Name);
          if (fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
            const companyId =
              fieldName === 'CaseManagerId'
                ? this.bookingClientSelected?.Id
                : fieldName === 'PCaseManagerId'
                ? this.payingClientSelected?.Id
                : 0;
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?companyId=${companyId || 0}&id=${this[computedName]?.Id ||
                  0}&pageGetter=addEditCR&fieldName=${fieldName}`,
              ),
            );
          } else {
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?action=edit&id=${this[computedName]?.Id ||
                  this[computedName]?.ID ||
                  0}&pageGetter=addEditCR&fieldName=${fieldName}`,
              ),
            );
          }
        } else {
          localStorage.removeItem(storageName);
          if (fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
            const companyId =
              fieldName === 'CaseManagerId'
                ? this.bookingClientSelected?.Id
                : fieldName === 'PCaseManagerId'
                ? this.payingClientSelected?.Id
                : 0;
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?companyId=${companyId || 0}&pageGetter=addEditCR&fieldName=${fieldName}`,
              ),
            );
          } else {
            setTimeout(() =>
              dashboardStore.open(`${pathname}?action=edit?id=0&pageGetter=addEditCR&fieldName=${fieldName}`),
            );
          }
        }
      }
      return;
    }
    return;
  };

  renderCheckboxSessionStyle = (isActive = false) => {
    const checkboxStyle = {
      fontWeight: 'bold',
      padding: '5px',
      color: 'white',
      width: '350px',
    };
    return isActive
      ? {
          ...checkboxStyle,
          backgroundColor: '#2cbf2c',
        }
      : {
          ...checkboxStyle,
          backgroundColor: 'gray',
        };
  };

  getMessageError = (key, type = 'field') => {
    const messageObj = this.invalidForm.find(i => i.key === key);
    if (messageObj && messageObj != {}) {
      const { message } = messageObj;
      const [title, messageError] = message.split(' - ');
      if (type === 'notification') return title + ' - ' + messageError;
      else {
        if (message.indexOf('is required') !== -1) {
          return 'This field is required';
        }
        return messageError;
      }
    }
    return;
  };

  showNotification = (message = '', description, typeNotification = 'error') => {
    return notification[typeNotification]({
      message: message,
      description: description,
      duration: 3,
    });
  };

  messageError = (type, field) => {
    if (type === 'email') {
      return ` ${FIELD_NAME_FOR_NOTIFICATION[field]} - Please input valid email`;
    }
    if (type === 'url') {
      return `${FIELD_NAME_FOR_NOTIFICATION[field]} - Please enter a valid url starts with http:// | https://`;
    }
    if (type === 'required') {
      return `${FIELD_NAME_FOR_NOTIFICATION[field]} is required`;
    }
  };

  @action removeError = key => {
    const invalidForm_ = this.invalidForm.filter(i => i.key !== key);
    return (this.invalidForm = invalidForm_);
  };

  @action validateField = (fieldName, value, type) => {
    const v = new Validator({ value }, { value: type });
    if (v.passes()) {
      const invalidForm = this.invalidForm.filter(i => i.key !== fieldName);
      return (this.invalidForm = invalidForm);
    } else {
      const invalidForm = this.invalidForm.filter(i => i.key === fieldName);
      if (!!invalidForm.length) {
        return;
      }
      return (this.invalidForm = [
        ...this.invalidForm,
        { key: fieldName, message: this.messageError(type, fieldName) },
      ]);
    }
  };

  handleChangeDatePicker = fieldName =>
    action(event => {
      const hour = moment().hour();
      const minute = moment().minute();
      const value = event ? event.hours(hour).minutes(minute) : null;

      return (this.clinicalNoteInfo[fieldName] = value);
    });
  handleChangeRangePicker = (fieldNameFrom, fieldNameTo) =>
    action(event => {
      const value = event;
      const [from, to] = value;
      this.clinicalNoteInfo[fieldNameFrom] = from;
      this.clinicalNoteInfo[fieldNameTo] = to;
      return;
    });

  @action handleCheckNCIPeriod = specialistId => {
    const specialist = this.crProviderSecond.find(i => i.Id === specialistId) ?? {};
    const { IsNotCurrentlyInterested, NotCurrentlyInterestedDate, NotCurrentlyInterestedEndDate } = specialist;
    if (!!IsNotCurrentlyInterested) {
      if (
        moment().isSameOrAfter(moment(NotCurrentlyInterestedDate), 'date') &&
        moment().isSameOrBefore(moment(NotCurrentlyInterestedEndDate), 'date')
      ) {
        setTimeout(() => {
          this.toggleModalConfirm(true, {
            modalType: 'confirm',
            message: 'This specialist indicates NCI during this period, do you want to continue with this booking?',
            onOk: action(() => {
              this.clinicalNoteInfo.DoctorId = specialistId;
            }),
          });
        }, 500);
      } else {
        this.clinicalNoteInfo.DoctorId = specialistId;
      }
    } else {
      this.clinicalNoteInfo.DoctorId = specialistId;
    }
  };

  handleChangeFieldSelect = fieldName =>
    action(event => {
      const value = event;
      if (fieldName === 'DoctorId') {
        return this.handleCheckNCIPeriod(value);
      }
      if (fieldName === 'CustomerId' && this.clinicalNoteInfo.Id > 0) {
        this.toggleModalConfirm(true, {
          message: 'Changing Booking Client can affect billing information later. Do you want to proceed?',
          onOk: action(() => {
            this.clinicalNoteInfo[fieldName] = value;
          }),
        });
        return;
      }
      if (fieldName) {
        return (this.clinicalNoteInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldInput = fieldName =>
    action(event => {
      const value = event.target.value;
      if (fieldName) {
        return (this.clinicalNoteInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldInputNumber = fieldName =>
    action(event => {
      const value = !!event ? event : 0;
      return (this.clinicalNoteInfo[fieldName] = value);
    });

  onCancellationStatusChanged = function(value) {
    if (!value) {
      this.handleChangeCRValue({
        CancelledDate: null,
        CancelledComments: null,
        CancellationStatus: value,
      });
    } else {
      this.handleChangeCRValue({
        CancelledDate: moment(),
        ReasonCancellation: 1,
      });
      if (value === 3) {
        this.handleChangeCRValue({
          NotifyCancellationToCM: false,
          NotifyOnHoldToCM: true,
          CancellationStatus: value,
        });
      } else {
        this.handleChangeCRValue({
          NotifyCancellationToCM: true,
          NotifyOnHoldToCM: false,
        });
      }

      if (value === 1) {
        if (!!this.clinicalNoteInfo.CaseStatus) {
          this.toggleModalConfirm(true, {
            message:
              'Cancelling request after it has been sent to provider will incur a fee of $35 even the provider has no record of the patient. Are you sure to select this option?',
            onOk: () =>
              this.handleChangeCRValue({
                CancellationStatus: value,
                CancelledDate: moment(),
              }),
          });
        } else {
          this.handleChangeCRValue({
            CancellationStatus: value,
          });
        }
      } else if (value === 2) {
        if (!this.clinicalNoteInfo.CaseStatus) {
          this.toggleModalConfirm(true, {
            message:
              'Cancellation fee only applies after the request has been sent to provider. Are you sure to select this option?',
            onOk: () =>
              this.handleChangeCRValue({
                CancellationStatus: value,
                CancelledDate: moment(),
              }),
          });
        } else {
          this.handleChangeCRValue({
            CancellationStatus: value,
          });
        }
      }
    }
  };

  handleChangeFieldRadio = fieldName =>
    action(event => {
      const value = event.target.value;
      if (fieldName) {
        if (fieldName === 'CancellationStatus') {
          var findARIncompleted = this.actionRequiredList ? this.actionRequiredList.filter(i => !i.Completed) : 0;
          if (!api.isCaseManager() && findARIncompleted && findARIncompleted.length > 0) {
            notification.destroy();
            notification.info({
              message: 'Action Required Reminder',
              description: 'Please check and clear pending required actions as needed.',
              duration: 15,
            });
          }
          return this.onCancellationStatusChanged(value);
        }
        return (this.clinicalNoteInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldCheckbox = fieldName =>
    action(event => {
      const value = event.target.checked;
      if (fieldName) {
        if (fieldName === 'IROFundingApproved') {
          if (value) {
            return (this.clinicalNoteInfo = {
              ...this.clinicalNoteInfo,
              IROFundingApprovalDate: moment(),
              [fieldName]: value,
            });
          } else {
            return (this.clinicalNoteInfo = {
              ...this.clinicalNoteInfo,
              [fieldName]: value,
            });
          }
        } else {
          return (this.clinicalNoteInfo[fieldName] = value);
        }
      }
      return;
    });

  handleChangeFieldCheckboxGroup = fieldName =>
    action(event => {
      const value = event;
      if (fieldName) {
        this.clinicalNoteInfo[fieldName] = value;
      }
      return;
    });

  renderDate = (dateStr, forDatePicker = true, isShowTime = false) => {
    const format = isShowTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY';
    const formatForFormatDate = isShowTime ? 'DD MMM YYYY, HH:mm' : 'DD MMM, YYYY';
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.startsWith('/Date')) {
          const date_ = formatDate(dateStr, isShowTime);
          return date_
            ? forDatePicker
              ? moment(date_, formatForFormatDate)
              : moment(date_, formatForFormatDate).format(format)
            : null;
        } else {
          return forDatePicker ? moment(dateStr) : moment(dateStr).format(format);
        }
      }
      return forDatePicker ? dateStr : dateStr.format(format);
    }
    return forDatePicker ? null : '';
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      const arr_ = arr.filter(i => i[key] !== item[key]);
      const item_ = toJS(item);
      return [item_, ...arr_];
    } else if (key && item && item[key] && !arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  arrayToArray = (arrayF = [], arrayS = [], key) => {
    if (
      !!arrayF.length &&
      !!arrayS.length &&
      key &&
      !!arrayF.filter(i => i[key]).length &&
      !!arrayS.filter(i => i[key]).length
    ) {
      return uniqBy([...arrayF, ...arrayS], key);
    }
    return arrayS;
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  formatReqDate = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date)
        .tz(SERVER_TIME_ZONE)
        .format('MM/DD/YYYY HH:mm:ss');
    }
    return date.tz(SERVER_TIME_ZONE).format('MM/DD/YYYY HH:mm:ss');
  };

  formatReqDateISOS = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date).format();
    }
    return date.format();
  };

  @action fetchConversations = (searchKey = '') => {
    this.loadingConversation = true;
    getCaseCorrespondence(this.clinicalNoteInfo.Id)
      .then(
        action(res => {
          if (searchKey !== '' && res?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            res.itemList = res.itemList.filter(t => t.Content.toLowerCase().indexOf(searchKey) > -1);
          }
          this.caseCorrespondences = res.itemList;
          this.loadingConversation = false;
        }),
      )
      .catch(() => {
        this.loadingConversation = false;
      });
  };

  handleRefetchActionNote = () => {
    getActionRequired(this.clinicalNoteInfo.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };

  handleSearch = searchOptName => event => {
    this.handleChangeSearchOpt({ keyword: event }, searchOptName);
  };

  renderStaffName = id => {
    const rs = this.staffs.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };

  @action handleChangeSearchOpt = (option = {}, searchOptionName = '') => {
    if (
      option == {} ||
      !searchOptionName ||
      typeof option !== 'object' ||
      typeof searchOptionName !== 'string' ||
      !this[searchOptionName]
    ) {
      return;
    } else {
      Object.keys(option).forEach(key => {
        this[searchOptionName][key] = option[key];
      });
    }
  };

  formatResDate = date => {
    if (!date || typeof date !== 'string') {
      return null;
    }
    return moment(formatDate(date, true), 'DD MMM YYYY HH:mm').tz(moment.tz.guess());
  };

  @action openModalHistory = () => {
    getAssignHistory({
      id: this.clinicalNoteInfo.Id,
      actionName: 'AddEditClinicalNote',
    }).then(
      action(res => {
        this.assignHistory = res.itemList;
        this.isOpenModalHistory = true;
      }),
    );
  };

  @action openModalCompanyHistory = () => {
    getCompanyRecentChangeHistory(this.clinicalNoteInfo.Id, 'cr').then(
      action(res => {
        this.assignCompanyHistory = res.itemList;
        this.isOpenModalCompanyHistory = true;
      }),
    );
  };

  handleScroll = searchOptName => event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
      const { numberItem } = this[searchOptName];
      this.handleChangeSearchOpt({ numberItem: numberItem + 20 }, searchOptName);
    }
  };

  renderAttachment = (attachments, reportType) => {
    return attachments ? attachments.filter(attachment => attachment.ReportType === reportType) : [];
  };

  renderAttachments = (attachments, reportTypes) => {
    return attachments ? attachments.filter(attachment => reportTypes.includes(attachment.ReportType)) : [];
  };

  RequestSentToGpDateSelectDateChange = () => {
    // if (this.clinicalNoteInfo.IsPaperworkReceived) {
    //   this.validateField('RequestSentToGpDate', this.clinicalNoteInfo.RequestSentToGpDate, 'required');
    // } else {
    //   this.removeError('RequestSentToGpDate');
    // }

    if (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) {
      if (!!this.clinicalNoteInfo.RequestSentToGpDate) {
        this.handleChangeCRValue({
          ExpectedCompletionDate: moment(this.clinicalNoteInfo.RequestSentToGpDate).add(7, 'days'),
          // RequestExpectedCompletionDate: moment(this.clinicalNoteInfo.RequestSentToGpDate).add(7, 'days'),
        });
      }
    }
  };

  @action handleRemoveFile = key => {
    const attachments = this.clinicalNoteInfo.Attachments;
    const newAttachments = attachments.filter(i => i.key !== key);
    this.clinicalNoteInfo = {
      ...this.clinicalNoteInfo,
      Attachments: newAttachments,
    };
  };

  checkStaffIsExist = id => {
    const rs = this.staffs.find(i => i.Id === id);
    return rs ? rs.FullName : null;
  };

  @action handleFetchClinicalNoteData = async (id = 0) => {
    this.loading = true;
    try {
      const clinicalNoteInfo = await getDataClinicalNote(id);
      const { model } = clinicalNoteInfo;
      if (model) {
        this.setFieldsValue({
          ClinicalNotesReceivedAttachmentUploadNotSaved: false,
          clinicalNoteInfo: {
            ...model,
            PatientId: model.Patient?.Id || null,
            SubBookingCM: [model.SubCaseManager1, model.SubCaseManager3, model.SubCaseManager2].filter(i => i),
            Attachments: model.Attachments ? model.Attachments.map((i, idx) => ({ ...i, key: idx })) : [],
            CreatedDate: !!id ? model.CreatedDate : moment(),
            IsActionRequired: true,
            ClinicInfo: model.Clinic,
            CreatedDate: this.formatResDate(model.CreatedDate),
            RequestSendToGpMethods: (model.RequestSendToGpMethods ?? []).map(r => r.Id),
            ActionRequiredDueDate: this.formatResDate(model.ActionRequiredDueDate),
            CancelledDate: this.formatResDate(model.CancelledDate),
            EmailInvoiceToMAGDateSent: formatDate(model.EmailInvoiceToMAGDateSent),
            EmailToCMsViewReportRecent: this.formatResDate(model.EmailToCMsViewReportRecent),
            ExpectedCompletionDate: this.formatResDate(model.ExpectedCompletionDate),
            InjuriedDate: this.formatResDate(model.InjuriedDate),
            InquiryDate: this.formatResDate(model.InquiryDate),
            InvoicePaidDate: this.formatResDate(model.InvoicePaidDate),
            PaperworkReceivedDate: this.formatResDate(model.PaperworkReceivedDate),
            PaymentRequestedDate: this.formatResDate(model.PaymentRequestedDate),
            PrepaymentRequiredDate: this.formatResDate(model.PrepaymentRequiredDate),
            RequestExpectedCompletionDate: this.formatResDate(model.RequestExpectedCompletionDate),
            RequestReceivedDate: this.formatResDate(model.RequestReceivedDate),
            RequestSentToGpDate: this.formatResDate(model.RequestSentToGpDate),
            RequestedCRRangeFrom: this.formatResDate(model.RequestedCRRangeFrom),
            RequestedCRRangeTo: this.formatResDate(model.RequestedCRRangeTo),
            SendPaymentToProviderDate: this.formatResDate(model.SendPaymentToProviderDate),
            SentDateLoAServiceProvider: this.formatResDate(model.SentDateLoAServiceProvider),
            SentDateNotifyBookingCMCancellation: this.formatResDate(model.SentDateNotifyBookingCMCancellation),
            SentDateNotifyDoctorCancellation: this.formatResDate(model.SentDateNotifyDoctorCancellation),
            StatusDate: this.formatResDate(model.StatusDate),
            SendCompletionUpdateToCMDate: this.formatResDate(model.SendCompletionUpdateToCMDate),
            IROFundingApprovalDate: this.formatResDate(model.IROFundingApprovalDate),
            IROFundingApprovedDate: this.formatResDate(model.IROFundingApprovedDate),
          },
        });
        if (
          model.CaseStatus === 13 ||
          model.CaseStatus === 14 ||
          model.CaseStatus === 4 ||
          model.CaseStatus === 8 ||
          model.CaseStatus === 7
        ) {
          this.setFieldsValue({ oldPaperwordReceived: true });
        } else if (model.IsPaperworkReceived != null) {
          this.setFieldsValue({
            oldPaperwordReceived: model.IsPaperworkReceived,
          });
        }
      } else {
        return;
      }
      if (!!id) {
        this.handleChangeSearchOpt(
          {
            companyId: model.CustomerId,
            keyword: model.CaseManagerId,
          },
          'bookingCMsSearchOpt',
        );
        this.handleChangeSearchOpt(
          {
            companyId: model.CustomerId,
            keyword: model.F_RDCCaseManagerId,
          },
          'deliveryContactsFirstSearchOpt',
        );
        this.handleChangeSearchOpt(
          {
            keyword: model.S_RDCCaseManagerId,
          },
          'deliveryContactsSecondSearchOpt',
        );
        this.handleChangeSearchOpt(
          {
            companyId: model.PCustomerId,
            keyword: model.PCaseManagerId,
          },
          'payingCMsSearchOpt',
        );
        this.handleChangeSearchOpt({ keyword: model.Patient?.Id || null }, 'claimantsSearchOpt');
        this.handleChangeSearchOpt({ keyword: model.ClinicId }, 'crProviderFirstSearchOpt');
        this.handleChangeSearchOpt({ keyword: model.DoctorId }, 'crProviderSecondSearchOpt');
        Promise.all([
          getClient({
            keyword: model.CustomerId,
          }),
          getCaseManagerOnboarded({
            companyId: model.CustomerId,
            keyword: model.CaseManagerId,
          }),
          getCaseManagerOnboarded({
            keyword: model.F_RDCCaseManagerId,
            companyId: model.CustomerId,
          }),
          getCaseManagerOnboarded({
            keyword: model.S_RDCCaseManagerId,
          }),
          getCaseManagerOnboarded({}), //Sub booking CMs
          getClient({
            keyword: model.PCustomerId,
          }),
          getCaseManagerOnboarded({
            companyId: model.PCustomerId,
            keyword: model.PCaseManagerId,
          }),
          getClaimant({
            keyword: model.Patient?.Id || '',
          }),
          getTypeOfClaim(),
          getIMSCode(),
          getWiroPrincipleLawyer(),
          getStaff(),
          getClinic({
            keyword: model.ClinicId,
          }),
          getCRRetriever({
            keyword: model.DoctorId,
          }),
          getActionRequired(id),
          getCaseCorrespondence(id),
          getClinicalNoteRequest(),
          getInvoice(id),
          getClinicalRecordLogs(id),
        ]).then(
          ([
            bookingClients,
            bookingCMs,
            deliveryContactFirst,
            deliveryContactsSecond,
            subBookingCMs,
            payingClients,
            payingCMs,
            claimants,
            claims,
            imsCodes,
            wiroPrincipleLawyers,
            staffs,
            crProviderFirst,
            crProviderSecond,
            actionRequiredList,
            caseCorrespondences,
            clinicalNoteRequest,
            listInvoice,
            clinicalLogs,
          ]) => {
            this.setFieldsValue({
              deliveryContactFirst: deliveryContactFirst.itemList || [],
              bookingClients: bookingClients.itemList || [],
              bookingCMs: model.CustomerId ? bookingCMs.itemList || [] : [],
              deliveryContactsSecond: deliveryContactsSecond.itemList || [],
              subBookingCMs: subBookingCMs.itemList || [],
              payingClients: payingClients.itemList || [],
              payingCMs: model.PCustomerId ? payingCMs.itemList || [] : [],
              claimants: claimants.itemList || [],
              claims: claims.itemList || [],
              imsCodes: imsCodes.itemList || [],
              wiroPrincipleLawyers: wiroPrincipleLawyers.itemList || [],
              staffs: staffs.itemList || [],
              crProviderFirst: crProviderFirst.itemList || [],
              crProviderSecond: crProviderSecond.itemList || [],
              actionRequiredList: actionRequiredList.itemList || [],
              caseCorrespondences: caseCorrespondences.itemList || [],
              clinicalNoteRequest: clinicalNoteRequest || [],
              listInvoice: listInvoice.itemList || [],
              utcServerTime: caseCorrespondences.utcServerTime,
              loading: false,
              clinicalLogs: clinicalLogs || [],
            });
            const assignStaff = staffs.itemList.find(staff => staff.Id === model.AssignedStaffId);
            if (!assignStaff) {
              this.handleChangeCRValue({ AssignedStaffId: model.CaseManager?.AssignedStaffId });
            }
            if (!assignStaff && api.isAdminOrMagStaffOrAccounting()) {
              this.handleChangeCRValue({ AssignedStaffId: api.currentUser.data.id });
            }
            this.handleChangeSearchOpt({ companyId: model.CustomerId }, 'bookingCMsSearchOpt');
            this.handleChangeSearchOpt({ companyId: model.CustomerId }, 'deliveryContactsFirstSearchOpt');
            this.handleChangeSearchOpt({ companyId: model.PCustomerId }, 'payingCMsSearchOpt');
          },
        );
      } else {
        if (api.isAdminOrMagStaffOrAccounting()) {
          Promise.all([
            getClient({}),
            getCaseManagerOnboarded({}),
            getCaseManagerOnboarded({}),
            getClient({}),
            getClaimant({}),
            getTypeOfClaim(),
            getIMSCode(),
            getWiroPrincipleLawyer(),
            getStaff(),
            getClinic({}),
            getCRRetriever({}),
            getClinicalNoteRequest(),
          ]).then(
            ([
              bookingClients,
              deliveryContactsSecond,
              subBookingCMs,
              payingClients,
              claimants,
              claims,
              imsCodes,
              wiroPrincipleLawyers,
              staffs,
              crProviderFirst,
              crProviderSecond,
              clinicalNoteRequest,
            ]) => {
              this.setFieldsValue({
                bookingClients: bookingClients.itemList || [],
                bookingCMs: [],
                deliveryContactsSecond: deliveryContactsSecond.itemList || [],
                subBookingCMs: subBookingCMs.itemList || [],
                payingClients: payingClients.itemList || [],
                payingCMs: [],
                claimants: claimants.itemList || [],
                claims: claims.itemList || [],
                imsCodes: imsCodes.itemList || [],
                wiroPrincipleLawyers: wiroPrincipleLawyers.itemList || [],
                staffs: staffs.itemList || [],
                crProviderFirst: crProviderFirst.itemList || [],
                crProviderSecond: crProviderSecond.itemList || [],
                actionRequiredList: [],
                caseCorrespondences: [],
                clinicalNoteRequest: clinicalNoteRequest || [],
                loading: false,
              });
            },
          );
        } else {
          const casemanager = api.currentUser.data;
          this.handleChangeCRValue({ F_RDCSameBookingCM: true, F_RDCCaseManagerId: casemanager.CaseManagerId });
          this.handleChangeSearchOpt();
          Promise.all([
            getClient({ keyword: casemanager.ClientId }),
            getCaseManagerOnboarded({ companyId: casemanager.ClientId, keyword: casemanager.CaseManagerId }),
            getCaseManagerOnboarded({ companyId: casemanager.ClientId }),
            getCaseManagerOnboarded({}),
            getClient({}),
            getClaimant({}),
            getTypeOfClaim(),
            getIMSCode(),
            getWiroPrincipleLawyer(),
            getStaff(),
            getClinic({}),
            getCRRetriever({}),
            getClinicalNoteRequest(),
          ]).then(
            ([
              bookingClients,
              bookingCMs,
              deliveryContactFirst,
              subBookingCMs,
              payingClients,
              claimants,
              claims,
              imsCodes,
              wiroPrincipleLawyers,
              staffs,
              crProviderFirst,
              crProviderSecond,
              clinicalNoteRequest,
            ]) => {
              this.handleChangeSearchOpt(
                { companyId: casemanager.ClientId, keyword: casemanager.CaseManagerId },
                'bookingCMsSearchOpt',
              );
              this.handleChangeSearchOpt(
                {
                  companyId: casemanager.ClientId,
                },
                'deliveryContactsSecondSearchOpt',
              );
              this.handleChangeSearchOpt({ companyId: casemanager.ClientId }, 'deliveryContactsFirstSearchOpt');
              this.setFieldsValue({
                bookingClients: bookingClients.itemList || [],
                bookingCMs: bookingCMs.itemList || [],
                deliveryContactFirst: deliveryContactFirst.itemList || [],
                deliveryContactsSecond: deliveryContactFirst.itemList || [],
                subBookingCMs: subBookingCMs.itemList || [],
                payingClients: payingClients.itemList || [],
                payingCMs: [],
                claimants: claimants.itemList || [],
                claims: claims.itemList || [],
                imsCodes: imsCodes.itemList || [],
                wiroPrincipleLawyers: wiroPrincipleLawyers.itemList || [],
                staffs: staffs.itemList || [],
                crProviderFirst: crProviderFirst.itemList || [],
                crProviderSecond: crProviderSecond.itemList || [],
                actionRequiredList: [],
                caseCorrespondences: [],
                clinicalNoteRequest: clinicalNoteRequest || [],
                loading: false,
              });
            },
          );
        }
      }
    } catch (e) {
    } finally {
      this.setFieldsValue({ isReady: true });
      this.handleStartReaction();
    }
  };

  @action handleResetStore = () => {
    this.loading = true;
    this.clinicalNoteInfo = INIT_CLINICAL_NOTE;
    this.ClinicalNotesReceivedAttachmentUploadNotSaved = false;
    this.isReady = false;

    this.invalidForm = [];
    this.ClinicalNotesReceivedAttachmentUploadNotSaved = false;
    this.oldPaperwordReceived = false;
    this.LOATemplate = {
      IsDisplay: '',
      FilePath: '/UserFile/ClinicAttachment/',
      FileTitle: '',
    };
    this.listInvoice = [];

    this.bookingClients = [];
    this.bookingCMs = [];
    this.deliveryContactFirst = [];
    this.deliveryContactsSecond = [];
    this.subBookingCMs = [];
    this.payingClients = [];
    this.payingCMs = [];
    this.claimants = [];
    this.claims = [];
    this.imsCodes = [];
    this.wiroPrincipleLawyers = [];
    this.staffs = [];
    this.crProviderFirst = [];
    this.crProviderSecond = [];
    this.actionRequiredList = [];
    this.caseCorrespondences = [];
    this.assignHistory = [];
    this.clinicalNoteRequest = [];

    //------Search Options------
    this.bookingClientsSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
    this.bookingCMsSearchOpt = {
      keyword: '',
      numberItem: 30,
      companyId: 0,
    };
    this.deliveryContactsFirstSearchOpt = {
      keyword: '',
      numberItem: 30,
      companyId: 0,
    };
    this.deliveryContactsSecondSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
    this.subBookingCMsSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
    this.payingClientsSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
    this.payingCMsSearchOpt = {
      keyword: '',
      numberItem: 30,
      companyId: 0,
    };
    this.claimantsSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
    this.crProviderFirstSearchOpt = {
      keyword: '',
      numberItem: 30,
      doctorId: 0,
    };
    this.crProviderSecondSearchOpt = {
      keyword: '',
      numberItem: 30,
    };
  };

  refetchSearch = (functionCallback, searchOpt, fieldName) => {
    return functionCallback && functionCallback(searchOpt).then(action(res => (this[fieldName] = res.itemList)));
  };

  onIsPaperworkReceivedChanged = () => {
    if (this.clinicalNoteInfo.IsPaperworkReceived) {
      if (!api.isAdminOrMagStaffOrAccounting()) {
        if (!this.renderAttachment(this.clinicalNoteInfo.Attachments, 5).length) {
          this.showNotification('Error', 'You need to attach Letter of Authority.', 'error');
          this.handleChangeCRValue({ IsPaperworkReceived: false });
          return;
        }
        if (!this.clinicalNoteInfo.EmailCNDocReq2 && !this.clinicalNoteInfo.EmailCNDocReq1) {
          this.showNotification('Error', 'At least one CR Provider must be selected to be sent to.', 'error');
          this.handleChangeCRValue({ IsPaperworkReceived: false });
          return;
        }
      } else {
        const otherMethods = this.clinicalNoteInfo.RequestSendToGpMethods || [];
        if (!this.clinicalNoteInfo.EmailLoAServiceProvider && !otherMethods.length) {
          this.showNotification('Error', 'Please select a request sending option first', 'error');
          this.handleChangeCRValue({
            IsPaperworkReceived: false,
          });
          return;
        }
      }
      this.handleChangeCRValue({
        RequestSentToGpDate: moment(),
        // PaperworkReceivedDate: moment(),
      });
      // if (this.clinicalNoteInfo.CreatedDate && !this.clinicalNoteInfo.RequestExpectedCompletionDate) {
      //   this.handleChangeCRValue({
      //     RequestExpectedCompletionDate: moment(this.clinicalNoteInfo.CreatedDate).add(7, 'days'),
      //   });
      // }
    } else {
      this.handleChangeCRValue({
        RequestSentToGpDate: null,
        // PaperworkReceivedDate: null,
      });
    }
  };

  MedicalCentreChange = () => {
    const item = this.crProviderFirstSelected;
    this.clinicalNoteInfo.ClinicInfo = item;
    if (item?.Email) {
      this.handleChangeCRValue({ EmailCNDocReq1: item.Email });
    }
    if (item?.AccountingEmail && !this.clinicalNoteInfo.SameAsBusinessEmail) {
      this.clinicalNoteInfo.SameAsBusinessEmailSend = item.AccountingEmail;
    }
    if (!this.crProviderSecondSelected) {
      this.handleChangeCRValue({ IsEmail1ForSendingCN: true });
    }
    if (!!item && item?.IsCNViewLOATemplate) {
      this.setFieldsValue({
        LOATemplate: {
          IsDisplay: true,
          FilePath: '/UserFile/ClinicAttachment/' + item.LOAFileName,
          FileTitle: item.LOAFileTitle,
        },
      });
    } else {
      this.setFieldsValue({
        LOATemplate: {
          IsDisplay: false,
          FilePath: '',
          FileTitle: '',
        },
      });
    }
  };

  renderDateTimeForCC = (datestr, showTime) => {
    const utcServerTime = this.utcServerTime;
    const utcLocalMinutes = new Date().getTimezoneOffset();
    const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr, true);
        return showTime
          ? moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY - HH:mm')
          : moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY');
      }
      return datestr;
    }
    return datestr;
  };

  IsEmail1ForSendingCNChange = () => {
    if (!this.crProviderFirstSelected && this.clinicalNoteInfo.IsEmail1ForSendingCN) {
      this.showNotification('Error', 'You need to select a medical centre', 'error');
      this.handleChangeCRValue({
        IsEmail1ForSendingCN: false,
      });
      return;
    }
    if (this.clinicalNoteInfo.IsEmail2ForSendingCN) {
      this.handleChangeCRValue({
        IsEmail2ForSendingCN: false,
        IsEmail1ForSendingCN: true,
      });
    }
  };

  IsEmail2ForSendingCNChange = () => {
    if (!this.crProviderSecondSelected?.Id && this.clinicalNoteInfo.IsEmail2ForSendingCN) {
      this.showNotification('Error', 'You need to select a specialist', 'error');
      this.handleChangeCRValue({
        IsEmail2ForSendingCN: false,
      });
      return;
    }
    if (this.clinicalNoteInfo.IsEmail1ForSendingCN) {
      this.handleChangeCRValue({
        IsEmail1ForSendingCN: false,
        IsEmail2ForSendingCN: true,
      });
    }
  };

  handleOnChangeDoctor = () => {
    const item = this.crProviderSecondSelected;
    if (item) {
      this.handleChangeCRValue({ EmailCNDocReq2: item.Email2 });
    }
    this.handleChangeCRValue({ IsEmail2ForSendingCN: true });
  };

  onchangeEmailLoAServiceProvider = () => {
    if (this.clinicalNoteInfo.EmailLoAServiceProvider) {
      if (!this.clinicalNoteInfo.EmailCNDocReq2 && !this.clinicalNoteInfo.EmailCNDocReq1) {
        this.showNotification('Error', 'At least email of one CR Provider must be updated to send request.', 'error');
        this.handleChangeCRValue({ EmailLoAServiceProvider: false });
        return;
      }
      if (!this.renderAttachment(this.clinicalNoteInfo.Attachments, 5).length) {
        this.showNotification('Error', 'You need to attach Letter of Authority.', 'error');
        this.handleChangeCRValue({ EmailLoAServiceProvider: false });
        return;
      }
      this.handleChangeCRValue({
        IsPaperworkReceived: true,
        RequestSentToGpDate: moment(),
      });
      // if (this.clinicalNoteInfo.CreatedDate && !this.clinicalNoteInfo.RequestExpectedCompletionDate) {
      //   this.handleChangeCRValue({
      //     RequestExpectedCompletionDate: moment(this.clinicalNoteInfo.CreatedDate).add(7, 'day'),
      //   });
      // }
    }
  };

  onclickRequestSendToGpMethodChecked = () => {
    const listMethod = this.clinicalNoteInfo.RequestSendToGpMethods || [];
    if (!!listMethod.length) {
      this.handleChangeCRValue({
        RequestSentToGpDate: moment(),
        IsPaperworkReceived: true,
      });
      // if (this.clinicalNoteInfo.CreatedDate && !this.clinicalNoteInfo.RequestExpectedCompletionDate) {
      //   this.handleChangeCRValue({
      //     RequestExpectedCompletionDate: moment(this.clinicalNoteInfo.CreatedDate).add(7, 'day'),
      //   });
      // }
    } else {
      this.handleChangeCRValue({
        RequestSentToGpDate: null,
        // PaperworkReceivedDate: moment(),
      });
    }
  };

  CheckWiRo = () => {
    let bRet = false;

    if (
      this.clinicalNoteInfo.WifroIlars &&
      (!this.clinicalNoteInfo.IlarsRef ||
        !this.clinicalNoteInfo.IMSApprovalCode ||
        !this.clinicalNoteInfo.IlarsWiroSolicitor)
    ) {
      bRet = true;
    }
    if (!bRet) {
      if (
        !this.clinicalNoteInfo.WifroIlars &&
        this.bookingClientSelected &&
        this.bookingClientSelected.ClientType &&
        this.bookingClientSelected.ClientType === 3 &&
        this.bookingClientSelected &&
        this.bookingClientSelected.StateName &&
        this.bookingClientSelected.StateName === 'NSW' &&
        this.claimantSelected.selected &&
        this.claimantSelected.selected.Id &&
        this.claimantSelected.selected.Id === 13 &&
        this.clinicalNoteInfo.ReportTypeId === 31
      ) {
        bRet = true;
      }
    }
    //}
    return bRet;
  };

  sendWarningWiro = () => {
    var objSend = {
      RecordId: this.clinicalNoteInfo.Id,
      IlarsRef: this.clinicalNoteInfo.IlarsRef,
      IMSApprovalCode: this.clinicalNoteInfo.IMSApprovalCode,
      IlarsWiroSolicitorName: this.clinicalNoteInfo.IlarsWiroSolicitor,
    };
    customFetch('/ClinicalNote/SendWiroMissing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...objSend }),
    }).then(res => {
      if (res.status === 'success') {
        notification.success({
          message: 'IRO Reminders',
          description: 'IRO reminders to Booking CM sucessfully!',
        });
      } else {
        notification.error({
          message: 'IRO Reminders',
          description: 'IRO reminders to Booking CM error!',
        });
      }
    });
  };

  ProcessWiroWarning = () => {
    this.toggleModalConfirm(true, {
      message:
        'IRO information for this case has not been provided and payment may not be approved. Do you still want to send the report?',
      onOk: () => {
        this.toggleModalConfirm(true, {
          message: 'Do you want to send IRO reminder to booking CM now?',
          onOk: () => this.sendWarningWiro(),
        });
      },
      functionCallback: () => {
        this.handleChangeCRValue({
          ClinicalNotesReceived: false,
          ClinicalNotesSentDate: null,
        });
        this.toggleModalConfirm(true, {
          message: 'Do you want to send IRO reminder to booking CM now?',
          onOk: () => this.sendWarningWiro(),
        });
      },
    });
  };

  onAllowCMstoviewreports = () => {
    if (this.clinicalNoteInfo.IsAllowCMsViewReports) {
      if (
        !this.renderAttachment(this.clinicalNoteInfo.Attachments, 1).length &&
        !this.renderAttachment(this.clinicalNoteInfo.Attachments, 7).length &&
        !this.clinicalNoteInfo.CRFilesExLink
      ) {
        this.showNotification('Error', 'Please attach CR files or insert download links');
        this.handleChangeCRValue({ IsAllowCMsViewReports: false });
        return;
      }

      if (!this.deliveryContactFirstSelected?.Email && !this.deliveryContactSecondSelected?.Email) {
        this.showNotification('Error', 'At least one Report Delivery Contact needs to be completed');
        this.handleChangeCRValue({ IsAllowCMsViewReports: false });
      } else {
        this.toggleModalConfirm(true, {
          message: 'Are you sure to allow CMs to view completed reports?',
          onOk: () => this.handleChangeCRValue({ IsAllowCMsViewReports: true }),
          functionCallbackCancel: () => this.handleChangeCRValue({ IsAllowCMsViewReports: false }),
        });
      }
    }
  };

  resetClinicalNotesReceivedSection = () => {
    this.handleChangeCRValue({
      ClinicalNotesReceived: false,
      ClinicalNotesSentDate: null,
      // Attachments: this.clinicalNoteInfo.Attachments
      //   ? this.clinicalNoteInfo.Attachments.filter(i => i.ReportType !== 1)
      //   : [],
    });
  };

  onClinicalNotesReceivedChanged = function() {
    if (this.clinicalNoteInfo.ClinicalNotesReceived) {
      this.handleChangeCRValue({
        ClinicalNotesSentDate: moment(),
        IsAllowCMsViewReports: true,
      });
      if (this.CheckWiRo()) {
        this.ProcessWiroWarning();
      }
      this.onAllowCMstoviewreports();
    } else {
      this.resetClinicalNotesReceivedSection();
    }
  };

  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      let arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      let i = +arr[0] + 'e' + sig + (+arr[1] + scale);
      let j = Math.round(i);
      let k = +(j + 'e-' + scale);
      return k;
    }
  }

  CalculaTaxPaymentInvoiceAmount = () => {
    if (!this.clinicalNoteInfo.PaymentRequestedInvoiceTax || !this.clinicalNoteInfo.PaymentRequestedInvoiceTax === 0) {
      if (this.clinicalNoteInfo.PaymentRequestedInvoiceAmount > 0) {
        const amount = (parseFloat(this.clinicalNoteInfo.PaymentRequestedInvoiceAmount) * 10) / 100;
        this.handleChangeCRValue({
          PaymentRequestedInvoiceTax: parseFloat(this.roundNumber(amount, 2)),
        });
      }
    }
  };
  onIsPaymentRequestedChange = () => {
    if (this.clinicalNoteInfo.IsPaymentRequested) {
      this.clinicalNoteInfo.PaymentRequestedDate = moment();
      this.clinicalNoteInfo.PrepaymentRequiredDate = moment();
      const attachments = this.renderAttachment(this.clinicalNoteInfo.Attachments, 2);
      if (!attachments.length) {
        this.showNotification('Error', 'Provider invoice must be attached first');
        this.handleChangeCRValue({ IsPaymentRequested: false });
      }
    } else {
      this.handleChangeCRValue({
        PaymentRequestedDate: null,
        PaymentRequestedInvoiceAmount: 0,
      });
    }
  };

  SendPaymentToProviderChange = () => {
    if (this.clinicalNoteInfo.IsSendPaymentToProvider) {
      if (!this.clinicalNoteInfo.SameAsBusinessEmailSend) {
        this.showNotification('Error', 'Please update an email to send receipt');
        this.handleChangeCRValue({ IsSendPaymentToProvider: false });
      }
    }
    // if (this.clinicalNoteInfo.IsSendPaymentToProvider) {
    //   if (!this.crProviderFirstSelected?.Email && !this.crProviderFirstSelected?.AccountingEmail) {
    //     this.showNotification('Error', 'The clinic has no email, please check and resend');
    //     this.handleChangeCRValue({ IsSendPaymentToProvider: false });
    //   } else if (!this.crProviderFirstSelected?.AccountingEmail) {
    //     this.toggleModalConfirm(true, {
    //       message: 'The clinic has no accounting email, do you want to send to business email instead?',
    //       onOk: () => {},
    //       functionCallbackCancel: () => {
    //         this.handleChangeCRValue({ IsSendPaymentToProvider: false });
    //       },
    //     });
    //   }
    // }
  };

  ReSentNotifyPaymentToProvider = () => {
    if (this.clinicalNoteInfo.IsSendPaymentToProvider) {
      this.toggleModalConfirm(true, {
        message: 'Do you want to resend payment receipt?',
        onOk: () => {
          customFetch('/ClinicalNote/ResendNotifyPaymentToProvider', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: this.clinicalNoteInfo.Id,
              email: this.clinicalNoteInfo.SameAsBusinessEmailSend,
            }),
          }).then(res => {
            if (res.status === 'success') {
              this.showNotification('Success', 'Resend payment notice to provider', 'success');
              this.handleChangeCRValue({
                SendPaymentToProviderDate: moment(res.UpdatedDate),
              });
              this.handleChangeFieldInput({
                SendPaymentToProviderSendBy: res.SendBy,
              });
            } else {
              this.showNotification('Error', 'Resend payment notice to provider');
            }
          });
        },
      });
    }
  };

  //reaction search option
  reactionSearchOptBookingClient = null;
  reactionSearchOptBookingCMs = null;
  reactionSearchOptDeliveryContactsSecond = null;
  reactionSearchOptSubBookingCMs = null;
  reactionSearchOptPayingClients = null;
  reactionSearchOptPayingCMs = null;
  reactionSearchOptClaimants = null;
  reactionSearchOptCrProviderFirst = null;
  reactionSearchOptCrProviderSecond = null;
  reactionSearchOptDeliveryFirst = null;
  //---reaction field value change
  reactionOnChangeBookingClient = null;
  reactionOnChangePayingClient = null;
  reactionOnChangeRDCSameBookingCM = null;
  reactionOnChangeRDCCaseManager = null;
  reactionOnchangeBookingCMs = null;
  reactionOnChangeIsPaperworkReceived = null;
  reactionOnChangeClinicId = null;
  reactionOnChangeDoctorId = null;
  reactionOnChangeEmailCNDocReq1 = null;
  reactionOnChangeEmailCNDocReq2 = null;
  reactionOnChangeIsEmail1ForSendingCN = null;
  reactionOnChangeIsEmail2ForSendingCN = null;
  // reactionOnChangeRequestExpectedCompletionDate = null;
  // reactionOnChangePaperworkReceivedDate = null;
  reactionOnChangeEmailLoAServiceProvider = null;
  reactionOnChangeOtherMethod = null;
  reactionOnChangeRequestSentToGpDate = null;
  reactionOnChangeClinicalNotesReceived = null;
  reactionOnChangeClinicalNotesSentDate = null;
  reactionOnChangeIsAllowCMsViewReports = null;
  reactionOnChangePaymentRequestedDate = null;
  reactionOnChangeIsPaymentRequested = null;
  reactionOnChangePrepaymentRequiredDate = null;
  reactionOnChangeInvoicePaid = null;
  reactionOnChangeInvoicePaidDate = null;
  reactionOnChangeIsSendPaymentToProvider = null;
  reactionOnChangeSameAsBusinessEmail = null;

  handleDisposeReaction = () => {
    //reaction search option
    this.reactionSearchOptBookingClient();
    this.reactionSearchOptBookingCMs();
    this.reactionSearchOptDeliveryContactsSecond();
    this.reactionSearchOptSubBookingCMs();
    this.reactionSearchOptPayingClients();
    this.reactionSearchOptPayingCMs();
    this.reactionSearchOptClaimants();
    this.reactionSearchOptCrProviderFirst();
    this.reactionSearchOptCrProviderSecond();
    this.reactionSearchOptDeliveryFirst();
    //---reaction field value change
    this.reactionOnChangeBookingClient();
    this.reactionOnChangePayingClient();
    this.reactionOnchangeBookingCMs();
    this.reactionOnChangeRDCSameBookingCM();
    this.reactionOnChangeRDCCaseManager();
    this.reactionOnChangeIsPaperworkReceived();
    this.reactionOnChangeClinicId();
    this.reactionOnChangeDoctorId();
    this.reactionOnChangeEmailCNDocReq1();
    this.reactionOnChangeEmailCNDocReq2();
    this.reactionOnChangeIsEmail1ForSendingCN();
    this.reactionOnChangeIsEmail2ForSendingCN();
    // this.reactionOnChangeRequestExpectedCompletionDate();
    // this.reactionOnChangePaperworkReceivedDate();
    this.reactionOnChangeEmailLoAServiceProvider();
    this.reactionOnChangeOtherMethod();
    this.reactionOnChangeRequestSentToGpDate();
    this.reactionOnChangeClinicalNotesReceived();
    this.reactionOnChangeClinicalNotesSentDate();
    this.reactionOnChangeIsAllowCMsViewReports();
    this.reactionOnChangePaymentRequestedDate();
    this.reactionOnChangeIsPaymentRequested();
    this.reactionOnChangePrepaymentRequiredDate();
    this.reactionOnChangeInvoicePaid();
    this.reactionOnChangeInvoicePaidDate();
    this.reactionOnChangeIsSendPaymentToProvider();
    this.reactionOnChangeSameAsBusinessEmail();
  };

  handleStartReaction = () => {
    this.reactionOnChangeSameAsBusinessEmail = reaction(
      () => this.clinicalNoteInfo.SameAsBusinessEmail,
      () => {
        if (this.clinicalNoteInfo.SameAsBusinessEmail) {
          this.clinicalNoteInfo.SameAsBusinessEmailSend = this.clinicalNoteInfo.ClinicInfo?.Email;
        } else {
          this.clinicalNoteInfo.SameAsBusinessEmailSend = this.clinicalNoteInfo.ClinicInfo?.AccountingEmail;
        }
      },
    );
    this.reactionOnChangeIsSendPaymentToProvider = reaction(
      () => this.clinicalNoteInfo.IsSendPaymentToProvider,
      () => this.SendPaymentToProviderChange(),
    );
    this.reactionOnChangeInvoicePaidDate = reaction(
      () => this.clinicalNoteInfo.InvoicePaidDate,
      () => {
        if (this.clinicalNoteInfo.InvoicePaid) {
          this.validateField('InvoicePaidDate', this.clinicalNoteInfo.InvoicePaidDate, 'required');
        } else {
          this.removeError('InvoicePaidDate');
        }
      },
    );
    this.reactionOnChangeInvoicePaid = reaction(
      () => this.clinicalNoteInfo.InvoicePaid,
      () => {
        this.handleChangeCRValue({
          InvoicePaidDate: this.clinicalNoteInfo.InvoicePaid ? moment() : null,
        });
        this.removeError('InvoicePaidDate');
        if (
          this.clinicalNoteInfo.InvoicePaid &&
          this.clinicalNoteInfo.ClinicInfo &&
          !this.clinicalNoteInfo.SameAsBusinessEmailSend
        ) {
          if (this.clinicalNoteInfo.SameAsBusinessEmail) {
            this.clinicalNoteInfo.SameAsBusinessEmailSend = this.clinicalNoteInfo.ClinicInfo.Email;
          } else {
            this.clinicalNoteInfo.SameAsBusinessEmailSend = this.clinicalNoteInfo.ClinicInfo.AccountingEmail;
          }
        }
      },
    );
    this.reactionOnChangePrepaymentRequiredDate = reaction(
      () => this.clinicalNoteInfo.PrepaymentRequiredDate,
      () => {
        if (this.clinicalNoteInfo.IsPaymentRequested) {
          this.validateField('PrepaymentRequiredDate', this.clinicalNoteInfo.PrepaymentRequiredDate, 'required');
        } else {
          this.removeError('PrepaymentRequiredDate');
        }
      },
    );
    this.reactionOnChangeIsPaymentRequested = reaction(
      () => this.clinicalNoteInfo.IsPaymentRequested,
      () => {
        this.onIsPaymentRequestedChange();
        this.removeError('PrepaymentRequiredDate');
        this.removeError('PaymentRequestedDate');
      },
    );
    this.reactionOnChangePaymentRequestedDate = reaction(
      () => this.clinicalNoteInfo.PaymentRequestedDate,
      () => {
        if (this.clinicalNoteInfo.IsPaymentRequested) {
          this.validateField('PaymentRequestedDate', this.clinicalNoteInfo.PaymentRequestedDate, 'required');
        } else {
          this.removeError('PaymentRequestedDate');
        }
      },
    );
    this.reactionOnChangeIsAllowCMsViewReports = reaction(
      () => this.clinicalNoteInfo.IsAllowCMsViewReports,
      () => this.onAllowCMstoviewreports(),
    );
    this.reactionOnChangeClinicalNotesSentDate = reaction(
      () => this.clinicalNoteInfo.ClinicalNotesSentDate,
      () => {
        if (this.clinicalNoteInfo.ClinicalNotesReceived) {
          this.validateField('ClinicalNotesSentDate', this.clinicalNoteInfo.ClinicalNotesSentDate, 'required');
        } else {
          this.removeError('ClinicalNotesSentDate');
        }
      },
    );
    this.reactionOnChangeClinicalNotesReceived = reaction(
      () => this.clinicalNoteInfo.ClinicalNotesReceived,
      () => this.onClinicalNotesReceivedChanged(),
    );
    this.reactionOnChangeRequestSentToGpDate = reaction(
      () => this.clinicalNoteInfo.RequestSentToGpDate,
      () => this.RequestSentToGpDateSelectDateChange(),
    );
    this.reactionOnChangeOtherMethod = reaction(
      () => this.clinicalNoteInfo.RequestSendToGpMethods,
      () => this.onclickRequestSendToGpMethodChecked(),
    );
    this.reactionOnChangeEmailLoAServiceProvider = reaction(
      () => this.clinicalNoteInfo.EmailLoAServiceProvider,
      () => this.onchangeEmailLoAServiceProvider(),
    );
    // this.reactionOnChangePaperworkReceivedDate = reaction(
    //   () => this.clinicalNoteInfo.PaperworkReceivedDate,
    //   () => {
    //     if (this.clinicalNoteInfo.IsPaperworkReceived) {
    //       this.validateField('PaperworkReceivedDate', this.clinicalNoteInfo.PaperworkReceivedDate, 'required');
    //     } else {
    //       this.removeError('PaperworkReceivedDate');
    //     }
    //   },
    // );
    // this.reactionOnChangeRequestExpectedCompletionDate = reaction(
    //   () => this.clinicalNoteInfo.RequestExpectedCompletionDate,
    //   () => {
    //     // if (
    //     //   this.clinicalNoteInfo.CancellationStatus === CONSTANTS.CancellationStatus.None &&
    //     //   this.clinicalNoteInfo.CaseStatus !== CONSTANTS.CaseStatus.CancelledNoCharge &&
    //     //   this.clinicalNoteInfo.CaseStatus !== CONSTANTS.CaseStatus.CancelledWithFee &&
    //     //   this.clinicalNoteInfo.IsPaperworkReceived
    //     // ) {
    //     //   this.validateField(
    //     //     'RequestExpectedCompletionDate',
    //     //     this.clinicalNoteInfo.RequestExpectedCompletionDate,
    //     //     'required',
    //     //   );
    //     // } else {
    //     //   this.removeError('RequestExpectedCompletionDate');
    //     // }
    //   },
    // );
    this.reactionOnChangeIsEmail1ForSendingCN = reaction(
      () => this.clinicalNoteInfo.IsEmail1ForSendingCN,
      () => this.IsEmail1ForSendingCNChange(),
    );
    this.reactionOnChangeIsEmail2ForSendingCN = reaction(
      () => this.clinicalNoteInfo.IsEmail2ForSendingCN,
      () => this.IsEmail2ForSendingCNChange(),
    );
    this.reactionOnChangeEmailCNDocReq2 = reaction(
      () => this.clinicalNoteInfo.EmailCNDocReq2,
      () => {
        if (this.clinicalNoteInfo.EmailCNDocReq2) {
          this.validateField('EmailCNDocReq2', this.clinicalNoteInfo.EmailCNDocReq2, 'email');
        } else {
          this.removeError('EmailCNDocReq2');
        }
      },
    );
    this.reactionOnChangeEmailCNDocReq1 = reaction(
      () => this.clinicalNoteInfo.EmailCNDocReq1,
      () => {
        if (this.clinicalNoteInfo.EmailCNDocReq1) {
          this.validateField('EmailCNDocReq1', this.clinicalNoteInfo.EmailCNDocReq1, 'email');
        } else {
          this.removeError('EmailCNDocReq1');
        }
      },
    );
    this.reactionOnchangeBookingCMs = reaction(
      () => this.clinicalNoteInfo.CaseManagerId,
      () => {
        this.handleChangeCRValue({ F_RDCSameBookingCM: !!this.clinicalNoteInfo.CaseManagerId });
        if (this.clinicalNoteInfo.F_RDCSameBookingCM) {
          this.handleChangeCRValue({
            F_RDCCaseManagerId: this.clinicalNoteInfo.CaseManagerId,
          });
        }
      },
    );
    this.reactionOnChangeDoctorId = reaction(
      () => this.clinicalNoteInfo.DoctorId,
      () => this.handleOnChangeDoctor(),
    );
    this.reactionOnChangeClinicId = reaction(
      () => this.clinicalNoteInfo.ClinicId,
      () => this.MedicalCentreChange(),
    );
    this.reactionOnChangeIsPaperworkReceived = reaction(
      () => this.clinicalNoteInfo.IsPaperworkReceived,
      () => this.onIsPaperworkReceivedChanged(),
    );
    this.reactionOnChangeRDCCaseManager = reaction(
      () => this.clinicalNoteInfo.F_RDCCaseManagerId,
      () => {
        this.handleChangeCRValue({
          F_RDCEmail: this.deliveryContactFirstSelected?.Email || '',
        });
      },
    );
    this.reactionOnChangeRDCSameBookingCM = reaction(
      () => this.clinicalNoteInfo.F_RDCSameBookingCM,
      () => {
        this.handleChangeCRValue({
          F_RDCCaseManagerId: this.clinicalNoteInfo.CaseManagerId,
        });
      },
    );
    this.reactionOnChangeBookingClient = reaction(
      () => this.clinicalNoteInfo.CustomerId,
      () => {
        this.handleChangeCRValue({
          CaseManagerId: null,
          F_RDCCaseManagerId: null,
        });
        this.handleChangeSearchOpt(
          {
            companyId: this.clinicalNoteInfo.CustomerId,
            keyword: '',
          },
          'bookingCMsSearchOpt',
        );
        this.setFieldsValue({ bookingCMs: [] });
      },
    );
    this.reactionOnChangePayingClient = reaction(
      () => this.clinicalNoteInfo.PCustomerId,
      () => {
        this.handleChangeCRValue({
          PCaseManagerId: null,
        });
        this.handleChangeSearchOpt(
          {
            companyId: this.clinicalNoteInfo.PCustomerId,
            keyword: '',
          },
          'payingCMsSearchOpt',
        );
        this.setFieldsValue({ payingCMs: [] });
      },
    );
    this.reactionSearchOptBookingClient = autorun(() => {
      const { bookingClientsSearchOpt } = this;
      this.refetchSearch(getClient, bookingClientsSearchOpt, 'bookingClients');
    });
    this.reactionSearchOptBookingCMs = autorun(() => {
      const { bookingCMsSearchOpt } = this;
      if (!!bookingCMsSearchOpt.companyId) {
        return this.refetchSearch(getCaseManagerOnboarded, bookingCMsSearchOpt, 'bookingCMs');
      } else {
        this.setFieldsValue({ bookingCMs: [] });
      }
    });
    this.reactionSearchOptDeliveryContactsSecond = autorun(() => {
      const { deliveryContactsSecondSearchOpt } = this;
      if (api.isAdminOrMagStaffOrAccounting()) {
        return this.refetchSearch(getCaseManagerOnboarded, deliveryContactsSecondSearchOpt, 'deliveryContactsSecond');
      } else {
        this.refetchSearch(
          getCaseManagerOnboarded,
          { ...deliveryContactsSecondSearchOpt, companyId: api.currentUser.data.ClientId },
          'deliveryContactsSecond',
        );
      }
    });
    this.reactionSearchOptSubBookingCMs = autorun(() => {
      const { subBookingCMsSearchOpt } = this;
      this.refetchSearch(getCaseManagerOnboarded, subBookingCMsSearchOpt, 'subBookingCMs');
    });
    this.reactionSearchOptPayingClients = autorun(() => {
      const { payingClientsSearchOpt } = this;
      this.refetchSearch(getClient, payingClientsSearchOpt, 'payingClients');
    });
    this.reactionSearchOptPayingCMs = autorun(() => {
      const { payingCMsSearchOpt } = this;
      if (!!payingCMsSearchOpt.companyId) {
        return this.refetchSearch(getCaseManagerOnboarded, payingCMsSearchOpt, 'payingCMs');
      } else {
        return this.setFieldsValue({ payingCMs: [] });
      }
    });
    this.reactionSearchOptClaimants = autorun(() => {
      const { claimantsSearchOpt } = this;
      this.refetchSearch(getClaimant, claimantsSearchOpt, 'claimants');
    });
    this.reactionSearchOptCrProviderFirst = autorun(() => {
      const { crProviderFirstSearchOpt } = this;
      this.refetchSearch(getClinic, crProviderFirstSearchOpt, 'crProviderFirst');
    });
    this.reactionSearchOptCrProviderSecond = autorun(() => {
      const { crProviderSecondSearchOpt } = this;
      this.refetchSearch(getCRRetriever, crProviderSecondSearchOpt, 'crProviderSecond');
    });
    this.reactionSearchOptDeliveryFirst = autorun(() => {
      const { deliveryContactsFirstSearchOpt } = this;
      this.refetchSearch(getCaseManagerOnboarded, deliveryContactsFirstSearchOpt, 'deliveryContactFirst');
    });
  };

  validateForm() {
    let errors = [];
    let errorClaimDetails = [];
    if (!this.bookingClientSelected) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a booking client');
    }
    if (!this.bookingCMSelected) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a booking case manager');
    }
    if (!this.claimantSelected) {
      errorClaimDetails.push('Claim Details, Claimant Info - Please select a claimant');
    }

    if (!this.clinicalNoteInfo.ClaimantNo) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please input reference/claim no.');
    }
    if (!this.clinicalNoteInfo.ReportId) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please select a type of claim');
    }
    if (this.clinicalNoteInfo.IROFundingApproved && !this.clinicalNoteInfo.IROFundingApprovalDate) {
      errorClaimDetails.push('Claim Details - IRO Funding Approval Date is required.');
    }
    let errorCaseDocuments = [];
    if (!this.crProviderFirstSelected && !this.crProviderSecondSelected && this.clinicalNoteInfo.IsPaperworkReceived) {
      errorCaseDocuments.push('Paperwork and CR Retrieval, Provider of Clinical Records - Please select a CR provider');
    }
    if (
      this.clinicalNoteInfo.EmailLoAServiceProvider &&
      !this.clinicalNoteInfo.EmailCNDocReq1 &&
      !this.clinicalNoteInfo.EmailCNDocReq2
    ) {
      errorCaseDocuments.push('Paperwork and CR Retrieval, Provider of Clinical Records - Please select a provider');
    }
    // if (
    //   this.clinicalNoteInfo.IsPaperworkReceived && !this.clinicalNoteInfo.PaperworkReceivedDate
    // ) {
    //   errorCaseDocuments.push('Paperwork and CR Retrieval, Provider Prepayment & CR Files - Please specify the paperwork received date');
    // }
    // if (this.clinicalNoteInfo.IsPaperworkReceived && !this.clinicalNoteInfo.RequestSentToGpDate) {
    //   errorCaseDocuments.push('Paperwork and CR Retrieval, Paperwork - Please select a paperwork sent date');
    // }
    if (this.clinicalNoteInfo.InvoicePaid && !this.clinicalNoteInfo.InvoicePaidDate) {
      errorCaseDocuments.push('Provider Payments, Please specify the paid date');
    }
    if (this.clinicalNoteInfo.IsPaymentRequested && !this.clinicalNoteInfo.PaymentRequestedDate) {
      errorCaseDocuments.push('Provider Payment, Please specify the invoice date');
    }
    // if (
    //   this.clinicalNoteInfo.RequestExpectedCompletionDate == null &&
    //   !this.clinicalNoteInfo.CancellationStatus &&
    //   this.clinicalNoteInfo.CaseStatus !== 1 &&
    //   this.clinicalNoteInfo.CaseStatus !== 2 &&
    //   this.clinicalNoteInfo.IsPaperworkReceived
    // ) {
    //   errorCaseDocuments.push(
    //     'Case Documents, Provider Prepayment & CR Files - You should specify the expected compeletion date.',
    //   );
    // }
    if (api.isAdminOrMagStaffOrAccounting() && !this.clinicalNoteInfo.AssignedStaffId) {
      // errorCaseDocuments.push('Case Documents, Provider Prepayment & CR Files - Please select a PIC.');
    }
    if (!this.crProviderFirstSelected && this.clinicalNoteInfo.IsEmail1ForSendingCN) {
      // errorCaseDocuments.push(
      //   'Paperwork and CR Retrieval, Provider of Clinical Records - Please select a Medical Centre.',
      // );
    }
    if (!this.crProviderFirstSelected && this.clinicalNoteInfo.IsEmail2ForSendingCN) {
      errorCaseDocuments.push(
        'Paperwork and CR Retrieval, Provider of Clinical Records - Please select a Treating Specialist.',
      );
    }
    if (
      this.clinicalNoteInfo.IsPaperworkReceived &&
      !this.clinicalNoteInfo.IsEmail1ForSendingCN &&
      !this.clinicalNoteInfo.IsEmail2ForSendingCN
    ) {
      errorCaseDocuments.push(
        'Paperwork and CR Retrieval, Provider of Clinical Records - At least one CR Provider must be selected to be sent to.',
      );
    }
    if (this.clinicalNoteInfo.ClinicalNotesReceived && !this.clinicalNoteInfo.ClinicalNotesSentDate) {
      errorCaseDocuments.push(
        'Paperwork and CR Retrieval, Completion of CR Retrieval - Please specify the CR File Sent Date',
      );
    }
    if (this.clinicalNoteInfo.RequestedCRRange === 1) {
      if (!this.clinicalNoteInfo.RequestedCRRangeFrom && !this.clinicalNoteInfo.RequestedCRRangeTo) {
        errorCaseDocuments.push('Claim Details, Claim No - Claim Type - Please input requested CR range date.');
      } else {
        if (!this.clinicalNoteInfo.RequestedCRRangeFrom) {
          errorCaseDocuments.push('Claim Details, Claim No - Claim Type - Please input requested CR range from date.');
        } else if (!this.clinicalNoteInfo.RequestedCRRangeTo) {
          errorCaseDocuments.push('Claim Details, Claim No - Claim Type - Please input requested CR range to date.');
        }
      }
    }
    if (
      !api.isAdminOrMagStaffOrAccounting() &&
      this.clinicalNoteInfo.IsPaperworkReceived &&
      !this.renderAttachment(this.clinicalNoteInfo.Attachments, 5).length
    ) {
      errorCaseDocuments.push('Paperwork and CR Retrieval, Paperwork - You need to attach Letter of Authority.');
    }

    // if (
    //   this.clinicalNoteInfo.RequestSendToGpMethods &&
    //   !!this.clinicalNoteInfo.RequestSendToGpMethods.length &&
    //   !this.clinicalNoteInfo.RequestSentToGpDate
    // ) {
    //   errorCaseDocuments.push(
    //     'Paperwork and CR Retrieval, RequestSentToGpDate - Sent date of paperwork received is required.',
    //   );
    // }
    if (
      this.clinicalNoteInfo.IsAllowCMsViewReports &&
      !this.deliveryContactFirstSelected?.Email &&
      !this.deliveryContactSecondSelected?.Email
    ) {
      errorCaseDocuments.push(
        'Claim Details, Booking Client and CM, at least one Report Delivery Contact needs to be selected to send CR Files to CMs.',
      );
    }

    if (!!errorClaimDetails.length) {
      errors = errors.concat(errorClaimDetails);
    }
    if (!!errorCaseDocuments.length) {
      errors = errors.concat(errorCaseDocuments);
    }
    return errors;
  }

  handleSave = () => {
    const { clinicalNoteInfo } = this;
    if (!clinicalNoteInfo.AssignedStaffId) {
      this.handleChangeCRValue({ AssignedStaffId: clinicalNoteInfo.CaseManager?.AssignedStaffId });
    }
    const { invalidForm } = this;
    const errors = this.validateForm();

    if (!!errors.length) {
      return errors.map(i => {
        this.showNotification('Error', i);
        return i;
      });
    } else if (!!invalidForm.length) {
      return invalidForm.map(i => {
        this.showNotification('Error', i.message);
        return i;
      });
    } else {
      const { clinicalNoteInfo, formatReqDate } = this;
      const clinicalNote = {
        ...clinicalNoteInfo,
        Patient: this.claimantSelected,
        ActionRequiredDueDate: formatReqDate(clinicalNoteInfo.ActionRequiredDueDate),
        CancelledDate: formatReqDate(clinicalNoteInfo.CancelledDate),
        CreatedDate: formatReqDate(clinicalNoteInfo.CreatedDate),
        EmailInvoiceToMAGDateSent: formatDate(clinicalNoteInfo.EmailInvoiceToMAGDateSent),
        EmailToCMsViewReportRecent: formatReqDate(clinicalNoteInfo.EmailToCMsViewReportRecent),
        ExpectedCompletionDate: formatReqDate(clinicalNoteInfo.ExpectedCompletionDate),
        InjuriedDate: formatReqDate(clinicalNoteInfo.InjuriedDate),
        InquiryDate: formatReqDate(clinicalNoteInfo.InquiryDate),
        InvoicePaidDate: formatReqDate(clinicalNoteInfo.InvoicePaidDate),
        PaperworkReceivedDate: formatReqDate(clinicalNoteInfo.PaperworkReceivedDate),
        PaymentRequestedDate: formatReqDate(clinicalNoteInfo.PaymentRequestedDate),
        PrepaymentRequiredDate: formatReqDate(clinicalNoteInfo.PrepaymentRequiredDate),
        RequestExpectedCompletionDate: formatReqDate(clinicalNoteInfo.RequestExpectedCompletionDate),
        RequestReceivedDate: formatReqDate(clinicalNoteInfo.RequestReceivedDate),
        RequestSentToGpDate: formatReqDate(clinicalNoteInfo.RequestSentToGpDate),
        RequestedCRRangeFrom: formatReqDate(clinicalNoteInfo.RequestedCRRangeFrom),
        RequestedCRRangeTo: formatReqDate(clinicalNoteInfo.RequestedCRRangeTo),
        SendPaymentToProviderDate: formatReqDate(clinicalNoteInfo.SendPaymentToProviderDate),
        SentDateLoAServiceProvider: formatReqDate(clinicalNoteInfo.SentDateLoAServiceProvider),
        SentDateNotifyBookingCMCancellation: formatDate(clinicalNoteInfo.SentDateNotifyBookingCMCancellation),
        SentDateNotifyDoctorCancellation: formatReqDate(clinicalNoteInfo.SentDateNotifyDoctorCancellation),
        StatusDate: formatReqDate(clinicalNoteInfo.StatusDate),
        IROFundingApprovalDate: formatReqDate(clinicalNoteInfo.IROFundingApprovalDate),
        UpdatedDate: this.formatReqDateISOS(clinicalNoteInfo.UpdatedDate),
        SubCaseManager1Id: clinicalNoteInfo.SubBookingCM[0]?.Id || null,
        SubCaseManager2Id: clinicalNoteInfo.SubBookingCM[1]?.Id || null,
        SubCaseManager3Id: clinicalNoteInfo.SubBookingCM[2]?.Id || null,
        patientInfo: this.claimantSelected,
        RequestSendToGpMethods: clinicalNoteInfo.RequestSendToGpMethods.map(item => ({ Id: item })),
      };
      delete clinicalNote.SubBookingCM;
      const bodyParam = {
        CRFilesExLink: clinicalNoteInfo.CRFilesExLink,
        attachments: clinicalNoteInfo.Attachments,
        patientInfo: this.claimantSelected,
        clinicalNote,
      };
      const paperworks = this.renderAttachments(bodyParam.attachments, [5, 0]);
      this.setFieldsValue({ loading: true });
      Promise.all([getUserUploadInfo(paperworks[0]?.UploadedById)]).then(([uP]) => {
        let isAdminOrStaffUpload = false;
        if (uP.status === 'success') {
          this.setFieldsValue({ loading: false });
          if (uP?.User?.Roles.some(r => r === 'admin' || r === 'magstaff')) {
            isAdminOrStaffUpload = true;
          }
        }
        if (
          api.isCaseManager() &&
          ((!bodyParam.clinicalNote.NotifyPaperworkReadinessToStaff && !isAdminOrStaffUpload) ||
            bodyParam.clinicalNote.IsUpdatedPaperwork)
        ) {
          if (paperworks.length) {
            this.toggleModalConfirm(true, {
              message: `Are these all the documents you would like the specialist to review?`,
              onOk: () => {
                if (bodyParam.clinicalNote.NotifyPaperworkReadinessToStaff === true) {
                  resendPaperworkReadiness(bodyParam.Id);
                }
                bodyParam.clinicalNote.NotifyPaperworkReadinessToStaff = true;
                this.doSaveCR(bodyParam);
              },
              functionCallbackCancel: () => {
                this.doSaveCR(bodyParam);
              },
            });
          } else {
            this.doSaveCR(bodyParam);
          }
        } else {
          this.doSaveCR(bodyParam);
        }
      });
    }
  };

  reOpenTab = (id, name) => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}`);
    });
  };

  doSaveCR(bodyParam) {
    this.setFieldsValue({ loading: true });
    saveCRNote(bodyParam)
      .then(({ status, data, errorList }) => {
        if (status === 'success') {
          // if (
          //   api.isCaseManager() &&
          //   bodyParam.clinicalNote.Id === 0 &&
          //   bodyParam.clinicalNote.CustomerId &&
          //   !bodyParam.clinicalNote.WifroIlars
          // ) {
          //   getDataClinicalNote(data.id).then(
          //     action(resAssessment => {
          //       // Type === 3 -> LawPlaintiff
          //       if (resAssessment.model.Customer.Type === 3) {
          //         return this.setFieldsValue({
          //           isOpenModalDisbursementFunding: true,
          //           modalDisbursementFundingParams: {
          //             id: data.id,
          //             caseNo: resAssessment.model.ClinicalNoteNumber,
          //             caseId: resAssessment.model.Id,
          //             fromService: 'CN',
          //             claimant: `${resAssessment.model.Patient?.Title} ${resAssessment.model.Patient?.FullName}`,
          //             claimantId: resAssessment.model.Patient?.Id,
          //             clientId: resAssessment.model.Customer?.Id,
          //             functionCallbackCancel: () => {
          //               this.showNotification('Success', 'Data has been saved successfully', 'success');
          //               return this.reOpenTab(data.id, resAssessment.model.ClinicalNoteNumber);
          //             },
          //           },
          //         });
          //       } else {
          //         this.showNotification('Success', 'Data has been saved successfully', 'success');
          //         return this.reOpenTab(data.id, data.name);
          //       }
          //     }),
          //   );
          // }
          this.showNotification('Success', 'Data has been saved successfully', 'success');
          return this.reOpenTab(data.id, data.name);
        } else {
          if (status === 'fail') {
            this.showNotification(
              'Error',
              'This record has been modified by another user. Please refresh and update again.',
              'error',
            );
          }
          this.setFieldsValue({ loading: false });
        }
      })
      .catch(() => {
        this.setFieldsValue({ loading: false });
      });
  }
  handleRequestFunding = () => {
    if (!!this.clinicalNoteInfo.Id) {
      return this.setFieldsValue({
        isOpenModalDisbursementFunding: true,
        modalDisbursementFundingParams: {
          id: this.clinicalNoteInfo.Id,
          caseNo: this.clinicalNoteInfo.ClinicalNoteNumber,
          caseId: this.clinicalNoteInfo.Id,
          fromService: 'CN',
          claimant: `${this.clinicalNoteInfo.Patient?.Title} ${this.clinicalNoteInfo.Patient?.FullName}`,
          claimantId: this.clinicalNoteInfo.Patient?.Id,
          clientId: this.clinicalNoteInfo.Customer?.Id,
          isManual: true,
        },
      });
    }
    return this.handleSave();
  };

  updateStatus = statusItem => {
    return updateClinicalRecordStatus(this.clinicalNoteInfo.Id, statusItem.key).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully',
        });
        action(() => {
          this.clinicalNoteInfo.CaseStatus = statusItem.id;
        })();

        getClinicalRecordLogs(this.clinicalNoteInfo.Id).then(logs => {
          action(() => {
            this.clinicalLogs = logs;
          })();
        });
      }
    });
  };
}

export default new ClinicalNoteAddEditStore();
