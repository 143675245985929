import React from 'react';
import cx from 'classnames';
import { Table, Icon, Dropdown as SelectDropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

import moment from 'moment';

import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import MessageTemplateStore from './MessageTemplateStore';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { toJS } from 'mobx';

const columns = [
  { title: '' },
  { title: 'Message Key', sortKey: 'TemplateKey' },
  { title: 'Subject', sortKey: 'Subject' },
  { title: 'Body' },
  { title: 'Sender', sortKey: 'Sender' },
  { title: 'Receivers' },
  { title: 'Bcc', sortKey: 'Bcc' },
  { title: 'Module Type', sortKey: 'ModuleType' },
  { title: 'Case Stage', sortKey: 'CaseStage' },
  { title: 'Logic' },
  { title: 'Status', sortKey: 'Status' },
  { title: 'Modified', sortKey: 'Modified' },
  { title: 'Modified By' },
  { title: 'Created', sortKey: 'Created' },
  { title: 'Created By' },
];

export default class MessageTemplateTable extends React.Component {
  renderOptModule = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataModuleType.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderModuleType = modulesType => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataModuleType.itemList);
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === modulesType).map(i => i.Label)[0];
  };

  handleConfirmDelete = (id, record) => () => {
    MessageTemplateStore.toggleModal(true, {
      modalType: 'confirm',
      message: `Do you want to permanently delete template: ${record.TemplateKey}`,
      onOk: this.handleDeleteItem(id),
    })();
  };
  renderStatusLabels = status => {
    switch (status) {
      case 0:
        return 'Active (in DB)';
      case 1:
        return 'Inactive (in DB)';
      case 2:
        return 'Active (not in DB)';
      case 3:
        return 'Inactive (not in DB)';
      default:
        return 'None';
    }
  };

  renderreceiverAliasLabels = receiverAlias => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataReceiver.itemList);
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.filter(i => i.Id === receiverAlias).map(i => i.Label)[0];
  };

  handleDeleteItem = id => async () => {
    MessageTemplateStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MailTemplate/DeleteRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await MessageTemplateStore.refetch({}, true);
    } finally {
      MessageTemplateStore.setFieldsValue({ loading: false });
    }
  };
  handleEdit = (id, record) => () => {
    MessageTemplateStore.setFieldsValue({
      template: record.Body,
    });
    MessageTemplateStore.toggleModal(true, {
      modalType: 'edit',
      id,
      record,
    })();
  };
  handleDuplicate = record => () => {
    let id = 0;
    MessageTemplateStore.setFieldsValue({
      template: record.Body,
    });
    MessageTemplateStore.toggleModal(true, {
      modalType: 'edit',
      id,
      record: {
        ...record,
        TemplateKey: '',
      },
      type: 'duplicate',
    })();
  };
  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {api.isAdmin() && <Menu.Item onClick={this.handleEdit(id, record)}>Edit</Menu.Item>}
            <Menu.Item onClick={this.handleDuplicate(record)}>Duplicate</Menu.Item>
            <Menu.Item onClick={this.handleConfirmDelete(id, record)}>Delete</Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };
  hnadleViewMore = (subject, template) => () => {
    MessageTemplateStore.toggleModal(true, {
      modalType: 'template',
      template,
      subject,
    })();
  };
  handleViewMoreLogic = (Logic, TemplateKey) => () => {
    MessageTemplateStore.toggleModal(true, {
      modalType: 'logic',
      Logic,
      TemplateKey,
    })();
  };
  handleChangeModule = async (_, { name, value, data }) => {
    const body = {
      ...data,
      ModuleType: value,
    };
    MessageTemplateStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MailTemplate/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Id: name,
          ...body,
        }),
      });
      await MessageTemplateStore.refetch({}, true);
    } finally {
      MessageTemplateStore.setFieldsValue({ loading: false });
    }
  };
  handleChangeCaseStage = async (_, { name, value, data }) => {
    const body = {
      ...data,
      CaseStage: value,
    };
    MessageTemplateStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/MailTemplate/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Id: name,
          ...body,
        }),
      });
      await MessageTemplateStore.refetch({}, true);
    } finally {
      MessageTemplateStore.setFieldsValue({ loading: false });
    }
  };
  renderEmailSenderReceiversBcc = bcc => {
    if (bcc) {
      const bcc_ = bcc.split(';');
      return bcc_.map((i, index) => (
        <div key={index}>
          <span>{i}</span>
        </div>
      ));
    }
    return '';
  };
  tripHtmlFromBody = htmlString => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || '';
  };
  renderCaseStage = () => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataCaseStage.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: 0 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };
  renderCaseStageText = value => {
    const array = [];
    const data = toJS(MessageTemplateStore.dataCaseStage.itemList);
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === value).map(i => i.Label)[0];
  };
  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={record.Status === 1}>
        <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>
        <Table.Cell
          className="Cell-link Cell-nowrap"
          selectable
          onClick={api.isAdmin() ? this.handleEdit(Id, record) : this.hnadleViewMore(record.Subject, record.Body)}
        >
          <Link to="#">{record.TemplateKey}</Link>
        </Table.Cell>
        <Table.Cell className="Cell-Subject">{record.Subject}</Table.Cell>
        <Table.Cell className="Cell-Body">
          <span className="Body-string">{this.tripHtmlFromBody(record.Body)}</span>

          {record.Body && (
            <div className="Cell-link">
              <Link onClick={this.hnadleViewMore(record.Subject, record.Body)} to="#">
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{this.renderEmailSenderReceiversBcc(record.Sender)}</Table.Cell>
        <Table.Cell>{this.renderreceiverAliasLabels(record.ReceiverAlias) || ''}</Table.Cell>
        <Table.Cell>{this.renderEmailSenderReceiversBcc(record.Bcc)}</Table.Cell>
        <Table.Cell>
          <SelectDropdown
            className="Select-module"
            selection
            selectOnNavigation={false}
            closeOnEscape
            name={Id}
            data={record}
            text={this.renderModuleType(record.ModuleType)}
            options={this.renderOptModule()}
            onChange={this.handleChangeModule}
          />
        </Table.Cell>
        <Table.Cell>
          <SelectDropdown
            className="Select-module"
            selection
            selectOnNavigation={false}
            closeOnEscape
            name={Id}
            data={record}
            text={this.renderCaseStageText(record.CaseStage)}
            options={this.renderCaseStage()}
            onChange={this.handleChangeCaseStage}
          />
        </Table.Cell>
        <Table.Cell className="Cell-logic">
          <span className="Body-string">{record.Logic}</span>
          {record.Logic && (
            <div className="Cell-link">
              <Link onClick={this.handleViewMoreLogic(record.Logic, record.TemplateKey)} to="#">
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{this.renderStatusLabels(record.Status)}</Table.Cell>
        <Table.Cell className="Cell-date">
          {record.Modified ? moment(record.Modified).format('DD MMM,YYYY') : ''}
        </Table.Cell>
        <Table.Cell>{record.ModifiedBy}</Table.Cell>
        <Table.Cell className="Cell-date">
          {record.Created ? moment(record.Created).format('DD MMM,YYYY') : ''}
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    MessageTemplateStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = MessageTemplateStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={MessageTemplateStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={MessageTemplateStore.curPage}
          totalPage={MessageTemplateStore.totalPage}
          totalItems={MessageTemplateStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
