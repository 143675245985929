import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Dropdown, Menu } from 'antd';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';

import ConversationModalForm from './ConversationModalForm';

const columns = [
  {},
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

const DATA = Array.from({ length: 5 });

class CaseCorrespondenceForm extends React.Component {
  state = {
    visible: false,
  };

  toggleModal = visible => () => {
    this.setState({ visible });
  };

  renderDropdownMenu = () => (
    <Menu>
      <Menu.Item>Allow CMs to view</Menu.Item>
      <Menu.Item>Allow Specialists to view</Menu.Item>
      <Menu.Item>Delete</Menu.Item>
    </Menu>
  );

  renderTableBody = () => {
    return DATA.map((_, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu()}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell>20/11/2020 0:01</Table.Cell>
        <Table.Cell>Case documents sent to doctor</Table.Cell>
        <Table.Cell>The CM for the case above has submitted and sent paperwork to doctor</Table.Cell>
        <Table.Cell>MAG Staff</Table.Cell>
        <Table.Cell>casemanager8798@gmail.com</Table.Cell>
        <Table.Cell>MAG Staff</Table.Cell>
        <Table.Cell>chinhnv.cirrus@gmail.com</Table.Cell>
        <Table.Cell>casemanager8798</Table.Cell>
        <Table.Cell>Automated Email</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    return (
      <div className="fluid-field">
        <div className="fluid-field" style={{ marginBottom: '1em' }}>
          <Button color="blue" onClick={this.toggleModal(true)}>
            Add New
          </Button>
          <Button color="blue">Export Case Correspondence</Button>
        </div>
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={columns} />
            <Table.Body>
              {DATA.length ? this.renderTableBody() : <TableEmptyMessage colSpan={columns.length} />}
            </Table.Body>
            <TableFooterPagination
              colSpan={columns.length}
              totalItems={1}
              currentPage={1}
              totalPage={1}
              onPageClick={() => {}}
            />
          </Table>
        </div>
        <ConversationModalForm visible={this.state.visible} onCancel={this.toggleModal(false)} />
      </div>
    );
  }
}

export default CaseCorrespondenceForm;
