import './ContentItem.scss';

import React from 'react';

import routes from '../../dashboard-routes';

// Implement shouldComponentUpdate with minimal props compare
// This helps the component will almost never get updated from Dashboard
class ContentItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.path !== this.props.path;
  }

  render() {
    const { path } = this.props;
    const { Component } = routes[path];
    return <Component path={path} />;
  }
}

export default ContentItem;
