import { Modal } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Table, Button } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader } from '../../shared/table';
import store from './store';

@observer
class ModalHistory extends React.Component {
  columns = [
    { title: '#' },
    { title: 'Previous PIC' },
    { title: 'Current PIC' },
    { title: 'Assigned By' },
    { title: 'Assigned Date' },
  ];

  @action handleClose = () => {
    store.isOpenModalHistory = false;
  };

  renderTableBody = () => {
    return store.assignHistory.map((assignment, idx) => (
      <Table.Row key={assignment.Id}>
        <Table.Cell>{idx + 1}</Table.Cell>
        <Table.Cell>{assignment.OldAssigneeFullName}</Table.Cell>
        <Table.Cell>{assignment.NewAssigneeFullName}</Table.Cell>
        <Table.Cell>{assignment.CreatedUserFullName}</Table.Cell>
        <Table.Cell>{assignment.CreatedDate}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { isOpenModalHistory } = store;

    return (
      <Modal
        title={'PIC Assignment History'}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={900}
        visible={isOpenModalHistory}
        footer={
          <Button className="negative" onClick={this.handleClose}>
            Close
          </Button>
        }
      >
        <div className="Modal-History">
          <Table sortable striped celled>
            <TableHeader columns={this.columns} />
            <Table.Body>
              {store.assignHistory.length ? (
                this.renderTableBody()
              ) : (
                <TableEmptyMessage colSpan={this.columns.length} />
              )}
            </Table.Body>
          </Table>
        </div>
      </Modal>
    );
  }
}

export default ModalHistory;
