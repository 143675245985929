import { Form, Select, Radio, Input, Checkbox, DatePicker, Dropdown, Menu } from 'antd';
import { Popup, Icon, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import React from 'react';

import store from './store';
import * as api from '@stores/api';
import moment from 'moment';
import { renderDate } from '../Shared';
import { CONSTANTS } from './type';

@observer
class CaseCancellationForm extends React.Component {
  render() {
    const { fileReviewInfo, setFieldValue, toggleCheckbox, handleResendNotifyCancellation } = store;

    return (
      <Form name="case-cancellation">
        {api.isAdminOrMagStaffOrAccounting() && !!fileReviewInfo.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 4
                <button className="field-actions" onClick={() => store.openModalHistory('PICcancelled')}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={fileReviewInfo.PICcancelled || undefined}
              onChange={setFieldValue('PICcancelled')}
            >
              {store.staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div className="fluid-field" />
        <Form.Item label="Case Status">
          <Radio.Group
            //disabled={fileReviewInfo.IsBilled || (!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager() && fileReviewInfo.CaseFlow === 1)}
            value={fileReviewInfo.CaseStatus}
            onChange={setFieldValue('CaseStatus')}
          >
            <Radio
              disabled={fileReviewInfo.isLockedCancelledNoFee || fileReviewInfo.isLockedCancelledWithFee}
              value={CONSTANTS.CaseStatus_None}
            >
              None
            </Radio>
            <Radio disabled={fileReviewInfo.isLockedCancelledNoFee} value={CONSTANTS.CaseStatus_CancelledNoCharge}>
              Cancelled No Fee
            </Radio>
            <Radio disabled={fileReviewInfo.isLockedCancelledWithFee} value={CONSTANTS.CaseStatus_CancelledWithFee}>
              Cancelled With Fee
            </Radio>
          </Radio.Group>
        </Form.Item>
        {!!fileReviewInfo.CaseStatus && (
          <>
            <Form.Item label="Date of Cancellation">
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={moment(fileReviewInfo.CancelledDate)}
                onChange={setFieldValue('CancelledDate')}
                disabled={fileReviewInfo.isLocked}
              />
            </Form.Item>
            <Form.Item label="Reasons">
              <Radio.Group
                value={fileReviewInfo.ReasonCancellation}
                disabled={fileReviewInfo.isLocked}
                onChange={setFieldValue('ReasonCancellation')}
              >
                <Radio value={1}>The request of the Case Manager</Radio>
                <br />
                <Radio value={2}>Specialist being unable to undertake assessment</Radio>
              </Radio.Group>
            </Form.Item>
            <br />
            <Form.Item label="Send Cancellation Confirmation to" className="cancellation-label"></Form.Item>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={fileReviewInfo.NotifyCancellationToSolictor}
                onChange={toggleCheckbox('NotifyCancellationToSolictor')}
                disabled={
                  api.isCaseManager()
                    ? fileReviewInfo.SentDateNotifyCancellationToSolictor || false
                    : fileReviewInfo.isLocked ||
                      fileReviewInfo.SentDateNotifyCancellationToSolictor ||
                      !fileReviewInfo.BookingSolicitorId
                }
              >
                Booking Solicitor{' '}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to CMs (Message-70)"
                    position="top center"
                  />
                )}
              </Checkbox>
              {fileReviewInfo.SentDateNotifyCancellationToSolictor && (
                <span style={{ color: '#107fc9', marginLeft: '2px' }}>
                  {fileReviewInfo.SentDateNotifyCancellationToSolictor
                    ? renderDate(fileReviewInfo.SentDateNotifyCancellationToSolictor, false, true)
                    : ''}
                  {fileReviewInfo.SentByNotifyCancellationToSolictor
                    ? ` - By ${
                        store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyCancellationToSolictor)
                          ? store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyCancellationToSolictor).FullName
                          : fileReviewInfo.SentByNotifyCancellationToSolictor
                      }`
                    : ''}
                </span>
              )}
            </div>
            {console.log(fileReviewInfo.isLocked)}
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={fileReviewInfo.NotifyCancellationToCM}
                onChange={toggleCheckbox('NotifyCancellationToCM')}
                disabled={
                  api.isCaseManager()
                    ? fileReviewInfo.SentDateNotifyBookingCMCancellation || false
                    : fileReviewInfo.isLocked || !!fileReviewInfo.SentDateNotifyBookingCMCancellation
                }
              >
                Booking CM
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to CMs (Message-70)"
                    position="top center"
                  />
                )}
                {fileReviewInfo.SentDateNotifyBookingCMCancellation && (
                  <span style={{ color: '#107fc9', marginLeft: '50px' }}>
                    {fileReviewInfo.SentDateNotifyBookingCMCancellation
                      ? renderDate(fileReviewInfo.SentDateNotifyBookingCMCancellation, false, true)
                      : ''}
                    {fileReviewInfo.SentByNotifyBookingCMCancellation
                      ? ` - By ${
                          store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyBookingCMCancellation)
                            ? store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyBookingCMCancellation).FullName
                            : fileReviewInfo.SentByNotifyBookingCMCancellation
                        }`
                      : ''}
                  </span>
                )}
              </Checkbox>
            </div>
            <div className="fluid-field checkbox-field">
              <Checkbox
                checked={fileReviewInfo.NotifyCancellationToDoctor}
                onChange={toggleCheckbox('NotifyCancellationToDoctor')}
                disabled={fileReviewInfo.isLocked || !!fileReviewInfo.SentDateNotifyDoctorCancellation}
              >
                Specialist{' '}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case cancellation notice will be sent to Specialist (MAIL-82)"
                    position="top center"
                  />
                )}
                {fileReviewInfo.SentDateNotifyDoctorCancellation && (
                  <span style={{ color: '#107fc9', marginLeft: '66px' }}>
                    {fileReviewInfo.SentDateNotifyDoctorCancellation
                      ? renderDate(fileReviewInfo.SentDateNotifyDoctorCancellation, false, true)
                      : ''}
                    {fileReviewInfo.SentByNotifyDoctorCancellation
                      ? ` - By ${
                          store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyDoctorCancellation)
                            ? store.staffs.find(i => i.Id === fileReviewInfo.SentByNotifyDoctorCancellation).FullName
                            : fileReviewInfo.SentByNotifyDoctorCancellation
                        }`
                      : ''}
                  </span>
                )}
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item className="fluid-field">
                <Dropdown
                  disabled={
                    !fileReviewInfo.SentDateNotifyCancellationToSolictor &&
                    !fileReviewInfo.SentDateNotifyBookingCMCancellation &&
                    !fileReviewInfo.SentDateNotifyDoctorCancellation
                  }
                  overlay={
                    <Menu>
                      <Menu.Item
                        disabled={
                          !fileReviewInfo.SentDateNotifyCancellationToSolictor || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(0)}
                      >
                        Resend Cancellation Confirmation to Booking Solicitor{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !fileReviewInfo.SentDateNotifyBookingCMCancellation || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(1)}
                      >
                        Resend Cancellation Confirmation to Booking CM{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !fileReviewInfo.SentDateNotifyDoctorCancellation || !api.isAdminOrMagStaffOrAccounting()
                        }
                        onClick={() => handleResendNotifyCancellation(2)}
                      >
                        Resend Cancellation Confirmation to Specialist{' '}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button className="blue">
                    Resend Cancellation Confirmation <Icon type="down" />
                  </Button>
                </Dropdown>
              </Form.Item>
            )}
          </>
        )}
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item label="Comments" className="fluid-field">
            <Input.TextArea
              rows={3}
              disabled={fileReviewInfo.isLocked}
              value={fileReviewInfo.CancelledComments}
              onChange={setFieldValue('CancelledComments')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default CaseCancellationForm;
