import { observer } from 'mobx-react';
import React from 'react';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import Store from './Store';
import { toJS } from 'mobx';

const businessTypes = [
  { text: `All`, value: 0 },
  { text: `Law Plaintiff`, value: 1 },
  { text: `Law Defendant`, value: 2 },
  { text: `Insurer/Self Insurer`, value: 3 },
];

@observer
class Toolbar extends React.Component {
  renderState = () => {
    const array = [];
    const data = toJS(Store.Cities);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ CityName: `All`, CityId: 0 });
    return array.map(opt => ({
      text: opt.CityName,
      value: opt.CityId,
    }));
  };

  renderStateText = value => {
    const array = [];
    const data = toJS(Store.Cities);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ CityName: `All`, CityId: 0 });
    return array.filter(i => i.CityId === value).map(opt => opt.CityName)[0];
  };

  renderCategory = () => {
    const array = [];
    const data = toJS(Store.Categories);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Title: `All`, Id: 0 });
    return array.map(opt => ({
      text: opt.Title,
      value: opt.Id,
    }));
  };

  renderCategoryText = value => {
    const array = [];
    const data = toJS(Store.Categories);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Title: `All`, Id: 0 });
    return array.filter(i => i.Id === value).map(opt => opt.Title)[0];
  };

  handleReset = () => {
    Store.resetStore();
    Store.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    Store.refetch({ curPage: 1 }, true);
  };

  handleSearchChange = (_, { value }) => {
    Store.setFieldsValue({ keyword: value });
  };

  handleRefresh = () => {
    Store.refetch({ data: null }, true);
  };

  handleChangeDropdown = (_, { name, value }) => {
    Store.refetch({ [name]: value }, true);
  };

  openCaseCreate = () => () => {
    return Store.toggleModal(true, {
      modalType: 'addOrEdit',
      id: 0,
      record: null,
    })();
  };

  renderFilterBusinessType = () => {
    return businessTypes.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterBusinessTypeText = value => {
    let labels = businessTypes.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  render() {
    const { keyword, loading, businessType, state, category } = Store;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`Category`}
            name="category"
            value={this.renderCategoryText(category)}
            options={this.renderCategory()}
            onChange={this.handleChangeDropdown}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`Business Type`}
            name="businessType"
            value={this.renderFilterBusinessTypeText(businessType)}
            options={this.renderFilterBusinessType()}
            onChange={this.handleChangeDropdown}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`State`}
            name="state"
            value={this.renderStateText(state)}
            options={this.renderState()}
            onChange={this.handleChangeDropdown}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            className="blue"
            disabled={loading}
            onClick={this.handleRefresh}
            style={{ marginLeft: '5px' }}
          >
            Refresh
          </TableToolbar.HighLightButton>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openCaseCreate()}
              style={{ marginLeft: '5px' }}
            >
              Add New
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Left>
        <TableToolbar.Right />
      </TableToolbar.Container>
    );
  }
}

export default Toolbar;
