import { action } from 'mobx';
import React from 'react';
import { Spin } from 'antd';

import DoctorDetails from './doctor-detail';
import router from '../../../stores/router';
import DoctorDetailsStore from './doctor-detail/DoctorDetailsStore';
import './index.scss';

class AddEditDoctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }
  @action componentDidMount() {
    const { id, action, step = 0 } = this.getRouteParams();
    if (id) {
      this.setState({ user: id, loading: false, action: action, step: step });
    } else {
      this.setState({ user: 0, loading: false });
    }
    DoctorDetailsStore.userId = router.location.state?.userId || null;
  }
  componentWillUnmount() {
    this.setState({ loading: true });
  }
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const state = router.location.state;
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return {
      ...params,
      ...state,
    };
  };

  render() {
    const { loading, user, action, step } = this.state;
    return (
      <div className={`Form-${loading ? 'Loading-DR' : 'Container-DR'}`}>
        {loading ? <Spin size="large" /> : <DoctorDetails doctorId={user} action={action} step={step} />}
      </div>
    );
  }
}
export default AddEditDoctor;
