import React from 'react';
import { Form, Checkbox, InputNumber, DatePicker, Select } from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react';

import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import { renderDate } from '../Shared';
import { Icon, Popup } from 'semantic-ui-react';

@observer
class FreeOffers extends React.Component {
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };
  render() {
    const { CMInfo, staffList } = AddEditStore;
    if (CMInfo.IsTypist) return null;
    return (
      <Form name="free-offers">
        <div className="fluid-field checkbox-field">
          <Checkbox checked={CMInfo.IsFreeBooking} onChange={AddEditStore.handleChecked('IsFreeBooking')}>
            Free CR Bookings
          </Checkbox>
          <Popup
            trigger={<Icon name="info circle" style={{ marginLeft: '-6px' }} />}
            content="Offer a number of bookings to be free of retrieval fees within the effective period"
            position="top center"
          />
        </div>
        <Form.Item label="Number of bookings">
          <InputNumber
            size="large"
            value={CMInfo.NumberOfFreeBookings}
            onChange={AddEditStore.handleFieldChange('NumberOfFreeBookings')}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              Approved by
              {!!CMInfo.DateCheckedFreeBooking && (
                <span style={{ marginLeft: '5px' }}>
                  ({renderDate(CMInfo.DateCheckedFreeBooking, false, true)} - By {CMInfo.CreateFreeBookingByName})
                </span>
              )}
            </>
          }
          validateStatus={AddEditStore.errors['FreeBookingApprovedBy'] ? 'error' : null}
          help={AddEditStore.errors['FreeBookingApprovedBy']}
          required={CMInfo.IsFreeBooking}
        >
          <Select
            size="large"
            value={CMInfo.FreeBookingApprovedBy}
            onChange={AddEditStore.handleFieldChange('FreeBookingApprovedBy')}
          >
            {staffList &&
              staffList.itemList.map(i => (
                <Select.Option key={i.Id} value={i.Id}>
                  {i.FullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Effective from">
          <DatePicker
            size="large"
            value={this.convertDate(CMInfo.FreeBookingFrom)}
            onChange={AddEditStore.handleFieldChange('FreeBookingFrom')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item label="Effective to">
          <DatePicker
            size="large"
            value={this.convertDate(CMInfo.FreeBookingTo)}
            onChange={AddEditStore.handleFieldChange('FreeBookingTo')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </Form>
    );
  }
}

export default FreeOffers;
