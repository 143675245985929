import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Table, TableBody } from 'semantic-ui-react';
import * as api from '../../../stores/api';
import { Link } from 'react-router-dom';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import ServiceFeeStore from './AMAServiceFeeStore';
import { Dropdown, Menu, notification } from 'antd';
import { deleteAMA } from './Service';

const columns = [
  { title: '' },
  { title: 'AMA Code', sortKey: 'AMACode' },
  { title: 'MBS Code' },
  { title: 'Service Type' },
  { title: 'Type of Procedures' },
  // { title: 'Description' },
  { title: 'Clinical Indication' },
  { title: 'Additional Notes' },
  { title: 'Scheduled Fee (excl GST)' },
  { title: 'Exclusion Codes' },
  { title: 'Status' },
  { title: 'Published Year' },
];

@observer
class ServiceFeeTable extends React.Component {
  openModal = options => {
    return ServiceFeeStore.toggleModal(true, options);
  };

  openModalEdit = (id, record) => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'createServiceFee',
      id,
      record,
    });
  };

  renderExclusionCode = record => {
    if (record.ListExcludedAMACode) {
      return record.ListExcludedAMACode.map(ex => {
        return (
          <label key={'ex-' + ex.Id} className="ui label" style={{ margin: '2px' }}>
            {' '}
            {ex.ExcludedAMACode}
          </label>
        );
      });
    }
    return null;
  };

  renderExclusionCodeRanges = record => {
    if (record.ExcusionCodeRanges && record.ExcusionCodeRanges.length > 0) {
      return record.ExcusionCodeRanges.map(ex => {
        return (
          <label key={'ex-range' + ex.Id} className="ui label" style={{ margin: '2px' }}>
            {' '}
            {ex.ExclusionRange}
          </label>
        );
      });
    }
    return null;
  };

  handleEdit = (id, record) => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'createServiceFee',
      id,
      record,
    })();
  };

  handleDeleteItem = id => {
    deleteAMA(id).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
          duration: 3,
        });
        ServiceFeeStore.refetch({ curPage: 1 });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      }
    });
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: `Do you want to delete this code?`,
              onOk: () => this.handleDeleteItem(id),
            })}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={record.Status !== 0}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell selectable className={cx({ 'Cell-nowrap Cell-link': record.Status === 0 })}>
          {api.isAdmin() ? (
            <Link to="#" onClick={this.openModalEdit(Id, record)} style={{ color: `#9f3a38` }}>
              {record.AMACode}
            </Link>
          ) : (
            record.AMACode
          )}
        </Table.Cell>
        <Table.Cell>{record.MBSCode}</Table.Cell>
        <Table.Cell>{record.ServiceType}</Table.Cell>
        <Table.Cell>{record.TypeProcedures}</Table.Cell>
        {/* <Table.Cell>{record.Description}</Table.Cell> */}
        <Table.Cell>{record.ClinicalIndication}</Table.Cell>
        <Table.Cell>{record.AdditionalNotes}</Table.Cell>
        <Table.Cell>${record.Fee === null ? 0 : record.Fee}</Table.Cell>
        <Table.Cell>
          {this.renderExclusionCode(record)}
          {record.ExcusionCodeRanges && record.ExcusionCodeRanges.length > 0
            ? this.renderExclusionCodeRanges(record)
            : ''}
        </Table.Cell>
        <Table.Cell>{record.StatusText}</Table.Cell>
        <Table.Cell>{record.PublishedYear}</Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    ServiceFeeStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = ServiceFeeStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;
    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={column} sortColumn={sortColumn} sortDirection={sortDirection} onSort={ServiceFeeStore.handleSort} />
        <TableBody>{this.renderTableBody(data, colSpan)}</TableBody>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ServiceFeeStore.curPage}
          totalPage={ServiceFeeStore.totalPage}
          totalItems={ServiceFeeStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ServiceFeeTable;
