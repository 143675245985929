import './index.scss';
import cx from 'classnames';
import { observer } from 'mobx-react';
// import moment from 'moment';
import React from 'react';
import { Accordion, Checkbox, Divider, Icon, Input, Label, Button } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import router from '../../../stores/router';
import TableToolbar from '../../shared/tableToolbar';
import DoctorCardList from './DoctorCardList';
import TablesIME from './TablesIME';
import TablesSupp from './TablesSupp';
import TableCR from './TablesCR';
import TablesFRs from './TablesFR';
import { action, observable } from 'mobx';
import imeStore from './../../doctor-routes/IMEReports/IMEStore';
import frStore from './../../doctor-routes/FileReview/fileReviewStore';
import suppStore from './../../doctor-routes/SupplementaryReports/SuppStore';
import mnStore from './../../admin-routes/MedNeg/MedNegStore';
import crStore from './clinicalNoteStore';
import uploadStore from './UploadFile/UploadStore';
import UniversalSearchModal from './Modal';

import TablesMN from './TablesMN';
import { getRouteParams } from '@utils/functions';
import eventBus, { EVENT_TYPE } from '@utils/eventBus';

@observer
class UniversalSearch extends React.Component {
  @observable accordionItems = [
    // {
    //   title: 'Doctors',
    //   data: [],
    //   Component: DoctorCardList,
    // },
    {
      title: 'IME Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      Component: TablesIME,
      handlePageClick: this.handlePageIMEClick,
    },
    {
      title: 'File Reviews',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      Component: TablesFRs,
      handlePageClick: this.handlePageFRClick,
    },
    {
      title: 'Supplementary Reports',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      Component: TablesSupp,
      handlePageClick: this.handlePageSUPPClick,
    },
    {
      title: 'Clinical Records',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      Component: TableCR,
      handlePageClick: this.handlePageCRClick,
    },
    {
      title: 'Med Negs',
      data: [],
      curPage: 1,
      totalPage: 0,
      sumItem: 0,
      Component: TablesMN,
      handlePageClick: this.handlePageMNClick,
    },
  ];
  @observable searchIME = true;
  @observable searchFr = true;
  @observable searchSupp = true;
  @observable searchCr = true;
  @observable searchMN = true;
  @observable loading = false;
  @observable searchAttachment = true;
  @observable noCaseFound = false;

  state = {
    search: '',
    active: [0, 1, 2, 3, 4],
    recentSearch: JSON.parse(localStorage.getItem('MAG/RECENT_SEARCH')) || [],
  };

  @action imeData = curPage => {
    if (curPage) {
      imeStore.currentPage = curPage;
    }
    imeStore.includeAttachment = this.searchAttachment;
    imeStore.keyword = this.state.search;
    imeStore.refetch({ currentPage: imeStore.currentPage }).then(
      action(() => {
        if (imeStore && imeStore.data && imeStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'IME Reports');
          this.accordionItems[idx].data = imeStore.data.itemList;
          this.accordionItems[idx].curPage = imeStore.data.curPage;
          if (imeStore.data.sumItem > 0) {
            this.accordionItems[idx].totalPage = imeStore.data.sumPage || imeStore.data.totalPage;
            this.accordionItems[idx].sumItem = imeStore.data.totalRow || imeStore.data.sumItem;
            this.noCaseFound = false;
          }
          this.loading = imeStore.loading;
        }
      }),
    );
  };

  @action crData = curPage => {
    if (curPage) {
      crStore.currentPage = curPage;
    }
    crStore.keyword = this.state.search;
    crStore.includeAttachment = this.searchAttachment;
    crStore.refetch({ currentPage: crStore.currentPage }).then(
      action(() => {
        if (crStore && crStore.data && crStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Clinical Records');
          this.accordionItems[idx].data = crStore.data.itemList;
          this.accordionItems[idx].curPage = crStore.data.curPage;
          if (crStore.data.sumItem > 0) {
            this.accordionItems[idx].totalPage = crStore.data.sumPage;
            this.accordionItems[idx].sumItem = crStore.data.sumItem;
            this.noCaseFound = false;
          }
          this.loading = crStore.loading;
        }
      }),
    );
  };

  @action suppData = curPage => {
    if (curPage) {
      suppStore.curPage = curPage;
    }
    suppStore.includeAttachment = this.searchAttachment;
    suppStore.onSearchChange(this.state.search).then(
      action(() => {
        if (suppStore && suppStore.data && suppStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Supplementary Reports');
          this.accordionItems[idx].data = suppStore.data.itemList;
          this.accordionItems[idx].curPage = suppStore.data.curPage;
          if (suppStore.data.sumItem > 0) {
            this.accordionItems[idx].totalPage = suppStore.data.sumPage;
            this.accordionItems[idx].sumItem = suppStore.data.sumItem;
            this.noCaseFound = false;
          }
          this.loading = suppStore.loading;
        }
      }),
    );
  };

  @action frData = curPage => {
    if (curPage) {
      frStore.curPage = curPage;
    }
    frStore.includeAttachment = this.searchAttachment;
    frStore.onSearchChange(this.state.search).then(
      action(() => {
        if (frStore && frStore.data && frStore.data.itemList) {
          var idx = this.accordionItems.findIndex(i => i.title === 'File Reviews');
          this.accordionItems[idx].data = frStore.data.itemList;
          this.accordionItems[idx].curPage = frStore.data.curPage;
          if (frStore.data.sumItem > 0) {
            this.accordionItems[idx].totalPage = frStore.data.sumPage;
            this.accordionItems[idx].sumItem = frStore.data.sumItem;
            this.noCaseFound = false;
          }
          this.loading = frStore.loading;
        }
      }),
    );
  };

  @action mnData = curPage => {
    if (curPage) {
      mnStore.curPage = curPage;
    }
    mnStore.includeAttachment = this.searchAttachment;
    mnStore.searchClaimantOnly = false;

    mnStore.onSearchChange(this.state.search).then(
      action(() => {
        if (mnStore && mnStore.data) {
          var idx = this.accordionItems.findIndex(i => i.title === 'Med Negs');
          console.log('idx', mnStore.data);
          this.accordionItems[idx].data = mnStore.data.itemList ?? [];
          this.accordionItems[idx].curPage = mnStore.data.curPage;
          if (mnStore.data.sumItem > 0) {
            this.accordionItems[idx].totalPage = mnStore.data.sumPage;
            this.accordionItems[idx].sumItem = mnStore.data.sumItem;
            this.noCaseFound = false;
          }
          this.loading = mnStore.loading;
        }
      }),
    );
  };

  @action handlePageFRClick = page => {
    this.loading = true;
    this.frData(page);
  };
  @action handlePageSUPPClick = page => {
    this.loading = true;
    this.suppData(page);
  };
  @action handlePageIMEClick = page => {
    this.loading = true;
    this.imeData(page);
  };

  @action handlePageCRClick = page => {
    this.loading = true;
    this.crData(page);
  };

  @action handlePageMNClick = page => {
    this.loading = true;
    this.mnData(page);
  };
  @action onSearch = () => {
    this.loading = true;
    this.noCaseFound = true;
    if (this.searchIME) {
      this.imeData();
    } else {
      this.clearData('IME Reports');
    }
    if (this.searchSupp) {
      this.suppData();
    } else {
      this.clearData('Supplementary Reports');
    }
    if (this.searchFr) {
      this.frData();
    } else {
      this.clearData('File Reviews');
    }
    if (this.searchCr && !api.isDoctor() && !api.isTypist()) {
      this.crData();
    } else {
      this.clearData('Clinical Records');
    }
    if (this.searchMN && api.isAdminOrMagStaffOrAccounting()) {
      this.mnData();
    } else {
      this.clearData('Med Negs');
    }
  };

  @action clearData = type => {
    this.accordionItems[this.accordionItems.findIndex(i => i.title === type)].data = [];
  };

  @action onClear = () => {
    this.setState({
      search: '',
    });
    this.noCaseFound = false;
    this.searchIME = true;
    this.searchFr = true;
    this.searchSupp = true;
    this.searchCr = true;
    this.searchMN = true;
    this.searchAttachment = true;
    imeStore.filter = 'All';
    frStore.filter = 'All';
    suppStore.filter = 'All';
    crStore.filter = 'All';
    mnStore.filter = 'All';
    imeStore.filterNextStatus = 'All';
    imeStore.filterTypes = null;
    frStore.filterTypes = null;
    suppStore.filterTypes = null;
    crStore.filterTypes = null;
    imeStore.toAppointmentDate = '';
    this.clearData('IME Reports');
    this.clearData('Supplementary Reports');
    this.clearData('File Reviews');
    this.clearData('Clinical Records');
    this.clearData('Med Negs');
  };

  handleSearchChange = ({ target }) => {
    this.setState({ search: target.value });
  };

  handleExportSearch = () => {
    if (this.state.search) {
      window.open(
        '/Report/ExportAllServices?keyword=' +
          this.state.search +
          '&ime=' +
          this.searchIME +
          '&fr=' +
          this.searchFr +
          '&supp=' +
          this.searchSupp +
          '&cr=' +
          this.searchCr +
          '&mn=' +
          this.searchMN,
        '_blank',
      );
    }
  };

  handleExportBillServices = () => {
    window.open('/ExportImport/ExportBillService?keyword=' + this.state.search, '_blank');
  };

  handleSubmit = event => {
    event && event.preventDefault();
    this.onSearch();
    this.handleRecentSearchChange({ newSearch: this.state.search });
  };

  handleRefetchTbl = () => {
    this.onSearch();
  };

  recentSearchHandle = search => () => {
    this.setState({ search }, () => this.onSearch());
  };

  renderRecentSearchList = recentSearch => {
    return recentSearch.map((search, idx) => (
      <Label key={idx}>
        <span onClick={this.recentSearchHandle(search)} className="clickable">
          {search}
        </span>
        <Icon name="delete" onClick={() => this.handleRecentSearchChange({ idx })} />
      </Label>
    ));
  };

  handleRecentSearchChange = ({ idx, newSearch }) => {
    const { recentSearch } = this.state;
    let newRecentSearch;

    if (!!newSearch) {
      newRecentSearch = [...(recentSearch.length < 5 ? recentSearch : recentSearch.slice(1)), newSearch];
    } else {
      newRecentSearch = recentSearch.filter((_, index) => index !== idx);
    }

    this.setState({ recentSearch: newRecentSearch }, () => {
      localStorage.setItem('MAG/RECENT_SEARCH', JSON.stringify(newRecentSearch));
    });
  };

  isActive = index => {
    return this.state.active.includes(index);
    //return false;
  };

  handleToggleActive = index => () => {
    this.setState(({ active, ...prev }) => ({
      ...prev,
      active: this.isActive(index) ? active.filter(idx => idx !== index) : [...active, index],
    }));
  };

  onCaseSearchUpdate = module => {
    if (module === 'IME') {
      this.imeData();
    }
    if (module === 'FR') {
      this.frData();
    }
    if (module === 'SUPP') {
      this.suppData();
    }
    if (module === 'CR') {
      this.crData();
    }
    if (module === 'MN') {
      this.mnData();
    }
  };
  @action componentDidMount() {
    eventBus.on(EVENT_TYPE.CASE_SEARCH_UPDATE, this.onCaseSearchUpdate);

    const { service, search = '' } = getRouteParams(router.location.search, router.location.state);
    if (!service) {
      return;
    }

    imeStore.filter = 'All';
    frStore.filter = 'All';
    suppStore.filter = 'All';
    crStore.filter = 'All';
    mnStore.filter = 'All';
    imeStore.filterNextStatus = 'All';
    imeStore.filterTypes = null;
    frStore.filterTypes = null;
    suppStore.filterTypes = null;
    crStore.filterTypes = null;
    imeStore.toAppointmentDate = '';
    this.searchIME = false;
    this.searchFr = false;
    this.searchSupp = false;
    this.searchCr = false;
    this.searchMN = false;
    this.searchAttachment = false;

    if (api.isAdminOrMagStaffOrAccounting()) {
      switch (service) {
        case 'ChangeABooking':
        case 'SearchAll':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          this.searchCr = true;
          this.searchMN = true;
          break;
        case 'UploadPaperwork':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.APPOINTMENT_CONFIRMED);
          frStore.filter = api.GetFRStatusText(api.frStatus.REPORT_REVIEW_REQUESTED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.REPORT_REVIEW_REQUESTED);
          break;
        case 'UpdateAttendance':
          this.searchIME = true;
          imeStore.filterTypes = [
            api.GetIMEStatusText(api.imeStatus.PAPERWORK_RECEIVED),
            api.GetIMEStatusText(api.imeStatus.APPOINTMENT_CONFIRMED),
          ];
          imeStore.sortColumnKey = 'DateFrom';
          imeStore.sortDirection = 'ASC';
          break;
        case 'UploadDictations':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.ATTENDED);
          frStore.filter = api.GetFRStatusText(api.frStatus.REQUEST_SENT_PROVIDER);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.REQUEST_SENT_TO_PROVIDER);
          break;
        case 'UploadReportDrafts':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.DICTATION_RECEIVED);
          frStore.filter = api.GetFRStatusText(api.frStatus.DICTATION_RECEIVED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.DICTATION_RECEIVED);
          break;
        case 'UploadReports':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          // this.searchCr = true;
          imeStore.filterTypes = [api.GetIMEStatusText(api.imeStatus.REPORT_RETURNED_FROM_DOCTOR)];
          frStore.filter = api.GetFRStatusText(api.frStatus.REPORT_RETURNED_FROM_DOCTOR);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.REPORT_RETURNED_FROMDOCTOR);
          // crStore.filterTypes = [
          //   api.GetCRStatusText(api.crStatus.CN_REQUEST_DOCS_SENT_TO_PROVIDER),
          //   api.GetCRStatusText(api.crStatus.PREPAYMENT_PAID),
          // ];
          break;
        case 'BillServices':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          this.searchCr = true;
          imeStore.filterTypes = [
            api.GetIMEStatusText(api.imeStatus.NOT_ATTEND),
            api.GetIMEStatusText(api.imeStatus.REPORT_COMPLETED),
            api.GetIMEStatusText(api.imeStatus.IME_CANCELLED_WITH_FEE),
            api.GetIMEStatusText(api.imeStatus.CANCELLED_SPECIALIST_TBP),
          ];
          frStore.filterTypes = [
            api.GetFRStatusText(api.frStatus.REPORT_COMPLETED),
            api.GetFRStatusText(api.frStatus.CANCELLED_WITH_FEE),
          ];
          suppStore.filterTypes = [
            api.GetSUPPStatusText(api.suppStatus.REPORT_COMPLETED),
            api.GetSUPPStatusText(api.suppStatus.CANCELLED_WITH_FEE),
          ];
          crStore.filter = api.GetCRStatusText(api.crStatus.CN_FILES_RECEIVED);
          break;
        default:
          return;
      }
    } else if (api.isTypist()) {
      switch (service) {
        case 'DownloadDictations':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.DICTATION_RECEIVED);
          frStore.filter = api.GetFRStatusText(api.frStatus.DICTATION_RECEIVED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.DICTATION_RECEIVED);
          break;
        case 'UploadTypedReports':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.DICTATION_RECEIVED);
          frStore.filter = api.GetFRStatusText(api.frStatus.DICTATION_RECEIVED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.DICTATION_RECEIVED);
          break;
        case 'SearchAll':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          break;
        default:
          return;
      }
    } else if (api.isCaseManager()) {
      switch (service) {
        case 'ChangeABooking':
        case 'SearchAll':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          this.searchCr = true;
          this.searchMN = true;
          break;
        case 'UploadPaperwork':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.APPOINTMENT_CONFIRMED);
          frStore.filter = api.GetFRStatusText(api.frStatus.REPORT_REVIEW_REQUESTED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.REPORT_REVIEW_REQUESTED);
          break;
        case 'ViewCompletedReports':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filter = api.GetIMEStatusText(api.imeStatus.REPORT_COMPLETED);
          frStore.filter = api.GetFRStatusText(api.frStatus.REPORT_COMPLETED);
          suppStore.filter = api.GetSUPPStatusText(api.suppStatus.REPORT_COMPLETED);
          break;
        default:
          return;
      }
    } else if (api.isDoctor()) {
      switch (service) {
        case 'ViewUpcomingCase':
          this.searchIME = true;
          imeStore.filterNextStatus = 'ViewUpcomingCase';
          imeStore.sortColumnKey = 'AppointmentDate';
          imeStore.sortDirection = 'ASC';
          break;
        case 'UpdateAttendance':
          this.searchIME = true;
          imeStore.filterNextStatus = 'Update Attendances';
          imeStore.sortColumnKey = 'AppointmentDate';
          imeStore.sortDirection = 'ASC';
          break;
        case 'UploadDictations':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filterNextStatus = 'Send Dictations';
          frStore.filterNextStatus = 'Send Dictations';
          suppStore.filterNextStatus = 'Send Dictations';
          break;
        case 'UploadCorrectedReport':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          imeStore.filterNextStatus = 'Send Reviewed/Completed Reports';
          frStore.filterNextStatus = 'Send Reviewed/Completed Reports';
          suppStore.filterNextStatus = 'Send Reviewed/Completed Reports';
          break;
        case 'SearchAll':
          this.searchIME = true;
          this.searchFr = true;
          this.searchSupp = true;
          break;
        default:
          return;
      }
    }

    this.setState({ service, search }, () => this.handleSubmit());
  }

  componentWillUnmount() {
    localStorage.removeItem('FindCases');
    eventBus.off(EVENT_TYPE.CASE_SEARCH_UPDATE, this.onCaseSearchUpdate);
  }

  render() {
    const { search, recentSearch, service } = this.state;
    const loading = this.loading;
    const { open } = crStore;

    return (
      <div>
        {!api.isCMInvoiceCheck () && <React.Fragment>
          <div className={cx('Universal-search-page mb-1', { loading })}>
            {(open || uploadStore.open) && <UniversalSearchModal refetchTable={this.handleRefetchTbl} />}
            <div className="search-title">
              <h1>{!api.isAdminOrMagStaffOrAccounting() ? 'Find My Cases' : 'Search Cases'}</h1>
            </div>
            <form className="search-form" onSubmit={this.handleSubmit}>
              <div className="inline">
                <Input
                  fluid
                  size="large"
                  icon="search"
                  value={search}
                  onChange={this.handleSearchChange}
                  placeholder="Search any keyword"
                  loading={loading}
                  disabled={loading}
                  className="search-input"
                  iconPosition="left"
                  label={
                    search.length ? <Button className="clear" type="button" icon="times" onClick={this.onClear} /> : null
                  }
                  labelPosition={search.length ? 'right' : null}
                />
                <TableToolbar.SubmitButton
                  className="Toolbar-highlight-button px-2"
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleSubmit}
                  disabled={loading}
                >
                  Search
                </TableToolbar.SubmitButton>
                {/* <TableToolbar.HighLightButton
                  onClick={this.onClear}
                  style={{ marginLeft: '5px' }}
                  className="negative"
                  disabled={loading}
                >
                  Clear
                </TableToolbar.HighLightButton> */}
                {api.isAdminOrMagStaffOrAccounting() && !service && (
                  <TableToolbar.HighLightButton
                    style={{ marginLeft: '5px', width: 220 }}
                    onClick={this.handleExportSearch}
                    className="positive"
                    disabled={loading}
                  >
                    Export Searches
                  </TableToolbar.HighLightButton>
                )}

                {service && service === 'BillServices' && (
                  <TableToolbar.HighLightButton
                    style={{ marginLeft: '5px', width: 220 }}
                    onClick={this.handleExportBillServices}
                    className="positive"
                    disabled={loading}
                  >
                    Export Excel
                  </TableToolbar.HighLightButton>
                )}
              </div>
              <div className="form-checkboxes">
                {/* {!api.isDoctor() &&
                  (<Checkbox label="Doctors" checked />)
                } */}
                <Checkbox
                  label="IME Reports"
                  name="searchIME"
                  checked={this.searchIME}
                  onChange={action(() => (this.searchIME = !this.searchIME))}
                />
                <Checkbox
                  label="File Reviews"
                  name="searchFr"
                  checked={this.searchFr}
                  onChange={action(() => (this.searchFr = !this.searchFr))}
                />
                <Checkbox
                  label="Supplementary Reports"
                  name="searchSupp"
                  checked={this.searchSupp}
                  onChange={action(() => (this.searchSupp = !this.searchSupp))}
                />
                {!api.isDoctor() && !api.isTypist() && (
                  <Checkbox
                    label="Clinical Records"
                    name="searchCr"
                    checked={this.searchCr}
                    onChange={action(() => (this.searchCr = !this.searchCr))}
                  />
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Checkbox
                    label="Med Negs"
                    name="searchMN"
                    checked={this.searchMN}
                    onChange={action(() => (this.searchMN = !this.searchMN))}
                  />
                )}
              </div>
              <div className="form-checkboxes inline">
                <span title="Show attached documents in matching cases">
                  <Checkbox
                    label="Show Case Documents"
                    name="searchSupp"
                    checked={this.searchAttachment}
                    onChange={action(() => (this.searchAttachment = !this.searchAttachment))}
                  />
                </span>
                <span
                  title="Find keywords in attached documents (e.g LOI, paperwork, draft and completed reports) only and show matching cases"
                  style={{ paddingLeft: '15px' }}
                >
                  <Checkbox label="Search Case Documents Only" disabled={true} />
                </span>
              </div>
              <div className="recent-search">
                <h4>Recent searches:</h4>
                <div className="recent-search-list">
                  {!recentSearch.length ? (
                    <div className="recent-search-emtpy-msg">No recent search</div>
                  ) : (
                    this.renderRecentSearchList(recentSearch)
                  )}
                </div>
              </div>
              {this.noCaseFound && !this.loading && (
                <div className="recent-search">
                  <div className="recent-search-list">
                    <div className="recent-search-emtpy-msg">
                      <h2>NO RESULTS</h2>
                    </div>
                  </div>
                </div>
              )}
            </form>
            <Accordion fluid className="mt-3">
              {this.accordionItems.map(({ title, data, Component, curPage, totalPage, sumItem, handlePageClick }, idx) => (
                <React.Fragment key={idx}>
                  {!(api.isDoctor() && Component === DoctorCardList) && data.length > 0 && (
                    <React.Fragment>
                      <Accordion.Title
                        index={idx}
                        className={cx('py-0', { 'mt-3': idx > 0 })}
                        active={this.isActive(idx)}
                        onClick={this.handleToggleActive(idx)}
                      >
                        <h3 className="mb-0">
                          <Icon name="dropdown" />
                          {title} ({sumItem})
                        </h3>
                      </Accordion.Title>
                      <Divider />
                      <Accordion.Content active={this.isActive(idx)}>
                        <Component
                          data={data}
                          searchAttachment={this.searchAttachment}
                          service={service}
                          curPage={curPage}
                          totalPage={totalPage}
                          sumItem={sumItem}
                          handlePageClick={handlePageClick}
                          refetchTable={this.handleRefetchTbl}
                        />
                      </Accordion.Content>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </Accordion>
          </div>
        </React.Fragment>}
      </div>
    );
  }
}

export default observer(UniversalSearch);
