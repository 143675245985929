import React from 'react';
import {
  Form,
  Checkbox,
  Divider,
  Radio,
  Select,
  Menu,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  DatePicker,
  notification,
  Collapse,
} from 'antd';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import moment from 'moment';

import FieldUpload from './FieldUpload';
import store from './Store';
import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import { getCustomerEmail } from './service';
import dashboardStore from '@stores/dashboard';
import ListInvoice from './ListInvoice';
import { Header } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';

const optionServiceProvider = [
  {
    Name: `Specialist`,
    Id: 1,
  },
  {
    Name: `Medicolegal Provider`,
    Id: 2,
  },
];

@observer
class ClientInvoicingInformation extends React.Component {
  renderStaffName = uId => {
    const staffs = toJS(store.listStaff);
    const nameIfCM = uId === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return staffs.itemList && staffs.itemList.length
      ? staffs.itemList.find(i => i.Id === uId)?.FullName
      : nameIfCM
      ? nameIfCM
      : '';
  };

  handleActionResend = (url, body) => {
    store.toggleModal(true, {
      message: 'Do you want to resend payment receipt to Specialist?',
      modalType: 'confirm',
      onOk: async () => {
        try {
          const response = await customFetch(url, {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body,
          });
          if (response.status === 'success') {
            notification.destroy();
            store.handleChangeValue('EmailPaymentReceiptToDoctorDateSent', moment());
            notification.success({
              message: 'Success',
              description: 'Resend email payment receipt to Specialist',
              duration: 5,
            });
          } else {
            notification.destroy();
            notification.error({
              duration: 5,
              message: 'Error',
              description: 'Resend email payment receipt to Specialist',
            });
          }
        } catch (e) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
            duration: 5,
          });
        }
      },
    })();
  };

  handleSearch = fieldName => value => {
    if (fieldName === 'payingClient') {
      return value
        ? store.handleRefetchPayingClient({
            keywordPayingCompany: value,
          })
        : store.handleRefetchPayingClient({
            keywordPayingCompany: '',
            numberPerPagePayingCompany: 30,
          });
    }
    if (fieldName === 'referenceClient') {
      return value
        ? store.handleRefetchReferenceClient({
            keywordReferenceCompany: value,
          })
        : store.handleRefetchReferenceClient({
            keywordReferenceCompany: '',
            numberPerPageReferenceCompany: 30,
          });
    }
    if (fieldName === 'payingCasemanger') {
      return value
        ? store.handleRefetchPayingCasemanager({
            keywordPayingCaseManager: value,
          })
        : store.handleRefetchPayingCasemanager({
            keywordPayingCaseManager: '',
            numberPerPagePayingCaseManager: 30,
          });
    }
  };

  handleResendEmailClientPaymentReceipt = () => {
    const itemModel = toJS(store.assessmentInfo);
    return customFetch('/MedicalService/ResendEmailClientPaymentReceipt', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend email payment receipt',
          });
          store.handleChangeValue('EmailPaymentReceiptToClientDateSent', moment());
          store.handleChangeValue('EmailPaymentReceiptToClientBy', response.sentBy);
        } else {
          notification.destroy();
          notification.Error({
            message: 'Error',
            description: 'Resend email payment receipt',
          });
        }
      })
      .catch(() => {});
  };

  ResendEmailClientPaymentReceipt = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend payment receipt?',
      onOk: () => this.handleResendEmailClientPaymentReceipt(),
    })();
  };

  handleScroll = fieldName => event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      if (fieldName === 'payingClient') {
        const number = store.numberPerPagePayingCompany;
        return store.handleRefetchPayingClient({
          numberPerPagePayingCompany: number + 30,
        });
      }
      if (fieldName === 'referenceClient') {
        const number = store.numberPerPageReferenceCompany;
        return store.handleRefetchReferenceClient({
          numberPerPageReferenceCompany: number + 30,
        });
      }
      if (fieldName === 'payingCasemanger') {
        const number = store.numberPerPagePayingCaseManager;
        return store.handleRefetchPayingCasemanager({
          numberPerPagePayingCaseManager: number + 30,
        });
      }
    }
  };

  @action adminExtraFocusOut = () => {
    const itemModel = toJS(store.assessmentInfo);
    var tax = itemModel.AdminExtraFeeAmount * 0.1;
    store.assessmentInfo = {
      ...itemModel,
      AdminExtraFeeTaxPercentage: parseFloat(tax.toFixed(2)),
    };
    store.calculateInvoice();
  };

  @action handleChangeModeMultipleObj = (_, event, fieldName) => {
    const data = event.map(i => i.props.data);
    const itemModel = toJS(store.assessmentInfo);
    const data_ = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null)).filter(i => i);
    var cloneData = [...data_];
    var newArr = [];
    if (cloneData && cloneData.length > 0) {
      const { lstInvoiceItemFee } = store;
      cloneData.forEach(el => {
        var fItem = lstInvoiceItemFee.find(i => i.ItemId === el.Id);
        if (fItem) {
          newArr.push(fItem);
        } else {
          var newObj = {
            AsessmentId: itemModel.Id,
            ItemId: el.Id,
            SeletedItemFees: true,
            ItemCode: el.ItemCode,
            ItemFeesAmount: el.ApplicablePrice ? el.ApplicablePrice : 0,
            ItemFeesTax: el.Tax,
            ItemFeesGST: el.ApplicablePrice && el.Tax ? (el.ApplicablePrice * el.Tax) / 100 : 0,
            SeletedOperator: false,
            ItemFeeQuantity: 1,
            TotalItemFeeAmount: el.ApplicablePrice ? el.ApplicablePrice : 0,
            ServiceProvider: 1,
            ItemFeePerformedById: '',
          };
          newArr.push(newObj);
        }
      });
    }
    store.setListItem('InvoiceServiceItem', newArr);
    store.assessmentInfo = { ...itemModel, [fieldName]: data_ };
    store.calculateTotalServiceFee();
    store.calculateInvoice();
    return;
  };

  @action handleChangeObj = (_, event, fieldName) => {
    if (event && event.props) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      if (fieldName === 'payingClient') {
        store.payingCasemanger = {};
        store[fieldName] = data_;
        return store.handleRefetchPayingCasemanager({
          keywordPayingCaseManager: '',
          numberPerPagePayingCaseManager: '',
          payingCasemangerCompanyId: data_?.Id,
        });
      }
    } else {
      if (fieldName === 'payingClient') {
        store.payingCasemanger = {};
      }
      return (store[fieldName] = {});
    }
  };

  handleChangeField = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'ClientPrepaymentRequiredDate') {
        if (itemModel.ClientPrepaymentRequired) {
          store.validateInput(value, 'required', fieldName);
        } else {
          store.invalidForm = store.invalidForm.filter(i => i !== fieldName);
        }
      }
      if (fieldName === 'ClientPrepaymentPaidDate') {
        if (itemModel.ClientPrepaymentReceived) {
          store.validateInput(value, 'required', fieldName);
        } else {
          store.invalidForm = store.invalidForm.filter(i => i !== fieldName);
        }
      }
      if (fieldName === 'SendInvoice' || fieldName === 'SendInvoice2') {
        store.validateInput(value, 'email', fieldName);
      }
      if (fieldName === 'PrepaymentRequiredDate') {
        if (itemModel.DoctorPrepaymentRequired) {
          store.validateInput(value, 'required', fieldName);
        } else {
          store.invalidForm = store.invalidForm.filter(i => i !== fieldName);
        }
      }
      if (fieldName === 'PaymentRequestedDate') {
        if (itemModel.PrepaymentRequired) {
          store.validateInput(value, 'required', fieldName);
        } else {
          store.invalidForm = store.invalidForm.filter(i => i !== fieldName);
        }
      }
      if (fieldName === 'CommissionRateType') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        return store.handleCommissionRateTypeChange();
      }
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      store.calculateTotalServiceFee();
      store.calculateInvoice();
      return;
    });

  handleItemFeeChangeField = (id, fieldName) =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const { lstInvoiceItemFee } = store;
      var fItem = lstInvoiceItemFee.find(i => i.ItemId === id);
      if (fItem) {
        fItem[fieldName] = value;
        if (fieldName === 'ItemFeesAmount' || fieldName === 'ItemFeeQuantity') {
          fItem.TotalItemFeeAmount = fItem.ItemFeeQuantity * fItem.ItemFeesAmount;
          fItem.ItemFeesGST = (fItem.TotalItemFeeAmount * fItem.ItemFeesTax) / 100;
        }
        store.calculateTotalServiceFee();
      }
    });

  handleItemFeeChangeDropdownList = (id, fieldName) =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const { lstInvoiceItemFee } = store;
      var fItem = lstInvoiceItemFee.find(i => i.ItemId === id);
      if (fItem) {
        fItem[fieldName] = value;
        if (fieldName === 'ServiceProvider') {
          if (value === 1) {
            fItem.SeletedItemFees = true;
            fItem.SeletedOperator = false;
          } else {
            fItem.SeletedItemFees = false;
            fItem.SeletedOperator = true;
          }
        }
      }
    });

  @action onClientPrepaymentRequiredChange = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (itemModel.ClientPrepaymentRequired) {
      store.assessmentInfo.ClientPrepaymentRequiredDate = moment();
      if (!itemModel.DelayPrepaymentRequired) {
        return store.toggleModal(true, {
          modalType: 'confirm',
          message: 'Do you want to delay sending completed reports to client until payment is made?',
          onOk: () => action(() => (store.assessmentInfo.DelayPrepaymentRequired = true))(),
        })();
      }
    }
  };

  handleResendInvoice = () => {
    const itemModel = toJS(store.assessmentInfo);
    return customFetch('/MedicalService/ResendInvoice', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id }),
    })
      .then(response => {
        if (response.UnAuthorized) {
          notification.destroy();
          return notification.error({
            message: 'Error',
            description: 'Sorry you are not Authorized to Perform this Action.',
          });
        }
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend Invoice',
          });
          store.handleChangeValue('EmailInvoiceToMAGDateSent', moment());
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: ' Resend Invoice',
          });
        }
      })
      .catch(() => {});
  };

  onclickResendInvoice = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to resend invoice to the operator?',
      onOk: () => this.handleResendInvoice(),
    })();
  };

  handleChangeEmailPaymentReceipt = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!itemModel.EmailInvoiceToMAG) {
      store.toggleModal(true, {
        message: 'Do you want to send invoice to Operator?',
        modalType: 'confirm',
        onOk: () => {
          if (!store.renderAttachment(itemModel.AssessmentAttachment, 11).length) {
            store.showNotificationError('Provider invoice must be attached first');
          } else {
            store.handleChangeValue('EmailInvoiceToMAG', true);
          }
        },
      })();
    }
  };

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;

      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'SI_SameBookingCM') {
        if (value === true) {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            SendInvoice: store.bookingCaseManager.Email,
          };
          store.invalidForm = store.invalidForm.filter(i => i !== 'SendInvoice');
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            SendInvoice: '',
          };
          store.invalidForm = store.invalidForm.filter(i => i !== 'SendInvoice');
        }
      } else if (fieldName === 'EmailInvoiceToMAG') {
        if (value && store.renderAttachment(itemModel.AssessmentAttachment, 11).length < 1) {
          store.showNotificationError('Provider invoice must be attached first');
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: false,
          };
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          };
        }
      } else if (fieldName === 'DoctorPrepaymentRequired') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          PrepaymentRequiredDate: value ? moment() : null,
        };
        if (!value) {
          store.invalidForm = store.invalidForm.filter(i => i !== 'PrepaymentRequiredDate');
        } else {
          this.handleChangeEmailPaymentReceipt();
          store.validateInput(store.assessmentInfo.PrepaymentRequiredDate, 'required', 'PrepaymentRequiredDate');
        }
      } else if (fieldName === 'EmailPaymentReceiptToClient') {
        if (value) {
          if (!itemModel.SendInvoice && !itemModel.SendInvoice2) {
            return store.showNotificationError('Please input email for Email For Sending Invoices.');
          } else {
            return (store.assessmentInfo = {
              ...itemModel,
              [fieldName]: value,
            });
          }
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          });
        }
      } else if (fieldName === 'ClientPrepaymentReceived') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          ClientPrepaymentPaidDate: value ? moment() : null,
        };
        if (!value) {
          store.invalidForm = store.invalidForm.filter(i => i !== 'ClientPrepaymentPaidDate');
        } else {
          store.validateInput(store.assessmentInfo.ClientPrepaymentPaidDate, 'required', 'ClientPrepaymentPaidDate');
        }
      } else if (fieldName === 'ClientPrepaymentRequired') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        if (!value) {
          store.invalidForm = store.invalidForm.filter(i => i !== 'ClientPrepaymentRequiredDate');
        } else {
          this.onClientPrepaymentRequiredChange();
          return store.validateInput(
            store.assessmentInfo.ClientPrepaymentRequiredDate,
            'required',
            'ClientPrepaymentRequiredDate',
          );
        }
        return this.onClientPrepaymentRequiredChange();
      } else if (fieldName === 'SpecialistPrepaymentPaid') {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          PrepaymentPaidDate: value ? moment() : null,
        });
      } else if (fieldName === 'SI_SameClientAccEmail') {
        if (value === true) {
          if (store.payingClient?.Id) {
            Promise.all([getCustomerEmail(store.payingClient.Id)]).then(
              action(([response]) => {
                store.assessmentInfo = {
                  ...itemModel,
                  [fieldName]: value,
                  SendInvoice2: response.email,
                };
                store.invalidForm = store.invalidForm.filter(i => i !== 'SendInvoice2');
              }),
            );
          } else {
            store.toggleModal(true, {
              modalType: 'confirm',
              message: 'Please choose Paying Client first.',
            })();
          }
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
            SendInvoice2: '',
          };
          store.invalidForm = store.invalidForm.filter(i => i !== 'SendInvoice2');
        }
      } else {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        store.invalidForm = store.invalidForm.filter(i => i !== 'SendInvoice2');
      }
    });

  handleOpenAddEdit = (actionType = 'add', storageName, storageValue, pathName, id = 0, fieldName) => {
    if (id === 0) {
      localStorage.setItem(storageName, storageValue);
      dashboardStore.close(pathName, true);

      if (pathName === '/view/add-edit-case-manager-2') {
        setTimeout(() => {
          dashboardStore.open(
            `${pathName}?pageGetter=addEditIME&fieldName=${fieldName}&companyId=${store.payingClient?.Id}`,
          );
        });
      } else {
        setTimeout(() => {
          dashboardStore.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      }
    } else {
      if (actionType === 'add') {
        localStorage.removeItem(storageName);
        dashboardStore.close(pathName, true);
        setTimeout(() => {
          dashboardStore.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      } else {
        localStorage.setItem(storageName, storageValue);
        dashboardStore.close(pathName, true);
        setTimeout(() => {
          dashboardStore.open(`${pathName}&pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      }
    }
  };

  handleCallBackSpecialistPaymentReceipt = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to email receipt to the provider?',
      onOk: () => action(() => (store.assessmentInfo.EmailPaymentReceiptToDoctor = true))(),
    })();
  };

  handleCallBackSpecialistPaymentInvoice = () => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to send specialist invoice to Operator?',
      onOk: () => action(() => (store.assessmentInfo.EmailInvoiceToMAG = true))(),
    })();
  };
  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      const arr_ = arr.filter(i => i[key] !== item[key]);
      return [...arr_, item];
    }
    return arr;
  };
  render() {
    const itemModel = toJS(store.assessmentInfo);
    const languages = toJS(store.languageList);
    const payingClients = toJS(store.listPayingCompany);
    const referenceClients = toJS(store.listReferenceCompany);
    const payingCasemangers = toJS(store.listPayingCaseManager);
    const billingItemCodes = toJS(store.listBillingItemCode);
    const staffs = toJS(store.listStaff);
    const lstInvoiceItemFee = toJS(store.lstInvoiceItemFee);

    if (api.isCaseManager()) {
      return <ListInvoice />;
    } else {
      return (
        <Form name="client-invoicing-information" className="grid-style">
          <Collapse bordered={false} defaultActiveKey={api.isDoctor() ? 4 : null}>
            {!api.isDoctor() && (
              <Collapse.Panel
                key="1"
                header={
                  <Header
                    title="Service Notes"
                    statuses={[
                      {
                        key: 'ime-service-note-urgent',
                        isChecked: !!itemModel.IsInvoiceUrgentReport,
                        text: 'Urgent',
                      },
                      {
                        key: 'ime-service-note-over-20',
                        isChecked: !!itemModel.IsLongPaperwork,
                        text: 'Over 20 pg',
                      },
                      {
                        key: 'ime-service-note-interpreter',
                        isChecked: !!itemModel.Interpreter,
                        text: 'Interpreter Required',
                      },
                      {
                        key: 'ime-service-note-operator-clinic',
                        isChecked: itemModel.ClinicOption === 'mag',
                        text: 'Medicolegal Provider Clinic',
                      },
                      {
                        key: 'ime-service-note-dictation',
                        isChecked: itemModel.DictationOption === 0,
                        text: 'Dictation Required',
                      },
                    ]}
                    isRequired={false}
                  />
                }
              >
                <div className="fluid-field checkbox-field">
                  <Checkbox disabled checked={itemModel.IsInvoiceUrgentReport}>
                    Urgent Report
                  </Checkbox>
                  <Checkbox disabled checked={itemModel.IsLongPaperwork}>
                    Over 20 pages
                  </Checkbox>
                </div>
                <Form.Item label="Interpreter Required">
                  <Radio.Group disabled value={itemModel.Interpreter}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Organised by Operator">
                  <Radio.Group disabled value={itemModel.HasInterpretationService}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Language">
                  <Select disabled size="large" value={itemModel.Language}>
                    {languages.itemList &&
                      languages.itemList.length &&
                      languages.itemList.map(i => (
                        <Select.Option value={i.LanguageId} key={i.LanguageId}>
                          {i.LanguageName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Interpreter Specialist">
                  <Select
                    disabled
                    size="large"
                    value={itemModel.InterpretationServiceClient}
                    placeholder="Select a service specialist"
                  >
                    {/* <Select.Option value="1">Option</Select.Option> */}
                  </Select>
                </Form.Item>
                <Form.Item label="Dictation and Report Typing by">
                  <Radio.Group disabled value={itemModel.DictationOption}>
                    <Radio value={0}>Medicolegal Provider</Radio>
                    <Radio value={1}>Specialist</Radio>
                    <Radio value={2}>Not sure</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Medical Practice Type">
                  <Radio.Group disabled value={itemModel.ClinicOption} className="clinic-type-radio-group">
                    <Radio value="mag" className="clinic-type-radio">
                      Medicolegal Provider - Owned Clinic
                    </Radio>
                    <Radio value="doctor" className="clinic-type-radio">
                      Specialist's Owned Clinic
                    </Radio>
                  </Radio.Group>
                  <Radio.Group disabled value={itemModel.ClinicOption} className="clinic-type-radio-group">
                    <Radio value="obtain" className="clinic-type-radio">
                      Obtained Appt
                    </Radio>
                    <Radio value="home" className="clinic-type-radio">
                      Home Visit
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider className="fluid-field" />
              </Collapse.Panel>
            )}
            {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && [
              <Collapse.Panel
                key="2"
                header={
                  <Header
                    title="Client Invoicing Information"
                    isChecked={itemModel.AssessmentServiceItems?.length > 0 ?? false}
                    text="Billing Items Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Paying Client">
                  <Select
                    size="large"
                    disabled={itemModel.isLocked}
                    value={store.payingClient?.Id || undefined}
                    showSearch
                    optionFilterProp="children"
                    onChange={(value, event) => this.handleChangeObj(value, event, 'payingClient')}
                    allowClear={true}
                    onSearch={this.handleSearch('payingClient')}
                    onPopupScroll={this.handleScroll('payingClient')}
                  >
                    {payingClients.itemList &&
                      payingClients.itemList.length &&
                      this.itemToArray(store.payingClient, payingClients.itemList, 'Id').map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.Name} - {i.StateName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="C/-Reference">
                  <Select
                    size="large"
                    disabled={itemModel.isLocked}
                    value={store.referenceClient?.Id || undefined}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'referenceClient')}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onSearch={this.handleSearch('referenceClient')}
                    onPopupScroll={this.handleScroll('referenceClient')}
                  >
                    {referenceClients.itemList &&
                      referenceClients.itemList.length &&
                      this.itemToArray(store.referenceClient, referenceClients.itemList, 'Id').map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.Name} - {i.StateName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Case Manager / Solicitor
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'add',
                                  'CmName',
                                  '',
                                  '/view/add-edit-case-manager-2',
                                  0,
                                  'payingCasemanger',
                                )
                              }
                            >
                              Add Case Manager
                            </Menu.Item>
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'edit',
                                  'CmName',
                                  store.payingCasemanger?.FullName || '',
                                  `/view/add-edit-case-manager-2?id=${store.payingCasemanger?.Id ||
                                    0}&action=edit&step=0&companyId=${store.payingClient?.Id}`,
                                  store.payingCasemanger?.Id || 0,
                                  'payingCasemanger',
                                )
                              }
                            >
                              Edit Case Manager
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <button className="field-actions">
                          Add/Edit <Icon type="down" />
                        </button>
                      </Dropdown>
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    disabled={itemModel.isLocked || !store.payingClient?.Id}
                    value={store.payingCasemanger?.Id || undefined}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'payingCasemanger')}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onSearch={this.handleSearch('payingCasemanger')}
                    onPopupScroll={this.handleScroll('payingCasemanger')}
                  >
                    {payingCasemangers.itemList &&
                      payingCasemangers.itemList.length &&
                      this.itemToArray(store.payingCasemanger, payingCasemangers.itemList, 'Id').map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.FullName} - {i.Email}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <div />
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 1:</span>
                      <Checkbox
                        checked={itemModel.SI_SameBookingCM}
                        disabled={itemModel.isLocked}
                        style={{ marginTop: 10 }}
                        onChange={this.handleChangeCheckBox('SI_SameBookingCM')}
                        className="checkbox-IsTypist"
                      >
                        Same as Booking CM
                      </Checkbox>
                    </span>
                  }
                  validateStatus={!store.validateField(itemModel.SendInvoice, 'email') ? 'error' : null}
                  help={
                    !store.validateField(itemModel.SendInvoice, 'email') ? 'Please enter a valid email address' : null
                  }
                >
                  <Input
                    size="large"
                    disabled={itemModel.isLocked || itemModel.SI_SameBookingCM}
                    value={itemModel.SendInvoice}
                    onChange={this.handleChangeField('SendInvoice')}
                  />
                </Form.Item>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Email For Sending Invoices 2:</span>
                      <Checkbox
                        style={{ marginTop: 10 }}
                        checked={itemModel.SI_SameClientAccEmail}
                        className="checkbox-IsTypist"
                        disabled={itemModel.isLocked}
                        onChange={this.handleChangeCheckBox('SI_SameClientAccEmail')}
                      >
                        Same as Client Accounting Email
                      </Checkbox>
                    </span>
                  }
                  validateStatus={!store.validateField(itemModel.SendInvoice2, 'email') ? 'error' : null}
                  help={
                    !store.validateField(itemModel.SendInvoice2, 'email') ? 'Please enter a valid email address' : null
                  }
                >
                  <Input
                    size="large"
                    disabled={itemModel.isLocked || itemModel.SI_SameClientAccEmail}
                    value={itemModel.SendInvoice2}
                    onChange={this.handleChangeField('SendInvoice2')}
                  />
                </Form.Item>
                <Form.Item label="Billing Item Codes">
                  <Select
                    size="large"
                    value={itemModel.AssessmentServiceItems ? itemModel.AssessmentServiceItems.map(i => i.Id) : []}
                    mode="multiple"
                    showArrow
                    disabled={itemModel.isLocked}
                    allowClear
                    onChange={(value, event) =>
                      this.handleChangeModeMultipleObj(value, event, 'AssessmentServiceItems')
                    }
                  >
                    {billingItemCodes.itemList &&
                      billingItemCodes.itemList.length &&
                      billingItemCodes.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* <Checkbox
                    checked={itemModel.IncludeServiceItemFeeToDoctorCommission}
                    style={{ marginTop: 10 }}
                    onChange={this.handleChangeCheckBox('IncludeServiceItemFeeToDoctorCommission')}
                  >
                    Specialist Service{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="If selected this fee will be added to eligible provider payment amount"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox> */}
                </Form.Item>
                <div />
                {lstInvoiceItemFee && lstInvoiceItemFee.length > 0 && (
                  <Form.Item className="fluid-field">
                    <React.Fragment>
                      <table className="ui celled sortable striped table">
                        <thead className="">
                          <tr>
                            <th className="cursor-default">Unit Rate</th>
                            <th className="cursor-default">Quantity</th>
                            <th className="cursor-default">Amount</th>
                            <th className="cursor-default">GST</th>
                            <th className="cursor-default" style={{ width: '210px' }}>
                              Performed By
                            </th>
                            <th className="cursor-default">Service Provider</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lstInvoiceItemFee.map(item => {
                            return (
                              <tr key={'td-item-' + item.ItemId}>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    onChange={this.handleItemFeeChangeField(item.ItemId, 'ItemFeesAmount')}
                                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={parseFloat(item.ItemFeesAmount).toFixed(2)}
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    onChange={this.handleItemFeeChangeField(item.ItemId, 'ItemFeeQuantity')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={parseFloat(item.ItemFeeQuantity)}
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    disabled
                                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={parseFloat(item.TotalItemFeeAmount).toFixed(2)}
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    size="large"
                                    onChange={this.handleItemFeeChangeField(item.ItemId, 'ItemFeesGST')}
                                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={parseFloat(item.ItemFeesGST).toFixed(2)}
                                  />
                                </td>
                                <td>
                                  <Select
                                    size="large"
                                    style={{ width: '100%' }}
                                    value={item.ItemFeePerformedById}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={this.handleItemFeeChangeDropdownList(item.ItemId, 'ItemFeePerformedById')}
                                  >
                                    {staffs.itemList &&
                                      staffs.itemList.length &&
                                      staffs.itemList.map(i => (
                                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                                          {i.FullName}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'ddlSVprovider'}
                                    value={item.ServiceProvider}
                                    onChange={this.handleItemFeeChangeDropdownList(item.ItemId, 'ServiceProvider')}
                                  >
                                    {optionServiceProvider &&
                                      optionServiceProvider.map(({ Id, Name }) => (
                                        <Select.Option value={Id} key={'opt-svp' + Id}>
                                          {Name}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </React.Fragment>
                  </Form.Item>
                )}

                <Form.Item label="Billing Item Fees (excl GST)">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    disabled
                    value={parseFloat(store.serviceItemTotalFee).toFixed(2)}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    disabled
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={parseFloat(store.serviceItemTotalTax).toFixed(2)}
                  />
                </Form.Item>
                <Form.Item label="Admin Extra (excl GST)">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    value={itemModel.AdminExtraFeeAmount}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    onKeyUp={this.adminExtraFocusOut}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.handleChangeField('AdminExtraFeeAmount')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={itemModel.AdminExtraFeeTaxPercentage}
                    onChange={this.handleChangeField('AdminExtraFeeTaxPercentage')}
                  />{' '}
                  <Checkbox
                    style={{ marginTop: 10 }}
                    checked={itemModel.IncludeAdminExtraFeeToDoctorCommission}
                    onChange={this.handleChangeCheckBox('IncludeAdminExtraFeeToDoctorCommission')}
                  >
                    Specialist Service{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="If selected this fee will be added to eligible provider payment amount"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </Form.Item>
                <div className="fluid-field checkbox-field">
                  <Checkbox
                    disabled={itemModel.isLocked}
                    checked={itemModel.HasOtherFee}
                    onChange={this.handleChangeCheckBox('HasOtherFee')}
                  >
                    Other Fees (excl GST)
                  </Checkbox>
                </div>
                {itemModel.HasOtherFee && (
                  <>
                    <Form.Item label="Other Fee Name" className="fluid-field">
                      <Input
                        size="large"
                        disabled={itemModel.isLocked}
                        value={itemModel.OtherFee1Label}
                        onChange={this.handleChangeField('OtherFee1Label')}
                      />
                    </Form.Item>
                    <Form.Item label="Fee">
                      <InputNumber
                        size="large"
                        style={{ width: '300px' }}
                        disabled={itemModel.isLocked}
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        value={itemModel.OtherFee1Value}
                        onChange={this.handleChangeField('OtherFee1Value')}
                      />{' '}
                      <Checkbox
                        checked={itemModel.IncludeManualFee1ToDoctorCommission}
                        style={{ marginTop: 10 }}
                        onChange={this.handleChangeCheckBox('IncludeManualFee1ToDoctorCommission')}
                      >
                        Specialist Service{' '}
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="If selected this fee will be added to eligible provider payment amount"
                          position="top center"
                          wide="very"
                        />
                      </Checkbox>
                    </Form.Item>
                    <Form.Item label="GST">
                      <InputNumber
                        size="large"
                        style={{ width: '300px' }}
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        disabled={itemModel.isLocked}
                        value={itemModel.OtherFee1TaxPercentage}
                        onChange={this.handleChangeField('OtherFee1TaxPercentage')}
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item label="Total Billing Amount (excl GST)">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={itemModel.TotalWithoutTax}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total GST">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={itemModel.TaxTotal}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Total Billing Amount (incl GST)">
                  <InputNumber
                    size="large"
                    style={{ width: '300px' }}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    // Total
                    value={itemModel.Total}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item className="fluid-field" label="Description">
                  <Input.TextArea
                    rows={3}
                    value={itemModel.Description}
                    onChange={this.handleChangeField('Description')}
                  />
                </Form.Item>
                <FieldUpload
                  uploadLoading={store.loadingUpload}
                  attachments={store.renderAttachment(itemModel.AssessmentAttachment, 10)}
                  reportType={10}
                  multiUpload={true}
                  label="Attach Client Files"
                />
              </Collapse.Panel>,
              <Collapse.Panel
                key="3"
                header={
                  <Header
                    title="Client Prepayment"
                    isChecked={itemModel.ClientPrepaymentReceived || itemModel.ClientPrepaymentRequired}
                    text={
                      itemModel.ClientPrepaymentReceived
                        ? 'Prepayment Received'
                        : itemModel.ClientPrepaymentRequired
                        ? 'Prepayment Required'
                        : ''
                    }
                    date={itemModel.ClientPrepaymentReceived ? itemModel.ClientPrepaymentPaidDate : null}
                    isRequired={false}
                  />
                }
              >
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={itemModel.ClientPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('ClientPrepaymentRequired')}
                    className="checkbox-session"
                  >
                    Client Prepayment Required
                  </Checkbox>
                </div>
                <div className="fluid-field checkbox-field">
                  <Checkbox checked={itemModel.ClientInvoiced} disabled>
                    Client Invoice Created
                    {itemModel.InvoiceNo ? ` - Invoice No - ${itemModel.InvoiceNo}` : ''}
                  </Checkbox>
                </div>
                <Form.Item label="Invoice Date">
                  <DatePicker
                    value={renderDate(itemModel.ClientInvoicedDate)}
                    disabled
                    format="DD/MM/YYYY"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label="To be paid by"
                  required={itemModel.ClientPrepaymentRequired}
                  validateStatus={
                    itemModel.ClientPrepaymentRequired && !itemModel.ClientPrepaymentRequiredDate ? 'error' : null
                  }
                  help={
                    itemModel.ClientPrepaymentRequired && !itemModel.ClientPrepaymentRequiredDate
                      ? 'Prepayment Required Date is required'
                      : ''
                  }
                >
                  <DatePicker
                    value={renderDate(itemModel.ClientPrepaymentRequiredDate)}
                    onChange={this.handleChangeField('ClientPrepaymentRequiredDate')}
                    disabled={!itemModel.ClientPrepaymentRequired}
                    format="DD/MM/YYYY"
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={itemModel.ClientPrepaymentReceived}
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('ClientPrepaymentReceived')}
                  >
                    Client Prepayment Received
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="Payment Date"
                  required={itemModel.ClientPrepaymentReceived}
                  validateStatus={
                    itemModel.ClientPrepaymentReceived && !itemModel.ClientPrepaymentPaidDate ? 'error' : null
                  }
                  help={
                    itemModel.ClientPrepaymentReceived && !itemModel.ClientPrepaymentPaidDate
                      ? 'Payment Date is required'
                      : ''
                  }
                >
                  <DatePicker
                    value={renderDate(itemModel.ClientPrepaymentPaidDate)}
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentReceived}
                    onChange={this.handleChangeField('ClientPrepaymentPaidDate')}
                    format="DD/MM/YYYY"
                    size="large"
                  />
                </Form.Item>{' '}
                <div className="fluid-field checkbox-field">
                  <Checkbox
                    checked={itemModel.DelayPrepaymentRequired}
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('DelayPrepaymentRequired')}
                  >
                    Delay Sending Reports
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Do not allow sending completed report to client until payment is made"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </div>
                <FieldUpload
                  uploadLoading={store.loadingUpload}
                  attachments={store.renderAttachment(itemModel.AssessmentAttachment, 21)}
                  reportType={21}
                  label="Attached Payment Receipt"
                />
                <Form.Item className="fluid-field">
                  <Checkbox
                    checked={itemModel.EmailPaymentReceiptToClient}
                    disabled={itemModel.isLocked || !itemModel.ClientPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('EmailPaymentReceiptToClient')}
                  >
                    Email Payment Receipt to Client
                    {api.isAdminOrMagStaffOrAccounting() && (
                      <Popup
                        trigger={<IconSemantic name="info circle" />}
                        content="Payment receipt will be sent to Client(MAIL-141)"
                        position="top center"
                        wide="very"
                      />
                    )}
                  </Checkbox>
                  <span style={{ color: '#107fc9' }}>
                    {itemModel.EmailPaymentReceiptToClientDateSent
                      ? renderDate(itemModel.EmailPaymentReceiptToClientDateSent, false, true)
                      : ''}
                    {itemModel.EmailPaymentReceiptToClientBy
                      ? ` - By ${store.renderStaffName(itemModel.EmailPaymentReceiptToClientBy)}`
                      : ''}
                  </span>
                  {!!itemModel.EmailPaymentReceiptToClientDateSent && (
                    <Button
                      className="blue"
                      style={{ marginLeft: 10 }}
                      onClick={() => this.ResendEmailClientPaymentReceipt()}
                    >
                      Resend Payment Receipt
                    </Button>
                  )}
                  <Form.Item className="fluid-field" label="Note">
                    <Input.TextArea
                      rows={3}
                      onChange={this.handleChangeField('ClientPrepaymentNote')}
                      value={itemModel.ClientPrepaymentNote}
                    />
                  </Form.Item>
                </Form.Item>
              </Collapse.Panel>,
            ]}

            {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && [
              <Collapse.Panel
                key="4"
                header={
                  <Header
                    title="Specialist Billing Information"
                    isChecked={(itemModel.CommissionRateType ?? -1) > -1}
                    text="Specialist Rate Updated"
                    isRequired={false}
                  />
                }
              >
                <Form.Item label="Booking Fee Type">
                  <Radio.Group
                    value={itemModel.CommissionRateType}
                    onChange={this.handleChangeField('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={0} className="radio">
                      Medicolegal Provider Add-On Rate
                    </Radio>
                    <Radio value={1} className="radio">
                      Medicolegal Provider Standard Rate
                    </Radio>
                  </Radio.Group>
                  <Radio.Group
                    value={itemModel.CommissionRateType}
                    onChange={this.handleChangeField('CommissionRateType')}
                    className="booking-fee-type-group"
                  >
                    <Radio value={3} className="radio">
                      Gross Margin COT
                    </Radio>
                    <Radio value={2} className="radio">
                      Fixed Amount
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {itemModel.CommissionRateType !== 2 ? (
                  <Form.Item
                    label={
                      <React.Fragment>
                        Commission Rate (%)
                        <button className="field-actions">Check Current Rate</button>
                      </React.Fragment>
                    }
                  >
                    <InputNumber
                      size="large"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      value={itemModel.CommissionRate}
                      onChange={this.handleChangeField('CommissionRate')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                ) : (
                  <div />
                )}
                <Form.Item label="Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={
                      itemModel.TotalAmountForCommissionCalculation
                        ? itemModel.TotalAmountForCommissionCalculation.toFixed(2)
                        : 0
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    disabled
                    value={
                      itemModel.TotalAmountForCommissionCalculationTax
                        ? itemModel.TotalAmountForCommissionCalculationTax.toFixed(2)
                        : 0
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={(
                      itemModel.TotalAmountForCommissionCalculation + itemModel.TotalAmountForCommissionCalculationTax
                    ).toFixed(2)}
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {itemModel.CommissionRateType === 3 ? (
                  <Form.Item required label={<React.Fragment>Gross Margin COT Rate (%)</React.Fragment>}>
                    <InputNumber
                      size="large"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      value={itemModel.CommissionOnTopRate}
                      onChange={this.handleChangeField('CommissionOnTopRate')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                ) : (
                  <div />
                )}
                <Form.Item label="Eligible Service Amount (excl GST)">
                  <InputNumber
                    size="large"
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={itemModel.CommissionAmount}
                    onChange={this.handleChangeField('CommissionAmount')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="GST">
                  <InputNumber
                    size="large"
                    disabled
                    value={(itemModel.CommissionAmount * 0.1).toFixed(2)}
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="Eligible Service Amount (incl GST)">
                  <InputNumber
                    size="large"
                    disabled
                    value={
                      itemModel.CommissionAmountGstNilEnabled
                        ? itemModel.CommissionAmount
                        : (itemModel.CommissionAmount * 1.1).toFixed(2)
                    }
                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Collapse.Panel>,
              <Collapse.Panel
                key="5"
                header={
                  <Header
                    title="Specialist Prepayment"
                    isChecked={itemModel.SpecialistPrepaymentPaid || itemModel.DoctorPrepaymentRequired}
                    text={
                      itemModel.SpecialistPrepaymentPaid
                        ? 'Prepayment Paid'
                        : itemModel.DoctorPrepaymentRequired
                        ? 'Prepayment Required'
                        : ''
                    }
                    date={itemModel.SpecialistPrepaymentPaid ? itemModel.PrepaymentPaidDate : null}
                    isRequired={false}
                  />
                }
              >
                <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={itemModel.DoctorPrepaymentRequired}
                    onChange={this.handleChangeCheckBox('DoctorPrepaymentRequired')}
                    className="checkbox-session"
                  >
                    Specialist Prepayment Required{' '}
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Prepayment notice will be sent to staff (MAIL-108)"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </div>
                <div className="checkbox-field" style={{ marginTop: 20 }}>
                  <Checkbox disabled checked={itemModel.Doctor?.ApprovedPrepayment || false}>
                    Approved Prepayment
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Prepayment notice will be sent to staff (MAIL-108)"
                      position="top center"
                      wide="very"
                    />
                  </Checkbox>
                </div>
                <Form.Item label="Valid until">
                  <Input size="large" disabled value={itemModel.ValidUntil} />
                </Form.Item>{' '}
                {!api.isDoctor() && (
                  <div className="checkbox-field">
                    <Checkbox disabled checked={itemModel.DoctorInvoiced} style={{ marginTop: 20 }}>
                      Specialist Bill Created
                    </Checkbox>
                  </div>
                )}
                {!api.isDoctor() && (
                  <>
                    <Form.Item label="Bill Date">
                      <DatePicker
                        value={renderDate(itemModel.DoctorInvoicedDate)}
                        onChange={this.handleChangeField('DoctorInvoicedDate')}
                        disabled
                        format="DD/MM/YYYY"
                        size="large"
                      />
                    </Form.Item>
                  </>
                )}
                {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
                  <Form.Item
                    label="Invoice Date"
                    required={itemModel.PrepaymentRequired}
                    validateStatus={itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'error' : null}
                    help={
                      itemModel.PrepaymentRequired && !itemModel.PaymentRequestedDate ? 'Invoice Date is required' : ''
                    }
                  >
                    <DatePicker
                      value={renderDate(itemModel.PaymentRequestedDate)}
                      disabled={itemModel.servicePrepaymentLocked}
                      onChange={this.handleChangeField('PaymentRequestedDate')}
                      format="DD/MM/YYYY"
                      size="large"
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="To be paid by"
                  required={itemModel.DoctorPrepaymentRequired}
                  validateStatus={
                    itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate ? 'error' : null
                  }
                  help={
                    itemModel.DoctorPrepaymentRequired && !itemModel.PrepaymentRequiredDate
                      ? 'Prepayment Required Date is required'
                      : ''
                  }
                >
                  <DatePicker
                    value={renderDate(itemModel.PrepaymentRequiredDate)}
                    disabled={itemModel.servicePrepaymentLocked}
                    onChange={this.handleChangeField('PrepaymentRequiredDate')}
                    format="DD/MM/YYYY"
                    size="large"
                  />
                </Form.Item>
                <FieldUpload
                  uploadLoading={store.loadingUpload}
                  attachments={store.renderAttachment(itemModel.AssessmentAttachment, 11)}
                  reportType={11}
                  label="Attached Specialist Invoice"
                  multiUpload={true}
                  functionCallback={this.handleCallBackSpecialistPaymentInvoice}
                />
                <div />
                {!itemModel.servicePrepaymentLocked && (
                  <Form.Item className="fluid-field">
                    <Checkbox
                      checked={itemModel.EmailInvoiceToMAG}
                      disabled={itemModel.servicePrepaymentLocked || !!itemModel.EmailInvoiceToMAGDateSent}
                      onChange={this.handleChangeCheckBox('EmailInvoiceToMAG')}
                    >
                      Email Invoice to Medicolegal Provider
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Popup
                          trigger={<IconSemantic name="info circle" />}
                          content="Specialist invoice will be sent to staff (MAIL-135)"
                          position="top center"
                          wide="very"
                        />
                      )}
                    </Checkbox>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.EmailInvoiceToMAGDateSent
                        ? renderDate(itemModel.EmailInvoiceToMAGDateSent, false, true)
                        : ''}
                      {itemModel.EmailInvoiceToMAGStaffSent ? ` - By ${itemModel.EmailInvoiceToMAGStaffSent}` : ''}
                    </span>
                    {!!itemModel.EmailInvoiceToMAGDateSent && (
                      <Button className="blue" style={{ marginLeft: 10 }} onClick={() => this.onclickResendInvoice()}>
                        Resend Invoice
                      </Button>
                    )}
                  </Form.Item>
                )}
                <Form.Item>
                  <Checkbox
                    disabled={itemModel.isLocked}
                    checked={itemModel.SpecialistPrepaymentPaid}
                    onChange={this.handleChangeCheckBox('SpecialistPrepaymentPaid')}
                    style={{ marginTop: 20 }}
                  >
                    Specialist Prepayment Paid
                  </Checkbox>
                </Form.Item>
                <Form.Item label="Payment Date">
                  <DatePicker
                    value={renderDate(itemModel.PrepaymentPaidDate)}
                    disabled={itemModel.isLocked || !itemModel.SpecialistPrepaymentPaid}
                    onChange={this.handleChangeField('PrepaymentPaidDate')}
                    format="DD/MM/YYYY"
                    size="large"
                  />
                </Form.Item>
                {!api.isDoctor() && (
                  <FieldUpload
                    uploadLoading={store.loadingUpload}
                    attachments={store.renderAttachment(itemModel.AssessmentAttachment, 12)}
                    reportType={12}
                    label="Attached Payment Receipt"
                    functionCallback={this.handleCallBackSpecialistPaymentReceipt}
                  />
                )}
                {!itemModel.servicePrepaymentLocked && (
                  <Form.Item className="fluid-field">
                    <div style={{ marginTop: 20 }}>
                      <Checkbox
                        disabled={itemModel.isLocked || !!itemModel.EmailPaymentReceiptToDoctorDateSent}
                        checked={itemModel.EmailPaymentReceiptToDoctor}
                        onChange={this.handleChangeCheckBox('EmailPaymentReceiptToDoctor')}
                      >
                        Email Payment Receipt to Specialist{' '}
                        {!api.isDoctor() && (
                          <Popup
                            trigger={<IconSemantic name="info circle" />}
                            content="Payment receipt will be sent to specialist (Mail-107)"
                            position="top center"
                            wide="very"
                          />
                        )}
                      </Checkbox>
                      <span style={{ color: '#107fc9', marginLeft: 5 }}>
                        {itemModel.EmailPaymentReceiptToDoctorDateSent
                          ? renderDate(itemModel.EmailPaymentReceiptToDoctorDateSent, false, true)
                          : ''}
                        {itemModel.EmailPaymentReceiptToDoctorSentBy
                          ? ` - By ${itemModel.EmailPaymentReceiptToDoctorSentBy}`
                          : ''}
                      </span>
                      {!!itemModel.EmailPaymentReceiptToDoctorDateSent && (
                        <Button
                          className="blue"
                          style={{ marginTop: 10 }}
                          onClick={() =>
                            this.handleActionResend(
                              '/MedicalService/ResendEmailPaymentReceipt',
                              JSON.stringify({ id: itemModel.Id }),
                            )
                          }
                        >
                          Resend Payment Receipt
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                )}
                <Form.Item className="fluid-field" label="Note">
                  <Input.TextArea
                    rows={3}
                    onChange={this.handleChangeField('SpecialistPrepaymentNote')}
                    value={itemModel.SpecialistPrepaymentNote}
                  />
                </Form.Item>
              </Collapse.Panel>,
            ]}
          </Collapse>
        </Form>
      );
    }
  }
}

export default ClientInvoicingInformation;
