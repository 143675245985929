import React from 'react';
import { Divider, notification, Spin } from 'antd';
import { action, toJS } from 'mobx';
import { Button, Dimmer } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import '../AddEditClient/index.scss';
import cx from 'classnames';
import GeneralInformation from './GeneralInformation';
import ActionRequired from './ActionRequired';
import ActionRequiredModal from './ActionRequiredModal';
import router from '../../../stores/router';
import AddEditStaffStore from './Store';
import dashboardStore from '../../../stores/dashboard';
import AddEditModal from './Modal';
import { ERROR_FIELDNAME, ERROR_MESSAGES } from './type';
import * as api from '../../../../src/stores/api';

const STEPS = ['General Information', 'Action Required'];

@observer
class AddEditStaff extends React.Component {
  state = {
    currentStep: 0,
    action: 'view',
    id: '',
    isRerender: false,
  };
  componentDidMount() {
    const { id, action } = this.getRouteParams();
    if (id) {
      this.setState({ id, action }, () => {
        AddEditStaffStore.fetchStaffData(id);
      });
    } else {
      AddEditStaffStore.initStaffData();
    }
  }
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const state = router.location.state;
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return {
      ...params,
      ...state,
    };
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 4,
    });
  };

  getKeywordErrorMessage = message => {
    return message
      ? message.toLowerCase().search('invalid') !== -1
        ? 'invalid'
        : message.toLowerCase().search('required') !== -1
        ? 'required'
        : message.toLowerCase().search('digits') !== -1
        ? 'digits'
        : null
      : null;
  };

  retrunErrorMessage = (keywordFieldName, message) => {
    if (this.getKeywordErrorMessage(message) && keywordFieldName) {
      if (keywordFieldName === 'Email' && this.getKeywordErrorMessage(message) === 'invalid') {
        return this.showFormErrorNoti('Invalid email address');
      } else {
        return this.showFormErrorNoti(
          `${ERROR_FIELDNAME[keywordFieldName]} ${ERROR_MESSAGES[this.getKeywordErrorMessage(message)]}`,
        );
      }
    } else {
      return this.showFormErrorNoti('An error occurred, please try again');
    }
  };

  handleSave = () => {
    const { StaffInfo, errors } = AddEditStaffStore;
    if (!StaffInfo.FirstName) {
      action(() => {
        errors.FirstName = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!StaffInfo.LastName) {
      action(() => {
        errors.LastName = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!StaffInfo.UserName) {
      action(() => {
        errors.UserName = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!StaffInfo.Email) {
      action(() => {
        errors.Email = 'This field is required';
      })();
      const { isRerender } = this.state;
      this.setState({ isRerender: !isRerender });
    }
    if (!!Object.keys(toJS(AddEditStaffStore.errors)).length) {
      const errorList = Object.entries(toJS(AddEditStaffStore.errors));
      notification.destroy();
      return errorList.map(([key, value]) => this.retrunErrorMessage(key, value));
    }

    AddEditStaffStore.handleSaveStaff()
      .then(() => {
        AddEditStaffStore.setFieldsValue({ loadingSave: false });
      })
      .catch(error => {
        const errors = Object.entries(error);
        AddEditStaffStore.setFieldsValue({ loadingSave: false });
        return errors.map(([key, value]) => {
          if (key === 'Email' && value === 'exists') {
            this.showFormErrorNoti(
              `The registered email has been used in another account. Please check for duplicate account under Login Accounts section`,
            );
          } else {
            this.showFormErrorNoti(`${key} is ${value}`);
          }
        });
        // this.showFormErrorNoti('An error occurred, please try again');
      });
  };

  handleClose = () => {
    const { isChanged } = AddEditStaffStore;
    if (!!Object.keys(isChanged).length) {
      return AddEditStaffStore.toggleModal(true, {
        modalType: 'confirm',
        onOk: () => dashboardStore.close(router.location.pathname),
        message: 'Are you sure not to save changes?',
      })();
    } else {
      return dashboardStore.close(router.location.pathname);
    }
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 0:
        return <GeneralInformation />;
      case 1:
        return <ActionRequired />;
      default:
        return <GeneralInformation isRerender={this.state.isRerender} />;
    }
  };

  render() {
    const { loading, loadingSave, open } = AddEditStaffStore;
    return (
      <div className="page-container add-edit-client">
        {open && <AddEditModal />}
        <Dimmer active={loading || loadingSave} inverted>
          <Spin size="large" />
        </Dimmer>
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {/* <h1 className="form-title">General Information</h1> */}
          {this.renderForm()}
          {/* <GeneralInformation isRerender={this.state.isRerender} /> */}
        </div>
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              //disabled={!StaffInfo.Roles || StaffInfo.Roles.find(i => i === 'magstaff').length <= 0}
            >
              {idx + 1}. {step}
            </Button>
          ))}
          {/* <Button fluid size="large" className="step active">
            1. General Information
          </Button> */}
          {AddEditStaffStore.openModalAction && <ActionRequiredModal />}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && <Button primary onClick={this.handleSave}>
            Save
          </Button>}
          <Button className="negative" onClick={this.handleClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditStaff;
