import './DoctorCardList.scss';

import React from 'react';
import { Icon } from 'semantic-ui-react';

function DoctorCardList({ data }) {
  return (
    <div className="Doctor-card-list">
      {!data || !data.length ? (
        <div className="empty-message">No Data Found</div>
      ) : (
        data.map(doc => (
          <div key={doc.id} className="Doctor-card-item">
            <img alt={doc.fullname} src={doc.avatar} />
            <div className="Doctor-info">
              <h3 className="doctor-name">
                {doc.fullname} ({doc.title}.)
              </h3>
              <span className="doctor-specialty">{doc.specialty}</span>
            </div>
            <div className="Doctor-info-contact-list">
              <Icon name="mobile alternate" disabled={!doc.mobilePhone} />
              <Icon name="phone" rotated="clockwise" disabled={!doc.phone} />
              <Icon name="mail" disabled={!doc.email} />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default DoctorCardList;
