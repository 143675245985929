import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import LoginUserStore from './LoginUserStore';
import ModalAddOrEdit from './ModalAddOrEdit';
import ModalLoginHistory from './ModalLoginHistory';
import ModalNotification from './ModalNotification';

function LoginUserModal() {
  const { modalType } = LoginUserStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...LoginUserStore} onCancel={LoginUserStore.toggleModal(false)} />;
  }
  if (modalType === 'notification') {
    return <ModalNotification {...LoginUserStore} onCancel={LoginUserStore.toggleModal(false)} />;
  }
  if (modalType === 'addOrEdit') {
    return <ModalAddOrEdit {...LoginUserStore} onCancel={LoginUserStore.toggleModal(false)} />;
  }
  if (modalType === 'showLoginHistory') {
    return <ModalLoginHistory {...LoginUserStore} onCancel={LoginUserStore.toggleModal(false)} />;
  }
}

export default LoginUserModal;
