import { action, observable, computed } from 'mobx';
import moment from 'moment';
import { getFundingReportInfo } from '../../admin-routes/FundingReport/Service';

const INIT_FUNDING_REPORT = {
  Id: 0,
  FundingProviderId: null,
  FundingAgreementId: null,
  InvoiceId: null,
  CaseId: null,
  ServiceType: null,
  ReportFee: null,
  ReportPaymentDate: null,
  EstimateSettlementDate: null,
  ActualSettlementDate: null,
  SettlementStatus: 0,
  Discount: null,
  UnpaidSettlementStatusDate: null,
  OverdueSettlementStatusDate: null,
  PartPaySettlementStatusDate: null,
  FullPaySettlementStatusDate: null,
  Attachments: [],
};

class Store {
  @observable loading = false;
  @observable open = false;
  @observable fundingReportInfo = INIT_FUNDING_REPORT;
  @observable modalParams = {};
  @observable lstFileAttachments = [];
  @observable lstFundingRequest = [];
  @observable lstFundedCompanies = [];

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };
  @action setLstData = (name, lst) => {
    this[name] = lst;
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action fetchData = id => {
    this.loading = true;
    Promise.all([
      getFundingReportInfo(id),
      //getFundingRequest(''),
      //getFundedCompanies(''),
    ]).then(
      action(
        ([
          infoResp,
          //fundingRqResp,
          //fundedCompaniesResp
        ]) => {
          // if(fundingRqResp.status === 'success'){
          //     this.setLstData('lstFundingRequest', fundingRqResp.itemList);
          // }
          // if(fundedCompaniesResp.status === 'success'){
          //   this.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
          // }
          this.fundingReportInfo = {
            ...infoResp.objItem,
            InvoiceObj: infoResp.objItem.InvoiceObj
              ? {
                  Id: infoResp.objItem.InvoiceObj.Id,
                  InvoiceNo: infoResp.objItem.InvoiceObj.InvoiceNo,
                  DateOfInvoice: this.momentToJS(infoResp.objItem.InvoiceObj.DateOfInvoice),
                }
              : {},

            // SigningDate: this.momentToJS(infoResp.objItem.SigningDate),
            // FundingPeriodFrom: this.momentToJS(infoResp.objItem.FundingPeriodFrom),
            // FundingPeriodTo: this.momentToJS(infoResp.objItem.FundingPeriodTo),
            // FacilityExpiryDate: this.momentToJS(infoResp.objItem.FacilityExpiryDate),
          };
          if (
            this.fundingReportInfo &&
            this.fundingReportInfo.Attachments &&
            this.fundingReportInfo.Attachments.length > 0
          ) {
            const newAttachments = [...this.fundingReportInfo.Attachments].map(i => ({
              ...i,
              key: i.FileName,
            }));
            this.setLstData('lstFileAttachments', newAttachments);
          } else {
            this.setLstData('lstFileAttachments', []);
          }
          console.log(this.fundingReportInfo);
          this.loading = false;
        },
      ),
    );
  };

  @action initData = () => {
    this.fundingReportInfo = { ...INIT_FUNDING_REPORT };
    this.setLstData('lstFileAttachments', []);
    // Promise.all([getFundingRequest(''),
    //     getFundedCompanies(''),
    //   ]).then(
    //     action(([fundingRqResp, fundedCompaniesResp]) => {
    //       if(fundingRqResp.status === 'success'){
    //           this.setLstData('lstFundingRequest', fundingRqResp.itemList);
    //       }
    //       if(fundedCompaniesResp.status === 'success'){
    //         this.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
    //       }
    //     })
    //   )
  };

  @action onReset = () => {
    this.fundingReportInfo = INIT_FUNDING_REPORT;
  };

  @action handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      this.fundingReportInfo[fieldName] = value;
    });

  @action handleDateFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      this.fundingReportInfo[fieldName] = value;
    });

  @action handleChangeFieldValue = (_, { name, value }) => {
    if (name === 'SettlementStatus') {
      if (value === 0 && !this.fundingReportInfo.UnpaidSettlementStatusDate) {
        this.fundingReportInfo.UnpaidSettlementStatusDate = moment();
      } else if (value === 1 && !this.fundingReportInfo.OverdueSettlementStatusDate) {
        this.fundingReportInfo.OverdueSettlementStatusDate = moment();
      } else if (value === 2 && !this.fundingReportInfo.PartPaySettlementStatusDate) {
        this.fundingReportInfo.PartPaySettlementStatusDate = moment();
      } else if (value === 3 && !this.fundingReportInfo.FullPaySettlementStatusDate) {
        this.fundingReportInfo.FullPaySettlementStatusDate = moment();
      }
    }
    this.fundingReportInfo[name] = value;
  };

  @action handleCheckboxFieldChange = fieldName =>
    action(event => {
      const value = !this.fundingAgreementInfo[fieldName];
      this.fundingReportInfo[fieldName] = value;
    });
}

export default new Store();
