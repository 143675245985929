import './index.scss';

import { observer } from 'mobx-react';
import React from 'react';

import JobTitleStore from './JobTitleStore';
import Form from './Form';
import Modal from './Modal';
import router from '../../../stores/router';
import { debounce } from 'lodash';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class JobTitle extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/add-edit-job-title-2', true);
      return;
    }
    this._searchDebounced();
    ui.on('open(/view/add-edit-job-title-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search, state } = router.location;
    const Id = parseInt(search.slice(4));

    if (Id !== 0) {
      if (state) {
        const { record } = state;
        JobTitleStore.setFieldsValue({
          dataJobTitle: {
            id: Id,
            name: record.Label || '',
            descriptions: record.Description || '',
            isActive: record.IsActive,
          },
        });
      }
    } else {
      JobTitleStore.onReset();
    }
  }, 100);

  render() {
    const { open } = JobTitleStore;

    return (
      <div className={`form-add-edit-container`}>
        <React.Fragment>
          <Form />
          {open && <Modal />}
        </React.Fragment>
      </div>
    );
  }
}

export default observer(JobTitle);
