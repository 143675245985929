import customFetch from '@utils/customFetch';

export const getSupplementReport = (id = 0, mType = '', mId = 0) => {
  return customFetch('/Supplementary/PostSupplementaryInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, mType, mId }),
  });
};

export const getWiroPrincipleLawyer = () => {
  return customFetch('/Supplementary/GetWIROPrincipleLawyer', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getSUPPStatusLogs = id => {
  return customFetch('/Supplementary/GetSupplementaryLogs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getIMSCode = () => {
  return customFetch('/Supplementary/GetIMSApprovalCode', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getCaseManagerOnboarded = ({ keyword = '', numberItem = 100, companyId = 0, IsOnboarded = false }) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
      companyId,
      IsOnboarded,
    }),
  });
};

export const getSpecificLookupConfigOrderNoByType = () => {
  return customFetch('/Default/GetSpecificLookupConfigOrderNoByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'PapToDoc' }),
  });
};

export const getCaseCorrespondence = (SupplementaryId = 0) => {
  return customFetch('/Supplementary/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ SupplementaryId }),
  });
};

export const getServiceItem = () => {
  return customFetch('/MAGService/GetAllByStatus', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      NumItemPerPage: 500,
      Showing: 'active',
    }),
  });
};

export const getActionRequired = (suppId = 0) => {
  return customFetch('/Supplementary/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ suppId }),
  });
};

export const getTypeOfClaim = () => {
  return customFetch('/Supplementary/GetActiveClaimTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getSpecialist = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/MedicalDoctor/GetAllWithoutNCI', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
      RecruitedOrContractedOnly: true,
    }),
  });
};

export const getClaimant = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/PatientInfo/GetPatientList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getClient = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getStaff = () => {
  return customFetch('/Supplementary/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getTypist = () => {
  return customFetch('/Default/GetTypists', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getInvoice = (objectId = 0) => {
  return customFetch('/Supplementary/GetListInvoiceID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectId, type: 'Supplementary' }),
  });
};

export const getCurrentRate = (doctorId = 0) => {
  return customFetch(`/medicaldoctor/getdoctordetails?id=${doctorId}`, {
    method: 'GET',
  });
};

export const submitConversation = data => {
  return customFetch('/Supplementary/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};

export const getAssignHistory = ({ id, actionName }) => {
  return customFetch('/Supplementary/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const getCompanyRecentChangeHistory = (id, actionName) => {
  return customFetch('/Default/GetAssignedCompanyHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const saveSupplementary = bodyParam => {
  return customFetch('/Supplementary/PostSupplementary_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyParam),
  });
};

export const getListInvoiceID = (objectId = 0) => {
  return customFetch('/Supplementary/GetListInvoiceID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectId: objectId, type: 'Supplementary' }),
  });
};

export const updateSuppReportStatus = (id, status) => {
  return customFetch('/Supplementary/ChangeStatusSUPP', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ supplementaryId: id, caseFlow: status }),
  });
};

export const getFullNameInCC = (type, keyword) => {
  return customFetch('/Supplementary/GetFullNameInCC?type=' + type + '&keyword=' + keyword, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const reSendUrgentReportRequest = (type, caseId, resendType) => {
  return customFetch('/MedicalService/ReSendUrgentReportRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type, objId: caseId, resendType: resendType }),
  });
};

export const resendPaperworkReadiness = id => {
  return customFetch('/Supplementary/ResendPaperworkReadiness', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getUserUploadInfo = id => {
  return customFetch('/Manager/GetUserById', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: id }),
  });
};

export const getCompanyInsuer = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
      IcareInsurer: true, //Insurer
    }),
  });
};

export const getLookUpConfig = (type = '') => {
  return customFetch('/Default/GetLookUpConfig', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type }),
  });
};

export const getLookupIcareReport = (
  types = [
    'IMEReportCapacity',
    'IMEReportDiagnosis',
    'IMEReportLiability',
    'IMEReportTreatment',
    'IMEReportWPI',
    'IMEReportPsyWPI',
    'IMEReportIMC',
    'IcareServiceType',
  ],
) => {
  return customFetch('/Default/GetLookupConfigByTypes2', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ types: types }),
  });
};

export const sendICareCompletionRequest = (id) => {
  return customFetch('/Supplementary/SendICareCompletionRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id }),
  });
};