import { observer } from 'mobx-react';
import React from 'react';
import VideoSessionStore from './VideoSessionStore';
import router from '@stores/router';
import ScopeLog from 'assets/images/scope-video/logo.png';
import './JaasVideoHomePage.scss';
import { Input, Button, Spin, Form } from 'antd';

@observer
class JaasViewSession extends React.Component {
  state = {
    room: null,
  };

  constructor(props) {
    super(props);
    const params = new URLSearchParams(router.location.search);
    const room = params.get('room');
    this.state.room = room;
  }

  onRoomChange = event => {
    this.setState({ room: event.target.value });
  };

  joinMeeting = () => {
    window.location = window.location.origin + '/view/video-meeting?token=' + this.state.room;
  };

  render() {
    return (
      <div className="scope-video-container">
        <img className="scope-logo" alt="scope-logo" src={ScopeLog} />
        <h1 className="title">Scope Telehealth</h1>
        <Form className="form-container">
          <Form.Item label="Room">
            <Input className="" size="large" value={this.state.room} onChange={this.onRoomChange} />
          </Form.Item>
          <Button type="primary" size="large" onClick={this.joinMeeting}>
            Join Meeting
          </Button>
        </Form>
      </div>
    );
  }
}

export default JaasViewSession;
