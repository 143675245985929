import { action, observable } from 'mobx';
import customFetch from '../../../utils/customFetch';
import moment from 'moment';

class Store {
  @observable loading = false;

  @observable keyword = '';
  @observable messages = [];

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action onSearchChange = keyword => {
    this.keyword = keyword;
    return this.fetchFiles();
  };

  @action fetchFiles = () => {
    this.loading = true;
    const formatTime = 'DD/MM/YYYY HH:mm:ss';
    this.messages = [...this.messages, { type: 'user', text: this.keyword, sentDate: moment().format(formatTime) }];
    localStorage.setItem('ChatMessageGPT', JSON.stringify(this.messages));
    return customFetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        messages: [
          {
            role: 'user',
            content: this.keyword,
          },
        ],
        model: 'gpt-3.5-turbo',
      }),
    })
      .then(
        action(res => {
          this.messages = [
            ...this.messages,
            { type: 'chatGPT', text: res?.choices[0]?.message?.content, sentDate: moment().format(formatTime) },
          ];
          localStorage.setItem('ChatMessageGPT', JSON.stringify(this.messages));
          this.loading = false;
          this.keyword = '';
        }),
      )
      .catch(
        action(e => {
          this.loading = false;
          this.keyword = '';
          this.messages = [
            ...this.messages,
            {
              type: 'chatGPT',
              text:
                'An error occurred. If this issue persists please contact us through our help center at help.openai.com.',
              sentDate: moment().format(formatTime),
            },
          ];
        }),
      );
  };

  @action resetStore = () => {
    this.keyword = '';
    this.messages = [];
    localStorage.setItem('ChatMessageGPT', JSON.stringify(this.messages));
  };
}
export default new Store();
