import React from 'react';
import { Form, Checkbox, Radio, Input, DatePicker, Select, notification } from 'antd';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import DragDropFile from './../../shared/DragDropFile';
import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import AddEditStore from './store';
import { toJS, action, observable } from 'mobx';
import CKEditor from './../../admin-routes/MessageTemplate/CKEditor';
import { File, NoFile } from '../AddEditDoctor/doctor-detail/Upload';
import * as api from '@stores/api';
import ModalConfirm from './ModalConfirm';
import { loadTalkingPoint } from './service';
import dashboardStore from '../../../stores/dashboard';
import { CMRelationshipActivityResults, CCServicesType } from '@utils/constants';
import { string } from 'prop-types';
//import { Link } from 'react-router-dom';

const conversationType = [
  { Name: 'Phone', Id: 0, Visible: false },
  { Name: 'Email', Id: 1, Visible: false },
  { Name: 'Email (Send Now)', Id: 4, Visible: false },
  { Name: 'Private Notes', Id: 5, Visible: true },
  { Name: 'Relationship Meeting', Id: 2, Visible: true },
  { Name: 'Relationship Call', Id: 8, Visible: true },
  { Name: 'Relationship Email', Id: 9, Visible: true },
  { Name: 'Relationship General Activity', Id: 3, Visible: true },
];

@observer
class ConversationModalForm extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};
  @observable clientInfo = {};
  @observable lstTalkingPoints = [];

  @action setClientInfo = info => {
    this.clientInfo = info;
  };

  @action setTalkingPoints = lstItems => {
    this.lstTalkingPoints = lstItems;
  };

  componentDidMount() {
    const { CMInfo } = AddEditStore;
    if (CMInfo && CMInfo.CompanyId) {
      loadTalkingPoint(CMInfo.CompanyId).then(resp => {
        this.setClientInfo(resp.clientModel);
        this.setTalkingPoints(resp.itemList);
      });
    }
  }

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };

  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  converDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString, true);
          const newDate = moment(dateFormat, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
          return moment(newDate, 'DD/MM/YYYY - HH:mm');
        }
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      } else {
        return moment(dateString, 'DD/MM/YYYY - HH:mm');
      }
    }
    return null;
  };

  handleSearch = val => {
    AddEditStore.refetchSearchCM({ keywordCM: val });
  };
  handleScroll = event => {
    const target = event.target;
    const number = AddEditStore.numberItemCM;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      AddEditStore.refetchSearchCM({ numberItemCM: number + 20 });
    }
  };
  converMinuteOrHour = (datestr, type) => {
    if (datestr) {
      if (typeof datestr === 'string') {
        if (type === 'hour') {
          return moment(datestr, 'DD/MM/YYYY - HH:mm').format('HH');
        }
        return moment(datestr, 'DD/MM/YYYY - HH:mm').format('mm');
      } else {
        if (type === 'hour') {
          return datestr.format('HH');
        } else {
          return datestr.format('mm');
        }
      }
    }
    return 0;
  };

  checkIsNumber = str => {
    if (typeof str !== 'string') {
      if (typeof str === 'number') return true;
      return false;
    } else {
      return !isNaN(str) && !isNaN(parseFloat(str));
    }
  };

  detectStringOrNumber = value => {
    return this.checkIsNumber(value) ? parseInt(value) : value;
  };

  isRelationshipType = type => {
    return type === 2 || type === 8 || type === 9;
  };
  handleSave = isSendMail => {
    const { conversationParam, CMInfo } = AddEditStore;
    const date = conversationParam.SentDate ? this.converDate(conversationParam.SentDate).format('YYYY-MM-DD') : null;
    const date_ = date ? date : null;
    const body = {
      hour: parseInt(this.converMinuteOrHour(conversationParam.SentDate, 'hour')),
      minute: parseInt(this.converMinuteOrHour(conversationParam.SentDate, 'minute')),
      CaseManagerId: CMInfo.Id,
      CallerSender: this.detectStringOrNumber(conversationParam.CallerSender),
      CallerSenderName: conversationParam.CallerSenderName,
      CallerSenderType: conversationParam.CallerSenderType,
      Content: conversationParam.Content,
      ConversationType: conversationParam.ConversationType,
      Id: conversationParam.Id,
      Receiver: this.detectStringOrNumber(conversationParam.Receiver),
      ReceiverName: conversationParam.ReceiverName,
      ReceiverType: conversationParam.ReceiverType,
      SentDate: conversationParam.SentDate, //date_.length > 10 ? date_.substring(0, 10) : date_,
      CallEmailResultId: conversationParam.CallEmailResultId,
      FollowUpNeeded: conversationParam.FollowUpNeeded,
      NextDueDate: conversationParam.NextDueDate,
      Subject: conversationParam.Subject,
      allowCMToView: conversationParam.allowCMToView,
      allowDoctorToView: conversationParam.allowDoctorToView,
      callerSenderId: this.detectStringOrNumber(conversationParam.CallerSender),
      receiverId: this.detectStringOrNumber(conversationParam.Receiver),
      isSendMail: isSendMail,
      campaignId: conversationParam.CampaignId,
      campaignObj: conversationParam.CampaignObj,
      attachment: AddEditStore.uploadFile,
      CCServiceTypeId: conversationParam.CCServiceTypeId,
      CCServiceTypeOther: conversationParam.CCServiceTypeOther,
      CategoriesIds:
        conversationParam.CategoriesIds && conversationParam.CategoriesIds.length > 0
          ? JSON.stringify(conversationParam.CategoriesIds)
          : null,
    };

    if (this.isRelationshipType(body.ConversationType) && !body.CallEmailResultId) {
      return notification.error({
        message: 'Error',
        description: 'Please select Relationship Activity Result',
        duration: 5,
      });
    }

    if (!body.Subject) {
      return notification.error({
        message: 'Error',
        description: 'Please enter subject',
        duration: 5,
      });
    }
    if (!body.Receiver) {
      return notification.error({
        message: 'Error',
        description: 'Please select a Receiver',
        duration: 5,
      });
    }
    if (!body.CallerSender) {
      return notification.error({
        message: 'Error',
        description: 'Please select a Caller/Sender',
        duration: 5,
      });
    }
    if (!body.SentDate) {
      return notification.error({
        message: 'Error',
        description: 'Please enter a Sent Date',
        duration: 5,
      });
    }
    AddEditStore.handleAdd(body);
    this.props.onCancel();
  };

  handleFieldChangeSelectMulti = fieldName =>
    action(event => {
      const { conversationParam, CMInfo } = AddEditStore;
      const value = event ? (event.target ? event.target.value : event) : null;
      conversationParam[fieldName] = value;
    });

  openTalkingPoint = () => {
    dashboardStore.open(`/view/talking-point`, true);
  };

  ckEditorRef = React.createRef();
  render() {
    const { visible, onCancel } = this.props;
    const { lstTalkingPoints } = this;
    const { conversationParam, staffList, CMInfo, campaignMergeAttachments, campaignDetail } = AddEditStore;
    const titleModal = conversationParam.Id === 0 ? 'Add New' : conversationParam.Subject;
    var strCMFullName = CMInfo.FirstName + ' ' + CMInfo.LastName;
    if (CMInfo.Telephone) strCMFullName += ' - ' + CMInfo.Telephone;
    if (CMInfo.Email) strCMFullName += ' - ' + CMInfo.Email;
    var arrCMs = [{ Id: CMInfo.Id, FullName: strCMFullName }];
    return (
      <Modal
        width={1000}
        onCancel={onCancel}
        visible={visible}
        footer={
          <React.Fragment>
            {api.isAdminOrMagStaffOrAccounting() && !api.isOnlySearch() && (
              <Button
                color="blue"
                //onClick={() => this.handleSave(true)}
                onClick={() => {
                  this.handleOpenConfirm(true, {
                    modalType: 'confirm',
                    message: `Are you sure to send this correspondence?`,
                    onOk: () => {
                      this.handleSave(true);
                      this.handleCancelConfirm();
                    },
                  });
                }}
                disabled={conversationParam.CallerSenderType !== 3}
              >
                Save & Send
              </Button>
            )}
            <Button color="blue" onClick={() => this.handleSave(false)}>
              Save
            </Button>
            <Button color="red" onClick={onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={titleModal}
      >
        <Form name="conversation" className="conversation-form">
          <div className="checkbox-field">
            <Checkbox
              checked={conversationParam.allowCMToView}
              onChange={AddEditStore.handleCheckedConversation('allowCMToView')}
            >
              Allow CMs to view
            </Checkbox>
          </div>
          <Form.Item label="Correspondence Type">
            <Select
              size="large"
              value={conversationParam.ConversationType}
              onChange={AddEditStore.handleFieldChangeConversation('ConversationType')}
            >
              {conversationType
                .filter(i => !i.Visible)
                .map(i => (
                  <Select.Option style={{ display: 'none' }} key={i.Id} value={i.Id}>
                    {i.Name}
                  </Select.Option>
                ))}
              {conversationType
                .filter(i => i.Visible)
                .map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Name}
                  </Select.Option>
                ))}
            </Select>
            {conversationParam.ConversationType === 4 && (
              <span style={{ color: 'red' }}>Emails will be sent after conversation is saved</span>
            )}
          </Form.Item>
          <Form.Item className="fluid-field" label="Service Type">
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={conversationParam.CCServiceTypeId}
              onChange={AddEditStore.handleFieldChangeConversation('CCServiceTypeId')}
            >
              {CCServicesType.filter(i => !i.Visible).map(({ Id, Name }) => (
                <Select.Option style={{ display: 'none' }} key={Id} value={Id}>
                  {Name}
                </Select.Option>
              ))}
              {CCServicesType.filter(i => i.Visible).map(({ Id, Name }) => (
                <Select.Option key={Id} value={Id}>
                  {Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Talking Points
                <button className="field-actions" onClick={() => this.openTalkingPoint()}>
                  Open Talking Points
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              mode="multiple"
              optionFilterProp="children"
              value={toJS(conversationParam.CategoriesIds) || []}
              onChange={this.handleFieldChangeSelectMulti('CategoriesIds')}
            >
              {lstTalkingPoints.map(({ Id, CategoryTitle, TalkingPointText }) => (
                <Select.Option key={Id} value={Id}>
                  {CategoryTitle} - {TalkingPointText}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {conversationParam.CCServiceTypeId == 99 && (
            <Form.Item className="fluid-field">
              <Input
                size="large"
                placeholder="Enter detail"
                value={conversationParam.CCServiceTypeOther}
                onChange={AddEditStore.handleFieldChangeConversation('CCServiceTypeOther')}
              />
            </Form.Item>
          )}
          {this.isRelationshipType(conversationParam.ConversationType) && (
            <>
              <Form.Item
                label="Relationship Activity Result"
                className="fluid-field"
                required
                validateStatus={!conversationParam.CallEmailResultId ? 'error' : null}
                help={!conversationParam.CallEmailResultId ? 'This field is required' : null}
              >
                <Select
                  showSearch
                  size="large"
                  optionFilterProp="children"
                  value={conversationParam.CallEmailResultId}
                  onChange={AddEditStore.handleFieldChangeConversation('CallEmailResultId')}
                  //placeholder="Select a campaign"
                >
                  {CMRelationshipActivityResults.filter(i => !i.Visible).map(({ Id, Name }) => (
                    <Select.Option style={{ display: 'none' }} key={Id} value={Id}>
                      {Name}
                    </Select.Option>
                  ))}
                  {CMRelationshipActivityResults.filter(i => i.Visible).map(({ Id, Name }) => (
                    <Select.Option key={Id} value={Id}>
                      {Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item className="fluid-field">
                <Checkbox
                  checked={conversationParam.FollowUpNeeded}
                  onChange={AddEditStore.handleCheckedConversation('FollowUpNeeded')}
                >
                  Follow-up Activity Needed
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Follow-up Due Date"
                required={conversationParam.FollowUpNeeded}
                validateStatus={conversationParam.FollowUpNeeded && !conversationParam.NextDueDate ? 'error' : null}
                help={
                  conversationParam.FollowUpNeeded && !conversationParam.NextDueDate ? 'This field is required' : null
                }
                className="fluid-field"
              >
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={this.converDate(conversationParam.NextDueDate)}
                  onChange={AddEditStore.handleFieldChangeConversation('NextDueDate')}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Link to Marketing Campaign" className="fluid-field">
            {/* <label style={{ display: 'inline-block', marginBottom: '8px' }}>
              Marketing Campaign:
              <Link to="#" onClick={this.getCampaignDetail(conversationParam.CampaignId)}>
                {' '}
                View Detail
              </Link>
            </label> */}
            <Select
              showSearch
              allowClear
              size="large"
              optionFilterProp="children"
              value={conversationParam.CampaignId}
              onChange={value => {
                if (value) {
                  AddEditStore.getCampaignDetail(value);
                } else {
                  AddEditStore.setFieldsValue({ campaignDetail: {} });
                }
                AddEditStore.handleFieldChangeConversation('CampaignId')(value);
              }}
              onSearch={AddEditStore.handleSearchCampaign()}
              placeholder="Select a campaign"
              // removeIcon={true}
              // clearIcon={true}
            >
              {AddEditStore.campaginList &&
                conversationParam.CampaignId &&
                conversationParam.CampaignObj &&
                !AddEditStore.campaginList.find(i => i.Id === conversationParam.CampaignId) && (
                  <Select.Option
                    style={{ display: 'none' }}
                    value={conversationParam.CampaignId}
                    key="campaign-default-id"
                  >
                    {`${conversationParam.CampaignObj.Id} - ${
                      conversationParam.CampaignObj.CampaignName
                    } | Start: ${moment(conversationParam.CampaignObj.CampaignDate).format('DD/MM/YYYY')} ${
                      conversationParam.CampaignObj.CampaignEndDate
                        ? '- End: ' + moment(conversationParam.CampaignObj.CampaignEndDate).format('DD/MM/YYYY')
                        : ''
                    }`}
                  </Select.Option>
                )}
              {AddEditStore.campaginList.map(({ Id, CampaignName, CampaignDate, CampaignEndDate }) => (
                <Select.Option key={Id} value={Id}>
                  {`${Id} - ${CampaignName} | Start: ${moment(CampaignDate).format('DD/MM/YYYY')} ${
                    CampaignEndDate ? '- End: ' + moment(CampaignEndDate).format('DD/MM/YYYY') : ''
                  }`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {conversationParam.CampaignId > 0 && campaignMergeAttachments && (
            <Form.Item label="Campaign Attachments" className="fluid-field campaignAttachments">
              {campaignMergeAttachments.length <= 0 ? (
                <NoFile />
              ) : (
                <div>
                  {campaignMergeAttachments &&
                    campaignMergeAttachments.map(i => (
                      <div className="mb-1" key={i.FileName}>
                        <File name={i.Title} downloadLink={`/UserFile/CampaignAttachment/${i.FileName}`} />
                      </div>
                    ))}
                </div>
              )}
            </Form.Item>
          )}
          <Form.Item
            label="Date/Time"
            required
            validateStatus={!conversationParam.SentDate ? 'error' : null}
            help={!conversationParam.SentDate ? 'This field is required' : null}
          >
            <DatePicker
              size="large"
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY - HH:mm"
              value={this.converDate(conversationParam.SentDate)}
              onChange={AddEditStore.handleFieldChangeConversation('SentDate')}
            />
          </Form.Item>
          <Form.Item
            label="Subject"
            required
            validateStatus={!conversationParam.Subject ? 'error' : null}
            help={!conversationParam.Subject ? 'This field is required' : null}
          >
            <Input
              size="large"
              value={conversationParam.Subject}
              onChange={AddEditStore.handleFieldChangeConversation('Subject')}
            />
          </Form.Item>
          {/* <Form.Item label="Content">
            <Input.TextArea
              rows={4}
              value={conversationParam.Content}
              onChange={AddEditStore.handleFieldChangeConversation('Content')}
            />
          </Form.Item> */}
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label className="" title="Activity Content">
                Activity Content
              </label>
              <span
                style={{ cursor: 'pointer', color: '#2185d0' }}
                onClick={() => {
                  if (campaignDetail?.CampaignContent) {
                    this.ckEditorRef.current.setNewHtml(campaignDetail?.CampaignContent);
                  }
                }}
              >
                Copy Campaign Content
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="This will copy the selected marketing campaign content to activity content blow"
                  position="top center"
                  wide="very"
                />
              </span>
            </div>
            <CKEditor
              ref={this.ckEditorRef}
              html={conversationParam.Content}
              onChangeValue={AddEditStore.handleFieldChangeConversation('Content')}
            />
          </div>
          {api.isAdminOrMagStaffOrAccounting() && (
            <div style={{ marginTop: '10px' }}>
              <DragDropFile
                onUploadFile={AddEditStore.UploadFile}
                onDeleteFile={AddEditStore.DeleteFile}
                fileList={AddEditStore.uploadFile}
                type="0"
                title="Attachment"
                maxSize="50"
                multiple={true}
              />
              <hr></hr>
            </div>
          )}
          <Form.Item
            label="Caller/Sender"
            required
            validateStatus={!conversationParam.CallerSender ? 'error' : null}
            help={!conversationParam.CallerSender ? 'This field is required' : null}
          >
            <Radio.Group
              value={conversationParam.CallerSenderType}
              onChange={AddEditStore.handleFieldChangeConversation('CallerSenderType')}
              disabled={this.isRelationshipType(conversationParam.ConversationType)}
            >
              <Radio value={1}>Case Manager</Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            {conversationParam.CallerSenderType === 1 ? (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.CallerSender),
                  label: toJS(conversationParam.CallerSenderName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('CallerSender')}
                // onSearch={this.handleSearch}
                // onPopupScroll={this.handleScroll}
                // optionFilterProp="children"
                // showSearch
                style={{ marginTop: '10px' }}
                placeholder="Select Case Manager"
              >
                {arrCMs &&
                  arrCMs.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.FullName}
                      {i.Telephone ? ' - ' + i.Telephone : ''}
                      {i.Email ? ' - ' + i.Email : ''}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.CallerSender),
                  label: toJS(conversationParam.CallerSenderName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('CallerSender')}
                placeholder="Select Client Officer"
                style={{ marginTop: '10px' }}
              >
                {staffList &&
                  staffList.itemList.map(i => (
                    <Select.Option value={i.Id} key={i.Id}>
                      {i.FullName ?? `${i.FirstName ?? '' + ' ' + i.LastName}`}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Receiver"
            required
            validateStatus={!conversationParam.Receiver ? 'error' : null}
            help={!conversationParam.Receiver ? 'This field is required' : null}
          >
            <Radio.Group
              value={conversationParam.ReceiverType}
              onChange={AddEditStore.handleFieldChangeConversation('ReceiverType')}
            >
              <Radio value={1}>Case Manager</Radio>
              <Radio value={3}>Staff</Radio>
            </Radio.Group>
            {conversationParam.ReceiverType === 1 ? (
              <Select
                size="large"
                onChange={AddEditStore.handleFieldChangeConversation('Receiver')}
                // onSearch={this.handleSearch}
                // onPopupScroll={this.handleScroll}
                // optionFilterProp="children"
                // showSearch
                labelInValue
                value={{
                  key: toJS(conversationParam).Receiver,
                  label: toJS(conversationParam).ReceiverName,
                }}
                style={{ marginTop: '10px' }}
                placeholder="Select Case Manager"
              >
                {arrCMs &&
                  arrCMs.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.FullName}
                      {i.Telephone ? ' - ' + i.Telephone : ''}
                      {i.Email ? ' - ' + i.Email : ''}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              <Select
                size="large"
                labelInValue
                value={{
                  key: toJS(conversationParam.Receiver),
                  label: toJS(conversationParam.ReceiverName),
                }}
                onChange={AddEditStore.handleFieldChangeConversation('Receiver')}
                style={{ marginTop: '10px' }}
                placeholder="Select Client Officer"
              >
                {staffList &&
                  staffList.itemList.map(i => (
                    <Select.Option value={i.Id} key={i.Id}>
                      {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Form>
        {this.openConfirm && (
          <ModalConfirm {...this.confirmParams} open={this.openConfirm} onCancel={this.handleCancelConfirm} />
        )}
      </Modal>
    );
  }
}

export default ConversationModalForm;
