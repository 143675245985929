import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import IMSApprovalCodeStore from './IMSApprovalCodeStore';

function IMSApprovalCodeModal() {
  const { modalType } = IMSApprovalCodeStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...IMSApprovalCodeStore} onCancel={IMSApprovalCodeStore.toggleModal(false)} />;
  }
}

export default IMSApprovalCodeModal;
