import React from 'react';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';

import './Progress.scss';

import * as api from '../../../stores/api';

import store from './store';
import { CR_CASE_PROGRESS } from './type';
import ProgressStatus from '../../shared/ProgressStatus/ProgressStatus';
import _ from 'lodash';

const statusGroups = [
  {
    name: 'Booking & Retrievals',
  },
  {
    name: 'Billing',
  },
  {
    name: 'Cancellation & On-Hold',
    type: 'radio',
  },
];

@observer
class Progress extends React.Component {
  handleUpdateStatus = statusItem => {
    if (store.clinicalNoteInfo.Id !== 0) {
      store.toggleModalConfirm(true, {
        modalType: 'confirm',
        onOk: () => store.updateStatus(statusItem),
        message: 'Are you sure to change case status?',
      });
    }
  };

  onClickStatus = (statusItem, itemModel) => {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      return;
    }
    if (
      api.isAdminOrMagStaffOrAccounting() &&
      itemModel.CancellationStatus !== 1 &&
      itemModel.CancellationStatus !== 2 &&
      itemModel.CancellationStatus !== 3
    ) {
      this.handleUpdateStatus(statusItem);
    } else {
      if (statusItem.id === 7 || statusItem.id === 8 || statusItem.id === 10) {
        this.handleUpdateStatus(statusItem);
      }
    }
  };

  onChangeProgress = key =>
    action(() => {
      if (key === 7) {
        store.onCancellationStatusChanged(1);
      }
      if (key === 8) {
        store.onCancellationStatusChanged(2);
      }
      if (key === 10) {
        store.onCancellationStatusChanged(3);
      }
      store.clinicalNoteInfo.CaseStatus = key;
    });

  render() {
    const itemModel = toJS(store.clinicalNoteInfo);

    let statusLog = toJS(store.clinicalLogs)?.itemList || [];
    const caseStatuses = CR_CASE_PROGRESS.map(item => {
      var status = _.last(statusLog.filter(i => i.status === item.key));
      return {
        ...item,
        changeByUser: status?.updatedUserName,
        statusDate: status?.statusDate,
        createdDate: status?.createdDate,
        label: item.label,
        checked: status != null,
        isCurrent: itemModel.CaseStatus === item.id,
      };
    });

    let groups = statusGroups.map(g => {
      var group = { ...g };

      var groupTasks = caseStatuses.filter(i => i.group === group.name);
      group.completed = false;
      group.inprogress = false;
      group.totalTask = group.type === 'radio' ? 1 : groupTasks.length;
      group.completedTaskCount = 0;
      group.active = groupTasks.some(i => i.id === itemModel.CaseStatus);
      group.inprogress = groupTasks.some(i => i.checked) || group.active;

      if (group.type === 'radio') {
        var checkedTasks = groupTasks.filter(i => i.checked);

        if (checkedTasks.length > 1) {
          checkedTasks.map(item => {
            item.checked = false;
            return item;
          });

          var latestStatus = _.maxBy(checkedTasks, i => i.createdDate);
          latestStatus.checked = true;
        }

        if (groupTasks.some(i => i.checked)) {
          group.completed = true;
          group.completedTaskCount = 1;
        }
      } else {
        if (groupTasks.every(i => i.checked)) {
          group.completed = true;
        }
        group.completedTaskCount = groupTasks.filter(i => i.checked).length;
      }

      return group;
    });

    return (
      <ProgressStatus
        statusGroups={groups}
        statuses={caseStatuses}
        onClickStatus={itemStatus => this.onClickStatus(itemStatus, itemModel)}
        description={() => (
          <div className="case-info">
            Case Info: <b>{`${store.clinicalNoteInfo.ClinicalNoteNumber} `}</b>
            {store.clinicalNoteInfo.Customer && (
              <span>
                - Client: <b>{`${store.clinicalNoteInfo.Customer.Name} `}</b>
              </span>
            )}
            {store.clinicalNoteInfo.CaseManager && (
              <span>
                - Booking CM: <b>{`${store.clinicalNoteInfo.CaseManager.FullName} `}</b>
              </span>
            )}
            {store.clinicalNoteInfo.Patient ? (
              <span>
                - Claimant:{' '}
                <b>{`${store.clinicalNoteInfo.Patient.FirstName} ${store.clinicalNoteInfo.Patient.LastName} `}</b>
              </span>
            ) : (
              ''
            )}
            {store.clinicalNoteInfo.Doctor ? (
              <span>
                - Specialist: <b>{`${store.clinicalNoteInfo.Doctor.FullName}`}</b>
              </span>
            ) : (
              ''
            )}
            {store.clinicalNoteInfo.Clinic ? (
              <span>
                - Clinic: <b>{`${store.clinicalNoteInfo.Clinic.Name}`}</b>
              </span>
            ) : (
              ''
            )}
          </div>
        )}
      />
    );
  }
}

export default Progress;
