import React from 'react';
import { Form, Input, Select, Checkbox, DatePicker, Divider } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import AddEditStore from './store';
import { formatDate } from '../../../utils/functions';
import { toJS } from 'mobx';
import { checkDuplicateEmail, checkDuplicateName, recentChangeStaff } from './service';
import { convertPhoneFaxNumber, convertMobileNumber } from '@utils/functions';
import * as api from '@stores/api';
import { renderDate } from '../Shared';

const stateOptions = [
  {
    value: 'ACT',
  },
  {
    value: 'NSW',
  },
  {
    value: 'VIC',
  },
  {
    value: 'QLD',
  },
  {
    value: 'SA',
  },
  {
    value: 'WA',
  },
  {
    value: 'TAS',
  },
  {
    value: 'NT',
  },
];

window.functionGetElementFormForResize = () => {
  return document.getElementById('contact-information-form');
};

@observer
class ContactInformation extends React.Component {
  handleSearch = val => {
    AddEditStore.refetchSearchClient({ keywordClient: val });
  };
  handleFieldAssignDate = dateStr => {
    if (dateStr) {
      if (dateStr.search('/Date') !== -1) {
        const date = formatDate(dateStr);
        return moment(date, 'DD MMM,YYYY').format('DD/MM/YYYY');
      }
      return dateStr;
    }
    return dateStr;
  };
  handleUnsubscribedDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
        }
        return dateStr;
      }
      return moment(dateStr).format('DD/MM/YYYY - HH:mm');
    }
    return '';
  };
  handleScroll = event => {
    const target = event.target;
    const number = AddEditStore.numberItemClient;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      AddEditStore.refetchSearchClient({ numberItemClient: number + 20 });
    }
  };

  handleOpenRecentChange = (record, type) => {
    if (type === 'client') {
    } else {
      Promise.all([recentChangeStaff(record.Id)]).then(([dataRecentChange]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange: dataRecentChange.itemList,
          record,
          type,
        })();
      });
    }
  };

  handleCheckDuplicate = (record, type) => {
    if (type === 'email') {
      Promise.all([checkDuplicateEmail(record.Id, record.Email)]).then(([duplicate]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'duplicate',
          dataDuplicate: duplicate.lstObjs,
          type,
        })();
      });
    } else {
      Promise.all([checkDuplicateName(record.Id, record.LastName)]).then(([duplicate]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'duplicate',
          dataDuplicate: duplicate.lstObjs,
          type,
        })();
      });
    }
  };

  renderPosition = Positions => {
    if (Positions) {
      if (typeof Positions === 'object') {
        const position = toJS(Positions);
        return position.Id;
      }
      return null;
    }
    return null;
  };
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        return moment(formatDate(dateString), 'DD MMM, YYYY');
      }
      return dateString;
    }
    return null;
  };

  openModal = options => {
    return AddEditStore.toggleModal(true, options);
  };

  handleChangeStatus = option => {
    if (option === 0) {
      AddEditStore.handleEnableCase(AddEditStore.CMInfo.Id);
    } else {
      AddEditStore.handleDisableCase(AddEditStore.CMInfo.Id);
    }
  };

  render() {
    const { CMInfo, clientList, staffList, loadingSearchClient, specificlookupList, greetingTitle } = AddEditStore;
    return (
      <Form id="contact-information-form" name="contact-information">
        {CMInfo.Id > 0 && (
          <Form.Item className="fluid-field">
            <Checkbox disabled={true} checked={CMInfo.IsActive}>
              Active Status{'  '}
              <Link to="#" onClick={this.openModal({ modalType: 'viewStatusHistory', id: CMInfo.Id, record: CMInfo })}>
                {' '}
                Recent changes{'  '}
              </Link>
              {!CMInfo.IsActive && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="green"
                  onClick={AddEditStore.toggleModal(true, {
                    modalType: 'confirm',
                    message: `Do you want to enable this typist?`,
                    onOk: () => this.handleChangeStatus(0),
                  })}
                >
                  Enable
                </Button>
              )}
              {CMInfo.IsActive && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="red"
                  onClick={this.openModal({ modalType: 'disable', modalParams: { Id: CMInfo.Id, Record: CMInfo } })}
                >
                  Disable
                </Button>
              )}
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item label="User ID">
          <Input size="large" value={CMInfo.Id} disabled />
        </Form.Item>
        <Form.Item label="Title">
          <Select size="large" value={CMInfo.Title} onChange={AddEditStore.handleFieldChange('Title')} allowClear>
            {greetingTitle.map((i, idx) => (
              <Select.Option key={idx} value={i.Title}>
                {i.Title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="First Name">
          <Input size="large" value={CMInfo.FirstName} onChange={AddEditStore.handleFieldChange('FirstName')} />
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Surname
              <button className="check-duplicate" onClick={() => this.handleCheckDuplicate(CMInfo, 'lastName')}>
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={CMInfo.LastName} onChange={AddEditStore.handleFieldChange('LastName')} />
        </Form.Item>
        <Form.Item
          label="Phone"
          validateStatus={AddEditStore.errors['OfficePhone'] ? 'error' : null}
          help={AddEditStore.errors['OfficePhone']}
        >
          <Input
            size="large"
            value={convertPhoneFaxNumber(CMInfo.OfficePhone)}
            onChange={AddEditStore.handleFieldChange('OfficePhone')}
          />
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['Telephone'] ? 'error' : null}
          help={AddEditStore.errors['Telephone']}
          label="Mobile"
        >
          <Input
            size="large"
            value={convertMobileNumber(CMInfo.Telephone)}
            onChange={AddEditStore.handleFieldChange('Telephone')}
          />
        </Form.Item>
        <Form.Item
          required={CMInfo.EnableLogin}
          validateStatus={AddEditStore.errors['Email'] ? 'error' : null}
          help={AddEditStore.errors['Email']}
          label={
            <React.Fragment>
              Email
              <button onClick={() => this.handleCheckDuplicate(CMInfo, 'email')} className="check-duplicate">
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={CMInfo.Email} onChange={AddEditStore.handleFieldChange('Email')} />
        </Form.Item>
        <Form.Item className="posstion-IsTypist-label" label={<span style={{ marginRight: '10px' }}>Position:</span>}>
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            value={this.renderPosition(CMInfo.Position)}
            onChange={AddEditStore.handleFieldChange('Position')}
            allowClear
          >
            {specificlookupList &&
              specificlookupList.itemList.map(i => (
                <Select.Option value={i.Id} key={i.Id}>
                  {i.Label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Suburb">
          <Input size="large" value={CMInfo.Suburb} onChange={AddEditStore.handleFieldChange('Suburb')} />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input size="large" value={CMInfo.Postcode} onChange={AddEditStore.handleFieldChange('Postcode')} />
        </Form.Item>
        <Form.Item label="State">
          <Select size="large" value={CMInfo.State} allowClear onChange={AddEditStore.handleFieldChange('State')}>
            {stateOptions.map((i, idx) => (
              <Select.Option key={idx} value={i.value}>
                {i.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div></div>
        {api.isAdminOrMagStaffOrAccounting() && (
          <>
            <Form.Item
              validateStatus={AddEditStore.errors['CompanyId'] ? 'error' : null}
              help={AddEditStore.errors['CompanyId']}
              label="Client"
            >
              <Select
                size="large"
                allowClear
                value={CMInfo.CompanyId}
                showSearch
                optionFilterProp="children"
                onChange={AddEditStore.handleFieldChange('CompanyId')}
                onSearch={this.handleSearch}
                onPopupScroll={this.handleScroll}
                loading={loadingSearchClient}
                disabled={CMInfo.IsTypist}
              >
                {clientList &&
                  clientList.itemList.map((i, idx) => (
                    <Select.Option value={i.Id} key={idx}>
                      {`(${i.Id}) - ${i.Name}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <React.Fragment>
                  Client Service Officer
                  <button onClick={() => this.handleOpenRecentChange(CMInfo, 'staff')} className="check-duplicate">
                    Recent changes
                  </button>
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={CMInfo.AssignedStaffId}
                allowClear
                onChange={AddEditStore.handleFieldChange('AssignedStaffId')}
              >
                {staffList &&
                  staffList.itemList.map(i => (
                    <Select.Option value={i.Id} key={i.Id}>
                      {i.FullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Assigned Date">
              <Input size="large" disabled value={this.handleFieldAssignDate(CMInfo.AssignedDate)} />
            </Form.Item>
          </>
        )}
        <div className="fluid-field">
          <Checkbox checked={!!toJS(CMInfo.UnsubscribedDate)} onChange={AddEditStore.handleChecked('Unsubscribed')}>
            Unsubscribe to Newsletters
          </Checkbox>
          {!!toJS(CMInfo.UnsubscribedDate) && (
            <span style={{ marginLeft: '30px' }}>
              Unsubscribed Date:
              {this.handleUnsubscribedDate(CMInfo.UnsubscribedDate)}
            </span>
          )}
        </div>

        <Form.Item label="Comments" className="fluid-field" style={{ marginBottom: 0 }}>
          <Input.TextArea rows={3} value={CMInfo.Comments} onChange={AddEditStore.handleFieldChange('Comments')} />
        </Form.Item>
        {api.isAdminOrMagStaffOrAccounting() && (
          <>
            <Divider className="fluid-field" />
            <h3 className="form-subtitle fluid-field">Recruitment Status</h3>
            <Form.Item
              validateStatus={AddEditStore.errors['RecruitmentStatus'] ? 'error' : null}
              help={AddEditStore.errors['RecruitmentStatus']}
            >
              <Checkbox checked={CMInfo.IsRecruiting} onChange={AddEditStore.handleChecked('IsRecruiting')}>
                Recruiting
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox onChange={AddEditStore.handleChecked('IsOnboarding')} checked={CMInfo.IsOnboarding}>
                Onboarding
              </Checkbox>
            </Form.Item>
            <Form.Item label="Recruiting Date">
              <DatePicker
                value={this.convertDate(CMInfo.RecruitingDate)}
                onChange={AddEditStore.handleFieldChange('RecruitingDate')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Onboarding Date">
              <DatePicker
                value={this.convertDate(CMInfo.OnboardingDate)}
                format="DD/MM/YYYY"
                onChange={AddEditStore.handleChecked('OnboardingDate')}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox onChange={AddEditStore.handleChecked('IsOnboarded')} checked={CMInfo.IsOnboarded}>
                Onboarded
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox onChange={AddEditStore.handleChecked('IsExcluding')} checked={CMInfo.IsExcluding}>
                Inactive
              </Checkbox>
            </Form.Item>
            <Form.Item label="Onboarded Date">
              <DatePicker
                value={this.convertDate(CMInfo.OnboardedDate)}
                onChange={AddEditStore.handleFieldChange('OnboardedDate')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Inactive Date">
              <DatePicker
                value={this.convertDate(CMInfo.ExcludingDate)}
                format="DD/MM/YYYY"
                onChange={AddEditStore.handleFieldChange('ExcludingDate')}
              />
            </Form.Item>

            {!CMInfo.IsActive && (
              <Form.Item>
                <Checkbox checked={!CMInfo.IsActive}>
                  Inactive
                  {!CMInfo.IsActive && CMInfo.InActiveDate && (
                    <span> (Inactive date: {renderDate(CMInfo.InActiveDate, false)})</span>
                  )}
                </Checkbox>
              </Form.Item>
            )}

            <Form.Item className="fluid-field" label="Comment">
              <Input.TextArea rows={4} value={CMInfo.Comments} onChange={AddEditStore.handleFieldChange('Comments')} />
            </Form.Item>
          </>
        )}
      </Form>
    );
  }
}

export default ContactInformation;
