import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import store from './Store';
import Table from './Table';
import Modal from './Modal';
import Toolbar from './Toolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class MZ900Calculations extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/greeting-title', true);
      return;
    }

    this._searchDebounced();
    ui.on('open(/view/claimants-2)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    store.fetchData(true, 'byKeyword');
  }, 500);

  render() {
    const { loading, data, open } = store;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Toolbar />
            <Table />
            {open && <Modal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(MZ900Calculations);
