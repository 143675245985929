import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Table } from 'semantic-ui-react';
import { Spin } from 'antd';
import cx from 'classnames';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import moment from 'moment';
import Modal from '../../shared/Modal';
import customFetch from '../../../utils/customFetch';
import Store from './ClientReportStore';
import { formatDate } from '../../../utils/functions';

const columns = [{ title: 'Type' }, { title: 'Export Date' }, { title: 'Export By' }];

@observer
class ModalExportHistories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listItem: [],
      curPage: 1,
      totalPage: 0,
      totalItems: 0,
    };
  }

  componentDidMount() {
    customFetch('/ExportHistory/GetAll?filterType=all', {
      method: 'GET',
    }).then(resp => {
      this.setState({
        ...this.state,
        loading: false,
        listItem: resp.itemList,
        totalPage: resp.sumPage,
        totalItems: resp.sumItem,
      });
      console.log(resp);
    });
  }

  onCancel = () => {
    this.props.onCancel();
  };

  handlePageClick = page => {
    //LoginUserHistoryStore.refetch({ curPage: page });
  };

  renderTableHistory = () =>
    this.state.listItem.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>
          {record.TypeOfExport === 1 ? 'Client' : record.TypeOfExport === 2 ? 'CM' : 'Specialist'}
        </Table.Cell>
        <Table.Cell>
          {record.ExportAt
            ? moment(formatDate(record.ExportAt, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.Staff.FullName}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { open } = Store;
    const { loading, listItem, curPage, totalItems, totalPage } = this.state;
    const colSpan = listItem.length;

    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="red" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
          title="Export History"
        >
          <div className="ant-modal-LoginHistory">
            {loading ? (
              <div className={`Table-loading`}>
                <Spin size="large" />
              </div>
            ) : (
              <div className={`Table-container`}>
                <Table sortable striped celled className={cx({ disabled: loading })}>
                  <TableHeader columns={columns} />
                  <Table.Body>
                    {listItem && listItem.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
                  </Table.Body>
                  <TableFooterPagination
                    colSpan={colSpan}
                    totalItems={totalItems}
                    currentPage={curPage}
                    totalPage={totalPage}
                    onPageClick={page => this.handlePageClick(page)}
                  />
                </Table>
              </div>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalExportHistories;
