import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import React from 'react';

import TableToolbar from '../../shared/tableToolbar';
import suppStore from './SuppStore';

@observer
class SuppToolbar extends React.Component {
  handleFilterNextStatus = (_, { value }) => {
    suppStore.refetch({ filterNextStatus: value, curPage: 1 }, true);
  };

  handleFilter = (_, { value }) => {
    suppStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleReset = () => {
    suppStore.resetStore();
    suppStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    suppStore.refetch({ curPage: 1 }, true);
  };

  @action handleRefresh = () => {
    suppStore.data = null;
    suppStore.refetch({}, true);
  };

  render() {
    const {
      keyword,
      loading,
      filter,
      fromTo,
      filterNextStatus,
      dynamicStatus,
      dynamicNextStatus,
      totalItems,
    } = suppStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input value={keyword} onChange={suppStore.handleSearchChange} disabled={loading} />
          <TableToolbar.RangePicker disabled={loading} value={fromTo} onChange={suppStore.handleDateChange} />
          <div className="pd-l-10">
            <TableToolbar.SubmitButton disabled={loading}>SEARCH</TableToolbar.SubmitButton>
          </div>
          <div className="pd-l-10">
            <TableToolbar.HighLightButton color="red" disabled={loading} onClick={this.handleReset}>
              CLEAR
            </TableToolbar.HighLightButton>
          </div>
          <div className="pd-l-10">
            <TableToolbar.HighLightButton className="blue" onClick={this.handleRefresh} disabled={loading}>
              REFRESH
            </TableToolbar.HighLightButton>
          </div>
        </TableToolbar.Left>
        <TableToolbar.Right>
          <span style={{ marginRight: `10px` }} className="nowrap">
            <p>
              <b>Total Cases: {totalItems}</b>
            </p>
          </span>
          <TableToolbar.Filter
            disabled={loading}
            label="Tasks Due"
            style={{ minWidth: '400px' }}
            value={filterNextStatus}
            options={toJS(dynamicNextStatus)}
            onChange={this.handleFilterNextStatus}
          />
          <TableToolbar.Filter
            disabled={loading}
            label="Case Status"
            style={{ minWidth: '400px' }}
            value={suppStore.GetCurrentStatusText(filter)}
            options={toJS(dynamicStatus)}
            onChange={this.handleFilter}
          />
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default SuppToolbar;
