import { notification } from 'antd';
import { uniqBy } from 'lodash-es';
import { action, computed, observable, toJS, reaction } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import dashboardStore from '../../../../stores/dashboard';

import customFetch from '../../../../utils/customFetch';
import { formatDate } from '../../../../utils/functions';
import { BaseProfileUploadStore } from '../../../shared/ProfileUpload';
import {
  getCMsWaitingNotificationsOfDoctor,
  saveCMsWaitingNotificationsOfDoctor,
  getCampaignAll,
  getAllBlockedClients,
  getCompanies,
  saveBlockedClientsOfDoctor,
  getTypeOfClaim,
  getTypeOfReport,
} from './service';
import * as api from '../../../../stores/api';

export const DoctorAttachmentType = {
  SampleReport: 0,
  Signature: 1,
  Qualification: 2,
  Accreditation: 3,
  Preference: 4,
  LetterHead: 5,
  ProfilePicture: 6,
  AHRPAAccreditation: 8,
  RequiredConsentForm: 9,
  TemplateReport: 10,
};

class DoctorDetailsStore extends BaseProfileUploadStore {
  @observable loading = true;
  @observable loadingClinic = true;
  @observable loadingConversation = false;
  @observable loadingCheckDuplicate = true;
  @observable dataDoctor = null;
  @observable dataConversationLogLoadList = null;
  @observable dataLookupConfigByParents = null;
  @observable dataActiveReportTypeList = null;
  @observable dataCaseManager = null;
  @observable dataAllCaseManager = null;
  @observable dataStaff = null;
  @observable dataState = null;
  @observable dataCountry = null;
  @observable dataCity = [];
  @observable dataMailingCity = [];
  @observable dataLookupConfig = null;
  @observable clinicList = null;
  @observable clinicListId = null;
  @observable dataClinicList = null;
  @observable dataChart = null;
  @observable locationList = [];
  @observable actionRequiredList = [];
  @observable notificationList = [];
  @observable arrTypeOfReports = [];
  @observable arrDoctorRates = [];
  @observable oldDataArrAccreditations = null;
  @observable arrAccreditations = [];

  @observable cmList = null;
  @observable CMsWaitingNotification1 = null;
  @observable CMsWaitingNotification1StartDate = null;
  @observable CMsWaitingNotification1EndDate = null;
  @observable CMsWaitingNotification2 = null;
  @observable CMsWaitingNotification2StartDate = null;
  @observable CMsWaitingNotification2EndDate = null;

  @observable dataAllClients = null;
  @observable blockedClients = null;
  @observable requestedByClients = null;

  @observable errorsList = {};

  @observable dataUser = null;
  @observable userId = null;

  @observable isEditing = false;

  @observable sampleReportAttachment = [];
  @observable templateReportAttachment = [];

  @observable oldDataDoctor = null;
  @observable sampleReports = [];
  @observable oldSampleReports = [];
  @observable attRequiredConsentForm = [];
  @observable oldAttRequiredConsentForm = [];
  @observable signatures = [];
  @observable oldSignatures = [];
  @observable letterHeadAttachments = [];
  @observable oldLetterHeadAttachments = [];
  @observable aHRPAAccreditationsAttachments = [];
  // @observable profilePictureAttachments = [];
  // @observable oldProfilePictureAttachments = [];
  @observable selectedAcceptedReportTypes = [];
  @observable oldSelectedAcceptedReportTypes = [];
  @observable selectedLocationsId = [];
  @observable oldSelectedLocationsId = [];
  @observable selectedClinicsId = [];
  @observable campaginList = [];
  @observable doctorClientRequestings = [];

  @observable keyword = '';
  @observable filter = 'All';

  @observable curPageClinic = 1;
  @observable curPageClinicSelected = 1;
  @observable clinicKeyword = '';
  @observable totalPageClinic = 0;
  @observable totalPageClinicSelected = 0;

  // @observable open = false;
  @observable modalParams = {};
  @observable totalItemsClinic = 0;
  @observable totalItemsClinicSelected = 0;

  @observable typeOfClaimId = null;
  @observable typeOfReportId = null;
  @observable reportTypeId = null;
  @observable typeOfClaimList = [];
  @observable typeOfReportList = [];
  @observable excludeTypeOfServices = [];
  @observable doctorReports = [];
  @observable bccList = [];
  @observable ciList = [];
  @observable currentCI = '';
  @observable currentCIIdx = 0;
  @observable btnCurrentCIText = 'Add New Note';

  @action setDoctorRates = arr => {
    this.arrDoctorRates = arr;
  };

  @action setArrAccreditations = arr => {
    this.arrAccreditations = arr;
  };

  @action setDoctorClientRequesting = arr => {
    this.doctorClientRequestings = arr;
  };

  @action setExcludeTypeOfServices = arr => {
    this.excludeTypeOfServices = arr;
  };

  @action setBCCList = arr => {
    this.bccList = arr;
  };

  @action setCIList = arr => {
    this.ciList = arr;
  };

  @action setBtnCurrentCIText = txt => {
    this.btnCurrentCIText = txt;
  };

  @action resetCurrentCI = txt => {
    this.currentCI = txt;
  };

  @action setCurrentCIIdx = idx => {
    this.currentCIIdx = idx;
  };

  @action setIsEditing = value => {
    if (value) {
      this.oldDataDoctor = toJS(this.dataDoctor);
      this.oldDataArrAccreditations = this.arrAccreditations;
      this.oldSelectedAcceptedReportTypes = toJS(this.selectedAcceptedReportTypes);
      this.oldLetterHeadAttachments = toJS(this.letterHeadAttachments);
      this.oldProfilePictureAttachments = toJS(this.profilePictureAttachments);
      this.oldSampleReports = toJS(this.sampleReports);
      this.oldAttRequiredConsentForm = toJS(this.attRequiredConsentForm);
      this.oldSignatures = toJS(this.signatures);
      this.oldSelectedLocationsId = toJS(this.selectedLocationsId);
    } else {
      if (!this.dataDoctor.Id) {
        this.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => window.closeCurrentTab(),
          message: `Any changes you haven't save will be lost. Are you sure to not save changes?`,
        })();
        return;
      } else {
        this.dataDoctor = toJS(this.oldDataDoctor);
        this.arrAccreditations = this.oldDataArrAccreditations;
        this.selectedAcceptedReportTypes = toJS(this.oldSelectedAcceptedReportTypes);
        this.letterHeadAttachments = toJS(this.oldLetterHeadAttachments);
        this.profilePictureAttachments = toJS(this.oldProfilePictureAttachments);
        this.sampleReports = toJS(this.oldSampleReports);
        this.attRequiredConsentForm = toJS(this.oldAttRequiredConsentForm);
        this.signatures = toJS(this.oldSignatures);
        this.selectedLocationsId = toJS(this.oldSelectedLocationsId);
        this.fetchSubSpecialties();
        this.loading = true;
        getCMsWaitingNotificationsOfDoctor(this.dataDoctor.Id)
          .then(
            action(result => {
              const { itemList } = result;
              if (!!itemList.length) {
                this.CMsWaitingNotification1 = itemList[0];
                this.CMsWaitingNotification1StartDate = itemList[0].StartDate;
                this.CMsWaitingNotification1EndDate = itemList[0].EndDate;
                this.CMsWaitingNotification2 = itemList[1];
                this.CMsWaitingNotification2StartDate = itemList[1].StartDate;
                this.CMsWaitingNotification2EndDate = itemList[1].EndDate;
              } else {
                this.CMsWaitingNotification1 = null;
                this.CMsWaitingNotification1StartDate = null;
                this.CMsWaitingNotification1EndDate = null;
                this.CMsWaitingNotification2 = null;
                this.CMsWaitingNotification2StartDate = null;
                this.CMsWaitingNotification2EndDate = null;
              }
              this.loading = false;
            }),
          )
          .catch(() => {});
        getAllBlockedClients(this.dataDoctor.Id)
          .then(
            action(result => {
              const { itemList } = result;
              if (!!itemList.length) {
                this.blockedClients = itemList[0];
              } else {
                this.blockedClients = null;
              }
              this.loading = false;
            }),
          )
          .catch(() => {});
        this.requestedByClients = this.dataDoctor?.RequestedByObj;
      }

      this.setDoctorRates(this.oldDataDoctor.LstDoctorRate);
      if (
        this.oldDataDoctor.DoctorBookingAndCancellationTerms &&
        this.oldDataDoctor.DoctorBookingAndCancellationTerms.length > 0
      ) {
        var arrTerms = this.oldDataDoctor.DoctorBookingAndCancellationTerms;
        arrTerms.forEach(el => {
          el.Created = el.Created ? this.momentToJS(el.Created) : null;
          el.Modified = el.Modified ? this.momentToJS(el.Modified) : null;
        });
        this.setBCCList(arrTerms);
      } else {
        this.setBCCList([]);
      }
      if (this.oldDataDoctor.DoctorConfidentialInsights && this.oldDataDoctor.DoctorConfidentialInsights.length > 0) {
        var arrConfidentialInsights = this.oldDataDoctor.DoctorConfidentialInsights;
        arrConfidentialInsights.forEach(el => {
          el.Created = el.Created ? this.momentToJS(el.Created) : null;
          el.Modified = el.Modified ? this.momentToJS(el.Modified) : null;
        });
        this.setCIList(arrConfidentialInsights);
      } else {
        this.setCIList([]);
      }
      // if ((this.dataDoctor?.TypeOfReports || []).length > 0) {
      //   var newArr = [];
      //   (this.dataDoctor.TypeOfReports || []).forEach(el => {
      //     var sel = this.arrDoctorRates.find(i => i.idx === el);
      //     newArr.push(sel);
      //   });
      //   this.setDoctorRates(newArr);
      // } else {
      //   this.setDoctorRates([]);
      // }
    }
    this.isEditing = value;
  };

  validatePhone = (phone, sixValid) => {
    if (!phone) {
      return true;
    }
    const value = phone.replace(/-/g, '').replace(/ /g, '');
    if (value.match(/[^0-9]/)) {
      return false;
    }
    switch (value.length) {
      case 6:
        return sixValid;
      case 10:
        return true;
      default:
        return false;
    }
  };

  compareDate = (date1, date2) => {
    return moment(date1, 'DD/MM/YYYY').isSameOrBefore(moment(date2, 'DD/MM/YYYY'), 'day');
  };

  validateEmail = email => {
    const v = new Validator({ email }, { email: 'email' });
    return v.passes();
  };

  validateDoctor = doctorParam => {
    const errors = [];
    if (!doctorParam.IsRecruiting && !doctorParam.IsOnboarding && !doctorParam.HasContractWithMag) {
      this.errorsList = {
        ...this.errorsList,
        Recruitment: 'Please select at least one recruitment status.',
      };
      errors.push(`Please select at least one recruitment status.`);
    }
    if (doctorParam.EnableLogin && !doctorParam.Email2) {
      this.errorsList = {
        ...this.errorsList,
        Email2: 'Please input business email address.',
      };
      errors.push('Please input business email address.');
    }
    if (doctorParam.IsRecruiting && !doctorParam.RecruitingDate) {
      errors.push('Please input date of recruiting.');
      this.errorsList = {
        ...this.errorsList,
        RecruitingDate: 'Please input date of recruiting.',
      };
    }
    if (doctorParam.IsNotCurrentlyInterested && !doctorParam.NotCurrentlyInterestedDate) {
      errors.push('Please input start date of NCI.');
      this.errorsList = {
        ...this.errorsList,
        NotCurrentlyInterestedDate: 'Please input start date of NCI.',
      };
    }
    if (doctorParam.IsOnboarding && !doctorParam.OnboardingDate) {
      errors.push('Please input date of onboarding.');
      this.errorsList = {
        ...this.errorsList,
        OnboardingDate: 'lease input date of onboarding.',
      };
    }
    if (!doctorParam.FirstName) {
      errors.push('Please input first name.');
      this.errorsList = {
        ...this.errorsList,
        FirstName: 'Please input first name.',
      };
    }
    if (!doctorParam.LastName) {
      errors.push('Please input surname.');
      this.errorsList = {
        ...this.errorsList,
        LastName: 'Please input surname.',
      };
    }
    if (
      !doctorParam.IsSendBookingConfToEmail2 &&
      !doctorParam.IsSendBookingConfToEmail &&
      !doctorParam.IsSendBookingConfToSecretaryEmail
    ) {
      errors.push('Please select at least one email for sending booking-related emails.');
    }
    if (!this.validatePhone(doctorParam.Telephone, false)) {
      errors.push('Valid mobile numbers should have 10 digits.');
      this.errorsList = {
        ...this.errorsList,
        Telephone: 'Valid mobile numbers should have 10 digits.',
      };
    }
    if (!this.validatePhone(doctorParam.OfficePhone, true)) {
      errors.push('Valid phone numbers should have 6 or 10 digits (including area code).');
      this.errorsList = {
        ...this.errorsList,
        OfficePhone: 'Valid phone numbers should have 6 or 10 digits (including area code).',
      };
    }
    if (!this.validatePhone(doctorParam.SecretaryTelephone, true)) {
      errors.push('Valid secretary/practice manager phone numbers should have 6 or 10 digits (including area code).');
      this.errorsList = {
        ...this.errorsList,
        SecretaryTelephone:
          'Valid secretary/practice manager phone numbers should have 6 or 10 digits (including area code).',
      };
    }
    if (!this.validatePhone(doctorParam.Fax, true)) {
      errors.push('Valid fax numbers should have 6 or 10 digits (including area code).');
      this.errorsList = {
        ...this.errorsList,
        Fax: 'Valid fax numbers should have 6 or 10 digits (including area code).',
      };
    }
    if (!!doctorParam.MAGChargeRate && !doctorParam.MAGChargeRateFrom) {
      errors.push('Please input effective from of Service - Rented Commission Rate 1.');
    }
    if (!!doctorParam.MAGChargeRate2 && !doctorParam.MAGChargeRate2From) {
      errors.push('Please input effective from of Service - Rented Commission Rate 2.');
    }

    if (!!doctorParam.MAGChargeRateFrom && !!doctorParam.MAGChargeRate2From) {
      if (
        !!doctorParam.MAGChargeRateTo &&
        this.compareDate(doctorParam.MAGChargeRate2From, doctorParam.MAGChargeRateTo)
      ) {
        errors.push(
          'Effective date of Medicolegal Provider Add-On Rate 2 must be after effective date of Medicolegal Provider Add-On Rate 1',
        );
      }
      if (
        !doctorParam.MAGChargeRateTo &&
        this.compareDate(doctorParam.MAGChargeRate2From, doctorParam.MAGChargeRateFrom)
      ) {
        errors.push(
          'Effective date of Medicolegal Provider Add-On Rate 2 must be after effective date of Medicolegal Provider Add-On Rate 1',
        );
      }
    }
    if (!!doctorParam.ChargeRate && !doctorParam.ChargeRateFrom) {
      errors.push('Please input effective from of Medicolegal Provider Standard Rate 1.');
    }
    if (!!doctorParam.ChargeRate2 && !doctorParam.ChargeRate2From) {
      errors.push('Please input effective from of Medicolegal Provider Standard Rate 2.');
    }
    if (!!doctorParam.ChargeRateFrom && !!doctorParam.ChargeRate2From) {
      if (!!doctorParam.ChargeRateTo && this.compareDate(doctorParam.ChargeRate2From, doctorParam.ChargeRateTo)) {
        errors.push(
          'Effective date of Medicolegal Provider Standard Rate 2 must be after effective date of Medicolegal Provider Standard Rate 1',
        );
      }
      if (!doctorParam.ChargeRateTo && this.compareDate(doctorParam.ChargeRate2From, doctorParam.ChargeRateFrom)) {
        errors.push(
          'Effective date of Medicolegal Provider Standard Rate 2 must be after effective date of Medicolegal Provider Standard Rate 1',
        );
      }
    }
    if (!!doctorParam.CommissionOnTop && !doctorParam.CommissionOnTopFrom) {
      errors.push('Please input effective from of Gross Margin COT 1.');
    }
    if (!!doctorParam.CommissionOnTop2 && !doctorParam.CommissionOnTop2From) {
      errors.push('Please input effective from of Gross Margin COT 2.');
    }
    if (!!doctorParam.CommissionOnTopFrom && !!doctorParam.CommissionOnTop2From) {
      if (
        !!doctorParam.CommissionOnTopTo &&
        this.compareDate(doctorParam.CommissionOnTop2From, doctorParam.CommissionOnTopTo)
      ) {
        errors.push('Effective date of Gross Margin COT 2 must be after effective period of Gross Margin COT 1.');
      }
      if (
        !doctorParam.CommissionOnTopTo &&
        this.compareDate(doctorParam.CommissionOnTop2From, doctorParam.CommissionOnTopFrom)
      ) {
        errors.push('Effective date of Gross Margin COT 2 must be after effective period of Gross Margin COT 1.');
      }
    }
    if (!!doctorParam.Comment && doctorParam.Comment.length > 750) {
      errors.push('Doctor’s brief profile exceeds word counts. Shorten it before doctor information can be saved.');
    }
    if (!!doctorParam.ConsultingLocations && doctorParam.ConsultingLocations.length > 2000) {
      errors.push('Too many consulting locations are selected. Could you please narrow down the list?');
    }

    if (!this.validateEmail(doctorParam.Email)) {
      errors.push('Personal email is invalid');
    }
    if (!this.validateEmail(doctorParam.Email2)) {
      errors.push('Business email is invalid');
    }
    if (!this.validateEmail(doctorParam.SecretaryEmail)) {
      errors.push('Secretary email is invalid');
    }
    if (!this.validateEmail(doctorParam.AccountingEmail1)) {
      errors.push('Accounting email 1 is invalid');
    }
    if (!this.validateEmail(doctorParam.AccountingEmail2)) {
      errors.push('Accounting email 2 is invalid is invalid');
    }
    if (this.bccList && this.bccList.length > 0) {
      var checkErrorBookingAndCancellationTerm = false;
      this.bccList.forEach(el => {
        if (!el.BookingAndCancellationTerm) checkErrorBookingAndCancellationTerm = true;
      });
      if (checkErrorBookingAndCancellationTerm) {
        errors.push('Terms must have some content');
      }
    }
    notification.destroy();
    if (errors.length) {
      errors.map(i => this.showFormErrorNoti(i));
      return false;
    }
    return true;
  };

  formatReqDate = date => {
    return date ? moment(date, 'DD/MM/YYYY').toISOString() : null;
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 5,
    });
  };

  @observable pageGetter = null;
  @observable fieldName = null;

  @action saveEdit = () => {
    const doctorParam = toJS(this.dataDoctor);
    if (!this.validateDoctor(doctorParam)) {
      return;
    }

    const selectedIndustryServiceTypes = doctorParam.IndustryExpertTypes.map(indId => {
      return toJS(this.dataLookupConfig.indItemList.find(ind => ind.Id === indId));
    }).filter(x => x);
    const selectedSpecialties = doctorParam.Specialties.map(speId => {
      return toJS(this.dataLookupConfig.speItemList.find(spe => spe.Id === speId));
    }).filter(x => x);
    const selectedSubSpecialties = doctorParam.SubSpecialties.map(speId => {
      return toJS((this.dataLookupConfigByParents?.itemList || []).find(spe => spe.Id === speId));
    }).filter(x => x);

    if (doctorParam.AccreditationList) {
      doctorParam.AccreditationList = doctorParam.AccreditationList.filter(x => x);
    }

    const { fieldName, pageGetter } = this;
    const { listPageGetters } = dashboardStore;
    const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);
    var lstRequestedBy = this.requestedByClients?.ClientIds;

    this.loading = true;

    customFetch('/MedicalDoctor/Save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorParam: {
          ...doctorParam,
          selectedIndustryServiceTypes,
          selectedSpecialties,
          selectedSubSpecialties,
          RegisterOption: doctorParam.RegisterOption || null,
          Qualifications: doctorParam.Qualifications?.toString() || '',
          ConsultingLocationStates: doctorParam.ConsultingLocationStates?.toString() || '',
          ConsultingLocations: doctorParam.ConsultingLocations.map(loc => {
            return [loc.id, loc.location, loc.postcode].join('|');
          }).toString(),
          ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
          ContractWithMagDate: this.formatReqDate(doctorParam.ContractWithMagDate),
          ContractWithMagEndDate: this.formatReqDate(doctorParam.ContractWithMagEndDate),
          NotCurrentlyInterestedDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedDate),
          NotCurrentlyInterestedEndDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedEndDate),
          RecruitingDate: this.formatReqDate(doctorParam.RecruitingDate),
          OnboardingDate: this.formatReqDate(doctorParam.OnboardingDate),
          QualificationReceivedDate: this.formatReqDate(doctorParam.QualificationReceivedDate),
          AccreditationReceivedDate: this.formatReqDate(doctorParam.AccreditationReceivedDate),
          SubmittedForReviewDate: this.formatReqDate(doctorParam.SubmittedForReviewDate),
          ActionRequiredDate: this.formatReqDate(doctorParam.ActionRequiredDate),
          ActionRequiredDueDate: this.formatReqDate(doctorParam.ActionRequiredDueDate),
          ActionRequiredCompletedDate: this.formatReqDate(doctorParam.ActionRequiredCompletedDate),
          ApprovedDate: this.formatReqDate(doctorParam.ApprovedDate),
          MAGChargeRateFrom: this.formatReqDate(doctorParam.MAGChargeRateFrom),
          MAGChargeRateTo: this.formatReqDate(doctorParam.MAGChargeRateTo),
          MAGChargeRate2From: this.formatReqDate(doctorParam.MAGChargeRate2From),
          MAGChargeRate2To: this.formatReqDate(doctorParam.MAGChargeRate2To),
          ChargeRateFrom: this.formatReqDate(doctorParam.ChargeRateFrom),
          ChargeRateTo: this.formatReqDate(doctorParam.ChargeRateTo),
          ChargeRate2From: this.formatReqDate(doctorParam.ChargeRate2From),
          ChargeRate2To: this.formatReqDate(doctorParam.ChargeRate2To),
          CommissionOnTopFrom: this.formatReqDate(doctorParam.CommissionOnTopFrom),
          CommissionOnTopTo: this.formatReqDate(doctorParam.CommissionOnTopTo),
          CommissionOnTop2From: this.formatReqDate(doctorParam.CommissionOnTop2From),
          CommissionOnTop2To: this.formatReqDate(doctorParam.CommissionOnTop2To),
          DictationOptionStartDate: this.formatReqDate(doctorParam.DictationOptionStartDate),
          DictationOptionEndDate: this.formatReqDate(doctorParam.DictationOptionEndDate),
          RequestedByIds: lstRequestedBy,
        },
        isRateChange: false,
        letterHeadAttachments: toJS(this.letterHeadAttachments),
        aHRPAAccreditationsAttachments: toJS(this.aHRPAAccreditationsAttachments),
        oldChargeRate: null,
        oldMAGChargeRate: null,
        profilePictureAttachments: toJS(this.profilePictureAttachments),
        rateChangePassword: '',
        ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
        sampleReports: toJS(this.sampleReports),
        requiredConsentForm: toJS(this.attRequiredConsentForm),
        selectedAcceptedReportTypes: this.selectedAcceptedReportTypes
          ? this.selectedAcceptedReportTypes.map(a => a)
          : [],
        selectedAccreditations: doctorParam.AccreditationList ?? [],
        selectedIndustryServiceTypes,
        selectedSpecialties,
        selectedSubSpecialties,
        signatures: toJS(this.signatures),
        DoctorRates: this.arrDoctorRates,
        DoctorAccreditationDetails: this.arrAccreditations,
        DoctorClientRequestings: this.doctorClientRequestings,
        ExcludeTypeOfServices: this.excludeTypeOfServices,
        DoctorBookingAndCancellationTerms: this.bccList,
        DoctorConfidentialInsights: this.ciList,
      }),
    })
      .then(
        action(async res => {
          this.isEditing = false;
          if (res.status === 'success') {
            const notification = [
              {
                Id: 0,
                DoctorId: doctorParam.Id,
                CMIds: this.CMsWaitingNotification1?.CMIds || '',
                StartDate: this.formatReqDate(this.CMsWaitingNotification1StartDate),
                EndDate: this.formatReqDate(this.CMsWaitingNotification1EndDate),
              },
              {
                Id: 0,
                DoctorId: doctorParam.Id,
                CMIds: this.CMsWaitingNotification2?.CMIds || '',
                StartDate: this.formatReqDate(this.CMsWaitingNotification2StartDate),
                EndDate: this.formatReqDate(this.CMsWaitingNotification2EndDate),
              },
            ];
            await saveCMsWaitingNotificationsOfDoctor(doctorParam.Id, notification);
            const blockedClients = [
              {
                Id: 0,
                DoctorId: doctorParam.Id,
                ClientIds: this.blockedClients?.ClientIds || '',
                StartDate: null,
                EndDate: null,
              },
            ];
            await saveBlockedClientsOfDoctor(doctorParam.Id, blockedClients);
            if (!doctorParam.Id && !!this.selectedClinicsId.length) {
              await customFetch('/MedicalDoctor/UpdateDoctorClinic', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                  clinics: toJS(this.selectedClinicsId),
                  doctorId: res.newId,
                }),
              });
            }
            if (!doctorParam.Id) {
              let firstName = this.dataDoctor.FirstName.charAt(0).toUpperCase() + this.dataDoctor.FirstName.slice(1);
              let lastName = this.dataDoctor.LastName.charAt(0).toUpperCase() + this.dataDoctor.LastName.slice(1);
              localStorage.setItem('DoctorName', `${firstName} ${lastName}`);
              dashboardStore.close('/view/add-edit-doctor-2', true);
              setTimeout(() => {
                dashboardStore.open(`/view/add-edit-doctor-2?id=${res.newId}&action=view`);
              });
            }
            await this.fetchAll(res.newId);
            if (fieldName && pageGetter) {
              const body = {
                isRateChange: false,
                letterHeadAttachments: toJS(this.letterHeadAttachments),
                aHRPAAccreditationsAttachments: toJS(this.aHRPAAccreditationsAttachments),
                oldChargeRate: null,
                oldMAGChargeRate: null,
                profilePictureAttachments: toJS(this.profilePictureAttachments),
                rateChangePassword: '',
                ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
                sampleReports: toJS(this.sampleReports),
                selectedAcceptedReportTypes: doctorParam.AcceptedReportTypes
                  ? doctorParam.AcceptedReportTypes.map(a => a.Id)
                  : [],
                selectedAccreditations: doctorParam.AccreditationList ?? [],
                selectedIndustryServiceTypes,
                selectedSpecialties,
                selectedSubSpecialties,
                signatures: toJS(this.signatures),
                ...{
                  ...doctorParam,
                  selectedIndustryServiceTypes,
                  selectedSpecialties,
                  selectedSubSpecialties,
                  RegisterOption: doctorParam.RegisterOption || null,
                  Qualifications: doctorParam.Qualifications?.toString() || '',
                  ConsultingLocationStates: doctorParam.ConsultingLocationStates?.toString() || '',
                  ConsultingLocations: doctorParam.ConsultingLocations.map(loc => {
                    return [loc.id, loc.location, loc.postcode].join('|');
                  }).toString(),
                  ReportTemplatesAttachments: JSON.stringify(doctorParam.ReportTemplatesAttachments),
                  ContractWithMagDate: this.formatReqDate(doctorParam.ContractWithMagDate),
                  ContractWithMagEndDate: this.formatReqDate(doctorParam.ContractWithMagEndDate),
                  NotCurrentlyInterestedDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedDate),
                  NotCurrentlyInterestedEndDate: this.formatReqDate(doctorParam.NotCurrentlyInterestedEndDate),
                  RecruitingDate: this.formatReqDate(doctorParam.RecruitingDate),
                  OnboardingDate: this.formatReqDate(doctorParam.OnboardingDate),
                  QualificationReceivedDate: this.formatReqDate(doctorParam.QualificationReceivedDate),
                  AccreditationReceivedDate: this.formatReqDate(doctorParam.AccreditationReceivedDate),
                  SubmittedForReviewDate: this.formatReqDate(doctorParam.SubmittedForReviewDate),
                  ActionRequiredDate: this.formatReqDate(doctorParam.ActionRequiredDate),
                  ActionRequiredDueDate: this.formatReqDate(doctorParam.ActionRequiredDueDate),
                  ActionRequiredCompletedDate: this.formatReqDate(doctorParam.ActionRequiredCompletedDate),
                  ApprovedDate: this.formatReqDate(doctorParam.ApprovedDate),
                  MAGChargeRateFrom: this.formatReqDate(doctorParam.MAGChargeRateFrom),
                  MAGChargeRateTo: this.formatReqDate(doctorParam.MAGChargeRateTo),
                  MAGChargeRate2From: this.formatReqDate(doctorParam.MAGChargeRate2From),
                  MAGChargeRate2To: this.formatReqDate(doctorParam.MAGChargeRate2To),
                  ChargeRateFrom: this.formatReqDate(doctorParam.ChargeRateFrom),
                  ChargeRateTo: this.formatReqDate(doctorParam.ChargeRateTo),
                  ChargeRate2From: this.formatReqDate(doctorParam.ChargeRate2From),
                  ChargeRate2To: this.formatReqDate(doctorParam.ChargeRate2To),
                  CommissionOnTopFrom: this.formatReqDate(doctorParam.CommissionOnTopFrom),
                  CommissionOnTopTo: this.formatReqDate(doctorParam.CommissionOnTopTo),
                  CommissionOnTop2From: this.formatReqDate(doctorParam.CommissionOnTop2From),
                  CommissionOnTop2To: this.formatReqDate(doctorParam.CommissionOnTop2To),
                  DictationOptionStartDate: this.formatReqDate(doctorParam.DictationOptionStartDate),
                  DictationOptionEndDate: this.formatReqDate(doctorParam.DictationOptionEndDate),
                },
              };
              dashboardStore.transferData({
                fieldName: fieldName,
                pageGetter: pageGetter,
                dataTransfer: {
                  ...body,
                  Id: res.newId,
                  FullName: `${body.FirstName} ${body.LastName}`,
                },
              });
              if (urlPageGetter && urlPageGetter.pathname) {
                dashboardStore.close('/view/add-edit-doctor-2', true);
                setTimeout(() => {
                  dashboardStore.open(urlPageGetter.pathname);
                });
              }
            }
            return;
          } else {
            return;
          }
        }),
      )
      .catch(
        action(error => {
          const errorList = Object.entries(error);
          if (error.hasOwnProperty('Email2') && error['Email2'] === 'exists') {
            this.handleUpdateDataDoctor('EnableLogin', false);
            this.showFormErrorNoti(
              `The registered email has been used in another account. Please check for duplicate account under Login Accounts section`,
            );
          } else {
            errorList.map(([key, value]) => this.showFormErrorNoti(`${key === 'Email2' ? 'Email' : key} is ${value}`));
          }
          this.loading = false;
        }),
      )
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  };

  @action setFieldValue = (_, { name, value }) => {
    this.dataDoctor[name] = value;
    if (name === 'AccreditationList') {
      const lstItems = this.arrAccreditations;
      if (value && value.length > 0) {
        var newArrAcc = [];
        value.forEach(el => {
          var accItem = (this.dataLookupConfig?.accreditationList || []).find(i => i.Id === el);
          var findObj = lstItems.find(i => i.AccId === el);
          if (findObj) {
            newArrAcc.push(findObj);
          } else {
            newArrAcc.push({
              Id: 0,
              AccId: el,
              Name: accItem?.Label,
              EffectiveDate: null,
              ExpriryDate: null,
              Attachments: [],
            });
          }
        });
        this.setArrAccreditations(newArrAcc);
      }else{
        this.setArrAccreditations([]);
      }
    }
    if (name === 'AssignedToId') {
      const assignedStaff = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.AssignedToId);
      this.dataDoctor.AssignedToUserFullName = assignedStaff?.FullName ?? '';
    } else if (name === 'ActionRequiredStaffId') {
      const assignedStaff = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.ActionRequiredStaffId);
      this.dataDoctor.ActionRequiredStaffName = assignedStaff.FullName;
    } else if (name === 'ApprovedBy') {
      const approvedBy = this.dataStaff.itemList.find(s => s.Id === this.dataDoctor.ApprovedBy);
      this.dataDoctor.ApprovedName = approvedBy.FullName;
    } else if (name === 'OfficePhone' || name === 'Telephone') {
      this.dataDoctor[name] = value.replace(/[^\d]/g, '');
    }
  };

  @action setFieldNamAndValue = (name, value) => {
    this.dataDoctor[name] = value;
  };

  @action toggleCheckbox = (_, { name, checked }) => {
    if (this.isEditing) {
      this.dataDoctor[name] = checked;
    }
    if (name === 'IsRecruiting' && checked) {
      this.dataDoctor.RecruitingDate = moment(new Date()).format('DD/MM/YYYY');
    } else if (name === 'IsOnboarding' && checked) {
      this.dataDoctor.OnboardingDate = moment(new Date()).format('DD/MM/YYYY');
    } else if (name === 'HasContractWithMag' && checked) {
      this.dataDoctor.ContractWithMagDate = moment(new Date()).format('DD/MM/YYYY');
    } else if (name === 'IsNotCurrentlyInterested' && checked) {
      //this.dataDoctor.AvailableForCMView = false;
      this.dataDoctor.SubscribeToPromotionCampaign = false;
      this.dataDoctor.NotCurrentlyInterestedDate = moment(new Date()).format('DD/MM/YYYY');
    } else if (
      // (name === 'AvailableForCMView' || name === 'SubscribeToPromotionCampaign') &&
      name === 'SubscribeToPromotionCampaign' &&
      checked &&
      this.dataDoctor.IsNotCurrentlyInterested
    ) {
      this.dataDoctor.IsNotCurrentlyInterested = false;
    }
  };

  @action handleUpdateDataDoctor = (key, value) => {
    const updatedDataDoctor = { ...this.dataDoctor };
    updatedDataDoctor[key] = value;
    this.dataDoctor = updatedDataDoctor;
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchFiles();
  };

  @action refetchClinic = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClinic(setTotalPage);
  };

  @action fetchSelectedClinic = list => {
    customFetch('/Clinic/GetClinicByIdList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(list),
    }).then(
      action(data => {
        this.clinicList = data;
        this.totalItemsClinicSelected = data.sumItem;
        this.totalPageClinicSelected = data.sumPage;
      }),
    );
  };

  @action fetchSelectedClinicByDoctorId = doctorId => {
    customFetch('/MedicalDoctor/GetDoctorClinicByDoctorId', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ doctorId: doctorId }),
    }).then(
      action(data => {
        this.clinicList = data;
        this.totalItemsClinicSelected = data.sumItem;
        this.totalPageClinicSelected = data.sumPage;
      }),
    );
  };

  @action fetchClinic = (setTotalPage = false) => {
    this.loadingClinic = true;
    customFetch('/Clinic/GetClinicList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: this.keyword,
        CurPage: this.curPageClinic,
        NumItemPerPage: this.pageSize,
        IsSelectionList: true,
      }),
    }).then(
      action(data => {
        this.dataClinicList = data;
        this.totalPageClinic = setTotalPage ? data.sumPage : this.totalPageClinic;
        this.totalItemsClinic = setTotalPage ? data.sumItem : this.totalItemsClinic;
        this.loadingClinic = false;
      }),
    );
  };

  @action fetchListClinicDoctor = id => {
    customFetch('/MedicalDoctor/GetDoctorClinics', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorId: id,
      }),
    }).then(action(data => (this.clinicListId = data)));
  };

  @action fetchSubSpecialties = () => {
    customFetch('/MedicalDoctor/GetLookupConfigByParents', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        parents: this.dataDoctor.Specialties.map(s => {
          return this.dataLookupConfig.speItemList.find(i => i.Id === s);
        }),
      }),
    }).then(
      action(data => {
        this.dataLookupConfigByParents = data;
        this.dataDoctor.SubSpecialties = this.dataDoctor.SubSpecialties.filter(sId => {
          return data.itemList.find(i => i.Id === sId);
        });
      }),
    );
  };

  @action fetchLocations = (_, { searchQuery }) => {
    if (searchQuery.length < 3) {
      this.locationList = [];
      return;
    }

    customFetch(`/api/auspostSuburb?q=${searchQuery}&excludePostBoxFlag=true`, {
      method: 'GET',
    }).then(
      action(data => {
        this.locationList = data;
      }),
    );
  };

  fetchActionRequired = doctorId => {
    return customFetch('/MedicalDoctor/ActionRequired_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ doctorId }),
    });
  };

  fetchDoctorReport = doctorId => {
    return customFetch('/MedicalDoctor/DoctorReport_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ doctorId }),
    });
  };

  addDoctorReport = model => {
    return customFetch('/MedicalDoctor/DoctorReport_Update', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ model }),
    });
  };

  deleteDoctorReport = id => {
    return customFetch('/MedicalDoctor/DoctorReport_Disable', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    });
  };

  @action handleRefetchActionNote = () => {
    return this.fetchActionRequired(this.dataDoctor.Id).then(action(res => (this.actionRequiredList = res.itemList)));
  };

  @action fetchConversations = (searchKey = '') => {
    this.loadingConversation = true;
    customFetch('/MedicalDoctor/ConversationLog_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorId: this.dataDoctor.Id,
      }),
    })
      .then(
        action(conversationList => {
          if (searchKey !== '' && conversationList?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            conversationList.itemList = conversationList.itemList.filter(
              t => t.Content.toLowerCase().indexOf(searchKey) > -1,
            );
          }
          this.dataConversationLogLoadList = conversationList;
          this.loadingConversation = false;
        }),
      )
      .catch(() => (this.loadingConversation = false));
  };

  @observable greetingTitle = [];
  @action fetchCaseManager = (keyword = '', numItemPerPage = 30) => {
    customFetch('/Staff/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        Showing: 'active',
        curPage: 1,
        keyword,
        numItemPerPage,
      }),
    }).then(
      action(data => {
        this.dataCaseManager = data.itemList;
      }),
    );
  };

  @action fetchClients = () => {
    getCompanies('', 50).then(
      action(data => {
        this.dataAllClients = data.itemList;
      }),
    );
  };

  @action fetchTypeOfReport = () => {
    customFetch('/TypesOfReport/GetActiveReportTypeList', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
    }).then(
      action(data => {
        this.arrTypeOfReports = data.itemList;
        this.typeOfReportList = data.itemList;
      }),
    );
  };

  @action fetchTypeOfClaim = () => {
    getTypeOfClaim().then(
      action(data => {
        this.typeOfClaimList = data.itemList;
      }),
    );
  };

  @action fetchAllCaseManager = (cmSearchKeyword = '', cmNumItemPerPage = 50) => {
    customFetch('/Staff/GetStaffOnboarded', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: false,
        Showing: 'active',
        curPage: 1,
        keyword: cmSearchKeyword,
        numItemPerPage: cmNumItemPerPage,
        IsOnboarded: true,
      }),
    }).then(
      action(data => {
        this.dataAllCaseManager = data.itemList;
      }),
    );
  };

  @action fetchAllClients = (searchKeyword = '', numItemPerPage = 50) => {
    getCompanies(searchKeyword, numItemPerPage).then(
      action(data => {
        this.dataAllClients = data.itemList;
      }),
    );
  };

  formatResDate = date => {
    return date ? moment(formatDate(date), 'DD MMM YYYY').format('DD/MM/YYYY') : '';
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  fetchCity = (countryId = 16) => {
    return customFetch('/MedicalDoctor/City_GetList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        countryId,
      }),
    });
  };

  @action fetchAll = async id => {
    this.loading = true;
    try {
      await customFetch('/MedicalDoctor/GetDoctorClinics', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          doctorId: id,
        }),
      }).then(
        action(async list => {
          this.clinicListId = list;
          await customFetch('/Clinic/GetClinicByIdList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(list.itemList),
          }).then(
            action(data => {
              this.clinicList = data;
              this.totalItemsClinicSelected = data.sumItem;
              this.totalPageClinicSelected = data.sumPage;
            }),
          );
        }),
      );
      if (!!id) {
        await getCMsWaitingNotificationsOfDoctor(id)
          .then(
            action(result => {
              const { itemList } = result;
              if (!!itemList.length) {
                this.CMsWaitingNotification1 = itemList[0];
                this.CMsWaitingNotification1StartDate = itemList[0].StartDate;
                this.CMsWaitingNotification1EndDate = itemList[0].EndDate;
                this.CMsWaitingNotification2 = itemList[1];
                this.CMsWaitingNotification2StartDate = itemList[1].StartDate;
                this.CMsWaitingNotification2EndDate = itemList[1].EndDate;
              } else {
                this.CMsWaitingNotification1 = null;
                this.CMsWaitingNotification1StartDate = null;
                this.CMsWaitingNotification1EndDate = null;
                this.CMsWaitingNotification2 = null;
                this.CMsWaitingNotification2StartDate = null;
                this.CMsWaitingNotification2EndDate = null;
              }
            }),
          )
          .catch(() => {});

        await getAllBlockedClients(id)
          .then(
            action(result => {
              const { itemList } = result;
              if (!!itemList.length) {
                this.blockedClients = itemList[0];
              } else {
                this.blockedClients = null;
              }
            }),
          )
          .catch(() => {});
      }
      await customFetch('/MedicalDoctor/ConversationLog_LoadList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          doctorId: id,
        }),
      }).then(
        action(data => {
          this.dataConversationLogLoadList = data;
        }),
      );

      await customFetch('/Default/GetActiveReportTypeList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataActiveReportTypeList = data;
        }),
      );
      await customFetch('/MedicalDoctor/GetLookUpConfig', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'doctor',
        }),
      }).then(
        action(data => {
          this.dataLookupConfig = data;
        }),
      );
      this.fetchCaseManager();
      this.fetchAllCaseManager();
      this.fetchClients();
      await customFetch('/MedicalDoctor/GetStaffs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataStaff = data;
        }),
      );
      await customFetch('/MedicalDoctor/GetStates', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }).then(
        action(data => {
          this.dataState = data;
        }),
      );
      await customFetch('/Clinic/Country_GetList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }).then(
        action(data => {
          this.dataCountry = data.itemList;
        }),
      );
      await customFetch(`/MedicalDoctor/GetTimeOfProcessComplete?doctorId=${id}`, {}).then(
        action(res => (this.dataChart = res)),
      );
      await customFetch('/GreetingTitle/GetAllByType?type=specialist', {
        method: 'GET',
      }).then(
        action(res => {
          this.greetingTitle = res.itemList;
        }),
      );
      this.fetchTypeOfReport();
      this.fetchTypeOfClaim();
      getCampaignAll().then(
        action(respCampgin => {
          this.campaginList = respCampgin.itemList;
        }),
      );
      if (!!id) {
        await this.fetchActionRequired(id).then(
          action(res => {
            this.actionRequiredList = res.itemList;
          }),
        );

        await this.fetchDoctorReport(id).then(
          action(res => {
            this.doctorReports = res.itemList;
          }),
        );
      }
      this.resetCurrentCI("");
      await customFetch('/MedicalDoctor/DoctorInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: id,
        }),
      }).then(
        action(data => {
          this.dataDoctor = {
            ...data,
            RegisterOption: data.RegisterOption || 0,
            ConsultingLocationStates: data.ConsultingLocationStates?.split(',') || [],
            Qualifications: data.Qualifications?.split(',') || [],
            Specialties: data.Specialties.map(a => a.Id),
            SubSpecialties: data.SubSpecialties.map(a => a.Id),
            AccreditationList: data.AccreditationList.map(a => a.Id).filter(i => i),
            ReportTemplatesAttachments: JSON.parse(data.ReportTemplatesAttachments) || [],
            ContractWithMagDate: this.formatResDate(data.ContractWithMagDate),
            ContractWithMagEndDate: this.formatResDate(data.ContractWithMagEndDate),
            NotCurrentlyInterestedDate: this.formatResDate(data.NotCurrentlyInterestedDate),
            NotCurrentlyInterestedEndDate: this.formatResDate(data.NotCurrentlyInterestedEndDate),
            RecruitingDate: this.formatResDate(data.RecruitingDate),
            OnboardingDate: this.formatResDate(data.OnboardingDate),
            QualificationReceivedDate: this.formatResDate(data.QualificationReceivedDate),
            AccreditationReceivedDate: this.formatResDate(data.AccreditationReceivedDate),
            SubmittedForReviewDate: this.formatResDate(data.SubmittedForReviewDate),
            ActionRequiredDate: this.formatResDate(data.ActionRequiredDate),
            ActionRequiredDueDate: this.formatResDate(data.ActionRequiredDueDate),
            ActionRequiredCompletedDate: this.formatResDate(data.ActionRequiredCompletedDate),
            ApprovedDate: this.formatResDate(data.ApprovedDate),
            MAGChargeRateFrom: this.formatResDate(data.MAGChargeRateFrom),
            MAGChargeRateTo: this.formatResDate(data.MAGChargeRateTo),
            MAGChargeRate2From: this.formatResDate(data.MAGChargeRate2From),
            MAGChargeRate2To: this.formatResDate(data.MAGChargeRate2To),
            ChargeRateFrom: this.formatResDate(data.ChargeRateFrom),
            ChargeRateTo: this.formatResDate(data.ChargeRateTo),
            ChargeRate2From: this.formatResDate(data.ChargeRate2From),
            ChargeRate2To: this.formatResDate(data.ChargeRate2To),
            CommissionOnTopFrom: this.formatResDate(data.CommissionOnTopFrom),
            CommissionOnTopTo: this.formatResDate(data.CommissionOnTopTo),
            CommissionOnTop2From: this.formatResDate(data.CommissionOnTop2From),
            CommissionOnTop2To: this.formatResDate(data.CommissionOnTop2To),
            DictationOptionStartDate: this.formatResDate(data.DictationOptionStartDate),
            DictationOptionEndDate: this.formatResDate(data.DictationOptionEndDate),
          };
          this.selectedAcceptedReportTypes = data.AcceptedReportTypes.map(a => a.Id);
          if (data.AccreditationListDetail && data.AccreditationListDetail.length > 0) {
            data.AccreditationListDetail.forEach(el => {
              el.EffectiveDate = el.EffectiveDate ? this.momentToJS(el.EffectiveDate) : null;
              el.ExpriryDate = el.ExpriryDate ? this.momentToJS(el.ExpriryDate) : null;
            });
            this.arrAccreditations = data.AccreditationListDetail;
          }else{
            this.arrAccreditations = [];
          }
          this.requestedByClients = data?.RequestedByObj;
          if (data.DoctorClientRequestings && data.DoctorClientRequestings.length > 0) {
            data.DoctorClientRequestings.forEach(el => {
              el.RequestingDate = el.RequestingDate ? this.momentToJS(el.RequestingDate) : null;
            });
            this.doctorClientRequestings = data.DoctorClientRequestings;
          } else {
            this.doctorClientRequestings = [];
          }
          if (data.ExcludeTypeOfServices && data.ExcludeTypeOfServices.length > 0) {
            this.excludeTypeOfServices = data.ExcludeTypeOfServices;
          } else {
            this.excludeTypeOfServices = [];
          }
          if (data.DoctorBookingAndCancellationTerms && data.DoctorBookingAndCancellationTerms.length > 0) {
            data.DoctorBookingAndCancellationTerms.forEach(el => {
              el.Created = el.Created ? this.momentToJS(el.Created) : null;
              el.Modified = el.Modified ? this.momentToJS(el.Modified) : null;
            });
            this.bccList = data.DoctorBookingAndCancellationTerms;
          } else {
            this.bccList = [];
          }
          if (data.DoctorConfidentialInsights && data.DoctorConfidentialInsights.length > 0) {
            data.DoctorConfidentialInsights.forEach(el => {
              el.Created = el.Created ? this.momentToJS(el.Created) : null;
              el.Modified = el.Modified ? this.momentToJS(el.Modified) : null;
            });
            this.ciList = data.DoctorConfidentialInsights;
          } else {
            this.ciList = [];
          }

          if (!this.dataDoctor.Id) {
            this.dataDoctor = {
              ...this.dataDoctor,
              Title: 'Dr',
              IsSendBookingConfToEmail2: true,
              IsSendPromotionMassEmail1: true,
              SubscribeToPromotionCampaign: true,
              SubscribeToAppointmentUpdateReminders: true,
              SubscribeToClinicAvailabilityReminders: true,
              SubscribeToAttendanceReminders: true,
              SubscribeToAttendanceRemindersViaEmail: true,
              SubscribeToDictationAndReportReminders: true,
              SubscribeToDictationAndReportRemindersViaEmail: true,
              SubscribeToAppointmentReminders: true,
              SubscribeToAppointmentRemindersViaEmail: true,
              IsRecruiting: true,
              RecruitingDate: moment(new Date()).format('DD/MM/YYYY'),
              CountryId: 16,
              AssignedToId: api.currentUser.data.id,
              //api.currentUser.data.FullName
            };
          }

          this.handleReactionProvider();
          const consultingLocations = data.ConsultingLocations?.split(',') || [];
          this.dataDoctor.ConsultingLocations = consultingLocations.map(loc => {
            const [locId, location, postcode] = loc.split('|');
            return { id: locId, location, postcode };
          });

          this.selectedLocationsId = consultingLocations.map(l => l.split('|')[0]);

          if (data.Attachments.length) {
            this.sampleReports = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.SampleReport;
            });
            this.attRequiredConsentForm = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.RequiredConsentForm;
            });
            this.signatures = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.Signature;
            });
            this.letterHeadAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.LetterHead;
            });
            this.aHRPAAccreditationsAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.AHRPAAccreditation;
            });

            this.profilePictureAttachments = data.Attachments.filter(att => {
              return att.AttachmentType === DoctorAttachmentType.ProfilePicture;
            });
          } else {
            this.sampleReports = [];
            this.attRequiredConsentForm = [];
            this.signatures = [];
            this.letterHeadAttachments = [];
            this.aHRPAAccreditationsAttachments = [];
            this.profilePictureAttachments = [];
          }
          this.selectedClinicsId = [];
          this.arrDoctorRates = data.LstDoctorRate;
          this.loading = false;
        }),
      );
      await customFetch('/MedicalDoctor/GetLookupConfigByParents', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          parents: this.dataDoctor.Specialties.map(s => {
            return this.dataLookupConfig.speItemList.find(i => i.Id === s);
          }),
        }),
      }).then(
        action(data => {
          this.dataLookupConfigByParents = data;
          this.loading = false;
        }),
      );
      this.dataDoctor.TypeOfReports = [];
    } catch (e) {}
  };

  @computed get dataIndustryExpertTypes() {
    return (this.dataLookupConfig?.indItemList || []).map(ind => ({
      value: ind.Id,
      text: ind.Label,
    }));
  }

  @computed get dataReportTypes() {
    return (this.dataActiveReportTypeList?.itemList || []).map(t => ({
      value: t.Id,
      text: t.Name,
    }));
  }

  @computed get dataWaitingNotifications1() {
    const list = this.CMsWaitingNotification1;
    const array = !!list
      ? uniqBy([...(list.CmList || []), ...this.dataAllCaseManager], 'Id')
      : [...this.dataAllCaseManager];
    return array
      .map((i, idx) => ({
        value: i.Id,
        text: `${i.Id} - ${i.FirstName} ${i.LastName} - ${i.Email}`,
        key: idx,
        data: i,
      }))
      .filter(i => !!i.value);
  }

  @computed get dataWaitingNotifications2() {
    const list = this.CMsWaitingNotification2;
    const array = !!list
      ? uniqBy([...(list.CmList || []), ...this.dataAllCaseManager], 'Id')
      : [...this.dataAllCaseManager];
    return array
      .map((i, idx) => ({
        value: i.Id,
        text: `${i.Id} - ${i.FirstName} ${i.LastName} - ${i.Email}`,
        key: idx,
        data: i,
      }))
      .filter(i => !!i.value);
  }

  @computed get dataBlockedClients() {
    const list = this.blockedClients;
    const array = !!list
      ? uniqBy([...(list.ClientList || []), ...this.dataAllClients], 'Id')
      : [...this.dataAllClients];
    return array
      .map((i, idx) => ({
        value: i.Id,
        text: `${i.Id} - ${i.Name}`,
        key: idx,
        data: i,
      }))
      .filter(i => !!i.value);
  }

  @computed get dataRequestedByClients() {
    const list = this.requestedByClients;
    const array = !!list
      ? uniqBy([...(list.ClientList || []), ...this.dataAllClients], 'Id')
      : [...this.dataAllClients];
    return array
      .map((i, idx) => ({
        value: i.Id,
        text: `${i.Id} - ${i.Name}`,
        key: idx,
        data: i,
      }))
      .filter(i => !!i.value);
  }

  @computed get dataLocations() {
    return [...this.locationList, ...this.dataDoctor.ConsultingLocations].map(l => ({
      value: l.id,
      text: `${l.location} - Postcode: ${l.postcode}`,
    }));
  }

  @computed get dataStates() {
    return (this.dataState?.itemList || []).map(s => ({
      value: s,
      text: s,
    }));
  }

  @computed get dataSpecialties() {
    return (this.dataLookupConfig?.speItemList || []).map(s => ({
      value: s.Id,
      text: s.Label,
    }));
  }

  @computed get dataSubSpecialties() {
    return (this.dataLookupConfigByParents?.itemList || []).map(s => ({
      value: s.Id,
      text: s.Label,
    }));
  }

  @computed get dataQualifications() {
    var arrRet = (this.dataLookupConfig?.quaItemList || []).map(q => ({
      value: q,
      text: q,
    }));
    const lstQualifications = toJS(this.dataDoctor.Qualifications);
    if (lstQualifications && lstQualifications.length > 0) {
      lstQualifications.forEach(q => {
        var findObj = arrRet.find(i => i.value === q);
        if (!findObj)
          arrRet.push({
            value: q,
            text: q,
          });
      });
    }
    return arrRet;
  }

  @computed get dataAccreditations() {
    return (this.dataLookupConfig?.accreditationList || []).map(a => ({
      value: a.Id,
      text: a.Label,
    }));
  }

  @computed get dataStaffs() {
    return (this.dataStaff?.itemList || []).map(s => ({
      value: s.Id,
      text: s.FullName,
    }));
  }

  @computed get dataCMs() {
    return (this.dataCaseManager || []).map(s => ({
      value: s.Id,
      text: s.FullName,
    }));
  }

  @computed get getCountryList() {
    return (this.dataCountry || []).map(s => ({
      value: s.CountryId,
      text: s.CountryName,
    }));
  }

  @computed get getCityList() {
    return (this.dataCity || []).map(s => ({
      value: s.CityId,
      text: s.CityName,
    }));
  }

  @computed get getMailingCityList() {
    return (this.dataMailingCity || []).map(s => ({
      value: s.CityId,
      text: s.CityName,
    }));
  }

  @computed get selectedReportTypes() {
    return (this.selectedAcceptedReportTypes || []).map(typeId => {
      return this.dataActiveReportTypeList.itemList.find(type => type.Id === typeId)?.Name;
    });
  }

  @computed get selectedLocations() {
    return (this.dataDoctor?.ConsultingLocations || []).map(l => `${l.location} - Postcode: ${l.postcode}`);
  }

  @computed get selectedSpecialties() {
    return (this.dataDoctor?.Specialties || []).map(speId => {
      return this.dataLookupConfig.speItemList.find(spe => spe.Id === speId)?.Label;
    });
  }

  @computed get selectedSubSpecialties() {
    return (this.dataDoctor?.SubSpecialties || []).map(speId => {
      return (this.dataLookupConfigByParents?.itemList || []).find(spe => spe.Id === speId)?.Label;
    });
  }

  @computed get selectedAccreditations() {
    return (this.dataDoctor?.AccreditationList || []).map(accId => {
      return this.dataLookupConfig.accreditationList.find(acc => acc.Id === accId)?.Label;
    });
  }

  @computed get selectedCountry() {
    return (this.dataCountry || []).find(c => c.CountryId === this.dataDoctor.CountryId)?.CountryName;
  }

  @computed get selectedMailingCountry() {
    return (this.dataCountry || []).find(c => c.CountryId === this.dataDoctor.CorrespondenceCountryId)?.CountryName;
  }

  @computed get selectedCity() {
    return (this.dataCity || []).find(c => c.CityId === this.dataDoctor.CityId)?.CityName;
  }

  @computed get selectedMailingCity() {
    return (this.dataMailingCity || []).find(c => c.CityId === this.dataDoctor.CorrespondenceCityId)?.CityName;
  }

  @computed get dataTypeOfClaims() {
    return [...this.typeOfClaimList].map(l => ({
      value: l.Id,
      text: l.Name,
    }));
  }

  @computed get dataTypeOfReports() {
    return [...this.typeOfReportList].map(l => ({
      value: l.Id,
      text: l.Name,
    }));
  }

  @computed get selectedGenders() {
    return [
      { value: 1, text: 'Male' },
      { value: 2, text: 'Female' },
      { value: 3, text: 'Prefer not to say' },
      { value: 4, text: 'Non-Binary' },
    ];
  }

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  formatPhoneNumber = (pn, type) => {
    if (pn) {
      const phone = pn.replace(/[^\d]/g, '');
      const phoneLength = phone.length;
      if (type === 'tele') {
        if (phoneLength < 4) return phone;
        return `${phone.slice(0, 4)} ${phone.slice(4)}`;
      } else {
        if (phoneLength < 2) return phone;
        return `${phone.slice(0, 2)} ${phone.slice(2)}`;
      }
    }
    return pn;
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };
  @action resetStore = () => {
    this.clinicKeyword = '';
    this.totalPage = 0;
    this.totalItemsClinic = 0;
    this.handleDisposeReaction();
  };

  reactionProvider = null;
  reactionProvider2 = null;
  reactionProvider3 = null;
  reactionProvider4 = null;
  handleDisposeReaction = () => {
    this.reactionProvider && this.reactionProvider();
    this.reactionProvider2 && this.reactionProvider2();
    this.reactionProvider3 && this.reactionProvider3();
    this.reactionProvider4 && this.reactionProvider4();
  };

  handleReactionProvider = () => {
    this.reactionProvider = reaction(
      () => this.dataDoctor.ProviderNumber,
      () => {
        if (!this.dataDoctor.ProviderNumber) {
          // this.dataDoctor.ProviderNumberNote = '';
          // this.dataDoctor.ProviderNumber2 = '';
          // this.dataDoctor.ProviderNumber2Note = '';
        }
      },
    );

    this.reactionProvider2 = reaction(
      () => this.dataDoctor.ProviderNumber2,
      () => {
        if (!this.dataDoctor.ProviderNumber2) {
          // this.dataDoctor.ProviderNumber2Note = '';
          // this.dataDoctor.ProviderNumber3 = '';
          // this.dataDoctor.ProviderNumber3Note = '';
        }
      },
    );

    this.reactionProvider3 = reaction(
      () => this.dataDoctor.ProviderNumber3,
      () => {
        if (!this.dataDoctor.ProviderNumber3) {
          // this.dataDoctor.ProviderNumber3Note = '';
          // this.dataDoctor.ProviderNumber4 = '';
          // this.dataDoctor.ProviderNumber4Note = '';
        }
      },
    );
    this.reactionProvider4 = reaction(
      () => this.dataDoctor.ProviderNumber4,
      () => {
        if (!this.dataDoctor.ProviderNumber4) {
          // this.dataDoctor.ProviderNumber4Note = '';
          // this.dataDoctor.ProviderNumber4 = '';
          // this.dataDoctor.ProviderNumber4Note = '';
        }
      },
    );
  };

  @action onCheckPassRates = (reportId, svType) => {
    customFetch('/MedicalDoctor/CheckPassRateTypeOfReport', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        doctorId: this.dataDoctor.Id,
        typeOfReportId: reportId,
        serviceType: svType,
      }),
    }).then(
      action(data => {
        if (data.status === 'success') {
          this.arrDoctorRates.forEach(r => {
            var currentItem = this.arrDoctorRates.find(i => i.idx === reportId && i.ServiceType === svType);
            if (currentItem) {
              if (svType === 'IME') {
                currentItem.MinRate = data.minIMERate;
                currentItem.MaxRate = data.maxIMERate;
                currentItem.AvgRate = data.avgIMERate;
                currentItem.ServiceItems = data.imeServiceItems;
                if (!currentItem.RateFrom) currentItem.RateFrom = data.minIMERate;
                if (!currentItem.RateTo) currentItem.RateTo = data.maxIMERate;
              } else if (svType === 'File Review') {
                currentItem.MinRate = data.minFRRate;
                currentItem.MaxRate = data.maxFRRate;
                currentItem.AvgRate = data.avgFRRate;
                currentItem.ServiceItems = data.frServiceItems;
                if (!currentItem.RateFrom) currentItem.RateFrom = data.minFRRate;
                if (!currentItem.RateTo) currentItem.RateTo = data.maxFRRate;
              }
            }
          });
        }
      }),
    );
  };
}

export default new DoctorDetailsStore();
