import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { action } from 'mobx';

import Modal from '../../../shared/Modal/';
import store from '../store';
import DragDropFile from '../../Shared/DragDropComponent';

@observer
class UploadComponent extends React.Component {
  handleRemoveFile = id => {
    store.removeAttachment(id);
  };

  @action handleUpload = (fileList, reportType) => {
    const attachment = {
      FileName: fileList[0].name,
      Title: fileList[0].title,
      NumberOfPages: fileList[0].numberOfPages,
      CampaignId: store.data.Id,
      AttachmentType: reportType,
      Id: 0,
    };
    const mktAttachment = store.data.Attachments || [];
    const newAttachments = [...mktAttachment, attachment].map((i, idx) => ({
      ...i,
      key: idx,
    }));
    store.setFieldsDataValue({ Attachments: newAttachments });
  };

  render() {
    const { isOpenModalUpload, modalUploadParams } = store;
    const { reportType, option, multiple, disabled, disabledDelete, uploadTitle, disableDragDrop } = modalUploadParams;

    const { Attachments } = store.data;

    const attachments_ = Attachments.filter(({ AttachmentType }) => AttachmentType === reportType);

    const title =
      attachments_ && !!attachments_.length
        ? `${attachments_.length} ${attachments_.length > 1 ? 'attachments' : 'attachment'} ${
            attachments_.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;

    const rule = option || null;
    return (
      <Modal
        visible={isOpenModalUpload}
        width={600}
        onCancel={() => store.toggleModalModalUpload(false, {})}
        className="custom-modal"
        footer={
          <React.Fragment>
            <Button color="blue" onClick={() => store.toggleModalModalUpload(false, {})}>
              OK
            </Button>
          </React.Fragment>
        }
        title={title}
      >
        <DragDropFile
          onUploadFile={this.handleUpload}
          onDeleteFile={this.handleRemoveFile}
          type={reportType}
          accept={rule ? rule.allowedExtensions : null}
          maxSize={rule ? parseInt(rule.maxFileSize.replace('MB')) : 50}
          multiple={multiple}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          disableDragDrop={disableDragDrop}
          renderAttachment={store.renderAttachment}
          toggleModalModalUpload={store.toggleModalModalUpload}
          Attachments={attachments_}
          baseUrl="/UserFile/CampaignAttachment"
        />
      </Modal>
    );
  }
}
export default UploadComponent;
