import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form, Input } from 'antd';

import ClinicStore from './store';
import { Popup, Icon as IconSemantic } from 'semantic-ui-react';

const ReceivingDailyBookingOpt = () => {
  return (
    <React.Fragment>
      <Form name="receiving-daily-booking-opt">
        <Form.Item
          required={ClinicStore.clinicInfo.EnableReceving}
          validateStatus={ClinicStore.errors['EmailReceiveDailyBookings'] ? 'error' : null}
          help={ClinicStore.errors['EmailReceiveDailyBookings']}
          label={
            <React.Fragment>
              <span style={{ paddingRight: '3px' }}>Email to Receive Daily Daysheets</span>
              <Popup
                trigger={<IconSemantic name="info circle" />}
                content="Specialist daysheets will be sent to this clinic 
                (MAIL-06: no bookings, MAIL-07: with bookings)"
                position="top center"
                wide="very"
              />
            </React.Fragment>
          }
        >
          <Input
            size="large"
            value={ClinicStore.clinicInfo.EmailReceiveDailyBookings}
            onChange={ClinicStore.handleFieldChange('EmailReceiveDailyBookings')}
          />
        </Form.Item>
        <Form.Item
          label="Sending Frequencies"
          validateStatus={ClinicStore.errors['SendingFrequencies'] ? 'error' : null}
          help={ClinicStore.errors['SendingFrequencies']}
        >
          <Input
            size="large"
            value={ClinicStore.clinicInfo.SendingFrequencies}
            onChange={ClinicStore.handleFieldChange('SendingFrequencies')}
          />
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={ClinicStore.handleFieldChange('EnableReceving')}
              checked={ClinicStore.clinicInfo.EnableReceving}
            ></Checkbox>
            <label style={{ marginLeft: '5px', verticalAlign: 'middle' }}>Enable Receiving</label>
          </div>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default observer(ReceivingDailyBookingOpt);
