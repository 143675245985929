import React from 'react';
import cx from 'classnames';
import { Divider, notification, Spin } from 'antd';
import { Button, Dimmer } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import './index.scss';

import LoginInformation from './LoginInformation';
import ContactInformation from './ContactInformation';
import AddEditModal from './Modal';
import AddEditStore from './store';
import router from '../../../stores/router';
import dashboard from '../../../stores/dashboard';
import { saveCaseManager } from './service';
import Chart from './Chart';
import * as api from '@stores/api';

const STEPS = ['General Information', 'Login Information', 'Activity Dashboard'];

window.getFormCasemangerProfile = () => {
  return document.getElementById('form-container-case-manager-profile');
};

@observer
class AddEditCaseManager extends React.Component {
  state = {
    currentStep: 0,
    action: 'view',
    isRerender: false,
  };

  componentDidMount() {
    AddEditStore.fetchCMData();
  }

  getClientHeight = () => {
    const tbl = document.getElementById('page-container-add-edit-case-manager');
    if (tbl) {
      return tbl.clientHeight;
    }
    return window.innerHeight;
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.destroy();
    notification.error({
      message: 'Error',
      description: message,
    });
  };

  actionSave = async body => {
    try {
      const respone = await saveCaseManager(body);
      if (respone.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Data has been saved successfully.`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false, loading: true });
        return AddEditStore.fetchCMData();
      } else {
        console.log(respone.errorList);
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Some error occurred. Please report this error to system administrator.`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false, loading: true });
      }
    } catch (e) {
      AddEditStore.setFieldsValue({ loadingSave: false });
    }
  };

  handleSave = () => {
    const { errors, CMInfo } = AddEditStore;

    AddEditStore.setFieldsValue({ loadingSave: true });

    if (CMInfo.OldPassword || CMInfo.NewPassword) {
      if (CMInfo.NewPassword !== CMInfo.ConfirmNewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Password and confirm password does not match`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (!CMInfo.OldPassword && CMInfo.NewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Please enter your old password`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (CMInfo.OldPassword && !CMInfo.NewPassword) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Please enter your new password`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else if (CMInfo.NewPassword.length < 8) {
        notification.destroy();
        notification.error({
          message: 'Fail',
          description: `Must contain at least 8 characters`,
          duration: 2,
        });
        AddEditStore.setFieldsValue({ loadingSave: false });
      } else {
        const { passwordStrengthCheck } = AddEditStore;
        if (!passwordStrengthCheck.digit) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.lowerCaseCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one lowercase letter.`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.upperCaseCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)`,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else if (!passwordStrengthCheck.specialCharacter) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,) `,
            duration: 2,
          });
          AddEditStore.setFieldsValue({ loadingSave: false });
        } else {
          if (!!Object.keys(errors).length) {
            AddEditStore.setFieldsValue({ loadingSave: false });
            return this.showFormErrorNoti();
          } else {
            return this.actionSave({ ...CMInfo, IsChangePassword: true });
          }
        }
      }
    } else {
      if (!!Object.keys(errors).length) {
        AddEditStore.setFieldsValue({ loadingSave: false });
        return this.showFormErrorNoti();
      } else {
        return this.actionSave({ ...CMInfo, IsChangePassword: false });
      }
    }
  };
  handleClose = () => {
    return AddEditStore.toggleModal(true, {
      modalType: 'confirm',
      onOk: () => dashboard.close(router.location.pathname),
      message: 'Are you sure not to save changes?',
    })();
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <LoginInformation />;
      case 2:
        return <Chart />;
      default:
        return <ContactInformation isRerender={this.state.isRerender} />;
    }
  };

  render() {
    const { loading, loadingSave, loadingCheckDuplicate, open, CMInfo } = AddEditStore;
    return (
      <div id="page-container-add-edit-case-manager" className="page-container add-edit-case-manager">
        <Dimmer
          active={loading || loadingCheckDuplicate || loadingSave || !CMInfo}
          style={{ height: `${this.getClientHeight()}px` }}
          inverted
        >
          <Spin size="large" />
        </Dimmer>
        {open && <AddEditModal />}
        <div className="form-container" id="form-container-case-manager-profile">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {!loading && CMInfo && this.renderForm()}
        </div>
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && <Button primary onClick={this.handleSave}>
            Save
          </Button>}
          <Button onClick={this.handleClose} className="negative">
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditCaseManager;
