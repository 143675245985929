import './index.scss';

import cx from 'classnames';
import { omit } from 'lodash';
import React from 'react';

import {
  Filter,
  HighLightButton,
  Input,
  RangePicker,
  SubmitButton,
  SearchDropdown,
  SearchDropdownSpecialist,
  DatePicker,
  FloatingDropdown,
  FloatingDropdownItem,
} from './ToolbarComponents';

export function Container({ className, children, ...props }) {
  return (
    <div {...props} className={cx('Toolbar-container', className)}>
      {children}
    </div>
  );
}

export function Left({ className, children, ...props }) {
  const WrappedComponent = props.as || 'form';
  return (
    <WrappedComponent {...omit(props, 'as')} className={cx('Toolbar-left')}>
      {children}
    </WrappedComponent>
  );
}

export function Right({ className, children, ...props }) {
  const WrappedComponent = props.as || 'div';
  return (
    <WrappedComponent {...omit(props, 'as')} className={cx('Toolbar-right')}>
      {children}
    </WrappedComponent>
  );
}

const TableToolbar = {
  Container,
  Left,
  Right,
  Input,
  RangePicker,
  Filter,
  SubmitButton,
  HighLightButton,
  SearchDropdown,
  SearchDropdownSpecialist,
  DatePicker,
  FloatingDropdown,
  FloatingDropdownItem,
};

export default TableToolbar;
