import { observer } from 'mobx-react';
import React from 'react';
import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import ServiceFeeStore from './AMAServiceFeeStore';
import { action, toJS } from 'mobx';

const STATUST = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];
@observer
class ServiceFeeToolbar extends React.Component {
  handSubmit = e => {
    e.preventDefault();
    ServiceFeeStore.refetch({ curPage: 1 }, true);
  };

  openModalCreate = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'createServiceFee',
    });
  };

  openModalAddCalculationRule = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'addCalculationRule',
    });
  };

  exportAMACodes = () => {
    ServiceFeeStore.exportSearchResults();
  };

  openModalImport = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'import',
    });
  };

  openModalTreatment = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'checkTreatment',
    });
  };

  @action onChangeST = (_, { value }) => {
    ServiceFeeStore.filterServiceType = value;
    ServiceFeeStore.filterTypeProcedure = '';
    ServiceFeeStore.fetchTypeProcedureAMACodeFiles();
    ServiceFeeStore.refetch({ curPage: 1 }, true);
  };

  @action onChangeTS = (_, { value }) => {
    ServiceFeeStore.filterTypeProcedure = value;
    ServiceFeeStore.refetch({ curPage: 1 }, true);
  };

  @action onChangeStatus = (_, { value }) => {
    ServiceFeeStore.filterStatus = value;
    ServiceFeeStore.refetch({ curPage: 1 }, true);
  };

  render() {
    const { loading, keyword } = ServiceFeeStore;
    return (
      <div>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={ServiceFeeStore.handleSearchChange} value={keyword} disabled={loading} />
            <TableToolbar.SearchDropdown
              disabled={loading}
              options={toJS(ServiceFeeStore.dataServiceType) || []}
              value={ServiceFeeStore.filterServiceType}
              onChange={this.onChangeST}
              searchPlaceHolder="Service Type"
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              options={toJS(ServiceFeeStore.dataTypeProcedure) || []}
              value={ServiceFeeStore.filterTypeProcedure}
              onChange={this.onChangeTS}
              searchPlaceHolder="Type Of Procedure"
            />
            <TableToolbar.Filter
              disabled={loading}
              options={STATUST.map(i => ({ value: i.value, text: i.label }))}
              value={STATUST.find(i => i.value === ServiceFeeStore.filterStatus).label}
              onChange={this.onChangeStatus}
              label="Status"
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={ServiceFeeStore.resetStore}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              disabled={loading}
              onClick={() => ServiceFeeStore.refetch({}, true)}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {/* <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openModalTreatment()}
              style={{ marginLeft: 5 }}
            >
              Check Treatment Fees
              </TableToolbar.HighLightButton> */}
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalCreate()}
                style={{ marginLeft: 5 }}
              >
                Add Code
              </TableToolbar.HighLightButton>
            )}
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={() => this.exportAMACodes()}
                style={{ marginLeft: 5 }}
              >
                Export Searches
              </TableToolbar.HighLightButton>
            )}
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalImport()}
                style={{ marginLeft: 5 }}
              >
                Import Codes
              </TableToolbar.HighLightButton>
            )}
            {/* {api.isAdminOrMagStaffOrAccounting() && (
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalAddCalculationRule()}
                style={{ marginLeft: 5 }}
              >
                Add Caculation Rule
              </TableToolbar.HighLightButton>
            )} */}
          </TableToolbar.Left>
        </TableToolbar.Container>
      </div>
    );
  }
}

export default ServiceFeeToolbar;
