import customFetch from '../../../utils/customFetch';

export const getItemInfor = id => {
  return customFetch('/MBSCode/FindItem?id=' + id, {
    method: 'GET',
  });
};

export const getCategories = () => {
  return customFetch('/MBSCode/GetCategories', {
    method: 'GET',
  });
};

export const getGroups = () => {
  return customFetch('/MBSCode/GetGroup', {
    method: 'GET',
  });
};

export const getAllItems = param => {
  return customFetch('/MBSCode/GetAll', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const saveData = param => {
  return customFetch('/MBSCode/Save', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const deleteItem = id => {
  return customFetch('/MBSCode/DeleteRecord', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ id: id }),
  });
};

export const getStateList = id => {
  return customFetch('/MBSCode/GetStates', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: {},
  });
};

export const searchAMACaculation = param => {
  return customFetch('/AMACalculationRule/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};
