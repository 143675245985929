import './DatePicker.scss';

import omit from 'lodash/omit';
import React from 'react';
import { DateInput, DatesRangeInput, TimeInput } from 'semantic-ui-calendar-react';
import { Icon } from 'semantic-ui-react';

import TextInput from './TextInput';

const Pickers = {
  DateInput,
  DatesRangeInput,
  TimeInput,
};

class DatePicker extends React.Component {
  static defaultProps = {
    closePortalWhenChanges: true,
    pickerType: 'DateInput',
  };

  constructor(props) {
    super(props);

    this.formikCtx = null;
    this.PickerComp = null;
    this.pickerRef = React.createRef();
    this.state = {
      mounted: false,
      listener: false,
      selectedDate: '',
    };
  }

  assignFormikCtx = formikCtx => {
    this.formikCtx = formikCtx;
  };

  setPickerComp = callback => {
    const { pickerType } = this.props;
    this.PickerComp = Pickers[pickerType];
  };

  onInputFocus = () => {
    this.setState({ mounted: true }, () => {
      setTimeout(() => {
        this.setState({ listener: true });
      }, 200);
    });
  };

  handleDateChange = (_, { value }) => {
    const { name, onChange } = this.props;
    this.formikCtx.form.values[name] = value;
    onChange && onChange(value);

    this.setState({ selectedDate: value }, () => {
      if (this.props.closePortalWhenChanges) {
        this.setState({ mounted: false, listener: false });
      }
    });
  };

  onClear = () => {
    const { onChange } = this.props;
    onChange && onChange('');
  };

  componentDidMount() {
    this.setPickerComp();
    window.addEventListener('click', this.closePopup);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickerType !== this.props.pickerType) {
      this.setPickerComp();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closePopup);
  }

  closePopup = event => {
    const { mounted, listener } = this.state;
    if (mounted && listener) {
      const { clientX, clientY } = event;
      const picker = this.pickerRef.current;
      const { left, right, top, bottom } = picker.getBoundingClientRect();
      if (!(clientX >= left && clientX <= right && clientY >= top && clientY <= bottom)) {
        this.setState({ mounted: false, listener: false });
      }
    }
  };

  render() {
    const { PickerComp } = this;
    const { mounted } = this.state;
    const { pickerType, format, defaultValue, pickerStyle, value } = this.props;

    return (
      <div className="DatePicker" ref={this.pickerRef}>
        <TextInput
          {...omit(this.props, ['pickerType', 'pickerStyle', 'closePopupWhenOnChange', 'closePortalWhenChanges'])}
          children={this.assignFormikCtx}
          onFocus={this.onInputFocus}
          className="input--overridden-style"
          icon={
            <Icon
              name={!!value ? 'x' : pickerType === 'TimeInput' ? 'time' : 'calendar'}
              link={!!value}
              onClick={() => {
                !!value && this.onClear();
              }}
            />
          }
          iconPosition="right"
          value={value}
        />
        {mounted && (
          <div className="Picker" ref={this.pickerRef} style={pickerStyle}>
            <div className="Picker-Arrow" />
            <PickerComp
              inline
              dateFormat={format || 'DD/MM/YYYY'}
              value={value || defaultValue}
              onChange={this.handleDateChange}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;
