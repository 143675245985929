import dashboardStore from '@stores/dashboard';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import imeStore from './IMEStore';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'magRefNo' },
  { title: 'Request Date', sortKey: 'bookingDate' },
  { title: 'Claim No', sortKey: 'claimNo' },
  { title: 'Type Of Claim/Report' },
  { title: 'Claimant', sortKey: 'claimant' },
  { title: 'Client ' },
  { title: 'Case Manager' },
  { title: 'Appointment Date', sortKey: 'appointmentDate' },
  { title: 'Case Progress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
];

class CmTable extends React.Component {
  handlePageClick = page => {
    imeStore.refetch({ currentPage: page });
  };
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Appointment':
        return 1;
      case 'Attendance':
        return 3;
      case 'Completion':
        return 4;
      case 'UploadInvoices':
        return 5;

      case 'Corespondence':
        return 6;
      default:
        return 0;
    }
  };

  openCaseDetail = (id, type) => () => {
    const caseNo = `Case A-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  openModalCaseProgress = (id, caseNo) => () => {
    imeStore.toggleModal(true, { id, caseNo })();
  };

  renderTableBody = () => {
    return (
      <React.Fragment>
        {imeStore.data.itemList.map(({ id, ...item }) => (
          <Table.Row key={id}>
            <Table.Cell className="Cell-actions">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item onClick={this.openCaseDetail(id, 'CaseDetails')}>View Request Details</Menu.Item>
                    <Menu.Item onClick={this.openCaseDetail(id, 'Appointment')}>View Case Documents</Menu.Item>
                    {item.currentStatus !== 'Cancelled-No Fee' && item.currentStatus !== 'Late Cancellation Fee' && (
                      <Menu.Item onClick={this.openCaseDetail(id, 'Attendance')}>Update Patient Attendance</Menu.Item>
                    )}
                    <Menu.Item onClick={this.openCaseDetail(id, 'UploadInvoices')}>Upload Invoices</Menu.Item>
                    <Menu.Item onClick={this.openCaseDetail(id, 'Completion')}>Upload Dictations & Reports</Menu.Item>
                    <Menu.Item onClick={this.openCaseDetail(id, 'Corespondence')}>View Case Correspondence</Menu.Item>
                  </Menu>
                }
              >
                <Icon name="ellipsis vertical" />
              </Dropdown>
            </Table.Cell>
            <Table.Cell className="Cell-nowrap Cell-link">
              <Link to="#" onClick={this.openCaseDetail(id)}>
                {item.magRefNo}
              </Link>
            </Table.Cell>
            <Table.Cell className="Cell-nowrap">
              {item.bookingDate
                ? moment(formatDate(item.bookingDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
            <Table.Cell className="Cell-nowrap">{item.claimNo}</Table.Cell>
            <Table.Cell>
              {item.typeOfClaim}/{item.typeOfReport}
            </Table.Cell>
            <Table.Cell>{item.claimant}</Table.Cell>
            <Table.Cell>{item.client}</Table.Cell>
            <Table.Cell>
              <h5 className="ma-0">{item.caseManager}</h5>
              {item.isDirect && <div>{item.cmEmail}</div>}
            </Table.Cell>
            <Table.Cell className="Cell-nowrap">
              {item.appointmentDate
                ? moment(formatDate(item.appointmentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
            <Table.Cell className="Cell-link">
              <Link to="#" onClick={this.openModalCaseProgress(id, item.magRefNo)}>
                {item.statusDoctorText}
              </Link>
            </Table.Cell>
            <Table.Cell className="Cell-nowrap">
              {item.statusDate
                ? moment(formatDate(item.statusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = imeStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={imeStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={imeStore.currentPage}
          totalPage={imeStore.totalPage}
          totalItems={imeStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default observer(CmTable);
