import { action, observable } from 'mobx';

class TypeOfClaimsStore {
  @observable loading = false;
  @observable loadingAcc = true;
  @observable dataAcc = null;

  @observable activeIdx = 0;
  @observable type = null;

  @observable dataClaim = {
    id: 0,
    name: '',
    descriptions: '',
    isActive: false,
  };

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action onReset = () => {
    this.dataClaim = {
      id: 0,
      name: '',
      descriptions: '',
      isActive: false,
    };
  };
}

export default new TypeOfClaimsStore();
