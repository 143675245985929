import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Select, Checkbox, Radio, DatePicker, notification } from 'antd';
import { Button, Table, Label, Popup, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { uniq } from 'lodash';
import { renderDate } from '../../../components/case-details-routes/Shared';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import store from './store';
import { sendOnboardedNoticeToCMs } from './service';
import { Link } from 'react-router-dom';
import dashboard from '../../../stores/dashboard';
import moment from 'moment';
import { formatDate } from '../../../utils/functions';
import ModalConfirm from '../../shared/Modal/ModalConfirm';

const avatarPlaceholder = '';

@observer
class FavouriteSpecialists extends React.Component {
  state = {
    checkedAll: !!store.registeredSpecialistIds.length,
  };

  detectIsNumeric = numberStr => {
    if (typeof numberStr != 'string') return false;
    return !isNaN(numberStr) && !isNaN(parseFloat(numberStr));
  };

  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };

  handleFilterSelectedDoctor = doctor => {
    const { searchSpecialist } = store;
    if (searchSpecialist) {
      const fullname = doctor.FullName.toUpperCase();
      if (this.detectIsNumeric(searchSpecialist)) {
        if (
          fullname.search(searchSpecialist.toUpperCase()) !== -1 ||
          doctor.Id.toString().search(searchSpecialist) !== -1
        ) {
          return doctor;
        }
        return null;
      } else {
        if (fullname.search(searchSpecialist.toUpperCase()) !== -1) {
          return doctor;
        }
        return null;
      }
    } else {
      return doctor;
    }
  };

  sendOnboardedNoticeToCMs = doctorId => {
    sendOnboardedNoticeToCMs(doctorId, store.CMInfo.Id).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Send Onboarded Notice to CMs successfully.`,
          duration: 5,
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Send Onboarded Notice to CMs error.`,
          duration: 5,
        });
      }
    });
  };

  openDoctorDetail = (id, name) => {
    localStorage.setItem('DoctorName', name);
    dashboard.close('/view/add-edit-doctor-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-doctor-2?id=${id}&action=edit&step=0`);
    });
  };

  openModal = options => {
    return store.toggleModal(true, options);
  };

  renderTableBody = () => {
    if (store.selectedSpecialist === 'selected') {
      return (
        store.registeredSpecialistObjs
          //.filter(i => this.handleFilterSelectedDoctor(i))
          .map(record => (
            <Table.Row key={record.Id}>
              <Table.Cell textAlign="center">
                <Checkbox checked={record.isChecked} onChange={this.handleCheckSpecialist(record.Id)} />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <DatePicker
                  style={{ width: '180px' }}
                  size="large"
                  value={record.RequestingDate}
                  onChange={this.handleRequestingDateChange(record.Id)}
                  format="DD/MM/YYYY"
                />
              </Table.Cell>
              <Table.Cell className="Cell-nowrap Cell-link doctor-info">
                <div
                  className="avatar"
                  style={{
                    background: `url(${record.Avartar || avatarPlaceholder})`,
                  }}
                />
                <div className="doctor-name">
                  <Link to="#" onClick={() => this.openDoctorDetail(record.Id, record.Title + ' ' + record.FullName)}>
                    {record.Title} {record.FullName}
                  </Link>
                </div>
              </Table.Cell>
              <Table.Cell>
                {record.Specialty &&
                  record.Specialty.split(', ').map(tag => (
                    <Label className="label-tag" key={tag}>
                      {tag}
                    </Label>
                  ))}
              </Table.Cell>
              {/* <Table.Cell>{record?.ClientType || null}</Table.Cell> */}
              <Table.Cell>{record.RecruitmentStatus || ''}</Table.Cell>
              <Table.Cell style={{ minWidth: '200px' }}>
                <Button
                  className="blue"
                  //onClick={() => this.sendOnboardedNoticeToCMs(record.Id)}
                  style={{ width: '100%' }}
                  onClick={this.openModal({
                    modalType: 'confirm',
                    message: 'Do you want to notify CM of this specialist joining our panel?',
                    onOk: () => this.sendOnboardedNoticeToCMs(record.Id),
                  })}
                >
                  Send{' '}
                </Button>
              </Table.Cell>
              <Table.Cell textAlign="center">{record?.CountIME || ''}</Table.Cell>
              <Table.Cell textAlign="center">{record?.CountFR || ''}</Table.Cell>
              <Table.Cell textAlign="center">{record?.CountSUPP || ''}</Table.Cell>
              <Table.Cell textAlign="center">{record?.CountMedNeg || ''}</Table.Cell>
            </Table.Row>
          ))
      );
    }
    return store.specialists.map(record => (
      <Table.Row key={record.Id}>
        <Table.Cell textAlign="center">
          <Checkbox checked={record.isChecked} onChange={this.handleCheckSpecialist(record.Id)} />
        </Table.Cell>
        <Table.Cell>
          <DatePicker
            style={{ width: '180px' }}
            size="large"
            value={record.RequestingDate}
            onChange={this.handleRequestingDateChange(record.Id)}
            format="DD/MM/YYYY"
          />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link doctor-info">
          <div
            className="avatar"
            style={{
              background: `url(${record.Avartar || avatarPlaceholder})`,
            }}
          />
          <div className="doctor-name">
            <Link to="#" onClick={() => this.openDoctorDetail(record.Id, record.Title + ' ' + record.FullName)}>
              {record.Title} {record.FullName}
            </Link>
          </div>
        </Table.Cell>
        <Table.Cell>
          {record.Specialty &&
            record.Specialty.split(', ').map(tag => (
              <Label className="label-tag" key={tag}>
                {tag}
              </Label>
            ))}
        </Table.Cell>
        <Table.Cell>{record.RecruitmentStatus || ''}</Table.Cell>
        {/* <Table.Cell>{record?.ClientType || null}</Table.Cell> */}
      </Table.Row>
    ));
  };

  isSpecialSelected = id => {
    const { registeredSpecialistObjs } = store;
    return registeredSpecialistObjs.find(obj => obj.Id === id);
    //return !!store.registeredSpecialistIds.find(sId => sId === id);
  };

  handleCheckAllSpecialist = ({ target: { checked } }) => {
    this.setState(
      { checkedAll: checked },
      action(() => {
        if (checked) {
          store.registeredSpecialistIds = uniq([...store.registeredSpecialistIds, ...store.specialists.map(s => s.Id)]);
        } else {
          store.registeredSpecialistIds = store.registeredSpecialistIds.reduce((acc, id) => {
            const index = store.specialists.findIndex(s => s.Id === id);
            if (index === -1) acc.push(id);
            return acc;
          }, []);
        }
      }),
    );
  };

  handleCheckSpecialist = id =>
    action(({ target: { checked } }) => {
      const { registeredSpecialistObjs } = store;
      if (checked) {
        var arrNew = [...registeredSpecialistObjs];
        var obj = arrNew.find(o => o.Id === id);
        var doctor = store.specialists.find(d => d.Id === id);
        if (doctor) {
          doctor.RequestingDate = moment();
          doctor.isChecked = true;
          if (!obj) {
            arrNew.push({ ...doctor, isChecked: true, RequestingDate: moment() });
          } else {
            obj.isChecked = true;
          }
        }
        store.setRegisteredSpecialistObjs(arrNew);
        //store.registeredSpecialistIds = [...store.registeredSpecialistIds, id];
      } else {
        var doctor = store.specialists.find(d => d.Id === id);
        if (doctor) {
          doctor.RequestingDate = null;
        }
        var arrNew = [...registeredSpecialistObjs];
        arrNew.forEach(el => {
          if (el.Id === id) {
            el.isChecked = false;
            //arrNew.push(el);
          }
        });
        store.setRegisteredSpecialistObjs(arrNew);
        //store.registeredSpecialistIds = store.registeredSpecialistIds.filter(sId => sId !== id);
      }
    });

  handleRequestingDateChange = id =>
    action(event => {
      let value = event ? (event.target ? event.target.value : event) : null;
      const { registeredSpecialistObjs, specialists } = store;
      var newSpecialists = [...specialists];
      newSpecialists.forEach(el => {
        if (el.Id === id) {
          el.RequestingDate = value;
        }
      });
      var arrNew = [...registeredSpecialistObjs];
      arrNew.forEach(el => {
        if (el.Id === id) {
          el.RequestingDate = value;
        }
      });
      store.setLstSpecialist(newSpecialists);
      store.setRegisteredSpecialistObjs(arrNew);
    });

  render() {
    var columns = [
      {
        title: (
          <React.Fragment>
            <div style={{ textAlign: 'center' }}>
              <div>Set to Favourite</div>
            </div>
          </React.Fragment>
        ),
      },
      { title: 'Requesting Date' },
      { title: 'Specialist' },
      { title: 'Specialty' },
      { title: 'Recruitment Status' },
      {
        title: (
          <React.Fragment>
            Notice of Available Specialist to CM
            <Popup
              trigger={<Icon name="info circle" style={{ marginLeft: '5px', color: 'black', marginTop: 5 }} />}
              content="Notify CM of specialist available on MLP panel (Mail-159)"
              position="top center"
            />
            {/* <Popup
              trigger={<Icon name="info circle" style={{ marginLeft: '-1.5rem', marginRight: '2rem' }} />}
              content="Send Onboarded notice (Mail-x) to all Requesting CMs"
              position="top center"
            /> */}
          </React.Fragment>
        ),
      },
      { title: 'IME Bookings' },
      { title: 'File Review Bookings' },
      { title: 'Supplementary Bookings' },
      { title: 'Med Neg Bookings' },
      // { title: 'Service Type' },
    ];

    if (store.selectedSpecialist === 'All') {
      columns = [
        {
          title: (
            <React.Fragment>
              <div style={{ textAlign: 'center' }}>
                <div>Set to Favourite</div>
                <Checkbox checked={this.state.checkedAll} onChange={this.handleCheckAllSpecialist} />
              </div>
            </React.Fragment>
          ),
        },
        { title: 'Requesting Date' },
        { title: 'Specialist' },
        { title: 'Specialty' },
        { title: 'Recruitment Status' },
        // { title: 'Service Type' },
      ];
    }

    return (
      <div className={cx({ disabled: store.specialistLoading })}>
        <div className="registered-specialists-searchbox">
          <div style={{ float: 'left', width: '100%', marginBottom: '6px' }}>
            <Input.Search
              placeholder="Search"
              style={{ marginRight: '5px', width: '300px', float: 'left' }}
              size="large"
              value={store.searchSpecialist}
              onChange={store.handleSearchChange}
            />
            <div style={{ marginRight: '5px', width: '300px', float: 'left', paddingTop: '7px' }}>
              {/* <Radio.Group value={store.selectedSearchSpecialistValue} onChange={store.handleChangeSearchSpecialist}>
                <Radio value={0}>All specialists</Radio>
                <Radio value={1}>Favourite specialists</Radio>
              </Radio.Group> */}
              <Select
                style={{ width: '100%', marginTop: '-7px' }}
                size="large"
                value={store.selectedSpecialist}
                onChange={store.handleSelectChangeSpecialist}
              >
                <Select.Option value="All">All specialists</Select.Option>
                {store.CMInfo.Id !== 0 && <Select.Option value="selected">Favourite specialists</Select.Option>}
              </Select>
            </div>
            <div style={{ marginTop: '0px', float: 'left' }}>
              <Button primary onClick={store.handleSearchSpecialist}>
                Search
              </Button>
              <Button negative onClick={store.handleClearSearchSpecialist}>
                Clear
              </Button>
            </div>
          </div>
        </div>
        <Table sortable striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {store.specialists.length ? this.renderTableBody() : <TableEmptyMessage colSpan={columns.length} />}
          </Table.Body>
          {store.selectedSpecialist === 'All' && (
            <TableFooterPagination
              colSpan={columns.length}
              totalItems={store.sumItemSpecialist}
              currentPage={store.curPageSpecialist}
              totalPage={store.sumPageSpecialist}
              onPageClick={store.handlePageClickSpecialist}
            />
          )}
        </Table>
      </div>
    );
  }
}

export default FavouriteSpecialists;
