import { formatDate } from '@utils/functions';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import store from './Store';

const columns = [
  { title: '' },
  { title: 'Id', sortKey: 'Id' },
  { title: 'Title', sortKey: 'Title' },
  { title: 'Description', sortKey: 'Description' },
  { title: 'Status', sortKey: 'Status' },
  { title: 'Type', sortKey: 'Type' },
  { title: 'Created', sortKey: 'Created' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

const typeLabel = [
  { text: `Staff`, value: `staff` },
  { text: `Case Manager`, value: `casemanager` },
  { text: `Specialist`, value: `specialist` },
  { text: `Claimant`, value: `claimant` },
  { text: `Typist`, value: `typist` },
];

@observer
class GreetingTable extends React.Component {
  openModal = options => {
    return store.toggleModal(true, options)();
  };

  renderDate = dateStr => {
    if (dateStr) {
      const date = formatDate(dateStr);
      return moment(date, 'DD MMM, YYYY').format('DD/MM/YYYY');
    }
    return '';
  };

  renderType = str => {
    if (str) {
      const array = str
        .split(',')
        .map(key => {
          const result = typeLabel.find(i => i.value === key);
          if (result) {
            return result.text;
          }
          return null;
        })
        .filter(opt => opt);
      return array.map((i, idx) => (
        <span key={idx} style={{ marginLeft: `${idx !== 0 ? '5px' : '0px'}` }}>
          {idx < array.length - 1 ? `${i},` : i}
        </span>
      ));
    }
    return '';
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.Status === 'active'}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">{Id}</Table.Cell>
        <Table.Cell
          selectable
          className={cx({
            'Cell-nowrap Cell-link': record.Status === 'active',
          })}
          onClick={() =>
            this.openModal({
              modalType: 'addEdit',
              id: Id,
              record,
            })
          }
        >
          <Link to="#">{record.Title}</Link>
        </Table.Cell>
        <Table.Cell>{record.Description}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.Status === 'active' })}>
            {record.Status === 'active' ? `Active` : `Inactive`}
          </p>
        </Table.Cell>
        <Table.Cell>{this.renderType(record.Type)}</Table.Cell>
        <Table.Cell>{this.renderDate(record.Created)}</Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  handleEditItemStatus = (id, record) => async () => {
    store.setFieldsValue({ loading: true });

    try {
      const result = await customFetch(
        `/GreetingTitle/UpdateStatus?id=${id}&status=${record.Status === 'inactive' ? 'active' : 'inactive'}`,
        {
          method: 'POST',
        },
      );
      if (result.status === 'success') {
        await store.refetch({}, true, 'byKeyword');
      }
    } catch (err) {
    } finally {
      store.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.Status === 'active' && (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'addEdit',
                  id,
                  record,
                })
              }
            >
              Edit
            </Menu.Item>
          )}
          {record.Status === 'inactive' && (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to enable this item?',
                  onOk: this.handleEditItemStatus(id, record),
                })
              }
            >
              Enable
            </Menu.Item>
          )}
          {record.Status === 'active' && (
            <Menu.Item
              onClick={() =>
                this.openModal({
                  modalType: 'confirm',
                  message: 'Do you want to disable this item?',
                  onOk: this.handleEditItemStatus(id, record),
                })
              }
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    store.refetch({ curPage: page }, 'byKeyword');
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = store;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={store.handleSort}
        />
        <Table.Body>{this.renderTableBody(data, colSpan)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage || 1}
          totalItems={store.totalItems || 0}
          totalPage={store.totalPage || 1}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default GreetingTable;
