import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';

import OtherSalesCallReportStore from './OtherSalesCallReportStore';
import TableToolbar from '../../shared/tableToolbar';

@observer
class OtherSalesCallReportToolbar extends React.Component {
  onChangeDateRange = (_, { value }) => {
    OtherSalesCallReportStore.refetch(
      {
        dateRange: value,
      },
      true,
    );
  };
  renderClientOfficer = () => {
    let array = toJS(OtherSalesCallReportStore.dataClient.itemList);
    array.unshift({
      Id: '',
      FullName: 'All',
      Count: OtherSalesCallReportStore.totalItem,
    });
    return array.map(i => ({
      value: i.Id,
      text: i.FullName,
    }));
  };
  renderOfficer = () => {
    let array = toJS(OtherSalesCallReportStore.dataOfficer.itemList);
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.map(i => ({
      value: i.Id,
      text: i.FullName + ' (' + i.Count + ')',
    }));
  };
  handleFilterClient = (_, { value, name }) => {
    if (name === 'clientOfficer') {
      OtherSalesCallReportStore.refetch(
        {
          staffId: value ? value : '',
        },
        true,
      );
    }
    if (name === 'callById') {
      OtherSalesCallReportStore.refetch(
        {
          callById: value ? value : '',
        },
        true,
      );
    }
  };
  renderClientOfficerText = value => {
    let array = toJS(OtherSalesCallReportStore.dataClient.itemList);
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderOfficerText = value => {
    let array = toJS(OtherSalesCallReportStore.dataOfficer.itemList);
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderOptDropdown = () => {
    const options = OtherSalesCallReportStore.dataStaff;
    return options.map((i, idx) => ({
      value: i.Id,
      text: `${i.FirstName} ${i.LastName} - ${i.Email}`,
      key: idx,
    }));
  };
  handleSearchChange = (_, { searchQuery }) => {
    OtherSalesCallReportStore.refetchSearch({ keywordSearch: searchQuery });
  };
  handleChangeDropdown = (_, { value }) => {
    OtherSalesCallReportStore.refetch(
      {
        id: value,
        keywordSearch: '',
      },
      true,
    );
  };
  handleScroll = event => {
    const target = event.target;
    const number = OtherSalesCallReportStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      OtherSalesCallReportStore.refetchSearch({ numberSearch: number + 20 });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    OtherSalesCallReportStore.fetchReport(true);
  };
  handleRefresh = () => {
    OtherSalesCallReportStore.refetch({ data: null }, true);
  };

  handleClear = () => {
    OtherSalesCallReportStore.resetStore();
  };
  handleExportSalesCall = () => {
    const { fromDate, toDate } = OtherSalesCallReportStore.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const searchResult = OtherSalesCallReportStore.data.itemList;
    if (searchResult) {
      if (searchResult.length !== 0) {
        window.open(
          `/Staff/ExportSalesCall?fromDate=${from}&toDate=${to}&cmEmail=${OtherSalesCallReportStore.cmEmail}&cmName=${
            OtherSalesCallReportStore.cmName
          }&sortBy=${OtherSalesCallReportStore.sortKey}&callById=${
            OtherSalesCallReportStore.callById
          }&sortDirection=${OtherSalesCallReportStore.sortDirection.replace('ending', '').toUpperCase()}&staffId=${
            OtherSalesCallReportStore.staffId
          }&curPage=${OtherSalesCallReportStore.curPage}&numItemPerPage=${OtherSalesCallReportStore.pageSize}`,
          '_blank',
        );
      } else {
        OtherSalesCallReportStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Error in export result. Data does not exist!',
        })();
      }
    } else {
      OtherSalesCallReportStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Error in export result. Data does not exist!',
      })();
    }
  };
  render() {
    const { dateRange, loading, loadingClient, callById } = OtherSalesCallReportStore;
    return (
      <form>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={callById}
              name="callById"
              options={this.renderClientOfficer()}
              text={`Call By: ${this.renderClientOfficerText(callById)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.RangePicker
              placeholder="From Activity Date - To Date"
              value={dateRange}
              onChange={this.onChangeDateRange}
            />
            <TableToolbar.SubmitButton className="px-2" onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleClear}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefresh}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {/* <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.handleExportSalesCall}
              style={{ marginLeft: '5px' }}
            >
              Export Sales Calls
            </TableToolbar.HighLightButton> */}
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
      </form>
    );
  }
}

export default OtherSalesCallReportToolbar;
