import { observer } from 'mobx-react';
import React from 'react';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import Store from './Store';
import { toJS } from 'mobx';

const taskStatuss = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class Toolbar extends React.Component {
  renderModuleType = () => {
    const array = [];
    const data = toJS(Store.dataModule.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
  };

  renderModuleTypeText = value => {
    const array = [];
    const data = toJS(Store.dataModule.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Label: `All`, Id: -1 });
    return array.filter(i => i.Id === value).map(opt => opt.Label)[0];
  };

  handleReset = () => {
    Store.resetStore();
    Store.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    Store.refetch({ curPage: 1 }, true);
  };

  handleSearchChange = (_, { value }) => {
    Store.setFieldsValue({ keyword: value });
  };

  handleRefresh = () => {
    Store.refetch({ data: null }, true);
  };

  handleChangeDropdown = (_, { name, value }) => {
    Store.refetch({ [name]: value }, true);
  };

  openCaseCreate = () => () => {
    return Store.toggleModal(true, {
      modalType: 'addOrEdit',
      id: 0,
      record: null,
    })();
  };

  renderFilterOptions = () => {
    return taskStatuss.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskStatuss.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  render() {
    const { keyword, loading, status } = Store;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`Status`}
            name="status"
            value={this.renderFilterText(status)}
            options={this.renderFilterOptions()}
            onChange={this.handleChangeDropdown}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          <TableToolbar.HighLightButton
            className="blue"
            disabled={loading}
            onClick={this.handleRefresh}
            style={{ marginLeft: '5px' }}
          >
            Refresh
          </TableToolbar.HighLightButton>
          {api.isAdmin() && (
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              onClick={this.openCaseCreate()}
              style={{ marginLeft: '5px' }}
            >
              Add New
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Left>
        <TableToolbar.Right />
      </TableToolbar.Container>
    );
  }
}

export default Toolbar;
