import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import InvoicesStore from './InvoicesStore';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import moment from 'moment';

const columns = [{ title: 'Sent Date' }, { title: 'Recipient' }, { title: 'Subject' }, { title: 'Invoices' }];

@observer
class ModalHistory extends React.Component {
  async componentDidMount() {
    const { objectId } = InvoicesStore.modalParams;
    await InvoicesStore.fetchInvoicesHistory(0, objectId);
  }

  renderTableBody = () => {
    return InvoicesStore.dataHistory.map(({ ...record }, id) => (
      <Table.Row key={id}>
        <Table.Cell>
          {record.sentDate
            ? moment(formatDate(record.sentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.recipient}</Table.Cell>
        <Table.Cell>{record.subject}</Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#">Download</Link>
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loadingHistory, dataHistory, toggleModal, open } = InvoicesStore;
    const colSpan = columns.length;
    const { record } = this.props.modalParams;

    return (
      <React.Fragment>
        <Modal
          width={900}
          visible={open}
          onCancel={toggleModal(false)}
          title={`Remender History - ${record.InvoiceNo}`}
        >
          {loadingHistory && !dataHistory ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columns} />
              <Table.Body>
                {dataHistory.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
              </Table.Body>
            </Table>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalHistory;
