import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ModalUpdate from './ModalUpdate';
import store from './Store';

function ViewModal() {
  const { modalType } = store.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...store} onCancel={store.toggleModal(false)} />;
  }
  if (modalType === 'update') {
    return <ModalUpdate {...store} onCancel={store.toggleModal(false)} />;
  }
}

export default ViewModal;
