import React from 'react';
import { Button } from 'semantic-ui-react';
import { Form, DatePicker, notification } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import { formatDate } from '../../../utils/functions';
import Modal from '../../shared/Modal/';
import store from './DRStore';
import { action } from 'mobx';
import axios from 'axios';

const datePickerFormat = 'DD/MM/YYYY';
const { $ } = window;
class ModalPaymentDate extends React.Component {
  constructor(props) {
    super(props);
  }

  onCancel = () => {
    this.props.onCancel();
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  handleExport = () => {
    let isView = store.isView;
    if (isView) {
      store.setFieldsValue({ open: false });
      let doctors = store.doctorIdList;
      doctors.forEach(el => {
        const { monthPeriod, yearPeriod, includeXeroUploadedInvoiceOnly, paymentDate } = store;
        const { fromDate, toDate } = store.getDateRange();
        const from_ = fromDate
          ? moment(fromDate, 'DD/MM/YYYY')
              .startOf(`day`)
              .toISOString()
          : '';
        const to_ = toDate
          ? moment(toDate, 'DD/MM/YYYY')
              .endOf(`day`)
              .toISOString()
          : '';
        const from = encodeURI(from_);
        const to = encodeURI(to_);
        const paymentDate_ = paymentDate ? paymentDate.toISOString() : '';
        const pDate = encodeURI(paymentDate_);

        const params = {
          doctorId: el,
          dateTypeToFilter: 'InvoiceDate',
          invoiceDateFrom: from,
          invoiceDateTo: to,
          paymentPeriod: monthPeriod + ' ' + yearPeriod,
          includeXeroUploadedInvoiceOnly,
          paymentDate: pDate,
        };
        window.open(`/Report/ExportDoctorCommissionRemittance?${$.param(params)}`, '_blank');
      });
    } else {
      const { monthPeriod, yearPeriod, doctorIdList, paymentDate } = store;
      if (doctorIdList.length === 0) {
        store.toggleModal(true, {
          modalType: `confirm`,
          message: `Please select at least 1 specialist`,
        })();
      } else {
        const { fromDate, toDate } = store.getDateRange();
        const from_ = fromDate
          ? moment(fromDate, 'DD/MM/YYYY')
              .startOf(`day`)
              .toISOString()
          : '';
        const to_ = toDate
          ? moment(toDate, 'DD/MM/YYYY')
              .endOf(`day`)
              .toISOString()
          : '';
        const paymentDate_ = paymentDate ? paymentDate.toISOString() : '';
        store.toggleModal(false, {});
        if (!from_) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please Select Provider Payment From Date',
          })();
        } else if (!to_) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please Select Provider Payment To Date',
          })();
        } else if (!paymentDate_) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Please input Payment Date',
          })();
        } else {
          this.handledSend();
          // store.toggleModal(true, {
          //   modalType: `confirm`,
          //   message: `You are about to send commission statements for period ${monthPeriod} ${yearPeriod} to ${doctorIdList.length} specialist. Click 'Yes' to proceed.`,
          //   onOk: () => this.handledSend(),
          // })();
        }
      }
    }
  };

  handledSend = async () => {
    const { monthPeriod, yearPeriod, includeXeroUploadedInvoiceOnly, doctorIdList, paymentDate } = store;
    const { fromDate, toDate } = store.getDateRange();
    const from_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const to_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const paymentDate_ = paymentDate ? paymentDate.toISOString() : '';
    const to = encodeURI(to_);
    const from = encodeURI(from_);
    const pDate = encodeURI(paymentDate_);
    store.setFieldsValue({ open: false });
    try {
      await axios
        .post('/DoctorCommissionRemittanceList/SendCommissionRemittanceStatementToDoctor', {
          doctorIds: doctorIdList.map(i => i),
          includeXeroUploadedInvoiceOnly: includeXeroUploadedInvoiceOnly,
          invoiceDateFrom: from,
          invoiceDateTo: to,
          notificationReceiverId: $.connection.hub.id,
          paymentPeriod: monthPeriod + ' ' + yearPeriod,
          paymentDate: pDate,
        })
        .then(res => {
          if (res.statusText === `OK`) {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: `Remittance sent successfully`,
              duration: 2,
              placement: `bottomRight`,
            });
          }
        });
    } catch (e) {
      store.toggleModal(true, {
        modalType: `confirm`,
        message:
          'Error occured while contacting server. Please report this error to system administrator and try again later.',
      });
    }
  };

  @action handleChangePaymentDate = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      store.setFieldsValue({ paymentDate: value });
    });

  render() {
    const { toggleModal, open, paymentDate, isView, monthPeriod, yearPeriod, doctorIdList } = store;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={350}
          onCancel={toggleModal(false)}
          title={isView ? `View Remittance Statements` : `Sending checked remittances`}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleExport}>
                {isView ? 'View' : 'Yes'}
              </Button>
              <Button className="negative" onClick={this.onCancel}>
                {isView ? 'Close' : 'Cancel'}
              </Button>
            </React.Fragment>
          }
        >
          <div className="Wrap-export-modal">
            <Form.Item
              label={'Specify Payment Date'}
              required
              validateStatus={!paymentDate ? 'error' : null}
              help={!paymentDate ? 'This field is required' : ''}
            >
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                value={this.convertDate(paymentDate)}
                format="DD/MM/YYYY"
                onChange={this.handleChangePaymentDate('paymentDate')}
              />
            </Form.Item>
            {!isView && (
              <span style={{ color: 'red' }}>
                You are about to send commission statements for period {monthPeriod} {yearPeriod} to{' '}
                {doctorIdList.length} {doctorIdList.length < 2 ? 'specialist' : 'specialists'} . Click 'Yes' to proceed?
              </span>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(ModalPaymentDate);
