import { action, observable } from 'mobx';
import moment from 'moment';

import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';

class ClinicalRecordStore {
  @observable loading = true;
  @observable loadingClaimant = true;
  @observable loadingStaffAssignAction = true;
  @observable loadingStaffAssign = true;
  @observable loadingCRStatus = true;
  @observable loadingOverDueCRStatus = true;

  @observable dataClaimant = null;
  @observable data = null;
  @observable dataStaffAssignAction = null;
  @observable dataStaffAssign = null;
  @observable dataCRStatus = null;
  @observable dataOverDueCRStatus = null;
  @observable showBookingToday = false;

  @observable keyword = '';
  @observable keywordClaimant = ``;
  @observable numberSearch = 30;

  @observable sortKey = api.isCaseManager() ? 'RequestDate' : 'StatusDate';
  @observable sortColumn = 'Status Date';
  @observable sortDirection = 'descending';
  @observable searchClaimantOnly = api.isAdminOrMagStaffOrAccounting() ? false : false;
  @observable filter = 'All';
  @observable filterOverdue = `All`;
  @observable actionRequiredById = '';
  @observable fromTo = ``;
  @observable NextTaskPIC = ``;
  @observable claimant = { Id: ``, FullName: `` };

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;
  @observable pageSize = 20;

  @observable open = false;
  @observable modalParams = {};
  @observable assignStaffs = [];

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action fetchClaimant = () => {
    this.loadingClaimant = true;
    customFetch('/ClinicalNote/GetSuggestClaimant', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordClaimant,
        Showing: 'active',
        numItemPerPage: this.numberSearch,
        IsSelectionList: true,
        curPage: 1,
      }),
    }).then(
      action(data => {
        this.dataClaimant = data;
        this.loadingClaimant = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchClinicalNotes();
  };

  @action fetchTaskPIC = () => {
    this.loadingStaffAssign = true;
    customFetch(`/ClinicalNote/GetStaffsAssign`, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataStaffAssign = data;
        this.loadingStaffAssign = false;
      }),
    );
  };

  @action fetchCaseStatus = () => {
    this.loadingCRStatus = true;
    customFetch(`/api/CaseStatusWithUsageCount?casetype=ClinicalNote`).then(
      action(data => {
        this.dataCRStatus = data;
        this.loadingCRStatus = false;
      }),
    );
  };

  @action fetchActionRequired = () => {
    this.loadingStaffAssignAction = true;
    customFetch(`/ClinicalNote/GetStaffsAssignedActionRequired`, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataStaffAssignAction = data;
        this.loadingStaffAssignAction = false;
      }),
    );
  };

  @action fetchNextTaskOverDue = () => {
    this.loadingOverDueCRStatus = true;
    customFetch(`/api/CaseStatusWithOverDueCount`).then(
      action(data => {
        this.dataOverDueCRStatus = data;
        this.loadingOverDueCRStatus = false;
      }),
    );
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format() : '',
      toDate: toDate
        ? moment(toDate, 'DD/MM/YYYY')
            .endOf('day')
            .format()
        : '',
    };
  };

  @action fetchClinicalNotes = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();

    const from = encodeURIComponent(fromDate);
    const to = encodeURIComponent(toDate);
    var claim = Object.entries(this.claimant)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    customFetch(
      `/api/ClinicalNote?ActionType=NONE&keyword=${this.keyword}&curPage=${this.curPage}&numItemPerPage=${
        this.pageSize
      }&sortBy=${this.sortKey}&sortDirection=${this.sortDirection
        .replace('ending', '')
        .toUpperCase()}&From=${from}&To=${to}&FilterType=${this.filter}&FilterOverdue=${this.filterOverdue}&NextPicId=${
        this.NextTaskPIC
      }&NumItemPerPage=${this.pageSize}&SearchClaimantOnly=${
        this.searchClaimantOnly
      }&IsActionRequired=${false}&IsUrgentReport=${false}&ActionRequiredById=${this.actionRequiredById}&ClaimantId=${
        this.claimant.Id
      }&Claimant=${claim}`,
    ).then(
      action(data => {
        this.data = data;
        this.fetchCaseStatus();
        this.fetchClaimant();
        this.fetchTaskPIC();
        this.showBookingToday = false;
        this.fetchNextTaskOverDue();
        this.fetchActionRequired();
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action getData = () => {
    this.loading = true;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action reset = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortColumn = 'Status Date';
    this.sortKey = api.isCaseManager() ? 'RequestDate' : 'StatusDate';
    this.sortDirection = 'descending';
    this.searchClaimantOnly = false;
    this.claimant = { Id: ``, FullName: `` };
    this.fromTo = ``;
    this.NextTaskPIC = ``;
    this.actionRequiredById = ``;
    this.filterOverdue = `All`;

    this.curPage = 1;
    this.totalPage = 0;

    this.fetchClinicalNotes(true);
  };

  @action resetSearch = () => {
    this.keywordClaimant = '';
    this.numberSearch = 30;

    this.fetchClaimant();
  };

  @action refetch = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClinicalNotes(setTotalPage);
  };
  @action refetchClaimant = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClaimant(setTotalPage);
  };
}

export default new ClinicalRecordStore();
