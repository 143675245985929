import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import React from 'react';

import TableToolbar from '../../shared/tableToolbar';
import ServiceFeeStore from './ServiceFeeStore';
import customFetch from '../../../utils/customFetch';
import * as api from '../../../stores/api';

const taskFilterServiceStatus = [
  { text: `All`, value: `All` },
  { text: `Active`, value: 1 },
  { text: `Deactive`, value: 0 },
];

const taskChooseServiceStatus = [
  { text: `All`, value: `All` },
  { text: `Active`, value: 1 },
  { text: `Inactive`, value: 2 },
];

@observer
class ServiceFeeToolbar extends React.Component {
  openModalEdit = id => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'create',
      id,
    });
  };
  openModalCreateServiceGroup = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'create',
    });
  };
  renderFilterServiceStatusOptions = () => {
    return taskFilterServiceStatus.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };
  renderFilterServiceStatusText = value => {
    let labels = taskFilterServiceStatus.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };
  renderChooseServiceStatusOptions = () => {
    return taskChooseServiceStatus.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };
  renderChooseServiceStatusText = value => {
    let labels = taskChooseServiceStatus.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };
  renderServiceGroupOptions = () => {
    const array = [];
    const data = toJS(ServiceFeeStore.dataServiceGroup);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', Name: `All`, Status: true });
    return array.map(opt => ({
      text: opt.Name,
      value: opt.Id,
    }));
  };
  renderServiceGroupText = value => {
    if (value === 'All') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(ServiceFeeStore.dataServiceGroup);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: 'All', Name: `All`, Status: true });
      let labels = array.filter(opt => opt.Id === value).map(opt => opt.Name);
      return labels[0];
    }
  };
  handleFilter = (_, { value }) => {
    ServiceFeeStore.refetch({ filter: value, curPage: 1 }, true);
  };
  handleFilterServiceGroup = (_, { value }) => {
    ServiceFeeStore.refetch({ serviceGroupId: value, curPage: 1 }, true);
  };
  handSubmit = e => {
    e.preventDefault();
    ServiceFeeStore.refetch({ curPage: 1 }, true);
  };

  handExport = e => {
    const { keyword, filter, serviceGroupId } = ServiceFeeStore;
    window.open(
      `/MAGService/ExportServiceItem?keyword=${keyword}&ServiceStatusId=${filter}&ServiceGroupId=${serviceGroupId}`,
      '_blank',
    );
  };

  openModal = options => {
    return ServiceFeeStore.toggleModal(true, options);
  };
  openModalCreate = () => {
    return ServiceFeeStore.toggleModal(true, {
      modalType: 'createServiceFee',
    });
  };
  exportService = () => {
    const { filter, serviceGroupId } = ServiceFeeStore;
    window.open(`/MAGService/ExportServiceItem?ServiceStatusId=${filter}&ServiceGroupId=${serviceGroupId}`, '_blank');
  };
  handleDeleteServiceGroup = id => async () => {
    ServiceFeeStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/MAGService/DeleteServiceGroup', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
    } finally {
      ServiceFeeStore.setFieldsValue({ loading: false, serviceGroupId: 'All' });
      await ServiceFeeStore.refetchGroup({}, true);
      await ServiceFeeStore.refetch({}, true);
    }
  };
  render() {
    const { loading, keyword, filter, serviceGroupId, loadingServiceGroup, dataServiceGroup } = ServiceFeeStore;
    return (
      <div>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={ServiceFeeStore.handleSearchChange} value={keyword} disabled={loading} />
            {!loadingServiceGroup && dataServiceGroup && (
              <TableToolbar.Filter
                disabled={loadingServiceGroup}
                value={this.renderServiceGroupText(serviceGroupId)}
                options={this.renderServiceGroupOptions()}
                onChange={this.handleFilterServiceGroup}
                label={`Service Group`}
              />
            )}
            <TableToolbar.Filter
              disabled={loading}
              value={this.renderChooseServiceStatusText(filter)}
              options={this.renderChooseServiceStatusOptions()}
              onChange={this.handleFilter}
              label={`Service status`}
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            {api.isAdmin() && (
              <TableToolbar.HighLightButton
                disabled={loading}
                className="positive"
                onClick={this.handExport}
                style={{ marginLeft: '5px' }}
              >
                Export Search Result
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Left>
          {api.isAdmin() && (
            <TableToolbar.Right>
              {serviceGroupId === 'All' ? (
                <div />
              ) : (
                <div>
                  <TableToolbar.SubmitButton
                    disabled={loading}
                    style={{ width: 120, marginLeft: 5 }}
                    onClick={this.openModalEdit(serviceGroupId)}
                  >
                    Edit
                  </TableToolbar.SubmitButton>
                  <TableToolbar.HighLightButton
                    className="negative"
                    disabled={loading}
                    style={{ marginLeft: 5, width: 120 }}
                    onClick={this.openModal({
                      modalType: 'confirm',
                      message: 'Do you want to delete this service group?',
                      onOk: this.handleDeleteServiceGroup(serviceGroupId),
                    })}
                  >
                    Delete
                  </TableToolbar.HighLightButton>
                </div>
              )}
            </TableToolbar.Right>
          )}
        </TableToolbar.Container>
        <TableToolbar.Container style={{ marginTop: 5 }}>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.Left>
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                style={{ width: 260 }}
                onClick={this.openModalCreateServiceGroup()}
              >
                Add New Service Group
              </TableToolbar.HighLightButton>
              <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                onClick={this.openModalCreate()}
                style={{ width: 260, marginLeft: 5 }}
              >
                Add New Item Code
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
          )}
          <TableToolbar.Right />
        </TableToolbar.Container>
      </div>
    );
  }
}

export default ServiceFeeToolbar;
