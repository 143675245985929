import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import LMQCriteriasStore from './LMQCriteriasStore';

function LMQCriteriasModal() {
  const { modalType } = LMQCriteriasStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...LMQCriteriasStore} onCancel={LMQCriteriasStore.toggleModal(false)} />;
  }
}

export default LMQCriteriasModal;
