import { action, observable } from 'mobx';

import customFetch from './customFetch';

class AjaxResult {
  @observable loading = false;
  @observable error = null;
  @observable data = null;

  constructor(url, body) {
    this.url = url;
    this.body = body;
  }

  @action
  load = promise => {
    this.loading = true;
    promise
      .then(
        action(data => {
          this.data = data;
        }),
      )
      .catch(
        action(error => {
          this.error = error;
        }),
      )
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  };

  get = (url = this.url) => {
    const promise = customFetch(url);
    this.load(promise);
    return promise;
  };

  post = (body = this.body, url = this.url) => {
    const opts = {
      method: 'POST',
    };
    if (body) {
      opts.body = JSON.stringify(body);
      opts.headers = {
        'Content-Type': 'application/json',
      };
    }
    const promise = customFetch(url, opts);
    this.load(promise);
    return promise;
  };
}

export default AjaxResult;
