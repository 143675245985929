import './Checkbox.scss';

import cx from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Divider } from 'semantic-ui-react';

import TextInput from './TextInput';

@observer
class Checkbox extends React.Component {
  static defaultProps = {
    defaultChecked: false,
    checked: false,
  };

  initialValue = () => {
    const { defaultChecked, checked } = this.props;
    return defaultChecked || checked || false;
  };

  @observable isChecked = this.initialValue();

  @action onCheckboxChange = () => {
    const { name, onChange } = this.props;
    this.isChecked = !this.isChecked;
    this.formikCtx.form.setFieldValue(name, this.isChecked);
    onChange && onChange(this.isChecked);
  };

  formikCtx = null;
  assignFormikCtx = ctx => {
    this.formikCtx = ctx;
  };

  componentDidMount() {
    this.formikCtx.form.values[this.props.name] = this.isChecked;
  }

  @action componentDidUpdate(prevProps) {
    const { checked } = this.props;
    if (prevProps.checked !== checked) {
      this.isChecked = checked;
    }
  }

  render() {
    const { name, label, className, disabled, note, hr } = this.props;
    return (
      <div className={cx('Checkbox', { checked: this.isChecked, disabled }, className)}>
        <TextInput
          readOnly
          tabIndex="0"
          type="checkbox"
          className="hidden"
          children={this.assignFormikCtx}
          name={name}
        />
        <label className="Checkbox-label" onClick={disabled ? null : this.onCheckboxChange}>
          {label}
        </label>
        {note && <div className="Checkbox-note">{note}</div>}
        {hr && <Divider />}
      </div>
    );
  }
}

export default Checkbox;
