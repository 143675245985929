import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';
import Modal from '../../../shared/Modal';
import { TableHeader } from '../../../shared/table';

const columns = [
  { title: 'Full Name' },
  { title: 'Specialty' },
  { title: 'Business Email' },
  { title: 'Personal Email' },
  { title: 'Module' },
];

@observer
class ModalDuplicateEmail extends React.Component {
  renderModalTitle = (dataDuplicate, type) => {
    if (!dataDuplicate.length) {
      return 'Duplicate message';
    }
    return type === 'Email' ? 'List of similar specialists' : 'List of similar specialists';
  };

  renderModalBody = (dataDuplicate, type) => {
    if (!dataDuplicate.length) {
      return type === 'Email' ? 'No similar emails are found' : 'No similar email are found';
    }
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {dataDuplicate.map((record, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{record.FullName}</Table.Cell>
              <Table.Cell>{record.Specialty}</Table.Cell>
              <Table.Cell>{record.BusinessEmail}</Table.Cell>
              <Table.Cell>{record.PersonalEmail}</Table.Cell>
              <Table.Cell>{record.Module}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { open } = DoctorDetailsStore;
    const { dataDuplicate, type } = DoctorDetailsStore.modalParams;
    return (
      <Modal
        visible={open}
        width={900}
        onCancel={DoctorDetailsStore.toggleModal(false)}
        title={this.renderModalTitle(dataDuplicate, type)}
      >
        {dataDuplicate && this.renderModalBody(dataDuplicate, type)}
      </Modal>
    );
  }
}

export default ModalDuplicateEmail;
