import { action, observable } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';

import {
  getCMInfo,
  getBusinessUnits,
  getClient,
  getSpecificLookupConfigByType,
  getStaffAll,
  getStaffs,
  fetchChartDate,
} from './service';
import * as api from '../../../stores/api';

const VALIDATE_RULES = {
  FirstName: 'required',
  CompanyId: 'required',
  FreeBookingApprovedBy: 'required',
  Email: ['email', 'required'],
  OfficePhone: 'max:10',
  Telephone: 'max:10',
  CityId: 'required',
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
};

class AddEditStore {
  @observable loading = true;
  @observable loadingConversation = false;
  @observable CMInfo = null;
  @observable dataChart = null;

  @observable CMInfo_ = null;
  @observable isChanged = {};
  @observable passwordStrengthCheck = {};

  @observable conversationSubReceiver = '';
  @observable conversationSubSender = '';

  //data
  @observable businessUnitsList = null;
  @observable staffList = null;
  @observable staffAllList = null;
  @observable specificlookupList = null;
  @observable clientList = null;
  @observable conversationList = null;

  //Search
  @observable keywordClient = '';
  @observable numberItemClient = 30;
  @observable loadingSearchClient = false;

  @observable keywordCM = '';
  @observable numberItemCM = 30;
  @observable loadingSearchCM = false;

  //Save
  @observable loadingSave = false;

  //Check duplicate
  @observable loadingCheckDuplicate = false;

  //Error
  @observable errors = {};

  //Modal
  @observable open = false;
  @observable modalParams = {};

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };
  //

  @action refetchSearchClient = data => {
    this.loadingSearchClient = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getClient(this.keywordClient, this.numberItemClient)]).then(
      action(([client]) => {
        this.clientList = client;
        this.loadingSearchClient = false;
      }),
    );
  };

  @action refetchSearchCM = data => {
    this.loadingSearchCM = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getStaffAll(this.keywordCM, this.numberItemCM)]).then(
      action(([cm]) => {
        this.staffAllList = cm;
        this.loadingSearchCM = false;
      }),
    );
  };

  @action handleAddError = (fieldName, errorMessage) => {
    this.errors[fieldName] = errorMessage;
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleValidate = (field, value) => {
    if (field === 'Email') {
      const emailRequired = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][1] }, ERROR_MESSAGES);
      const emailValidation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][0] }, ERROR_MESSAGES);
      if (emailRequired.passes()) {
        delete this.errors[field];
        if (emailValidation.passes()) {
          delete this.errors[field];
        } else {
          this.errors[field] = emailValidation.errors.first(field);
        }
      } else {
        this.errors[field] = emailRequired.errors.first(field);
      }
    } else {
      const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);
      if (validation.passes()) {
        delete this.errors[field];
      } else {
        this.errors[field] = validation.errors.first(field);
      }
    }
  };

  @action fetchCMData = () => {
    Promise.all([getCMInfo(), getBusinessUnits(), getSpecificLookupConfigByType(), getStaffs()]).then(
      action(([info, businessUnits, specificlookup, staff]) => {
        Promise.all([
          getClient(info.CompanyId),
          getStaffAll(),
          fetchChartDate(api.currentUser.data.CaseManagerId),
        ]).then(
          action(([client, staffAll, dataChart]) => {
            this.loading = false;

            this.dataChart = dataChart.itemList.reduce(function(r, a) {
              r[a.Type] = r[a.Type] || [];
              r[a.Type].push(a);
              return r;
            }, Object.create(null));
            this.clientList = client;
            const businessUnitsList = JSON.parse(businessUnits);
            this.specificlookupList = specificlookup;
            this.staffAllList = staffAll;
            this.staffList = staff;
            this.CMInfo = {
              ...info,
              ConfirmNewPassword: '',
              OfficePhone: info.OfficePhone ? info.OfficePhone.replace(/\D/g, '').replace(/[^\d]/g, '') : null,
              Telephone: info.Telephone ? info.Telephone.replace(/\D/g, '').replace(/[^\d]/g, '') : null,
            };
            this.CMInfo_ = {
              ...info,
              ConfirmNewPassword: '',
              OfficePhone: info.OfficePhone ? info.OfficePhone.replace(/\D/g, '').replace(/[^\d]/g, '') : null,
              Telephone: info.Telephone ? info.Telephone.replace(/\D/g, '').replace(/[^\d]/g, '') : null,
            };
            this.businessUnitsList = businessUnitsList;
          }),
        );
      }),
    );
  };

  @action handleChecked = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'Unsubscribed') {
        if (this.CMInfo.UnsubscribedDate) {
          this.CMInfo.UnsubscribedDate = null;
          return (this.CMInfo.Unsubscribed = false);
        } else {
          this.CMInfo.UnsubscribedDate = moment().toDate();
          return (this.CMInfo.Unsubscribed = true);
        }
      }
      this.CMInfo[fieldName] = value;
    });

  handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'AssignedStaffId') {
        this.CMInfo.AssignedDate = moment().format('DD/MM/YYYY');
      } else if (fieldName === 'Telephone' || fieldName === 'OfficePhone') {
        this.CMInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      } else if (fieldName === 'Position') {
        const exist = this.specificlookupList.itemList.find(i => i.Id === value);
        this.CMInfo[fieldName] = exist?.Id;
      } else {
        this.CMInfo[fieldName] = value;
      }

      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.CMInfo[fieldName]);
      }
    });

  handleChangeFieldForPW = fieldName =>
    action(event => {
      const value = event.target.value;
      this.CMInfo = { ...this.CMInfo, [fieldName]: value };
    });

  @action handleFieldChangePW = (fieldName, value) => {
    this.CMInfo[fieldName] = value;
  };

  @action resetStore = () => {
    this.loading = true;
    this.CMInfo = null;
    this.CMInfo_ = null;
    this.errors = {};
  };

  @action handleSaveStaff = () => {
    this.loadingSave = true;
  };
}

export default new AddEditStore();
