import './SessionNote.scss';
import React from 'react';
import * as api from '@stores/api';
import { Tooltip } from 'antd';
import { Icon } from 'semantic-ui-react';

const overlayInnerStyle = {
  borderRadius: '10px',
  color: 'black',
  padding: '1rem',
  maxHeight: '40vh',
  overflow: 'auto',
};

const noteGroup = [
  {
    title: (
      <>
        Confirmed Available{' '}
        <Tooltip
          color="white"
          placement="top"
          overlayInnerStyle={overlayInnerStyle}
          overlayStyle={{ minWidth: '300px' }}
          title={'Both date and time are confirmed available'}
        >
          <Icon name="info circle" />
        </Tooltip>
      </>
    ),
    className: 'available',
    key: 'Confirmed Availability',
  },
  {
    title: (
      <>
        Unconfirmed Available{' '}
        <Tooltip
          color="white"
          placement="top"
          overlayInnerStyle={overlayInnerStyle}
          overlayStyle={{ minWidth: '300px' }}
          title={'Date is confirmed, time is not yet confirmed available'}
        >
          <Icon name="info circle" />
        </Tooltip>
      </>
    ),
    className: 'on-demand',
    key: 'Unconfirmed Availability',
  },
];

const noteItems = [
  { label: 'In-person Only', className: 'inperson' },
  { label: 'Video Only', className: 'video' },
  { label: 'In-person or Video', className: 'both' },
];

function SessionNote() {
  return (
    <div className="Session-note-container">
      {noteGroup.map(({ key, title, className: c1 }, i) => (
        <div className="Session-note-group" key={key}>
          <h4 className="Session-note-title">{title}</h4>
          {noteItems.map(({ label, className: c2 }, idx) => (
            <div key={idx} className={`Session-note-item ${c1} ${c2}`}>
              <label className="Session-note-label">{label}</label>
              <div className="Session-note-background" />
            </div>
          ))}
          <div className="Session-note-item booked">
            <label className="Session-note-label">Booked</label>
            <div className="Session-note-background" style={i ? { background: '#ff7373' } : {}} />
          </div>
          {key === 'Unconfirmed Availability' && !api.isCaseManager() && (
            <div className="Session-note-item booked">
              <label className="Session-note-label">Reserved</label>
              <div className="Session-note-background" style={{ background: '#1fc0da' }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default SessionNote;
