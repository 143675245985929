import { action, observable } from 'mobx';
import { notification } from 'antd';
import * as api from '../../stores/api';
import { getErrorMessage } from '../../utils/errors';

class ReportStatusStore {
  @observable data = null;
  @observable loading = false;
  @observable registerSuccessfully = false;

  @observable contentErrorEmail = '';
  @observable contentErrorSearch = '';

  @observable email = '';
  @observable search = '';

  @action onValidateElement = () => {
    if (!this.search) {
      this.contentErrorSearch = 'Please enter value';
      return false;
    } else {
      this.contentErrorSearch = '';
    }

    if (!this.email) {
      this.contentErrorEmail = 'Please enter email';
      return false;
    } else {
      this.contentErrorEmail = '';
    }

    if (!this.validateEmail(this.email)) {
      this.contentErrorEmail = 'Please enter valid email';
      return false;
    } else {
      this.contentErrorEmail = '';
    }

    return true;
  };

  @action handleChange = ({ target }) => {
    this[target.name] = target.value;
    this.onValidateElement();
  };

  @action SendRequest = () => {
    if (!this.onValidateElement()) {
      return;
    }
    this.loading = true;
    api.reportStatus.post(
      { keyword: this.search, email: this.email },
      action(res => {
        if (res.status === 'success') {
          this.data = res.itemList;
        } else {
          notification.error({
            message: 'Error',
            description: getErrorMessage(res.code),
            duration: 2,
          });
        }

        this.loading = false;
      }),
    );
  };

  @action SendMail = () => {
    if (!this.onValidateElement()) {
      return;
    }
    this.loading = true;
    api.SendReportStatus.post(
      { keyword: this.search, email: this.email },
      action(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report Status was sent`,
            duration: 2,
          });
        } else {
          notification.error({
            message: 'Error',
            description: getErrorMessage(res.code),
            duration: 2,
          });
        }
        this.loading = false;
      }),
    );
  };

  @action Clear = () => {
    this.data = [];
    this.search = '';
    this.email = '';
  };

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
}

export default new ReportStatusStore();
