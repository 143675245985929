import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import * as api from '../../stores/api';
import router from '../../stores/router';
import connect from '../../utils/apiConnectQuery';
import apiResetStore from '../../utils/apiResetStore';
import { getErrorMessage } from '../../utils/errors';
import AnonymousLoading from '../anonymous/AnonymousLoading';
import Form from '../shared/form/Form';

@connect(api.resetCheckCode)
@observer
class Reset extends React.Component {
  onValidSubmit = data => {
    api.reset.post(data, () => {
      // Automatically log the user in after successfully reset password
      const { UserName } = api.resetCheckCode.data;
      const { Password } = data;
      api.login.post(
        { UserName, Password },
        () => {
          router.history.push('/');
          apiResetStore();
        },
        () => {
          router.history.push('/view/login');
        },
        () => {
          setTimeout(() => {
            notification.success({
              message: 'Success',
              description: 'Your password has been reset successfully',
              duration: 0,
              key: 'ResetPasswordSuccess',
            });
          }, 2000);
        },
      );
    });
  };

  renderCheckResetCode() {
    const { fetched, loading, error, data } = api.resetCheckCode;
    if (!fetched || loading) {
      return <AnonymousLoading />;
    }
    if (error) {
      return (
        <React.Fragment>
          <p>{getErrorMessage(error)}</p>
          <Divider />
          <Link to="/view/login" className="link">
            Back to login
          </Link>
        </React.Fragment>
      );
    }
    if (data) {
      return (
        <p>
          Hello <span className="text-bold">{data.FullName}</span>, to reset your password please enter your new
          password and submit.
        </p>
      );
    }
    return null;
  }

  renderForm() {
    if (!api.resetCheckCode.data) {
      return null;
    }
    return (
      <React.Fragment>
        <p>{getErrorMessage('Password')}</p>
        <Form
          id="reset-form"
          onValidSubmit={this.onValidSubmit}
          fields={[
            {
              name: 'Password',
              rule: 'required|password',
              type: 'PasswordInput',
              label: 'New Password',
              msg: 'Invalid Password',
              autoComplete: 'current-password',
            },
          ]}
          submit={{
            label: 'Submit',
            loading: api.reset.loading,
            icon: 'angle double right',
          }}
        />
        <Divider />
        <Link to="/view/login" className="link">
          Back to login
        </Link>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCheckResetCode()}
        {this.renderForm()}
      </React.Fragment>
    );
  }
}

export default Reset;
