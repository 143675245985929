import { observer } from 'mobx-react';
import { Divider } from 'antd';
import React from 'react';

import store from './store';

@observer
class MarketingCampaign extends React.Component {
  renderStaffName = id => {
    return store.staffs.find(i => i.Id === id)?.FullName;
  };
  render() {
    const { data } = store;
    return (
      <div style={{ marginLeft: '15px' }}>
        <div className="fluid-field" style={{ marginBottom: '1em' }}>
          <span style={{ width: '50px', display: 'inline-block' }}>From:</span> {this.renderStaffName(data.From)}
        </div>
        <Divider />
        <div className="fluid-field" style={{ marginBottom: '1em' }}>
          <span style={{ display: 'inline-block' }}>Subject:</span> {data.Subject || ''}
        </div>
        <Divider />
        <div dangerouslySetInnerHTML={{ __html: data.CampaignContent }} />
        <div dangerouslySetInnerHTML={{ __html: data.MailSignature }} />
      </div>
    );
  }
}

export default MarketingCampaign;
