import React from 'react';
import Chart from 'react-apexcharts';
import { observer } from 'mobx-react';

import AddEditStore from './store';
import { toJS } from 'mobx';
import moment from 'moment';
import { Spin } from 'antd';

@observer
class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartWidth: 800,
      chartHeight: 600,
      options: {
        chart: {
          type: 'bar',
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: 'Not Canceled',
          data: [],
        },
        {
          name: 'Canceled',
          data: [],
        },
      ],
      loadingChart: true,
    };
  }
  componentDidMount() {
    const item = window.getFormCasemangerProfile();
    if (item) {
      this.setState({
        chartHeight: item.clientHeight,
        chartWidth: item.clientWidth,
      });
    }
    const { loading, dataChart } = AddEditStore;
    if (!loading && dataChart) {
      const data = Object.entries(toJS(AddEditStore.dataChart));
      if (!!data.length) {
        const canceled = data
          .filter(([key]) => key !== 'Cancelled')
          .map(([key, value]) => ({ name: key, data: value }))[0];
        const notCanceled = data
          .filter(([key]) => key !== 'Not Cancelled')
          .map(([key, value]) => ({ name: key, data: value }))[0];
        const lastSixMonthName = Array.from({ length: 6 })
          .map((_, idx) => ({
            month: moment()
              .subtract(idx, 'months')
              .format('MMM'),
            key: parseInt(
              moment()
                .subtract(idx, 'months')
                .format('MM'),
            ),
          }))
          .reverse();
        const notCanceledData = {
          ...notCanceled,
          data: lastSixMonthName.map(i => {
            const existValue = notCanceled.data.find(item => item.Month === i.key);
            if (existValue) {
              return existValue.NumberOfBookings;
            }
            return 0;
          }),
        };
        const canceledData = {
          ...canceled,
          data: lastSixMonthName.map(i => {
            const existValue = canceled.data.find(item => item.Month === i.key);
            if (existValue) {
              return existValue.NumberOfBookings;
            }
            return 0;
          }),
        };
        this.setState(
          state => {
            state.series = [{ ...canceledData }, { ...notCanceledData }];
            state.options.xaxis.categories = lastSixMonthName.map(i => i.month);
          },
          () => {
            this.setState({ loadingChart: false });
          },
        );
      } else {
        this.setState({ loadingChart: false });
      }
    }
  }

  render() {
    const { loadingChart } = this.state;
    return (
      <React.Fragment>
        {loadingChart ? (
          <Spin size="large" />
        ) : (
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            style={{
              width: `${this.state.chartWidth}px`,
              height: `${this.state.chartHeight}px`,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ApexChart;
