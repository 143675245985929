import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';

import TableToolbar from '../../shared/tableToolbar';
import ClientsStore from './ClientsStore';
import dashboard from '../../../stores/dashboard';
import _ from 'lodash';
import * as api from '../../../stores/api';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];
const subcriptionOptions = [
  { text: `All`, value: `All` },
  { text: `Yes`, value: `Yes` },
  { text: `No`, value: `No` },
];
const State = [
  { id: '', label: 'All' },
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];
@observer
class ClientsToolbar extends React.Component {
  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };
  handleScroll = event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    const number = ClientsStore.numberSearch;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ClientsStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  openModal = options => {
    return ClientsStore.toggleModal(true, options);
  };
  renderState = () => {
    return State.map(i => ({
      value: i.label,
      text: i.label,
    }));
  };
  renderStateText = value => {
    return value ? value : 'All';
  };

  handleSelectState = (_, { value }) => {
    console.log(value);
    ClientsStore.refetch(
      {
        StateId: `${value === 'All' ? '' : value}`,
        curPage: 1,
      },
      true,
    );
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    ClientsStore.refetch({ showing: value, curPage: 1 }, true);
  };

  renderFilterSubcriptionOptions = () => {
    return subcriptionOptions.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterSubcriptionText = value => {
    let labels = subcriptionOptions.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilterSubcription = (_, { value }) => {
    ClientsStore.refetch({ filterSubcription: value, curPage: 1 }, true);
  };

  handleReset = () => {
    ClientsStore.resetStore();
    ClientsStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    ClientsStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    localStorage.removeItem('CompanyName');
    dashboard.close('/view/add-edit-client-2', true);
    setTimeout(() => {
      dashboard.open('/view/add-edit-client-2');
    });
  };

  handleChangeSearchDropdown = e => {
    ClientsStore.refetchSearch({
      keyword: e.target.value,
      keywordSearch: e.target.value,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(ClientsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      Name: ``,
      Telephone: ``,
      FullAddress: ``,
    });
    return array.map(opt => ({
      text: `${
        opt.Id === `All`
          ? `All`
          : `${opt.Id || ``}
      ${`- ${opt.Name || ``}`}
      ${`Phone: ${opt.Telephone || ``}`}
      ${`Email: ${opt.FullAddress || ``}`}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.Name || ''}`,
        key: `${opt.Name || ''} ${opt.FullAddress}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const client = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (client.id === `All` || value === `All`) {
      ClientsStore.refetch(
        {
          keyword: ``,
          curPage: 1,
          keywordSearch: ``,
        },
        true,
      );
    } else {
      ClientsStore.refetch(
        {
          keyword: client.id,
          curPage: 1,
          numberPageSearch: 30,
          keywordSearch: `${client.id}`,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(ClientsStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', Name: ``, Email: `` });
    let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.Name}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };
  handleRefresh = () => {
    ClientsStore.refetch(
      {
        data: null,
        dataSearch: null,
      },
      true,
    );
  };

  renderActionPICOptions = () => {
    const array = [];
    const data = toJS(ClientsStore.dataActionPIC?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: `${opt.FullName} (${opt.Count})`,
          value: opt.Id,
        };
      }
    });
  };

  renderActionPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(ClientsStore.dataActionPIC.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return `${opt.FullName} (${opt.Count})`;
          }
        });
      return labels[0];
    }
  };

  renderStaffOptions = () => {
    const array = [];
    const data = toJS(ClientsStore.dataStaffs?.itemList || []);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    _.sortBy(array, 'FullName').unshift({ Id: '', FullName: 'All', Count: 0 });
    return array.map(opt => {
      if (opt.FullName === 'All') {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      } else {
        return {
          text: opt.FullName,
          value: opt.Id,
        };
      }
    });
  };

  renderStaffText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(ClientsStore.dataStaffs.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      let labels = array
        .filter(opt => opt.Id === value)
        .map(opt => {
          if (opt.FullName === 'All') {
            return opt.FullName;
          } else {
            return opt.FullName;
          }
        });
      return labels[0];
    }
  };

  handleFilterActionPIC = (_, { value }) => {
    ClientsStore.refetch({ ActionRequiredById: value, curPage: 1 }, true);
  };

  handleFilterBusinessAccountManager = (_, { value }) => {
    ClientsStore.refetch({ BusinessAccountManagerId: value, curPage: 1 }, true);
  };

  render() {
    const {
      keyword,
      loading,
      showing,
      loadingSearch,
      ActionRequiredById,
      totalItems,
      BusinessAccountManagerId,
      filterSubcription,
      StateId,
    } = ClientsStore;
    return (
      <>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              onScroll={this.handleScroll}
              search
              disabled={loading}
              selectOnNavigation={false}
              allowAdditions
              additionLabel=""
              value={this.renderSearchText(keyword)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: '5px' }}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.openCaseCreate()}
            >
              Add New Client
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            disabled={loading}
            label={`Client Status`}
            value={this.renderFilterText(showing)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`Subscription`}
            value={this.renderFilterSubcriptionText(filterSubcription)}
            options={this.renderFilterSubcriptionOptions()}
            onChange={this.handleFilterSubcription}
          />
          <TableToolbar.Filter
            label="Action PIC"
            value={this.renderActionPICText(ActionRequiredById)}
            options={this.renderActionPICOptions()}
            onChange={this.handleFilterActionPIC}
            disabled={loading}
          />
          <TableToolbar.Filter
            label="Business Account Manager"
            value={this.renderStaffText(BusinessAccountManagerId)}
            options={this.renderStaffOptions()}
            onChange={this.handleFilterBusinessAccountManager}
            disabled={loading}
          />
          <TableToolbar.Filter
            style={{ marginBottom: '1em' }}
            disabled={loading}
            label={`State`}
            value={this.renderStateText(StateId)}
            options={this.renderState()}
            onChange={this.handleSelectState}
          />
        </TableToolbar.Container>
      </>
    );
  }
}

export default ClientsToolbar;
