import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import Modal from '../../shared/Modal';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import ClientsStore from './ClientsStore';
import moment from 'moment';

const columns = [
  { title: 'Case No' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Specialist', sortKey: 'Specialist' },
  { title: 'Claim/Report Type' },
  { title: 'Case Status' },
  { title: 'Status Date' },
];

@observer
class ModalBookingHistory extends React.Component {
  renderTableHistory = () =>
    ClientsStore.dataBookingHistory.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell style={{ maxWidth: '200px' }}>{record.CaseNo}</Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.CMFullName}
          <div>{record.CMEmail ? `${record.CMEmail}` : ''}</div>
          <div>{record.CMPhone ? `${record.CMPhone}` : ''}</div>
        </Table.Cell>
        <Table.Cell style={{ maxWidth: '300px' }}>
          <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorFullName}{' '}
            {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
          </h5>
          <div>Specialty: {record.DoctorSepcialty}</div>
          <div>
            <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
          </div>
          <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
            Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
          </div>
        </Table.Cell>
        <Table.Cell>{record.ClaimReportType}</Table.Cell>
        <Table.Cell>{record.CaseStatus}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));

  handlePageClick = page => {
    const { clientId } = this.props.modalParams;
    //console.log(clientId);
    ClientsStore.refetchBookingHistory(clientId, { curPageBookingHistory: page });
  };

  render() {
    const { dataBookingHistory, loadingBookingHistory, toggleModal, open, totalBooking } = ClientsStore;
    const { record } = this.props.modalParams;
    const colSpan = columns.length;

    return (
      <Modal
        visible={open}
        width={1400}
        onCancel={toggleModal(false)}
        title={`Booking History - ${record.Name} (${totalBooking})`}
      >
        {loadingBookingHistory && !dataBookingHistory ? (
          <div className={`Table-loading`}>
            <Spin size="large" />
          </div>
        ) : (
          <Table sortable striped celled className={cx({ disabled: loadingBookingHistory })}>
            <TableHeader
              columns={columns}
              sortColumn={ClientsStore.sortColumnBooking}
              sortDirection={ClientsStore.sortDirectionBooking}
              onSort={ClientsStore.handleSortBooking}
            />
            <Table.Body>
              {dataBookingHistory.itemList.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
            </Table.Body>
            <TableFooterPagination
              colSpan={colSpan}
              currentPage={ClientsStore.curPageBookingHistory}
              totalPage={ClientsStore.totalPageBookingHistory}
              totalItems={ClientsStore.totalItemsBookingHistory}
              onPageClick={this.handlePageClick}
            />
          </Table>
        )}
      </Modal>
    );
  }
}

export default ModalBookingHistory;
