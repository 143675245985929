import React from 'react';
import { Form, Radio, Input, Checkbox, DatePicker } from 'antd';

class CaseCancellationForm extends React.Component {
  render() {
    return (
      <Form name="case-cancellation">
        <Form.Item label="Case Cancellation Status" className="fluid-field">
          <Radio.Group>
            <Radio>None</Radio>
            <Radio>Cancelled No Fee</Radio>
            <Radio>Cancelled With Fee</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Reasons" className="fluid-field">
          <Radio.Group>
            <Radio>The request of the Case Manager</Radio>
            <Radio>Doctor being unable to undertake assessment</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Send Cancellation Notice to">
          <Input size="large" />
        </Form.Item>
        <div className="fluid-field checkbox-field">
          <Checkbox>Notify Booking CM of Case Cancellation</Checkbox>
        </div>
        <div className="fluid-field checkbox-field">
          <Checkbox>Notify Doctor of Case Cancellation</Checkbox>
        </div>
        <Form.Item label="Date of Cancellation">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Comments" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default CaseCancellationForm;
