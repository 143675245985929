import './HideSidebarIcon.scss';

import React from 'react';
import { Icon } from 'semantic-ui-react';

import ui from '../../../stores/dashboard';

class HideSidebarIcon extends React.Component {
  render() {
    const { toggleSidebarVisible } = ui;
    return (
      <div className="DashboardHeaderIcon HideSidebarIcon" onClick={toggleSidebarVisible}>
        <Icon name="window close outline" fitted />
      </div>
    );
  }
}

export default HideSidebarIcon;
