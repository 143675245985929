import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import store from './store';

const columns = [{ title: 'Id' }, { title: 'Username' }, { title: 'Reset At' }, { title: 'Reset By' }];

@observer
class TableData extends React.Component {
  handlePageClick = page => {
    store.refetch({ curPage: page });
  };

  renderTableBody = data => {
    return data.map(record => (
      <Table.Row key={record.Id}>
        <Table.Cell>{record.Id}</Table.Cell>
        <Table.Cell>{record.Username}</Table.Cell>
        <Table.Cell>{moment(record.ResetAt).format('DD/MM/YYYY - HH:mm')}</Table.Cell>
        <Table.Cell>{record.ResetBy}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, data, totalItem } = store;
    const colSpan = columns.length;

    return (
      <Table striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={columns} />
        <Table.Body>
          {!loading && !!data.length ? this.renderTableBody(data) : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage}
          totalPage={store.totalPage}
          onPageClick={this.handlePageClick}
          totalItems={totalItem}
        />
      </Table>
    );
  }
}

export default TableData;
