import { observer } from 'mobx-react';
import React from 'react';

import TableToolbar from '../../../shared/tableToolbar';
import BusinessUnitStore from './store';
import * as api from '../../../../stores/api';

const statusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class BusinessUnitToolbar extends React.Component {
  renderFilterOptions = () => {
    return statusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = statusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    BusinessUnitStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handSubmit = e => {
    e.preventDefault();
    BusinessUnitStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => {
    const id = 0;
    return BusinessUnitStore.toggleModal(true, {
      modalType: 'addEdit',
      id,
    })();
  };

  onOpenCaseCreateNew = () => {};

  render() {
    const { keyword, loading, filter } = BusinessUnitStore;
    return (
      <React.Fragment>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.Input onChange={BusinessUnitStore.handleSearchChange} value={keyword} disabled={loading} />
            <TableToolbar.Filter
              disabled={loading}
              value={this.renderFilterText(filter)}
              label={`Status`}
              options={this.renderFilterOptions()}
              onChange={this.handleFilter}
            />
            <TableToolbar.SubmitButton disabled={loading} onClick={this.handSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              disabled={loading}
              className="negative"
              onClick={BusinessUnitStore.resetStore}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right>
            {api.isAdminOrMagStaffOrAccounting() && !api.isOnlySearch() && (
              <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.openCaseCreate}>
                Add Business Unit
              </TableToolbar.HighLightButton>
            )}
          </TableToolbar.Right>
        </TableToolbar.Container>
      </React.Fragment>
    );
  }
}

export default BusinessUnitToolbar;
