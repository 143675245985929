import customFetch from '../../../utils/customFetch';

export const getCMInfo = (id, cId = 0) => {
  return customFetch('/Staff/CaseManagerInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, cId }),
  });
};

export const getBusinessUnits = () => {
  return customFetch('/BusinessUnit/GetBusinessUnits', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const getStaffs = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getClient = (keyword = '', numberItem = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
      Showing: 'active',
    }),
  });
};

export const getSpecificLookupConfigByType = () => {
  return customFetch('/Staff/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'job' }),
  });
};

export const getStaffAll = (keyword = '', numberItem = 30) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getCampaignAll = (keyword = '', numberItem = 30) => {
  return customFetch('/ManagerCampaign/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
      CampaignStatus: null, //Status: Processing
      IsCurrent: true,
    }),
  });
};

export const checkDuplicateName = (id, lastname) => {
  return customFetch('/Staff/CheckDuplicateName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, lastname }),
  });
};

export const checkDuplicateEmail = (id, email) => {
  return customFetch('/Staff/CheckDuplicateEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, email }),
  });
};

export const getConversationLog = (cmId, followUp = false) => {
  return customFetch('/Staff/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ caseManagerId: cmId, followUpNeeded: followUp }),
  });
};

export const allowCMViewConversationLog = (id, type = 0) => {
  return customFetch('/Staff/UpdateDoctorAndCMsView', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, type }),
  });
};

export const deleteConversationLog = id => {
  return customFetch('/Staff/DeleteConversationLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const saveConversationLog = body => {
  return customFetch('/Staff/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const recentChangeClient = id => {
  return customFetch('/Staff/GetAssignedClientHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const recentChangeStaff = id => {
  return customFetch('/Staff/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ actionName: 'ViewCM', id }),
  });
};

export const getCreatedByHistories = id => {
  return customFetch('/Staff/GetCreatedByHistories?staffId=' + id, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const saveCaseManager = (staffParam, CompanyBusinessUnit) => {
  return customFetch('/Staff/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ staffParam, CompanyBusinessUnit }),
  });
};

export const fetchChartDate = id => {
  return customFetch(`/Staff/GetCMBookingLast6Month?caseManagerId=${id}`, {});
};

export const fetchGreetingTitlesByType = type => {
  return customFetch(`/GreetingTitle/GetAllByType?type=${type}`, {
    method: 'GET',
  });
};

export const getActionRequired = ItemId => {
  return customFetch('/Staff/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ItemId }),
  });
};

export const viewBookingHistory = (caseManagerId, sortKeyBooking, sortDirectionBooking) => {
  return customFetch(
    `/Staff/GetBookingHistory?caseManagerId=${caseManagerId}&sortBy=${sortKeyBooking}&sortDirection=${sortDirectionBooking
      .replace('ending', '')
      .toUpperCase()}`,
    {
      method: 'GET',
    },
  );
};

//Favourite
export const getAllSpecialist = body => {
  return customFetch('/MedicalDoctor/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const getRegisteredSpecialistDetails = (sId, arrId) => {
  return customFetch('/Staff/GetFavouriteDoctorByIdList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ staffId: sId, arrId }),
  });
};
export const getRegisteredSpecialists = sId => {
  return customFetch('/Staff/GetFavouriteDoctorIds', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ sId }),
  });
};

export const registeredSpecialists = (sId, lstItems) => {
  return customFetch('/Staff/UpdateFavouriteDoctor', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ sId, models: lstItems }),
  });
};

export const sendOnboardedNoticeToCMs = (dId, cmId) => {
  return customFetch('/MedicalDoctor/SendOnboardedNoticeToCMs2', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId: dId, cmId: cmId }),
  });
};

export const loadTalkingPoint = clientId => {
  return customFetch('/TalkingPoint/GetAllByType?clientId=' + clientId, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getClientById = clientId => {
  return customFetch('/Company/CompanyInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: clientId,
    }),
  });
};

export const changeAuthorize = body => {
  customFetch('/Staff/Authorize', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(body),
  });
};
