import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import SalesCallReportStore from './SalesCallReportStore';
import SalesCallReportToolbar from './SalesCallReportToolbar';
import SalesCallReportTable from './SalesCallReportTable';
import SalesCallModal from './SalesCallModal';

class SalesCallReport extends React.Component {
  componentDidMount() {
    SalesCallReportStore.fetchReport(true);
    SalesCallReportStore.fetchClient();
    SalesCallReportStore.fetchRecruitment();
    SalesCallReportStore.fetchSearchDoctor();
  }

  componentWillUnmount() {
    SalesCallReportStore.resetStore();
  }

  render() {
    const { loading, data, loadingStaff, dataStaff, loadingClient, dataClient, open } = SalesCallReportStore;
    return (
      <div className={`Table-container`}>
        <React.Fragment>
          <SalesCallReportToolbar />
          {(loading && !data) || (loadingStaff && !dataStaff) || (loadingClient && !dataClient) ? (
            <div className="Table-loading">
              <Spin size="large" />
            </div>
          ) : (
            <SalesCallReportTable />
          )}
          {open && <SalesCallModal />}
        </React.Fragment>
      </div>
    );
  }
}

export default observer(SalesCallReport);
