import customFetch from '@utils/customFetch';

export const getDataClinicalNote = (id = 0) => {
  return customFetch('/ClinicalNote/PostClinicalNoteInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getWiroPrincipleLawyer = () => {
  return customFetch('/ClinicalNote/GetWIROPrincipleLawyer', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getIMSCode = () => {
  return customFetch('/ClinicalNote/GetIMSApprovalCode', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getCaseManagerOnboarded = ({ keyword = '', numberItem = 30, companyId = 0 }) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
      companyId,
    }),
  });
};

export const getCaseCorrespondence = (ClinicalNoteID = 0) => {
  return customFetch('/ClinicalNote/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ClinicalNoteID }),
  });
};

export const getTypeOfClaim = () => {
  return customFetch('/ClinicalNote/GetActiveClaimTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getSpecialist = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/MedicalDoctor/GetAllWithoutNCI', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
      RecruitedOrContractedOnly: true,
    }),
  });
};

export const getClaimant = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/PatientInfo/GetPatientList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getClient = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getStaff = () => {
  return customFetch('/ClinicalNote/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getClinic = ({ doctorId = 0, keyword = '', numberItem = 30 }) => {
  return customFetch('/Clinic/GetClinicList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      curPage: 1,
      doctorId,
      keyword,
      numItemPerPage: numberItem,
      type: null,
    }),
  });
};

export const getClinicalNoteRequest = () => {
  return customFetch('/api/ClinicalNoteRequestSentByOption', {
    method: 'GET',
  });
};

export const getCRRetriever = ({ keyword = '', numberItem = 30 }) => {
  return customFetch('/MedicalDoctor/GetAllCRRetriever', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      RecruitedOrContractedOnly: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
    }),
  });
};

export const getInvoice = (objectId = 0) => {
  return customFetch('/MedicalDoctor/GetListInvoiceID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectId, type: 'ClinicalNote' }),
  });
};

export const getActionRequired = (clinicalNoteId = 0) => {
  return customFetch('/ClinicalNote/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicalNoteId }),
  });
};

export const submitConversation = data => {
  return customFetch('/ClinicalNote/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};

export const getAssignHistory = ({ id, actionName }) => {
  return customFetch('/Staff/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const getCompanyRecentChangeHistory = (id, actionName) => {
  return customFetch('/Default/GetAssignedCompanyHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, actionName }),
  });
};

export const saveCRNote = body => {
  return customFetch('/api/ClinicalNote', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const updateClinicalRecordStatus = (id, status) => {
  return customFetch('/ClinicalNote/ChangeStatusCR', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicalNoteId: id, caseFlow: status }),
  });
};

export const getClinicalRecordLogs = id => {
  return customFetch('/api/ClinicalNoteLog?clinicalNoteId=' + id, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getFullNameInCC = (type, keyword) => {
  return customFetch('/ClinicalNote/GetFullNameInCC?type=' + type + '&keyword=' + keyword, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const resendPaperworkReadiness = id => {
  return customFetch('/ClinicalNote/ResendPaperworkReadiness', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getUserUploadInfo = id => {
  return customFetch('/Manager/GetUserById', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: id }),
  });
};
