import customFetch from '../../../utils/customFetch';

export const getListCampaign = (
  CurPage = 1,
  NumItemOfPage = 20,
  sortBy = 'CampaignName',
  sortDirection = 'ascending',
  Keyword = '',
  From = null,
  To = null,
  TargetAudience = 0,
  TargetStatus = 0,
  Channel = 0,
  CampaignStatus = null,
) => {
  return customFetch('/ManagerCampaign/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      CurPage: CurPage,
      NumItemOfPage: NumItemOfPage,
      sortBy: sortBy,
      sortDirection: sortDirection.replace('ending', '').toUpperCase(),
      Keyword: Keyword,
      From: From,
      To: To,
      TargetAudience: TargetAudience,
      TargetStatus: TargetStatus,
      Channel: Channel,
      CampaignStatus: CampaignStatus,
    }),
  });
};

export const getConfigType = (type = 'Channel') => {
  return customFetch(`/ManagerCampaign/GetConfigData?type=${type}`, {
    method: 'GET',
  });
};

export const deleteCaseManagerCampaign = id => {
  return customFetch(`/ManagerCampaign/DeleteRecord?Id=${id}`, {
    method: 'POST',
  });
};

export const fetchChartDate = id => {
  return customFetch(`/Staff/GetCMBookingLast6Month?caseManagerId=${id}`, {});
};

export const getCampaignInfo = id => {
  return customFetch(`/ManagerCampaign/FindItem?Id=${id}`, {
    method: 'GET',
  });
};

export const getStaff = () => {
  return customFetch('/ManagerCampaign/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const saveCaseManagerCampaign = bodyParam => {
  return customFetch('/ManagerCampaign/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ model: bodyParam }),
  });
};

export const saveAndSendPreivewCaseManagerCampaign = bodyParam => {
  return customFetch('/ManagerCampaign/SaveAndSendPreview', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ model: bodyParam }),
  });
};

export const uploadFileTemplate = body => {
  return customFetch('/File/UploadFileTemp', {
    method: 'POST',
    body,
  });
};
