/**
 * NOTE
 *
 * ALL function was defined in this file MUST be pure
 */

////////////////////////////////////////////////////////////////////////////
////////////////////////////// ALL NORMALIZE ///////////////////////////////
///// All data structure were used by SmartSearch MUST be define here //////
////////////////////////////////////////////////////////////////////////////
export const normalizeSpecialty = sItem => {
  return {
    id: sItem.id,
    description: sItem.description,
    label: sItem.label,
    parentId: sItem.parentId,
    type: sItem.type,
  };
};

export const normalizeSubSpecialty = ssItem => {
  return {
    id: ssItem.id,
    label: ssItem.label,
    parentId: ssItem.parentId,
  };
};

export const normalizeSession = ss => {
  return {
    date: ss.date,
    dateText: ss.dateText,
    available: ss.availableSession,
    total: ss.totalSession,
  };
};

export const normalizeDoctor = (dr, clinic = {}, sessionList = []) => {
  const { specialties = {}, subSpecialties = {} } = dr;
  const { list: spList = [] } = specialties;
  const { list: sspList = [] } = subSpecialties;
  const specialtyList = spList.map(sp => normalizeSpecialty(sp));
  const subSpecialtyList = sspList.map(sp => normalizeSubSpecialty(sp));
  let result = {
    id: dr.id,
    uniqueId: dr.id,
    title: dr.title,
    fullName: dr.fullName,
    fullAddress: dr.fullAddress,
    specialtyList,
    subSpecialtyList,
    registerOption: dr.registerOption,
    serviceExclusion: dr.serviceExclusion,
    accreditations: dr.accreditations,
    qualifications: dr.qualifications,
  };

  if (clinic && clinic.id) {
    result = {
      ...result,
      uniqueId: `${dr.id}___${clinic.id}`,
      clinicName: clinic.name,
      clinicAddress: clinic.fullAddress,
    };
  }

  if (sessionList.length > 0) {
    result = {
      ...result,
      sessionList,
    };
  }

  return result;
};

export const normalizeClinic = clinic => {
  const result = {
    id: clinic.id,
    name: clinic.name,
    fullAddress: clinic.fullAddress,
    correspondenceFullAddress: clinic.correspondenceFullAddress,
    distance: clinic.Distance, // Missing from graphQL
    distanceText: clinic.DistanceText, // Missing from graphQL
  };

  return {
    ...result,
    doctorList: clinic.doctors.list.map(dr => normalizeDoctor(dr, clinic)),
  };
};

export const normalizeMonthly = m => {
  const { doctors = [] } = m;

  return {
    id: m.month,
    month: m.month,
    monthText: m.monthText,
    immediateAvailable: m.immediateAvailable,
    noAppointment: m.noAppointment,
    doctorList: doctors.map(dr =>
      normalizeDoctor(
        dr.doctor,
        dr.clinic,
        dr.sessions.map(ss => normalizeSession(ss)),
      ),
    ),
    availableSession: m.availableSession,
    totalSession: m.totalSession,
  };
};

export const getReportTypeOptions = list => {
  return list.map(rp => {
    return {
      key: rp.id,
      value: rp.id,
      text: rp.name,
    };
  });
};

const accreditationOpt = ({ Id, Label, Description }) => ({
  key: Id,
  value: Label,
  text: Label + (Description ? ` - ${Description}` : ''),
});

const specialtyOpt = ({ id, label }) => ({
  key: id,
  value: id,
  text: label,
});

export const getOptions = (list, key) => {
  return list.map(key === 'acc' ? accreditationOpt : specialtyOpt).sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const registerOptions = [
  { id: 'P', value: 'P', label: 'Plaintiff' },
  { id: 'D', value: 'D', label: 'Defendant' },
  { id: 'P&D', value: 'P&D', label: 'Plaintiff & Defendant' },
  { id: '', value: '', label: 'All' },
];

////////////////////////////////////////////////////////////////////////////
//////////////////////////// ALL TABLE_FIELDS //////////////////////////////
// All fields for render table(s) used by SmartSearch MUST be define here //
////////////////////////////////////////////////////////////////////////////
