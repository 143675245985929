import cx from 'classnames';
import React, { Fragment } from 'react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { DatePicker, notification } from 'antd';
import { Button, Grid, Form, Label, Segment, Radio, Checkbox, Input, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import Validator from 'validatorjs';
import customFetch from '../../../../utils/customFetch';
import DoctorDetailsStore, { DoctorAttachmentType } from './DoctorDetailsStore';
import { Upload, File, NoFile } from './Upload';
import TextArea from './TextArea';
import './DoctorDetails.scss';
import * as api from '../../../../stores/api';
import { renderDate } from '@components/case-details-routes/Shared';
import { uniqBy } from 'lodash-es';
import { formatDate } from '../../../../utils/functions';
import moment from 'moment';
import store from '@components/doctor-routes/AddEditMySessions/store';
import { getTableFieldChangeHistory } from './service';

@observer
class GeneralInformationTab extends React.Component {
  renderLabel = array => {
    if (array) {
      if (array.length === 0) {
        return <span className="Label-item">None</span>;
      } else {
        return array.map(
          (i, idx) =>
            !!i && (
              <Label key={idx} className="Field-option">
                {i}
              </Label>
            ),
        );
      }
    }
    return <span className="Label-item">None</span>;
  };

  renderLabelCMS = (obj = {}) => {
    if (obj && obj.CmList && !!obj.CmList.length) {
      const myArray = obj.CmList;
      return myArray.map(
        (i, idx) =>
          !!i && (
            <Label key={idx} className="Field-option">
              {`${i.Id} - ${i.FirstName} ${i.LastName} - ${i.Email}`}
            </Label>
          ),
      );
    } else {
      return <span className="Label-item">None</span>;
    }
  };

  renderLabelBlockedClients = (obj = {}) => {
    if (obj && obj.ClientList && !!obj.ClientList.length) {
      const myArray = obj.ClientList;
      return myArray.map(
        (i, idx) =>
          !!i && (
            <Label key={idx} className="Field-option">
              {`${i.Id} - ${i.Name}`}
            </Label>
          ),
      );
    } else {
      return <span className="Label-item">None</span>;
    }
  };

  setSpecialties = (event, data) => {
    DoctorDetailsStore.setFieldValue(event, data);
    DoctorDetailsStore.fetchSubSpecialties();
  };

  handleChangeCmNotification = action((_, { value, name, options }) => {
    const array = (DoctorDetailsStore[name]
      ? uniqBy(
          [
            ...options,
            ...(DoctorDetailsStore[name].CmList || []).map((cm, idx) => ({
              value: cm.Id,
              key: idx,
              text: `${cm.Id} - ${cm.FirstName} ${cm.LastName} - ${cm.Email}`,
              data: cm,
            })),
          ],
          'value',
        )
      : options
    )
      .map(i => {
        if (value.find(id => id === i.value)) {
          return { ...i.data };
        } else {
          return null;
        }
      })
      .filter(i => !!i);
    DoctorDetailsStore[name] = {
      ...DoctorDetailsStore[name],
      CMIds: value.join(';'),
      CmList: array,
    };
  });

  handleChangeBlockedClients = action((_, { value, name, options }) => {
    console.log(DoctorDetailsStore[name]);
    const array = (DoctorDetailsStore[name]
      ? uniqBy(
          [
            ...options,
            ...(DoctorDetailsStore[name].ClientList || []).map((c, idx) => ({
              value: c.Id,
              key: idx,
              text: `${c.Id} - ${c.Name} - ${c.Email}`,
              data: c,
            })),
          ],
          'value',
        )
      : options
    )
      .map(i => {
        if (value.find(id => id === i.value)) {
          return { ...i.data };
        } else {
          return null;
        }
      })
      .filter(i => !!i);
    DoctorDetailsStore[name] = {
      ...DoctorDetailsStore[name],
      ClientIds: value.join(';'),
      ClientList: array,
    };
  });

  @action setAcceptedReportTypes = (_, { value }) => {
    DoctorDetailsStore.selectedAcceptedReportTypes = value;
  };

  @action uploadCV = data => {
    const cv = data.fileList[0];
    if (cv) {
      DoctorDetailsStore.dataDoctor.CV = cv.name;
      DoctorDetailsStore.dataDoctor.CVName = cv.title;
    }
  };

  @action removeCV = () => {
    DoctorDetailsStore.dataDoctor.CV = null;
    DoctorDetailsStore.dataDoctor.CVName = null;
  };

  @action uploadLetterHead = data => {
    DoctorDetailsStore.letterHeadAttachments = [
      ...DoctorDetailsStore.letterHeadAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.LetterHead,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action uploadAHRPAAccreditation = data => {
    DoctorDetailsStore.aHRPAAccreditationsAttachments = [
      ...DoctorDetailsStore.aHRPAAccreditationsAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.AHRPAAccreditation,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeLetterHead = index => {
    DoctorDetailsStore.letterHeadAttachments.splice(index, 1);
  };

  @action removeAHRPAAccreditation = index => {
    DoctorDetailsStore.aHRPAAccreditationsAttachments.splice(index, 1);
  };

  @action uploadSignatures = data => {
    DoctorDetailsStore.signatures = [
      ...DoctorDetailsStore.signatures,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        AttachmentType: DoctorAttachmentType.Signature,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeSignatures = index => {
    DoctorDetailsStore.signatures.splice(index, 1);
  };

  uploadSampleReport = e => {
    const { files } = e.target;

    const totalLength = DoctorDetailsStore.sampleReports.length + files.length;
    if (totalLength > 3) {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'alert',
        message: `You are trying to upload too many sample reports.
        Please select up to 3 files only.`,
        cancelText: 'Cancel',
      })();
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });

    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.sampleReports = [
          ...DoctorDetailsStore.sampleReports,
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.SampleReport,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  @action removeSampleReport = index => {
    DoctorDetailsStore.sampleReports.splice(index, 1);
  };

  uploadRequiredConsentForm = e => {
    const { files } = e.target;
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });
    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.attRequiredConsentForm = [
          ...DoctorDetailsStore.attRequiredConsentForm,
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.RequiredConsentForm,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  uploadSampleAttachment = e => {
    const { files } = e.target;
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });
    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.sampleReportAttachment = [
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.SampleReport,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  uploadTemplateAttachment = e => {
    const { files } = e.target;
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });
    customFetch('/File/UploadFileTemp', {
      method: 'POST',
      body: formData,
    }).then(
      action(data => {
        DoctorDetailsStore.templateReportAttachment = [
          ...data.fileList.map(file => ({
            Id: 0,
            FileDisplayName: file.title,
            PhysicalFileName: file.name,
            AttachmentType: DoctorAttachmentType.TemplateReport,
            NumberOfPages: file.numberOfPages,
          })),
        ];
      }),
    );
  };

  @action removeRequiredConsentFormt = index => {
    DoctorDetailsStore.attRequiredConsentForm.splice(index, 1);
  };

  @action removeTemplateAttachment = index => {
    DoctorDetailsStore.templateReportAttachment.splice(index, 1);
  };

  @action removeSampleAttachment = index => {
    DoctorDetailsStore.sampleReportAttachment.splice(index, 1);
  };

  @action uploadReportTemplate = data => {
    DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments = [
      ...DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments,
      ...data.fileList.map(file => ({
        Id: 0,
        FileDisplayName: file.title,
        PhysicalFileName: file.name,
        NumberOfPages: file.numberOfPages,
      })),
    ];
  };

  @action removeReportTemplate = index => {
    DoctorDetailsStore.dataDoctor.ReportTemplatesAttachments.splice(index, 1);
  };

  @action uploadAccreditationAttachmentComplete = accId => data => {
    const lstItems = DoctorDetailsStore.arrAccreditations;
    var newArr = [];
    lstItems.forEach(el => {
      if (el.AccId == accId) {
        var addObj = {
          Id: el.Id,
          DoctorId: el.DoctorId,
          AccId: el.AccId,
          Name: el.Name,
          EffectiveDate: el.EffectiveDate,
          ExpriryDate: el.ExpriryDate,
          Attachments: [
            ...el.Attachments,
            ...data.fileList.map(file => ({
              Id: 0,
              DoctorId: DoctorDetailsStore.doctorId,
              AccId: accId,
              FileDisplayName: file.title,
              PhysicalFileName: file.name,
              NumberOfPages: file.numberOfPages,
            })),
          ],
        };
        newArr.push(addObj);
      } else {
        newArr.push(el);
      }
    });
    DoctorDetailsStore.setArrAccreditations(newArr);
  };

  @action removeAccreditationAttachment = (accId, index) => {
    var lstItems = DoctorDetailsStore.arrAccreditations;
    var item = lstItems.find(i => i.AccId == accId);
    if (item && item.Attachments) {
      item.Attachments.splice(index, 1);
    }
  };

  @action selectLocation = (_, { value }) => {
    DoctorDetailsStore.selectedLocationsId = value;
    if (DoctorDetailsStore.dataDoctor.ConsultingLocations.length < value.length) {
      const newLocId = value[value.length - 1];
      const newLoc = DoctorDetailsStore.locationList.find(loc => loc.id === newLocId);
      DoctorDetailsStore.dataDoctor.ConsultingLocations.push(newLoc);
    } else {
      DoctorDetailsStore.dataDoctor.ConsultingLocations = DoctorDetailsStore.dataDoctor.ConsultingLocations.filter(
        loc => {
          return value.includes(loc.id);
        },
      );
    }
  };

  @action selectTypeOfClaim = (_, { value }) => {
    DoctorDetailsStore.typeOfClaimId = value;
  };

  @action selectTypeOfReport = (_, { value }) => {
    DoctorDetailsStore.typeOfReportId = value;
  };

  @action selectReportTypeId = (_, { value }) => {
    DoctorDetailsStore.reportTypeId = value;
  };

  addExcludeService = () => {
    const { excludeTypeOfServices } = DoctorDetailsStore;
    if (DoctorDetailsStore.typeOfClaimId && DoctorDetailsStore.typeOfReportId) {
      var findItem = excludeTypeOfServices.find(
        i =>
          i.TypeOfClaimId === DoctorDetailsStore.typeOfClaimId &&
          i.TypeOfReportId === DoctorDetailsStore.typeOfReportId,
      );
      if (!findItem) {
        var newArr = [...excludeTypeOfServices];
        var tcl = DoctorDetailsStore.typeOfClaimList.find(i => i.Id == DoctorDetailsStore.typeOfClaimId);
        var trp = DoctorDetailsStore.typeOfReportList.find(i => i.Id == DoctorDetailsStore.typeOfReportId);
        newArr.push({
          idx: newArr.length + 1,
          TypeOfClaimId: DoctorDetailsStore.typeOfClaimId,
          TypeOfReportId: DoctorDetailsStore.typeOfReportId,
          TypeOfClaim: tcl,
          TypeOfReport: trp,
        });
        DoctorDetailsStore.setExcludeTypeOfServices(newArr);
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Excluding Service already exists.`,
          duration: 5,
        });
      }
    } else {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: `Please select a type of claim or type of report.`,
        duration: 5,
      });
    }
  };

  addDoctorReport = () => {
    const { reportTypeId, sampleReportAttachment, templateReportAttachment, doctorReports } = DoctorDetailsStore;
    if (reportTypeId) {
      var findItem = doctorReports.find(i => i.ReportTypeId === reportTypeId);
      if (!findItem) {
        DoctorDetailsStore.addDoctorReport({
          DoctorId: DoctorDetailsStore.dataDoctor.Id,
          ReportTypeId: reportTypeId,
          SampleAttachments: sampleReportAttachment?.length > 0 ? sampleReportAttachment[0] : null,
          TemplateAttachments: templateReportAttachment?.length > 0 ? templateReportAttachment[0] : null,
        }).then(
          action(data => {
            DoctorDetailsStore.reportTypeId = null;
            DoctorDetailsStore.sampleReportAttachment = [];
            DoctorDetailsStore.templateReportAttachment = [];
            DoctorDetailsStore.fetchDoctorReport(DoctorDetailsStore.dataDoctor.Id).then(
              action(res => {
                DoctorDetailsStore.doctorReports = res.itemList;
              }),
            );
          }),
        );
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: `Type of Report already exists.`,
          duration: 5,
        });
      }
    } else {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: `Please select a type of report.`,
        duration: 5,
      });
    }
  };

  @action deleteExcludeService = idx => {
    const { excludeTypeOfServices } = DoctorDetailsStore;
    var newArr = [];
    var countIdx = 1;
    excludeTypeOfServices.forEach(el => {
      if (el.idx !== idx) {
        newArr.push({
          idx: countIdx,
          TypeOfClaimId: el.TypeOfClaimId,
          TypeOfReportId: el.TypeOfReportId,
          TypeOfClaim: el.TypeOfClaim,
          TypeOfReport: el.TypeOfReport,
        });
        countIdx++;
      }
    });
    DoctorDetailsStore.setExcludeTypeOfServices(newArr);
  };

  @action deleteDoctorReport = item => {
    DoctorDetailsStore.deleteDoctorReport(item.Id).then(() => {
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Item has been deleted`,
        duration: 5,
      });
      DoctorDetailsStore.fetchDoctorReport(item.DoctorId).then(
        action(res => {
          DoctorDetailsStore.doctorReports = res.itemList;
        }),
      );
    });
  };

  @action handleAppointmentReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToAppointmentReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAppointmentReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToAppointmentRemindersViaSMS;
    }
  };

  @action handleCancellationReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToCancellationReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToCancellationReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToCancellationRemindersViaSMS;
    }
  };

  @action handleAvailableForServiceSharing = (_, { name, checked }) => {
    if (checked) {
      DoctorDetailsStore.dataDoctor[name] = checked;
      if (DoctorDetailsStore.dataDoctor.Id > 0 && !DoctorDetailsStore.dataDoctor.SendOnboardingNoticeToUHG) {
        DoctorDetailsStore.dataDoctor.SendOnboardingNoticeToUHG = true;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
    }
  };

  @action handleSendOnboardingNoticeToUHG = (_, { name, checked }) => {
    DoctorDetailsStore.dataDoctor[name] = checked;
  };

  @action handleChangeCheckboxField = (_, { name, checked }) => {
    DoctorDetailsStore.dataDoctor[name] = checked;
  };

  @action handleAttendanceReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToAttendanceReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToAttendanceReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToAttendanceRemindersViaSMS;
    }
  };

  @action handleDictationAndReportReminders = (_, { name, checked }) => {
    if (name === 'SubscribeToDictationAndReportReminders') {
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportReminders = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaEmail = checked;
      if (!checked) {
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaSMS = checked;
      }
    } else {
      DoctorDetailsStore.dataDoctor[name] = checked;
      DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportReminders =
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaEmail ||
        DoctorDetailsStore.dataDoctor.SubscribeToDictationAndReportRemindersViaSMS;
    }
  };

  @action resetLocations = () => {
    DoctorDetailsStore.locationList = [];
  };

  validateEmail = emailStr => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    }
    return null;
  };

  validatePhone = phoneNumber => {
    if (phoneNumber) {
      const validation = new Validator({ phone: phoneNumber }, { phone: 'max:10' }, '');
      if (validation.passes()) {
        return null;
      }
      return 'Must be less than or equal to 10 digits';
    }
    return null;
  };

  handleChangeDatePicker = fieldName =>
    action(data => {
      DoctorDetailsStore[fieldName] = data;
    });

  renderDatePickerDate = date => {
    return date ? moment(date, 'DD/MM/YYYY') : null;
  };

  handleChangeAccDatePicker = (accId, fieldName) =>
    action(data => {
      var lstItem = DoctorDetailsStore.arrAccreditations;
      var findItem = lstItem.find(i => i.AccId === accId);
      if (findItem) {
        findItem[fieldName] = data;
      }
    });

  notifyEnable = () => {
    DoctorDetailsStore.toggleModal(true, {
      modalType: 'alert',
      message: 'This user is not yet enabled for login. Enable login first.',
      cancelText: 'Cancel',
    })();
  };

  handleEditUser = () => {
    const { userId } = DoctorDetailsStore;
    if (userId) {
      customFetch('/Manager/GetUserById', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }),
      }).then(
        action(data => {
          DoctorDetailsStore.dataUser = data;
          DoctorDetailsStore.toggleModal(true, { modalType: 'editUser' })();
        }),
      );
    }
  };

  handleUnlockUser = () => {
    const { userId } = DoctorDetailsStore;
    if (userId) {
      customFetch('/manager/unlockUser', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId }),
      }).then(() => {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `User has been unlocked`,
          duration: 5,
        });
      });
    }
  };

  handleResetPassword = () => {
    const userId = DoctorDetailsStore.dataDoctor.UserId;
    const doctorId = DoctorDetailsStore.dataDoctor.Id;
    if (userId) {
      customFetch('/Manager/ResetPassword', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ userId, doctorId }),
      }).then(res => {
        DoctorDetailsStore.toggleModal(true, {
          modalType: 'alert',
          withoutIcon: true,
          message: `Generated password: ${res.result}`,
          cancelText: 'Cancel',
        })();
      });
    }
  };

  handleResendLoginInformation = () => {
    const userId = DoctorDetailsStore.dataDoctor.UserId;
    const doctorId = DoctorDetailsStore.dataDoctor.Id;
    if (userId) {
      customFetch('/Manager/ResendLoginInformation', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ uId: userId, doctorId }),
      }).then(() => {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Resend login Information: Successfully!!`,
          duration: 5,
        });
      });
    }
  };

  onSendUpdateOnboardingNoticetoUHG  = () => {
    const doctorId = DoctorDetailsStore.dataDoctor.Id;
    if (doctorId) {
      customFetch('/MedicalDoctor/SendUpdateOnboardingNoticetoUHG', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ doctorId }),
      }).then(() => {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Send Onboarding Notice to UHG: Successfully!!`,
          duration: 5,
        });
      });
    }
  };

  @action addBookingAndCancellationTerm = () => {
    //console.log(api.currentUser);
    const { bccList } = DoctorDetailsStore;
    var newArr = [...bccList];
    // newArr.forEach(el => {
    //   el.IsActive = false;
    // });
    newArr.unshift({
      Id: 0,
      idx: newArr.length + 1,
      BookingAndCancellationTerm: '',
      IsActive: false,
      Created: moment(),
      CreatedBy: api.currentUser.data.FullName,
      Modified: moment(),
      ModifiedBy: api.currentUser.data.FullName,
    });
    DoctorDetailsStore.setBCCList(newArr);
  };

  @action clearActiveBookingAndCancellationTerm = () => {
    const { bccList } = DoctorDetailsStore;
    var newArr = [...bccList];
    newArr.forEach(el => {
      el.IsActive = false;
    });
    DoctorDetailsStore.setBCCList(newArr);
  };

  checkDeleteBookingAndCancellationTerm = idx => {
    var textConfrm = 'Are you sure to delete Booking and Cancellation Term? ';
    DoctorDetailsStore.toggleModal(true, {
      modalType: 'confirm',
      message: textConfrm,
      onOk: () => this.deleteBookingAndCancellationTerm(idx),
    })();
  };

  @action deleteBookingAndCancellationTerm = idx => {
    const { bccList } = DoctorDetailsStore;
    var newArr = [];
    bccList.forEach(el => {
      if (el.idx !== idx) {
        newArr.push(el);
      }
    });
    DoctorDetailsStore.setBCCList(newArr);
  };

  @action rdoBookingAndCancellationTermChange = idx =>
    action(event => {
      const value = event.target.value;
      const { bccList } = DoctorDetailsStore;
      bccList.forEach(el => {
        el.IsActive = false;
      });
      var currentItem = bccList.find(i => i.idx === idx);
      if (currentItem) {
        currentItem.IsActive = true;
      }
    });

  @action txtBookingAndCancellationTermChange = idx =>
    action(event => {
      const value = event.target.value;
      const { bccList } = DoctorDetailsStore;
      var currentItem = bccList.find(i => i.idx === idx);
      if (currentItem) {
        currentItem.BookingAndCancellationTerm = value;
      }
    });

  @action confidentialInsightChange = () =>
    action(event => {
      const value = event.target.value;
      DoctorDetailsStore.resetCurrentCI(value);
    });

  checkAndAddConfidentialInsight = () => {
    var textConfrm = "Are you sure to add new note? Notes can't be deleted.";
    if (DoctorDetailsStore.currentCIIdx) textConfrm = "Are you sure to update this note? Old notes can't be restored";
    if (DoctorDetailsStore.currentCI) {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'confirm',
        message: textConfrm,
        onOk: () => this.addConfidentialInsight(),
      })();
    } else {
      notification.destroy();
      notification.info({
        message: 'Confidential Insight',
        description: `Please input new note.`,
        duration: 5,
      });
    }
  };

  updateConfidentialInsight = idx => {
    const { ciList } = DoctorDetailsStore;
    var findItem = ciList.find(i => i.idx === idx);
    if (findItem) {
      DoctorDetailsStore.resetCurrentCI(findItem.ConfidentialInsight);
      DoctorDetailsStore.setCurrentCIIdx(findItem.idx);
      DoctorDetailsStore.setBtnCurrentCIText('Update Note');
    }
  };

  cancelUpdateConfidentialInsight = () => {
    DoctorDetailsStore.setBtnCurrentCIText('Add New Note');
    DoctorDetailsStore.setCurrentCIIdx(0);
    DoctorDetailsStore.resetCurrentCI('');
  };

  addConfidentialInsight = () => {
    const { ciList, btnCurrentCIText, currentCIIdx, currentCI } = DoctorDetailsStore;
    var newArr = [...ciList];
    if (currentCI) {
      if (btnCurrentCIText === 'Add New Note') {
        newArr.unshift({
          idx: newArr.length + 1,
          Id: 0,
          ConfidentialInsight: currentCI,
          Created: moment(),
          CreatedBy: api.currentUser.data.FullName,
          Modified: moment(),
          ModifiedBy: api.currentUser.data.FullName,
        });
        DoctorDetailsStore.setCIList(newArr);
        DoctorDetailsStore.resetCurrentCI('');
      } else {
        var newUpdateArr = [];
        var findItem = newArr.find(i => i.idx === currentCIIdx);
        if (findItem) {
          findItem = {
            ...findItem,
            ConfidentialInsight: currentCI,
            Modified: moment(),
            ModifiedBy: api.currentUser.data.FullName,
          };
          newArr.forEach(el => {
            if (el.idx !== currentCIIdx) {
              newUpdateArr.push(el);
            } else {
              newUpdateArr.push(findItem);
            }
          });
          DoctorDetailsStore.setCIList(newUpdateArr);
        } else {
          notification.destroy();
          notification.info({
            message: 'Confidential Insight',
            description: `Can't found need update`,
            duration: 5,
          });
        }
        DoctorDetailsStore.resetCurrentCI('');
        DoctorDetailsStore.setBtnCurrentCIText('Add New Note');
        DoctorDetailsStore.setCurrentCIIdx(0);
      }
    }
  };

  formatDisplayDate = date => {
    return date ? moment(formatDate(date), 'DD MMM YYYY').format('DD/MM/YYYY') : '';
  };

  formatDisplayDate1 = date => {
    if (date.length > 0) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '';
    //return date ? moment(date).format('DD/MM/YYYY') : '';
  };

  handleFieldRecentChange = async (recordId, fieldName) => {
    try {
      let title = 'Enable Reminder Templates ';
      if (fieldName === 'EnabledMail172') title = title + 'Mail-172';
      if (fieldName === 'EnabledMail173') title = title + 'Mail-173';
      if (fieldName === 'EnabledMail174') title = title + 'Mail-174';
      if (fieldName === 'EnabledMail175') title = title + 'Mail-175';
      const response = await getTableFieldChangeHistory('Doctor', recordId, fieldName, 1, 1000);
      const dataFieldChange = response.itemList;
      if (response.status === 'success') {
        //console.log(dataFieldChange);
        return DoctorDetailsStore.toggleModal(true, { modalType: 'fieldChange', title: title, dataFieldChange })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  render() {
    const {
      dataDoctor,
      isEditing,
      setFieldValue,
      changeValueArrAccreditations,
      toggleCheckbox,
      selectedReportTypes,
      selectedLocations,
      selectedSpecialties,
      selectedSubSpecialties,
      selectedAccreditations,
      CMsWaitingNotification1,
      CMsWaitingNotification1StartDate,
      CMsWaitingNotification1EndDate,
      CMsWaitingNotification2,
      CMsWaitingNotification2StartDate,
      CMsWaitingNotification2EndDate,
      userId,
      blockedClients,
      excludeTypeOfServices,
      doctorReports,
      bccList,
      ciList,
      btnCurrentCIText,
      currentCIIdx,
    } = DoctorDetailsStore;
    const dateFormat = 'DD/MM/YYYY';

    const enableLoginDate = dataDoctor.EnableLoginDate
      ? moment(formatDate(dataDoctor.EnableLoginDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
      : '';
    const enableLoginBy = dataDoctor.EnableLoginBy ? dataDoctor.EnableLoginBy : '';

    const labelEnableLogin =
      !dataDoctor.EnableLogin || !enableLoginDate
        ? `Enable Login`
        : 'Enable Login (' + enableLoginDate + ' - ' + enableLoginBy + ')';

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Profile</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Brief Profile{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Staff, CMs and Specialists can view"
                        position="top center"
                        wide="very"
                      />{' '}
                    </label>
                    {!isEditing ? (
                      <p className="Form-Field-Text" style={{ whiteSpace: 'pre-line' }}>
                        {dataDoctor.Comment || 'None'}
                      </p>
                    ) : (
                      <TextArea
                        name="Comment"
                        placeholder="Please write 1 paragraph describing your medical and medico-legal expertise, including industries you have worked in. Use 3rd person pronoun such as title + surname, he/she to describe about yourself. This will be used to assist the customer in selecting the right medico-legal professional to conduct the assessment, and for promotional purposes."
                        maxLength="750"
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Accepted Report Types</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedReportTypes)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Accepted Report Types"
                        multiple
                        search
                        selection
                        name="selectedAcceptedReportTypes"
                        options={DoctorDetailsStore.dataReportTypes}
                        value={toJS(DoctorDetailsStore.selectedAcceptedReportTypes) || []}
                        onChange={this.setAcceptedReportTypes}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <div className="fields" style={{ marginBottom: '-10px' }}>
                  <div className="eight wide field">
                    <label className="Form-Field-label">Excluding Services</label>
                  </div>
                </div>
                <Form.Group style={{ margin: '0' }}>
                  <table className="ui celled sortable striped table">
                    <thead className="">
                      <tr>
                        <th className="cursor-default">Type of Claim</th>
                        <th className="cursor-default">Type of Report</th>
                        {isEditing && (
                          <th style={{ textAlign: 'center' }} title="Delete">
                            Delete
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {isEditing && (
                        <tr>
                          <td>
                            <Dropdown
                              style={{ width: '100%' }}
                              search
                              clearable={true}
                              selection
                              name="ConsultingLocations"
                              options={DoctorDetailsStore.dataTypeOfClaims}
                              value={toJS(DoctorDetailsStore.typeOfClaimId)}
                              onChange={this.selectTypeOfClaim}
                            />
                          </td>
                          <td>
                            <Dropdown
                              style={{ width: '100%' }}
                              search
                              clearable={true}
                              selection
                              name="TypeOfReport"
                              options={DoctorDetailsStore.dataTypeOfReports}
                              value={toJS(DoctorDetailsStore.typeOfReportId)}
                              onChange={this.selectTypeOfReport}
                            />
                          </td>
                          <td></td>
                        </tr>
                      )}
                      {isEditing && (
                        <tr>
                          <td colSpan={3}>
                            <Button color="blue" onClick={() => this.addExcludeService()}>
                              Add Excluding Service
                            </Button>
                          </td>
                        </tr>
                      )}
                      {!!toJS(excludeTypeOfServices) &&
                        toJS(excludeTypeOfServices).map(item => {
                          return (
                            <tr key={item.idx}>
                              <td>{item.TypeOfClaim.Name}</td>
                              <td>{item.TypeOfReport.Name}</td>
                              {isEditing && (
                                <td style={{ textAlign: 'center' }}>
                                  <Link
                                    to="#"
                                    onClick={DoctorDetailsStore.toggleModal(true, {
                                      modalType: 'confirm',
                                      message: `Do you want to delete this service?`,
                                      onOk: () => this.deleteExcludeService(item.idx),
                                    })}
                                  >
                                    <span style={{ color: 'red' }}>X</span>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Form.Group>

                <div className="fields" style={{ marginBottom: '-10px' }}>
                  <div className="eight wide field">
                    <label className="Form-Field-label">Type of Report</label>
                  </div>
                </div>
                <Form.Group style={{ margin: '0' }}>
                  <table className="ui celled sortable striped table">
                    <thead className="">
                      <tr>
                        <th className="cursor-default">Type of Report</th>
                        <th className="cursor-default">Sample Report</th>
                        <th className="cursor-default">Template Report</th>
                        {isEditing && (
                          <th style={{ textAlign: 'center' }} title="Delete">
                            Delete
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {isEditing && (
                        <tr>
                          <td>
                            <Dropdown
                              style={{ width: '100%' }}
                              search
                              clearable={true}
                              selection
                              name="TypeOfReport"
                              options={DoctorDetailsStore.dataTypeOfReports}
                              value={toJS(DoctorDetailsStore.reportTypeId)}
                              onChange={this.selectReportTypeId}
                            />
                          </td>
                          <td>
                            <React.Fragment>
                              {DoctorDetailsStore.sampleReportAttachment?.length > 0 ? (
                                DoctorDetailsStore.sampleReportAttachment.map((i, idx) => (
                                  <div className="mb-1" key={i.PhysicalFileName}>
                                    <File
                                      name={i.FileDisplayName}
                                      downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                                      onRemove={() => this.removeSampleAttachment(idx)}
                                    />
                                  </div>
                                ))
                              ) : (
                                <div className="mb-1">
                                  <Upload
                                    multiple={false}
                                    onChange={this.uploadSampleAttachment}
                                    placeholder="Upload Sample Report"
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          </td>
                          <td>
                            <React.Fragment>
                              {DoctorDetailsStore.templateReportAttachment?.length > 0 ? (
                                DoctorDetailsStore.templateReportAttachment.map((i, idx) => (
                                  <div className="mb-1" key={i.PhysicalFileName}>
                                    <File
                                      name={i.FileDisplayName}
                                      downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                                      onRemove={() => this.removeTemplateAttachment(idx)}
                                    />
                                  </div>
                                ))
                              ) : (
                                <div className="mb-1">
                                  <Upload
                                    multiple={false}
                                    onChange={this.uploadTemplateAttachment}
                                    placeholder="Upload Template Report"
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          </td>
                        </tr>
                      )}
                      {isEditing && (
                        <tr>
                          <td colSpan={3}>
                            {!api.isOnlySearch() &&  <Button color="blue" onClick={() => this.addDoctorReport()}>
                              Add Type of Report
                            </Button>}
                          </td>
                        </tr>
                      )}
                      {!!toJS(doctorReports) &&
                        toJS(doctorReports).map(item => {
                          return (
                            <tr key={item.idx}>
                              <td>{item.ReportTypeName}</td>
                              <td>
                                <div className="mb-1">
                                  <File
                                    name={item.SampleAttachments?.FileDisplayName}
                                    downloadLink={`/UserFile/DoctorAttachment/${item.SampleAttachments?.PhysicalFileName}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="mb-1">
                                  <File
                                    name={item.TemplateAttachments?.FileDisplayName}
                                    downloadLink={`/UserFile/DoctorAttachment/${item.TemplateAttachments?.PhysicalFileName}`}
                                  />
                                </div>
                              </td>
                              {isEditing && (
                                <td style={{ textAlign: 'center' }}>
                                  <Link
                                    to="#"
                                    onClick={DoctorDetailsStore.toggleModal(true, {
                                      modalType: 'confirm',
                                      message: `Do you want to delete this type of report?`,
                                      onOk: () => this.deleteDoctorReport(item),
                                    })}
                                  >
                                    <span style={{ color: 'red' }}>X</span>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Consulting Locations (Suburbs or Towns)</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedLocations)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Enter at least 3 letters to search for a  locations"
                        multiple
                        search
                        selection
                        name="ConsultingLocations"
                        onSearchChange={DoctorDetailsStore.fetchLocations}
                        options={DoctorDetailsStore.dataLocations}
                        onClose={this.resetLocations}
                        value={toJS(DoctorDetailsStore.selectedLocationsId)}
                        onChange={this.selectLocation}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Consulting Locations (States)</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(dataDoctor.ConsultingLocationStates)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Consulting locations (States)"
                        multiple
                        search
                        selection
                        name="ConsultingLocationStates"
                        options={DoctorDetailsStore.dataStates}
                        value={toJS(dataDoctor.ConsultingLocationStates)}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label" style={{ display: 'inline-block' }}>
                      <React.Fragment>
                        Booking and Cancellation Terms
                        <Popup
                          trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                          content="Staff, CMs and Specialist can view in bookings and calendar."
                          position="top center"
                        />
                        {isEditing && (
                          <Button color="blue" onClick={() => this.addBookingAndCancellationTerm()}>
                            Add New Term
                          </Button>
                        )}
                        {isEditing && bccList && bccList.length > 0 && (
                          <Button color="blue" onClick={() => this.clearActiveBookingAndCancellationTerm()}>
                            Clear Effective
                          </Button>
                        )}
                      </React.Fragment>
                    </label>
                    {!isEditing ? (
                      <div style={{ width: '100%', maxHeight: '190px', overflowY: 'auto' }}>
                        <table className="ui celled sortable striped table">
                          <tbody>
                            {bccList && bccList.length > 0 ? (
                              !!toJS(bccList) &&
                              toJS(bccList).map(item => {
                                return (
                                  <tr key={'view-bccList' + item.idx}>
                                    <td>
                                      <React.Fragment>
                                        <input
                                          style={{ float: 'left', marginTop: '5px', marginRight: '6px' }}
                                          type="radio"
                                          checked={item.IsActive}
                                        />{' '}
                                        <p
                                          className="Form-Field-Text"
                                          style={{ whiteSpace: 'pre-line', marginBottom: '2px' }}
                                        >
                                          {item.BookingAndCancellationTerm}
                                        </p>
                                        <div style={{ width: '100%', paddingBottom: '8px' }}>
                                          <span style={{ color: '#a1b3d3e0', fontSize: '11px' }}>
                                            Created: {item.Created.format('DD/MM/YYYY HH:mm')} by {item.CreatedBy} -
                                            Updated: {item.Modified.format('DD/MM/YYYY HH:mm')} by {item.ModifiedBy}
                                          </span>
                                        </div>
                                      </React.Fragment>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr key={'view-bccList--0'}>
                                <td>
                                  <span className="Label-item">None</span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div style={{ width: '100%', maxHeight: '190px', overflowY: 'auto' }}>
                        {!!toJS(bccList) &&
                          toJS(bccList).map(item => {
                            return (
                              <React.Fragment key={'BookingsAndCancellationTerm' + item.idx}>
                                {item.Id === 0 && (
                                  <Link to="#" onClick={() => this.checkDeleteBookingAndCancellationTerm(item.idx)}>
                                    <Icon color="red" name="close"></Icon>
                                  </Link>
                                )}
                                <Radio
                                  name="rdoBookingsAndCancellationTerm"
                                  checked={item.IsActive}
                                  onChange={this.rdoBookingAndCancellationTermChange(item.idx)}
                                ></Radio>
                                {item.IsActive ? ' Effective -' : ''}
                                {item.Id > 0 && (
                                  <span style={{ color: '#a1b3d3e0', fontSize: '11px' }}>
                                    {' '}
                                    Created: {item.Created.format('DD/MM/YYYY HH:mm')} by {item.CreatedBy} - Updated:{' '}
                                    {item.Modified.format('DD/MM/YYYY HH:mm')} by {item.ModifiedBy}{' '}
                                  </span>
                                )}
                                <TextArea
                                  disabled={!api.isAdminOrMagStaffOrAccounting()}
                                  name={'TextBookingAndCancellationTerm' + item.idx}
                                  placeholder="List service conditions for booking and cancellation"
                                  value={item.BookingAndCancellationTerm}
                                  maxLength="500"
                                  onChange={this.txtBookingAndCancellationTermChange(item.idx)}
                                />
                              </React.Fragment>
                              // <Input name={'BookingAndCancellationTerm' + item.idx} disabled={!api.isAdminOrMagStaffOrAccounting()} value={item.BookingAndCancellationTerm} onChange={this.bookingAndCancellationTermChange(item.idx)} />
                            );
                          })}
                      </div>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Paperwork Notes</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.PaperworkNotes || 'None'}</span>
                      </div>
                    ) : (
                      <TextArea name="PaperworkNotes" maxLength="2000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      <React.Fragment>
                        <div style={{ color: 'red', float: 'left', marginRight: '5px' }}>
                          Confidential Insights
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content="Only staff can view this information"
                            position="top center"
                            wide="very"
                          />{' '}
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          {isEditing && (
                            <Button
                              style={{ float: 'left' }}
                              color="blue"
                              onClick={() => this.checkAndAddConfidentialInsight()}
                            >
                              {btnCurrentCIText}
                            </Button>
                          )}
                          {isEditing && (
                            <Button
                              style={{ display: currentCIIdx > 0 ? 'block' : 'none', float: 'left' }}
                              color="blue"
                              onClick={() => this.cancelUpdateConfidentialInsight()}
                            >
                              Cancel Update
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    </label>
                    {isEditing && (
                      <TextArea
                        name="currentCIXX"
                        maxLength="4000"
                        onChange={this.confidentialInsightChange()}
                        value={DoctorDetailsStore.currentCI}
                      />
                    )}
                    <div style={{ width: '100%', maxHeight: '190px', overflowY: 'auto' }}>
                      <table className="ui celled sortable striped table">
                        <tbody>
                          {!!toJS(ciList) &&
                            toJS(ciList).map(item => {
                              return (
                                <tr key={'confidentialInsight' + item.idx}>
                                  <td>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                      {isEditing && (api.isAdmin() || api.currentUser.data.id === item.CreatedById) && (
                                        <Link to="#" onClick={() => this.updateConfidentialInsight(item.idx)}>
                                          <Icon name="edit"></Icon>
                                        </Link>
                                      )}
                                      {item.ConfidentialInsight}
                                    </p>
                                    {item.Id > 0 && (
                                      <p style={{ color: '#a1b3d3e0', fontSize: '11px', marginBottom: '0' }}>
                                        Created: {item.Created.format('DD/MM/YYYY HH:mm')} by {item.CreatedBy} -
                                        Updated: {item.Modified.format('DD/MM/YYYY HH:mm')} by {item.ModifiedBy}
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">LinkedIn</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.LinkedInUrl || 'None'}</span>
                      </div>
                    ) : (
                      <Input name="LinkedInUrl" value={dataDoctor.LinkedInUrl || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Video Profile</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.VideoProfileUrl || 'None'}</span>
                      </div>
                    ) : (
                      <Input name="VideoProfileUrl" value={dataDoctor.VideoProfileUrl || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Specialty & Accreditations</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Specialty</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedSpecialties)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Specialty"
                        multiple
                        search
                        selection
                        name="Specialties"
                        options={DoctorDetailsStore.dataSpecialties}
                        value={toJS(dataDoctor.Specialties) || []}
                        onChange={this.setSpecialties}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Qualifications</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(dataDoctor.Qualifications)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Qualifications"
                        multiple
                        search
                        selection
                        name="Qualifications"
                        options={DoctorDetailsStore.dataQualifications}
                        value={toJS(dataDoctor.Qualifications) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Sub-Specialty</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedSubSpecialties)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Sub-Specialty"
                        multiple
                        search
                        selection
                        name="SubSpecialties"
                        options={DoctorDetailsStore.dataSubSpecialties}
                        value={toJS(dataDoctor.SubSpecialties) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>

                  <Form.Field>
                    <label className="Form-Field-label">Accreditations</label>
                    {!isEditing ? (
                      <div>{this.renderLabel(selectedAccreditations)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Accreditations"
                        multiple
                        search
                        selection
                        name="AccreditationList"
                        options={DoctorDetailsStore.dataAccreditations}
                        value={toJS(dataDoctor.AccreditationList) || []}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">AHPRA</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.APRHARegistration || ''}</span>
                      </div>
                    ) : (
                      <Input
                        name="APRHARegistration"
                        value={dataDoctor.APRHARegistration || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">SIRA</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SIRA || ''}</span>
                      </div>
                    ) : (
                      <Input name="SIRA" value={dataDoctor.SIRA || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          <th className="cursor-default">Accreditation</th>
                          <th className="cursor-default">Effective date</th>
                          <th className="cursor-default">Expiry date</th>
                          <th className="cursor-default">Attachment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(DoctorDetailsStore.arrAccreditations) &&
                          toJS(DoctorDetailsStore.arrAccreditations).map(item => {
                            return (
                              <tr key={'tr-accitem' + item.AccId}>
                                <td>{item.Name}</td>
                                <td>
                                  {!isEditing ? (
                                    <div>
                                      <span className="Label-item">
                                        {item.EffectiveDate?.format('DD/MM/YYYY') || 'None'}
                                      </span>
                                    </div>
                                  ) : (
                                    <DatePicker
                                      value={item.EffectiveDate}
                                      format={dateFormat}
                                      name="EffectiveDate"
                                      onChange={this.handleChangeAccDatePicker(item.AccId, 'EffectiveDate')}
                                    />
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    <div>
                                      <span className="Label-item">
                                        {item.ExpriryDate?.format('DD/MM/YYYY') || 'None'}
                                      </span>
                                    </div>
                                  ) : (
                                    <DatePicker
                                      value={item.ExpriryDate}
                                      format={dateFormat}
                                      name="ExpriryDate"
                                      onChange={this.handleChangeAccDatePicker(item.AccId, 'ExpriryDate')}
                                    />
                                  )}
                                </td>
                                <td>
                                  {!isEditing ? (
                                    !item.Attachments || !item.Attachments.length ? (
                                      <NoFile />
                                    ) : (
                                      <div>
                                        {item.Attachments &&
                                          item.Attachments.map(i => (
                                            <div className="mb-1" key={i.PhysicalFileName}>
                                              <File
                                                name={i.FileDisplayName}
                                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                                              />
                                            </div>
                                          ))}
                                      </div>
                                    )
                                  ) : (
                                    <React.Fragment>
                                      <div className="mb-1">
                                        <Upload
                                          multiple
                                          placeholder="Upload accreditations"
                                          onComplete={this.uploadAccreditationAttachmentComplete(item.AccId)}
                                        />
                                      </div>
                                      {item.Attachments &&
                                        item.Attachments.map((i, idx) => (
                                          <div className="mb-1" key={i.PhysicalFileName}>
                                            <File
                                              name={i.FileDisplayName}
                                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                                              onRemove={() => this.removeAccreditationAttachment(item.AccId, idx)}
                                            />
                                          </div>
                                        ))}
                                    </React.Fragment>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Form.Field>
                  <Form.Field>&nbsp;</Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Additional Info</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager First Name</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryFirstName || 'None'}</span>
                      </div>
                    ) : (
                      <Input
                        name="SecretaryFirstName"
                        value={dataDoctor.SecretaryFirstName || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Surname</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretarySurname || 'None'}</span>
                      </div>
                    ) : (
                      <Input
                        name="SecretarySurname"
                        value={dataDoctor.SecretarySurname || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Email</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryEmail || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="SecretaryEmail"
                          value={dataDoctor.SecretaryEmail || ''}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.SecretaryEmail) && '-error'}`,
                          )}
                          onChange={setFieldValue}
                        />
                        {this.validateEmail(dataDoctor.SecretaryEmail) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.SecretaryEmail)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Secretary/Practice Manager Phone</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.SecretaryTelephone || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="SecretaryTelephone"
                          value={dataDoctor.SecretaryTelephone || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validatePhone(dataDoctor.SecretaryTelephone) && '-error'}`,
                          )}
                        />
                        {this.validatePhone(dataDoctor.SecretaryTelephone) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validatePhone(dataDoctor.SecretaryTelephone)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Fax</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.Fax || 'None'}</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="Fax"
                          value={dataDoctor.Fax || ''}
                          onChange={setFieldValue}
                          className={cx(`input-doctor-details-edit${this.validatePhone(dataDoctor.Fax) && '-error'}`)}
                        />
                        {this.validatePhone(dataDoctor.Fax) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validatePhone(dataDoctor.Fax)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Contacts for Receiving Booking Information</label>
                    <Checkbox
                      label="Business email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToEmail2"
                      checked={dataDoctor.IsSendBookingConfToEmail2}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Personal email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToEmail"
                      checked={dataDoctor.IsSendBookingConfToEmail}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Secretary/Practice Manager Email"
                      className="Form-field-radio"
                      name="IsSendBookingConfToSecretaryEmail"
                      checked={dataDoctor.IsSendBookingConfToSecretaryEmail}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Promotion/mass email</label>
                    <Checkbox
                      label="Business email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail1"
                      checked={dataDoctor.IsSendPromotionMassEmail1}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Personal email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail2"
                      checked={dataDoctor.IsSendPromotionMassEmail2}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                    <Checkbox
                      label="Secretary/Practice Manager Email"
                      className="Form-field-radio"
                      name="IsSendPromotionMassEmail3"
                      checked={dataDoctor.IsSendPromotionMassEmail3}
                      disabled={!isEditing}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Video Assessment Status</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Video Assessments</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={1}
                      checked={dataDoctor.TeleAssessment === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={2}
                      checked={dataDoctor.TeleAssessment === 2}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="TeleAssessment"
                      value={0}
                      checked={dataDoctor.TeleAssessment === 0}
                      onChange={setFieldValue}
                    />
                    <Link
                      to="#"
                      className="Form-Field-Link"
                      onClick={() => window.open('https://scopevideo.com.au/', '_blank')}
                    >
                      (More on Scope Video)
                    </Link>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Medical Negligence Status</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Medical Negligence</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={1}
                      checked={dataDoctor.MedNegStatus === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={4}
                      checked={dataDoctor.MedNegStatus === 4}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="MedNegStatus"
                      value={0}
                      checked={dataDoctor.MedNegStatus === 0}
                      onChange={setFieldValue}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label className="Form-Field-label">Accept Free Preliminary Opinion Request</label>
                    <Radio
                      label="Yes"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={1}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 1}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="No"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={2}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 2}
                      onChange={setFieldValue}
                    />
                    <Radio
                      label="Unsure"
                      className="Form-field-radio"
                      disabled={!isEditing || dataDoctor.MedNegStatus !== 1}
                      name="FreePreliminaryOpinionRequest"
                      value={0}
                      checked={dataDoctor.FreePreliminaryOpinionRequest === 0}
                      onChange={setFieldValue}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">iCare Matter Status</label>
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label className="Form-Field-label">Accept iCare Matters</label>
                  <Checkbox
                    label="AcceptICareMatter"
                    className="Form-field-new-ui-checkbox"
                    disabled={!isEditing}
                    name="AcceptICareMatter"
                    checked={dataDoctor.AcceptICareMatter}
                    onChange={toggleCheckbox}
                  />
                </Form.Field>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Documents</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">CV</label>
                    {!isEditing ? (
                      dataDoctor.CV === null ? (
                        <NoFile />
                      ) : (
                        <File
                          name={dataDoctor.CVName + ' - Uploaded: ' + this.formatDisplayDate(dataDoctor.CVUploaded)}
                          downloadLink={`/UserFile/DoctorCV/${dataDoctor.CV}`}
                        />
                      )
                    ) : dataDoctor.CV === null ? (
                      <Upload onComplete={this.uploadCV} placeholder="Upload CV" />
                    ) : (
                      <File
                        name={dataDoctor.CVUploaded ? dataDoctor.CVName + ' - Uploaded: ' + this.formatDisplayDate(dataDoctor.CVUploaded) : dataDoctor.CVName}
                        downloadLink={`/UserFile/DoctorCV/${dataDoctor.CV}`}
                        onRemove={this.removeCV}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">AHPRA and Accreditations</label>
                    {!isEditing ? (
                      !DoctorDetailsStore.aHRPAAccreditationsAttachments.length ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.aHRPAAccreditationsAttachments.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName + ' - Uploaded: ' + this.formatDisplayDate(i.UploadedDate)}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            placeholder="Upload accreditations"
                            onComplete={this.uploadAHRPAAccreditation}
                          />
                        </div>
                        {DoctorDetailsStore.aHRPAAccreditationsAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeAHRPAAccreditation(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Required Consent Form</label>
                    {!isEditing ? (
                      DoctorDetailsStore.attRequiredConsentForm.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.attRequiredConsentForm.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName + ' - Uploaded: ' + this.formatDisplayDate(i.UploadedDate)}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            onChange={this.uploadRequiredConsentForm}
                            placeholder="Upload required consent form"
                          />
                        </div>
                        {DoctorDetailsStore.attRequiredConsentForm.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeRequiredConsentFormt(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Signature</label>
                    {!isEditing ? (
                      DoctorDetailsStore.signatures.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.signatures.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName + ' - Uploaded: ' + this.formatDisplayDate(i.UploadedDate)}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple onComplete={this.uploadSignatures} placeholder="Upload signature" />
                        </div>
                        {DoctorDetailsStore.signatures.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeSignatures(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                {/* <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Report Templates</label>
                    {!isEditing ? (
                      dataDoctor.ReportTemplatesAttachments.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {dataDoctor.ReportTemplatesAttachments.map(i => (
                            <div className="mb-1" key={i.Id ? i.FileName : i.PhysicalFileName}>
                              <File
                                name={
                                  (i.Id ? i.Title : i.FileDisplayName) +
                                  ' - Uploaded: ' +
                                  this.formatDisplayDate1(i.CreateDate)
                                }
                                downloadLink={`
                                /UserFile/DoctorReportTemplates/${i.Id ? i.FileName : i.PhysicalFileName}
                              `}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload
                            multiple
                            placeholder="Upload report templates"
                            onComplete={this.uploadReportTemplate}
                          />
                        </div>
                        {dataDoctor.ReportTemplatesAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.Id ? i.FileName : i.PhysicalFileName}>
                            <File
                              name={i.Id ? i.Title : i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorReportTemplates/${i.Id ? i.FileName : i.PhysicalFileName}`}
                              onRemove={() => this.removeReportTemplate(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Report Samples</label>
                    {!isEditing ? (
                      DoctorDetailsStore.sampleReports.length === 0 ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.sampleReports.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName + ' - Uploaded: ' + this.formatDisplayDate(i.UploadedDate)}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple onChange={this.uploadSampleReport} placeholder="Upload sample reports" />
                        </div>
                        {DoctorDetailsStore.sampleReports.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeSampleReport(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group> */}
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Letter head</label>
                    {!isEditing ? (
                      !DoctorDetailsStore.letterHeadAttachments.length ? (
                        <NoFile />
                      ) : (
                        <div>
                          {DoctorDetailsStore.letterHeadAttachments.map(i => (
                            <div className="mb-1" key={i.PhysicalFileName}>
                              <File
                                name={i.FileDisplayName + ' - Uploaded: ' + this.formatDisplayDate(i.UploadedDate)}
                                downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="mb-1">
                          <Upload multiple placeholder="Upload letterhead" onComplete={this.uploadLetterHead} />
                        </div>
                        {DoctorDetailsStore.letterHeadAttachments.map((i, idx) => (
                          <div className="mb-1" key={i.PhysicalFileName}>
                            <File
                              name={i.FileDisplayName}
                              downloadLink={`/UserFile/DoctorAttachment/${i.PhysicalFileName}`}
                              onRemove={() => this.removeLetterHead(idx)}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field> </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Subscriptions & Waiting List</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Promotion Campaigns</label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToPromotionCampaign"
                      checked={dataDoctor.SubscribeToPromotionCampaign}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      View other Specialists' Sessions in Same Centre
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="To allow this specialist to view sessions of others in the same medical centre without identifying details"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="ViewOtherDoctorSession"
                      checked={dataDoctor.ViewOtherDoctorSession}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Appointment Daysheet Updates
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Mail-06 or Mail-07 will be sent if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentUpdateReminders"
                      checked={dataDoctor.SubscribeToAppointmentUpdateReminders}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Availability Update Reminders
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Mail-20 will be sent if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToClinicAvailabilityReminders"
                      checked={dataDoctor.SubscribeToClinicAvailabilityReminders}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Updating Patient Attendance Reminders
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Mail-09 or Mail-09A will be sent if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceReminders"
                      checked={dataDoctor.SubscribeToAttendanceReminders}
                      onChange={this.handleAttendanceReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceRemindersViaEmail"
                      checked={dataDoctor.SubscribeToAttendanceRemindersViaEmail}
                      onChange={this.handleAttendanceReminders}
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAttendanceRemindersViaSMS"
                      checked={dataDoctor.SubscribeToAttendanceRemindersViaSMS}
                      onChange={this.handleAttendanceReminders}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Submitting Dictations and Reports Reminders
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Mail-12 or Mail-12A or Mail-12B will be sent if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportReminders"
                      checked={dataDoctor.SubscribeToDictationAndReportReminders}
                      onChange={this.handleDictationAndReportReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportRemindersViaEmail"
                      checked={dataDoctor.SubscribeToDictationAndReportRemindersViaEmail}
                      onChange={this.handleDictationAndReportReminders}
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToDictationAndReportRemindersViaSMS"
                      checked={dataDoctor.SubscribeToDictationAndReportRemindersViaSMS}
                      onChange={this.handleDictationAndReportReminders}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Appointment Confirmation
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="MAIL-78 for in-person, MAIL-40 for video assessment will be sent if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      className="Form-field-new-ui-checkbox"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentConfirmation"
                      checked={dataDoctor.SubscribeToAppointmentConfirmation}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Available for Service Sharing
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Allow this specialist to be shared with other MLPs"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      disabled={!isEditing}
                      label="Yes"
                      name="AvailableForServiceSharing"
                      checked={dataDoctor.AvailableForServiceSharing}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleAvailableForServiceSharing}
                    />
                    {dataDoctor.AvailableForServiceSharingDate && (
                      <span>{DoctorDetailsStore.formatResDate(dataDoctor.AvailableForServiceSharingDate)}</span>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Appointment Reminders
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        //content="MAIL-39, MAIL-40, MAIL-78, MAIL-86, MAIL-87, MAIL-88 will be sent to specialists if subscribed"
                        content="Appointment reminders (MAIL-38 for video) will be sent to specialists if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentReminders"
                      checked={dataDoctor.SubscribeToAppointmentReminders}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleAppointmentReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      name="SubscribeToAppointmentRemindersViaEmail"
                      checked={dataDoctor.SubscribeToAppointmentRemindersViaEmail}
                      onChange={this.handleAppointmentReminders}
                      className="Form-field-new-ui-checkbox"
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToAppointmentRemindersViaSMS"
                      checked={dataDoctor.SubscribeToAppointmentRemindersViaSMS}
                      onChange={this.handleAppointmentReminders}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Appointment Cancellation Notices
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="MAIL-81 will be sent to specialists if subscribed"
                        position="top center"
                      />
                    </label>
                    <Checkbox
                      label="Subscribed"
                      disabled={!isEditing}
                      name="SubscribeToCancellationReminders"
                      checked={dataDoctor.SubscribeToCancellationReminders}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleCancellationReminders}
                    />
                    <Checkbox
                      label="Via Email"
                      disabled={!isEditing}
                      name="SubscribeToCancellationRemindersViaEmail"
                      checked={dataDoctor.SubscribeToCancellationRemindersViaEmail}
                      onChange={this.handleCancellationReminders}
                      className="Form-field-new-ui-checkbox"
                    />
                    <Checkbox
                      label="Via SMS"
                      disabled={!isEditing}
                      className="Form-field-new-ui-checkbox"
                      name="SubscribeToCancellationRemindersViaSMS"
                      checked={dataDoctor.SubscribeToCancellationRemindersViaSMS}
                      onChange={this.handleCancellationReminders}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label className="Form-Field-label">
                    <Fragment>
                      Send Onboarding Notice to UHG
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Onboarding information will be sent to UHG (MAIL-153)"
                        position="top center"
                      />
                      <Button color="blue" onClick={() => this.onSendUpdateOnboardingNoticetoUHG()}>
                        Send Updates
                      </Button>
                    </Fragment>
                  </label>
                  <Checkbox
                    disabled={!isEditing || !dataDoctor.AvailableForServiceSharing}
                    label="Yes"
                    name="SendOnboardingNoticeToUHG"
                    checked={dataDoctor.SendOnboardingNoticeToUHG}
                    className="Form-field-new-ui-checkbox"
                    onChange={this.handleSendOnboardingNoticeToUHG}
                  />
                  {dataDoctor.SendOnboardingNoticeToUHGDate && (
                    <span>{DoctorDetailsStore.formatResDate(dataDoctor.SendOnboardingNoticeToUHGDate)}</span>
                  )}
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Enable Reminder Templates</label>
                    <Checkbox
                      disabled={!isEditing}
                      label="Mail-172"
                      name="EnabledMail172"
                      checked={dataDoctor.EnabledMail172}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleChangeCheckboxField}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-18px' }} />}
                      content="New Availabilities added"
                      position="top center"
                    />{' '}
                    {!!dataDoctor.Id && (
                      <button
                        className="field-actions"
                        onClick={() => this.handleFieldRecentChange(dataDoctor.Id, 'EnabledMail172')}
                      >
                        Recent Changes
                      </button>
                    )}
                    <br />
                    <Checkbox
                      disabled={!isEditing}
                      label="Mail-173"
                      name="EnabledMail173"
                      checked={dataDoctor.EnabledMail173}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleChangeCheckboxField}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-18px' }} />}
                      content="IME Cancelled"
                      position="top center"
                    />{' '}
                    {!!dataDoctor.Id && (
                      <button
                        className="field-actions"
                        onClick={() => this.handleFieldRecentChange(dataDoctor.Id, 'EnabledMail173')}
                      >
                        Recent Changes
                      </button>
                    )}
                    <br />
                    <Checkbox
                      disabled={!isEditing}
                      label="Mail-174"
                      name="EnabledMail174"
                      checked={dataDoctor.EnabledMail174}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleChangeCheckboxField}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-18px' }} />}
                      content="Upcoming Available Session(s)"
                      position="top center"
                    />{' '}
                    {!!dataDoctor.Id && (
                      <button
                        className="field-actions"
                        onClick={() => this.handleFieldRecentChange(dataDoctor.Id, 'EnabledMail174')}
                      >
                        Recent Changes
                      </button>
                    )}
                    <br />
                    <Checkbox
                      disabled={!isEditing}
                      label="Mail-175"
                      name="EnabledMail175"
                      checked={dataDoctor.EnabledMail175}
                      className="Form-field-new-ui-checkbox"
                      onChange={this.handleChangeCheckboxField}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-18px' }} />}
                      content=" Fully Booked over the next 4 weeks"
                      position="top center"
                    />
                    {!!dataDoctor.Id && (
                      <button
                        className="field-actions"
                        onClick={() => this.handleFieldRecentChange(dataDoctor.Id, 'EnabledMail175')}
                      >
                        Recent Changes
                      </button>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Blocked Clients
                      <Popup
                        trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                        content="Blocked clients are not allowed to book this specialist"
                        position="top center"
                      />
                    </label>
                    {!isEditing ? (
                      <div>{this.renderLabelBlockedClients(blockedClients)}</div>
                    ) : (
                      <Dropdown
                        placeholder="Select clients"
                        multiple
                        search
                        selection
                        name="blockedClients"
                        options={DoctorDetailsStore.dataBlockedClients}
                        value={
                          blockedClients?.ClientIds
                            ? toJS(blockedClients?.ClientIds)
                                .split(';')
                                .map(i => +i)
                            : null
                        }
                        onChange={this.handleChangeBlockedClients}
                        onSearchChange={(_, { searchQuery }) => DoctorDetailsStore.fetchAllClients(searchQuery)}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="Form-Field-label">
                        Waiting List Notifications 1
                        <Popup
                          trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                          content="When this specialist's appointments become available, a notification will be sent to waiting CMs (MAIL-147)"
                          position="top center"
                        />
                      </label>
                      {!isEditing ? (
                        <div>{this.renderLabelCMS(CMsWaitingNotification1)}</div>
                      ) : (
                        <Dropdown
                          placeholder="Select a Case Manager"
                          multiple
                          search
                          selection
                          name="CMsWaitingNotification1"
                          options={DoctorDetailsStore.dataWaitingNotifications1}
                          value={
                            CMsWaitingNotification1?.CMIds
                              ? toJS(CMsWaitingNotification1?.CMIds)
                                  .split(';')
                                  .map(i => +i)
                              : null
                          }
                          onChange={this.handleChangeCmNotification}
                          onSearchChange={(_, { searchQuery }) => DoctorDetailsStore.fetchAllCaseManager(searchQuery)}
                        />
                      )}
                    </Form.Field>
                  </Form.Group>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="Form-Field-label">Appointment Start Date</label>
                      {!isEditing ? (
                        <div>
                          <span className="Label-item">{renderDate(CMsWaitingNotification1StartDate, false)}</span>
                        </div>
                      ) : (
                        <DatePicker
                          value={renderDate(CMsWaitingNotification1StartDate)}
                          format={dateFormat}
                          name="CMsWaitingNotification1StartDate"
                          onChange={this.handleChangeDatePicker('CMsWaitingNotification1StartDate')}
                        />
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label className="Form-Field-label">Appointment End Date</label>
                      {!isEditing ? (
                        <div>
                          <span className="Label-item">{renderDate(CMsWaitingNotification1EndDate, false)}</span>
                        </div>
                      ) : (
                        <DatePicker
                          value={renderDate(CMsWaitingNotification1EndDate)}
                          format={dateFormat}
                          name="CMsWaitingNotification1EndDate"
                          onChange={this.handleChangeDatePicker('CMsWaitingNotification1EndDate')}
                        />
                      )}
                    </Form.Field>
                  </Form.Group>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="Form-Field-label">
                        Waiting List Notifications 2
                        <Popup
                          trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                          content="When this specialist's appointments become available, a notification will be sent to waiting CMs (MAIL-147)"
                          position="top center"
                        />
                      </label>
                      {!isEditing ? (
                        <div>{this.renderLabelCMS(CMsWaitingNotification2)}</div>
                      ) : (
                        <Dropdown
                          placeholder="Select a Case Manager"
                          multiple
                          search
                          selection
                          name="CMsWaitingNotification2"
                          options={DoctorDetailsStore.dataWaitingNotifications2}
                          value={
                            CMsWaitingNotification2?.CMIds
                              ? toJS(CMsWaitingNotification2?.CMIds)
                                  .split(';')
                                  .map(i => +i)
                              : null
                          }
                          onChange={this.handleChangeCmNotification}
                          onSearchChange={(_, { searchQuery }) => DoctorDetailsStore.fetchAllCaseManager(searchQuery)}
                        />
                      )}
                    </Form.Field>
                  </Form.Group>
                )}
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="Form-Field-label">Appointment Start Date</label>
                      {!isEditing ? (
                        <div>
                          <span className="Label-item">{renderDate(CMsWaitingNotification2StartDate, false)}</span>
                        </div>
                      ) : (
                        <DatePicker
                          value={renderDate(CMsWaitingNotification2StartDate)}
                          format={dateFormat}
                          name="CMsWaitingNotification2StartDate"
                          onChange={this.handleChangeDatePicker('CMsWaitingNotification2StartDate')}
                        />
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label className="Form-Field-label">Appointment End Date</label>
                      {!isEditing ? (
                        <div>
                          <span className="Label-item">{renderDate(CMsWaitingNotification2EndDate, false)}</span>
                        </div>
                      ) : (
                        <DatePicker
                          value={renderDate(CMsWaitingNotification2EndDate)}
                          format={dateFormat}
                          name="CMsWaitingNotification2EndDate"
                          onChange={this.handleChangeDatePicker('CMsWaitingNotification2EndDate')}
                        />
                      )}
                    </Form.Field>
                  </Form.Group>
                )}
              </Form>
            </Segment>
            <Segment>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Login and Availability</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Login Information</label>
                    <Checkbox
                      label={labelEnableLogin}
                      //className="Form-field-radio"
                      disabled={!isEditing}
                      name="EnableLogin"
                      checked={dataDoctor.EnableLogin}
                      onChange={toggleCheckbox}
                    />
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                      content="When enabling login, an activation email will be sent to the user's business email (MAIL-26)"
                      position="top center"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Username</label>
                    <div>
                      <span className="Label-item">
                        {dataDoctor.EnableLogin
                          ? dataDoctor.Username
                            ? dataDoctor.Username
                            : dataDoctor.Email2 || 'None'
                          : 'None'}
                      </span>
                    </div>
                  </Form.Field>
                </Form.Group>
                {dataDoctor.Id !== 0 && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Button
                        disabled={!userId}
                        color="blue"
                        onClick={!dataDoctor.EnableLogin ? this.notifyEnable : this.handleEditUser}
                      >
                        Edit Username
                      </Button>
                      <Button
                        disabled={!userId}
                        color="blue"
                        onClick={!dataDoctor.EnableLogin ? this.notifyEnable : this.handleUnlockUser}
                      >
                        Unlock User
                      </Button>
                      <Button
                        disabled={!userId}
                        color="blue"
                        onClick={
                          !dataDoctor.EnableLogin
                            ? this.notifyEnable
                            : DoctorDetailsStore.toggleModal(true, {
                                modalType: 'confirm',
                                message: `Do you want to reset password for user ${dataDoctor.FirstName} ${dataDoctor.LastName}? New password will be sent to the user automatically.`,
                                onOk: this.handleResetPassword,
                              })
                        }
                      >
                        Reset Password
                      </Button>
                      <Button
                        disabled={!userId}
                        color="blue"
                        onClick={!dataDoctor.EnableLogin ? this.notifyEnable : this.handleResendLoginInformation}
                      >
                        Resend Login Information
                        <Popup
                          trigger={<Icon name="info circle" style={{ marginLeft: '3px' }} />}
                          content="Login information will be resent to this account's business email (MAIL-117)"
                          position="top center"
                        />
                      </Button>
                      {/* {dataDoctor.Username && (
                        <Button
                          disabled={!userId}
                          color="blue"
                          onClick={() =>
                            DoctorDetailsStore.toggleModal(true, {
                              modalType: 'passwordRsHistory',
                            })()
                          }
                        >
                          Password Reset History
                        </Button>
                      )} */}
                      <Button
                        disabled={!dataDoctor.EnableLogin}
                        color="blue"
                        onClick={() =>
                          DoctorDetailsStore.toggleModal(true, {
                            modalType: 'userUpdateHistory',
                          })()
                        }
                      >
                        Update History
                      </Button>
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group>
                  <Form.Field>
                    <Checkbox
                      label="Online Booking Available to CMs"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="AvailableForCMView"
                      checked={dataDoctor.AvailableForCMView}
                      onChange={toggleCheckbox}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    {/* <label className="Form-Field-label">
                      Not Currently Interested (NCI)
                    </label> */}
                    <Checkbox
                      label="Not Currently Interested (NCI)"
                      className="Form-field-radio"
                      disabled={!isEditing}
                      name="IsNotCurrentlyInterested"
                      checked={dataDoctor.IsNotCurrentlyInterested}
                      onChange={toggleCheckbox}
                      style={{ marginRight: '0px !important' }}
                    />
                    {'  '}
                    <Popup
                      trigger={<Icon name="info circle" style={{ marginLeft: '-30px' }} />}
                      content="Specialists marked as NCI will not be contacted or listed for bookings"
                      position="top center"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">NCI Start Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="NotCurrentlyInterestedDate"
                        dateFormat="DD/MM/YYYY"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.NotCurrentlyInterestedDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">NCI End Date</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedEndDate || 'None'}</span>
                      </div>
                    ) : (
                      <DateInput
                        name="NotCurrentlyInterestedEndDate"
                        dateFormat="DD/MM/YYYY"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.NotCurrentlyInterestedEndDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">NCI Comment</label>
                    {!isEditing ? (
                      <div>
                        <span className="Label-item">{dataDoctor.NotCurrentlyInterestedComment || 'None'}</span>
                      </div>
                    ) : (
                      <TextArea name="NotCurrentlyInterestedComment" maxLength="2000" />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default GeneralInformationTab;
