import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import moment from 'moment';

import Modal from '../../../shared/Modal/';
import store from '../store';
import * as api from '@stores/api';
import DragDropFile from '../../Shared/DragDropComponent';
import { ReplaceFileNameUpload } from '@utils/functions';
import { renderDate } from '../../Shared';

@observer
class UploadComponent extends React.Component {
  handleRemoveFile = id => {
    store.handleRemoveFile(id);
  };

  @action handleDraftAttachmentUploadComplete = () => {
    const itemModel = toJS(store.fileReviewInfo);
    const specialist = toJS(store.specialist);
    store.fileReviewInfo = {
      ...itemModel,
      ReportDraftReceivedDate: moment(),
    };

    if (api.isAdminOrMagStaffOrAccounting()) {
      const itemModel_ = toJS(store.fileReviewInfo);
      store.fileReviewInfo = {
        ...itemModel_,
        ReportDraftSentDate: moment(),
        ReportDraftReceived: true,
      };

      if (!itemModel.EmailReportDraftToDoctorBE || !itemModel.EmailReportDraftToDoctor) {
        store.toggleModal(true, {
          modalType: true,
          message: 'Do you want to attach report draft to notification email to provider?',
          onOk: () =>
            action(() => {
              store.fileReviewInfo = {
                ...toJS(store.fileReviewInfo),
                EmailReportDraftToDoctorBE: true,
                EmailReportDraftToDoctor: true,
              };
            })(),
        });
      }
    }
  };

  @action handleUpload = (fileList, reportType) => {
    store.fileReviewInfo = {
      ...toJS(store.fileReviewInfo),
    };

    const { functionCallback } = store.modalUploadParams;

    const attachments = store.fileReviewInfo?.FileReviewAttachment
      ? toJS(store.fileReviewInfo.FileReviewAttachment)
      : [];
    const dictationFiles = store.fileReviewInfo?.FileReviewAttachment
      ? toJS(store.fileReviewInfo.FileReviewAttachment).filter(i => i.Type === 2)
      : [];
    const attachment = fileList.map(i => ({
      FileName: i.name,
      Id: 0,
      NumberOfPages: i.numberOfPages,
      ReportType: reportType,
      Title:
        reportType === 2
          ? ReplaceFileNameUpload(
              'Supplementary',
              store.fileReviewInfo.FileReviewNumber,
              store.selectedPatient?.FullName,
              store.selectedDoctor?.Title,
              store.selectedDoctor?.FullName,
              renderDate(store.fileReviewInfo.RequestDate, false) || '',
              false,
              '',
              !!dictationFiles.length ? dictationFiles.length + 1 : 1,
            )
          : i.title,
      CreatedDate: moment(),
    }));
    const newAttachments = [...attachment, ...attachments].map((i, idx) => ({
      ...i,
      key: idx,
    }));
    store.fileReviewInfo = {
      ...toJS(store.fileReviewInfo),
      FileReviewAttachment: newAttachments,
    };

    if (this.props.functionCallback) {
      store.toggleModalModalUpload(false);
      this.props.functionCallback();
    }
    if (functionCallback) {
      store.toggleModalModalUpload(false);
      functionCallback(fileList, reportType);
    }
    return;
  };

  render() {
    const { isOpenModalUpload, modalUploadParams } = store;
    const {
      reportType,
      attachments,
      option,
      multiple,
      disabled,
      disabledDelete,
      uploadTitle,
      disableDragDrop,
    } = modalUploadParams;

    const { FileReviewAttachment } = store.fileReviewInfo;

    const attachments_ = FileReviewAttachment.filter(({ ReportType }) => ReportType === reportType);

    const title =
      attachments_ && !!attachments_.length
        ? `${attachments_.length} ${attachments_.length > 1 ? 'attachments' : 'attachment'} ${
            attachments_.length > 1 ? 'are' : 'is'
          } found`
        : disabled
        ? 'No files found'
        : `Upload your attachment`;

    const rule = option || null;
    return (
      <Modal
        visible={isOpenModalUpload}
        width={600}
        onCancel={() => store.toggleModalModalUpload(false, {})}
        className="custom-modal"
        footer={
          <React.Fragment>
            <Button color="blue" onClick={() => store.toggleModalModalUpload(false, {})}>
              OK
            </Button>
          </React.Fragment>
        }
        title={title}
      >
        <DragDropFile
          onUploadFile={this.handleUpload}
          onDeleteFile={this.handleRemoveFile}
          type={reportType}
          accept={rule ? rule.allowedExtensions : null}
          maxSize={rule ? parseInt(rule.maxFileSize.replace('MB')) : 50}
          multiple={multiple}
          attachments={attachments}
          disabled={disabled}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          disableDragDrop={disableDragDrop}
          renderAttachment={store.renderAttachment}
          toggleModalConfirm={store.toggleModalConfirm}
          toggleModalModalUpload={store.toggleModalModalUpload}
          Attachments={store.fileReviewInfo?.FileReviewAttachment}
          baseUrl="/UserFile/FileReviewAttachment"
        />
      </Modal>
    );
  }
}
export default UploadComponent;
