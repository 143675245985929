import './style.scss';
import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { getEmbedReportSetting } from './reportService';
import { notification } from 'antd';

const PowerBIReport = ({ reportId, reportName }) => {
  const [reportConfig, setReportConfig] = useState(null);

  useEffect(() => {
    getEmbedReportSetting(reportId)
      .then(resp => {
        setReportConfig(resp);
      })
      .catch(error => {
        notification.error({
          message: 'Error',
          description: error,
        });
      });
  }, [reportId]);

  if (!reportConfig) {
    return null;
  }

  return (
    <div className="report-container">
      <PowerBIEmbed embedConfig={reportConfig} cssClassName={'report-style-class'} />
    </div>
  );
};

export default PowerBIReport;
