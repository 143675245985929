import { action, observable, computed } from 'mobx';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';

class OtherSalesCallReportStore {
  @observable loading = true;
  @observable data = null;
  @observable loadingStaff = true;
  @observable dataStaff = [];
  @observable loadingClient = true;
  @observable dataClient = null;
  @observable loadingRecruitment = true;
  @observable dataRecruitment = null;

  @observable id = 0;

  @observable filter = 'All';
  @observable sortDirection = 'descending';
  @observable keywordSearch = '';
  @observable numberSearch = 30;
  @observable sortColumn = 'Case Manager';
  @observable sortKey = 'CallDate';
  @observable totalItem = 0;
  @observable dateRange = '';
  @observable doctorName = '';
  @observable doctorEmail = '';
  @observable cmEmail = '';
  @observable cmName = '';
  @observable staffId = '';
  @observable totalItem = 0;
  @observable totalPage = 0;
  @observable callById = '';

  @observable curPage = 0;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @computed get searchValue() {
    const { id, dataStaff } = this;
    return dataStaff.find(i => i.Id === id);
  }

  getDateRange = () => {
    const [fromDate, toDate] = this.dateRange.split(' - ');
    return {
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
  };

  @action fetchSearch = () => {
    this.loadingStaff = true;
    return customFetch('/MedicalDoctor/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordSearch,
        CurPage: 1,
        NumItemPerPage: this.numberPageSearch,
        Showing: this.filterStatus,
        IsSelectionList: true,
      }),
    }).then(
      action(data => {
        this.dataStaff = data.itemList;
        this.loadingStaff = false;
      }),
    );
  };

  @action refetchSearch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearch();
  };

  @action fetchReport = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    return customFetch(
      `/MedicalDoctor/OtherSalesCall_LoadList?sortBy=${this.sortKey}&sortDirection=${this.sortDirection
        .replace('ending', '')
        .toUpperCase()}&fromDate=${from}&toDate=${to}&doctorEmail=${this.searchValue?.Email2 || ''}&doctorName=${this
        .searchValue?.FullName || ''}&staffId=${this.staffId}&callById=${this.callById}&curPage=${
        this.curPage
      }&numItemPerPage=${this.pageSize}`,
    ).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  @action fetchClient = () => {
    this.loadingClient = true;
    customFetch('/MedicalDoctor/GetStaffSalesCall?type=1').then(
      action(data => {
        this.loadingClient = false;
        this.dataClient = data;
      }),
    );
  };

  @action fetchRecruitment = () => {
    this.loadingRecruitment = true;
    customFetch('/MedicalDoctor/GetStaffSalesCall?type=3').then(
      action(data => {
        this.loadingRecruitment = false;
        this.dataRecruitment = data;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchReport(true);
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchReport(setTotalPage);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.id = 0;

    this.filter = 'All';
    this.sortDirection = 'descending';
    this.keywordSearch = '';
    this.numberSearch = 30;
    this.sortColumn = 'Case Manager';
    this.sortKey = 'CallDate';
    this.totalItem = 0;
    this.dateRange = '';
    this.doctorName = '';
    this.doctorEmail = '';
    this.cmEmail = '';
    this.cmName = '';
    this.staffId = '';
    this.totalItem = 0;
    this.totalPage = 0;
    this.callById = '';

    this.curPage = 0;
    this.pageSize = 20;
    this.totalPage = 0;

    this.open = false;
    this.modalParams = {};
    this.fetchReport(true);
  };
}

export default new OtherSalesCallReportStore();
