import { Spin } from 'antd';
import cx from 'classnames';
import React from 'react';

const DoctorInfoPopover = ({
  isLoading,
  position,
  classNames,
  doctorTitle,
  doctorName,
  doctorBriefProfile,
  doctorConsultingLocations,
  doctorServiceExclusion,
  doctorCommissionOnTop,
  doctorBookingsAndCancellationTerms,
}) => {
  return (
    <div className={cx('mag-popover-container', classNames)} style={position}>
      <h3 className="mag-popover-title">{doctorTitle + ' ' + doctorName}</h3>
      {isLoading ? (
        <Spin className="mag-popover-loading" size="large" />
      ) : (
        <section>
          <h4 className="mag-popover-subtitle">Brief Profile:</h4>
          <p className="mag-popover-content">{doctorBriefProfile || 'None'}</p>
          <h4 className="mag-popover-subtitle">Consulting Locations:</h4>
          <p className="mag-popover-content">{doctorConsultingLocations || 'None'}</p>
          {doctorBookingsAndCancellationTerms && (
            <React.Fragment>
              <h4 className="mag-popover-subtitle red">Booking and Cancellation Terms:</h4>
              <p className="mag-popover-content red">{doctorBookingsAndCancellationTerms || 'None'}</p>
            </React.Fragment>
          )}
          {doctorCommissionOnTop && <div className="mag-popover-message">Non standard fees applied</div>}
        </section>
      )}
    </div>
  );
};
export default React.memo(DoctorInfoPopover);
