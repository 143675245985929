import customFetch from '../../../utils/customFetch';

export const getAllRecords = (keyword = '', curPage = 1, numItemPerPage = 0, sortBy = '', sortDirection = '') => {
  return customFetch('/FundingReport/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getFundingReportInfo = (id = 0) => {
  return customFetch(`/FundingReport/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveData = param => {
  return customFetch('/FundingReport/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteRecord = id => {
  return customFetch('/FundingReport/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getFundingRequest = (keyword = '') => {
  return customFetch('/FundingRequest/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: 1,
      NumItemPerPage: 500,
    }),
  });
};

export const getFundedCompanies = keyword => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      ActionRequired: false,
      NumItemPerPage: 500,
      RecruitedOrContractedOnlyge: true,
      SortBy: 'Name',
      SortDirection: 'ASC',
      curPage: 1,
      keyword: keyword,
      ActionRequiredById: '',
      //IsClientFundingProvider: true,
      IsSelectionList: true,
    }),
  });
};
