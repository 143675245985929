import { Spin } from 'antd';
import cx from 'classnames';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import ActionNoteForm from '../../shared/ActionNoteForm';
import Modal from '../../shared/Modal';
import store from './ClientsStore';
import * as api from '../../../stores/api';

class ModalActionNotes extends React.Component {
  state = {
    staffs: [],
    notes: [],
    loading: true,
    toggleNewNote: false,
  };

  async componentDidMount() {
    try {
      const staffsPromise = customFetch('/Company/GetStaffs', {
        method: 'POST',
      });

      const notesPromise = customFetch('/Company/ActionRequired_LoadList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ItemId: store.modalParams.id }),
      });

      const [staffs, notes] = await Promise.all([staffsPromise, notesPromise]);
      this.setState({
        staffs: uniqBy(staffs.itemList, 'Id'),
        notes: notes.itemList,
        toggleNewNote: !notes.itemList.length,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderNoteForm = () => {
    const { staffs, notes } = this.state;
    if (!notes.length) return null;

    return notes.map(note => (
      <ActionNoteForm
        key={note.Id}
        staffs={staffs}
        note={note}
        onSubmit={this.handleSubmit}
        onDelete={this.handleDelete}
      />
    ));
  };

  handleSubmit = async note => {
    this.setState({ loading: true });
    const data = omit(note, [
      'CreatedDate',
      'CreatedUserId',
      'LastUpdatedBy',
      'LastUpdatedDate',
      'StaffFirstName',
      'StaffLastName',
    ]);

    const dueDate = moment(note.DueDate);
    data.HourListActionRequired = dueDate.hour();
    data.MinuteListActionRequired = dueDate.minute();
    data.DueDate = new Date(dueDate.format('MM-DD-YYYY')).toISOString();
    data.CompletedDate = data.Completed
      ? new Date((data.CompletedDate ? moment(data.CompletedDate) : moment()).format('MM-DD-YYYY')).toISOString()
      : null;

    data.companyId = data.CompanyId;
    delete data.CompanyId;

    try {
      if (data.Id) {
        await customFetch('/Company/ActionRequired_Update', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ model: data }),
        });
      } else {
        await customFetch('/Company/ActionRequired_Create', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            model: {
              ...data,
              companyId: store.modalParams.id,
              HourAR: [],
              MinuteAR: [],
              ActionRequiredPICStaff: {
                selected: this.state.staffs.find(staff => staff.Id === data.StaffAssignedId),
              },
            },
          }),
        });
      }

      await store.refetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  handleDelete = async id => {
    this.setState({ loading: true });

    try {
      await customFetch('/Company/ActionRequired_Disable', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      this.setState(prev => ({
        ...prev,
        notes: prev.notes.filter(note => note.Id !== id),
      }));

      await store.refetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  handleToggleNewNote = () => {
    this.setState({ toggleNewNote: !this.state.toggleNewNote });
  };

  render() {
    const { loading, staffs, toggleNewNote } = this.state;
    const { open, toggleModal, modalParams } = store;
    const title = `Action Notes: ${modalParams.modalTitle}`;
    var newNote = { DueDate: moment(), StaffAssignedId: api.currentUser.data.id };

    return (
      <Modal visible={open} title={title} footer={true}>
        {loading && !staffs.length ? (
          <Spin className="modal-spin" />
        ) : (
          <div className={cx({ 'modal-form-disabled': loading })}>
            {!api.isOnlySearch() && <Button color="blue" className="mb-1" onClick={this.handleToggleNewNote}>
              <Icon name="plus" /> New note
            </Button>}
            <Button className="negative" onClick={toggleModal(false)} style={{ float: !api.isOnlySearch() ? 'right' : '', marginBottom: !api.isOnlySearch() ? '0' : '15px'}}>
              Close
            </Button>
            {toggleNewNote && <ActionNoteForm staffs={staffs} note={newNote} onSubmit={this.handleSubmit} />}
            {this.renderNoteForm()}
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalActionNotes;
