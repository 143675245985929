import React from 'react';
import cx from 'classnames';
import { Table, Icon } from 'semantic-ui-react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from './Store';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { deleteRecord } from './Service';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: '' },
  { title: 'Request No', sortKey: 'RequestNo' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Lender' },
  { title: 'Facility Period' },
  { title: 'Request Status' },
  { title: 'Status Date' },
];

@observer
class AMAAftercareRuleTable extends React.Component {
  openModal = options => {
    return store.toggleModal(true, options);
  };
  handleDelete = id => {
    store.setFieldsValue({ loading: true });
    Promise.all([deleteRecord(id)])
      .then(([responseDelete]) => {
        if (responseDelete.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been deleted successfully.',
            duration: 3,
          });
          store.refetch({}, true);
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      });
  };
  handleEdit = (id, record) => {
    localStorage.setItem('FundingRequest', record.RequestNo + ' - Request');
    dashboardStore.close('/view/add-edit-funding-request');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-request?id=${id}`);
    });
  };
  renderTableAction = (id, record) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleEdit(id, record)}>Edit</Menu.Item>
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Deleting this rule can affect current invoices. Are you sure to continue??',
                onOk: () => this.handleDelete(id),
              })}
              //onClick={() => this.handleDelete(id)}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableAction(Id, record)}</Table.Cell>}
        {api.isAdmin() ? (
          <Table.Cell className="Cell-link Cell-nowrap" selectable onClick={() => this.handleEdit(Id, record)}>
            <Link to="#" onClick={() => this.handleEdit(Id, record)}>
              {record.RequestNo}
            </Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="Cell-nowrap">{record.RequestNo}</Table.Cell>
        )}
        <Table.Cell>{record.RequestDate ? moment(record.RequestDate).format('DD/MM/YYYY') : 'N/A'}</Table.Cell>
        <Table.Cell>{record.FundingProviderObj ? record.FundingProviderObj.Name : ''}</Table.Cell>
        <Table.Cell>
          {record.FundingPeriodFrom ? moment(record.FundingPeriodFrom).format('DD/MM/YYYY') : 'N/A'}
          {record.FundingPeriodTo ? ' - ' + moment(record.FundingPeriodTo).format('DD/MM/YYYY') : ' - N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.FundingRequestStatus === 0
            ? 'None'
            : record.FundingRequestStatus === 1
            ? 'Pending'
            : record.FundingRequestStatus === 2
            ? 'Sent'
            : record.FundingRequestStatus === 3
            ? 'Reviewed'
            : record.FundingRequestStatus === 4
            ? 'Approved'
            : 'Rejected'}
        </Table.Cell>
        <Table.Cell>
          {record.FundingRequestStatus === 0
            ? 'N/A'
            : record.FundingRequestStatus === 1
            ? record.FundingStatusDatePending
              ? moment(record.FundingStatusDatePending).format('DD/MM/YYYY')
              : 'N/A'
            : record.FundingRequestStatus === 2
            ? record.FundingStatusDateSent
              ? moment(record.FundingStatusDateSent).format('DD/MM/YYYY')
              : 'N/A'
            : record.FundingRequestStatus === 3
            ? record.FundingStatusDateReviewed
              ? moment(record.FundingStatusDateReviewed).format('DD/MM/YYYY')
              : 'N/A'
            : record.FundingRequestStatus === 4
            ? record.FundingStatusDateApproved
              ? moment(record.FundingStatusDateApproved).format('DD/MM/YYYY')
              : 'N/A'
            : record.FundingStatusDateRejected
            ? moment(record.FundingStatusDateRejected).format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    store.refetch({ curPage: page });
  };

  render() {
    const { loading, data, sortColumn, sortDirection } = store;
    const colummn_ = api.isAdmin() ? columns : columns.filter(i => i.title);
    const colSpan = colummn_.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={colummn_}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={store.handleSort}
        />
        <Table.Body>{this.renderTableBody(colSpan, data)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={store.curPage}
          totalPage={store.totalPage}
          totalItems={store.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}
export default AMAAftercareRuleTable;
