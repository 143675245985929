import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import TableToolbar from '../../shared/tableToolbar';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import AddEditStore from './store';
import moment from 'moment';

const columns = [
  { title: 'Case No' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Specialist/Provider', sortKey: 'Specialist' },
  { title: 'Claim/Report Type' },
  { title: 'Case Status' },
  { title: 'Status Date' },
];

const filterTypes = [
  { text: `All`, value: `All` },
  { text: `IME Assessment`, value: `IME` },
  { text: `File Review`, value: `FR` },
  { text: `Supplementary`, value: `SUPP` },
  { text: `Clinical Record`, value: `CR` },
  { text: `Medical Negligence`, value: `MN` },
];

@observer
class ModalBookingHistory extends React.Component {
  renderFilterTypeOptions = () => {
    return filterTypes.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterTypeText = value => {
    let labels = filterTypes.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  updateDimensions = () => {
    const tbl = document.getElementById('Table-booking-history');
    const step = window.functionGetHeightAddEditClientStep();
    const screenWidth = window.innerWidth;
    if (tbl) {
      if (step) {
        tbl.style.height = `${step.clientHeight * 0.7}px`;
      } else {
        tbl.style.height = '30em';
      }
      return (tbl.style.width = `${screenWidth * 0.575}px`);
    }
    return null;
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  handleTypeFilter = (_, { value }) => {
    AddEditStore.filterBookingHistory(value);
  };

  renderTableHistory = () =>
    AddEditStore.dataBookingHistoryView.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell style={{ maxWidth: '200px' }}>{record.CaseNo}</Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.CMFullName}
          <div>{record.CMEmail ? `${record.CMEmail}` : ''}</div>
          <div>{record.CMPhone ? `${record.CMPhone}` : ''}</div>
        </Table.Cell>
        <Table.Cell style={{ maxWidth: '300px' }}>
          {record.Type !== 'CR' && (
            <div>
              <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
                {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorFullName}{' '}
                {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
              </h5>
              <div>Specialty: {record.DoctorSepcialty}</div>
              <div>
                <FormatPhoneNumber
                  officePhoneNumber={record.DoctorOfficePhone}
                  mobilePhoneNumber={record.DoctorPhone}
                />
              </div>
              <div className={cx(record.DoctorContractReady ? 'text-success' : 'text-danger')}>
                Contract Ready: {!record.DoctorContractReady ? 'No' : 'Yes'}
              </div>
            </div>
          )}
          {record.Type === 'CR' && (
            <div>
              <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
                {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.DoctorFullName}{' '}
              </h5>
              <div>{record.DoctorEmail}</div>
              <div>{record.DoctorPhone}</div>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{record.ClaimReportType}</Table.Cell>
        <Table.Cell>{record.CaseStatus}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));

  handlePageClick = page => {
    AddEditStore.refetchBookingHistory({ curPageBookingHistory: page });
  };

  render() {
    const { dataBookingHistory, dataBookingHistoryView, loadingBookingHistory } = AddEditStore;
    const colSpan = columns.length;
    const { keywordBH, loadingBH, filterBH } = AddEditStore;
    return (
      <React.Fragment>
        <div>
          <React.Fragment>
            <TableToolbar.Container>
              <TableToolbar.Left>
                <TableToolbar.Filter
                  disabled={loadingBH}
                  value={this.renderFilterTypeText(filterBH)}
                  label={`Type`}
                  options={this.renderFilterTypeOptions()}
                  onChange={this.handleTypeFilter}
                />
                <TableToolbar.HighLightButton
                  disabled={loadingBH}
                  className="negative"
                  onClick={AddEditStore.resetStoreBH}
                  style={{ marginLeft: '5px' }}
                >
                  Clear
                </TableToolbar.HighLightButton>
              </TableToolbar.Left>
            </TableToolbar.Container>
          </React.Fragment>
        </div>
        <div className="fluid-field table-booking-history" id="Table-booking-history">
          {loadingBookingHistory ? (
            <Spin size="large" />
          ) : (
            <Table sortable striped celled className={cx({ disabled: loadingBookingHistory })}>
              <TableHeader
                columns={columns}
                sortColumn={AddEditStore.sortColumnBookingHistory}
                sortDirection={AddEditStore.sortDirectionBookingHistory}
                onSort={AddEditStore.handleSortBooking}
              />
              <Table.Body>
                {!dataBookingHistoryView ? (
                  <TableEmptyMessage colSpan={colSpan} />
                ) : dataBookingHistoryView.length ? (
                  this.renderTableHistory()
                ) : (
                  <TableEmptyMessage colSpan={colSpan} />
                )}
              </Table.Body>
              <TableFooterPagination
                colSpan={colSpan}
                currentPage={AddEditStore.curPageBookingHistory}
                totalPage={AddEditStore.totalPageBookingHistory}
                totalItems={AddEditStore.totalItemsBookingHistory}
                onPageClick={this.handlePageClick}
              />
            </Table>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ModalBookingHistory;
