import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Icon } from 'semantic-ui-react';

class ShortcutTable extends React.Component {
  render() {
    const shortcut = this.props.shortcutOptions || [];
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {!!shortcut.length &&
              shortcut.map(i => (
                <Menu.Item onClick={i.onClickShortCut} key={i.key}>
                  {i.title}
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <Icon name="ellipsis vertical" />
      </Dropdown>
    );
  }
}

export default ShortcutTable;
