import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import TypeOfClaimsStore from './TypeOfClaimsStore';

function TypeOfClaimsModal() {
  const { modalType } = TypeOfClaimsStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...TypeOfClaimsStore} onCancel={TypeOfClaimsStore.toggleModal(false)} />;
  }
}

export default TypeOfClaimsModal;
