import customFetch from '../../../utils/customFetch';

export const getSalesTarget = (keyword = '', curPage = 1, numItemPerPage = 0, sortBy = '', sortDirection = '') => {
  return customFetch('/SaleTarget/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getSaleTargetInfo = (id = 0) => {
  return customFetch(`/SaleTarget/FindItem?Id=${id}`, {
    method: 'GET',
  });
};

export const saveTarget = param => {
  return customFetch('/SaleTarget/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteTarget = id => {
  return customFetch('/SaleTarget/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};
