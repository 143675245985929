import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ClientsStore from './ClientsStore';
import ModalActionNotes from './ModalActionNotes';
import ModalAssignDoctor from './ModalAssignDoctors';
import ModalBookingHistory from './ModalBookingHistory';
import ModalExport from './ModalExport';

function ClientsModal() {
  const { modalType } = ClientsStore.modalParams;

  if (modalType === 'confirm') {
    return <ModalConfirm {...ClientsStore} onCancel={ClientsStore.toggleModal(false)} />;
  }

  if (modalType === 'notes') {
    return <ModalActionNotes {...ClientsStore} onCancel={ClientsStore.toggleModal(false)} />;
  }

  if (modalType === 'assignDoctor') {
    return <ModalAssignDoctor {...ClientsStore} onCancel={ClientsStore.toggleModal(false)} />;
  }

  if (modalType === 'export') {
    return <ModalExport {...ClientsStore} onCancel={ClientsStore.toggleModal(false)} />;
  }
  if (modalType === 'bookingHistory') {
    return <ModalBookingHistory {...ClientsStore} onCancel={ClientsStore.toggleModal(false)} />;
  }
}

export default ClientsModal;
