import React from 'react';
import { Button, Divider, Form, Header, TextArea } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal';
import InvoicesStore from './InvoicesStore';

function ModalNotification({ open, onCancel, modalParams }) {
  const { userId, record } = modalParams;
  const [comment, setComment] = React.useState();
  const renderAddOrEditForm = () => (
    <React.Fragment>
      <Form size={'small'}>
        <Form.Field>
          <Header size="medium">Do you want to delete this voice [{record.InvoiceNo}]?</Header>
          <Divider />
          <Form.Field
            control={TextArea}
            value={comment}
            onChange={(_, { value }) => setComment(value)}
            label="Reason"
          />
        </Form.Field>
      </Form>
    </React.Fragment>
  );

  const cancelInvoice = async () => {
    try {
      customFetch(`/Invoice/Delete`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          Id: userId,
          comment,
        }),
      }).then(() => onCancel());
    } finally {
      InvoicesStore.refetch({}, true);
    }
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={cancelInvoice}>
            Yes
          </Button>
          <Button className="negative" onClick={onCancel}>
            No
          </Button>
        </React.Fragment>
      }
    >
      {renderAddOrEditForm()}
    </Modal>
  );
}

export default ModalNotification;
