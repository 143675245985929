import Calendar from './Calendar';
import SearchSessionsView from './SearchSessionsView';
import IMEAssessmentDetail from './IMEAssessmentDetail';
import IMEAssessments from './IMEAssessments';
import ClaimantDetail from './ClaimantDetail';

export default [
  {
    path: '/iframe/mag-session-calendar',
    Component: Calendar,
    title: '',
  },
  {
    path: '/iframe/mag-search-sessions',
    Component: SearchSessionsView,
    title: '',
  },
  {
    path: '/iframe/mag-ime-assessment-detail',
    Component: IMEAssessmentDetail,
    title: '',
  },
  {
    path: '/iframe/mag-ime-assessments',
    Component: IMEAssessments,
    title: '',
  },
  {
    path: '/iframe/mag-claimant-detail',
    Component: ClaimantDetail,
    title: '',
  },
].reduce((map, route) => {
  map[route.path] = route;
  return map;
}, {});
