import React from 'react';
import cx from 'classnames';
import { Divider, notification, Spin } from 'antd';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import '../AddEditClinic/index.scss';
import './index.scss';

import ProgressStatus from './ProgressStatus';
import ClaimDetails from './ClaimDetails';
import AppointmentDetailsAndCaseDocuments from './AppointmentDetailsAndCaseDocuments';
// import AppointmentReminder from './AppointmentReminder';
import AppointmentAttendanceAndCaseCancellation from './AppointmentAttendanceAndCaseCancellation';
import DictationsAndReportInformation from './DictationsAndReportInformation';
import ClientInvoicingInformation from './ClientInvoicingInformation';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';
import AddEditStore from './Store';
import AddEditModal from './Modal';
import { saveAssessment, getAssessmentInfo, getUserUploadInfo } from './service';
import Paperwork from './Paperwork';

import moment from 'moment';
import { CONSTANTS, ASSESSMENT_PIC_IN_CHARGE_OF, FIELD_NAME_FOR_NOTIFICATION, LOCAL_CONSTANT } from './type';
import { toJS, action } from 'mobx';
import dashboardStore from '@stores/dashboard';
import router from '@stores/router';
import ui from '../../../stores/dashboard';
import * as api from '../../../stores/api';

@observer
class AddEditImeAssessments extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  _reloadPageToGetNewInfo = debounce(pathName => {
    const { pageGetter, fieldName, dataTransfer, listPageGetters } = dashboardStore;
    if (!pathName) {
      pathName = router.location.pathname;
    }
    const page = listPageGetters.find(i => i.pathname === pathName);
    if (page && page.pathname) {
      if (pageGetter === page.key) {
        if (fieldName && dataTransfer) {
          if (fieldName === 'SessionId') {
            const action = localStorage.getItem('ChangeCalSessionMode');
            if (action === 'addAppt') {
              AddEditStore.handleChangeValue('Appointments', [
                ...AddEditStore.assessmentInfo.Appointments,
                dataTransfer.Appointment,
              ]);
            }
            if (action === 'updateAppt') {
              AddEditStore.handleChangeValue('SessionId', dataTransfer.SessionsId);
              AddEditStore.getAppointmentData(dataTransfer.SessionsId, AddEditStore.BindAppointmentData);
            }

            return dashboardStore.transferData({
              dataTransfer: null,
              fieldName: null,
              pageGetter: null,
            });
          }
          const fieldNotified = FIELD_NAME_FOR_NOTIFICATION.find(i => i.key === fieldName);
          AddEditStore.toggleModal(true, {
            modalType: 'confirm',
            message: `Do you want to add this ${fieldNotified?.text} (${dataTransfer?.FullName ||
              dataTransfer?.Name}) to the current booking?`,
            onOk: () => {
              AddEditStore.setFieldsValue({ [fieldName]: dataTransfer });
            },
          })();
          return dashboardStore.transferData({
            dataTransfer: null,
            fieldName: null,
            pageGetter: null,
          });
        }
        return;
      } else {
        return;
      }
    }
  }, 1000);

  onIframeReload = event => {
    if (event.data.action === 'OPEN_IME_DETAIL') {
      // reload data
      ui.emit('open(/view/add-edit-ime-assessment-2)', '/view/add-edit-ime-assessment-2');
    }
  };

  @action componentDidMount() {
    const { id = 0, step = 0, dId, sId } = this.getRouteParams();
    window.addEventListener('message', this.onIframeReload);
    ui.on('open(/view/add-edit-ime-assessment-2)', pathName => this._reloadPageToGetNewInfo(pathName));
    AddEditStore.currentStep = +step;
    AddEditStore.fetchAssessmentInfo(+id, +dId, +sId);
    AddEditStore.handleReaction();
  }
  componentWillUnmount() {
    AddEditStore.handleResetStore();
    window.removeEventListener('message', this.onIframeReload);
  }
  handleStepChange = step => () => {
    AddEditStore.handleChangeStep(step);
  };

  @action handleChangeValue = (fieldName, value) => {
    const itemModel = toJS(AddEditStore.assessmentInfo);
    return (AddEditStore.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  @action ChangeAssDate = () => {
    const itemModel = toJS(AddEditStore.assessmentInfo);
    const hourList = AddEditStore.generateTimeRange(24);
    const minuteList = AddEditStore.generateTimeRange(60);
    if (itemModel.DateFrom) {
      if (itemModel.DateFromHour && itemModel.DateFromHour.value > 0) {
        let dateFrom = itemModel.DateFrom;
        dateFrom.hour(itemModel.DateFromHour.value);
        dateFrom.minutes(itemModel.DateFromMinute.value);
        if (itemModel.DateToHour.value > 0) {
          let dateTo = itemModel.DateFrom;
          dateTo.hour(itemModel.DateToHour.value);
          dateTo.minutes(itemModel.DateToMinute.value);
        }
      } else {
        this.handleChangeValue('DateFromHour', hourList[9]);
        this.handleChangeValue('DateFromMinute', minuteList[0]);
        this.handleChangeValue('DateToHour', hourList[9]);
        this.handleChangeValue('DateToMinute', minuteList[0]);
      }
      if (
        !itemModel.RequestExpectedCompletionDate &&
        itemModel.Attendance !== CONSTANTS.Attendance_LCF &&
        itemModel.Attendance !== CONSTANTS.Attendance_Cancelled
      ) {
        this.handleChangeValue('RequestExpectedCompletionDate', itemModel.DateFrom.addDays(3));
        this.handleChangeValue('RequestExpectedCompletionDate', itemModel.DateFrom.addDays(3));
        const day = itemModel.RequestExpectedCompletionDate.day();
        if (day === 6) {
          this.handleChangeValue('RequestExpectedCompletionDate', itemModel.DateFrom.addDays(5));
        } else if (day === 0) {
          this.handleChangeValue('RequestExpectedCompletionDate', itemModel.DateFrom.addDays(4));
        }
      }
    }
  };

  handleSave = () => {
    // this.ChangeAssDate();
    let itemModel = toJS(AddEditStore.assessmentInfo);
    Object.entries(toJS(AddEditStore.assessmentInfo)).map(
      ([key, value]) =>
        key.toLowerCase().search('date') !== -1 &&
        value &&
        value instanceof moment &&
        (itemModel = {
          ...itemModel,
          [key]: value.toISOString(),
        }),
    );
    const ActionTypes = {
      None: 0,
      UrgentReport: 2,
      OtherAction: 1,
    };
    if (itemModel.UrgentReport && !itemModel.UrgentReportCompleted) {
      itemModel.ActionType = ActionTypes.UrgentReport;
    } else if (itemModel.ActionRequired && !itemModel.ActionCompleted) {
      itemModel.ActionType = ActionTypes.OtherAction;
    } else {
      itemModel.ActionType = ActionTypes.None;
    }

    if (itemModel.ClaimantStatus) {
      itemModel.ClaimantStatus = '1';
    } else {
      itemModel.ClaimantStatus = '0';
    }

    if (!itemModel.SelectedPaperworkToDoctorBies) {
      itemModel = { ...itemModel, SelectedPaperworkToDoctorBies: [] };
    }
    if (itemModel.EmailInvoiceToMAGStaffSent) {
      const staffs = toJS(AddEditStore.listStaff);
      const staff = staffs.itemList.find(item => item.Id === itemModel.EmailInvoiceToMAGStaffSent);
      itemModel.EmailInvoiceToMAGStaffSent = staff?.FullName || itemModel.EmailInvoiceToMAGStaffSent;
    }
    const claimTypeModel = toJS(AddEditStore.claimType);
    const reasonReferralModel = toJS(AddEditStore.reasonReferral);
    const reportTypeModel = toJS(AddEditStore.reportType);
    itemModel.ReportId = claimTypeModel && claimTypeModel?.Id ? claimTypeModel.Id : null;
    itemModel.ReportTypeId = reportTypeModel && reportTypeModel.Id ? reportTypeModel.Id : null;
    itemModel.ReasonReferralId = reasonReferralModel && reasonReferralModel?.Id ? reasonReferralModel.Id : null;
    itemModel.SessionIds = itemModel.Appointments.map(item => item.SessionId);
    itemModel.SessionId = !!itemModel.SessionId ? itemModel.SessionId : null;
    itemModel.oldAttendance = itemModel.Attendance;
    itemModel.newPatientInfo = toJS(AddEditStore.claimant);
    itemModel.selectedServiceItemIds = itemModel.AssessmentServiceItems.map(i => i.Id).filter(i => i);
    const subBookingSolicitor = AddEditStore.subBookingSolicitor.map(i => i);
    subBookingSolicitor.map((i, idx) => (itemModel[`SubCaseManager${idx + 1}`] = i));
    subBookingSolicitor.map((i, idx) => (itemModel[`SubCaseManager${idx + 1}Id`] = i.Id));

    let formData = toJS({
      ...AddEditStore.submitData,
      clientPrepaymentReceived: itemModel.ClientPrepaymentReceived,
      specialistPrepaymentPaid: itemModel.SpecialistPrepaymentPaid,
      urgentReportReportCompletionDate: itemModel.UrgentReportReportCompletionDate,
      urgentReportConfirmedByCM: itemModel.UrgentReportConfirmedByCM,
      notifyUrgentReportToStaff: itemModel.NotifyUrgentReportToStaff,
      notifyUrgentReportToSpecialist: itemModel.NotifyUrgentReportToSpecialist,
      notifyUrgentReportToCM: itemModel.NotifyUrgentReportToCM,
      requestAcceptedBySpecialist: itemModel.RequestAcceptedBySpecialist,
    });
    itemModel.RequestStatus = 0;

    const reportTypeNotIsAttachment = [16, 17]; //Report type === 16 or === 17 is link

    itemModel.AssessmentAttachment = itemModel.AssessmentAttachment.filter(
      i => reportTypeNotIsAttachment.indexOf(i.ReportType) === -1,
    );

    itemModel.SubCaseManager1Id = itemModel.SubCaseManager1Id || '';
    itemModel.SubCaseManager2Id = itemModel.SubCaseManager2Id || '';
    itemModel.SubCaseManager3Id = itemModel.SubCaseManager3Id || '';
    itemModel.SentDateEmailToCMConfirmAppointment = itemModel.SentDateEmailToCMConfirmAppointment
      ? `/Date(${moment(itemModel.SentDateEmailToCMConfirmAppointment)
          .toDate()
          .getTime()})/`
      : null;
    itemModel.SentDateEmailToDoctorConfirmAppointment = itemModel.SentDateEmailToDoctorConfirmAppointment
      ? `/Date(${moment(itemModel.SentDateEmailToDoctorConfirmAppointment)
          .toDate()
          .getTime()})/`
      : null;

    if (itemModel.RequestResolved) {
      itemModel.RequestStatus = CONSTANTS.RequestResolved;
    } else if (itemModel.RequestCancelled) {
      itemModel.RequestStatus = CONSTANTS.RequestCancelled;
    }
    itemModel.CustomerId = AddEditStore.bookingClient?.Id || null;
    itemModel.Customer = AddEditStore.bookingClient?.Id ? AddEditStore.bookingClient : null;
    itemModel.CaseManagerId = AddEditStore.bookingCaseManager?.Id || null;
    itemModel.CaseManager = AddEditStore.bookingCaseManager?.Id ? AddEditStore.bookingCaseManager : null;
    itemModel.Patient = AddEditStore.claimant?.Id ? AddEditStore.claimant : null;
    itemModel.PatientId = AddEditStore.claimant?.Id || null;
    itemModel.BodyParts = AddEditStore.bodyParts ? [...AddEditStore.bodyParts] : [];
    itemModel.SubBodyParts = AddEditStore.subBodyParts ? [...AddEditStore.subBodyParts] : [];
    itemModel.ClaimReqSpecialty = JSON.stringify(AddEditStore.claimReqSpecialty);
    itemModel.F_RDCCaseManagerId = AddEditStore.Report_Delivery_Contact_1?.Id || null;
    itemModel.F_RDCfirstName = AddEditStore.Report_Delivery_Contact_1?.FirstName || null;
    itemModel.F_RDClastName = AddEditStore.Report_Delivery_Contact_1?.LastName || null;
    itemModel.F_RDCEmail = AddEditStore.Report_Delivery_Contact_1?.Email || null;
    itemModel.S_RDCCaseManagerId = AddEditStore.Report_Delivery_Contact_2?.Id || null;
    itemModel.S_RDCfirstName = AddEditStore.Report_Delivery_Contact_2?.FirstName || null;
    itemModel.S_RDClastName = AddEditStore.Report_Delivery_Contact_2?.LastName || null;
    itemModel.S_RDCEmail = AddEditStore.Report_Delivery_Contact_2?.Email || null;
    itemModel.BookingSolicitor = AddEditStore.bookingSolicitor?.Id ? AddEditStore.bookingSolicitor : null;
    itemModel.BookingSolicitorId = AddEditStore.bookingSolicitor?.Id || null;
    itemModel.ClaimType = AddEditStore.claimType?.Id ? AddEditStore.claimType : null;
    itemModel.InsurerId = AddEditStore.companyInsurer?.Id || null;
    itemModel.CompanyInsurer = AddEditStore.companyInsurer?.Id ? AddEditStore.companyInsurer : null;
    itemModel.ReasonReferral = AddEditStore.reasonReferral?.Id ? AddEditStore.reasonReferral : null;
    itemModel.ReportType = AddEditStore.reportType?.Id ? AddEditStore.reportType : null;
    itemModel.MasterClient = AddEditStore.subBookingClient?.Id ? AddEditStore.subBookingClient : null;
    itemModel.MasterClientId = AddEditStore.subBookingClient?.Id || null;
    itemModel.SubBookingClientCMId = AddEditStore.subBookingClientCM?.Id || null;
    // itemModel.Customer = AddEditStore.principleLawyer;
    itemModel.PCustomer = AddEditStore.payingClient?.Id ? AddEditStore.payingClient : null;
    itemModel.PCustomerId = AddEditStore.payingClient?.Id || null;
    itemModel.CReferenceId = AddEditStore.referenceClient?.Id || null;
    itemModel.CReference = AddEditStore.referenceClient?.Id ? AddEditStore.referenceClient : null;
    itemModel.PCaseManagerId = AddEditStore.payingCasemanger?.Id || null;
    itemModel.PCaseManager = AddEditStore.payingCasemanger?.Id ? AddEditStore.payingCasemanger : null;
    itemModel.Doctor = AddEditStore.specialist?.Id ? AddEditStore.specialist : null;
    itemModel.DoctorId = AddEditStore.specialist?.Id || null;

    itemModel.Clinic = AddEditStore.clinic?.ID ? AddEditStore.clinic : null;
    itemModel.ClinicId = AddEditStore.clinic?.ID ? AddEditStore.clinic.ID : 0;
    itemModel.ConsentFormReceivedDate = moment(itemModel.ConsentFormReceivedDate).toISOString();
    itemModel.EassessDoctorLocationChecklistReceivedDate = moment(
      itemModel.EassessDoctorLocationChecklistReceivedDate,
    ).toISOString();
    itemModel.EassessClaimantLocationChecklistReceivedDate = moment(
      itemModel.EassessClaimantLocationChecklistReceivedDate,
    ).toISOString();
    itemModel.TypistApproverdBy = itemModel.TypistApproverdBy ? itemModel.TypistApproverdBy.Id : null;
    itemModel.TypistApproverdOn = itemModel.TypistApproverdOn
      ? moment(itemModel.TypistApproverdOn).toISOString()
      : null;
    itemModel.TypistSubmittingDate = itemModel.TypistSubmittingDate
      ? moment(itemModel.TypistSubmittingDate).toISOString()
      : null;
    itemModel.TypistTranscribingTime = itemModel.TypistTranscribingTime
      ? parseInt(itemModel.TypistTranscribingTime)
      : null;
    if (AddEditStore.AdditionalServiceSupplierObj && AddEditStore.AdditionalServiceSupplierObj.Id) {
      itemModel.AdditionalServiceSupplierId = AddEditStore.AdditionalServiceSupplierObj.Id;
    }
    // itemModel.MagBookingManagerId =
    //   itemModel.CaseManager?.AssignedStaffId || null;
    // itemModel.MagAccountManagerId = itemModel.Customer?.AssignedToId || null;
    // itemModel.ClinicRoom = AddEditStore.room;
    formData.SelectedBodyParts = toJS(AddEditStore.bodyParts);
    formData.WaitingList = toJS(AddEditStore.waitingList.map(i => i.Id));
    formData.SelectedSubBodyParts = toJS(AddEditStore.subBodyParts);
    formData.PaperworkExLink = itemModel.PaperworkExLink;
    formData.PaperworkOtherExLink = itemModel.PaperworkOtherExLink;
    formData.copy = itemModel.copy;
    formData.StatusPics = [
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.REQUEST_RECEIVED,
        value: toJS(AddEditStore.PIC_1)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.APPOINTMENT_CONFIRMED,
        // value:
        //   AddEditStore.renderAssessmentPIC(
        //     'APPOINTMENT_CONFIRMED',
        //     toJS(AddEditStore.caseStatus),
        //   )?.picId || null,
        value: toJS(AddEditStore.PIC_2)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.PAPERWORK_RECEIVED,
        // value:
        //   AddEditStore.renderAssessmentPIC(
        //     'PAPERWORK_RECEIVED',
        //     toJS(AddEditStore.caseStatus),
        //   )?.picId || null,
        value: toJS(AddEditStore.PIC_3)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.ATTENDED,
        value: toJS(AddEditStore.PIC_4)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.NOT_ATTENDED,
        value: AddEditStore.renderAssessmentPIC('NOT_ATTENDED', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.DICTATION_RECEIVED,
        value: toJS(AddEditStore.PIC_5)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.REPORT_DRAFT_RECEIVED,
        value: toJS(AddEditStore.PIC_6)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.REPORT_COMPLETED,
        value: toJS(AddEditStore.PIC_8)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.CORRECTED_REPORT,
        // value:
        //   AddEditStore.renderAssessmentPIC(
        //     'CORRECTED_REPORT',
        //     toJS(AddEditStore.caseStatus),
        //   )?.picId || null,
        value: toJS(AddEditStore.PIC_7)?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.CANCELLED_NO_FEE,
        value: AddEditStore.renderAssessmentPIC('CANCELLED_NO_FEE', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.CANCELLED_LCF,
        value: AddEditStore.renderAssessmentPIC('CANCELLED_LCF', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.ServiceBill,
        value: AddEditStore.renderAssessmentPIC('ServiceBill', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.DICTATION_RECEIVED_2,
        value: AddEditStore.renderAssessmentPIC('DICTATION_RECEIVED_2', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.PAYMENTRE_QUESTED,
        value: AddEditStore.renderAssessmentPIC('PAYMENTRE_QUESTED', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.PREPAYMENT_PAID,
        value: AddEditStore.renderAssessmentPIC('PREPAYMENT_PAID', toJS(AddEditStore.caseStatus))?.picId || null,
      },
      {
        key: ASSESSMENT_PIC_IN_CHARGE_OF.CANCELLED_SPECIALIST_TBP,
        value:
          AddEditStore.renderAssessmentPIC('CANCELLED_SPECIALIST_TBP', toJS(AddEditStore.caseStatus))?.picId || null,
      },
    ];
    if (!itemModel.Id) {
      itemModel.SubBodyParts = [];
      itemModel.BodyParts = [];
      itemModel.AssessmentServiceItems = [];
      itemModel.CReference = null;
    }
    formData.LstInvoiceItemFee = AddEditStore.lstInvoiceItemFee;
    const params = Object.assign({}, itemModel, { formData: formData });
    const errors = AddEditStore.validateForm();
    if (errors.length !== 0) {
      errors.map(i => AddEditStore.showNotificationError(i));
      return;
    } else {
      const { invalidForm } = AddEditStore;
      if (!!invalidForm.length) {
        return AddEditStore.showNotificationError('Some fields are invalid, please check the form again');
      } else {
        if (AddEditStore.assessmentInfo.Id === 0 && AddEditStore.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate) {
          AddEditStore.toggleModal(true, {
            modalType: 'confirm',
            message: `This specialist may not apply Gazetted rates for this service. Do you want to continue?`,
            onOk: () => {
              this.doSaveIME(params);
            },
          })();
        } else {
          const paperworks = AddEditStore.renderAttachments(params.AssessmentAttachment, [
            LOCAL_CONSTANT.ReportType_Paperwork,
            LOCAL_CONSTANT.ReportType_PaperworkOther,
          ]);
          getUserUploadInfo(paperworks[0]?.UploadedBy).then(uP => {
            let isAdminOrStaffUpload = false;
            if (uP.status === 'success') {
              if (uP?.User?.Roles.some(r => r === 'admin' || r === 'magstaff')) {
                isAdminOrStaffUpload = true;
              }
            }
            if (
              api.isCaseManager() &&
              ((!params.NotifyPaperworkReadinessToStaff && !isAdminOrStaffUpload) || itemModel.IsUpdatedPaperwork)
            ) {
              if (paperworks.length) {
                AddEditStore.toggleModal(true, {
                  modalType: 'confirm',
                  message: `Are these all the documents you would like the specialist to review?`,
                  onOk: () => {
                    if (params.NotifyPaperworkReadinessToStaff === true) {
                      AddEditStore.handleResendPaperworkReadiness();
                    }
                    params.NotifyPaperworkReadinessToStaff = true;
                    this.doSaveIME(params);
                  },
                  functionCallbackCancel: () => {
                    this.doSaveIME(params);
                  },
                })();
              } else {
                this.doSaveIME(params);
              }
            } else {
              this.doSaveIME(params);
            }
          });
        }
        //this.doSaveIME(params);
      }
    }
  };

  formatNumber = value => {
    return !!value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  };

  doSaveIME = async params => {
    try {
      AddEditStore.setFieldsValue({ loading: true });
      const response = await saveAssessment({
        ...params,
        SelectedDoctorProviderNoIdx: params.SelectedDoctorProviderNoIdx
          ? params.SelectedDoctorProviderNoIdx.toString()
          : '',
      });
      if (response.status === 'success') {
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
        });
        if (!params.Id) {
          // if (api.isCaseManager() && AddEditStore.bookingClient && !params.WifroIlars) {
          //   Promise.all([getAssessmentInfo(response.Id)]).then(([resAssessment]) => {
          //     // ClientType === 3 -> LawPlaintiff
          //     if (resAssessment && resAssessment.model.Customer.ClientType === 3) {
          //       return AddEditStore.toggleModal(true, {
          //         modalType: 'disbursement',
          //         id: response.Id,
          //         caseNo: resAssessment.model.AssessmentNumber,
          //         caseId: resAssessment.model.Id,
          //         fromService: 'A',
          //         claimant: `${resAssessment.model.Patient?.Title} ${resAssessment.model.Patient?.FullName}`,
          //         claimantId: resAssessment.model.Patient?.Id,
          //         functionCallbackCancel: () => AddEditStore.fetchAssessmentInfo(response.Id),
          //         clientId: resAssessment.model.CustomerId,
          //         isManual: false,
          //       })();
          //     } else {
          //       AddEditStore.fetchAssessmentInfo(response.Id).then(res => {});
          //     }
          //   });
          // } else {
          getAssessmentInfo(response.Id).then(res => {
            const caseNo = `Case ${res.model.AssessmentNumber}`;
            localStorage.setItem('AssessmentCaseId', caseNo);
            dashboardStore.close('/view/add-edit-ime-assessment-2');
            setTimeout(() => {
              dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${response.Id}`);
            });
          });
          // }
        } else {
          AddEditStore.fetchAssessmentInfo(response.Id).then(res => {});
        }
      } else {
        if (response.status === 'fail' && response.error.RecordChange) {
          notification.error({
            message: 'Error',
            description: 'This record has been modified by another user. Please refresh and update again.',
          });
        }
        if (response.status === 'fail' && response.error.Session) {
          notification.error({
            message: 'Error',
            description: 'This session is already booked.',
          });
        }
        AddEditStore.setFieldsValue({ loading: false });
      }
    } catch (ex) {
      notification.error({
        message: 'Error',
        description: ex.generalerror
          ? ex.generalerror
          : 'Error occured while contacting server. Please report this error to system administrator and try again later.',
      });
      AddEditStore.setFieldsValue({ loading: false });
    } finally {
    }
  };

  handleRequestFunding = () => {
    const { assessmentInfo } = AddEditStore;
    if (!!assessmentInfo.Id) {
      return AddEditStore.toggleModal(true, {
        modalType: 'disbursement',
        caseNo: assessmentInfo.AssessmentNumber,
        caseId: assessmentInfo.Id,
        fromService: 'A',
        claimant: `${assessmentInfo.Patient?.Title} ${assessmentInfo.Patient?.FullName}`,
        claimantId: assessmentInfo.Patient?.Id,
        clientId: assessmentInfo.CustomerId,
        isManual: true,
      })();
    }
    return this.handleSave();
  };

  renderForm = () => {
    switch (AddEditStore.currentStep) {
      case 1:
        return <AppointmentDetailsAndCaseDocuments />;
      case 2:
        return <Paperwork />;
      case 3:
        return <AppointmentAttendanceAndCaseCancellation />;
      case 4:
        return <DictationsAndReportInformation />;
      case 5:
        return <ClientInvoicingInformation />;
      case 6:
        return <CaseCorrespondence />;
      case 7:
        return <ActionRequired />;
      default:
        return <ClaimDetails />;
    }
  };

  renderStep = () => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      return AddEditStore.steps.map((step, idx) => (
        <Button
          key={step.key}
          fluid
          size="large"
          className={cx('step', {
            active: AddEditStore.currentStep === idx,
          })}
          onClick={this.handleStepChange(idx)}
        >
          {idx + 1}. {step.title}
        </Button>
      ));
    } else {
      if (api.isCaseManager()) {
        return AddEditStore.steps.map(
          (step, idx) =>
            idx !== 7 && (
              <Button
                key={step.key}
                fluid
                size="large"
                className={cx('step', {
                  active: AddEditStore.currentStep === idx,
                })}
                disabled={!AddEditStore.assessmentInfo.Id && (idx === 3 || idx === 4 || idx === 5 || idx === 6)}
                onClick={this.handleStepChange(idx)}
              >
                {idx + 1}. {idx !== 5 ? step.title : 'Invoices'}
              </Button>
            ),
        );
      }
      if (api.isTypist()) {
        return AddEditStore.steps.map(
          (step, idx) =>
            idx !== 7 &&
            idx !== 6 &&
            idx !== 5 && (
              <Button
                key={step.key}
                fluid
                size="large"
                className={cx('step', {
                  active: AddEditStore.currentStep === idx,
                })}
                onClick={this.handleStepChange(idx)}
              >
                {idx + 1}. {idx !== 5 ? step.title : 'Invoices'}
              </Button>
            ),
        );
      }
      if (api.isDoctor()) {
        return AddEditStore.steps.map(
          (step, idx) =>
            idx !== 7 &&
            idx !== 5 && (
              <Button
                key={step.key}
                fluid
                size="large"
                className={cx('step', {
                  active: AddEditStore.currentStep === idx,
                })}
                onClick={this.handleStepChange(idx)}
              >
                {idx === 6 ? idx : idx + 1}. {idx !== 5 ? step.title : 'Invoices'}
              </Button>
            ),
        );
      }
      return AddEditStore.steps.map(
        (step, idx) =>
          idx !== 7 && (
            <Button
              key={step.key}
              fluid
              size="large"
              className={cx('step', {
                active: AddEditStore.currentStep === idx,
              })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {idx !== 5 ? step.title : 'Specialist Payment'}
            </Button>
          ),
      );
    }
  };

  render() {
    const { loading, open, assessmentInfo } = AddEditStore;
    const { lockTypist, oldPaperworkReceived } = AddEditStore.handleLockReportCompleteCM(assessmentInfo);

    return (
      <div className={cx(`page-${loading ? 'loading' : 'container'}`)}>
        {loading ? (
          <Spin size="large" />
        ) : (!api.isCMInvoiceCheck() && <React.Fragment>
            {!!assessmentInfo.Id && <ProgressStatus />}
            <div className="form-container">
              <h1 className="form-title">
                {AddEditStore.currentStep === 5 && api.isCaseManager()
                  ? 'Invoices'
                  : AddEditStore.currentStep === 5 && api.isDoctor()
                  ? 'Specialist Payment'
                  : AddEditStore.steps[AddEditStore.currentStep].title}
              </h1>
              {this.renderForm()}
            </div>
            <div className="step-container step-container-sticky">{this.renderStep()}</div>
            <div className="form-footer">
              <Divider />
              {/* {assessmentInfo.Id > 0 && !api.isDoctor() && !api.isTypist() && (
                <Button
                  style={{ marginRight: '5px' }}
                  primary
                  onClick={() =>
                    window.open('/MedicalService/AppointmentConfirmationLetterPrint?aId=' + assessmentInfo.Id, '_blank')
                  }
                >
                  Print Confirmation (to Client)
                </Button>
              )}
              {assessmentInfo.Id > 0 && !api.isCaseManager() && !api.isTypist() && (
                <Button
                  style={{ marginRight: '5px' }}
                  primary
                  onClick={() => window.open('/MedicalService/DoctorConfPrint?aId=' + assessmentInfo.Id, '_blank')}
                >
                  Print Confirmation (to Specialist)
                </Button>
              )} */}
              {!!AddEditStore.assessmentInfo.Id && api.isCaseManager() && AddEditStore.bookingClient?.ClientType === 3 && (
                <Button primary onClick={() => this.handleRequestFunding()}>
                  Request Funding for this Report
                </Button>
              )}

              {!api.isOnlySearch() && <Button
                primary
                disabled={
                  loading ||
                  (((api.isDoctor() && assessmentInfo.Status === 'Billed') ||
                    (api.isCaseManager() &&
                      (assessmentInfo.Status === 'Billed' ||
                        assessmentInfo.Status === api.imeStatus.CANCELLED ||
                        assessmentInfo.Status === api.imeStatus.IME_CANCELLED_WITH_FEE ||
                        assessmentInfo.Status === api.imeStatus.CANCELLED_SPECIALIST_TBP))) &&
                    !lockTypist)
                }
                onClick={this.handleSave}
              >
                Save
              </Button>}
              <Button className="negative" onClick={() => dashboardStore.close(router.location.pathname)}>
                Close
              </Button>
            </div>
            {open && <AddEditModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default AddEditImeAssessments;
