import { action, observable } from 'mobx';

import customFetch from './customFetch';

class QueryResult {
  @observable fetched = false;
  @observable loading = false;
  @observable error = null;
  @observable data = null;

  constructor(url) {
    this.url = url;
  }

  fetch = (...args) => {
    if (this.fetched) {
      return;
    }
    this.refetch(...args);
  };

  @action refetch = (...urlArgs) => {
    if (this.loading) {
      return;
    }
    // Build url in case of function
    let { url } = this;
    if (typeof url === 'function') {
      url = url(...urlArgs);
    }
    this.fetched = true;
    // Reset query state but keep data
    this.loading = true;
    this.error = null;
    // Call fetch and handle promise
    customFetch(url)
      .then(
        action(res => {
          this.data = res;
        }),
      )
      .catch(
        action(err => {
          this.error = err;
        }),
      )
      .then(
        action(() => {
          this.loading = false;
        }),
      );
  };

  @action reset = () => {
    this.fetched = false;
    this.loading = false;
    this.error = null;
    this.data = null;
  };
}

const createQuery = url => new QueryResult(url);

export default createQuery;
