import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import AddEditStore from './store';
import dashboardStore from '../../../stores/dashboard';
import BusinessUnit from './BusinessUnit';

@observer
class AssignCMAndBU extends React.Component {
  openViewAssign = (record, type) => {
    localStorage.setItem('ManageCmName', `Case Managers - ${record.Name}`);
    if (type === 'cm') {
      dashboardStore.close('/view/case-managers-2', true);
      return setTimeout(() => {
        dashboardStore.open(`/view/case-managers-2?clientId=${record.Id}`);
      });
    }
  };
  render() {
    const data = toJS(AddEditStore.clientInfo);
    return (
      <React.Fragment>
        <div className="fluid-field">{data.Id !== 0 && <BusinessUnit companyId={data.Id} />}</div>
      </React.Fragment>
    );
  }
}

export default AssignCMAndBU;
