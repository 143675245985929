import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import Store from './clinicalNoteStore';
import * as api from '../../../stores/api';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const { toggleModal, modalParams } = Store;
    localStorage.setItem('ClinicalRecordCaseId', modalParams.caseNo);
    const type = `Corespondence`;
    iframe.forceOpenTab('AddEditClinicalNote', `?id=${modalParams.id}&view=AddEditClinicalNote#${type}`, {
      id: modalParams.id,
      view: 'AddEditClinicalNote',
    });
    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = Store;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = `/api/ClinicalNoteLog?clinicalNoteId=${modalParams.id}`;
  const param = { method: 'GET' };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1250}
      title={title}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={openCaseCorrespondence}>
            <Icon name="table" /> View Case Correspondence
          </Button>
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress progressList={api.CR_CaseProgress.data.itemList} param={param} showHistory url={url} />
    </Modal>
  );
}

export default ModalCaseProgress;
