import React from 'react';
import { Form, Input, notification, Spin, Select, InputNumber, DatePicker, TimePicker, Tooltip } from 'antd';
import { Button, Icon, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Modal from '../../shared/Modal';
import store from './Store';
import { formatDate } from '../../../utils/functions';
import DragDropFile from './../../shared/DragDropFile';
import { action, toJS, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { renderDate } from '../../../components/case-details-routes/Shared';
import * as api from '../../../stores/api';
import { getFundingRequest, getFundedCompanies, getFundingAgreementInfo, saveData } from './Service';
import ModalConfirm from './ModalConfirm';

@observer
class ModalUpdate extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };

  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  state = {
    loading: true,
    data: null,
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  componentDidMount() {
    const { id, record } = store.modalParams;
    if (id) {
      Promise.all([getFundingAgreementInfo(id), getFundingRequest(''), getFundedCompanies('')]).then(
        ([resp, fundingRqResp, fundedCompaniesResp]) => {
          if (resp.status === 'success') {
            if (resp.objItem && resp.objItem.Attachments && resp.objItem.Attachments.length > 0) {
              const newAttachments = [...resp.objItem.Attachments].map(i => ({
                ...i,
                key: i.FileName,
              }));
              store.setFileAttachment(newAttachments);
            } else {
              store.setFileAttachment([]);
            }
            var itemInfo = {
              ...resp.objItem,
              SigningDate: this.momentToJS(resp.objItem.SigningDate),
              FundingPeriodFrom: this.momentToJS(resp.objItem.FundingPeriodFrom),
              FundingPeriodTo: this.momentToJS(resp.objItem.FundingPeriodTo),
              FacilityExpiryDate: this.momentToJS(resp.objItem.FacilityExpiryDate),
            };
            this.setState({ data: itemInfo });
          }
          if (fundingRqResp.status === 'success') {
            store.setLstData('lstFundingRequest', fundingRqResp.itemList);
          }
          if (fundedCompaniesResp.status === 'success') {
            store.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
          }
          this.setState({ loading: false });
          //console.log(resp);
        },
      );
    } else {
      Promise.all([getFundingRequest(''), getFundedCompanies('')]).then(([fundingRqResp, fundedCompaniesResp]) => {
        if (fundingRqResp.status === 'success') {
          store.setLstData('lstFundingRequest', fundingRqResp.itemList);
        }
        if (fundedCompaniesResp.status === 'success') {
          store.setLstData('lstFundedCompanies', fundedCompaniesResp.itemList);
        }
      });
      var newData = {
        Id: 0,
        RequestId: null,
        AgreementNo: null,
        FundingRqNo: null,
        FundingProvider: null,
        FundingProviderObj: null,
        SigningDate: null,
        SignPersonProvider: null,
        SignPersonProviderObj: null,
        SignPersonLawyer: null,
        FundedCompanyId: null,
        FundedCompanyObj: null,
        FundingPeriodFrom: null,
        FundingPeriodTo: null,
        GrossMargin: 0,
        Discount: 0,
        FundingAmount: 0,
        FundingAmountRemaining: 0,
        OverfundingLimitApplicable: false,
        FacilityExpiryDate: null,
        Attachments: [],
      };
      this.setState({ data: newData, loading: false });
    }
  }

  handleSave = () => {
    const { data } = this.state;
    if (!data.AgreementNo) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'All required fields must be updated',
      });
      return;
    }
    const { lstFileAttachments } = store;
    var saveDataObj = data;
    if (lstFileAttachments && lstFileAttachments.length > 0) {
      saveDataObj.Attachments = lstFileAttachments;
    } else {
      saveDataObj.Attachments = [];
    }
    saveData(saveDataObj).then(resp => {
      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully.',
        });
        this.setState({ data: { ...data, Id: resp.model.Id } });
        store.refetch({}, true);
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again.',
        });
      }
    });

    console.log(saveDataObj);
  };

  handleFieldChange = fieldName => event => {
    const { lstFundingRequest } = store;
    const { data } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    console.log(value);
    if (fieldName === 'RequestId') {
      var fundingRq = lstFundingRequest.find(i => i.Id === value);
      if (fundingRq) {
        this.setState({
          data: {
            ...data,
            FundingProvider: fundingRq.FundingProvider,
            FundingProviderObj: fundingRq.FundingProviderObj,
            SignPersonProvider: fundingRq.SignPersonProvider,
            SignPersonProviderObj: fundingRq.SignPersonProviderObj,
            [fieldName]: value,
          },
        });
      }
    } else {
      this.setState({
        data: {
          ...data,
          [fieldName]: value,
        },
      });
    }
  };

  @action handleDateFieldChange = fieldName =>
    action(event => {
      const { data } = this.state;
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.setState({
        data: {
          ...data,
          [fieldName]: value,
        },
      });
    });

  handleCheckboxFieldChange = fieldName =>
    action(event => {
      const { data } = this.state;
      const value = !data[fieldName];
      this.setState({
        data: {
          ...data,
          [fieldName]: value,
        },
      });
    });

  dragdropUploadFile = (fileList, reportType) => {
    const { data } = this.state;
    const { lstFileAttachments } = store;
    const attachment = {
      FileName: fileList[0].name,
      Title: fileList[0].title,
      NumberOfPages: fileList[0].numberOfPages,
      Type: 3,
      CaseId: data.Id,
      AttachmentType: reportType,
      Id: 0,
    };
    const newAttachments = [...lstFileAttachments, attachment].map(i => ({
      ...i,
      key: i.FileName,
    }));
    store.setFileAttachment(newAttachments);
  };

  dragdropDeleteFile = key => {
    const { lstFileAttachments } = store;
    const newAttachments = lstFileAttachments.filter(i => i.key !== key);
    store.setFileAttachment(newAttachments);
  };

  renderAttachment = (Attachments = [], attachmentType) => {
    return Attachments.filter(i => i.AttachmentType === attachmentType);
  };

  render() {
    const { open, lstFileAttachments, lstFundingRequest, lstFundedCompanies } = store;
    const { data, loading } = this.state;
    return (
      <Modal
        width={'75%'}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={'Funding Agreement'}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div className="form-container">
            <Form name="funding-agreement" className="grid-style estimate-form">
              <Form.Item
                label="Funding Agreement No"
                required={true}
                validateStatus={!data.AgreementNo ? 'error' : null}
                help={!data.AgreementNo ? 'This field is required' : null}
              >
                <Input
                  size="large"
                  type="text"
                  value={data.AgreementNo}
                  onChange={this.handleFieldChange('AgreementNo')}
                />
              </Form.Item>
              <Form.Item label="Funding Request No">
                {/* <Input
                                size="large"
                                type="text"
                                value={data.FundingRqNo}
                                onChange={this.handleFieldChange('FundingRqNo')}
                            /> */}
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={data.RequestId}
                  onChange={this.handleFieldChange('RequestId')}
                >
                  {lstFundingRequest &&
                    lstFundingRequest.map(i => (
                      <Select.Option value={i.Id} key={i.Id}>
                        {i.RequestNo}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Funding Provider">
                <Select size="large" style={{ width: '100%' }} disabled={true} value={data.FundingProvider}>
                  {data.FundingProviderObj && (
                    <Select.Option value={data.FundingProvider}>
                      {data.FundingProviderObj.Id} - {data.FundingProviderObj.Name}
                    </Select.Option>
                  )}
                </Select>
                {/* <Input
                                size="large"
                                disabled={true}
                                type="text"
                                value={data.FundingRqNo}
                                onChange={this.handleFieldChange('FundingRqNo')}
                            /> */}
              </Form.Item>
              <Form.Item label="Funded Company">
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={data.FundedCompanyId}
                  onChange={this.handleFieldChange('FundedCompanyId')}
                >
                  {lstFundedCompanies &&
                    lstFundedCompanies.map(i => (
                      <Select.Option value={i.Id} key={'fund-c-' + i.Id}>
                        {i.Id} - {i.Name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Signing Person (Funding Provider)">
                <Select size="large" style={{ width: '100%' }} disabled={true} value={data.SignPersonProvider}>
                  {data.SignPersonProviderObj && (
                    <Select.Option value={data.SignPersonProvider}>
                      {data.SignPersonProviderObj.Id} - {data.SignPersonProviderObj.FullName}
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item label="Signing Person (Law Firm)">
                <Input
                  type="text"
                  size="large"
                  value={data.SignPersonLawyer}
                  onChange={this.handleFieldChange('SignPersonLawyer')}
                />
              </Form.Item>
              <Form.Item label="Signing Date">
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.SigningDate, true, false)}
                  onChange={this.handleDateFieldChange('SigningDate')}
                />
              </Form.Item>
              <Form.Item label="Funding Period">
                <React.Fragment>
                  <div style={{ float: 'left' }}>
                    <div className="ant-col ant-form-item-control-wrapper">
                      <DatePicker
                        size="large"
                        allowClear={false}
                        format="DD/MM/YYYY"
                        value={renderDate(data.FundingPeriodFrom, true, false)}
                        onChange={this.handleDateFieldChange('FundingPeriodFrom')}
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: '10px', float: 'left' }}>
                    <div className="ant-col ant-form-item-control-wrapper">
                      <DatePicker
                        size="large"
                        allowClear={false}
                        format="DD/MM/YYYY"
                        value={renderDate(data.FundingPeriodTo, true, false)}
                        onChange={this.handleDateFieldChange('FundingPeriodTo')}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </Form.Item>
              <Form.Item label="Gross Margin">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  className="input-manual-rate"
                  addonBefore="$"
                  formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={data.GrossMargin}
                  onChange={this.handleFieldChange('GrossMargin')}
                />
              </Form.Item>
              <Form.Item label="Discount">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  className="input-manual-rate"
                  addonBefore="$"
                  formatter={value => `% ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={data.Discount}
                  onChange={this.handleFieldChange('Discount')}
                />
              </Form.Item>
              <Form.Item label="Funding Amount">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  className="input-manual-rate"
                  addonBefore="$"
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={data.FundingAmount}
                  onChange={this.handleFieldChange('FundingAmount')}
                />
              </Form.Item>
              <Form.Item label="Funding Amount Remaining">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  className="input-manual-rate"
                  addonBefore="$"
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={data.FundingAmountRemaining}
                  onChange={this.handleFieldChange('FundingAmountRemaining')}
                />
              </Form.Item>
              <Form.Item label="" style={{ paddingTop: '20px' }}>
                <div>
                  Overfunding Limit Applicable:
                  <span>
                    <Checkbox
                      style={{ paddingTop: '4px', paddingLeft: '10px' }}
                      onChange={this.handleCheckboxFieldChange('OverfundingLimitApplicable')}
                      checked={data.OverfundingLimitApplicable}
                    ></Checkbox>
                  </span>
                </div>
              </Form.Item>
              <Form.Item label="Facility Expiry Date">
                <DatePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY"
                  value={renderDate(data.FacilityExpiryDate, true, false)}
                  onChange={this.handleDateFieldChange('FacilityExpiryDate')}
                />
              </Form.Item>
              <Form.Item label="Attached Agreement" className="fluid-field">
                <DragDropFile
                  onUploadFile={this.dragdropUploadFile}
                  onDeleteFile={this.dragdropDeleteFile}
                  type={1}
                  maxSize={50}
                  multiple={true}
                  uploadTitle={'Attachment'}
                  renderAttachment={this.renderAttachment}
                  Attachments={lstFileAttachments}
                  baseUrl="/UserFile/FundingAgreementAttachmentPath"
                />
              </Form.Item>
            </Form>
          </div>
        )}
        {this.openConfirm && <ModalConfirm {...this.confirmParams} open={this.openConfirm} />}
      </Modal>
    );
  }
}
export default ModalUpdate;
