import React from 'react';

import AnonymousLoading from '../anonymous/AnonymousLoading';
import logout from '../anonymous/logout';

class Logout extends React.Component {
  componentDidMount() {
    logout();
  }
  render() {
    return <AnonymousLoading message="Logging out" />;
  }
}

export default Logout;
