import React from 'react';
import Chart from 'react-apexcharts';
import { observer } from 'mobx-react';

import DoctorDetailsStore from './DoctorDetailsStore';
import { toJS } from 'mobx';
// import moment from 'moment';
import { Spin } from 'antd';
import { Grid, Segment, Menu } from 'semantic-ui-react';

@observer
class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: 'last10',
      totalDateChart: {
        series: [],
        options: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
          },
          plotOptions: {
            bar: {
              columnWidth: '30%',
            },
          },

          labels: [],
          markers: {
            size: 0,
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            position: 'top',
          },
          yaxis: {
            title: {
              text: 'Total Date',
            },
            labels: {
              formatter: function(val) {
                return `${val.toFixed(1)} ${val === 1 ? 'Day' : 'Days'}`;
              },
            },
          },
        },
      },

      firstStatisticData: {
        series: [],
        options: {
          xaxis: {
            categories: ['Min', 'Average', 'Max'],
          },
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return `${val.toFixed(1)} ${val === 1 ? 'Day' : 'Days'}`;
              },
            },
          },
          stroke: {
            curve: 'smooth',
          },
        },
      },
      middleStatisticData: {
        series: [],
        options: {
          xaxis: {
            categories: ['Min', 'Average', 'Max'],
          },
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return `${val.toFixed(1)} ${val === 1 ? 'Day' : 'Days'}`;
              },
            },
          },
          stroke: {
            curve: 'smooth',
          },
        },
      },

      lastestStatisticData: {
        series: [],
        options: {
          xaxis: {
            categories: ['Min', 'Average', 'Max'],
          },
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return `${val.toFixed(1)} ${val === 1 ? 'Day' : 'Days'}`;
              },
            },
          },
          stroke: {
            curve: 'smooth',
          },
        },
      },

      loadingChart: false,
    };
  }
  componentDidMount() {
    const { loading, dataChart } = DoctorDetailsStore;
    if (!loading && dataChart) {
      const data = toJS(DoctorDetailsStore.dataChart);
      const totalBookings =
        this.state.activeMenu === 'last10'
          ? 10
          : this.state.activeMenu === 'last20'
          ? 20
          : this.state.activeMenu === 'All'
          ? data.itemList.length
          : 0;

      const listCaseNo = data.itemList.filter((_, idx) => idx < totalBookings).map(i => i.CaseNo);
      const listTotalDate = data.itemList.filter((_, idx) => idx < totalBookings).map(i => i.TotalDays);
      const firstStatistic = {
        name: 'First Statistic',
        data: Object.entries(data.firstStatistic)
          .map(([_, value]) => value.toFixed(2))
          .sort(function(a, b) {
            return a - b;
          }),
      };
      const middleStatistic = {
        name: 'Middle Statistic',
        data: Object.entries(data.middleStatistic)
          .map(([_, value]) => value.toFixed(2))
          .sort(function(a, b) {
            return a - b;
          }),
      };
      const lastestStatistic = {
        name: 'Lastest Statistic',
        data: Object.entries(data.latestStatistic)
          .map(([_, value]) => value.toFixed(2))
          .sort(function(a, b) {
            return a - b;
          }),
      };
      this.setState(
        state => {
          const { options, series } = state.totalDateChart;
          state.totalDateChart.series = [...series, { name: 'Total Date', data: listTotalDate, type: 'line' }];
          state.firstStatisticData.series = [firstStatistic];
          state.middleStatisticData.series = [middleStatistic];
          state.lastestStatisticData.series = [lastestStatistic];
          state.totalDateChart.options = { ...options, labels: listCaseNo };
        },
        () => this.setState({ loadingChart: false }),
      );
    }
  }

  handleItemClick = (_, { name }) => {
    this.setState({ activeMenu: name });
    const data = toJS(DoctorDetailsStore.dataChart);
    const totalBookings = name === 'All' ? data.itemList.length : parseInt(name.slice(4));

    const listCaseNo = data.itemList.filter((_, idx) => idx < totalBookings).map(i => i.CaseNo);
    const listTotalDate = data.itemList.filter((_, idx) => idx < totalBookings).map(i => i.TotalDays);
    this.setState(state => {
      const { options } = state.totalDateChart;
      state.totalDateChart.series = [{ name: 'Total Date', data: listTotalDate, type: 'line' }];
      state.totalDateChart.options = { ...options, labels: listCaseNo };
    });
  };

  render() {
    const {
      loadingChart,
      totalDateChart,
      firstStatisticData,
      middleStatisticData,
      lastestStatisticData,
      activeMenu,
    } = this.state;
    const { dataChart } = DoctorDetailsStore;
    return (
      <React.Fragment>
        {loadingChart ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Segment>
                    <div>
                      <label style={{ fontWeight: 'bold', marginRight: '20px' }}>Top 25%</label>
                    </div>
                    <label>
                      Average: {firstStatisticData.series[0]?.data[1] || 0}{' '}
                      {`${firstStatisticData.series[0]?.data[1] === '1.00' ? 'Day' : 'Days'}`}
                    </label>
                    <Chart options={firstStatisticData.options} series={firstStatisticData.series} type="area" />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <div>
                      <label style={{ fontWeight: 'bold', marginRight: '20px' }}>Middle 50%</label>
                    </div>
                    <label>
                      Average: {middleStatisticData.series[0]?.data[1] || 0}{' '}
                      {`${middleStatisticData.series[0]?.data[1] === '1.00' ? 'Day' : 'Days'}`}
                    </label>
                    <Chart options={middleStatisticData.options} series={middleStatisticData.series} type="area" />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <div>
                      <label style={{ fontWeight: 'bold', marginRight: '20px' }}>Bottom 25%</label>
                    </div>
                    <label>
                      Average: {lastestStatisticData.series[0]?.data[1] || 0}{' '}
                      {`${lastestStatisticData.series[0]?.data[1] === '1.00' ? 'Day' : 'Days'}`}
                    </label>
                    <Chart options={lastestStatisticData.options} series={lastestStatisticData.series} type="area" />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Segment>
                    {dataChart.itemList && (
                      <Menu pointing secondary>
                        {Array.from({
                          length: Math.ceil(dataChart.itemList.length / 10),
                        }).map((_, idx) => (
                          <Menu.Item
                            name={`last${(idx + 1) * 10}`}
                            key={idx}
                            active={activeMenu === `last${(idx + 1) * 10}`}
                            onClick={this.handleItemClick}
                          >
                            {`Last ${(idx + 1) * 10} Bookings`}
                          </Menu.Item>
                        ))}
                        <Menu.Item name="All" labels="" active={activeMenu === 'All'} onClick={this.handleItemClick}>
                          All Of Bookings
                        </Menu.Item>
                      </Menu>
                    )}
                    <Chart options={totalDateChart.options} series={totalDateChart.series} type="line" />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ApexChart;
