import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import ClinicStore from './store';
import Modal from '../../shared/Modal';
import { TableHeader } from '../../shared/table';

const columns = [{ title: 'Clinic Name' }, { title: 'Address' }, { title: 'Suburb' }, { title: 'State' }];

@observer
class ModalDuplicate extends React.Component {
  renderModalTitle = () => {
    if (!ClinicStore.duplicatedData.length) {
      return 'Duplicate message';
    }
    return 'List of similar medical/service centres';
  };

  renderModalBody = () => {
    if (!ClinicStore.duplicatedData.length) {
      return 'No similar clinics are found';
    }
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {ClinicStore.duplicatedData.map((record, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{record.Name}</Table.Cell>
              <Table.Cell>{record.Address}</Table.Cell>
              <Table.Cell>{record.Suburb}</Table.Cell>
              <Table.Cell>{record.State}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  render() {
    return (
      <Modal
        visible={!ClinicStore.duplicateLoading && !!ClinicStore.duplicatedData}
        width={700}
        onCancel={ClinicStore.handleCloseModal}
        title={ClinicStore.duplicatedData && this.renderModalTitle()}
      >
        {ClinicStore.duplicatedData && this.renderModalBody()}
      </Modal>
    );
  }
}

export default ModalDuplicate;
