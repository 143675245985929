import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import TypistModal from './TypistModal';
import TypistStore from './TypistStore';
import TypistTable from './TypistTable';
import TypistToolbar from './TypistToolbar';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';

class Typist extends React.Component {
  state = {
    companyId: 0,
  };
  componentDidMount() {
    TypistStore.fetchCase(true);
    TypistStore.fetchClient();
    ui.on('open(/view/typist)', this._searchDebounced);
    this._searchDebounced();
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?clientId=') !== -1) {
        const id = search.slice(10);
        return this.setState({ companyId: id }, () =>
          TypistStore.refetch(
            {
              companyId: id,
              sortKey: 'Id',
              sortColumn: 'ID',
              sortDirection: 'descending',
            },
            true,
          ),
        );
      }
    } else {
      const { companyId } = this.state;
      if (companyId === 0) {
        localStorage.removeItem('ManageCmName');
        return TypistStore.refetch({ companyId: 0 }, true);
      }
    }
    return TypistStore.fetchCase(true);
  }, 1000);

  render() {
    const { loading, data, open, loadingSearch, dataSearch, loadingClient, dataClient } = TypistStore;
    return (
      <div
        className={`Table-${
          (loading && !data) || (loadingSearch && !dataSearch) || (loadingClient && !dataClient)
            ? 'loading'
            : 'container'
        }`}
      >
        {(loading && !data) || (loadingSearch && !dataSearch) || (loadingClient && !dataClient) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <TypistToolbar />
            <TypistTable />
            {open && <TypistModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(Typist);
