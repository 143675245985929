import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import ImagingStore from './ImagingStore';
import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'Case No ' },
  { title: 'Claim No' },
  { title: 'Type of Claim' },
  { title: 'Claimant Name' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Imaging Centre' },
  { title: 'Doctor/Consultant' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
  { title: 'Action Required' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

@observer
class ImagingTable extends React.Component {
  openModal = options => {
    return ImagingStore.toggleModal(true, options);
  };

  editImagingCase = id => () => {
    const caseNo = `Case I-${(id + '').padStart(6, 0)}`;
    localStorage.setItem(`ImagingCaseId`, caseNo);
    iframe.forceOpenTab('PostMRI', `?id=${id}&view=PostMRI`, {
      id,
      view: 'PostMRI',
    });
  };

  addInvoice = Id => () => {
    localStorage.removeItem(`InvoiceCaseId`);
    iframe.forceOpenTab('ViewInvoice', `?id=${0}&mId=${Id}&type=M&view=ViewInvoice`, {
      id: 0,
      mId: Id,
      type: 'M',
      view: 'ViewInvoice',
    });
  };

  viewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  printInvoice = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  renderActionRequired = record => {
    if (!record.IsActionRequired && !record.IsUrgentReport) {
      return `None`;
    }
    if (record.IsActionRequired) {
      return `Other Action - Due Date  ${
        record.ActionRequiredDueDate
          ? moment(formatDate(record.ActionRequiredDueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'
      } - PIC: ${record.ActionRequiredStaffName}`;
    }
    if (record.IsUrgentReport) {
      return `Urgent Report - Due Date  ${
        record.UrgentReportDueDate
          ? moment(formatDate(record.UrgentReportDueDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'
      } - PIC: ${record.UrgentReportStaffName}`;
    }
  };

  renderDoctorConsultant = record => {
    return (
      <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
        <div>
          {record.DoctorName} <br />
          {record.DoctorRegisterOption || ''} <br />
          {record.DoctorSpecialty && `Specialty: ${record.DoctorSpecialty}`}
          {record.DoctorSpecialty && <br />}
          {record.DoctorEmail || ``}
          {record.DoctorEmail && <br />}
          <span>
            <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
          </span>
        </div>
      </Table.Cell>
    );
  };

  handlePageClick = page => {
    ImagingStore.refetch({ curPage: page });
  };

  convertEmailToUserName = email => {
    return email.replace(/^(.+)@(.+)$/g, '$1');
  };

  renderTableBody = () => {
    return ImagingStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.editImagingCase(Id)}>
            {record.CaseNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
        <Table.Cell>
          {record.Claimant} <br />{' '}
          {`DOB: 
          ${record.ClaimantDoB ? moment(formatDate(record.ClaimantDoB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}`}
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>{record.Client}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.CaseManager} <br />
          {record.CMMobilePhone && (
            <span>
              Phone: <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
            </span>
          )}
          {record.CMEmail && <br />}
          {record.CMEmail && `Email: ${record.CMEmail}`}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.ClinicName}</Table.Cell>
        {this.renderDoctorConsultant(record)}
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.CaseNumber,
            })}
          >
            {record.CaseStatus}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          {record.IsActionRequired || record.IsUrgentReport ? (
            <div style={{ color: `red` }}>{this.renderActionRequired(record)}</div>
          ) : (
            <div>{this.renderActionRequired(record)}</div>
          )}
          <br />
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.CaseNumber,
              dataNotes: record,
            })}
          >
            (View)
          </Link>
        </Table.Cell>
        <Table.Cell>{this.convertEmailToUserName(record.CreatedBy)}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.editImagingCase(id)}>Edit</Menu.Item>
          {record.CaseStatus === `Report Completed` && <Menu.Divider fitted />}
          {record.CaseStatus === `Service Billed` && <Menu.Divider fitted />}
          {record.CaseStatus === `Report Completed` && <Menu.Item onClick={this.addInvoice(id)}>Add Invoice</Menu.Item>}
          {record.CaseStatus === `Service Billed` && (
            <Menu.Item onClick={this.viewInvoice(record.InvoiceId)}>View Invoice</Menu.Item>
          )}
          {record.CaseStatus === `Service Billed` && (
            <Menu.Item onClick={this.printInvoice(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print Invoice
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data } = ImagingStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={ImagingStore.sortColumn}
          sortDirection={ImagingStore.sortDirection}
          onSort={ImagingStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ImagingStore.curPage}
          totalPage={ImagingStore.totalPage}
          totalItems={ImagingStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ImagingTable;
