import './SessionCalendar.scss';

import { observer } from 'mobx-react';
import React from 'react';

import Calendar from './Calendar';

@observer
class SessionCalendar extends React.Component {
  render() {
    return (
      <div className="Session-calendar-container">
        <Calendar />
      </div>
    );
  }
}

export default SessionCalendar;
