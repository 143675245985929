import React from 'react';
import { observer } from 'mobx-react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Spin } from 'antd';
import Modal from '../../shared/Modal';
import Store from './Store';
import { viewAMAUpdateHistory } from './Service';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import moment from 'moment';
import { formatDate } from '../../../utils/functions';

const columns = [
  // { title: 'Calculation Name'},
  { title: 'AMA Code Range' },
  { title: 'Action' },
  { title: 'Updated Date' },
  { title: 'Updated By' },
];

@observer
class AMAUpdateHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Id: Store.modalParams.Id,
      CalculationName: Store.modalParams.CalculationName,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Promise.all([viewAMAUpdateHistory(this.state.Id)]).then(([resp]) => {
      if (resp.status === 'success') {
        Store.setListHistory(resp.itemList);
      }
      this.setState({ loading: false });
    });
  }

  // componentWillUnmount() {
  //   this.handleResetDataModal();
  // }

  onCancel = () => {
    this.props.onCancel();
  };

  renderTableBody = (colSpan, data) => {
    if (!data || !data.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        {/* <Table.Cell className="Cell-actions">
          {record.CalculationName}
        </Table.Cell> */}
        <Table.Cell className="Cell-actions">{record.AMACodeRange}</Table.Cell>
        <Table.Cell className="Cell-actions">{record.Action}</Table.Cell>
        <Table.Cell className="Cell-actions">
          {record.CreatedDate ? moment(formatDate(record.CreatedDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell className="Cell-actions">{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading } = this.state;
    const { open, lstHistories } = Store;
    const colSpan = columns.length;
    return (
      <Modal
        visible={open}
        width={800}
        onCancel={this.props.onCancel}
        footer={
          <React.Fragment>
            <Button color="red" onClick={this.onCancel}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title={this.state.CalculationName + ' - Applicable Item Ranges'}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Table striped celled>
              <TableHeader columns={columns} />
              <Table.Body>{this.renderTableBody(colSpan, lstHistories)}</Table.Body>
            </Table>
          </div>
        )}
      </Modal>
    );
  }
}

export default AMAUpdateHistory;
