import { action, observable } from 'mobx';
import moment from 'moment';
import customFetch from '@utils/customFetch';
import dashboard from '../../../stores/dashboard';
import { PRODUCTION_HOST } from '@utils/constants';
import Validator from 'validatorjs';

const publicIp = require('public-ip');

class VideoSessionStore {
  @observable loading = true;
  @observable data = null;
  @observable dataSession = null;
  @observable claimant = { Id: ``, FullName: `` };

  @observable assessmentId = 0;
  @observable videoSessionId = 0;

  @observable sortKey = '';
  @observable numberSearch = 30;
  @observable sortDirection = 'DESC';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable searchClaimantOnly = false;
  @observable pageSize = 20;
  @observable FilterType = 'All';
  @observable keyword = '';

  @observable fromToRequest = '';

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action validateField = (value, type) => {
    if (type && value) {
      const v = new Validator({ value }, { value: type });
      return v.passes();
    }
    return true;
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchMain();
  };

  @action fetchMain = (setTotalPage = false, assessmentId = 0) => {
    this.loading = true;
    this.data = null;
    const { fromDate, toDate } = this.getDateRange();

    let from = '';
    if (fromDate) {
      from = this.showBookingToday ? fromDate.format() : fromDate.toISOString();
    }
    customFetch('/VideoSession/VideoSession_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ClaimantId: this.claimant.Id,
        CurPage: this.curPage,
        FilterType: this.FilterType,
        From: from,
        To: toDate,
        Keyword: this.keyword,
        SearchClaimantOnly: this.searchClaimantOnly,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        NumItemPerPage: this.pageSize,
        VideoSessionId: this.videoSessionId,
        AssessmentId: assessmentId,
      }),
    }).then(
      action(data => {
        if (data.itemList.length === 0) {
          dashboard.close(`/view/video-session?assessmentId=${assessmentId}`, true);
          setTimeout(() => {
            dashboard.open(`/view/ime-assessments-2`, true);
          });
        }
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
        this.showBookingToday = false;
      }),
    );
  };

  @action viewSession = async token => {
    this.loading = true;
    this.dataSession = null;
    const ip = await publicIp.v4();
    const data = await customFetch('/VideoSession/ViewVideo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: token,
        ipAddress: ip,
      }),
    });

    action(data => {
      this.loading = false;
      this.jaasToken = data.item.Token;

      if (data.status === 'success') {
        var domain = 'https://staging.scopevideo.com.au';
        if (window.location.hostname === PRODUCTION_HOST) {
          domain = 'https://scopevideo.com.au';
        }
        window.location = `${domain}/${data.item.Token}?jwt=${data.Video_JWT}`;
      }
    })(data);
  };

  getJaasVideoToken = async sessionToken => {
    const ip = await publicIp.v4();
    console.log(ip);
    const resp = await customFetch('/VideoSession/GenerateJaasVideoToken', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: sessionToken,
        ipAddress: ip,
      }),
    });

    return resp;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleResetStore = () => {
    this.data = null;
    this.loading = true;
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromToRequest.split(' - ');
    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  @action refetch = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchMain(setTotalPage);
  };
}

export default new VideoSessionStore();
