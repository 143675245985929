import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from 'semantic-ui-react';
import MailList from './MailList';
import MailSearch from './MailSearch';
import MailDetail from './MailDetail';
import ReplyModal from './ReplyModal';
import ActionNotesModal from './ActionNotesModal';
import Store from './Store';
import './MailSearch.scss';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class IMS extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/ims-approval-2', true);
      return;
    }

    Store.fetchMails(true);
    Store.loadPicList();
    Store.loadAssignedPicList();
    Store.loadAssignedStatusCount();
    Store.loadAssignedCaseOnwers();
    Store.loadInquiryKeySetting();
  }

  render() {
    const { selectedMail, isReplyModalOpen, isActionNotesModalOpen } = Store;

    return (
      <>
        <Grid>
          <Grid.Row>
            <MailSearch />
          </Grid.Row>
          <Grid.Row className="no-padding-top">
            <Grid.Column width={7}>
              <MailList />
            </Grid.Column>
            <Grid.Column width={9}>
              {selectedMail && (
                <>
                  <MailDetail selectedMail={selectedMail} />
                  {isReplyModalOpen && <ReplyModal selectedMail={selectedMail} isOpen={true} />}
                </>
              )}
              {isActionNotesModalOpen && <ActionNotesModal selectedMail={selectedMail} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer> */}
      </>
    );
  }
}

export default IMS;
