import React from 'react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

function ModalUserAlert({ open, onCancel, modalParams }) {
  const { message } = modalParams;

  const renderConfirmMessage = () => <div className="modal-confirm-message">{message}</div>;

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="red" onClick={onCancel}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalUserAlert;
