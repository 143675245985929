import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import IMSApprovalCodeStore from './IMSApprovalCodeStore';

const columns = [
  { title: '' },
  {
    title: 'PAYMENT CLASSIFICATION CODE',
    sortKey: `PaymentClassificationCode`,
  },
  { title: 'SERVICE DESCRIPTION' },
  { title: 'COMMENTS' },
  { title: 'STATUS', sortKey: 'IsActive' },
];

@observer
class IMSApprovalCodeTable extends React.Component {
  openModal = options => {
    return IMSApprovalCodeStore.toggleModal(true, options);
  };

  openCaseDetail = (id, imsApproval) => () => {
    localStorage.setItem('ImsApproval', imsApproval);
    iframe.forceOpenTab('AddOrEditIMSApprovalCodeWIRO', `?id=${id}&view=AddOrEditIMSApprovalCodeWIRO`, {
      id,
      view: 'AddOrEditIMSApprovalCodeWIRO',
    });
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        {api.isAdmin() && <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>}
        <Table.Cell selectable className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}>
          <Link to="#" onClick={this.openCaseDetail(Id, record.PaymentClassificationCode)}>
            {record.PaymentClassificationCode}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ServiceDescription}</Table.Cell>
        <Table.Cell>{record.Comments}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
      </Table.Row>
    ));
  };

  handleDelete = id => async () => {
    IMSApprovalCodeStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/IMSApprovalCodeWIRO/DeleteRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await IMSApprovalCodeStore.refetch({}, true);
    } finally {
      IMSApprovalCodeStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = (id, record) => async () => {
    IMSApprovalCodeStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/IMSApprovalCodeWIRO/EditItemStatus', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, status: !record.IsActive }),
      });
      await IMSApprovalCodeStore.refetch({}, true);
    } finally {
      IMSApprovalCodeStore.setFieldsValue({ loading: false });
    }
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && (
            <Menu.Item onClick={this.openCaseDetail(id, record.PaymentClassificationCode)}>Edit</Menu.Item>
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item>
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id, record),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    IMSApprovalCodeStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = IMSApprovalCodeStore;
    const column = api.isAdmin() ? columns.map(i => i) : columns.filter(i => i.title !== '').map(i => i);
    const colSpan = column.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={column}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={IMSApprovalCodeStore.handleSort}
        />
        <Table.Body>{this.renderTableBody(data, colSpan)}</Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={IMSApprovalCodeStore.curPage}
          totalPage={IMSApprovalCodeStore.totalPage || 0}
          totalItems={IMSApprovalCodeStore.totalItems || 0}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default IMSApprovalCodeTable;
