import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import Store from './clinicalNoteStore';
import * as api from '../../../stores/api';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = Store;

    const caseNo = `Case S-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('PostSupplementary', caseNo);
    iframe.forceOpenTab('PostSupplementary', `?id=${id}&view=PostSupplementary#Corespondence`, {
      id,
      view: 'PostSupplementary',
    });

    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = Store;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/Supplementary/GetSupplementaryLogs';
  const body = { Id: modalParams.id };

  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1250}
      title={title}
      footer={
        <React.Fragment>
          {!api.isTypist() && (
            <Button color="blue" onClick={openCaseCorrespondence}>
              <Icon name="table" /> View Case Correspondence
            </Button>
          )}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress progressList={api.SUPP_CaseProgress.data.itemList} url={url} body={body} showHistory />
    </Modal>
  );
}

export default ModalCaseProgress;
