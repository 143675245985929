import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';
import ClientsStore from './ClientsStore';
import AssignDoctorStore from './AssignDoctorStore';
import ModalNotification from './ModalNotification';
import dashboard from '../../../stores/dashboard';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'Client', sortKey: 'Name' },
  { title: 'Action Required' },
  { title: 'Business Account Manager' },
  // { title: 'Address' },
  { title: 'State', sortKey: 'CityId' },
  { title: 'Office Phone' },
  { title: 'Website' },
  { title: 'Business Type' },
  { title: 'Account Status' },
  { title: 'Date Of Status' },
  { title: 'Created Date', sortKey: 'Created' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

@observer
class ClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalNotification: false,
      message: ``,
    };
  }
  openModal = options => {
    return ClientsStore.toggleModal(true, options);
  };

  handlePageClick = page => {
    ClientsStore.refetch({ curPage: page });
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  renderTableBody = () => {
    return ClientsStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          onClick={this.openCaseUpdate(Id, record)}
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
        >
          {!record.IsActive ? <Link to="#">{Id}</Link> : <Link to="#">{Id}</Link>}
        </Table.Cell>
        <Table.Cell
          onClick={this.openCaseUpdate(Id, record)}
          selectable
          className={cx(`${record.IsActive ? `Client-name Cell-link` : `Client-name`}`)}
        >
          {!record.IsActive ? (
            <Link to="#" onClick={this.openCaseUpdate(Id, record)}>
              {record.Name}
            </Link>
          ) : (
            <Link to="#" onClick={this.openCaseUpdate(Id, record)}>
              {record.Name}
            </Link>
          )}
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              modalType: 'notes',
              modalTitle: `${record.Name}`,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          <br />
          <div>
            {record.ActionRequiredType > 0 && record.ActionOverview
              ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
              : ''}
          </div>
        </Table.Cell>
        <Table.Cell>{record.BusinessAccountManager}</Table.Cell>
        {/* <Table.Cell className={cx(`${record.FullAddress && `Full-address`}`)}>{record.FullAddress}</Table.Cell> */}
        <Table.Cell>{record.CityName}</Table.Cell>
        <Table.Cell>
          <FormatPhoneNumber officePhoneNumber={record.Telephone} />
        </Table.Cell>
        <Table.Cell className={cx(`${record.Website && `Full-address`}`)}>{record.Website}</Table.Cell>
        <Table.Cell>
          {record.ClientType === 1
            ? `Insurer`
            : record.ClientType === 2
            ? `Law Defendant`
            : record.ClientType === 3
            ? `Law Plaintiff`
            : record.ClientType === 4
            ? `Law Practice`
            : record.ClientType === 5
            ? `Other`
            : record.ClientType === 6
            ? `Self Insurer`
            : record.ClientType === 7
            ? `Interpreter Provider`
            : null}
        </Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `InActive`}</p>
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        {/* <Table.Cell
          className={cx(
            `${
              record.ActionRequired
                ? `Cell-link Cell-Action-Required`
                : `Cell-link`
            }`,
          )}
        >
          {record.ActionRequired ? (
            <div>
              Yes - DueDate:{' '}
              {record.DueDate
                ? moment(formatDate(record.DueDate), 'DD MMM YYYY').format(
                    'DD/MM/YYYY',
                  )
                : 'N/A'}
            </div>
          ) : (
            <div>No</div>
          )}
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.id,
            })}
          >
            <span>(view)</span>
          </Link>
        </Table.Cell> */}
        <Table.Cell>
          {record.Created ? moment(formatDate(record.Created), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  handleEditItemStatus = id => async () => {
    ClientsStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/Company/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      }).then(data => {
        if (data.status === 'fail') {
          this.setState({
            openModalNotification: true,
            message: data.message,
          });
        }
      });
      await ClientsStore.fetchClients({}, true);
    } finally {
      ClientsStore.setFieldsValue({ loading: false });
    }
  };

  fetchClinicDoctor = async clinicDoctors => {
    if (clinicDoctors.length === 0) {
      AssignDoctorStore.fetchDoctor(true);
      AssignDoctorStore.setFieldsValue({
        filter: `All`,
      });
    } else {
      AssignDoctorStore.setFieldsValue({
        loading: true,
        doctors: clinicDoctors,
      });
      await customFetch('/MedicalDoctor/GetDoctorByIdList', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(clinicDoctors.map(i => i)),
      }).then(
        action(data =>
          AssignDoctorStore.setFieldsValue({
            data,
            loading: false,
            totalPage: data.sumPage || AssignDoctorStore.totalPage,
            curPage: 1,
          }),
        ),
      );
    }
  };

  handleAssignDoctor = (cId, record) => () => {
    // customFetch('/Company/GetCompanyDoctorIds', {
    //   headers: { 'Content-Type': 'application/json' },
    //   method: 'POST',
    //   body: JSON.stringify({ cId }),
    // })
    //   .then(action(data => this.fetchClinicDoctor(data.itemList)))
    //   .then(
    //     this.openModal({
    //       modalType: 'assignDoctor',
    //       record,
    //       cId,
    //     }),
    //   );
    localStorage.setItem('CompanyName', record.Name);
    dashboard.close('/view/add-edit-client-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-client-2?id=${cId}&action=edit&step=4`);
    });
  };

  handleViewBookingHistory = (id, record) => {
    ClientsStore.fetchBookingHistory(id);
    ClientsStore.setFieldsValue({
      clientId: id,
      dataBookingHistory: null,
      totalBooking: 0,
    });
    ClientsStore.toggleModal(true, {
      modalType: 'bookingHistory',
      clientId: id,
      record,
    })();
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseUpdate(id, record)}>Edit</Menu.Item>
          <Menu.Item onClick={() => this.handleViewBookingHistory(id, record)}>View Booking History</Menu.Item>

          <Menu.Item onClick={this.openManageCaseManagers(id, record)}>Manage CMs</Menu.Item>

          {record.IsActive && (
            <Menu.Item onClick={this.handleAssignDoctor(id, record)}>Manage Favourite Specialists</Menu.Item>
          )}

          <Menu.Item onClick={this.openManageBusinessUnits(id, record)}>Manage Business Units</Menu.Item>

          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to enable client '${record.Name}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to disable client '${record.Name}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Disable
            </Menu.Item>
          )}

          {/* <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item> */}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  openCaseUpdate = (id, record) => () => {
    localStorage.setItem('CompanyName', record.Name);
    dashboard.close('/view/add-edit-client-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-client-2?id=${id}&action=edit`);
    });
  };

  openManageCaseManagers = (id, name) => () => {
    localStorage.setItem('ManageCmName', `Case Managers - ${name.Name}`);
    dashboard.open(`/view/case-managers-2?clientId=${id}`);
  };

  openManageBusinessUnits = (id, record) => () => {
    localStorage.setItem('CompanyName', record.Name);
    dashboard.close('/view/add-edit-client-2', true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-client-2?id=${id}&action=edit&step=2`);
    });
  };

  handleDelete = id => async () => {
    ClientsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Company/Delete', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });
      await ClientsStore.refetch({}, true);
    } finally {
      ClientsStore.setFieldsValue({ loading: false });
    }
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalNotification
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { loading, data } = ClientsStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        {this.renderModal()}
        <TableHeader
          columns={columns}
          sortColumn={ClientsStore.sortColumn}
          sortDirection={ClientsStore.sortDirection}
          onSort={ClientsStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ClientsStore.curPage}
          totalPage={ClientsStore.totalPage}
          totalItems={ClientsStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ClientsTable;
