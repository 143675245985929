import React from 'react';

import ModalAlert from './ModalConfirm';
import ModalConfirm from '../../../shared/Modal/ModalConfirm';
import DoctorDetailsStore, { DoctorAttachmentType } from './DoctorDetailsStore';
import ModalAddClinic from './ModalAddClinic';
import ModalProfilePicture from './ModalProfilePicture';
import ModalConversation from './ModalConversation';
import ModalChangePassword from './ModalChangePassword';

function DoctorDetailsModal() {
  const { modalType } = DoctorDetailsStore.modalParams;
  if (modalType === 'alert') {
    return (
      <ModalAlert
        open={DoctorDetailsStore.open}
        message={DoctorDetailsStore.modalParams.message}
        onCancel={DoctorDetailsStore.toggleModal(false)}
      />
    );
  }
  if (modalType === 'confirm') {
    return <ModalConfirm {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'uploadPicture') {
    return (
      <ModalProfilePicture
        open={DoctorDetailsStore.open}
        attachmentType={DoctorAttachmentType.ProfilePicture}
        toggleModal={DoctorDetailsStore.toggleModal}
        uploadFile={DoctorDetailsStore.uploadFile}
        uploadFileFromBase64={DoctorDetailsStore.uploadFileFromBase64}
      />
    );
  }
  if (modalType === 'addEditClinic') {
    return <ModalAddClinic {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
  if (modalType === 'addEditConversation') {
    return <ModalConversation data={DoctorDetailsStore.modalParams.conversation} />;
  }
  if (modalType === 'changePassword') {
    return <ModalChangePassword {...DoctorDetailsStore} onCancel={DoctorDetailsStore.toggleModal(false)} />;
  }
}

export default DoctorDetailsModal;
