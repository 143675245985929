import './DoctorDetails.scss';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import cx from 'classnames';
import Validator from 'validatorjs';
import { Link } from 'react-router-dom';
import { Icon, Menu, Grid, Image, Tab, Checkbox, Form, Button, Input, Dropdown } from 'semantic-ui-react';
import { Spin } from 'antd';
import * as api from '@stores/api';
import ActionRequired from './ActionRequiredTab';
import Address from './Address';
import CaseCorrespondence from './CaseCorrespondence';
import MedicalCentreTab from './MedicalCentreTab';
import PaymentInformationTab from './PaymentInformationTab';
import GeneralInformationTab from './GeneralInformationTab';
import RecruitmentLoginInformationTab from './RecruitmentLoginInformation';
import CustomButton from '../../../shared/CustomButton';
import DoctorDetailsStore from './DoctorDetailsStore';
import DoctorDetailModal from './DoctorDetailModal';
import DoctorDashBoard from './Chart';
import dashboardStore from '@stores/dashboard';

@observer
class DoctorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridCol: {
        gridImg: 4,
        gridProfile: 12,
      },
    };
  }
  titleOptions = [
    {
      text: 'Dr',
      value: 'Dr',
    },
    {
      text: 'A. Prof',
      value: 'A. Prof',
    },
    {
      text: 'Professor',
      value: 'Professor',
    },
    {
      text: 'Mr',
      value: 'Mr',
    },
    {
      text: 'Mrs',
      value: 'Mrs',
    },
  ];

  @action componentWillUnmount() {
    DoctorDetailsStore.isEditing = false;
  }
  @action componentDidMount() {
    DoctorDetailsStore.fetchDoctorProfile();
  }

  renderTabGenerlInformation = () => {
    return <GeneralInformationTab />;
  };
  renderTabAddress = () => {
    return <Address />;
  };
  renderTabPaymentInformation = () => {
    return <PaymentInformationTab />;
  };

  renderMedicalCentre = () => {
    return <MedicalCentreTab />;
  };

  renderActionRequire = () => {
    return <ActionRequired />;
  };

  renderCaseCorrespondence = () => {
    return <CaseCorrespondence />;
  };

  renderRecruitmentLoginInformationTab = () => {
    return <RecruitmentLoginInformationTab />;
  };

  renderDoctorDashBoar = () => {
    return <DoctorDashBoard />;
  };

  callDoctor = phone => {
    if (phone) {
      window.open(`tel:${phone}`);
    } else {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Doctor does not have a phone number.',
      })();
    }
  };

  getProfileImageUrl = () => {
    if (!DoctorDetailsStore.profilePictureAttachments.length) {
      return 'https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg';
    }
    const avatar = toJS(DoctorDetailsStore.profilePictureAttachments[0]);
    return avatar.Id
      ? `/UserFile/DoctorAttachment/${avatar.PhysicalFileName}`
      : `/UserFile/UploadFileTemp/${avatar.PhysicalFileName}`;
  };

  renderIndustryType = arrayId => {
    const matchItem = arrayId
      .map(id => DoctorDetailsStore.dataLookupConfig.indItemList.find(i => i.Id === id))
      .map(i => i.Label);
    return matchItem;
  };

  @action openUploadModal = () => {
    DoctorDetailsStore.toggleModal(true, {
      modalType: 'uploadPicture',
    })();
  };

  @action removeProfilePicture = () => {
    DoctorDetailsStore.profilePictureAttachments = [];
  };

  validateEmail = (emailStr, type) => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    } else {
      if (
        type === 'businessEmail' &&
        (DoctorDetailsStore.dataDoctor.EnableLogin || DoctorDetailsStore.dataDoctor.IsOnboarding)
      ) {
        return 'This field is required';
      } else {
        return null;
      }
    }
  };

  validatePhone = phoneNumber => {
    if (phoneNumber) {
      const validation = new Validator({ phone: phoneNumber }, { phone: 'max:10' }, '');
      if (validation.passes()) {
        return null;
      }
      return 'Must be less than or equal to 10 digits';
    }
    return null;
  };

  handleChangePassword = () => {
    return DoctorDetailsStore.toggleModal(true, {
      modalType: 'changePassword',
    })();
  };

  openClinicCalendar = () => {
    const { dataDoctor } = DoctorDetailsStore;
    const doctorSession = {
      doctorId: dataDoctor?.Id || 0,
      calendarViewMode: 'week',
    };
    localStorage.setItem('Data:DoctorSessionDateSelected', JSON.stringify(doctorSession));
    dashboardStore.close('/view/calendar-2');
    setTimeout(() => {
      dashboardStore.open(`/view/calendar-2`);
    });
  };

  render() {
    const { gridCol } = this.state;
    const {
      open,
      loading,
      dataActiveReportTypeList,
      dataCity,
      dataConversationLogLoadList,
      dataDoctor,
      dataLookupConfig,
      dataLookupConfigByParents,
      dataStaff,
      dataState,
      formatPhoneNumber,
      clinicList,
      isEditing,
      setFieldValue,
      toggleCheckbox,
      dataChart,
    } = DoctorDetailsStore;

    const renderTabPanes = [
      {
        menuItem: (
          <Menu.Item key="General Information">
            <label className="Tab-label">Profile-Subscription-Login</label>
          </Menu.Item>
        ),
        render: () => this.renderTabGenerlInformation(),
      },
      {
        menuItem: (
          <Menu.Item key="Office And Mailing Address">
            <label className="Tab-label">Office & Mailing Address</label>
          </Menu.Item>
        ),
        render: () => this.renderTabAddress(),
      },
      {
        menuItem: (
          <Menu.Item key="Recruitment And Login Information">
            <label className="Tab-label">Recruitment Information</label>
          </Menu.Item>
        ),
        render: () => this.renderRecruitmentLoginInformationTab(),
      },

      {
        menuItem: (
          <Menu.Item key="Payment & Rates">
            <label className="Tab-label">Payment & Rates</label>
          </Menu.Item>
        ),
        render: () => this.renderTabPaymentInformation(),
      },
      {
        menuItem: (
          <Menu.Item key="Medical Centres">
            <label className="Tab-label">Medical Centres</label>
          </Menu.Item>
        ),
        render: () => this.renderMedicalCentre(),
      },
      {
        menuItem: (
          <Menu.Item key="Case Correspondence">
            <label className="Tab-label">Case Correspondence</label>
          </Menu.Item>
        ),
        render: () => this.renderCaseCorrespondence(),
      },
      {
        menuItem: (
          <Menu.Item key="Activity Dashboar" disabled={!dataChart}>
            <label className="Tab-label">Activity Dashboard</label>
          </Menu.Item>
        ),
        render: () => this.renderDoctorDashBoar(),
      },
    ];
    return (
      <React.Fragment>
        <div
          className={`${loading &&
            (!dataActiveReportTypeList ||
              !dataCity ||
              !dataConversationLogLoadList ||
              !dataDoctor ||
              !dataLookupConfig ||
              !dataLookupConfigByParents ||
              !dataStaff ||
              !dataState ||
              !clinicList) &&
            'Form-Loading-DR'}`}
        >
          {loading &&
          (!dataActiveReportTypeList ||
            !dataCity ||
            !dataConversationLogLoadList ||
            !dataDoctor ||
            !dataLookupConfig ||
            !dataLookupConfigByParents ||
            !dataStaff ||
            !dataState ||
            !clinicList) ? (
            <Spin size="large" />
          ) : (
            <React.Fragment>
              {loading ? (
                <div className="Form-Loading-DR">
                  <Spin size="large" />
                </div>
              ) : (
                <div className="DoctorDetail">
                  <Grid divided="vertically">
                    <Grid.Row>
                      <Grid.Column textAlign="center" width={gridCol.gridImg}>
                        <div className="Avatar">
                          <Image src={this.getProfileImageUrl(dataDoctor)} />
                        </div>
                        {isEditing && (
                          <div className="Avatar-Actions">
                            {!api.isOnlySearch() && <Button className="blue" onClick={this.openUploadModal}>
                              Upload
                            </Button>}
                            {!api.isOnlySearch() && <Button
                              className="negative"
                              onClick={this.removeProfilePicture}
                              disabled={!DoctorDetailsStore.profilePictureAttachments.length}
                            >
                              Delete
                            </Button>}
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column width={gridCol.gridProfile}>
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <div className="Form-Container-DR-View">
                            <Link className="Form-Container-DR-Consultants" to="/view/specialists-2">
                              <Icon name="angle left" />
                              Specialists
                            </Link>
                          </div>
                        )}
                        <div className="Form-Container-DR-View">
                          {!isEditing ? (
                            <span className="Form-Container-DR-Name">
                              {`${dataDoctor.Title}. ${dataDoctor.FirstName} ${dataDoctor.LastName} (Specialist ID: ${dataDoctor.Id})`}
                            </span>
                          ) : (
                            <Form>
                              <Form.Group widths="equal">
                                <Form.Select
                                  options={this.titleOptions}
                                  name="Title"
                                  value={dataDoctor.Title}
                                  width="3"
                                  clearable
                                  onChange={setFieldValue}
                                />
                                <Form.Input
                                  placeholder="First name"
                                  name="FirstName"
                                  value={dataDoctor.FirstName || ''}
                                  width="4"
                                  onChange={setFieldValue}
                                />
                                <Form.Input
                                  placeholder="Last name"
                                  name="LastName"
                                  value={dataDoctor.LastName || ''}
                                  width="4"
                                  onChange={setFieldValue}
                                />
                              </Form.Group>
                            </Form>
                          )}
                        </div>
                        <div className="Form-Container-DR-View mb-1">
                          <Checkbox
                            label="Available for Online Bookings to CMs"
                            name="AvailableForCMView"
                            checked={dataDoctor.AvailableForCMView}
                            onChange={toggleCheckbox}
                          />
                          <Link to="#" className="Form-Field-Link" onClick={this.openClinicCalendar}>
                            View this doctor/specialist's clinic calendar
                          </Link>
                        </div>
                        {!isEditing && (
                          <div className="Form-Container-DR-View">
                            {dataDoctor.OfficePhone && (
                              <CustomButton
                                className="btn-primary"
                                style={{ width: '212px', height: '46px' }}
                                onClick={() => this.callDoctor(dataDoctor.OfficePhone)}
                              >
                                <Icon name="phone" />
                                <span>{formatPhoneNumber(dataDoctor.OfficePhone, 'office')}</span>
                              </CustomButton>
                            )}
                            <CustomButton
                              className="btn-secondary"
                              style={{
                                marginLeft: `${dataDoctor.OfficePhone ? '10px' : '0px'}`,
                                height: '46px',
                                width: '142px',
                              }}
                              onClick={() => DoctorDetailsStore.setIsEditing(true)}
                            >
                              <Icon name="pencil" />
                              EDIT
                            </CustomButton>
                            <CustomButton
                              className="btn-secondary"
                              style={{
                                marginLeft: '10px',
                                height: '46px',
                                // width: '142px',
                              }}
                              onClick={() => this.handleChangePassword()}
                            >
                              <Icon name="lock" />
                              Reset Password
                            </CustomButton>
                          </div>
                        )}
                        {!isEditing ? (
                          <div className="Form-Container-DR-View" style={{ marginTop: '14px' }}>
                            <Form>
                              <Form.Group widths="equal">
                                <Form.Field inline>
                                  <label className="Form-Container-DR-Label">Business Email:</label>

                                  <span className="Form-Container-DR-Text">{dataDoctor.Email2}</span>
                                </Form.Field>

                                <Form.Field inline>
                                  <label className="Form-Container-DR-Label">Personal Email:</label>

                                  <span className="Form-Container-DR-Text">{dataDoctor.Email}</span>
                                </Form.Field>
                                <Form.Field inline>
                                  <label className="Form-Container-DR-Label">Industry Expert Type:</label>
                                  <span className="Form-Container-DR-Text">
                                    {this.renderIndustryType(dataDoctor.IndustryExpertTypes).join(', ')}
                                  </span>
                                </Form.Field>
                              </Form.Group>
                              <Form.Group widths="equal">
                                <Form.Field inline>
                                  <label className="Form-Container-DR-Label">Mobile phone:</label>

                                  <span className="Form-Container-DR-Text">
                                    {formatPhoneNumber(dataDoctor.Telephone, 'tele')}
                                  </span>
                                </Form.Field>
                                <Form.Field inline>
                                  <label className="Form-Container-DR-Label">Office phone:</label>
                                  <span className="Form-Container-DR-Text">
                                    {formatPhoneNumber(dataDoctor.OfficePhone, 'office')}
                                  </span>
                                </Form.Field>
                                <Form.Field inline>
                                  {/* <label className="Form-Container-DR-Label">
                                    Register as:
                                  </label>
                                  <span className="Form-Container-DR-Text">
                                    {dataDoctor.RegisterOption || 'Unknown'}
                                  </span> */}
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          </div>
                        ) : (
                          <div className="Form-Container-DR-View" style={{ marginTop: '14px' }}>
                            <Form>
                              <Form.Group widths="equal">
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Business Email:</label>
                                  <Input
                                    name="Email2"
                                    value={dataDoctor.Email2 || ''}
                                    onChange={setFieldValue}
                                    className={cx(
                                      `input-doctor-details-edit${this.validateEmail(
                                        dataDoctor.Email2,
                                        'businessEmail',
                                      ) && '-error'}`,
                                    )}
                                  />
                                  {this.validateEmail(dataDoctor.Email2, 'businessEmail') && (
                                    <span className="invalid-label-eror-doctor-details">
                                      {this.validateEmail(dataDoctor.Email2, 'businessEmail')}
                                    </span>
                                  )}
                                </Form.Field>
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Personal Email:</label>
                                  <Input
                                    name="Email"
                                    value={dataDoctor.Email || ''}
                                    onChange={setFieldValue}
                                    className={cx(
                                      `input-doctor-details-edit${this.validateEmail(
                                        dataDoctor.Email,
                                        'personnalEmal',
                                      ) && '-error'}`,
                                    )}
                                  />
                                  {this.validateEmail(dataDoctor.Email, 'personnalEmal') && (
                                    <span className="invalid-label-eror-doctor-details">
                                      {this.validateEmail(dataDoctor.Email, 'personnalEmal')}
                                    </span>
                                  )}
                                </Form.Field>
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Industry Expert Type:</label>
                                  <Dropdown
                                    multiple
                                    search
                                    selection
                                    name="IndustryExpertTypes"
                                    options={DoctorDetailsStore.dataIndustryExpertTypes}
                                    value={toJS(dataDoctor.IndustryExpertTypes)}
                                    onChange={setFieldValue}
                                  />
                                </Form.Field>
                              </Form.Group>
                              <Form.Group widths="equal">
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Mobile phone: :</label>
                                  <React.Fragment>
                                    <Input
                                      name="Telephone"
                                      value={dataDoctor.Telephone || ''}
                                      onChange={setFieldValue}
                                      className={cx(
                                        `input-doctor-details-edit${this.validatePhone(dataDoctor.Telephone) &&
                                          '-error'}`,
                                      )}
                                    />
                                    {this.validatePhone(dataDoctor.Telephone) && (
                                      <span className="invalid-label-eror-doctor-details">
                                        {this.validatePhone(dataDoctor.Telephone)}
                                      </span>
                                    )}
                                  </React.Fragment>
                                </Form.Field>
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Office phone:</label>
                                  <Input
                                    name="OfficePhone"
                                    value={dataDoctor.OfficePhone || ''}
                                    onChange={setFieldValue}
                                    className={cx(
                                      `input-doctor-details-edit${this.validatePhone(dataDoctor.OfficePhone) &&
                                        '-error'}`,
                                    )}
                                  />
                                  {this.validatePhone(dataDoctor.OfficePhone) && (
                                    <span className="invalid-label-eror-doctor-details">
                                      {this.validatePhone(dataDoctor.OfficePhone)}
                                    </span>
                                  )}
                                </Form.Field>
                                <Form.Field>
                                  <label className="Form-Container-DR-Label">Register as:</label>
                                  <Dropdown
                                    selection
                                    name="RegisterOption"
                                    options={[
                                      {
                                        value: 'P',
                                        text: 'For Applicants (P)',
                                      },
                                      {
                                        value: 'D',
                                        text: 'For Insurers (D)',
                                      },
                                      {
                                        value: 'P&D',
                                        text: 'For Both (P & D)',
                                      },
                                      {
                                        value: 0,
                                        text: 'Unknown',
                                      },
                                    ]}
                                    value={dataDoctor.RegisterOption}
                                    onChange={setFieldValue}
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {open && <DoctorDetailModal />}
                  <Tab menu={{ secondary: true, pointing: true }} panes={renderTabPanes} />
                  {isEditing && (
                    <div className="PageFooter">
                      <div>
                        {!api.isOnlySearch() && <Button className="blue" onClick={DoctorDetailsStore.saveEdit}>
                          {!!dataDoctor.Id ? 'Save' : 'Create'}
                        </Button>}
                        <Button className="negative" onClick={() => DoctorDetailsStore.setIsEditing(false)}>
                          Close
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default DoctorDetail;
