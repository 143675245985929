import React from 'react';

import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import fileStore from './fileReviewStore';
import * as api from '../../../stores/api';

function ModalCaseProgress() {
  const { open, modalParams, toggleModal } = fileStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/FileReview/GetFileReviewLogs';
  const body = { fileReviewId: modalParams.id };

  return (
    <Modal visible={open} onCancel={toggleModal(false)} width={1100} title={title}>
      <CaseProgress progressList={api.FR_CaseProgress.data.itemList} url={url} body={body} />
    </Modal>
  );
}

export default ModalCaseProgress;
