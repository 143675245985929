import { Divider, Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import * as api from '../../../stores/api';
import moment from 'moment';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import mednegStore from './MedNegStore';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'MedNegNumber' },
  { title: 'Request Date', sortKey: 'RequestReceivedDate' },
  { title: 'Action Required' },
  { title: 'Case Status' },
  { title: 'Status Date' },
  { title: 'Preliminary Opinions' },
  { title: 'Extended Opinions' },
  { title: 'Full Reports' },
  { title: 'Claim No', sortKey: 'ClaimantNo' },
  // { title: 'Type of Claim/Report', sortKey: 'TypeOfClaimnt' },
  { title: 'Claimant', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

const columnsCM = [
  { title: '' },
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  // { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Doctor' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
];

@observer
class MedNegTable extends React.Component {
  openCaseDetail = (id, type, record) => () => {
    localStorage.setItem('MedNegCaseId', `Case ${record.MedNegNumber}`);
    iframe.forceOpenTab('PostMedNeg', `?id=${id}&view=PostMedNeg#${type}`, {
      id,
      view: 'PostMedNeg',
    });
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  openViewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  openModal = options => {
    return mednegStore.toggleModal(true, options);
  };

  handleDuplicateAndRemoe = (id, fn, act) => async () => {
    mednegStore.setFieldsValue({ loading: true });

    try {
      await fn(id);
      await mednegStore.refetch({}, true);

      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Med Neg is ${act}d`,
        duration: 2,
      });
    } finally {
      mednegStore.setFieldsValue({ loading: false });
    }
  };

  duplicate = id => {
    return customFetch('/MedNeg/MedNeg_CopyDetail', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ mednegId: id }),
    });
  };

  handleDuplicate = id => async () => {
    customFetch('/MedNeg/MedNeg_CopyDetail', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ mednegId: id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.success({
            message: 'Success',
            description: 'Med Neg is duplicate',
          });
          mednegStore.refetch({}, true);
          localStorage.setItem('MedNegCaseId', `Case ${response.medNegNumber}`);
          iframe.forceOpenTab('PostMedNeg', `?id=${response.id}&view=PostMedNeg`, {
            id,
            view: 'PostMedNeg',
          });
        }
      })
      .catch(() => {});
  };

  delete = id => {
    return customFetch('/MedNeg/Delete', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id }),
    });
  };

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=M&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'M',
      view: 'ViewInvoice',
    });
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  convertDoctorName = name => {
    const n = '(P)';
    const m = '(P&D)';
    if (name) {
      if (name.search(m)) {
        return name.replace(m, '');
      } else if (name.search(n)) {
        return name.replace(n, '');
      }
    }
    return name;
  };

  renderSpecialist = doctors => {
    return (
      <React.Fragment>
        {(doctors || doctors).map((item, idx) => (
          <div key={idx}>
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'specialist',
                  id: item.DoctorId,
                  localStorageName: 'DoctorName',
                  localStorageValue: item.FullName,
                })
              }
            >
              {`(${item.DoctorId}) ${item.Title} ${item.FullName}`}
            </Link>
            {item.Phone && <br></br>}
            {item.Phone && <span>{`Phone: ${item.Phone}`}</span>}
            {item.Status && <br></br>}
            {item.Status && <span>{`Status: ${item.Status}`}</span>}
            {idx < (doctors || doctors).length - 1 && <Divider />}
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id, ``, record)}>
            {record.MedNegNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.RequestReceivedDate
            ? moment(formatDate(record.RequestReceivedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.MedNegNumber,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          {record.ActionOverview && (
            <div>
              {record.ActionOverview}
              <br />
              <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
            </div>
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.MedNegNumber,
              status: record.CaseProgressText,
            })}
          >
            {record.CaseFlowText}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell style={{ minWidth: 250 }} className="Cell-link Cell-nowrap">
          {/* <div dangerouslySetInnerHTML={{ __html: record.Specialty1 }} /> */}
          {this.renderSpecialist(record.PODoctor)}
        </Table.Cell>
        <Table.Cell style={{ minWidth: 250 }} className="Cell-link Cell-nowrap">
          {/* <div dangerouslySetInnerHTML={{ __html: record.Specialty2 }} /> */}
          {this.renderSpecialist(record.EODoctor)}
        </Table.Cell>
        <Table.Cell style={{ minWidth: 250 }} className="Cell-link Cell-nowrap">
          {/* <div dangerouslySetInnerHTML={{ __html: record.Specialty3 }} /> */}
          {this.renderSpecialist(record.FullReportDoctor)}
        </Table.Cell>
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        {/* <Table.Cell>
          <div>{record.TypeOfClaimnt}</div>
          <div>{record.TypeOfReport}</div>
        </Table.Cell> */}
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'claimant',
                id: record.ClaimantId,
                localStorageName: 'ClaimantName',
                localStorageValue: record.Claimant,
              })
            }
          >
            ({record.ClaimantId}) {record.Claimant}
          </Link>
          <br /> DOB:{' '}
          {record.ClaimantDOB ? moment(formatDate(record.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
          <br />
          <FormatPhoneNumber
            officePhoneNumber={record.ClaimantHomePhone}
            mobilePhoneNumber={record.ClaimantTelePhone}
          />
        </Table.Cell>
        <Table.Cell style={{ minWidth: 200 }}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: record.CaseManagerId,
                localStorageName: 'CmName',
                localStorageValue: record.CaseManager,
              })
            }
          >
            {record.CaseManagerId > 0 ? `(${record.CaseManagerId})` : ``} {record.CaseManager}
          </Link>
          <div>{record.CMEmail}</div>
          <div>{record.CMMobilePhone}</div>
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyCM = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetailCM(Id, 'Document', record)}>
            {record.MedNegNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate ? moment(formatDate(record.RequestDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        {/* <Table.Cell>{
          record.TypeOfClaimnt}
          {record.TypeOfReport}
        </Table.Cell> */}
        <Table.Cell>
          ({record.ClaimantId}) {record.Claimant}
        </Table.Cell>
        <Table.Cell>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell>
          <h5 className="ma-0">{record.CaseManager}</h5>
          {record.IsDirect && <div>{record.CMEmail}</div>}
          {record.IsDirect && <div>{record.CMMobilePhone}</div>}
        </Table.Cell>
        <Table.Cell>
          <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
            {record.DoctorName}{' '}
          </h5>
          {record.DoctorName && (
            <React.Fragment>
              <div style={{ whiteSpace: 'nowrap' }}>Specialty: {record.DoctorSpecialty}</div>
              {api.isAdminOrMagStaffOrAccounting() && <div>{record.DoctorPhone}</div>}
              {api.isAdminOrMagStaffOrAccounting() && (
                <div className={cx(record.ContractReady ? 'text-success' : 'text-danger')}>
                  Contract Ready: {!record.ContractReady ? 'No' : 'Yes'}
                </div>
              )}
            </React.Fragment>
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link"></Table.Cell>
        <Table.Cell>
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  openCaseDetailCM = (id, type, record) => () => {
    localStorage.setItem('MedNegCaseId', `Case ${record.MedNegNumber}`);
    iframe.forceOpenTab('PostMedNeg', `?id=${id}&view=PostMedNeg#${type}`, {
      id,
      view: 'PostMedNeg',
    });
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openCaseDetail(id, `CaseDetails`, record)}>Update Claim Details</Menu.Item>
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Update Case Documents</Menu.Item>
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openCaseDetail(id, `Document`, record)}>Update Dictations & Reports</Menu.Item>
          )}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openCaseDetail(id, `ServicePrepaymentSection`, record)}>
              Update Provider Payment
            </Menu.Item>
          )}
          {api.isCaseManager() && (
            <Menu.Item onClick={this.openCaseDetailCM(id, `CaseDetails`, record)}>Update Claim Details</Menu.Item>
          )}
          {api.isCaseManager() && (
            <Menu.Item onClick={this.openCaseDetailCM(id, `Document`, record)}>Upload Case Documents</Menu.Item>
          )}
          {api.isCaseManager() && (
            <Menu.Item onClick={this.openCaseDetailCM(id, `Document`, record)}>View Completed Reports</Menu.Item>
          )}
          {api.isCaseManager() && (
            <Menu.Item onClick={this.openCaseDetailCM(id, `Cancellation`, record)}>Cancel Case Request</Menu.Item>
          )}
          <Menu.Item onClick={this.openCaseDetail(id, 'Corespondence', record)}>
            {api.isAdminOrMagStaffOrAccounting() ? `Update Correspondence` : `View Correspondence`}
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>

          {(record.IsBilled || record.Status === 'Report Completed' || record.Status === 'Cancelled With Fee') &&
            api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item style={{ pointerEvents: 'none' }}>
                <Divider style={{ margin: 0 }} />
              </Menu.Item>
            )}
          {/* {(record.Status === 'Report Completed' ||
            record.Status === 'Cancelled With Fee' ||
            record.IsBilled ||
            record.InvoiceId > 0) && (
              <Menu.Item style={{ pointerEvents: 'none' }}>
                <Divider style={{ margin: 0 }} />
              </Menu.Item>
            )} */}
          {/* <Menu.Item onClick={this.openCaseDetailCM(id, `Invoices`)}>
            View Invoice
          </Menu.Item> */}
          {(record.Status === 'Report Completed' || record.Status === 'Cancelled With Fee') &&
            api.isAdminOrMagStaffOrAccounting() && (
              <Menu.Item onClick={this.handleAddCopyInvoice(id, 0)}>
                <Icon name="plus" size="small" /> Generate Invoice
              </Menu.Item>
            )}
          {record.IsBilled && api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
          )}
          {record.InvoiceId > 0 && api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openViewInvoice(record.InvoiceId)}>View Invoice</Menu.Item>
          )}
          {record.InvoiceId > 0 && api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print invoice
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message:
                  'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
                onOk: this.handleDuplicateAndRemoe(id, this.duplicate, `duplicate`), //this.handleDuplicate(id),
              })}
            >
              Rebook this Case
            </Menu.Item>
          )}
          {/* {api.isAdmin() && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to delete this file review?',
                onOk: this.handleDuplicateAndRemoe(id, this.delete, `delete`),
              })}
            >
              Delete this Case
            </Menu.Item>
          )} */}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    mednegStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = mednegStore;
    const colSpan = api.isAdminOrMagStaffOrAccounting() ? columns.length : columnsCM.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: `0` }}>
        <TableHeader
          columns={api.isAdminOrMagStaffOrAccounting() ? columns : columnsCM}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={mednegStore.handleSort}
        />
        <Table.Body>
          {api.isAdminOrMagStaffOrAccounting()
            ? this.renderTableBody(data, colSpan)
            : this.renderTableBodyCM(data, colSpan)}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={mednegStore.curPage}
          totalPage={mednegStore.totalPage}
          totalItems={mednegStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default MedNegTable;
