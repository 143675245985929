import './index.scss';

import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import imeStore from './IMEStore';
import IMETable from './IMETable';
import IMEToolbar from './IMEToolbar';
import ModalCaseProgress from './ModalCaseProgress';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';

// /MedicalService/_RenderPartialAssessment2?id=${id}

@observer
class IMEReport extends React.Component {
  renderCase = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case `Corrected Report Sent to MAG`:
        return `Corrected Report Returned from Provider`;
      default:
        return caseStatus;
    }
  };
  renderNextTask = task => {
    switch (task) {
      case 'Confirm Appointments':
        return 'Appointment Requested';
      case 'Submit Case Documents':
        return 'Appointment Confirmed';
      case 'Pay Invoices':
        return 'Service Billed';
      default:
        return task;
    }
  };
  componentDidMount() {
    this._searchDebounced();
    imeStore.getIMEStatus();
    imeStore.getIMENextStatus();

    ui.on('open(/view/doctor-ime)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?nextTaskDue=') !== -1) {
        const id = search.replace('?nextTaskDue=', '').replaceAll('%20', ' ');
        imeStore.refetch({ filterNextStatus: this.renderCase(id) }, true);
      } else {
        const id = search.replace('?caseStatus=', '').replaceAll('%20', ' ');
        imeStore.refetch({ filter: this.renderCase(id) }, true);
      }
    } else {
      imeStore.refetch({}, true);
    }
  }, 1000);

  render() {
    const { loading, data, open } = imeStore;
    return (
      <div className={`Ime-table Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <IMEToolbar />
            <IMETable />
            {open && <ModalCaseProgress />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default IMEReport;
