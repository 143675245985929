import {
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Radio,
  Select,
  notification,
} from 'antd';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import * as api from '@stores/api';
import customFetch from '@utils/customFetch';
import moment from 'moment';
import { Header } from '../../shared/Collapse/Panel';
import { commonInlineStyles, renderDate } from '../Shared';
import FieldUpload from './FieldUpload';
import { reSendUrgentReportRequest, resendPaperworkReadiness, sendICareCompletionRequest } from './service';
import store from './store';
import { CONSTANTS } from './type';
import AudioTranscript from '../../shared/AudioTranscript';
var momentBusinessDays = require('moment-business-days');
// import Validator from 'validatorjs';

const styles = {
  minHeight40: {
    minHeight: '40px',
    lineHeight: '40px',
  },
  requiredColor: { color: 'red' },
  minHeightAuto: { minHeight: 'auto' },
  marginTop25: { marginTop: 25 },
  highlightInLineWidth300: {
    ...commonInlineStyles.highlightInline,
    width: 300,
  },
  marginLeftHighlightInLineWidth300: {
    ...commonInlineStyles.highlightInline,
    width: 300,
    marginLeft: 25,
  },
};

@observer
class CaseDocumentsForm extends React.Component {
  handleAfterUploadReportDraft = () => {
    const itemModel = toJS(store.supplementaryInfo);
    const specialist = toJS(store.specialistSelected);
    store.handleChangeSuppValue({ ReportDraftSentDate: moment() });
    if (api.isAdminOrMagStaffOrAccounting()) {
      store.handleChangeSuppValue({ ReportDraftSentDate: moment(), ReportDraftReceived: true });

      if (!itemModel.EmailReportDraftToDoctorBE || !itemModel.EmailReportDraftToDoctor) {
        store.toggleModalConfirm(true, {
          message: 'Do you want to attach report draft to notification email to provider?',
          onOk: () => store.handleChangeSuppValue({ EmailReportDraftToDoctorBE: true, EmailReportDraftToDoctor: true }),
        });
      }
    }
  };
  onclickResendSentDateToTypist = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend dictation files, report templates and previous reports to the typist?',
      onOk: () => {
        return customFetch('/Supplementary/ResendNotifyTypistAvailableDictationFiles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: store.supplementaryInfo.Id }),
        }).then(response => {
          if (response.status === 'success') {
            store.handleChangeSuppValue({
              SentDateToTypist: moment(response.ResendDatetime),
            });
            store.showNotification('Success', 'Resend report', 'success');
          } else {
            store.showNotification('Error', 'Resend report', 'error');
          }
        });
      },
    });
  };
  onclickCheckReportSampleAndTemplate = () => {
    store.ReportDictationSentToTypistChange();
  };
  handleAfterUploadFinalReport = () => {
    const itemModel = toJS(store.supplementaryInfo);
    if (!itemModel.ReportCompleted) {
      store.toggleModalConfirm(true, {
        modalType: 'confirm',
        message: 'Do you want to send report to CMs?',
        onOk: () => {
          store.handleChangeSuppValue({ ReportCompleted: true });
          if (itemModel.F_RDCCaseManagerId) {
            store.handleChangeSuppValue({ IsAllowSolicitorViewReport: true });
          }
          if (itemModel.S_RDCCaseManagerId) {
            store.handleChangeSuppValue({ IsAllowCMViewReport: true });
          }
        },
      });
    }
    // if (!itemModel.ReportCompleted && itemModel.S_RDCCaseManagerId) {
    //   store.toggleModalConfirm(true, {
    //     modalType: 'confirm',
    //     message: 'Do you want to send report to Client Receiving Contact 2',
    //     onOk: () => {
    //       store.handleChangeSuppValue({ ReportCompleted: true, IsAllowCMViewReport: true });
    //     },
    //   });
    // }
  };
  handleAfterUploadPaperworkOtherPaperwork = (fileList = []) => {
    const attachments = store.supplementaryInfo.Attachments;
    const itemModel = toJS(store.supplementaryInfo);

    const paperworkAndPaperworkOtherAttachment = !!attachments.length
      ? attachments.filter(i => i.Type === 2 || i.Type === 3)
      : [];

    //check total pages > 20
    if (!!paperworkAndPaperworkOtherAttachment.length) {
      const totalPage = paperworkAndPaperworkOtherAttachment.map(i => i.NumberOfPages).reduce((a, b) => a + b);
      if (totalPage > 20) {
        store.handleChangeSuppValue({ IsLongPaperwork: true });
      }
      store.totalPages = totalPage;
    }
    store.handleChangeSuppValue('PaperworkReceivedDate', moment());
    store.handleChangeSuppValue('IsUpdatedPaperwork', true);
    if (!!fileList.length && !itemModel.AllowDoctorToViewPaperwork && !api.isCaseManager()) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to send paperwork to specialist?',
        onOk: () => {
          if (!itemModel.IsPaperworkReceived) {
            store.handleChangeSuppValue({ IsPaperworkReceived: true });
          }
          if (!itemModel.AllowDoctorToViewPaperwork) {
            store.handleChangeSuppValue({ AllowDoctorToViewPaperwork: true });
          }
          store.handleChangeSuppValue({ PaperworkByOtherMethodDate: moment() });
          setTimeout(() => {
            if (api.isCaseManager()) {
              store.toggleModalConfirm(true, {
                message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
              });
            }
          }, 1000);
        },
      });
    }
    if (!!fileList.length && !itemModel.NotifyPaperworkReadinessToStaff && api.isCaseManager()) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to send paperwork readiness to MLP Staff?',
        onOk: () => {
          if (!itemModel.NotifyPaperworkReadinessToStaff) {
            store.handleChangeSuppValue({ NotifyPaperworkReadinessToStaff: true });
          }
        },
      });
    }
  };
  functionCallbackAfterUploadDictation = () => {
    const itemModel = toJS(store.supplementaryInfo);
    store.handleChangeSuppValue({ DictationOption: 0 });
    if (itemModel.DictationReceived === false) {
      if (api.isDoctor()) {
        store.handleChangeSuppValue({ DictationReceived: true });
      } else {
        // store.toggleModalConfirm(true, {
        //   message: 'Do you want to mark the case as Dictation Received?',
        //   onOk: () => {

        //   },
        // });
        store.handleChangeSuppValue({ DictationReceived: true });
      }
    }
    if (api.isDoctor()) {
      store.toggleModalConfirm(true, {
        message: 'Make sure you click "Save" button at the bottom of the page to save the changes',
        hideWarningIcon: true,
      });
    }
  };
  handResendReportDraft = () => {
    if (store.supplementaryInfo.EmailReportDraftToDoctor) {
      const attachments = store.renderAttachment(store.supplementaryInfo.Attachments, 6);
      if (!attachments.length) {
        store.showNotification('Error', 'You must attach report draft to send to Specialist.');
        return;
      }
    }
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend report draft?',
      onOk: () => {
        customFetch('/Supplementary/ResendEmailDraftReportToDoctor', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: store.supplementaryInfo.Id,
            emailReportDraftToDoctor: store.supplementaryInfo.EmailReportDraftToDoctor,
            sendToBE: store.supplementaryInfo.EmailReportDraftToDoctorBE,
            sendToPE: store.supplementaryInfo.EmailReportDraftToDoctorPE,
          }),
        }).then(response => {
          if (response.status === 'success') {
            store.showNotification('Success', 'Resend report draft to Specialist', 'success');
            store.handleChangeSuppValue({ EmailReportDraftToDoctorDateSent: moment() });
          } else {
            store.showNotification('Error', 'Resend report draft to Specialist');
          }
        });
      },
    });
  };
  resendPaperwork = () => {
    customFetch('/Supplementary/ResendPaperwork', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: store.supplementaryInfo?.Id }),
    })
      .then(response => {
        if (response.status === 'success') {
          store.showNotification('Success', 'Resend Paperwork', 'success');
          store.handleChangeSuppValue({
            SentDateDoctorViewPaperwork: moment(),
          });
          store.handleChangeSuppValue({
            DoctorViewPaperworkByUser: response.sentBy,
          });
        } else {
          store.showNotification('Error', 'Resend Paperwork', 'error');
        }
      })
      .catch(() => {});
  };

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.supplementaryInfo);
      if (fieldName === 'IsInvoiceUrgentReport') {
        if (value) {
          store.toggleModalConfirm(true, {
            message: 'Specialist may charge a higher rate for urgent reports. Do you want to continue?',
            onOk: () => this.handleUrgentReport(fieldName),
          });
          return;
        }
      }
      return (store.supplementaryInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  @action handleUrgentReport = fieldName => {
    const itemModel = toJS(store.supplementaryInfo);
    store.supplementaryInfo = {
      ...itemModel,
      [fieldName]: true,
    };
  };

  @action handleChangeFieldUrgentReportType = fieldName =>
    action(event => {
      const itemModel = toJS(store.supplementaryInfo);
      const value = event ? (event.target ? event.target.value : event) : event;
      if (fieldName === 'UrgentReportType' && value === 1) {
        var dfCompletionDate = !!itemModel.PaperworkByOtherMethodDate
          ? momentBusinessDays(itemModel.PaperworkByOtherMethodDate.clone()).businessAdd(2)
          : null;
        store.supplementaryInfo = {
          ...itemModel,
          [fieldName]: value,
          UrgentReportReportCompletionDate: dfCompletionDate,
        };
      } else {
        store.supplementaryInfo = {
          ...itemModel,
          [fieldName]: value,
        };
      }
    });

  handleNotifyUrgentReport = type => {
    const itemModel = toJS(store.supplementaryInfo);
    reSendUrgentReportRequest('SUPP', itemModel.Id, type).then(resp => {
      if (resp.status === 'success') {
        if (type === 1) {
          store.handleChangeValue('NotifyUrgentReportToStaffDate', moment());
          store.handleChangeValue('NotifyUrgentReportToStaffBy', resp.resendBy);
        } else if (type === 2) {
          store.handleChangeValue('NotifyUrgentReportToSpecialistDate', moment());
          store.handleChangeValue('NotifyUrgentReportToSpecialistBy', resp.resendBy);
        } else if (type === 3) {
          store.handleChangeValue('NotifyUrgentReportToCMDate', moment());
          store.handleChangeValue('NotifyUrgentReportToCMBy', resp.resendBy);
        }
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Resend Urgent Report Request',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Resend Urgent Report Request',
        });
      }
    });
  };

  render() {
    // const validateLinkIsValid = link => {
    //   // const v = new Validator({ link }, { link: 'url' });
    //   return v.passes();
    // };
    const {
      handleChangeFieldCheckbox,
      handleChangeFieldSelect,
      handleChangeDatePicker,
      getMessageError,
      handleChangeFieldInput,
      handleChangeFieldCheckboxGroup,
      handleChangeFieldInputNumber,
      handleSearch,
      handleScroll,
      itemToArray,
      lookupConfigs,
      supplementaryInfo,
      deliverySecondCMs,
      bookingCMs,
      bookingCMSelected,
      staffs,
      typist,
      totalPages,
    } = store;
    const itemModel = toJS(supplementaryInfo);
    return (
      <Form name="case-documents" className="grid-style">
        <Collapse bordered={false} defaultActiveKey={api.isTypist() ? ['2'] : []}>
          <Collapse.Panel
            key="1"
            header={
              <Header
                title="Paperwork"
                isChecked={supplementaryInfo.IsPaperworkReceived}
                text={api.isDoctor() ? 'Paperwork Sent' : 'Sent to Specialist'}
                date={supplementaryInfo.PaperworkByOtherMethodDate}
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
              <Checkbox
                checked={supplementaryInfo.IsPaperworkReceived}
                onChange={handleChangeFieldCheckbox('IsPaperworkReceived')}
                className="checkbox-session"
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  (supplementaryInfo.WiroIlars && !supplementaryInfo.IROFundingApproved)
                }
              >
                {api.isDoctor() ? 'Paperwork Sent' : 'Paperwork Sent to Specialist'}
              </Checkbox>
            </div>
            {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
              <Form.Item
                label={
                  <React.Fragment>
                    PIC 2
                    <button className="field-actions" onClick={() => store.openModalHistory('PICsentToProvider')}>
                      Recent Changes
                    </button>
                  </React.Fragment>
                }
              >
                <Select
                  showSearch
                  size="large"
                  optionFilterProp="children"
                  value={supplementaryInfo.PICsentToProvider || undefined}
                  onChange={handleChangeFieldSelect('PICsentToProvider')}
                >
                  {staffs.map(({ Id, FullName }) => (
                    <Select.Option key={Id} value={Id}>
                      {FullName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item label="Paperwork Received Date">
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    disabled={supplementaryInfo.IsLocked}
                    value={renderDate(supplementaryInfo.PaperworkReceivedDate)}
                    onChange={handleChangeDatePicker('PaperworkReceivedDate')}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item label="Paperwork Notes" className="fluid-field">
              <Input.TextArea
                rows={3}
                value={supplementaryInfo.SpecialistPaperworkNotes}
                disabled={true}
              />
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item
                  label="Paperwork Sent Date"
                  required={itemModel.IsPaperworkReceived}
                  validateStatus={
                    itemModel.IsPaperworkReceived && !itemModel.PaperworkByOtherMethodDate ? 'error' : null
                  }
                  help={
                    itemModel.IsPaperworkReceived && !itemModel.PaperworkByOtherMethodDate
                      ? 'This field is required'
                      : ''
                  }
                >
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    disabled={supplementaryInfo.IsLocked}
                    value={renderDate(supplementaryInfo.PaperworkByOtherMethodDate)}
                    onChange={handleChangeDatePicker('PaperworkByOtherMethodDate')}
                  />
                </Form.Item>
              </>
            )}
            {!api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Paperwork Uploaded Date">
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  disabled={supplementaryInfo.IsLocked}
                  value={renderDate(supplementaryInfo.PaperworkReceivedDate)}
                  onChange={handleChangeDatePicker('PaperworkReceivedDate')}
                />
              </Form.Item>
            )}
            <Form.Item
              label={
                <React.Fragment>
                  Standard Completion Date{' '}
                  <Popup
                    className="icon-msg-to"
                    trigger={<Icon name="info circle" />}
                    content="Report completion reminder (MAIL-89) will be sent 3 days before the expected due date"
                    position="top center"
                  />
                </React.Fragment>
              }
            >
              <DatePicker
                size="large"
                value={renderDate(itemModel.RequestExpectedCompletionDate)}
                format="DD/MM/YYYY"
                disabled={
                  itemModel.IsLocked ||
                  !(itemModel.ChargeStatus !== 3 && itemModel.ChargeStatus !== 2) ||
                  !supplementaryInfo.IsPaperworkReceived ||
                  api.isCaseManager()
                }
                onChange={handleChangeDatePicker('RequestExpectedCompletionDate')}
              />
            </Form.Item>

            {!(api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id) && <div className="fluid-field" />}
            <Form.Item
              label={
                api.isDoctor() ? (
                  <span>Attached Letter of Instruction (LOI): (Only 1 document allowed)</span>
                ) : (
                  <span>Attach Letter of Instruction (LOI): (Only 1 document allowed)</span>
                )
              }
              className="add-file-container posstion-IsTypist-label"
            >
              <FieldUpload
                type={2}
                option={{
                  maxFileSize: '100MB',
                  allowedExtensions: 'application/pdf',
                }}
                disabled={
                  !(
                    !api.isDoctor() &&
                    !(!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) &&
                    !api.isTypist()
                  ) ||
                  (api.isCaseManager() &&
                    (supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                disabledDelete={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  api.isTypist() ||
                  (api.isCaseManager() &&
                    (supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                functionCallback={this.handleAfterUploadPaperworkOtherPaperwork}
                uploadTitle={'1 PDF file only'}
                disableDragDrop={true}
              />
            </Form.Item>
            <Form.Item label="Special Request">
              <React.Fragment>
                <Checkbox
                  checked={supplementaryInfo.IsLongPaperwork}
                  onChange={handleChangeFieldCheckbox('IsLongPaperwork')}
                  disabled={api.isCaseManager() || api.isTypist()}
                >
                  {'>'} 20 pages
                </Checkbox>
                <div className={'ant-checkbox-wrapper'}>Total pages: {totalPages}</div>
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={supplementaryInfo.IsInvoiceUrgentReport}
                  onChange={this.handleChangeCheckBox('IsInvoiceUrgentReport')}
                  disabled={api.isTypist()}
                >
                  Urgent Report Completion
                </Checkbox>
                <br/>
                {supplementaryInfo.IsInvoiceUrgentReport && (
                  <Radio.Group
                    style={{ marginTop: '13px' }}
                    disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                    value={supplementaryInfo.UrgentReportType}
                    onChange={this.handleChangeFieldUrgentReportType('UrgentReportType')}
                  >
                    <Radio value={1} style={styles.radioBlockBottom}>
                      VWA Report{' '}
                      <Popup
                        trigger={<Icon name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports within 2-7 business days of paperwork sent sate, additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                    <Radio value={2} style={styles.radioBlockBottom}>
                      Other Report
                      <Popup
                        trigger={<Icon name="info circle" style={commonInlineStyles.marginLeft5} />}
                        content="Urgent reports within 2-7 business days of paperwork sent sate,no  additional fees apply"
                        position="top center"
                        wide="very"
                      />
                    </Radio>
                  </Radio.Group>
                )}
                {supplementaryInfo.IsInvoiceUrgentReport && <br />}
                {supplementaryInfo.IsInvoiceUrgentReport && (
                  <Form.Item label="Requested Completion Date Before" style={{ marginTop: '13px', marginBottom: '0' }}>
                    <DatePicker
                      size="large"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      format="DD/MM/YYYY"
                      value={renderDate(supplementaryInfo.UrgentReportReportCompletionDate)}
                      onChange={handleChangeDatePicker('UrgentReportReportCompletionDate')}
                    />
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={supplementaryInfo.UrgentReportConfirmedByCM}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      onChange={this.handleChangeCheckBox('UrgentReportConfirmedByCM')}
                    >
                      Request Authorised by CM{' '}
                    </Checkbox>
                    {!!supplementaryInfo.UrgentReportConfirmedByCMDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {supplementaryInfo.UrgentReportConfirmedByCMDate
                            ? renderDate(supplementaryInfo.UrgentReportConfirmedByCMDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {supplementaryInfo.UrgentReportConfirmedByCMBy
                            ? ` - By ${supplementaryInfo.UrgentReportConfirmedByCMBy}`
                            : ''}
                        </span>
                      </>
                    )}
                    <br />
                    <Checkbox
                      style={{ marginTop: '13px' }}
                      checked={supplementaryInfo.RequestAcceptedBySpecialist}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                      onChange={this.handleChangeCheckBox('RequestAcceptedBySpecialist')}
                    >
                      Request Accepted by Specialist{' '}
                    </Checkbox>
                    {!!supplementaryInfo.RequestAcceptedBySpecialistDate && (
                      <>
                        <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                          {supplementaryInfo.RequestAcceptedBySpecialistDate
                            ? renderDate(supplementaryInfo.RequestAcceptedBySpecialistDate, false, true)
                            : ''}
                        </span>
                        <span style={{ color: '#107fc9' }}>
                          {supplementaryInfo.RequestAcceptedBySpecialistBy
                            ? ` - By ${supplementaryInfo.RequestAcceptedBySpecialistBy}`
                            : ''}
                        </span>
                      </>
                    )}
                  </Form.Item>
                )}
                {false && supplementaryInfo.IsInvoiceUrgentReport && (
                  <Form.Item
                    label="Attached CM Authorisation"
                    className="add-file-container add-files-urgent-report"
                    style={{ marginTop: '13px', marginBottom: '0' }}
                  >
                    <FieldUpload
                      type={21}
                      multiple
                      disabledDelete={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disableDragDrop={true}
                    />
                  </Form.Item>
                )}
                {false && supplementaryInfo.IsInvoiceUrgentReport && (
                  <div>
                    <div>
                      <b>Send Urgent Report Request to{' : '}</b>
                      <Dropdown
                        disabled={
                          !supplementaryInfo.NotifyUrgentReportToStaffDate &&
                          !supplementaryInfo.NotifyUrgentReportToSpecialistDate &&
                          !supplementaryInfo.NotifyUrgentReportToCMDate
                        }
                        overlay={
                          <Menu>
                            <Menu.Item
                              disabled={!supplementaryInfo.NotifyUrgentReportToStaffDate}
                              onClick={() => this.handleNotifyUrgentReport(1)}
                            >
                              MLP Staff{' '}
                            </Menu.Item>
                            <Menu.Item
                              disabled={!supplementaryInfo.NotifyUrgentReportToCMDate}
                              onClick={() => this.handleNotifyUrgentReport(3)}
                            >
                              Case Manager{' '}
                            </Menu.Item>
                            <Menu.Item
                              disabled={!supplementaryInfo.NotifyUrgentReportToSpecialistDate}
                              onClick={() => this.handleNotifyUrgentReport(2)}
                            >
                              Specialist{' '}
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button className="blue">Resend Urgent Report Request</Button>
                      </Dropdown>
                    </div>
                    <React.Fragment>
                      <Checkbox
                        style={{ marginTop: '8px' }}
                        checked={supplementaryInfo.NotifyUrgentReportToStaff}
                        disabled={
                          !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                          !!supplementaryInfo.NotifyUrgentReportToStaffDate
                        }
                        onChange={this.handleChangeCheckBox('NotifyUrgentReportToStaff')}
                      >
                        MLP Staff{' '}
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Urgent Report Request will be sent (Mail-163)"
                          position="top center"
                        />
                      </Checkbox>
                      {!!supplementaryInfo.NotifyUrgentReportToStaffDate && (
                        <>
                          <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                            {supplementaryInfo.NotifyUrgentReportToStaffDate
                              ? renderDate(supplementaryInfo.NotifyUrgentReportToStaffDate, false, true)
                              : ''}
                          </span>
                          <span style={{ color: '#107fc9' }}>
                            {supplementaryInfo.NotifyUrgentReportToStaffBy
                              ? ` - By ${supplementaryInfo.NotifyUrgentReportToStaffBy}`
                              : ''}
                          </span>
                        </>
                      )}
                      <br />
                      <Checkbox
                        style={{ marginTop: '13px' }}
                        checked={supplementaryInfo.NotifyUrgentReportToCM}
                        disabled={
                          !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                          !!supplementaryInfo.NotifyUrgentReportToCMDate
                        }
                        onChange={this.handleChangeCheckBox('NotifyUrgentReportToCM')}
                      >
                        Case Manager{' '}
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Urgent Report Request will be sent (Mail-165)"
                          position="top center"
                        />
                      </Checkbox>
                      {!!supplementaryInfo.NotifyUrgentReportToCMDate && (
                        <>
                          <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                            {supplementaryInfo.NotifyUrgentReportToCMDate
                              ? renderDate(supplementaryInfo.NotifyUrgentReportToCMDate, false, true)
                              : ''}
                          </span>
                          <span style={{ color: '#107fc9' }}>
                            {supplementaryInfo.NotifyUrgentReportToCMBy
                              ? ` - By ${supplementaryInfo.NotifyUrgentReportToCMBy}`
                              : ''}
                          </span>
                        </>
                      )}
                      <br />
                      <Checkbox
                        style={{ marginTop: '13px' }}
                        checked={supplementaryInfo.NotifyUrgentReportToSpecialist}
                        disabled={
                          !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                          !!supplementaryInfo.NotifyUrgentReportToSpecialistDate
                        }
                        onChange={this.handleChangeCheckBox('NotifyUrgentReportToSpecialist')}
                      >
                        Specialist{' '}
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Urgent Report Request will be sent (Mail-164)"
                          position="top center"
                        />
                      </Checkbox>
                      {!!supplementaryInfo.NotifyUrgentReportToSpecialistDate && (
                        <>
                          <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                            {supplementaryInfo.NotifyUrgentReportToSpecialistDate
                              ? renderDate(supplementaryInfo.NotifyUrgentReportToSpecialistDate, false, true)
                              : ''}
                          </span>
                          <span style={{ color: '#107fc9' }}>
                            {supplementaryInfo.NotifyUrgentReportToSpecialistBy
                              ? ` - By ${supplementaryInfo.NotifyUrgentReportToSpecialistBy}`
                              : ''}
                          </span>
                        </>
                      )}
                    </React.Fragment>
                  </div>
                )}
              </React.Fragment>
            </Form.Item>
            {!api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item
                label="Paperwork Sent Date"
                required={itemModel.IsPaperworkReceived}
                validateStatus={itemModel.IsPaperworkReceived && !itemModel.PaperworkByOtherMethodDate ? 'error' : null}
                help={
                  itemModel.IsPaperworkReceived && !itemModel.PaperworkByOtherMethodDate ? 'This field is required' : ''
                }
              >
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  disabled={supplementaryInfo.IsLocked}
                  value={renderDate(supplementaryInfo.PaperworkByOtherMethodDate)}
                  onChange={handleChangeDatePicker('PaperworkByOtherMethodDate')}
                />
              </Form.Item>
            )}
            <Form.Item
              label={
                <>
                  Or Share Download Link
                  <button
                    className={'field-actions'}
                    disabled={getMessageError('PaperworkExLink', 'field') || !supplementaryInfo.PaperworkExLink}
                    onClick={() => {
                      window.open(supplementaryInfo.PaperworkExLink, '_blank');
                    }}
                  >
                    Open Link
                  </button>
                </>
              }
              className="fluid-field"
              validateStatus={getMessageError('PaperworkExLink', 'field') ? 'error' : null}
              help={getMessageError('PaperworkExLink', 'field') ? getMessageError('PaperworkExLink', 'field') : ''}
            >
              <Input.TextArea
                rows={3}
                disabled={api.isDoctor()}
                onChange={handleChangeFieldInput('PaperworkExLink')}
                value={supplementaryInfo.PaperworkExLink}
              />
            </Form.Item>
            <Form.Item label="Share Password 1" className="fluid-field">
              <Input
                size="large"
                disabled={api.isDoctor()}
                value={supplementaryInfo.SharePassword1}
                onChange={handleChangeFieldInput('SharePassword1')}
              />
            </Form.Item>
            <Form.Item
              label={!api.isDoctor() ? 'Attach Original Reports' : 'Attach Original Reports'}
              className="add-file-container"
            >
              <FieldUpload
                type={3}
                multiple
                functionCallback={this.handleAfterUploadPaperworkOtherPaperwork}
                disabledDelete={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  api.isTypist() ||
                  (api.isCaseManager() &&
                    (supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
                disabled={
                  !(
                    !api.isDoctor() &&
                    !(!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) &&
                    !api.isTypist()
                  ) ||
                  (api.isCaseManager() &&
                    (supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                      supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Or Share Download Link
                  <button
                    className={'field-actions'}
                    disabled={
                      getMessageError('PaperworkOtherExLink', 'field') || !supplementaryInfo.PaperworkOtherExLink
                    }
                    onClick={() => {
                      window.open(supplementaryInfo.PaperworkOtherExLink, '_blank');
                    }}
                  >
                    Open Link
                  </button>
                </>
              }
              className="fluid-field"
              validateStatus={getMessageError('PaperworkOtherExLink', 'field') ? 'error' : null}
              help={
                getMessageError('PaperworkOtherExLink', 'field') ? getMessageError('PaperworkOtherExLink', 'field') : ''
              }
            >
              <Input.TextArea
                rows={3}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  api.isTypist()
                }
                onChange={handleChangeFieldInput('PaperworkOtherExLink')}
                value={supplementaryInfo.PaperworkOtherExLink}
              />
            </Form.Item>
            <Form.Item label="Share Password 2" className="fluid-field">
              <Input
                size="large"
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  api.isTypist()
                }
                value={supplementaryInfo.SharePassword2}
                onChange={handleChangeFieldInput('SharePassword2')}
              />
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Comments" className="fluid-field">
                <Input.TextArea
                  rows={3}
                  value={supplementaryInfo.PaperworkComment}
                  onChange={handleChangeFieldInput('PaperworkComment')}
                  disabled={!api.isAdminOrMagStaffOrAccounting()}
                />
              </Form.Item>
            )}
            <Divider className="fluid-field" />
            <div className="checkbox-field align-start ">
              <Checkbox
                checked={supplementaryInfo.NotifyPaperworkReadinessToStaff}
                onChange={handleChangeFieldCheckbox('NotifyPaperworkReadinessToStaff')}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  !!supplementaryInfo.SentDateDoctorViewPaperwork ||
                  api.isTypist() ||
                  (supplementaryInfo.WiroIlars && !supplementaryInfo.IROFundingApproved)
                }
              >
                Send Paperwork Readiness to MLP Staff
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="A notice will be sent to staff to review paperwork before sending to specialist (MAIL-PAPERWORK-READINESS)"
                  position="top center"
                  wide="very"
                />
              </Checkbox>
              {supplementaryInfo.SentDateNotifyPaperworkReadinessToStaff && (
                <>
                  <span style={commonInlineStyles.highlightInline}>
                    {supplementaryInfo.SentDateNotifyPaperworkReadinessToStaff
                      ? renderDate(supplementaryInfo.SentDateNotifyPaperworkReadinessToStaff, false, true)
                      : ''}
                    {' - By ' + store.renderStaffName(supplementaryInfo.SentByNotifyPaperworkReadinessToStaff)}
                  </span>
                  {supplementaryInfo.SentDateNotifyPaperworkReadinessToStaff && (
                    <Button
                      className="blue"
                      disabled={
                        api.isDoctor() ||
                        (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                        !supplementaryInfo.NotifyPaperworkReadinessToStaff ||
                        api.isTypist() ||
                        (api.isCaseManager() &&
                          (supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledNoCharge ||
                            supplementaryInfo.CancelledChargeStatus === CONSTANTS.CaseStatus_CancelledWithFee))
                      }
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to resend Paperwork readiness to Staff?',
                          onOk: () => {
                            resendPaperworkReadiness(supplementaryInfo.Id)
                              .then(response => {
                                if (response.status === 'success') {
                                  notification.destroy();
                                  notification.success({
                                    description: 'Resend paperwork readiness',
                                    message: 'Success',
                                  });
                                  store.handleChangeValue('SentDateNotifyPaperworkReadinessToStaff', moment());
                                  store.handleChangeValue('SentByNotifyPaperworkReadinessToStaff', response.sentBy);
                                } else {
                                  notification.destroy();
                                  notification.Error({
                                    description: 'Resend paperwork readiness',
                                    message: 'Error',
                                  });
                                }
                              })
                              .catch(() => {});
                          },
                        });
                      }}
                    >
                      Resend Paperwork Readiness
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="ant-row ant-form-item fluid-field">
              <React.Fragment>
                <Checkbox
                  checked={supplementaryInfo.IcareClaim}
                  onChange={handleChangeFieldCheckbox('IcareClaim')}
                  disabled={api.isDoctor() || api.isTypist()}
                >
                  <h4 style={commonInlineStyles.inlineBlock}>
                    This is iCare Claim
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Send iCare reminders to Staff (MAIL-169)"
                      position="top center"
                      wide="very"
                    />
                  </h4>
                  {supplementaryInfo.IcareClaim && supplementaryInfo.IcareClaimDate && (
                    <span style={styles.highlightInline}>
                      {supplementaryInfo.IcareClaimDate
                        ? ' ' + renderDate(supplementaryInfo.IcareClaimDate, false, true)
                        : ''}
                      {' - By ' + store.renderStaffName(supplementaryInfo.IcareClaimBy)}
                    </span>
                  )}
                </Checkbox>
                {api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.IcareClaim && (
                  <Button
                    className="blue"
                    onClick={() => {
                      store.toggleModalConfirm(true, {
                        message: 'Do you want to send iCare report reminder to Staff?',
                        onOk: () => {
                          sendICareCompletionRequest(supplementaryInfo.Id)
                            .then(response => {
                              if (response.status === 'success') {
                                notification.destroy();
                                notification.success({
                                  description: 'Sent iCare report reminder successfully!',
                                  message: 'Success',
                                });
                              }
                            })
                            .catch(() => {});
                        },
                      });
                    }}
                  >
                    Send iCare Reminder To Staff
                  </Button>
                )}
              </React.Fragment>
            </div>
            {supplementaryInfo.IcareClaim && (
              <>
                <Form.Item label="Insurer">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={supplementaryInfo.InsurerId || undefined}
                    onChange={handleChangeFieldSelect('InsurerId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.listCompanyInsurer?.map(({ Id, Name, StateName }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Reason for Referral">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={supplementaryInfo.ReasonReferralId || undefined}
                    onChange={handleChangeFieldSelect('ReasonReferralId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.listReasonReferral?.map(({ Id, Label }) => (
                      <Select.Option key={Id} value={Id}>
                        {Label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Service Type">
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={supplementaryInfo.IcareServiceTypeId || undefined}
                    onChange={handleChangeFieldSelect('IcareServiceTypeId')}
                    disabled={api.isTypist() || api.isDoctor()}
                  >
                    {store.icareLookupList &&
                      store.icareLookupList.itemList &&
                      store.icareLookupList.itemList.length &&
                      store.icareLookupList.itemList[7] &&
                      store.icareLookupList.itemList[7].length > 0 &&
                      store.icareLookupList.itemList[7].map(i => (
                        <Select.Option key={i.Id} value={i.Id}>
                          {i.Label}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item></Form.Item>
              </>
            )}
            <div className="checkbox-field align-start ">
              <Checkbox
                checked={supplementaryInfo.AllowDoctorToViewPaperwork}
                onChange={handleChangeFieldCheckbox('AllowDoctorToViewPaperwork')}
                disabled={
                  api.isDoctor() ||
                  (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                  !!supplementaryInfo.SentDateDoctorViewPaperwork ||
                  api.isTypist() ||
                  (supplementaryInfo.WiroIlars && !supplementaryInfo.IROFundingApproved) ||
                  api.isCaseManager()
                }
              >
                Send Paperwork to Specialist
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Case documents will be sent to specialist (Message-73A, Message-73A-Resend)"
                    position="top center"
                  />
                )}
              </Checkbox>
              {supplementaryInfo.SentDateDoctorViewPaperwork && (
                <>
                  <span style={commonInlineStyles.highlightInline}>
                    {supplementaryInfo.SentDateDoctorViewPaperwork
                      ? renderDate(supplementaryInfo.SentDateDoctorViewPaperwork, false, true)
                      : ''}
                    {' - By ' + store.renderStaffName(supplementaryInfo.DoctorViewPaperworkByUser)}
                  </span>
                  {supplementaryInfo.SentDateDoctorViewPaperwork && (
                    <Button
                      className="blue"
                      disabled={
                        api.isDoctor() ||
                        (!api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.ChargeStatus === 0) ||
                        !supplementaryInfo.AllowDoctorToViewPaperwork ||
                        api.isTypist() ||
                        api.isCaseManager()
                      }
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to resend Paperwork to Specialist?',
                          onOk: () => {
                            this.resendPaperwork();
                          },
                        });
                      }}
                    >
                      Resend Paperwork
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="fluid-field" />
            <Divider className="fluid-field" />
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item label="Send Paperwork by Other Methods">
                  <Checkbox.Group
                    value={
                      supplementaryInfo.SelectedPaperworkToDoctorBies
                        ? [...supplementaryInfo.SelectedPaperworkToDoctorBies]
                        : []
                    }
                    disabled={supplementaryInfo.IsLocked}
                    onChange={handleChangeFieldCheckboxGroup('SelectedPaperworkToDoctorBies')}
                  >
                    {lookupConfigs.map(i => (
                      <Checkbox key={i.Id} value={i.Id}>
                        {i.Label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
                <div className="checkbox-field align-start">
                  <Checkbox
                    disabled={supplementaryInfo.IsLocked || !api.isAdminOrMagStaffOrAccounting()}
                    checked={supplementaryInfo.DisableReportReminder}
                    onChange={handleChangeFieldCheckbox('DisableReportReminder')}
                  >
                    Disable Report Reminders
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Stop sending report completion reminders (Mail-86, Mail-87, Mail-86A, Mail-87A, Mail-88A)"
                      position="top center"
                    />
                  </Checkbox>
                </div>
              </>
            )}
          </Collapse.Panel>
          {!api.isCaseManager() && [
            <Collapse.Panel
              key="2"
              header={
                <Header
                  title="Dictation & Typist"
                  statuses={[
                    {
                      key: 'supp-dictation-received',
                      isChecked: supplementaryInfo.DictationReceived,
                      text: api.isDoctor() ? 'Dictations Sent' : 'Received from Specialist',
                      date: supplementaryInfo.DictationReceivedDate,
                      shouldFormatDate: true,
                    },
                    {
                      key: 'supp-dictation-sent-typist',
                      isChecked: supplementaryInfo.DictationSentToTypist,
                      text: 'Sent To Typist',
                      date: supplementaryInfo.SentDateToTypist,
                      shouldFormatDate: true,
                    },
                  ]}
                  isRequired={false}
                />
              }
            >
              {!api.isCaseManager() && (
                <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                  <Checkbox
                    checked={supplementaryInfo.DictationReceived}
                    disabled={!api.isAdminOrMagStaffOrAccounting()}
                    onChange={handleChangeFieldCheckbox('DictationReceived')}
                    className="checkbox-session"
                  >
                    {api.isDoctor() ? 'Dictations Sent to Medicolegal Provider' : 'Dictation Received'}
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 3
                      <button className="field-actions" onClick={() => store.openModalHistory('PICDictationReceived')}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={supplementaryInfo.PICDictationReceived || undefined}
                    onChange={handleChangeFieldSelect('PICDictationReceived')}
                  >
                    {staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!api.isCaseManager() && !supplementaryInfo.isMagDirect && (
                <>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      label="Dictation Received Date"
                      required={supplementaryInfo.DictationReceived}
                      validateStatus={
                        supplementaryInfo.DictationReceived && !itemModel.DictationReceivedDate ? 'error' : null
                      }
                      help={
                        supplementaryInfo.DictationReceived && !itemModel.DictationReceivedDate
                          ? 'This field is required'
                          : ''
                      }
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(supplementaryInfo.DictationReceivedDate)}
                        onChange={handleChangeDatePicker('DictationReceivedDate')}
                        disabled={!api.isAdminOrMagStaffOrAccounting() || !supplementaryInfo.DictationReceived}
                      />
                    </Form.Item>
                  )}
                  {!(api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id) && <div className="fluid-field" />}
                  <Form.Item label="Attached Dictations" className="add-file-container">
                    <FieldUpload
                      type={5}
                      multiple
                      // disabled={(api.isDoctor() && supplementaryInfo.ChargeStatus === 0) || api.isTypist()}
                      disabled={api.isTypist()}
                      disabledDelete={store.isLockReport}
                      functionCallback={this.functionCallbackAfterUploadDictation}
                    />
                  </Form.Item>{' '}
                  {!api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      label="Dictation Received Date"
                      required={supplementaryInfo.DictationReceived}
                      validateStatus={
                        supplementaryInfo.DictationReceived && !itemModel.DictationReceivedDate ? 'error' : null
                      }
                      help={
                        supplementaryInfo.DictationReceived && !itemModel.DictationReceivedDate
                          ? 'This field is required'
                          : ''
                      }
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(supplementaryInfo.DictationReceivedDate)}
                        onChange={handleChangeDatePicker('DictationReceivedDate')}
                        disabled={!api.isAdminOrMagStaffOrAccounting() || !supplementaryInfo.DictationReceived}
                      />
                    </Form.Item>
                  )}
                </>
              )}

              {api.isAdminOrMagStaffOrAccounting() && (
                <Form.Item
                  label={
                    <>
                      Or Share Download Link{' '}
                      <button
                        className={'field-actions'}
                        disabled={
                          getMessageError('DictationOnedriveLinkFile', 'field') ||
                          !supplementaryInfo.DictationOnedriveLinkFile
                        }
                        onClick={() => {
                          window.open(supplementaryInfo.DictationOnedriveLinkFile, '_blank');
                        }}
                      >
                        Open Link
                      </button>
                    </>
                  }
                  className="fluid-field"
                  validateStatus={getMessageError('DictationOnedriveLinkFile', 'field') ? 'error' : null}
                  help={
                    getMessageError('DictationOnedriveLinkFile', 'field')
                      ? getMessageError('DictationOnedriveLinkFile', 'field')
                      : ''
                  }
                >
                  <Input.TextArea
                    rows={3}
                    onChange={handleChangeFieldInput('DictationOnedriveLinkFile')}
                    value={supplementaryInfo.DictationOnedriveLinkFile}
                    disabled={!api.isAdminOrMagStaffOrAccounting()}
                  />
                </Form.Item>
              )}

              <AudioTranscript
                files={store.supplementaryInfo.Attachments.filter(({ Type }) => Type === 5)}
                getAudioTranscription={store.getAudioTranscription}
                isLoading={store.isLoadingAudioTranscribe}
              />

              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <div className="fluid-field checkbox-field">
                    <Checkbox
                      checked={supplementaryInfo.SendConfirmationToSpecialist}
                      onChange={handleChangeFieldCheckbox('SendConfirmationToSpecialist')}
                      disabled={supplementaryInfo.IsLocked || supplementaryInfo.SendConfirmationToSpecialistDate}
                    >
                      <h4 style={commonInlineStyles.inlineBlock}>Send Dictation Confirmation to Specialist</h4>{' '}
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Dictation receipt confirmation will be sent to specialist (MAIL-121)"
                          position="top center"
                          wide="very"
                        />
                      )}
                    </Checkbox>
                    {supplementaryInfo.SendConfirmationToSpecialistDate && (
                      <>
                        <span style={commonInlineStyles.highlightInline}>
                          {supplementaryInfo.SendConfirmationToSpecialistDate
                            ? renderDate(supplementaryInfo.SendConfirmationToSpecialistDate, false, true)
                            : ''}
                          {' By ' + store.renderStaffName(supplementaryInfo.SendConfirmationToSpecialistDateByUser)}
                        </span>
                      </>
                    )}
                  </div>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item label="Dictation Comments" className="fluid-field">
                      <Input.TextArea
                        rows={3}
                        onChange={handleChangeFieldInput('DictationComment')}
                        value={supplementaryInfo.DictationComment}
                        disabled={!api.isAdminOrMagStaffOrAccounting()}
                      />
                    </Form.Item>
                  )}
                  <Divider className="fluid-field" />
                </>
              )}
              {((api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.DictationReceived) || api.isTypist()) && (
                <>
                  <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                    <Checkbox
                      checked={supplementaryInfo.DictationSentToTypist}
                      onChange={handleChangeFieldCheckbox('DictationSentToTypist')}
                      className="checkbox-session"
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                    >
                      Dictation Sent To Typist
                    </Checkbox>
                  </div>
                  <Form.Item
                    label="Typist"
                    required={supplementaryInfo.DictationSentToTypist}
                    validateStatus={
                      supplementaryInfo.DictationSentToTypist && !itemModel.DictationTypistID ? 'error' : null
                    }
                    help={
                      supplementaryInfo.DictationSentToTypist && !itemModel.DictationTypistID
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      placeholder="Select a typist"
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      value={supplementaryInfo.DictationTypistID || undefined}
                      onChange={handleChangeFieldSelect('DictationTypistID')}
                      allowClear
                    >
                      {typist.map(({ Id, FullName, Email }) => (
                        <Select.Option key={Id} value={Id}>
                          {Id} - {FullName} - {Email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="fluid-field" />
                  <Form.Item label="Attached Dictation Instruction" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={10}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      disabledDelete={supplementaryInfo.IsLocked}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={!supplementaryInfo.DictationReceived}
                      onClick={() => {
                        this.onclickCheckReportSampleAndTemplate();
                      }}
                      style={{ marginTop: '25px', width: '100%' }}
                    >
                      Attach Specialist's Report Templates & Samples
                    </Button>
                  </Form.Item>
                  <Form.Item label="Attached Report Templates" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={12}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      disabledDelete={supplementaryInfo.IsLocked}
                    />
                  </Form.Item>{' '}
                  <Form.Item label="Attached Report Samples" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={11}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      disabledDelete={supplementaryInfo.IsLocked}
                    />
                  </Form.Item>
                  <div className="checkbox-field fluid-field" style={styles.marginTop25}>
                    <Checkbox
                      disabled={
                        !api.isAdminOrMagStaffOrAccounting() ||
                        (!supplementaryInfo.DictationSentToTypist && !supplementaryInfo.DictationTypistID) ||
                        supplementaryInfo.SentDateToTypist
                      }
                      checked={supplementaryInfo.SentToTypist}
                      onChange={handleChangeFieldCheckbox('SentToTypist')}
                    >
                      <h4 style={commonInlineStyles.inlineBlock}>Send Files to Typist</h4>
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Dictation files will be sent to typists (MAIL-102, MAIL-102-Resend)"
                          position="top center"
                          wide="very"
                        />
                      )}
                    </Checkbox>
                    {supplementaryInfo.SentDateToTypist && (
                      <>
                        <span style={styles.highlightInLineWidth300}>
                          {supplementaryInfo.SentDateToTypist
                            ? renderDate(supplementaryInfo.SentDateToTypist, false, true)
                            : ''}
                          {supplementaryInfo.ToTypistByUser
                            ? ` - By ${store.renderStaffName(supplementaryInfo.ToTypistByUser)}`
                            : ''}
                        </span>
                        {!!supplementaryInfo.SentDateToTypist && (
                          <Button
                            className="blue"
                            style={commonInlineStyles.marginLeft10}
                            onClick={this.onclickResendSentDateToTypist}
                            disabled={!supplementaryInfo.SentDateToTypist}
                          >
                            Resend Dictation Files
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <Form.Item label="Attached Typed Report" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={16}
                      disabled={!api.isAdminOrMagStaffOrAccounting() && !api.isTypist()}
                      disabledDelete={supplementaryInfo.IsLocked && !api.isTypist()}
                    />
                  </Form.Item>
                  <div className="fluid-field" />
                  <Form.Item label="Typist's transcribing time (hours)">
                    <InputNumber
                      size="large"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      value={supplementaryInfo.TypistTranscribingTime}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                      onChange={handleChangeFieldInputNumber('TypistTranscribingTime')}
                    />
                  </Form.Item>
                  <Form.Item label="Typist's submitting date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isTypist())}
                      value={renderDate(supplementaryInfo.TypistSubmittingDate)}
                      onChange={handleChangeDatePicker('TypistSubmittingDate')}
                    />
                  </Form.Item>
                  <Form.Item label="Approved by">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      placeholder="Select a staff"
                      value={supplementaryInfo.TypistApproverdBy || undefined}
                      onChange={handleChangeFieldSelect('TypistApproverdBy')}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                    >
                      {store.staffs.map(({ Id, FullName }) => (
                        <Select.Option key={Id} value={Id}>
                          {FullName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Approved on">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                      value={renderDate(supplementaryInfo.TypistApproverdOn)}
                      onChange={handleChangeDatePicker('TypistApproverdOn')}
                    />
                  </Form.Item>
                </>
              )}
            </Collapse.Panel>,
            <Collapse.Panel
              key="3"
              header={
                <Header
                  title="Report Draft to Specialist"
                  isChecked={supplementaryInfo.ReportDraftReceived}
                  text="Sent to Specialist"
                  date={supplementaryInfo.ReportDraftReceivedDate}
                  isRequired={false}
                />
              }
            >
              {!(!supplementaryInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) && (
                <>
                  <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                    <Checkbox
                      className="checkbox-session"
                      checked={supplementaryInfo.ReportDraftReceived}
                      onChange={handleChangeFieldCheckbox('ReportDraftReceived')}
                      disabled={!api.isAdminOrMagStaffOrAccounting()}
                    >
                      Report Draft Sent to Specialist
                    </Checkbox>
                  </div>
                  {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
                    <Form.Item
                      label={
                        <React.Fragment>
                          PIC 4
                          <button
                            className="field-actions"
                            onClick={() => store.openModalHistory('PICcorrectedReport')}
                          >
                            Recent Changes
                          </button>
                        </React.Fragment>
                      }
                    >
                      <Select
                        showSearch
                        size="large"
                        optionFilterProp="children"
                        value={supplementaryInfo.PICReportDraftReceived || undefined}
                        onChange={handleChangeFieldSelect('PICcorrectedReport')}
                      >
                        {store.staffs.map(({ Id, FullName }) => (
                          <Select.Option key={Id} value={Id}>
                            {FullName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}

              {!(!supplementaryInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) &&
                api.isAdminOrMagStaffOrAccounting() && (
                  <Form.Item label="Report Draft Received Date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={!api.isAdminOrMagStaffOrAccounting() || !supplementaryInfo.ReportDraftReceived}
                      value={renderDate(supplementaryInfo.ReportDraftReceivedDate)}
                      onChange={handleChangeDatePicker('ReportDraftReceivedDate')}
                    />
                  </Form.Item>
                )}
              {!(api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id) && <div className="fluid-field" />}
              {!(!supplementaryInfo.isMagDirect && api.isCaseManager() && !api.isTypist()) && (
                <>
                  <Form.Item label="Attached Report Draft" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={6}
                      disabled={supplementaryInfo.IsLocked}
                      disabledDelete={supplementaryInfo.IsLocked}
                      functionCallback={this.handleAfterUploadReportDraft}
                    />
                  </Form.Item>
                  <Form.Item label="Report Draft Sent Date">
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabled={!api.isAdminOrMagStaffOrAccounting() || !supplementaryInfo.ReportDraftReceived}
                      value={renderDate(supplementaryInfo.ReportDraftSentDate)}
                      onChange={handleChangeDatePicker('ReportDraftSentDate')}
                    />
                  </Form.Item>
                </>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <Checkbox
                    checked={supplementaryInfo.EmailReportDraftToDoctorBE}
                    onChange={handleChangeFieldCheckbox('EmailReportDraftToDoctorBE')}
                    disabled={
                      !api.isAdminOrMagStaffOrAccounting() ||
                      !!supplementaryInfo.EmailReportDraftToDoctorDateSent ||
                      !supplementaryInfo.ReportDraftReceived ||
                      !store.specialistSelected?.BusinessEmail
                    }
                  >
                    {supplementaryInfo.ReportDraftReceived &&
                      !supplementaryInfo.EmailReportDraftToDoctorBE &&
                      !supplementaryInfo.EmailReportDraftToDoctor &&
                      !supplementaryInfo.EmailReportDraftToDoctorPE && <span style={styles.requiredColor}>*</span>}
                    Send to Specialist Business Email{' '}
                    {store.specialistSelected?.BusinessEmail ? '(' + store.specialistSelected?.BusinessEmail + ')' : ''}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={supplementaryInfo.EmailReportDraftToDoctorPE}
                    onChange={handleChangeFieldCheckbox('EmailReportDraftToDoctorPE')}
                    disabled={
                      !api.isAdminOrMagStaffOrAccounting() ||
                      (!!supplementaryInfo.EmailReportDraftToDoctorDateSent &&
                        supplementaryInfo.EmailReportDraftToDoctorPE) ||
                      !supplementaryInfo.ReportDraftReceived ||
                      !store.specialistSelected?.Email2
                    }
                  >
                    {supplementaryInfo.ReportDraftReceived &&
                      !supplementaryInfo.EmailReportDraftToDoctorBE &&
                      !supplementaryInfo.EmailReportDraftToDoctor &&
                      !supplementaryInfo.EmailReportDraftToDoctorPE && <span style={styles.requiredColor}>*</span>}
                    Send to Specialist Personal Email{' '}
                    {store.specialistSelected?.PersonalEmail ? '(' + store.specialistSelected?.PersonalEmail + ')' : ''}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Completed Report Draft will be sent to Specialist (MAIL-101, MAIL-101-Resend)"
                      position="top center"
                      wide="very"
                    />{' '}
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={supplementaryInfo.EmailReportDraftToDoctor}
                    onChange={handleChangeFieldCheckbox('EmailReportDraftToDoctor')}
                    disabled={
                      !api.isAdminOrMagStaffOrAccounting() ||
                      !supplementaryInfo.ReportDraftReceived ||
                      (!supplementaryInfo.EmailReportDraftToDoctorBE && !supplementaryInfo.EmailReportDraftToDoctorPE)
                    }
                  >
                    Send as Attachment
                  </Checkbox>
                  <br />
                  {supplementaryInfo.EmailReportDraftToDoctorDateSent && (
                    <>
                      {supplementaryInfo.EmailReportDraftToDoctorDateSent && (
                        <>
                          <span style={{ color: '#107fc9' }}>
                            {renderDate(supplementaryInfo.EmailReportDraftToDoctorDateSent, false, true)} - By{' '}
                            {store.renderStaffName(supplementaryInfo.EmailReportDraftToDoctorByUser)}
                            <Button
                              className="blue"
                              style={{ marginLeft: '5px' }}
                              disabled={!api.isAdminOrMagStaffOrAccounting()}
                              onClick={() => {
                                this.handResendReportDraft();
                              }}
                            >
                              Resend Report Draft
                            </Button>
                          </span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <Form.Item label="Comments" className="fluid-field">
                    <Input.TextArea
                      rows={3}
                      value={supplementaryInfo.ReportDraftComment}
                      onChange={handleChangeFieldInput('ReportDraftComment')}
                    />
                  </Form.Item>{' '}
                  <Divider className="fluid-field" />
                </>
              )}
            </Collapse.Panel>,
          ]}
          {!api.isCaseManager() && (
            <Collapse.Panel
              key="4"
              header={
                <Header
                  title="Reviewed Report from Specialist"
                  isChecked={supplementaryInfo.ReportReturnedFromDoctor}
                  text={api.isDoctor() ? 'Report Sent' : 'Received from Specialist'}
                  date={supplementaryInfo.CompletionDate}
                  isRequired={false}
                />
              }
            >
              {!(!supplementaryInfo.isMagDirect && api.isCaseManager()) && (
                <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                  <Checkbox
                    checked={supplementaryInfo.ReportReturnedFromDoctor}
                    onChange={handleChangeFieldCheckbox('ReportReturnedFromDoctor')}
                    className="checkbox-session"
                    disabled={supplementaryInfo.IsLocked || !api.isAdminOrMagStaffOrAccounting()}
                    style={{ width: '400px' }}
                  >
                    {api.isDoctor()
                      ? 'Reviewed/Completed Report Sent to Medicolegal Provider'
                      : 'Reviewed/Completed Report Received'}
                  </Checkbox>
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
                <Form.Item
                  label={
                    <React.Fragment>
                      PIC 5
                      <button className="field-actions" onClick={() => store.openModalHistory('PICcorrectedReport')}>
                        Recent Changes
                      </button>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    value={supplementaryInfo.PICcorrectedReport || undefined}
                    onChange={handleChangeFieldSelect('PICcorrectedReport')}
                  >
                    {store.staffs.map(({ Id, FullName }) => (
                      <Select.Option key={Id} value={Id}>
                        {FullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <div className="ant-row ant-form-item fluid-field">
                <React.Fragment>
                  <Checkbox
                    checked={supplementaryInfo.IcareClaim}
                    onChange={handleChangeFieldCheckbox('IcareClaim')}
                    disabled={api.isDoctor() || api.isTypist()}
                  >
                    <h4 style={commonInlineStyles.inlineBlock}>
                      This is iCare Claim
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Send iCare reminders to Staff (MAIL-169)"
                        position="top center"
                        wide="very"
                      />
                    </h4>
                    {supplementaryInfo.IcareClaim && supplementaryInfo.IcareClaimDate && (
                      <span style={styles.highlightInline}>
                        {supplementaryInfo.IcareClaimDate
                          ? ' ' + renderDate(supplementaryInfo.IcareClaimDate, false, true)
                          : ''}
                        {' - By ' + store.renderStaffName(supplementaryInfo.IcareClaimBy)}
                      </span>
                    )}
                  </Checkbox>
                  {api.isAdminOrMagStaffOrAccounting() && supplementaryInfo.IcareClaim && (
                    <Button
                      className="blue"
                      onClick={() => {
                        store.toggleModalConfirm(true, {
                          message: 'Do you want to send iCare report reminder to Staff?',
                          onOk: () => {
                            sendICareCompletionRequest(supplementaryInfo.Id)
                              .then(response => {
                                if (response.status === 'success') {
                                  notification.destroy();
                                  notification.success({
                                    description: 'Sent iCare report reminder successfully!',
                                    message: 'Success',
                                  });
                                }
                              })
                              .catch(() => {});
                          },
                        });
                      }}
                    >
                      Send iCare Reminder To Staff
                    </Button>
                  )}
                </React.Fragment>
              </div>
              {supplementaryInfo.IcareClaim && (
                <>
                  <Form.Item label="Insurer">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={supplementaryInfo.InsurerId || undefined}
                      onChange={handleChangeFieldSelect('InsurerId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.listCompanyInsurer?.map(({ Id, Name, StateName }) => (
                        <Select.Option key={Id} value={Id}>
                          {Id} - {Name} - {StateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Reason for Referral">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={supplementaryInfo.ReasonReferralId || undefined}
                      onChange={handleChangeFieldSelect('ReasonReferralId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.listReasonReferral?.map(({ Id, Label }) => (
                        <Select.Option key={Id} value={Id}>
                          {Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Service Type">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      value={supplementaryInfo.IcareServiceTypeId || undefined}
                      onChange={handleChangeFieldSelect('IcareServiceTypeId')}
                      disabled={api.isTypist() || api.isDoctor()}
                    >
                      {store.icareLookupList &&
                        store.icareLookupList.itemList &&
                        store.icareLookupList.itemList.length &&
                        store.icareLookupList.itemList[7] &&
                        store.icareLookupList.itemList[7].length > 0 &&
                        store.icareLookupList.itemList[7].map(i => (
                          <Select.Option key={i.Id} value={i.Id}>
                            {i.Label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item></Form.Item>
                </>
              )}
              {!(!supplementaryInfo.isMagDirect && api.isCaseManager()) && (
                <>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      label={
                        api.isDoctor()
                          ? 'Reviewed/Completed Report Sent Date'
                          : 'Reviewed/Completed Report Received Date'
                      }
                      required={supplementaryInfo.CompletionDate}
                      validateStatus={supplementaryInfo.CompletionDate && !itemModel.CompletionDate ? 'error' : null}
                      help={
                        supplementaryInfo.CompletionDate && !itemModel.CompletionDate ? 'This field is required' : ''
                      }
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(supplementaryInfo.CompletionDate)}
                        onChange={handleChangeDatePicker('CompletionDate')}
                      />
                    </Form.Item>
                  )}
                  {!(api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id) && <div className="fluid-field" />}
                  <Form.Item label="Attached Reviewed/Completed Report" className="add-file-container">
                    <FieldUpload
                      multiple
                      type={13}
                      functionCallback={() => {
                        if (!supplementaryInfo.ReportReturnedFromDoctor) {
                          store.handleChangeSuppValue({ ReportReturnedFromDoctor: true });
                        }
                        if (!supplementaryInfo.NotifyCorrectedReportToStaff) {
                          store.handleChangeSuppValue({ NotifyCorrectedReportToStaff: true });
                        }
                        if (!supplementaryInfo.NotifyCorrectedReportToSpecialist) {
                          store.handleChangeSuppValue({ NotifyCorrectedReportToSpecialist: true });
                        }
                      }}
                      // disabled={(api.isDoctor() && supplementaryInfo.ChargeStatus === 0) || api.isTypist()}
                      disabled={api.isTypist()}
                      disabledDelete={
                        (!api.isAdminOrMagStaffOrAccounting() && !api.isDoctor()) ||
                        (!api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.EmailToCMsViewReportRecent)
                      }
                    />
                  </Form.Item>
                  {!api.isAdminOrMagStaffOrAccounting() && (
                    <Form.Item
                      label={
                        api.isDoctor()
                          ? 'Reviewed/Completed Report Sent Date'
                          : 'Reviewed/Completed Report Received Date'
                      }
                      required={supplementaryInfo.CompletionDate}
                      validateStatus={supplementaryInfo.CompletionDate && !itemModel.CompletionDate ? 'error' : null}
                      help={
                        supplementaryInfo.CompletionDate && !itemModel.CompletionDate ? 'This field is required' : ''
                      }
                    >
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={renderDate(supplementaryInfo.CompletionDate)}
                        onChange={handleChangeDatePicker('CompletionDate')}
                        disabled={api.isTypist()}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    className="fluid-field cancellation-label"
                    label="Send Reviewed/Completed Report Notice To"
                  >
                    <br />
                    <div style={{ marginBottom: '6px' }}>
                      <Checkbox
                        checked={supplementaryInfo.NotifyCorrectedReportToStaff}
                        onChange={handleChangeFieldCheckbox('NotifyCorrectedReportToStaff')}
                        disabled={
                          !!supplementaryInfo.SentDateNotifyCorrectedReportToStaff ||
                          (api.isDoctor() && supplementaryInfo.NotifyCorrectedReportToStaff) ||
                          api.isTypist()
                        }
                      >
                        Staff{' '}
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="Reviewed/Completed report notice will be sent to Staff (MAIL-24)"
                          position="top center"
                          wide="very"
                        />
                        <span>
                          {`${
                            supplementaryInfo.SentDateNotifyCorrectedReportToStaff
                              ? `${renderDate(supplementaryInfo.SentDateNotifyCorrectedReportToStaff, false, true)}`
                              : ''
                          } ${
                            supplementaryInfo.SentByNotifyCorrectedReportToStaff
                              ? ` - By ${supplementaryInfo.SentByNotifyCorrectedReportToStaff}`
                              : ''
                          }`}
                        </span>
                      </Checkbox>
                    </div>
                    <br />
                    <Checkbox
                      checked={supplementaryInfo.NotifyCorrectedReportToSpecialist}
                      onChange={handleChangeFieldCheckbox('NotifyCorrectedReportToSpecialist')}
                      disabled={!!supplementaryInfo.SentDateNotifyCorrectedReportToSpecialist || api.isTypist()}
                    >
                      Specialist{' '}
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Reviewed/Completed report notice will be sent to Specialist (MAIL-122)"
                        position="top center"
                        wide="very"
                      />
                      <span>
                        {`${
                          supplementaryInfo.SentDateNotifyCorrectedReportToSpecialist
                            ? `${renderDate(supplementaryInfo.SentDateNotifyCorrectedReportToSpecialist, false, true)}`
                            : ''
                        } ${
                          supplementaryInfo.SentByNotifyCorrectedReportToSpecialist
                            ? ` - By ${supplementaryInfo.SentByNotifyCorrectedReportToSpecialist}`
                            : ''
                        }`}
                      </span>
                    </Checkbox>
                  </Form.Item>
                </>
              )}
            </Collapse.Panel>
          )}
          {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() || api.isDoctor()) && (
            <Collapse.Panel
              key="5"
              header={
                <Header
                  title="Report to CMs"
                  isChecked={supplementaryInfo.ReportCompleted}
                  text="Sent to CMs"
                  date={supplementaryInfo.ReportCompletedSentDate}
                  isRequired={false}
                />
              }
            >
              {' '}
              {
                <>
                  {' '}
                  <Divider className="fluid-field" />
                  <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
                    <Checkbox
                      checked={supplementaryInfo.ReportCompleted}
                      onChange={handleChangeFieldCheckbox('ReportCompleted')}
                      className="checkbox-session"
                      disabled={store.isLockReport || api.isDoctor()}
                    >
                      Report Sent to CMs
                    </Checkbox>
                  </div>
                  {api.isAdminOrMagStaffOrAccounting() && !!supplementaryInfo.Id && (
                    <Form.Item
                      label={
                        <React.Fragment>
                          PIC 5
                          <button className="field-actions" onClick={() => store.openModalHistory('PICcompleted')}>
                            Recent Changes
                          </button>
                        </React.Fragment>
                      }
                    >
                      <Select
                        showSearch
                        size="large"
                        optionFilterProp="children"
                        value={supplementaryInfo.PICcompleted || undefined}
                        onChange={handleChangeFieldSelect('PICcompleted')}
                      >
                        {store.staffs.map(({ Id, FullName }) => (
                          <Select.Option key={Id} value={Id}>
                            {FullName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {(api.isAdminOrMagStaffOrAccounting() ||
                    (api.isDoctor() && store.specialistSelected?.ClientType > 0)) && (
                    <Form.Item label="Report Fee Status">
                      <Radio.Group
                        value={supplementaryInfo.ChargeStatus}
                        onChange={store.handleChangeFieldRadio('ChargeStatus')}
                        disabled={store.isLockReport}
                      >
                        <Radio value={0}>Chargeable Report</Radio>
                        <Radio value={1}>Clarification-No Fee</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {supplementaryInfo.ChargeStatus === 1 && api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      <Form.Item label="No Fee Reason" className="fluid-field">
                        <Select
                          value={!!supplementaryInfo.NoChargeReason ? supplementaryInfo.NoChargeReason : null}
                          disabled={supplementaryInfo.IsLocked || !api.isAdminOrMagStaffOrAccounting()}
                          onChange={handleChangeFieldSelect('NoChargeReason')}
                          size="large"
                        >
                          <Select.Option value={1}>MAG</Select.Option>
                          <Select.Option value={2}>Specialist</Select.Option>
                          <Select.Option value={3}>Client</Select.Option>
                          <Select.Option value={4}>Clarification</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item label="Comment" className="fluid-field">
                        <Input.TextArea
                          rows={3}
                          value={supplementaryInfo.NoFeeReasonComment}
                          onChange={handleChangeFieldInput('NoFeeReasonComment')}
                          disabled={!api.isAdminOrMagStaffOrAccounting()}
                        />
                      </Form.Item>
                    </>
                  )}
                  {(supplementaryInfo.ChargeStatus === 0 || supplementaryInfo.ChargeStatus === 1) &&
                    api.isAdminOrMagStaffOrAccounting() && (
                      <>
                        <Form.Item label="Date of Report Sent to CMs">
                          <DatePicker
                            size="large"
                            format="DD/MM/YYYY"
                            value={renderDate(supplementaryInfo.ReportCompletedSentDate)}
                            onChange={handleChangeDatePicker('ReportCompletedSentDate')}
                            disabled={store.isLockReport || supplementaryInfo.ChargeStatus === 4}
                          />
                        </Form.Item>
                      </>
                    )}
                  <div className="fluid-field" />
                  {supplementaryInfo.ChargeStatus !== 3 && (
                    <>
                      <Form.Item
                        label="Attach Final Report"
                        className="add-file-container"
                        style={styles.minHeightAuto}
                      >
                        <FieldUpload
                          multiple
                          type={0}
                          disabled={store.isLockReport || api.isDoctor()}
                          disabledDelete={store.isLockReport}
                          functionCallback={this.handleAfterUploadFinalReport}
                        />
                      </Form.Item>
                      <div>
                        <Form.Item
                          label={
                            <>
                              Or Share Download Link{' '}
                              <button
                                className={'field-actions'}
                                disabled={
                                  getMessageError('FinalReportOnedriveLinkFile', 'field') ||
                                  !supplementaryInfo.FinalReportOnedriveLinkFile
                                }
                                onClick={() => {
                                  window.open(supplementaryInfo.FinalReportOnedriveLinkFile, '_blank');
                                }}
                              >
                                Open Link
                              </button>
                            </>
                          }
                          className="fluid-field"
                          validateStatus={getMessageError('FinalReportOnedriveLinkFile', 'field') ? 'error' : null}
                          help={
                            getMessageError('FinalReportOnedriveLinkFile', 'field')
                              ? getMessageError('FinalReportOnedriveLinkFile', 'field')
                              : ''
                          }
                        >
                          <Input.TextArea
                            rows={3}
                            onChange={handleChangeFieldInput('FinalReportOnedriveLinkFile')}
                            value={supplementaryInfo.FinalReportOnedriveLinkFile}
                            disabled={!api.isAdminOrMagStaffOrAccounting()}
                          />
                        </Form.Item>
                        <Form.Item label="Password" className="fluid-field">
                          <Input
                            size="large"
                            disabled={store.isLockReport || api.isDoctor()}
                            value={supplementaryInfo.FinalReportOnedriveFilePassword}
                            onChange={handleChangeFieldInput('FinalReportOnedriveFilePassword')}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}{' '}
                  {(supplementaryInfo.ChargeStatus === 0 || supplementaryInfo.ChargeStatus === 1) &&
                    !api.isAdminOrMagStaffOrAccounting() && (
                      <>
                        <Form.Item label="Date of Report Sent to CMs">
                          <DatePicker
                            size="large"
                            format="DD/MM/YYYY"
                            value={renderDate(supplementaryInfo.ReportCompletedSentDate)}
                            onChange={handleChangeDatePicker('ReportCompletedSentDate')}
                            disabled={store.isLockReport || supplementaryInfo.ChargeStatus === 4}
                          />
                        </Form.Item>
                      </>
                    )}
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      {' '}
                      <Form.Item
                        label="Send Final Report to"
                        style={commonInlineStyles.marginBottom0}
                        className="fluid-field"
                      ></Form.Item>
                      <div className="fluid-field checkbox-field">
                        <Checkbox
                          checked={supplementaryInfo.IsAllowSpecialistViewReport}
                          onChange={handleChangeFieldCheckbox('IsAllowSpecialistViewReport')}
                          disabled={
                            supplementaryInfo.IsLocked ||
                            supplementaryInfo.ChargeStatus === 4 ||
                            supplementaryInfo.SentDateAllowSpecialistViewReport
                          }
                        >
                          <span style={commonInlineStyles.inlineBlock}>Specialist</span>{' '}
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content="Send copy of final report to specialist (MAIL-59)"
                            position="top center"
                            wide="very"
                          />
                        </Checkbox>
                        {supplementaryInfo.SentDateAllowSpecialistViewReport && (
                          <>
                            <span style={commonInlineStyles.highlightInline}>
                              {supplementaryInfo.SentDateAllowSpecialistViewReport
                                ? renderDate(supplementaryInfo.SentDateAllowSpecialistViewReport, false, true)
                                : ''}
                              {' By ' + store.renderStaffName(supplementaryInfo.SentByAllowSpecialistViewReport)}
                            </span>
                            <Button
                              className="blue"
                              disabled={
                                supplementaryInfo.IsLocked ||
                                !supplementaryInfo.IsAllowSpecialistViewReport ||
                                !supplementaryInfo.SentDateAllowSpecialistViewReport
                              }
                              onClick={() => {
                                store.toggleModalConfirm(true, {
                                  message: 'Do you want to resend completed to specialist?',
                                  onOk: () => {
                                    customFetch('/Supplementary/ResendReports', {
                                      headers: { 'Content-Type': 'application/json' },
                                      method: 'POST',
                                      body: JSON.stringify({ id: supplementaryInfo.Id, toSpecialist: true }),
                                    })
                                      .then(response => {
                                        if (response.status === 'success') {
                                          notification.destroy();
                                          notification.success({
                                            message: 'Success',
                                            description: 'Resend report',
                                          });
                                          store.handleChangeSuppValue({
                                            SentDateAllowSpecialistViewReport: moment(response.ResendDatetime),
                                          });
                                        } else {
                                          notification.destroy();
                                          notification.Error({
                                            message: 'Error',
                                            description: 'Resend report',
                                          });
                                        }
                                      })
                                      .catch(() => {});
                                  },
                                });
                              }}
                            >
                              Resend
                            </Button>
                          </>
                        )}
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={supplementaryInfo.IsAllowSolicitorViewReport}
                            onChange={handleChangeFieldCheckbox('IsAllowSolicitorViewReport')}
                            disabled={
                              supplementaryInfo.IsLocked ||
                              supplementaryInfo.ChargeStatus === 4 ||
                              !!supplementaryInfo.SentDateAllowSolicitorViewReport ||
                              // !store.bookingSolicitorSelected ||
                              // !supplementaryInfo.bookingSolicitorSelected?.Id
                              !itemModel.F_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 1{' '}
                            {api.isAdminOrMagStaffOrAccounting() && (
                              <Popup
                                trigger={<Icon name="info circle" />}
                                content="Final report will be sent to CMs (MAIL-59, DIRECT-59)"
                                position="top center"
                              />
                            )}
                          </Checkbox>
                          {supplementaryInfo.SentDateAllowSolicitorViewReport && (
                            <>
                              <span style={styles.highlightInLineWidth300}>
                                {supplementaryInfo.SentDateAllowSolicitorViewReport
                                  ? renderDate(supplementaryInfo.SentDateAllowSolicitorViewReport, false, true)
                                  : ''}
                                {supplementaryInfo.SentByAllowSolicitorViewReport
                                  ? ` - By ${store.renderStaffName(supplementaryInfo.SentByAllowSolicitorViewReport)}`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  supplementaryInfo.IsLocked ||
                                  !supplementaryInfo.IsAllowSolicitorViewReport ||
                                  !supplementaryInfo.SentDateAllowSolicitorViewReport
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend completed to Client Receiving Contact 1?',
                                    onOk: () => {
                                      customFetch('/Supplementary/ResendReportToSolictor', {
                                        headers: { 'Content-Type': 'application/json' },
                                        method: 'POST',
                                        body: JSON.stringify({ id: supplementaryInfo.Id }),
                                      })
                                        .then(response => {
                                          if (response.status === 'success') {
                                            notification.destroy();
                                            notification.success({
                                              message: 'Success',
                                              description: 'Resend report',
                                            });
                                            store.handleChangeSuppValue({
                                              SentDateAllowSolicitorViewReport: moment(response.ResendDatetime),
                                            });
                                          } else {
                                            notification.destroy();
                                            notification.Error({
                                              message: 'Error',
                                              description: 'Resend report',
                                            });
                                          }
                                        })
                                        .catch(() => {});
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={commonInlineStyles.marginTop5}
                          value={itemModel.F_RDCCaseManagerId || undefined}
                          onChange={handleChangeFieldSelect('F_RDCCaseManagerId')}
                          disabled={store.isLockReport_CM || itemModel.F_RDCSameBookingCM || api.isTypist()}
                          onSearch={handleSearch('searchOptBookingCM')}
                          onPopupScroll={handleScroll('searchOptBookingCM')}
                          showSearch
                          allowClear
                          filterOption="children"
                        >
                          {itemToArray(bookingCMSelected, bookingCMs, 'Id').map(({ Id, FullName, Email }) => (
                            <Select.Option value={Id} key={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={supplementaryInfo.IsAllowCMViewReport}
                            onChange={handleChangeFieldCheckbox('IsAllowCMViewReport')}
                            disabled={
                              supplementaryInfo.IsLocked ||
                              supplementaryInfo.ChargeStatus === 4 ||
                              !!supplementaryInfo.EmailToCMsViewReportRecent ||
                              !itemModel.S_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 2{' '}
                            {api.isAdminOrMagStaffOrAccounting() && (
                              <Popup
                                trigger={<Icon name="info circle" />}
                                content="Final report will be sent to CMs (MAIL-59, DIRECT-59)"
                                position="top center"
                              />
                            )}
                          </Checkbox>
                          {supplementaryInfo.EmailToCMsViewReportRecent && (
                            <>
                              <span style={styles.marginLeftHighlightInLineWidth300}>
                                {supplementaryInfo.EmailToCMsViewReportRecent
                                  ? renderDate(supplementaryInfo.EmailToCMsViewReportRecent, false, true)
                                  : ''}
                                {supplementaryInfo.EmailToCMsViewReportRecentByUser
                                  ? ` - By ${
                                      store.staffs.find(
                                        i => i.Id === supplementaryInfo.EmailToCMsViewReportRecentByUser,
                                      )
                                        ? store.staffs.find(
                                            i => i.Id === supplementaryInfo.EmailToCMsViewReportRecentByUser,
                                          ).FullName
                                        : supplementaryInfo.EmailToCMsViewReportRecentByUser
                                    }`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  supplementaryInfo.IsLocked ||
                                  !supplementaryInfo.IsAllowCMViewReport ||
                                  !supplementaryInfo.EmailToCMsViewReportRecent
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend completed to Client Receiving Contact 2?',
                                    onOk: () => {
                                      if (!supplementaryInfo.F_RDCEmail && !supplementaryInfo.S_RDCEmail) {
                                        store.showError('At least one Report Delivery Contact needs to be completed');
                                      } else {
                                        customFetch('/Supplementary/ResendReports', {
                                          headers: { 'Content-Type': 'application/json' },
                                          method: 'POST',
                                          body: JSON.stringify({ id: supplementaryInfo.Id }),
                                        })
                                          .then(response => {
                                            if (response.status === 'success') {
                                              notification.destroy();
                                              notification.success({
                                                message: 'Success',
                                                description: 'Resend report',
                                              });
                                              store.handleChangeSuppValue({
                                                EmailToCMsViewReportRecent: moment(response.ResendDatetime),
                                              });
                                            } else {
                                              notification.destroy();
                                              notification.Error({
                                                message: 'Error',
                                                description: 'Resend report',
                                              });
                                            }
                                          })
                                          .catch(() => {});
                                      }
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          size="large"
                          value={itemModel.S_RDCCaseManagerId || undefined}
                          onChange={handleChangeFieldSelect('S_RDCCaseManagerId')}
                          disabled={store.isLockReport_CM || api.isTypist()}
                          onSearch={handleSearch('searchOptReportDeliveryContactSecond')}
                          onPopupScroll={handleScroll('searchOptReportDeliveryContactSecond')}
                          showSearch
                          allowClear
                          filterOption="children"
                        >
                          {deliverySecondCMs.map(({ Id, FullName, Email }) => (
                            <Select.Option value={Id} key={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                  <Form.Item label="Attach Amended Reports" className="add-file-container" style={styles.minHeightAuto}>
                    <FieldUpload
                      multiple
                      type={4}
                      disabled={store.isLockReport || api.isDoctor()}
                      disabledDelete={store.isLockReport}
                    />
                  </Form.Item>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <>
                      <Form.Item
                        label="Send Amended Reports to"
                        style={commonInlineStyles.marginBottom0}
                        className="fluid-field"
                      ></Form.Item>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={supplementaryInfo.IsAllowSolicitorViewAmendedReport}
                            onChange={handleChangeFieldCheckbox('IsAllowSolicitorViewAmendedReport')}
                            disabled={
                              supplementaryInfo.IsLocked ||
                              supplementaryInfo.ChargeStatus === 4 ||
                              !!supplementaryInfo.SentDateAllowSolicitorViewAmendedReport ||
                              // !store.bookingSolicitorSelected ||
                              // !store.bookingSolicitorSelected?.Id
                              !itemModel.F_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 1{' '}
                            {api.isAdminOrMagStaffOrAccounting() && (
                              <Popup
                                trigger={<Icon name="info circle" />}
                                content="Amended Final report will be sent to CMs (MAIL-100, DIRECT-100)"
                                position="top center"
                              />
                            )}
                          </Checkbox>
                          {supplementaryInfo.SentDateAllowSolicitorViewAmendedReport && (
                            <>
                              <span style={styles.highlightInLineWidth300}>
                                {supplementaryInfo.SentDateAllowSolicitorViewAmendedReport
                                  ? renderDate(supplementaryInfo.SentDateAllowSolicitorViewAmendedReport, false, true)
                                  : ''}
                                {supplementaryInfo.SentByAllowSolicitorViewAmendedReport
                                  ? ` - By ${store.renderStaffName(
                                      supplementaryInfo.SentByAllowSolicitorViewAmendedReport,
                                    )}`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  supplementaryInfo.IsLocked ||
                                  !supplementaryInfo.IsAllowSolicitorViewAmendedReport ||
                                  !supplementaryInfo.SentDateAllowSolicitorViewAmendedReport
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend amended report to Client Receiving Contact 1?',
                                    onOk: () => {
                                      customFetch('/Supplementary/ResendAmendedReportToSolictor', {
                                        headers: { 'Content-Type': 'application/json' },
                                        method: 'POST',
                                        body: JSON.stringify({ id: supplementaryInfo.Id }),
                                      })
                                        .then(response => {
                                          if (response.status === 'success') {
                                            notification.destroy();
                                            notification.success({
                                              message: 'Success',
                                              description: 'Resend Amended report',
                                            });
                                            store.handleChangeValue(
                                              'SentDateAllowSolicitorViewAmendedReport',
                                              moment(response.ResendDatetime),
                                            );
                                          } else {
                                            notification.destroy();
                                            notification.Error({
                                              message: 'Error',
                                              description: 'Resend Amended report',
                                            });
                                          }
                                        })
                                        .catch(() => {});
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          size="large"
                          style={commonInlineStyles.marginTop5}
                          value={itemModel.F_RDCCaseManagerId || undefined}
                          onChange={handleChangeFieldSelect('F_RDCCaseManagerId')}
                          disabled={store.isLockReport_CM || itemModel.F_RDCSameBookingCM || api.isTypist()}
                          onSearch={handleSearch('searchOptBookingCM')}
                          onPopupScroll={handleScroll('searchOptBookingCM')}
                          showSearch
                          allowClear
                          filterOption="children"
                        >
                          {itemToArray(bookingCMSelected, bookingCMs, 'Id').map(({ Id, FullName, Email }) => (
                            <Select.Option value={Id} key={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div style={styles.minHeight40}>
                          <Checkbox
                            checked={supplementaryInfo.IsAllowCMViewAmendedReport}
                            onChange={handleChangeFieldCheckbox('IsAllowCMViewAmendedReport')}
                            disabled={
                              supplementaryInfo.IsLocked ||
                              supplementaryInfo.ChargeStatus === 4 ||
                              !!supplementaryInfo.SentAmendedReportsDate ||
                              !itemModel.S_RDCCaseManagerId
                            }
                          >
                            Client Receiving Contact 2{' '}
                            {api.isAdminOrMagStaffOrAccounting() && (
                              <Popup
                                trigger={<Icon name="info circle" />}
                                content="Amended Final report will be sent to CMs (MAIL-100, DIRECT-100)"
                                position="top center"
                              />
                            )}
                          </Checkbox>
                          {supplementaryInfo.SentAmendedReportsDate && (
                            <>
                              <span style={styles.marginLeftHighlightInLineWidth300}>
                                {supplementaryInfo.SentAmendedReportsDate
                                  ? renderDate(supplementaryInfo.SentAmendedReportsDate, false, true)
                                  : ''}
                                {supplementaryInfo.SentAmendedReportsByUser
                                  ? ` - By ${
                                      store.staffs.find(i => i.Id === supplementaryInfo.SentAmendedReportsByUser)
                                        ? store.staffs.find(i => i.Id === supplementaryInfo.SentAmendedReportsByUser)
                                            .FullName
                                        : supplementaryInfo.SentAmendedReportsByUser
                                    }`
                                  : ''}
                              </span>
                              <Button
                                className="blue"
                                disabled={
                                  supplementaryInfo.IsLocked ||
                                  !supplementaryInfo.IsAllowCMViewAmendedReport ||
                                  !supplementaryInfo.SentAmendedReportsDate
                                }
                                onClick={() => {
                                  store.toggleModalConfirm(true, {
                                    message: 'Do you want to resend amended report to Client Receiving Contact 2?',
                                    onOk: () => {
                                      if (!supplementaryInfo.F_RDCEmail && !supplementaryInfo.S_RDCEmail) {
                                        store.showError('At least one Report Delivery Contact needs to be completed');
                                      } else {
                                        customFetch('/Supplementary/ResendAmendedReports', {
                                          headers: { 'Content-Type': 'application/json' },
                                          method: 'POST',
                                          body: JSON.stringify({ id: supplementaryInfo.Id }),
                                        })
                                          .then(response => {
                                            if (response.status === 'success') {
                                              notification.destroy();
                                              notification.success({
                                                message: 'Success',
                                                description: 'Resend Amended report',
                                              });
                                              store.handleChangeValue(
                                                'SentAmendedReportsDate',
                                                moment(response.ResendDatetime),
                                              );
                                            } else {
                                              notification.destroy();
                                              notification.Error({
                                                message: 'Error',
                                                description: 'Resend Amended report',
                                              });
                                            }
                                          })
                                          .catch(() => {});
                                      }
                                    },
                                  });
                                }}
                              >
                                Resend
                              </Button>
                            </>
                          )}
                        </div>
                        <Select
                          style={commonInlineStyles.marginTop5}
                          size="large"
                          value={itemModel.S_RDCCaseManagerId || undefined}
                          onChange={handleChangeFieldSelect('S_RDCCaseManagerId')}
                          disabled={store.isLockReport_CM || api.isTypist()}
                          onSearch={handleSearch('searchOptReportDeliveryContactSecond')}
                          onPopupScroll={handleScroll('searchOptReportDeliveryContactSecond')}
                          showSearch
                          allowClear
                          filterOption="children"
                        >
                          {deliverySecondCMs.map(({ Id, FullName, Email }) => (
                            <Select.Option value={Id} key={Id}>
                              {Id} - {FullName} - {Email}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <Form.Item label="Comments" className="fluid-field">
                        <Input.TextArea
                          rows={3}
                          value={supplementaryInfo.Comment}
                          onChange={handleChangeFieldInput('Comment')}
                        />
                      </Form.Item>
                    </>
                  )}
                </>
              }
            </Collapse.Panel>
          )}
        </Collapse>
      </Form>
    );
  }
}

export default CaseDocumentsForm;
