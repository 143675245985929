import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { notification, Select } from 'antd';
import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import Store from './Store';
import { getCities } from './Service';
import * as api from '@stores/api';

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.modalParams.record);
    this.state = {
      cities: [],
      body: this.props.modalParams.record
        ? {
            ...this.props.modalParams.record,
            LanguageName: this.props.modalParams.record.LanguageName || '',
            Description: this.props.modalParams.record.Description || '',
            IsActive: this.props.modalParams.record.IsActive || false,
          }
        : {
            LanguageName: '',
            Description: '',
            IsActive: true,
          },
    };
  }

  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    console.log(name);
    this.setState(state => (state.body[name] = value));
  };

  handleChecked = () => {
    const { body } = this.state;
    this.setState(action(state => (state.body.IsActive = !body.IsActive)));
  };

  @action toggleCheckbox = (_, { name, checked }) => {
    console.log(name);
    this.setState(state => (state.body[name] = checked));
  };

  handleSelectChange = fieldName => event => {
    const { body } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      body: {
        ...body,
        [fieldName]: value,
      },
    });
  };

  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    if (!body.LanguageName) {
      return;
    }
    const param = {
      LanguageName: body.LanguageName,
      Description: body.Description,
      IsActive: body.IsActive,
    };
    Store.setFieldsValue({ loading: true });
    try {
      var resp = await customFetch('/Language/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          LanguageId: id,
          ...param,
        }),
      });

      if (resp.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Successfully',
          description: 'Data has been saved successfully.',
          duration: 3,
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }

      await Store.refetch({}, true);
    } catch (e) {
    } finally {
      Store.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body, cities } = this.state;
    const { loading } = Store;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`Add/update Language`}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>}
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  error={!body.LanguageName ? 'This field is required' : null}
                  label="Name"
                  value={body.LanguageName}
                  placeholder="LanguageName"
                  name="LanguageName"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  rows={3}
                  label="Description"
                  value={body.Description}
                  placeholder="Description"
                  name="Description"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Checkbox label="Active" checked={body.IsActive} onClick={this.handleChecked} disabled={loading} />
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
