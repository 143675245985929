import React from 'react';
import { Link } from 'react-router-dom';

const accordionItems = [
  {
    title: 'IME Reports',
    cmUrl: '/view/ime-assessments-2',
    adminUrl: '/view/ime-assessments-2',
    drUrl: '/view/doctor-ime',
  },
  {
    title: 'File Reviews',
    cmUrl: '/view/admin-file-reviews',
    adminUrl: '/view/admin-file-reviews',
    drUrl: '/view/doctor-file-reviews',
  },
  {
    title: 'Supplementary Reports',
    cmUrl: '/view/supplementary-reports-2',
    adminUrl: '/view/supplementary-reports-2',
    drUrl: '/view/doctor-supplementary',
  },
];

const accordionPaths = ['/MedicalService', '/Supplementary', '/FileReview'];

function EnhancedLink({ children, ...props }) {
  const handleClick = event => {
    event.stopPropagation();
    props.onClick && props.onClick(event);
  };

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  );
}

export { accordionItems, accordionPaths, EnhancedLink };
