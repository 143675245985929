import React from 'react';
import { CKEditor } from 'ckeditor4-react';

let config = {
  extraPlugins: 'justify,font,colorbutton,colordialog',
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    {
      name: 'undo',
      groups: ['undo'],
    },
    {
      name: 'redo',
      groups: ['redo'],
    },
    '/',
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    '/',
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
  ],
  removeButtons:
    'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting',
  fontSize_sizes: '6/6px;8/8px;10/10px;12/12px;16/16px;24/24px;48/48px;',
  font_names: 'Arial/Arial, Helvetica, sans-serif;' + 'Times New Roman/Times New Roman, Times, serif;' + 'Verdana',
  allowedContent: true,
};

class Editor extends React.Component {
  constructor(props) {
    super(props);
    if (props.disabled && props.disabled === true) {
      config = { ...config, readOnly: true };
    } else {
      config = { ...config, readOnly: false };
    }
    this.onChange = this.onChange.bind(this);
    this.state = {
      html: this.props.html,
    };
  }

  onChange(evt) {
    var html = evt.editor.getData();
    this.setState({ html });
    this.props.handleChangeContent && this.props.handleChangeContent(html);
  }

  render() {
    return <CKEditor config={config} initData={this.state.html} onChange={this.onChange} />;
  }
}
export default Editor;
