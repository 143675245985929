import customFetch from '../../../utils/customFetch';
import { models } from 'powerbi-client';

export const getEmbedReportSetting = reportId => {
  return new Promise((resolve, reject) => {
    customFetch('/EmbedReport/GetEmbedReport?reportId=' + reportId, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
    })
      .then(resp => {
        const { EmbedToken, EmbedReports } = resp;
        const report = EmbedReports[0];

        const embedConfiguration = {
          accessToken: EmbedToken.Token,
          embedUrl: report.EmbedUrl,
          id: report.ReportId,
          type: 'report',
          permissions: models.Permissions.All,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        };

        resolve(embedConfiguration);
      })
      .catch(resp => {
        reject(resp);
      });
  });
};
