import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Dropdown, Form, Input, Loader, Radio, Segment, TextArea } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import TypeOfReportStore from './TypeOfReportStore';

@observer
class FormAddEdit extends React.Component {
  state = {
    canEdit: api.isAdmin(),
  };
  renderAccLabel = () => {
    const { dataAcc } = TypeOfReportStore;
    let data = dataAcc.itemList.map(opt => ({
      text: opt.Label,
      value: opt.Id,
    }));
    return data;
  };

  handleChange = (_, { name, value }) => {
    const { dataReport } = TypeOfReportStore;
    TypeOfReportStore.setFieldsValue({
      dataReport: { ...dataReport, [name]: value },
    });
  };

  handleChangeActive = () => {
    const { dataReport } = TypeOfReportStore;
    const { isActive } = TypeOfReportStore;
    TypeOfReportStore.setFieldsValue({
      dataReport: { ...dataReport, isActive: !isActive },
    });
  };
  handSave = async () => {
    const { dataReport, dataAcc } = TypeOfReportStore;
    let model = {};
    let url = '';
    const newvalue = dataReport.qualifiedAccreditations.map(n => dataAcc.itemList.find(i => i.Id === n));
    model = {
      Accreditations: newvalue.map(i => i),
      Description: dataReport.descriptions,
      Id: dataReport.id,
      IsActive: dataReport.isActive,
      Name: dataReport.name,
      ReportDueDay: dataReport.periodDays,
    };
    url = '/TypesOfReport/SaveData';
    await customFetch(`${url}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ accreditations: newvalue, reportType: model }),
    }).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          title: 'Success',
          message: 'Data has been saved successfully.',
        });
      }
    });
  };
  handleClose = () => {
    window.closeCurrentTab();
    TypeOfReportStore.onReset();
  };
  onClose = () => {
    return TypeOfReportStore.toggleModal(true, {
      modalType: 'confirm',
      message: 'All unsaved changes will be discarded. Do you want to continue?',
      onOk: () => this.handleClose(),
    })();
  };
  handleChangDropdown = (_, { value }) => {
    const { dataReport } = TypeOfReportStore;

    TypeOfReportStore.setFieldsValue({
      dataReport: { ...dataReport, qualifiedAccreditations: value },
    });
  };
  render() {
    const { dataReport } = TypeOfReportStore;
    const { canEdit } = this.state;

    const data = dataReport;
    return (
      <Segment size="massive">
        <Dimmer>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Field>
            <label>Report Name</label>
            <Input placeholder="Report Name" value={data.name} name="name" onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Completion Period (days)</label>
            <Input type="number" value={data.periodDays} name="periodDays" onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder="Description"
              name="descriptions"
              className="text-area-description"
              onChange={this.handleChange}
              value={data.descriptions}
            />
          </Form.Field>
          <Form.Field>
            <label>Qualified Accreditations</label>
            <Dropdown
              options={this.renderAccLabel()}
              search
              clearable
              fluid
              selection
              value={data.qualifiedAccreditations.map(i => i)}
              onChange={this.handleChangDropdown}
              multiple
            />
          </Form.Field>
          <Form.Field>
            <label>Set Type Active</label>
            <Radio toggle checked={data.isActive} onChange={this.handleChangeActive} />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Button floated="right" className="negative" onClick={this.onClose}>
                Close
              </Button>
              {!api.isOnlySearch() && <Button floated="right" onClick={this.handSave} className="btn-primary-submit" disabled={!canEdit}>
                Save
              </Button>}
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default FormAddEdit;
