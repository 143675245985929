import { notification } from 'antd';
import get from 'lodash/get';
import React from 'react';

export const messages = {
  NetworkFailed: 'Can not connect to the server',
  ServerError: 'An error occurred on the server',
  Unknown: 'An error occurred, please try again',
  ExistEmail:
    'This email has been registered already. You can reset password or contact support@medicolegalassessmentsgroup.com.au for support',
  Unauthorized:
    'You do not have permission to view this data or have been logged out from the system, please refresh the browser and try again',
  RequireAnonymous: 'You have been logged in already, please refresh the browser and try again',

  InvalidModel: 'Your provided data is invalid',

  LoginIncorrect: 'Your username or password is incorrect',
  AccountDisabled:
    'Due to multiple incorrect login attempts, your account has been disabled. You can try again after 5 minutes or use "Forgot Password" link to reset password. Contact support@medicolegalassessmentsgroup.com.au for more support',
  AccountLockedOut:
    'Due to multiple incorrect login attempts, your account has been disabled. You can try again after 5 minutes or use "Forgot Password" link to reset password. Contact support@medicolegalassessmentsgroup.com.au for more support',
  AccountRequireVerify: 'Your account requires verification, please check with your administrator',

  InvalidResetCode: 'The link you followed is invalid or expired',
  CaseNotFound: 'This case number does not exist. Please check again',
  Required: 'This field is required',
  Email: 'Invalid email address',
  Password:
    'Password must have at least 8 characters, including special character: !@#$%&*(), uppercase letter: A-Z, lowercase letter: a-z, and digit: 0-9',
};

const getErrorMessage = err => messages[err.message] || messages[err] || messages.Unknown;

const showFetchError = err => {
  notification.destroy('FetchError');
  setTimeout(() => {
    notification.error({
      message: 'Error',
      description: getErrorMessage(err),
      duration: 0,
      key: 'FetchError',
    });
  }, 170);
};

const showInvalidFormError = () => {
  notification.destroy('InvalidFormError');
  setTimeout(() => {
    notification.error({
      message: 'Invalid',
      description: messages.InvalidModel,
      duration: 0,
      key: 'InvalidFormError',
    });
  }, 170);
};

const getApolloErrorType = err => {
  const errType = get(err, 'graphQLErrors.0.message');
  if (errType && errType in messages) {
    return errType;
  }
  const status = get(err, 'networkError.response.status');
  if (status && (status < 200 || status > 600)) {
    return 'NetworkFailed';
  }
  return 'Unknown';
};

const getErrorMessageFromType = errType => {
  if (errType in messages) {
    return messages[errType];
  }
  return messages.Unknown;
};

const getApolloErrorMessage = err => {
  const errType = getApolloErrorType(err);
  return getErrorMessageFromType(errType);
};

const showApolloError = err => {
  notification.destroy('ApolloError');
  setTimeout(() => {
    notification.error({
      message: 'Error',
      description: getApolloErrorMessage(err),
      duration: 0,
      key: 'ApolloError',
    });
  }, 170);
};

export const showUnexpectedError = (message, autoDestroy = false) => {
  notification.destroy('UnexpectedError');
  setTimeout(() => {
    notification.error({
      message: 'Error',
      description: message || messages.Unknown,
      duration: 0,
      key: 'UnexpectedError',
    });
  }, 170);
  if (autoDestroy) {
    setTimeout(() => {
      notification.destroy('UnexpectedError');
    }, 2000);
  }
};

export const showUnauthorizedError = () => {
  notification.destroy('UnauthorizedError');
  setTimeout(() => {
    notification.error({
      message: 'Unauthorized',
      description: (
        <span>
          You need to <a href="/view/register">register</a> an account to make a booking
        </span>
      ),
      duration: 0,
      key: 'UnauthorizedError',
    });
  }, 170);
};

export { getErrorMessage, showFetchError, showInvalidFormError, showApolloError };
