import './index.scss';
import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { Divider, Icon, Table, TableBody } from 'semantic-ui-react';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import dashboardStore from '@stores/dashboard';
import uploadStore from '../../shared/UniversalSearch/UploadFile/UploadStore';
import customFetch from '../../../utils/customFetch';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import IMEStore from './IMEStore';
import appStore from 'stores/app';

const columnsForAdmin = [
  { title: '' },
  { title: 'Case No', sortKey: 'AssessmentNumber' },
  { title: 'Case Status', sortKey: 'Status' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'InquiryDate' },
  { title: 'Appointment Date', sortKey: 'DateFrom' },
  { title: 'Action Required', sortKey: '' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Type Of Claim/Report', sortKey: 'TypeOfClaimLabel' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Booking Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Assessing Specialist', sortKey: 'DoctorName' },
  { title: 'Clinic Information' },
  { title: 'Next Task' },
  { title: 'Next Task PIC', sortKey: 'NextPicName' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];
const columnsForCM = [
  { title: '' },
  { title: 'Case No', sortKey: 'MagRefNo' },
  { title: 'Case Status', sortKey: 'StatusCMText' },
  { title: 'Request Date', sortKey: 'BookingDate' },
  { title: 'Appointment Date', sortKey: 'AppointmentDate' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Type Of Claim/Report', sortKey: 'TypeOfClaimLabel' },
  { title: 'Claimant', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManagerId' },
  { title: 'Doctor', sortKey: 'Doctor' },
  { title: 'Clinic Centre', sortKey: 'ClinicEmail' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Created By' },
];
@observer
class IMETable extends React.Component {
  openModal = options => {
    return IMEStore.toggleModal(true, options);
  };

  convertEmailCreatedAt = email => {
    const email_ = email ? email.replace(/^(.+)@(.+)$/g, '$1') : email;
    return email_;
  };

  isShowDetailTypist = record => {
    if (api.isTypist()) {
      switch (record.Status) {
        case api.imeStatus.CANCELLED:
        case api.imeStatus.IME_CANCELLED_WITH_FEE:
        case api.imeStatus.BILLED:
        case api.imeStatus.FULLY_PAID:
          return false;
        default:
          break;
      }
    }
    return true;
  };

  openCaseDetail = (id, name) => () => {
    localStorage.setItem('AssessmentCaseId', `Case ${name}`);
    if (appStore.iframeMode) {
      this.openCaseDetailIframeMode(id);
      return;
    }
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}`);
    });
  };

  openCaseDetailIframeMode = (id, view) => {
    window.parent.postMessage({ action: 'OPEN_IME_DETAIL', data: { id: id, view: view } }, '*');
  };
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Appointment':
        return 1;
      case 'Paperwork':
        return 2;
      case 'Attendance':
        return 3;
      case 'Completion':
        return 4;
      case 'Invoices':
      case 'ServicePrepaymentSection':
        return 5;
      case 'Corespondence':
        return 6;
      case 'ActionRequired':
        return 7;
      default:
        return 0;
    }
  };

  openCaseUploadAppointment = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI('Appointment')}`);
    });
  };

  openCaseUploadPaperwork = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI('Paperwork')}`);
    });
  };

  openCaseUpdateAttAndCancel = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI('Attendance')}`);
    });
  };

  openCaseDictation = (id, name) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('AssessmentCaseId', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI('Completion')}`);
    });
  };

  openCaseCorrespondence = (id, name, type) => () => {
    localStorage.setItem('AssessmentCaseId', `Case ${name}`);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  addNewInvoice = (id, name) => () => {
    // localStorage.setItem('InvoiceCaseId', id);
    localStorage.removeItem(`InvoiceCaseId`);
    iframe.forceOpenTab('ViewInvoice', `?id=0&mId=${id}&type=A&view=ViewInvoice`, {
      id: 0,
      type: 'A',
      mId: id,
      view: 'ViewInvoice',
    });
  };

  openCaseViewInvoice = (id, name) => () => {
    localStorage.setItem('InvoiceCaseId', `INV-${id}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  doOpenCaseRequestSuppReport = (id, name) => () => {
    localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
    });
  };

  openCaseRequestSuppReport = (id, name) => async () => {
    await customFetch('/Supplementary/CheckOriginalCaseNo', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ caseNo: name }),
    }).then(res => {
      if (res.status === 'success') {
        if (res.exist) {
          var existItems = '';
          var message = '';

          res.listExist.forEach(i => {
            existItems += i.CaseNo + ' - ' + i.StatusStaffText + ', ';
          });

          if (res.listExist.length > 1) {
            message =
              'Supplementary requests (' +
              existItems.substring(0, existItems.length - 2) +
              ') have already been created for this case. Do you want to proceed?';
          } else {
            message =
              'A supplementary request (' +
              existItems.substring(0, existItems.length - 2) +
              ') has already been created for this case. Do you want to proceed?';
          }
          IMEStore.toggleModal(true, {
            modalType: 'confirm',
            message: message,
            onOk: function() {
              localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
              dashboardStore.close('/view/add-edit-supplementary-report-2');
              setTimeout(() => {
                dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
              });
            },
          })();
        } else {
          localStorage.setItem('SupplementaryCaseId', 'New Supplementary Report');
          dashboardStore.close('/view/add-edit-supplementary-report-2');
          setTimeout(() => {
            dashboardStore.open(`/view/add-edit-supplementary-report-2?caseNo=${name}&mType=A&mId=${id}`);
          });
        }
      }
    });
  };

  handleDuplicate = id => async () => {
    customFetch('/MedicalService/Assessment_CopyDetail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ assessmentId: id }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.success({
            message: 'Success',
            description: 'Assessment is duplicated',
          });
          IMEStore.refetch({}, true);
        }
      })
      .catch(() => {});
  };

  handleDelete = (id, record) => {
    if (record.Status === api.imeStatus.IME_REQUESTED || record.Status === api.imeStatus.CANCELLED) {
      IMEStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to delete this assessment?',
        onOk: async () => {
          IMEStore.setFieldsValue({ loading: true });
          try {
            const response = await await customFetch('/MedicalService/DeleteAssessment', {
              headers: { 'Content-Type': 'application/json' },
              method: 'POST',
              body: JSON.stringify({ id: id }),
            });
            if (response.status === 'success') {
              await IMEStore.refetch({}, true);
            }
          } catch (error) {
          } finally {
            IMEStore.setFieldsValue({ loading: false });
          }
        },
      })();
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  handlePrintAppCon = id => {
    window.open('/MedicalService/AppointmentConfirmationLetterPrint?aId=' + id, '_blank');
  };

  handPrintDoctor = id => {
    window.open('/MedicalService/DoctorConfPrint?aId=' + id, '_blank');
  };

  handPrintInvoice = id => {
    window.open('/Invoice/ViewBilling?invoiceId=' + id, '_blank');
  };

  handleCopyVoice = id => async () => {
    try {
      await customFetch('/Invoice/Checkexist', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id: id, module: 'Assessment' }),
      });
      await IMEStore.refetch({}, true);
    } finally {
    }
  };

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/MedicalService/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 1, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } finally {
    }
  };

  handleAttendanceRemider = id => async () => {
    try {
      await customFetch('/MedicalService/SendAttendanceRemider', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Attendance reminder was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Attendance reminder can't be sent`,
            duration: 2,
          });
        }
      });
    } catch (e) {}
  };

  handleUnClockForEdit = id => async () => {
    try {
      await customFetch('/MedicalService/UnlockEdit', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id: id, module: 'Assessment' }),
      });
      await IMEStore.refetch({}, true);
    } finally {
    }
  };

  renderTableBodyForAdmin = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          onClick={this.openCaseDetail(Id, record.AssessmentNumber)}
          className="Cell-link Cell-nowrap"
        >
          <Link to="#" onClick={this.openCaseDetail(Id, record.AssessmentNumber)}>
            {record.AssessmentNumber}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.AssessmentNumber,
              status: record.Status,
            })}
          >
            {record.OldBilled ? (
              record.Status === api.imeStatus.IME_REQUESTED ? (
                <div style={{ color: 'red' }}>Old-Billed</div>
              ) : (
                'Old-Billed'
              )
            ) : record.Status === api.imeStatus.IME_REQUESTED ? (
              <div style={{ color: 'red' }}>{record.StatusStaffText}</div>
            ) : (
              record.StatusStaffText
            )}
          </Link>
        </Table.Cell>
        <Table.Cell>{moment(record.StatusDate).format('DD/MM/YYYY')}</Table.Cell>
        <Table.Cell>
          {record.InquiryDate ? moment(formatDate(record.InquiryDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        {this.renderVideoSessionDetailForAdmin(Id, record)}
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.AssessmentNumber,
              client: record.Client,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          <br />
          <div>
            {record.ActionRequiredType > 0 && record.ActionOverview
              ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
              : ''}
          </div>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo || 'N/A'}</Table.Cell>
        <Table.Cell>
          {record.TypeOfClaimLabel}/{record.TypeOfReportLabel}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'claimant',
                id: record.ClaimantId,
                localStorageName: 'ClaimantName',
                localStorageValue: record.Claimant,
              })
            }
          >
            ({record.ClaimantId}) {record.Claimant}
          </Link>
          <br /> DOB:{' '}
          {record.ClaimantDoB ? moment(formatDate(record.ClaimantDoB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
          <br />
          <FormatPhoneNumber
            officePhoneNumber={record.ClaimantHomePhone}
            mobilePhoneNumber={record.ClaimantTelePhone}
          />
        </Table.Cell>
        <Table.Cell className={cx(`${(record.Client || record.SubBookingClientName) && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <div>
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'cm',
                  id: record.CaseManagerId,
                  localStorageName: 'CmName',
                  localStorageValue: record.CaseManager,
                })
              }
            >
              ({record.CaseManagerId}) {record.CaseManager}
            </Link>
            <br /> {record.CMEmail}
            <br />
            <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          {record.HasMultiAppointment ? (
            `Multi-Option: ${record.Appointments.map(m => m.DoctorTitle + ' ' + m.DoctorFullName)} 
                            ${record.Appointments.length === 1 ? '' : ','}`
          ) : (
            <div>
              <Link
                to="#"
                onClick={() =>
                  OpenCaseDetails({
                    tabName: 'specialist',
                    id: record.DoctorId,
                    localStorageName: 'DoctorName',
                    localStorageValue: record.DoctorName,
                  })
                }
              >
                {record.DoctorName ? `(${record.DoctorId}) ${record.DoctorName}` : ''}{' '}
                {record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}
              </Link>
              {record.DoctorSpecialty ? <br /> : ''}
              {record.DoctorSpecialty ? `Specialty: ${record.DoctorSpecialty}` : ''} <br />
              {record.DoctorSpecialty
                ? `Video Assessment: ${
                    record.DoctorTeleAssessment === 0 ? 'Unsure' : record.DoctorTeleAssessment === 1 ? 'Yes' : 'No'
                  }`
                : ''}
              <br />
              <FormatPhoneNumber mobilePhoneNumber={record.DoctorPhone} officePhoneNumber={record.DoctorOfficePhone} />
              {record.DoctorPhone || record.DoctorOfficePhone ? <br /> : ''}
              {record.DoctorEmail ? record.DoctorEmail : ''}
              {record.DoctorEmail ? <br /> : ''}
              {record.DoctorName ? (
                <span className={record.ContractReady === 1 ? 'link-add-action' : 'link-view-action'}>
                  Contract Ready: {record.ContractReady === 1 ? 'Yes' : 'No'}
                </span>
              ) : (
                ''
              )}{' '}
              <br />
              {record.DoctorName ? (
                <span>
                  {record.DoctorType === 0
                    ? 'MAG Ultimate'
                    : record.DoctorType === 1
                    ? 'MAG Direct-Essential'
                    : 'MAG Direct-Extra'}
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'clinic',
                id: record.ClinicId,
                localStorageName: 'ClinicName',
                localStorageValue: record.ClinicName,
              })
            }
          >
            {record.ClinicId > 0 ? <span>({record.ClinicId})</span> : ''} {record.ClinicName}
          </Link>
          <br />
          <FormatPhoneNumber phoneNumber={record.ClinicPhone} />
          {record.ClinicPhone && <br />}
          {record.ClinicEmail}
        </Table.Cell>

        <Table.Cell>
          {record.IsOverdue ? (
            <div style={{ color: 'red' }}>{record.NextStatusStaffText}</div>
          ) : (
            record.NextStatusStaffText
          )}
        </Table.Cell>
        <Table.Cell>{record.NextPicName}</Table.Cell>
        <Table.Cell>{this.convertEmailCreatedAt(record.CreatedBy)}</Table.Cell>
      </Table.Row>
    ));
  };

  openCaseDetailCM = (id, type, record) => () => {
    const caseNo = `Case ${record.magRefNo || record.AssessmentNumber || record.MagRefNo}`;
    localStorage.setItem('AssessmentCaseId', caseNo);

    if (appStore.iframeMode) {
      this.openCaseDetailIframeMode(id, 'PostAssessment#' + type);
    } else {
      dashboardStore.close('/view/add-edit-ime-assessment-2');
      setTimeout(() => {
        dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
      });
    }
  };

  openViewSuppRequest = (id, record) => {
    return IMEStore.toggleModal(true, {
      modalType: 'viewSuppRequest',
      id,
      record,
    })();
  };

  renderTableBodyForCM = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }
    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">
          {this.isShowDetailTypist(record) ? this.renderTableActionCM(Id, record) : ``}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          {this.isShowDetailTypist(record) ? (
            <Link to="#" onClick={this.openCaseDetailCM(Id, `Appointment`, record)}>
              {record.magRefNo || record.AssessmentNumber || record.MagRefNo}
            </Link>
          ) : (
            record.magRefNo || record.AssessmentNumber || record.MagRefNo
          )}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.AssessmentNumber || record.magRefNo || record.AssessmentNumber || record.MagRefNo,
            })}
          >
            {record.StatusCMText}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.createDate || record.CreateDate || record.BookingDate
            ? moment(formatDate(record.createDate || record.CreateDate || record.BookingDate), 'DD MMM YYYY').format(
                'DD/MM/YYYY',
              )
            : 'N/A'}
        </Table.Cell>
        {this.renderVideoSessionDetailForCM(Id, record)}
        <Table.Cell>{record.claimNo || record.ClaimNo}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          {record.typeOfClaim || record.TypeOfClaimLabel || record.TypeOfClaim}/
          {record.typeOfReport || record.TypeOfReport}
        </Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
            {!api.isTypist() && <span>({record.claimantId || record.ClaimantId})</span>} {record.claimant || record.Claimant}
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          {record.client || record.Client}
        </Table.Cell>
        <Table.Cell className={cx(`${record.isDirect && `Cell-Client-with-mail`}`)}>
          ({record.CaseManagerId || record.caseManagerId}){' '}
          {record.caseManager || record.CaseManager || record.CaseManagerName}
          {(record.isDirect || record.IsDirect || record.isMagDirect) && <div>{record.cmEmail || record.CMEmail}</div>}
          {(record.isDirect || record.IsDirect || record.isMagDirect) && (
            <div>
              <FormatPhoneNumber phoneNumber={record.cmMobilePhone || record.CMMobilePhone} />
            </div>
          )}
        </Table.Cell>
        <Table.Cell className={cx(`${record.isDirect && `Cell-Client-with-mail`}`)}>
          {record.doctorName || record.DoctorName || record.doctor || record.Doctor}
          {(record.isDirect || record.IsDirect || record.isMagDirect) && (
            <div>{record.doctorBusinessEmail || record.DoctorBusinessEmail}</div>
          )}
          {(record.isDirect || record.IsDirect || record.isMagDirect) && (
            <div>
              <FormatPhoneNumber
                officePhone={record.DoctorOfficePhone || record.doctorOfficePhone}
                mobilePhoneNumber={record.doctorPhone || record.DoctorPhone}
              />
            </div>
          )}
        </Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          {record.clinicName || record.ClinicName}
          <div>{record.clinicEmail || record.ClinicEmail}</div>
          <div>
            <FormatPhoneNumber officePhone={record.clinicPhone || record.ClinicPhone} />
          </div>
        </Table.Cell>
        <Table.Cell>
          {record.statusDate || record.StatusDate
            ? moment(formatDate(record.statusDate || record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.createdByName || record.CreatedByName}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActionCM = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        !api.isTypist() && api.isCaseManager() ? (
          <Menu>
            <Menu.Item onClick={this.openCaseDetailCM(id, `CaseDetails`, record)}>Update Claim Details</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Appointment`, record)}>View Appointment Details</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Paperwork`, record)}>Upload Case Documents</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Attendance`, record)}>Cancel Appointment</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Completion`, record)}>View Completed Reports</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Corespondence`, record)}>View Correspondence</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Invoices`, record)}>View Invoice</Menu.Item>
            <Menu.Item style={{ pointerEvents: 'none' }}>
              <Divider style={{ margin: 0 }} />
            </Menu.Item>
            {record.IsExistSuppRequest && (
              <Menu.Item onClick={() => this.openViewSuppRequest(id, record)}>View Supp Requests</Menu.Item>
            )}
            <Menu.Item onClick={this.openCaseRequestSuppReport(id, record.MagRefNo)}>Request Supp Report</Menu.Item>
          </Menu>
        ) : (
          <Menu>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Completion`, record)}>Download Dictations</Menu.Item>
            <Menu.Item onClick={this.openCaseDetailCM(id, `Completion`, record)}>Upload Typed Reports</Menu.Item>
          </Menu>
        )
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(id, record.AssessmentNumber)}>Update Claim Details</Menu.Item>
          <Menu.Item onClick={this.openCaseUploadAppointment(id, record.AssessmentNumber)}>
            Update Appointment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUploadPaperwork(id, record.AssessmentNumber)}>Update Paperwork</Menu.Item>
          <Menu.Item onClick={this.openCaseUpdateAttAndCancel(id, record.AssessmentNumber)}>
            Update Attendance & Cancellation
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDictation(id, record.AssessmentNumber)}>
            Update Dictations & Reports
          </Menu.Item>
          <Menu.Item onClick={this.openCaseCorrespondence(id, record.AssessmentNumber, `ServicePrepaymentSection`)}>
            Update Provider Payment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseCorrespondence(id, record.AssessmentNumber, `Corespondence`)}>
            Update Correspondence
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item onClick={() => this.handlePrintAppCon(id)}>Print Appointment Confirmation (CMs)</Menu.Item>
          <Menu.Item onClick={() => this.handPrintDoctor(id)}>Print Appointment Confirmation (Specialists)</Menu.Item>
          {!api.isCaseManager() && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to booking CM?',
                onOk: this.handleSendStatusUpdate(id, 0),
              })}
            >
              Send Status Update (CMs) (MAIL-105)
            </Menu.Item>
          )}
          {!api.isCaseManager() && record.DoctorName && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to assessing specialist?',
                onOk: this.handleSendStatusUpdate(id, 1),
              })}
            >
              Send Status Update (Specialists) (MAIL-106)
            </Menu.Item>
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to remind specialist to update claimant atttendance?',
              onOk: this.handleAttendanceRemider(id),
            })}
          >
            Send Attendance Remider (MAIL-09)
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {!record.InvoiceId && record.DoctorId > 0 ? (
            <Menu.Item onClick={this.addNewInvoice(id, record.AssessmentNumber)}>
              <Icon name="plus" size="small" />
              Generate Invoice
            </Menu.Item>
          ) : (
            <div />
          )}
          {record.InvoiceId > 0 ? (
            <Menu.Item onClick={this.openCaseViewInvoice(record.InvoiceId, record.AssessmentNumber)}>
              View Invoice
            </Menu.Item>
          ) : (
            <div />
          )}
          {record.IsBilled ? <Menu.Item onClick={this.handleCopyVoice(id)}>Copy Invoice</Menu.Item> : <div />}
          {record.InvoiceId > 0 ? (
            <Menu.Item onClick={() => this.handPrintInvoice(record.InvoiceId)}>Print Invoice</Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {record.IsBilled ? (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to unlock this assessment?',
                onOk: this.handleUnClockForEdit(id),
              })}
            >
              Unlock for Edit
            </Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message:
                'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
              onOk: this.handleDuplicate(id),
            })}
          >
            Rebook this Case
          </Menu.Item>
          {/* {api.isAdmin() && <Menu.Item onClick={() => this.handleDelete(id, record)}>Delete this Case</Menu.Item>} */}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {record.IsExistSuppRequest && (
            <Menu.Item onClick={() => this.openViewSuppRequest(id, record)}>View Supp Requests</Menu.Item>
          )}
          <Menu.Item onClick={this.openCaseRequestSuppReport(id, record.AssessmentNumber)}>
            Request Supp Report
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  openSessionDetail = (id, record) => {
    if (api.isTypist()) {
      return;
      //MAG-bug 282 comment
      // return uploadStore.toggleModal(true, {
      //   modalType: 'openUpdateModel',
      //   id,
      //   caseNo: record.AssessmentNumber || record.magRefNo || record.MagRefNo,
      //   status: record.Status,
      //   claimant: record.Claimant,
      //   serviceType: 'Assessment',
      //   label: 'IME',
      //   service: 'UpdateAttendance',
      // })();
    }
    if (record.AssessmentMethod && record.AssessmentMethodType === 0) {
      localStorage.setItem('VideoSessionCaseId', `Video Session (${record.AssessmentNumber})`);
      dashboardStore.close('/view/video-session');
      setTimeout(() => {
        dashboardStore.open(`/view/video-session?assessmentId=${id}`);
      });
    } else {
      return uploadStore.toggleModal(true, {
        modalType: 'openUpdateModel',
        id,
        caseNo: record.AssessmentNumber || record.magRefNo || record.MagRefNo,
        status: record.Status,
        claimant: record.Claimant,
        serviceType: 'Assessment',
        label: 'IME',
        service: 'UpdateAttendance',
        record: record,
      })();
    }
  };

  renderClassAppointmentForCM = record => {
    if (record.Status === 'Cancelled-No Fee' || record.Status === 'Late Cancellation Fee') {
      return 'Cell-link link-view-action-imp';
    }
    return 'Cell-link';
  };

  renderVideoSessionDetailForCM = (Id, record) => {
    return (
      <React.Fragment>
        <Table.Cell
          selectable
          className={this.renderClassAppointmentForCM(record)}
          style={{ textAlign: 'center' }}
          onClick={() => this.openSessionDetail(Id, record)}
        >
          {record.HasMultiAppointment ? (
            this.renderMultiAppointments(record.Appointments)
          ) : (
            <Link to="#" onClick={() => this.openSessionDetail(Id, record)}>
              {record.appointmentDate || record.AppointmentDate || record.BookingDate
                ? moment(
                    formatDate(record.appointmentDate || record.AppointmentDate || record.BookingDate, true),
                    'DD MMM YYYY, HH:mm',
                  ).format('DD/MM/YYYY - HH:mm')
                : 'N/A'}
            </Link>
          )}
        </Table.Cell>
      </React.Fragment>
    );
  };

  renderMultiAppointments = appointments => {
    return (
      <React.Fragment>
        <div style={{ minWidth: '220px' }}>
          <span style={{ display: 'inline-block' }}>Multi-Option:</span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {appointments.map((m, idx) =>
              m.StartTime ? (
                <span key={idx} style={{ display: 'inline-block' }}>
                  {moment(m.StartTime).format('DD/MM/YYYY - HH:mm')}
                  {m.EndTime ? ' - ' + moment(m.EndTime).format('HH:mm') : ''}
                </span>
              ) : (
                ''
              ),
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderVideoSessionDetailForAdmin = (Id, record) => {
    var color = '#1fc0da';
    if (record.Status === api.imeStatus.CANCELLED || record.Status === api.imeStatus.IME_CANCELLED_WITH_FEE) {
      color = 'red';
    } else if (record.Status === api.imeStatus.CASE_CREATED || record.Status === api.imeStatus.IME_REQUESTED) {
      return (
        <React.Fragment>
          <Table.Cell selectable style={{ textAlign: 'center', color: 'black' }}>
            {record.HasMultiAppointment
              ? this.renderMultiAppointments(record.Appointments)
              : record.DateFrom
              ? moment(record.DateFrom).format('DD/MM/YYYY - HH:mm')
              : ''}
            <br />
            {record.AssessmentMethod ? <Icon name="video camera" /> : ''}{' '}
            {record.EassessClaimantLocationChecklistReceived ? <Icon name="check" /> : ''}
            {record.Interpreter && (
              <React.Fragment>
                {record.InterpretationStatus === 1 ? (
                  <span style={{ color: '#1fc0da' }}>
                    Interpreter: <Icon name="check" />
                  </span>
                ) : (
                  ''
                )}
                {record.InterpretationStatus === -1 ? <span style={{ color: 'red' }}>Interpreter: X</span> : ''}
              </React.Fragment>
            )}
          </Table.Cell>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Table.Cell
          selectable
          style={{ textAlign: 'center', color: color }}
          onClick={() => this.openSessionDetail(Id, record)}
        >
          <Link to="#" onClick={() => this.openSessionDetail(Id, record)}>
            {record.HasMultiAppointment
              ? `Multi-Option: ${record.Appointments.map(m =>
                  m.StartTime ? moment(m.StartTime).format('DD/MM/YYYY - HH:mm') : '',
                )} 
                                ${record.Appointments.length === 1 ? '' : ','}`
              : record.DateFrom
              ? moment(record.DateFrom).format('DD/MM/YYYY - HH:mm')
              : ''}
            <br />
            {record.AssessmentMethod ? <Icon name="video camera" /> : ''}{' '}
            {record.EassessClaimantLocationChecklistReceived ? <Icon name="check" /> : ''}
          </Link>
          {record.Interpreter && (
            <React.Fragment>
              {record.InterpretationStatus === 0 ? (
                <span style={{ color: '#1fc0da' }}>
                  Interpreter: <Icon name="check" />
                </span>
              ) : (
                ''
              )}
              {record.InterpretationStatus === -1 ? <span style={{ color: 'red' }}>Interpreter: X</span> : ''}
            </React.Fragment>
          )}
        </Table.Cell>
      </React.Fragment>
    );
  };

  handlePageClick = page => {
    IMEStore.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = IMEStore;
    const colSpan = api.isAdminOrMagStaffOrAccounting() ? columnsForAdmin.length : columnsForCM.length;
    return (
      <Table sortable celled striped className={cx({ disabled: loading })}>
        <TableHeader
          columns={api.isAdminOrMagStaffOrAccounting() ? columnsForAdmin : columnsForCM}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={IMEStore.handleSort}
        />
        <TableBody>
          {api.isAdminOrMagStaffOrAccounting()
            ? this.renderTableBodyForAdmin(data, colSpan)
            : this.renderTableBodyForCM(data, colSpan)}
        </TableBody>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={IMEStore.curPage}
          totalPage={IMEStore.totalPage}
          totalItems={IMEStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default IMETable;
