import React from 'react';
import { Form, Input, notification, Spin, Select, InputNumber, DatePicker, TimePicker, Tooltip } from 'antd';
import { Button, Icon, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import './ModalCreate.scss';
import Modal from '../../shared/Modal';
import ServiceEstimateStore from './Store';
import { formatDate } from '../../../utils/functions';
import DragDropFile from './../../shared/DragDropFile';
import { action, toJS, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { renderDate } from '../../../components/case-details-routes/Shared';
import * as api from '../../../stores/api';
import ModalConfirm from './ModalConfirm';
import {
  getServiceEstimateInfo,
  saveServiceEstimate,
  searchAMAFee,
  searchAMACaculation,
  searchIMEAsssessment,
  searchFileReview,
  searchSupplementary,
  getCompanyList,
  getGetAllCaculationRate,
  getFeeByDateOfService,
  getTimeUnit,
  searchAMAProfessionalAttendance,
  getEstimateLink,
  getListAMASpecific,
  getM4ModifierExclusionCode,
  getMZ900Calculations,
  searchMBSCode,
  getMBSCodeFeeByDateOfService,
  searchMBSProfessionalAttendance,
  searchAnaesthesiaAMAFee
} from './Service';

const optionsServiceType = [
  {
    text: `IME Assessments (A)`,
    value: `IME`,
  },
  {
    text: `File Reviews (F)`,
    value: `FR`,
  },
  {
    text: `Supplementary Reports (S)`,
    value: `SUPP`,
  },
];
const optionsGender = [
  {
    text: `Male`,
    value: 'Male',
  },
  {
    text: `Female`,
    value: 'Female',
  },
  {
    text: `Other`,
    value: 'Other',
  },
  {
    text: `Prefer not to say`,
    value: 'Prefer not to say',
  },
];

const optionServiceItemTypes = [
  {
    Name: `General`,
    Id: 1,
  },
  {
    Name: `Professional Attendances`,
    Id: 2,
  },
  {
    Name: `Anaesthesia`,
    Id: 3,
  },
  {
    Name: `Assistance at Operation`,
    Id: 4,
  },
];

const optionMZ900Rates = [
  {
    Name: `AMA`,
    Id: 1,
  },
  {
    Name: `SIRA`,
    Id: 2,
  },
];

const optionM4Eligible = [
  {
    Name: `Yes`,
    Id: 1,
  },
  {
    Name: `No`,
    Id: 2,
  },
];

@observer
class ModalCreate extends React.Component {
  @observable openConfirm = false;
  @observable confirmParams = {};

  @action handleCancelConfirm = () => {
    this.openConfirm = false;
  };

  @action handleOpenConfirm = (isOpen, params) => {
    this.openConfirm = isOpen;
    this.confirmParams = params;
  };

  state = {
    loading: true,
    data: null,
  };

  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      let arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      let i = +arr[0] + 'e' + sig + (+arr[1] + scale);
      let j = Math.round(i);
      let k = +(j + 'e-' + scale);
      return k;
    }
  }

  renderServiceTypeLabel = () => {
    return optionsServiceType.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderServiceTypeLabelText = value => {
    return optionsServiceType.filter(i => i.value === value).map(i => i.text)[0];
  };

  renderGenderLabel = () => {
    return optionsGender.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderGenderLabelText = value => {
    return optionsGender.filter(i => i.value === value).map(i => i.text)[0];
  };

  parseTimeRangeItem = time => {
    if (time < 10) {
      return { label: '0' + time, value: time };
    } else {
      return { label: time, value: time };
    }
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return moment(result.toISOString());
      }
    }
  };

  componentDidMount() {
    const { id, record } = ServiceEstimateStore.modalParams;
    const { amaSelectionList, mbsSelectionList, amaCaculationList } = ServiceEstimateStore;
    if (id) {
      Promise.all([
        getServiceEstimateInfo(id),
        searchAMAFee(''),
        searchMBSCode(''),
        getEstimateLink('', record.ClientId),
        getListAMASpecific(),
        getM4ModifierExclusionCode(),
        getMZ900Calculations(),
        searchAnaesthesiaAMAFee(''),
      ]).then(
        ([
          itemInfo,
          amaResp,
          mbsResp,
          estimateLinkResp,
          amaSpecificResp,
          m4ModifierExclusionCodeResp,
          respMZ900Calculations,
          anaesthesiaAmaResp
        ]) => {
          //console.log(estimateLinkResp.itemList);
          if (respMZ900Calculations && respMZ900Calculations.itemList) {
            ServiceEstimateStore.setListItem('lstMZ900Calculations', respMZ900Calculations.itemList);
          }
          if (amaResp && amaResp.itemList) {
            ServiceEstimateStore.setListItem('amaSelectionList', amaResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('amaSelectionList', []);
          }
          if (anaesthesiaAmaResp && anaesthesiaAmaResp.itemList) {
            ServiceEstimateStore.setAnaesthesiaAmaSelectionList(anaesthesiaAmaResp.itemList);
          } else {
            ServiceEstimateStore.setAnaesthesiaAmaSelectionList([]);
          }
          if (mbsResp && mbsResp.itemList) {
            ServiceEstimateStore.setListItem('mbsSelectionList', mbsResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('mbsSelectionList', []);
          }
          if (estimateLinkResp && estimateLinkResp.itemList) {
            ServiceEstimateStore.setListItem('SearchEstimate', estimateLinkResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('SearchEstimate', []);
          }
          if (amaSpecificResp && amaSpecificResp.itemList) {
            ServiceEstimateStore.setListItem('AMASpecific', amaSpecificResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('AMASpecific', []);
          }
          if (m4ModifierExclusionCodeResp && m4ModifierExclusionCodeResp.itemList) {
            ServiceEstimateStore.setListItem('M4ModifierExclusionCode', m4ModifierExclusionCodeResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('M4ModifierExclusionCode', []);
          }

          if (itemInfo.objItem && !!itemInfo.objItem.InjuryBodyParts) {
            itemInfo.objItem.InjuryBodyParts = JSON.parse(itemInfo.objItem.InjuryBodyParts);
          } else {
            itemInfo.objItem.InjuryBodyParts = [];
          }
          if (itemInfo.objItem && itemInfo.objItem.ServiceItemType) {
            itemInfo.objItem.ServiceItemType = JSON.parse(itemInfo.objItem.ServiceItemType);
          } else {
            itemInfo.objItem.ServiceItemType = [];
          }
          if (itemInfo.objItem && itemInfo.objItem.ManualSpecialistSpecialty) {
            itemInfo.objItem.ManualSpecialistSpecialty = JSON.parse(itemInfo.objItem.ManualSpecialistSpecialty);
          } else {
            itemInfo.objItem.ManualSpecialistSpecialty = [];
          }
          this.setState({
            loading: false,
            data: itemInfo.objItem,
          });
          if (itemInfo.objItem.ManualClaimantDOB != null && itemInfo.objItem.ManualDateOfInjury != null) {
            const { data } = this.state;
            this.setState({
              data: {
                ...data,
                ManualClaimantDOB: this.momentToJS(itemInfo.objItem.ManualClaimantDOB),
                ManualDateOfInjury: this.momentToJS(itemInfo.objItem.ManualDateOfInjury),
              },
            });
          }
          if (itemInfo.objItem.DefaultDateOfService != null) {
            const { data } = this.state;
            this.setState({
              data: {
                ...data,
                DefaultDateOfService: this.momentToJS(itemInfo.objItem.DefaultDateOfService),
              },
            });
          }
          if (itemInfo.objItem.DateOfInvoice != null) {
            const { data } = this.state;
            this.setState({
              data: {
                ...data,
                DateOfInvoice: this.momentToJS(itemInfo.objItem.DateOfInvoice),
              },
            });
          }
          if (itemInfo.objItem.StartDate != null) {
            const { data } = this.state;
            this.setState({
              data: {
                ...data,
                StartDate: this.momentToJS(itemInfo.objItem.StartDate),
                DateFromHour: this.parseTimeRangeItem(this.momentToJS(itemInfo.objItem.StartDate).hour()),
                DateFromMinute: this.parseTimeRangeItem(this.momentToJS(itemInfo.objItem.StartDate).minutes()),
              },
            });
          }
          if (itemInfo.objItem.EndDate != null) {
            const { data } = this.state;
            this.setState({
              data: {
                ...data,
                EndDate: this.momentToJS(itemInfo.objItem.EndDate),
                DateToHour: this.parseTimeRangeItem(this.momentToJS(itemInfo.objItem.EndDate).hour()),
                DateToMinute: this.parseTimeRangeItem(this.momentToJS(itemInfo.objItem.EndDate).minutes()),
              },
            });
          }

          if (itemInfo.objItem.ServiceEstimateAMAItems && itemInfo.objItem.ServiceEstimateAMAItems.length > 0) {
            var newArr = [];
            var countIdx = 1;
            itemInfo.objItem.ServiceEstimateAMAItems.forEach(item => {
              //push object to newArr
              newArr.push({
                idx: countIdx,
                amaItem: item,
                amaCodeOptions: itemInfo.InvoieCheckType === 0 ? amaSelectionList : mbsSelectionList,
                amaCaculationOptions: amaCaculationList,
                arrPerOptions: item.ListRates ? item.ListRates : [],
                DateOfService: item.DateOfService ? this.momentToJS(item.DateOfService) : null,
              });
              countIdx++;
            });
            ServiceEstimateStore.setServiceItems(newArr);
          } else {
            ServiceEstimateStore.setServiceItems([]);
          }

          if (itemInfo.objItem.AftercareItems && itemInfo.objItem.AftercareItems.length > 0) {
            var listItems = [];
            var countIdx = 1;
            itemInfo.objItem.AftercareItems.forEach(el => {
              listItems.push({
                Id: el.Id,
                idx: countIdx,
                EstimateId: el.EstimateId,
                AMACodeId: el.AMACodeId,
                AMAFee: el.AMAFee,
                AftercareRuleId: el.AftercareRuleId,
                AftercareRule: el.AftercareRule,
                DateOfService: el.DateOfService ? this.momentToJS(el.DateOfService) : null,
                AftercareToDate: el.AftercareToDate ? this.momentToJS(el.AftercareToDate) : null,
                ScheduledFee: el.ScheduledFee,
                AdjustedFee: el.AdjustedFee,
                InvoicedFee: el.InvoicedFee,
                Approved: el.Approved,
              });
              countIdx = countIdx + 1;
            });
            ServiceEstimateStore.setListItem('aftercare', listItems);
          } else {
            ServiceEstimateStore.setListItem('aftercare', []);
          }

          if (itemInfo.objItem.AnaesthesiaItems && itemInfo.objItem.AnaesthesiaItems.length > 0) {
            var listItems = [];
            var lstM4Items = [];
            var lstTimeItems = [];
            var countIdx = 1;
            itemInfo.objItem.AnaesthesiaItems.forEach(el => {
              if (
                el.AMAFee &&
                el.AMAFee.AMACode &&
                (el.AMAFee.AMACode.toLowerCase() === 'm4' || el.AMAFee.AMACode.toLowerCase() === 'time')
              ) {
              } else {
                listItems.push({
                  Id: el.Id,
                  idx: countIdx,
                  EstimateId: el.EstimateId,
                  AMACodeId: el.AMACodeId,
                  AMAFee: el.AMAFee,
                  DateOfService: el.DateOfService ? this.momentToJS(el.DateOfService) : null,
                  Duration: el.Duration,
                  TimeUnit: el.TimeUnit,
                  BaseUnit: el.BaseUnit,
                  ModifyingUnitId: el.ModifyingUnitId,
                  ModifyingUnits: el.ModifyingUnits,
                  UnitRate: el.UnitRate,
                  ScheduledFee: el.ScheduledFee,
                  InvoicedFee: el.InvoicedFee,
                  M4Eligible: el.M4Eligible,
                  Approved: el.Approved,
                });
                countIdx = countIdx + 1;
              }
            });
            if (!itemInfo.objItem.AnaesthesiaItems || itemInfo.objItem.AnaesthesiaItems.length > 0) {
              var foundM4Obj = itemInfo.objItem.AnaesthesiaItems.find(i => i.AMAFee?.AMACode?.toLowerCase() === 'm4');
              if (foundM4Obj) {
                lstM4Items = [
                  {
                    Id: foundM4Obj.Id,
                    idx: 99,
                    EstimateId: foundM4Obj.EstimateId,
                    AMACodeId: foundM4Obj.AMACodeId,
                    AMAFee: foundM4Obj.AMAFee,
                    DateOfService: foundM4Obj.DateOfService ? this.momentToJS(foundM4Obj.DateOfService) : null,
                    Duration: foundM4Obj.Duration,
                    TimeUnit: foundM4Obj.TimeUnit,
                    BaseUnit: foundM4Obj.BaseUnit,
                    ModifyingUnitId: foundM4Obj.ModifyingUnitId,
                    ModifyingUnits: foundM4Obj.ModifyingUnits,
                    UnitRate: foundM4Obj.UnitRate,
                    ScheduledFee: foundM4Obj.ScheduledFee,
                    InvoicedFee: foundM4Obj.InvoicedFee,
                    M4Eligible: foundM4Obj.M4Eligible,
                    Approved: foundM4Obj.Approved,
                  },
                ];
              } else {
                const { data } = this.state;
                var m4Obj = null;
                if (amaSpecificResp && amaSpecificResp.itemList) {
                  m4Obj = amaSpecificResp.itemList.find(i => i.AMACode.toLowerCase() === 'm4');
                }
                lstM4Items = [
                  {
                    Id: 0,
                    idx: 99,
                    EstimateId: data.Id,
                    AMACodeId: m4Obj ? m4Obj.Id : 0,
                    AMAFee: m4Obj,
                    DateOfService: moment(),
                    Duration: 0,
                    TimeUnit: 0,
                    BaseUnit: 0,
                    ModifyingUnitId: null,
                    ModifyingUnits: null,
                    UnitRate: 89,
                    ScheduledFee: 0,
                    InvoicedFee: 0,
                    M4Eligible: 1,
                    Approved: false,
                  },
                ];
              }
              var foundTimeObj = itemInfo.objItem.AnaesthesiaItems.find(
                i => i.AMAFee && i.AMAFee.AMACode?.toLowerCase() === 'time',
              );
              if (foundTimeObj) {
                lstTimeItems = [
                  {
                    Id: foundTimeObj.Id,
                    idx: 98,
                    EstimateId: foundTimeObj.EstimateId,
                    AMACodeId: foundTimeObj.AMACodeId,
                    AMAFee: foundTimeObj.AMAFee,
                    DateOfService: foundTimeObj.DateOfService ? this.momentToJS(foundTimeObj.DateOfService) : null,
                    Duration: foundTimeObj.Duration,
                    TimeUnit: foundTimeObj.TimeUnit,
                    BaseUnit: foundTimeObj.BaseUnit,
                    ModifyingUnitId: foundTimeObj.ModifyingUnitId,
                    ModifyingUnits: foundTimeObj.ModifyingUnits,
                    UnitRate: foundTimeObj.UnitRate,
                    ScheduledFee: foundTimeObj.ScheduledFee,
                    InvoicedFee: foundTimeObj.InvoicedFee,
                    M4Eligible: foundTimeObj.M4Eligible,
                    Approved: foundTimeObj.Approved,
                  },
                ];
              } else {
                const { data } = this.state;
                var m4Obj = null;
                if (amaSpecificResp && amaSpecificResp.itemList) {
                  m4Obj = amaSpecificResp.itemList.find(i => i.AMACode.toLowerCase() === 'time');
                }
                lstTimeItems = [
                  {
                    Id: 0,
                    idx: 98,
                    EstimateId: data.Id,
                    AMACodeId: m4Obj ? m4Obj.Id : 0,
                    AMAFee: m4Obj,
                    DateOfService: moment(),
                    Duration: 0,
                    TimeUnit: 0,
                    BaseUnit: 0,
                    ModifyingUnitId: null,
                    ModifyingUnits: null,
                    UnitRate: 89,
                    ScheduledFee: 0,
                    InvoicedFee: 0,
                    M4Eligible: 1,
                    Approved: true,
                  },
                ];
              }
            }
            ServiceEstimateStore.setListItem('anaesthesia', listItems);
            ServiceEstimateStore.setListItem('M4', lstM4Items);
            ServiceEstimateStore.setListItem('Time', lstTimeItems);
          } else {
            ServiceEstimateStore.setListItem('anaesthesia', []);
          }
          if (itemInfo.objItem.ProfessionalAttendanceItems && itemInfo.objItem.ProfessionalAttendanceItems.length > 0) {
            var listItems = [];
            var countIdx = 1;
            itemInfo.objItem.ProfessionalAttendanceItems.forEach(el => {
              listItems.push({
                Id: el.Id,
                idx: countIdx,
                EstimateId: el.EstimateId,
                AMACodeId: el.AMACodeId,
                AMAFee: el.AMAFee,
                DateOfService: el.DateOfService ? this.momentToJS(el.DateOfService) : null,
                AdjustedFee: el.AdjustedFee,
                InvoicedFee: el.InvoicedFee,
                Approved: el.Approved,
              });
              countIdx = countIdx + 1;
            });
            ServiceEstimateStore.setListItem('professionalAttendance', listItems);
          } else {
            ServiceEstimateStore.setListItem('professionalAttendance', []);
          }
          if (itemInfo.objItem.AssistanceAtOperationItems && itemInfo.objItem.AssistanceAtOperationItems.length > 0) {
            var listAssistanceAtOperationItems = [];
            var countIdx = 1;
            itemInfo.objItem.AssistanceAtOperationItems.forEach(async(el) => {
              const rateResp = await getGetAllCaculationRate(el.CalculationId);
              listAssistanceAtOperationItems.push({
                Id: el.Id,
                idx: countIdx,
                EstimateId: el.EstimateId,
                AMACodeId: el.AMACodeId,
                AMAFee: el.AMAFee,
                AdjustedFee: el.AdjustedFee,
                AssistanceRequired: el.AssistanceRequired,
                DateOfService: el.DateOfService ? this.momentToJS(el.DateOfService) : null,
                ScheduledFee: el.ScheduledFee,
                IsMBSAssistAllowed: el.IsMBSAssistAllowed,
                CalculationId: el.CalculationId,
                CalculationName: el.CalculationName,
                arrPerOptions: rateResp.lstRet,
                IsManualRate: el.IsManualRate,
                RateValue: el.RateValue,
              });
              countIdx = countIdx + 1;
              ServiceEstimateStore.setListItem('assistanceAtOperation', listAssistanceAtOperationItems.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
            });
          } else {
            ServiceEstimateStore.setListItem('assistanceAtOperation', []);
          }
          if (
            itemInfo.objItem.AssistanceAtOperationMZ900Items &&
            itemInfo.objItem.AssistanceAtOperationMZ900Items.length > 0
          ) {
            var listAssistanceAtOperationMZ900Items = [];
            itemInfo.objItem.AssistanceAtOperationMZ900Items.forEach(el => {
              listAssistanceAtOperationMZ900Items.push({
                Id: el.Id,
                EstimateId: el.EstimateId,
                AMACodeId: el.AMACodeId,
                AMAFee: el.AMAFee,
                RateType: el.RateType,
                AdjustedFee: el.AdjustedFee,
                InvoicedFee: el.InvoicedFee,
                ExpectedFee: el.ExpectedFee,
              });
            });
            ServiceEstimateStore.setListItem('showMZ900', true);
            ServiceEstimateStore.setListItem('mz900', listAssistanceAtOperationMZ900Items);
          } else {
            ServiceEstimateStore.setListItem('mz900', []);
          }
          this.calculationFeeAndAjustedFee();
        },
      );
    } else {
      Promise.all([searchAMAFee(''), searchAnaesthesiaAMAFee(''), getListAMASpecific(), getM4ModifierExclusionCode(), getMZ900Calculations()]).then(
        ([amaResp, anaesthesiaAmaResp, amaSpecificResp, m4ModifierExclusionCodeResp, respMZ900Calculations]) => {
          if (respMZ900Calculations && respMZ900Calculations.itemList) {
            ServiceEstimateStore.setListItem('lstMZ900Calculations', respMZ900Calculations.itemList);
          }
          if (amaResp && amaResp.itemList) {
            ServiceEstimateStore.setListItem('amaSelectionList', amaResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('amaSelectionList', []);
          }
          if (anaesthesiaAmaResp && anaesthesiaAmaResp.itemList) {
            ServiceEstimateStore.setAnaesthesiaAmaSelectionList(anaesthesiaAmaResp.itemList);
          } else {
            ServiceEstimateStore.setAnaesthesiaAmaSelectionList([]);
          }
          if (amaSpecificResp && amaSpecificResp.itemList) {
            ServiceEstimateStore.setListItem('AMASpecific', amaSpecificResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('AMASpecific', []);
          }
          if (m4ModifierExclusionCodeResp && m4ModifierExclusionCodeResp.itemList) {
            ServiceEstimateStore.setListItem('M4ModifierExclusionCode', m4ModifierExclusionCodeResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('M4ModifierExclusionCode', []);
          }
        },
      );

      ServiceEstimateStore.setServiceItems([]);
      ServiceEstimateStore.setListItem('aftercare', []);
      ServiceEstimateStore.setListItem('anaesthesia', []);
      ServiceEstimateStore.setListItem('mz900', []);
      ServiceEstimateStore.setListItem('M4', []);
      ServiceEstimateStore.setListItem('Time', []);
      if (api.isAdminOrMagStaffOrAccounting()) {
        this.setState({
          loading: false,
          data: {
            Id: 0,
            EstimateNo: '',
            ServiceType: 'IME',
            StartDate: moment(),
            DefaultDateOfService: moment(),
          },
        });
      } else if (api.isCaseManager()) {
        ServiceEstimateStore.getCMByClientId(api.currentUser.data.ClientId);
        this.setState({
          loading: false,
          data: {
            Id: 0,
            EstimateNo: '',
            ServiceType: 'IME',
            ClientId: api.currentUser.data.ClientId,
            CaseManagerId: api.currentUser.data.CaseManagerId,
            StartDate: moment(),
            IsLinkToEst: true,
          },
        });
      }
    }
  }

  handlConfirmBeforeSave = isPrint => {
    const { data } = this.state;
    const {
      lstServiceItems,
      lstProfessionalAttendanceItems,
      lstAnaesthesiaItems,
      lstTimeUnits,
      lstMZ900Items,
    } = ServiceEstimateStore;
    var checkRequiredDOF = false;
    lstServiceItems.forEach(el => {
      if (!checkRequiredDOF && !el.DateOfService) checkRequiredDOF = true;
    });
    lstProfessionalAttendanceItems.forEach(el => {
      if (!checkRequiredDOF && !el.DateOfService) checkRequiredDOF = true;
    });
    lstAnaesthesiaItems.forEach(el => {
      if (!checkRequiredDOF && !el.DateOfService) checkRequiredDOF = true;
    });

    var maxValue = Math.max.apply(
      null,
      lstTimeUnits.map(function(i) {
        return i.EndTime;
      }),
    );
    var checkMaxValue = false;
    if (lstAnaesthesiaItems && lstAnaesthesiaItems.length > 0) {
      lstAnaesthesiaItems.forEach(el => {
        if (el.Duration > maxValue) {
          checkMaxValue = true;
        }
      });
    }
    var checkRequiredRateMz900 = false;
    if (lstMZ900Items && lstMZ900Items.length > 0) {
      if (lstMZ900Items[0].Rate) checkRequiredRateMz900 = true;
    }
    //console.log('data', data);
    if (
      !data.ServiceType ||
      // !data.ClientId ||
      // !data.CaseManagerId ||
      // !data.ManualClaimantGender ||
      // (!data.IsManual && !data.ManualDateOfInjury) ||
      // !data.DateOfInvoice ||
      // (!data.IsManual && !data.ManualClaimantDOB) ||
      // !data.TypeOfWork ||
      // !data.InjuryBodyParts ||
      // data.InjuryBodyParts?.length === 0 ||
      checkRequiredDOF ||
      checkMaxValue ||
      checkRequiredRateMz900
    ) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'All required fields must be updated',
        duration: 3,
      });
    } else {
      var submitData = data;
      var isSaved = false;
      this.handleOpenConfirm(true, {
        modalType: 'confirm',
        message: `Do you want to update last save to current time? This can affect duration of the estimate.`,
        onOk: () => {
          isSaved = true;
          submitData.EndDate = moment();
          this.handleSave(submitData, isPrint);
        },
        onCancel: () => {
          this.handleCancelConfirm();
          if (!isSaved) {
            this.handleSave(submitData, isPrint);
          }
        },
      });
    }
  };

  handleSave = (submitData, isPrint) => {
    const {
      lstServiceItems,
      lstAftercareItems,
      lstAnaesthesiaItems,
      lstTimeUnits,
      lstM4Items,
      lstTimeItems,
      lstProfessionalAttendanceItems,
      lstAssistanceAtOperationItems,
      lstMZ900Items,
    } = ServiceEstimateStore;
    var newArr = [];
    lstServiceItems.forEach(item => {
      if (item.amaItem && item.amaItem.AMAFee) {
        newArr.push({
          Idx: item.idx,
          EstimateId: item.amaItem.EstimateId,
          AMAFeeId: item.amaItem.AMAFee.Id,
          CalculationId: item.amaItem.CalculationId,
          PercentageApplied: item.amaItem.PercentageApplied,
          AdjustedFee: item.amaItem.AdjustedFee,
          DateOfService: item.DateOfService,
          InvoicedFee: item.amaItem.InvoicedFee,
          IsManualRate: item.amaItem.IsManualRate,
          ManualRateValue: item.amaItem.ManualRateValue,
          Approved: item.amaItem.Approved,
        });
      }
    });
    if (submitData.ServiceItemType && submitData.ServiceItemType.includes(1)) {
      submitData.ServiceEstimateAMAItems = newArr;
    } else {
      submitData.ServiceEstimateAMAItems = null;
    }
    submitData.InjuryBodyParts = JSON.stringify(submitData.InjuryBodyParts);
    submitData.ManualSpecialistSpecialty = JSON.stringify(submitData.ManualSpecialistSpecialty);
    submitData.ServiceItemType = JSON.stringify(submitData.ServiceItemType);
    if (submitData.ServiceItemType && submitData.ServiceItemType.includes(2)) {
      submitData.ProfessionalAttendanceItems = lstProfessionalAttendanceItems;
      submitData.AftercareItems = lstAftercareItems;
    } else {
      submitData.ProfessionalAttendanceItems = null;
      submitData.AftercareItems = null;
    }
    if (submitData.ServiceItemType && submitData.ServiceItemType.includes(3)) {
      var newArr = [...lstAnaesthesiaItems];
      if (lstM4Items && lstM4Items.length > 0) {
        newArr.push(lstM4Items[0]);
      }
      if (lstTimeItems && lstTimeItems.length > 0) {
        newArr.push(lstTimeItems[0]);
      }
      submitData.AnaesthesiaItems = newArr;
    } else {
      submitData.AnaesthesiaItems = null;
    }
    if (submitData.ServiceItemType && submitData.ServiceItemType.includes(4)) {
      submitData.AssistanceAtOperationItems = lstAssistanceAtOperationItems;
      submitData.AssistanceAtOperationMZ900Items = lstMZ900Items;
    } else {
      submitData.AssistanceAtOperationItems = [];
      submitData.AssistanceAtOperationMZ900Items = [];
    }
    this.setState({ loading: true });
    Promise.all([saveServiceEstimate(submitData)])
      .then(([response]) => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Data has been saved successfully.',
            duration: 3,
          });
          ServiceEstimateStore.refetch({}, true);
          if (isPrint) {
            window.open('/ServiceEstimate/PrintEstimate?id=' + response.model.Id, '_blank');
          }
          // this.props.onCancel();
        } else {
          notification.destroy();
          notification.error({
            message: 'Error',
            description: 'Invalid form',
            duration: 3,
          });
        }
        submitData.ManualSpecialistSpecialty = ServiceEstimateStore.checkIsJSON(submitData.ManualSpecialistSpecialty)
          ? JSON.parse(submitData.ManualSpecialistSpecialty)
          : submitData.ManualSpecialistSpecialty;
        submitData.InjuryBodyParts = ServiceEstimateStore.checkIsJSON(submitData.InjuryBodyParts)
          ? JSON.parse(submitData.InjuryBodyParts)
          : submitData.InjuryBodyParts;
        submitData.ServiceItemType = ServiceEstimateStore.checkIsJSON(submitData.ServiceItemType)
          ? JSON.parse(submitData.ServiceItemType)
          : submitData.ServiceItemType;
        const { data } = this.state;
        this.setState({
          loading: false,
          data: {
            ...data,
            Id: response.model.Id,
            EstimateNo: response.model.EstimateNo,
            EndDate: this.momentToJS(response.model.EndDate),
            DateToHour: this.parseTimeRangeItem(this.momentToJS(response.model.EndDate).hour()),
            DateToMinute: this.parseTimeRangeItem(this.momentToJS(response.model.EndDate).minutes()),
          },
        });
        //this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 3,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handlePrint = id => {
    window.open('/ServiceEstimate/PrintEstimate?id=' + id, '_blank');
  };

  @action handleChangeServiceItem = () =>
    action(event => {
      const value = event;
      const { lstServiceItemOptions } = ServiceEstimateStore;
      const { data } = this.state;
      var obj = lstServiceItemOptions.find(i => i.CaseId === value);
      if (obj) {
        this.setState({
          data: {
            ...data,
            CaseId: obj.CaseId,
            CaseNo: obj.CaseNo,
            ClaimNumber: obj.ClaimNumber,
            ClaimantDOB: obj.ClaimantDOB,
            AssessingSpecialist: obj.AssessingSpecialist,
            SpecialistSpecialty: obj.SpecialistSpecialty,
          },
        });
      }
    });

  @action handleSearchEstimate = () =>
    action(event => {
      const value = event;
      const { data } = this.state;
      //console.log(value);
      getEstimateLink(value, data.ClientId).then(
        action(searchResp => {
          if (searchResp.status === 'success' && searchResp.itemList && searchResp.itemList.length > 0) {
            ServiceEstimateStore.setListItem('SearchEstimate', searchResp.itemList);
          }
        }),
      );
    });

  numberWithCommas = x => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  };

  handleFieldChange = fieldName => event => {
    const { data } = this.state;
    const { lstEstimateLink } = ServiceEstimateStore;
    const value = event ? (event.target ? event.target.value : event) : null;
    if (fieldName === 'TotalQuoteFee') {
      return this.setState({
        data: {
          ...data,
          [fieldName]: value,
          Difference: ((value || 0) - (data.AdjustedFee || 0)).toFixed(2),
        },
      });
    }
    //OnlyAaO
    if (fieldName === 'OnlyAaOTotalQuoteFee') {
      return this.setState({
        data: {
          ...data,
          [fieldName]: value,
          OnlyAaODifference: ((value || 0) - (data.OnlyAaOAdjustedFee || 0)).toFixed(2),
        },
      });
    }
    if (fieldName === 'IsManual') {
      return this.setState({
        data: {
          ...data,
          [fieldName]: value === 'true' ? true : false,
        },
      });
    }
    if (fieldName === 'InjuryBodyParts') {
      if (value.includes(0)) {
        ServiceEstimateStore.setShowOtherBodyPart(true);
      } else {
        ServiceEstimateStore.setShowOtherBodyPart(false);
      }
      //console.log(value);
    }
    if(fieldName == 'State'){
      if(value === "QLD" || value === "VIC" || value === "WA") {
        return this.setState({
          data: {
            ...data,
            InvoieCheckType: 1,
            [fieldName]: value,
          },
        });
      }else{
        return this.setState({
          data: {
            ...data,
            InvoieCheckType: 0,
            [fieldName]: value,
          },
        });
      }
    }
    if (fieldName === 'ClientId') {
      ServiceEstimateStore.getCMByClientId(value);
      //Search Estimate
      getEstimateLink('', value).then(
        action(searchResp => {
          if (searchResp.status === 'success' && searchResp.itemList && searchResp.itemList.length > 0) {
            ServiceEstimateStore.setListItem('SearchEstimate', searchResp.itemList);
          } else {
            ServiceEstimateStore.setListItem('SearchEstimate', []);
          }
        }),
      );
      return this.setState({
        data: {
          ...data,
          CaseManagerId: undefined,
          [fieldName]: value,
        },
      });
    }
    if (fieldName === 'LinkToEstimateId') {
      var linkObj = lstEstimateLink.find(i => i.Id === value);
      if (linkObj) {
        //console.log(linkObj);
        this.handleOpenConfirm(true, {
          modalType: 'confirm',
          message: `Do you want to copy information from the linked quote/invoice to this estimate?`,
          onOk: () => {
            return this.setState({
              data: {
                ...data,
                CaseManagerId: linkObj.CaseManagerId,
                CheckTypeId: linkObj.CheckTypeId,
                InvoiceNo: linkObj.InvoiceNo,
                DateOfInvoice: linkObj.DateOfInvoice ? this.momentToJS(linkObj.DateOfInvoice) : null,
                ManualAssessingSpecialist: linkObj.ManualAssessingSpecialist,
                ManualClaimNumber: linkObj.ManualClaimNumber,
                ManualClaimantDOB: linkObj.ManualClaimantDOB ? this.momentToJS(linkObj.ManualClaimantDOB) : null,
                ManualClaimantGender: linkObj.ManualClaimantGender ? linkObj.ManualClaimantGender : null,
                ManualDateOfInjury: linkObj.ManualDateOfInjury ? this.momentToJS(linkObj.ManualDateOfInjury) : null,
                //StartDate: linkObj.StartDate ? this.momentToJS(linkObj.StartDate) : null,
                //EndDate: linkObj.EndDate ? this.momentToJS(linkObj.EndDate) : null,
                ManualSpecialistSpecialty:
                  linkObj.ManualSpecialistSpecialty &&
                  ServiceEstimateStore.checkIsJSON(linkObj.ManualSpecialistSpecialty)
                    ? JSON.parse(linkObj.ManualSpecialistSpecialty)
                    : [],
                TypeOfWork: linkObj.TypeOfWork,
                InjuryBodyParts:
                  linkObj.InjuryBodyParts && ServiceEstimateStore.checkIsJSON(linkObj.InjuryBodyParts)
                    ? JSON.parse(linkObj.InjuryBodyParts)
                    : [],
                OtherBodyPart: linkObj.OtherBodyPart,
                [fieldName]: value,
              },
            });
          },
          onCancel: () => {
            this.handleCancelConfirm();
          },
        });
      }
    }
    this.setState({
      data: {
        ...data,
        [fieldName]: value,
      },
    });
  };

  onChecked = fieldName => {
    const { data } = this.state;
    if (fieldName === 'IsManual') {
      this.handleFieldChangeValue('IsManual', !data.IsManual);
    }
    if (fieldName === 'IsLinkToEst') {
      this.handleFieldChangeValue('IsLinkToEst', !data.IsLinkToEst);
    }
  };

  handleFieldChangeValue = (fieldName, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [fieldName]: value,
      },
    });
  };

  convertDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm');
        }
        return moment(dateStr, 'DD/MM/YYYY');
      }
      return dateStr;
    }
    return null;
  };

  @action handleChecked = fieldName =>
    action(event => {
      const { data } = this.state;
      const hourList = ServiceEstimateStore.generateTimeRange(24);
      const minuteList = ServiceEstimateStore.generateTimeRange(60);
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'StartDate') {
        if (value) {
          this.handleFieldChangeValue('StartDate', value);
        } else {
          this.handleFieldChangeValue('StartDate', null);
        }
      }
      if (fieldName === 'EndDate') {
        if (value) {
          this.handleFieldChangeValue('EndDate', value);
        } else {
          this.handleFieldChangeValue('EndDate', null);
        }
      }
      if (fieldName === 'DateFromHour') {
        if (value) {
          const dateFrom = event;
          this.setState({
            data: {
              ...data,
              StartDate: value,
              DateFromHour: hourList[dateFrom.hour()],
              DateFromMinute: minuteList[dateFrom.minutes()],
            },
          });
        } else {
          this.handleFieldChangeValue('StartDate', null);
        }
      }
      if (fieldName === 'DateToHour') {
        const dateTo = event;
        if (value) {
          this.setState({
            data: {
              ...data,
              EndDate: value,
              DateToHour: hourList[dateTo.hour()],
              DateToMinute: minuteList[dateTo.minutes()],
            },
          });
        } else {
          this.handleFieldChangeValue('EndDate', null);
        }
      }
      if (fieldName === 'ManualDateOfInjury') {
        if (value) {
          this.handleFieldChangeValue('ManualDateOfInjury', value);
        } else {
          this.handleFieldChangeValue('ManualDateOfInjury', null);
        }
      }
      if (fieldName === 'DateOfService') {
        if (value) {
          this.handleFieldChangeValue('DateOfService', value);
        } else {
          this.handleFieldChangeValue('DateOfService', null);
        }
      }
      if (fieldName === 'DateOfInvoice') {
        if (value) {
          this.handleFieldChangeValue('DateOfInvoice', value);
        } else {
          this.handleFieldChangeValue('DateOfInvoice', null);
        }
      }
      if (fieldName === 'ManualClaimantDOB') {
        if (value) {
          this.handleFieldChangeValue('ManualClaimantDOB', value);
        } else {
          this.handleFieldChangeValue('ManualClaimantDOB', null);
        }
      }
      if(fieldName === 'DefaultDateOfService'){
        if (value) {
          this.handleFieldChangeValue('DefaultDateOfService', value);
        } else {
          this.handleFieldChangeValue('DefaultDateOfService', null);
        }
      }
    });

  dragdropUploadFile = (acceptedFiles, reportType) => {
    var uploadFile = [];
    if (acceptedFiles != null) {
      acceptedFiles.forEach(function(element) {
        element.reportType = reportType;
        element.filename = element.name;
      });
      uploadFile = acceptedFiles
        .filter(i => i.Id === undefined)
        .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
    }
    if (this.state.data.Attachments && this.state.data.Attachments.length) {
      const { data } = this.state;
      const newAttachments = this.state.data.Attachments;
      uploadFile.forEach(key => {
        newAttachments.push({
          Id: 0,
          Type: 1,
          CaseId: this.state.data.Id,
          FileName: key.name,
          NumberOfPages: key.numberOfPages,
          Title: key.title,
          AttachmentType: 1,
        });
      });
      const newData = {
        ...data,
        Attachments: newAttachments,
      };
      this.setState({ loading: false, data: newData });
    } else {
      const { data } = this.state;
      const newAttachments = [];
      uploadFile.forEach(key => {
        newAttachments.push({
          Id: 0,
          Type: 1,
          CaseId: this.state.data.Id,
          FileName: key.name,
          NumberOfPages: key.numberOfPages,
          Title: key.title,
          AttachmentType: 1,
        });
      });
      const newData = {
        ...data,
        Attachments: newAttachments,
      };
      this.setState({ loading: false, data: newData });
    }
  };

  dragdropDeleteFile = deletedFiles => {
    const { data } = this.state;
    if (deletedFiles != null) {
      var newAttachments = [];
      if (this.state.data.Attachments && this.state.data.Attachments.length) {
        newAttachments = this.state.data.Attachments;
      }
      newAttachments = newAttachments.filter(
        x => (x.name || x.FileName) !== (deletedFiles.name || deletedFiles.FileName),
      );
      const newData = {
        ...data,
        Attachments: newAttachments,
      };
      this.setState({ loading: false, data: newData });
    }
  };

  handleChangeServiceItemType = () =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { data } = this.state;
      var oldValue = data.ServiceItemType ? [...data.ServiceItemType] : null;
      const {
        lstServiceItems,
        lstAnaesthesiaItems,
        lstProfessionalAttendanceItems,
        lstAssistanceAtOperationItems,
      } = ServiceEstimateStore;
      if (oldValue && oldValue.length > value.length) {
        if (!value.includes(1) && lstServiceItems && lstServiceItems.length > 0) {
          notification.destroy();
          notification.error({
            message: 'Select Service Types',
            description: 'Please remove all items before un-select service type!',
            duration: 3,
          });
          return;
        }
        if (!value.includes(2) && lstProfessionalAttendanceItems && lstProfessionalAttendanceItems.length > 0) {
          notification.destroy();
          notification.error({
            message: 'Select Service Types',
            description: 'Please remove all items before un-select service type!',
            duration: 3,
          });
          return;
        }
        if (!value.includes(3) && lstAnaesthesiaItems && lstAnaesthesiaItems.length > 0) {
          notification.destroy();
          notification.error({
            message: 'Select Service Types',
            description: 'Please remove all items before un-select service type!',
            duration: 3,
          });
          return;
        }
      }
      if (value.includes(2)) {
        this.onAddAftercareItem();
      }
      if (value.includes(4)) {
        this.onAddAssistanceAtOperationItem();
      }
      if (
        lstServiceItems.length > 0 ||
        lstAnaesthesiaItems.length > 0 ||
        lstProfessionalAttendanceItems.length > 0 ||
        lstAssistanceAtOperationItems.length > 0
      ) {
        this.calculationFeeAndAjustedFee();
      }
      this.setState({
        data: {
          ...data,
          ServiceItemType: value,
        },
      });
    });

  onAddServiceItem = () => {
    const { data } = this.state;
    const { amaSelectionList, amaCaculationList } = ServiceEstimateStore;
    var listItems = toJS(ServiceEstimateStore.lstServiceItems);
    var dOService = moment();
    if(data && data.DefaultDateOfService){
      dOService = data.DefaultDateOfService; 
    }
    listItems.push({
      idx: listItems.length + 1,
      amaItem: {
        AMAFee: {},
        AMAFeeId: 0,
        // MBSCode: {},
        // MBSCodeId: 0,
        AdjustedFee: 0,
        CalculationId: null,
        CalculationRule: {},
        PercentageApplied: null,
        EstimateId: data.Id,
        Id: 0,
        DateOfService: dOService,
        InvoicedFee: 0,
        IsManualRate: false,
        ManualRateValue: null,
        Approved: true,
      },
      DateOfService: dOService,
      amaCodeOptions: amaSelectionList,
      amaCaculationOptions: amaCaculationList,
      arrPerOptions: [{ idx: 1, value: 0 }],
    });
    ServiceEstimateStore.setServiceItems(listItems);
  };

  @action onApplyCalculationRule = () => {
    var listItems = [...toJS(ServiceEstimateStore.lstServiceItems)];
    var arrSort = [];
    var arrSort1 = [];
    listItems.forEach(el => {
      //if(!el.DateOfService) el.DateOfService = moment();
      if (el.amaItem.Approved && el.DateOfService && !el.amaItem.IsManualRate) {
        el.AMACodeFee = el.amaItem.AMAFee.Fee;
        var fItem = arrSort.find(
          i =>
            i.DateOfService.format('DD/MM/YYYY') === el.DateOfService.format('DD/MM/YYYY') &&
            i.CalculationId === el.amaItem.CalculationId &&
            el.amaItem.IsManualRate === false,
        );
        if (!fItem) {
          arrSort.push({
            DateOfService: el.DateOfService,
            CalculationId: el.amaItem.CalculationId,
            Items: [el],
          });
        } else {
          fItem.Items.push(el);
        }
      } else if (el.amaItem.Approved && !el.DateOfService && !el.amaItem.IsManualRate) {
        el.AMACodeFee = el.amaItem.AMAFee.Fee;
        var fItem1 = arrSort1.find(
          i => !i.DateOfService && i.CalculationId === el.amaItem.CalculationId && el.amaItem.IsManualRate === false,
        );
        if (!fItem1) {
          arrSort1.push({
            DateOfService: el.DateOfService,
            CalculationId: el.amaItem.CalculationId,
            Items: [el],
          });
        } else {
          fItem1.Items.push(el);
        }
      }
    });
    //console.log(arrSort1);
    arrSort.forEach(el => {
      var isSort = false;
      el.Items = [
        ...el.Items.sort((a, b) => (a.AMACodeFee > b.AMACodeFee ? 1 : b.AMACodeFee > a.AMACodeFee ? -1 : 0)).reverse(),
      ];
      //console.log(el.Items);
      if (el.CalculationId && el.CalculationId > 0) {
        var arrOptionSoft = [];
        if (!isSort) {
          arrOptionSoft = [
            ...el.Items[0].arrPerOptions.sort((a, b) => (a.Rate > b.Rate ? 1 : b.Rate > a.Rate ? -1 : 0)).reverse(),
          ]; //[...el.Items[0].arrPerOptions].sort(i => i.Rate).reverse();
          //console.log(arrOptionSoft);
          isSort = true;
        }
        var countIdxRate = 0;
        el.Items.forEach(i => {
          i.amaItem.PercentageApplied = arrOptionSoft[countIdxRate].Rate;
          if (countIdxRate < arrOptionSoft.length - 1) {
            countIdxRate++;
          }
        });
      }
    });
    arrSort1.forEach(el => {
      var isSort1 = false;
      el.Items = [
        ...el.Items.sort((a, b) => (a.AMACodeFee > b.AMACodeFee ? 1 : b.AMACodeFee > a.AMACodeFee ? -1 : 0)).reverse(),
      ];
      //console.log(el.Items);
      if (el.CalculationId && el.CalculationId > 0) {
        var arrOptionSoft = [];
        if (!isSort1) {
          arrOptionSoft = [
            ...el.Items[0].arrPerOptions.sort((a, b) => (a.Rate > b.Rate ? 1 : b.Rate > a.Rate ? -1 : 0)).reverse(),
          ];
          isSort1 = true;
        }
        var countIdxRate = 0;
        el.Items.forEach(i => {
          i.amaItem.PercentageApplied = arrOptionSoft[countIdxRate].Rate;
          if (countIdxRate < arrOptionSoft.length - 1) {
            countIdxRate++;
          }
        });
      }
    });
    listItems.forEach(el => {
      arrSort.forEach(sR => {
        sR.Items.forEach(rI => {
          if (el.idx === rI.idx) {
            el.amaItem.PercentageApplied = rI.amaItem.PercentageApplied;
            if (el.amaItem.IsManualRate) {
              el.amaItem.AdjustedFee = this.roundNumber((rI.amaItem.AMAFee.Fee * rI.amaItem.ManualRateValue) / 100, 2);
            } else {
              el.amaItem.AdjustedFee = this.roundNumber(
                (rI.amaItem.AMAFee.Fee * rI.amaItem.PercentageApplied) / 100,
                2,
              );
            }
          }
        });
      });
      arrSort1.forEach(sR => {
        sR.Items.forEach(rI => {
          if (el.idx === rI.idx) {
            el.amaItem.PercentageApplied = rI.amaItem.PercentageApplied;
            if (el.amaItem.IsManualRate) {
              el.amaItem.AdjustedFee = this.roundNumber((rI.amaItem.AMAFee.Fee * rI.amaItem.ManualRateValue) / 100, 2);
            } else {
              el.amaItem.AdjustedFee = this.roundNumber(
                (rI.amaItem.AMAFee.Fee * rI.amaItem.PercentageApplied) / 100,
                2,
              );
            }
          }
        });
      });
    });
    ServiceEstimateStore.setServiceItems(listItems);
    this.calculationFeeAndAjustedFee();
    this.onAddAssistanceAtOperationItem();
  };

  @action deleteAllAMAItem = () => {
    this.handleOpenConfirm(true, {
      modalType: 'confirm',
      message: `Do you want to delete all items?`,
      onOk: () => {
        ServiceEstimateStore.setServiceItems([]);
        this.calculationFeeAndAjustedFee();
      },
      onCancel: () => {
        this.handleCancelConfirm();
      }
    });
    
  };

  @action deleteAMAItem = idx => {
    const { lstServiceItems, amaSelectionList, amaCaculationList } = ServiceEstimateStore;
    var oldArr = lstServiceItems;
    var newArr = [];
    var totalFee = 0;
    var countIdx = 1;
    oldArr.forEach(it => {
      if (it.idx !== idx) {
        newArr.push({
          idx: countIdx,
          amaItem: it.amaItem,
          DateOfService: it.DateOfService,
          amaCodeOptions: amaSelectionList,
          amaCaculationOptions: amaCaculationList,
          arrPerOptions: it.arrPerOptions,
        });
        //totalFee += it.amaItem.AMACode.Fee;
        countIdx++;
      }
    });
    ServiceEstimateStore.setServiceItems(newArr);
    this.calculationFeeAndAjustedFee();
    this.onAddAssistanceAtOperationItem();
    //this.handleFieldChangeValue('TotalScheduledFee', totalFee);
  };

  renderExclusionCode = (idx, record) => {
    if (record && record.ListExcludedAMACode) {
      return record.ListExcludedAMACode.map(ex => {
        return (
          <label className="ui label" style={{ margin: '2px' }}>
            {' '}
            {ex.ExcludedAMACode}
          </label>
        );
      });
    }
    return null;
  };

  renderExclusionCodeRanges = (idx, record) => {
    if (record.ExcusionCodeRanges && record.ExcusionCodeRanges.length > 0) {
      return record.ExcusionCodeRanges.map(ex => {
        return (
          <label className="ui label" style={{ margin: '2px' }}>
            {' '}
            {ex.ExclusionRange}
          </label>
        );
      });
    }
    return null;
  };

  renderClassName = idx => {
    const { lstServiceItems } = ServiceEstimateStore;
    var colorType = 0;
    var currentItem = lstServiceItems.find(i => i.idx === idx);
    if (currentItem.amaItem && !currentItem.amaItem.Approved) colorType = 1;
    if (colorType === 0) {
      var foundCode = false;
      //Check same amacode & current date
      foundCode = this.checkSameAMACodeAndDate(idx, lstServiceItems);
      //Check in range
      if (!foundCode && currentItem.DateOfService) {
        foundCode = this.checkAMACodeInExclusionRange(
          currentItem.amaItem.AMAFee.AMACode,
          currentItem.amaItem.AMAFee.ExcusionCodeRanges,
        );
        if (!foundCode) {
          lstServiceItems.forEach(i => {
            if (
              i.idx !== idx &&
              currentItem.DateOfService &&
              i.DateOfService &&
              !foundCode &&
              this.convertDate(currentItem.DateOfService).format('dd/MM/yyyy') ===
                this.convertDate(i.DateOfService).format('dd/MM/yyyy')
            ) {
              if (i.amaItem.AMAFee.ExcusionCodeRanges && i.amaItem.AMAFee.ExcusionCodeRanges.length > 0) {
                foundCode = this.checkAMACodeInExclusionRange(
                  currentItem.amaItem.AMAFee.AMACode,
                  i.amaItem.AMAFee.ExcusionCodeRanges,
                );
              }
            }
          });
        }
      }
      //Check other excluded AMACode
      if (!foundCode) {
        if (currentItem && currentItem.amaItem && currentItem.amaItem.AMAFee && currentItem.amaItem.AMAFee.AMACode) {
          lstServiceItems.forEach(i => {
            if (
              i.idx !== idx &&
              currentItem.DateOfService &&
              i.DateOfService &&
              !foundCode &&
              this.convertDate(currentItem.DateOfService).format('dd/MM/yyyy') ===
                this.convertDate(i.DateOfService).format('dd/MM/yyyy')
            ) {
              if (
                i.amaItem.AMAFee.ListExcludedAMACode &&
                i.amaItem.AMAFee.ListExcludedAMACode.length > 0 &&
                !foundCode
              ) {
                foundCode = this.checkAMACodeExclusion(
                  currentItem.amaItem.AMAFee.AMACode,
                  i.amaItem.AMAFee.ListExcludedAMACode,
                );
              }
            }
          });
        }
      }
      if (foundCode) colorType = 2;
    }
    //console.log('Idx: ' + idx + ':: ' + colorType);
    if (colorType === 1) return 'row-lightgray';
    else if (colorType === 2) return 'negative';
    else return '';
  };

  renderClassNameAnaesthesia = idx => {
    const { lstAnaesthesiaItems } = ServiceEstimateStore;
    var colorType = 0;
    var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
    if (!currentItem.Approved) colorType = 1;
    if (colorType == 0) {
      var foundCode = false;
      if (!currentItem.DateOfService) foundCode = true;
      if (!foundCode) {
        //Check in range
        if (currentItem.DateOfService) {
          foundCode = this.checkAMACodeInExclusionRange(
            currentItem.AMAFee.AMACode,
            currentItem.AMAFee.ExcusionCodeRanges,
          );
          if (!foundCode) {
            lstAnaesthesiaItems.forEach(i => {
              if (
                i.idx !== idx &&
                currentItem.DateOfService &&
                i.DateOfService &&
                !foundCode &&
                this.convertDate(currentItem.DateOfService).format('dd/MM/yyyy') ===
                  this.convertDate(i.DateOfService).format('dd/MM/yyyy')
              ) {
                if (i.AMAFee.ExcusionCodeRanges && i.AMAFee.ExcusionCodeRanges.length > 0) {
                  foundCode = this.checkAMACodeInExclusionRange(
                    currentItem.AMAFee.AMACode,
                    i.AMAFee.ExcusionCodeRanges,
                  );
                }
              }
            });
          }
        }
        //Check other excluded AMACode
        if (!foundCode) {
          if (currentItem && currentItem.AMAFee && currentItem.AMAFee.AMACode) {
            lstAnaesthesiaItems.forEach(i => {
              if (
                i.idx !== idx &&
                currentItem.DateOfService &&
                i.DateOfService &&
                !foundCode &&
                this.convertDate(currentItem.DateOfService).format('dd/MM/yyyy') ===
                  this.convertDate(i.DateOfService).format('dd/MM/yyyy')
              ) {
                if (i.AMAFee.ListExcludedAMACode && i.AMAFee.ListExcludedAMACode.length > 0 && !foundCode) {
                  foundCode = this.checkAMACodeExclusion(currentItem.AMAFee.AMACode, i.AMAFee.ListExcludedAMACode);
                }
              }
            });
          }
        }
      }
      if (foundCode) colorType = 2;
    }
    if (colorType === 1) return 'row-lightgray';
    else if (colorType === 2) return 'negative';
    else return '';
  };

  checkSameAMACodeAndDate(idx, lstItems) {
    var foundCode = false;
    var currentItem = lstItems.find(i => i.idx === idx);
    lstItems.forEach(el => {
      if (!foundCode && el.idx !== idx) {
        if (
          currentItem &&
          currentItem.amaItem &&
          currentItem.amaItem.AMAFee &&
          el.amaItem &&
          el.amaItem.AMAFee &&
          el.amaItem.AMAFee.AMACode === currentItem.amaItem.AMAFee.AMACode
        ) {
          if (currentItem.DateOfService === el.DateOfService) {
            foundCode = true;
          }
        }
      }
    });
    return foundCode;
  }

  checkAMACodeExclusion(amaCode, lstExcludedAMACode) {
    var foundCode = false;
    if (amaCode && lstExcludedAMACode && lstExcludedAMACode.length > 0) {
      lstExcludedAMACode.forEach(exc => {
        if (amaCode === exc.ExcludedAMACode && !foundCode) foundCode = true;
      });
    }
    return foundCode;
  }

  checkAMACodeInExclusionRange(amaCode, excusionCodeRanges) {
    var foundCode = false;
    if (amaCode && excusionCodeRanges && excusionCodeRanges.length > 0) {
      excusionCodeRanges.forEach(range => {
        let exclusionRange = range.ExclusionRange.replace(/ /gm, '');
        if (exclusionRange.includes('-') && exclusionRange.length === 11) {
          let arrExclusion = exclusionRange.split('-');
          let startCheck = parseInt(arrExclusion[0].substring(2, 5));
          let endCheck = parseInt(arrExclusion[1].substring(2, 5));
          for (let i = startCheck; i <= endCheck; i++) {
            var checkCode = arrExclusion[0].substring(0, 2) + i.toString().padStart(3, '0');
            if (checkCode === amaCode && !foundCode) foundCode = true;
          }
        }
      });
    }
    return foundCode;
  }

  @action handleSearchClient = () =>
    action(event => {
      const value = event;
      if (value.length > 2) {
        getCompanyList(value, 50).then(
          action(resp => {
            ServiceEstimateStore.setClientOptions(resp.itemList || []);
          }),
        );
      }
    });

  calculationFeeAndAjustedFee = () => {
    var totalUnits = 0;
    var totalFee = 0;
    var totalAjustedFee = 0;
    var totalInvoicedFee = 0;
    var totalOnlyAaOAjustedFee = 0;
    var totalOnlyAaOInvoicedFee = 0;
    const {
      lstServiceItems,
      lstAnaesthesiaItems,
      lstProfessionalAttendanceItems,
      lstMZ900Items,
      lstM4Items,
      lstTimeItems,
      lstAssistanceAtOperationItems,
    } = ServiceEstimateStore;
    //General
    console.log(lstServiceItems);
    lstServiceItems.forEach(el => {
      if (el.amaItem.Approved && el.amaItem.AMAFee && el.amaItem.AMAFee.Fee && el.amaItem.AMAFee.Fee > 0) {
        totalFee += el.amaItem.AMAFee.Fee;
      }
      if (
        el.amaItem.Approved &&
        el.amaItem.AMAFee &&
        el.amaItem.AMAFee.Fee &&
        el.amaItem.AMAFee.Fee > 0 &&
        el.amaItem.PercentageApplied &&
        el.amaItem.PercentageApplied > 0
      ) {
        if (el.amaItem.IsManualRate) {
          totalAjustedFee += el.amaItem.AdjustedFee;
        } else {
          totalAjustedFee += el.amaItem.AdjustedFee;
        }
      } else if (
        el.amaItem.Approved &&
        el.amaItem.AMAFee &&
        el.amaItem.AMAFee.Fee &&
        el.amaItem.AMAFee.Fee > 0 &&
        el.amaItem.CalculationId === -1
      ) {
        totalAjustedFee += el.amaItem.AMAFee.Fee;
      }
      //if (el.amaItem.Approved) 
      totalInvoicedFee += el.amaItem.InvoicedFee;
    });
    //Anaesthesia
    lstAnaesthesiaItems.forEach(el => {
      if (el.Approved) {
        totalAjustedFee += el.ScheduledFee ? el.ScheduledFee : 0;
        if (el.BaseUnit && el.M4Eligible === 1) totalUnits = totalUnits + el.BaseUnit;
        if (el.TimeUnit && el.M4Eligible === 1) totalUnits = totalUnits + el.TimeUnit;
      }
      totalInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
    });
    lstTimeItems.forEach(el => {
      if (el.Approved) {
        totalAjustedFee += el.ScheduledFee ? el.ScheduledFee : 0;
        if (el.TimeUnit && el.M4Eligible === 1) totalUnits = totalUnits + el.TimeUnit;
      }
      totalInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
    });
    ServiceEstimateStore.setTotalUnitToM4Items(totalUnits);
    lstM4Items.forEach(el => {
      if (el.Approved) {
        totalAjustedFee += el.ScheduledFee ? el.ScheduledFee : 0;
      }
      totalInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
    });
    //Professional Attendance
    lstProfessionalAttendanceItems.forEach(el => {
      if (el.Approved) {
        totalAjustedFee += el.AdjustedFee ? el.AdjustedFee : 0;
      }
      totalInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
    });
    //MZ900
    if (!!this.state.data && !!this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(4)) {
      lstMZ900Items.forEach(el => {
        totalAjustedFee += el.AdjustedFee ? el.AdjustedFee : 0;
        totalInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
        totalOnlyAaOAjustedFee += el.AdjustedFee ? el.AdjustedFee : 0;
        totalOnlyAaOInvoicedFee += el.InvoicedFee ? el.InvoicedFee : 0;
      });
    }
    var totalDiffidenceFee = totalInvoicedFee - totalAjustedFee;
    var totaOnlyAaODiffidenceFee = totalOnlyAaOInvoicedFee - totalOnlyAaOAjustedFee;

    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        TotalScheduledFee: this.roundNumber(totalFee, 2),
        AdjustedFee: this.roundNumber(totalAjustedFee, 2),
        TotalQuoteFee: this.roundNumber(totalInvoicedFee, 2),
        Difference: this.roundNumber(totalDiffidenceFee, 2),
        //OnlyAaO
        OnlyAaOAdjustedFee: this.roundNumber(totalOnlyAaOAjustedFee, 2),
        OnlyAaOTotalQuoteFee: this.roundNumber(totalOnlyAaOInvoicedFee, 2),
        OnlyAaODifference: this.roundNumber(totaOnlyAaODiffidenceFee, 2),
      },
    });
  };

  @action handleChangeAMAFeeCode = (idx) =>
    action(event => {
      const value = event;
      const { amaSelectionList, lstServiceItems } = ServiceEstimateStore;
      var item = amaSelectionList.find(i => i.Id === value);
      var currentSvItem = lstServiceItems.find(s => s.idx === idx);
      var newArr = lstServiceItems;
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getFeeByDateOfService(value, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.amaCodeFee) {
            fee = resp.amaCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.amaItem.Id = 0;
              i.amaItem.AMAFeeId = value;
              i.amaItem.AMAFee = item;
              i.amaItem.AMAFee.Fee = fee;
              if (item.DefaultCanculationRule) {
                i.amaItem.CalculationId = item.DefaultCanculationRule;
                getGetAllCaculationRate(i.amaItem.CalculationId).then(
                  action(rateResp => {
                    i.arrPerOptions = rateResp.lstRet;
                  }),
                );
              } else {
                i.amaItem.CalculationId = -1;
                i.arrPerOptions = [];
              }
              if (item.DefaultCanculationRate) {
                i.amaItem.PercentageApplied = item.DefaultCanculationRate;
              } else {
                i.amaItem.PercentageApplied = 100;
              }
            }
            if (
              i.amaItem.AMAFee &&
              i.amaItem.AMAFee.Fee &&
              i.amaItem.AMAFee.Fee > 0 &&
              i.amaItem.PercentageApplied &&
              i.amaItem.PercentageApplied > 0
            ) {
              i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
            } else {
              i.amaItem.AdjustedFee = i.amaItem.AMAFee.Fee;
            }
          });
          ServiceEstimateStore.setServiceItems(newArr);
          this.calculationFeeAndAjustedFee();
          this.onAddAssistanceAtOperationItem();
        }),
      );
    });

  @action handleChangeMBSFeeCode = idx =>
    action(event => {
      const value = event;
      const { mbsSelectionList, lstServiceItems } = ServiceEstimateStore;
      var item = mbsSelectionList.find(i => i.Id === value);
      var currentSvItem = lstServiceItems.find(s => s.idx === idx);
      var newArr = lstServiceItems;
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getMBSCodeFeeByDateOfService(value, this.state.data.State, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.mbsCodeFee) {
            fee = resp.mbsCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.amaItem.Id = 0;
              i.amaItem.AMAFeeId = value;
              //i.amaItem.MBSCodeId = value;
              i.amaItem.AMAFee = item;
              i.amaItem.AMAFee.Fee = fee;
              if (item.DefaultCanculationRule) {
                i.amaItem.CalculationId = item.DefaultCanculationRule;
                getGetAllCaculationRate(i.amaItem.CalculationId).then(
                  action(rateResp => {
                    i.arrPerOptions = rateResp.lstRet;
                  }),
                );
              } else {
                i.amaItem.CalculationId = -1;
                i.arrPerOptions = [];
              }
              if (item.DefaultCanculationRate) {
                i.amaItem.PercentageApplied = item.DefaultCanculationRate;
              } else {
                i.amaItem.PercentageApplied = 100;
              }
            }
            if (
              i.amaItem.AMAFee &&
              i.amaItem.AMAFee.Fee &&
              i.amaItem.AMAFee.Fee > 0 &&
              i.amaItem.PercentageApplied &&
              i.amaItem.PercentageApplied > 0
            ) {
              i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
            } else {
              i.amaItem.AdjustedFee = i.amaItem.AMAFee.Fee;
            }
          });
          ServiceEstimateStore.setServiceItems(newArr);
          this.calculationFeeAndAjustedFee();
          this.onAddAssistanceAtOperationItem();
        }),
      );
    });  

  @action handleSearchAMAFeeCode = idx =>
    action(event => {
      const value = event;
      const { lstServiceItems } = ServiceEstimateStore;
      if (value.length >= 2) {
        searchAMAFee(value).then(
          action(resp => {
            var arrAMACodeList = resp.itemList;
            var newArr = lstServiceItems;
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.amaCodeOptions = arrAMACodeList;
              }
            });
            ServiceEstimateStore.setAmaSelectionList(arrAMACodeList);
            ServiceEstimateStore.setServiceItems(newArr);
          }),
        );
      }
    });

  @action handleSearchAnaesthesiaAMAFeeCode = idx =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchAnaesthesiaAMAFee(value).then(
          action(resp => {
            ServiceEstimateStore.setAnaesthesiaAmaSelectionList(resp.itemList);
          }),
        );
      }
    });
  
  @action handleSearchMBSCode = idx =>
    action(event => {
      const value = event;
      const { lstServiceItems } = ServiceEstimateStore;
      if (value.length >= 2) {
        searchMBSCode(value).then(
          action(resp => {
            var arrMBSCodeList = resp.itemList;
            // var newArr = lstServiceItems;
            // newArr.forEach(i => {
            //   if (i.idx === idx) {
            //     i.amaCodeOptions = arrMBSCodeList;
            //   }
            // });
            ServiceEstimateStore.setMBSSelectionList(arrMBSCodeList);
            //ServiceEstimateStore.setServiceItems(newArr);
          }),
        );
      }
    });
    

  @action handleSearchAMAProfessionalAttendanceFeeCode = idx =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchAMAProfessionalAttendance(value).then(
          action(resp => {
            var arrAMACodeList = resp.itemList;
            ServiceEstimateStore.setListItem('searchAMAProfessionalAttendanceList', arrAMACodeList);
          }),
        );
      }
    });

  @action handleSearchMBSProfessionalAttendanceFeeCode = idx =>
    action(event => {
      const value = event;
      if (value.length >= 2) {
        searchMBSProfessionalAttendance(value).then(
          action(resp => {
            var arrAMACodeList = resp.itemList;
            ServiceEstimateStore.setListItem('searchMBSProfessionalAttendanceList', arrAMACodeList);
          }),
        );
      }
    });

  @action handleChangeAMACalculation = idx =>
    action(event => {
      const value = event;
      getGetAllCaculationRate(value).then(
        action(rateResp => {
          const { lstServiceItems } = ServiceEstimateStore;
          var newArr = lstServiceItems;
          if (rateResp.lstRet && rateResp.lstRet.length > 0) {
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.amaItem.CalculationId = value;
                i.arrPerOptions = rateResp.lstRet;
              }
            });
            ServiceEstimateStore.setServiceItems(newArr);
          } else {
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.amaItem.CalculationId = value;
                i.arrPerOptions = [];
                i.amaItem.PercentageApplied = 100;
                i.amaItem.AdjustedFee = this.roundNumber(i.amaItem.AMAFee.Fee, 2);
              }
            });
            this.calculationFeeAndAjustedFee();
          }
          this.onAddAssistanceAtOperationItem();
        }),
      );
    });

  @action handleSearchAMACalculation = idx =>
    action(event => {
      const value = event;
      const { lstServiceItems } = ServiceEstimateStore;
      if (value.length > 3) {
        searchAMACaculation({
          Keyword: value,
          CurPage: 1,
          NumItemPerPage: 50,
        }).then(
          action(resp => {
            var arrAMACodeList = resp.itemList;
            var newArr = lstServiceItems;
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.amaCaculationOptions = arrAMACodeList;
              }
            });
            ServiceEstimateStore.setServiceItems(newArr);
          }),
        );
      }
    });

  @action handlePercentageApplied = idx =>
    action(event => {
      const value = event;
      const { lstServiceItems } = ServiceEstimateStore;
      var newArr = lstServiceItems;
      newArr.forEach(i => {
        if (i.idx === idx) {
          i.amaItem.PercentageApplied = value;
        }
        if (
          i.amaItem.AMAFee &&
          i.amaItem.AMAFee.Fee &&
          i.amaItem.AMAFee.Fee > 0 &&
          i.amaItem.PercentageApplied &&
          i.amaItem.PercentageApplied > 0
        ) {
          if (i.amaItem.IsManualRate) {
            i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.ManualRateValue) / 100, 2);
          } else {
            i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
          }
        }
      });
      ServiceEstimateStore.setServiceItems(newArr);
      this.onAddAssistanceAtOperationItem();
      this.calculationFeeAndAjustedFee();
    });

  @action handleChangeDateOfService = idx =>
    action(event => {
      const value = event;
      const { lstServiceItems } = ServiceEstimateStore;
      var newArr = lstServiceItems;
      var currentItem = lstServiceItems.find(i => i.idx === idx);
      var searchFeeDate = value;
      if (!searchFeeDate) searchFeeDate = moment();
      getFeeByDateOfService(currentItem.amaItem.AMAFeeId, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          if (resp.status === 'success' && resp.amaCodeFee) {
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.DateOfService = value;
                if (resp.amaCodeFee.Fee) {
                  i.amaItem.AMAFee.Fee = resp.amaCodeFee.Fee;
                  if (i.amaItem.IsManualRate) {
                    i.amaItem.AdjustedFee = this.roundNumber(
                      (i.amaItem.AMAFee.Fee * i.amaItem.ManualRateValue) / 100,
                      2,
                    );
                  } else {
                    i.amaItem.AdjustedFee = this.roundNumber(
                      (i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100,
                      2,
                    );
                  }
                }
                //if(!value) i.amaItem.Approved = false;
              }
            });
            ServiceEstimateStore.setServiceItems(newArr);
          } else {
            newArr.forEach(i => {
              if (i.idx === idx) {
                i.amaItem.AMAFee.Fee = 0;
                i.amaItem.AdjustedFee = 0;
                i.DateOfService = value;
                //if(!value) i.amaItem.Approved = false;
              }
            });
            ServiceEstimateStore.setServiceItems(newArr);
          }
          this.calculationFeeAndAjustedFee();
          this.onAddAssistanceAtOperationItem();
        }),
      );
    });

  @action handleChangeAdjustedFee = (idx, serviceType) =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { lstProfessionalAttendanceItems } = ServiceEstimateStore;
      if (serviceType === 'ProfessionalAttendance') {
        var currentItem = lstProfessionalAttendanceItems.find(i => i.idx === idx);
        if (currentItem) currentItem.AdjustedFee = value;
      }
      this.calculationFeeAndAjustedFee();
    });

  @action handleChangeInvoicedFee = (idx, serviceType) =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      var currentItem = null;
      const {
        lstServiceItems,
        lstAftercareItems,
        lstAnaesthesiaItems,
        lstProfessionalAttendanceItems,
        lstMZ900Items,
      } = ServiceEstimateStore;
      if (serviceType === 'General') {
        currentItem = lstServiceItems.find(i => i.idx === idx);
        if (currentItem) currentItem.amaItem.InvoicedFee = value;
      } else if (serviceType === 'Anaesthesia') {
        currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
        if (currentItem) currentItem.InvoicedFee = value;
      } else if (serviceType === 'Aftercare') {
        currentItem = lstAftercareItems.find(i => i.idx === idx);
        if (currentItem) currentItem.InvoicedFee = value;
      } else if (serviceType === 'ProfessionalAttendance') {
        currentItem = lstProfessionalAttendanceItems.find(i => i.idx === idx);
        if (currentItem) currentItem.InvoicedFee = value;
      } else if (serviceType === 'MZ900') {
        currentItem = lstMZ900Items && lstMZ900Items.length > 0 ? lstMZ900Items[0] : null;
        if (currentItem) currentItem.InvoicedFee = value;
      }

      this.calculationFeeAndAjustedFee();
      this.calculationExpectedFee();
    });

  onCheckedApproved = (idx, serviceType) =>
    action(event => {
      var currentItem = null;
      const {
        lstServiceItems,
        lstAftercareItems,
        lstAnaesthesiaItems,
        lstProfessionalAttendanceItems,
        lstAssistanceAtOperationItems,
      } = ServiceEstimateStore;
      if (serviceType === 'General') {
        var newArr = lstServiceItems;
        newArr.forEach(i => {
          if (i.idx === idx) {
            var value = !i.amaItem.Approved;
            if (!value) {
              i.amaItem.AdjustedFee = 0;
              //i.amaItem.InvoicedFee = 0;
            } else {
              if (i.amaItem.IsManualRate) {
                i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.ManualRateValue) / 100, 2);
              } else {
                i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
              }
            }
            i.amaItem.Approved = value;
          }
        });
        ServiceEstimateStore.setServiceItems(newArr);
      } else if (serviceType === 'Anaesthesia') {
        currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
        if (currentItem) {
          var value = !currentItem.Approved;
          if (!value) {
            currentItem.ScheduledFee = 0;
          } else {
            currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
              currentItem.BaseUnit,
              currentItem.TimeUnit,
              currentItem.ModifyingUnits,
              currentItem.UnitRate,
            );
          }
          currentItem.Approved = value;
        }
      } else if (serviceType === 'Aftercare') {
        currentItem = lstAftercareItems.find(i => i.idx === idx);
        if (currentItem) {
          currentItem.Approved = !currentItem.Approved;
        }
      } else if (serviceType === 'ProfessionalAttendance') {
        currentItem = lstProfessionalAttendanceItems.find(i => i.idx === idx);
        if (currentItem) {
          var value = !currentItem.Approved;
          if (!value) {
            currentItem.AdjustedFee = 0;
          } else {
            currentItem.AdjustedFee = currentItem.AMAFee.Fee;
          }
          currentItem.Approved = value;
        }
      }
      if (serviceType === 'AssistanceRequired') {
        currentItem = lstAssistanceAtOperationItems.find(i => i.idx === idx);
        if (currentItem) {
          currentItem.AssistanceRequired = !currentItem.AssistanceRequired;
          currentItem.RateValue = 0;
          currentItem.AdjustedFee = currentItem.AssistanceRequired ? currentItem.AMAFee.Fee * (currentItem.RateValue)/100 * 0.2 : 0
        }
      }
      this.onAddAssistanceAtOperationItem();
      this.calculationFeeAndAjustedFee();
    });

  onTimeCheckedApproved = () =>
    action(event => {
      const { lstTimeItems } = ServiceEstimateStore;
      lstTimeItems[0].Approved = !lstTimeItems[0].Approved;
      this.calculationFeeAndAjustedFee();
    });

  onM4CheckedApproved = () =>
    action(event => {
      const { lstM4Items } = ServiceEstimateStore;
      if (lstM4Items[0].Approved) {
        lstM4Items[0].Approved = !lstM4Items[0].Approved;
        this.calculationFeeAndAjustedFee();
      } else {
        var onClickOK = false;
        if (lstM4Items && lstM4Items.length > 0) {
          this.handleOpenConfirm(true, {
            modalType: 'confirm',
            message: `M4 modifier only applies for after-hour emergencies. Are you sure to approve?`,
            onOk: () => {
              onClickOK = true;
              var newArr = [{ ...lstM4Items[0], Approved: true }];
              ServiceEstimateStore.setListItem('M4', newArr);
              this.calculationFeeAndAjustedFee();
            },
            onCancel: () => {
              if (!onClickOK) {
                var newArr = [{ ...lstM4Items[0], Approved: false }];
                ServiceEstimateStore.setListItem('M4', newArr);
                this.calculationFeeAndAjustedFee();
              }
              this.handleCancelConfirm();
            },
          });
        }
      }
    });

  @action handleChangeManualRateValue = idx =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { lstServiceItems } = ServiceEstimateStore;
      var newArr = lstServiceItems;
      newArr.forEach(i => {
        if (i.idx === idx) {
          i.amaItem.ManualRateValue = value;
          if (i.amaItem.IsManualRate) {
            i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.ManualRateValue) / 100, 2);
          } else {
            i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
          }
        }
      });
      ServiceEstimateStore.setServiceItems(newArr);
      this.calculationFeeAndAjustedFee();
    });

  @action onItemChecked = idx =>
    action(event => {
      const { lstServiceItems } = ServiceEstimateStore;
      var newArr = lstServiceItems;
      newArr.forEach(i => {
        if (i.idx === idx) {
          i.amaItem.IsManualRate = !i.amaItem.IsManualRate;
        }
      });
      newArr.forEach(i => {
        if (i.amaItem.IsManualRate) {
          i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.ManualRateValue) / 100, 2);
        } else {
          i.amaItem.AdjustedFee = this.roundNumber((i.amaItem.AMAFee.Fee * i.amaItem.PercentageApplied) / 100, 2);
        }
      });
      ServiceEstimateStore.setServiceItems(newArr);
      this.calculationFeeAndAjustedFee();
    });

  onAddAftercareItem = () => {
    const { data } = this.state;
    //var listItems = toJS(ServiceEstimateStore.lstAftercareItems);
    var dOService = moment();
    if(data && data.DefaultDateOfService){
      dOService = data.DefaultDateOfService; 
    }
    var listItems = [
      {
        Id: 0,
        idx: 1,
        EstimateId: data.Id,
        AMACodeId: null,
        AMAFee: {},
        AftercareRuleId: null,
        AftercareRule: null,
        DateOfService: dOService,
        AftercareToDate: null,
        ScheduledFee: null,
        AdjustedFee: null,
        InvoicedFee: null,
        Approved: true,
      },
    ];
    ServiceEstimateStore.setListItem('aftercare', listItems);
  };

  @action deleteAftercareItem = idx => {
    const { lstAftercareItems } = ServiceEstimateStore;
    var oldArr = lstAftercareItems;
    var newArr = [];
    var countIdx = 1;
    oldArr.forEach(it => {
      if (it.idx !== idx) {
        newArr.push({
          Id: it.Id,
          idx: countIdx,
          EstimateId: it.EstimateId,
          AMACodeId: it.AMACodeId,
          AMAFee: it.AMAFee,
          AftercareRuleId: it.AftercareRuleId,
          AftercareRule: it.AftercareRule,
          DateOfService: it.DateOfService,
          AftercareToDate: it.AftercareToDate,
          ScheduledFee: it.ScheduledFee,
          AdjustedFee: it.AdjustedFee,
          InvoicedFee: it.InvoicedFee,
          Approved: it.Approved,
        });
        countIdx++;
      }
    });
    ServiceEstimateStore.setListItem('aftercare', newArr);
  };

  handleChangeAftercareField = (idx, field) =>
    action(event => {
      const value = event;
      const { lstAftercareItems, lstAftercareRules, lstProfessionalAttendanceItems } = ServiceEstimateStore;
      var currentItem = lstAftercareItems.find(i => i.idx === idx);
      var currentRule = lstAftercareRules.find(i => i.Id === value);
      if (field === 'Rule') {
        if (currentItem) {
          currentItem.AftercareRuleId = currentRule.Id;
          currentItem.AftercareRule = currentRule;
          currentItem.RuleNotes = currentRule.Description;

          var compareDate = moment(currentItem.DateOfService.clone().format('YYYY-MM-DD'));
          lstProfessionalAttendanceItems.forEach(pa => {
            var proDay = moment(pa.DateOfService.clone().format('YYYY-MM-DD'));
            if(proDay.isSame(compareDate) && currentItem.AftercareRuleId === 1) pa.Approved = false;
          });

          if (currentItem.DateOfService && currentRule.Duration) {
            var newDate = currentItem.DateOfService.clone().add(currentRule.Duration, 'days');
            currentItem.AftercareToDate = newDate;
          } else if (currentItem.DateOfService && currentRule.Duration === 0) {
            currentItem.AftercareToDate = currentItem.DateOfService.clone();
          }
        }
      }
    });

  @action handleChangeAMAFeeCodeAftercare = idx =>
    action(event => {
      const value = event;
      const { amaSelectionList, lstAftercareItems } = ServiceEstimateStore;
      var item = amaSelectionList.find(i => i.Id === value);
      var currentSvItem = lstAftercareItems.find(s => s.idx === idx);
      var newArr = lstAftercareItems;
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getFeeByDateOfService(value, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.amaCodeFee) {
            fee = resp.amaCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.AMACodeId = value;
              i.AMAFee = item;
              i.AMAFee.Fee = fee;
              i.ScheduledFee = fee;
              i.AdjustedFee = fee;
            }
          });
          ServiceEstimateStore.setListItem('aftercare', newArr);
          //this.calculationFeeAndAjustedFee();
        }),
      );
    });

  @action handleChangeAftercareDateOfService = idx =>
    action(event => {
      const value = event;
      const { lstAftercareItems, lstProfessionalAttendanceItems } = ServiceEstimateStore;
      var currentItem = lstAftercareItems.find(i => i.idx === idx);
      if (currentItem && value) {
        currentItem.DateOfService = value;
        var compareDate = moment(value.clone().format('YYYY-MM-DD'));
        lstProfessionalAttendanceItems.forEach(pa => {
          var proDay = moment(pa.DateOfService.clone().format('YYYY-MM-DD'));
          if(proDay.isSame(compareDate) && currentItem.AftercareRuleId === 1) pa.Approved = false;
        });
        if (currentItem.AftercareRule && currentItem.AftercareRule.Duration) {
          var newDate = currentItem.DateOfService.clone().add(currentItem.AftercareRule.Duration, 'days');
          currentItem.AftercareToDate = newDate;
        }
      } else {
        currentItem.DateOfService = null;
        currentItem.AftercareToDate = null;
      }
    });

  onAddAnaesthesiaItem = () => {
    const { data } = this.state;
    //console.log(data);
    const { lstAMASpecific, lstM4Items, lstTimeItems } = ServiceEstimateStore;
    var listItems = toJS(ServiceEstimateStore.lstAnaesthesiaItems);
    var dOService = moment();
    if(data && data.DefaultDateOfService){
      dOService = data.DefaultDateOfService; 
    }
    listItems.push({
      Id: 0,
      idx: listItems.length + 1,
      EstimateId: data.Id,
      AMACodeId: null,
      AMAFee: {},
      DateOfService: dOService,
      Duration: null,
      TimeUnit: null,
      BaseUnit: null,
      ModifyingUnitId: null,
      ModifyingUnits: null,
      UnitRate: 89,
      ScheduledFee: null,
      InvoicedFee: null,
      M4Eligible: 1,
      Approved: true,
    });
    ServiceEstimateStore.setListItem('anaesthesia', listItems);
    //Time
    if (!lstTimeItems || lstTimeItems.length === 0) {
      var timeItem = lstAMASpecific.find(i => i.AMACode.toLowerCase() === 'time');
      this.addUpdateAMATime(data, timeItem);
    }
    //M4
    if (!lstM4Items || lstM4Items.length === 0) {
      var m4Item = lstAMASpecific.find(i => i.AMACode === 'M4');
      this.addUpdateAMAM4(data, m4Item);
    }
    this.calculationFeeAndAjustedFee();
  };

  addUpdateAMATime = async (data, timeItem, isUpdate = false) => {
    const { lstAnaesthesiaItems, lstTimeItems } = ServiceEstimateStore;
    let amaTimeId = timeItem.Id;
    if (isUpdate) amaTimeId = timeItem.AMACodeId;
    const response = await getFeeByDateOfService(amaTimeId, lstAnaesthesiaItems[0].DateOfService.format('YYYY-MM-DD'));
    var feeTime = 0;
    if (response.status === 'success' && response.amaCodeFee) {
      feeTime = response.amaCodeFee.Fee ?? 89;
    } else {
      feeTime = lstAnaesthesiaItems[0].UnitRate;
    }
    if (isUpdate) {
      var lstTime = [
        {
          Id: lstTimeItems[0].Id,
          idx: lstTimeItems[0].idx,
          EstimateId: lstTimeItems[0].EstimateId,
          AMACodeId: lstTimeItems[0].AMACodeId,
          AMAFee: lstTimeItems[0].AMAFee,
          DateOfService: lstAnaesthesiaItems[0].DateOfService,
          Duration: lstTimeItems[0].Duration,
          TimeUnit: lstTimeItems[0].TimeUnit,
          BaseUnit: lstTimeItems[0].BaseUnit,
          ModifyingUnitId: lstTimeItems[0].ModifyingUnitId,
          ModifyingUnits: lstTimeItems[0].ModifyingUnits,
          UnitRate: feeTime,
          ScheduledFee: lstTimeItems[0].ScheduledFee,
          InvoicedFee: lstTimeItems[0].InvoicedFee,
          M4Eligible: lstTimeItems[0].M4Eligible,
          Approved: lstTimeItems[0].Approved,
        },
      ];
      ServiceEstimateStore.setListItem('Time', []);
      ServiceEstimateStore.setListItem('Time', lstTime);
    } else {
      var lstTime = [
        {
          Id: 0,
          idx: 99,
          EstimateId: data && data.Id ? data.Id : 0,
          AMACodeId: amaTimeId,
          AMAFee: timeItem,
          DateOfService: lstAnaesthesiaItems[0].DateOfService,
          Duration: 0,
          TimeUnit: 0,
          BaseUnit: 0,
          ModifyingUnitId: null,
          ModifyingUnits: null,
          UnitRate: feeTime,
          ScheduledFee: 0,
          InvoicedFee: null,
          M4Eligible: 1,
          Approved: true,
        },
      ];
      ServiceEstimateStore.setListItem('Time', lstTime);
    }
  };

  addUpdateAMAM4 = async (data, m4Item, isUpdate = false) => {
    const { lstAnaesthesiaItems, lstM4Items } = ServiceEstimateStore;
    let amaTimeId = m4Item.Id;
    if (isUpdate) amaTimeId = m4Item.AMACodeId;

    const resp = await getFeeByDateOfService(amaTimeId, lstAnaesthesiaItems[0].DateOfService.format('YYYY-MM-DD'));
    var feeTime = 0;
    if (resp.status === 'success' && resp.amaCodeFee) {
      feeTime = resp.amaCodeFee.Fee ?? 89;
    } else {
      feeTime = lstAnaesthesiaItems[0].UnitRate;
    }
    if (isUpdate) {
      var lstM4 = [
        {
          Id: lstM4Items[0].Id,
          idx: lstM4Items[0].idx,
          EstimateId: lstM4Items[0].EstimateId,
          AMACodeId: lstM4Items[0].AMACodeId,
          AMAFee: lstM4Items[0].AMAFee,
          DateOfService: lstAnaesthesiaItems[0].DateOfService,
          Duration: lstM4Items[0].Duration,
          TimeUnit: lstM4Items[0].TimeUnit,
          BaseUnit: lstM4Items[0].BaseUnit,
          ModifyingUnitId: lstM4Items[0].ModifyingUnitId,
          ModifyingUnits: lstM4Items[0].ModifyingUnits,
          UnitRate: feeTime,
          ScheduledFee: lstM4Items[0].ScheduledFee,
          InvoicedFee: lstM4Items[0].InvoicedFee,
          M4Eligible: lstM4Items[0].M4Eligible,
          Approved: lstM4Items[0].Approved,
        },
      ];
      ServiceEstimateStore.setListItem('M4', []);
      ServiceEstimateStore.setListItem('M4', lstM4);
    } else {
      var lstM4 = [
        {
          Id: 0,
          idx: 99,
          EstimateId: data && data.Id ? data.Id : 0,
          AMACodeId: amaTimeId,
          AMAFee: m4Item,
          DateOfService: lstAnaesthesiaItems[0].DateOfService,
          Duration: 0,
          TimeUnit: 0,
          BaseUnit: 0,
          ModifyingUnitId: null,
          ModifyingUnits: null,
          UnitRate: feeTime,
          ScheduledFee: 0,
          InvoicedFee: null,
          M4Eligible: 1,
          Approved: false,
        },
      ];
      ServiceEstimateStore.setListItem('M4', lstM4);
    }
  };

  deleteAnaesthesiaItem = idx => {
    this.handleOpenConfirm(true, {
      modalType: 'confirm',
      message: `Do you want to delete this items?`,
      onOk: () => {
        const { lstAnaesthesiaItems, lstM4Items } = ServiceEstimateStore;
        var oldArr = lstAnaesthesiaItems;
        var newArr = [];
        var countIdx = 1;
        oldArr.forEach(it => {
          if (it.idx !== idx) {
            newArr.push({
              Id: it.Id,
              idx: countIdx,
              EstimateId: it.EstimateId,
              AMACodeId: it.AMACodeId,
              AMAFee: it.AMAFee,
              DateOfService: it.DateOfService,
              Duration: it.Duration,
              TimeUnit: it.TimeUnit,
              BaseUnit: it.BaseUnit,
              UnitRate: it.UnitRate,
              ModifyingUnitId: it.ModifyingUnitId,
              ModifyingUnits: it.ModifyingUnits,
              ScheduledFee: it.ScheduledFee,
              InvoicedFee: it.InvoicedFee,
              M4Eligible: it.M4Eligible,
              Approved: it.Approved,
            });
            countIdx++;
          }
        });
        ServiceEstimateStore.setListItem('anaesthesia', newArr);
        if (newArr.length == 0) {
          ServiceEstimateStore.setListItem('M4', []);
        }
        this.calculationFeeAndAjustedFee();
      },
      onCancel: () => {
        this.handleCancelConfirm();
      },
    });
  };

  deleteAllAnaesthesiaItem = () => {
    this.handleOpenConfirm(true, {
      modalType: 'confirm',
      message: `Do you want to delete all items?`,
      onOk: () => {
        ServiceEstimateStore.setListItem('anaesthesia', []);
        ServiceEstimateStore.setListItem('M4', []);
        this.calculationFeeAndAjustedFee();
      },
      onCancel: () => {
        this.handleCancelConfirm();
      },
    });
  };

  @action handleChangeAMAFeeCodeAnaesthesia = idx =>
    action(event => {
      const value = event;
      var checkExclusion = false;
      const { amaSelectionList, amaAnaesthesiaSelectionList, lstAnaesthesiaItems, lstM4ModifierExclusionCodes } = ServiceEstimateStore;
      var item = amaAnaesthesiaSelectionList.find(i => i.Id === value);
      var currentSvItem = lstAnaesthesiaItems.find(s => s.idx === idx);
      var newArr = lstAnaesthesiaItems;
      lstM4ModifierExclusionCodes.forEach(el => {
        if (!checkExclusion && el.ExclusionCode === item.AMACode) {
          checkExclusion = true;
        }
      });
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getFeeByDateOfService(value, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.amaCodeFee) {
            fee = resp.amaCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.AMACodeId = value;
              i.BaseUnit = item.BaseUnit; //item.BaseUnit;
              i.UnitRate = fee ? fee : 89; //item.UnitRate ? item.UnitRate : 89;
              i.AMAFee = item;
              i.AMAFee.Fee = fee;
              i.M4Eligible = checkExclusion ? 2 : 1;
              i.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                i.BaseUnit,
                i.TimeUnit,
                i.ModifyingUnits,
                i.UnitRate,
              );
            }
          });
          ServiceEstimateStore.setListItem('anaesthesia', newArr);
          this.calculationFeeAndAjustedFee();
        }),
      );
    });
  
  @action handleChangeMBSFeeCodeAnaesthesia = idx => 
    action(event => {
      const value = event;
      var checkExclusion = false;
      const { mbsSelectionList, lstAnaesthesiaItems, lstM4ModifierExclusionCodes } = ServiceEstimateStore;
      var item = mbsSelectionList.find(i => i.Id === value);
      var currentSvItem = lstAnaesthesiaItems.find(s => s.idx === idx);
      var searchFeeDate = currentSvItem.DateOfService;
      var newArr = lstAnaesthesiaItems;
      getMBSCodeFeeByDateOfService(value, this.state.data.State, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.mbsCodeFee) {
            fee = resp.mbsCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.AMACodeId = value;
              i.BaseUnit = item.BaseUnit;
              i.UnitRate = fee ? fee : 89; //item.UnitRate ? item.UnitRate : 89;
              i.AMAFee = item;
              i.AMAFee.Fee = fee;
              i.M4Eligible = checkExclusion ? 2 : 1;
              i.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                i.BaseUnit,
                i.TimeUnit,
                i.ModifyingUnits,
                i.UnitRate,
              );
            }
          });
        }), 
      );
  });
  
  @action handleChangeAnaesthesiaDateOfService = idx =>
    action(event => {
      const value = event;
      const { lstAnaesthesiaItems } = ServiceEstimateStore;
      var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
      var searchFeeDate = value;
      if (!searchFeeDate) searchFeeDate = moment();
      if (currentItem.AMACodeId) {
        getFeeByDateOfService(currentItem.AMACodeId, searchFeeDate.format('YYYY-MM-DD')).then(
          action(resp => {
            var fee = 0;
            if (resp.status === 'success' && resp.amaCodeFee) {
              fee = resp.amaCodeFee.Fee;
            }
            if (currentItem && value) {
              currentItem.DateOfService = value;
              currentItem.UnitRate = fee;
              currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                currentItem.BaseUnit,
                currentItem.TimeUnit,
                currentItem.ModifyingUnits,
                currentItem.UnitRate,
              );
            } else {
              currentItem.DateOfService = null;
              if (!value) currentItem.Approved = false;
              currentItem.AMAFee.Fee = 0;
              currentItem.UnitRate = 0;
              currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                currentItem.BaseUnit,
                currentItem.TimeUnit,
                currentItem.ModifyingUnits,
                currentItem.UnitRate,
              );
            }
            this.calculationFeeAndAjustedFee();
          }),
        );
      } else {
        currentItem.DateOfService = value;
        if (!value) currentItem.Approved = false;
      }
      if (idx === 1) {
        const { lstTimeItems, lstM4Items } = ServiceEstimateStore;
        this.addUpdateAMATime(null, lstTimeItems[0], true);
        this.addUpdateAMAM4(null, lstM4Items[0], true);
      }
    });

  handleChangeM4Eligible = idx =>
    action(event => {
      const value = event;
      const { lstAnaesthesiaItems } = ServiceEstimateStore;
      var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
      if (currentItem) {
        currentItem.M4Eligible = value;
      }
      this.calculationFeeAndAjustedFee();
    });

  handleChangeTimeEligible = idx =>
    action(event => {
      const value = event;
      const { lstTimeItems } = ServiceEstimateStore;
      var currentItem = lstTimeItems.find(i => i.idx === idx);
      if (currentItem) {
        currentItem.M4Eligible = value;
      }
      this.calculationFeeAndAjustedFee();
    });

  handleChangeModifyingUnit = idx =>
    action(event => {
      const value = event;
      const { lstAnaesthesiaItems, lstModifyingUnits } = ServiceEstimateStore;
      var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
      if (value) {
        var modifyingUnit = lstModifyingUnits.find(m => m.Id === value);
        if (modifyingUnit) {
          currentItem.ModifyingUnitId = value;
          currentItem.ModifyingUnits = modifyingUnit.ModifyUnit;
          currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
            currentItem.BaseUnit,
            currentItem.TimeUnit,
            currentItem.ModifyingUnits,
            currentItem.UnitRate,
          );
        }
      }
      //console.log(value);
    });

  calculateAdjustedFeeAnaesthesia(baseUnit, timeUnit, modifyingUnit, fee) {
    if (!fee || fee === 0) return 0;
    var valueRet = 0;
    var totalUnit = 0;
    if (baseUnit) totalUnit = totalUnit + parseInt(baseUnit);
    if (timeUnit) totalUnit = totalUnit + parseInt(timeUnit);
    //if (modifyingUnit) totalUnit = totalUnit + parseInt(modifyingUnit);
    valueRet = totalUnit * fee;
    return valueRet;
  }

  handleChangeDuration = idx =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { lstAnaesthesiaItems, lstTimeUnits } = ServiceEstimateStore;
      var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
      if (currentItem && value) {
        currentItem.Duration = value;
        var timeUnitObj = lstTimeUnits.find(u => u.StartTime <= value && u.EndTime >= value);
        if (timeUnitObj) {
          currentItem.TimeUnit = timeUnitObj.TimeUnit;
          currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
            currentItem.BaseUnit,
            currentItem.TimeUnit,
            currentItem.ModifyingUnits,
            currentItem.UnitRate,
          );
        } else {
          getTimeUnit(value).then(
            action(resp => {
              if (resp.status === 'success') {
                currentItem.TimeUnit = resp.timeUnit;
              } else {
                currentItem.TimeUnit = null;
              }
              currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                currentItem.BaseUnit,
                currentItem.TimeUnit,
                currentItem.ModifyingUnits,
                currentItem.UnitRate,
              );
              this.calculationFeeAndAjustedFee();
            }),
          );
        }
      }
      this.calculationFeeAndAjustedFee();
    });

  handleChangeTimeDuration = idx =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { lstTimeUnits, lstTimeItems } = ServiceEstimateStore;
      var currentItem = lstTimeItems.find(i => i.idx === idx);
      if (currentItem && value) {
        currentItem.Duration = value;
        var timeUnitObj = lstTimeUnits.find(u => u.StartTime <= value && u.EndTime >= value);
        if (timeUnitObj) {
          currentItem.TimeUnit = timeUnitObj.TimeUnit;
          currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
            currentItem.BaseUnit,
            currentItem.TimeUnit,
            currentItem.ModifyingUnits,
            currentItem.UnitRate,
          );
        } else {
          getTimeUnit(value).then(
            action(resp => {
              if (resp.status === 'success') {
                currentItem.TimeUnit = resp.timeUnit;
              } else {
                currentItem.TimeUnit = null;
              }
              currentItem.ScheduledFee = this.calculateAdjustedFeeAnaesthesia(
                currentItem.BaseUnit,
                currentItem.TimeUnit,
                currentItem.ModifyingUnits,
                currentItem.UnitRate,
              );
              this.calculationFeeAndAjustedFee();
            }),
          );
        }
      }
      this.calculationFeeAndAjustedFee();
    });

  handleChangeTimeItemInvoicedFee = () =>
    action(event => {
      const value = event;
      const { lstTimeItems } = ServiceEstimateStore;
      if (lstTimeItems && lstTimeItems.length > 0) {
        lstTimeItems[0].InvoicedFee = value;
      }
      this.calculationFeeAndAjustedFee();
    });

  handleChangeM4ItemInvoicedFee = () =>
    action(event => {
      const value = event;
      const { lstM4Items } = ServiceEstimateStore;
      if (lstM4Items && lstM4Items.length > 0) {
        lstM4Items[0].InvoicedFee = value;
      }
      this.calculationFeeAndAjustedFee();
    });

  // @action onCheckDurationUnit = (idx) => {
  //   const { lstAnaesthesiaItems, lstTimeUnits } = ServiceEstimateStore;
  //   var currentItem = lstAnaesthesiaItems.find(i => i.idx === idx);
  //   if(currentItem) {
  //     var timeUnitObj = lstTimeUnits.find(u => u.StartTime <= currentItem.Duration && u.EndTime >= currentItem.Duration);
  //     if(timeUnitObj){
  //       currentItem.TimeUnit = timeUnitObj.TimeUnit;
  //       currentItem.ScheduledFee = (currentItem.BaseUnit + currentItem.TimeUnit) * currentItem.UnitRate;
  //     }else{
  //       getTimeUnit(currentItem.Duration).then(
  //         action(resp => {
  //           if(resp.status === 'success'){
  //             currentItem.TimeUnit = resp.timeUnit;
  //             currentItem.ScheduledFee = (currentItem.BaseUnit + currentItem.TimeUnit) * currentItem.UnitRate;
  //           }else{
  //             currentItem.TimeUnit = 0;
  //             currentItem.ScheduledFee = currentItem.BaseUnit * currentItem.UnitRate;
  //           }
  //         }));
  //     }
  //   }
  // };

  onAddProfessionalAttendance = () => {
    const { data } = this.state;
    var listItems = toJS(ServiceEstimateStore.lstProfessionalAttendanceItems);
    var currentDate = moment();
    if(data && data.DefaultDateOfService){
      currentDate = data.DefaultDateOfService; 
    }
    //var isBetween = this.checkProDate(currentDate);
    listItems.push({
      Id: 0,
      idx: listItems.length + 1,
      EstimateId: data.Id,
      AMACodeId: null,
      AMAFee: {},
      DateOfService: currentDate,
      AdjustedFee: null,
      InvoicedFee: null,
      Approved: true,
    });
    ServiceEstimateStore.setListItem('professionalAttendance', listItems);
    this.calculationFeeAndAjustedFee();
  };

  @action deleteProfessionalAttendance = idx => {
    this.handleOpenConfirm(true, {
      modalType: 'confirm',
      message: `Do you want to delete this item?`,
      onOk: () => {
        const { lstProfessionalAttendanceItems } = ServiceEstimateStore;
        var oldArr = lstProfessionalAttendanceItems;
        var newArr = [];
        var countIdx = 1;
        oldArr.forEach(it => {
          if (it.idx !== idx) {
            newArr.push({
              Id: it.Id,
              idx: countIdx,
              EstimateId: it.EstimateId,
              AMACodeId: it.AMACodeId,
              AMAFee: it.AMAFee,
              DateOfService: it.DateOfService,
              AdjustedFee: it.AdjustedFee,
              InvoicedFee: it.InvoicedFee,
              Approved: it.Approved,
            });
            countIdx++;
          }
        });
        ServiceEstimateStore.setListItem('professionalAttendance', newArr.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
        this.calculationFeeAndAjustedFee();
      },
      onCancel: () => {
        this.handleCancelConfirm();
      },
    });
  };

  @action deleteAllProfessionalAttendance = () => {
    this.handleOpenConfirm(true, {
      modalType: 'confirm',
      message: `Do you want to delete all items?`,
      onOk: () => {
        ServiceEstimateStore.setListItem('professionalAttendance', []);
        this.calculationFeeAndAjustedFee();
      },
      onCancel: () => {
        this.handleCancelConfirm();
      },
    });
  };

  @action handleChangeAMAFeeCodeProfessional = idx =>
    action(event => {
      const value = event;
      const { amaProfessionalAttendanceList, lstProfessionalAttendanceItems } = ServiceEstimateStore;
      var item = amaProfessionalAttendanceList.find(i => i.Id === value);
      var currentSvItem = lstProfessionalAttendanceItems.find(s => s.idx === idx);
      var newArr = lstProfessionalAttendanceItems;
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getFeeByDateOfService(value, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.amaCodeFee) {
            fee = resp.amaCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.AMACodeId = value;
              i.AMAFee = item;
              i.AMAFee.Fee = fee;
              i.ScheduledFee = fee;
              i.AdjustedFee = fee;
            }
          });
          ServiceEstimateStore.setListItem('professionalAttendance', newArr.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
          this.calculationFeeAndAjustedFee();
        }),
      );
    });

  @action handleChangeMBSFeeCodeProfessional = idx =>
    action(event => {
      const value = event;
      const { mbsProfessionalAttendanceList, lstProfessionalAttendanceItems } = ServiceEstimateStore;
      var item = mbsProfessionalAttendanceList.find(i => i.Id === value);
      var currentSvItem = lstProfessionalAttendanceItems.find(s => s.idx === idx);
      var newArr = lstProfessionalAttendanceItems;
      var searchFeeDate = currentSvItem.DateOfService;
      if (!searchFeeDate) searchFeeDate = moment();
      //console.log(item);
      getMBSCodeFeeByDateOfService(value, this.state.data.State, searchFeeDate.format('YYYY-MM-DD')).then(
        action(resp => {
          var fee = 0;
          if (resp.status === 'success' && resp.mbsCodeFee) {
            fee = resp.mbsCodeFee.Fee;
          }
          newArr.forEach(i => {
            if (i.idx === idx) {
              i.AMACodeId = value;
              i.AMAFee = item;
              i.AMAFee.Fee = fee;
              i.ScheduledFee = fee;
              i.AdjustedFee = fee;
            }
          });
          ServiceEstimateStore.setListItem('professionalAttendance', newArr);
          this.calculationFeeAndAjustedFee();
        }),
      );
    });

  @action handleChangeProfessionalDateOfService = idx =>
    action(event => {
      const value = event;
      const { lstProfessionalAttendanceItems } = ServiceEstimateStore;
      var currentItem = lstProfessionalAttendanceItems.find(i => i.idx === idx);
      var searchFeeDate = value;
      if (!searchFeeDate) searchFeeDate = moment();
      //var isBetween = this.checkProDate(searchFeeDate);
      if (currentItem.AMACodeId) {
        getFeeByDateOfService(currentItem.AMACodeId, searchFeeDate.format('YYYY-MM-DD')).then(
          action(resp => {
            var fee = 0;
            if (resp.status === 'success' && resp.amaCodeFee) {
              fee = resp.amaCodeFee.Fee;
            }
            if (currentItem && value) {
              currentItem.DateOfService = value;
              currentItem.AMAFee.Fee = fee;
              //currentItem.Approved = !isBetween;
            } else {
              currentItem.DateOfService = null;
              currentItem.AMAFee.Fee = 0;
              //currentItem.Approved = !isBetween;
            }
          }),
        );
      } else {
        currentItem.DateOfService = value;
        //currentItem.Approved = !isBetween;
      }
      this.calculationFeeAndAjustedFee();
    });

  renderClassNameProfessionalCheck = idx => {
    var colorType = 0;
    const { lstProfessionalAttendanceItems } = ServiceEstimateStore;
    var currentItem = lstProfessionalAttendanceItems.find(i => i.idx === idx);
    if (!currentItem.Approved) {
      return 'row-lightgray';
    }
    var isBetween = this.checkProDate(currentItem.DateOfService);
    if (isBetween) {
      return 'negative';
    }
    return '';
  };

  checkProDate(dateCheck) {
    var isBetween = false;
    const { lstAftercareItems } = ServiceEstimateStore;
    if (dateCheck && lstAftercareItems) {
      var proDay = moment(dateCheck.clone().format('YYYY-MM-DD'));
      lstAftercareItems.forEach(aft => {
        var beforeDate = aft.DateOfService ? moment(aft.DateOfService.clone().format('YYYY-MM-DD')) : null;
        var afterDate = aft.AftercareToDate ? moment(aft.AftercareToDate.clone().format('YYYY-MM-DD')) : null;
        //console.log(proDay + " ____ " + beforeDate + "____" + afterDate);
        var check1 = beforeDate ? proDay.isAfter(beforeDate) || proDay.isSame(beforeDate) : false;
        var check2 = afterDate ? proDay.isBefore(afterDate) || proDay.isSame(afterDate) : false;
        if (check1 && check2) {
          isBetween = true;
        }
      });
    }
    return isBetween;
  }

  getAsyncGetAllCaculationRate = async (calculationId) => {
    const rateResp = await getGetAllCaculationRate(calculationId);
    return rateResp.lstRet;
  }

  onAddAssistanceAtOperationItem = () => {
    const { data } = this.state;
    const { lstServiceItems, lstAssistanceAtOperationItems, lstMZ900Items, amaCaculationList } = ServiceEstimateStore;
    console.log(lstAssistanceAtOperationItems);
    var newArr = [];
    var countIdx = 1;
    if (!lstAssistanceAtOperationItems || lstAssistanceAtOperationItems.length === 0) {
      lstServiceItems.forEach(async(el) => {
        if (
          el.amaItem &&
          el.amaItem.AMAFee &&
          el.amaItem.AMAFee.ServiceType &&
          el.amaItem.AMAFee.ServiceType === 'Surgical Operations'
        ) {
          var calObj = amaCaculationList.find(i => i.Id ===  el.amaItem.CalculationId);
          const rateResp = await getGetAllCaculationRate(el.amaItem.CalculationId);
          newArr.push({
            idx: countIdx,
            EstimateId: data.Id,
            AMACodeId: el.amaItem.AMAFee.Id,
            AMAFee: el.amaItem.AMAFee,
            AdjustedFee: el.amaItem.AMAFee.Assist ? el.amaItem.AMAFee.Fee * (el.amaItem.IsManualRate ? el.amaItem.ManualRateValue : el.amaItem.PercentageApplied)/100 * 0.2 : 0,//el.amaItem.AdjustedFee,
            AssistanceRequired: el.amaItem.AMAFee.Assist ? true : false,
            DateOfService: el.DateOfService,
            ScheduledFee: el.amaItem.AMAFee.Fee,
            CalculationName: calObj ? calObj.CalculationName : '',
            CalculationId: el.amaItem.CalculationId,
            arrPerOptions: rateResp.lstRet,
            IsManualRate: el.amaItem.IsManualRate,
            RateValue: el.amaItem.AMAFee.Assist ? el.amaItem.IsManualRate ? el.amaItem.ManualRateValue : el.amaItem.PercentageApplied : 0
          });
          countIdx++;
          ServiceEstimateStore.setListItem('assistanceAtOperation', newArr.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
          this.processMZ900();
        }
      });
    } else {
      lstServiceItems.forEach(async(el) => {
        if (
          el.amaItem &&
          el.amaItem.AMAFee &&
          el.amaItem.AMAFee.ServiceType &&
          el.amaItem.AMAFee.ServiceType === 'Surgical Operations'
        ) {
          var findAMACode = lstAssistanceAtOperationItems.find(i => i.AMAFee.AMACode === el.amaItem.AMAFee.AMACode);
          if (findAMACode) {
            var calObj = amaCaculationList.find(i => i.Id ===  findAMACode.CalculationId);
            newArr.push({
              idx: countIdx,
              EstimateId: data.Id,
              AMACodeId: findAMACode.AMACodeId,
              AMAFee: findAMACode.AMAFee,
              AdjustedFee: !findAMACode.AssistanceRequired ? 0 : findAMACode.AdjustedFee,
              AssistanceRequired: findAMACode.AssistanceRequired,
              DateOfService: findAMACode.DateOfService,
              ScheduledFee: findAMACode.ScheduledFee,
              CalculationId: findAMACode.CalculationId,
              CalculationName: findAMACode.CalculationName,
              arrPerOptions: findAMACode.arrPerOptions,
              IsManualRate: findAMACode.IsManualRate,
              RateValue: findAMACode.RateValue,
            });
          } else {
            var calObj = amaCaculationList.find(i => i.Id ===  el.amaItem.CalculationId);
            const rateResp = await getGetAllCaculationRate(el.amaItem.CalculationId);
            newArr.push({
              idx: countIdx,
              EstimateId: data.Id,
              AMACodeId: el.amaItem.AMAFee.Id,
              AMAFee: el.amaItem.AMAFee,
              AdjustedFee: el.amaItem.AMAFee.Assist ? el.amaItem.AMAFee.Fee * (el.amaItem.IsManualRate ? el.amaItem.ManualRateValue : el.amaItem.PercentageApplied)/100 * 0.2 : 0,//el.amaItem.AdjustedFee,
              AssistanceRequired: el.amaItem.AMAFee.Assist ? true : false,
              DateOfService: el.DateOfService,
              ScheduledFee: el.amaItem.AMAFee.Fee,
              CalculationId: el.amaItem.CalculationId,
              CalculationName: calObj ? calObj.CalculationName : '',
              arrPerOptions: rateResp.lstRet,
              IsManualRate: el.amaItem.IsManualRate,
              RateValue: el.amaItem.AMAFee.Assist ? el.amaItem.IsManualRate ? el.amaItem.ManualRateValue : el.amaItem.PercentageApplied : 0
            });
          }
          countIdx++;
          ServiceEstimateStore.setListItem('assistanceAtOperation', newArr.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
          this.processMZ900();
        }
      });
    }
  };

  @action processMZ900 = () => {
    const { data } = this.state;
    const { lstServiceItems, lstAssistanceAtOperationItems, lstMZ900Items } = ServiceEstimateStore;
    var showMZ900 = this.checkShowMZ900(lstAssistanceAtOperationItems);

    ServiceEstimateStore.setListItem('showMZ900', showMZ900);
    if (lstAssistanceAtOperationItems.length > 0 && showMZ900) {
      if (!lstMZ900Items || lstMZ900Items.length === 0) {
        ServiceEstimateStore.setListItem('mz900', [
          {
            EstimateId: data.Id,
            AMACodeId: 999,
            AMAFee: { AMACode: 'MZ900', MBSCode: 'na' },
            RateType: null,
            AdjustedFee: null,
            InvoicedFee: null,
            ExpectedFee: null,
          },
        ]);
      } else {
        var totalAdjustedFee = 0;
        lstAssistanceAtOperationItems.forEach(el => {
          totalAdjustedFee =
            el.AdjustedFee && el.AssistanceRequired ? totalAdjustedFee + el.AdjustedFee : totalAdjustedFee + 0;
        });
        var mz900AdjustedFee = totalAdjustedFee;// ? this.roundNumber((totalAdjustedFee * 20) / 100, 2) : 0;
        let dateOfService = lstServiceItems[0].DateOfService;
        let minAdjustedFee = this.getMZ900Rate(lstMZ900Items[0].RateType, dateOfService);
        if (mz900AdjustedFee < minAdjustedFee) {
          lstMZ900Items[0].AdjustedFee = this.roundNumber(minAdjustedFee, 2);
        } else {
          lstMZ900Items[0].AdjustedFee = mz900AdjustedFee;
        }
      }
    } else {
      ServiceEstimateStore.setListItem('mz900', []);
    }
    this.calculationExpectedFee();
    this.calculationFeeAndAjustedFee();
  }

  checkShowMZ900(lstAssistanceAtOperationItems) {
    var check = false;
    if (lstAssistanceAtOperationItems && lstAssistanceAtOperationItems.length > 0) {
      lstAssistanceAtOperationItems.forEach(el => {
        if (el.AssistanceRequired && !check) {
          check = true;
        }
      });
    }
    return check;
  }

  createDateRemoveTime = date => {
    const datearr = date.split('/');
    const d = new Date(datearr[2], Number(datearr[1]) - 1, datearr[0]);
    return d;
  };

  getMZ900Rate = (value, dateOfService) => {
    //Get current rate in setting
    const { lstMZ900Calculations } = ServiceEstimateStore;
    let mzValue = 0;
    lstMZ900Calculations.forEach(el => {
      let effectiveFrom = this.convertDate(el.EffectiveFrom)?.format('DD/MM/YYYY');
      let effectiveTo = this.convertDate(el.EffectiveTo)?.format('DD/MM/YYYY');
      let currentDate = this.createDateRemoveTime(
        dateOfService ? dateOfService.format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
      );
      let effectiveFrom_ = null;
      if (!!effectiveFrom) effectiveFrom_ = this.createDateRemoveTime(effectiveFrom);
      let effectiveTo_ = null;
      if (!!effectiveTo) effectiveTo_ = this.createDateRemoveTime(effectiveTo);

      if (value == 1 && el.Type === 'AMA') {
        if (
          !!effectiveFrom_ &&
          !!effectiveTo_ &&
          effectiveFrom_.getTime() <= currentDate.getTime() &&
          currentDate.getTime() <= effectiveTo_.getTime()
        ) {
          mzValue = el.Rate;
        } else if (!effectiveFrom_ && !!effectiveTo_ && effectiveTo_.getTime() >= currentDate.getTime()) {
          mzValue = el.Rate;
        } else if (!!effectiveFrom_ && !effectiveTo_ && effectiveFrom_.getTime() <= currentDate.getTime()) {
          mzValue = el.Rate;
        }
      } else if (value == 2 && el.Type === 'SIRA') {
        if (
          !!effectiveFrom_ &&
          !!effectiveTo_ &&
          effectiveFrom_.getTime() <= currentDate.getTime() &&
          currentDate.getTime() <= effectiveTo_.getTime()
        ) {
          mzValue = el.Rate;
        } else if (!effectiveFrom_ && !!effectiveTo_ && effectiveTo_.getTime() >= currentDate.getTime()) {
          mzValue = el.Rate;
        } else if (!!effectiveFrom_ && !effectiveTo_ && effectiveFrom_.getTime() <= currentDate.getTime()) {
          mzValue = el.Rate;
        }
      }
    });
    return mzValue;
  };

  //MZ900 Cal
  @action handleChangeAssistanceAtOperationRate = () =>
    action(event => {
      const value = event;
      var totalAdjustedFee = 0;
      const {
        lstServiceItems,
        lstAssistanceAtOperationItems,
        lstMZ900Items,
        lstMZ900Calculations,
      } = ServiceEstimateStore;
      var showMZ900 = this.checkShowMZ900(lstAssistanceAtOperationItems);
      let dateOfService = lstServiceItems[0].DateOfService;
      //console.log(dateOfService);
      ServiceEstimateStore.showMZ900 = showMZ900;
      if (lstAssistanceAtOperationItems && lstAssistanceAtOperationItems.length > 0 && showMZ900) {
        lstAssistanceAtOperationItems.forEach(el => {
          if (el.AssistanceRequired) {
            totalAdjustedFee = el.AdjustedFee ? totalAdjustedFee + el.AdjustedFee : totalAdjustedFee + 0;
          }
        });
        var mz900AdjustedFee = totalAdjustedFee;// ? this.roundNumber((totalAdjustedFee * 20) / 100, 2) : 0;
        let minAdjustedFee = this.getMZ900Rate(value, dateOfService);
        //console.log(mz900AdjustedFee)
        lstMZ900Items[0].RateType = value;
        if (mz900AdjustedFee < minAdjustedFee) {
          lstMZ900Items[0].AdjustedFee = this.roundNumber(minAdjustedFee, 2);
        } else {
          lstMZ900Items[0].AdjustedFee = mz900AdjustedFee;
        }
        this.calculationExpectedFee();
      }
    });

  @action calculationExpectedFee = () => {
    const {
      lstServiceItems,
      lstAssistanceAtOperationItems,
      lstMZ900Items,
      lstMZ900Calculations,
    } = ServiceEstimateStore;
    let expectedFee = 0;
    let invoiceFee = 0;
    if (!!lstMZ900Items.length) {
      lstAssistanceAtOperationItems.forEach(el => {
        if (el.AssistanceRequired) {
          lstServiceItems.forEach(svItem => {
            if (el.AMACodeId === svItem.amaItem.AMAFeeId) {
              invoiceFee += svItem.amaItem.InvoicedFee;
            }
          });
        }
        expectedFee = invoiceFee && invoiceFee > 0 ? this.roundNumber((invoiceFee * 20) / 100, 2) : 0;
      });
      let rateType = lstMZ900Items[0].RateType;
      let minExpectedFeeMZ900 = 0;
      if (lstServiceItems.length && !!rateType) {
        let dateOfService = lstServiceItems[0].DateOfService;
        if (!!dateOfService && lstMZ900Calculations.length) {
          minExpectedFeeMZ900 = this.getMZ900Rate(rateType, dateOfService);
        }
      }
      if (expectedFee < minExpectedFeeMZ900) {
        lstMZ900Items[0].ExpectedFee = this.roundNumber(minExpectedFeeMZ900, 2);
      } else {
        lstMZ900Items[0].ExpectedFee = expectedFee;
      }
    }
  };

  renderClassHighlightMZ900 = () => {
    const { lstMZ900Items } = ServiceEstimateStore;
    if (lstMZ900Items.length > 0) {
      let mz900Item = lstMZ900Items[0];
      if (mz900Item.InvoicedFee !== mz900Item.ExpectedFee || mz900Item.InvoicedFee < mz900Item.AdjustedFee) {
        return 'negative';
      }
    }
    return '';
  };

  @action onApplyAAOCalculationRule = () => {
    //console.log(123445);
    var listItems = [...toJS(ServiceEstimateStore.lstAssistanceAtOperationItems)];
    var arrSort = [];
    var arrSort1 = [];
    listItems.forEach(el => {
      if (el.AssistanceRequired && el.DateOfService && !el.IsManualRate) {
        //el.AMACodeFee = el.ScheduledFee;
        var fItem = arrSort.find(
          i => i.DateOfService.format('DD/MM/YYYY') === el.DateOfService.format('DD/MM/YYYY') &&
            i.CalculationId === el.CalculationId && el.IsManualRate === false,
        );
        if (!fItem) {
          arrSort.push({
            DateOfService: el.DateOfService,
            CalculationId: el.CalculationId,
            Items: [el],
          });
        } else {
          fItem.Items.push(el);
        }
      } else if (el.AssistanceRequired && !el.DateOfService && !el.IsManualRate) {
        //el.AMACodeFee = el.ScheduledFee;
        var fItem1 = arrSort1.find(
          i => !i.DateOfService && i.CalculationId === el.CalculationId && el.IsManualRate === false,
        );
        if (!fItem1) {
          arrSort1.push({
            DateOfService: el.DateOfService,
            CalculationId: el.CalculationId,
            Items: [el],
          });
        } else {
          fItem1.Items.push(el);
        }
      }
    });

    arrSort.forEach(el => {
      var isSort = false;
      el.Items = [
        ...el.Items.sort((a, b) => (a.ScheduledFee > b.ScheduledFee ? 1 : b.ScheduledFee > a.ScheduledFee ? -1 : 0)).reverse(),
      ];
      //console.log(el.Items);
      if (el.CalculationId && el.CalculationId > 0) {
        var arrOptionSoft = [];
        if (!isSort) {
          arrOptionSoft = [
            ...el.Items[0].arrPerOptions.sort((a, b) => (a.Rate > b.Rate ? 1 : b.Rate > a.Rate ? -1 : 0)).reverse(),
          ];
          isSort = true;
        }
        var countIdxRate = 0;
        el.Items.forEach(i => {
          i.RateValue = arrOptionSoft[countIdxRate].Rate;
          if (countIdxRate < arrOptionSoft.length - 1) {
            countIdxRate++;
          }
        });
      }
    });
    arrSort1.forEach(el => {
      var isSort1 = false;
      el.Items = [
        ...el.Items.sort((a, b) => (a.ScheduledFee > b.ScheduledFee ? 1 : b.ScheduledFee > a.ScheduledFee ? -1 : 0)).reverse(),
      ];
      //console.log(el.Items);
      if (el.CalculationId && el.CalculationId > 0) {
        var arrOptionSoft = [];
        if (!isSort1) {
          arrOptionSoft = [
            ...el.Items[0].arrPerOptions.sort((a, b) => (a.Rate > b.Rate ? 1 : b.Rate > a.Rate ? -1 : 0)).reverse(),
          ];
          isSort1 = true;
        }
        var countIdxRate = 0;
        el.Items.forEach(i => {
          i.RateValue = arrOptionSoft[countIdxRate].Rate;
          if (countIdxRate < arrOptionSoft.length - 1) {
            countIdxRate++;
          }
        });
      }
    });
    listItems.forEach(el => {
      arrSort.forEach(sR => {
        sR.Items.forEach(rI => {
          if (el.idx === rI.idx) {
            el.RateValue = rI.RateValue;
            if (el.IsManualRate) {
              el.AdjustedFee = this.roundNumber((rI.ScheduledFee * rI.ManualRateValue) / 100 * 0.2, 2);
            } else {
              el.AdjustedFee = this.roundNumber(
                (rI.ScheduledFee * rI.RateValue) / 100 * 0.2,
                2,
              );
            }
          }
        });
      });
      arrSort1.forEach(sR => {
        sR.Items.forEach(rI => {
          if (el.idx === rI.idx) {
            el.RateValue = rI.RateValue;
            if (el.IsManualRate) {
              el.AdjustedFee = this.roundNumber((rI.ScheduledFee * rI.ManualRateValue) / 100 * 0.2, 2);
            } else {
              el.AdjustedFee = this.roundNumber(
                (rI.ScheduledFee * rI.RateValue) / 100 * 0.2,
                2,
              );
            }
          }
        });
      });
    });
    ServiceEstimateStore.setListItem('assistanceAtOperation', listItems);
    this.processMZ900();
    this.calculationFeeAndAjustedFee();
  }

  @action handleAaOPercentageApplied = idx =>
    action(event => {
      const value = event;
      const { lstAssistanceAtOperationItems, lstServiceItems, lstMZ900Items } = ServiceEstimateStore;
      var newArr = lstAssistanceAtOperationItems;
      newArr.forEach(el => {
        if (el.idx === idx) {
          el.RateValue = value;
          el.AdjustedFee = el.AssistanceRequired ? el.ScheduledFee * el.RateValue/100 * 0.2 : 0;
        }
      });
      ServiceEstimateStore.setListItem('assistanceAtOperation', newArr.sort((a, b) => (a.idx > b.idx ? 1 : b.idx > a.idx ? -1 : 0)));
      var totalAdjustedFee = 0;
      if (lstAssistanceAtOperationItems && lstAssistanceAtOperationItems.length > 0) {
        lstAssistanceAtOperationItems.forEach(el => {
          if (el.AssistanceRequired) {
            totalAdjustedFee = el.AdjustedFee ? totalAdjustedFee + el.AdjustedFee : totalAdjustedFee + 0;
          }
        });
        var mz900AdjustedFee = totalAdjustedFee;// ? this.roundNumber((totalAdjustedFee * 20) / 100, 2) : 0;
        let minAdjustedFee = this.getMZ900Rate(value, lstServiceItems[0].DateOfService);
        if (mz900AdjustedFee < minAdjustedFee) {
          lstMZ900Items[0].AdjustedFee = this.roundNumber(minAdjustedFee, 2);
        } else {
          lstMZ900Items[0].AdjustedFee = mz900AdjustedFee;
        }
        this.calculationExpectedFee();
      }
      this.calculationFeeAndAjustedFee();
    });

  render() {
    //const { record, id } = ServiceEstimateStore.modalParams;
    const {
      open,
      lstServiceItemOptions,
      lstServiceItems,
      amaCaculationList,
      amaSelectionList,
      amaAnaesthesiaSelectionList,
      mbsSelectionList,
      amaProfessionalAttendanceList,
      mbsProfessionalAttendanceList,
      lstBodyPartsOptions,
      lstTypeOfWokOptions,
      lstClientOptions,
      lstCMOptions,
      lstSpecialtiesOptions,
      isShowOtherBodyPart,
      lstAftercareRules,
      lstAftercareItems,
      lstAnaesthesiaItems,
      lstTimeUnits,
      lstModifyingUnits,
      lstProfessionalAttendanceItems,
      lstCheckTypes,
      lstEstimateLink,
      lstAssistanceAtOperationItems,
      showMZ900,
      lstMZ900Items,
      lstAMASpecific,
      lstM4Items,
      lstTimeItems,
      lstState
    } = ServiceEstimateStore;
    const { data, loading } = this.state;
    return (
      <Modal
        width={'85%'}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            {/* <Button disabled={!id} color="blue" onClick={() => this.handlePrint(id)}>
              Print
            </Button> */}
            <Button color="blue" onClick={() => this.handlConfirmBeforeSave(false)}>
              Save
            </Button>
            <Button color="blue" onClick={() => this.handlConfirmBeforeSave(true)}>
              Save & Print
            </Button>
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!data || !data.Id ? 'Run New Check' : 'Estimate Check No: ' + data.EstimateNo}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div className="form-container">
            <Form name="conversation" className="grid-style estimate-form">
              
              <Form.Item
                label="Company"
                // required
                // validateStatus={!data.ClientId ? 'error' : null}
                // help={!data.ClientId ? 'This field is required' : ''}
              >
                <Select
                  size="large"
                  disabled={api.isCaseManager()}
                  key={'select-client-item'}
                  value={data.ClientId}
                  onChange={this.handleFieldChange('ClientId')}
                  onSearch={this.handleSearchClient()}
                  showSearch
                  filterOption="children"
                >
                  {data.Client && !lstClientOptions.find(({ Id }) => Id === data.ClientId) && (
                    <Select.Option style={{ display: 'none' }} value={data.ClientId} key="selected-client-id">
                      {data.Client.Id} - {data.Client.Name}
                    </Select.Option>
                  )}
                  {lstClientOptions?.map(i => (
                    <Select.Option value={i.Id} key={'client-' + i.Id}>
                      {i.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Case Manager"
                // required
                // validateStatus={!data.CaseManagerId ? 'error' : null}
                // help={!data.CaseManagerId ? 'This field is required' : ''}
              >
                <Select
                  size="large"
                  disabled={api.isCaseManager()}
                  value={data.CaseManagerId}
                  onChange={this.handleFieldChange('CaseManagerId')}
                  showSearch
                  filterOption="children"
                >
                  {data.CaseManager && !lstCMOptions.find(({ Id }) => Id === data.CaseManagerId) && (
                    <Select.Option style={{ display: 'none' }} value={data.CaseManagerId}>
                      {data.CaseManager.Id} - {data.CaseManager.FullName}
                    </Select.Option>
                  )}
                  {lstCMOptions?.map(i => (
                    <Select.Option value={i.Id} key={'casemanager-' + i.Id}>
                      {i.FullName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="State/Jurisdiction"
                required
                validateStatus={!data.State ? 'error' : null}
                help={!data.State ? 'This field is required' : ''}
              >
                <Select
                  size="large"
                  key={'select-state-item'}
                  value={data.State}
                  onChange={this.handleFieldChange('State')}
                  filterOption="children"
                >
                  {lstState?.map(i => (
                    <Select.Option value={i} key={'state-' + i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Check Type">
                <Select
                  size="large"
                  value={data.CheckTypeId}
                  onChange={this.handleFieldChange('CheckTypeId')}
                  showSearch
                  filterOption="children"
                >
                  {data.CheckType && !lstCheckTypes.find(({ Id }) => Id === data.CheckTypeId) && (
                    <Select.Option style={{ display: 'none' }} value={data.CheckTypeId}>
                      {data.CheckType.Id} - {data.CheckType.Label}
                    </Select.Option>
                  )}
                  {lstCheckTypes?.map(i => (
                    <Select.Option value={i.Id} key={'checkType-' + i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Date of Invoice/Request">
                <DatePicker size="large"
                  value={this.convertDate(data.DateOfInvoice)}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  onChange={this.handleChecked('DateOfInvoice')}
                />
              </Form.Item>
              <Form.Item label="Default Date of Service">
                <DatePicker
                    size="large"
                    allowClear={false}
                    format="DD/MM/YYYY"
                    value={renderDate(data.DefaultDateOfService, true, false)}
                    onChange={this.handleChecked('DefaultDateOfService')}
                  />
              </Form.Item>
              {/* <Form.Item label="Link to Quote/Invoice Check">
                <Select size="large"
                    key="select-linkEst"
                    value={data.LinkToEstimateId}
                    onChange={this.handleFieldChange('LinkToEstimateId')}
                    showSearch
                    optionFilterProp="children"
                    allowClear
                  >
                    {lstEstimateLink &&
                      data.LinkToEstimateId &&
                      !lstEstimateLink.find(i => i.Id === data.LinkToEstimateId) && (
                        <Select.Option
                          style={{ display: 'none' }}
                          value={data.LinkToEstimateId}
                          key="linkEst-default-id"
                        >
                          {data.LinkToEstimate ? data.LinkToEstimate.EstimateNo : ''}
                        </Select.Option>
                      )}
                    {lstEstimateLink &&
                      lstEstimateLink.map(i => (
                        <Select.Option value={i.Id} key={'linkEst-' + i.EstimateNo}>
                          {i.EstimateNo}
                        </Select.Option>
                      ))}
                  </Select>
              </Form.Item>
              <Form.Item label="Invoice No">
                <Input size="large" type="text" value={data.InvoiceNo} onChange={this.handleFieldChange('InvoiceNo')} />
              </Form.Item> */}
              
              {false && <Form.Item className="fluid-field">
                <table className="ui celled sortable striped table">
                  <thead className="">
                    <tr>
                      <th style={{ width: '235px' }} className="cursor-default">
                        <span>Link to Quote/Invoice Check</span>
                        {/* {' '}<Checkbox onChange={() => this.onChecked('IsLinkToEst')} checked={data.IsLinkToEst} /> */}
                        {/* {data.IsManual && <span>Link to Case</span>}
                        {!data.IsManual && <span>Link to Case</span>}{' '}
                        <Checkbox onChange={this.onChecked('IsManual')} checked={data.IsManual} /> */}
                      </th>
                      <th style={{ width: '150px' }} className="cursor-default">
                        Invoice No
                      </th>
                      {/* <th style={{ width: '150px' }} className="cursor-default">
                        Date of Invoice/Request
                      </th> */}
                      <th style={{ width: '150px' }} className="cursor-default">
                        Claim Number
                      </th>
                      <th style={{ width: '150px' }} className="cursor-default">
                        Date of Injury{/* <span style={{ color: 'red' }}>*</span> Date of Injury */}
                      </th>
                      <th style={{ width: '150px' }} className="cursor-default">
                        Claimant DOB {/* <span style={{ color: 'red' }}>*</span> Claimant DOB */}
                      </th>
                      <th style={{ width: '180px' }} className="cursor-default">
                        Claimant Gender{/* <span style={{ color: 'red' }}>*</span> Claimant Gender */}
                      </th>
                      <th style={{ width: '200px' }} className="cursor-default">
                        Requesting Surgeon
                      </th>
                      <th className="cursor-default">Specialty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {data.IsManual && (
                          <div>
                            <span style={{ display: 'inline-flex', margin: '6px 0' }}>Service Type</span>
                          </div>
                        )}
                        {data.IsManual && (
                          <Select
                            key="select-sv-type"
                            // disabled={data.Id > 0}
                            value={data.ServiceType}
                            onChange={this.handleFieldChange('ServiceType')}
                            showArrow
                            optionFilterProp="children"
                            allowClear
                          >
                            {optionsServiceType.map(i => (
                              <Select.Option value={i.value} key={'svtype-' + i.value}>
                                {i.text}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {/* {data.IsLinkToEst && ( */}
                        <Select
                          key="select-linkEst"
                          value={data.LinkToEstimateId}
                          onChange={this.handleFieldChange('LinkToEstimateId')}
                          //onSearch={this.handleSearchEstimate()}
                          showSearch
                          optionFilterProp="children"
                          allowClear
                        >
                          {lstEstimateLink &&
                            data.LinkToEstimateId &&
                            !lstEstimateLink.find(i => i.Id === data.LinkToEstimateId) && (
                              <Select.Option
                                style={{ display: 'none' }}
                                value={data.LinkToEstimateId}
                                key="linkEst-default-id"
                              >
                                {data.LinkToEstimate ? data.LinkToEstimate.EstimateNo : ''}
                              </Select.Option>
                            )}
                          {lstEstimateLink &&
                            lstEstimateLink.map(i => (
                              <Select.Option value={i.Id} key={'linkEst-' + i.EstimateNo}>
                                {i.EstimateNo}
                              </Select.Option>
                            ))}
                        </Select>
                        {/* )} */}
                      </td>
                      <td>
                        <Input type="text" value={data.InvoiceNo} onChange={this.handleFieldChange('InvoiceNo')} />
                      </td>
                      {/* <td>
                        <DatePicker
                          value={this.convertDate(data.DateOfInvoice)}
                          format="DD/MM/YYYY"
                          allowClear={false}
                          onChange={this.handleChecked('DateOfInvoice')}
                        />
                      </td> */}
                      <td>
                        {data.IsManual && <span>{data.ClaimNumber}</span>}
                        {!data.IsManual && (
                          <Input
                            type="text"
                            value={data.ManualClaimNumber}
                            onChange={this.handleFieldChange('ManualClaimNumber')}
                          />
                        )}
                      </td>
                      <td>
                        {data.IsManual && (
                          <span>
                            {data.DateOfInjury
                              ? moment(formatDate(data.DateOfInjury), 'DD MMM YYYY').format('DD/MM/YYYY')
                              : 'N/A'}
                          </span>
                        )}
                        {!data.IsManual && (
                          <DatePicker
                            allowClear={false}
                            value={this.convertDate(data.ManualDateOfInjury)}
                            format="DD/MM/YYYY"
                            onChange={this.handleChecked('ManualDateOfInjury')}
                          />
                        )}
                      </td>
                      <td>
                        {data.IsManual && (
                          <span>
                            {data.ClaimantDOB
                              ? moment(formatDate(data.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY')
                              : 'N/A'}
                          </span>
                        )}
                        {!data.IsManual && (
                          <DatePicker
                            value={this.convertDate(data.ManualClaimantDOB)}
                            format="DD/MM/YYYY"
                            allowClear={false}
                            onChange={this.handleChecked('ManualClaimantDOB')}
                          />
                        )}
                      </td>
                      <td>
                        {/* {data.IsManual && <span>{data.ClaimantGender}</span>} */}
                        <Select
                          key="select-gender"
                          value={data.ManualClaimantGender}
                          onChange={this.handleFieldChange('ManualClaimantGender')}
                          showArrow
                          optionFilterProp="children"
                        >
                          {optionsGender.map(i => (
                            <Select.Option value={i.value} key={'gender' + i.value}>
                              {i.text}
                            </Select.Option>
                          ))}
                        </Select>
                      </td>
                      <td>
                        {data.IsManual && <span>{data.AssessingSpecialist}</span>}
                        {!data.IsManual && (
                          <Input
                            type="text"
                            value={data.ManualAssessingSpecialist}
                            onChange={this.handleFieldChange('ManualAssessingSpecialist')}
                          />
                        )}
                      </td>
                      <td>
                        {data.IsManual && <span>{data.SpecialistSpecialty}</span>}
                        {!data.IsManual && (
                          <Select
                            size="large"
                            mode="multiple"
                            value={toJS(data.ManualSpecialistSpecialty)}
                            onChange={this.handleFieldChange('ManualSpecialistSpecialty')}
                            showArrow
                            optionFilterProp="children"
                          >
                            {lstSpecialtiesOptions.map(i => (
                              <Select.Option value={i.Id} key={'specialty' + i.Label}>
                                {i.Label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {/* <Input type="text" value={data.ManualSpecialistSpecialty} onChange={this.handleFieldChange('ManualSpecialistSpecialty')}/>} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form.Item>}
              {false && <Form.Item
                label="Type Of Work"
                // required
                // validateStatus={!data.TypeOfWork ? 'error' : null}
                // help={!data.TypeOfWork ? 'This field is required' : ''}
              >
                <Select
                  size="large"
                  value={data.TypeOfWork}
                  onChange={this.handleFieldChange('TypeOfWork')}
                  showArrow
                  optionFilterProp="children"
                >
                  {lstTypeOfWokOptions.map(i => (
                    <Select.Option value={i.Id} key={'tfw-' + i.Label}>
                      {i.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>}
              {false && <Form.Item
                label="Injured Body Parts"
                // required
                // validateStatus={data.InjuryBodyParts?.length === 0 || !data.InjuryBodyParts ? 'error' : null}
                // help={data.InjuryBodyParts?.length === 0 || !data.InjuryBodyParts ? 'This field is required' : ''}
              >
                <Select
                  size="large"
                  mode="multiple"
                  value={toJS(data.InjuryBodyParts)}
                  onChange={this.handleFieldChange('InjuryBodyParts')}
                  showArrow
                  optionFilterProp="children"
                >
                  {lstBodyPartsOptions.map(i => (
                    <Select.Option value={i.Id} key={'bp-' + i.Label}>
                      {i.Label}
                    </Select.Option>
                  ))}
                </Select>
                {isShowOtherBodyPart && (
                  <Input
                    style={{ marginTop: '3px' }}
                    size="large"
                    type="text"
                    placeholder="Other body part"
                    value={data.OtherBodyPart}
                    onChange={this.handleFieldChange('OtherBodyPart')}
                  />
                )}
              </Form.Item>}
              {false && <Form.Item className="fluid-field attach-contact-list" label="Attach Doctor Invoice">
                <div className="add-file-container-marketing-campaign">
                  <div style={{ marginTop: '10px' }}>
                    <DragDropFile
                      multiple={true}
                      onUploadFile={this.dragdropUploadFile}
                      onDeleteFile={this.dragdropDeleteFile}
                      fileList={this.state.data.Attachments?.filter(i => i.AttachmentType === 1)}
                      type="1"
                      title="Attachment"
                      maxSize="50"
                    />
                    <hr></hr>
                  </div>
                </div>
                <br />
              </Form.Item>}
              <Form.Item>
                <div style={{ width: '40%', float: 'left' }}>
                  <div style={{ width: '100%', textAlign: 'left' }} className="ant-col ant-form-item-label">
                    <label className="ant-form-item-required" title="Checking Date">
                      Checking Date
                    </label>
                  </div>
                  <div style={{ width: '60%', float: 'left' }}>
                    <DatePicker disabled={!api.isAdminOrMagStaffOrAccounting()}
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      value={renderDate(data.StartDate, true, false)}
                      onChange={this.handleChecked('StartDate')}
                    />
                  </div>
                  <div style={{ width: '40%', float: 'left' }}>
                    <TimePicker disabled={!api.isAdminOrMagStaffOrAccounting()}
                      style={{ width: '100%' }}
                      format="HH:mm"
                      showTime
                      size="large"
                      allowClear={false}
                      value={renderDate(data.StartDate ? moment(data.StartDate) : null, true, true)}
                      onChange={this.handleChecked('DateFromHour')}
                    />
                  </div>
                </div>
                <div style={{ width: '40%', float: 'left', marginLeft: '2%' }}>
                  <div style={{ width: '100%', textAlign: 'left' }} className="ant-col ant-form-item-label">
                    <label title="Last Save">Last Save</label>
                  </div>
                  <div style={{ width: '60%', float: 'left' }}>
                    <DatePicker disabled={!api.isAdminOrMagStaffOrAccounting()}
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      value={renderDate(data.EndDate, true, false)}
                      onChange={this.handleChecked('EndDate')}
                    />
                  </div>
                  <div style={{ width: '40%', float: 'left' }}>
                    <TimePicker disabled={!api.isAdminOrMagStaffOrAccounting()}
                      style={{ width: '100%' }}
                      format="HH:mm"
                      showTime
                      size="large"
                      allowClear={false}
                      value={renderDate(data.EndDate ? moment(data.EndDate) : null, true, true)}
                      onChange={this.handleChecked('DateToHour')}
                    />
                  </div>
                </div>
                <div style={{ width: '16%', float: 'left', marginLeft: '2%' }}>
                  <div style={{ width: '100%', textAlign: 'left' }} className="ant-col ant-form-item-label">
                    <label title="Duration">Duration (hh:mm)</label>
                  </div>
                  <div>
                    <input style={{backgroundColor: "#f5f5f5"}}
                      disabled
                      className="ant-input ant-input-lg"
                      value={
                        moment(data.StartDate) && moment(data.EndDate)
                          ? moment(data.EndDate)
                              .diff(moment(data.StartDate), 'hours')
                              .toString()
                              .padStart(2, '0') +
                            ':' +
                            (moment(data.EndDate).diff(moment(data.StartDate), 'minutes') % 60)
                              .toString()
                              .padStart(2, '0')
                          : ''
                      }
                    />
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="fluid-field">
                <React.Fragment>
                  <label>
                    <h2>Select Service Types</h2>
                  </label>
                  <Select
                    disabled={!this.state.data.State}
                    className="search-sv-type"
                    mode="multiple"
                    showArrow
                    size="large"
                    optionFilterProp="children"
                    placeholder="Select service type"
                    value={toJS(this.state.data.ServiceItemType)}
                    onChange={this.handleChangeServiceItemType()}
                  >
                    {optionServiceItemTypes &&
                      optionServiceItemTypes.map(({ Id, Name }) => (
                        <Select.Option key={Id} value={Id}>
                          {Name}
                        </Select.Option>
                      ))}
                  </Select>
                </React.Fragment>
              </Form.Item>
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(1) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h2>General</h2>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                             || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                             && <th className="cursor-default">AMA Code</th>}
                          {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                             || this.state.data.State === "WA") 
                             && <th className="cursor-default">MBS Code</th>}
                          <th className="cursor-default">
                            <span style={{ color: 'red' }}>*</span> Date of Service{' '}
                          </th>
                          <th className="cursor-default">Scheduled Fee</th>
                          <th className="cursor-default">Calculation Rule</th>
                          <th className="cursor-default">Percentage Applied</th>
                          <th className="cursor-default">Adjusted Fee</th>
                          <th className="cursor-default">Invoiced Fee</th>
                          <th className="cursor-default">Exclusion Codes</th>
                          <th className="cursor-default">Clinical Indication</th>
                          <th className="cursor-default">Additional Notes</th>
                          <th style={{ width: '88.22px' }} className="cursor-default">
                            Approved
                          </th>
                          <th>
                            <Link title="Delete all" to="#" onClick={() => this.deleteAllAMAItem()}>
                              <span style={{ color: 'red' }}>X</span>
                            </Link>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstServiceItems) &&
                          toJS(lstServiceItems).map(item => {
                            return (
                              <tr key={'general-xx-item-' + item.idx} className={this.renderClassName(item.idx)}>
                                  {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                                    || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                                    && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '325px' }}
                                    key={'general-ama' + item.idx}
                                    name={item.idx}
                                    value={item.amaItem.AMAFeeId || undefined}
                                    onChange={this.handleChangeAMAFeeCode(item.idx)}
                                    onSearch={this.handleSearchAMAFeeCode('seachAMACode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaSelectionList &&
                                      item.amaItem &&
                                      item.amaItem.AMAFeeId &&
                                      !amaSelectionList.find(i => i.Id === item.amaItem.AMAFeeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.amaItem.AMAFeeId}
                                          key="general-amacode-id"
                                        >
                                          <Tooltip title={item.Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {item.amaItem.AMAFee && item.amaItem.AMAFee.AMACode
                                                ? item.amaItem.AMAFee.AMACode
                                                : ''}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      )}
                                    {amaSelectionList &&
                                      amaSelectionList.map(({ Id, AMACode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {AMACode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                  {item.amaItem.AMAFee && item.amaItem.AMAFee.ServiceType === 'Anaesthesia' ? (
                                    <span style={{ display: 'inline-block', marginTop: '3px', color: 'red' }}>
                                      Add Anaesthesia codes to Anaesthesia service section
                                    </span>
                                  ) : null}
                                </td>}
                                {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                                  || this.state.data.State === "WA") 
                                  && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '325px' }}
                                    key={'general-mbs' + item.idx}
                                    name={item.idx}
                                    value={item.amaItem.AMAFeeId || undefined}
                                    onChange={this.handleChangeMBSFeeCode(item.idx)}
                                    onSearch={this.handleSearchMBSCode('seachMBSCode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {mbsSelectionList &&
                                      item.amaItem &&
                                      item.amaItem.AMAFeeId &&
                                      !mbsSelectionList.find(i => i.Id === item.amaItem.AMAFeeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.amaItem.AMAFeeId}
                                          key="general-mbs-id"
                                        >
                                          {item.amaItem.AMAFee && item.amaItem.AMAFee.MBSCode
                                            ? item.amaItem.AMAFee.MBSCode
                                            : ''}
                                        </Select.Option>
                                      )}
                                    {mbsSelectionList &&
                                      mbsSelectionList.map(({ Id, MBSCode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {MBSCode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>}
                                <td>
                                  <DatePicker
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                    onChange={this.handleChangeDateOfService(item.idx)}
                                  />
                                </td>
                                <td>
                                  {item.amaItem.AMAFee && item.amaItem.AMAFee.Fee
                                    ? `$${this.numberWithCommas(item.amaItem.AMAFee.Fee)}`
                                    : '$0'}
                                </td>
                                <td style={{ width: '225px' }}>
                                  <Select
                                    style={{ width: '200px' }}
                                    key={'cal' + item.idx}
                                    value={item.amaItem.CalculationId}
                                    onChange={this.handleChangeAMACalculation(item.idx)}
                                    onSearch={this.handleSearchAMACalculation(item.idx)}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaCaculationList &&
                                      amaCaculationList.map(({ Id, CalculationName }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={CalculationName} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {CalculationName}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td style={{ width: '125px' }}>
                                  <div className="manual-rate">
                                    <Tooltip title={'Manually set %'} placement="top">
                                      <Checkbox
                                        onChange={this.onItemChecked(item.idx)}
                                        checked={item.amaItem.IsManualRate}
                                      ></Checkbox>
                                    </Tooltip>{' '}
                                    {item.amaItem.IsManualRate && (
                                      <InputNumber
                                        className="input-manual-rate"
                                        addonBefore="$"
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        value={item.amaItem.ManualRateValue}
                                        onChange={this.handleChangeManualRateValue(item.idx)}
                                      />
                                    )}
                                    {!item.amaItem.IsManualRate && (
                                      <Select
                                        style={{ width: '100px' }}
                                        key={'per' + item.idx}
                                        value={item.amaItem.PercentageApplied}
                                        onChange={this.handlePercentageApplied(item.idx)}
                                        filterOption="children"
                                      >
                                        {item.arrPerOptions.map(({ Id, Rate, RateGuide }) => (
                                          <Select.Option value={Rate} key={item.idx + '-' + Id} title={RateGuide}>
                                            {Rate}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {item.amaItem.AdjustedFee
                                    ? `$${this.numberWithCommas(item.amaItem.AdjustedFee)}`
                                    : '$0'}
                                </td>
                                <td>
                                  <InputNumber
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.amaItem.InvoicedFee}
                                    onChange={this.handleChangeInvoicedFee(item.idx, 'General')}
                                  />
                                </td>
                                <td>
                                  {this.renderExclusionCode(item.idx, item.amaItem.AMAFee)}
                                  {this.renderExclusionCodeRanges(item.idx, item.amaItem.AMAFee)}
                                  {/* {item.amaItem.AMAFee.ExclutionRange ?  <label className="ui label" style={{margin: "2px"}}>{item.amaItem.AMAFee.ExclutionRange}</label> : ''} */}
                                </td>
                                {/* <td>{item.amaItem.AMAFee.Description ? item.amaItem.AMAFee.Description.substring(0, 20): ''}{(item.amaItem.AMAFee.Description && item.amaItem.AMAFee.Description.length > 20) ? ' ...': ''}</td> */}
                                <td>
                                  <Tooltip title={item.amaItem.AMAFee.ClinicalIndication} placement="top">
                                    {item.amaItem.AMAFee && item.amaItem.AMAFee.ClinicalIndication
                                      ? item.amaItem.AMAFee.ClinicalIndication.substring(0, 10)
                                      : 'N/A'}
                                    {item.amaItem.AMAFee &&
                                    item.amaItem.AMAFee.ClinicalIndication &&
                                    item.amaItem.AMAFee.ClinicalIndication.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>
                                <td>
                                  <Tooltip title={item.amaItem.AMAFee.AdditionalNotes} placement="top">
                                    {item.amaItem.AMAFee && item.amaItem.AMAFee.AdditionalNotes
                                      ? item.amaItem.AMAFee.AdditionalNotes.substring(0, 10)
                                      : 'N/A'}
                                    {item.amaItem.AMAFee &&
                                    item.amaItem.AMAFee.AdditionalNotes &&
                                    item.amaItem.AMAFee.AdditionalNotes.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox
                                    onChange={this.onCheckedApproved(item.idx, 'General')}
                                    checked={item.amaItem.Approved}
                                  />
                                </td>
                                <td>
                                  <Link to="#" onClick={() => this.deleteAMAItem(item.idx)}>
                                    <span style={{ color: 'red' }}>X</span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Button color="green" onClick={this.onAddServiceItem}>
                      <Icon name="plus" /> Add Item
                    </Button>{' '}
                    <Button color="green" onClick={this.onApplyCalculationRule}>
                      <Icon name="refresh" /> Apply Calculation Rules
                    </Button>
                  </React.Fragment>
                </Form.Item>
              )}
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(2) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h2>Professional Attendances</h2>
                    <h3>Professional Attendance Codes</h3>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                             || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                             && <th className="cursor-default">AMA Code</th>}
                          {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                             || this.state.data.State === "WA") 
                             && <th className="cursor-default">MBS Code</th>}
                          {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                             || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                             && <th className="cursor-default">Service Type</th>}
                          {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                             || this.state.data.State === "WA") 
                             && <th className="cursor-default">Category</th>}
                          <th className="cursor-default">
                            <span style={{ color: 'red' }}>*</span> Date of Service{' '}
                          </th>
                          <th className="cursor-default">Adjusted Fee</th>
                          <th className="cursor-default">Invoiced Fee</th>
                          <th className="cursor-default">Exclusion Codes</th>
                          <th className="cursor-default">Clinical Indication</th>
                          <th className="cursor-default">Additional Notes</th>
                          <th style={{ width: '88.22px' }} className="cursor-default">
                            Approved
                          </th>
                          <th>
                            <Link title="Delete all" to="#" onClick={() => this.deleteAllProfessionalAttendance()}>
                              <span style={{ color: 'red' }}>X</span>
                            </Link>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstProfessionalAttendanceItems) &&
                          toJS(lstProfessionalAttendanceItems).map(item => {
                            return (
                              <tr key={item.idx} className={this.renderClassNameProfessionalCheck(item.idx)}>
                                {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                                  || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                                  && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'professional' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeAMAFeeCodeProfessional(item.idx)}
                                    onSearch={this.handleSearchAMAProfessionalAttendanceFeeCode('seachAMACode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaProfessionalAttendanceList &&
                                      item.AMACodeId &&
                                      !amaProfessionalAttendanceList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="professional-amacode-id"
                                        >
                                          <Tooltip title={item.Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {item.AMAFee && item.AMAFee.AMACode ? item.AMAFee.AMACode : ''}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      )}
                                    {amaProfessionalAttendanceList &&
                                      amaProfessionalAttendanceList.map(({ Id, AMACode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {AMACode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>}
                                {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                                  || this.state.data.State === "WA") 
                                  && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '100' }}
                                    key={'professional-mbs-' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeMBSFeeCodeProfessional(item.idx)}
                                    onSearch={this.handleSearchMBSProfessionalAttendanceFeeCode('seachMBSCode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {mbsProfessionalAttendanceList &&
                                      item.AMACodeId &&
                                      !mbsProfessionalAttendanceList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="professional-mbscode-id"
                                        >
                                          {item.AMAFee && item.AMAFee.MBSCode ? item.AMAFee.MBSCode : ''}
                                        </Select.Option>
                                      )}
                                    {mbsProfessionalAttendanceList &&
                                      mbsProfessionalAttendanceList.map(({ Id, MBSCode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {MBSCode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>}
                                <td>{(this.state.data.State === "QLD" || this.state.data.State === "VIC" || this.state.data.State === "WA") ? item.AMAFee.Category : item.AMAFee.ServiceType}</td>
                                <td>
                                  <DatePicker
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                    onChange={this.handleChangeProfessionalDateOfService(item.idx)}
                                  />
                                </td>
                                <td>
                                  ${item.AMAFee ? this.numberWithCommas(item.AdjustedFee) : '$0'}
                                  {/* <InputNumber
                                  addonBefore="$"
                                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                  value={item.Adjusted}
                                  onChange={this.handleChangeAdjustedFee(item.idx, 'ProfessionalAttendance')}
                                /> */}
                                </td>
                                <td>
                                  <InputNumber
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.InvoicedFee}
                                    onChange={this.handleChangeInvoicedFee(item.idx, 'ProfessionalAttendance')}
                                  />
                                </td>
                                <td>
                                  {this.renderExclusionCode(item.idx, item.AMAFee)}
                                  {this.renderExclusionCodeRanges(item.idx, item.AMAFee)}
                                </td>
                                <td>
                                  <Tooltip title={item.AMAFee.ClinicalIndication} placement="top">
                                    {item.AMAFee && item.AMAFee.ClinicalIndication
                                      ? item.AMAFee.ClinicalIndication.substring(0, 10)
                                      : 'N/A'}
                                    {item.AMAFee &&
                                    item.AMAFee.ClinicalIndication &&
                                    item.AMAFee.ClinicalIndication.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>
                                <td>
                                  <Tooltip title={item.AMAFee.AdditionalNotes} placement="top">
                                    {item.AMAFee && item.AMAFee.AdditionalNotes
                                      ? item.AMAFee.AdditionalNotes.substring(0, 10)
                                      : 'N/A'}
                                    {item.AMAFee &&
                                    item.AMAFee.AdditionalNotes &&
                                    item.AMAFee.AdditionalNotes.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox
                                    onChange={this.onCheckedApproved(item.idx, 'ProfessionalAttendance')}
                                    checked={item.Approved}
                                  />
                                </td>
                                <td>
                                  <Link to="#" onClick={() => this.deleteProfessionalAttendance(item.idx)}>
                                    <span style={{ color: 'red' }}>X</span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Button color="green" onClick={this.onAddProfessionalAttendance}>
                      <Icon name="plus" /> Add Item
                    </Button>
                  </React.Fragment>
                </Form.Item>
              )}
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(2) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h3>Aftercare & Consultations Check</h3>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          <th className="cursor-default">AMA Code of Surgery</th>
                          <th className="cursor-default">MBS Code</th>
                          <th className="cursor-default" style={{ width: '250px' }}>
                            Date of Surgery
                          </th>
                          <th className="cursor-default">Aftercare Rule</th>
                          <th className="cursor-default">Aftercare Duration (days)</th>
                          <th className="cursor-default">Aftercare Period</th>
                          <th className="cursor-default">Notes</th>
                          {/* <th className="cursor-default">Scheduled Fee</th>
                          <th className="cursor-default">Adjusted Fee</th>
                          <th className="cursor-default" style={{width: "250px"}}>Invoiced Fee</th> */}
                          {/* <th style={{width: "88.22px"}} className="cursor-default">Approved</th> */}
                          {/* <th style={{width: "30px"}}></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstAftercareItems) &&
                          toJS(lstAftercareItems).map(item => {
                            return (
                              <tr key={'aftercare-item-' + item.idx}>
                                <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'aftercare' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeAMAFeeCodeAftercare(item.idx)}
                                    onSearch={this.handleSearchAMAFeeCode('seachAMACode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaSelectionList &&
                                      item.AMACodeId &&
                                      !amaSelectionList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="aftercare-amacode-id"
                                        >
                                          <Tooltip title={item.Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {item.AMAFee && item.AMAFee.AMACode ? item.AMAFee.AMACode : ''}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      )}
                                    {amaSelectionList &&
                                      amaSelectionList.map(({ Id, AMACode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {AMACode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                  {item.AMAFee && item.AMAFee.ServiceType === 'Professional Attendances' ? (
                                    <span style={{ display: 'inline-block', marginTop: '3px', color: 'red' }}>
                                      Professional Attendances should not be selected for Aftercare
                                    </span>
                                  ) : null}
                                </td>
                                <td style={{ width: '250px' }}>
                                  <Select
                                    style={{ width: '100' }}
                                    key={'aftercare-mbs-' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeAMAFeeCodeAftercare(item.idx)}
                                    onSearch={this.handleSearchAMAFeeCode('seachMBSCode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaSelectionList &&
                                      item.AMACodeId &&
                                      !amaSelectionList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="aftercare-mbscode-id"
                                        >
                                          {item.AMAFee && item.AMAFee.MBSCode ? item.AMAFee.MBSCode : ''}
                                        </Select.Option>
                                      )}
                                    {amaSelectionList &&
                                      amaSelectionList.map(({ Id, MBSCode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {MBSCode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td>
                                  <DatePicker
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                    onChange={this.handleChangeAftercareDateOfService(item.idx)}
                                  />
                                </td>
                                <td>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'aftercare-rule-' + item.idx}
                                    value={item.AftercareRuleId}
                                    onChange={this.handleChangeAftercareField(item.idx, 'Rule')}
                                    filterOption="children"
                                  >
                                    {lstAftercareRules &&
                                      lstAftercareRules.map(({ Id, Name, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {Name}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td>{item.AftercareRule?.Duration}</td>
                                <td>
                                  {item.DateOfService && item.AftercareToDate
                                    ? item.DateOfService.format('DD/MM/YYYY') +
                                      ' - ' +
                                      item.AftercareToDate.format('DD/MM/YYYY')
                                    : ''}
                                </td>
                                <td>
                                  <Tooltip title={item.RuleNotes} placement="top">
                                    {item.RuleNotes
                                      ? item.RuleNotes.substring(0, 10)
                                      : 'N/A'}
                                    {item.RuleNotes &&
                                    item.RuleNotes.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>
                                {/* <td>
                                ${this.numberWithCommas(item.ScheduledFee)}
                              </td>
                              <td>
                                ${this.numberWithCommas(item.AdjustedFee)}
                              </td>
                              <td>
                                <InputNumber style={{width:"100%"}}
                                  addonBefore="$"
                                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                  value={item.InvoicedFee}
                                  onChange={this.handleChangeInvoicedFee(item.idx, 'Aftercare')}
                                />
                              </td> */}
                                {/* <td style={{textAlign: "center"}}>
                                <Checkbox onChange={this.onCheckedApproved(item.idx, 'Aftercare')} checked={item.Approved} />
                              </td> */}
                                {/* <td style={{textAlign: "center"}}>
                                <Link to="#" onClick={() => this.deleteAftercareItem(item.idx)}>
                                  <span style={{color:"red"}}>X</span>
                                </Link>
                              </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* <Button color="green" onClick={this.onAddAftercareItem}>
                      <Icon name="plus" /> Add Item
                    </Button>{' '} */}
                  </React.Fragment>
                </Form.Item>
              )}

              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(3) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h2>Anaesthesia</h2>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                             || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                             && <th className="cursor-default">AMA Code</th>}
                          {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                             || this.state.data.State === "WA") 
                             && <th className="cursor-default">MBS Code</th>}
                          <th className="cursor-default" style={{ width: '250px' }}>
                            <span style={{ color: 'red' }}>*</span> Date of Service
                          </th>
                          <th className="cursor-default">Base Units</th>
                          <th className="cursor-default">Unit Rate</th>
                          <th className="cursor-default">Adjusted Fee</th>
                          <th className="cursor-default" style={{ width: '250px' }}>
                            Invoiced Fee
                          </th>
                          <th className="cursor-default">M4 Eligible</th>
                          <th className="cursor-default">Exclusion Codes</th>
                          <th className="cursor-default">Clinical Indication</th>
                          <th style={{ width: '88.22px' }} className="cursor-default">
                            Approved
                          </th>
                          <th style={{ width: '30px' }}>
                            <Link title="Delete all" to="#" onClick={() => this.deleteAllAnaesthesiaItem()}>
                              <span style={{ color: 'red' }}>X</span>
                            </Link>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstAnaesthesiaItems) &&
                          toJS(lstAnaesthesiaItems).map(item => {
                            return (
                              <tr
                                key={'anaesthesia-item-' + item.idx}
                                className={this.renderClassNameAnaesthesia(item.idx)}
                              >
                                {(this.state.data.State === "NSW" || this.state.data.State === "TAS"
                                  || this.state.data.State === "WA" || this.state.data.State === "ACT") 
                                  && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '325px' }}
                                    key={'anaesthesia' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeAMAFeeCodeAnaesthesia(item.idx)}
                                    onSearch={this.handleSearchAnaesthesiaAMAFeeCode('seachAMACode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {amaAnaesthesiaSelectionList &&
                                      item.AMACodeId &&
                                      !amaAnaesthesiaSelectionList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="anaesthesia-amacode-id"
                                        >
                                          <Tooltip title={item.Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {item.AMAFee && item.AMAFee.AMACode ? item.AMAFee.AMACode : ''}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      )}
                                    {amaAnaesthesiaSelectionList &&
                                      amaAnaesthesiaSelectionList.map(({ Id, AMACode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {AMACode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>}
                                {(this.state.data.State === "QLD" || this.state.data.State === "VIC"
                                  || this.state.data.State === "WA") 
                                  && <td style={{ width: '350px' }}>
                                  <Select
                                    style={{ width: '325px' }}
                                    key={'anaesthesia-mbs-' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                    onChange={this.handleChangeMBSFeeCodeAnaesthesia(item.idx)}
                                    onSearch={this.handleSearchMBSCode('seachMBSCode')}
                                    showSearch
                                    filterOption="children"
                                  >
                                    {mbsSelectionList &&
                                      item.AMACodeId &&
                                      !mbsSelectionList.find(i => i.Id === item.AMACodeId) && (
                                        <Select.Option
                                          style={{ display: 'none' }}
                                          value={item.AMACodeId}
                                          key="anaesthesia-mbscode-id"
                                        >
                                          {item.AMAFee && item.AMAFee.MBSCode ? item.AMAFee.MBSCode : ''}
                                        </Select.Option>
                                      )}
                                    {mbsSelectionList &&
                                      mbsSelectionList.map(({ Id, MBSCode, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {MBSCode}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>}
                                <td>
                                  <DatePicker
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                    onChange={this.handleChangeAnaesthesiaDateOfService(item.idx)}
                                  />
                                </td>
                                <td>{item.BaseUnit}</td>
                                {/* <td>
                                  <div style={{ width: '250px', float: 'left' }}>
                                    <Input disabled={item.AMAFee.AMACode !== 'Time'}
                                      style={{ width: '100%' }}
                                      type="number"
                                      value={item.Duration}
                                      onChange={this.handleChangeDuration(item.idx)}
                                    />
                                    {item.Duration &&
                                    item.Duration >
                                      Math.max.apply(
                                        null,
                                        lstTimeUnits.map(function(i) {
                                          return i.EndTime;
                                        }),
                                      ) ? (
                                      <span style={{ display: 'inline-block', marginTop: '3px', color: 'red' }}>
                                        Max{' '}
                                        {Math.max.apply(
                                          null,
                                          lstTimeUnits.map(function(i) {
                                            return i.EndTime;
                                          }),
                                        )}{' '}
                                        minutes is allowed
                                      </span>
                                    ) : null}
                                  </div>{' '}
                                </td>
                                <td>{item.TimeUnit}</td> */}
                                {/* <td>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'modifying-unit-' + item.idx}
                                    value={item.ModifyingUnitId}
                                    onChange={this.handleChangeModifyingUnit(item.idx)}
                                    filterOption="children"
                                  >
                                    {lstModifyingUnits &&
                                      lstModifyingUnits.map(({ Id, Code, Description }) => (
                                        <Select.Option value={Id} key={Id}>
                                          <Tooltip title={Description} placement="right">
                                            <span
                                              style={{
                                                width: '175',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {Code}
                                            </span>
                                          </Tooltip>
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td>{item.ModifyingUnits}</td> */}
                                <td>${this.numberWithCommas(item.UnitRate)}</td>
                                <td>${this.numberWithCommas(item.ScheduledFee)}</td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.InvoicedFee}
                                    onChange={this.handleChangeInvoicedFee(item.idx, 'Anaesthesia')}
                                  />
                                </td>
                                <td>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'m4-eligible'}
                                    value={item.M4Eligible}
                                    onChange={this.handleChangeM4Eligible(item.idx)}
                                  >
                                    {optionM4Eligible &&
                                      optionM4Eligible.map(({ Id, Name }) => (
                                        <Select.Option value={Id} key={'opt-m4-eligible-' + Id}>
                                          {Name}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td>
                                  {this.renderExclusionCode(item.idx, item.AMAFee)}
                                  {this.renderExclusionCodeRanges(item.idx, item.AMAFee)}
                                </td>
                                <td>
                                  <Tooltip title={item.AMAFee.ClinicalIndication} placement="top">
                                    {item.AMAFee && item.AMAFee.ClinicalIndication
                                      ? item.AMAFee.ClinicalIndication.substring(0, 10)
                                      : 'N/A'}
                                    {item.AMAFee &&
                                    item.AMAFee.ClinicalIndication &&
                                    item.AMAFee.ClinicalIndication.length > 10
                                      ? ' ...'
                                      : ''}
                                  </Tooltip>
                                </td>

                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox
                                    onChange={this.onCheckedApproved(item.idx, 'Anaesthesia')}
                                    checked={item.Approved}
                                  />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Link to="#" onClick={() => this.deleteAnaesthesiaItem(item.idx)}>
                                    <span style={{ color: 'red' }}>X</span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Button color="green" onClick={this.onAddAnaesthesiaItem}>
                      <Icon name="plus" /> Add Item
                    </Button>{' '}
                  </React.Fragment>
                </Form.Item>
              )}
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(3) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h3>Time</h3>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          <th className="cursor-default">AMA Code</th>
                          <th className="cursor-default">MBS Code</th>
                          <th className="cursor-default">Date of Service</th>
                          <th className="cursor-default" style={{ width: '250px' }}>
                            Duration (Max:{' '}
                            {Math.max.apply(
                              null,
                              lstTimeUnits.map(function(i) {
                                return i.EndTime;
                              }),
                            )}{' '}
                            minutes)
                          </th>
                          <th className="cursor-default">Time Unit</th>
                          <th className="cursor-default">Unit Rate</th>
                          <th className="cursor-default" style={{ width: '350px' }}>
                            Adjusted Fee
                          </th>
                          <th className="cursor-default" style={{ width: '350px' }}>
                            Invoiced Fee
                          </th>
                          <th className="cursor-default" style={{ width: '126.43px' }}>
                            M4 Eligible
                          </th>
                          <th className="cursor-default" style={{ width: '88.22px' }}>
                            Approved
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstTimeItems) &&
                          toJS(lstTimeItems).map(item => {
                            return (
                              <tr key={'m4-time-item'} className={item.Approved ? '' : 'row-lightgray'}>
                                <td style={{ width: '150px' }}>
                                  <Select
                                    style={{ width: '125px' }}
                                    key={'m4-time' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                  >
                                    <Select.Option
                                      style={{ display: 'none' }}
                                      value={item.AMACodeId}
                                      key="m4-time-amacode-id"
                                    >
                                      <Tooltip title={item.AMAFee.Description} placement="right">
                                        <span
                                          style={{
                                            width: '175',
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {item.AMAFee && item.AMAFee.AMACode ? item.AMAFee.AMACode : ''}
                                        </span>
                                      </Tooltip>
                                    </Select.Option>
                                  </Select>
                                </td>
                                <td style={{ width: '150px' }}>
                                  <Select
                                    style={{ width: '125px' }}
                                    key={'m4-time-mbs-' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                  >
                                    <Select.Option
                                      style={{ display: 'none' }}
                                      value={item.AMACodeId}
                                      key="m4-time-mbscode-id"
                                    >
                                      {item.AMAFee && item.AMAFee.MBSCode ? item.AMAFee.MBSCode : ''}
                                    </Select.Option>
                                  </Select>
                                </td>
                                <td>
                                  <DatePicker
                                    disabled={true}
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                  />
                                </td>
                                <td>
                                  <div style={{ width: '250px', float: 'left' }}>
                                    <Input
                                      disabled={item.AMAFee.AMACode !== 'Time'}
                                      style={{ width: '100%' }}
                                      type="number"
                                      value={item.Duration}
                                      onChange={this.handleChangeTimeDuration(item.idx)}
                                    />
                                    {item.Duration &&
                                    item.Duration >
                                      Math.max.apply(
                                        null,
                                        lstTimeUnits.map(function(i) {
                                          return i.EndTime;
                                        }),
                                      ) ? (
                                      <span style={{ display: 'inline-block', marginTop: '3px', color: 'red' }}>
                                        Max{' '}
                                        {Math.max.apply(
                                          null,
                                          lstTimeUnits.map(function(i) {
                                            return i.EndTime;
                                          }),
                                        )}{' '}
                                        minutes is allowed
                                      </span>
                                    ) : null}
                                  </div>{' '}
                                </td>
                                <td>{item.TimeUnit}</td>
                                <td>${this.numberWithCommas(item.UnitRate)}</td>
                                <td>${this.numberWithCommas(item.ScheduledFee)}</td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.InvoicedFee}
                                    onChange={this.handleChangeTimeItemInvoicedFee()}
                                  />
                                </td>
                                <td>
                                  <Select
                                    style={{ width: '100%' }}
                                    key={'time-eligible'}
                                    value={item.M4Eligible}
                                    onChange={this.handleChangeTimeEligible(item.idx)}
                                  >
                                    {optionM4Eligible &&
                                      optionM4Eligible.map(({ Id, Name }) => (
                                        <Select.Option value={Id} key={'opt-time-eligible-' + Id}>
                                          {Name}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox onChange={this.onTimeCheckedApproved()} checked={item.Approved} />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </React.Fragment>
                </Form.Item>
              )}
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(3) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h3>M4 Modifier</h3>
                    <table className="ui celled sortable striped table">
                      <thead className="">
                        <tr>
                          <th className="cursor-default">AMA Code</th>
                          <th className="cursor-default">MBS Code</th>
                          <th className="cursor-default">Date of Service</th>
                          <th className="cursor-default">Total Units</th>
                          <th className="cursor-default">Unit Rate</th>
                          <th className="cursor-default" style={{ width: '350px' }}>
                            Adjusted Fee
                          </th>
                          <th className="cursor-default" style={{ width: '350px' }}>
                            Invoiced Fee
                          </th>
                          <th className="cursor-default" style={{ width: '88.22px' }}>
                            Approved
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!toJS(lstM4Items) &&
                          toJS(lstM4Items).map(item => {
                            return (
                              <tr key={'m4-item'} className={item.Approved ? '' : 'row-lightgray'}>
                                <td style={{ width: '150px' }}>
                                  <Select
                                    style={{ width: '125px' }}
                                    key={'m4' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                  >
                                    <Select.Option
                                      style={{ display: 'none' }}
                                      value={item.AMACodeId}
                                      key="m4-amacode-id"
                                    >
                                      <Tooltip title={item?.AMAFee?.Description} placement="right">
                                        <span
                                          style={{
                                            width: '175',
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {item.AMAFee && item.AMAFee.AMACode ? item.AMAFee.AMACode : ''}
                                        </span>
                                      </Tooltip>
                                    </Select.Option>
                                  </Select>
                                </td>
                                <td style={{ width: '150px' }}>
                                  <Select
                                    style={{ width: '125px' }}
                                    key={'m4-mbs-' + item.idx}
                                    name={item.idx}
                                    value={item.AMACodeId || undefined}
                                  >
                                    <Select.Option
                                      style={{ display: 'none' }}
                                      value={item.AMACodeId}
                                      key="m4-mbscode-id"
                                    >
                                      {item.AMAFee && item.AMAFee.MBSCode ? item.AMAFee.MBSCode : ''}
                                    </Select.Option>
                                  </Select>
                                </td>
                                <td>
                                  <DatePicker
                                    disabled={true}
                                    value={this.convertDate(item.DateOfService)}
                                    format="DD/MM/YYYY"
                                  />
                                </td>
                                <td>{item.BaseUnit}</td>
                                <td>${this.numberWithCommas(item.UnitRate)}</td>
                                <td>${this.numberWithCommas(item.Approved ? item.ScheduledFee : 0)}</td>
                                <td>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.InvoicedFee}
                                    onChange={this.handleChangeM4ItemInvoicedFee()}
                                  />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox onChange={this.onM4CheckedApproved()} checked={item.Approved} />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </React.Fragment>
                </Form.Item>
              )}
              {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(4) && (
                <Form.Item className="fluid-field">
                  <React.Fragment>
                    <h2>Assistance at Operation</h2>
                    <div style={{ width: '100%' }}>
                      <table className="ui celled sortable striped table">
                        <thead className="">
                          <tr>
                            <th className="cursor-default">AMA Code</th>
                            <th className="cursor-default">MBS Code</th>
                            <th className="cursor-default">Date of Service</th>
                            <th className="cursor-default">Scheduled Fee</th>
                            <th className="cursor-default">MBS - Assist Allowed</th>
                            <th className="cursor-default">Calculation Rule</th>
                            <th className="cursor-default">Percentage Applied</th>
                            <th className="cursor-default">Adjusted Fee</th>
                            <th className="cursor-default">Clinical Indication</th>
                            <th className="cursor-default">Additional Notes</th>
                            <th className="cursor-default">Service Type</th>
                            <th className="cursor-default">Assistance Required</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!toJS(lstAssistanceAtOperationItems) &&
                            toJS(lstAssistanceAtOperationItems).map(item => {
                              return (
                                <tr key={'assistance-item-' + item.idx}>
                                  <td>{item.AMAFee.AMACode}</td>
                                  <td>{item.AMAFee.MBSCode}</td>
                                  
                                  <td>
                                    <DatePicker
                                      disabled={true}
                                      value={this.convertDate(item.DateOfService)}
                                      format="DD/MM/YYYY"
                                    />
                                  </td>
                                  <td>{item.ScheduledFee ? `$${this.numberWithCommas(item.ScheduledFee)}` : ''}</td>
                                  <td style={{ textAlign: 'center' }}>{item.AMAFee.Assist ? 'Yes' : 'No'}</td>
                                  <td style={{ width: '225px' }}>
                                  <Tooltip title={item.CalculationName} placement="top">
                                      {item.CalculationName ? item.CalculationName.substring(0, 20)
                                        : 'N/A'}
                                      {item.CalculationName &&
                                        item.CalculationName.length > 20
                                        ? ' ...'
                                        : ''}
                                    </Tooltip>
                                  </td>
                                  <td style={{ width: '125px' }}>
                                    {/* {item.RateValue ? `${this.numberWithCommas(item.RateValue)}` : ''} */}
                                    <Select disabled={!item.AssistanceRequired}
                                        style={{ width: '100px' }}
                                        key={'per' + item.idx}
                                        value={item.RateValue}
                                        onChange={this.handleAaOPercentageApplied(item.idx)}
                                        filterOption="children"
                                      >
                                        {item.arrPerOptions.map(({ Id, Rate, RateGuide }) => (
                                          <Select.Option value={Rate} key={item.idx + '-' + Id} title={RateGuide}>
                                            {Rate}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                  </td>
                                  <td>{item.AdjustedFee ? `$${this.numberWithCommas(item.AdjustedFee)}` : '$0'}</td>
                                  <td>
                                    <Tooltip title={item.AMAFee.ClinicalIndication} placement="top">
                                      {item.AMAFee && item.AMAFee.ClinicalIndication
                                        ? item.AMAFee.ClinicalIndication.substring(0, 10)
                                        : 'N/A'}
                                      {item.AMAFee &&
                                      item.AMAFee.ClinicalIndication &&
                                      item.AMAFee.ClinicalIndication.length > 10
                                        ? ' ...'
                                        : ''}
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <Tooltip title={item.AMAFee.AdditionalNotes} placement="top">
                                      {item.AMAFee && item.AMAFee.AdditionalNotes
                                        ? item.AMAFee.AdditionalNotes.substring(0, 10)
                                        : 'N/A'}
                                      {item.AMAFee &&
                                      item.AMAFee.AdditionalNotes &&
                                      item.AMAFee.AdditionalNotes.length > 10
                                        ? ' ...'
                                        : ''}
                                    </Tooltip>
                                  </td>
                                  <td>{item.AMAFee.ServiceType}</td>
                                  <td>
                                    <Checkbox
                                      onChange={this.onCheckedApproved(item.idx, 'AssistanceRequired')}
                                      checked={item.AssistanceRequired}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <Button color="green" onClick={this.onApplyAAOCalculationRule}>
                        <Icon name="refresh" /> Apply Calculation Rules
                      </Button>
                    </div>
                    {showMZ900 && (
                      <div style={{ width: '100%' }}>
                        <br />
                        <table className="ui celled sortable striped table">
                          <thead className="">
                            <tr>
                              <th className="cursor-default">AMA Code of Assistance at Operation</th>
                              <th className="cursor-default">MBS Code</th>
                              <th className="cursor-default">
                                <span style={{ color: 'red' }}>*</span> Rate
                              </th>
                              <th className="cursor-default">Adjusted Fee</th>
                              <th className="cursor-default">Invoiced Fee</th>
                              {/* <th className="cursor-default">
                                Expected Fee
                                <Tooltip
                                  title="Expected Fee: 20% of total invoiced amount of services requiring Assistance at Operation (AaO) or min rate of AaO"
                                  placement="right"
                                >
                                  <Icon name="info circle" />
                                </Tooltip>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {!!toJS(lstMZ900Items) &&
                              toJS(lstMZ900Items).map(item => {
                                return (
                                  <tr key={'mz900-item'} className={this.renderClassHighlightMZ900()}>
                                    <td>{item.AMAFee.AMACode}</td>
                                    <td>{item.AMAFee.MBSCode}</td>
                                    <td>
                                      <Select
                                        style={{ width: '100%' }}
                                        key={'mz900-rate'}
                                        value={item.RateType}
                                        onChange={this.handleChangeAssistanceAtOperationRate()}
                                        //filterOption="children"
                                      >
                                        {optionMZ900Rates &&
                                          optionMZ900Rates.map(({ Id, Name }) => (
                                            <Select.Option value={Id} key={'opt-mz900-rate-' + Id}>
                                              {Name}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </td>
                                    <td>{item.AdjustedFee ? `$${this.numberWithCommas(item.AdjustedFee)}` : '$0'}</td>
                                    <td>
                                      <InputNumber
                                        style={{ width: '100%' }}
                                        addonBefore="$"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        value={item.InvoicedFee}
                                        onChange={this.handleChangeInvoicedFee(0, 'MZ900')}
                                      />
                                    </td>
                                    {/* <td>{item.ExpectedFee ? `$${this.numberWithCommas(item.ExpectedFee)}` : '$0'}</td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </React.Fragment>
                </Form.Item>
              )}
              <Form.Item className="fluid-field">
                <React.Fragment>
                  <table className="ui celled sortable striped table">
                    <tbody>
                      <tr>
                        {/* <td>Total Scheduled Fee:</td>
                          <td>
                            <InputNumber
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              value={data.TotalScheduledFee}
                              className={data.TotalScheduledFee < 0 ? 'fee-difference' : ''}
                              disabled
                            />
                          </td> */}
                        <td>Total Adjusted Fee:</td>
                        <td>
                          <InputNumber
                            style={{ width: '100%' }}
                            disabled
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            value={data.AdjustedFee}
                            className={data.AdjustedFee < 0 ? 'fee-difference' : ''}
                          />
                        </td>
                        <td>Total Invoiced Fee:</td>
                        <td>
                          <InputNumber
                            style={{ width: '100%' }}
                            disabled
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            value={data.TotalQuoteFee}
                            onChange={this.handleFieldChange('TotalQuoteFee')}
                            className={data.TotalQuoteFee < 0 ? 'fee-difference' : ''}
                          />
                        </td>
                        <td>Fee Difference:</td>
                        <td>
                          <InputNumber
                            style={{ width: '100%' }}
                            disabled
                            addonBefore="$"
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            value={data.Difference}
                            onChange={this.handleFieldChange('Difference')}
                            className={data.Difference < 0 ? 'fee-difference' : ''}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.state.data.ServiceItemType && this.state.data.ServiceItemType.includes(4) && (
                    <table className="ui celled sortable striped table">
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            Only Assistance at Operation
                          </td>
                        </tr>
                        <tr>
                          <td>Total Adjusted Fee:</td>
                          <td>
                            <InputNumber
                              style={{ width: '100%' }}
                              disabled
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              value={data.OnlyAaOAdjustedFee}
                              className={data.OnlyAaOAdjustedFee < 0 ? 'fee-difference' : ''}
                            />
                          </td>
                          <td>Total Invoiced Fee:</td>
                          <td>
                            <InputNumber
                              style={{ width: '100%' }}
                              disabled
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              value={data.OnlyAaOTotalQuoteFee}
                              onChange={this.handleFieldChange('OnlyAaOTotalQuoteFee')}
                              className={data.OnlyAaOTotalQuoteFee < 0 ? 'fee-difference' : ''}
                            />
                          </td>
                          <td>Fee Difference:</td>
                          <td>
                            <InputNumber
                              style={{ width: '100%' }}
                              disabled
                              addonBefore="$"
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              value={data.OnlyAaODifference}
                              onChange={this.handleFieldChange('OnlyAaODifference')}
                              className={data.OnlyAaODifference < 0 ? 'fee-difference' : ''}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </React.Fragment>
              </Form.Item>
            </Form>
          </div>
        )}
        {this.openConfirm && <ModalConfirm {...this.confirmParams} open={this.openConfirm} />}
      </Modal>
    );
  }
}

export default ModalCreate;
