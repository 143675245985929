import './index.scss';
import React from 'react';
import {
  Button,
  Input,
  Radio,
  Select,
  Label,
  Dimmer,
  Loader,
  Tab,
  Icon,
  Menu,
  Image,
  Table,
  Checkbox,
  Popup,
  Icon as IconSemantic,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { notification, Spin, Dropdown } from 'antd';
import { formatDate } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';
import moment from 'moment';
import PersonalDetailStore from './store';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { ProfileUpload } from '@components/shared/ProfileUpload';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { Link } from 'react-router-dom';
import ActionRequiredModal from './ActionRequiredModal';
import { getMyDetail, getActionRequired, getUserSetting } from './service';

const UserAttachmentType = {
  ProfilePicture: 0,
};

const State = [
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

const styles = {
  radioBtn: {
    fontSize: '0.895em',
  },
  avatar: {
    width: 180,
    marginBottom: '0.8125em',
  },
};

const columns = [
  {},
  { title: 'Action Subject' },
  { title: 'Due Date' },
  { title: 'Action Notes' },
  { title: 'PIC' },
  { title: 'Action Complete' },
  { title: 'Completed Date' },
];

const getProfileImageUrl = profilePictureAttachments => {
  if (!profilePictureAttachments.length) {
    return 'https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg';
  }
  const avatar = toJS(profilePictureAttachments[0]);
  return avatar.Id
    ? `/UserFile/UserAttachment/${avatar.PhysicalFileName}`
    : `/UserFile/UploadFileTemp/${avatar.PhysicalFileName}`;
};

@observer
class MyDetail extends React.Component {
  @observable openModalAction = false;

  @action toggleModalAction = isOpen => {
    this.openModalAction = isOpen;
  };

  state = {
    user: null,
    loading: true,
    progressBarWidh: 0,
    confirmPassword: '',
    passwordStrength: 'short',
    passwordStrengthCheck: {
      eightCharacter: false,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    },
    userSettings: {},
    showPassword: false,
  };

  componentWillUnmount() {
    PersonalDetailStore.changeField('activeIndex', 0);
  }

  @action componentDidMount() {
    const userSettings = localStorage.getItem('userSettings');
    if (!!userSettings) {
      this.setState({ userSettings: JSON.parse(userSettings) });
    }
    PersonalDetailStore.isEditing = false;
    Promise.all([getMyDetail(), getActionRequired(), getUserSetting()]).then(
      action(([res, actionRequiredList, userSettingResp]) => {
        if (res.model.Attachments?.length) {
          PersonalDetailStore.profilePictureAttachments = res.model.Attachments.filter(att => {
            return att.AttachmentType === UserAttachmentType.ProfilePicture;
          });
        }
        //moment(date_, 'DD MMM, YYYY')
        if (userSettingResp.status === 'success') {
          if (userSettingResp.model) {
            var resResult = {
              ...userSettingResp.model,
              dateFrom: userSettingResp.model.dateFrom
                ? moment(formatDate(userSettingResp.model.dateFrom), 'DD MMM, YYYY')
                : null,
              dateTo: userSettingResp.model.dateTo
                ? moment(formatDate(userSettingResp.model.dateTo), 'DD MMM, YYYY')
                : null,
            };
            localStorage.removeItem('userSettings');
            localStorage.setItem('userSettings', JSON.stringify(this.state.userSettings));
            this.setState({ userSettings: resResult });
          }
        }
        PersonalDetailStore.Id = res.model.Id;
        PersonalDetailStore.setListActionRequired(actionRequiredList.itemList);
        this.setState({
          user: {
            ...res.model,
            Birthday: res.model.Birthday || '',
            Address: res.model.Address || '',
            CityId: res.model.CityId || '',
            District: res.model.District || '',
            OldPassword: res.model.OldPassword || '',
            NewPassword: res.model.NewPassword || '',
            Postcode: res.model.Postcode || '',
            Telephone: res.model.Telephone || '',
          },
          loading: false,
        });
      }),
    );
    // customFetch('/Account/MyDetail', {
    //   method: 'POST',
    // }).then(
    //   action(res => {

    //   }),
    // );
  }

  handleShowPassword = () => {
    const isShowed = this.state.showPassword;
    this.setState({ showPassword: !isShowed });
  };

  renderStateOptions = () => {
    return State.map(state => ({
      text: state.label,
      value: state.id,
    }));
  };

  changeProgressbar = (firstValue, secondValue) => {
    for (let i = firstValue; i <= secondValue; i++) {
      setTimeout(() => {
        this.setState({ progressBarWidh: i });
      });
    }
  };

  handleChangeBirthDate = (_, { value }) => {
    this.setState(state => (state.user.Birthday = value));
  };

  checkPasswordStrength = value => {
    const value_ = value.toLowerCase();
    const regExpWeak = /[a-zA-Z]/;
    const regExpMedium = /\d+/;
    const regExpStrong = /[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
    let strength = 0;
    let passwordStrengthCheck = {
      eightCharacter: value.length >= 8,
      specialCharacter: false,
      upperCaseCharacter: false,
      lowerCaseCharacter: false,
      digit: false,
    };
    const weakPass = value_.match(regExpWeak);
    const mediumPass = value_.match(regExpMedium);
    const strongPass = value_.match(regExpStrong);
    const password = value.split('');
    password.forEach(i => {
      if (regExpWeak.test(i)) {
        if (weakPass) {
          if (/[A-Z]/.test(i)) {
            strength += 4;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 3;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        } else {
          if (/[A-Z]/.test(i)) {
            strength += 6;
            passwordStrengthCheck.upperCaseCharacter = true;
          } else {
            strength += 5;
            passwordStrengthCheck.lowerCaseCharacter = true;
          }
        }
      } else if (regExpMedium.test(i)) {
        if (mediumPass) {
          strength += 4;
          passwordStrengthCheck.digit = true;
        } else {
          strength += 7;
          passwordStrengthCheck.digit = true;
        }
      } else if (regExpStrong.test(i)) {
        if (strongPass) {
          passwordStrengthCheck.specialCharacter = true;
          strength += 8;
        } else {
          passwordStrengthCheck.specialCharacter = true;
          strength += 10;
        }
      }
    });
    return {
      passwordStrengthCheck,
      strength: strength > 100 ? 100 : strength,
    };
  };

  handleChangePassword = (_, { value }) => {
    const progress = document.querySelector('.progress');
    const { strength, passwordStrengthCheck } = this.checkPasswordStrength(value);
    this.setState({ passwordStrengthCheck });
    this.setState(state => (state.user.NewPassword = value));
    if (value !== '') {
      if (strength <= 3) {
        this.setState({ passwordStrength: 'short', progressBarWidh: strength });
        progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
      } else if (strength <= 25) {
        this.setState({ passwordStrength: 'weak', progressBarWidh: strength });
        progress.style.background = 'linear-gradient(45deg, #FF3333, #ff0000)';
      } else if (strength <= 50) {
        this.setState({
          passwordStrength: 'medium',
          progressBarWidh: strength,
        });
        progress.style.background = 'linear-gradient(45deg, #FFCC33, #FF6600)';
      } else if (strength <= 75) {
        this.setState({ passwordStrength: 'good', progressBarWidh: strength });
        progress.style.background = 'linear-gradient(45deg, #66FFFF, #3399FF)';
      } else if (strength <= 100) {
        this.setState({
          passwordStrength: 'strong',
          progressBarWidh: strength,
        });
        progress.style.background = 'linear-gradient(45deg, #99FF99, #66CC00)';
      } else {
        this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
      }
    } else {
      this.setState({ passwordStrength: 'short', progressBarWidh: 0 });
    }
  };

  onChangeTab = (e, { activeIndex }) => {
    PersonalDetailStore.changeField('activeIndex', activeIndex);
    this.setState(
      {
        passwordStrength: 'short',
        passwordStrengthCheck: {
          eightCharacter: false,
          specialCharacter: false,
          upperCaseCharacter: false,
          lowerCaseCharacter: false,
          digit: false,
        },
        showPassword: false,
        confirmPassword: '',
        progressBarWidh: 0,
      },
      () =>
        this.setState(state => {
          state.user.NewPassword = '';
          state.user.OldPassword = '';
        }),
    );
  };

  handleCheckedChangePassword = () => {
    const isChecked = this.state.user.IsChangePassword;
    this.setState(state => (state.user.IsChangePassword = !isChecked));
  };
  handleRadioBox = (_, { value }) => {
    this.setState(state => (state.user.Gender = value));
  };
  convertPhoneNumber = (number, type) => {
    const value = number;
    if (!value) return value;
    else if (value) {
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;
      if (type === 'Mobile') {
        if (cvLength < 4) {
          return currentValue;
        }
        if (cvLength < 8) {
          const sliceTwo = currentValue.slice(4, 7);
          return `${currentValue.slice(0, 4)}${sliceTwo ? ` ${currentValue.slice(4, 7)}` : ``}`;
        } else if (cvLength <= 10) {
          return `${currentValue.slice(0, 4)} ${currentValue.slice(4, 7)}${currentValue.slice(7, 10)}`;
        }
      } else {
        if (cvLength < 2) {
          return currentValue;
        }
        if (cvLength < 8) {
          const sliceTwo = currentValue.slice(2, 7);
          return `${currentValue.slice(0, 2)}${sliceTwo ? ` ${currentValue.slice(2, 7)}` : ``}`;
        } else if (cvLength <= 10) {
          return `${currentValue.slice(0, 2)} ${currentValue.slice(2, 7)}${currentValue.slice(7, 10)}`;
        }
      }
    }
  };
  handleChangeInput = (_, { name, value }) => {
    if (name === 'Telephone') {
      this.setState(state => (state.user[name] = this.convertPhoneNumber(value, 'PhoneNumber')));
    } else {
      this.setState(state => (state.user[name] = value));
    }
  };
  handleChangeUserSetting = (_, { name, value }) => {
    const userSettings = this.state.userSettings;
    if (name === 'recieveDailyTally') {
      if (userSettings && this.state.userSettings.recieveDailyTally) {
        value = !this.state.userSettings.recieveDailyTally;
      } else {
        value = true;
      }
    }
    if (name === 'recieveActionAndActivityFollowUp') {
      if (userSettings && this.state.userSettings.recieveActionAndActivityFollowUp) {
        value = !this.state.userSettings.recieveActionAndActivityFollowUp;
      } else {
        value = true;
      }
    }
    if (name === 'numberBeforeToday' || name === 'numberAfterToday') {
      console.log(value);
      if (name === 'numberBeforeToday') {
        if (value) {
          var dFrom = moment().add(-value, 'days');
          this.setState(state => ({
            userSettings: {
              ...userSettings,
              dateFrom: dFrom,
              [name]: value,
            },
          }));
        } else {
          var dTo = moment().add(-value, 'days');
          this.setState(state => ({
            userSettings: {
              ...userSettings,
              dateFrom: null,
              [name]: value,
            },
          }));
        }
      }
      if (name === 'numberAfterToday') {
        if (value) {
          var dTo = moment().add(value, 'days');
          this.setState(state => ({
            userSettings: {
              ...userSettings,
              dateTo: dTo,
              [name]: value,
            },
          }));
        } else {
          var dTo = moment().add(value, 'days');
          this.setState(state => ({
            userSettings: {
              ...userSettings,
              dateTo: null,
              [name]: value,
            },
          }));
        }
      }
    } else {
      this.setState(state => (state.userSettings[name] = value));
    }
  };
  handleSelectState = (_, { value }) => {
    this.setState(state => (state.user.CityId = value));
  };
  handleUpdateUserSetting = e => {
    //console.log(this.state.userSettings);
    const uS = this.state.userSettings;
    var saveModel = {
      ...uS,
      dateFrom: uS.dateFrom ? uS.dateFrom.format('YYYY/MM/DD') : null,
      dateTo: uS.dateTo ? uS.dateTo.format('YYYY/MM/DD') : null,
    };
    e.preventDefault();
    localStorage.setItem('userSettings', JSON.stringify(this.state.userSettings));
    customFetch('/Account/UpdateSettings', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ model: saveModel }),
    }).then(res => {
      if (res.status === 'success') {
        //console.log(this.state.userSettings);
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Your settings has been updated successfully.',
        });
      }
    });
  };
  @action hangdleSubmit = async e => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      if (this.state.user.OldPassword || this.state.user.NewPassword) {
        if (this.state.user.NewPassword !== this.state.confirmPassword) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Password and confirm password does not match`,
            duration: 2,
          });
        } else if (!this.state.user.OldPassword && this.state.user.NewPassword) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Please enter your old password`,
            duration: 2,
          });
        } else if (this.state.user.OldPassword && !this.state.user.NewPassword) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Please enter your new password`,
            duration: 2,
          });
        } else if (this.state.user.NewPassword.length < 8) {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Must contain at least 8 characters`,
            duration: 2,
          });
        } else {
          const { passwordStrengthCheck } = this.state;
          if (!passwordStrengthCheck.digit) {
            notification.destroy();
            notification.error({
              message: 'Fail',
              description: `Password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)`,
              duration: 2,
            });
          } else if (!passwordStrengthCheck.lowerCaseCharacter) {
            notification.destroy();
            notification.error({
              message: 'Fail',
              description: `Password must contain at least one lowercase letter.`,
              duration: 2,
            });
          } else if (!passwordStrengthCheck.upperCaseCharacter) {
            notification.destroy();
            notification.error({
              message: 'Fail',
              description: `Password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)`,
              duration: 2,
            });
          } else if (!passwordStrengthCheck.specialCharacter) {
            notification.destroy();
            notification.error({
              message: 'Fail',
              description: `Password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,) `,
              duration: 2,
            });
          } else {
            const res = await customFetch('/Account/SaveMyAccount', {
              method: 'POST',
              headers: { 'Content-type': 'application/json' },
              body: JSON.stringify({
                ...this.state.user,
                Birthday: this.formatBoDReq(this.state.user.Birthday),
                Telephone: this.state.user.Telephone.replace(/[^\d]/g, ''),
                ConfirmNewPassword: this.state.confirmPassword,
                IsChangePassword: this.state.user.OldPassword && this.state.user.NewPassword ? true : false,
                Attachments: toJS(PersonalDetailStore.profilePictureAttachments),
              }),
            });
            if (res.status === 'success') {
              customFetch('/Account/MyDetail', {
                method: 'POST',
              }).then(
                action(res => {
                  this.setState({
                    user: {
                      ...res.model,
                      Birthday: res.model.Birthday || '',
                      Address: res.model.Address || '',
                      CityId: res.model.CityId || '',
                      District: res.model.District || '',
                      OldPassword: res.model.OldPassword || '',
                      NewPassword: res.model.NewPassword || '',
                      Postcode: res.model.Postcode || '',
                      Telephone: res.model.Telephone || '',
                    },
                    loading: false,
                    passwordStrength: 'short',
                    passwordStrengthCheck: {
                      eightCharacter: false,
                      specialCharacter: false,
                      upperCaseCharacter: false,
                      lowerCaseCharacter: false,
                      digit: false,
                    },
                    confirmPassword: '',
                    progressBarWidh: 0,
                  });
                  notification.destroy();
                  notification.success({
                    message: 'Success',
                    description: `Your account has been updated successfully`,
                    duration: 2,
                  });
                  PersonalDetailStore.isEditing = false;
                  if (res.model.Attachments?.length) {
                    PersonalDetailStore.profilePictureAttachments = res.model.Attachments.filter(att => {
                      return att.AttachmentType === UserAttachmentType.ProfilePicture;
                    });
                  }
                  PersonalDetailStore.Id = res.model.Id;
                }),
              );
            }
          }
        }
      } else {
        const res = await customFetch('/Account/SaveMyAccount', {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            ...this.state.user,
            Birthday: this.formatBoDReq(this.state.user.Birthday),
            Telephone: this.state.user.Telephone.replace(/[^\d]/g, ''),
            ConfirmNewPassword: this.state.confirmPassword,
            IsChangePassword: this.state.user.OldPassword && this.state.user.NewPassword ? true : false,
            Attachments: toJS(PersonalDetailStore.profilePictureAttachments),
          }),
        });
        if (res.status === 'success') {
          customFetch('/Account/MyDetail', {
            method: 'POST',
          }).then(
            action(res => {
              this.setState({
                user: {
                  ...res.model,
                  Birthday: res.model.Birthday || '',
                  Address: res.model.Address || '',
                  CityId: res.model.CityId || '',
                  District: res.model.District || '',
                  OldPassword: res.model.OldPassword || '',
                  NewPassword: res.model.NewPassword || '',
                  Postcode: res.model.Postcode || '',
                  Telephone: res.model.Telephone || '',
                },
                loading: false,
                passwordStrength: 'short',
                passwordStrengthCheck: {
                  eightCharacter: false,
                  specialCharacter: false,
                  upperCaseCharacter: false,
                  lowerCaseCharacter: false,
                  digit: false,
                },
                confirmPassword: '',
                progressBarWidh: 0,
              });
              notification.destroy();
              notification.success({
                message: 'Success',
                description: `Your account has been updated successfully`,
                duration: 2,
              });
              PersonalDetailStore.isEditing = false;
              if (res.model.Attachments?.length) {
                PersonalDetailStore.profilePictureAttachments = res.model.Attachments.filter(att => {
                  return att.AttachmentType === UserAttachmentType.ProfilePicture;
                });
              }
              PersonalDetailStore.Id = res.model.Id;
            }),
          );
        }
      }
    } catch (e) {
    } finally {
      this.setState({ loading: false, confirmPassword: '' });
    }
  };
  handleChangeCofirmPassword = (_, { value }) => {
    this.setState({ confirmPassword: value });
  };
  renderBirtDate = date => {
    if (date.search('/Date') !== -1) {
      const date_ = formatDate(date);
      return moment(date_, 'DD MMM, YYYY').format('DD/MM/YYYY');
    }
    return date;
  };
  formatBoDReq = (date = '') => {
    if (!date) return '';
    if (date.search('/Date') !== -1) {
      return moment(date).toDate();
    }
    return moment(date, 'DD/MM/YYYY').toDate();
  };

  @action openUploadModal = () => {
    PersonalDetailStore.toggleModal(true, {
      modalType: 'uploadPicture',
    })();
  };

  @action removeProfilePicture = () => {
    PersonalDetailStore.profilePictureAttachments = [];
  };

  toggleModal = () => {
    PersonalDetailStore.toggleModalAction(true);
  };

  renderDate = (datestr, fieldName) => {
    if (datestr) {
      if (fieldName === 'DueDate') {
        const date = formatDate(datestr, true);
        return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
      } else {
        const date = formatDate(datestr);
        return moment(date, 'DD MMM, YYYY').format('DD/MM/YYYY');
      }
    }
    return '';
  };

  handleOpenConfirm = data => {
    return PersonalDetailStore.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to delete this action note',
      onOk: () => this.handleDisableActionNote(data.Id),
    })();
  };

  renderDropdownMenu = data => (
    <Menu>
      <Menu.Item onClick={this.toggleModal}>Edit</Menu.Item>
      {/* <Menu.Item onClick={() => this.handleOpenConfirm(data)}>Delete</Menu.Item> */}
    </Menu>
  );

  renderTableBody = data => {
    return data.map((i, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={this.toggleModal}>
            {i.Subject}
          </Link>
        </Table.Cell>
        <Table.Cell>{this.renderDate(i.DueDate, 'DueDate')}</Table.Cell>
        <Table.Cell>{i.ActionNote}</Table.Cell>
        <Table.Cell>{`${i.StaffFirstName || ''} ${i.StaffLastName || ''}`}</Table.Cell>
        <Table.Cell>
          <p style={{ color: `${i.Completed ? 'green' : 'red'}` }}>{i.Completed ? 'Completed' : 'Not Completed'}</p>
        </Table.Cell>
        <Table.Cell>{this.renderDate(i.CompletedDate)}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loading, user, progressBarWidh, confirmPassword, passwordStrengthCheck, userSettings } = this.state;
    const { isEditing, open, profilePictureAttachments } = PersonalDetailStore;
    const data = toJS(PersonalDetailStore.actionRequiredList);

    const panes = [
      {
        menuItem: (
          <Menu.Item key="profile">
            Personal Details <Icon name="user" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon style={{ marginBottom: '20px' }} size="medium">
              Personal Details
            </Label>
            <form>
              <div className="Form-field">
                <div className="Avatar" style={styles.avatar}>
                  <Image src={getProfileImageUrl(profilePictureAttachments)} />
                </div>
                {isEditing ? (
                  <div className="Avatar-Actions">
                    <Button className="blue" onClick={this.openUploadModal} type="button">
                      Upload
                    </Button>
                    <Button
                      type="button"
                      className="negative"
                      onClick={this.removeProfilePicture}
                      disabled={!profilePictureAttachments.length}
                    >
                      Delete
                    </Button>
                    <Button type="button" onClick={() => PersonalDetailStore.setIsEditing(false)}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className="Avatar-Actions">
                    <Button type="button" className="blue" onClick={() => PersonalDetailStore.setIsEditing(true)}>
                      Change Avatar
                    </Button>
                  </div>
                )}
              </div>
              <div className="Form-field">
                <label className="Form-label">First Name</label>
                <Input
                  className="Field-Input"
                  value={user.FirstName}
                  name="FirstName"
                  placeholder="First Name"
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Surname</label>
                <Input
                  className="Field-Input"
                  value={user.LastName}
                  placeholder="Surname"
                  name="LastName"
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Gender</label>
                <Radio
                  label="F"
                  className="Radio-box"
                  style={styles.radioBtn}
                  checked={user.Gender === 0}
                  value={0}
                  onChange={this.handleRadioBox}
                />
                <Radio
                  label="M"
                  className="Radio-box"
                  style={styles.radioBtn}
                  checked={user.Gender === 1}
                  value={1}
                  onChange={this.handleRadioBox}
                />
                <Radio
                  label="Other"
                  className="Radio-box"
                  style={styles.radioBtn}
                  checked={user.Gender === 2}
                  value={2}
                  onChange={this.handleRadioBox}
                />
                <Radio
                  label="Prefer not to say"
                  className="Radio-box"
                  style={styles.radioBtn}
                  checked={user.Gender === 3}
                  value={3}
                  onChange={this.handleRadioBox}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Date of Birth</label>
                <DateInput
                  className="Field-BirthDate"
                  clearable
                  animation=""
                  duration={0}
                  placeholder="Date"
                  dateFormat="DD/MM/YYYY"
                  value={this.renderBirtDate(user.Birthday)}
                  onChange={this.handleChangeBirthDate}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label" />
                <Button className="blue" type="submit" onClick={this.hangdleSubmit}>
                  Update
                </Button>
              </div>
            </form>
            {open && (
              <ProfileUpload
                open={open}
                toggleModal={PersonalDetailStore.toggleModal}
                uploadFile={PersonalDetailStore.uploadFile}
                uploadFileFromBase64={PersonalDetailStore.uploadFileFromBase64}
                attachmentType={UserAttachmentType.ProfilePicture}
              />
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="contact">
            Contact Information <Icon name="phone" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon style={{ marginBottom: '20px' }} size="medium">
              Contact Information
            </Label>
            <form>
              <div className="Form-field">
                <label className="Form-label">Email</label>
                <Input className="Field-Input" value={user.Email} disabled />
              </div>
              <div className="Form-field">
                <label className="Form-label">Tel</label>
                <Input
                  className="Field-Input"
                  value={user.Telephone}
                  name="Telephone"
                  onChange={this.handleChangeInput}
                  maxLength={11}
                  placeholder="Telephone"
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Street Address</label>
                <Input
                  className="Field-Input"
                  value={user.Address}
                  name="Address"
                  placeholder="Street Address"
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Suburb</label>
                <Input
                  className="Field-Input"
                  value={user.District}
                  name="District"
                  placeholder="Suburb"
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">Postcode</label>
                <Input
                  className="Field-Input"
                  value={user.Postcode}
                  onChange={this.handleChangeInput}
                  name="Postcode"
                  placeholder="Postcode"
                  type="number"
                />
              </div>
              <div className="Form-field">
                <label className="Form-label">State</label>
                <Select
                  className="Filed-State"
                  options={this.renderStateOptions()}
                  placeholder="Select State"
                  value={user.CityId}
                  onChange={this.handleSelectState}
                />
              </div>
              <div className="Form-field">
                <label className="Form-label" />
                <Button className="blue" type="submit" onClick={this.hangdleSubmit}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="messages">
            Reset Password <Icon name="lock" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon style={{ marginBottom: '20px' }} size="medium">
              Reset Password
            </Label>
            <form>
              <React.Fragment>
                <div className="Form-field">
                  <label className="Form-label">Username</label>
                  <Input className="Field-Input" value={user.UserName} disabled />
                </div>
                <div className="Form-field">
                  <label className="Form-label">Old password</label>
                  <Input
                    className="Field-Input"
                    type="password"
                    name="OldPassword"
                    value={user.OldPassword}
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="Form-field">
                  <label className="Form-label">New password</label>
                  <Input
                    className="Field-Input"
                    onChange={this.handleChangePassword}
                    value={user.NewPassword}
                    type={`${!this.state.showPassword ? `password` : ``}`}
                    icon={<Icon name="eye slash" inverted link onClick={this.handleShowPassword} />}
                    name="NewPassword"
                  />
                </div>
                <div className="Form-field">
                  <label className="Form-label" />
                  <div className="indicator">
                    <div className="percent">
                      <div className="progress" style={{ width: `${progressBarWidh}%` }}></div>
                    </div>
                  </div>
                  <div className="Field-Password-Checker">
                    <p
                      className={`Field-text-password-${
                        this.state.passwordStrength === 'short'
                          ? 'short'
                          : this.state.passwordStrength === 'weak'
                          ? 'weak'
                          : this.state.passwordStrength === 'medium'
                          ? 'medium'
                          : this.state.passwordStrength === 'good'
                          ? 'good'
                          : this.state.passwordStrength === 'strong' && 'strong'
                      }`}
                    >
                      {this.state.passwordStrength === 'short'
                        ? 'Your password is too short'
                        : this.state.passwordStrength === 'weak'
                        ? 'Your password is too week'
                        : this.state.passwordStrength === 'medium'
                        ? 'Your password is medium'
                        : this.state.passwordStrength === 'good'
                        ? 'Your password is good'
                        : this.state.passwordStrength === 'strong'
                        ? 'Your password is strong'
                        : ''}
                    </p>
                  </div>
                </div>
                <div className="Form-field">
                  <label className="Form-field-checkbox"></label>
                  <div className="List-password-strength">
                    <label>
                      <input type="checkbox" name="" disabled checked={passwordStrengthCheck.eightCharacter} />
                      <i className="checked-icon"></i>
                      <span>Must contain at least 8 characters</span>
                    </label>
                    <label>
                      <input type="checkbox" name="" disabled checked={passwordStrengthCheck.specialCharacter} />
                      <i className="checked-icon"></i>
                      <span>Must contain at least one special character (!@#$%&*())</span>
                    </label>
                    <label>
                      <input type="checkbox" name="" disabled checked={passwordStrengthCheck.upperCaseCharacter} />
                      <i className="checked-icon"></i>
                      <span>Must contain at least one Uppercase letter (A through Z)</span>
                    </label>
                    <label>
                      <input type="checkbox" name="" disabled checked={passwordStrengthCheck.lowerCaseCharacter} />
                      <i className="checked-icon"></i>
                      <span>Must contain at least one lowercase letter (a through z)</span>
                    </label>
                    <label>
                      <input type="checkbox" name="" disabled checked={passwordStrengthCheck.digit} />
                      <i className="checked-icon"></i>
                      <span>Must contain at least one number (0 through 9)</span>
                    </label>
                  </div>
                </div>
                <div className="Form-field">
                  <label className="Form-label">Confirm password</label>
                  <Input
                    className="Field-Input"
                    onChange={this.handleChangeCofirmPassword}
                    value={confirmPassword}
                    type="password"
                    error={!(confirmPassword === user.NewPassword)}
                  />
                </div>
                {confirmPassword !== user.NewPassword && (
                  <div className="Form-field" style={{ marginTop: '-0.8125em' }}>
                    <label className="Form-label"></label>
                    <Label
                      pointing
                      prompt
                      color="red"
                      style={{
                        color: 'white',
                      }}
                    >
                      Password and confirm password does not match
                    </Label>
                  </div>
                )}
              </React.Fragment>
              <div className="Form-field">
                <label className="Form-label" />
                <Button className="blue" type="submit" onClick={this.hangdleSubmit}>
                  Update
                </Button>
              </div>
            </form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="messages">
            Action Required <Icon name="file text" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon style={{ marginBottom: '20px' }} size="medium">
              Action Required
            </Label>
            <React.Fragment>
              <div className="fluid-field">
                <div className="fluid-field" style={{ marginBottom: '1em' }}>
                  <Button color="blue" onClick={this.toggleModal}>
                    Add New
                  </Button>
                </div>
                <div className="table-case">
                  <Table sortable striped celled>
                    <TableHeader columns={columns} />
                    <Table.Body>
                      {data && data.length && data.length !== 0 ? (
                        this.renderTableBody(data)
                      ) : (
                        <TableEmptyMessage colSpan={columns.length} />
                      )}
                    </Table.Body>
                    <TableFooterPagination
                      colSpan={columns.length}
                      totalItems={data && data.length && data.length !== 0 ? data.length : 0}
                      currentPage={data && data.length && data.length !== 0 ? 1 : 0}
                      totalPage={data && data.length && data.length !== 0 ? 1 : 0}
                      onPageClick={() => {}}
                    />
                  </Table>
                </div>
              </div>
            </React.Fragment>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="messages">
            User Setting <Icon name="cog" style={{ marginLeft: '10px' }} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Label as="a" color="teal" ribbon style={{ marginBottom: '20px' }} size="medium">
              User Setting
            </Label>
            <React.Fragment>
              <form>
                <div className="Form-field" style={{ display: 'flex' }}>
                  <label className="Form-label">
                    Calendar: Starting date shown in Calendar
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Set number of days before today."
                      position="top center"
                      wide="very"
                    />
                  </label>
                  <Input
                    className="Field-Input"
                    value={userSettings.numberBeforeToday}
                    onChange={this.handleChangeUserSetting}
                    name="numberBeforeToday"
                    placeholder="Number of days"
                    type="number"
                  />
                  <DateInput
                    style={{ display: 'none' }}
                    clearable
                    animation=""
                    duration={0}
                    placeholder="From Date"
                    dateFormat="DD/MM/YYYY"
                    className="Field-BirthDate"
                    name="dateFrom"
                    value={userSettings.dateFrom}
                    onChange={this.handleChangeUserSetting}
                  />
                </div>
                <br />
                <div className="Form-field" style={{ display: 'flex' }}>
                  <label className="Form-label">
                    Calendar: Ending date shown in Calendar
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Set number of days after today."
                      position="top center"
                      wide="very"
                    />
                  </label>
                  <Input
                    className="Field-Input"
                    value={userSettings.numberAfterToday}
                    onChange={this.handleChangeUserSetting}
                    name="numberAfterToday"
                    placeholder="Number of days"
                    type="number"
                  />
                  {/* style={{display: 'none'}} */}
                  <DateInput
                    style={{ display: 'none' }}
                    clearable
                    animation=""
                    duration={0}
                    placeholder="To Date"
                    dateFormat="DD/MM/YYYY"
                    className="Field-BirthDate"
                    value={userSettings.dateTo}
                    name="dateTo"
                    onChange={this.handleChangeUserSetting}
                  />
                </div>
                <br />
                <div className="Form-field" style={{ display: 'flex' }}>
                  <label className="Form-label">
                    Receive Daily Relationship Activity Tally
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Daily relationship activity tally will be sent to this account (Mail-160)."
                      position="top center"
                      wide="very"
                    />
                  </label>
                  <Checkbox
                    name="recieveDailyTally"
                    checked={userSettings.recieveDailyTally}
                    onChange={this.handleChangeUserSetting}
                  />
                </div>
                <br />
                <br />
                <div className="Form-field" style={{ display: 'flex' }}>
                  <label className="Form-label">Daily Relationship Activity Target</label>
                  <Input
                    className="Field-Input"
                    value={userSettings.dailyQuote}
                    onChange={this.handleChangeUserSetting}
                    name="dailyQuote"
                    placeholder="Set daily target"
                    type="number"
                  />
                </div>
                <br />
                <br />
                <div className="Form-field" style={{ display: 'flex' }}>
                  <label className="Form-label">
                    Receive Action Required and Relationship Activity Follow-Up
                    <Popup
                      trigger={<IconSemantic name="info circle" />}
                      content="Montly Action Required and Relationship Activity Follow-Up (Mail-161)."
                      position="top center"
                      wide="very"
                    />
                  </label>
                  <Checkbox
                    name="recieveActionAndActivityFollowUp"
                    checked={userSettings.recieveActionAndActivityFollowUp}
                    onChange={this.handleChangeUserSetting}
                  />
                </div>
                <br />
                <br />
                <br />
                <div className="Form-field">
                  <label className="Form-label" />
                  <Button className="blue" onClick={this.handleUpdateUserSetting}>
                    Update
                  </Button>
                </div>
              </form>
            </React.Fragment>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <div className="admin-my-details">
        <div className={`Form-${loading && !user ? 'Loading' : 'Container'}`}>
          {loading && !user ? (
            <Spin size="large" />
          ) : (
            <React.Fragment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Tab
                panes={panes}
                menu={{ pointing: true, vertical: true }}
                menuPosition="right"
                // className="Form-tab"
                onTabChange={this.onChangeTab}
                activeIndex={PersonalDetailStore.activeIndex}
              />
            </React.Fragment>
          )}
        </div>
        {PersonalDetailStore.openModalAction && <ActionRequiredModal />}
      </div>
    );
  }
}
export default MyDetail;
