import './Tooltip.scss';

import cx from 'classnames';
import omit from 'lodash/omit';
import React, { cloneElement, Component, createRef, Fragment } from 'react';

import Portal from './form/Portal';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.target = createRef();
    this.state = {
      active: false,
      position: {},
    };
  }

  getPosition = () => {
    let position = {};
    const { current } = this.target;
    const { top, bottom, left, right } = current.getBoundingClientRect();

    if (right + 160 >= window.innerWidth) {
      position.right = 2;
      position.swap = 'right';
    } else {
      position.left = left;
    }

    if (bottom + 33 >= window.innerHeight) {
      position.top = top - 43;
      position.swap = 'top';
    } else {
      position.top = bottom + 10;
    }
    return position;
  };

  onMouseEnter = event => {
    const { onAppear } = this.props;
    const position = this.getPosition();
    this.setState({ active: true, position }, () => {
      onAppear && onAppear(event);
    });
  };

  onMouseLeave = event => {
    const { onDisappear } = this.props;
    this.setState({ active: false }, () => {
      onDisappear && onDisappear(event);
    });
  };

  render() {
    const { children, appear, className, ...props } = this.props;
    const { active, position } = this.state;

    if (!children) return null;

    return (
      <Fragment>
        {cloneElement(children, {
          ...omit(props, 'onAppear', 'onDisappear', 'position'),
          className: cx('hoverable', className, children.props.className),
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          ref: this.target,
        })}

        {active && !!appear && (
          <Portal>
            <div
              className={cx('Tooltip', {
                [`swap-${position.swap}`]: position.swap,
              })}
              style={{ ...omit(position, 'swap') }}
            >
              <div className="Tooltip-arrow" />
              <div>{appear}</div>
            </div>
          </Portal>
        )}
      </Fragment>
    );
  }
}

export default Tooltip;
