import './Anonymous.scss';

import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Header } from 'semantic-ui-react';

import * as api from '../../stores/api';
import router from '../../stores/router';
import connect from '../../utils/apiConnectQuery';
import anonymousRoutes from '../anonymous-routes';
import AnonymousLoading from './AnonymousLoading';
import logout from './logout';

const FIXED_ICS_ADDR = 'Suite 507, 83 York Street, Sydney, NSW 2000';
const FIXED_MAP_URL = `https://www.google.com/maps?q=${FIXED_ICS_ADDR.replace(/[^\d\w]+/g, '+')}`;

@connect(api.currentUser)
@connect(api.IME_CaseProgress)
@connect(api.FR_CaseProgress)
@connect(api.SUPP_CaseProgress)
@connect(api.CR_CaseProgress)
@connect(api.MN_CaseProgress)
@observer
class Anonymous extends React.Component {
  renderTitle() {
    const r = anonymousRoutes[router.location.pathname];
    const { title, description } = this.props.Component ? this.props : r;
    return (
      <div className="register-title">
        {title && <Header as="h1">{title}</Header>}
        {description && <p>{description}</p>}
      </div>
    );
  }
  renderContent() {
    const r = anonymousRoutes[router.location.pathname];
    const { Component, ignoreAnonymous } = this.props.Component ? this.props : r;
    // Need to spread variables from these api query observable fields here
    // So it will always re render correctly when those fields changed
    const { loading: loggingOut } = api.logout;
    const { loading: loggingInAsTourist } = api.loginAsTourist;
    const { fetched, loading, data } = api.currentUser;
    // Totally ignore the anonymous check
    if (ignoreAnonymous) {
      return <Component />;
    }
    // Render loading message for these cases
    if (loggingOut) {
      return <AnonymousLoading message="Logging out" />;
    }
    if (loggingInAsTourist) {
      return <AnonymousLoading message="Logging in as a guest" />;
    }
    if (!fetched || loading) {
      return <AnonymousLoading />;
    }
    // Render the welcome message if the user has logged in
    if (data && router.location.pathname !== '/view/video') {
      return (
        <React.Fragment>
          <p>
            Greeting, you have logged in as <span className="text-bold">{data.FullName}</span>.{' '}
            <span className="link" onClick={logout}>
              Logout
            </span>
          </p>
          <Divider />
          <div>
            <Link to="/" className="link">
              Back to dashboard
            </Link>
          </div>
        </React.Fragment>
      );
    }
    // Otherwise render the component
    return <Component />;
  }

  isSiteInvoiceCheck = () => {
    let hotName = window.location.hostname;
    console.log(hotName);
    if(hotName.includes('invoicecheck.kawaconn.com')){
      return true;
    }
    return false;
  }

  render() {
    const isVideo = router.location.pathname === '/view/video';

    if (isVideo) {
      return (
        <div>
          {this.renderTitle()}
          {this.renderContent()}
        </div>
      );
    }

    return (
      <div className="Anonymous">
        <div className="Anonymous-Content">
          <div className="Anonymous-Children">
            {this.renderTitle()}
            {this.renderContent()}
          </div>
          <div className="Anonymous-Footer">
            <div className="Anonymous-FooterItem">
              <div className="text-bold">Powered by:</div>
              <div
                className="text-secondary"
                onClick={() => window.open('https://kawaconn.com.au/', '_blank')}
                style={{ cursor: 'pointer' }}
              >
                Kawaconn
              </div>
              <div className="text-secondary">ABN: 98639063988</div>
              <div className="text-secondary">{FIXED_ICS_ADDR}</div>
              <div className="text-secondary">Copyright &copy; {new Date().getFullYear()}</div>
            </div>
            <div className="Anonymous-FooterItem">
              <div className="text-bold">Head office:</div>
              <div className="text-secondary">Medicolegal Assessments Group</div>
              <a
                className="text-secondary text-underline"
                target="_blank"
                rel="noopener noreferrer"
                href={FIXED_MAP_URL}
              >
                {FIXED_ICS_ADDR}
              </a>
              <div className="text-secondary">P.O. Box Q384, Sydney NSW 1230</div>
            </div>
            <div className="Anonymous-FooterItem">
              <div className="text-bold">Phone:</div>
              <div className="text-secondary">(02) 8090 7611</div>
              <div className="text-bold">Fax:</div>
              <div className="text-secondary">(02) 8090 7610</div>
            </div>
          </div>
        </div>
        <div className="Anonymous-Sidebar">
          <div className="Anonymous-Cirrus">
            <div className="Anonymous-CirrusLogo" style={{backgroundImage: this.isSiteInvoiceCheck() ? `url(/Content/build/static/media/mag-logo5.png)` : `url(/Content/build/static/media/mag-logo4.png)`}}/>
            <div className="Anonymous-CirrusTitle" />
            <div className="Anonymous-CirrusPowered" />
            <div className="Anonymous-CirrusSologan" />
          </div>
        </div>
      </div>
    );
  }
}

export default Anonymous;
