import { notification } from 'antd';
import { action, autorun, computed, observable, toJS, when, reaction } from 'mobx';
import Validator from 'validatorjs';
import moment from 'moment';
import { uniqBy } from 'lodash-es';

import * as api from '@stores/api';
import { formatDate } from '../../../utils/functions';

import {
  getInvoice,
  getActionRequired,
  getCaseCorrespondence,
  getCaseManagerOnboarded,
  getClaimant,
  getWiroPrincipleLawyer,
  getTypist,
  getTypeOfClaim,
  getClient,
  getIMSCode,
  getServiceItem,
  getSpecialist,
  getSpecificLookupConfigOrderNoByType,
  getStaff,
  getSupplementReport,
  getAssignHistory,
  saveSupplementary,
  getListInvoiceID,
  getSUPPStatusLogs,
  updateSuppReportStatus,
  getCompanyRecentChangeHistory,
  resendPaperworkReadiness,
  getUserUploadInfo,
  getCompanyInsuer,
  getLookUpConfig,
  getLookupIcareReport,
} from './service';
import customFetch from '@utils/customFetch';
import { SUPP_INIT_DATA, SERVICE_NOTE_FEE_INIT, FIELD_NAME_FOR_ADD_EDIT, CONSTANTS } from './type';
import dashboardStore from '@stores/dashboard';

const FIELD_NAME_FOR_NOTIFICATION = {
  SendInvoice: 'Email for sending invoices 1',
  SendInvoice2: 'Email for sending invoices 2',
  PaperworkOtherExLink: 'Paperwork other download link',
  PaperworkExLink: 'Paperwork download link',
  ClientPrepaymentRequiredDate: 'Client Prepayment Required Date',
  ClientPrepaymentPaidDate: 'Client Prepayment Paid Date',
  PrepaymentPaidDate: 'Prepayment Paid Date',
  PaymentRequestedDate: 'Payment Requested Date',
  PrepaymentRequiredDate: 'Prepayment Required Date',
  // PaperworkReceivedDate: 'Paperwork Received Date',
  DictationOnedriveLinkFile: 'OneDrive links to Dictation Files',
  DictationTypistID: 'Typist',
  // RequestExpectedCompletionDate: 'Expected Completion Date',
  InjuriedDate: 'Date of Injury',
};

class SupplementaryReportsStore {
  @observable loading = true;
  @observable loadingConversation = false;
  @observable supplementaryInfo = SUPP_INIT_DATA;
  @observable currentStep = 0;
  @observable IsExpectedCompletionDate = true;
  @observable suppStatusLogs = null;

  @observable listInvoice = [];
  @observable isOpenModalDisbursementFunding = false;
  @observable modalDisbursementFundingParams = {};

  @observable isReady = false;

  @observable isLockPaperword = false;

  @observable isLockReport_CM = false;
  @observable isServicePrepaymentLocked = false;
  @observable isLockReport = false;

  @observable invalidForm = [];
  @observable serviceItemTotalTax = 0;
  @observable serviceItemTotalFee = 0;

  @observable assignHistory = [];
  @observable assignCompanyHistory = [];
  @observable utcServerTime = {};

  @observable searchOptOriginalSpecialist = {
    keyword: '',
    numberItem: 30,
  };
  @observable searchOptSpecialist = {
    keyword: '',
    numberItem: 30,
  };
  @observable searchOptSuppliers = {
    keyword: '',
    numberItem: 30,
  };
  @observable searchOptBookingClient = {
    keyword: '',
    numberItem: 30,
  };
  @observable searchOptBookingSubClient = {
    keyword: '',
    numberItem: 30,
  };
  @observable searchOptBookingCM = {
    keyword: '',
    numberItem: 1000,
    companyId: 0,
    IsOnboarded: true,
  };

  @observable searchOptBookingSolicitor = {
    keyword: '',
    numberItem: 1000,
    companyId: 0,
  };
  @observable searchOptSubBookingCMs = {
    keyword: '',
    numberItem: 1000,
    companyId: 0,
  };
  @observable searchOptReportDeliveryContactSecond = {
    keyword: '',
    numberItem: 5000,
  };
  @observable searchOptPClient = { keyword: '', numberItem: 30 };
  @observable searchOptPCM = { keyword: '', numberItem: 1000, companyId: 0 };
  @observable searchOptClaimant = { keyword: '', numberItem: 30 };
  @observable searchOptPayingClient = { keyword: '', numberItem: 30 };
  @observable searchOptPayingCM = { keyword: '', numberItem: 1000, companyId: 0 };
  @observable searchOptCReference = { keyword: '', numberItem: 30 };

  @observable serviceItemId = [];

  @observable originalSpecialists = [];
  @observable deliverySecondCMs = [];
  @observable specialists = [];
  @observable suppliers = [];
  @observable bookingClients = [];
  @observable bookingCMs = [];
  @observable bookingSolicitors = [];
  @observable subBookingCMs = [];
  @observable pClients = [];
  @observable pCMs = [];
  @observable staffs = [];
  @observable typeOfClaims = [];
  @observable typist = [];
  @observable claimants = [];
  @observable imsCodes = [];
  @observable WIROPrincipleLawyers = [];
  @observable payingClients = [];
  @observable payingCMs = [];
  @observable CReferences = [];
  @observable serviceItemCodes = [];
  @observable actionRequiredList = [];
  @observable caseCorrespondences = [];
  @observable invoices = [];
  @observable lookupConfigs = [];
  @observable additionalServiceSupplierObj = {};
  //2336
  @observable bookingSubClients = [];

  @observable listCompanyInsurer = null;
  @observable keywordCompanyInsurer = '';
  @observable numberCompanyInsurerPerPage = 30;
  @observable listReasonReferral = null;
  @observable icareLookupList = null;
  //----Modal ----
  @observable isOpenModalConfirm = false;
  @observable modalParams = {};
  @observable isOpenModalHistory = false;
  @observable isOpenModalCompanyHistory = false;
  @observable open = false;
  @observable isOpenModalAction = false;
  @observable actionRequiredList = [];
  @observable modalUploadParams = {};
  @observable isOpenModalUpload = false;

  @action toggleModalModalUpload = (isOpenModalUpload, modalUploadParams = {}) => {
    this.isOpenModalUpload = isOpenModalUpload;
    this.modalUploadParams = modalUploadParams;
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.isOpenModalConfirm = isOpen;
    this.modalParams = modalParams;
  };

  @action toggleModalAction = isOpen => {
    this.isOpenModalAction = isOpen;
  };
  @observable totalPages = 0;

  //---Computed --------------------------------
  //---Claim Details
  @computed get originalSpecialistSelected() {
    const { supplementaryInfo } = this;
    const { DoctorId } = supplementaryInfo;
    return DoctorId ? this.originalSpecialists.find(i => i.Id === DoctorId) : null;
  }
  @computed get specialistSelected() {
    const { supplementaryInfo, specialists } = this;
    const { AssessedDoctorId } = supplementaryInfo;
    return AssessedDoctorId ? specialists.find(i => i.Id === AssessedDoctorId) : null;
  }
  @computed get bookingClientSelected() {
    const { supplementaryInfo, bookingClients } = this;
    const { ClientId } = supplementaryInfo;
    var client = ClientId ? bookingClients.find(i => i.Id === ClientId) : null;
    return client;
  }
  @computed get bookingSubClientSelected() {
    const { supplementaryInfo, bookingSubClients } = this;
    const { SubClientId } = supplementaryInfo;
    var client = SubClientId ? bookingSubClients.find(i => i.Id === SubClientId) : null;
    return client;
  }
  @computed get bookingCMSelected() {
    const { CaseManagerId, CaseManager } = this.supplementaryInfo;
    const cms = [CaseManager, ...this.bookingCMs];
    return CaseManagerId
      ? cms
          .filter(function(el) {
            return el;
          })
          .find(i => i.Id === CaseManagerId)
      : null;
  }
  @computed get deliveryContactFirstSelected() {
    const { F_RDCCaseManagerId, CaseManager } = this.supplementaryInfo;
    const cms = [CaseManager || {}, ...this.bookingCMs];
    return cms.find(i => i.Id === F_RDCCaseManagerId);
  }
  @computed get deliveryContactSecondSelected() {
    const { supplementaryInfo } = this;
    const { S_RDCCaseManagerId } = supplementaryInfo;
    return S_RDCCaseManagerId
      ? (api.isAdminOrMagStaffOrAccounting() ? this.deliverySecondCMs : this.bookingCMs).find(
          i => i.Id === S_RDCCaseManagerId,
        )
      : null;
  }
  @computed get bookingSolicitorSelected() {
    const { BookingSolicitorId, BookingSolicitor } = this.supplementaryInfo;
    const cms = [BookingSolicitor || {}, ...this.bookingSolicitors];
    return BookingSolicitorId ? cms.find(i => i.Id === BookingSolicitorId) : null;
  }

  @computed get pClientSelected() {
    const { supplementaryInfo, pClients } = this;
    const { PClientId, PClient } = supplementaryInfo;
    const clients = [PClient || {}, ...pClients];
    return PClientId ? clients.find(i => i.Id === PClientId) : null;
  }
  @computed get pCMSelected() {
    const { supplementaryInfo, pCMs } = this;
    const { PCaseManagerId, PCaseManager } = supplementaryInfo;
    const pcms = [PCaseManager || {}, ...pCMs];
    return PCaseManagerId ? pcms.find(i => i.Id === PCaseManagerId) : null;
  } //
  @computed get claimantSelected() {
    const { supplementaryInfo, claimants } = this;
    const { ClaimantId, Claimant } = supplementaryInfo;
    const claimts_ = [Claimant || {}, ...claimants];
    return ClaimantId ? claimts_.find(i => i.Id === ClaimantId) : null;
  }
  //---Client & Specialist Invoice
  @computed get payingClientSelected() {
    const { supplementaryInfo } = this;
    const { ServiceNotesandFeeObj } = supplementaryInfo;
    const { PayingClientId, PayingClient } = ServiceNotesandFeeObj;
    const payingclients = [PayingClient || {}, ...this.payingClients];
    return payingclients.find(i => i.Id === PayingClientId);
  }
  @computed get payingCMSelected() {
    const { supplementaryInfo, payingCMs } = this;
    const { ServiceNotesandFeeObj } = supplementaryInfo;
    const { PayingCaseManagerId, PayingCaseManager } = ServiceNotesandFeeObj;
    const payingCms = [PayingCaseManager || {}, ...payingCMs];
    return payingCms.find(i => i.Id === PayingCaseManagerId);
  }
  @computed get CReferenceSelected() {
    const { supplementaryInfo } = this;
    const { ServiceNotesandFeeObj } = supplementaryInfo;
    const { CReferenceId } = ServiceNotesandFeeObj;
    return this.CReferences.find(i => i.Id === CReferenceId);
  }
  @computed get serviceItemCodeSelected() {
    const { serviceItemId } = this;
    return !!serviceItemId.length ? this.serviceItemCodes.filter(i => serviceItemId.find(item => item === i.Id)) : null;
  }

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      const arr_ = arr.filter(i => i[key] !== item[key]);
      const item_ = toJS(item);
      return [...arr_, item_];
    } else if (key && item && item[key] && !arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  renderDateTimeForCC = (datestr, showTime) => {
    const utcServerTime = this.utcServerTime;
    const utcLocalMinutes = new Date().getTimezoneOffset();
    const addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    if (datestr) {
      if (datestr.search('/Date') !== -1) {
        const date = formatDate(datestr, true);
        return showTime
          ? moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY - HH:mm')
          : moment(date, 'DD MMM YYYY, HH:mm')
              .add(addminutes, 'minutes')
              .format('DD/MM/YYYY');
      }
      return datestr;
    }
    return datestr;
  };

  arrayToArray = (arrayF = [], arrayS = [], key) => {
    if (
      !!arrayF.length &&
      !!arrayS.length &&
      key &&
      !!arrayF.filter(i => i[key]).length &&
      !!arrayS.filter(i => i[key]).length
    ) {
      return uniqBy([...arrayF, ...arrayS], key);
    }
    return arrayS;
  };

  @action handleChangeValue = (fieldName, value) => {
    const { supplementaryInfo } = this;
    this.supplementaryInfo = { ...supplementaryInfo, [fieldName]: value };
  };

  handleCheckNCIPeriod = (specialistId, oldId) => {
    const specialist = this.specialists.find(i => i.Id === specialistId) ?? {};
    const { IsNotCurrentlyInterested, NotCurrentlyInterestedDate, NotCurrentlyInterestedEndDate } = specialist;
    if (!!IsNotCurrentlyInterested) {
      if (
        moment().isSameOrAfter(moment(NotCurrentlyInterestedDate), 'day') &&
        moment().isSameOrBefore(moment(NotCurrentlyInterestedEndDate), 'day')
      ) {
        setTimeout(() => {
          this.toggleModalConfirm(true, {
            modalType: 'confirm',
            message: 'This specialist indicates NCI during this period, do you want to continue with this booking?',
            functionCallbackCancel: action(() => {
              this.supplementaryInfo.AssessedDoctorId = oldId;
            }),
          });
        }, 500);
      }
    }
  };

  handleAssessedDoctorChange = (oldDoctorId, doctorId) => {
    const { Id } = this.supplementaryInfo;
    if (!!doctorId && api.isAdminOrMagStaffOrAccounting() && !Id) {
      customFetch('/Supplementary/GetAttachContractDoctor', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ doctorId: doctorId }),
      }).then(res => {
        if (res.status === 'success') {
          const contract = res.contract;
          let isOnboarded =
            contract.HasContractWithMag &&
            moment(contract.ContractWithMagDate).isBefore(moment()) &&
            (!contract.ContractWithMagEndDate || moment(contract.ContractWithMagEndDate).isAfter(moment()));

          if (!isOnboarded) {
            this.toggleModalConfirm(true, {
              message: 'This specialist does not have a contract with us. Do you want to continue?',
              onOk: () => {
                this.handleChangeSuppValue({ AssessedDoctorId: doctorId, SelectedAssessedDoctorProviderNoIdx: null });
                this.handleCheckNCIPeriod(doctorId, oldDoctorId);
              },
              functionCallbackCancel: () => this.handleChangeSuppValue({ AssessedDoctorId: oldDoctorId }),
            });
          } else {
            this.handleCheckNCIPeriod(doctorId, oldDoctorId);
          }
        }
      });
    } else {
      this.handleChangeSuppValue({ AssessedDoctorId: oldDoctorId });
    }
    const doctorSelected = this.specialists.find(i => i.Id === doctorId);
    if (!Id && doctorSelected?.DictationOptionStartDate) {
      const sDate = moment(doctorSelected.DictationOptionStartDate).toDate();
      if (
        doctorSelected.DictationOptionEndDate &&
        this.inRangeDate(new Date(), sDate, moment(doctorSelected.DictationOptionEndDate).toDate())
      ) {
        this.handleChangeSuppValue({ DictationOption: doctorSelected.DictationOption });
      } else if (this.inRangeDate2(new Date(), sDate)) {
        this.handleChangeSuppValue({ DictationOption: doctorSelected.DictationOption });
      } else this.handleChangeSuppValue({ DictationOption: 1 });
    } else {
      this.handleChangeSuppValue({ DictationOption: 1 });
    }
  };

  reactionCalculateInvoice = () => {};
  reactionPayingClientId = () => {};
  reactionPClientId = () => {};
  reactionClientId = () => {};
  reactionSubClientId = () => {};
  reactionF_RDCSameBookingCM = () => {};
  reactionIsExpectedCompletionDate = () => {};
  reactionCaseManagerId = () => {};
  reactionSendInvoice = () => {};
  reactionSendInvoice2 = () => {};
  reactionSameBookingCM = () => {};
  reactionSameClientAccEmail = () => {};
  reactionCalculateInvoiceOnChange = () => {};
  reactionAdminExtraFeeAmount = () => {};
  reactionServiceId = () => {};
  reactionHasOtherFee = () => {};
  reactionOtherFee1Value = () => {};
  reactionOtherFee2Value = () => {};
  reactionCommissionRateType = () => {};
  reactionAssessedDoctorId = () => {};
  reactionClientPrepaymentRequired = () => {};
  reactionClientPrepaymentRequiredDate = () => {};
  reactionClientPrepaymentPaidDate = () => {};
  reactionChargeStatus = () => {};
  reactionPrepaymentPaidDate = () => {};
  reactionPaymentRequestedDate = () => {};
  reactionPrepaymentRequiredDate = () => {};
  reactionClientPrepaymentReceived = () => {};
  reactionEmailPaymentReceiptToClient = () => {};
  reactionDoctorPrepaymentRequired = () => {};
  reactionSpecialistPrepaymentPaid = () => {};
  reactionEmailPaymentReceiptToDoctor = () => {};
  reactionEmailInvoiceToMAG = () => {};
  reactionCancelledChargeStatus = () => {};
  // reactionPaperworkReceivedDate = () =>{};
  reactionIsPaperworkReceived = () => {};
  reactionAllowDoctorToViewPaperwork = () => {};
  reactionPaperworkExLink = () => {};
  reactionPaperworkOtherExLink = () => {};
  reactionDictationOnedriveLinkFile = () => {};
  reactionDictationReceived = () => {};
  reactionDictationSentToTypist = () => {};
  reactionDictationTypistID = () => {};
  reactionReportDraftReceived = () => {};
  reactionEmailReportDraftToDoctor = () => {};
  reactionReportCompleted = () => {};
  reactionAllowCMViewReportChanged = () => {};
  reactionAllowSolicitorViewReportChanged = () => {};
  reactionAllowSpecialistViewReportChanged = () => {};
  reactionAllowSolicitorViewAmendedReportChanged = () => {};
  reactionReportReturnedFromDoctorChanged = () => {};
  reactioInjuriedDate = () => {};
  reactionIsAllowCMViewAmendedReport = () => {};
  reactionPCSameBookingClient = () => {};
  reactionPMSameBookingCM = () => {};
  autorunsearchOptPayingClient = () => {};
  autorunsearchOptClaimant = () => {};
  autorunsearchOptPClient = () => {};
  autorunsearchOptPCM = () => {};
  autorunsearchOptPayingCM = () => {};
  autorunsearchOptCReference = () => {};
  autorunsearchOptSpecialist = () => {};
  autorunsearchOptSupplier = () => {};
  autorunsearchOptSubBookingCMs = () => {};
  autorunsearchOptOriginalSpecialist = () => {};
  autorunsearchOptReportDeliveryContactSecond = () => {};
  autorunsearchOptBookingClient = () => {};
  autorunsearchOptBookingSubClient = () => {};
  autorunsearchOptBookingCM = () => {};
  autorunsearchOptBookingSolicitor = () => {};
  autorunGetCompanyInsuer = () => {};
  autorunGetReasonReferral = () => {};
  autorunGetLookupIcareReport = () => {};
  reactionChangeDoctorId = () => {};

  handleDisposeReaction = () => {
    this.autorunsearchOptPayingClient();
    this.autorunsearchOptClaimant();
    this.autorunsearchOptPClient();
    this.autorunsearchOptPCM();
    this.autorunsearchOptPayingCM();
    this.autorunsearchOptCReference();
    this.autorunsearchOptSpecialist();
    this.autorunsearchOptSupplier();
    this.autorunsearchOptSubBookingCMs();
    this.autorunsearchOptOriginalSpecialist();
    this.autorunsearchOptReportDeliveryContactSecond();
    this.autorunsearchOptBookingClient();
    this.autorunsearchOptBookingSubClient();
    this.autorunsearchOptBookingCM();
    this.autorunsearchOptBookingSolicitor();
    this.reactionCalculateInvoice();
    this.reactionPayingClientId();
    this.reactionPClientId();
    this.reactionClientId();
    this.reactionSubClientId();
    this.reactionF_RDCSameBookingCM();
    this.reactionIsExpectedCompletionDate();
    this.reactionCaseManagerId();
    this.reactionSendInvoice();
    this.reactionSendInvoice2();
    this.reactionSameBookingCM();
    this.reactionSameClientAccEmail();
    this.reactionCalculateInvoiceOnChange();
    this.reactionAdminExtraFeeAmount();
    this.reactionServiceId();
    this.reactionHasOtherFee();
    this.reactionOtherFee1Value();
    this.reactionOtherFee2Value();
    this.reactionCommissionRateType();
    this.reactionAssessedDoctorId();
    this.reactionClientPrepaymentRequired();
    this.reactionClientPrepaymentRequiredDate();
    this.reactionClientPrepaymentPaidDate();
    this.reactionChargeStatus();
    this.reactionPrepaymentPaidDate();
    this.reactionPaymentRequestedDate();
    this.reactionPrepaymentRequiredDate();
    this.reactionClientPrepaymentReceived();
    this.reactionEmailPaymentReceiptToClient();
    this.reactionDoctorPrepaymentRequired();
    this.reactionSpecialistPrepaymentPaid();
    this.reactionEmailPaymentReceiptToDoctor();
    this.reactionEmailInvoiceToMAG();
    this.reactionCancelledChargeStatus();
    // this.reactionPaperworkReceivedDate();
    this.reactionIsPaperworkReceived();
    this.reactionAllowDoctorToViewPaperwork();
    this.reactionPaperworkExLink();
    this.reactionPaperworkOtherExLink();
    this.reactionDictationOnedriveLinkFile();
    this.reactionDictationReceived();
    // this.reactionDictationSentToTypist();
    this.reactionDictationTypistID();
    this.reactionReportDraftReceived();
    this.reactionEmailReportDraftToDoctor();
    this.reactionReportCompleted();
    this.reactionAllowCMViewReportChanged();
    this.reactionAllowSolicitorViewReportChanged();
    this.reactionAllowSpecialistViewReportChanged();
    this.reactionAllowSolicitorViewAmendedReportChanged();
    this.reactionReportReturnedFromDoctorChanged();
    this.reactioInjuriedDate();
    this.reactionIsAllowCMViewAmendedReport();
    this.reactionPCSameBookingClient();
    this.reactionPMSameBookingCM();
    this.autorunGetCompanyInsuer();
    this.autorunGetReasonReferral();
    this.autorunGetLookupIcareReport();
    this.reactionChangeDoctorId();
  };

  //---Auto Run --------------------------------
  functionAutoRun = () => {
    this.reactionChangeDoctorId = reaction(
      () => this.supplementaryInfo.AssessedDoctorId,
      () => {
        this.handleCheckICare();
      },
    );
    this.reactioInjuriedDate = reaction(
      () => this.supplementaryInfo.InjuriedDate,
      () => {
        if (!this.supplementaryInfo.InjuriedDate) {
          return;
        } else {
          if (moment(this.supplementaryInfo.InjuriedDate).isSameOrBefore(moment())) {
            return;
          } else {
            this.showNotification('Error', 'Date of Injury cannot be a future date');
            this.handleChangeSuppValue({ InjuriedDate: null });
          }
        }
      },
    );
    this.reactionCalculateInvoice = when(
      () => this.isReady,
      () => {
        this.handleCalculateInvoice();
      },
    );
    this.reactionPayingClientId = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.PayingClientId,
      () => {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        const { PayingClientId } = ServiceNotesandFeeObj;
        this.handleChangeSearchOpt({ companyId: PayingClientId }, 'searchOptPayingCM');
        return action(() => {
          this.supplementaryInfo.ServiceNotesandFeeObj.PayingCaseManagerId = null;
        })();
      },
    );
    this.reactionPClientId = reaction(
      () => this.supplementaryInfo.PClientId,
      () => {
        const { supplementaryInfo } = this;
        const { PClientId } = supplementaryInfo;
        this.handleChangeSearchOpt({ companyId: PClientId }, 'searchOptPCM');
        this.handleChangeSuppValue({ PMSameBookingCM: false });
        return action(() => {
          this.supplementaryInfo.PCaseManagerId = null;
          this.supplementaryInfo.PCaseManager = null;
        })();
      },
    );
    this.reactionClientId = reaction(
      () => this.supplementaryInfo.ClientId,
      () => {
        const { supplementaryInfo } = this;
        const { ClientId, SubClientId } = supplementaryInfo;
        this.handleChangeSearchOpt({ companyId: ClientId }, 'searchOptBookingCM');
        this.handleChangeSearchOpt({ companyId: ClientId }, 'searchOptBookingSolicitor');
        if (!this.supplementaryInfo.Id || this.supplementaryInfo.Id <= 0) {
          var customer = this.bookingClients.find(i => i.Id === ClientId);
          if (customer.ReportDeliveryContact) {
            this.supplementaryInfo.S_RDCCaseManagerId = customer.ReportDeliveryContact;
          }
        }
        return action(() => {
          if (!api.isCaseManager()) {
            this.supplementaryInfo.CaseManagerId = null;
            this.supplementaryInfo.BookingSolicitorId = null;
          }

          if (!api.isAdminOrMagStaffOrAccounting()) {
            this.supplementaryInfo.S_RDCCaseManagerId = null;
          }
          this.supplementaryInfo.F_RDCCaseManagerId = null;
          this.supplementaryInfo.PCaseManagerId = null;
          this.supplementaryInfo.PCaseManager = null;
          if (this.supplementaryInfo.PCSameBookingClient) {
            this.supplementaryInfo.PClientId = this.bookingClientSelected?.Id ?? null;
            this.supplementaryInfo.PClient = { ...this.bookingClientSelected };
          }
        })();
      },
    );
    this.reactionSubClientId = reaction(
      () => this.supplementaryInfo.SubClientId,
      () => {
        const { supplementaryInfo } = this;
        const { SubClientId } = supplementaryInfo;
        this.handleChangeSearchOpt({ companyId: SubClientId }, 'searchOptBookingSolicitor');
      },
    );
    this.reactionF_RDCSameBookingCM = reaction(
      () => this.supplementaryInfo.F_RDCSameBookingCM,
      () => {
        return action(() => {
          this.supplementaryInfo.F_RDCEmail = this.bookingCMSelected?.Email || '';
          this.supplementaryInfo.F_RDCCaseManagerId = this.bookingCMSelected?.Id || null;
        })();
      },
    );
    this.reactionIsExpectedCompletionDate = reaction(
      () => this.supplementaryInfo.IsExpectedCompletionDate,
      () => {
        // if (this.supplementaryInfo.IsExpectedCompletionDate) {
        //   this.validateField(
        //     'RequestExpectedCompletionDate',
        //     this.supplementaryInfo.RequestExpectedCompletionDate,
        //     'required',
        //   );
        // } else {
        //   this.removeError('RequestExpectedCompletionDate');
        // }
      },
    );

    this.reactionCaseManagerId = reaction(
      () => this.supplementaryInfo.CaseManagerId,
      () => {
        this.handleChangeSuppValue({ F_RDCSameBookingCM: !!this.supplementaryInfo.CaseManagerId });
        return action(() => {
          if (this.supplementaryInfo.F_RDCSameBookingCM) {
            this.supplementaryInfo.F_RDCEmail = this.bookingCMSelected?.Email || '';
            this.supplementaryInfo.F_RDCCaseManagerId = this.bookingCMSelected?.Id || null;
          }

          if (this.supplementaryInfo.PMSameBookingCM) {
            this.supplementaryInfo.PCaseManagerId = this.bookingCMSelected?.Id ?? null;
            this.supplementaryInfo.PCaseManager = { ...this.bookingCMSelected };
          }
        })();
      },
    );

    this.reactionPCSameBookingClient = reaction(
      () => this.supplementaryInfo.PCSameBookingClient,
      () =>
        action(() => {
          this.supplementaryInfo.PClientId = this.bookingClientSelected?.Id ?? null;
          this.supplementaryInfo.PClient = { ...this.bookingClientSelected };
        })(),
    );

    this.reactionPMSameBookingCM = reaction(
      () => this.supplementaryInfo.PMSameBookingCM,
      () =>
        action(() => {
          this.supplementaryInfo.PCaseManagerId = this.bookingCMSelected?.Id ?? null;
          this.supplementaryInfo.PCaseManager = { ...this.bookingCMSelected };
        })(),
    );

    //----auto run search opt
    this.autorunsearchOptPayingClient = autorun(() => {
      const { searchOptPayingClient } = this;
      return getClient(searchOptPayingClient)
        .then(action(res => (this.payingClients = res.itemList)))
        .catch(() => {});
    });
    this.autorunsearchOptClaimant = autorun(() => {
      const { searchOptClaimant } = this;
      return getClaimant(searchOptClaimant)
        .then(action(res => (this.claimants = res.itemList)))
        .catch(() => {});
    });

    this.autorunsearchOptPClient = autorun(() => {
      const { searchOptPClient } = this;
      return getClient(searchOptPClient)
        .then(action(res => (this.pClients = res.itemList)))
        .catch(() => {});
    });

    this.autorunsearchOptPCM = autorun(() => {
      const { searchOptPCM } = this;
      if (searchOptPCM.companyId) {
        return getCaseManagerOnboarded(searchOptPCM)
          .then(action(res => (this.pCMs = res.itemList)))
          .catch(() => {});
      } else {
        return action(() => (this.pCMs = []))();
      }
    });

    this.autorunsearchOptReportDeliveryContactSecond = autorun(() => {
      const { searchOptReportDeliveryContactSecond } = this;
      if (api.isAdminOrMagStaffOrAccounting()) {
        return getCaseManagerOnboarded(searchOptReportDeliveryContactSecond)
          .then(action(res => (this.deliverySecondCMs = res.itemList)))
          .catch(() => {});
      } else {
        return getCaseManagerOnboarded({
          ...searchOptReportDeliveryContactSecond,
          companyId: api.currentUser.data.ClientId,
        })
          .then(action(res => (this.deliverySecondCMs = res.itemList)))
          .catch(() => {});
      }
    });

    this.autorunsearchOptBookingClient = autorun(() => {
      const { searchOptBookingClient } = this;
      return getClient(searchOptBookingClient)
        .then(action(res => (this.bookingClients = res.itemList)))
        .catch(() => {});
    });

    this.autorunsearchOptBookingSubClient = autorun(() => {
      const { searchOptBookingSubClient } = this;
      return getClient(searchOptBookingSubClient)
        .then(action(res => (this.bookingSubClients = res.itemList)))
        .catch(() => {});
    });

    this.autorunsearchOptBookingCM = autorun(() => {
      const { searchOptBookingCM } = this;
      if (searchOptBookingCM.companyId) {
        return getCaseManagerOnboarded(searchOptBookingCM)
          .then(action(res => (this.bookingCMs = res.itemList)))
          .catch(() => {});
      } else {
        return action(() => (this.bookingCMs = []))();
      }
    });
    this.autorunsearchOptBookingSolicitor = autorun(() => {
      const { searchOptBookingSolicitor } = this;
      if (searchOptBookingSolicitor.companyId) {
        return getCaseManagerOnboarded(searchOptBookingSolicitor)
          .then(action(res => (this.bookingSolicitors = res.itemList)))
          .catch(() => {});
      } else {
        return action(() => (this.bookingSolicitors = []))();
      }
    });
    this.autorunsearchOptOriginalSpecialist = autorun(() => {
      const { searchOptOriginalSpecialist } = this;
      return getSpecialist(searchOptOriginalSpecialist)
        .then(action(res => (this.originalSpecialists = res.itemList)))
        .catch(() => {});
    });
    this.autorunsearchOptSubBookingCMs = autorun(() => {
      const { searchOptSubBookingCMs } = this;
      return getCaseManagerOnboarded(searchOptSubBookingCMs).then(action(res => (this.subBookingCMs = res.itemList)));
    });

    this.autorunsearchOptSpecialist = autorun(() => {
      const { searchOptSpecialist } = this;
      return getSpecialist(searchOptSpecialist)
        .then(action(res => (this.specialists = res.itemList)))
        .catch(() => {});
    });
    this.autorunsearchOptSupplier = autorun(() => {
      const { searchOptSuppliers } = this;
      return getSpecialist(searchOptSuppliers)
        .then(action(res => (this.suppliers = res.itemList)))
        .catch(() => {});
    });
    this.autorunsearchOptCReference = autorun(() => {
      const { searchOptCReference } = this;
      return getClient(searchOptCReference)
        .then(action(res => (this.CReferences = res.itemList)))
        .catch(() => {});
    });

    this.autorunsearchOptPayingCM = autorun(() => {
      const { searchOptPayingCM } = this;
      return getCaseManagerOnboarded(searchOptPayingCM)
        .then(action(res => (this.payingCMs = res.itemList)))
        .catch(() => {});
    });

    this.autorunGetCompanyInsuer = autorun(() => {
      const { keywordCompanyInsurer, numberCompanyInsurerPerPage } = this;
      return getCompanyInsuer(keywordCompanyInsurer, numberCompanyInsurerPerPage)
        .then(action(res => (this.listCompanyInsurer = res.itemList)))
        .catch(() => {});
    });

    this.autorunGetReasonReferral = autorun(() => {
      return getLookUpConfig('ReasonReferral')
        .then(action(res => (this.listReasonReferral = res.reasonReferralItemList)))
        .catch(() => {});
    });

    this.autorunGetLookupIcareReport = autorun(() => {
      return getLookupIcareReport()
        .then(action(res => (this.icareLookupList = res)))
        .catch(() => {});
    });

    this.reactionSendInvoice = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.SendInvoice,
      () => {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        const { SendInvoice } = ServiceNotesandFeeObj;
        this.validateField('SendInvoice', SendInvoice, 'email');
        return;
      },
    );

    this.reactionSendInvoice2 = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.SendInvoice2,
      () => {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        const { SendInvoice2 } = ServiceNotesandFeeObj;
        this.validateField('SendInvoice2', SendInvoice2, 'email');
        return;
      },
    );

    this.reactionSameBookingCM = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.SameBookingCM,
      () => {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        const { SameBookingCM } = ServiceNotesandFeeObj;
        this.handleSameBCMSendInvoice(SameBookingCM);
        return;
      },
    );
    this.reactionSameClientAccEmail = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.SameClientAccEmail,
      () => {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        const { SameClientAccEmail } = ServiceNotesandFeeObj;
        this.handleSameACESendInvoice(SameClientAccEmail);
        return;
      },
    );

    this.reactionCalculateInvoiceOnChange = reaction(
      () =>
        this.supplementaryInfo.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission ||
        this.supplementaryInfo.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission ||
        this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage ||
        this.supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage ||
        this.supplementaryInfo.ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission ||
        this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee2TaxPercentage ||
        this.supplementaryInfo.ServiceNotesandFeeObj.CommissionRate ||
        this.supplementaryInfo.ServiceNotesandFeeObj.CommissionOnTopRate,
      () => {
        this.handleCalculateInvoice();
      },
    );

    this.reactionAdminExtraFeeAmount = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount,
      () => {
        let tax = this.supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount * 0.1;
        this.handleChangeServiceNotesandFeeObj({
          AdminExtraFeeTaxPercentage: tax.toFixed(2),
        });
        this.handleCalculateInvoice();
      },
    );

    this.reactionServiceId = reaction(
      () => this.serviceItemId,
      () => {
        this.handleChangeServiceNotesandFeeObj({
          ListServiceItems: this.serviceItemCodeSelected,
        });
        this.handleCalculateInvoice();
      },
    );

    this.reactionHasOtherFee = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.HasOtherFee,
      () => {
        this.handleChangeServiceNotesandFeeObj({
          OtherFee1Value: 0,
          OtherFee2Value: 0,
        });
      },
    );

    this.reactionOtherFee1Value = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Value,
      () => {
        let tax = this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Value * 0.1;

        this.handleChangeServiceNotesandFeeObj({
          OtherFee1TaxPercentage: tax.toFixed(2),
        });
        this.handleCalculateInvoice();
      },
    );

    this.reactionOtherFee2Value = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee2Value,
      () => {
        let tax = this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee2Value * 0.1;
        this.handleChangeServiceNotesandFeeObj({
          OtherFee2TaxPercentage: tax.toFixed(2),
        });
        this.handleCalculateInvoice();
      },
    );

    this.reactionCommissionRateType = reaction(
      () => this.supplementaryInfo.ServiceNotesandFeeObj.CommissionRateType,
      () => this.handleCommissionRateTypeChange(),
    );

    this.reactionAssessedDoctorId = reaction(
      () => this.supplementaryInfo.AssessedDoctorId,
      () => {
        this.handleCommissionRateTypeChange();
      },
    );

    this.reactionClientPrepaymentRequired = reaction(
      () => this.supplementaryInfo.ClientPrepaymentRequired,
      () => {
        this.ClientPrepaymentRequiredChange();
      },
    );

    this.reactionClientPrepaymentRequiredDate = reaction(
      () => this.supplementaryInfo.ClientPrepaymentRequiredDate,
      () => {
        if (this.supplementaryInfo.ClientPrepaymentRequired) {
          this.validateField(
            'ClientPrepaymentRequiredDate',
            this.supplementaryInfo.ClientPrepaymentRequiredDate,
            'required',
          );
        } else {
          this.removeError('ClientPrepaymentRequiredDate');
        }
      },
    );

    this.reactionClientPrepaymentPaidDate = reaction(
      () => this.supplementaryInfo.ClientPrepaymentPaidDate,
      () => {
        if (this.supplementaryInfo.ClientPrepaymentReceived) {
          this.validateField('ClientPrepaymentPaidDate', this.supplementaryInfo.ClientPrepaymentPaidDate, 'required');
        } else {
          this.removeError('ClientPrepaymentPaidDate');
        }
      },
    );

    this.reactionChargeStatus = reaction(
      () => this.supplementaryInfo.ChargeStatus,
      () => this.SetDate(),
    );
    this.reactionPrepaymentPaidDate = reaction(
      () => this.supplementaryInfo.PrepaymentPaidDate,
      () => {
        if (this.supplementaryInfo.SpecialistPrepaymentPaid) {
          this.validateField('PrepaymentPaidDate', this.supplementaryInfo.PrepaymentPaidDate, 'required');
        } else {
          this.removeError('PrepaymentPaidDate');
        }
      },
    );
    this.reactionPaymentRequestedDate = reaction(
      () => this.supplementaryInfo.PaymentRequestedDate,
      () => {
        if (this.supplementaryInfo.PrepaymentRequired) {
          this.validateField('PaymentRequestedDate', this.supplementaryInfo.PaymentRequestedDate, 'required');
        } else {
          this.removeError('PaymentRequestedDate');
        }
      },
    );

    this.reactionPrepaymentRequiredDate = reaction(
      () => this.supplementaryInfo.PrepaymentRequiredDate,
      () => {
        if (this.supplementaryInfo.DoctorPrepaymentRequired) {
          this.validateField('PrepaymentRequiredDate', this.supplementaryInfo.PrepaymentRequiredDate, 'required');
        } else {
          this.removeError('PrepaymentRequiredDate');
        }
      },
    );

    this.reactionClientPrepaymentReceived = reaction(
      () => this.supplementaryInfo.ClientPrepaymentReceived,
      () => {
        this.supplementaryInfo.ClientPrepaymentPaidDate = this.supplementaryInfo.ClientPrepaymentReceived
          ? moment()
          : null;
      },
    );
    this.reactionEmailPaymentReceiptToClient = reaction(
      () => this.supplementaryInfo.EmailPaymentReceiptToClient,
      () => {
        if (
          !this.supplementaryInfo.ServiceNotesandFeeObj.SendInvoice &&
          !this.supplementaryInfo.ServiceNotesandFeeObj.SendInvoice2
        ) {
          this.showNotification('Error', 'Please input email for Email For Sending Invoices.', 'error');
          this.supplementaryInfo.EmailPaymentReceiptToClient = false;
        }
      },
    );

    this.reactionDoctorPrepaymentRequired = reaction(
      () => this.supplementaryInfo.DoctorPrepaymentRequired,
      () => this.handleActiveDoctorPrepaymentRequired(),
    );
    this.reactionSpecialistPrepaymentPaid = reaction(
      () => this.supplementaryInfo.SpecialistPrepaymentPaid,
      () =>
        (this.supplementaryInfo.PrepaymentPaidDate = this.supplementaryInfo.SpecialistPrepaymentPaid ? moment() : null),
    );
    this.reactionEmailPaymentReceiptToDoctor = when(
      () => this.supplementaryInfo.EmailPaymentReceiptToDoctor,
      () => {
        const attachments = this.renderAttachment(this.supplementaryInfo.Attachments, 15);
        if (!attachments.length) {
          this.showNotification('Error', 'Payment receipt must be attached first', 'error');
          return (this.supplementaryInfo.EmailPaymentReceiptToDoctor = false);
        }
      },
    );
    this.reactionEmailInvoiceToMAG = when(
      () => this.supplementaryInfo.EmailInvoiceToMAG,
      () => {
        const attachments = this.renderAttachment(this.supplementaryInfo.Attachments, 14);
        if (this.supplementaryInfo.EmailInvoiceToMAG && !attachments.length) {
          this.showNotification('Error', 'Provider invoice must be attached first', 'error');
          return (this.supplementaryInfo.EmailPaymentReceiptToDoctor = false);
        }
      },
    );
    this.reactionCancelledChargeStatus = reaction(
      () => this.supplementaryInfo.CancelledChargeStatus,
      () => this.CancelledChange(),
    );

    // this.reactionPaperworkReceivedDate = reaction(
    //   () => this.supplementaryInfo.PaperworkReceivedDate,
    //   () => {
    //     if (this.supplementaryInfo.IsPaperworkReceived) {
    //       this.validateField('PaperworkReceivedDate', this.supplementaryInfo.PaperworkReceivedDate, 'required');
    //     } else {
    //       this.removeError('PaperworkReceivedDate');
    //     }
    //   },
    // );

    this.reactionIsPaperworkReceived = reaction(
      () => this.supplementaryInfo.IsPaperworkReceived,
      () => this.onIsPaperworkReceivedChanged(),
    );

    this.reactionAllowDoctorToViewPaperwork = reaction(
      () => this.supplementaryInfo.AllowDoctorToViewPaperwork,
      () => this.onAllowDoctorToViewPaperworkChange(),
    );

    this.reactionPaperworkExLink = reaction(
      () => this.supplementaryInfo.PaperworkExLink,
      () => this.validateField('PaperworkExLink', this.supplementaryInfo.PaperworkExLink, 'url'),
    );

    this.reactionPaperworkOtherExLink = reaction(
      () => this.supplementaryInfo.PaperworkOtherExLink,
      () => this.validateField('PaperworkOtherExLink', this.supplementaryInfo.PaperworkOtherExLink, 'url'),
    );

    this.reactionDictationOnedriveLinkFile = reaction(
      () => this.supplementaryInfo.DictationOnedriveLinkFile,
      () => this.validateField('DictationOnedriveLinkFile', this.supplementaryInfo.DictationOnedriveLinkFile, 'url'),
    );

    this.reactionDictationReceived = reaction(
      () => this.supplementaryInfo.DictationReceived,
      () => this.DictationReceivedChanged(),
    );

    this.reactionDictationTypistID = reaction(
      () => this.supplementaryInfo.DictationTypistID,
      () => {
        if (this.supplementaryInfo.DictationSentToTypist) {
          this.validateField('DictationTypistID', this.supplementaryInfo.DictationTypistID, 'required');
        } else {
          this.removeError('DictationTypistID');
        }
      },
    );
    this.reactionReportDraftReceived = reaction(
      () => this.supplementaryInfo.ReportDraftReceived,
      () => {
        this.ReportDraftReceivedChanged();
      },
    );

    this.reactionEmailReportDraftToDoctor = reaction(
      () => this.supplementaryInfo.EmailReportDraftToDoctor,
      () => {
        this.EmailReportDraftToDoctorChange();
      },
    );
    this.reactionReportCompleted = reaction(
      () => this.supplementaryInfo.ReportCompleted,
      () => this.ReportCompletedChange(),
    );
    this.reactionAllowCMViewReportChanged = reaction(
      () => this.supplementaryInfo.IsAllowCMViewReport,
      () => this.AllowCMViewReportChanged(),
    );
    this.reactionAllowSolicitorViewReportChanged = reaction(
      () => this.supplementaryInfo.IsAllowSolicitorViewReport,
      () => this.AllowSolicitorViewReportChanged(),
    );
    this.reactionAllowSpecialistViewReportChanged = reaction(
      () => this.supplementaryInfo.IsAllowSpecialistViewReport,
      () => this.AllowSpecialistViewReportChanged(),
    );
    this.reactionAllowSolicitorViewAmendedReportChanged = reaction(
      () => this.supplementaryInfo.IsAllowSolicitorViewAmendedReport,
      () => this.AllowSolicitorViewAmendedReportChanged(),
    );
    this.reactionIsAllowCMViewAmendedReport = reaction(
      () => this.supplementaryInfo.IsAllowCMViewAmendedReport,
      () => this.AllowCMViewAmendedReportChanged(),
    );
    this.reactionReportReturnedFromDoctorChanged = reaction(
      () => this.supplementaryInfo.ReportReturnedFromDoctor,
      () => {
        if (this.supplementaryInfo.ReportReturnedFromDoctor) {
          this.ReportReturnedFromDoctorChanged();
        }
      },
    );
  };
  //---Action   --------------------------------

  @action AllowCMViewAmendedReportChanged = () => {
    if (
      this.checkDelayPrepaymentRequired() &&
      this.supplementaryInfo.CaseManager &&
      this.deliverySecondCMs &&
      this.deliverySecondCMs.length > 0
    ) {
      const completeAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 4);
      if (this.supplementaryInfo.IsAllowCMViewAmendedReport) {
        // if (!this.supplementaryInfo.F_RDCEmail && !this.supplementaryInfo.F_RDCEmail) {
        //   this.showNotification('Error', 'At least one Report Delivery Contact needs to be completed');
        //   this.supplementaryInfo.IsAllowCMViewAmendedReport = false;
        //   return;
        // }
        if (!completeAttachment.length) {
          this.showNotification('Error', 'You need to attach amended reports first!');
          this.supplementaryInfo.IsAllowCMViewAmendedReport = false;
        } else {
          if (!(this.supplementaryInfo.DelayPrepaymentRequired && !this.supplementaryInfo.ClientPrepaymentPaidDate)) {
            this.toggleModalConfirm(true, {
              message: 'Are you sure to allow Client Receiving Contact 2 to view amended reports?',
              onOk: () =>
                this.handleChangeSuppValue({
                  IsAllowCMViewAmendedReport: true,
                }),
              functionCallbackCancel: () =>
                this.handleChangeSuppValue({
                  IsAllowCMViewAmendedReport: false,
                }),
            });
          }
        }
      }
    }
  };

  @action AllowSolicitorViewAmendedReportChanged = () => {
    if (
      this.checkDelayPrepaymentRequired() &&
      this.supplementaryInfo.CaseManager &&
      this.bookingCMs &&
      this.bookingCMs.length > 0
    ) {
      if (
        this.supplementaryInfo.F_RDCCaseManagerId &&
        !(this.supplementaryInfo.DelayPrepaymentRequired && !this.supplementaryInfo.ClientPrepaymentPaidDate)
      ) {
        if (this.supplementaryInfo.IsAllowSolicitorViewAmendedReport) {
          const completeAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 4);
          if (!completeAttachment.length) {
            this.showNotification('Error', 'You need to attach amended reports first!');
            this.supplementaryInfo.IsAllowSolicitorViewAmendedReport = false;
            return;
          } else {
            this.toggleModalConfirm(true, {
              message: 'Are you sure to allow Delivery Contact 1 to view amended reports?',
              onOk: () =>
                this.handleChangeSuppValue({
                  IsAllowSolicitorViewAmendedReport: true,
                }),
              functionCallbackCancel: () =>
                this.handleChangeSuppValue({
                  IsAllowSolicitorViewAmendedReport: false,
                }),
            });
          }
        }
      } else {
        this.supplementaryInfo.IsAllowSolicitorViewAmendedReport = false;
      }
    }
  };

  @action AllowCMViewReportChanged = () => {
    if (
      this.checkDelayPrepaymentRequired() &&
      this.supplementaryInfo.CaseManager &&
      this.deliverySecondCMs &&
      this.deliverySecondCMs.length > 0
    ) {
      const completeAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 0);
      if (this.supplementaryInfo.IsAllowCMViewReport) {
        if (!completeAttachment.length) {
          this.showNotification('Error', 'You need to attach final reports first!');
          this.supplementaryInfo.IsAllowSolicitorViewReport = false;
          return;
        }
        if (!(this.supplementaryInfo.DelayPrepaymentRequired && !this.supplementaryInfo.ClientPrepaymentPaidDate)) {
          this.toggleModalConfirm(true, {
            message: 'Are you sure to allow Client Receiving Contact 2 to view completed reports?',
            onOk: () => this.handleChangeSuppValue({ IsAllowCMViewReport: true }),
            functionCallbackCancel: () => this.handleChangeSuppValue({ IsAllowCMViewReport: false }),
          });
        }
        this.supplementaryInfo.ReportCompleted = true;
      }
    }
  };

  @action AllowSpecialistViewReportChanged = () => {
    if (this.checkDelayPrepaymentRequired()) {
      const completeAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 0);
      if (this.supplementaryInfo.IsAllowSpecialistViewReport) {
        if (!completeAttachment.length) {
          this.showNotification('Error', 'You need to attach final reports first!');
          this.supplementaryInfo.IsAllowSpecialistViewReport = false;
          return;
        }
        if (!(this.supplementaryInfo.DelayPrepaymentRequired && !this.supplementaryInfo.ClientPrepaymentPaidDate)) {
          this.toggleModalConfirm(true, {
            message: 'Are you sure to allow specialist to view completed reports?',
            onOk: () => this.handleChangeSuppValue({ IsAllowCMViewReport: true }),
            functionCallbackCancel: () => this.handleChangeSuppValue({ IsAllowSpecialistViewReport: false }),
          });
        }
      }
    }
  };

  @action AllowSolicitorViewReportChanged = () => {
    if (
      this.checkDelayPrepaymentRequired() &&
      this.supplementaryInfo.CaseManager &&
      this.bookingCMs &&
      this.bookingCMs.length > 0
    ) {
      const completeAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 0);
      if (this.supplementaryInfo.IsAllowSolicitorViewReport) {
        if (!completeAttachment.length) {
          this.showNotification('Error', 'You need to attach final reports first!');
          this.supplementaryInfo.IsAllowSolicitorViewReport = false;
          return;
        }

        this.toggleModalConfirm(true, {
          message: 'Are you sure to allow Client Receiving Contact 1 to view completed reports?',
          onOk: () => this.handleChangeSuppValue({ IsAllowSolicitorViewReport: true }),
          functionCallbackCancel: () => this.handleChangeSuppValue({ IsAllowSolicitorViewReport: false }),
        });
        this.supplementaryInfo.ReportCompleted = true;
      }
    }
  };

  ReportCompletedChange = () => {
    if (this.checkDelayPrepaymentRequired()) {
      this.supplementaryInfo.ReportCompletedSentDate = moment();
      console.log(this.supplementaryInfo.ChargeStatus);
      if (this.supplementaryInfo.ReportCompleted && this.supplementaryInfo.ChargeStatus === 4) {
        this.supplementaryInfo.ChargeStatus = 0;
        this.SetDate();
      }
      // if (this.supplementaryInfo.ReportCompleted) {
      //   if (this.supplementaryInfo.ChargeStatus !== 0) {
      //     this.supplementaryInfo.ChargeStatus = 0;
      //     this.SetDate();
      //   }
      // } else {
      //   this.supplementaryInfo.ChargeStatus = 4;
      //   this.SetDate();
      // }
    }
    return;
  };

  @action checkDelayPrepaymentRequired = () => {
    if (this.supplementaryInfo.DelayPrepaymentRequired && !this.supplementaryInfo.ClientPrepaymentPaidDate) {
      this.showNotification(
        'Error',
        'This case has delayed sending report to client. Please check under Client & Specialist Payments.',
        'error',
      );
      this.supplementaryInfo.ReportCompleted = false;
      this.supplementaryInfo.IsAllowSolicitorViewReport = false;
      this.supplementaryInfo.IsAllowCMViewReport = false;
      this.supplementaryInfo.IsAllowSolicitorViewAmendedReport = false;
      this.supplementaryInfo.IsAllowCMViewAmendedReport = false;
      return false;
    }
    return true;
  };

  @action SetDate = () => {
    switch (this.supplementaryInfo.ChargeStatus) {
      case 4:
        // this.supplementaryInfo.ReportCompletedSentDate = null;
        this.supplementaryInfo.CancelledDate = null;
        break;
      case 0:
        //this.supplementaryInfo.CompletionDate = new Date();
        //this.supplementaryInfo.ReportCompletedSentDate = new Date();
        this.supplementaryInfo.CancelledDate = null;

        if (this.CheckWiRo()) {
          this.ProcessWiroWarning(2);
        }
        break;
      case 1:
        //this.supplementaryInfo.ReportCompletedSentDate = new Date();
        this.supplementaryInfo.CancelledDate = null;
        break;
      case 2:
        this.supplementaryInfo.ReportCompletedSentDate = null;
        this.supplementaryInfo.CancelledDate = moment();
        break;
      case 3:
        this.supplementaryInfo.ReportCompletedSentDate = null;
        this.supplementaryInfo.CancelledDate = moment();
        break;
      default:
        break;
    }
  };

  CheckWiRo = () => {
    let bRet = false;
    if (
      this.supplementaryInfo.WiroIlars &&
      (!this.supplementaryInfo.IlarsRef ||
        !this.supplementaryInfo.IMSApprovalCode ||
        !this.supplementaryInfo.IlarsWiroSolicitor)
    ) {
      bRet = true;
    }
    if (!bRet) {
      if (
        !this.supplementaryInfo.WiroIlars &&
        this.bookingClientSelected?.ClientType === 3 &&
        this.bookingClientSelected?.StateName === 'NSW' &&
        this.claimantSelected?.Id === 13
      ) {
        bRet = true;
      }
    }
    //}
    return bRet;
  };

  sendWarningWiro = () => {
    var objSend = {
      RecordId: this.supplementaryInfo.Id,
      IlarsRef: this.supplementaryInfo.IlarsRef,
      IMSApprovalCode: this.supplementaryInfo.IMSApprovalCode,
      IlarsWiroSolicitorName: this.supplementaryInfo.IlarsWiroSolicitor,
    };
    customFetch('/Supplementary/SendWiroMissing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...objSend }),
    }).then(res => {
      if (res.status === 'success') {
        notification.success({
          message: 'IRO Reminders',
          description: 'IRO reminders to Booking CM successfully!',
        });
      } else {
        notification.error({
          message: 'IRO Reminders',
          description: 'IRO reminders to Booking CM error!',
        });
      }
    });
  };

  ProcessWiroWarning = type => {
    let msg =
      'IRO information for this case has not been provided and payment may not be approved. Do you want to send IRO reminder now?';
    if (type === 2) {
      msg =
        'IRO information for this case has not been provided and payment may not be approved. Do you still want to send the report?';
    }
    return this.toggleModalConfirm(true, {
      message: msg,
      onOk: () => {
        if (type === 1) {
          this.handleChangeSuppValue({
            ReportReturnedFromDoctor: false,
            CompletionDate: null,
          });
          this.sendWarningWiro();
        }
        // if (type === 2) {
        //   setTimeout(() => {
        //     this.toggleModalConfirm(true, {
        //       message: 'Do you want to send IRO reminder to booking CM now?',
        //       onOk: () => {
        //         this.sendWarningWiro();
        //       },
        //     });
        //   }, 1000);
        // }
      },
      functionCallbackCancel: () => {
        if (type === 2) {
          this.handleChangeSuppValue({
            ReportCompleted: false,
            ReportCompletedSentDate: null,
            ChargeStatus: 4,
          });
          setTimeout(() => {
            this.toggleModalConfirm(true, {
              message: 'Do you want to send IRO reminder to booking CM now?',
              onOk: () => {
                this.sendWarningWiro();
              },
            });
          }, 1000);
        }
      },
    });
  };

  ReportReturnedFromDoctorChanged = () => {
    if (this.supplementaryInfo.ReportReturnedFromDoctor) {
      this.supplementaryInfo.CompletionDate = moment();
      if (this.CheckWiRo()) {
        this.ProcessWiroWarning(1);
      }
    }
  };

  @action EmailReportDraftToDoctorChange = () => {
    const reportDraftReceivedAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 6);
    if (this.supplementaryInfo.EmailReportDraftToDoctor) {
      if (!reportDraftReceivedAttachment.length) {
        this.showNotification('Error', 'You must attach report draft to send to Specialist.', 'error');
        this.supplementaryInfo.EmailReportDraftToDoctor = false;
      }
    }
    if (this.specialistSelected && this.specialistSelected.Email2 && this.supplementaryInfo.EmailReportDraftToDoctor) {
      this.supplementaryInfo.EmailReportDraftToDoctorBE = true;
    }
  };

  @action ReportDraftReceivedChanged = () => {
    const reportDraftReceivedAttachment = this.supplementaryInfo.Attachments.filter(i => i.Type === 6);
    if (this.supplementaryInfo.ReportDraftReceived) {
      this.supplementaryInfo.ReportDraftReceivedDate = moment();
      this.supplementaryInfo.ReportDraftSentDate = moment();
      if (
        (!this.supplementaryInfo.EmailReportDraftToDoctorBE || !this.supplementaryInfo.EmailReportDraftToDoctor) &&
        !!reportDraftReceivedAttachment.length
      ) {
        this.toggleModalConfirm(true, {
          message: 'Do you want to email report draft to provider/specialist?',
          onOk: () => this.handleChangeSuppValue({ EmailReportDraftToDoctorBE: true, EmailReportDraftToDoctor: true }),
        });
      }
    } else {
      this.supplementaryInfo.EmailReportDraftToDoctorBE = false;
      this.supplementaryInfo.EmailReportDraftToDoctorPE = false;
      this.supplementaryInfo.EmailReportDraftToDoctor = false;
    }
  };
  @action ReportDictationSentToTypistChange = async () => {
    if (this.supplementaryInfo.DictationSentToTypist) {
      try {
        const suppDoctorSample = this.supplementaryInfo.Attachments
          ? toJS(this.supplementaryInfo.Attachments).filter(i => i.Type === 11)
          : [];
        const suppDoctorTemplate = this.supplementaryInfo.Attachments
          ? toJS(this.supplementaryInfo.Attachments).filter(i => i.Type === 12)
          : [];
        if (!!suppDoctorSample.length && !!suppDoctorTemplate.length) {
          notification.destroy();
          notification.error({
            message: 'Error',
            description:
              'Please remove current attached report templates and samples before copying from specialist profile.',
          });
          return;
        } else {
          const response = await customFetch('/Supplementary/GetDoctorTemplateAndSample', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: this.supplementaryInfo.Id }),
          });

          if (response.status === 'success') {
            this.toggleModalConfirm(true, {
              message: 'Matching report samples are found. Do you want to send to typist? ',
              onOk: () => {
                const { doctorSample, doctorTemplate } = response;

                const doctorSample_ = doctorSample
                  ? doctorSample.map(i => ({
                      Id: 0,
                      Title: i.Title,
                      FileName: i.FileName,
                      Type: 11,
                    }))
                  : [];
                const DoctorSampleAttachment = [...doctorSample_];
                const doctorTemplate_ = doctorTemplate
                  ? doctorTemplate.map(i => ({
                      Id: 0,
                      Title: i.Title,
                      FileName: i.FileName,
                      Type: 12,
                    }))
                  : [];
                const DoctorReportTemplateAttachment = [...doctorTemplate_];
                if (!suppDoctorTemplate.length) {
                  const suppAttachments = this.supplementaryInfo.Attachments
                    ? toJS(this.supplementaryInfo.Attachments)
                    : [];
                  this.handleChangeSuppValue({
                    Attachments: [...suppAttachments, ...DoctorReportTemplateAttachment].map((i, idx) => ({
                      ...i,
                      key: idx,
                    })),
                  });
                }
                if (!suppDoctorSample.length) {
                  const suppAttachments = this.supplementaryInfo.Attachments
                    ? toJS(this.supplementaryInfo.Attachments)
                    : [];
                  this.handleChangeSuppValue({
                    Attachments: [...suppAttachments, ...DoctorSampleAttachment].map((i, idx) => ({
                      ...i,
                      key: idx,
                    })),
                  });
                }

                // this.toggleModalConfirm(true, {
                //   message: 'Do you want to send dictation files to Typist?',
                //   onOk: () => {
                //     if (this.supplementaryInfo.DictationTypistID) {
                //       this.handleChangeSuppValue({ SentToTypist: true });
                //     } else {
                //       notification.error({
                //         message: 'Error',
                //         description: 'Please select a typist.',
                //       });
                //     }
                //   },
                // });
              },
            });
          } else {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'There is no report template or sample in specialist profile.',
            });
          }
        }
      } catch (e) {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'There is no report template or sample in specialist profile.',
        });
      }
    }
  };
  @action openModalHistory = actionName => {
    getAssignHistory({
      id: this.supplementaryInfo.Id,
      actionName: actionName === 'PostSupplementary' ? actionName : `Supp_${actionName}`,
    }).then(
      action(res => {
        this.assignHistory = res.itemList;
        this.isOpenModalHistory = true;
      }),
    );
  };

  @action openModalCompanyHistory = () => {
    getCompanyRecentChangeHistory(this.supplementaryInfo.Id, 'supp').then(
      action(res => {
        this.assignCompanyHistory = res.itemList;
        this.isOpenModalCompanyHistory = true;
      }),
    );
  };

  @action DictationReceivedChanged = () => {
    if (this.supplementaryInfo.DictationReceived) {
      this.supplementaryInfo.SendConfirmationToSpecialist = true;
      this.supplementaryInfo.DictationReceivedDate = moment();
    } else {
      this.supplementaryInfo.SendConfirmationToSpecialist = false;
    }
  };

  @action onAllowDoctorToViewPaperworkChange = () => {
    const attachments = this.supplementaryInfo.Attachments;
    const paperworkAttachment = !!attachments.length ? attachments.filter(i => i.Type === 2) : [];
    if (
      this.supplementaryInfo.AllowDoctorToViewPaperwork &&
      !paperworkAttachment.length &&
      !this.supplementaryInfo.PaperworkExLink
    ) {
      this.showNotification(
        'Error',
        'You need to attach LOI, paperwork or share download links before sending to Specialist.',
        'error',
      );
      this.supplementaryInfo.AllowDoctorToViewPaperwork = false;
      return;
    } else {
      if (this.supplementaryInfo.AllowDoctorToViewPaperwork) {
        if (!this.supplementaryInfo.AssessedDoctorId) {
          this.showNotification(
            'Error',
            'Report assessing doctor under Claim Details must be selected first.',
            'error',
          );
          this.supplementaryInfo.AllowDoctorToViewPaperwork = false;
          return;
        }

        this.supplementaryInfo.IsPaperworkReceived = true;
        this.onIsPaperworkReceivedChanged();
      }
    }
  };

  @action onIsPaperworkReceivedChanged = () => {
    if (this.supplementaryInfo.IsPaperworkReceived) {
      // const selectedItems = this.supplementaryInfo.SelectedPaperworkToDoctorBies || [];

      const attachmentModel = this.renderAttachment(this.supplementaryInfo.Attachments, 2);

      // if (
      //   api.isAdminOrMagStaffOrAccounting() &&
      //   !selectedItems.length &&
      //   !this.supplementaryInfo.AllowDoctorToViewPaperwork
      // ) {
      //   this.showNotification('Error', 'Please select a request sending option first', 'error');
      //   this.supplementaryInfo.IsPaperworkReceived = false;
      //   return;
      // }
      if (!api.isAdminOrMagStaffOrAccounting() && !attachmentModel.length && !this.supplementaryInfo.PaperworkExLink) {
        this.showNotification('Info', 'Please send paperwork to Specialist', 'info');
        // this.supplementaryInfo.IsPaperworkReceived = false;
        // return;
      }
      var momentBusinessDays = require('moment-business-days');
      // this.supplementaryInfo.PaperworkReceivedDate = moment();
      this.supplementaryInfo.PaperworkByOtherMethodDate = moment();
      this.supplementaryInfo.RequestExpectedCompletionDate = momentBusinessDays(moment()).businessAdd(7);
    } else {
      // this.supplementaryInfo.PaperworkReceivedDate = null;
      this.supplementaryInfo.PaperworkByOtherMethodDate = null;
    }
  };

  CancelledChange = () => {
    switch (this.supplementaryInfo.CancelledChargeStatus) {
      case 4:
        this.supplementaryInfo.CancelledDate = null;
        this.IsExpectedCompletionDate = true;
        break;
      case 2:
        this.supplementaryInfo.CancelledDate = moment();
        this.IsExpectedCompletionDate = false;
        break;
      case 3:
        this.supplementaryInfo.CancelledDate = moment();
        this.IsExpectedCompletionDate = false;
        break;
      default:
        break;
    }
  };

  handleRefetchActionNote = () => {
    getActionRequired(this.supplementaryInfo.Id)
      .then(action(res => (this.actionRequiredList = res.itemList)))
      .catch(() => {});
  };

  renderCheckboxSessionStyle = (isActive = false) => {
    return isActive
      ? {
          fontWeight: 'bold',
          backgroundColor: '#2cbf2c',
          padding: '5px',
          color: 'white',
          width: '350px',
        }
      : {
          fontWeight: 'bold',
          backgroundColor: 'gray',
          padding: '5px',
          color: 'white',
          width: '350px',
        };
  };

  handleActiveDoctorPrepaymentRequired = () => {
    if (this.supplementaryInfo.DoctorPrepaymentRequired) {
      this.supplementaryInfo.PrepaymentRequiredDate = moment();
      if (!this.supplementaryInfo.EmailInvoiceToMAG) {
        this.toggleModalConfirm(true, {
          message: 'Do you want to send invoice to Medicolegal Provider?',
          onOk: () => {
            const paymentRequested = this.renderAttachment(this.supplementaryInfo.Attachments, 14);
            if (!paymentRequested.length) {
              this.showNotification('Error', 'Provider invoice must be attached first', 'error');
            } else {
              this.handleChangeSuppValue({ EmailInvoiceToMAG: true });
            }
          },
        });
      }
    }
  };

  renderStaffName = id => {
    const rs = this.staffs.find(i => i.Id === id);
    const nameIfCM = id === api.currentUser.data.id ? api.currentUser.data.FullName : null;
    return rs ? rs.FullName : nameIfCM ? nameIfCM : id;
  };

  @action handleRemoveFile = key => {
    const attachments = this.supplementaryInfo.Attachments;
    const newAttachments = attachments.filter(i => i.key !== key);
    this.supplementaryInfo = {
      ...this.supplementaryInfo,
      Attachments: newAttachments,
    };
  };

  ClientPrepaymentRequiredChange = () => {
    if (this.supplementaryInfo.ClientPrepaymentRequired) {
      this.supplementaryInfo.ClientPrepaymentRequiredDate = moment();

      if (!this.supplementaryInfo.DelayPrepaymentRequired) {
        this.toggleModalConfirm(true, {
          message: 'Do you want to delay sending completed reports to client until payment is made?',
          onOk: () => this.handleChangeSuppValue({ DelayPrepaymentRequired: true }),
        });
      }
    } else {
      this.supplementaryInfo.ClientPrepaymentRequiredDate = null;
    }
  };

  inRangeDate = (d, start, end) => {
    let dD = moment(d);
    let cD1 = moment(start);
    let cD2 = moment(end);
    return isFinite(d) && isFinite(start) && isFinite(end) ? cD1 <= dD && dD <= cD2 : false;
  };

  inRangeDate2 = (d, start) => {
    let dD = moment(d);
    let cD1 = moment(start);
    return isFinite(d) && isFinite(start) ? cD1 <= dD : false;
    // return false;
  };

  momentToJS = (momentDate, outputFormat, isGetMomentObj) => {
    let result;
    if (!momentDate) {
      result = moment(); //.tz('Australia/Sydney');
    } else {
      result = moment(momentDate); //.tz('Australia/Sydney');
    }
    if (outputFormat) {
      return result.format(outputFormat);
    } else {
      if (isGetMomentObj) {
        return result;
      } else {
        return new Date(result.toISOString());
      }
    }
  };

  formatReqDate = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date).toISOString();
    }
    return date.toISOString();
  };

  @action fetchConversations = (searchKey = '') => {
    this.loadingConversation = true;
    getCaseCorrespondence(this.supplementaryInfo.Id)
      .then(
        action(res => {
          if (searchKey !== '' && res?.itemList?.length > 0) {
            searchKey = searchKey.toLowerCase();
            res.itemList = res.itemList.filter(t => t.Content.toLowerCase().indexOf(searchKey) > -1);
          }
          this.caseCorrespondences = res.itemList;
          this.loadingConversation = false;
        }),
      )
      .catch(() => (this.loadingConversation = false));
  };

  handleCommissionRateTypeChange = () => {
    const commissionRateType = this.supplementaryInfo.ServiceNotesandFeeObj.CommissionRateType;
    const itemModel = toJS(this.supplementaryInfo);
    const { ServiceNotesandFeeObj } = this.supplementaryInfo;
    switch (commissionRateType) {
      case 0:
        if (this.specialistSelected) {
          let dObj = this.specialistSelected;
          let compareDate = itemModel.CompletionDate;
          if (!compareDate) {
            compareDate = itemModel.DateRequest;
          }
          if (!!dObj.MAGChargeRate && !!dObj.MAGChargeRate2) {
            let isSetValue = false;
            if (
              !!dObj.MAGChargeRateTo &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.MAGChargeRateFrom),
                this.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate,
              });
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRateTo &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate,
              });
              isSetValue = true;
            }
            if (
              !!dObj.MAGChargeRate2To &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.MAGChargeRate2From),
                this.momentToJS(dObj.MAGChargeRate2To),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate2,
              });
              isSetValue = true;
            } else if (
              !dObj.MAGChargeRate2To &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.MAGChargeRate2From))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate2,
              });
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.MAGChargeRateTo &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.MAGChargeRateFrom),
                this.momentToJS(dObj.MAGChargeRateTo),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate,
              });
            } else if (
              !dObj.MAGChargeRateTo &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.MAGChargeRateFrom))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate,
              });
            } else {
              ServiceNotesandFeeObj.CommissionRate = 0;
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.MAGChargeRate2To &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.MAGChargeRate2From),
                this.momentToJS(dObj.MAGChargeRate2To),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate2,
              });
            } else if (
              !dObj.MAGChargeRate2To &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.MAGChargeRate2From))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.MAGChargeRate2,
              });
            } else {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else {
            this.handleChangeServiceNotesandFeeObj({
              CommissionRate: 0,
            });
          }
        } else {
          this.handleChangeServiceNotesandFeeObj({
            CommissionRate: 0,
          });
        }
        break;
      case 1:
        if (!!itemModel.AssessedDoctorId) {
          let dObj = this.specialistSelected;
          let compareDate = itemModel.CompletionDate;
          if (!compareDate) {
            compareDate = itemModel.DateRequest;
          }
          if (!!dObj.ChargeRate && !!dObj.ChargeRate2) {
            let isSetValue = false;
            if (
              !!dObj.ChargeRateTo &&
              this.inRangeDate(compareDate, this.momentToJS(dObj.ChargeRateFrom), this.momentToJS(dObj.ChargeRateTo))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate,
              });
              isSetValue = true;
            } else if (!dObj.ChargeRateTo && this.inRangeDate2(compareDate, this.momentToJS(dObj.ChargeRateFrom))) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRat2,
              });
              isSetValue = true;
            }
            if (
              !!dObj.ChargeRate2To &&
              this.inRangeDate(compareDate, this.momentToJS(dObj.ChargeRate2From), this.momentToJS(dObj.ChargeRate2To))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate2,
              });
              isSetValue = true;
            } else if (!dObj.ChargeRate2To && this.inRangeDate2(compareDate, this.momentToJS(dObj.ChargeRate2From))) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate2,
              });
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.ChargeRateTo &&
              this.inRangeDate(compareDate, this.momentToJS(dObj.ChargeRateFrom), this.momentToJS(dObj.ChargeRateTo))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate,
              });
            } else if (!dObj.ChargeRateTo && this.inRangeDate2(compareDate, this.momentToJS(dObj.ChargeRateFrom))) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate,
              });
            } else {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.ChargeRate2To &&
              this.inRangeDate(compareDate, this.momentToJS(dObj.ChargeRate2From), this.momentToJS(dObj.ChargeRate2To))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate2,
              });
            } else if (!dObj.ChargeRate2To && this.inRangeDate2(compareDate, this.momentToJS(dObj.ChargeRate2From))) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: dObj.ChargeRate2,
              });
            } else {
              this.handleChangeServiceNotesandFeeObj({
                CommissionRate: 0,
              });
            }
          } else {
            this.handleChangeServiceNotesandFeeObj({
              CommissionRate: 0,
            });
          }
        } else {
          this.handleChangeServiceNotesandFeeObj({
            CommissionRate: 0,
          });
        }
        break;
      case 2:
        this.handleChangeServiceNotesandFeeObj({
          CommissionAmount: this.specialistSelected?.MAGChargeRateFixedAmount || 0,
        });
        break;
      case 3:
        this.handleChangeServiceNotesandFeeObj({
          CommissionRate: 100,
        });
        if (this.specialistSelected) {
          let dObj = this.specialistSelected;
          let compareDate = itemModel.CompletionDate;
          if (!compareDate) {
            compareDate = itemModel.DateRequest;
          }
          if (!!dObj.CommissionOnTop && !!dObj.CommissionOnTop2) {
            let isSetValue = false;
            if (
              !!dObj.CommissionOnTopTo &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.CommissionOnTopFrom),
                this.momentToJS(dObj.CommissionOnTopTo),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop,
              });
              isSetValue = true;
            } else if (
              !dObj.CommissionOnTopTo &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.CommissionOnTopFrom))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop,
              });
              isSetValue = true;
            }
            if (
              !!dObj.CommissionOnTop2To &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.CommissionOnTop2From),
                this.momentToJS(dObj.CommissionOnTop2To),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop2,
              });
              isSetValue = true;
            } else if (
              !dObj.CommissionOnTop2To &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.CommissionOnTop2From))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop2,
              });
              isSetValue = true;
            }
            if (!isSetValue) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate) {
            if (
              !!dObj.CommissionOnTopTo &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.CommissionOnTopFrom),
                this.momentToJS(dObj.CommissionOnTopTo),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop,
              });
            } else if (
              !dObj.CommissionOnTopTo &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.CommissionOnTopFrom))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop,
              });
            } else {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: 0,
              });
            }
          } else if (!!dObj.MAGChargeRate2) {
            if (
              !!dObj.CommissionOnTop2To &&
              this.inRangeDate(
                compareDate,
                this.momentToJS(dObj.CommissionOnTop2From),
                this.momentToJS(dObj.CommissionOnTop2To),
              )
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop2,
              });
            } else if (
              !dObj.CommissionOnTop2To &&
              this.inRangeDate2(compareDate, this.momentToJS(dObj.CommissionOnTop2From))
            ) {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: dObj.CommissionOnTop2,
              });
            } else {
              this.handleChangeServiceNotesandFeeObj({
                CommissionOnTopRate: 0,
              });
            }
          } else {
            this.handleChangeServiceNotesandFeeObj({
              CommissionOnTopRate: 0,
            });
          }
        } else {
          this.handleChangeServiceNotesandFeeObj({
            CommissionOnTopRate: 0,
          });
        }
        break;
      default:
        break;
    }
    this.handleCalculateInvoice();
  };

  @action handleChangeServiceNotesandFeeObj = data => {
    Object.keys(data).forEach(key => {
      this.supplementaryInfo.ServiceNotesandFeeObj[key] = data[key];
    });
  };

  @action handleCalculateTotalServiceFee = () => {
    let serviceItemTotalFee = 0;
    let serviceItemTotalTax = 0;
    const { ListServiceItems } = this.supplementaryInfo.ServiceNotesandFeeObj;
    if (!ListServiceItems) {
      serviceItemTotalFee = 0;
      serviceItemTotalTax = 0;
    } else {
      ListServiceItems.map(item => {
        const itemPrice = item.ApplicablePrice;
        let itemTax = 0;
        if (item.Tax > 0) {
          itemTax = (itemPrice * item.Tax) / 100;
        }
        serviceItemTotalFee += itemPrice;
        serviceItemTotalTax += itemTax;
        return item;
      });
    }

    this.serviceItemTotalFee = serviceItemTotalFee;
    this.serviceItemTotalTax = serviceItemTotalTax;
  };

  handleCalculateTotalOtherFee = () => {
    let totalOtherFee = 0;
    let otherFeeTax = 0;
    if (this.supplementaryInfo.ServiceNotesandFeeObj.HasOtherFee) {
      let fee1 = parseFloat(this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Value || 0);
      let tax1 = parseFloat(this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage || 0);
      if (!isNaN(fee1) && fee1 !== 0) {
        totalOtherFee += fee1;
        if (!isNaN(tax1) && tax1 > 0) {
          otherFeeTax += tax1;
        }
      }
    }
    return {
      totalOtherFee,
      otherFeeTax,
    };
  };

  handleCalculateAmountForCommissionCalculation = () => {
    let amountForCommissionCalculation = 0;
    const serviceFee = parseFloat(
      this.supplementaryInfo.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission
        ? this.serviceItemTotalFee || 0
        : 0,
    );
    const adminExtraFee = parseFloat(
      this.supplementaryInfo.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission
        ? this.supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount || 0
        : 0,
    );
    const otherFee1 = parseFloat(
      this.supplementaryInfo.ServiceNotesandFeeObj.HasOtherFee &&
        this.supplementaryInfo.ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission
        ? this.supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Value || 0
        : 0,
    );
    amountForCommissionCalculation = serviceFee + adminExtraFee + otherFee1;

    return amountForCommissionCalculation;
  };

  handleCalculateAmountForCommissionCalculationTax = () => {
    return 0.1 * this.handleCalculateAmountForCommissionCalculation();
  };

  handleCalculateInvoice = () => {
    this.handleCalculateTotalServiceFee();
    const { supplementaryInfo } = this;
    const { ServiceNotesandFeeObj } = supplementaryInfo;
    const wiroType = 0;
    const commissionRateType = ServiceNotesandFeeObj.CommissionRateType;
    const otherFeeAndTax = this.handleCalculateTotalOtherFee();
    const totalOtherFee = otherFeeAndTax.totalOtherFee;
    const otherFeeTax = otherFeeAndTax.otherFeeTax;
    const serviceTax = this.serviceItemTotalTax;
    const serviceFee = this.serviceItemTotalFee;
    const adminExtraFee = ServiceNotesandFeeObj.AdminExtraFeeAmount || 0;
    const adminExtraFeeTax = ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage || 0;
    const doctorServiceAmount = wiroType === 2 ? 0 : this.handleCalculateAmountForCommissionCalculation();
    const doctorServiceTax = wiroType === 2 ? 0 : this.handleCalculateAmountForCommissionCalculationTax();
    const commissionRate = parseFloat(ServiceNotesandFeeObj.CommissionRate) || 0;
    const commissionAmount = (parseFloat(doctorServiceAmount) * parseFloat(commissionRate)) / 100;
    let cOnTopRate = 0;
    if (ServiceNotesandFeeObj.CommissionOnTopRate !== null) {
      cOnTopRate = parseFloat(ServiceNotesandFeeObj.CommissionOnTopRate);
    }
    const cotRate = commissionRateType === 3 ? cOnTopRate : 0;
    const cotAmount = (this.handleCalculateAmountForCommissionCalculation() * cotRate) / 100;
    const cotTax = cotAmount * 0.1 || 0; // 10%
    //Total
    let totalWithoutTax =
      parseFloat(serviceFee) + parseFloat(totalOtherFee) + parseFloat(adminExtraFee) + parseFloat(cotAmount);
    let totalTax = parseFloat(otherFeeTax) + parseFloat(serviceTax) + parseFloat(adminExtraFeeTax) + parseFloat(cotTax);
    let totalWithTax = 0;

    if (wiroType === 2) {
      const reimbursement = totalWithoutTax;
      supplementaryInfo.Reimbursement = reimbursement;
      totalWithTax = totalWithoutTax - reimbursement + totalTax;
    } else if (wiroType === 1) {
      totalTax = 0; // wiro invoice has tax = 0
      totalWithTax = totalWithoutTax + totalTax;
    } else {
      totalWithTax = totalWithoutTax + totalTax;
    }

    this.handleChangeServiceNotesandFeeObj({
      TotalWithoutTax: totalWithoutTax ? parseFloat(totalWithoutTax).toFixed(2) : 0,
      TaxTotal: totalTax ? parseFloat(totalTax).toFixed(2) : 0,
      Total: totalWithTax,
      CommissionOnTopAmount: cotAmount ? parseFloat(cotAmount).toFixed(2) : 0,
      TotalAmountForCommissionCalculation: doctorServiceAmount ? parseFloat(doctorServiceAmount).toFixed(2) : 0,
      TotalAmountForCommissionCalculationTax: doctorServiceTax ? parseFloat(doctorServiceTax).toFixed(2) : 0,
    });

    if (commissionRateType !== 2) {
      this.handleChangeServiceNotesandFeeObj({
        CommissionAmount: commissionAmount ? parseFloat(commissionAmount).toFixed(2) : 0,
      });
    }
  };

  handleSameBCMSendInvoice = value => {
    const { bookingCMSelected } = this;
    const { supplementaryInfo } = this;
    const { ServiceNotesandFeeObj } = supplementaryInfo;
    if (value) {
      if (!!bookingCMSelected?.Id) {
        this.handleChangeSuppValue({
          ServiceNotesandFeeObj: {
            ...ServiceNotesandFeeObj,
            SendInvoice: bookingCMSelected?.Email || null,
          },
        });
      } else {
        this.showNotification('Error', 'Please choose CM first.', 'error');
      }
    } else {
      this.handleChangeSuppValue({
        ServiceNotesandFeeObj: {
          ...ServiceNotesandFeeObj,
          SendInvoice: null,
        },
      });
    }
  };

  handleSameACESendInvoice = value => {
    if (value) {
      if (!this.payingClientSelected?.Id) {
        this.showNotification('Error', 'Please choose Paying Client first.', 'error');
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        return this.handleChangeSuppValue({
          ServiceNotesandFeeObj: {
            ...ServiceNotesandFeeObj,
            SameClientAccEmail: false,
          },
        });
      } else {
        const { supplementaryInfo } = this;
        const { ServiceNotesandFeeObj } = supplementaryInfo;
        return customFetch('/MedicalService/GetCustomerEmail', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.payingClientSelected.Id,
          }),
        })
          .then(response => {
            if (response.status === 'success') {
              this.handleChangeSuppValue({
                ServiceNotesandFeeObj: {
                  ...ServiceNotesandFeeObj,
                  SendInvoice2: response.email,
                },
              });
            } else {
              if (response.errorList.length > 0) {
                this.handleChangeSuppValue({
                  ServiceNotesandFeeObj: {
                    ...ServiceNotesandFeeObj,
                    SameClientAccEmail: false,
                  },
                });
              } else {
                this.showNotification('Error', 'Error occured while sending invoice, please try again later.', 'error');
                this.handleChangeSuppValue({
                  ServiceNotesandFeeObj: {
                    ...ServiceNotesandFeeObj,
                    SameClientAccEmail: false,
                  },
                });
              }
            }
          })
          .catch(() => {});
      }
    } else {
      const { supplementaryInfo } = this;
      const { ServiceNotesandFeeObj } = supplementaryInfo;
      this.handleChangeSuppValue({
        ServiceNotesandFeeObj: {
          ...ServiceNotesandFeeObj,
          SendInvoice2: '',
        },
      });
    }
  };

  getMessageError = (key, type = 'field') => {
    const messageObj = this.invalidForm.find(i => i.key === key);
    if (messageObj && messageObj !== {}) {
      const { message } = messageObj;
      const [title, messageError] = message.split(' - ');
      if (type === 'notification') return title + ' - ' + messageError;
      else {
        if (message.indexOf('is required') !== -1) {
          return 'This field is required';
        }
        return messageError;
      }
    }
    return;
  };

  messageError = (type, field) => {
    if (type === 'email') {
      return ` ${FIELD_NAME_FOR_NOTIFICATION[field]} - Please input valid email`;
    }
    if (type === 'url') {
      return `${FIELD_NAME_FOR_NOTIFICATION[field]} - Please enter a valid url starts with http:// | https://`;
    }
    if (type === 'required') {
      return `${FIELD_NAME_FOR_NOTIFICATION[field]} is required`;
    }
  };

  @action removeError = key => {
    const invalidForm_ = this.invalidForm.filter(i => i.key !== key);
    return (this.invalidForm = invalidForm_);
  };

  @action addError = (key, message) => {
    return (this.invalidForm = [...this.invalidForm, { key, message }]);
  };

  @action validateField = (fieldName, value, type) => {
    const v = new Validator({ value }, { value: type });
    if (v.passes()) {
      const invalidForm = this.invalidForm.filter(i => i.key !== fieldName);
      return (this.invalidForm = invalidForm);
    } else {
      const invalidForm = this.invalidForm.filter(i => i.key === fieldName);
      if (!!invalidForm.length) {
        return;
      }
      return (this.invalidForm = [
        ...this.invalidForm,
        { key: fieldName, message: this.messageError(type, fieldName) },
      ]);
    }
  };

  renderAttachment = (attachments, reportType) => {
    return attachments ? attachments.filter(attachment => attachment.Type === reportType) : [];
  };
  renderAttachments = (attachments, reportTypes) => {
    return attachments ? attachments.filter(attachment => reportTypes.includes(attachment.ReportType)) : [];
  };
  handleChangeDatePicker = fieldName =>
    action(event => {
      const value = event;
      if (fieldName === 'PaperworkByOtherMethodDate') {
        var momentBusinessDays = require('moment-business-days');
        this.supplementaryInfo.RequestExpectedCompletionDate = momentBusinessDays(value).businessAdd(7);
      }
      return (this.supplementaryInfo[fieldName] = value);
    });

  handleChangeFieldSelect = fieldName =>
    action(event => {
      const value = event;
      if (fieldName) {
        if (fieldName === 'PayingClientId' || fieldName === 'PayingCaseManagerId' || fieldName === 'CReferenceId') {
          return (this.supplementaryInfo.ServiceNotesandFeeObj[fieldName] = value);
        }
        if (fieldName === 'serviceItemId') {
          return (this[fieldName] = value);
        }
        if (fieldName === 'AssessedDoctorId') {
          let findDoctor = this.specialists.find(i => i.Id === value);
          if (findDoctor) {
            this.supplementaryInfo.SpecialistPaperworkNotes = findDoctor.PaperworkNotes;
          }
          this.handleAssessedDoctorChange(this.supplementaryInfo.AssessedDoctorId, value);
          return (this.supplementaryInfo[fieldName] = value);
        }
        if (fieldName === 'SubBookingCMId') {
          if (value.length > 3) {
            return;
          }
        }
        if (fieldName === 'DoctorId') {
          // set default value for Provider No
          this.supplementaryInfo.SelectedDoctorProviderNoIdx = null;
          this.supplementaryInfo.DoctorId = value;
          return;
        }
        if (fieldName === 'ClientId') {
          if (this.supplementaryInfo.Id > 0) {
            this.toggleModalConfirm(true, {
              message: 'Changing Booking Client can affect billing information later. Do you want to proceed?',
              onOk: action(() => {
                const data_ = this.bookingClients.find(i => i.Id === value);
                if (data_ && data_.CreditTerm === 1) {
                  //ClientPrepaymentRequired
                  this.supplementaryInfo.ClientPrepaymentRequired = true;
                  this.supplementaryInfo.ClientPrepaymentRequiredDate = moment();
                  if (!this.supplementaryInfo.DelayPrepaymentRequired) {
                    this.supplementaryInfo.DelayPrepaymentRequired = true;
                  }
                }
                this.supplementaryInfo[fieldName] = value;
              }),
            });
            return;
          } else {
            const data_ = this.bookingClients.find(i => i.Id === value);
            if (data_ && data_.CreditTerm === 1) {
              //ClientPrepaymentRequired
              this.supplementaryInfo.ClientPrepaymentRequired = true;
              this.supplementaryInfo.ClientPrepaymentRequiredDate = moment();
              if (!this.supplementaryInfo.DelayPrepaymentRequired) {
                this.supplementaryInfo.DelayPrepaymentRequired = true;
              }
            }
          }
          this.handleCheckICare();
        }
        if (fieldName === 'SubClientId') {
          this.supplementaryInfo.SubBookingCM = [];
          this.searchOptSubBookingCMs = {
            keyword: '',
            numberItem: 1000,
            companyId: value,
          };
        }
        return (this.supplementaryInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldInput = fieldName =>
    action(event => {
      const value = event.target.value;
      if (fieldName) {
        if (
          fieldName === 'SendInvoice' ||
          fieldName === 'SendInvoice2' ||
          fieldName === 'AdminExtraFeeAmount' ||
          fieldName === 'OtherFee1Label' ||
          fieldName === 'OtherFee1Description' ||
          fieldName === 'Description' ||
          fieldName === 'OtherFee2Description'
        ) {
          return (this.supplementaryInfo.ServiceNotesandFeeObj[fieldName] = value);
        }
        return (this.supplementaryInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldInputNumber = fieldName =>
    action(event => {
      const value = event;
      if (
        fieldName === 'AdminExtraFeeAmount' ||
        fieldName === 'OtherFee1Value' ||
        fieldName === 'AdminExtraFeeTaxPercentage' ||
        fieldName === 'OtherFee1TaxPercentage' ||
        fieldName === 'OtherFee2Value' ||
        fieldName === 'CommissionRate' ||
        fieldName === 'CommissionOnTopRate' ||
        fieldName === 'CommissionAmount'
      ) {
        return (this.supplementaryInfo.ServiceNotesandFeeObj[fieldName] = value);
      }
      return (this.supplementaryInfo[fieldName] = value);
    });

  @action handleChangeStatusToCancelled = statusToCancelled => {
    var findARIncompleted = this.actionRequiredList ? this.actionRequiredList.filter(i => !i.Completed) : 0;
    if (!api.isCaseManager() && findARIncompleted && findARIncompleted.length > 0) {
      notification.destroy();
      notification.info({
        message: 'Action Required Reminder',
        description: 'Please check and clear pending required actions as needed.',
        duration: 15,
      });
    }
    this.supplementaryInfo.NotifyCancellationToCM = true;
    if (statusToCancelled !== CONSTANTS.CaseStatus_None) {
      if ((statusToCancelled === 2 && this.supplementaryInfo.IsPaperworkReceived) || statusToCancelled === 3) {
        this.supplementaryInfo.NotifyDoctorCancelled = true;
      } else {
        this.supplementaryInfo.NotifyDoctorCancelled = false;
      }
    }
    if (this.bookingSolicitorSelected && this.bookingSolicitorSelected.Id > 0) {
      this.supplementaryInfo.NotifyCancellationToSolictor = true;
    }
    this.supplementaryInfo.ReasonCancellation = 1;
    this.supplementaryInfo.SentByNotifyDoctorCancellation = api.isAdminOrMagStaffOrAccounting() || api.isCaseManager();
  };

  handleChangeFieldRadio = fieldName =>
    action(event => {
      const value = event.target.value;
      if (fieldName === 'CancelledChargeStatus') {
        if (value === 2 || value === 3) {
          this.toggleModalConfirm(true, {
            modalType: 'confirm',
            message: 'Once a case is cancelled, the case status can not be changed. Do you want to proceed?',
            onOk: () => {
              this.handleChangeStatusToCancelled(value);
            },
            functionCallbackCancel: () => {
              this.handleChangeSuppValue({ CancelledChargeStatus: 4 });
              // this.handleChangeSuppValue({NotifyCancellationToCM: false});
              // this.handleChangeSuppValue({NotifyDoctorCancelled: false});
              // this.handleChangeSuppValue({NotifyCancellationToSolictor: false});
            },
          });
        }
      }
      if (fieldName) {
        if (fieldName === 'CommissionRateType') {
          return (this.supplementaryInfo.ServiceNotesandFeeObj[fieldName] = value);
        }
        return (this.supplementaryInfo[fieldName] = value);
      }

      return;
    });

  handleChangeFieldCheckbox = fieldName =>
    action(event => {
      const value = event.target.checked;
      if (fieldName) {
        if (
          fieldName === 'SameBookingCM' ||
          fieldName === 'IncludeServiceItemFeeToDoctorCommission' ||
          fieldName === 'SameClientAccEmail' ||
          fieldName === 'IncludeAdminExtraFeeToDoctorCommission' ||
          fieldName === 'HasOtherFee' ||
          fieldName === 'IncludeManualFee1ToDoctorCommission'
        ) {
          this.supplementaryInfo.ServiceNotesandFeeObj[fieldName] = value;
          return;
        }
        if (fieldName === 'NotifyPaperworkReadinessToStaff' && api.isCaseManager() && value) {
          const attachments = this.supplementaryInfo.Attachments;
          const paperworkAttachment = !!attachments.length ? attachments.filter(i => i.Type === 2) : [];
          if (!paperworkAttachment.length && !this.supplementaryInfo.PaperworkExLink) {
            this.showNotification(
              'Error',
              'You need to attach LOI, paperwork or share download links before sending to Specialist.',
              'error',
            );
            this.supplementaryInfo.AllowDoctorToViewPaperwork = false;
            return;
          } else {
            this.supplementaryInfo[fieldName] = value;
            return;
          }
          return;
        }
        if (fieldName === 'ReportCompleted') {
          const reportCompletedAttachments = this.supplementaryInfo.Attachments.filter(i => i.Type === 0);
          if (!reportCompletedAttachments.length && value) {
            notification.info({
              message: 'Information',
              description: 'Please remember to attach reports',
              duration: 5,
            });
          }
        }

        if (fieldName === 'DictationSentToTypist') {
          return this.onDictationToTypistChanged(value);
        }

        if (fieldName === 'SentToTypist' && value) {
          this.supplementaryInfo.DictationSentToTypist = true;
        }

        if (
          (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) &&
          value &&
          fieldName === 'DictationReceived'
        ) {
          this.supplementaryInfo.DictationOption = 0;
          return (this.supplementaryInfo[fieldName] = value);
        }

        if (fieldName === 'WiroIlars') {
          if (value && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
            notification.info({
              message: 'Information',
              description: 'Funding approval must be provided before paperwork can be sent to specialist',
              duration: 15,
            });
          }
        }

        if (fieldName === 'IROFundingApproved') {
          if (value) this.supplementaryInfo.IROFundingApprovalDate = moment();
          return (this.supplementaryInfo[fieldName] = value);
        }

        return (this.supplementaryInfo[fieldName] = value);
      }
      return;
    });

  handleChangeFieldCheckboxGroup = fieldName =>
    action(event => {
      const value = event;
      if (fieldName) {
        this.supplementaryInfo[fieldName] = value;

        if (fieldName === 'SelectedPaperworkToDoctorBies') {
          if (!!value.length && api.isAdminOrMagStaffOrAccounting()) {
            this.handleChangeValue('IsPaperworkReceived', true);
          }
        }
      }
      return;
    });

  onDictationToTypistChanged = checked => {
    const itemModel = toJS(this.supplementaryInfo);

    if (!itemModel.DictationTypistID) {
      this.showNotification('Error', 'Please select a typist');
      return;
    }

    this.handleChangeSuppValue({ DictationSentToTypist: checked });
    if (checked && !itemModel.SentToTypist) {
      return this.toggleModalConfirm(true, {
        message: 'Do you want to send dictation files, report templates and previous reports to the typist?',
        onOk: () => {
          this.handleChangeSuppValue({ SentToTypist: true });
        },
      });
    }
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleChangeSuppValue = (obj = {}) => {
    if (obj === {} || typeof obj !== 'object') {
      return;
    } else {
      Object.keys(obj).forEach(key => {
        this.supplementaryInfo[key] = obj[key];
      });
    }
  };

  handleSearch = searchOptName => event => {
    this.handleChangeSearchOpt({ keyword: event }, searchOptName);
  };

  handleScroll = searchOptName => event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
      const { numberItem } = this[searchOptName];
      this.handleChangeSearchOpt({ numberItem: numberItem + 20 }, searchOptName);
    }
  };

  @action handleChangeSearchOpt = (option = {}, searchOptionName = '') => {
    if (
      option === {} ||
      !searchOptionName ||
      typeof option !== 'object' ||
      typeof searchOptionName !== 'string' ||
      !this[searchOptionName]
    ) {
      return;
    } else {
      Object.keys(option).forEach(key => {
        this[searchOptionName][key] = option[key];
      });
    }
  };

  @action handleSetTotalPages = totalpages => {
    this.totalPages = totalpages;
  };

  showNotification = (message = '', description, typeNotification = 'error') => {
    return notification[typeNotification]({
      message: message,
      description: description,
      duration: 5,
    });
  };

  loadCaseFromOriginal = viewModel => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      this.setFieldsValue({
        isServicePrepaymentLocked: false,
        isLockReport_CM: false,
      });
    } else if (api.isCaseManager()) {
      // CM is not allow to modify Report
      this.setFieldsValue({ isLockReport: true });
      this.setFieldsValue({ isServicePrepaymentLocked: true });
    } else if (api.isDoctor()) {
      // Doctor is not allow to modify paperwork
      this.setFieldsValue({ isLockPaperword: true });
      this.setFieldsValue({ isLockReport_CM: true });
      this.setFieldsValue({ isServicePrepaymentLocked: false });
    } else if (api.isTypist()) {
      this.setFieldsValue({ isLockPaperword: true });
      this.setFieldsValue({ isLockReport_CM: true });
      this.setFieldsValue({ isServicePrepaymentLocked: false });
      this.setFieldsValue({ isLockReport: true });
    }
    if (!!viewModel.CancelledChargeStatus && viewModel.CancelledChargeStatus === 4) {
      this.setFieldsValue({ IsExpectedCompletionDate: true });
    } else {
      this.setFieldsValue({ IsExpectedCompletionDate: false });
    }
    if (api.isCaseManager()) {
      this.setFieldsValue({ isLockPaperword: viewModel.IsPaperworkReceived });
      if (viewModel.ChargeStatus !== 4) {
        this.setFieldsValue({ isLockPaperword: true });
      }
    }

    if (api.isDoctor()) {
      if (viewModel.ChargeStatus !== 4) {
        this.setFieldsValue({ isLockReport: true });
      }
    }
    if (api.isCaseManager() && !!viewModel.Id) {
      if (viewModel.ChargeStatus !== 4) {
        this.setFieldsValue({ isLockReport_CM: true });
      }
    }
    const { Doctor, AssessedDoctor } = viewModel;

    this.handleChangeSearchOpt(
      {
        companyId: viewModel.Client ? viewModel.Client.Id || null : null,
      },
      'searchOptBookingCM',
    );
    this.handleChangeSearchOpt(
      {
        companyId: viewModel.Client ? viewModel.Client.Id || null : null,
      },
      'searchOptBookingSolicitor',
    );
    this.handleChangeSearchOpt(
      {
        companyId: viewModel.ServiceNotesandFeeObj ? viewModel.ServiceNotesandFeeObj.PayingClientId || null : null,
      },
      'searchOptPayingCM',
    );
    this.handleChangeSearchOpt(
      {
        companyId: viewModel.PClient ? viewModel.PClient?.Id || null : null,
      },
      'searchOptPCM',
    );
    this.handleChangeSearchOpt(
      {
        keyword: Doctor ? Doctor.Id : 0,
      },
      'searchOptOriginalSpecialist',
    );
    this.handleChangeSearchOpt(
      {
        keyword: AssessedDoctor ? AssessedDoctor.Id : '',
      },
      'searchOptSpecialist',
    );
    this.handleChangeSearchOpt(
      {
        keyword: viewModel.Claimant ? viewModel.Claimant.FullName : null,
      },
      'searchOptClaimant',
    );

    let attachments = this.supplementaryInfo.Attachments ? [...this.supplementaryInfo.Attachments] : [];
    if (viewModel.Attachments && viewModel.Attachments.length > 0) {
      viewModel.Attachments.forEach(att => {
        attachments.push(att);
      });
    }

    this.setFieldsValue({
      supplementaryInfo: {
        ...viewModel,
        Attachments: attachments,
        DateRequest: moment(),
        DoctorId: viewModel.Doctor ? viewModel.Doctor.Id || null : null,
        AssessedDoctorId: viewModel.AssessedDoctor ? viewModel.AssessedDoctor.Id || null : null,
        ClientId: viewModel.Client ? viewModel.Client.Id || null : null,
        CaseManagerId: viewModel.CaseManager ? viewModel.CaseManager.Id || null : null,
        BookingSolicitorId: viewModel.BookingSolicitor ? viewModel.BookingSolicitor.Id || null : null,
        PClientId: viewModel.PClient ? viewModel.PClient.Id || null : null,
        PCaseManagerId: viewModel.PCaseManager ? viewModel.PCaseManager.Id || null : null,
        SubBookingCM: [viewModel.SubCaseManager1, viewModel.SubCaseManager3, viewModel.SubCaseManager2].filter(i => i),
        ClaimantId: viewModel.Claimant ? viewModel.Claimant.Id || null : null,
        ReportId: !!viewModel.ReportId ? viewModel.ReportId : null,
        ActionRequired: true,
        ServiceNotesandFeeObj: {
          AdminExtraFeeAmount: null,
          AdminExtraFeeTaxPercentage: null,
          CReferenceId: null,
          CommissionOnTopRate: null,
          CommissionRate: null,
          CommissionRateType: 0,
          Description: null,
          HasOtherFee: false,
          IncludeAdminExtraFeeToDoctorCommission: false,
          IncludeManualFee1ToDoctorCommission: false,
          IncludeServiceItemFeeToDoctorCommission: false,
          InvoiceAttachment: null,
          OtherFee1Description: null,
          OtherFee1Label: null,
          OtherFee1TaxPercentage: null,
          OtherFee1Value: null,
          OtherFee2Description: null,
          OtherFee2Label: null,
          OtherFee2TaxPercentage: null,
          OtherFee2Value: null,
          PayingCaseManagerId: null,
          PayingClientId: null,
          SameBookingCM: false,
          SameClientAccEmail: false,
          SendInvoice: null,
          SendInvoice2: null,
          SelectedServiceItemIds: '[]',
          ListServiceItems: null,
        },
        ClaimantStatus: null,
        SelectedAssessedDoctorProviderNoIdx: viewModel.AssessedDoctor?.Id
          ? viewModel.SelectedAssessedDoctorProviderNoIdx
          : null,
        SelectedDoctorProviderNoIdx: viewModel.Doctor?.Id ? viewModel.SelectedDoctorProviderNoIdx : null,
        // PCSameBookingClient: (viewModel.PClient?.Id ?? -1) === (viewModel.Client?.Id ?? 0) ? true : false,
        // PMSameBookingCM: (viewModel.PCaseManager?.Id ?? -1) === (viewModel.CaseManager?.Id ?? 0) ? true : false,
        F_RDCSameBookingCM: true,
        F_RDCEmail: viewModel.CaseManager?.Email || '',
        F_RDCCaseManagerId: viewModel.CaseManager?.Id || null,
      },
    });
  };

  handleCheckICare = () => {
    const { bookingClientSelected, specialistSelected } = this;
    if (!!bookingClientSelected && !!specialistSelected) {
      if (
        (bookingClientSelected.IcareInsurer || this.supplementaryInfo?.IcareClaim) &&
        !specialistSelected.AcceptICareMatter
      ) {
        this.setFieldsValue({
          open: true,
          modalParams: {
            modalType: 'confirm',
            message: 'This specialist may not accept iCare matters, are you sure to book this report?',
            onOk: () => {},
            functionCallBack: () => {},
          },
        });
      }
    }
  };

  getOriginalByCaseNo = caseNo => {
    return customFetch('/Supplementary/CheckOriginalCaseNo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ caseNo: caseNo }),
    }).then(response => {
      if (response.status === 'success') {
        this.loadCaseFromOriginal(response.viewModel);
        this.setFieldsValue({
          loading: false,
        });
        setTimeout(() => {
          this.functionAutoRun();
          this.setFieldsValue({ isReady: true });
        });
      } else {
        this.toggleModalConfirm(true, {
          hideWarningIcon: true,
          message: 'Not found Case Original.',
        });
        this.setFieldsValue({
          loading: false,
        });
        setTimeout(() => {
          this.functionAutoRun();
          this.setFieldsValue({ isReady: true });
        });
      }
    });
  };

  formatResDate = date => {
    if (!date || typeof date !== 'string') {
      return null;
    }
    return moment(formatDate(date), 'DD MMM YYYY');
  };

  @action fetchSupplementaryData = async (id = 0, caseNo, mType, mId) => {
    try {
      this.setFieldsValue({ loading: true });
      this.handleDisposeReaction();
      const { model } = await getSupplementReport(id, mType, mId);
      if (api.isAdminOrMagStaffOrAccounting()) {
        this.setFieldsValue({
          isServicePrepaymentLocked: false,
          isLockReport_CM: false,
        });
      } else if (api.isCaseManager()) {
        // CM is not allow to modify Report
        this.setFieldsValue({ isLockReport: true });
        this.setFieldsValue({ isServicePrepaymentLocked: true });
        this.setFieldsValue({ isLockPaperword: model.IsPaperworkReceived });
        if (model.ChargeStatus !== 4) {
          this.setFieldsValue({ isLockPaperword: true });
          if (!!model.Id) {
            this.setFieldsValue({ isLockReport_CM: true });
          }
        }
      } else if (api.isDoctor()) {
        // Doctor is not allow to modify paperwork
        this.setFieldsValue({ isLockPaperword: true });
        this.setFieldsValue({ isLockReport_CM: true });
        this.setFieldsValue({ isServicePrepaymentLocked: false });
        if (model.ChargeStatus !== 4) {
          this.setFieldsValue({ isLockReport: true });
        }
      } else if (api.isTypist()) {
        this.setFieldsValue({ isLockPaperword: true });
        this.setFieldsValue({ isLockReport_CM: true });
        this.setFieldsValue({ isServicePrepaymentLocked: false });
        this.setFieldsValue({ isLockReport: true });
      }

      if (!!model.CancelledChargeStatus && model.CancelledChargeStatus === 4) {
        this.setFieldsValue({ IsExpectedCompletionDate: true });
      } else {
        this.setFieldsValue({ IsExpectedCompletionDate: false });
      }
      const { Doctor, AssessedDoctor, Client, PClient, Claimant, ServiceNotesandFeeObj, SubClient } = model;
      const { PayingClientId, CReferenceId, ListServiceItems } = ServiceNotesandFeeObj || SERVICE_NOTE_FEE_INIT;
      this.handleChangeSearchOpt(
        {
          companyId: model.Client?.Id || null,
        },
        'searchOptBookingCM',
      );
      this.handleChangeSearchOpt(
        {
          companyId: model.Client?.Id || null,
          keyword: model.BookingSolicitor?.FullName || '',
        },
        'searchOptBookingSolicitor',
      );
      this.handleChangeSearchOpt(
        {
          companyId: model.ServiceNotesandFeeObj?.PayingClientId || null,
        },
        'searchOptPayingCM',
      );
      this.handleChangeSearchOpt(
        {
          companyId: model.PClient?.Id || null,
          keyword: model.PCaseManager?.FullName || '',
        },
        'searchOptPCM',
      );
      this.handleChangeSearchOpt({ keyword: Doctor ? Doctor.Id : 0 }, 'searchOptOriginalSpecialist');
      this.handleChangeSearchOpt({ keyword: AssessedDoctor ? AssessedDoctor.Id : '' }, 'searchOptSpecialist');
      this.handleChangeSearchOpt({ keyword: Client ? Client.Name : null }, 'searchOptBookingClient');
      this.handleChangeSearchOpt({ keyword: SubClient ? SubClient.Name : null }, 'searchOptBookingSubClient');
      this.handleChangeSearchOpt(
        {
          keyword: PClient ? PClient.Name : null,
        },
        'searchOptPClient',
      );
      this.handleChangeSearchOpt(
        {
          keyword: Claimant ? Claimant.FullName : null,
        },
        'searchOptClaimant',
      );
      this.handleChangeSearchOpt(
        {
          keyword: PayingClientId || '',
        },
        'searchOptPayingClient',
      );
      this.handleChangeSearchOpt(
        {
          keyword: CReferenceId,
        },
        'searchOptCReference',
      );
      this.handleChangeSearchOpt(
        {
          keyword: model.S_RDCCaseManagerId || '',
        },
        'searchOptReportDeliveryContactSecond',
      );

      Promise.all([
        getCaseManagerOnboarded({}),
        getStaff(),
        getTypeOfClaim(),
        getTypist(),
        getIMSCode(),
        getWiroPrincipleLawyer(),
        getServiceItem(),
        getSpecificLookupConfigOrderNoByType(),
        getCaseManagerOnboarded({ keyword: '', numberItem: 100, companyId: SubClient?.Id }),
      ]).then(
        action(
          ([
            subBookingCMs,
            staffs,
            typeOfClaims,
            typist,
            imsCodes,
            WIROPrincipleLawyers,
            serviceItemCodes,
            lookupConfigs,
            subBookingClientCMs,
          ]) => {
            ///this.subBookingCMs = subBookingClientCMs.itemList;
            this.staffs = staffs.itemList;
            this.typeOfClaims = typeOfClaims.itemList;
            this.typist = typist.itemList;
            this.imsCodes = imsCodes.itemList;
            this.WIROPrincipleLawyers = WIROPrincipleLawyers.itemList;
            this.serviceItemCodes = serviceItemCodes.itemList;
            this.lookupConfigs = lookupConfigs.itemList;
            if (!!id) {
              this.setFieldsValue({
                serviceItemId: ListServiceItems ? ListServiceItems.map(i => i.Id) : [],
              });
              this.setFieldsValue({
                supplementaryInfo: {
                  ...model,
                  Attachments: model.Attachments.map((i, idx) => ({
                    ...i,
                    key: idx,
                  })),
                  IsLocked: !api.isAdminOrMagStaffOrAccounting(),
                  DoctorId: !!model.Doctor ? model.Doctor.Id : null,
                  AssessedDoctorId: !!model.AssessedDoctor ? model.AssessedDoctor.Id : null,
                  BookingSolicitorId: !!model.BookingSolicitor ? model.BookingSolicitor.Id : null,
                  PClientId: !!model.PClient ? model.PClient.Id : null,
                  PCaseManagerId: !!model.PCaseManager ? model.PCaseManager.Id : null,
                  SubBookingCM: [model.SubCaseManager1, model.SubCaseManager3, model.SubCaseManager2].filter(i => i),
                  ClaimantId: !!model.Claimant ? model.Claimant.Id : null,
                  ActionRequired: true,
                  ServiceNotesandFeeObj: model.ServiceNotesandFeeObj || SERVICE_NOTE_FEE_INIT,
                  SelectedAssessedDoctorProviderNoIdx: model.AssessedDoctor?.Id
                    ? model.SelectedAssessedDoctorProviderNoIdx
                    : null,
                  SelectedDoctorProviderNoIdx: model.Doctor?.Id ? model.SelectedDoctorProviderNoIdx : null,
                  ClientId: model.Client?.Id || null,
                  CaseManagerId: model.CaseManager?.Id || null,
                  PaperworkByOtherMethodDate: this.formatResDate(model.PaperworkByOtherMethodDate),
                  UrgentReportReportCompletionDate: this.formatResDate(model.UrgentReportReportCompletionDate),
                  ClaimantStatus: model.ClaimantStatus ? 1 : 0,
                  IROFundingApprovalDate: this.formatReqDate(model.IROFundingApprovalDate)
                  // PCSameBookingClient: (model.PClient?.Id ?? -1) === (model.Client?.Id ?? 0) ? true : false,
                  // PMSameBookingCM: (model.PCaseManager?.Id ?? -1) === (model.CaseManager?.Id ?? 0) ? true : false,
                },
              });
              Promise.all([
                getActionRequired(id),
                getCaseCorrespondence(id),
                getInvoice(id),
                getSUPPStatusLogs(id),
                getListInvoiceID(model.Id),
              ]).then(
                action(([actionRequiredList, caseCorrespondences, invoices, suppStatusLogs, listInvoice]) => {
                  this.actionRequiredList = actionRequiredList.itemList;
                  this.caseCorrespondences = caseCorrespondences.itemList;
                  this.invoices = invoices.itemList;
                  this.suppStatusLogs = suppStatusLogs.itemList;
                  this.listInvoice = listInvoice.itemList;
                  this.setFieldsValue({ utcServerTime: caseCorrespondences.utcServerTime });
                }),
              );

              var totalP = 0;
              const paperworkAndPaperworkOtherAttachment = !!model.Attachments.length
                ? model.Attachments.filter(i => i.Type === 2 || i.Type === 3)
                : [];
              if (!!paperworkAndPaperworkOtherAttachment.length) {
                paperworkAndPaperworkOtherAttachment.forEach(element => {
                  totalP = totalP + element.NumberOfPages;
                });
                this.handleSetTotalPages(totalP);
              }
              if (!caseNo) {
                this.setFieldsValue({ loading: false });
                setTimeout(() => {
                  this.functionAutoRun();
                  this.setFieldsValue({ isReady: true });
                });
              } else {
                this.getOriginalByCaseNo(caseNo);
              }
            } else {
              this.setFieldsValue({
                supplementaryInfo: {
                  ...model,
                  Attachments: model.Attachments.map((i, idx) => ({
                    ...i,
                    key: idx,
                  })),
                  DateRequest: moment(),
                  ReportId: !!model.ReportId ? model.ReportId : null,
                  //RequestExpectedCompletionDate: moment().add(7, 'day'),
                  CancelledChargeStatus: 4,
                  ActionRequired: true,
                  DoctorId: model.Doctor ? model.Doctor.Id : null,
                  AssessedDoctorId: model.AssessedDoctor ? model.AssessedDoctor.Id : null,
                  ClientId: model.Client?.Id || null,
                  CaseManagerId: model.CaseManager?.Id || null,
                  BookingSolicitorId: model.BookingSolicitor ? model.BookingSolicitor.Id : null,
                  PClientId: model.PClient ? model.PClient.Id : null,
                  PCaseManagerId: model.PCaseManager ? model.PCaseManager.Id : null,
                  SubBookingCM: [model.SubCaseManager1, model.SubCaseManager3, model.SubCaseManager2].filter(i => i),
                  ClaimantId: model.Claimant ? model.Claimant.Id : null,
                  ServiceNotesandFeeObj: {
                    AdminExtraFeeAmount: null,
                    AdminExtraFeeTaxPercentage: null,
                    CReferenceId: null,
                    CommissionOnTopRate: null,
                    CommissionRate: null,
                    CommissionRateType: 0,
                    Description: null,
                    HasOtherFee: false,
                    IncludeAdminExtraFeeToDoctorCommission: false,
                    IncludeManualFee1ToDoctorCommission: false,
                    IncludeServiceItemFeeToDoctorCommission: false,
                    InvoiceAttachment: null,
                    OtherFee1Description: null,
                    OtherFee1Label: null,
                    OtherFee1TaxPercentage: null,
                    OtherFee1Value: null,
                    OtherFee2Description: null,
                    OtherFee2Label: null,
                    OtherFee2TaxPercentage: null,
                    OtherFee2Value: null,
                    PayingCaseManagerId: null,
                    PayingClientId: null,
                    SameBookingCM: false,
                    SameClientAccEmail: false,
                    SendInvoice: null,
                    SendInvoice2: null,
                    SelectedServiceItemIds: '[]',
                    ListServiceItems: null,
                  },
                  ClaimantStatus: null,
                  SelectedDoctorProviderNoIdx: null,
                  SelectedAssessedDoctorProviderNoIdx: null,
                  // PCSameBookingClient: (model.PClient?.Id ?? -1) === (model.Client?.Id ?? 0) ? true : false,
                  // PMSameBookingCM: (model.PCaseManager?.Id ?? -1) === (model.CaseManager?.Id ?? 0) ? true : false,
                },
              });

              this.suppStatusLogs = null;
              if (!caseNo) {
                this.setFieldsValue({ loading: false });
                setTimeout(() => {
                  this.functionAutoRun();
                  this.setFieldsValue({ isReady: true });
                });
              } else {
                this.getOriginalByCaseNo(caseNo);
              }
            }
          },
        ),
      );
    } catch (e) {}
  };

  validateForm = () => {
    let errors = [];
    let errorClaimDetails = [];
    let errorCaseDocuments = [];
    const itemModel = toJS(this.supplementaryInfo);
    if (!this.supplementaryInfo.ClientId) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a booking client.');
    }
    if (!this.bookingCMSelected && !api.isDoctor()) {
      errorClaimDetails.push('Claim Details, Booking Client and CM - Please select a booking CM.');
    }
    if (!this.claimantSelected) {
      errorClaimDetails.push('Claim Details, Claimant Info - Please select a claimant');
    }
    if (!itemModel.ClaimNo) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please input reference/claim No.');
    }
    if (!itemModel.ReportId) {
      errorClaimDetails.push('Claim Details, Claim No - Claim Type - Please select type of claim.');
    }
    if (!itemModel.AssessedDoctorId && api.isAdminOrMagStaffOrAccounting()) {
      errorClaimDetails.push('Claim Details - Please select a Supp Report Assessor.');
    }
    if (itemModel.IROFundingApproved && !itemModel.IROFundingApprovalDate) {
      errorClaimDetails.push('Claim Details - IRO Funding Approval Date is required.');
    }
    // if (!itemModel.RequestExpectedCompletionDate && this.IsExpectedCompletionDate) {
    //   errorClaimDetails.push('Claim Details - Please input Expected Completion Date.');
    // }
    // if (!itemModel.RequestExpectedCompletionDate && this.IsExpectedCompletionDate) {
    //   errorClaimDetails.push('Claim Details - Please input Expected Completion Date.');
    // }
    // if (!itemModel.PaperworkReceivedDate && itemModel.IsPaperworkReceived) {
    //   errorCaseDocuments.push('Claim Details - Please input Date of Completion.');
    // }
    if (!itemModel.PaperworkByOtherMethodDate && itemModel.IsPaperworkReceived) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Paperwork - Please select a paperwork sent date.',
      );
    }
    if (itemModel.DictationReceived && itemModel.DictationReceivedDate == null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Dictation - Please input date of dictation received date.',
      );
    }
    if (itemModel.ReportDraftReceived && itemModel.ReportDraftReceivedDate == null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Report Draft - Please input date of report draft received date.',
      );
    }
    if (itemModel.ReportDraftReceived && itemModel.ReportDraftSentDate == null) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Report Draft - Please input date of draft sent date.',
      );
    }
    if (itemModel.ReportCompletedSentDate == null && itemModel.ReportCompleted) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Final Report - Please input date of report completion sent date.',
      );
    }
    if (!itemModel.Attachments.filter(i => i.Type === 0).length && itemModel.ReportCompleted) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Final Report - You need to attach completed reports.',
      );
    }
    if (itemModel.DictationSentToTypist && !itemModel.DictationTypistID) {
      errorCaseDocuments.push('Paperwork, Dictations & Report Information, Dictation - Please select a typist.');
    }
    if (
      itemModel.EmailReportDraftToDoctor &&
      !itemModel.EmailReportDraftToDoctorBE &&
      !itemModel.EmailReportDraftToDoctorPE
    ) {
      errorCaseDocuments.push(
        'Paperwork, Dictations & Report Information, Report Draft - At least one doctor email should be selected.',
      );
    }
    if (
      itemModel.IsInvoiceUrgentReport &&
      !!itemModel.PaperworkByOtherMethodDate &&
      !!itemModel.UrgentReportReportCompletionDate
    ) {
      var dfCompletionDate = itemModel.PaperworkByOtherMethodDate.businessDiff(
        itemModel.UrgentReportReportCompletionDate,
      );
      if (dfCompletionDate < 2 || dfCompletionDate > 7) {
        errorCaseDocuments.push(
          'Paperwork - Urgent report completion date must be between 2-7 business days of paperwork sent date.',
        );
      }
    }
    // if (itemModel.IsInvoiceUrgentReport && !itemModel.UrgentReportReportCompletionDate) {
    //   errorCaseDocuments.push(
    //     'Paperwork, Dictations & Report Information, Paperwork - Requested Completion Date Before is required.',
    //   );
    // }
    if (!!errorClaimDetails.length) {
      errors = [...errors, ...errorClaimDetails];
    }
    if (!!errorCaseDocuments.length) {
      errors = [...errors, ...errorCaseDocuments];
    }
    return errors;
  };

  updateStatus = newStatus => {
    return updateSuppReportStatus(this.supplementaryInfo.Id, newStatus).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been saved successfully',
        });

        action(() => {
          this.supplementaryInfo.CaseStatus = newStatus;
        })();

        getSUPPStatusLogs(this.supplementaryInfo.Id).then(logs => {
          action(() => {
            this.suppStatusLogs = logs.itemList;
          })();
        });
      }
    });
  };

  handleSave = () => {
    const errorsValidForm = this.validateForm();
    const { invalidForm } = this;
    if (!!errorsValidForm.length || !!invalidForm.length) {
      if (!!errorsValidForm.length) {
        return errorsValidForm.map(i => {
          this.showNotification('Error', i, 'error');
          return i;
        });
      }
      if (!!invalidForm.length) {
        return invalidForm.map(i => {
          this.showNotification('Error', i.message, 'error');
          return i;
        });
      }
    } else {
      const { supplementaryInfo, formatReqDate } = this;

      let bodyParam = {
        ...supplementaryInfo,
        ActionRequiredDueDate: formatReqDate(supplementaryInfo.ActionRequiredDueDate),
        ServiceNotesandFee: {
          AdminExtraFeeAmount: supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeAmount,
          AdminExtraFeeTaxPercentage: supplementaryInfo.ServiceNotesandFeeObj.AdminExtraFeeTaxPercentage,
          CReferenceId: this.CReferenceSelected?.Id || null,
          CommissionAmount: parseFloat(supplementaryInfo.ServiceNotesandFeeObj.CommissionAmount).toFixed(2),
          CommissionOnTopAmount: supplementaryInfo.ServiceNotesandFeeObj.CommissionOnTopAmount,
          CommissionOnTopRate: supplementaryInfo.ServiceNotesandFeeObj.CommissionOnTopRate,
          CommissionRate: supplementaryInfo.ServiceNotesandFeeObj.CommissionRate,
          CommissionRateType: supplementaryInfo.ServiceNotesandFeeObj.CommissionRateType,
          Description: supplementaryInfo.ServiceNotesandFeeObj.Description,
          HasOtherFee: supplementaryInfo.ServiceNotesandFeeObj.HasOtherFee,
          IncludeAdminExtraFeeToDoctorCommission:
            supplementaryInfo.ServiceNotesandFeeObj.IncludeAdminExtraFeeToDoctorCommission,
          IncludeManualFee1ToDoctorCommission:
            supplementaryInfo.ServiceNotesandFeeObj.IncludeManualFee1ToDoctorCommission,
          IncludeServiceItemFeeToDoctorCommission:
            supplementaryInfo.ServiceNotesandFeeObj.IncludeServiceItemFeeToDoctorCommission,
          InvoiceAttachment: supplementaryInfo.ServiceNotesandFeeObj.InvoiceAttachment,
          ListServiceItems: supplementaryInfo.ServiceNotesandFeeObj.ListServiceItems || null,
          PayingCaseManager: supplementaryInfo.ServiceNotesandFeeObj.PayingCaseManager || null,
          PayingClient: supplementaryInfo.ServiceNotesandFeeObj.PayingClient || null,
          CReference: supplementaryInfo.ServiceNotesandFeeObj.CReference || null,
          OtherFee1Description: supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Description,
          OtherFee1Label: supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Label,
          OtherFee1TaxPercentage: supplementaryInfo.ServiceNotesandFeeObj.OtherFee1TaxPercentage,
          OtherFee1Value: supplementaryInfo.ServiceNotesandFeeObj.OtherFee1Value,
          OtherFee2Description: supplementaryInfo.ServiceNotesandFeeObj.OtherFee2Description,
          OtherFee2Label: supplementaryInfo.ServiceNotesandFeeObj.OtherFee2Label,
          OtherFee2TaxPercentage: supplementaryInfo.ServiceNotesandFeeObj.OtherFee2TaxPercentage,
          OtherFee2Value: supplementaryInfo.ServiceNotesandFeeObj.OtherFee2Value,
          PayingCaseManagerId: this.payingCMSelected?.Id || null,
          PayingClientId: this.payingClientSelected?.Id || null,
          SameBookingCM: supplementaryInfo.ServiceNotesandFeeObj.SameBookingCM,
          SameClientAccEmail: supplementaryInfo.ServiceNotesandFeeObj.SameClientAccEmail,
          SelectedServiceItemIds: JSON.stringify(this.serviceItemId),
          SendInvoice: supplementaryInfo.ServiceNotesandFeeObj.SendInvoice,
          SendInvoice2: supplementaryInfo.ServiceNotesandFeeObj.SendInvoice2,
          TaxTotal: parseFloat(supplementaryInfo.ServiceNotesandFeeObj.TaxTotal),
          Total: supplementaryInfo.ServiceNotesandFeeObj.Total,
          TotalAmountForCommissionCalculation:
            supplementaryInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculation,
          TotalAmountForCommissionCalculationTax:
            supplementaryInfo.ServiceNotesandFeeObj.TotalAmountForCommissionCalculationTax,
          TotalWithoutTax: supplementaryInfo.ServiceNotesandFeeObj.TotalWithoutTax,
        },
        AssessedDoctor: this.specialistSelected,
        BookingSolicitor: this.bookingSolicitorSelected,
        CaseManager: this.bookingCMSelected,
        Claimant: this.claimantSelected,
        Client: this.bookingClientSelected,
        SubClient: this.bookingSubClientSelected,
        ClientPrepaymentRequiredDate: formatReqDate(supplementaryInfo.ClientPrepaymentRequiredDate),
        CompletionDate: formatReqDate(supplementaryInfo.CompletionDate),
        DateRequest: formatReqDate(supplementaryInfo.DateRequest),
        DictationReceivedDate: formatReqDate(supplementaryInfo.DictationReceivedDate),
        Doctor: this.originalSpecialistSelected,
        EmailInvoiceToMAGDateSent: formatReqDate(supplementaryInfo.EmailInvoiceToMAGDateSent),
        EmailPaymentReceiptToClientDateSent: formatReqDate(supplementaryInfo.EmailPaymentReceiptToClientDateSent),
        EmailPaymentReceiptToDoctorDateSent: formatReqDate(supplementaryInfo.EmailPaymentReceiptToDoctorDateSent),

        EmailReportDraftToDoctorDateSent: formatReqDate(supplementaryInfo.EmailReportDraftToDoctorDateSent),
        PCaseManager: this.pCMSelected,
        PClient: this.pClientSelected,
        PaperworkByOtherMethodDate: formatReqDate(supplementaryInfo.PaperworkByOtherMethodDate),
        PaperworkReceivedDate: formatReqDate(supplementaryInfo.PaperworkReceivedDate),
        PrepaymentRequiredDate: formatReqDate(supplementaryInfo.PrepaymentRequiredDate),
        ReportCompletedSentDate: formatReqDate(supplementaryInfo.ReportCompletedSentDate),
        ReportDraftReceivedDate: formatReqDate(supplementaryInfo.ReportDraftReceivedDate),
        ReportDraftSentDate: formatReqDate(supplementaryInfo.ReportDraftSentDate),
        RequestExpectedCompletionDate: formatReqDate(supplementaryInfo.RequestExpectedCompletionDate),
        S_RDCCaseManagerId: this.deliveryContactSecondSelected?.Id || null,
        F_RDCCaseManagerId: this.deliveryContactFirstSelected?.Id || null,
        CancelledDate: formatReqDate(supplementaryInfo.CancelledDate),
        TypistSubmittingDate: formatReqDate(supplementaryInfo.TypistSubmittingDate),
        ModiifedDate: formatReqDate(supplementaryInfo.ModiifedDate),
        SubCaseManager1: [...supplementaryInfo.SubBookingCM][0] || '',
        SubCaseManager2: [...supplementaryInfo.SubBookingCM][1] || '',
        SubCaseManager3: [...supplementaryInfo.SubBookingCM][2] || '',
        BookingSolicitorId: null,
        ClinicOption: 0,
        UrgentReportStaffId: supplementaryInfo.UrgentReportStaffId || '',
        StaffId: supplementaryInfo.StaffId || '',
        IcareClaim: supplementaryInfo.IcareClaim,
        InsurerId: supplementaryInfo.InsurerId || null,
        ReasonReferralId: supplementaryInfo.ReasonReferralId || null,
        IcareServiceTypeId: supplementaryInfo.IcareServiceTypeId || null,
      };

      if (!!bodyParam.Id) {
        bodyParam.ServiceNotesandFeeObj = bodyParam.ServiceNotesandFee;
      } else {
        bodyParam.ServiceNotesandFeeObj = null;
      }

      // delete bodyParam.DoctorId;
      // delete bodyParam.AssessedDoctorId;
      // delete bodyParam.ClientId;
      // delete bodyParam.CaseManagerId;
      // delete bodyParam.BookingSolicitorId;
      // delete bodyParam.PClientId;
      // delete bodyParam.PCaseManagerId;
      // delete bodyParam.SubBookingCM;
      // delete bodyParam.ClaimantId;

      if (!bodyParam.Id) {
        delete bodyParam.ServiceNotesandFeeObj;
      }
      if (bodyParam.UrgentReport && !bodyParam.UrgentReportCompleted) {
        bodyParam.ActionType = 1;
      } else if (bodyParam.ActionRequired && !bodyParam.ActionCompleted) {
        bodyParam.ActionType = 2;
      } else {
        bodyParam.ActionType = 0;
      }
      const paperworks = this.renderAttachments(bodyParam.FileReviewAttachment, [2, 3]);
      this.setFieldsValue({ loading: true });
      getUserUploadInfo(paperworks[0]?.UploadedById).then(uP => {
        let isAdminOrStaffUpload = false;
        if (uP.status === 'success') {
          if (uP?.User?.Roles.some(r => r === 'admin' || r === 'magstaff')) {
            isAdminOrStaffUpload = true;
          }
        }
        if (
          api.isCaseManager() &&
          ((!bodyParam.NotifyPaperworkReadinessToStaff && isAdminOrStaffUpload) || bodyParam.IsUpdatedPaperwork)
        ) {
          if (paperworks.length) {
            this.toggleModalConfirm(true, {
              message: `Are these all the documents you would like the specialist to review?`,
              onOk: () => {
                if (bodyParam.NotifyPaperworkReadinessToStaff === true) {
                  resendPaperworkReadiness(bodyParam.Id);
                }
                bodyParam.NotifyPaperworkReadinessToStaff = true;
                this.doSaveSupp(bodyParam);
              },
              functionCallbackCancel: () => {
                this.doSaveSupp(bodyParam);
              },
            });
          } else {
            this.doSaveSupp(bodyParam);
          }
        } else {
          this.doSaveSupp(bodyParam);
        }
      });
    }
  };

  doSaveSupp(bodyParam) {
    this.setFieldsValue({ loading: true });
    saveSupplementary(bodyParam)
      .then(res => {
        if (res.status === 'success') {
          // if (api.isCaseManager() && bodyParam.Id === 0 && bodyParam.ClientId && !bodyParam.WifroIlars) {
          //   return Promise.all([getSupplementReport(res.sId)]).then(([resAssessment]) => {
          //     // ClientType === 3 -> LawPlaintiff
          //     if (resAssessment && resAssessment.model.Client.ClientType === 3) {
          //       return this.setFieldsValue({
          //         isOpenModalDisbursementFunding: true,
          //         modalDisbursementFundingParams: {
          //           id: res.Id,
          //           caseNo: resAssessment.model.SuppNo,
          //           caseId: resAssessment.model.Id,
          //           fromService: 'S',
          //           claimant: `${resAssessment.model.Claimant?.Title} ${resAssessment.model.Claimant?.FullName}`,
          //           claimantId: resAssessment.model.Claimant?.Id,
          //           functionCallbackCancel: () => {
          //             this.fetchSupplementaryData(res.sId);
          //           },
          //           clientId: resAssessment.model.Client?.Id,
          //           isManual: false,
          //         },
          //       });
          //     } else {
          //       this.showNotification('Success', 'Data has been saved successfully.', 'success');
          //       this.fetchSupplementaryData(res.sId);
          //     }
          //   });
          // }
          this.showNotification('Success', 'Data has been saved successfully.', 'success');
          this.fetchSupplementaryData(res.sId);
        } else {
          if (res.status === 'fail' && res.error.RecordChange) {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'This record has been modified by another user. Please refresh and update again.',
            });
          }
          this.setFieldsValue({ loading: false });
        }
      })
      .catch(() => {
        this.setFieldsValue({ loading: false });
      });
  }
  handleRequestFunding = () => {
    if (!!this.supplementaryInfo.Id) {
      return this.setFieldsValue({
        isOpenModalDisbursementFunding: true,
        modalDisbursementFundingParams: {
          caseNo: this.supplementaryInfo.SuppNo,
          caseId: this.supplementaryInfo.Id,
          fromService: 'S',
          claimant: `${this.supplementaryInfo.Claimant?.Title} ${this.supplementaryInfo.Claimant?.FullName}`,
          claimantId: this.supplementaryInfo.Claimant?.Id,
          clientId: this.supplementaryInfo.ClientId,
          isManual: true,
        },
      });
    }
    return this.handleSave();
  };

  handleOpenAddEdit = (type = '', fieldName = '') => {
    const result = FIELD_NAME_FOR_ADD_EDIT.find(i => i.fieldName === fieldName);
    if (result) {
      const { pathname, computedName, storageName } = result;
      dashboardStore.close(pathname);
      if (type === 'add') {
        localStorage.removeItem(storageName);
        if (fieldName === 'PayingCaseManagerId' || fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
          const companyId =
            fieldName === 'CaseManagerId'
              ? this.bookingClientSelected?.Id
              : fieldName === 'PCaseManagerId'
              ? this.pClientSelected?.Id
              : fieldName === 'PayingCaseManagerId'
              ? this.payingClientSelected?.Id
              : 0;
          setTimeout(() =>
            dashboardStore.open(
              `${pathname}?companyId=${companyId || 0}&pageGetter=addEditSupp&fieldName=${fieldName}`,
            ),
          );
        } else {
          setTimeout(() =>
            dashboardStore.open(`${pathname}?action=edit?id=0&pageGetter=addEditSupp&fieldName=${fieldName}`),
          );
        }
      }
      if (type === 'edit') {
        const value = this[computedName];
        if (value) {
          localStorage.setItem(storageName, value.FullName || value.Name);
          if (fieldName === 'PayingCaseManagerId' || fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
            const companyId =
              fieldName === 'CaseManagerId'
                ? this.bookingClientSelected?.Id
                : fieldName === 'PCaseManagerId'
                ? this.pClientSelected?.Id
                : fieldName === 'PayingCaseManagerId'
                ? this.payingClientSelected?.Id
                : 0;
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?companyId=${companyId || 0}&id=${this[computedName]?.Id ||
                  0}&pageGetter=addEditSupp&fieldName=${fieldName}`,
              ),
            );
          } else {
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?action=edit&id=${this[computedName]?.Id ||
                  0}&pageGetter=addEditSupp&fieldName=${fieldName}`,
              ),
            );
          }
        } else {
          localStorage.removeItem(storageName);
          if (fieldName === 'PayingCaseManagerId' || fieldName === 'PCaseManagerId' || fieldName === 'CaseManagerId') {
            const companyId =
              fieldName === 'CaseManagerId'
                ? this.bookingClientSelected?.Id
                : fieldName === 'PCaseManagerId'
                ? this.pClientSelected?.Id
                : fieldName === 'PayingCaseManagerId'
                ? this.payingClientSelected?.Id
                : 0;
            setTimeout(() =>
              dashboardStore.open(
                `${pathname}?companyId=${companyId || 0}&pageGetter=addEditSupp&fieldName=${fieldName}`,
              ),
            );
          } else {
            setTimeout(() =>
              dashboardStore.open(`${pathname}?action=edit?id=0&pageGetter=addEditSupp&fieldName=${fieldName}`),
            );
          }
        }
      }
      return;
    }
    return;
  };

  handleResendNotifyCancellation = (sendtype = 0) => {
    this.toggleModalConfirm(true, {
      message: 'Do you want to resend cancellation confirmation?',
      onOk: () => {
        this.doResendNotifyCancellation(sendtype);
      },
      //functionCallbackCancel: () => this.handleChangeSuppValue({ AssessedDoctorId: oldDoctorId }),
    });
  };

  doResendNotifyCancellation = (sendtype = 0) => {
    const { supplementaryInfo } = this;
    customFetch('/Supplementary/ResendEmailCancelled', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ suppId: supplementaryInfo.Id, sendtype: sendtype }),
    })
      .then(response => {
        if (response.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend Cancellation Confirmation',
          });
          if (sendtype == 0) {
            this.handleChangeFileReviewValue({ SentDateNotifyCancellationToSolictor: response.sentDate });
          } else if (sendtype == 1) {
            this.handleChangeSuppValue({ SentDateNotifyBookingCMCancellation: response.sentDate });
          } else {
            this.handleChangeSuppValue({ SentDateNotifyDoctorCancellation: response.sentDate });
          }
          //store.handleChangeValue('EmailPaymentReceiptToClientDateSent', moment());
          //store.handleChangeValue('EmailPaymentReceiptToClientBy', response.sentBy);
        } else {
          notification.destroy();
          notification.Error({
            message: 'Error',
            description: 'Resend Cancellation Confirmation',
          });
        }
      })
      .catch(() => {});
  };

  @action clearStore = () => {
    this.isReady = false;
    this.loading = true;
    this.loadingConversation = false;

    this.invalidForm = [];
    this.IsExpectedCompletionDate = false;

    this.searchOptOriginalSpecialist = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptSpecialist = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptBookingClient = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptBookingSubClient = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptBookingCM = {
      keyword: '',
      numberItem: 30,
      companyId: 0,
    };
    this.searchOptReportDeliveryContactSecond = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptPClient = { keyword: '', numberItem: 30 };
    this.searchOptPCM = { keyword: '', numberItem: 30, companyId: 0 };
    this.searchOptClaimant = { keyword: '', numberItem: 30 };
    this.searchOptPayingClient = { keyword: '', numberItem: 30 };
    this.searchOptPayingCM = { keyword: '', numberItem: 30, companyId: 0 };
    this.searchOptCReference = { keyword: '', numberItem: 30 };

    this.imsCodes = [];
    this.serviceItemId = [];
    this.WIROPrincipleLawyers = [];
    this.originalSpecialists = [];
    this.specialists = [];
    this.bookingClients = [];
    this.bookingCMs = [];
    this.bookingSolicitors = [];
    this.subBookingCMs = [];
    this.pClients = [];
    this.pCMs = [];
    this.claimants = [];
    this.payingClients = [];
    this.payingCMs = [];
    this.CReferences = [];
    this.serviceItemCodes = [];
    this.staffs = [];
    this.typeOfClaims = [];
    this.typist = [];
    this.caseCorrespondences = [];
    this.actionRequiredList = [];
    this.invoices = [];
    this.lookupConfigs = [];
    this.suppStatusLogs = [];
    this.supplementaryInfo = SUPP_INIT_DATA;

    this.searchOptOriginalSpecialist = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptSpecialist = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptBookingClient = {
      keyword: '',
      numberItem: 30,
    };
    this.searchOptBookingCM = {
      keyword: '',
      numberItem: 1000,
      companyId: 0,
      IsOnboarded: true,
    };

    this.searchOptBookingSolicitor = {
      keyword: '',
      numberItem: 1000,
      companyId: 0,
    };
    this.searchOptSubBookingCMs = {
      keyword: '',
      numberItem: 1000,
      companyId: 0,
    };
    this.searchOptReportDeliveryContactSecond = {
      keyword: '',
      numberItem: 5000,
    };
    this.searchOptPClient = { keyword: '', numberItem: 30 };
    this.searchOptPCM = { keyword: '', numberItem: 1000, companyId: 0 };
    this.searchOptClaimant = { keyword: '', numberItem: 30 };
    this.searchOptPayingClient = { keyword: '', numberItem: 30 };
    this.searchOptPayingCM = { keyword: '', numberItem: 1000, companyId: 0 };
    this.searchOptCReference = { keyword: '', numberItem: 30 };
  };

  @observable isLoadingAudioTranscribe = false;
  @action getAudioTranscription = (attachmentFile, language) => {
    this.isLoadingAudioTranscribe = true;
    customFetch(`/Supplementary/GenerateAudioTranscription?fileId=${attachmentFile.Id}&language=${language}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 'success') {
          notification.destroy();

          if (!attachmentFile.TranscriptId) {
            notification.success({
              message: 'Success',
              description: 'Generate audio transcription successfully!',
            });
          }
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          // description: '',
        });
      })
      .finally(() => {
        action(() => {
          this.isLoadingAudioTranscribe = false;
        })();

        this.fetchSupplementaryData(this.supplementaryInfo?.Id);
      });
  };
  handleResetStore = () => {
    this.handleDisposeReaction();
    this.clearStore();
  };
}

export default new SupplementaryReportsStore();
