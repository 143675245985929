import { observer } from 'mobx-react';
import React from 'react';
import * as api from '@stores/api';
import TableToolbar from '../../shared/tableToolbar';
import store from './Store';

const statusLabels = [
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class DomainExclusionToolbar extends React.Component {
  renderFilterOptions = array => {
    return array.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  handleCreate = () => {
    return store.toggleModal(true, { modalType: 'addEdit', id: 0 })();
  };

  // renderFilterText = (value, array) => {
  //   let labels = array.filter(opt => opt.value === value).map(opt => opt.text);
  //   return labels[0];
  // };
  // handleFilter = (_, { name, value }) => {
  //   store.refetch({ [name]: value, curPage: 1 }, true, name === 'filter' ? 'byKeyword' : 'byType');
  // };

  onChangeSearchInput = (_, { value }) => {
    store.setFieldsValue({ keyword: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    store.refetch({ curPage: 1 }, true);
  };

  handleReset = () => {
    store.resetStore();
  };

  render() {
    const { keyword, loading, filter } = store;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left>
          <TableToolbar.Input
            placeholder={'Search domain'}
            value={keyword}
            onChange={this.onChangeSearchInput}
            disabled={loading}
          />
          {/* <TableToolbar.Filter
            disabled={loading}
            value={this.renderFilterText(filter, statusLabels)}
            label="Status"
            name="filter"
            options={this.renderFilterOptions(statusLabels)}
            onChange={this.handleFilter}
          /> */}
          <TableToolbar.SubmitButton onClick={this.handleSubmit}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            className="negative"
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {!api.isOnlySearch() && <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.handleCreate}>
            Add Domain
          </TableToolbar.HighLightButton>}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default DomainExclusionToolbar;
