import './FormGroupTitle.scss';

import React from 'react';

const FormGroupTitle = ({ children }) => (
  <div className="FormGroupTitle">
    <div className="FormGroupTitle-Text">{children}</div>
    <div className="FormGroupTitle-Divider" />
  </div>
);

export default FormGroupTitle;
