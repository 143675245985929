import React from 'react';
import { Form, Input, DatePicker, Checkbox, Divider, Select, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import moment from 'moment';

import store from './store';
import { CONSTANTS } from './type';
import FieldUpload from './FieldUpload';
import customFetch from '@utils/customFetch';
import * as api from '@stores/api';
import ListInvoice from './ListInvoice';
import { renderDate } from '../Shared';

@observer
class ProviderPayment extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  handleUploadProviderInvoice = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    if (!itemModel.EmailInvoiceToMAG) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to email invoice to Medicolegal Provider?',
        onOk: () => {
          store.handleChangeCRValue({ EmailInvoiceToMAG: true });
        },
      });
    }
  };
  handleResendInvoice = () => {
    store.toggleModalConfirm(true, {
      message: 'Do you want to resend invoice?',
      onOk: () => {
        const itemModel = toJS(store.clinicalNoteInfo);
        customFetch('/ClinicalNote/ResendInvoice', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: itemModel.Id }),
        }).then(res => {
          if (res.status === 'success') {
            store.showNotification('Success', 'Resend Invoice', 'success');
            store.handleChangeCRValue({ EmailInvoiceToMAGDateSent: moment() });
          } else {
            store.showNotification('Error', 'Resend Invoice');
          }
        });
      },
    });
  };
  handleAfterUploadPaymentReceipt = () => {
    const itemModel = toJS(store.clinicalNoteInfo);
    if (!itemModel.IsSendPaymentToProvider) {
      store.toggleModalConfirm(true, {
        message: 'Do you want to send payment receipt to provider?',
        onOk: () => {
          store.handleChangeCRValue({ IsSendPaymentToProvider: true });
        },
      });
    }
  };
  render() {
    const itemModel = toJS(store.clinicalNoteInfo);
    const { staffs } = store;
    const {
      handleChangeFieldSelect,
      handleChangeFieldInput,
      handleChangeDatePicker,
      getMessageError,
      handleChangeFieldInputNumber,
      handleChangeFieldCheckbox,
    } = store;
    if (api.isAdminOrMagStaffOrAccounting())
      return (
        <Form name="provider-payment">
          <Form.Item label="Provider Invoice No">
            <Input
              size="large"
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
              value={itemModel.ClinicInvoiceNo}
              onChange={handleChangeFieldInput('ClinicInvoiceNo')}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Date"
            required={itemModel.IsPaymentRequested}
            validateStatus={getMessageError('PaymentRequestedDate', 'field') ? 'error' : null}
            help={
              getMessageError('PaymentRequestedDate', 'field') ? getMessageError('PaymentRequestedDate', 'field') : ''
            }
          >
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              value={renderDate(itemModel.PaymentRequestedDate)}
              onChange={handleChangeDatePicker('PaymentRequestedDate')}
              style={{ width: 250 }}
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
            />
          </Form.Item>
          <Form.Item label="Amount (excl GST)">
            <InputNumber
              size="large"
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
              style={{ width: 250 }}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              value={itemModel.PaymentRequestedInvoiceAmount}
              onBlur={store.CalculaTaxPaymentInvoiceAmount}
              onChange={handleChangeFieldInputNumber('PaymentRequestedInvoiceAmount')}
            />
          </Form.Item>
          <Form.Item label="GST">
            <InputNumber
              size="large"
              value={itemModel.PaymentRequestedInvoiceTax}
              onChange={handleChangeFieldInputNumber('PaymentRequestedInvoiceTax')}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: 250 }}
            />
          </Form.Item>
          <FieldUpload
            label="Attach Provider Invoice"
            multiple
            type={2}
            functionCallback={this.handleUploadProviderInvoice}
            disabled={
              itemModel.IsLocked ||
              (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
            }
          />
          <div className="checkbox-field fluid-field">
            <Checkbox
              style={{ marginTop: '20px' }}
              checked={itemModel.EmailInvoiceToMAG}
              onChange={handleChangeFieldCheckbox('EmailInvoiceToMAG')}
              disabled={!!itemModel.EmailInvoiceToMAGDateSent}
            >
              Email Invoice to Medicolegal Provider{' '}
              {this.renderPopup('Provider invoice will be sent to staff (MAIL-135)')}
            </Checkbox>
            {itemModel.EmailInvoiceToMAGDateSent && (
              <span className="field-actions">
                {renderDate(itemModel.EmailInvoiceToMAGDateSent, false, true)}{' '}
                {itemModel.EmailInvoiceToMAGStaffSent ? ` - By ${itemModel.EmailInvoiceToMAGStaffSent}` : ''}
              </span>
            )}
            {!!itemModel.EmailInvoiceToMAGDateSent && (
              <Button className="blue" onClick={this.handleResendInvoice}>
                Resend Invoice
              </Button>
            )}
          </div>
          <Divider className="fluid-field" />
          <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              checked={itemModel.IsPaymentRequested}
              onChange={handleChangeFieldCheckbox('IsPaymentRequested')}
              className="checkbox-session"
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
            >
              Prepayment Required {this.renderPopup('Prepayment notice will be sent to staff (MAIL-50)')}
            </Checkbox>
          </div>
          <Form.Item
            label={
              <React.Fragment>
                PIC 3
                <button className="field-actions" onClick={store.openModalHistory}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={store.checkStaffIsExist(itemModel.PICPrepaymentRequired) || undefined}
              onChange={handleChangeFieldSelect('PICPrepaymentRequired')}
            >
              {staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Prepayment Required Date"
            required={itemModel.IsPaymentRequested}
            validateStatus={getMessageError('PrepaymentRequiredDate', 'field') ? 'error' : null}
            help={
              getMessageError('PrepaymentRequiredDate', 'field')
                ? getMessageError('PrepaymentRequiredDate', 'field')
                : ''
            }
          >
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              onChange={handleChangeDatePicker('PrepaymentRequiredDate')}
              value={renderDate(itemModel.PrepaymentRequiredDate)}
              disabled={!itemModel.IsPaymentRequested}
              style={{ width: 250 }}
            />
          </Form.Item>
          <Divider className="fluid-field" />
          <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              checked={itemModel.InvoicePaid}
              onChange={handleChangeFieldCheckbox('InvoicePaid')}
              className="checkbox-session"
              disabled={
                itemModel.IsLocked ||
                (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                  itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
              }
            >
              Prepayment Paid
            </Checkbox>
          </div>
          <Form.Item
            label={
              <React.Fragment>
                PIC 4
                <button className="field-actions" onClick={store.openModalHistory}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={store.checkStaffIsExist(itemModel.PICPrepaymentPaid) || undefined}
              onChange={handleChangeFieldSelect('PICPrepaymentPaid')}
            >
              {staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {itemModel.InvoicePaid && (
            <>
              <Form.Item
                label="Payment Date"
                required={itemModel.InvoicePaid}
                validateStatus={getMessageError('InvoicePaidDate', 'field') ? 'error' : null}
                help={getMessageError('InvoicePaidDate', 'field') ? getMessageError('InvoicePaidDate', 'field') : ''}
              >
                <DatePicker
                  size="large"
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                  }
                  format="DD/MM/YYYY"
                  onChange={handleChangeDatePicker('InvoicePaidDate')}
                  value={renderDate(itemModel.InvoicePaidDate)}
                />
              </Form.Item>
              <FieldUpload
                type={3}
                label="Attach Payment Receipt"
                multiple
                disabled={
                  itemModel.IsLocked ||
                  (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                    itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                }
                functionCallback={this.handleAfterUploadPaymentReceipt}
              />
              <div>
                Provider Accounting Email:{' '}
                <Checkbox
                  style={{ marginBottom: '20px' }}
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                  }
                  checked={itemModel.SameAsBusinessEmail}
                  onChange={handleChangeFieldCheckbox('SameAsBusinessEmail')}
                >
                  To Business Email
                </Checkbox>
                <Input
                  size="large"
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                  }
                  value={itemModel.SameAsBusinessEmailSend}
                  onChange={handleChangeFieldInput('SameAsBusinessEmailSend')}
                />
              </div>
              <Form.Item label="Comments" className="fluid-field">
                <Input.TextArea
                  rows={3}
                  value={itemModel.InvoicePaidComment}
                  onChange={handleChangeFieldInput('InvoicePaidComment')}
                  disabled={
                    itemModel.IsLocked ||
                    (itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.None &&
                      itemModel.CancellationStatus !== CONSTANTS.CancellationStatus.OnHold)
                  }
                />
              </Form.Item>
              <div className="fluid-field checkbox-field">
                <Checkbox
                  disabled={
                    itemModel.IsLocked ||
                    !store.renderAttachment(itemModel.Attachments, 3).length ||
                    !!itemModel.SendPaymentToProviderDate
                  }
                  checked={itemModel.IsSendPaymentToProvider}
                  onChange={handleChangeFieldCheckbox('IsSendPaymentToProvider')}
                >
                  Email Payment Receipt to Provider{' '}
                  {this.renderPopup('Payment receipt will be sent to Specialist (Mail-49)')}
                </Checkbox>
                {itemModel.IsSendPaymentToProvider && !!itemModel.SendPaymentToProviderDate && (
                  <span className="field-actions">
                    {renderDate(itemModel.SendPaymentToProviderDate, false, true)} - by{' '}
                    {itemModel.SendPaymentToProviderSendBy}
                  </span>
                )}
                {itemModel.IsSendPaymentToProvider && !!itemModel.SendPaymentToProviderDate && (
                  <Button className="blue" onClick={store.ReSentNotifyPaymentToProvider}>
                    Resend Receipt
                  </Button>
                )}
              </div>
            </>
          )}
        </Form>
      );
    return <ListInvoice />;
  }
}

export default ProviderPayment;
