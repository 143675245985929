import './CustomButton.scss';

import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';

export default function CustomButton({ className, children, type, ...props }) {
  type = type || 'submit';

  return (
    <Button
      {...props}
      type={type}
      className={cx(
        {
          'btn-primary': type === 'primary',
          'btn-secondary': type === 'secondary',
          'btn-dark': type === 'dark',
        },
        className,
      )}
    >
      {children || 'Submit'}
    </Button>
  );
}
