import { notification } from 'antd';
import React from 'react';
import { Button, Form, Input, Segment, TextArea, Divider } from 'semantic-ui-react';
import customFetch from '../../../utils/customFetch';
import dashboard from '../../../stores/dashboard';

import './index.scss';
import router from '../../../stores/router';

class SendFeedBack extends React.Component {
  state = {
    Subject: '',
    Content: '',
  };
  handleChange = (_, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };
  handleSave = () => {
    const { Subject, Content } = this.state;
    if (Subject && Content) {
      customFetch('/SendFeedback/PostSendFeedback', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fbContent: Content, fbSubject: Subject }),
      })
        .then(res => {
          if (res.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Feedback submitted successfully',
              duration: 4,
            });
            return dashboard.close(router.location.pathname);
          }
        })
        .catch(() => {
          notification.destroy();
          return notification.error({
            message: 'Error',
            description: 'An error occurred, please try again',
            duration: 4,
          });
        });
    } else {
      notification.destroy();
      return notification.error({
        message: 'Error',
        description: 'Invalid form',
        duration: 4,
      });
    }
  };
  handleClose = () => {
    return dashboard.close(router.location.pathname);
  };
  render() {
    return (
      <div className="Form-Feedback-Container">
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <h4>
                We are looking forward to your valued feedback to help improve our service to you.
                <br />
                One of our staff will respond to your feedback shortly
              </h4>
              <Divider />
            </Form.Field>
          </Form.Group>
          <Segment>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Subject:</label>
                <Input className="text-input" name="Subject" value={this.state.Subject} onChange={this.handleChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Your feedback:</label>
                <TextArea
                  className="text-area"
                  name="Content"
                  value={this.state.Content}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Button onClick={this.handleSave} className="btn-send blue px-2">
              Send
            </Button>
            <Button onClick={this.handleClose} className="negative px-2">
              Cancel
            </Button>
          </Segment>
        </Form>
      </div>
    );
  }
}

export default SendFeedBack;
