import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStore from './store';
import ModalDuplicate from './ModalDuplicate';
import ModalRecentChange from './ModalRecentChange';
import ModalNotification from './ModalNotification';
import ModalEditUser from './ModalEditUser';
import ModalPasswordResetHistory from './ModalPasswordResetHistory';
import ModalChangePassword from './ModalChangePassword';
import ModalDisableUser from './ModalDisableUser';
import ModalStatusHistory from './ModalStatusHistory';

function AddEditModal() {
  const { modalType } = AddEditStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'duplicate') {
    return <ModalDuplicate {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'recentChange') {
    return <ModalRecentChange {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'notification') {
    return <ModalNotification {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'editUser') {
    return <ModalEditUser onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'passwordRsHistory') {
    return <ModalPasswordResetHistory onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'changePassword') {
    return <ModalChangePassword onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'disable') {
    return <ModalDisableUser {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
  if (modalType === 'viewStatusHistory') {
    return <ModalStatusHistory {...AddEditStore} onCancel={AddEditStore.toggleModal(false)} />;
  }
}

export default AddEditModal;
