import React from 'react';
import cx from 'classnames';
import { Divider, notification, Spin } from 'antd';
import { observer } from 'mobx-react';
import { toJS, action } from 'mobx';
import moment from 'moment';
import { Button, Dimmer } from 'semantic-ui-react';

import '../AddEditClinic/index.scss';
import './index.scss';

import dashboardStore from '../../../stores/dashboard';
import GeneralInformation from './GeneralInformation';
import DisbursementFunding from './DisbursementFunding';
import ReportDeliveryContact from './ReportDeliveryContact';
import AssignCMAndBU from './AssignCMAndBU';
import AddEditStore from './store';
import AssignDoctor from './AssignDoctor';
import BookingHistory from './BookingHistory';
import Modal from './Modal';
import { formatDate } from '../../../utils/functions';
import { ERROR_FIELDNAME, ERROR_MESSAGES } from './type';
import { saveCompany, registeredSpecialists } from './service';
import router from '../../../stores/router';
import CaseCorrespondence from './CaseCorrespondence';
import ActionRequired from './ActionRequired';
import ActionRequiredModal from './ActionRequiredModal';
import * as api from '@stores/api';

const STEPS = [
  'General Information',
  'Notifications & Subscriptions',
  'Business Units',
  'Booking History',
  'Favourite Specialists',
  'Disbursement Funding',
  'Case Correspondence',
  'Action Required',
];

const STEPS2 = [
  'General Information',
];

const VALIDATE_RULES = {
  Name: 'required',
  Telephone: 'max:10',
  Email: 'email',
  // EffectiveMAGDirectEssentialFrom: 'required',
  // EffectiveMAGDirectExtraFrom: 'required',
  // EffectiveMAGUltimateFrom: 'required',
  CityId: 'required',
  AccPhoneNumber: 'max:10',
  AccEmail: 'email',
  DefaultBookingConfirmationEmail: 'email',
  ReportDeliveryContactEmail: 'email',
};

window.functionGetHeightAddEditClientStep = () => {
  return document.getElementById('client-add-edit-step');
};

@observer
class AddEditClient extends React.Component {
  state = {
    currentStep: 0,
    isRerender: false,
    pageGetter: null,
    fieldName: null,
  };

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/add-edit-client-2', true);
      return;
    }

    const { id, action, step = 0, pageGetter, fieldName } = this.getRouteParams();

    if (pageGetter && fieldName) {
      this.setState({ pageGetter, fieldName });
    }
    if (id) {
      this.setState({ id, action, currentStep: +step }, () => {
        AddEditStore.fetchAll(id);
      });
    } else {
      AddEditStore.initData();
    }
  }
  getClientHeight = () => {
    const tbl = document.getElementById('page-container-add-edit-client');
    if (tbl) {
      return tbl.clientHeight;
    }
    return window.innerHeight;
  };
  componentWillUnmount() {
    AddEditStore.resetStore();
  }

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const state = router.location.state;
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return {
      ...params,
      ...state,
    };
  };

  showFormErrorNoti = (message = 'Invalid form') => {
    notification.error({
      message: 'Error',
      description: message,
      duration: 5,
    });
  };

  getKeywordErrorMessage = message => {
    return message
      ? message.toLowerCase().search('invalid') !== -1
        ? 'invalid'
        : message.toLowerCase().search('required') !== -1
        ? 'required'
        : message.toLowerCase().search('10 digits') !== -1
        ? 'maxdigits'
        : message.toLowerCase().search('6 digits') !== -1
        ? 'mindigits'
        : null
      : null;
  };

  retrunErrorMessage = (keywordFieldName, message) => {
    if (keywordFieldName === 'RecruitmentStatus') {
      return this.showFormErrorNoti(message);
    } else if (this.getKeywordErrorMessage(message) && keywordFieldName) {
      if (keywordFieldName === 'Email' && this.getKeywordErrorMessage(message) === 'invalid') {
        return this.showFormErrorNoti('Invalid email address');
      } else {
        return this.showFormErrorNoti(
          `${ERROR_FIELDNAME[keywordFieldName]} ${ERROR_MESSAGES[this.getKeywordErrorMessage(message)]}`,
        );
      }
    } else {
      return this.showFormErrorNoti('An error occurred, please try again');
    }
  };

  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          const date = moment(dateFormat, 'DD MMM,YYYY').format('YYYY-MM-DD');
          return moment(date, 'YYYY-MM-DD').toDate();
        } else {
          const date = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
          return moment(date, 'YYYY-MM-DD').toDate();
        }
      } else {
        const date = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
        return moment(date, 'YYYY-MM-DD').toDate();
      }
    }
    return null;
  };

  createBodyParam = () => {
    const data = toJS(AddEditStore.clientInfo);
    const bodyParam = {
      ABN: data.ABN,
      AccContact: data.AccContact,
      AccEmail: data.AccEmail,
      AccPhoneNumber: data.AccPhoneNumber,
      ActionCompleted: data.ActionCompleted,
      ActionNotes: data.ActionNotes,
      ActionRequired: data.ActionRequired,
      Description: data.Description,
      Address: data.Address,
      AppointmentBookingReminderDueDay: data.AppointmentBookingReminderDueDay,
      ApprovedFundingDate: this.convertDate(data.ApprovedFundingDate),
      AssignedToUserId: data.AssignedToUserId,
      CityId: data.CityId,
      ClientAdvisors: data.ClientAdvisors,
      ClientType: data.ClientType,
      ClientType2: data.ClientType2,
      CommentsFunding: data.CommentsFunding,
      CompanyBusinessUnit: data.CompanyBusinessUnit,
      CompletedDate: this.convertDate(data.CompletedDate),
      CorrespondenceAddress: data.CorrespondenceAddress,
      CorrespondenceCityId: data.CorrespondenceCityId,
      CorrespondenceCountryId: data.CorrespondenceCountryId,
      CorrespondenceDistrict: data.CorrespondenceDistrict,
      CorrespondencePostcode: data.CorrespondencePostcode,
      CountryId: data.CountryId,
      District: data.District,
      DoctorTypesForSearching: data.DoctorTypesForSearching,
      DueDate: this.convertDate(data.DueDate),
      EffectiveMAGDirectEssentialFrom: this.convertDate(data.EffectiveMAGDirectEssentialFrom),
      EffectiveMAGDirectEssentialTo: this.convertDate(data.EffectiveMAGDirectEssentialTo),
      EffectiveMAGDirectExtraFrom: this.convertDate(data.EffectiveMAGDirectExtraFrom),
      EffectiveMAGDirectExtraTo: this.convertDate(data.EffectiveMAGDirectExtraTo),
      EffectiveMAGUltimateFrom: this.convertDate(data.EffectiveMAGUltimateFrom),
      EffectiveMAGUltimateTo: this.convertDate(data.EffectiveMAGUltimateTo),
      Email: data.Email,
      DXEmail: data.DXEmail,
      EndFundingTerm: this.convertDate(data.EndFundingTerm),
      Fax: data.Fax,
      FundingApprovedById: data.FundingApprovedById,
      FundingAttachments: data.FundingAttachments,
      Id: data.Id,
      IsDisbursementFunding: data.IsDisbursementFunding,
      MAGDirectEssentialServiceFees: data.MAGDirectEssentialServiceFees,
      MAGDirectEssentialServiceFeesGST: data.MAGDirectEssentialServiceFeesGST,
      MAGDirectExtraServiceFees: data.MAGDirectExtraServiceFees,
      MAGDirectExtraServiceFeesGST: data.MAGDirectExtraServiceFeesGST,
      MAGUltimateServiceFees: data.MAGUltimateServiceFees,
      MAGUltimateServiceFeesGST: data.MAGUltimateServiceFeesGST,
      Name: data.Name,
      OtherClientType: data.OtherClientType,
      Postcode: data.Postcode,
      PrimarySegments: data.PrimarySegments,
      RDCCaseManagerEmail: data.RDCCaseManagerEmail,
      RDCCaseManagerName: data.RDCCaseManagerName,
      ReportCompletionReminderDueDay: data.ReportCompletionReminderDueDay,
      ReportDeliveryContact: data.ReportDeliveryContact,
      SecondarySegments: data.SecondarySegments,
      StaffId: data.StaffId,
      StartFundingTerm: this.convertDate(data.StartFundingTerm),
      SubscribeToInvoiceReminders: data.SubscribeToInvoiceReminders,
      SubscribeToPromotions: data.SubscribeToPromotions,
      SubscriptionNotes: data.SubscriptionNotes,
      PreApprovedDisbursementFundingTaken: data.PreApprovedDisbursementFundingTaken,
      PreApprovedDisbursementFunding: data.PreApprovedDisbursementFunding,
      PreApprovedDisbursementFundingAmount: data.PreApprovedDisbursementFundingAmount,
      DisbursmentFundingAmount: data.DisbursmentFundingAmount,
      Telephone: data.Telephone,
      Website: data.Website,
      DefaultBookingConfirmationEmail: data.DefaultBookingConfirmationEmail,
      ReportDeliveryContactEmail: data.ReportDeliveryContactEmail,
      SubscribeSpecialistInvoiceCheck: data.SubscribeSpecialistInvoiceCheck,
      CreditTerm: data.CreditTerm,
      IcareInsurer: data.IcareInsurer,
      ClientProfileId: data.ClientProfileId,
      ClientProfileOther: data.ClientProfileOther,
    };
    return bodyParam;
  };

  renderErrorFieldName = type => {
    switch (type) {
      case 0:
        return 'EffectiveMAGUltimateFrom';
      case 1:
        return 'EffectiveMAGDirectEssentialFrom';
      case 2:
        return 'EffectiveMAGDirectExtraFrom';
      default:
        return '';
    }
  };

  detectInvalidField = fieldName => {
    const { clientInfo } = AddEditStore;
    if (fieldName === 'EffectiveMAGUltimateFrom') {
      if (clientInfo.ClientType2 === 0) return AddEditStore.handleValidate(fieldName, clientInfo[fieldName]);
      return null;
    } else if (fieldName === 'EffectiveMAGDirectEssentialFrom') {
      if (clientInfo.ClientType2 === 1) return AddEditStore.handleValidate(fieldName, clientInfo[fieldName]);
      return null;
    } else if (fieldName === 'EffectiveMAGDirectExtraFrom') {
      if (clientInfo.ClientType2 === 2) return AddEditStore.handleValidate(fieldName, clientInfo[fieldName]);
      return null;
    }
    return AddEditStore.handleValidate(fieldName, clientInfo[fieldName]);
  };

  compareDate = (dateF, dateS) => {
    if (!!dateF && !dateS) {
      return true;
    } else if (!dateF && !!dateS) {
      return false;
    } else if (!!dateF && !!dateS) {
      return moment(dateF).isSameOrBefore(moment(dateS));
    } else {
      return true;
    }
  };

  @action handleSaveData = () => {
    const { clientInfo } = AddEditStore;
    if(AddEditStore.isDuplicateComp && clientInfo.Id === 0){
      AddEditStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'A similar client name exists. Do you want to add new one?',
        onOk: () => this.handleSave(),
      })();
    } else {
      this.handleSave();
    }
  }

  handleSave = () => {
    const bodyParam = this.createBodyParam();
    AddEditStore.setFieldsValue({ loadingSave: true });
    Object.keys(VALIDATE_RULES).map(i => this.detectInvalidField(i));
    const { isRerender } = this.state;
    this.setState({ isRerender: !isRerender });
    if (bodyParam.PreApprovedDisbursementFunding && !bodyParam.PreApprovedDisbursementFundingAmount) {
      AddEditStore.handleValidate('PreApprovedDisbursementFundingAmount', null);
      this.showFormErrorNoti('Pre-Approved Amount is required');
      return;
    } else {
      AddEditStore.removeError('PreApprovedDisbursementFundingAmount');
    }
    if (bodyParam.IsDisbursementFunding && !bodyParam.DisbursmentFundingAmount) {
      AddEditStore.handleValidate('DisbursmentFundingAmount', null);
      this.showFormErrorNoti('Approved Amount is required');

      return;
    } else {
      AddEditStore.removeError('DisbursmentFundingAmount');
    }
    if (bodyParam.CountryId === 16 && !bodyParam.CityId) {
      AddEditStore.handleValidate('CityId', null);
      return this.showFormErrorNoti('State is required');
    } else {
      AddEditStore.removeError('CityId');
    }
    if (!bodyParam.ClientType) {
      AddEditStore.errors = {
        ...AddEditStore.errors,
        OtherClientType: 'This field is required',
      };
    }
    if (bodyParam.ClientType2 < 0 || bodyParam.ClientType2 === null) {
      AddEditStore.errors = {
        ...AddEditStore.errors,
        ClientType2: 'This field is required',
      };
    }
    const errors = toJS(AddEditStore.errors);
    if (!!Object.keys(errors).length) {
      AddEditStore.setFieldsValue({ loadingSave: false });
      notification.destroy();
      const errorList = Object.entries(AddEditStore.errors);
      return errorList.map(([key, value]) => this.retrunErrorMessage(key, value));
    }

    const { fieldName, pageGetter } = this.state;
    const { listPageGetters } = dashboardStore;
    const urlPageGetter = listPageGetters.find(i => i.key === pageGetter);

    const {
      EffectiveMAGDirectEssentialFrom,
      EffectiveMAGDirectEssentialTo,
      EffectiveMAGDirectExtraFrom,
      EffectiveMAGDirectExtraTo,
      EffectiveMAGUltimateFrom,
      EffectiveMAGUltimateTo,
      ClientType2,
    } = bodyParam;
    if (
      (ClientType2 === 1 && !this.compareDate(EffectiveMAGDirectEssentialFrom, EffectiveMAGDirectEssentialTo)) ||
      (ClientType2 === 2 && !this.compareDate(EffectiveMAGDirectExtraFrom, EffectiveMAGDirectExtraTo)) ||
      (ClientType2 === 0 && !this.compareDate(EffectiveMAGUltimateFrom, EffectiveMAGUltimateTo))
    ) {
      notification.destroy();
      return notification.error({
        message: 'Error',
        description: 'Effective to must be after effective from',
      });
    }

    if (bodyParam.Id !== 0) {
      Promise.all([saveCompany(bodyParam), registeredSpecialists(bodyParam.Id, AddEditStore.registeredSpecialistIds)])
        .then(([res, resRegisterdSpecialist]) => {
          if (res.status === 'success' && resRegisterdSpecialist.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
            });
            AddEditStore.setFieldsValue({ loadingSave: false });
            if (fieldName && pageGetter) {
              dashboardStore.transferData({
                fieldName: fieldName,
                pageGetter: pageGetter,
                dataTransfer: bodyParam,
              });
            }
            if (urlPageGetter && urlPageGetter.pathname) {
              dashboardStore.close(router.location.pathname);
              setTimeout(() => {
                dashboardStore.open(urlPageGetter.pathname);
              });
            }
            return;
          } else {
            AddEditStore.setFieldsValue({ loadingSave: false });
            return this.showFormErrorNoti('An error occurred, please try again');
          }
        })
        .catch(() => {
          this.showFormErrorNoti('An error occurred, please try again');
          AddEditStore.setFieldsValue({ loadingSave: false });
        });
    } else {
      Promise.all([saveCompany(bodyParam)])
        .then(([res]) => {
          if (res.status === 'success') {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: 'Data has been saved successfully.',
            });
            AddEditStore.setFieldsValue({ loadingSave: false });
            if (fieldName && pageGetter) {
              dashboardStore.transferData({
                fieldName: fieldName,
                pageGetter: pageGetter,
                dataTransfer: { ...bodyParam, Id: res.newId },
              });
            }

            if (urlPageGetter && urlPageGetter.pathname) {
              dashboardStore.close(router.location.pathname);
              setTimeout(() => {
                dashboardStore.open(urlPageGetter.pathname);
              });
            } else {
              localStorage.setItem('CompanyName', bodyParam.Name);
              dashboardStore.close('/view/add-edit-client-2');
              setTimeout(() => {
                dashboardStore.open(`/view/add-edit-client-2?id=${res.newId}`);
              });
            }
          } else {
            return this.showFormErrorNoti('An error occurred, please try again');
          }
        })
        .catch(error => {
          console.error(error);
          this.showFormErrorNoti('An error occurred, please try again');
          AddEditStore.setFieldsValue({ loadingSave: false });
        });
    }
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <ReportDeliveryContact />;
      case 2:
        return <AssignCMAndBU />;
      case 3:
        return <BookingHistory />;
      case 4:
        return <AssignDoctor />;
      case 5:
        return <DisbursementFunding />;
      case 6:
        return <CaseCorrespondence />;
      case 7:
        return <ActionRequired />;
      default:
        return <GeneralInformation isRerender={this.state.isRerender} />;
    }
  };

  handleClose = () => {
    return AddEditStore.toggleModal(true, {
      modalType: 'confirm',
      message: 'Are you sure not to save changes?',
      onOk: () => dashboardStore.close(router.location.pathname),
    })();
  };

  render() {
    const { loadingSave, loading, loadingCheckDuplicate, open, loadingBookingHistory } = AddEditStore;
    return (
      <div className="page-container add-edit-client" id="page-container-add-edit-client">
        <Dimmer
          active={loading || loadingCheckDuplicate || loadingSave || loadingBookingHistory}
          inverted
          style={{ height: `${this.getClientHeight()}px` }}
        >
          <Spin size="large" />
        </Dimmer>
        {open && <Modal />}
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {this.renderForm()}
          {AddEditStore.openModalAction && <ActionRequiredModal />}
        </div>
        <div id="client-add-edit-step" className="step-container step-container-sticky">
          {!api.isSiteOnlyInvoiceCheck() ? STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              disabled={(idx === 2 || idx === 3 || idx === 4) && AddEditStore.clientInfo.Id === 0}
            >
              {idx + 1}. {step}
            </Button>
          )) : STEPS2.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
              disabled={(idx === 2 || idx === 3 || idx === 4) && AddEditStore.clientInfo.Id === 0}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && !api.isSiteOnlyInvoiceCheck() && <Button primary onClick={this.handleSaveData}>
            Save
          </Button>}
          <Button className="negative" onClick={this.handleClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditClient;
