import { action, observable } from 'mobx';
import moment from 'moment';

import * as api from '../../stores/api';
import customFetch from '../../utils/customFetch';

const localeFormat = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

class StatisticsStore {
  constructor() {
    if (!api.isCaseManager()) {
      // window.$.connection.hub.start({ pingInterval: null });
      // window.$.connection.statisticHub.client.refresh = this.onStatisticsRefresh;
    }
  }

  @observable isShowChart = false;
  @observable loadingChart = true;
  @observable dataChart = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [],
    },
  };
  @observable seriesChart = [];
  @observable stateDataChart = '';
  @observable numberItemPerPageChart = 8;
  @observable curPageChart = 4;
  @observable totalPageChart = 0;
  @observable totalItemChart = 0;

  @observable dataTarget = null;

  @observable loading = true;
  @observable statistics = {};
  @observable bookingCount = 0;
  @observable todayCount = 0;
  @observable tomorrowCount = 0;
  @observable UploadCases = {};

  @observable cmInfo = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action resetChart = () => {
    this.loadingChart = true;
    this.dataChart = null;
    this.stateDataChart = '';
    this.curPageChart = 1;
    this.totalPageChart = 0;
    this.totalItemChart = 0;
  };

  @action fetchDataChart = () => {
    this.loadingChart = true;
    return customFetch(
      `/Report/GetStatisticsSessionByState?state=${this.stateDataChart}&curPage=${this.curPageChart}&numItemOfPage=${this.numberItemPerPageChart}`,
      {
        method: 'GET',
      },
    )
      .then(
        action(data => {
          const dataChart_ = this.dataChart;
          if (data.data.length === 0) {
            this.dataChart = {
              ...dataChart_,
              xaxis: {
                categories: [],
                title: {
                  text: 'Specialists',
                },
              },
              yaxis: {
                title: {
                  text: 'Total Sessions',
                },
              },
            };
            this.seriesChart = [
              {
                name: 'Total Sessions',
                data: [],
              },
            ];
          } else {
            this.dataChart = {
              ...dataChart_,
              xaxis: {
                categories: data.data.map(i => ({
                  specialist: i.FullName,
                  createdBy: i.CreatedBy,
                })),
                title: {
                  text: 'Specialists',
                },
                labels: {
                  formatter: function(val) {
                    return `${val.specialist} - By ${val.createdBy}`;
                  },
                },
              },
              yaxis: {
                title: {
                  text: 'Total Sessions',
                },
              },
            };
            this.seriesChart = [
              {
                name: 'Total Sessions',
                data: data.data.map(i => i.TotalSession),
              },
            ];
          }
          this.totalItemChart = data.sumItem;
          this.totalPageChart = data.sumPage;
          this.loadingChart = false;
        }),
      )
      .catch(() => {});
  };

  @action refetchDataChart = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchDataChart();
  };

  getDataTarget = () => {
    return customFetch('/SaleTarget/GetAll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Keyword: '',
        CurPage: 1,
        NumItemPerPage: 100,
        SortBy: 'Id',
        SortDirection: 'ASC',
      }),
    });
  };

  toISODate = date => {
    return (
      date
        .toLocaleString('ja', localeFormat)
        .split('/')
        .join('-') + 'T17:00:00.000Z'
    );
  };

  toGTMDate = date => {
    const localeStr = new Date(date).toLocaleString('en', {
      ...localeFormat,
      weekday: 'short',
      month: 'short',
    });

    const [weekday, month, day, year] = localeStr.split(' ');
    return [weekday, day.replace(',', ''), month, year].join(' ') + ' 17:00:00 GMT';
  };

  getStatistics = () => {
    return customFetch('/Report/GetStatisticsData', {
      method: 'POST',
    });
  };

  getBookingCount = () => {
    return customFetch('/Report/MyBookingCount', {
      method: 'POST',
    });
  };

  onStatisticsRefresh = data => {
    this.getBookingCount().then(
      action(res => {
        this.statistics = data;
        this.bookingCount = res.data;
      }),
    );
  };

  getAdminAssessmentCount = date => {
    const iSODate = this.toISODate(date);
    return customFetch('/MedicalService/Assessment_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        IMEType: 'All',
        NextPicId: null,
        From: null,
        FilterOverdue: 'All',
        FilterType: 'All',
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
        Keyword: '',
        CurPage: 1,
        NumItemPerPage: 1,
        SortBy: 'StatusDate',
        SortDirection: 'DESC',
        SearchClaimantOnly: true,
        To: null,
        FromAppointmentDate: iSODate,
        ToAppointmentDate: iSODate,
        CountOnly: true,
      }),
    }).then(res => res.sumItem);
  };

  getCmAssessmentCount = date => {
    const { CaseManagerId, ClientId } = api.currentUser.data;
    const iSODate = this.toISODate(date);

    return customFetch('/CMReports/GetCMReports', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        CaseMngId: CaseManagerId,
        ClientId: ClientId,
        From: iSODate,
        To: iSODate,
      }),
    }).then(res => res.sumItem);
  };

  getDrAssessmentCount = type => {
    // type: 1 => GetToday
    // type: 2 => GetTomorrow

    let date =
      type === 2
        ? moment()
            .add(1, `day`)
            .startOf(`day`)
            .format('YYYY-MM-DD')
        : moment()
            .startOf(`day`)
            .format('YYYY-MM-DD');
    const countOnly = true;
    return customFetch(
      `/api/OnlinedoctorReportSearch?keyword=&currentPage=1&pageSize=20&sortBy=bookingDate&sortDirection=DESC&doctorId=${api.currentUser.data.DoctorId}&From=${date}&To=${date}&CountOnly=${countOnly}`,
    ).then(res => res.totalRow);
  };

  @action fetchAdminData = () => {
    const tomorrow = new Date();
    const today = new Date();
    today.setDate(today.getDate() - 1);

    Promise.all([
      this.getStatistics(),
      this.getBookingCount(),
      this.getAdminAssessmentCount(today),
      this.getAdminAssessmentCount(tomorrow),
      this.getDataTarget(),
    ]).then(
      action(([statistics, bookingCount, todayCount, tomorrowCount, dataTarget]) => {
        this.bookingCount = bookingCount.data;
        this.statistics = statistics.data;
        this.todayCount = todayCount;
        this.tomorrowCount = tomorrowCount;
        this.dataTarget = dataTarget;
        this.loading = false;
      }),
    );
  };

  @action fetchCmData = () => {
    const tomorrow = new Date();
    const today = new Date();
    today.setDate(today.getDate() - 1);

    Promise.all([this.getCmAssessmentCount(today), this.getCmAssessmentCount(tomorrow), this.getDataTarget()]).then(
      action(([todayCount, tomorrowCount, dataTarget]) => {
        this.todayCount = todayCount;
        this.tomorrowCount = tomorrowCount;
        this.dataTarget = dataTarget;
        this.loading = false;
      }),
    );
  };

  @action fetchDrData = () => {
    // const today = new Date();
    Promise.all([this.getDrAssessmentCount(1), this.getDrAssessmentCount(2), this.getDataTarget()]).then(
      action(([todayCount, tomorrowCount, dataTarget]) => {
        this.todayCount = todayCount;
        this.tomorrowCount = tomorrowCount;
        this.dataTarget = dataTarget;
        this.loading = false;
      }),
    );
  };

  @action fetchCountUploadCase = () => {
    return customFetch('/Default/CountUploadCase', {
      method: 'POST',
    }).then(
      action(data => {
        this.UploadCases = data;
      }),
    );
  };
}

export default new StatisticsStore();
