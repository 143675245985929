import React from 'react';
import { observer } from 'mobx-react';
import FullCalendar from '@fullcalendar/react';
import { action } from 'mobx';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';
import { Button, Icon, Label, Dropdown } from 'semantic-ui-react';
import { DatePicker, Popover, Spin } from 'antd';
import TableToolbar from '../../shared/tableToolbar';
import * as api from '../../../stores/api';
import DailyBookingTable from './DailyBookingTable';
import store from './store';
import { getDoctorInfo } from './service';
import customFetch from '@utils/customFetch';
import { timezoneList } from './type';
import { debounce } from 'lodash-es';
import { SERVER_TIME_ZONE } from '@utils/constants';

const calendarItemTypeSession = 0;
const sessionAcceptTypeInPersonAssessment = 0;
const sessionAcceptTypeVideoAssessment = 1;
const sessionAcceptTypeBoth = 2;
const calendarItemTypeAppointment = 1;

/**
 *
 * @param {moment} momentDate
 * @param {string} fromTimeZone. Date created from this TimeZone
 * @param {string} toTimeZone View date at this TimeZone
 * @returns
 */
function getRelativeDate(momentDate, fromTimeZone, toTimeZone) {
  if (fromTimeZone === toTimeZone) {
    return momentDate.tz(toTimeZone);
  }
  let fromTimeZoneDefaultOffset = moment.tz(fromTimeZone).utcOffset();
  if (momentDate.tz(fromTimeZone).isDST()) {
    fromTimeZoneDefaultOffset = momentDate.tz(fromTimeZone).utcOffset();
  }

  const fromTimeZoneOffset = momentDate.tz(fromTimeZone).utcOffset();
  const toTimeZoneOffset = momentDate.tz(toTimeZone).utcOffset();

  const shiftOffset = toTimeZoneOffset - fromTimeZoneOffset;
  return momentDate.utcOffset(fromTimeZoneDefaultOffset + shiftOffset);
}

const EventContent = ({ eventInfo }) => {
  const [doctor, setDoctor] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { event, timeText } = eventInfo;
  const { title, extendedProps, id, textColor } = event;
  const { isShowVideoIcon, isShowCheckIcon, isShowUserIcon, appointmentAddress } = extendedProps;

  let sessionTime = timeText;

  const sessionTimeZone = extendedProps.timeZone || store.timeZone;
  if (sessionTimeZone) {
    // Show datetime relative to timeonze in session. Aware of timezone
    const relativeDate = getRelativeDate(moment(event.start), sessionTimeZone, store.timeZone);

    sessionTime = relativeDate.format('hh:mm A');
  }

  const fetchDoctorInfo = debounce(props => {
    const { doctorId } = props;

    if (doctorId) {
      setLoading(true);
      setDoctor(null);

      const arrayPropmise = [];

      arrayPropmise.push(getDoctorInfo(doctorId));

      arrayPropmise.push(
        customFetch('/Calendar/GetReservedForClientsAsync', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ calendarId: id }),
        }),
      );

      Promise.all(arrayPropmise)
        .then(res => {
          setLoading(false);
          setDoctor({
            ...res[0],
            reservedForClients: res[1],
          });
        })
        .catch(err => {
          setDoctor(null);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setDoctor(null);
    }
  }, 1000);

  return (
    <Popover
      placement="right"
      trigger="hover"
      content={
        <div style={{ maxWidth: '32rem' }}>
          {loading && <Spin />}
          {!loading && doctor && (
            <>
              <h3>{`${doctor.title ? doctor.title : ''} ${doctor.firstName ? doctor.firstName : ''} ${
                doctor.lastName ? doctor.lastName : ''
              }`}</h3>
              {doctor.specialties && !!doctor.specialties.length && (
                <div>
                  Specialty:{' '}
                  {doctor.specialties.map((i, idx) => (
                    <Label key={idx}>{i.label}</Label>
                  ))}
                </div>
              )}
              {doctor.subSpecialties && !!doctor.subSpecialties.length && (
                <div>
                  Sub-Specialty:{' '}
                  {doctor.subSpecialties.map((i, idx) => (
                    <Label key={idx}>{i.label}</Label>
                  ))}
                </div>
              )}
              {doctor.reservedForClients && !!doctor.reservedForClients.length && (
                <div>
                  <h4 style={{ marginTop: '0.8em', fontWeight: 600 }}>This session reserved for:</h4>
                  {doctor.reservedForClients.map((i, idx) => (
                    <Label key={idx}>{i.Name}</Label>
                  ))}
                </div>
              )}{' '}
              <h4 style={{ marginTop: '0.8em', fontWeight: 600 }}>Appointment Address</h4>
              <p style={{ whiteSpace: 'pre-line' }}>{appointmentAddress}</p>
              <h4 style={{ marginTop: '0.8em', fontWeight: 600 }}>Brief Profile</h4>
              <p style={{ whiteSpace: 'pre-line' }}>{doctor.profileAndPreferences}</p>
              <h4 style={{ marginTop: '0.8em', fontWeight: 600 }}>Consulting Locations</h4>
              <p style={{ whiteSpace: 'pre-line' }}>{doctor.consultingLocations}</p>
              {doctor.commissionOnTop && (
                <div>
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <div>
                      <h4 style={{ marginTop: ' 0.8em', fontWeight: 600 }}>Commission Information</h4>
                      <p>Commission On Top - COT(%): {doctor.commissionOnTop}</p>
                    </div>
                  )}
                  {!api.isAdminOrMagStaffOrAccounting() && (
                    <div
                      style={{
                        color: '#31708f',
                        fontSize: '0.95em',
                        fontStyle: 'italic',
                      }}
                    >
                      Non standard fees applied
                    </div>
                  )}
                </div>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <>
                  <h4 style={{ marginTop: '0.8em', fontWeight: 600 }}>Confidential Insights</h4>
                  <p style={{ whiteSpace: 'pre-line' }}>{doctor.preferences}</p>
                </>
              )}
              {api.isAdminOrMagStaffOrAccounting() && (
                <div>
                  <h4
                    style={{
                      marginTop: '0.8em',
                      fontWeight: '600',
                      color: 'red',
                    }}
                  >
                    Bookings and Cancellation Terms
                  </h4>
                  <p style={{ whiteSpace: 'pre-line', color: 'red' }}>{doctor.doctorBookingsAndCancellationTerms}</p>
                </div>
              )}
            </>
          )}
        </div>
      }
    >
      <span
        onMouseEnter={() => fetchDoctorInfo(extendedProps)}
        onMouseLeave={() => fetchDoctorInfo.cancel()}
        className="popup-specialist-info"
        style={{
          backgroundColor: event.backgroundColor,
          fontWeight: 'bold',
          // color: 'white',
        }}
      >
        <span className="popup-specialist-info-title" style={{ color: textColor }}>
          {sessionTime}
          <React.Fragment>
            {isShowVideoIcon && <Icon name="video camera" style={{ margin: '0 5px' }} />}
            {isShowUserIcon && <Icon name="doctor" style={{ margin: '0 5px' }} />}{' '}
            {isShowCheckIcon && <Icon name="check" style={{ margin: '0 5px' }} />}
            {title}
          </React.Fragment>
        </span>
      </span>
    </Popover>
  );
};

@observer
class Calendar extends React.Component {
  calendarRef = React.createRef();

  state = {
    loading: false,
    monthDate:
      moment()
        .startOf('month')
        .format('DD/MM/YYYY') +
      ' - ' +
      moment()
        .endOf('month')
        .format('DD/MM/YYYY'),
    currentDate: null,
  };

  componentDidMount() {
    const dsdSelected = localStorage.getItem('Data:DoctorSessionDateSelected');
    if (dsdSelected) {
      const data = JSON.parse(dsdSelected);
      this.calendarRef.current.getApi().changeView('timeGridDay', moment(data.selectedDate).toISOString());
      this.setState({
        monthDate: moment(data.selectedDate).format('DD/MM/YYYY'),
      });
    }
  }

  componentDidUpdate() {
    const { start, isReloadCalendar, isResetStore, calendarViewMode } = store;
    const { currentViewType } = this.calendarRef.current.getApi().currentDataManager.data;
    const dsdSelected = localStorage.getItem('Data:DoctorSessionDateSelected');

    if (isResetStore) {
      store.setFieldsValue({ isResetStore: false, isReloadCalendar: false });
      if (calendarViewMode === 'dailyBookingByRooms') {
        return this.setState({ monthDate: store.start });
      }
      this.setState({
        monthDate:
          moment()
            .startOf('month')
            .format('DD/MM/YYYY') +
          ' - ' +
          moment()
            .endOf('month')
            .format('DD/MM/YYYY'),
      });
      return this.calendarRef.current.getApi().changeView('dayGridMonth', moment().toISOString());
    } else if (store.calendarViewMode !== 'dayGridMonth' && isReloadCalendar) {
      store.setFieldsValue({ isReloadCalendar: false, isResetStore: false });
      const calendarCurrDate = moment(this.calendarRef.current.getApi().getDate()).format('DD/MM/YYYY');
      if (store.calendarViewMode === 'timeGridDay') {
        !!store.clinicId || store.dayWeekViewType === 'all'
          ? this.changeDayOrViewCalendar(calendarCurrDate, 'timeGridDay')
          : this.changeDayOrViewCalendar(calendarCurrDate, 'resourceTimeGridDay');
      }
      if (store.calendarViewMode === 'timeGridWeek') {
        !!store.clinicId || store.dayWeekViewType === 'all'
          ? this.changeDayOrViewCalendar(calendarCurrDate, 'timeGridWeek')
          : this.changeDayOrViewCalendar(calendarCurrDate, 'resourceTimeGridWeek');
      }
    } else if (start && isReloadCalendar) {
      store.setFieldsValue({ isReloadCalendar: false, isResetStore: false });
      if (calendarViewMode === 'dailyBookingByRooms') {
        return this.setState({ monthDate: store.start });
      }
      const calendarCurrDate = moment(this.calendarRef.current.getApi().getDate()).format('DD/MM/YYYY');
      return this.calendarRef.current
        .getApi()
        .changeView(currentViewType, moment(calendarCurrDate, 'DD/MM/YYYY').toISOString());
    } else if (dsdSelected) {
      const data = JSON.parse(dsdSelected);
      this.calendarRef.current.getApi().changeView('timeGridDay', moment(data.selectedDate).toISOString());
      this.setState({
        monthDate: moment(data.selectedDate).format('DD/MM/YYYY'),
      });
      return localStorage.removeItem('Data:DoctorSessionDateSelected');
    }
  }

  createDate = date => {
    const datearr = date.split('/');
    const d = new Date(datearr[2], Number(datearr[1]) - 1, datearr[0]);
    return d;
  };

  handleEventClick = eventClickInfo => {
    const { event } = eventClickInfo;
    const { id } = event;
    return store.toggleModal(true, { modalType: 'editSession', id })();
  };

  renderSessionColor = item => {
    var title = '';
    var isShowVideoIcon = false;
    var isShowCheckIcon = false;
    var isShowUserIcon = false;
    var textColor = '#000000';
    if (api.isDoctor() && item.PatientName) {
      title = item.PatientName + (!item.IsTeleAssessment ? ' - ' + item.ClinicName : '');
    } else {
      title = item.DoctorTitle + ' ' + item.DoctorName + (!item.IsTeleAssessment ? ' - ' + item.ClinicName : '');
      if (api.isAdminOrMagStaffOrAccounting() && item.PatientName) title += ' - ' + item.PatientName;
    }
    let backgroundColor = '#009B72';
    let borderColor = '#009B72';
    if (item.ItemType === calendarItemTypeSession) {
      if (item.IsReserved && api.isAdminOrMagStaffOrAccounting()) {
        backgroundColor = '#1FC0DA';
        borderColor = '#1FC0DA';
      } else if (item.Confirmed) {
        if (api.isCaseManager()) {
          title += ' (Available)';
        }

        backgroundColor = '#009B72';
        if (item.SessionAcceptsType === sessionAcceptTypeInPersonAssessment) {
          isShowUserIcon = true;
          borderColor = '#009B72';
        } else if (item.SessionAcceptsType === sessionAcceptTypeVideoAssessment) {
          isShowVideoIcon = true;
          textColor = '#ffffff';
          backgroundColor = '#514163';
          borderColor = '#514163';
        } else if (item.SessionAcceptsType === sessionAcceptTypeBoth) {
          isShowVideoIcon = true;
          isShowUserIcon = true;
          backgroundColor = '#febf40';
          borderColor = '#febf40';
        }
      } else {
        if (api.isCaseManager()) {
          title += ' (Available - subject to MAG confirmation)';
        }

        backgroundColor = '#99d7c6';
        if (item.SessionAcceptsType === sessionAcceptTypeInPersonAssessment) {
          isShowUserIcon = true;
          borderColor = '#99d7c6';
        } else if (item.SessionAcceptsType === sessionAcceptTypeVideoAssessment) {
          isShowVideoIcon = true;
          textColor = '#ffffff';
          backgroundColor = '#afa8b8';
          borderColor = '#afa8b8';
        } else if (item.SessionAcceptsType === sessionAcceptTypeBoth) {
          isShowVideoIcon = true;
          isShowUserIcon = true;
          backgroundColor = '#fedc96';
          borderColor = '#fedc96';
        }
      }
    } else if (item.ItemType === calendarItemTypeAppointment) {
      if (api.isCaseManager()) {
        title += ' (Booked)';
      }

      if (item.Confirmed) {
        backgroundColor = '#a62639';
        textColor = '#ffffff';

        if (item.IsTeleAssessment) {
          if (item.EassessClaimantLocationChecklistReceived) {
            title = item.AssessmentNumber + ' ' + title;
            isShowCheckIcon = true;
          } else {
            title = item.AssessmentNumber + ' ' + title;
          }
          isShowVideoIcon = true;
          backgroundColor = '#a62639';
          borderColor = '#a62639';
        } else {
          isShowUserIcon = true;
          title = item.AssessmentNumber + ' ' + title;
          borderColor = '#a62639';
        }
      } else {
        backgroundColor = '#a62639';
        textColor = '#ffffff';

        if (item.IsTeleAssessment) {
          backgroundColor = '#FF7373';
          borderColor = '#FF7373';
        } else {
          backgroundColor = '#FF7373';
          borderColor = '#FF7373';
        }
      }
    }

    if (item.IsMagDirectSession && item.ItemType === calendarItemTypeSession) {
      backgroundColor = '#588A93';
      borderColor = '#588A93';
    }
    if (api.isDoctor() && api.currentUser.data.DoctorId !== item.DoctorId) {
      title = '';
    }
    return {
      backgroundColor,
      title,
      borderColor,
      isShowVideoIcon,
      isShowUserIcon,
      isShowCheckIcon,
      textColor,
    };
  };
  changeDateMoment = (date, type, action, value) => {
    if (typeof date === 'string') {
      date = store.getMomentDate(date);
    }
    if (action === 'prev') {
      return moment(date).subtract(value, type);
    }
    if (action === 'next') {
      return moment(date).add(value, type);
    }
  };
  getDateTimestamp = date => {
    return +date.match(/\d+/)[0];
  };
  formatDate = datesString => {
    if (!datesString) return '';

    if (datesString.startsWith('/Date(-')) {
      datesString = -this.getDateTimestamp(datesString);
    } else if (datesString.startsWith('/Date(')) {
      datesString = this.getDateTimestamp(datesString);
    }
    return datesString ? moment(datesString).toISOString() : '';
  };

  renderDayHeaderContent = args => {
    const { calendarViewMode } = store;
    if (calendarViewMode !== 'dayGridMonth')
      return moment(args.date)
        .tz(store.timeZone)
        .format('ddd DD/MM');
    return moment(args.date)
      .tz(store.timeZone)
      .format('dddd');
  };

  changeDayOrViewCalendar = (dateString, view) => {
    if (view === 'resourceTimeGridDay' || view === 'timeGridDay') {
      store.setFieldsValue({
        currentDate: moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      });
      this.setState({ monthDate: moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY') });
    } else {
      store.setFieldsValue({
        currentDate: null,
      });
    }
    store.setFieldsValue({
      calendarViewMode: view,
    });
    return this.calendarRef.current.getApi().changeView(view, moment(dateString, 'DD/MM/YYYY').toISOString());
  };

  updateTimeRange = (date, viewMode, notResearch = false) => {
    store.updateTimeRange(date, viewMode);
    this.changeDayOrViewCalendar(date, viewMode);
    if (!notResearch) {
      store.handleSearch();
    }
  };

  handleChangeView = (viewName, date, notResearch = false) => e => {
    e.preventDefault();
    this.setState({ currentDate: null });
    if (viewName === 'timeGridDay') {
      if (!!store.selectedDoctor?.Id && !store.selectedDoctors.length && store.dayWeekViewType === 'column') {
        store.setFieldsValue({ selectedDoctors: [store.selectedDoctor] });
      }
      if (store.dayWeekViewType === 'all') {
        this.updateTimeRange(date || store.getMomentDate(moment()), viewName);
      } else {
        this.updateTimeRange(date || store.getMomentDate(moment()), 'resourceTimeGridDay');
      }
    }
    if (viewName === 'timeGridWeek') {
      if (!!store.selectedDoctor?.Id && !store.selectedDoctors.length && store.dayWeekViewType === 'column') {
        store.setFieldsValue({ selectedDoctors: [store.selectedDoctor] });
      }
      if (store.dayWeekViewType === 'all') {
        this.updateTimeRange(date || store.getMomentDate(moment()), viewName);
      } else {
        this.updateTimeRange(date || store.getMomentDate(moment()), 'resourceTimeGridWeek');
      }
    }
    if (viewName === 'dayGridMonth') {
      if (store.dayWeekViewType === 'column') {
        if (store.selectedDoctors.length === 1) {
          store.setFieldsValue({ doctorId: store.selectedDoctors[0]?.Id });
        } else {
          store.setFieldsValue({ doctorId: null });
        }
        store.setFieldsValue({ selectedDoctors: [] });
      }
      this.updateTimeRange(date || store.getMomentDate(moment()), viewName);
    }
    return store.handleSearch();
  };

  handleNextOrPrev = mode => e => {
    e.preventDefault();
    const { calendarViewMode } = store;

    let nextStartDate;
    if (calendarViewMode === 'timeGridDay' || calendarViewMode === 'resourceTimeGridWeek') {
      nextStartDate = this.changeDateMoment(store.start, 'day', mode, 1);
    }

    if (calendarViewMode === 'timeGridWeek' || calendarViewMode === 'resourceTimeGridWeek') {
      nextStartDate = this.changeDateMoment(store.start, 'week', mode, 1).startOf('isoWeek');
    }

    if (calendarViewMode === 'dayGridMonth') {
      nextStartDate = this.changeDateMoment(store.start, 'month', mode, 1).startOf('month');
    }
    this.updateTimeRange(nextStartDate, calendarViewMode);
  };

  @action handleChange = (_, { value, name }) => {
    if (value) {
      if (name === 'GoToDate') {
        this.calendarRef.current.getApi().changeView('resourceTimeGridDay', moment(value, 'DD/MM/YYYY').toISOString());
        store.setFieldsValue({
          calendarViewMode: 'timeGridDay',
        });
      }
    }
    return store.setFieldsValue({
      [name]: value,
    });
  };

  handleDateClick = dateInfo => {
    const date = dateInfo.dateStr;
    if (!!store.selectedDoctor?.Id && !store.selectedDoctors.length) {
      store.setFieldsValue({ selectedDoctors: [store.selectedDoctor] });
    }
    if (store.dayWeekViewType === 'all') {
      this.calendarRef.current.getApi().changeView('timeGridDay', moment(date, 'YYYY-MM-DD').toISOString());
    } else if (!!store.clinicId) {
      this.calendarRef.current.getApi().changeView('timeGridDay', moment(date, 'YYYY-MM-DD').toISOString());
    } else {
      this.calendarRef.current.getApi().changeView('resourceTimeGridDay', moment(date, 'YYYY-MM-DD').toISOString());
    }
    this.setState({ monthDate: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') });
    store.setFieldsValue({
      calendarViewMode: 'timeGridDay',
      currentDate: moment(date, 'YYYY-MM-DD'),
    });
    const dateTime = moment(dateInfo.dateStr).format('HH:mm');
    if (store.calendarViewMode === 'timeGridDay' && dateTime !== '00:00') {
      store.setFieldsValue({
        calendarViewMode: 'timeGridDay',
        currentDate: moment(dateInfo.dateStr),
        addByClickTimeSlot: true,
      });
      return store.toggleModal(true, { modalType: 'editSession', id: null })();
    }
  };

  handleJumpToSpecificDate = event => {
    store.setFieldsValue({ showWeekend: true });

    setTimeout(() => {
      this.setState({ currentDate: store.getMomentDate(event) });
      if (!!event) {
        this.updateTimeRange(store.getMomentDate(event), 'timeGridDay');
        store.setFieldsValue({ calendarViewMode: 'timeGridDay' });
      } else {
        this.updateTimeRange(store.getMomentDate(event), 'dayGridMonth');
        store.setFieldsValue({ calendarViewMode: 'dayGridMonth' });
      }
    }, 500);
  };

  renderEventContent = eventInfo => {
    if (eventInfo) return <EventContent eventInfo={eventInfo} />;
    return null;
  };

  renderHeightCalendar = () => {
    switch (store.calendarViewMode) {
      case 'dayGridMonth':
        return 600;
      case 'timeGridDay':
      case 'timeGridWeek':
      case 'resourceTimeGridDay':
      case 'resourceTimeGridWeek':
        return 525;
      default:
        return 0;
    }
  };

  selectTimeToAddSession = info => {
    const dateFrom = moment(info.startStr);
    const dateTo = moment(info.endStr);
    if (store.calendarViewMode !== 'dayGridMonth' && Math.abs(dateFrom.diff(dateTo, 'hour')) < 15) {
      store.setFieldsValue({
        currentDate: dateFrom,
        addByClickTimeSlot: true,
        endDateSelected: dateTo,
      });
      return store.toggleModal(true, { modalType: 'editSession', id: null })();
    } else {
      console.log(this.calendarRef.current.getApi().unselect());
    }
  };
  setTodayViewMode = e => {
    e.preventDefault();
    this.updateTimeRange(moment().tz(store.timeZone), 'timeGridDay');
  };

  render() {
    const { sessions, timeZone, loading, calendarDateString } = store;
    const event = (loading ? [] : sessions).map(session => {
      var {
        title,
        backgroundColor,
        isShowVideoIcon,
        isShowCheckIcon,
        isShowUserIcon,
        textColor,
      } = this.renderSessionColor(session);
      return {
        id: session.Id,
        title: title,
        start: this.formatDate(session.DateFrom),
        end: this.formatDate(session.DateTo),
        color: backgroundColor,
        doctorId: session.DoctorId,
        isShowVideoIcon: isShowVideoIcon,
        isShowCheckIcon: isShowCheckIcon,
        isShowUserIcon: isShowUserIcon,
        textColor: textColor,
        resourceId: session.DoctorId,
        appointmentAddress: session.AppointmentAddress,
        timeZone: timeZone || session.TimeZone,
      };
    });

    return (
      <div>
        <TableToolbar.Container>
          <TableToolbar.Left>
            {store.calendarViewMode !== 'dailyBookingByRooms' && (
              <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label
                    style={{
                      marginRight: '5px',
                      fontWeight: 'bold',
                      width: '75px',
                    }}
                  >
                    Time zone:
                  </label>
                  <Dropdown
                    search
                    selection
                    options={timezoneList.map(i => ({ value: i, text: i }))}
                    value={store.timeZone}
                    name="timeZone"
                    className="Toolbar-input"
                    style={{
                      marginRight: '10px',
                      width: '350px',
                    }}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </TableToolbar.Left>
          <div className="calendar-header-nav" style={{ marginTop: '5px', display: 'flex', alignItems: 'center' }}>
            <span className="calendar-header-date">{calendarDateString}</span>
          </div>
          <TableToolbar.Right>
            <Button.Group basic size="small" style={{ float: 'right', marginBottom: '10px', marginLeft: '10px' }}>
              <DatePicker
                format="DD/MM/YYYY"
                size="large"
                onChange={event => this.handleJumpToSpecificDate(event?.toISOString())}
                value={this.state.currentDate}
              />
              <Button
                active={
                  (store.calendarViewMode === 'timeGridDay' || store.calendarViewMode === 'resourceTimeGridDay') &&
                  calendarDateString === moment().format('DD/MM/YYYY')
                }
                onClick={this.setTodayViewMode}
                content="Today"
                disabled={loading}
              />
              <Button
                active={store.calendarViewMode === 'timeGridDay' || store.calendarViewMode === 'resourceTimeGridDay'}
                onClick={this.handleChangeView('timeGridDay', store.start)}
                content="Day"
                disabled={loading}
              />
              <Button
                active={store.calendarViewMode === 'timeGridWeek' || store.calendarViewMode === 'resourceTimeGridWeek'}
                onClick={this.handleChangeView('timeGridWeek', store.start)}
                content="Week"
                disabled={loading}
              />
              <Button
                active={store.calendarViewMode === 'dayGridMonth'}
                onClick={this.handleChangeView('dayGridMonth', store.start)}
                content="Month"
                disabled={loading}
              />
              <Button onClick={this.handleNextOrPrev('prev')} disabled={loading}>
                <Icon name="angle left" />
              </Button>
              <Button onClick={this.handleNextOrPrev('next')} disabled={loading}>
                <Icon name="angle right" />
              </Button>
            </Button.Group>
          </TableToolbar.Right>
        </TableToolbar.Container>

        {store.calendarViewMode === 'dailyBookingByRooms' && <DailyBookingTable />}
        <div
          style={
            store.calendarViewMode !== 'dayGridMonth' && store.selectedDoctors.length > 2
              ? {
                  visibility: `${store.calendarViewMode === 'dailyBookingByRooms' ? 'hidden' : 'visible'}`,
                  width: 3000,
                }
              : {
                  visibility: `${store.calendarViewMode === 'dailyBookingByRooms' ? 'hidden' : 'visible'}`,
                }
          }
          className="calendar"
        >
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, momentTimezonePlugin]}
            dateClick={this.handleDateClick}
            timeZone={timeZone}
            eventContent={this.renderEventContent}
            firstDay={1}
            weekends={
              store.calendarViewMode === 'timeGridDay' || store.calendarViewMode === 'dailyBookingByRooms'
                ? true
                : store.showWeekend
            }
            headerToolbar={false}
            schedulerLicenseKey={'0461836208-fcs-1521557896'}
            selectMirror={true}
            initialView="dayGridMonth"
            contentHeight={1600}
            ref={this.calendarRef}
            events={event}
            height={store.calendarViewMode === 'dailyBookingByRooms' ? 0 : this.renderHeightCalendar()}
            dayMaxEvents={5}
            dayHeaderContent={this.renderDayHeaderContent}
            eventClick={this.handleEventClick}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
            }}
            datesAboveResources={true}
            selectable={store.calendarViewMode !== 'dayGridMonth'}
            select={this.selectTimeToAddSession}
            resources={store.selectedDoctors.filter((_, idx) => idx < 6).map(i => ({ id: i.Id, title: i.FullName }))}
          />
        </div>
      </div>
    );
  }
}

export default Calendar;
