import './configureInput.scss';

import cx from 'classnames';
import { Field } from 'formik';
import omit from 'lodash/omit';
import React from 'react';
import { Label } from 'semantic-ui-react';

import { getErrorMessage } from '../../../utils/errors';

const querySelector = (dom, sel, fn) => {
  const e = dom && dom.querySelector(sel);
  return e && fn(e);
};

const queryInput = (dom, fn) => querySelector(dom, '.ui.input input, textarea', fn);

const configureInput = UnnormalizedInput => {
  class NormalizedInput extends React.Component {
    containerDomNode = null;
    setContainerDomNode = node => {
      this.containerDomNode = node;
    };

    onLabelClick = () => {
      queryInput(this.containerDomNode, i => i.focus());
    };

    renderField = p => {
      const { label, hideLabel, rule, children, msg, ...props } = this.props;
      const hasLabel = !hideLabel && label;
      const r = rule || '';
      const required = /\brequired\b/.test(r);
      const { touched, errors } = p.form;
      const error = touched[props.name] && errors[props.name];
      const onBlur = event => {
        props.onBlur && props.onBlur(event);
        p.field.onBlur(event);
      };

      return (
        <div className={cx('Input', { error, hasLabel })} ref={this.setContainerDomNode}>
          {hasLabel && (
            <label className={cx('Input-Label', { required })} onClick={this.onLabelClick}>
              {label}
            </label>
          )}
          <div className="Input-Field">
            <UnnormalizedInput
              fluid
              {...omit(props, 'defaultValue')}
              {...p.field}
              onBlur={onBlur}
              error={!!error}
              ctx={p.form}
              value={props.value}
              disabled={props.disabled}
              maxLength={props.maxLength}
              type={props.type}
              max={props.max}
            />
          </div>
          {typeof children === 'function' ? children(p) : children}
          {error && (
            <div className="Input-Error">
              <Label className="Input-ErrorPointing" color="red" pointing>
                {error === 'Password' ? msg || getErrorMessage(error) : getErrorMessage(error)}
              </Label>
            </div>
          )}
        </div>
      );
    };

    render() {
      return <Field {...this.props} children={this.renderField} />;
    }
  }
  return NormalizedInput;
};

export { queryInput };
export default configureInput;
