import { observer } from 'mobx-react';
// import { debounce } from 'lodash';
import React from 'react';
import VideoSessionStore from './VideoSessionStore';
import router from '@stores/router';
import VideoModal from './VideoModal';
import { Spin } from 'antd';
import uploadStore from '../../shared/UniversalSearch/UploadFile/UploadStore';
import UniversalSearchModal from '../../shared/UniversalSearch/Modal';
import Table from './Table';

@observer
class VideoSession extends React.Component {
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  componentDidMount() {
    const { assessmentId = 0 } = this.getRouteParams();
    VideoSessionStore.fetchMain(false, assessmentId);
  }

  componentWillUnmount() {
    VideoSessionStore.handleResetStore();
  }

  render() {
    const { loading, data } = VideoSessionStore;

    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            {VideoSessionStore.open && <VideoModal />}
            {uploadStore.open && <UniversalSearchModal />}
            {data.itemList.map((item, index) => (
              <Table data={item} key={index} />
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(VideoSession);
