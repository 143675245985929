import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import DoctorReportsStore from './DoctorReportsStore';
import ModalExport from './ModalExport';
import ModalExportSpecialistStatus from './ModalExportSpecialistStatus';
import ModalVerifyAccessCode from './ModalVerifyAccessCode';
import ModalImport from './ModalImport';
import ModalFilterExport from './ModalFilterExport';

function DoctorReportModal() {
  const { modalType } = DoctorReportsStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
  if (modalType === 'exportDoctor') {
    return <ModalExport {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
  if (modalType === 'exportDoctorStatus') {
    return <ModalExportSpecialistStatus {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
  if (modalType === 'verifyAccessCode') {
    return <ModalVerifyAccessCode {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
  if (modalType === 'import') {
    return <ModalImport {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
  if (modalType === 'exportFilters') {
    return <ModalFilterExport {...DoctorReportsStore} onCancel={DoctorReportsStore.toggleModal(false)} />;
  }
}

export default DoctorReportModal;
