import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, TableBody } from 'semantic-ui-react';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import Store from './store';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: 'Campaign' },
  { title: 'Channel' },
  { title: 'Schedule Option' },
  { title: 'Volume' },
  { title: 'Sent Date' },
];

@observer
class CampaignScheduleHistoryTable extends React.Component {
  openModal = options => {
    return Store.toggleModal(true, options);
  };

  renderTableBody = (data, colSpan) => {
    if (!data || !data.itemList.length) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell>{record.Campaign.CampaignName}</Table.Cell>
        <Table.Cell>{record.ChannelName}</Table.Cell>
        <Table.Cell>
          {record.ScheduledMethod == 4
            ? 'Monthly'
            : record.ScheduledMethod == 3
            ? 'Weekly'
            : record.ScheduledMethod == 2
            ? 'Daily'
            : record.ScheduledMethod == 1
            ? 'One Time'
            : 'None'}
        </Table.Cell>
        <Table.Cell>{record.Volume}</Table.Cell>
        <Table.Cell>
          {record.SendDate ? moment(formatDate(record.SendDate), 'DD MMM YYYY').format('DD/MM/YYYY') : ''}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => this.handleAddEdit(record, id)}>Edit</Menu.Item>
          <Menu.Item
            onClick={() =>
              this.handleToggleModal({
                modalType: 'confirm',
                onOk: () => this.handleDelete(id),
                message: 'Are you sure to delete this campaign?',
              })
            }
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    Store.refetch({ curPage: page });
  };

  render() {
    const { data, loading, sortColumn, sortDirection } = Store;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader columns={columns} sortColumn={sortColumn} sortDirection={sortDirection} />
        <TableBody>{this.renderTableBody(data, columns.length)}</TableBody>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={Store.curPage}
          totalPage={Store.totalPage}
          totalItems={Store.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default CampaignScheduleHistoryTable;
