import { Spin } from 'antd';
import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

import * as api from '../../stores/api';
import customFetch from '../../utils/customFetch';
import { EnhancedLink as Link } from './accordionUtils';

class ActionRequiredSection extends React.Component {
  isEmpty = [];
  defaultActive = Array.from({ length: this.props.accordionItems.length }).map((_, idx) => idx);

  state = {
    active: this.defaultActive,
    loading: true,
    data: null,
  };

  async componentDidMount() {
    const responses = await Promise.all(
      api.isAdminOrMagStaffOrAccounting()
        ? this.getDataForAd()
        : api.isDoctor()
        ? this.getDataForDr()
        : this.getDataForCm(),
    );
    this.setState({
      data: responses.map(res => res?.itemList || (!!res.length ? res : [])),
      loading: false,
    });
  }

  getDataForAd = () => [
    customFetch('/MedicalService/GetStaffsAssignedActionRequired', {
      method: 'POST',
    }),
    customFetch('/FileReview/GetStaffsAssignedActionRequired', {
      method: 'POST',
    }),
    customFetch('/Supplementary/GetStaffsAssignedActionRequired', {
      method: 'POST',
    }),
    customFetch('/ClinicalNote/GetStaffsAssignedActionRequired', {
      method: 'POST',
    }),
  ];

  getDataForCm = () => [
    customFetch('/MedicalService/GetDynamicAssessmentStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        CaseMngId: api.currentUser.data.CaseManagerId,
        ClientId: api.currentUser.data.ClientId,
      }),
    }),
    customFetch('/FileReview/GetDynamicFileReviewStatus', {
      method: 'POST',
    }),
    customFetch('/Supplementary/GetDynamicSupplementaryStatus', {
      method: 'POST',
    }),
    api.isTypist() ? [] : customFetch('/api/CaseStatusWithUsageCount?casetype=6'),
  ];

  getDataForDr = () => {
    const imeStatus = customFetch('/MedicalService/GetDynamicAssessmentStatus', { method: 'POST' });
    const frStatus = customFetch('/FileReview/GetDynamicFileReviewStatus', {
      method: 'POST',
    });
    const suppStatus = customFetch('/Supplementary/GetDynamicSupplementaryStatus', { method: 'POST' });
    return [imeStatus, frStatus, suppStatus];
  };

  handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const { active } = this.state;

    if (active.includes(index)) {
      this.setState({ active: active.filter(i => i !== index) });
    } else {
      this.setState({ active: [...active, index] });
    }
  };

  renderAccordion = () => (
    <Accordion>
      {this.props.accordionItems.map(({ title, cmUrl, adminUrl, drUrl }, idx) => {
        const isCm = api.isCaseManager();
        const isAdmin = api.isAdminOrMagStaffOrAccounting();

        const data = (this.state.data[idx] || []).filter(({ Count, count }) => Count || count);
        this.isEmpty = [...this.isEmpty, !data.length];

        return data ? (
          <React.Fragment key={idx}>
            <Accordion.Title index={idx} active={this.state.active.includes(idx)} onClick={this.handleClick}>
              <div>
                <Icon name="dropdown" />
                {title}
              </div>
              <Link to={isCm ? cmUrl : isAdmin ? adminUrl : drUrl}>View</Link>
            </Accordion.Title>
            {this.renderAccordionContent(data, idx, `${isCm ? cmUrl : isAdmin ? adminUrl : drUrl}`)}
          </React.Fragment>
        ) : (
          this.renderEmptyMessage()
        );
      })}
    </Accordion>
  );

  renderLabelStatus = (status, idx) => {
    switch (status) {
      case 'Dictation Received':
        return 'Dictations Sent to MAG';
      case 'Corrected Report Returned from Provider':
        return 'Corrected Report Sent to MAG';
      default:
        return status;
    }
  };

  renderAccordionContent = (data, idx, pathName) => {
    return (
      <Accordion.Content active={this.state.active.includes(idx)}>
        {data.map(
          ({ FullName, Status, Count, status, count, statusChangeTodayCount, statusChangeYesterdayCount, Id }, idx) => (
            <div key={idx} className="content-item">
              <span
                className="Text-truncate"
                style={{ width: '50%' }}
                title={this.renderLabelStatus(Status || status || FullName, idx)}
              >
                {api.isAdminOrMagStaffOrAccounting() && (
                  <Link style={{ color: '#1fc0da' }} to={`${pathName}?actionRequired=${Id}`}>
                    {this.renderLabelStatus(Status || status || FullName, idx)}
                  </Link>
                )}
                {api.isCaseManager() && (
                  <Link
                    style={{ color: '#1fc0da' }}
                    to={`${pathName}?caseStatus=${this.renderLabelStatus(Status || status || FullName, idx)}`}
                  >
                    {this.renderLabelStatus(Status || status || FullName, idx)}
                  </Link>
                )}
                {api.isDoctor() && (
                  <Link
                    style={{ color: '#1fc0da' }}
                    to={`${pathName}?caseStatus=${this.renderLabelStatus(Status || status || FullName, idx)}`}
                  >
                    {this.renderLabelStatus(Status || status || FullName, idx)}
                  </Link>
                )}
              </span>
              <span>({Count || count})</span>
            </div>
          ),
        )}
      </Accordion.Content>
    );
  };

  renderEmptyMessage = () => {
    const itemLength = this.props.accordionItems.length;
    if (itemLength === this.isEmpty.length && this.isEmpty.every(i => i)) {
      return (
        <div className="section-empty-message" key="empty-message">
          {api.isAdminOrMagStaffOrAccounting() ? 'No actions' : 'No cases'}
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <section className="action-required-section">
        <h3 className="section-title">{api.isAdminOrMagStaffOrAccounting() ? 'Action Required' : 'Case Statuses'}</h3>
        {this.state.loading ? <Spin /> : this.renderAccordion()}
      </section>
    );
  }
}

export default ActionRequiredSection;
