import moment from 'moment';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import MedicalCentresStore from './MedicalCentresStore';
import dashboard from '../../../stores/dashboard';

const columns = [
  { title: '' },
  { title: 'ID' },
  { title: 'Clinic Name', sortKey: 'Name' },
  { title: 'Action Required' },
  { title: 'Location' },
  { title: 'State', sortKey: 'City' },
  { title: 'Centre Type' },
  { title: 'Total Doctors' },
  { title: 'Account Status' },
  { title: 'Date Of Status' },
  { title: 'Created At', sortKey: 'Created' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

@observer
class MedicalCentres extends React.Component {
  openModal = options => {
    return MedicalCentresStore.toggleModal(true, options);
  };

  openViewCaseDetails = (id, record) => event => {
    event.preventDefault();
    localStorage.setItem(`ClinicName`, record.Name);
    dashboard.close(`/view/add-edit-clinic-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-clinic-2?id=${id}&action=edit`);
    });
  };

  openFormEditClinic = (id, record) => () => {
    localStorage.setItem(`ClinicName`, record.Name);
    dashboard.close(`/view/add-edit-clinic-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-clinic-2?id=${id}&action=edit`);
    });
  };

  openEditRoom = (id, record) => () => {
    localStorage.setItem(`ClinicName`, record.Name);
    dashboard.close(`/view/add-edit-clinic-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-clinic-2?id=${id}&action=edit&step=0`);
    });
  };

  openAssignSpecialists = (id, record) => () => {
    localStorage.setItem(`ClinicName`, record.Name);
    dashboard.close(`/view/add-edit-clinic-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-clinic-2?id=${id}&action=edit&step=1`);
    });
  };

  openCaseCalendar = clinicId => () => {
    const doctorSession = {
      clinicId: clinicId || 0,
      doctorId: null,
      selectedDate: moment(),
    };
    localStorage.setItem('Data:DoctorSessionDateSelected', JSON.stringify(doctorSession));
    dashboard.close('/view/calendar-2');

    setTimeout(() => {
      dashboard.open(`/view/calendar-2`);
    });
  };

  handleDelete = id => async () => {
    MedicalCentresStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Clinic/Delete', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      await MedicalCentresStore.refetch({}, true);
    } finally {
      MedicalCentresStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = clinicId => async () => {
    MedicalCentresStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/Clinic/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ clinicId }),
      });
      await MedicalCentresStore.refetch({}, true);
    } finally {
      MedicalCentresStore.setFieldsValue({ loading: false });
    }
  };

  convertEmailCreatedAt = email => {
    const domain = '@medicolegalassessmentsgroup.com.au';
    const email_ = email ? email.search(domain) && email.replace(domain, '') : email;
    return email_;
  };

  handlePageClick = page => {
    MedicalCentresStore.refetch({ curPage: page });
  };

  renderTableBody = () => {
    return MedicalCentresStore.data.itemList.map(({ ID, ...record }) => (
      <Table.Row key={ID} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(ID, record)}</Table.Cell>
        <Table.Cell className={cx(record.IsActive ? 'Cell-nowrap Cell-link' : 'Cell-nowrap')} selectable>
          <Link to="#" onClick={this.openViewCaseDetails(ID, record)}>
            {ID}
          </Link>
        </Table.Cell>
        <Table.Cell className={cx(record.IsActive ? 'Cell-nowrap Cell-link' : 'Cell-nowrap')} selectable>
          <Link to="#" onClick={this.openViewCaseDetails(ID, record)}>
            {record.Name}
          </Link>
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              id: ID,
              modalType: 'notes',
              modalTitle: `${record.Name}`,
            })}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          <br />
          <div>
            {record.ActionRequiredType > 0 && record.ActionOverview
              ? `${record.ActionOverview} - ${this.convertEmailCreatedAt(record.ActionRequiredUserName)}`
              : ''}
          </div>
        </Table.Cell>
        <Table.Cell>{record.FullAddress}</Table.Cell>
        <Table.Cell>{record.City}</Table.Cell>
        <Table.Cell>{record.CentreType}</Table.Cell>
        <Table.Cell>{record.TotalDoctor}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `InActive`}</p>
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.Created ? moment(formatDate(record.Created), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.openFormEditClinic(id, record)}>Edit</Menu.Item>}
          {record.IsActive && <Menu.Item onClick={this.openEditRoom(id, record)}>Add Room</Menu.Item>}
          {record.IsActive && (
            <Menu.Item onClick={this.openAssignSpecialists(id, record)}>Assign Specialists</Menu.Item>
          )}
          <Menu.Item onClick={this.openCaseCalendar(id)}>View this clinic's calendar</Menu.Item>
          {/* <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message: 'Do you want to delete this item?',
              onOk: this.handleDelete(id),
            })}
          >
            Delete
          </Menu.Item> */}
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to enable this item?',
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to disable this item?',
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data } = MedicalCentresStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={MedicalCentresStore.sortColumn}
          sortDirection={MedicalCentresStore.sortDirection}
          onSort={MedicalCentresStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={MedicalCentresStore.curPage}
          totalPage={MedicalCentresStore.totalPage}
          totalItems={MedicalCentresStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default MedicalCentres;
