import { observer } from 'mobx-react';
import React from 'react';

import TableToolbar from '../../../shared/tableToolbar';
import SessionAvailabilityStore from './SessionAvailabilityStore';

const stateLabels = [
  { id: 'All', label: 'All' },
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

@observer
class SessionAvailabilityToolbar extends React.Component {
  renderFilterOptions = () => {
    return stateLabels.map(opt => ({
      text: opt.label,
      value: opt.id,
    }));
  };
  renderFilterText = value => {
    if (value) {
      return stateLabels.filter(i => i.id === value).map(opt => opt.label)[0];
    }
    return 'All';
  };
  handleFilter = (_, { value }) => {
    SessionAvailabilityStore.refetch({
      filter: value === 'All' ? null : value,
    });
  };

  handleChange = (_, { value }) => {
    SessionAvailabilityStore.setFieldsValue({ fromTo: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { fromTo } = SessionAvailabilityStore;
    const { toDate } = SessionAvailabilityStore.getDateRange();
    if (fromTo) {
      if (toDate) {
        SessionAvailabilityStore.refetch({});
      } else {
        SessionAvailabilityStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please select end date',
        })();
      }
    } else {
      SessionAvailabilityStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select date range',
      })();
    }
  };

  handlExport = () => {
    const { filter, fromTo, sortKey, sortDirection } = SessionAvailabilityStore;
    const { fromDate, toDate } = SessionAvailabilityStore.getDateRange();
    if (fromTo) {
      return window.open(
        `/Calendar/ExportSessionAvailability?state=${filter}&dateFrom=${fromDate}&dateTo=${toDate}&sortBy=${sortKey}&sortDirection=${sortDirection
          .replace('ending', '')
          .toUpperCase()}`,
        '_blank',
      );
    } else {
      return SessionAvailabilityStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Please select date range',
      })();
    }
  };

  render() {
    const { loading, filter, fromTo } = SessionAvailabilityStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left>
          <TableToolbar.RangePicker
            value={fromTo}
            onChange={this.handleChange}
            dateFormat="DD/MM/YYYY"
            placeholder="From Date - To Date"
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            value={this.renderFilterText(filter)}
            label="State"
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.SubmitButton
            className="Toolbar-highlight-button px-2"
            onClick={this.handleSubmit}
            disabled={loading}
          >
            Search
          </TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="Toolbar-highlight-button positive px-2"
            onClick={this.handlExport}
            style={{ marginLeft: '5px' }}
            disabled={loading}
          >
            Export
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right></TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default SessionAvailabilityToolbar;
