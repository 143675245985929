import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import OtherSalesCallReportStore from './OtherSalesCallReportStore';

function OtherSalesCallModal() {
  const { modalType } = OtherSalesCallReportStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...OtherSalesCallReportStore} onCancel={OtherSalesCallReportStore.toggleModal(false)} />;
  }
}

export default OtherSalesCallModal;
