import './Radio.scss';

import cx from 'classnames';
import React from 'react';

import TextInput from './TextInput';

class Radio extends React.Component {
  formikCtx = null;
  assignFormikCtx = ctx => {
    this.formikCtx = ctx;
  };

  removeAllCheckedClass = () => {
    const { name } = this.props;
    [...document.querySelectorAll(`div[name=${name}].Radio`)].forEach(node => {
      node.classList.remove('checked');
    });
  };

  handleClick = event => {
    event.persist();
    this.removeAllCheckedClass();
    event.target.parentElement.classList.add('checked');

    const { name, value, onChange } = this.props;
    this.formikCtx.form.setFieldValue(name, value);
    onChange && onChange(event, this.props);
  };

  componentDidMount() {
    const { name, value, checked } = this.props;
    if (checked) {
      this.formikCtx.form.values[name] = value;
    }
  }

  render() {
    const { name, value, label, className, checked, style, disabled } = this.props;
    return (
      <div name={name} style={style} className={cx('Radio', { checked, disabled }, className)}>
        <TextInput
          type="radio"
          name={name}
          value={value}
          className="hidden"
          readOnly
          tabIndex="0"
          children={this.assignFormikCtx}
        />
        <label className="Radio-label" value={value} onClick={disabled ? null : this.handleClick}>
          {label}
        </label>
      </div>
    );
  }
}

export default Radio;
