import React from 'react';
import { Form, Input, Select, DatePicker, Radio, Divider, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react';

class FactualInvestigationDetails extends React.Component {
  render() {
    return (
      <Form name="factual-investigation-details">
        <Form.Item label="Investigation No">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Investigation No">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Booking Client</h3>
        <Form.Item
          label={
            <React.Fragment>
              Booking Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Booking Case Manager / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              MAG AM
              <button className="field-actions">Recent Changes</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Sub-Booking Case Manager / Solicitor">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying Client
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Paying Case Manager / Solicitor
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you originally hear about us?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Where did you hear about MAG for todays booking?">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Claimant Details</h3>
        <Form.Item
          required
          label={
            <React.Fragment>
              Claimant
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Claimant Title">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Claimant Name">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Date of Birth">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Mobile phone">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Reference/Claim No" required>
          <Input size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Claimant Employer</h3>
        <Form.Item label="Business Name">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Trading Name">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Address">
          <Input size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Employer Contact</h3>
        <Form.Item label="Name">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Position">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Work Number">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Mobile Number">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Fax">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Email">
          <Input size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Employer Contact</h3>
        <Form.Item required label="Type of Claim">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Require Interpreter" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Interpreter Language">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item label="Date of Injury" required>
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Type of Injury" required>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Injury Description" required className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Reason For Investigation" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          required
          label={
            <React.Fragment>
              Investigator
              <button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Interview Supervisor</Checkbox>
        </div>
        <Form.Item label="Interview Supervisor Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Interview Supervisor Date">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Interview Manager</Checkbox>
        </div>
        <Form.Item label="Interview Manager Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Interview Manager Date">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Interview Colleague</Checkbox>
        </div>
        <Form.Item label="Interview Colleague Note" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Interview Colleague Date">
          <DatePicker size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Background" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Attachment" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>

        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field">
          <Checkbox>Is this IRO/ILARS</Checkbox>
        </div>
        <Form.Item label="ILARS Ref">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Billing Item Code">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="IRO Principal Lawyer">
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="ILARS Solicitor">
          <Input size="large" />
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Case Due Date</h3>
        <Form.Item label="Expected Completion Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Request Comment" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Request Sent To Service Provider/Investigator" className="fluid-field">
          <Radio.Group>
            <Radio>Yes</Radio>
            <Radio>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Request Sent Date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Request Sent By">
          <Checkbox.Group>
            <Checkbox>Email</Checkbox>
            <Checkbox>Standard Post</Checkbox>
            <Checkbox>Express Post</Checkbox>
            <Checkbox>DX</Checkbox>
            <Checkbox>Courier</Checkbox>
            <Checkbox>Others</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    );
  }
}

export default FactualInvestigationDetails;
