import { Dropdown, Menu, notification } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon, Segment, Table, TableBody } from 'semantic-ui-react';
import { action } from 'mobx';
import customFetch from '../../../../utils/customFetch';
import { TableEmptyMessage, TableHeader } from '../../../shared/table';
import DoctorDetailsStore from './DoctorDetailsStore';
import './DoctorDetails.scss';
import { getConversationLogContent, tripHtmlFromBody } from '@utils/functions';
import { Input } from '@components/shared/tableToolbar/ToolbarComponents';
import * as api from '@stores/api';

const columns = [
  {},
  { title: 'Date Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Correspondence Type' },
  { title: 'Relationship Follow-Up Due' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
];

@observer
class CaseCorrespondenceTab extends React.Component {
  openModal = async (record, actionParam) => {
    if (record?.Id) {
      await getConversationLogContent(record.Id, 'MedicalDoctor').then(action(res => (record.Content = res?.content)));
    }
    return DoctorDetailsStore.toggleModal(true, {
      modalType: 'addEditConversation',
      conversation: record,
      actionParam,
    })();
  };

  handleDelete = id => {
    customFetch('/MedicalDoctor/DeleteConversationLog', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(res => {
      if (res.status === 'success') {
        DoctorDetailsStore.fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while delete data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  handleAllow = id => {
    customFetch('/MedicalDoctor/UpdateDoctorAndCMsView', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, type: 1 }),
    }).then(res => {
      if (res.status === 'success') {
        DoctorDetailsStore.fetchConversations();
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else if (res.status === 'fail') {
        notification.destroy();
        notification.error({
          description:
            'Error occured while update data. Please report this to system administrator and try again later.',
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  renderDropdownMenu = record => (
    <Menu>
      <Menu.Item
        onClick={DoctorDetailsStore.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => this.handleAllow(record.Id),
          message: `Are you sure to ${
            !record.AllowDoctorToView ? 'allow' : 'disallow'
          } Specialist/Providers to view this entry?`,
        })}
      >
        {!record.AllowDoctorToView ? 'Allow Specialist/Providers to view' : 'Disallow Specialist/Providers to view'}
      </Menu.Item>
      {!record.IsAutoEmail && <Menu.Item onClick={() => this.openModal(record)}>Edit</Menu.Item>}
      <Menu.Item
        onClick={DoctorDetailsStore.toggleModal(true, {
          modalType: 'confirm',
          onOk: () => this.handleDelete(record.Id),
          message: 'Are you sure to delete this entry?',
        })}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  convertDateUTC = dateString => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    var utcServerTime = dataConversationLogLoadList.utcServerTime;
    var utcLocalMinutes = new Date().getTimezoneOffset();
    var addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    return moment(dateString)
      .add(addminutes, 'minutes')
      .format('DD/MM/YYYY HH:mm');
  };

  convertDateUTC1 = dateString => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    var utcServerTime = dataConversationLogLoadList.utcServerTime;
    var utcLocalMinutes = new Date().getTimezoneOffset();
    var addminutes = utcServerTime.TotalMinutes + utcLocalMinutes;
    return moment(dateString)
      .add(addminutes, 'minutes')
      .format('DD/MM/YYYY');
  };

  renderTableBody = () => {
    const { dataConversationLogLoadList } = DoctorDetailsStore;
    const colSpan = columns.length;
    if (dataConversationLogLoadList.itemList.length === 0) {
      return <TableEmptyMessage colSpan={colSpan} />;
    }

    return dataConversationLogLoadList.itemList.map((i, idx) => (
      <Table.Row negative={!i.AllowDoctorToView} key={idx}>
        <Table.Cell>
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell>{this.convertDateUTC(i.SentDate)}</Table.Cell>
        <Table.Cell>
          <p className="Form-Field-Text-subject">{i.Subject}</p>
        </Table.Cell>
        <Table.Cell>
          <div className="cell-correspondence-content-body">{tripHtmlFromBody(i.Content)}</div>
          {i.Content && (
            <div className="Cell-link">
              <Link onClick={() => this.openModal(i, 'view')} to="#">
                View more
              </Link>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{i.TypeLog}</Table.Cell>
        <Table.Cell className="cell-correspondence">
          {i.NextDueDate ? this.convertDateUTC(i.NextDueDate) : ''}
        </Table.Cell>
        <Table.Cell>{i.CallerSenderType}</Table.Cell>
        <Table.Cell>{i.CallerSenderName}</Table.Cell>
        <Table.Cell>{i.ReceiverType}</Table.Cell>
        <Table.Cell>{i.ReceiverName}</Table.Cell>
        <Table.Cell>{i.UserName}</Table.Cell>
      </Table.Row>
    ));
  };
  renderTable = () => {
    return (
      <Table sortable striped celled>
        <TableHeader columns={columns} />
        <TableBody>{this.renderTableBody()}</TableBody>
      </Table>
    );
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Case Correspondence</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal" style={{ marginBottom: 0, display: '-webkit-inline-box' }}>
                  {!api.isOnlySearch() &&<Button className="ml-1" color="blue" onClick={() => this.openModal()}>
                    Add New
                  </Button>}
                  <Form
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        DoctorDetailsStore.fetchConversations(event.target.value);
                      }
                    }}
                  >
                    <Input
                      fluid
                      loading={DoctorDetailsStore.loadingConversation}
                      icon="search"
                      placeholder="Search any keyword"
                      iconPosition="left"
                      size={'small'}
                    />
                  </Form>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <div className="Form-Field-table" style={{ marginTop: '1rem' }}>
                      {this.renderTable()}
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default CaseCorrespondenceTab;
