import React from 'react';
import { Form, Input, Divider, Select, Radio } from 'antd';
import { Checkbox } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import AddEditStore from './store';
import ServiceAndIndustryTypes from './ServiceAndIndustryTypes';
import { checkDuplicateCompany } from './service';
import { convertPhoneFaxNumber, formatDate } from '@utils/functions';
import Validator from 'validatorjs';

@observer
class GeneralInformation extends React.Component {
  handleSameAsAddress = () => {
    const { clientInfo } = AddEditStore;
    return action(() => {
      clientInfo.CorrespondenceCityId = clientInfo.CityId;
      clientInfo.CorrespondenceAddress = clientInfo.Address;
      clientInfo.CorrespondenceCountryId = clientInfo.CountryId;
      clientInfo.CorrespondencePostcode = clientInfo.Postcode;
      clientInfo.CorrespondenceDistrict = clientInfo.District;
    })();
  };
  handleCheckDuplicate = record => {
    Promise.all([checkDuplicateCompany(record.Id, record.Name)]).then(([duplicate]) => {
      if(duplicate && duplicate.lstObjs && duplicate.lstObjs.length > 0){
        AddEditStore.setFieldsValue({isDuplicateComp: true});
      }
      return AddEditStore.toggleModal(true, {
        modalType: 'duplicate',
        dataDuplicate: duplicate.lstObjs,
      })();
    });
  };

  validateField = (value, type) => {
    if (type && value) {
      const v = new Validator({ value }, { value: type });
      return v.passes();
    }
    return true;
  };

  render() {
    const data = toJS(AddEditStore.clientInfo);
    const { countryList, cityList, countryCorrespondenceList, cityCorrespondenceList, clientProfilePMS, clientProfileCMS } = AddEditStore;
    return (
      <React.Fragment>
        <Form name="general-information">
          <Form.Item label="Client ID">
            <Input size="large" value={data.Id} disabled />
          </Form.Item>
          <Form.Item
            required
            label={
              <React.Fragment>
                Company
                <button onClick={() => this.handleCheckDuplicate(data)} className="check-duplicate">
                  Check duplicate
                </button>
              </React.Fragment>
            }
            validateStatus={AddEditStore.errors['Name'] ? 'error' : null}
            help={AddEditStore.errors['Name']}
          >
            <Input size="large" value={data.Name} onChange={AddEditStore.handleFieldChange('Name')} />
          </Form.Item>
          <Form.Item
            label="Phone"
            validateStatus={AddEditStore.errors['Telephone'] ? 'error' : null}
            help={AddEditStore.errors['Telephone']}
          >
            <Input
              size="large"
              value={convertPhoneFaxNumber(data.Telephone)}
              onChange={AddEditStore.handleFieldChange('Telephone')}
            />
          </Form.Item>
          <Form.Item
            validateStatus={AddEditStore.errors['Fax'] ? 'error' : null}
            help={AddEditStore.errors['Fax']}
            label="Fax"
          >
            <Input
              size="large"
              value={convertPhoneFaxNumber(data.Fax)}
              onChange={AddEditStore.handleFieldChange('Fax')}
            />
          </Form.Item>
          <Form.Item
            validateStatus={AddEditStore.errors['Email'] ? 'error' : null}
            help={AddEditStore.errors['Email']}
            label="Email"
          >
            <Input size="large" value={data.Email} onChange={AddEditStore.handleFieldChange('Email')} />
          </Form.Item>
          <Form.Item label="DX Mail">
            <Input size="large" value={data.DXEmail} onChange={AddEditStore.handleFieldChange('DXEmail')} />
          </Form.Item>
          <Form.Item
            label={
              <>
                Website
                <button
                  className={'field-actions'}
                  disabled={!this.validateField(data.Website, 'url') || !data.Website}
                  onClick={() => {
                    window.open(data.Website, '_blank');
                  }}
                >
                  Open Link
                </button>
              </>
            }
            validateStatus={!this.validateField(data.Website, 'url') ? 'error' : null}
            help={!this.validateField(data.Website, 'url') ? 'Include http:// or https:// in website' : ''}
          >
            <Input size="large" value={data.Website} onChange={AddEditStore.handleFieldChange('Website')} />
          </Form.Item>
          <Form.Item label="ABN">
            <Input size="large" value={data.ABN} onChange={AddEditStore.handleFieldChange('ABN')} />
          </Form.Item>
          <Form.Item label="Credit Term">
            <Radio.Group onChange={AddEditStore.handleFieldChange('CreditTerm')} value={data.CreditTerm}>
              <Radio key={0} value={0}>
                Standard
              </Radio>
              <Radio key={1} value={1}>
                Prepayment required
              </Radio>
              <Radio key={2} value={2}>
                Disbursement Funding Approved
              </Radio>
            </Radio.Group>
            {data.CreditTermDate && (
              <div>
                <br />
                {moment(formatDate(data.CreditTermDate, true)).format('DD/MM/YYYY HH:MM')} - {data.CreditTermBy}
              </div>
            )}
          </Form.Item>
          <Form.Item label={data.ClientType === 1 || data.ClientType === 6 ? 'CMS' : 'PMS'}>
            <Select
                size="large"
                value={data.ClientProfileId}
                showSearch
                optionFilterProp="children"
                onChange={AddEditStore.handleFieldChange('ClientProfileId')}
              >
                <Select.Option key={' '} value={0}>{' '}</Select.Option>
                {(data.ClientType !== 1 && data.ClientType !== 6) && clientProfilePMS &&
                  clientProfilePMS.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
                {(data.ClientType === 1 || data.ClientType === 6) && clientProfileCMS &&
                  clientProfileCMS.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
          {(data.ClientProfileId === 4310 || data.ClientProfileId === 4295) && <Form.Item></Form.Item>}
          {(data.ClientProfileId === 4310 || data.ClientProfileId === 4295) && <Form.Item label="Please specify">
            <Input size="large" value={data.ClientProfileOther} onChange={AddEditStore.handleFieldChange('ClientProfileOther')}/>
          </Form.Item>}
          <Form.Item label="Comments/Description" className="fluid-field">
            <Input.TextArea
              rows={3}
              value={data.Description}
              onChange={AddEditStore.handleFieldChange('Description')}
              style={{ marginBottom: 0 }}
            />
          </Form.Item>
          <Divider className="fluid-field" />
          <h3 className="form-subtitle fluid-field">Office Address</h3>
          <Form.Item label="Address">
            <Input size="large" value={data.Address} onChange={AddEditStore.handleFieldChange('Address')} />
          </Form.Item>
          <Form.Item label="Suburb">
            <Input size="large" value={data.District} onChange={AddEditStore.handleFieldChange('District')} />
          </Form.Item>
          <Form.Item label="Postcode">
            <Input size="large" value={data.Postcode} onChange={AddEditStore.handleFieldChange('Postcode')} />
          </Form.Item>
          <Form.Item
            validateStatus={AddEditStore.errors['CityId'] ? 'error' : null}
            help={AddEditStore.errors['CityId']}
            label="State"
            required={data.CountryId === 16}
          >
            <Select size="large" allowClear value={data.CityId} onChange={AddEditStore.handleFieldChange('CityId')}>
              {cityList &&
                cityList.itemList.map(i => (
                  <Select.Option key={i.CityId} value={i.CityId}>
                    {i.CityName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country">
            <Select
              size="large"
              value={data.CountryId}
              onChange={AddEditStore.handleFieldChange('CountryId')}
              showSearch
              optionFilterProp="children"
            >
              {countryList &&
                countryList.itemList.map(i => (
                  <Select.Option key={i.CountryId} value={i.CountryId}>
                    {i.CountryName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Divider className="fluid-field" />
          <h3 className="form-subtitle fluid-field">Mailing Address</h3>

          <span
            className="fluid-field same-as-address"
            style={{ marginBottom: '1em' }}
            onClick={this.handleSameAsAddress}
          >
            Same as office address
          </span>
          <Form.Item label="Address">
            <Input
              size="large"
              value={data.CorrespondenceAddress}
              onChange={AddEditStore.handleFieldChange('CorrespondenceAddress')}
            />
          </Form.Item>
          <Form.Item label="Suburb">
            <Input
              size="large"
              value={data.CorrespondenceDistrict}
              onChange={AddEditStore.handleFieldChange('CorrespondenceDistrict')}
            />
          </Form.Item>
          <Form.Item label="Postcode">
            <Input
              size="large"
              value={data.CorrespondencePostcode}
              onChange={AddEditStore.handleFieldChange('CorrespondencePostcode')}
            />
          </Form.Item>
          <Form.Item label="State">
            <Select
              size="large"
              value={data.CorrespondenceCityId}
              onChange={AddEditStore.handleFieldChange('CorrespondenceCityId')}
            >
              {cityCorrespondenceList &&
                cityCorrespondenceList.itemList.map(i => (
                  <Select.Option key={i.CityId} value={i.CityId}>
                    {i.CityName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country" style={{ marginBottom: 0 }}>
            <Select
              size="large"
              value={data.CorrespondenceCountryId}
              showSearch
              onChange={AddEditStore.handleFieldChange('CorrespondenceCountryId')}
            >
              {countryCorrespondenceList &&
                countryCorrespondenceList.itemList.map(i => (
                  <Select.Option key={i.CountryId} value={i.CountryId}>
                    {i.CountryName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
        <div style={{ marginTop: '45px', marginBottom: '45px' }}>
          <Divider className="fluid-field" />
        </div>
        <ServiceAndIndustryTypes />
        <div style={{ marginTop: '45px', marginBottom: '45px' }}>
          <Divider className="fluid-field" />
        </div>
        {/* <h3 className="form-subtitle fluid-field">Report & Accounting Contacts</h3> */}
      </React.Fragment>
    );
  }
}

export default GeneralInformation;
