import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import Modal from '../../shared/Modal';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import Store from './store';
import moment from 'moment';

const columns = [
  { title: 'Campaign' },
  { title: 'Channel' },
  { title: 'Schedule Option' },
  { title: 'Volume' },
  { title: 'Sent Date' },
];

@observer
class CampaignScheduleHistory extends React.Component {
  componentDidMount() {
    const { campaignId } = Store.modalParams;
    Store.refetchScheduleHistory({ campaignHistoryId: campaignId, curPageScheduleHistory: 1 });
  }

  handlePageClick = page => {
    const { campaignId } = Store.modalParams;
    Store.refetchScheduleHistory({ campaignHistoryId: campaignId, curPageScheduleHistory: page });
  };

  renderTableHistory = () =>
    Store.dataScheduleHistory?.itemList.map(({ ...record }, index) => (
      <Table.Row key={record.Id}>
        <Table.Cell>{record.Campaign.CampaignName}</Table.Cell>
        <Table.Cell>{record.ChannelName}</Table.Cell>
        <Table.Cell>
          {record.ScheduledMethod == 4
            ? 'Monthly'
            : record.ScheduledMethod == 3
            ? 'Weekly'
            : record.ScheduledMethod == 2
            ? 'Daily'
            : record.ScheduledMethod == 1
            ? 'One Time'
            : 'None'}
        </Table.Cell>
        <Table.Cell>{record.Volume}</Table.Cell>
        <Table.Cell>
          {record.SendDate ? moment(formatDate(record.SendDate), 'DD MMM YYYY').format('DD/MM/YYYY') : ''}
        </Table.Cell>
      </Table.Row>
    ));

  render() {
    const { dataScheduleHistory, loadingHistory, toggleModal, open } = Store;
    const colSpan = columns.length;

    return (
      <Modal visible={open} width={1400} onCancel={toggleModal(false)} title={` Sent Campaigns`}>
        {loadingHistory && !dataScheduleHistory ? (
          <div className={`Table-loading`}>
            <Spin size="large" />
          </div>
        ) : (
          <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
            <TableHeader columns={columns} />
            <Table.Body>
              {dataScheduleHistory?.itemList?.length ? (
                this.renderTableHistory()
              ) : (
                <TableEmptyMessage colSpan={colSpan} />
              )}
            </Table.Body>
            <TableFooterPagination
              colSpan={colSpan}
              currentPage={Store.curPageScheduleHistory}
              totalPage={Store.totalPageScheduleHistory}
              totalItems={Store.totalItemsScheduleHistory}
              onPageClick={this.handlePageClick}
            />
          </Table>
        )}
      </Modal>
    );
  }
}

export default CampaignScheduleHistory;
