export const ERROR_FIELDNAME = {
  Name: 'Client name',
  CityId: 'State',
  Email: 'General Information - Email address',
  Telephone: 'General Information - Phone format',
  EffectiveMAGUltimateFrom: 'Effective from',
  EffectiveMAGDirectEssentialFrom: 'Effective from',
  EffectiveMAGDirectExtraFrom: 'Effective from',
  Fax: 'Fax',
  OtherClientType: 'Business type',
  ClientType2: 'Client type',
  AccPhoneNumber: 'Contacts & Notifications - Phone format',
  AccEmail: 'Contacts & Notifications - Email address',
  DefaultBookingConfirmationEmail: 'Contacts & Notifications - Default booking confirmation email address',
};

export const ERROR_MESSAGES = {
  required: 'is required',
  invalid: 'is invalid',
  maxdigits: 'must be less than or equal to 10 digits',
  mindigits: 'must be more than or equal to 6 digits',
};
