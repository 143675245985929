import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import VideoSessionStore from './VideoSessionStore';
import router from '@stores/router';
import { JaaSMeeting } from '@jitsi/web-sdk';
import './JaasViewSession.scss';

const jitsiConfig = {
  enableWelcomePage: true,
};

@observer
class JaasViewSession extends React.Component {
  state = {
    room: null,
    jaasToken: null,
  };

  async componentWillMount() {
    const params = new URLSearchParams(router.location.search);
    const sessionToken = params.get('token');
    const resp = await VideoSessionStore.getJaasVideoToken(sessionToken);

    this.setState({ jaasToken: resp.Video_JWT, room: resp.item.Token });
    // this.setState({ room: sessionToken });
  }
  render() {
    if (this.state.jaasToken && this.state.room) {
      return (
        <JaaSMeeting
          appId="vpaas-magic-cookie-217086ead2d34f9f9994702d83e7adea"
          roomName={this.state.room}
          jwt={this.state.jaasToken}
          configOverwrite={{
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            disableThirdPartyRequests: true,
            disableLocalVideoFlip: true,
            backgroundAlpha: 0.5,
          }}
          interfaceConfigOverwrite={{
            VIDEO_LAYOUT_FIT: 'nocrop',
            MOBILE_APP_PROMO: true,
            TILE_VIEW_MAX_COLUMNS: 4,
          }}
          // spinner={SpinnerView}
          onApiReady={externalApi => {
            // const iframe = externalApi.getIFrame();
            externalApi.on('participantLeft', () => {
              window.location.href = window.location.origin + '/view/video-meeting-home?room=' + this.state.room;
            });
          }}
          getIFrameRef={iframeNode => {
            if (navigator.maxTouchPoints > 0) {
              iframeNode.style.width = window.screen.width + 'px';
              iframeNode.style.height = window.innerHeight + 'px';
            } else {
              iframeNode.style.width = '100%';
              iframeNode.style.height = '100vh';
            }
          }}
        />
      );
    }

    return <Spin size="large" />;
  }
}

export default JaasViewSession;
