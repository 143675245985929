import { Spin } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Divider, Header, Table } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import customFetch from '../../../utils/customFetch';

const columns = [
  { title: '' },
  { title: 'RECEIVERS' },
  { title: 'IPEMAIL/SMS' },
  { title: 'SENT DATE/TIME' },
  { title: 'SENT BY' },
];

@observer
class ModalHistory extends React.Component {
  state = {
    data: null,
    loading: true,
  };
  async componentDidMount() {
    const { record } = this.props.modalParams;
    try {
      this.setState({ loading: true });
      const historyPromise = customFetch('/MedicalService/AppointmentReminderHistory', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          id: record.AssessmentId,
          recordType: 2,
        }),
      });

      const [history] = await Promise.all([historyPromise]);
      this.setState({
        data: history.itemList,
      });
    } finally {
      this.setState({ loading: false });
    }
  }
  renderTableHistory = data =>
    data.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>{index + 1}</Table.Cell>
        <Table.Cell className={cx({ 'Cell-nowrap': record.Enabled })}>{record.Receivers}</Table.Cell>
        <Table.Cell>{record.ServiceType}</Table.Cell>
        <Table.Cell>{record.SentDate}</Table.Cell>
        <Table.Cell>{record.StaffSent}</Table.Cell>
      </Table.Row>
    ));
  handlePageClick = page => {
    // LoginUserHistoryStore.refetch({ curPage: page });
  };
  render() {
    const { data, loading } = this.state;
    const { open, onCancel } = this.props;
    const colSpan = columns.length;

    return (
      <Modal
        visible={open}
        width={1200}
        onCancel={onCancel}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={onCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-LoginHistory">
          <Header size="small">{`Reminder History`}</Header>
          <Divider />
          {loading && !data ? (
            <div className={`Table-loading`}>
              <Spin size="large" />
            </div>
          ) : (
            <div className={`Table-container`}>
              <Table sortable striped celled className={cx({ disabled: loading })}>
                <TableHeader columns={columns} />
                <Table.Body>
                  {data.length ? this.renderTableHistory(data) : <TableEmptyMessage colSpan={colSpan} />}
                </Table.Body>
              </Table>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default ModalHistory;
