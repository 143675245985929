import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';

import ModuleModal from './ModuleModal';
import ModuleStore from './ModuleStore';
import ModuleTable from './ModuleTable';
import ModuleToolbar from './ModuleToolbar';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class ModuleTypeTable extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/module-type-template', true);
      return;
    }
    ModuleStore.fetchModule(true);
    ModuleStore.fetchModuleFilter();
  }
  render() {
    const { data, loading, open, dataModule, loadingModule } = ModuleStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingModule && !dataModule) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingModule && !dataModule) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ModuleToolbar />
            <ModuleTable />
            {open && <ModuleModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default observer(ModuleTypeTable);
