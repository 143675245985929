import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Table, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Spin } from 'antd';
import AddEditStore from './Store';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import moment from 'moment';
import dashboardStore from '@stores/dashboard';
import iframe from '../../dashboard-routes/Iframe/iframeData';

const columnsA = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsF = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsS = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsCR = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsMN = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  // { title: 'Doctor Name' },
  { title: 'Case Manager' },
  { title: 'Client' },
  { title: 'Status' },
  { title: 'Status Date' },
];

const columnsLMQ = [
  { title: 'Case No.' },
  { title: 'Claim No' },
  { title: 'Case Manager' },
  { title: 'Booking Client' },
  { title: 'Matter Type' },
  { title: 'LMQ Status' },
  { title: 'LMQ Status Date' },
];

const columnsFD = [
  { title: 'Transaction No.' },
  { title: 'Transaction Date' },
  { title: 'Case No' },
  { title: 'Claim No' },
  { title: 'Lender' },
  { title: 'Transaction Amount' },
  { title: 'Invoice Payment Date' },
  { title: 'Settlement Status' },
  { title: 'Settlement Status Date' },
];

@observer
class TableHistoryModal extends React.Component {
  openCaseDetail = (caseNo, id) => () => {
    const type = caseNo.slice(0, 1);
    const tabDetail =
      type === 'A'
        ? '/view/add-edit-ime-assessment-2'
        : type === 'F'
        ? '/view/add-edit-file-review-2'
        : type === 'S'
        ? '/view/add-edit-supplementary-report-2'
        : type === 'C'
        ? '/view/add-edit-clinical-notes-2'
        : '';
    const storageName =
      type === 'A'
        ? 'AssessmentCaseId'
        : type === 'F'
        ? 'FileReviewCaseId'
        : type === 'S'
        ? 'SupplementaryCaseId'
        : 'ClinicalRecordCaseId';
    if (type !== 'M') {
      localStorage.setItem(`${storageName}`, `Case ${caseNo}`);
      dashboardStore.open(`${tabDetail}?id=${id}`);
    } else {
      localStorage.setItem('MedNegCaseId', `Case ${caseNo}`);
      iframe.forceOpenTab('PostMedNeg', `?id=${id}`, {
        id,
        view: 'PostMedNeg',
      });
    }
  };

  numberDecimalFormatter = value => {
    return !!value ? '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ 0';
  };

  openFDCaseDetail = (caseNo, id) => () => {
    localStorage.setItem('FacilityReport', `${caseNo} - Funded Transaction`);
    dashboardStore.close('/view/add-edit-funding-report');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-report?id=${id}`);
    });
  };

  renderTableBodyA = () => {
    return AddEditStore.dataHistory.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.AssessmentNumber, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.AssessmentNumber, Id)}>
            {record.AssessmentNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.Doctor}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyF = () => {
    return AddEditStore.dataHistory.fileReviews.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.FileReviewNumber, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.FileReviewNumber, Id)}>
            {record.FileReviewNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimantNo}</Table.Cell>
        <Table.Cell>{record.DoctorName}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyS = () => {
    return AddEditStore.dataHistory.supps.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.SuppNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.SuppNo, Id)}>
            {record.SuppNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.DoctorName}</Table.Cell>
        <Table.Cell>{record.CaseManager}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyCR = () => {
    return AddEditStore.dataHistory.clinicalRecords.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.CaseNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.CaseNo, Id)}>
            {record.CaseNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.DoctorName}</Table.Cell>
        <Table.Cell>{record.CaseManagerName}</Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyMN = () => {
    return AddEditStore.dataHistory.mednegs.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.CaseNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.CaseNo, Id)}>
            {record.CaseNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        {/* <Table.Cell>{record.DoctorName}</Table.Cell> */}
        <Table.Cell>{record.CaseManagerName}</Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyLMQ = () => {
    return AddEditStore.dataHistory.lmqs.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openCaseDetail(record.CaseNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openCaseDetail(record.CaseNo, Id)}>
            {record.CaseNo}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        {/* <Table.Cell>{record.DoctorName}</Table.Cell> */}
        <Table.Cell>{record.CaseManagerName}</Table.Cell>
        <Table.Cell>{record.ClientName}</Table.Cell>
        <Table.Cell>{record.MatterType}</Table.Cell>
        <Table.Cell>{record.Status}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderTableBodyFD = () => {
    return AddEditStore.dataHistory.fundingReports.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell selectable onClick={this.openFDCaseDetail(record.TransactionNo, Id)} className="Cell-link">
          <Link to="#" onClick={this.openFDCaseDetail(record.TransactionNo, Id)}>
            {record.TransactionNo}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.TransactionDate
            ? moment(formatDate(record.TransactionDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CaseNo}</Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell>{record.Lender}</Table.Cell>
        <Table.Cell>{this.numberDecimalFormatter(record.TransactionAmount)}</Table.Cell>
        <Table.Cell>
          {record.InvoicePaymentDate
            ? moment(formatDate(record.InvoicePaymentDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.SettlementStatus}</Table.Cell>
        <Table.Cell>
          {record.SettlementStatusDate
            ? moment(formatDate(record.SettlementStatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { loadingHistory, dataHistory, claimantInfo } = AddEditStore;
    const colSpanA = columnsA.length;
    const colSpanF = columnsF.length;
    const colSpanS = columnsS.length;
    const colSpanCR = columnsCR.length;
    const colSpanMN = columnsMN.length;
    const colSpanLMQ = columnsLMQ.length;
    const colSpanFD = columnsFD.length;
    const renderTabpane = [
      {
        menuItem: 'IME Reports',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsA} />
              <Table.Body>
                {dataHistory.itemList.length ? this.renderTableBodyA() : <TableEmptyMessage colSpan={colSpanA} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'File Reviews',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsF} />
              <Table.Body>
                {dataHistory.fileReviews.length ? this.renderTableBodyF() : <TableEmptyMessage colSpan={colSpanF} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Supplementary Reports',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsF} />
              <Table.Body>
                {dataHistory.supps.length ? this.renderTableBodyS() : <TableEmptyMessage colSpan={colSpanS} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Clinical Records Retrievals',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsCR} />
              <Table.Body>
                {dataHistory.clinicalRecords.length ? (
                  this.renderTableBodyCR()
                ) : (
                  <TableEmptyMessage colSpan={colSpanCR} />
                )}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Medical Negligence',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsMN} />
              <Table.Body>
                {dataHistory.mednegs.length ? this.renderTableBodyMN() : <TableEmptyMessage colSpan={colSpanMN} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Facility Disbursement',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsFD} />
              <Table.Body>
                {dataHistory.fundingReports.length ? (
                  this.renderTableBodyFD()
                ) : (
                  <TableEmptyMessage colSpan={colSpanFD} />
                )}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'LMQ',
        render: () => (
          <Tab.Pane attached={false}>
            <Table sortable striped celled className={cx({ disabled: loadingHistory })}>
              <TableHeader columns={columnsLMQ} />
              <Table.Body>
                {dataHistory.lmqs.length ? this.renderTableBodyLMQ() : <TableEmptyMessage colSpan={colSpanLMQ} />}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
    ];
    if (claimantInfo.Id === 0) return null;
    return (
      <React.Fragment>
        {loadingHistory && !dataHistory ? (
          <div className={`Table-loading`}>
            <Spin size="large" />
          </div>
        ) : (
          <Tab menu={{ pointing: true }} panes={renderTabpane} />
        )}
      </React.Fragment>
    );
  }
}

export default TableHistoryModal;
