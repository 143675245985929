import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import BusinessUnitModal from './Modal';
import BusinessUnitStore from './store';
import BusinessUnitTable from './Table';
import BusinessUnitToolbar from './Toolbar';

class BusinessUnit extends React.Component {
  componentDidMount() {
    const { companyId } = this.props;
    BusinessUnitStore.refetch({ companyId });
  }

  render() {
    const { loading, data, open } = BusinessUnitStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <BusinessUnitToolbar />
            <BusinessUnitTable />
            {open && <BusinessUnitModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(BusinessUnit);
