import { action, observable } from 'mobx';
import moment from 'moment';
import {
  getServiceEstimate,
  //searchAMAFee,
  searchAMACaculation,
  getAllTypeOfWork,
  getAllBodyParts,
  getStaffGetAll,
  getAllCompany,
  getCompanyList,
  getSpecialtyList,
  getAllAMAAftercareRule,
  getAllTimeUnit,
  getAllModifiesUnit,
  searchAMAProfessionalAttendance,
  getCheckType,
  GetListCaseManager,
  getStateList,
  searchMBSProfessionalAttendance
  //getEstimateLink
} from './Service';

class ServiceEstimateStore {
  @observable loading = true;
  @observable data = null;

  @observable filter = '';
  @observable sortDirection = 'descending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';
  @observable totalItems = 0;
  @observable fromTo = '';
  @observable caseManagerId = 0;
  @observable exportByClient = 0;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};
  @observable lstServiceItems = [];
  @observable amaSelectionList = null;
  @observable mbsSelectionList = null;  
  @observable amaProfessionalAttendanceList = null;
  @observable mbsProfessionalAttendanceList = null;
  @observable amaCaculationList = null;
  @observable amaCaculationPercentageList = [];
  @observable seachAMAKeyword = '';
  @observable curPageAMA = 1;
  @observable pageSizeAMA = 20;
  @observable totalPageAMA = 0;
  @observable clientId = 0;

  @observable seachAMACaculationKeyword = '';
  @observable curPageAMACaculation = 1;
  @observable pageSizeAMACaculation = 20;
  @observable totalPageAMACaculation = 0;
  @observable totalAdjustedFee = 0;

  @observable lstServiceItemOptions = [];
  @observable lstTypeOfWokOptions = [];
  @observable lstBodyPartsOptions = [];
  @observable isShowOtherBodyPart = false;
  @observable lstClientOptions = [];
  @observable lstCMOptions = [];
  @observable lstSpecialtiesOptions = [];
  @observable lstAftercareItems = [];
  @observable lstAnaesthesiaItems = [];
  @observable lstAftercareRules = [];
  @observable lstTimeUnits = [];
  @observable lstModifyingUnits = [];
  @observable lstProfessionalAttendanceItems = [];
  @observable lstCheckTypes = [];
  @observable lstEstimateLink = [];
  @observable lstAssistanceAtOperationItems = [];
  @observable showMZ900 = false;
  @observable lstMZ900Items = [];
  @observable lstAMASpecific = [];
  @observable lstM4ModifierExclusionCodes = [];
  @observable lstM4Items = [];
  @observable lstTimeItems = [];
  @observable lstMZ900Calculations = [];
  @observable dataCM = [];
  @observable lstState = [];
  @observable amaAnaesthesiaSelectionList = null;

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');
    return {
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
  };

  @action setAmaSelectionList = lst => {
    this.amaSelectionList = lst;
  };

  @action setAnaesthesiaAmaSelectionList = lst => {
    this.amaAnaesthesiaSelectionList = lst;
  };

  @action setMBSSelectionList = lst => {
    this.mbsSelectionList = lst;
  };

  @action setServiceItems = lst => {
    this.lstServiceItems = lst;
  };

  @action setListItem = (name, lst) => {
    if (name === 'showMZ900') {
      this.showMZ900 = lst;
    }
    if (name === 'amaSelectionList') {
      this.amaSelectionList = lst;
    }
    if (name === 'mbsSelectionList') {
      this.mbsSelectionList = lst;
    }
    if (name === 'searchAMAProfessionalAttendanceList') {
      this.amaProfessionalAttendanceList = lst;
    }
    if (name === 'searchMBSProfessionalAttendanceList') {
      this.mbsProfessionalAttendanceList = lst;
    }
    if (name === 'aftercare') {
      this.lstAftercareItems = lst;
    }
    if (name === 'anaesthesia') {
      this.lstAnaesthesiaItems = lst;
    }
    if (name === 'professionalAttendance') {
      this.lstProfessionalAttendanceItems = lst;
    }
    if (name === 'assistanceAtOperation') {
      this.lstAssistanceAtOperationItems = lst;
    }
    if (name === 'mz900') {
      this.lstMZ900Items = lst;
    }
    if (name === 'SearchEstimate') {
      this.lstEstimateLink = lst;
    }
    if (name === 'AMASpecific') {
      this.lstAMASpecific = lst;
    }
    if (name === 'M4') {
      this.lstM4Items = lst;
    }
    if (name === 'M4ModifierExclusionCode') {
      this.lstM4ModifierExclusionCodes = lst;
    }
    if (name === 'Time') {
      this.lstTimeItems = lst;
    }
    if (name === 'lstMZ900Calculations') {
      this.lstMZ900Calculations = lst;
    }
    if(name == 'lstState'){
      this.lstState = lst;
    }
  };

  @action setCaculationPercentages = lst => {
    this.amaCaculationPercentageList = lst;
  };

  @action setServiceItemOptions = lst => {
    this.lstServiceItemOptions = lst;
  };

  @action setClientOptions = lst => {
    this.lstClientOptions = lst;
  };

  @action setShowOtherBodyPart = value => {
    this.isShowOtherBodyPart = value;
  };

  @action setTotalUnitToM4Items = value => {
    if (this.lstM4Items && this.lstM4Items.length > 0) {
      this.lstM4Items[0].BaseUnit = value / 2;
      if (this.lstM4Items[0].BaseUnit)
        this.lstM4Items[0].ScheduledFee = this.lstM4Items[0].BaseUnit * this.lstM4Items[0].UnitRate;
    }
  };

  @action getCompanyInfo = value => {
    getAllCompany(value, 50).then(
      action(resp => {
        this.setClientOptions(resp.itemList || []);
      }),
    );
  };
  // @action setApprovedToM4Items = value => {
  //   if(this.lstM4Items && this.lstM4Items.length > 0){
  //     this.lstM4Items[0].Approved = value;
  //   };
  // };

  @action fetchData = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    let cmId = this.caseManagerId;
    Promise.all([
      getServiceEstimate(
        this.keyword,
        from,
        to,
        cmId,
        this.curPage,
        this.pageSize,
        this.sortKey,
        this.sortDirection.replace('ending', '').toUpperCase(),
      ),
      //searchAMAFee(''),
      searchAMACaculation({
        Keyword: this.seachAMAKeyword,
        CurPage: this.curPageAMA,
        NumItemPerPage: this.pageSizeAMA,
      }),
      getAllTypeOfWork(''),
      getAllBodyParts(''),
      getCompanyList('', 50),
      getSpecialtyList(),
      getAllAMAAftercareRule(),
      getAllTimeUnit(),
      getAllModifiesUnit(),
      searchAMAProfessionalAttendance(''),
      searchMBSProfessionalAttendance(''),
      getCheckType(),
      GetListCaseManager(),
      //getEstimateLink('', 0),
    ])
      .then(
        action(
          ([
            estimateResp,
            //amaResp,
            amaCaculationResp,
            typeOfWorkResp,
            bodyPartsResp,
            clientResp,
            specialtyResp,
            aftercareRuleResp,
            timeUnitResp,
            modifiesUnitResp,
            amaProfessionalAttendanceResp,
            mbsProfessionalAttendanceResp,
            checkTypeResp,
            getCMResp,
            //estimateLinkResp,
          ]) => {
            if (estimateResp.curPage <= 1) {
              this.totalPage = estimateResp.sumPage;
              this.totalItems = estimateResp.sumItem;
            }
            this.data = estimateResp;
            //if (amaResp && amaResp.itemList) this.amaSelectionList = amaResp.itemList;
            if (amaCaculationResp && amaCaculationResp.itemList) {
              var addObj = { Id: -1, CalculationName: 'None', CalculationRules: 'None', AMACalculationRuleRates: [] };
              amaCaculationResp.itemList.unshift(addObj);
              this.amaCaculationList = amaCaculationResp.itemList;
            }
            if (typeOfWorkResp && typeOfWorkResp.itemList) this.lstTypeOfWokOptions = typeOfWorkResp.itemList;
            if (bodyPartsResp && bodyPartsResp.itemList) {
              this.lstBodyPartsOptions = bodyPartsResp.itemList;
              this.lstBodyPartsOptions.push({ Id: 0, Label: 'Other', Description: 'Other' });
            } else {
              this.lstBodyPartsOptions = [{ Id: 0, Label: 'Other', Description: 'Other' }];
            }
            if (clientResp && clientResp.itemList) this.lstClientOptions = clientResp.itemList;
            if (specialtyResp && specialtyResp.itemList) this.lstSpecialtiesOptions = specialtyResp.itemList;
            if (aftercareRuleResp && aftercareRuleResp.itemList) this.lstAftercareRules = aftercareRuleResp.itemList;
            if (timeUnitResp && timeUnitResp.itemList) this.lstTimeUnits = timeUnitResp.itemList;
            if (modifiesUnitResp && modifiesUnitResp.itemList) this.lstModifyingUnits = modifiesUnitResp.itemList;
            if (amaProfessionalAttendanceResp && amaProfessionalAttendanceResp.itemList)
              this.amaProfessionalAttendanceList = amaProfessionalAttendanceResp.itemList;
            if (mbsProfessionalAttendanceResp && mbsProfessionalAttendanceResp.itemList)
              this.mbsProfessionalAttendanceList = mbsProfessionalAttendanceResp.itemList;
            if (checkTypeResp && checkTypeResp.itemList) this.lstCheckTypes = checkTypeResp.itemList;
            // if (estimateLinkResp && estimateLinkResp.itemList){
            //   this.lstEstimateLink = estimateLinkResp.itemList;
            // }
            // else{
            //   this.lstEstimateLink = [];
            // }
            if(getCMResp && getCMResp.status === 'success'){
              this.dataCM = getCMResp.lstCMs;
            }else{
              this.dataCM = [];
            }
            this.loading = false;
          },
        ),
      )
      .catch(() => {});
  };

  @action fetchState = () => {
    getStateList().then(resp => {
      if (resp.status === 'success') {
        this.setListItem('lstState', resp.itemList);
      }
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchData();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchData(setTotalPage);
  };

  @action updateData = (data) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  // toggleModal = (isOpen, params = {}) => {
  //   return action(() => {
  //     this.open = isOpen;
  //     this.modalParams = params;
  //   });
  // };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  generateTimeRange = total => {
    let result = [];
    for (let i = 0; i < total; i++) {
      if (i < 10) {
        result.push({ label: '0' + i, value: i });
      } else result.push({ label: i.toString(), value: i });
    }
    return result;
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'descending';

    this.curPage = 1;
    this.totalPage = 0;
    this.fetchData(true);
  };

  @action exportSearchResult = () => {
    window.open('/ServiceEstimate/ExportSearchResult?keyword=' + this.keyword, '_blank');
  };

  exportInvoiceCheck = () => {
    const { fromDate, toDate } = this.getDateRange();
    const from = fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    const to = toDate ? moment(toDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
    window.open('/ServiceEstimate/ExportDataClient?keyword=' + this.keyword + '&exportOption=' + this.exportByClient + '&caseManagerId=' + this.caseManagerId + '&from=' + from + '&to=' + to, '_blank');
  };

  @action getCMByClientId = cId => {
    getStaffGetAll('', 50, cId).then(
      action(resp => {
        if (resp && resp.itemList) this.lstCMOptions = resp.itemList;
        else this.lstCMOptions = [];
      }),
    );
  };
}

export default new ServiceEstimateStore();
