import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class ClientsStore {
  @observable loading = true;
  @observable loadingSearch = true;
  @observable data = null;
  @observable dataSearch = null;

  @observable actionRequired = false;
  @observable recruitedOrContractedOnlyge = true;
  @observable showing = 'active';
  @observable sortBy = 'Name';
  @observable sortDirection = 'ascending';
  @observable sortColumn = 'Client';
  @observable keyword = '';
  @observable keywordSearch = '';
  @observable filterSubcription = 'All';

  @observable clientId = 0;

  @observable loadingBookingHistory = true;
  @observable dataBookingHistory = null;
  @observable sortDirectionBooking = 'ascending';
  @observable sortColumnBooking = 'Request Date';
  @observable sortKeyBooking = 'RequestDate';
  @observable totalBooking = 0;

  @observable curPage = 1;
  @observable numberSearch = 30;
  @observable pageSize = 20;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @observable curPageBookingHistory = 1;
  @observable pageSizeBookingHistory = 50;
  @observable totalPageBookingHistory = 0;
  @observable totalItemsBookingHistory = 0;

  //Action required
  @observable loadingActionPIC = true;
  @observable dataActionPIC = null;
  @observable ActionRequiredById = '';
  @observable dataStaffs = null;
  @observable BusinessAccountManagerId = '';
  @observable StateId = '';
  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchBookingHistory = clientId => {
    this.loadingBookingHistory = true;
    customFetch(
      `/Company/GetBookingHistory?clientId=${clientId}&sortBy=${
        this.sortKeyBooking
      }&sortDirection=${this.sortDirectionBooking.replace('ending', '').toUpperCase()}&currentPage=${
        this.curPageBookingHistory
      }&numItemPerPage=${this.pageSizeBookingHistory}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataBookingHistory = data;
        this.loadingBookingHistory = false;
        this.totalPageBookingHistory = data.sumPage;
        this.totalItemsBookingHistory = data.sumItem;
        this.totalBooking = data.sumItem;
      }),
    );
  };

  @action fetchSearch = () => {
    this.loadingSearch = true;
    customFetch('/Company/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        ActionRequired: this.actionRequired,
        NumItemPerPage: this.numberSearch,
        RecruitedOrContractedOnlyge: this.recruitedOrContractedOnlyge,
        Showing: this.showing,
        SortBy: this.sortBy,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        curPage: this.curPageSearch,
        keyword: this.keywordSearch,
        ActionRequiredById: this.ActionRequiredById,
        FilterSubcription: this.filterSubcription,
      }),
    }).then(
      action(data => {
        this.dataSearch = data;
        this.loadingSearch = false;
      }),
    );
  };
  @action fetchClients = (setTotalPage = true) => {
    this.loading = true;
    customFetch('/Company/GetAll', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        ActionRequired: this.actionRequired,
        NumItemPerPage: this.pageSize,
        RecruitedOrContractedOnlyge: this.recruitedOrContractedOnlyge,
        Showing: this.showing,
        SortBy: this.sortBy,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        curPage: this.curPage,
        keyword: this.keyword,
        ActionRequiredById: this.ActionRequiredById,
        BusinessAccountManagerId: this.BusinessAccountManagerId,
        FilterSubcription: this.filterSubcription,
        State: this.StateId,
      }),
    }).then(
      action(data => {
        this.fetchSearch();
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
    this.fetchActionPIC();
    this.fetchStaffs();
  };

  @action fetchActionPIC = () => {
    this.loadingActionPIC = true;
    customFetch('/Company/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataActionPIC = data;
        this.loadingActionPIC = false;
      }),
    );
  };

  @action fetchStaffs = () => {
    this.loadingActionPIC = true;
    customFetch('/Staff/GetStaffs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ getAll: true }),
    }).then(
      action(data => {
        this.dataStaffs = data;
        this.loadingActionPIC = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortBy = sortKey;
    this.fetchClients();
  };
  @action handleSortBooking = ({ sortKey }, { column, direction }) => {
    this.sortColumnBooking = column;
    this.sortDirectionBooking = direction;
    this.sortKeyBooking = sortKey;
    this.fetchBookingHistory(this.clientId);
  };

  @action refetch = (data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchClients(setTotalPage);
  };

  @action refetchBookingHistory = (clientId, data = {}, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    customFetch(
      `/Company/GetBookingHistory?clientId=${clientId}&sortBy=${
        this.sortKeyBooking
      }&sortDirection=${this.sortDirectionBooking.replace('ending', '').toUpperCase()}&currentPage=${
        this.curPageBookingHistory
      }&numItemPerPage=${this.pageSizeBookingHistory}`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.dataBookingHistory = data;
        this.loadingBookingHistory = false;
        this.totalPageBookingHistory = data.sumPage;
        this.totalItemsBookingHistory = data.sumItem;
        this.totalBooking = data.sumItem;
      }),
    );
  };

  @action refetchSearch = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchSearch(true);
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.showing = 'active';
    this.sortBy = 'Name';
    this.sortDirection = 'ascending';
    this.keyword = '';
    this.BusinessAccountManagerId = '';
    this.FilterSubcription = 'All';
    this.StateId = 'All';
    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new ClientsStore();
