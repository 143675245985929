import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import * as api from '../../stores/api';
import Form from '../shared/form/Form';

@observer
class Forgot extends React.Component {
  @observable showSuccessMessage = false;

  onValidSubmit = data => {
    api.forgot.post(data, () => {
      this.showSuccessMessage = true;
    });
  };
  @action onRetryClick = () => {
    this.showSuccessMessage = false;
  };

  render() {
    return (
      <React.Fragment>
        {this.showSuccessMessage && (
          <p>
            <span>
              Your password reset email has been sent. Make sure you check Spam/Junk email folder in case the email is
              filtered to there. Contact support@medicolegalassessmentsgroup.com.au if you don’t receive password reset
              email.{' '}
            </span>
            <span className="link" onClick={this.onRetryClick}>
              Retry
            </span>
          </p>
        )}
        {!this.showSuccessMessage && (
          <Form
            id="forgot-form"
            onValidSubmit={this.onValidSubmit}
            fields={[
              {
                name: 'Email',
                rule: 'required|email',
                label: 'Email',
              },
            ]}
            submit={{
              label: 'Submit',
              loading: api.forgot.loading,
              icon: 'angle double right',
            }}
          />
        )}
        <Divider />
        <div>
          <Link to="/view/login" className="link">
            Back to login
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Forgot;
