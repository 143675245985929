import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import './index.scss';
import FileReviewModal from './FileReviewModal';
import fileReviewStore from './fileReviewStore';
import FileReviewToolbar from './FileReviewToolbar';
import FileReviewTable from './FileReviewTable';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';
import * as api from '../../../stores/api';
import moment from 'moment';

window.reloadFileReview = () => {
  fileReviewStore.refetch({}, true);
};
@observer
class FileReview extends React.Component {
  renderCaseForCM = caseStatus => {
    switch (caseStatus) {
      case `Dictations Sent to MAG`:
        return `Dictation Received`;
      case 'Corrected Report Sent to MAG':
        return 'Corrected Report Returned from Provider';
      default:
        return caseStatus;
    }
  };
  componentDidMount() {
    this._searchDebounced();
    // ui.on('open(/view/admin-file-reviews)', this._searchDebounced);
  }
  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?actionRequired=') !== -1) {
        const id = search.replace('?actionRequired=', '');
        fileReviewStore.refetch({ actionRequiredById: id }, true);
      } else if (search.indexOf('?caseStatus=') !== -1 && api.isCaseManager()) {
        const id = search.replace('?caseStatus=', '');
        fileReviewStore.refetch({ filter: this.renderCaseForCM(id) }, true);
      } else if (search.indexOf('?fromToRequest') !== -1) {
        fileReviewStore.refetch({ fromTo: `${moment().format('DD/MM/YYYY')} - ` }, true);
      } else {
        const id = search.replace('?nextTaskDue=', '');
        if (api.isCaseManager()) {
          fileReviewStore.refetch({ filter: id }, true);
        } else {
          fileReviewStore.refetch({ filterOverdue: id }, true);
        }
      }
    } else {
      fileReviewStore.refetch({}, true);
    }
  }, 1000);

  render() {
    const {
      loading,
      data,
      open,
      dataClaimant,
      loadingClaimant,
      loadingDynamicFRStatus,
      loadingStaff,
      loadingDynamicOverDueFRStatus,
      loadingStaffAssign,
      loadingStaffAssignAction,
      dataDynamicFileReviewStatus,
      dataDynamicOverDueFileReviewStatus,
      dataStaff,
      dataStaffAssign,
      dataStaffAssignAction,
    } = fileReviewStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {(loading && !data) ||
        (!dataClaimant && loadingClaimant) ||
        (loadingDynamicFRStatus && !dataDynamicFileReviewStatus) ||
        (loadingStaff && !dataStaff) ||
        (loadingDynamicOverDueFRStatus && !dataDynamicOverDueFileReviewStatus) ||
        (loadingStaffAssign && !dataStaffAssign) ||
        (loadingStaffAssignAction && !dataStaffAssignAction) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <FileReviewToolbar />
            <FileReviewTable />
            {open && <FileReviewModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FileReview;
