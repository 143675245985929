import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import './index.scss';
import MedNegModal from './MedNegModal';
import medNegStore from './MedNegStore';
import MedNegToolbar from './MedNegToolbar';
import MedNegTable from './MedNegTable';
import ui from '../../../stores/dashboard';
import moment from 'moment';
import { debounce } from 'lodash';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

window.reloadMedNeg = () => {
  medNegStore.refetch({}, true);
};
@observer
class MedNeg extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting() && !api.isCaseManager()) {
      dashboardStore.close('/view/admin-med-neg', true);
      return;
    }
    this._searchDebounced();
    // ui.on('open(/view/ime-assessments-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?fromToRequest') !== -1) {
        medNegStore.refetch({ fromTo: `${moment().format('DD/MM/YYYY')} - ` }, true);
      }
    } else {
      medNegStore.fetchFiles(true);
      medNegStore.fetchDynamicMedNegNextTask();
    }
  }, 1000);

  render() {
    const {
      loading,
      data,
      open,
      dataClaimant,
      loadingClaimant,
      loadingDynamicMNStatus,
      loadingStaff,
      loadingDynamicOverDueMNStatus,
      loadingStaffAssign,
      loadingStaffAssignAction,
      dataDynamicMedNegStatus,
      dataDynamicOverDueMedNegStatus,
      dataStaff,
      dataStaffAssign,
      dataStaffAssignAction,
    } = medNegStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {(loading && !data) ||
        (loadingClaimant && !dataClaimant) ||
        (loadingDynamicMNStatus && !dataDynamicMedNegStatus) ||
        (loadingStaff && !dataStaff) ||
        (loadingDynamicOverDueMNStatus && !dataDynamicOverDueMedNegStatus) ||
        (loadingStaffAssign && !dataStaffAssign) ||
        (loadingStaffAssignAction && !dataStaffAssignAction) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <MedNegToolbar />
            <MedNegTable />
            {open && <MedNegModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default MedNeg;
