import customFetch from '../../../utils/customFetch';

export const getAllData = (
  keyword = '',
  filterType = 'All',
  curPage = 1,
  numItemPerPage = 20,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/Language/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      FilterType: filterType,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
