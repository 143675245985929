import React from 'react';
import { Divider, Spin } from 'antd';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import router from '../../../stores/router';
import Modal from './Modal';
import '../AddEditClinic/index.scss';
import './index.scss';
import Form from './Form';
import ModalUpload from './UploadComponent';
import PreviewCampaign from './PreviewCampaign';

import store from './store';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

const STEPS = ['Campaign Information', 'Preview Email'];

@observer
class AddEditClaimant extends React.Component {
  state = {
    currentStep: 0,
  };

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }

    return params;
  };

  renderForm = () => {
    switch (this.state.currentStep) {
      case 1:
        return <PreviewCampaign />;
      default:
        return <Form isRerender={this.state.isRerender} />;
    }
  };

  handleStepChange = step => () => {
    this.setState({ currentStep: step });
  };

  componentWillUnmount() {
    store.resetStore();
  }

  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/add-edit-marketing-campaign', true);
      return;
    }
    const { id } = this.getRouteParams();
    store.getCampaignDetails(id);
  }

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return params;
  };

  handleSave = () => {};

  render() {
    return store.loading ? (
      <div className="page-loading">
        <Spin size="large" />
      </div>
    ) : (
      <div className="page-container">
        <div className="form-container">
          <h1 className="form-title">{STEPS[this.state.currentStep]}</h1>
          {this.renderForm()}
        </div>
        <div className="step-container step-container-sticky">
          {STEPS.map((step, idx) => (
            <Button
              key={step}
              fluid
              size="large"
              className={cx('step', { active: this.state.currentStep === idx })}
              onClick={this.handleStepChange(idx)}
            >
              {idx + 1}. {step}
            </Button>
          ))}
        </div>
        {store.open && <Modal />}
        {store.isOpenModalUpload && <ModalUpload />}
        <div className="form-footer">
          <Divider />
          {!api.isOnlySearch() && <Button
            color="blue"
            onClick={() => store.handleSave(true)}
            disabled={!store.data.Id || (store.data.Channel !== 2713 && store.data.Channel !== 2738)}
          >
            Save & Send Preview
          </Button>}
          {!api.isOnlySearch() && <Button primary onClick={() => store.handleSave(false)}>
            Save
          </Button>}
          <Button className="negative" onClick={() => dashboardStore.close(window.location.pathname)}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEditClaimant;
