import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/functions';

import { Icon, Table } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import dashboard from '../../../stores/dashboard';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import ClaimantsStore from './ClaimantsStore';

const columns = [
  { title: '' },
  { title: 'ID', sortKey: 'Id' },
  { title: 'Title', sortKey: 'Title' },
  { title: 'First Name', sortKey: 'FirstName' },
  { title: 'Last Name', sortKey: 'LastName' },
  { title: 'DOB', sortKey: 'DOB' },
  { title: 'Mobile Phone', sortKey: 'Telephone' },
  { title: 'Home Phone', sortKey: 'HomePhone' },
  { title: 'Email', sortKey: 'Email' },
  { title: 'Claimant Status', sortKey: 'IsActive' },
  { title: 'Date Of Status', sortKey: 'StatusChangedDate' },
  { title: 'Created At', sortKey: 'Created' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

@observer
class ClaimantsTable extends React.Component {
  openModal = options => {
    return ClaimantsStore.toggleModal(true, options);
  };

  handlePageClick = page => {
    ClaimantsStore.refetch({ curPage: page });
  };

  openCaseDetail = (id, record) => () => {
    localStorage.setItem('ClaimantName', `${record.FirstName} ${record.LastName}`);
    dashboard.close(`/view/add-edit-claimant-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-claimant-2?id=${id}&action=edit`);
    });
  };

  handleDelete = id => async () => {
    ClaimantsStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/PatientInfo/Delete', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      await ClaimantsStore.fetchClaimants({}, true);
    } finally {
      ClaimantsStore.setFieldsValue({ loading: false });
    }
  };

  handleEditItemStatus = id => async () => {
    ClaimantsStore.setFieldsValue({ loading: true });

    try {
      await customFetch('/PatientInfo/Authorize', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id }),
      });

      await ClaimantsStore.fetchClaimants({}, true);
    } finally {
      ClaimantsStore.setFieldsValue({ loading: false });
    }
  };

  renderDOB = date => {
    const date_ = date ? date : `N/A`;
    if (date_ === `N/A`) {
      return date_;
    } else {
      return moment(formatDate(date_), 'DD MMM YYYY').format(`DD/MM/YYYY`);
    }
  };

  renderTableBody = () => {
    return ClaimantsStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id} negative={!record.IsActive}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          selectable
          style={{ cursor: 'pointer' }}
          className={cx({ 'Cell-nowrap Cell-link': record.IsActive })}
          onClick={this.openCaseDetail(Id, record)}
        >
          <Link to="#" onClick={this.openCaseDetail(Id, record)}>
            {Id}
          </Link>
        </Table.Cell>
        <Table.Cell>{record.Title}</Table.Cell>
        <Table.Cell>{record.FirstName}</Table.Cell>
        <Table.Cell>{record.LastName}</Table.Cell>
        <Table.Cell className="Cell-nowrap">{this.renderDOB(record.DOB)}</Table.Cell>
        <Table.Cell className="Cell-nowrap">
          <FormatPhoneNumber mobilePhoneNumber={record.Telephone} />
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          <FormatPhoneNumber officePhoneNumber={record.HomePhone} />
        </Table.Cell>
        <Table.Cell>{record.Email}</Table.Cell>
        <Table.Cell>
          <p className={cx({ 'text-danger': !record.IsActive })}>{record.IsActive ? `Active` : `Inactive`}</p>
        </Table.Cell>
        <Table.Cell>
          {record.StatusChangedDate
            ? moment(formatDate(record.StatusChangedDate), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.Created ? moment(formatDate(record.Created), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CreatedBy}</Table.Cell>
      </Table.Row>
    ));
  };

  viewAssessmentHistory = (id, record) => {
    localStorage.setItem('ClaimantName', `${record.FirstName} ${record.LastName}`);
    dashboard.close(`/view/add-edit-claimant-2`, true);

    setTimeout(() => {
      dashboard.open(`/view/add-edit-claimant-2?id=${id}&action=edit&step=1`);
    });
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {record.IsActive && <Menu.Item onClick={this.openCaseDetail(id, record)}>Edit</Menu.Item>}
          {record.IsActive && (
            <Menu.Item onClick={() => this.viewAssessmentHistory(id, record)}>View Service History</Menu.Item>
          )}
          {!record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to enable claimant '${record.FirstName} ${record.LastName}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Enable
            </Menu.Item>
          )}
          {record.IsActive && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: `Do you want to disable claimant '${record.FirstName} ${record.LastName}'?`,
                onOk: this.handleEditItemStatus(id),
              })}
            >
              Disable
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  render() {
    const { loading, data } = ClaimantsStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={ClaimantsStore.sortColumn}
          sortDirection={ClaimantsStore.sortDirection}
          onSort={ClaimantsStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ClaimantsStore.curPage}
          totalPage={ClaimantsStore.totalPage}
          totalItems={ClaimantsStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ClaimantsTable;
