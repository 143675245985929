import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import CaseProgress from './CaseProgress';
import Modal from '../../shared/Modal';
import IMEStore from './IMEStore';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

function ModalCaseProgress() {
  const openCaseCorrespondence = () => {
    const {
      toggleModal,
      modalParams: { id },
    } = IMEStore;

    const caseNo = `Case A-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('PostAssessment', caseNo);
    dashboardStore.close('/view/add-edit-ime-assessment-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-ime-assessment-2?id=${id}&step=6`);
    });
    toggleModal(false)();
  };

  const { open, modalParams, toggleModal } = IMEStore;
  const title = `Status History: ${modalParams.caseNo}`;
  const url = '/MedicalService/GetAssessmentLogs';
  const body = { assessmentId: modalParams.id };
  return (
    <Modal
      visible={open}
      onCancel={toggleModal(false)}
      width={1700}
      title={title}
      footer={
        <React.Fragment>
          {!api.isTypist() && (
            <Button color="blue" onClick={openCaseCorrespondence}>
              <Icon name="table" /> View Case Correspondence
            </Button>
          )}
          <Button color="red" onClick={toggleModal(false)}>
            <Icon name="close" /> Close
          </Button>
        </React.Fragment>
      }
    >
      <CaseProgress
        progressList={api.IME_CaseProgress.data.itemList}
        currentStatus={modalParams.status}
        url={url}
        body={body}
        showHistory
      />
    </Modal>
  );
}

export default ModalCaseProgress;
