import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import Store from './store';
import CampaignHistoryTable from './Table';
import CampaignHistoryToolbar from './Toolbar';
import ui from '../../../stores/dashboard';

@observer
class CampaignScheduleHistory extends React.Component {
  componentDidMount() {
    Store.fetchCampaignHistory(true);
  }

  render() {
    const { loading, data, open } = Store;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            {/* <CampaignHistoryToolbar /> */}
            <CampaignHistoryTable />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CampaignScheduleHistory;
