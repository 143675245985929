import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';

// import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import TypistStore from './TypistStore';
import dashboardStore from '../../../stores/dashboard';
import * as api from '../../../stores/api';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Recruiting`, value: `IsRecruiting` },
  { text: `Onboarding`, value: `IsOnboarding` },
  { text: `Onboarded`, value: `IsOnboarded` },
  { text: `Inactive`, value: `IsExcluding` },
];
const State = [
  { id: '', label: 'All' },
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

@observer
class CaseManagersToolbar extends React.Component {
  componentDidMount() {
    TypistStore.fetchTypistGroup(true);
  }
  renderState = () => {
    return State.map(i => ({
      value: i.label,
      text: i.label,
    }));
  };
  renderStateText = value => {
    return value ? value : 'All';
  };

  handleSelectState = (_, { value }) => {
    TypistStore.refetch(
      {
        cmState: `${value === 'All' ? '' : value}`,
      },
      true,
    );
  };

  openModal = options => {
    return TypistStore.toggleModal(true, options);
  };

  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleFilter = (_, { value }) => {
    TypistStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleScroll = event => {
    const target = event.target;
    const number = TypistStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      TypistStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  handleReset = () => {
    TypistStore.resetStore();
    TypistStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    TypistStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    const { companyId } = TypistStore;
    dashboardStore.close('/view/add-edit-typist', true);
    localStorage.removeItem('TypistName');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-typist?companyId=${companyId}`);
    });
  };

  handleChangeSearchDropdown = e => {
    TypistStore.refetchSearch({
      keywordSearch: e.target.value,
      keyword: e.target.value,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(TypistStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({
      Id: 'All',
      Name: ``,
      Email: ``,
    });
    return array.map(opt => ({
      text: `${
        opt.Id === `All`
          ? `All`
          : `${opt.Id || ''} ${opt.FirstName ? `- ${opt.FirstName}` : ''} ${opt.LastName || ''} ${opt.Email}`
      }`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FirstName || ''} ${opt.LastName || ''}`,
        key: `${opt.FirstName || ''} ${opt.LastName}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const cm = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (cm.id === `All` || value === `All`) {
      TypistStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      TypistStore.resetSearch();
    } else {
      TypistStore.refetch(
        {
          keyword: cm.id,
          curPage: 1,
          numberSearch: 30,
          keywordSearch: `${cm.id}`,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(TypistStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', Name: ``, Email: `` });
    let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FirstName || ''} ${opt.LastName || ''}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleExportFreeBookingOffers = () => {
    window.open('/Staff/ExportFreeBookingOffers', '_blank');
  };

  handleExportSalesCall = () => {
    window.open('/Staff/ExportSalesCall', '_blank');
  };

  handleRefresh = () => {
    TypistStore.refetch(
      {
        dataSearch: null,
        data: null,
      },
      true,
    );
  };

  renderClientOfficer = () => {
    let clients = toJS(TypistStore.dataClient.itemList);
    clients.unshift({
      Id: '',
      FullName: 'All',
    });
    return clients.map(client => ({
      value: client.Id,
      text: `${client.FullName}`,
    }));
  };

  handleScrollClient = event => {
    const target = event.target;
    const number = TypistStore.numberClientPage;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      TypistStore.refetchClient({ numberClientPage: number + 20 });
    }
  };

  handleClientSearch = (_, { searchQuery }) => {
    TypistStore.refetchClient({ keywordClient: searchQuery });
  };

  handleFilterClient = (_, { value }) => {
    TypistStore.refetch(
      {
        clientOfficerId: value,
      },
      true,
    );
  };
  renderClientOfficerText = value => {
    let clients = toJS(TypistStore.dataClient.itemList);
    clients.unshift({
      Id: '',
      FullName: 'All',
    });
    return clients.filter(client => client.Id === value).map(client => client.FullName)[0];
  };

  render() {
    const {
      keyword,
      loading,
      filter,
      loadingSearch,
      loadingClient,
      keywordClient,
      clientOfficerId,
      cmState,
      totalItems,
    } = TypistStore;
    return (
      <>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              allowAdditions
              disabled={loading}
              additionLabel=""
              onScroll={this.handleScroll}
              selectOnNavigation={false}
              value={this.renderSearchText(keyword)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: '5px' }}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() && <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.openCaseCreate()}
            >
              Add New Typist
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            disabled={loading}
            label={`Account Status`}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.Filter
            disabled={loading}
            label={`State`}
            value={this.renderStateText(cmState)}
            options={this.renderState()}
            onChange={this.handleSelectState}
          />
          <TableToolbar.SearchDropdown
            disabled={loading}
            loading={loadingClient}
            selectOnNavigation={false}
            text={`Client Officer: ${this.renderClientOfficerText(clientOfficerId)}`}
            value={clientOfficerId}
            options={this.renderClientOfficer()}
            onChange={this.handleFilterClient}
            searchQuery={keywordClient}
            onSearchChange={this.handleClientSearch}
            onScroll={this.handleScrollClient}
          />
        </TableToolbar.Container>
      </>
    );
  }
}

export default CaseManagersToolbar;
