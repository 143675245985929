import React from 'react';
import {
  Form,
  Checkbox,
  DatePicker,
  Input,
  Divider,
  Select,
  Dropdown,
  Menu,
  Icon,
  InputNumber,
  Radio,
  notification,
  Collapse,
} from 'antd';
import { observer } from 'mobx-react';
import { Popup, Icon as IconSemantic } from 'semantic-ui-react';
import { toJS, action } from 'mobx';
import moment from 'moment';

import * as api from '../../../stores/api';
import dashboard from '../../../stores/dashboard';
import { formatDate } from '../../../utils/functions';

import FieldUpload from './FieldUpload';
import store from './Store';
import appStore from 'stores/app';
import {
  getStaffRecentChangeHistory,
  getCompanyRecentChangeHistory,
  getConfirmDoctorRate,
  getAllBlockedClients,
  checkExcludeTypeOfService,
} from './service';
import { Header, HeaderWithoutCheck } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';

const getBookingClientName = (id, bookingClients) => {
  if (id && bookingClients?.length) {
    const foundItem = bookingClients.find(bc => bc.Id === id);
    return foundItem?.Name ?? '';
  }

  return '';
};

@observer
class ClaimDetails extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  disablePurchase = () => {
    const itemModel = toJS(store.assessmentInfo);
    const { lockTypist } = store.handleLockReportCompleteCM(itemModel);
    if (!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || lockTypist) return true;
    return false;
  };

  handleSearch = fieldName => value => {
    if (fieldName === 'companyInsurer') {
      return value
        ? store.handleRefetchCompanyInsurer({ keywordCompanyInsurer: value })
        : store.handleRefetchCompanyInsurer({
            keywordCompanyInsurer: '',
          });
    }
    if (fieldName === 'bookingCaseManager') {
      return value
        ? store.handleRefetchBookingCM({ bookingCMKeyword: value })
        : store.handleRefetchBookingCM({
            bookingCMKeyword: '',
          });
    }
    if (fieldName === 'Report_Delivery_Contact_1') {
      return value
        ? store.handleRefetchlistRDC_1_CM({ listRDC_1_CMKeyword: value })
        : store.handleRefetchlistRDC_1_CM({
            listRDC_1_CMKeyword: store.Report_Delivery_Contact_1?.Id || '',
            listRDC_1_CMNumItemPerPage: 30,
          });
    }
    if (fieldName === 'Report_Delivery_Contact_2') {
      if (api.isCaseManager()) {
        return value
          ? store.handleRefetchlistRDC_2_CM({
              listRDC_2_CMKeyword: value,
              listRDC_2_CMCompanyId: store.bookingClient?.Id,
            })
          : store.handleRefetchlistRDC_2_CM({
              listRDC_2_CMKeyword: store.Report_Delivery_Contact_2?.Id || '',
              listRDC_2_CMNumItemPerPage: 30,
            });
      }
      return value
        ? store.handleRefetchlistRDC_2_CM({ listRDC_2_CMKeyword: value })
        : store.handleRefetchlistRDC_2_CM({
            listRDC_2_CMKeyword: store.Report_Delivery_Contact_2?.Id || '',
            listRDC_2_CMNumItemPerPage: 30,
          });
    }
    if (fieldName === 'bookingSolicitor') {
      return value
        ? store.handleRefetchBookingSolicitor({
            bookingSolicitorKeyword: value,
          })
        : store.handleRefetchBookingSolicitor({
            bookingSolicitorKeyword: store.bookingSolicitor?.Id || '',
            bookingSolicitorNumItemPerPage: 30,
          });
    }
    if (fieldName === 'subBookingSolicitor') {
      return value
        ? store.handleRefetchBookingSubSolicitor({
            bookingSubSolicitorKeyword: value,
          })
        : store.handleRefetchBookingSubSolicitor({
            bookingSubSolicitorKeyword: '',
            bookingSubSolicitorNumItemPerPage: 30,
          });
    }
    if (fieldName === 'bookingClient') {
      return value
        ? store.handleRefetchBookingClient({
            keywordCompany: value,
          })
        : store.handleRefetchBookingClient({
            keywordCompany: '',
            numberCompanyPerPage: 30,
          });
    }
    if (fieldName === 'subBookingClient') {
      return value
        ? store.handleRefetchBookingSubClient({
            keywordSubCompany: value,
          })
        : store.handleRefetchBookingSubClient({
            keywordSubCompany: '',
            numberSubCompanyPerPage: 30,
          });
    }
    if (fieldName === 'subBookingClientCM') {
      return value
        ? store.handleRefetchBookingSubClientCM({
            keywordSubBookingClientCM: value,
          })
        : store.handleRefetchBookingSubClientCM({
            keywordSubBookingClientCM: '',
            numberSubBookingClientCMPerPage: 30,
          });
    }
    if (fieldName === 'claimant') {
      return value
        ? store.handleRefetchClaimant({
            keywordPatient: value,
          })
        : store.handleRefetchClaimant({
            keywordPatient: '',
            numberPatientPerPage: 30,
          });
    }
    if (fieldName === 'specialist') {
      return value
        ? store.handleRefetchSpecialist({ keywordAllWithoutNCI: value })
        : store.handleRefetchSpecialist({
            keywordAllWithoutNCI: store.specialist?.Id || '',
            numItemPerPageAllWithoutNCI: 30,
          });
    }
    if (fieldName === 'supplier') {
      return value
        ? store.handleRefetchSupplier({ keywordAllWithoutNCI: value })
        : store.handleRefetchSupplier({
            keywordAllWithoutNCI: '',
            numItemPerPageAllWithoutNCI: 30,
          });
    }
  };

  handleScroll = fieldName => event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      if (fieldName === 'bookingCaseManager') {
        const number = store.bookingCMNumItemPerPage;
        return store.handleRefetchBookingCM({
          bookingCMNumItemPerPage: number + 30,
        });
      }
      if (fieldName === 'Report_Delivery_Contact_1') {
        const number = store.listRDC_1_CMNumItemPerPage;
        return store.handleRefetchlistRDC_1_CM({
          listRDC_1_CMNumItemPerPage: number + 30,
        });
      }
      if (fieldName === 'Report_Delivery_Contact_2') {
        if (api.isCaseManager()) return;
        else {
          const number = store.listRDC_2_CMNumItemPerPage;
          return store.handleRefetchlistRDC_2_CM({
            listRDC_2_CMNumItemPerPage: number + 30,
          });
        }
      }
      if (fieldName === 'bookingSolicitor') {
        const number = store.bookingSolicitorNumItemPerPage;
        return store.handleRefetchlistRDC_1_CM({
          bookingSolicitorNumItemPerPage: number + 30,
        });
      }
      if (fieldName === 'subBookingSolicitor') {
        const number = store.bookingSubSolicitorNumItemPerPage;
        return store.handleRefetchlistRDC_1_CM({
          bookingSubSolicitorNumItemPerPage: number + 30,
        });
      }
      if (fieldName === 'bookingClient') {
        const number = store.numberCompanyPerPage;
        return store.handleRefetchBookingClient({
          numberCompanyPerPage: number + 30,
          isGetDeliveryContact: true,
        });
      }
      if (fieldName === 'subBookingClient') {
        const number = store.numberSubCompanyPerPage;
        return store.handleRefetchBookingSubClient({
          numberSubCompanyPerPage: number + 30,
        });
      }
      if (fieldName === 'companyInsurer') {
        const number = store.numberCompanyInsurerPerPage;
        return store.handleRefetchCompanyInsurer({
          numberCompanyInsurerPerPage: number + 30,
        });
      }
      if (fieldName === 'subBookingClientCM') {
        const number = store.numberSubBookingClientCMPerPage;
        return store.handleRefetchBookingSubClientCM({
          numberSubBookingClientCMPerPage: number + 30,
        });
      }
      if (fieldName === 'claimant') {
        const number = store.numberPatientPerPage;
        return store.handleRefetchClaimant({
          numberPatientPerPage: number + 30,
        });
      }
    }
  };

  renderDOB = dobStr => {
    if (dobStr) {
      if (typeof dobStr === 'string') {
        if (dobStr.startsWith('/Date')) {
          return moment(formatDate(dobStr), 'DD MMM, YYYY').format('DD/MM/YYYY');
        } else return moment(dobStr, 'DD/MM/YYYY').format('DD/MM/YYYY');
      } else return dobStr.format('DD/MM/YYYY');
    } else {
      return '';
    }
  };

  @action handleClaimantChanged = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (itemModel.AssessmentMethod) {
      if (!!store.claimant) {
        store.assessmentInfo = {
          ...toJS(store.assessmentInfo),
          EassessClaimantEmail: store.claimant.Email,
          EassessClaimantPhone: store.claimant.Telephone,
        };
      }

      if (!store.assessmentInfo.EassessClaimantEmail && store.bookingCaseManager) {
        store.assessmentInfo = {
          ...toJS(store.assessmentInfo),
          EassessClaimantEmail: store.bookingCaseManager.Email,
        };
      }
    }
  };

  @action handleChangeClient = (data_, fieldName) => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      //Wraning block client
      notification.destroy();
      if (store.blockedClients && store.blockedClients.length > 0 && store.specialist && store.specialist.Id > 0) {
        var findClient = store.blockedClients.find(i => i.Id === data_.Id);
        if (findClient) {
          notification.error({
            message: 'Blocked Client',
            description: 'This client has been blocked from booking the specialist.',
            duration: 15,
          });
        }
      } else if (store.specialist && store.specialist.Id > 0) {
        getAllBlockedClients(store.specialist.Id).then(
          action(resp => {
            const { itemList } = resp;
            if (!!itemList.length && itemList[0] && itemList[0].ClientList && itemList[0].ClientList.length > 0) {
              store.blockedClients = itemList[0].ClientList;
              notification.destroy();
              var findClient = store.blockedClients.find(i => i.Id === data_.Id);
              if (findClient) {
                notification.error({
                  message: 'Blocked Client',
                  description: 'This client has been blocked from booking the specialist.',
                  duration: 15,
                });
              }
            } else {
              store.blockedClients = [];
            }
          }),
        );
      }
    }
    //--- Booking CM
    store.handleRefetchBookingCM({
      bookingCMCompanyId: data_.Id,
      bookingCaseManager: {},
      bookingCMKeyword: '',
    });
    //--- Report Receiving Contact 1
    store.handleRefetchlistRDC_1_CM({
      listRDC_1_CMCompanyId: data_.Id,
      Report_Delivery_Contact_1: {},
      listRDC_1_CMKeyword: '',
    });
    //--- Report Receiving Contact 2
    store.handleRefetchlistRDC_2_CM({
      Report_Delivery_Contact_2: {},
      listRDC_2_CMKeyword: '',
    });
    //--- Booking Solicitor
    store.handleRefetchBookingSolicitor({
      bookingSolicitorCompanyId: data_.Id,
      bookingSolicitor: {},
      bookingSolicitorKeyword: '',
    });

    if (data_ && data_.CreditTerm === 1) {
      //ClientPrepaymentRequired
      store.assessmentInfo.ClientPrepaymentRequired = true;
      store.assessmentInfo.ClientPrepaymentRequiredDate = moment();
      if (!store.assessmentInfo.DelayPrepaymentRequired) {
        store.assessmentInfo.DelayPrepaymentRequired = true;
      }
    }

    //CIR-1828: Copy Client extra report contact into Report Receiving Contact 2
    if (!store.assessmentInfo.Id || store.assessmentInfo.Id <= 0) {
      var cm = store.listCompany.itemList.find(i => i.Id === store.bookingCMCompanyId);
      console.log(cm);
      if (cm && cm.ReportDeliveryContact) {
        if (!!cm.ReportDeliveryContact) {
          console.log(cm.ReportDeliveryContact);
          store.Report_Delivery_Contact_2 = cm.ReportDeliveryContactObj;
        } else {
          store.Report_Delivery_Contact_2.Id = cm.ReportDeliveryContact;
        }
      }
    }
    store[fieldName] = data_;
    store.handleCheckICare();
  };

  handleChangeDropdownObj = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      return;
    });

  @action handleChangeObj = (_, event, fieldName) => {
    if (event && event.props) {
      const { data } = event.props;
      notification.destroy();
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      if (fieldName === 'bookingClient') {
        if (store.assessmentInfo.Id > 0) {
          store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Changing Booking Client can affect billing information later. Do you want to proceed?',
            onOk: action(() => {
              this.handleChangeClient(data_, fieldName);
            }),
          })();
          return;
        } else {
          this.handleChangeClient(data_, fieldName);
        }
      }
      if (fieldName === 'subBookingClientCM') {
        // store.handleRefetchBookingSubClientCM({
        //   subBookingClientCMId: data_.Id,
        //   listSubBookingClientCM: {},
        //   keywordSubBookingClientCM: '',
        // });
        store.handleRefetchSubBookingCM({
          bookingClientCMId: data_.Id,
          listSubBookingCM: {},
          subBookingSolicitor: [],
        });
      }
      if (fieldName === 'bookingCaseManager') {
        console.log(_);
        const itemModel = toJS(store.assessmentInfo);
        store.cmSelected = data_;
        store.Report_Delivery_Contact_1 = data_;
        store.handleRefetchlistRDC_1_CM({});
        store[fieldName] = data_;
        if(itemModel.Id === 0 && !data_.Email){
          store.setFieldsValue({
            assessmentInfo: {
              ...itemModel,
              NotifyCmAppointmentInquiry: false,
              NotifyCmAppointmentConfirmed:  false,
            },
          });
        }else if(itemModel.Id === 0){
          if(itemModel.IsAppointmentConfirmed){
            store.setFieldsValue({
              assessmentInfo: {
                ...itemModel,
                NotifyCmAppointmentConfirmed:  true,
              },
            });
          }else{
            store.setFieldsValue({
              assessmentInfo: {
                ...itemModel,
                NotifyCmAppointmentInquiry: true,
              },
            });
          }
        }
        return this.handleClaimantChanged();
      }
      if (fieldName === 'PIC_1') {
        return (store[fieldName] = {
          picId: data_.Id,
          picName: data_.FullName,
          inChargeOf: 0,
        });
      }
      if (fieldName === 'claimant') {
        store[fieldName] = data_;
        return this.handleClaimantChanged();
      }
      // if(fieldName === 'reportType'){
      //   //console.log(data_.Id);
      //   if(store.specialistAcceptedTypes && store.specialistAcceptedTypes.length > 0){
      //     var findRT = store.specialistAcceptedTypes.find(r => r.Id === data_.Id);
      //     if(!findRT){
      //       notification.error({
      //         message: 'Session accepted types',
      //         description: `This session does not accept this report type. Please check with our staff.`,
      //         duration: 15,
      //       });
      //       // store.toggleModal(true, {
      //       //   modalType: 'warning',
      //       //   title: 'Session accepted types',
      //       //   message: 'This session does not accept this report type. Please check with our staff.',
      //       // })();
      //     }
      //   }
      // }
      //console.log('Check DoctorRate', store.specialist, data_.Id);
      if (fieldName === 'reportType') {
        store.checkExclusionReportType(data_.Id);
      }
      if (fieldName === 'reportType' && store.specialist && store.specialist?.Id > 0 && data_.Id > 0) {
        getConfirmDoctorRate(store.specialist?.Id, data_.Id).then(
          action(resp => {
            if (resp.status === 'success' && resp.showConfirm) {
              //store.assessmentInfo.DoctorRate = resp.objRet;
              store.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate = true;
              notification.error({
                message: 'Gazetted Rate',
                description: `This specialist may not apply Gazetted rates for this service.`,
                duration: 15,
              });
            } else {
              store.assessmentInfo.ShowConfirmGazettedRateOfDoctorRate = false;
            }
          }),
        );
        //console.log(1, store.assessmentInfo);
      }
      if ((fieldName === 'claimType' || fieldName === 'reportType') && store.specialist?.Id) {
        var toc = 0;
        var tor = 0;
        if (fieldName === 'claimType') {
          toc = data_.Id;
          tor = store.reportType?.Id;
        }
        if (fieldName === 'reportType') {
          tor = data_.Id;
          toc = store.claimType?.Id;
        }
        checkExcludeTypeOfService(store.specialist?.Id, toc, tor).then(
          action(resp => {
            if (resp.status === 'success' && resp.isExcludeService) {
              //notification.destroy();
              notification.warning({
                message: 'Error',
                description: `This specialist does not accept performing the required service. Please check with our staff`,
                duration: 15,
              });
            }
          }),
        );
      }
      return (store[fieldName] = data_);
    } else {
      if (fieldName === 'bookingClient') {
        //--- Booking CM
        store.handleRefetchBookingCM({
          bookingCMCompanyId: 0,
          bookingCaseManager: {},
          bookingCMKeyword: '',
        });
        //--- Report Receiving Contact 1
        store.handleRefetchlistRDC_1_CM({
          listRDC_1_CMCompanyId: 0,
          Report_Delivery_Contact_1: {},
          listRDC_1_CMKeyword: '',
        });
        //--- Report Receiving Contact 2
        store.handleRefetchlistRDC_2_CM({
          Report_Delivery_Contact_2: {},
          listRDC_2_CMKeyword: '',
        });
        //--- Booking Solicitor
        store.handleRefetchBookingSolicitor({
          bookingSolicitorCompanyId: 0,
          bookingSolicitor: {},
          bookingSolicitorKeyword: '',
        });
        return (store[fieldName] = {});
      }
      if (fieldName === 'bookingSolicitor') {
        store.handleChangeValue('NotifyCmSolicitorCancellation', false);
        store.handleChangeValue('NotifyCmSolicitorAboutDidNotAttendCase', false);
      }
      if (fieldName === 'claimant') {
        store[fieldName] = {};
        return this.handleClaimantChanged();
      }
      if (fieldName === 'bookingCaseManager') {
        store.assessmentInfo.F_RDCSameBookingCM = false;
        store.Report_Delivery_Contact_1 = {};
        store.handleRefetchlistRDC_1_CM({
          listRDC_1_CMKeyword: '',
        });
        store[fieldName] = {};
        return this.handleClaimantChanged();
      }
      if (fieldName === 'reportType') {
        console.log(2, store.assessmentInfo);
      }
      return (store[fieldName] = {});
    }
  };

  @action handleChangeField = (value, fieldName) => {
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = { ...itemModel, [fieldName]: value });
  };

  @action handleChangeModeMultipleObj = (_, event, fieldName, lengthLimit) => {
    const data = event.map(i => i.props.data);
    const data_ = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null)).filter(i => i.Id);
    //console.log(data_);
    if (fieldName === 'bodyParts') {
      store.getLookupConfigChild(data_);
    }
    if (lengthLimit) {
      if (data_.length <= lengthLimit) {
        return (store[fieldName] = data_);
      } else return;
    }
    return (store[fieldName] = data_);
  };

  @action handleChangeDateRangePicker = (value, fieldNameFrom, fieldNameTo) => {
    const [from, to] = value;
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldNameFrom]: from,
      [fieldNameTo]: to,
    });
  };

  handleChangeTextInput = fieldName =>
    action(event => {
      const value = event?.target ? (event.target.value ? event.target.value : '') : event;
      const itemModel = toJS(store.assessmentInfo);
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
    });

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;

      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'F_RDCSameBookingCM') {
        if (value === true) {
          store.Report_Delivery_Contact_1 = store.bookingCaseManager;
          store.handleRefetchlistRDC_1_CM({
            listRDC_1_CMKeyword: store.bookingCaseManager.Id,
          });
        }
      }

      if (fieldName === 'IROFundingApproved') {
        if (value) {
          return (store.assessmentInfo = {
            ...itemModel,
            IROFundingApprovalDate: moment(),
            [fieldName]: value,
          });
        } else {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          });
        }
      }

      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      if (fieldName === 'WifroIlars') {
        if (value && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
          notification.info({
            message: 'Information',
            description:
              'Funding approval must be provided 7 days before appointment date or booking can be cancelled automatically',
            duration: 15,
          });
        }
        store.handleActiveUploadType(toJS(store.currentStep));
      }
    });

  @action handleChangeDatePicker = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    if (fieldName === 'InjuriedDate') {
      const today = moment();
      const isBefore = moment(value).isBefore(today);
      if (!isBefore && value) {
        return store.showNotificationError('Date of Injury cannot be a future date');
      } else {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        });
      }
    }
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  handleRecentChange = async (record, actionName, columns, fieldNameStaffRecentChange) => {
    try {
      const response = await getStaffRecentChangeHistory(record.Id, actionName);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  handleRecentCompanyChange = async (record, columns) => {
    try {
      const response = await getCompanyRecentChangeHistory(record.Id, 'ime');
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentCompanyChange',
          dataRecentChange,
          columns,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  handleOpenAddEdit = (actionType = 'add', storageName, storageValue, pathName, id = 0, fieldName) => {
    if (appStore.iframeMode) {
      if (id === 0 || actionType !== 'add') {
        localStorage.setItem(storageName, storageValue);
      } else {
        localStorage.removeItem(storageName);
      }
      window.parent.postMessage(
        {
          action: 'OPEN_VIEW',
          option: {
            pathName,
            fieldName,
            id,
            storageName,
          },
        },
        '*',
      );
      return;
    }

    if (id === 0) {
      localStorage.setItem(storageName, storageValue);
      dashboard.close(pathName, true);
      setTimeout(() => {
        if (pathName === '/view/add-edit-case-manager-2') {
          dashboard.open(
            `${pathName}?pageGetter=addEditIME&fieldName=${fieldName}&companyId=${store.bookingClient?.Id}`,
          );
        } else {
          dashboard.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
        }
      });
    } else {
      if (actionType === 'add') {
        localStorage.removeItem(storageName);
        dashboard.close(pathName, true);
        setTimeout(() => {
          dashboard.open(`${pathName}?pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      } else {
        localStorage.setItem(storageName, storageValue);
        dashboard.close(pathName, true);
        setTimeout(() => {
          dashboard.open(`${pathName}&pageGetter=addEditIME&fieldName=${fieldName}`);
        });
      }
    }
  };

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      if (Object.keys(item).length > 1) {
        const arr_ = arr.filter(i => i[key] !== item[key]);
        const item_ = toJS(item);
        return [...arr_, item_];
      } else {
        return arr;
      }
    } else if (key && item && item[key] && !!!arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  arrayToArray = (arrayF = [], arrayS = [], key) => {
    if (
      !!arrayF.length &&
      !!arrayS.length &&
      key &&
      !!arrayF.filter(i => i[key]).length &&
      !!arrayS.filter(i => i[key]).length
    ) {
      return arrayS.filter(s => {
        const existValue = arrayF.find(f => f[key] === s[key]);
        if (existValue) {
          return existValue;
        }
        return s;
      });
    }
    return arrayS;
  };

  render() {
    const itemModel = toJS(store.assessmentInfo);
    const staffs = toJS(store.listStaff);
    const bookingCMs = toJS(store.listBookingCM);
    const bookingSolicitors = toJS(store.listBookingSolicitor);
    const listSubBookingCMs = toJS(store.listSubBookingCM);
    const claimants = toJS(store.listClaimant);
    const claimTypes = toJS(store.listClaimType);
    const reasonReferral = toJS(store.listReasonReferral);
    const reportTypes = toJS(store.listReportType);
    const lookupConfigsParent = toJS(store.listConfigParents);
    const lookupConfigsChild = toJS(store.listConfigChild);
    const IMSApprovalCodes = toJS(store.listIMSApprovalCode);
    const wiroPrincipleLawyers = toJS(store.listWiroPrincipleLawyer);
    const pic_1 = toJS(store.PIC_1);
    const lockRule = store.handleLockReportCompleteCM(itemModel);
    const listRDC1CM = toJS(store.listRDC_1_CM);
    const listRDC2CM = toJS(store.listRDC_2_CM);
    const companys = toJS(store.listCompany);
    const subCompanys = toJS(store.listSubBookingClientCM);
    const insurerCompanies = toJS(store.listCompanyInsurer);
    const listSpecialist = toJS(store.listSpecialist);
    const listSupplier = toJS(store.listSupplier);
    const icareLookupList = toJS(store.icareLookupList);

    const columsStaffRecentChange = [
      { title: 'Case Manager' },
      { title: 'Old Assigned Staff' },
      { title: 'Current Assigned Staff' },
      { title: 'Assigned By' },
      { title: 'Assigned Date' },
    ];
    const columsCompanyRecentChange = [
      { title: 'Booking Client' },
      { title: 'Case Manager' },
      { title: 'Updated By' },
      { title: 'Updated Date' },
    ];
    const fieldNameStaffRecentChange = [
      'CaseManagerFullName',
      'OldAssigneeFullName',
      'NewAssigneeFullName',
      'CreatedUserFullName',
      'CreatedDate',
    ];

    return (
      <Form name="claim-details" className="grid-style">
        {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 1
                <button
                  className="field-actions"
                  onClick={() =>
                    this.handleRecentChange(
                      itemModel,
                      'Status_RequestReceived',
                      columsStaffRecentChange,
                      fieldNameStaffRecentChange,
                    )
                  }
                >
                  Recent changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              size="large"
              value={pic_1?.picId || undefined}
              showSearch
              optionFilterProp="children"
              onChange={(value, event) => this.handleChangeObj(value, event, 'PIC_1')}
            >
              {(staffs?.itemList || []).map(i => (
                <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                  {i.FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Request Date">
          <DatePicker
            size="large"
            value={renderDate(itemModel.InquiryDate)}
            format="DD/MM/YYYY"
            disabled={itemModel.isLocked || !api.isAdminOrMagStaffOrAccounting()}
            onChange={value => this.handleChangeDatePicker('InquiryDate', value)}
          />
        </Form.Item>
        {api.isAdminOrMagStaffOrAccounting() && (
          <>
            <div className="checkbox-field fluid-field">
              <Checkbox
                checked={itemModel.OldBilled}
                disabled={itemModel.isLocked || !itemModel.PaperWorkReceived}
                onChange={this.handleChangeCheckBox('OldBilled')}
              >
                Old-Billed
              </Checkbox>
            </div>
          </>
        )}
        <Divider className="fluid-field" />
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <HeaderWithoutCheck
                title="Booking Client and CM"
                statuses={[
                  {
                    key: 'ime-bc',
                    text: store.bookingClient?.Id
                      ? `Client: ${getBookingClientName(store.bookingClient.Id, companys.itemList)}`
                      : '',
                  },
                  {
                    key: 'ime-bcm',
                    text: store.bookingCaseManager?.FullName ? `Booking CM: ${store.bookingCaseManager.FullName}` : '',
                  },
                ]}
              />
            }
          >
            <Form.Item
              required
              validateStatus={!!store.bookingClient?.Id ? null : 'error'}
              help={!!store.bookingClient?.Id ? '' : 'This field is required'}
              // className="fluid-field"
              label={
                <React.Fragment>
                  Booking Client
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <React.Fragment>
                      {'  '}
                      <button
                        className="btn-action"
                        style={{ fontSize: '0.9em', textDecoration: 'none' }}
                        onClick={() => this.handleRecentCompanyChange(itemModel, columsCompanyRecentChange)}
                      >
                        Recent Changes
                      </button>
                      {'  '}
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'add',
                                  'CompanyName',
                                  '',
                                  '/view/add-edit-client-2',
                                  0,
                                  'bookingClient',
                                )
                              }
                            >
                              Add Client
                            </Menu.Item>
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'edit',
                                  'CompanyName',
                                  store.bookingClient?.Name || 0,
                                  `/view/add-edit-client-2?id=${store.bookingClient?.Id || 0}&action=edit&step=0`,
                                  store.bookingClient?.Id || 0,
                                  'bookingClient',
                                )
                              }
                            >
                              Edit Client
                            </Menu.Item>
                            {/* <Menu.Item
                              onClick={() => this.handleRecentCompanyChange(itemModel, columsCompanyRecentChange)}
                            >
                              Recent Changes
                            </Menu.Item> */}
                          </Menu>
                        }
                      >
                        <button className="field-actions">
                          Add/Edit <Icon type="down" />
                        </button>
                      </Dropdown>
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={
                  store.bookingClient.Id
                    ? store.bookingClient.Id !== 0
                      ? store.bookingClient?.Id
                      : undefined
                    : undefined
                }
                showSearch
                allowClear
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  itemModel.Status === api.imeStatus.CLIENT_INVOICE ||
                  itemModel.Status === api.imeStatus.DOCTOR_INVOICE ||
                  itemModel.Status === api.imeStatus.BILLED ||
                  itemModel.Status === api.imeStatus.FULLY_PAID ||
                  itemModel.Status === api.imeStatus.IME_CANCELLED_WITH_FEE ||
                  itemModel.Status === api.imeStatus.CANCELLED_SPECIALIST_TBP ||
                  itemModel.Status === api.imeStatus.CANCELLED
                }
                optionFilterProp="children"
                onChange={(value, event) => this.handleChangeObj(value, event, 'bookingClient')}
                onSearch={this.handleSearch('bookingClient')}
                onPopupScroll={this.handleScroll('bookingClient')}
              >
                {companys.itemList &&
                  this.itemToArray(store.bookingClient, companys.itemList, 'Id').map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.Name} - {i.StateName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              validateStatus={!!store.bookingCaseManager?.Id ? null : 'error'}
              help={!!store.bookingCaseManager?.Id ? '' : 'This field is required'}
              label={
                <React.Fragment>
                  Booking CM
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() =>
                              this.handleOpenAddEdit(
                                'add',
                                'CmName',
                                '',
                                '/view/add-edit-case-manager-2',
                                0,
                                'bookingCaseManager',
                              )
                            }
                          >
                            Add Case Manager
                          </Menu.Item>
                          {!!store.bookingCaseManager?.Id && (
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'edit',
                                  'CmName',
                                  store.bookingCaseManager?.FullName || '',
                                  `/view/add-edit-case-manager-2?id=${store.bookingCaseManager?.Id ||
                                    0}&action=edit&step=0&companyId=${store.bookingClient?.Id || 0}`,
                                  store.bookingCaseManager?.Id || 0,
                                  'bookingCaseManager',
                                )
                              }
                            >
                              Edit Case Manager
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                optionFilterProp="children"
                size="large"
                allowClear
                value={store.bookingCaseManager?.Id || undefined}
                onSearch={this.handleSearch('bookingCaseManager')}
                onPopupScroll={this.handleScroll('bookingCaseManager')}
                onChange={(value, event) => this.handleChangeObj(value, event, 'bookingCaseManager')}
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() ||
                  itemModel.Status === api.imeStatus.CLIENT_INVOICE ||
                  itemModel.Status === api.imeStatus.DOCTOR_INVOICE ||
                  itemModel.Status === api.imeStatus.BILLED ||
                  itemModel.Status === api.imeStatus.FULLY_PAID ||
                  itemModel.Status === api.imeStatus.IME_CANCELLED_WITH_FEE ||
                  itemModel.Status === api.imeStatus.CANCELLED_SPECIALIST_TBP ||
                  itemModel.Status === api.imeStatus.CANCELLED
                }
              >
                {this.itemToArray(store.bookingCaseManager, bookingCMs?.itemList || [], 'Id')
                  .filter(i => i.Id)
                  .map(i => (
                    <Select.Option value={i.Id} key={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.FullName} - {i.Email}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <React.Fragment>
                  Booking Solicitor
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() =>
                              this.handleOpenAddEdit(
                                'add',
                                'CmName',
                                '',
                                '/view/add-edit-case-manager-2',
                                0,
                                'bookingSolicitor',
                              )
                            }
                          >
                            Add Solicitor
                          </Menu.Item>
                          {store.bookingSolicitor?.Id && (
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'edit',
                                  'CmName',
                                  store.bookingSolicitor?.FullName || '',
                                  `/view/add-edit-case-manager-2?id=${store.bookingSolicitor?.Id ||
                                    0}&action=edit&step=0&companyId=${store.bookingClient?.Id || 0}`,
                                  store.bookingSolicitor?.Id || 0,
                                  'bookingSolicitor',
                                )
                              }
                            >
                              Edit Solicitor
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={store.bookingSolicitor?.Id || undefined}
                onChange={(value, event) => this.handleChangeObj(value, event, 'bookingSolicitor')}
                onPopupScroll={this.handleScroll('bookingSolicitor')}
                showSearch
                allowClear
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                optionFilterProp="children"
                onSearch={this.handleSearch('bookingSolicitor')}
              >
                {this.itemToArray(store.bookingSolicitor, bookingSolicitors?.itemList || [], 'Id')
                  .filter(i => i.Id)
                  .map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.FullName} - {i.Email}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {!api.isTypist() && <Form.Item label=""></Form.Item>}
            {api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() ? (
              <React.Fragment>
                <Form.Item label="Sub-Booking Client">
                  <Select
                    size="large"
                    value={
                      store.subBookingClientCM
                        ? store.subBookingClientCM.Id !== 0
                          ? store.subBookingClientCM?.Id
                          : undefined
                        : undefined
                    }
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={(value, event) => this.handleChangeObj(value, event, 'subBookingClientCM')}
                    onSearch={this.handleSearch('subBookingClientCM')}
                    onPopupScroll={this.handleScroll('subBookingClientCM')}
                    disabled={api.isCaseManager()}
                  >
                    {subCompanys.itemList &&
                      this.itemToArray(store.subBookingClientCM, subCompanys.itemList, 'Id').map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.Name} - {i.StateName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Sub-Booking CM / Solicitor">
                  <Select
                    size="large"
                    value={store.subBookingSolicitor.map(i => i.Id) || []}
                    mode="multiple"
                    onChange={(value, event) =>
                      this.handleChangeModeMultipleObj(value, event, 'subBookingSolicitor', 3)
                    }
                    showSearch
                    showArrow
                    allowClear
                    disabled={itemModel.isLocked || api.isCaseManager()}
                    optionFilterProp="children"
                    onSearch={this.handleSearch('subBookingSolicitor')}
                    onPopupScroll={this.handleScroll('subBookingSolicitor')}
                  >
                    {listSubBookingCMs.itemList &&
                      listSubBookingCMs.itemList.length &&
                      this.arrayToArray(store.subBookingSolicitor, listSubBookingCMs.itemList, 'Id').map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.FullName} - {i.Email}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </React.Fragment>
            ) : (
              <div />
            )}
            {!api.isDoctor() && (
              <>
                <Form.Item label="Purchase Order No">
                  <Input
                    size="large"
                    value={itemModel.PurchaseOrderNo}
                    // disabled={this.disablePurchase()}
                    disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || lockRule.lockTypist}
                    onChange={this.handleChangeTextInput('PurchaseOrderNo')}
                  />
                </Form.Item>
                <Form.Item label="Authorised Purchase Amount">
                  <InputNumber
                    size="large"
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    // disabled={this.disablePurchase()}
                    disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || lockRule.lockTypist}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={itemModel.AuthorisedPurchaseAmount}
                    onChange={this.handleChangeTextInput('AuthorisedPurchaseAmount')}
                  />
                </Form.Item>
                {itemModel.CustomerId && itemModel.Customer.ClientType === 3 && (
                  <Form.Item label="Approved Disbursement Funding">
                    <Radio.Group disabled value={itemModel.Customer.IsDisbursementFunding}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {itemModel.CustomerId && itemModel.Customer.ClientType === 3 && !api.isTypist() && (
                  <div className="checkbox-field">
                    <Checkbox
                      checked={itemModel.IsRequiredForThisBooking}
                      disabled={api.isDoctor()}
                      onChange={this.handleChangeCheckBox('IsRequiredForThisBooking')}
                    >
                      Use Funding for this Booking
                    </Checkbox>
                  </div>
                )}
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span style={{ marginRight: '10px' }}>Report Receiving Contact 1:</span>
                    </span>
                  }
                >
                  <Select
                    size="large"
                    allowClear
                    value={store.Report_Delivery_Contact_1?.Id || undefined}
                    disabled={lockRule.lockReportComplete_CM || !api.isAdminOrMagStaffOrAccounting()}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_1')}
                    onPopupScroll={this.handleScroll('Report_Delivery_Contact_1')}
                    showSearch
                    optionFilterProp="children"
                    onSearch={this.handleSearch('Report_Delivery_Contact_1')}
                  >
                    {this.itemToArray(store.Report_Delivery_Contact_1, listRDC1CM?.itemList || [], 'Id').map(i => (
                      <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                        {i.Id} - {i.FullName} - {i.Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Report Receiving Contact 2">
                  <Select
                    size="large"
                    value={store.Report_Delivery_Contact_2?.Id || !api.isAdminOrMagStaffOrAccounting()}
                    onChange={(value, event) => this.handleChangeObj(value, event, 'Report_Delivery_Contact_2')}
                    onPopupScroll={this.handleScroll('Report_Delivery_Contact_2')}
                    showSearch
                    allowClear
                    disabled={lockRule.lockReportComplete_CM}
                    optionFilterProp="children"
                    onSearch={this.handleSearch('Report_Delivery_Contact_2')}
                  >
                    {!api.isCaseManager() &&
                      listRDC2CM.itemList.map(i => (
                        <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                          {i.Id} - {i.FullName} - {i.Email}
                        </Select.Option>
                      ))}
                    {api.isCaseManager &&
                      this.itemToArray(store.Report_Delivery_Contact_2, bookingCMs?.itemList || [], 'Id')
                        .filter(i => i.Id)
                        .map(i => (
                          <Select.Option value={i.Id} key={i.Id} data={JSON.stringify(i)}>
                            {i.Id} - {i.FullName} - {i.Email}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </>
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Additional Service Supplier">
                <Select
                  size="large"
                  value={store.AdditionalServiceSupplierObj?.Id || undefined}
                  onChange={(value, event) => this.handleChangeObj(value, event, 'AdditionalServiceSupplierObj')}
                  onPopupScroll={this.handleScroll('AdditionalServiceSupplierObj')}
                  showSearch
                  allowClear
                  disabled={lockRule.lockReportComplete_CM}
                  optionFilterProp="children"
                  onSearch={this.handleSearch('supplier')}
                >
                  {this.itemToArray(store.AdditionalServiceSupplierObj, listSupplier.itemList, 'Id').map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.Title} {i.FullName} - {i.BusinessEmail}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <HeaderWithoutCheck
                title="Claimant Info"
                text={
                  store.claimant
                    ? `${store.claimant.FullName} - DoB: ${renderDate(store.claimant.DOB || '', false, false)}`
                    : ''
                }
              />
            }
          >
            <Form.Item
              required
              validateStatus={!!store.claimant?.Id ? null : 'error'}
              help={!!store.claimant?.Id ? '' : 'This field is required'}
              label={
                <React.Fragment>
                  Claimant
                  {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() =>
                              this.handleOpenAddEdit(
                                'add',
                                'ClaimantName',
                                '',
                                '/view/add-edit-claimant-2',
                                0,
                                'claimant',
                              )
                            }
                          >
                            Add Claimant
                          </Menu.Item>
                          {!!store.claimant?.Id && (
                            <Menu.Item
                              onClick={() =>
                                this.handleOpenAddEdit(
                                  'edit',
                                  'ClaimantName',
                                  `${store.claimant?.FirstName || ''} ${store.claimant?.LastName || ''}`,
                                  `/view/add-edit-claimant-2?id=${store.claimant?.Id || 0}&action=edit&step=0`,
                                  store.claimant?.Id || 0,
                                  'claimant',
                                )
                              }
                            >
                              Edit Claimant
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={store.claimant?.Id || undefined}
                onChange={(value, event) => {
                  this.handleChangeObj(value, event, 'claimant');
                }}
                disabled={itemModel.isLocked}
                showSearch
                allowClear
                optionFilterProp="children"
                onSearch={this.handleSearch('claimant')}
                onPopupScroll={this.handleScroll('claimant')}
              >
                {this.itemToArray(store.claimant, claimants?.itemList || [], 'Id')
                  .filter(i => i.Id)
                  .map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.FirstName} {i.LastName} -{' '}
                      {store.claimant?.DOB ? this.renderDOB(store.claimant.DOB) : ''}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Birth">
              <Input size="large" disabled value={store.claimant?.DOB ? this.renderDOB(store.claimant.DOB) : ''} />
            </Form.Item>
            <Form.Item label="Mobile phone">
              <Input size="large" disabled value={store.claimant?.Telephone || ''} />
            </Form.Item>
            <Form.Item label="Email">
              <Input size="large" disabled value={store.claimant?.Email || ''} />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <HeaderWithoutCheck
                title="Claim No - Claim Type"
                text={itemModel.ClaimNo?.length > 0 ? `Claim No: ${itemModel.ClaimNo}` : ''}
              />
            }
          >
            <Form.Item
              label="Reference/Claim No"
              required
              validateStatus={!!itemModel.ClaimNo ? null : 'error'}
              help={!!itemModel.ClaimNo ? '' : 'This field is required'}
            >
              <Input
                size="large"
                value={itemModel.ClaimNo}
                onChange={this.handleChangeTextInput('ClaimNo')}
                disabled={itemModel.isLocked}
              />
            </Form.Item>
            <Form.Item label="Client File No">
              <Input
                size="large"
                disabled={itemModel.isLocked}
                value={itemModel.ClientRequestNo}
                onChange={this.handleChangeTextInput('ClientRequestNo')}
              />
            </Form.Item>
            <Form.Item
              label="Type of Claim"
              required
              validateStatus={!!store.claimType?.Id ? null : 'error'}
              help={!!store.claimType?.Id ? '' : 'This field is required'}
            >
              <Select
                size="large"
                disabled={itemModel.isLocked}
                value={store.claimType?.Id || undefined}
                onChange={(value, event) => this.handleChangeObj(value, event, 'claimType')}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {claimTypes &&
                  claimTypes.itemList &&
                  store.claimType &&
                  !!itemModel?.ClaimType?.Id &&
                  !claimTypes.itemList.find(i => i.Id === store.claimType?.Id) && (
                    <Select.Option key={itemModel.ClaimType.Id} value={itemModel.ClaimType.Id}>
                      {itemModel.ClaimType.Name}
                    </Select.Option>
                  )}
                {(claimTypes?.itemList || []).map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Type of Report"
              required
              validateStatus={!!store.reportType?.Id ? null : 'error'}
              help={!!store.reportType?.Id ? '' : 'This field is required'}
            >
              <Select
                size="large"
                disabled={itemModel.isLocked}
                value={store.reportType?.Id || undefined}
                onChange={(value, event) => this.handleChangeObj(value, event, 'reportType')}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {reportTypes &&
                  reportTypes.itemList &&
                  !!itemModel?.ReportType?.Id &&
                  store.reportType &&
                  !reportTypes.itemList.find(i => i.Id === store.reportType?.Id) && (
                    <Select.Option key={itemModel.ReportType.Id} value={itemModel.ReportType.Id}>
                      {itemModel.ReportType.Name}
                    </Select.Option>
                  )}
                {(reportTypes?.itemList || []).map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Required Specialty">
              <Select
                size="large"
                disabled={api.isTypist() || api.isDoctor()}
                mode="multiple"
                showSearch
                showArrow
                optionFilterProp="children"
                value={store.bodyParts.map(i => i.Id)}
                allowClear
                onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'bodyParts')}
              >
                {(lookupConfigsParent?.itemList || [])[0]?.map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Required Sub-Specialty"
              required={!!store.bodyParts.find(i => i.Label === 'Orthopaedic Surgeon')}
              validateStatus={
                !!store.bodyParts.find(i => i.Label === 'Orthopaedic Surgeon') &&
                (!store.subBodyParts || store.subBodyParts.length === 0)
                  ? 'error'
                  : null
              }
              help={
                !!store.bodyParts.find(i => i.Label === 'Orthopaedic Surgeon') &&
                (!store.subBodyParts || store.subBodyParts.length === 0)
                  ? 'This field is required'
                  : ''
              }
            >
              <Select
                size="large"
                value={store.subBodyParts.map(i => i.Id)}
                mode="multiple"
                disabled={api.isTypist() || api.isDoctor()}
                showSearch
                showArrow
                allowClear
                optionFilterProp="children"
                onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'subBodyParts')}
              >
                {lookupConfigsChild &&
                  lookupConfigsChild.itemList &&
                  lookupConfigsChild.itemList.length &&
                  lookupConfigsChild.itemList.map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="This specialist only accepts sub-specialty">
              <Input size="large" disabled value={store.specialist?.SubSpecialty || ''} />
            </Form.Item>
            <Form.Item label="Date of Injury">
              <DatePicker
                size="large"
                value={renderDate(itemModel.InjuriedDate)}
                format="DD/MM/YYYY"
                disabled={itemModel.isLocked}
                onChange={value => this.handleChangeDatePicker('InjuriedDate', value)}
              />
            </Form.Item>
            <Form.Item className="fluid-field" label="Injury Description">
              <Input.TextArea
                size="large"
                rows={3}
                value={itemModel.InjuryDescription}
                disabled={itemModel.isLocked}
                onChange={this.handleChangeTextInput('InjuryDescription')}
              />
            </Form.Item>
            <Form.Item label="Employer Of Claimant">
              <Input
                size="large"
                disabled={itemModel.isLocked}
                value={itemModel.EmployerOfClaimant}
                onChange={this.handleChangeTextInput('EmployerOfClaimant')}
              />
            </Form.Item>
            {/* {!(!itemModel.Id && itemModel.SessionId > 0 && itemModel.IsAppointmentConfirmed) && (
              <Form.Item label="Appointment Preference">
                <DatePicker.RangePicker
                  size="large"
                  onChange={value => this.handleChangeDateRangePicker(value, 'ClaimReqDateFrom', 'ClaimReqDateTo')}
                  value={[renderDate(itemModel.ClaimReqDateFrom), renderDate(itemModel.ClaimReqDateTo)]}
                  format="DD/MM/YYYY"
                  disabled={itemModel.isLocked}
                />
              </Form.Item>
            )} */}
            {!(
              (!itemModel.Id && itemModel.SessionId > 0 && itemModel.IsAppointmentConfirmed) ||
              !!store.clinic?.Id
            ) && (
              <Form.Item label="Location">
                <Input
                  size="large"
                  value={itemModel.ClaimReqLocation}
                  onChange={this.handleChangeTextInput('ClaimReqLocation')}
                  disabled={itemModel.isLocked}
                />
              </Form.Item>
            )}
            {!(
              (itemModel.Id === 0 && itemModel.SessionId > 0 && itemModel.IsAppointmentConfirmed) ||
              !!store.specialist?.Id
            ) && (
              <Form.Item label="Specialties">
                <Select
                  size="large"
                  mode="multiple"
                  showSearch
                  showArrow
                  allowClear
                  optionFilterProp="children"
                  value={store.claimReqSpecialty.map(i => i.Id)}
                  onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'claimReqSpecialty')}
                  disabled={itemModel.isLocked}
                >
                  {lookupConfigsParent.itemList &&
                    lookupConfigsParent.itemList.length &&
                    lookupConfigsParent.itemList[0].map(i => (
                      <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                        {i.Label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {!api.isDoctor() && (
              <Form.Item className="fluid-field" label="Inquiry Notes">
                <Input.TextArea
                  disabled={api.isTypist()}
                  rows={3}
                  value={itemModel.RequestNotes}
                  onChange={this.handleChangeTextInput('RequestNotes')}
                />
              </Form.Item>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            header={
              <Header
                title="IRO/ILARS"
                isChecked={itemModel.WifroIlars}
                text={
                  <React.Fragment>
                    IRO Funded{' '}
                    {itemModel.IROFundingApproved && (
                      <span>
                        {' '}
                        - <i aria-hidden="true" className="green check icon"></i> Funding Approved
                      </span>
                    )}
                  </React.Fragment>
                }
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={itemModel.WifroIlars}
                onChange={this.handleChangeCheckBox('WifroIlars')}
                className="checkbox-session"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.Status === 'Billed' ||
                  itemModel.Status === 'Fully Paid' ||
                  itemModel.OldBilled
                }
              >
                Report Funded by IRO/ILARS
              </Checkbox>
            </div>
            <Form.Item>
              <Checkbox
                style={{ marginTop: '13px' }}
                checked={itemModel.IROFundingApproved}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WifroIlars}
                onChange={this.handleChangeCheckBox('IROFundingApproved')}
              >
                IRO Funding Approved
                {this.renderPopup(
                  'Booking can be cancelled if IRO funding approval not provided 7 days prior appointment date (Mail-166)',
                )}
                {!!itemModel.IROFundingApprovedDate && itemModel.IROFundingApproved && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.IROFundingApprovedDate
                        ? renderDate(itemModel.IROFundingApprovedDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.IROFundingApprovedBy ? ` - By ${itemModel.IROFundingApprovedBy}` : ''}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item label={'IRO Funding Approval Date'} required={itemModel.IROFundingApproved}>
              <DatePicker
                size="large"
                value={renderDate(itemModel.IROFundingApprovalDate)}
                format="DD/MM/YYYY"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WifroIlars}
                onChange={value => this.handleChangeDatePicker('IROFundingApprovalDate', value)}
              />
            </Form.Item>
            <FieldUpload
              uploadLoading={store.loadingUpload}
              attachments={store.renderAttachment(itemModel.AssessmentAttachment, 20)}
              label="Attach Funding Approval (Only 1 document allowed)"
              reportType={20}
              disabled={
                api.isDoctor() ||
                api.isTypist() ||
                itemModel.Status === 'Billed' ||
                itemModel.Status === 'Fully Paid' ||
                itemModel.OldBilled ||
                !itemModel.WifroIlars
              }
            />
            <Form.Item label="ILARS Ref">
              <Input
                size="large"
                value={itemModel.IlarsRef}
                onChange={this.handleChangeTextInput('IlarsRef')}
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.Status === 'Billed' ||
                  itemModel.Status === 'Fully Paid' ||
                  itemModel.OldBilled ||
                  !itemModel.WifroIlars
                }
              />
            </Form.Item>
            <Form.Item label="Billing Item Code">
              <Select
                size="large"
                value={itemModel.IMSApprovalCode || undefined}
                onChange={this.handleChangeTextInput('IMSApprovalCode')}
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.Status === 'Billed' ||
                  itemModel.Status === 'Fully Paid' ||
                  itemModel.OldBilled ||
                  !itemModel.WifroIlars
                }
                allowClear
                showSearch
              >
                {(IMSApprovalCodes?.itemList || []).map(({ Id, PaymentClassificationCode, ServiceDescription }) => (
                  <Select.Option key={Id} value={PaymentClassificationCode}>
                    {PaymentClassificationCode} - {ServiceDescription}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="IRO Principal Lawyer">
              <Select
                size="large"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.Status === 'Billed' ||
                  itemModel.Status === 'Fully Paid' ||
                  itemModel.OldBilled ||
                  !itemModel.WifroIlars
                }
                value={itemModel.IlarsWiroSolicitor || undefined}
                onChange={value => this.handleChangeField(value, 'IlarsWiroSolicitor')}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {itemModel.IlarsWiroSolicitorObj &&
                  !(wiroPrincipleLawyers?.itemList || []).find(i => i.Id == itemModel.IlarsWiroSolicitorObj.Id) && (
                    <Select.Option
                      key={itemModel.IlarsWiroSolicitorObj.Id}
                      value={itemModel.IlarsWiroSolicitorObj.Id}
                      style={{ display: 'none' }}
                    >
                      {itemModel.IlarsWiroSolicitorObj.Id} - {itemModel.IlarsWiroSolicitorObj.FullName}
                    </Select.Option>
                  )}
                {(wiroPrincipleLawyers?.itemList || []).map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.FullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>

        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Staff Booking Notes
                {this.renderPopup('Only staff can see/update staff notes.')}
              </React.Fragment>
            }
          >
            <Input.TextArea
              rows={3}
              onChange={this.handleChangeTextInput('ClaimReqBookingComment')}
              value={itemModel.ClaimReqBookingComment}
              disabled={api.isTypist()}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() || api.isDoctor()) && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                CM Booking Notes to Staff/Specialist
                {this.renderPopup('Booking CM can add notes to staff/specialist. Specialist can view notes.')}
              </React.Fragment>
            }
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              rows={3}
              disabled={api.isDoctor()}
              value={itemModel.CMNotes}
              onChange={this.handleChangeTextInput('CMNotes')}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Specialist Notes to Staff
                {this.renderPopup('Specialist can add/update notes. Only staff view specialist notes.')}
              </React.Fragment>
            }
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              rows={3}
              value={itemModel.SpecialistNotes}
              onChange={this.handleChangeTextInput('SpecialistNotes')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default ClaimDetails;
