import customFetch from '../../../utils/customFetch';

export const getClinicInfo = id => {
  return customFetch('/Clinic/ClinicInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getClinicServices = () => {
  return customFetch('/Clinic/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'ClinicService' }),
  });
};

export const getCountryList = () => {
  return customFetch('/Clinic/Country_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getCityList = countryId => {
  return customFetch('/Clinic/City_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ countryId }),
  });
};

export const checkDuplicate = (type, body) => {
  return customFetch(`/Clinic/${type}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const uploadFile = body => {
  return customFetch('/File/UploadFileTemp', {
    method: 'POST',
    body,
  });
};

export const getClinicRooms = id => {
  return customFetch('/Clinic/GetRooms', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId: id }),
  });
};

export const getRegisteredSpecialists = clinicId => {
  return customFetch('/Clinic/GetClinicDoctors', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId }),
  });
};

export const getRegisteredSpecialistDetails = arrId => {
  return customFetch('/MedicalDoctor/GetDoctorByIdList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(arrId),
  });
};

export const getAllSpecialist = body => {
  return customFetch('/MedicalDoctor/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const saveSpecialists = body => {
  return customFetch('/Clinic/UpdateClinicDoctor', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const saveClinic = body => {
  return customFetch('/Clinic/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

//Case Correspondence
export const getStaff = () => {
  return customFetch('/Staff/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ camelCaseResult: true }),
  });
};

export const getStaffAll = (keyword = '', numberStaff = 30) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberStaff,
    }),
  });
};

export const getConversationLog = itemId => {
  return customFetch('/Clinic/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId: itemId }),
  });
};

export const saveConversationLog = body => {
  return customFetch('/Clinic/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const allowCMViewConversationLog = (id, type = 0) => {
  return customFetch('/Clinic/UpdateDoctorAndCMsView', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, type }),
  });
};

export const deleteConversationLog = id => {
  return customFetch('/Clinic/DeleteConversationLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};
