import { observer } from 'mobx-react';
import React from 'react';
import TableToolbar from '../../shared/tableToolbar';
import store from './Store';
import * as api from '../../../stores/api';
import dashboardStore from '@stores/dashboard';

@observer
class Toolbar extends React.Component {
  handleSearchChange = (_, { value }) => {
    store.setFieldsValue({ keyword: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    store.refetch({}, true);
  };

  handleReset = () => {
    store.resetStore();
  };

  handleCreate = () => {
    //return store.toggleModal(true, { modalType: 'update', id: 0 })();
    localStorage.setItem('AddEditFundingReport', 'AddEditFundingReport');
    dashboardStore.close('/view/add-edit-funding-report');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-funding-report?id=${0}`);
    });
  };

  render() {
    const { keyword, loading } = store;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdminOrMagStaffOrAccounting() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton className="positive" disabled={loading} onClick={this.handleCreate}>
              Add New
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default Toolbar;
