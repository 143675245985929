import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AddEditStaffStore from './Store';

function AddEditModal() {
  const { modalType } = AddEditStaffStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AddEditStaffStore} onCancel={AddEditStaffStore.toggleModal(false)} />;
  }
}

export default AddEditModal;
