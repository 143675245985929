import React from 'react';
import * as api from '../../../stores/api';
import CaseProgress from '../../shared/CaseProgress';
import Modal from '../../shared/Modal';
import suppStore from './SuppStore';

function ModalCaseProgress() {
  const { open, modalParams, toggleModal } = suppStore;
  const title = `Case Progress: ${modalParams.caseNo}`;
  const url = '/Supplementary/GetSupplementaryLogs';
  const body = { Id: modalParams.id };

  return (
    <Modal visible={open} onCancel={toggleModal(false)} width={1300} title={title}>
      <CaseProgress progressList={api.SUPP_CaseProgress.data.itemList} url={url} body={body} />
    </Modal>
  );
}

export default ModalCaseProgress;
