import React from 'react';
import { observer } from 'mobx-react';
import { Form, Segment, Message, Icon } from 'semantic-ui-react';
import { notification } from 'antd';

import { convertMobileNumber, convertPhoneFaxNumber } from '@utils/functions';
import { FILE_EXTENSION } from '@utils/constants';
import { uploadFileBase } from '@utils/uploadFileBase';

import './index.scss';

import store from './store';

@observer
class FormRequestNonService extends React.Component {
  formatNumber = value => {
    return !!value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  };

  renderMessage = () => {
    const { data } = store;

    if (data.IsDisbursementFunding) {
      return `Your company has been approved for same-day disbursement funding of $${this.formatNumber(
        (data.DisbursmentFundingAmount || 0).toString(),
      )} from MAG Capital. If you want to access funding, please upload the invoices here:`;
    } else if (data.PreApprovedDisbursementFunding) {
      return `Your company has been pre-approved for same-day disbursement funding of $${this.formatNumber(
        (data.PreApprovedDisbursementFundingAmount || 0).toString(),
      )} from MAG Capital. If you want to access funding, please upload the invoices here or request more information:`;
    } else {
      return 'Kawaconn Capital offers same-day disbursement funding. For more information, click "Submit Request" below.';
    }
  };

  handleUpload = event => {
    const files = event.target.files;
    if (!files || !files.length) {
      return;
    } else {
      const file = files[0];
      const type = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
      const size = file.size / 1024 / 1024;
      if (size > 50) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'The file you have selected is too large. The maximum size is 50MB.',
        });
      } else if (FILE_EXTENSION.indexOf(type) === -1) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'File type not permitted',
        });
      } else {
        const { DisbursementAttachment } = store;
        uploadFileBase(files).then(({ fileList }) => {
          const attachment = {
            FileName: fileList[0]?.name,
            Title: fileList[0]?.title,
            NumberOfPages: fileList[0]?.numberOfPages,
            DisbursmentId: null,
            AttachmentType: 1,
          };
          store.setFieldsValue({
            DisbursementAttachment: [...DisbursementAttachment, attachment].map((i, idx) => ({ ...i, key: idx })),
          });
        });
      }
    }
  };

  render() {
    const { handleChangeValue, DisbursementAttachment, data } = store;

    return (
      <>
        <h3>{this.renderMessage()}</h3>
        <Form>
          <Segment>
            <label style={{ color: 'rgb(0, 0, 0.87', fontSize: '.92857143em', fontWeight: 700, textTransform: 'none' }}>
              Invoice Attachments
            </label>
            <div className="add-file-container-DisbursementFunding">
              {!!DisbursementAttachment.length &&
                DisbursementAttachment.map(i => (
                  <Message className="upload-box" key={i.key}>
                    <div className="file-info">
                      <div className="file-info-inner">
                        <Icon name="file alternate" />
                        <div className="file-name">{i.Title}</div>
                      </div>
                      <div className="file-actions">
                        <Icon name="download" />
                        <Icon name="delete" color="red" onClick={() => this.props.handleRemove(i.key)} />
                      </div>
                    </div>
                  </Message>
                ))}
              <Message className="upload-box">
                <div className="upload-file">
                  <input type="file" onChange={this.handleUpload} />
                  Click here to upload
                </div>
              </Message>
            </div>
            <Form.Group widths="equal">
              <Form.Input
                label="Additional Information"
                value={store.AdditionalInfo || ''}
                placeholder="Additional Information"
                name="AdditionalInfo"
                onChange={handleChangeValue}
              />
            </Form.Group>
            {(data.IsDisbursementFunding || data.PreApprovedDisbursementFunding) && (
              <Form.Group widths="equal">
                <Form.Select
                  required
                  error={!store.TypeOfDisbursementId ? 'This field is required' : null}
                  label="Type of Disbursement"
                  value={store.TypeOfDisbursementId || ''}
                  placeholder="Type of Disbursement"
                  name="TypeOfDisbursementId"
                  options={(store.disbursementType || []).map(i => ({ text: i.Label, value: i.Id }))}
                  search
                  onChange={handleChangeValue}
                />
              </Form.Group>
            )}
            {(store.TypeOfDisbursementId === 2758 || store.TypeOfDisbursementId === 2757) && (
              <Form.Group widths="equal">
                <Form.Input
                  error={!store.OtherDisbursementType ? 'This field is required' : null}
                  label={`Specify ${store.TypeOfDisbursementId === 2758 ? 'disbursement' : 'report'} name`}
                  value={store.OtherDisbursementType || ''}
                  placeholder={`Specify ${store.TypeOfDisbursementId === 2758 ? 'disbursement' : 'report'} name`}
                  name="OtherDisbursementType"
                  onChange={handleChangeValue}
                />
              </Form.Group>
            )}
            <Form.Group widths="equal">
              <Form.Input
                required
                error={!store.legalCompanyName ? 'This field is required' : null}
                label="Company name"
                value={store.legalCompanyName || ''}
                placeholder="Company name"
                name="legalCompanyName"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={!store.contactName ? 'This field is required' : null}
                label="Contact Full Name"
                value={store.contactName || ''}
                placeholder="Contact Full Name"
                name="contactName"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                required
                error={!store.position ? 'This field is required' : null}
                label="Position"
                value={store.position || ''}
                placeholder="Position"
                name="position"
                search
                options={(store.positions || []).map(i => ({ text: i.Label, value: i.Id }))}
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={
                  store.error.email
                    ? 'Please enter a valid email address'
                    : !store.email
                    ? 'This field is required'
                    : null
                }
                label="Email"
                value={store.email || ''}
                placeholder="Email"
                name="email"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                error={
                  store.error.phone
                    ? 'Must be less than or equal to 10 digits'
                    : !store.phone
                    ? 'This field is required'
                    : null
                }
                label="Phone"
                value={convertPhoneFaxNumber(store.phone) || ''}
                placeholder="Phone"
                name="phone"
                onChange={handleChangeValue}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Mobile"
                value={convertMobileNumber(store.mobile) || ''}
                error={store.error.mobile ? 'Must be less than or equal to 10 digits' : null}
                placeholder="Mobile"
                name="mobile"
                onChange={handleChangeValue}
              />
            </Form.Group>
          </Segment>
        </Form>
      </>
    );
  }
}
export default FormRequestNonService;
