import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import './Progress.scss';
import moment from 'moment';
import * as api from '@stores/api';

import store from './store';
import { SUPP_STATUS } from './type';
import ProgressStatus from '../../shared/ProgressStatus/ProgressStatus';
import _ from 'lodash';

const statusGroups = [
  {
    name: 'Booking & Paperwork',
  },
  {
    name: 'Reporting',
  },
  {
    name: 'Billing',
  },
  {
    name: 'Cancellation',
    type: 'radio',
  },
];

@observer
class Progress extends React.Component {
  handleUpdateStatus = newStatus => {
    if (store.supplementaryInfo.Id !== 0) {
      store.toggleModalConfirm(true, {
        modalType: 'confirm',
        onOk: () => store.updateStatus(newStatus),
        message: 'Are you sure to change case status?',
      });
    }
  };

  onClickStatus = (statusItem, itemModel) => {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      return;
    }
    if (api.isAdminOrMagStaffOrAccounting() && itemModel.CancelledChargeStatus === 4) {
      this.handleUpdateStatus(statusItem.id);
    } else {
      if (statusItem.id === 9 || statusItem.id === 8) {
        this.handleUpdateStatus(statusItem.id);
      }
    }
  };

  render() {
    const itemModel = toJS(store.supplementaryInfo);

    let statusLog = toJS(store.suppStatusLogs) || [];

    const caseStatuses = SUPP_STATUS.map(item => {
      var status = _.last(statusLog.filter(i => i.Status === item.key));
      return {
        ...item,
        changeByUser: status?.UserName,
        statusDate: status?.StatusDate,
        createdDate: moment(status?.CreatedDate).unix(),
        label: api.GetSUPPStatusText(item.key),
        checked: status != null,
        isCurrent: itemModel.CaseStatus === item.id,
      };
    });

    let groups = statusGroups.map(g => {
      var group = { ...g };

      var groupTasks = caseStatuses.filter(i => i.group === group.name);
      group.completed = false;
      group.inprogress = false;
      group.totalTask = group.type === 'radio' ? 1 : groupTasks.length;
      group.completedTaskCount = 0;
      group.active = groupTasks.some(i => i.id === itemModel.CaseStatus);
      group.inprogress = groupTasks.some(i => i.checked) || group.active;

      if (group.type === 'radio') {
        var checkedTasks = groupTasks.filter(i => i.checked);

        if (checkedTasks.length > 1) {
          checkedTasks.map(item => {
            item.checked = false;
            return item;
          });
          var latestStatus = _.maxBy(checkedTasks, i => i.createdDate);
          latestStatus.checked = true;
        }

        if (groupTasks.some(i => i.checked)) {
          group.completed = true;
          group.completedTaskCount = 1;
        }
      } else {
        if (groupTasks.every(i => i.checked)) {
          group.completed = true;
        }
        group.completedTaskCount = groupTasks.filter(i => i.checked).length;
      }

      return group;
    });

    return (
      <ProgressStatus
        statusGroups={groups}
        statuses={caseStatuses}
        onClickStatus={itemStatus => this.onClickStatus(itemStatus, itemModel)}
        description={() => (
          <div className="case-info">
            Case Info: <b>{`${store.supplementaryInfo.SuppNo} `}</b>
            {store.supplementaryInfo.Client && (
              <span>
                - Client: <b>{`${store.supplementaryInfo.Client.Name} `}</b>
              </span>
            )}
            {store.supplementaryInfo.CaseManager && (
              <span>
                - Booking CM: <b>{`${store.supplementaryInfo.CaseManager.FullName} `}</b>
              </span>
            )}
            {store.supplementaryInfo.Claimant ? (
              <span>
                - Claimant:{' '}
                <b>{`${store.supplementaryInfo.Claimant.FirstName} ${store.supplementaryInfo.Claimant.LastName} `}</b>
              </span>
            ) : (
              ''
            )}
            {store.supplementaryInfo.AssessedDoctor ? (
              <span>
                - Specialist:{' '}
                <b>{`${store.supplementaryInfo.AssessedDoctor.Title} ${store.supplementaryInfo.AssessedDoctor.FullName}`}</b>
              </span>
            ) : (
              ''
            )}
          </div>
        )}
      />
    );
  }
}

export default Progress;
