import React from 'react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

export default class ModalTemplate extends React.Component {
  onOpenConfirm = () => {
    this.props.onCancel();
  };
  convertStringToPixel = subject => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    ctx.font = '25px Arial';
    let width = Math.ceil(ctx.measureText(subject).width);
    if (width < 500) {
      return 500;
    } else if (width > 1500) {
      return 1500;
    }
    return width;
  };
  render() {
    const { open } = this.props;
    const { template, subject } = this.props.modalParams;
    return (
      <Modal
        visible={open}
        width={this.convertStringToPixel(subject)}
        onCancel={this.onOpenConfirm}
        title={subject}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: template }} />
      </Modal>
    );
  }
}
