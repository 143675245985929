import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import AddEditStore from './store';
import Modal from '../../shared/Modal';
import { TableHeader, TableEmptyMessage } from '../../shared/table';
import { toJS } from 'mobx';

const columnsStaff = [
  { title: 'Previous PIC' },
  { title: 'Current PIC' },
  { title: 'Assigned By' },
  { title: 'Assigned Date' },
];

const columnsClient = [
  { title: 'Previous PIC' },
  { title: 'Current PIC' },
  { title: 'Assigned By' },
  { title: 'Assigned Date' },
];

@observer
class ModalDuplicate extends React.Component {
  renderModalBodyStaff = dataRecentChange => {
    if (Array.isArray(dataRecentChange)) {
      if (dataRecentChange.length === 0) {
        return (
          <Table striped celled>
            <TableHeader columns={columnsStaff} />
            <Table.Body>
              <TableEmptyMessage colSpan={4} />
            </Table.Body>
          </Table>
        );
      }
      return (
        <Table striped celled>
          <TableHeader columns={columnsStaff} />
          <Table.Body>
            {dataRecentChange.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{record.OldAssigneeFullName}</Table.Cell>
                <Table.Cell>{record.NewAssigneeFullName}</Table.Cell>
                <Table.Cell>{record.CreatedUserFullName}</Table.Cell>
                <Table.Cell>{record.CreatedDate}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Table striped celled>
          <TableHeader columns={columnsStaff} />
          <Table.Body>
            <TableEmptyMessage colSpan={4} />
          </Table.Body>
        </Table>
      );
    }
  };
  renderModalBodyClient = dataRecentChange => {
    if (Array.isArray(dataRecentChange)) {
      if (dataRecentChange.length === 0) {
        return (
          <Table striped celled>
            <TableHeader columns={columnsClient} />
            <Table.Body>
              <TableEmptyMessage colSpan={4} />
            </Table.Body>
          </Table>
        );
      }
      return (
        <Table striped celled>
          <TableHeader columns={columnsClient} />
          <Table.Body>
            {dataRecentChange.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{record.OldClientName}</Table.Cell>
                <Table.Cell>{record.NewClientName}</Table.Cell>
                <Table.Cell>{record.CreatedUserFullName}</Table.Cell>
                <Table.Cell>{record.CreatedDate}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Table striped celled>
          <TableHeader columns={columnsClient} />
          <Table.Body>
            <TableEmptyMessage colSpan={4} />
          </Table.Body>
        </Table>
      );
    }
  };

  render() {
    const { open } = AddEditStore;
    const { dataRecentChange, type, record } = AddEditStore.modalParams;
    return (
      <Modal
        visible={open}
        width={900}
        onCancel={AddEditStore.toggleModal(false)}
        title={`${type === 'client' ? 'Recent Change History' : 'PIC Assignment History'} - ${record.FirstName} ${
          record.LastName
        }`}
      >
        {type === 'staff' && this.renderModalBodyStaff(toJS(dataRecentChange))}
        {type === 'client' && this.renderModalBodyClient(toJS(dataRecentChange))}
      </Modal>
    );
  }
}

export default ModalDuplicate;
