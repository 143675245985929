import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';

import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import Store from './Store';

const statusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
];

@observer
class ModalEditTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: this.props.modalParams.record
        ? {
            ...this.props.modalParams.record,
            domain: this.props.modalParams.record.Domain || '',
            domainName: this.props.modalParams.record.DomainName || '',
            isActive: this.props.modalParams.record.IsActive || false,
          }
        : {
            domain: '',
            domainName: '',
            isActive: true,
          },
    };
  }

  renderStatusLabel = () => {
    return statusOptions.map(i => ({
      text: i.text,
      value: i.value,
    }));
  };

  renderStatusLabelText = value => {
    return statusOptions.filter(i => i.value === value).map(i => i.text)[0];
  };

  handleChangeDropdown = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleChangeInput = (_, { name, value }) => {
    this.setState(state => (state.body[name] = value));
  };
  handleSave = async () => {
    const { id } = this.props.modalParams;
    const { body } = this.state;
    if (!body.label) {
    }
    const param = {
      Domain: body.domain,
      DomainName: body.domainName,
      IsActive: body.isActive,
    };
    Store.setFieldsValue({ loading: true });
    try {
      await customFetch('/ExclusionDomain/Save', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Id: id,
          ...param,
        }),
      });
      await Store.refetch({}, true);
    } catch (e) {
    } finally {
      Store.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };
  handleCancel = () => {
    this.props.onCancel();
  };
  render() {
    const { open } = this.props;
    const { id } = this.props.modalParams;
    const { body } = this.state;
    const { loading } = Store;
    const canEdit = isAdmin();

    return (
      <Modal
        visible={open}
        width={1000}
        onCancel={this.handleCancel}
        title={`${id === 0 ? `Create New` : `${body.domainName}`}`}
        footer={
          <React.Fragment>
            <Button className="blue" disabled={loading || !canEdit} onClick={this.handleSave}>
              Save
            </Button>
            <Button className="negative" disabled={loading} onClick={this.handleCancel}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Form>
            <Segment>
              <Dimmer active={loading}>
                <Loader />
              </Dimmer>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  error={!body.domain ? 'This field is required' : null}
                  label="Domain"
                  value={body.domain}
                  placeholder="Domain"
                  name="domain"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  error={!body.domainName ? 'This field is required' : null}
                  label="Domain Name"
                  value={body.domainName}
                  placeholder="Domain Name"
                  name="domainName"
                  onChange={this.handleChangeInput}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Select
                  label="Status"
                  options={this.renderStatusLabel()}
                  name="isActive"
                  onChange={this.handleChangeDropdown}
                  text={this.renderStatusLabelText(body.isActive)}
                />
              </Form.Group>
            </Segment>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default ModalEditTemplate;
