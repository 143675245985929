import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';
import moment from 'moment';
import { notification } from 'antd';
import axios from 'axios';
import { Checkbox, Icon } from 'semantic-ui-react';

import TableToolbar from '../../shared/tableToolbar';

import DRStore from './DRStore';

const { $ } = window;
@observer
class DRToolbar extends React.Component {
  renderMonthOpt = () => {
    const { paymentPeriodMonths } = DRStore;
    return paymentPeriodMonths.map(i => ({
      text: i,
      value: i,
    }));
  };

  renderYearOpt = () => {
    const { paymentPeriodYears } = DRStore;
    return paymentPeriodYears.map(i => ({
      text: i,
      value: i,
    }));
  };

  handledSend = async () => {
    const { monthPeriod, yearPeriod, includeXeroUploadedInvoiceOnly, doctorIdList } = DRStore;
    const { fromDate, toDate } = DRStore.getDateRange();
    const from_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const to_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const to = encodeURI(to_);
    const from = encodeURI(from_);
    DRStore.toggleModal(false, {});
    try {
      await axios
        .post('/DoctorCommissionRemittanceList/SendCommissionRemittanceStatementToDoctor', {
          doctorIds: doctorIdList.map(i => i),
          includeXeroUploadedInvoiceOnly: includeXeroUploadedInvoiceOnly,
          invoiceDateFrom: from,
          invoiceDateTo: to,
          notificationReceiverId: $.connection.hub.id,
          paymentPeriod: monthPeriod + ' ' + yearPeriod,
        })
        .then(res => {
          if (res.statusText === `OK`) {
            notification.destroy();
            notification.success({
              message: 'Success',
              description: `Remittance sent successfully`,
              duration: 2,
              placement: `bottomRight`,
            });
          }
        });
    } catch (e) {
      DRStore.toggleModal(true, {
        modalType: `confirm`,
        message:
          'Error occured while contacting server. Please report this error to system administrator and try again later.',
      });
    }
  };

  handleSendStatements = () => {
    const { monthPeriod, yearPeriod, doctorIdList } = DRStore;
    if (doctorIdList.length === 0) {
      DRStore.toggleModal(true, {
        modalType: `confirm`,
        message: `Please select at least 1 specialist`,
      })();
    } else {
      const { fromDate, toDate } = DRStore.getDateRange();
      const from_ = fromDate
        ? moment(fromDate, 'DD/MM/YYYY')
            .startOf(`day`)
            .toISOString()
        : '';
      const to_ = toDate
        ? moment(toDate, 'DD/MM/YYYY')
            .endOf(`day`)
            .toISOString()
        : '';
      DRStore.toggleModal(false, {});
      if (!from_) {
        return DRStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please Select Provider Payment From Date',
        })();
      } else if (!to_) {
        return DRStore.toggleModal(true, {
          modalType: 'confirm',
          message: 'Please Select Provider Payment To Date',
        })();
      } else {
        DRStore.toggleModal(true, {
          modalType: `confirm`,
          message: `You are about to send commission statements for period ${monthPeriod} ${yearPeriod} to ${doctorIdList.length} specialist. Click 'Yes' to proceed.`,
          onOk: () => this.handledSend(),
        })();
      }
    }
  };

  handleExportDoctor = () => {
    const { keyword, includeXeroUploadedInvoiceOnly, paymentDate } = DRStore;
    const { fromDate, toDate } = DRStore.getDateRange();
    const from_ = fromDate
      ? moment(fromDate, 'DD/MM/YYYY')
          .startOf(`day`)
          .toISOString()
      : '';
    const to_ = toDate
      ? moment(toDate, 'DD/MM/YYYY')
          .endOf(`day`)
          .toISOString()
      : '';
    const paymentDate_ = moment().toISOString();
    const from = encodeURI(from_);
    const to = encodeURI(to_);
    const pDate = encodeURI(paymentDate_);

    window.open(
      '/DoctorCommissionRemittanceList/ExportDoctorRemittanceList?fullName=' +
        keyword +
        '&invoiceDateFrom=' +
        from +
        '&invoiceDateTo=' +
        to +
        '&excludeReferringSolicitor=' +
        true +
        '&includeXeroUploadedInvoiceOnly=' +
        includeXeroUploadedInvoiceOnly +
        '&excludeCreditedInvoices=' +
        true +
        '&paymentDate=' +
        pDate,
      '_blank',
    );
  };

  handleBatchUpdatePayments = () => {
    return DRStore.toggleModal(true, { modalType: 'updatePayment' })();
  };

  handleChangeSearchDropdown = (_, { searchQuery }) => {
    DRStore.refetchSearch({
      keywordSearch: searchQuery,
      keyword: searchQuery,
    });
  };

  handleViewCheckedPayments = () => {
    DRStore.setFieldsValue({ isView: true });
    return DRStore.toggleModal(true, { modalType: 'paymentDate' })();
  };

  handleSendStatements = () => {
    DRStore.setFieldsValue({ isView: false });
    return DRStore.toggleModal(true, { modalType: 'paymentDate' })();
  };

  renderSearchOpt = () => {
    const array = [];
    const data = toJS(DRStore.dataDoctor.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    return array.map(opt => ({
      text: `${opt.Id === `All` ? `All` : `${opt.Id || ``} ${opt.FullName || ``}`}`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FullName || ''}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const doctor = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (doctor.id === `All` || value === `All` || value === '') {
      DRStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      DRStore.resetSearch();
    } else {
      DRStore.refetch(
        {
          keyword: doctor.value,
          curPage: 1,
          numberSearch: 30,
          keywordSearch: '',
          doctorId: doctor.id,
        },
        true,
      );
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(DRStore.dataDoctor.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    let labels = array.filter(opt => opt.Id === value).map(opt => ` ${opt.Id} ${opt.FullName || ``}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleScroll = event => {
    const target = event.target;
    const number = DRStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      DRStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  handleChangePeriod = (_, { name, value }) => {
    DRStore.setFieldsValue({
      [name]: value,
    });

    const { monthPeriod, yearPeriod } = DRStore;
    const month = moment(`${monthPeriod} ${yearPeriod}`, 'MMM YYYY');
    const start = month.startOf('month').format('DD/MM/YYYY');
    const end = month.endOf('month').format('DD/MM/YYYY');
    this.handleChangeFromTo(null, { value: `${start} - ${end}` });
  };

  handleChecked = () => {
    const checked = DRStore.includeXeroUploadedInvoiceOnly;
    DRStore.refetch({ includeXeroUploadedInvoiceOnly: !checked });
  };

  handleReset = () => {
    DRStore.refetch({});
  };

  handleChangeFromTo = (_, { value }) => {
    return DRStore.refetch({ fromTo: value });
  };

  render() {
    const {
      loading,
      doctorId,
      loadingDoctor,
      includeXeroUploadedInvoiceOnly,
      fromTo,
      monthPeriod,
      yearPeriod,
      keywordSearch,
      totalRemittanceAmout,
    } = DRStore;

    return (
      <form className="doctor-remittance-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <Checkbox
              label="
                Including Xero uploaded invoices only"
              onChange={this.handleChecked}
              checked={includeXeroUploadedInvoiceOnly}
              disabled={loading}
              style={{ marginRight: `5px` }}
            />
            <TableToolbar.SearchDropdown
              loading={loadingDoctor}
              disabled={loading}
              selectOnNavigation={false}
              clearable
              selectOnBlur={false}
              search
              onScroll={this.handleScroll}
              icon={!doctorId ? <Icon name="dropdown" /> : <Icon name="delete" />}
              searchQuery={keywordSearch}
              value={this.renderSearchText(doctorId)}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderSearchOpt()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.RangePicker
              placeholder="Payment date from - to"
              // className={`Toolbar-range-picker${!fromDate && '-error'}`}
              className="Toolbar-range-picker"
              disabled={loading}
              value={fromTo}
              onChange={this.handleChangeFromTo}
            />
            <div className="form-totalRemittanceAmout">
              <label>Total Checked Amount (incl GST)</label>
              <TableToolbar.Input
                className="totalRemittanceAmout"
                placeholder={'Search job title'}
                value={totalRemittanceAmout}
                disabled={true}
              />
            </div>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
        {/* {!fromDate && (
          <Label
            color="red"
            className="Field-Label-warning-date-range-picker"
            pointing
          >
            Please select a date range
          </Label>
        )} */}
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <span style={{ marginRight: `10px` }}>Payment Period:</span>
            <TableToolbar.Filter
              options={this.renderMonthOpt()}
              value={monthPeriod}
              disabled={loading}
              label="Month"
              name="monthPeriod"
              onChange={this.handleChangePeriod}
            />
            <TableToolbar.Filter
              options={this.renderYearOpt()}
              value={yearPeriod}
              label="Year"
              name="yearPeriod"
              disabled={loading}
              onChange={this.handleChangePeriod}
            />
            <TableToolbar.HighLightButton disabled={loading} className="blue" onClick={this.handleSendStatements}>
              Send Remittance To Checked Specialists
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="positive"
              style={{ marginLeft: `5px` }}
              onClick={this.handleExportDoctor}
              disabled={loading}
            >
              Export Remittance List
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="positive"
              style={{ marginLeft: `5px` }}
              onClick={this.handleViewCheckedPayments}
              disabled={loading}
            >
              View Checked Statements
            </TableToolbar.HighLightButton>
            {/* <TableToolbar.HighLightButton
              className="positive"
              style={{ marginLeft: `5px` }}
              onClick={this.handleBatchUpdatePayments}
              disabled={loading}
            >
              Batch Update Payments
            </TableToolbar.HighLightButton> */}
            <TableToolbar.HighLightButton
              disabled={loading}
              style={{ marginLeft: `5px` }}
              onClick={this.handleReset}
              className="blue"
            >
              Refresh
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
      </form>
    );
  }
}

export default DRToolbar;
