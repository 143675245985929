import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class JobTitleStore {
  @observable loading = false;
  @observable loadingAcc = true;
  @observable dataAcc = null;

  @observable activeIdx = 0;
  @observable type = null;

  @observable qualifiedAccreditations = [];

  @observable dataReport = {
    id: 0,
    name: '',
    descriptions: '',
    isActive: false,
    periodDays: 0,
    qualifiedAccreditations: [],
  };

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchDetail = () => {
    this.loadingAcc = true;
    customFetch('/TypesOfReport/GetSpecificLookupConfigByType', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        type: 'acc',
      }),
    }).then(
      action(data => {
        this.dataAcc = data;
        this.loadingAcc = false;
      }),
    );
  };
  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action onReset = () => {
    this.dataReport = {
      id: 0,
      name: '',
      descriptions: '',
      isActive: false,
      periodDays: 0,
      qualifiedAccreditations: [],
    };
  };
}

export default new JobTitleStore();
