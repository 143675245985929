import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Modal from '../../shared/Modal';

function ModalConfirm({ open, onCancel, hideWarningIcon, functionCallbackCancel, ...props }) {
  const renderConfirmMessage = () => {
    return (
      <div className="modal-confirm-message d-flex">
        {!hideWarningIcon && <Icon name="warning sign" color="yellow" />}
        <div>
          <p>{props.message}</p>
        </div>
      </div>
    );
  };

  const handleCancel = () => {
    onCancel();
    return functionCallbackCancel && functionCallbackCancel();
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={handleCancel}
      footer={
        <React.Fragment>
          {props.onOk && (
            <Button
              color="blue"
              onClick={() => {
                props.onOk();
                onCancel();
              }}
            >
              <Icon name="check" /> Yes
            </Button>
          )}
          <Button color="red" onClick={handleCancel}>
            <Icon name="close" /> {props.cancelText || 'No'}
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalConfirm;
