import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import MedicalCentresStore from './MedicalCentresStore';
import ModalActionNotes from './ModalActionNotes';

function MedicalCentresModal() {
  const { modalType } = MedicalCentresStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...MedicalCentresStore} onCancel={MedicalCentresStore.toggleModal(false)} />;
  }

  if (modalType === 'notes') {
    return <ModalActionNotes {...MedicalCentresStore} onCancel={MedicalCentresStore.toggleModal(false)} />;
  }
}

export default MedicalCentresModal;
