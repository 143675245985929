import React from 'react';
import { Button, Divider, Form, Header, Icon } from 'semantic-ui-react';
import moment from 'moment';

import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import InvoicesStore from './InvoicesStore';
import TableToolbar from '../../shared/tableToolbar';
import ModalNotification from './ModalNotification';

class ModalXero extends React.Component {
  state = {
    openModalNotification: false,
    message: `Request sent. This is a long-running task, you will see results in the Notifications box (below account name).`,
    checkedClaimtOnly: true,
    type: null,
    date: '',
  };

  handleSubmit = e => {
    const { id } = InvoicesStore.modalParams;
    e.preventDefault();
    const time = moment().format('HH:mm:ss');
    const date = moment(
      moment(this.state.date, `DD/MM/YYYY`).format(`YYYY-MM-DD`) + time,
      'YYYY-MM-DD hh:mm:ss',
    ).toISOString();
    if (!date) {
      this.setState({
        openModalNotification: true,
        message: `Invalid date range.`,
      });
    } else {
      customFetch('/invoice/UploadToXero', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          invoiceId: id,
          xeroInvoiceDate: date,
        }),
      }).then(() => InvoicesStore.toggleModal(false, {})());
    }
  };

  handleChangeFromTo = (_, { value }) => {
    this.setState({ date: value });
  };

  renderModal = () => {
    const { message, openModalNotification } = this.state;
    return (
      <ModalNotification
        open={openModalNotification}
        message={message}
        onCancel={() => this.setState({ openModalNotification: false })}
      />
    );
  };

  render() {
    const { open, toggleModal } = InvoicesStore;
    const { date } = this.state;
    const { id } = InvoicesStore.modalParams;
    return (
      <Modal
        visible={open}
        width={600}
        onCancel={toggleModal(false)}
        footer={
          <React.Fragment>
            {this.renderModal()}
            <Button onClick={this.handleSubmit} color="blue">
              Upload
            </Button>
            <Button className="negative" onClick={toggleModal(false)}>
              <Icon name="delete" />
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div className="ant-modal-addOrEdit">
          <Form size={'small'}>
            <Form.Field>
              <Header size="medium">Invoice No {id} - Upload to Xero</Header>
              <Divider />
              <TableToolbar.DatePicker
                value={date}
                placeholder={`Xero Invoice Date`}
                dateFormat={'DD/MM/YYYY'}
                onChange={this.handleChangeFromTo}
                className="Toolbar-range-picker"
              />
            </Form.Field>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ModalXero;
