import { notification, Spin } from 'antd';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../../shared/Modal';
import CMReportStore from './CMReportStore';
import customFetch from '../../../utils/customFetch';

class ModalImport extends React.Component {
  state = { files: null, errorMessage: '' };

  uploadFileTemplate = body => {
    if (CMReportStore.modalParams.type === 1) {
      return customFetch('/ExportImport/UploadFileImportCaseManager', {
        method: 'POST',
        body,
      });
    } else {
      return customFetch('/ExportImport/UploadCMAccountActivation', {
        method: 'POST',
        body,
      });
    }
  };
  handleUpload = event => {
    const files = event.target.files;
    if (!files || !files.length) {
      return;
    } else {
      const file = files[0];
      const type = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'The file you have selected is too large. The maximum size is 5MB.',
        });
      } else if (type === 'xls' || type === 'xlsx') {
        CMReportStore.setLoadingUpload(true);
        const formData = new FormData();
        formData.append(files[0].name, files[0]);
        this.uploadFileTemplate(formData)
          .then(res => {
            if (CMReportStore.modalParams.type === 1) {
              if (res.status === 'success') {
                notification.destroy();
                notification.success({
                  message: 'Success',
                  description: 'Upload file successfully',
                });
                CMReportStore.setLoadingUpload(false);
                //return CMReportStore.toggleModal(false, {})();
              } else {
                notification.destroy();
                return notification.error({
                  message: 'Error',
                  description: 'Upload file failed',
                });
              }
            } else {
              if (res.status === 'success') {
                CMReportStore.setLoadingUpload(false);
                notification.success({
                  message: 'Success',
                  description: 'Upload file successfully',
                });
                window.open(res.FilePath, '_blank');
                //return CMReportStore.toggleModal(false, {})();
              } else {
                CMReportStore.setLoadingUpload(false);
                if (res.Error === 'TemplateInvalid') {
                  notification.destroy();
                  return notification.error({
                    message: 'Error',
                    description: 'Please check upload file format',
                  });
                } else {
                  if (res.FilePath) {
                    window.open(res.FilePath, '_blank');
                  }
                  notification.destroy();
                  return notification.error({
                    message: 'Error',
                    description: 'Upload file failed',
                  });
                }
              }
            }
          })
          .catch(() => {});
      } else {
        notification.destroy();
        return notification.error({
          message: 'Error',
          description: 'File type not permitted',
        });
      }
    }
  };

  handleDowloadTemplate = () => {
    window.open('/ExportImport/DownloadCaseManagerImportTemplate', '_blank');
  };
  onCancel = () => {
    return CMReportStore.toggleModal(false, {})();
  };
  render() {
    const { toggleModal, open, modalParams, loadingUpload } = CMReportStore;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={toggleModal(false)}
          title={modalParams.type === 1 ? `Import Case Managers` : `Activate CM accounts`}
          footer={
            <React.Fragment>
              <Button className="negative" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
        >
          {!this.state.files || !this.state.files.length ? (
            <div className="Modal-UploadProfilePicture">
              <div className="mb-1">
                <p>You can upload one .XLSX file only. Max file size is 500 records.</p>
                {modalParams.type !== 1 && (
                  <p>
                    (CM list should have first column for emails<span style={{ color: 'red' }}>*</span>, second for
                    FirstName. Once uploaded, accounts will be activated immediately - MAIL-27)
                  </p>
                )}
                {modalParams.type === 1 && (
                  <label
                    className="Button"
                    style={{ marginRight: '10px', minWidth: '250px' }}
                    onClick={this.handleDowloadTemplate}
                  >
                    <Icon name="download" />
                    <span className="title">Download Template</span>
                  </label>
                )}
                <label className="Button" style={{ minWidth: '250px' }}>
                  <Icon name="upload" />
                  <span className="title-download">
                    {' '}
                    {modalParams.type === 1 ? <span>Upload Case Managers</span> : <span>Upload CM accounts</span>}{' '}
                  </span>
                  <input hidden type="file" onChange={this.handleUpload} />
                </label>
              </div>
            </div>
          ) : null}
          <div className={loadingUpload ? 'uploadcm-loading' : ''}>
            {loadingUpload && <Spin style={{ margin: '0 auto' }} size="large" />}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default ModalImport;
