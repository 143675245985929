import Validator from 'validatorjs';

const messages = Validator.getMessages('en');
Object.keys(messages).forEach(k => {
  messages[k] = k[0].toUpperCase() + k.slice(1);
});

Validator.register(
  'password',
  v => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v),
  'Password',
);
