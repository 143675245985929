import { action, observable, toJS } from 'mobx';
import customFetch from '../../utils/customFetch';
import * as api from '../../stores/api';

const DoctorTitle = ['Dr', 'A.Prof', 'Professor', 'Mr', 'Ms'];

const State = [
  { id: '108', label: 'ACT' },
  { id: '109', label: 'NSW' },
  { id: '110', label: 'VIC' },
  { id: '111', label: 'QLD' },
  { id: '112', label: 'SA' },
  { id: '113', label: 'WA' },
  { id: '114', label: 'TAS' },
  { id: '115', label: 'NT' },
];

class RegisterStore {
  @observable loading = false;
  @observable registerSuccessfully = false;

  @observable ddlTitle = [];
  @observable ddlState = [];
  @observable ddlConsultingState = [];
  @observable ddlSpecialty = [];
  @observable ddlSubSpecialty = [];
  @observable ddlQualifications = [];
  @observable ddlAccreditations = [];
  @observable ddlAcceptedReportTypes = [];
  @observable ddlConsultingLocations = [];

  @observable specialty = [];
  @observable subSpecialty = [];
  @observable qualifications = [];
  @observable accreditations = [];
  @observable consultingLocations = [];
  @observable acceptedReportTypes = [];
  @observable consultingState = [];

  @observable contentErrorTitle = '';
  @observable contentErrorFirstName = '';
  @observable contentErrorLastName = '';
  @observable contentErrorEmail = '';
  @observable contentErrorLinkedIn = '';
  @observable contentErrorVideoLink = '';

  @observable firstName = '';
  @observable lastName = '';
  @observable email = '';
  @observable phone = '';
  @observable mobile = '';
  @observable fax = '';
  @observable address = '';
  @observable suburb = '';
  @observable postCode = '';
  @observable state = '';
  @observable briefProfile = '';
  @observable preferenceWork = '';
  @observable title = '';
  @observable linkedIn = '';
  @observable videoLink = '';
  @observable bookingsAndCancellationTerms = '';

  @action Clear = () => {
    this.contentErrorTitle = '';
    this.contentErrorFirstName = '';
    this.contentErrorLastName = '';
    this.contentErrorEmail = '';
    this.contentErrorState = '';

    this.loading = false;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.mobile = '';
    this.fax = '';
    this.title = '';
    this.specialty = [];
    this.registerSuccessfully = false;
  };

  @action renderOptions = () => {
    this.ddlState = State.map(opt => ({
      key: opt.id,
      text: opt.label,
      value: opt.id,
    }));

    this.ddlConsultingState = State.map(opt => ({
      key: opt.label,
      text: opt.label,
      value: opt.label,
    }));

    this.ddlTitle = DoctorTitle.map(opt => ({
      key: opt,
      text: opt,
      value: opt,
    }));

    customFetch('/MedicalDoctor/GetLookUpConfig', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ type: 'doctor' }),
    }).then(
      action(data => {
        this.ddlSpecialty = data.speItemList.map(opt => ({
          key: opt.Id,
          text: opt.Label,
          value: opt.Id,
        }));
        this.ddlQualifications = data.quaItemList.sort().map(opt => ({
          key: opt,
          text: opt,
          value: opt,
        }));
        this.ddlAccreditations = data.accreditationList.sort().map(opt => ({
          key: opt.Id,
          text: opt.Label,
          value: opt.Id,
        }));
      }),
    );

    customFetch('/MedicalDoctor/GetActiveReportTypeList', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }).then(
      action(data => {
        this.ddlAcceptedReportTypes = data.itemList.map(opt => ({
          key: opt.Id,
          text: opt.Name,
          value: opt.Id,
        }));
      }),
    );
  };

  @action onValidateElement = () => {
    if (!this.title) {
      this.contentErrorTitle = 'Please select at least one title for this user';
      return false;
    } else {
      this.contentErrorTitle = '';
    }

    if (!this.firstName) {
      this.contentErrorFirstName = 'Please enter first name';
      return false;
    } else {
      this.contentErrorFirstName = '';
    }

    if (!this.lastName) {
      this.contentErrorLastName = 'Please enter last name';
      return false;
    } else {
      this.contentErrorLastName = '';
    }

    if (!this.email) {
      this.contentErrorEmail = 'Please enter email';
      return false;
    } else {
      this.contentErrorEmail = '';
    }

    if (!this.validateEmail(this.email)) {
      this.contentErrorEmail = 'Please enter valid email';
      return false;
    } else {
      this.contentErrorEmail = '';
    }

    if (this.videoLink && !this.validURL(this.linkedIn)) {
      this.contentErrorLinkedIn = 'Please enter valid link';
      return false;
    } else {
      this.contentErrorLinkedIn = '';
    }

    if (this.videoLink && !this.validURL(this.videoLink)) {
      this.contentErrorVideoLink = 'Please enter valid link';
      return false;
    } else {
      this.contentErrorVideoLink = '';
    }

    if (!this.state) {
      this.contentErrorState = 'Please select a state';
      return false;
    } else {
      this.contentErrorState = '';
    }

    return true;
  };

  @action onSearchConsulting = (e, data) => {
    customFetch(`/api/auspostSuburb?q=${data.searchQuery}&excludePostBoxFlag=true`).then(
      action(data => {
        this.ddlConsultingLocations = data.map(opt => ({
          key: opt.id + '|' + opt.location + '|' + opt.postcode,
          text: opt.location + '|' + opt.postcode,
          value: opt.id + '|' + opt.location + '|' + opt.postcode,
        }));
      }),
    );
  };

  @action onChangeElement = (e, data) => {
    this[data.name] = data.value;

    if (data.name === 'specialty') {
      customFetch('/MedicalDoctor/GetLookupConfigByParents', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          parents: this.specialty.map(value => {
            return {
              Id: value,
            };
          }),
        }),
      }).then(
        action(data => {
          this.ddlSubSpecialty = data.itemList.map(opt => ({
            key: opt.Id,
            text: opt.Label,
            value: opt.Id,
          }));
        }),
      );
    }

    this.onValidateElement();
  };

  @action handleChange = ({ target }) => {
    if (target.name === 'phone' || target.name === 'mobile') {
      if (target.value.length > 10) {
        return;
      }
    }

    this[target.name] = target.value;
    this.onValidateElement();
  };

  @action RegisterDoctor = () => {
    if (!this.onValidateElement()) {
      return;
    }
    var model = {
      Title: this.title,
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      Phone: this.phone,
      Mobile: this.mobile,
      Fax: this.fax,
      Address: this.address,
      Suburb: this.suburb,
      PostCode: this.postCode,
      State: this.state,
      BriefProfile: this.briefProfile,
      PreferenceWork: this.preferenceWork,
      LinkedIn: this.linkedIn,
      VideoLink: this.videoLink,
      BookingsAndCancellationTerms: this.bookingsAndCancellationTerms,
      ConsultingState: toJS(this.consultingState),
      Specialty: toJS(this.specialty),
      SubSpecialty: toJS(this.subSpecialty),
      Qualifications: toJS(this.qualifications),
      Accreditations: toJS(this.accreditations),
      ConsultingLocations: toJS(this.consultingLocations),
      AcceptedReportTypes: toJS(this.acceptedReportTypes),
    };
    this.loading = true;
    api.registerDoctor.post(model, () => {
      this.loading = false;
      this.registerSuccessfully = true;
    });
  };

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  validURL = str => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(str);
  };
}

export default new RegisterStore();
