import { initHelpDeskSystem } from '@utils/helpDeskSystem';
import { observer } from 'mobx-react';
import React from 'react';

import * as api from '../../stores/api';
import Login from '../anonymous-routes/Login';
import Anonymous from '../anonymous/Anonymous';
import AnonymousLoading from '../anonymous/AnonymousLoading';
import Dashboard from './Dashboard';

@observer
class WithGuard extends React.Component {
  render() {
    const { data } = api.currentUser;
    if (!data) {
      return <Anonymous Component={Login} />;
    }

    if (api.isAdmin()) {
      initHelpDeskSystem(api.currentUser?.data);
    }
    if (
      api.isTypist() ||
      api.isCaseManager() ||
      api.isTourist() ||
      api.isDoctor() ||
      api.isAdminOrMagStaffOrAccounting()
    ) {
      return <Dashboard />;
    }

    return (
      <div className="loading-container">
        <AnonymousLoading message="Redirecting" />
      </div>
    );
  }
}

export default WithGuard;
