import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import CaseManagersModal from './CaseManagersModal';
import CaseManagersStore from './CaseManagersStore';
import CaseManagersTable from './CaseManagersTable';
import CaseManagersToolbar from './CaseManagersToolbar';
import ui from '../../../stores/dashboard';
import router from '../../../stores/router';

class CaseManagers extends React.Component {
  state = {
    companyId: 0,
  };
  componentDidMount() {
    CaseManagersStore.fetchClient();
    ui.on('open(/view/case-managers-2)', this._searchDebounced);
    this._searchDebounced();
  }

  _searchDebounced = debounce(() => {
    const { search } = router.location;
    if (search) {
      if (search.indexOf('?clientId=') !== -1) {
        const id = search.slice(10);
        return this.setState({ companyId: id }, () =>
          CaseManagersStore.refetch(
            {
              companyId: id,
              sortKey: 'Id',
              sortColumn: 'ID',
              sortDirection: 'descending',
            },
            true,
          ),
        );
      }
    } else {
      const { companyId } = this.state;
      if (companyId === 0) {
        localStorage.removeItem('ManageCmName');
        return CaseManagersStore.refetch({ companyId: 0 }, true);
      }
    }
    return CaseManagersStore.fetchCase(true);
  }, 1000);

  render() {
    const { loading, data, open, loadingSearch, dataSearch, loadingClient, dataClient } = CaseManagersStore;
    return (
      <div
        className={`Table-${
          (loading && !data) || (loadingSearch && !dataSearch) || (loadingClient && !dataClient)
            ? 'loading'
            : 'container'
        }`}
      >
        {(loading && !data) || (loadingSearch && !dataSearch) || (loadingClient && !dataClient) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <CaseManagersToolbar />
            <CaseManagersTable />
            {open && <CaseManagersModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(CaseManagers);
