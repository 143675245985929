import { action, observable } from 'mobx';
import moment from 'moment';

import { getListCampaignScheduleHistory } from './service';

class Store {
  @observable loading = false;
  @observable data = null;
  @observable campaignId = null;
  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };
  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : '',
    };
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.fetchCampaignHistory();
  };

  @action fetchCampaignHistory = (setTotalPage = true) => {
    this.loading = true;
    Promise.all([getListCampaignScheduleHistory(this.campaignId, this.curPage, 20)]).then(
      action(([listCampaignHistory]) => {
        this.data = listCampaignHistory;
        this.loading = false;
        this.totalPage = !!listCampaignHistory.sumPage ? listCampaignHistory.sumPage : this.totalPage;
        this.totalItems = !!listCampaignHistory.sumItem ? listCampaignHistory.sumItem : this.totalItems;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.open = isOpen;
    this.modalParams = modalParams;
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage = true) => {
    this.loading = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getListCampaignScheduleHistory(this.campaignId, this.curPage, 20)]).then(
      action(([listCampaignHistory]) => {
        this.data = listCampaignHistory;
        this.loading = false;
        this.totalPage = !!listCampaignHistory.sumPage ? listCampaignHistory.sumPage : this.totalPage;
        this.totalItems = !!listCampaignHistory.sumItem ? listCampaignHistory.sumItem : this.totalItems;
      }),
    );
  };

  @action resetStore = () => {
    this.campaignId = null;
    this.curPage = 1;
    this.totalPage = 0;

    this.refetch({}, true);
  };
}

export default new Store();
