import * as api from '@stores/api';

export const MAG_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Internet search',
    value: '3',
  },
  {
    label: 'Colleague',
    value: '4',
  },
  {
    label: 'Word of mouth',
    value: '5',
  },
  {
    label: 'Others',
    value: '6',
  },
];

export const WIRO_INVOICE_TYPE = ['None', 'Wiro', 'WiroSolicitor', 'NonWiro'];

export const DOCTOR_QUESTIONS = [
  {
    label: 'Newsletter',
    value: '1',
  },
  {
    label: 'Email',
    value: '2',
  },
  {
    label: 'Previously used specialist',
    value: '3',
  },
  {
    label: 'Others',
    value: '4',
  },
];

export const conversationTypes = [
  {
    label: 'Phone',
    value: 0,
    visible: true,
  },
  {
    label: 'Email',
    value: 1,
    visible: true,
  },
  {
    label: 'Email (Send Now)',
    value: 4,
    visible: false,
  },
  {
    label: 'Private Notes',
    value: 5,
    visible: true,
  },
  {
    label: 'Relationship Meeting',
    value: 2,
    visible: true,
  },
  {
    label: 'Relationship Call',
    value: 8,
    visible: true,
  },
  {
    label: 'Relationship Email',
    value: 9,
    visible: true,
  },
  {
    label: 'Others',
    value: 3,
    visible: true,
  },
];

export const FR_CASE_PROGRESS = [
  {
    id: api.frStatusEnum.REPORT_REVIEW_REQUESTED,
    key: api.frStatus.REPORT_REVIEW_REQUESTED,
    group: 'Booking & Paperwork',
  },
  {
    id: api.frStatusEnum.REQUEST_SENT_PROVIDER,
    key: api.frStatus.REQUEST_SENT_PROVIDER,
    group: 'Booking & Paperwork',
  },
  {
    id: api.frStatusEnum.DICTATION_RECEIVED,
    key: api.frStatus.DICTATION_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.frStatusEnum.REPORT_DRAFT_RECEIVED,
    key: api.frStatus.REPORT_DRAFT_RECEIVED,
    group: 'Reporting',
  },
  {
    id: api.frStatusEnum.REPORT_RETURNED_FROM_DOCTOR,
    key: api.frStatus.REPORT_RETURNED_FROM_DOCTOR,
    group: 'Reporting',
  },
  {
    id: api.frStatusEnum.REPORT_COMPLETED,
    key: api.frStatus.REPORT_COMPLETED,
    group: 'Reporting',
  },
  {
    id: api.frStatusEnum.CANCELLED_NO_FEE,
    key: api.frStatus.CANCELLED_NO_FEE,
    group: 'Cancellation',
  },
  {
    id: api.frStatusEnum.CANCELLED_WITH_FEE,
    key: api.frStatus.CANCELLED_WITH_FEE,
    group: 'Cancellation',
  },
  {
    id: api.frStatusEnum.CLIENT_INVOICE,
    key: api.frStatus.CLIENT_INVOICE,
    group: 'Billing',
  },
  {
    id: api.frStatusEnum.DOCTOR_INVOICE,
    key: api.frStatus.DOCTOR_INVOICE,
    group: 'Billing',
  },
  // {
  //   id: api.frStatusEnum.SERVICE_BILLED,
  //   key: api.frStatus.SERVICE_BILLED,
  //   group: 'Billing',
  // },
  {
    id: api.frStatusEnum.SERVICE_PAID,
    key: api.frStatus.SERVICE_PAID,
    group: 'Billing',
  },
];

export const CONSTANTS = {
  CaseStatus_None: 0,
  CaseStatus_CancelledNoCharge: 1,
  CaseStatus_CancelledWithFee: 2,
  CaseFlow: {
    CaseCreated: 0,
    ReportReviewRequested: 1,
    RequestSentProvider: 2,
    DictationReceived: 3,
    ReportDraftReceived: 4,
    ReportReturnedFromDoctor: 5,
    ReportCompleted: 6,
    CancelledNoFee: 7,
    CancelledWithFee: 8,
    ClientInvoiced: 9,
    DoctorInvoiced: 10,
    ServiceBilled: 11,
    ServicePaid: 12,
  },
  TypeCallerReceiver: {
    CaseManager: 1,
    Provider: 2,
    Staff: 3,
  },
};
export const VIEW_INVOICE_GLOBAL = {
  CommissionType: {
    Mag: 0,
    NonMag: 1,
    FixedAmount: 2,
    COT: 3,
  },
};

export const INIT_FILE_REVIEW = {
  ActionCompleted: false,
  ActionNotes: null,
  ActionRequired: true,
  ActionRequiredDueDate: null,
  ActionRequiredType: 0,
  ActionRequiredUserName: '',
  ActionSubject: null,
  AllowDoctorToViewPaperwork: false,
  AssignedStaffId: null,
  AuthorisedPurchaseAmount: null,
  AutoRemindReportDraftCompletionDeadlineToMagStaff: false,
  BillingDate: null,
  BookingComment: null,
  BookingSolicitor: null,
  BookingSolicitorId: null,
  CMid: 0,
  CancelledComments: null,
  CancelledDate: null,
  CaseFlow: 0,
  CaseManager: null,
  CaseManagerId: null,
  CaseStatus: 0,
  ClaimType: null,
  ClaimantNo: null,
  ClaimantStatus: false,
  ClientInvoiced: false,
  ClientInvoicedDate: null,
  ClientPrepaymentPaidDate: null,
  ClientPrepaymentReceived: false,
  ClientPrepaymentRequired: false,
  ClientPrepaymentRequiredDate: null,
  ClientRequestNo: null,
  Comments: null,
  CompletedDate: null,
  Customer: null,
  CustomerId: null,
  DatereadyCreateInvoice: null,
  DelayPrepaymentRequired: false,
  DictationComment: null,
  DictationOnedriveLinkFile: null,
  DictationOption: 0,
  DictationReceived: false,
  DictationReceivedDate: null,
  DictationSentToTypist: false,
  DictationTypistID: null,
  DisableReportReminder: false,
  DisableReportReminderBy: null,
  DisableReportReminderDate: null,
  Doctor: null,
  DoctorId: null,
  DoctorInvoiced: false,
  DoctorInvoicedDate: null,
  DoctorPrepaymentRequired: false,
  DoctorViewPaperworkByUser: null,
  EmailInvoiceToMAG: false,
  EmailInvoiceToMAGDateSent: null,
  EmailInvoiceToMAGStaffSent: '',
  EmailPaymentReceiptToClient: false,
  EmailPaymentReceiptToClientBy: '',
  EmailPaymentReceiptToClientDateSent: null,
  EmailPaymentReceiptToDoctor: false,
  EmailPaymentReceiptToDoctorDateSent: null,
  EmailReportDraftToDoctor: false,
  EmailReportDraftToDoctorBE: false,
  EmailReportDraftToDoctorByUser: null,
  EmailReportDraftToDoctorDateSent: null,
  EmailReportDraftToDoctorPE: false,
  EmailToCMsViewReportRecent: null,
  EmailToCMsViewReportRecentByUser: null,
  EmployerOfClaimant: null,
  F_RDCCaseManagerId: null,
  F_RDCEmail: null,
  F_RDCSameBookingCM: false,
  F_RDCfirstName: null,
  F_RDClastName: null,
  FileReviewAttachment: [],
  FileReviewNumber: null,
  IMSApprovalCode: null,
  Id: 0,
  IlarsRef: null,
  IlarsWiroSolicitor: null,
  IlarsWiroSolicitorOld: null,
  InjuriedDate: null,
  InjuryDescription: null,
  IsAllowCMViewAmendedReport: false,
  IsAllowCMViewReport: false,
  IsAllowSolicitorViewAmendedReport: false,
  IsAllowSolicitorViewReport: false,
  IsBilled: false,
  IsInvoiceUrgentReport: false,
  IsLongPaperwork: false,
  IsPaperworkReceived: false,
  IsPaymentRequested: false,
  IsPrepaymentPaid: false,
  IsSendPrepaymentPaidToProvider: false,
  MedNegId: null,
  MedNegNumber: '',
  NotifyCancellationToCM: false,
  NotifyCancellationToDoctor: false,
  NotifyCancellationToSolictor: false,
  OriginalMAGInfo: null,
  OriginalMAGInfo2: null,
  OriginalMAGInfoTxt: null,
  OriginalMAGInfoTxt2: null,
  PCaseManager: null,
  PCaseManagerId: null,
  PCustomer: null,
  PCustomerId: null,
  PICDictationReceived: null,
  PICReportDraftReceived: null,
  PICcancelled: null,
  PICcompleted: null,
  PICcorrectedReport: null,
  PICsentToProvider: null,
  PaperworkByOtherMethod: false,
  PaperworkByOtherMethodDate: null,
  PaperworkExLink: '',
  PaperworkOtherExLink: '',
  PaperworkReceivedDate: null,
  Patient: null,
  PaymentRequestedAttachment: null,
  PaymentRequestedDate: null,
  PaymentRequestedInvoiceAmount: null,
  PaymentRequestedInvoiceNo: null,
  PaymentRequestedInvoiceTax: null,
  PrepaymentPaidAttachment: null,
  PrepaymentPaidComment: null,
  PrepaymentPaidDate: null,
  PrepaymentRequired: false,
  PrepaymentRequiredDate: null,
  PurchaseOrderNo: null,
  ReadyCreateInvoice: false,
  ReasonCancellation: 0,
  ReportCompleted: false,
  ReportCompletedDate: null,
  ReportCompletedSentDate: null,
  ReportDraftComment: null,
  ReportDraftReceived: false,
  ReportDraftReceivedDate: null,
  ReportDraftSentDate: null,
  ReportRequiredDate: null,
  ReportReturnedFromDoctor: false,
  ReportType: null,
  ReportTypeId: 0,
  RequestDate: null,
  RequestExpectedCompletionDate: null,
  S_RDCCaseManagerId: null,
  S_RDCEmail: null,
  S_RDCfirstName: null,
  S_RDClastName: null,
  SelectedDoctorProviderNoIdx: 1,
  SelectedPaperworkToDoctorBies: [],
  SendConfirmationToSpecialist: false,
  SendConfirmationToSpecialistDate: null,
  SendConfirmationToSpecialistDateByUser: null,
  SendToWIRO: false,
  SentAmendedReportsByUser: null,
  SentAmendedReportsDate: null,
  SentByAllowSolicitorViewAmendedReport: '',
  SentByAllowSolicitorViewReport: '',
  SentByNotifyBookingCMCancellation: '',
  SentByNotifyCancellationToSolictor: '',
  SentByNotifyDoctorCancellation: '',
  SentDateAllowSolicitorViewAmendedReport: null,
  SentDateAllowSolicitorViewReport: null,
  SentDateDoctorViewPaperwork: null,
  SentDateNotifyBookingCMCancellation: null,
  SentDateNotifyCancellationToSolictor: null,
  SentDateNotifyDoctorCancellation: null,
  SentDateToTypist: null,
  SentToTypist: false,
  ServiceNotesandFeeObj: null,
  SpecialistPrepaymentPaid: false,
  SpecialtyMedNegId: null,
  StaffId: null,
  Status: null,
  StatusDate: null,
  SubCaseManager1: null,
  SubCaseManager1Id: null,
  SubCaseManager2: null,
  SubCaseManager2Id: null,
  SubCaseManager3: null,
  SubCaseManager3Id: null,
  ToTypistByUser: null,
  TypistApproverdBy: null,
  TypistApproverdOn: null,
  TypistSubmittingDate: null,
  TypistTranscribingTime: null,
  UrgentReport: false,
  UrgentReportCompleted: false,
  UrgentReportCompletedDate: null,
  UrgentReportDueDate: null,
  UrgentReportNotes: null,
  UrgentReportStaffId: null,
  UserReadyCreateInvoice: '',
  WifroIlars: false,
  isDirect: false,
  isLocked: false,
  PCSameBookingClient: false,
  PMSameBookingCM: false,
  ClientPrepaymentNote: null,
  SpecialistPrepaymentNote: null,
  UrgentReportReportCompletionDate: null,
  UrgentReportConfirmedByCM: false,
  UrgentReportConfirmedByCMDate: null,
  UrgentReportConfirmedByCMBy: null,
  NotifyUrgentReportToStaff: false,
  NotifyUrgentReportToStaffDate: null,
  NotifyUrgentReportToStaffBy: null,
  NotifyUrgentReportToSpecialist: false,
  NotifyUrgentReportToSpecialistDate: null,
  NotifyUrgentReportToSpecialistBy: null,
  NotifyUrgentReportToCM: false,
  NotifyUrgentReportToCMDate: null,
  NotifyUrgentReportToCMBy: null,
  RequestAcceptedBySpecialist: false,
  RequestAcceptedBySpecialistDate: null,
  RequestAcceptedBySpecialistBy: null,
  IROFundingApproved: false,
  IROFundingApprovalDate: null,
  IROFundingApprovedDate: null,
  IROFundingApprovedBy: null,
};
export const LOCAL_CONSTANT = {
  ReportType_Paperwork: 1,
  ReportType_PaperworkOther: 6,
};
