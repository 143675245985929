import { observer } from 'mobx-react';
import React from 'react';

import iframe from '../../dashboard-routes/Iframe/iframeData';
import TableToolbar from '../../shared/tableToolbar';
import IMSApprovalCodeStore from './IMSApprovalCodeStore';
import * as api from '../../../stores/api';

const statusLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class IMSApprovalCodeToolbar extends React.Component {
  renderFilterOptions = () => {
    return statusLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = statusLabels.filter(opt => opt.value === value).map(opt => opt.text);
    if (labels[0]) return labels[0];
    return `All`;
  };

  handleReset = () => {
    IMSApprovalCodeStore.resetStore();
    IMSApprovalCodeStore.refetch({}, true);
  };

  handleFilter = (_, { value }) => {
    IMSApprovalCodeStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    IMSApprovalCodeStore.refetch({ curPage: 1 }, true);
  };

  openCaseCreate = () => () => {
    var id = 0;
    if (localStorage.getItem(`ImsApproval`)) {
      localStorage.removeItem(`ImsApproval`);
    }
    iframe.forceOpenTab('AddOrEditIMSApprovalCodeWIRO', `?id=${id}&view=AddOrEditIMSApprovalCodeWIRO`, {
      id,
      view: 'AddOrEditIMSApprovalCodeWIRO',
    });
  };

  render() {
    const { keyword, loading, filter } = IMSApprovalCodeStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left>
          <TableToolbar.Input
            placeholder={'Search billing items'}
            value={keyword}
            onChange={IMSApprovalCodeStore.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.Filter
            disabled={loading}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            label={`Status`}
            onChange={this.handleFilter}
          />
          <TableToolbar.SubmitButton
            className="Toolbar-highlight-button px-2"
            onClick={this.handleSubmit}
            disabled={loading}
          >
            Search
          </TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            disabled={loading}
            className="negative"
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          {api.isAdmin() && !api.isOnlySearch() && (
            <TableToolbar.HighLightButton disabled={loading} className="positive" onClick={this.openCaseCreate()}>
              Add Billing Item Code
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default IMSApprovalCodeToolbar;
