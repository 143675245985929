import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { TableHeader, TableEmptyMessage } from '../../shared/table';
import iframe from '../../dashboard-routes/Iframe/iframeData';

class InvoiceHistory extends React.Component {
  renderTableEmpty = columns => {
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          <TableEmptyMessage colSpan={columns.length} />
        </Table.Body>
      </Table>
    );
  };

  openViewInvoice = record => {
    localStorage.setItem('InvoiceCaseId', `INV-${record.invoiceNo}`);
    iframe.forceOpenTab('ViewInvoice', `?id=${record.id}&view=ViewInvoice`, {
      id: record.id,
      view: 'ViewInvoice',
    });
  };

  checkIsDateString = datesString => {
    return moment(datesString).format('DD/MM/YYYY');
  };

  renderTableCell = (record, idx) => {
    if (this.checkIsDateString(record) !== 'Invalid date') {
      return <Table.Cell key={idx}>{this.checkIsDateString(record)}</Table.Cell>;
    } else {
      return <Table.Cell key={idx}>{record}</Table.Cell>;
    }
  };

  renderTableData = (dataInvoice, columns) => {
    if (Array.isArray(dataInvoice)) {
      if (dataInvoice.length === 0) {
        return this.renderTableEmpty(columns);
      }
      return (
        <Table striped celled>
          <TableHeader columns={columns} />
          <Table.Body>
            {dataInvoice.map((record, idx) => (
              <Table.Row key={idx}>
                <Table.Cell className="Cell-link">
                  <Link to="" onClick={() => this.openViewInvoice(record)}>
                    {record.invoiceNo}
                  </Link>
                </Table.Cell>
                <Table.Cell>{this.checkIsDateString(record.invoiceDate)}</Table.Cell>
                <Table.Cell>{record.amountExcludeGst}</Table.Cell>
                <Table.Cell>{record.amountIncludeGst}</Table.Cell>
                <Table.Cell>{record.invoiceIssuerName}</Table.Cell>
                <Table.Cell>{record.isUploadedToXero ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{this.checkIsDateString(record.xeroUploadedDate)}</Table.Cell>
                <Table.Cell>{record.isFullyPaid ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{this.checkIsDateString(record.lastPaymentDate)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return this.renderTableEmpty(columns);
    }
  };
  render() {
    const { dataInvoice, columns } = this.props;
    return this.renderTableData(dataInvoice, columns);
  }
}
export default InvoiceHistory;
