import React from 'react';
import { Form, Input, Select, Checkbox, DatePicker, Divider } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import AddEditStore from './store';
import { formatDate, convertPhoneFaxNumber, convertMobileNumber } from '@utils/functions';
import { toJS } from 'mobx';
import {
  checkDuplicateEmail,
  checkDuplicateName,
  recentChangeClient,
  recentChangeStaff,
  getCreatedByHistories,
} from './service';

import * as api from '@stores/api';
import { renderDate } from '../Shared';
import _ from 'lodash';

const stateOptions = [
  {
    value: 'ACT',
  },
  {
    value: 'NSW',
  },
  {
    value: 'VIC',
  },
  {
    value: 'QLD',
  },
  {
    value: 'SA',
  },
  {
    value: 'WA',
  },
  {
    value: 'TAS',
  },
  {
    value: 'NT',
  },
];

const sourceOptions = [
  { value: 1, text: 'Referral from existing CM' },
  { value: 2, text: 'Company website' },
  { value: 3, text: 'LinkedIn' },
  { value: 4, text: 'LOI Scraping' },
  { value: 5, text: 'Online Registration' },
  { value: 6, text: 'Conference' },
  { value: 0, text: 'Other' },
];

window.functionGetElementFormForResize = () => {
  return document.getElementById('contact-information-form');
};

@observer
class ContactInformation extends React.Component {
  handleSearch = val => {
    AddEditStore.refetchSearchClient({ keywordClient: val });
  };
  handleFieldAssignDate = dateStr => {
    if (dateStr) {
      if (dateStr.search('/Date') !== -1) {
        const date = formatDate(dateStr);
        return moment(date, 'DD MMM,YYYY').format('DD/MM/YYYY');
      }
      return dateStr;
    }
    return dateStr;
  };
  handleUnsubscribedDate = dateStr => {
    if (dateStr) {
      if (typeof dateStr === 'string') {
        if (dateStr.search('/Date') !== -1) {
          const date = formatDate(dateStr, true);
          return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
        }
        return dateStr;
      }
      return moment(dateStr).format('DD/MM/YYYY - HH:mm');
    }
    return '';
  };
  handleScroll = event => {
    const target = event.target;
    const number = AddEditStore.numberItemClient;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      AddEditStore.refetchSearchClient({ numberItemClient: number + 20 });
    }
  };

  handleOpenRecentChange = (record, type) => {
    if (type === 'client') {
      Promise.all([recentChangeClient(record.Id)]).then(([dataRecentChange]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange: dataRecentChange.itemList,
          record,
          type,
        })();
      });
    } else {
      Promise.all([recentChangeStaff(record.Id)]).then(([dataRecentChange]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange: dataRecentChange.itemList,
          record,
          type,
        })();
      });
    }
  };

  handleOpenCreatedByChange = record => {
    Promise.all([getCreatedByHistories(record.Id)]).then(([histories]) => {
      return AddEditStore.toggleModal(true, {
        modalType: 'createdByHistory',
        dataCreatedHistories: histories.itemList,
        record,
      })();
    });
  };

  handleCheckDuplicate = (record, type) => {
    if (type === 'email') {
      Promise.all([checkDuplicateEmail(record.Id, record.Email)]).then(([duplicate]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'duplicate',
          dataDuplicate: duplicate.lstObjs,
          type,
        })();
      });
    } else {
      Promise.all([checkDuplicateName(record.Id, record.LastName)]).then(([duplicate]) => {
        return AddEditStore.toggleModal(true, {
          modalType: 'duplicate',
          dataDuplicate: duplicate.lstObjs,
          type,
        })();
      });
    }
  };
  renderBussinessUnit = businessUnits => {
    if (!businessUnits || businessUnits.length === 0) {
      return [];
    } else if (typeof businessUnits[0] === 'object') {
      return toJS(businessUnits).map(i => i.id);
    } else {
      return toJS(businessUnits);
    }
  };
  renderPosition = Positions => {
    if (Positions) {
      if (typeof Positions === 'object') {
        const position = toJS(Positions);
        return position.Id;
      }
      return null;
    }
    return null;
  };
  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        return moment(formatDate(dateString), 'DD MMM, YYYY');
      }
      return dateString;
    }
    return null;
  };

  openModal = options => {
    return AddEditStore.toggleModal(true, options);
  };

  handleChangeStatus = option => {
    if (option === 0) {
      AddEditStore.handleEnableCase(AddEditStore.CMInfo.Id);
    } else {
      AddEditStore.handleDisableCase(AddEditStore.CMInfo.Id, 'Other', option === 0 ? 'Enable' : 'Disable');
    }
  };

  render() {
    const { CMInfo, loadingSearchClient, specificlookupList, greetingTitle } = AddEditStore;
    let { clientList, staffList } = AddEditStore;
    if (staffList?.itemList) {
      staffList = {
        itemList: _.unionBy(
          [
            ...staffList?.itemList,
            { ...CMInfo.AssignedStaff, AssignedStaffId: CMInfo.AssignedStaffId, Id: CMInfo.AssignedStaffId },
            { ...CMInfo.CreatedBy, CreatedById: CMInfo.CreatedById, Id: CMInfo.CreatedById },
          ],
          'Id',
        ),
      };
    }

    return (
      <Form id="contact-information-form" name="contact-information">
        {CMInfo.Id > 0 && (
          <Form.Item className="fluid-field">
            <Checkbox disabled={true} checked={CMInfo.IsActive}>
              Active Status{'  '}
              <Link to="#" onClick={this.openModal({ modalType: 'history', id: CMInfo.Id, record: CMInfo })}>
                {' '}
                Recent changes{'  '}
              </Link>
              {!CMInfo.IsActive && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="green"
                  onClick={AddEditStore.toggleModal(true, {
                    modalType: 'confirm',
                    message: `Do you want to enable this case manager?`,
                    onOk: () => this.handleChangeStatus(0),
                  })}
                >
                  Enable
                </Button>
              )}
              {CMInfo.IsActive && !api.isSiteOnlyInvoiceCheck() && (
                <Button
                  style={{ borderRadius: '20px', height: '27px', lineHeight: '2px' }}
                  color="red"
                  onClick={this.openModal({ modalType: 'disable', modalParams: { Id: CMInfo.Id, Record: CMInfo } })}
                >
                  Disable
                </Button>
              )}
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item label="CM ID">
          <Input size="large" value={CMInfo.Id} disabled />
        </Form.Item>
        <Form.Item label="Title">
          <Select size="large" value={CMInfo.Title} onChange={AddEditStore.handleFieldChange('Title')} allowClear>
            {greetingTitle.map((i, idx) => (
              <Select.Option key={idx} value={i.Title}>
                {i.Title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="First Name">
          <Input size="large" value={CMInfo.FirstName} onChange={AddEditStore.handleFieldChange('FirstName')} />
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Surname
              <button className="check-duplicate" onClick={() => this.handleCheckDuplicate(CMInfo, 'lastName')}>
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={CMInfo.LastName} onChange={AddEditStore.handleFieldChange('LastName')} />
        </Form.Item>
        <Form.Item
          label="Phone"
          validateStatus={AddEditStore.errors['OfficePhone'] ? 'error' : null}
          help={AddEditStore.errors['OfficePhone']}
        >
          <Input
            size="large"
            value={convertPhoneFaxNumber(CMInfo.OfficePhone)}
            onChange={AddEditStore.handleFieldChange('OfficePhone')}
          />
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['Telephone'] ? 'error' : null}
          help={AddEditStore.errors['Telephone']}
          label="Mobile"
        >
          <Input
            size="large"
            value={convertMobileNumber(CMInfo.Telephone)}
            onChange={AddEditStore.handleFieldChange('Telephone')}
          />
        </Form.Item>
        <Form.Item
          required={CMInfo.EnableLogin}
          validateStatus={AddEditStore.errors['Email'] ? 'error' : null}
          help={AddEditStore.errors['Email']}
          label={
            <React.Fragment>
              Email
              <button onClick={() => this.handleCheckDuplicate(CMInfo, 'email')} className="check-duplicate">
                Check duplicate
              </button>
            </React.Fragment>
          }
        >
          <Input size="large" value={CMInfo.Email} onChange={AddEditStore.handleFieldChange('Email')} />
        </Form.Item>
        <Form.Item
          className="posstion-IsTypist-label"
          label={
            <span>
              <span style={{ marginRight: '10px' }}>Position:</span>
            </span>
          }
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            value={this.renderPosition(CMInfo.Position)}
            onChange={AddEditStore.handleFieldChange('Position')}
            allowClear
          >
            {specificlookupList &&
              specificlookupList.itemList
                .filter(i => i.IsActive)
                .map(i => (
                  <Select.Option value={i.Id} key={i.Id}>
                    {i.Label}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item label="Suburb">
          <Input size="large" value={CMInfo.Suburb} onChange={AddEditStore.handleFieldChange('Suburb')} />
        </Form.Item>
        <Form.Item label="Postcode">
          <Input size="large" value={CMInfo.Postcode} onChange={AddEditStore.handleFieldChange('Postcode')} />
        </Form.Item>
        <Form.Item label="Linkedin">
          <Input size="large" value={CMInfo.LinkedIn} onChange={AddEditStore.handleFieldChange('LinkedIn')} />
        </Form.Item>
        <Form.Item label="State">
          <Select size="large" value={CMInfo.State} allowClear onChange={AddEditStore.handleFieldChange('State')}>
            {stateOptions.map((i, idx) => (
              <Select.Option key={idx} value={i.value}>
                {i.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Referral Source">
          <Select size="large" value={CMInfo.CMSource} allowClear onChange={AddEditStore.handleFieldChange('CMSource')}>
            {sourceOptions.map((i, idx) => (
              <Select.Option key={idx} value={i.value}>
                {i.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          required={!CMInfo.IsTypist}
          validateStatus={AddEditStore.errors['CompanyId'] ? 'error' : null}
          help={AddEditStore.errors['CompanyId']}
          label={
            <React.Fragment>
              Client
              <button className="check-duplicate" onClick={() => this.handleOpenRecentChange(CMInfo, 'client')}>
                Recent changes
              </button>
            </React.Fragment>
          }
        >
          <Select
            size="large"
            allowClear
            value={CMInfo.CompanyId}
            showSearch
            optionFilterProp="children"
            onChange={AddEditStore.handleFieldChange('CompanyId')}
            onSearch={this.handleSearch}
            onPopupScroll={this.handleScroll}
            loading={loadingSearchClient}
            disabled={CMInfo.IsTypist}
          >
            {clientList &&
              clientList.itemList.map((i, idx) => (
                <Select.Option value={i.Id} key={idx}>
                  {`(${i.Id}) - ${i.Name}`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Business Unit">
          <Select
            mode="multiple"
            size="large"
            allowClear
            disabled
            showArrow
            value={this.renderBussinessUnit(CMInfo.CompanyBusinessUnit)}
            onChange={AddEditStore.handleFieldChange('CompanyBusinessUnit')}
          >
            {CMInfo.CompanyBusinessUnit &&
              CMInfo.CompanyBusinessUnit.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.title}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Created By
              <button className="check-duplicate" onClick={() => this.handleOpenCreatedByChange(CMInfo)}>
                Recent changes
              </button>
            </React.Fragment>
          }
        >
          <Select
            size="large"
            value={CMInfo.CreatedById}
            allowClear
            onChange={AddEditStore.handleFieldChange('CreatedById')}
            disabled={CMInfo.Id > 0}
          >
            {staffList &&
              staffList.itemList.map(i => (
                <Select.Option value={i.Id} key={i.Id}>
                  {i.FullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <React.Fragment>
              Account Manager
              <button onClick={() => this.handleOpenRecentChange(CMInfo, 'staff')} className="check-duplicate">
                Recent changes
              </button>
            </React.Fragment>
          }
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            value={CMInfo.AssignedStaffId}
            allowClear
            onChange={AddEditStore.handleFieldChange('AssignedStaffId')}
          >
            {staffList &&
              staffList.itemList.map(i => (
                <Select.Option value={i.Id} key={i.Id}>
                  {i.FullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Assigned Date">
          <Input size="large" disabled value={this.handleFieldAssignDate(CMInfo.AssignedDate)} />
        </Form.Item>
        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Contact Verification and Notifications</h3>
        {api.isAdminOrMagStaffOrAccounting() && (
          <div style={{ marginBottom: '15px' }} className="fluid-field">
            <Checkbox onChange={AddEditStore.handleChecked('IsVerified')} checked={CMInfo.IsVerified}>
              Contact Detail Verified
            </Checkbox>
            {CMInfo.IsVerified && !!toJS(CMInfo.VerifiedDate) && (
              <span>Verified Date: {this.handleUnsubscribedDate(CMInfo.VerifiedDate)}</span>
            )}
            {CMInfo.IsVerified && !!toJS(CMInfo.VerifiedBy) && <span>- By {CMInfo.VerifiedBy}</span>}
            {!CMInfo.IsVerified && !!toJS(CMInfo.UnVerifiedDate) && (
              <span>To Verify Date: {this.handleUnsubscribedDate(CMInfo.UnVerifiedDate)}</span>
            )}
            {!CMInfo.IsVerified && !!toJS(CMInfo.UnVerifiedBy) && <span>- By {CMInfo.UnVerifiedBy}</span>}
          </div>
        )}
        <div className="fluid-field">
          <Checkbox onChange={AddEditStore.handleChecked('IsSubscribed')} checked={CMInfo.IsSubscribed}>
            Subscribed to Promotions
          </Checkbox>

          {/* <Checkbox checked={!!toJS(CMInfo.UnsubscribedDate)} onChange={AddEditStore.handleChecked('Unsubscribed')}>
            Subscribed to Promotions
          </Checkbox>*/}
          {CMInfo.Id > 0 && CMInfo.IsSubscribed && !!toJS(CMInfo.SubscribedDate) && (
            <span>Subscribed Date: {this.handleUnsubscribedDate(CMInfo.SubscribedDate)}</span>
          )}
          {CMInfo.Id > 0 && CMInfo.IsSubscribed && !!toJS(CMInfo.SubscribedBy) && <span>- By {CMInfo.SubscribedBy}</span>}
          {CMInfo.Id > 0 && !CMInfo.IsSubscribed && !!toJS(CMInfo.UnsubscribedDate) && (
            <span>Unsubscribed Date: {this.handleUnsubscribedDate(CMInfo.UnsubscribedDate)}</span>
          )}
          {CMInfo.Id > 0 && !CMInfo.IsSubscribed && !!toJS(CMInfo.UnsubscribedBy) && <span>- By {CMInfo.UnsubscribedBy}</span>}
        </div>
        <Form.Item label="Subscription Notes" className="fluid-field" style={{ marginBottom: 0, marginTop: 10 }}>
          <Input.TextArea rows={3} value={CMInfo.Comments} onChange={AddEditStore.handleFieldChange('Comments')} />
        </Form.Item>
        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Subscribe to Specialist Invoice Check</h3>
          <div style={{ marginBottom: '15px' }} className="fluid-field">
            <Checkbox onChange={AddEditStore.handleChecked('SubscribeSpecialistInvoiceCheck')} checked={CMInfo.SubscribeSpecialistInvoiceCheck}>
              Yes
            </Checkbox>
            {CMInfo.SubscribeSpecialistInvoiceCheck && !!toJS(CMInfo.SubscribeSpecialistInvoiceCheckDate) && (
              <span>Subscribed Date: {this.handleUnsubscribedDate(CMInfo.SubscribeSpecialistInvoiceCheckDate)}</span>
            )}
            {CMInfo.SubscribeSpecialistInvoiceCheck && !!toJS(CMInfo.SubscribeSpecialistInvoiceCheckBy) && <span>- By {CMInfo.SubscribeSpecialistInvoiceCheckBy}</span>}
            {!CMInfo.SubscribeSpecialistInvoiceCheck && !!toJS(CMInfo.UnSubscribeSpecialistInvoiceCheckDate) && (
              <span>Unsubscribed Date: {this.handleUnsubscribedDate(CMInfo.UnSubscribeSpecialistInvoiceCheckDate)}</span>
            )}
            {!CMInfo.SubscribeSpecialistInvoiceCheck && !!toJS(CMInfo.UnSubscribeSpecialistInvoiceCheckBy) && <span>- By {CMInfo.UnSubscribeSpecialistInvoiceCheckBy}</span>}
          </div>
        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">Recruitment Status</h3>
        <Form.Item className="fluid-field">
          <Checkbox checked={CMInfo.IsNonNookingContact} onChange={AddEditStore.handleChecked('IsNonNookingContact')}>
            Non-Booking Contact
          </Checkbox>
        </Form.Item>
        <Form.Item
          validateStatus={AddEditStore.errors['RecruitmentStatus'] ? 'error' : null}
          help={AddEditStore.errors['RecruitmentStatus']}
        >
          <Checkbox checked={CMInfo.IsRecruiting} onChange={AddEditStore.handleChecked('IsRecruiting')}>
            Recruiting
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={AddEditStore.handleChecked('IsOnboarding')} checked={CMInfo.IsOnboarding}>
            Onboarding
          </Checkbox>
        </Form.Item>
        <Form.Item label="Recruiting Date">
          <DatePicker
            value={this.convertDate(CMInfo.RecruitingDate)}
            onChange={AddEditStore.handleFieldChange('RecruitingDate')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item label="Onboarding Date">
          <DatePicker
            value={this.convertDate(CMInfo.OnboardingDate)}
            format="DD/MM/YYYY"
            onChange={AddEditStore.handleChecked('OnboardingDate')}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={AddEditStore.handleChecked('IsOnboarded')} checked={CMInfo.IsOnboarded}>
            Onboarded
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={AddEditStore.handleChecked('IsExcluding')} checked={CMInfo.IsExcluding}>
            Excluded
          </Checkbox>
        </Form.Item>
        <Form.Item label="Onboarded Date">
          <DatePicker
            value={this.convertDate(CMInfo.OnboardedDate)}
            onChange={AddEditStore.handleFieldChange('OnboardedDate')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item label="Excluding Date">
          <DatePicker
            value={this.convertDate(CMInfo.ExcludingDate)}
            format="DD/MM/YYYY"
            onChange={AddEditStore.handleFieldChange('ExcludingDate')}
          />
        </Form.Item>
        {!CMInfo.IsActive && (
          <Form.Item>
            <Checkbox checked={!CMInfo.IsActive}>
              Inactive {!CMInfo.IsActive && <span>(Inactive date: {renderDate(CMInfo.InActiveDate, false)})</span>}
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item className="fluid-field" label="Comment">
          <Input.TextArea
            rows={4}
            value={CMInfo.RecruitmentComment}
            onChange={AddEditStore.handleFieldChange('RecruitmentComment')}
          />
        </Form.Item>
      </Form>
    );
  }
}

export default ContactInformation;
