import customFetch from '../../../../utils/customFetch';

export const getBusinessUnit = (
  body = {
    CurPage: 1,
    Keyword: '',
    NumItemPerPage: 20,
    Showing: 'active',
    SortDirection: 'ASC',
    companyId: 0,
    SortBy: 'Id',
  },
) => {
  return customFetch('/BusinessUnit/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const handleEditStatusBU = id => {
  return customFetch('/BusinessUnit/Authorize', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getCaseManagerList = (keyword = '', buCompanyId = 0) => {
  return customFetch('/Staff/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      numItemPerPage: 30,
      Showing: `active`,
      curPage: 1,
      keyword: keyword,
      BUCompanyId: buCompanyId,
    }),
  });
};

export const validateCMBUManager = (cmId, companyId = 0) => {
  return customFetch('/BusinessUnit/ValidateCMManager', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ cmId, companyId }),
  });
};

export const getBUInfo = (id, cId) => {
  return customFetch('/BusinessUnit/BusinessUnitInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, cId }),
  });
};

export const getStaffByCompanyId = cId => {
  return customFetch('/BusinessUnit/GetStaffsByCompanyID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ CompanyId: cId, camelCaseResult: true }),
  });
};

export const saveBusinessInfo = body => {
  return customFetch('/BusinessUnit/Save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};
