import customFetch from '../../../utils/customFetch';

export const getAMAAftercareRule = (keyword = '', curPage = 1, numItemPerPage = 0, sortBy = '', sortDirection = '') => {
  return customFetch('/AMAAftercareRule/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getAMAAftercareRuleInfo = (id = 0) => {
  return customFetch(`/AMAAftercareRule/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveAMAAftercareRule = param => {
  return customFetch('/AMAAftercareRule/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteAMAAftercareRule = id => {
  return customFetch('/AMAAftercareRule/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchAMAAftercareRule = param => {
  return customFetch('/AMAAftercareRule/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};
