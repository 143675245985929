import { observer } from 'mobx-react';
import React from 'react';

import TableToolbar from '../../shared/tableToolbar';
import ServiceEstimateStore from './Store';
import _ from 'lodash';
import * as api from '../../../stores/api';

@observer
class ServiceEstimateToolbar extends React.Component {
  handleSearchChange = (_, { value }) => {
    ServiceEstimateStore.setFieldsValue({ keyword: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    ServiceEstimateStore.refetch({}, true);
  };

  handleReset = () => {
    ServiceEstimateStore.resetStore();
  };

  handleExportSearch = () => {
    ServiceEstimateStore.exportSearchResult();
  };

  handleInvoiceCheck = () => {
    ServiceEstimateStore.exportInvoiceCheck();
  };

  handleCreate = () => {
    ServiceEstimateStore.setShowOtherBodyPart(false);
    if(api.isCaseManager()) ServiceEstimateStore.getCompanyInfo(api.currentUser.data.ClientId);
    ServiceEstimateStore.setListItem('aftercare',[]);
    ServiceEstimateStore.setListItem('anaesthesia',[]);
    ServiceEstimateStore.setListItem('professionalAttendance',[]);
    ServiceEstimateStore.setListItem('assistanceAtOperation',[]);

    return ServiceEstimateStore.toggleModal(true, { modalType: 'create' })();
  };

  handleChangeFromTo = (_, { value }) => {
    ServiceEstimateStore.refetch({ fromTo: value });
  };

  renderOptionCM = () => {
    const { dataCM } = ServiceEstimateStore;
    const options = [{ value: null, text: 'All' }];
    let arrayCMs = _.sortBy(dataCM, 'FirstName', 'asc').map(i => ({
      value: i.Id,
      text: `${i.Id} - ${i.Title} ${i.FullName}`,
    }));
    return [...options, ...arrayCMs] || options;
  };

  renderOptionCMText = val => {
    const { dataCM } = ServiceEstimateStore;
    if (val > 0) {
      return dataCM.filter(i => i.Id === val).map(i => `${i.Title} ${i.FullName}`)[0];
    } else {
      return 'All';
    }
  };

  renderOptionExportByClient = () => {
    let arrays = [
      { text: 'My Checks', value: 0 },
      { text: 'All Checks', value: 1 },
    ];
    return arrays;
  };

  renderOptionExportByClientText = val => {
    if (val === 1) {
      return 'All Checks';
    }
    return 'My Checks';
  };

  handleFilterChange = (_, { value }) => {
    ServiceEstimateStore.refetch({ caseManagerId: value });
  };

  handleFilterChangeExportByClient = (_, { value }) => {
    ServiceEstimateStore.updateData({ exportByClient: value });
  };

  render() {
    const { keyword, fromTo, caseManagerId, exportByClient, loading, totalItems } = ServiceEstimateStore;
    return (
      <TableToolbar.Container style={{ justifyContent: 'flex-start' }}>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Input
            placeholder="Search"
            value={keyword}
            onChange={this.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.RangePicker
            placeholder={'Checking date From - To'}
            value={fromTo}
            className="Toolbar-range-picker"
            onChange={this.handleChangeFromTo}
            disabled={loading}
          />
          {!api.isCaseManager() && (
            <TableToolbar.Filter
              selection
              label={`Case Manager`}
              value={this.renderOptionCMText(caseManagerId)}
              className="Toolbar-filter"
              name={'caseManagerId'}
              options={this.renderOptionCM()}
              onChange={this.handleFilterChange}
              disabled={loading}
            />
          )}
          {api.isCaseManager() && (
            <TableToolbar.Filter
              selection
              label={`Export`}
              value={this.renderOptionExportByClientText(exportByClient)}
              className="Toolbar-filter"
              name={'exportByClient'}
              options={this.renderOptionExportByClient()}
              onChange={this.handleFilterChangeExportByClient}
              disabled={loading}
            />
          )}
          <TableToolbar.SubmitButton disabled={loading}>Search</TableToolbar.SubmitButton>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
          {/* <TableToolbar.HighLightButton
            className="positive"
            disabled={loading}
            onClick={this.handleExportSearch}
            style={{ marginLeft: '5px' }}
          >
            Export Searches
          </TableToolbar.HighLightButton> */}
          <TableToolbar.HighLightButton
            className="positive"
            disabled={loading}
            onClick={this.handleInvoiceCheck}
            style={{ marginLeft: '5px' }}
          >
            Export Searches
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Left>
          {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
            <TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.handleCreate}
            >
              Run New Check
            </TableToolbar.HighLightButton>
          )}
        </TableToolbar.Left>
        <TableToolbar.Left>
          <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label> <span>{totalItems}</span>
        </TableToolbar.Left>
      </TableToolbar.Container>
    );
  }
}

export default ServiceEstimateToolbar;
