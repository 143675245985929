import React from 'react';
import { Checkbox, Form, Select, DatePicker, Input, Radio } from 'antd';
import { Button, Icon } from 'semantic-ui-react';

import './ConversationModalForm.scss';
import Modal from '../../shared/Modal';
import * as api from '../../../../src/stores/api';

class ConversationModalForm extends React.Component {
  render() {
    const { visible, onCancel } = this.props;

    return (
      <Modal
        width={600}
        onCancel={onCancel}
        visible={visible}
        footer={
          <React.Fragment>
            {!api.isOnlySearch() && <Button color="blue">Save</Button>}
            <Button color="red" onClick={onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title="Add New"
      >
        <Form name="conversation" className="conversation-form">
          <Form.Item>
            <Checkbox>Allow CMs to view</Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox>Allow Specialists/Clinics to view</Checkbox>
          </Form.Item>
          <Form.Item label="Correspondence Type">
            <Select size="large" value="1">
              <Select.Option value="1">Option</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Date/Time">
            <DatePicker size="large" showTime />
          </Form.Item>
          <Form.Item label="Subject" required>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Content">
            <Input.TextArea size="large" rows={5} />
          </Form.Item>
          <Form.Item label="Receiver" required>
            <Radio.Group>
              <Radio>Case Manager</Radio>
              <Radio>Specialist</Radio>
              <Radio>MAG Staff</Radio>
            </Radio.Group>
            <Input size="large" value="VALUE FROM SELECTED RADIO" disabled />
          </Form.Item>
          <Form.Item label="Caller/Sender" required>
            <Radio.Group>
              <Radio>Case Manager</Radio>
              <Radio>Specialist</Radio>
              <Radio>MAG Staff</Radio>
            </Radio.Group>
            <Input size="large" value="VALUE FROM SELECTED RADIO" disabled />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ConversationModalForm;
