import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class Store {
  @observable loading = true;
  @observable loadingModule = true;
  @observable dataModule = null;
  @observable data = null;

  @observable filter = 'targetAudience';
  @observable filterCaseStage = -1;
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';
  @observable totalItems = 0;
  @observable status = 'All';

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchModule = (setTotalPage = false) => {
    this.loading = true;
    customFetch(`/TargetAudience/GetAll?keyword=${this.keyword}&filter=${this.status}`, {
      method: 'GET',
    }).then(
      action(data => {
        this.data = data;
        this.totalPage = data.sumPage;
        this.totalItems = data.sumItem;
        console.log(data);
        this.loading = false;
      }),
    );
  };

  @action fetchModuleFilter = () => {
    this.loadingModule = true;
    customFetch(`/TargetAudience/GetAll?keyword=`, {
      method: 'GET',
    }).then(
      action(data => {
        this.dataModule = data;
        this.loadingModule = false;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchModule();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchModule(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'ascending';
    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new Store();
