import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import SalesCallReportStore from './SalesCallReportStore';
import SalesCallReportToolbar from './SalesCallReportToolbar';
import SalesCallReportTable from './SalesCallReportTable';
import SalesCallModal from './SalesCallModal';

class SalesCallReport extends React.Component {
  componentDidMount() {
    SalesCallReportStore.fetchReport(true);
    SalesCallReportStore.fetchClient();
    SalesCallReportStore.fetchCampaign();
    SalesCallReportStore.fetchOfficer();
    SalesCallReportStore.fetchSearchStaff();
  }

  componentWillUnmount() {
    SalesCallReportStore.resetStore();
  }

  render() {
    const { loading, loadingStaff, loadingClient, open } = SalesCallReportStore;
    return (
      <div className={`Table-container}`}>
        <React.Fragment>
          <SalesCallReportToolbar />
          {loading || loadingStaff || loadingClient ? (
            <div className="Table-loading">
              <Spin size="large" />
            </div>
          ) : (
            <SalesCallReportTable />
          )}

          {open && <SalesCallModal />}
        </React.Fragment>
      </div>
    );
  }
}

export default observer(SalesCallReport);
