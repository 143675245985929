import dashboardStore from '@stores/dashboard';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import suppStore from './SuppStore';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'SuppNo' },
  { title: 'Request Date', sortKey: 'DateRequest' },
  { title: 'Original Case', sortkey: 'CaseNo' },
  { title: 'Claim No', sortKey: 'ClaimNo' },
  { title: 'Type of Claim' },
  { title: 'Claimant Name', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Case Manager', sortKey: 'CaseManager' },
  { title: 'Case Status', sortKey: 'CaseProgress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
];

@observer
class SuppTable extends React.Component {
  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Paperwork':
        return 1;
      case 'Cancellation':
        return 2;
      case 'Corespondence':
        return 4;
      default:
        return 0;
    }
  };
  openCaseDetail = (id, type) => () => {
    const caseNo = `Case S-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('SupplementaryCaseId', caseNo);
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    return setTimeout(() => {
      dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  openModalCaseProgress = (id, caseNo) => () => {
    suppStore.toggleModal(true, { id, caseNo })();
  };

  renderTableBody = () => {
    return suppStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item onClick={this.openCaseDetail(Id, 'CaseDetails')}>View Request Details</Menu.Item>
                <Menu.Item onClick={this.openCaseDetail(Id, 'Paperwork')}>View Case Documents</Menu.Item>
                <Menu.Item onClick={this.openCaseDetail(Id, 'Paperwork')}>Upload Dictations & Reports</Menu.Item>
                {/* <Menu.Item onClick={this.openCaseDetail(Id, 'Cancellation')}>
                  View Case Cancellation
                </Menu.Item> */}
                <Menu.Item onClick={this.openCaseDetail(Id, 'Corespondence')}>View Case Correspondence</Menu.Item>
              </Menu>
            }
          >
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id)}>
            {record.SuppNo}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.DateRequest
            ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.CaseNo}</Table.Cell>
        <Table.Cell>{record.ClaimNo}</Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.TypeOfClaim}</Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.Claimant}</Table.Cell>
        <Table.Cell>{record.Client}</Table.Cell>
        <Table.Cell>
          <h5 className="ma-0">{record.CaseManager}</h5>
          {record.IsDirect && <div>{record.CMEmail}</div>}
          {record.IsDirect && <div>{record.CMMobilePhone}</div>}
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={this.openModalCaseProgress(Id, record.SuppNo)}>
            {record.StatusStaffText}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));
  };

  handlePageClick = page => {
    suppStore.refetch({ curPage: page });
  };

  render() {
    const { loading, data } = suppStore;
    const colSpan = columns.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })}>
        <TableHeader
          columns={columns}
          sortColumn={suppStore.sortColumn}
          sortDirection={suppStore.sortDirection}
          onSort={suppStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? this.renderTableBody() : <TableEmptyMessage colSpan={colSpan} />}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={suppStore.curPage}
          totalItems={suppStore.totalItems}
          totalPage={suppStore.totalPage}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default SuppTable;
