import dashboardStore from '@stores/dashboard';
import { find } from 'lodash';
import { toJS } from 'mobx';
import Validator from 'validatorjs';
import customFetch from './customFetch';
import { showUnexpectedError } from './errors';

export function getDateTimestamp(date) {
  return +date.match(/\d+/)[0];
}

export function formatDate(dateStr, includeTime) {
  if (!dateStr) return '';
  includeTime = includeTime || false;

  if (typeof dateStr !== 'string') return '';
  if (dateStr.startsWith('/Date(-')) {
    dateStr = -getDateTimestamp(dateStr);
  } else if (dateStr.startsWith('/Date(')) {
    dateStr = getDateTimestamp(dateStr);
  }

  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const newDate = new Date(dateStr).toLocaleDateString('en-GB', options);
  let [day, month, year, time] = newDate.split(' ');
  year = year.replace(',', '');

  return !includeTime ? `${day} ${month}, ${year}` : `${day} ${month} ${year}, ${time}`;
}

export function calcPages(currentPage, totalPage) {
  const range = Math.min(totalPage, 10);
  const pages = [];

  const start = Math.min(totalPage + 1 - range, Math.max(1, currentPage - ((range / 2) | 0)));

  const end = start + range;
  for (let i = start; i < end; i++) {
    pages.push(i);
  }

  return pages;
}

export function getItemTextByKey(items, key) {
  var r = find(items, item => item.key === key);
  return r ? r.text : '';
}

function convertCollection(collections, labelKey = 'Text', defaultFirstItem = { Value: 0, Text: 'All', Count: 0 }) {
  const data = toJS(collections) || [];
  return []
    .concat(defaultFirstItem)
    .concat(data)
    .filter(opt => opt[labelKey] != null);
}

export function getRenderText(
  value,
  collections,
  key = 'Value',
  labelKey = 'Text',
  defaultFirstItem = { Value: 0, Text: 'All', Count: 0 },
  defaultText = 'All',
) {
  if (!value) return defaultText;
  const data = convertCollection(collections, key, labelKey, defaultFirstItem);
  const label = data.find(opt => opt[key] === value) ?? defaultFirstItem;
  if (!label[key]) {
    return label[labelKey];
  }

  return `${label[labelKey]}${label.Count ? ` (${label.Count})` : ''}`;
}

export function getRenderOptions(
  collections,
  callbackfn,
  labelKey = 'Text',
  defaultFirstItem = { Value: 0, Text: 'All', Count: 0 },
) {
  const data = convertCollection(collections, labelKey, defaultFirstItem);
  return data.map(callbackfn);
}

export function convertPhoneFaxNumber(value) {
  if (value) {
    const valueLength = value.length;
    if (valueLength < 3) {
      return value;
    } else if (valueLength >= 3 && valueLength <= 6) {
      return `${value.slice(0, 2)}-${value.slice(2, 6)}`;
    } else if (valueLength > 6 && valueLength <= 10) {
      return `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`;
    }
    return `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}-${value.slice(10)}`;
  }
  return value;
}

export function convertMobileNumber(value) {
  if (value) {
    const valueLength = value.length;
    if (valueLength < 3) {
      return value;
    } else if (valueLength >= 3 && valueLength <= 6) {
      return `${value.slice(0, 2)}-${value.slice(2, 6)}`;
    } else if (valueLength === 7) {
      return `${value.slice(0, 4)}-${value.slice(4, 7)}`;
    } else if (valueLength > 7 && valueLength <= 10) {
      return `${value.slice(0, 4)}-${value.slice(4, 7)}-${value.slice(7, 10)}`;
    }
    return `${value.slice(0, 4)}-${value.slice(4, 7)}-${value.slice(7, 10)}-${value.slice(10)}`;
  }
  return value;
}

export const ReplaceFileNameUpload = (
  serviceType,
  caseNumber,
  claimantFullname,
  doctorTitle,
  doctorFullName,
  inputDate,
  isRequireVideo,
  clinicName,
  number,
) => {
  var retStr = claimantFullname + ' - ' + doctorTitle + '.' + doctorFullName + ' - ' + inputDate;
  switch (serviceType) {
    case 'Assessment':
      retStr += ' - ';
      if (isRequireVideo) {
        retStr += 'telehealth';
      } else {
        retStr += clinicName;
      }
      retStr += ' - Case No:' + caseNumber;
      if (number > 0) {
        retStr += ' - ' + number;
      }
      break;
    case 'Supplementary':
    case 'FileReview':
      retStr = retStr + ' - Case No: ' + caseNumber;
      if (number > 0) {
        retStr += ' - ' + number;
      }
      break;
    default:
      break;
  }
  return retStr;
};

export const convertCamelCaseToUrl = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

export const getRouteParams = (routerSearch, routerState) => {
  const search = new URLSearchParams(routerSearch);
  const state = routerState;
  const params = {};

  for (let p of search.entries()) {
    params[p[0]] = p[1];
  }
  return {
    ...params,
    ...state,
  };
};
export const GetFileExtention = fileName => {
  const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
  return '.' + ext;
};

export const numberParser = value => value?.replace(/\$\s?|(,*)/g, '');

export const currencyFormatter = value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const rateFormatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const checkPhoneNumberMaxAndMin = (value, maxOrMin = true) => {
  const removeChar = numberParser(value);
  const validate = new Validator({ removeChar }, { removeChar: maxOrMin ? 'max:10' : 'min:6' });
  return !validate.passes()
    ? maxOrMin
      ? 'Must be less than or equal to 10 digits'
      : 'Must be more than or equal to 6 digits'
    : '';
};

export const tabInfo = {
  cm: '/view/add-edit-case-manager-2',
  specialist: '/view/add-edit-doctor-2',
  claimant: '/view/add-edit-claimant-2',
  clinic: '/view/add-edit-clinic-2',
  client: '/view/add-edit-client-2',
};

export const OpenCaseDetails = ({ tabName = '', id = 0, localStorageName = '', localStorageValue = '' }) => {
  const pathName = tabInfo[tabName];
  if (!tabName) {
    return;
  }
  if (id === null || id === undefined) {
    return showUnexpectedError();
  }
  localStorage.setItem(localStorageName, localStorageValue);
  dashboardStore.close(pathName);
  return setTimeout(() => {
    dashboardStore.open(`${pathName}?id=${id}`);
  });
};

export const getActivityType = typeLog => {
  switch (typeLog) {
    case 2:
      return 'Relationship Meeting';
    case 8:
      return 'Relationship Call';
    case 9:
      return 'Relationship Email';
    default:
      return '';
  }
};
export const getConversationLogContent = (objectId, serviceName = '') => {
  return customFetch(`/${serviceName}/ConversationLog_Content?id=${objectId}`, {
    method: 'GET',
  });
};

export const tripHtmlFromBody = htmlString => {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = htmlString;
  return tmp.textContent || tmp.innerText || '';
};
