import React from 'react';
import { Form, Input, DatePicker, Select, Checkbox } from 'antd';

class ActionRequiredForm extends React.Component {
  render() {
    return (
      <Form name="action-required">
        <Form.Item label="Action Subject" required>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Due Date">
          <DatePicker size="large" showTime />
        </Form.Item>
        <Form.Item label="PIC" required>
          <Select size="large" value="1">
            <Select.Option value="1">Option</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Action notes" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
        <div className="fluid-field checkbox-field">
          <Checkbox>Action completed</Checkbox>
        </div>
      </Form>
    );
  }
}

export default ActionRequiredForm;
