import React from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react';
import { action, toJS } from 'mobx';

import Modal from '../../../shared/Modal/';
import store from '../store';
import DragDropFile from './DragDropFile';
import moment from 'moment';

@observer
class UploadComponent extends React.Component {
  handleRemoveFile = id => {
    store.handleRemoveFile(id);
  };

  @action handleUpload = (fileList, reportType) => {
    store.clinicalNoteInfo = {
      ...toJS(store.clinicalNoteInfo),
    };

    const { functionCallback } = store.modalUploadParams;

    // this.props.functionCallback && this.props.functionCallback();
    const attachments = store.clinicalNoteInfo?.Attachments ? toJS(store.clinicalNoteInfo.Attachments) : [];
    const attachment = fileList.map(i => ({
      FileName: i.name,
      Id: 0,
      NumberOfPages: i.numberOfPages,
      ReportType: reportType,
      Title: i.title,
      CreatedDate: moment(),
    }));
    const newAttachments = [...attachment, ...attachments].map((i, idx) => ({
      ...i,
      key: idx,
    }));
    store.clinicalNoteInfo = {
      ...toJS(store.clinicalNoteInfo),
      Attachments: newAttachments,
    };
    if (this.props.functionCallback) {
      store.toggleModalModalUpload(false);
      this.props.functionCallback();
    }
    if (functionCallback) {
      store.toggleModalModalUpload(false);
      functionCallback(fileList, reportType);
    }
    return;
  };

  render() {
    const { isOpenModalUpload, modalUploadParams } = store;
    const {
      reportType,
      attachments,
      option,
      multiple,
      disabled,
      disabledDelete,
      uploadTitle,
      isViewMergeFile,
    } = modalUploadParams;

    const attachment_ = (store.clinicalNoteInfo.Attachments || []).filter(
      ({ ReportType }) => ReportType === reportType,
    );

    const title =
      attachment_ && !!attachment_.length
        ? `${attachment_.length} ${attachment_.length > 1 ? 'attachments' : 'attachment'} ${
            attachment_.length > 1 ? 'are' : 'is'
          } found`
        : !isViewMergeFile
        ? disabled
          ? 'No files found'
          : `Upload your attachment`
        : 'No Merge File Found';

    const rule = option || null;
    return (
      <Modal
        visible={isOpenModalUpload}
        width={600}
        onCancel={() => store.toggleModalModalUpload(false, {})}
        className="custom-modal"
        footer={
          <React.Fragment>
            <Button color="red" onClick={() => store.toggleModalModalUpload(false, {})}>
              <Icon name="close" /> Close
            </Button>
          </React.Fragment>
        }
        title={title}
      >
        <DragDropFile
          onUploadFile={this.handleUpload}
          onDeleteFile={this.handleRemoveFile}
          type={reportType}
          accept={rule ? rule.allowedExtensions : null}
          maxSize={rule ? parseInt(rule.maxFileSize.replace('MB')) : 50}
          multiple={multiple}
          disabled={disabled}
          attachments={attachments}
          disabledDelete={disabledDelete}
          uploadTitle={uploadTitle}
          isViewMergeFile={isViewMergeFile}
        />
      </Modal>
    );
  }
}
export default UploadComponent;
