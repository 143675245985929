import React from 'react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

function ModalNotification({ open, onCancel, modalParams }) {
  const { message, onOk } = modalParams;

  const renderConfirmMessage = () => <div className="modal-confirm-message">{message}</div>;

  const handleOnOkClick = () => {
    onOk && onOk();
    onCancel();
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button onClick={handleOnOkClick}>Close</Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalNotification;
