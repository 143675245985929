import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';

import TableToolbar from '../../shared/tableToolbar';
import CMReportStore from './CMReportStore';
import { Icon } from 'semantic-ui-react';
import dashboardStore from '../../../stores/dashboard';
import _ from 'lodash';

@observer
class DoctorReportsToolbar extends React.Component {
  renderOptionCM = () => {
    const { dataCM } = CMReportStore;
    return _.sortBy(dataCM, 'FirstName', 'asc').map(i => ({
      value: i.Id,
      text: `${i.Id} - ${i.Title} ${i.FirstName} ${i.LastName} - ${i.Email}`,
    }));
  };
  handleFilterAction = (_, { value }) => {
    CMReportStore.refetch({ CMId: value ? value : 0, keyword: '' });
  };
  handleChangeFromTo = (_, { value }) => {
    CMReportStore.refetch({ fromTo: value });
  };
  renderOptionCMText = val => {
    const { dataCM } = CMReportStore;
    return dataCM.filter(i => i.Id === val).map(i => `${i.Title} ${i.FirstName} ${i.LastName} - ${i.Email}`)[0];
  };
  handleSearchChange = (_, { searchQuery }) => {
    CMReportStore.refetchCM({ keyword: searchQuery });
  };
  onScroll = event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    const number = CMReportStore.numberSearch;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      CMReportStore.refetchCM({ numberSearch: number + 20 });
    }
  };
  handleClear = () => {
    CMReportStore.resetStore();
  };
  handleSubmit = e => {
    e.preventDefault();
    CMReportStore.fetchBookingHistory();
  };
  handleOpenCMSaleCalls = () => {
    dashboardStore.open('/view/sale-call-report');
  };
  handleExportCMLastBooking = () => {
    window.open('/Staff/ExportCMsNotBookedLast6Week');
  };

  handleExportFreeCR = () => {
    window.open('/Staff/ExportFreeBookingOffers', '_blank');
  };
  handleOpenModalExport = () => {
    return CMReportStore.toggleModal(true, { modalType: 'export' })();
  };
  render() {
    const { loadingCM, keyword, CMId, fromTo } = CMReportStore;
    return (
      <form className="CM-report-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <TableToolbar.SearchDropdown
              onScroll={this.onScroll}
              options={this.renderOptionCM()}
              loading={loadingCM}
              selectOnNavigation={false}
              onSearchChange={this.handleSearchChange}
              searchQuery={keyword}
              value={this.renderOptionCMText(CMId)}
              onChange={this.handleFilterAction}
              className="CM-Toolbar-input"
              selectOnBlur={false}
              clearable
              placeholder="Search CM booking history"
              icon={!CMId ? <Icon name="dropdown" /> : <Icon name="delete" />}
            />
            <TableToolbar.RangePicker
              placeholder={'From request date - To request date'}
              value={fromTo}
              className="Toolbar-range-picker"
              onChange={this.handleChangeFromTo}
            />
            <TableToolbar.SubmitButton className="Toolbar-highlight-button px-2" onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              style={{ marginLeft: '5px' }}
              className="negative px-2"
              onClick={this.handleClear}
            >
              Clear
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
      </form>
    );
  }
}

export default DoctorReportsToolbar;
