import customFetch from '../../../utils/customFetch';

export const preApprovedFundingTaken = model => {
  return customFetch('/Company/PreApprovedFundingTaken', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      model,
    }),
  });
};

export const getCheckPreApprovedDisbursment = (companyId = 0, isManual = false) => {
  return customFetch(`/Company/CheckPreApprovedDisbursment?companyId=${companyId}&isManual=${isManual}`, {
    method: 'GET',
  });
};

export const getSpecificLookupConfigByType = () => {
  return customFetch('/Staff/GetSpecificLookupConfigByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'job' }),
  });
};

export const getDisbursementType = () => {
  return customFetch('/MedicalDoctor/LookUpConfig_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      type: 'disbursement',
      numItemPerPage: 1000,
      curPage: 1,
      keyword: '',
    }),
  });
};
