import React from 'react';
import { Form, Input, notification, Spin, Select, DatePicker } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { formatDate } from '../../../utils/functions';
import moment from 'moment';
import './css.scss';
import Modal from '../../shared/Modal';
import store from './Store';
import customFetch from '@utils/customFetch';

const typeOptions = [
  { text: `AMA`, value: `AMA` },
  { text: `SIRA`, value: `SIRA` },
];
const statusOptions = [
  { value: 1, text: 'Active' },
  { value: 0, text: 'Inactive' },
];

@observer
class ConversationModalForm extends React.Component {
  state = {
    loading: true,
    dataObj: {
      Description: '',
      Type: 'AMA',
      EffectiveFrom: null,
      EffectiveTo: null,
      Status: 1,
      Id: 0,
    },
    loading: false,
  };
  handleSave = async () => {
    try {
      const { dataObj } = this.state;
      const params = { ...dataObj };
      if (params.Id === 0) {
        delete params.Id;
      }
      const res = await customFetch('/MZ900Calculations/SaveData', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(params),
      });
      if (res.status === 'success') {
        store.refetch({}, true);

        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data saved successfully',
        });
        return store.toggleModal(false, {})();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    } catch (err) {}
  };

  componentDidMount() {
    const { record, id } = store.modalParams;
    if (record && !!id) {
      const dataObj = {
        Id: id,
        Type: record.Type,
        Description: record.Description,
        Rate: record.Rate,
        EffectiveFrom: this.convertDate(record.EffectiveFrom),
        EffectiveTo: this.convertDate(record.EffectiveTo),
        Status: record.Status,
      };
      this.setState({ dataObj }, () => this.setState({ loading: false }));
    } else {
      const dataObj = {
        Id: 0,
        Description: '',
        Type: 'AMA',
        Status: 1,
      };
      this.setState({ dataObj }, () => this.setState({ loading: false }));
    }
  }

  handleFieldChange = fieldName => event => {
    const { dataObj } = this.state;
    const value = event ? (event.target ? event.target.value : event) : null;
    this.setState({
      dataObj: {
        ...dataObj,
        [fieldName]: value,
      },
    });
  };

  convertDate = dateString => {
    if (dateString) {
      if (typeof dateString === 'string') {
        if (dateString.search('/Date') !== -1) {
          const dateFormat = formatDate(dateString);
          return moment(dateFormat, 'DD MMM,YYYY');
        }
      } else {
        return moment(dateString, 'DD/MM/YYYY');
      }
    }
    return null;
  };

  render() {
    const { record, id } = store.modalParams;
    const { open } = store;
    const { dataObj, loading } = this.state;

    return (
      <Modal
        width={1200}
        onCancel={this.props.onCancel}
        visible={open}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSave}>
              Save
            </Button>
            <Button color="red" onClick={this.props.onCancel}>
              <Icon name="close" />
              Close
            </Button>
          </React.Fragment>
        }
        title={!id ? 'Add New Greeting Title' : record.Title}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form name="conversation" className="conversation-form">
            <Form.Item label="Type">
            <Select
                size="large"
                value={dataObj.Type}
                onChange={this.handleFieldChange('Type')}
                style={{ marginTop: '10px' }}
                optionFilterProp="children"
              >
                {typeOptions.map(i => (
                  <Select.Option value={i.value} key={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Description">
            <Input.TextArea
                rows={4}
                value={dataObj.Description}
                onChange={this.handleFieldChange('Description')}
              />
            </Form.Item>
            <Form.Item label="Rate">
              <Input value={dataObj.Rate} onChange={this.handleFieldChange('Rate')} />
            </Form.Item>
            <Form.Item label="Effective from">
              <DatePicker
                size="large"
                value={this.convertDate(dataObj.EffectiveFrom)}
                onChange={this.handleFieldChange('EffectiveFrom')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Effective to">
              <DatePicker
                size="large"
                value={this.convertDate(dataObj.EffectiveTo)}
                onChange={this.handleFieldChange('EffectiveTo')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Status">
              <Select
                size="large"
                value={dataObj.Status}
                onChange={this.handleFieldChange('Status')}
                style={{ marginTop: '10px' }}
                optionFilterProp="children"
              >
                {statusOptions.map(i => (
                  <Select.Option value={i.value} key={i.value}>
                    {i.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            
          </Form>
        )}
      </Modal>
    );
  }
}

export default ConversationModalForm;
