import './index.scss';

import { notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Dimmer, Form, Input, Loader, Radio, Segment, TextArea } from 'semantic-ui-react';
import * as api from '@stores/api';
import { isAdmin } from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import LMQStore from './LMQStore';

@observer
class FormAddEdit extends React.Component {
  handleChange = (_, { name, value }) => {
    const { dataClaim } = LMQStore;
    LMQStore.setFieldsValue({
      dataClaim: { ...dataClaim, [name]: value },
    });
  };

  handleChangeActive = () => {
    const { dataClaim } = LMQStore;
    const { isActive } = dataClaim;
    LMQStore.setFieldsValue({
      dataClaim: { ...dataClaim, isActive: !isActive },
    });
  };
  handSave = async () => {
    const { dataClaim } = LMQStore;
    let model = {};
    let url = '';
    model = {
      Description: dataClaim.descriptions,
      Id: dataClaim.id,
      IsActive: dataClaim.isActive,
      Name: dataClaim.name,
    };
    url = '/LMQ/SaveData';
    await customFetch(`${url}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(model),
    }).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          title: 'Success',
          message: 'Data has been saved successfully.',
        });
      }
    });
  };
  handleClose = () => {
    window.closeCurrentTab();
    LMQStore.onReset();
  };
  onClose = () => {
    return LMQStore.toggleModal(true, {
      modalType: 'confirm',
      message: 'All unsaved changes will be discarded. Do you want to continue?',
      onOk: () => this.handleClose(),
    })();
  };

  render() {
    const { dataClaim: data } = LMQStore;
    const canEdit = isAdmin();
    return (
      <Segment size="massive">
        <Dimmer>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Field>
            <label>Matter Type</label>
            <Input placeholder="Matter Type" value={data.name} name="name" onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder="Description"
              name="descriptions"
              className="text-area-description"
              onChange={this.handleChange}
              value={data.descriptions}
            />
          </Form.Field>
          <Form.Field>
            <label>Set Type Active</label>
            <Radio toggle checked={data.isActive} onChange={this.handleChangeActive} />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Button floated="right" className="negative" onClick={this.onClose}>
                Close
              </Button>
              {!api.isOnlySearch() && <Button floated="right" onClick={this.handSave} className="btn-primary-submit" disabled={!canEdit}>
                Save
              </Button>}
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default FormAddEdit;
