import './MailSearch.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Item, Visibility, Divider, Dropdown, Button, Checkbox, Icon } from 'semantic-ui-react';
import { notification } from 'antd';
import { isNil, map, filter } from 'lodash';
import moment from 'moment';
import ModalConfirm from '@components/shared/Modal/ModalConfirm';
import Store from './Store';
import { DeletedFolderId, Folders } from './constants';
import { stripHtml } from 'string-strip-html';

@observer
class MailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
    };
  }

  handleToggleSelectAll = () => {
    var newState = !this.state.selectAll;

    this.setState({
      selectAll: newState,
    });

    const newList = map(Store.mailData, m => {
      const newItem = { ...m };
      newItem.selected = newState;
      return newItem;
    });

    Store.setFieldsValue({ mailData: newList });
  };

  handleLoadMore = () => {
    Store.nextPage();
  };

  handleMailSelected = item => () => {
    Store.setFieldsValue({ selectedMail: item });
  };

  handleToggleConversationView = item => () => {
    if (item.isExpanded) {
      Store.showConversations(item, false);
    } else {
      Store.showConversations(item, true);
    }
  };

  handleMoveItem = (id, toFolder) => () => {
    Store.moveItems([id], toFolder);
  };

  handleOnSort = () => {
    const dir = Store.sortDirection === 'DESC' ? 'ASC' : 'DESC';
    Store.setFieldsValue({ sortDirection: dir });
    Store.fetchMails(true);
  };

  handleOnItemSelectionChanged = item => () => {
    const newList = map(Store.mailData, m => {
      if (m.id === item.id) {
        const newItem = { ...m };
        newItem.selected = !m.selected;
        return newItem;
      }
      return m;
    });
    Store.setFieldsValue({ mailData: newList });
  };

  handleDeleteSelectedItems = () => {
    const selectedItems = filter(Store.mailData, m => m.selected);
    if (selectedItems.length) {
      Store.setFieldsValue({ isConfirmModalOpen: true });
    } else {
      notification.destroy();
      notification.warning({
        message: 'Delete',
        description: 'Please select at least one item.',
        placement: 'topRight',
      });
    }

    this.setState({ selectAll: false });
  };

  handleOnConfirmDelete = () => {
    const selectedItems = filter(Store.mailData, m => m.selected);
    const itemIds = map(selectedItems, m => m.id);
    Store.moveItems(itemIds, DeletedFolderId);
  };

  handleOnCloseConfirmModal = () => {
    Store.setFieldsValue({ isConfirmModalOpen: false });
  };

  handleClearSelectedItems = () => {
    const newList = map(Store.mailData, m => {
      const newItem = { ...m };
      newItem.selected = false;

      return newItem;
    });
    this.setState({ selectAll: false });
    Store.setFieldsValue({ mailData: newList });
  };

  getCaseOwnerName = caseOwnerId => {
    if (!caseOwnerId) {
      return 'Unassigned';
    }
    const caseOwner = Store.assignedCaseOwnerOptions.find(i => i.value === caseOwnerId);
    return caseOwner ? caseOwner.fullName : '';
  };

  render() {
    const { mailData, selectedMail, selectedFolder, sortDirection, totalItems, isConfirmModalOpen } = Store;

    return (
      !isNil(mailData) && (
        <>
          <div className="action-bar">
            <div>
              <Checkbox
                className="select-all-emails"
                checked={this.state.selectAll}
                onChange={this.handleToggleSelectAll}
              />
              <Button basic content="Clear All" onClick={this.handleClearSelectedItems} />
              <Button basic content="Delete" onClick={this.handleDeleteSelectedItems} />
            </div>
            <div>
              <span className="item-count">{totalItems} items</span>
              <Button
                basic
                size="small"
                icon={sortDirection === 'DESC' ? 'sort content descending' : 'sort content ascending'}
                onClick={this.handleOnSort}
                title="Sort by Received Date"
              />
            </div>
          </div>
          <Divider />
          <div className="mail-list infinite-scroll">
            <Visibility onBottomVisible={this.handleLoadMore} once={false}>
              <Item.Group divided>
                {mailData.map(item => (
                  <React.Fragment key={item.id}>
                    <Item className={(selectedMail && item.id === selectedMail.id) || item.selected ? 'selected' : ''}>
                      <div className="item-selection">
                        <Checkbox checked={item.selected} onChange={this.handleOnItemSelectionChanged(item)} />
                      </div>
                      <div className="item-selection">
                        <Icon
                          title="Expand/Collapse"
                          name={
                            item.conversationCount > 1 ? (item.isExpanded ? 'chevron down' : 'chevron right') : null
                          }
                          onClick={this.handleToggleConversationView(item)}
                        />
                      </div>
                      <Item.Content verticalAlign="middle" onClick={this.handleMailSelected(item)}>
                        <Item.Header className={item.folder === DeletedFolderId ? 'negative' : ''}>
                          [{item.inquiryStatusText}] [{item.caseTypeText}] [{this.getCaseOwnerName(item.caseOwnerId)}]{' '}
                          {item.subject}
                        </Item.Header>
                        <Item.Meta>
                          {item.sender} -- {moment(item.createdDate).format('DD/MM/YYYY @ HH:mm')}
                        </Item.Meta>
                      </Item.Content>
                      <div className="actions">
                        <Dropdown button icon="ellipsis vertical" direction="left">
                          <Dropdown.Menu>
                            {map(Folders, f => (
                              <Dropdown.Item
                                key={f.value}
                                disabled={selectedFolder === f.value}
                                text={f.text}
                                onClick={this.handleMoveItem(item.id, f.value)}
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Item>
                    {item.isExpanded && item.conversations && (
                      <>
                        <Divider />
                        <Item.Group divided className="child-list">
                          {item.conversations.map(conv => (
                            <Item
                              key={conv.id}
                              className={
                                (selectedMail && conv.id === selectedMail.id) || conv.selected ? 'selected' : ''
                              }
                            >
                              <Item.Content
                                className="header-container"
                                verticalAlign="middle"
                                onClick={this.handleMailSelected(conv)}
                              >
                                <Item.Header className="sub-message-header">
                                  {stripHtml(conv.body).result.substr(0, 200)}
                                </Item.Header>
                                <Item.Meta>
                                  {conv.sender} -- {moment(item.createdDate).format('DD/MM/YYYY @ HH:mm')}
                                </Item.Meta>
                              </Item.Content>
                            </Item>
                          ))}
                        </Item.Group>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Item.Group>
            </Visibility>
          </div>
          <ModalConfirm
            open={isConfirmModalOpen}
            modalParams={{
              message: 'Do you want to delete selected cases?',
              onOk: this.handleOnConfirmDelete,
            }}
            onCancel={this.handleOnCloseConfirmModal}
          />
        </>
      )
    );
  }
}

export default MailList;
