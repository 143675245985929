import { action, observable } from 'mobx';
import moment from 'moment';

import customFetch from '../../../../utils/customFetch';

class SessionAvailabilityStore {
  @observable loading = false;
  @observable data = null;

  @observable fromTo = `${moment().format('DD/MM/YYYY')} - ${moment()
    .add(1, 'months')
    .format('DD/MM/YYYY')}`;
  @observable filter = null;

  @observable sortKey = 'Doctor';
  @observable sortColumn = 'Specialist';
  @observable sortDirection = 'descending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchAll();
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : null,
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').format('MM-DD-YYYY') : null,
    };
  };

  @action fetchAll = (setTotalPage = true) => {
    this.loading = true;

    const { fromDate, toDate } = this.getDateRange();

    customFetch(
      `/Calendar/GetSessionAvailability?state=${this.filter}&dateFrom=${fromDate}&dateTo=${toDate}&sortBy=${
        this.sortKey
      }&sortDirection=${this.sortDirection.replace('ending', '').toUpperCase()}&curPage=${
        this.curPage
      }&numItemPerPage=20`,
      {
        method: 'GET',
      },
    ).then(
      action(data => {
        this.data = data;
        this.totalPage = setTotalPage ? data.sumPage : this.totalPage;
        this.totalItems = setTotalPage ? data.sumItem : this.totalItems;
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchAll(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = null;

    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new SessionAvailabilityStore();
