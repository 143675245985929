import React from 'react';
import { Icon } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';

export const Upload = ({ multiple = false, onChange, onComplete, placeholder }) => {
  const { uploadFile } = DoctorDetailsStore;

  return (
    <div className="Upload-File">
      <label className="Button">
        <Icon name="upload" />
        <span>Upload</span>
        <input hidden type="file" multiple={multiple} onChange={onChange || uploadFile(onComplete)} />
      </label>
      <p>{placeholder}</p>
    </div>
  );
};

export const File = ({ name, downloadLink, onRemove }) => {
  return (
    <div className="Upload-File">
      <Icon name="file alternate" size="big" />
      <span className="FileName">{name}</span>
      <div className="Actions">
        <Icon name="download" onClick={() => window.open(downloadLink, '_blank')} />
        {onRemove && <Icon name="delete" color="red" onClick={onRemove} />}
      </div>
    </div>
  );
};

export const NoFile = () => {
  return (
    <div className="Upload-File">
      <Icon name="file alternate" size="big" />
      <span className="FileName">No file available</span>
    </div>
  );
};
