import { action, observable, autorun } from 'mobx';
import moment from 'moment';
import { notification } from 'antd';
import * as api from '../../../stores/api';

import {
  getCampaignInfo,
  getStaff,
  getConfigType,
  saveCaseManagerCampaign,
  saveAndSendPreivewCaseManagerCampaign,
  getListCampaignScheduleHistory,
} from './service';
import dashboardStore from '@stores/dashboard';

class MarketingCampaignStore {
  @observable loading = false;
  @observable data = {};
  @observable channels = [];
  @observable targetAudience = [];
  @observable targetStatus = [];
  @observable errors = {};

  @observable keyword = '';
  @observable staffs = [];
  @observable isOpenModalUpload = false;
  @observable modalUploadParams = {};

  @observable loadingHistory = false;
  @observable campaignHistoryId = null;
  @observable dataScheduleHistory = null;
  @observable curPageScheduleHistory = 1;
  @observable totalPageScheduleHistory = 0;
  @observable totalItemsScheduleHistory = 0;

  @observable open = false;
  @observable modalParams = {};

  renderAttachment = (Attachments = [], attachmentType) => {
    return Attachments.filter(i => i.AttachmentType === attachmentType);
  };
  removeAttachment = key => {
    const { Attachments = [] } = this.data;
    const newAttachments = Attachments.filter(i => i.key !== key);
    return this.setFieldsDataValue({ Attachments: newAttachments });
  };

  @action toggleModalModalUpload = (isOpenModalUpload, modalUploadParams = {}) => {
    this.isOpenModalUpload = isOpenModalUpload;
    this.modalUploadParams = modalUploadParams;
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action setFieldsDataValue = data => {
    Object.keys(data).forEach(key => {
      this.data[key] = data[key];
    });
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  showNotification = (message = '', description, typeNotification = 'error') => {
    return notification[typeNotification]({
      message: message,
      description: description,
      duration: 3,
    });
  };

  compareDate = (dateF, dateS) => {
    if (dateF && dateS) {
      return moment(dateF).isAfter(moment(dateS));
    }
    return false;
  };

  handleChangeDatePicker = fieldName =>
    action(event => {
      const _data = this.data;
      const value = event;
      if (fieldName === 'CampaignEndDate' && this.compareDate(this.data.CampaignDate, value)) {
        return this.showNotification('Error', 'Campaign Date must be after Campaign End Date.');
      }
      if (fieldName === 'CampaignDate' && this.compareDate(value, this.data.CampaignEndDate)) {
        return this.showNotification('Error', 'Campaign Date must be before Campaign End Date.');
      }
      if (fieldName === 'ScheduledEndDate' && this.compareDate(this.data.ScheduledStartDate, value)) {
        return this.showNotification('Error', 'Scheduled Start Date must be after Scheduled End Date.');
      }
      return (this.data = {
        ..._data,
        [fieldName]: value,
      });
    });

  handleChangeFieldSelectMultiple = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      return (this.data[fieldName] = value);
    });
  handleChangeFieldSelect = fieldName =>
    action(event => {
      const value = event;
      if (fieldName === 'ScheduledMethod') {
        if (value === 1) {
          const _data = this.data;
          this.data = {
            ..._data,
            ScheduledEndDate: null,
            [fieldName]: value,
          };
          return;
        }
      }
      return (this.data[fieldName] = value);
    });

  handleChangeFieldInput = fieldName =>
    action(event => {
      const value = event.target.value;
      if (fieldName === 'ResponsesRate' || fieldName === 'SuccessRate') {
        return (this.data[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, ''));
      }
      return (this.data[fieldName] = value);
    });

  handleChangeFieldCheckbox = fieldName =>
    action(event => {
      const value = event.target.checked;
      if (fieldName === 'NeedSendEmail') {
        return (this.data = {
          ...this.data,
          [fieldName]: value,
        });
      }
      if (fieldName === 'IsCancelSchedule') {
        if (value) {
          return this.toggleModalConfirm(true, {
            modalType: 'confirm',
            message: `Do you want to cancel current campaign scheduling?`,
            onOk: () => this.handlesCancelScheduleOK(),
          });
        } else {
          return (this.data = {
            ...this.data,
            CancelScheduleDate: moment(),
            [fieldName]: value,
          });
        }
      }
      return (this.data[fieldName] = value);
    });

  handlesCancelScheduleOK = () => {
    let newData = {
      ...this.data,
      CancelScheduleDate: moment(),
      IsCancelSchedule: !this.data.IsCancelSchedule,
    };
    this.setFieldsValue({ data: newData });
  };

  handleChangeFieldRadio = fieldName =>
    action(event => {
      const value = event.target.value;
      return (this.data[fieldName] = value);
    });

  handleChangeCKEditor = fieldName =>
    action(value => {
      return (this.data[fieldName] = value);
    });

  @action getCampaignDetails = (id = 0) => {
    this.loading = true;
    Promise.all([
      getCampaignInfo(id),
      getStaff(),
      getConfigType('TargetAudience'),
      getConfigType('Channel'),
      getConfigType('TargetStatus'),
    ])
      .then(([campaign, staffs, listAudiences, listChannels, listStatus]) => {
        if (!listChannels.itemList?.some(x => x.Id == campaign.objItem.Channel)) {
          listChannels.itemList = [
            ...listChannels.itemList,
            { Id: campaign.objItem.Channel, Label: campaign.objItem.ChannelLabel },
          ];
        }

        this.setFieldsValue({
          data: {
            ...campaign.objItem,
            Attachments: (campaign.objItem.Attachments || []).map((i, idx) => ({
              ...i,
              key: idx,
            })), //.filter((i, idx) => ({ ...i, key: idx })),
            TargetAudience: campaign.objItem.TargetAudience > 0 ? campaign.objItem.TargetAudience : null,
            Channel: campaign.objItem.Channel > 0 ? campaign.objItem.Channel : null,
            TargetStatus: campaign.objItem.TargetStatus > 0 ? campaign.objItem.TargetStatus : null,
            SuccessRate: campaign.objItem.SuccessRate > 0 ? campaign.objItem.SuccessRate : null,
            ResponsesRate: campaign.objItem.ResponsesRate > 0 ? campaign.objItem.ResponsesRate : null,
            Location: campaign.objItem.Location ? campaign.objItem.Location.split(';') : [],
            ScheduledMethod: campaign.objItem.ScheduledMethod === 0 ? 1 : campaign.objItem.ScheduledMethod,
          },
          loading: false,
          staffs: staffs.itemList,
          targetAudience: listAudiences.itemList,
          channels: listChannels.itemList,
          targetStatus: listStatus.itemList,
        });
        this.startReaction();
        if (!id) {
          this.setFieldsValue({
            data: {
              Id: 0,
              CampaignName: '',
              Channel: null,
              CampaignDate: moment(),
              CMResponses: false,
              CMResponsesDate: moment(),
              CMResponsesBy: '',
              Comment: '',
              TargetAudience: null,
              TargetStatus: null,
              Location: [],
              ResponsesRate: 0,
              SuccessRate: 0,
              CampaignContent: null,
              NeedSendEmail: false,
              Bcc: null,
              From: api.currentUser.data.id,
              To: null,
              Volume: null,
              Subject: null,
              ReplyTo: null,
              Attachments: [],
              Status: 0, // Status Planning
              Sender: '',
              CampaignSubject: '',
              ScheduledMethod: 1,
              ScheduledStartDate: null,
              ScheduledEndDate: null,
            },
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setFieldsDataValue({ loading: false });
      });
  };

  @action toggleModalConfirm = (isOpen, modalParams = {}) => {
    this.open = isOpen;
    this.modalParams = modalParams;
  };

  @action addErrors = (name, message) => {
    this.errors[name] = message;
  };

  @action removeError = name => {
    delete this.errors[name];
  };

  reactionValidate = () => {};

  startReaction = () => {
    this.reactionValidate = autorun(() => {
      const { data } = this;
      const {
        CampaignName,
        Channel,
        Subject,
        From,
        To,
        Attachments,
        CampaignDate,
        CampaignEndDate,
        NeedSendEmail,
        ScheduledStartDate,
      } = data;
      const contactListAtt = this.renderAttachment(Attachments, 3);
      if (!CampaignName) {
        this.addErrors('CampaignName', 'This field is required');
      } else {
        this.removeError('CampaignName');
      }
      if (NeedSendEmail && !ScheduledStartDate) {
        this.addErrors('ScheduledStartDate', 'This field is required');
      } else {
        this.removeError('ScheduledStartDate');
      }
      if (!Channel) {
        this.addErrors('Channel', 'This field is required');
      } else {
        this.removeError('Channel');
      }
      if ((!CampaignDate || !CampaignEndDate) && Channel !== 2738) {
        this.addErrors('CampaignDate', 'These fields are required');
      } else {
        this.removeError('CampaignDate');
      }
      if (!Subject && (Channel === 2738 || Channel === 2713)) {
        this.addErrors('Subject', 'This field is required');
      } else {
        this.removeError('Subject');
      }
      if (!From && Channel === 2713) {
        this.addErrors('From', 'This field is required');
      } else {
        this.removeError('From');
      }
      if (!To && Channel === 2713 && !contactListAtt.length) {
        this.addErrors('To', 'This field is required');
      } else {
        this.removeError('To');
      }
    });
  };

  fieldErrorName = {
    CampaignName: 'Campaign Name',
    Channel: 'Channel',
    Subject: 'Subject',
    From: 'From',
    To: 'To',
    CampaignDate: 'Start - End Date',
  };

  formatReqDate = date => {
    if (!date) {
      return null;
    } else if (typeof date === 'string' && date.startsWith('/Date')) {
      return moment(date).toISOString();
    }
    return date.toISOString();
  };

  handleSave = (type, isPreview = true) => {
    const { errors } = this;
    if (Object.keys(errors).length) {
      Object.keys(errors).map(i =>
        this.showNotification(
          'Error',
          `Please enter ${i === 'To' ? 'To or Attached Contact List' : this.fieldErrorName[i]}`,
        ),
      );
      return;
    }
    const params = {
      ...this.data,
      CampaignDate: this.formatReqDate(this.data.CampaignDate),
      CampaignEndDate: this.formatReqDate(this.data.CampaignEndDate),
      ScheduledStartDate: this.formatReqDate(this.data.ScheduledStartDate),
      ScheduledEndDate: this.formatReqDate(this.data.ScheduledEndDate),
    };
    this.setFieldsValue({ loading: true });
    if (!type) {
      saveCaseManagerCampaign(params)
        .then(response => {
          this.setFieldsValue({ loading: false });
          if (response.status === 'success') {
            this.showNotification('Success', 'Data has been saved successfully.', 'success');
            this.getCampaignDetails(response?.model?.Id);
          } else if (response.status === 'fail') {
            this.showNotification('Error', 'An error occurred, please try again');
          }
        })
        .catch(() => {});
    } else {
      saveAndSendPreivewCaseManagerCampaign(params, isPreview).then(
        action(response => {
          if (response.status === 'success') {
            this.setFieldsValue({ loading: false });
            this.getCampaignDetails(response?.model?.Id);
            if (!isPreview) {
              this.showNotification('Success', 'Send Campaign Successfully.', 'success');
              this.data.SentDateCampaignManual = response.sentDate;
              this.data.SentByCampaignManual = response.sentBy;
              this.data.NeedSendEmail = false;
            } else {
              this.showNotification('Success', 'Data has been saved successfully.', 'success');
            }
          } else if (response.status === 'fail') {
            this.showNotification('Error', 'An error occurred, please try again');
          }
        }),
      );
    }
  };

  @action resetStore = () => {
    this.errors = {};
    this.data = {};
    this.reactionValidate();
  };

  @action refetchScheduleHistory = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getListCampaignScheduleHistory(this.campaignHistoryId, this.curPageScheduleHistory, 20)]).then(
      action(([listCampaignHistory]) => {
        this.dataScheduleHistory = listCampaignHistory;
        this.loadingHistory = false;
        this.totalPageScheduleHistory = listCampaignHistory.sumPage;
        this.totalItemsScheduleHistory = listCampaignHistory.sumItem;
      }),
    );
  };
}

export default new MarketingCampaignStore();
