import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from './';

function ModalConfirm({ open, onCancel, modalParams, hideWarningIcon, footerCloseButton, avoidCancelOnSubmit }) {
  const { message, onOk, functionCallbackCancel } = modalParams;

  const renderConfirmMessage = () => (
    <div className="modal-confirm-message">
      {!hideWarningIcon && <Icon name="warning sign" color="yellow" />}
      {message}
    </div>
  );

  const handleOnOkClick = () => {
    onOk && onOk();
    !avoidCancelOnSubmit && onCancel();
  };

  const handleCancel = () => {
    functionCallbackCancel && functionCallbackCancel();
    onCancel();
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={handleCancel}
      footer={
        footerCloseButton ? (
          <Button color="blue" onClick={handleCancel}>
            OK
          </Button>
        ) : (
          <React.Fragment>
            <Button color="blue" onClick={handleOnOkClick}>
              <Icon name="check" /> Yes
            </Button>
            <Button color="red" onClick={handleCancel}>
              <Icon name="close" /> No
            </Button>
          </React.Fragment>
        )
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalConfirm;
