import {
  notification,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Radio,
  Select,
  Collapse,
} from 'antd';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import dashboard from '../../../stores/dashboard';
import store from './store';
import Upload from './FieldUpload';
import * as api from '@stores/api';
import moment from 'moment';
import { CONSTANTS } from './type';
import { Header, HeaderWithoutCheck } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';
import { checkExcludeTypeOfService } from './service';

@observer
class ClaimDetailsForm extends React.Component {
  addEditDoctor = doctor => {
    if (!doctor) {
      localStorage.removeItem('DoctorName');
      dashboard.close('/view/add-edit-doctor-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-doctor-2?pageGetter=addEditFR&fieldName=specialist&action=edit`);
      });
    } else {
      localStorage.setItem('DoctorName', doctor.FullName);
      dashboard.close('/view/add-edit-doctor-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-doctor-2?id=${doctor.Id}&pageGetter=addEditFR&fieldName=specialist&action=edit`);
      });
    }
  };

  addEditClient = (client, fieldName) => {
    if (!client) {
      localStorage.removeItem('CompanyName');
      dashboard.close('/view/add-edit-client-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-client-2?pageGetter=addEditFR&fieldName=${fieldName}`);
      });
    } else {
      localStorage.setItem('CompanyName', client.Name);
      dashboard.close('/view/add-edit-client-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-client-2?id=${client.Id}&pageGetter=addEditFR&fieldName=${fieldName}`);
      });
    }
  };

  addEditCM = (cm, fieldName) => {
    if (!cm) {
      localStorage.removeItem('CmName');
      dashboard.close('/view/add-edit-case-manager-2', true);
      const companyId =
        fieldName === 'bookingCaseManager'
          ? store.selectedCustomer
            ? store.selectedCustomer.Id
            : 0
          : store.selectedPCustomer
          ? store.selectedPCustomer.Id
          : 0;
      setTimeout(() => {
        dashboard.open(
          `/view/add-edit-case-manager-2?pageGetter=addEditFR&fieldName=${fieldName}&companyId=${companyId}`,
        );
      });
    } else {
      localStorage.setItem('CmName', cm.FullName);
      dashboard.close('/view/add-edit-case-manager-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-case-manager-2?id=${cm.Id}&pageGetter=addEditFR&fieldName=${fieldName}`);
      });
    }
  };

  addEditClaimant = claimant => {
    if (!claimant) {
      localStorage.removeItem('ClaimantName');
      dashboard.close('/view/add-edit-claimant-2', true);
      setTimeout(() => {
        dashboard.open('/view/add-edit-claimant-2?pageGetter=addEditFR&fieldName=claimant');
      });
    } else {
      localStorage.setItem('ClaimantName', claimant.FullName);
      dashboard.close('/view/add-edit-claimant-2', true);
      setTimeout(() => {
        dashboard.open(`/view/add-edit-claimant-2?id=${claimant.Id}&pageGetter=addEditFR&fieldName=claimant`);
      });
    }
  };

  handleSearch = key =>
    action(value => {
      store[key] = value;
    });

  handleScroll = key =>
    action(event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (scrollHeight <= scrollTop + 0.5 + clientHeight) {
        store[key] += 20;
      }
    });
  @action handleChangeObj = (_, event, fieldName) => {
    if (event) {
      const { data } = event.props;
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      if (fieldName === 'ClaimType' && data_.Id > 0 && store.fileReviewInfo.DoctorId) {
        notification.destroy();
        checkExcludeTypeOfService(store.fileReviewInfo.DoctorId, data_.Id, store.fileReviewInfo.ReportTypeId).then(
          resp => {
            if (resp.status === 'success' && resp.isExcludeService) {
              notification.warning({
                message: 'Error',
                description: `This specialist does not accept performing the required service. Please check with our staff`,
                duration: 15,
              });
            }
          },
        );
      }
      return (store.fileReviewInfo[fieldName] = data_);
    }
    return (store.fileReviewInfo[fieldName] = null);
  };

  renderDoctorProviderNote = doctorInfo => {
    if (!doctorInfo) return [];
    return [
      {
        no: doctorInfo.ProviderNumber,
        note: doctorInfo.ProviderNumberNote,
      },
      {
        no: doctorInfo.ProviderNumber2,
        note: doctorInfo.ProviderNumber2Note,
      },
      {
        no: doctorInfo.ProviderNumber3,
        note: doctorInfo.ProviderNumber3Note,
      },
      {
        no: doctorInfo.ProviderNumber4,
        note: doctorInfo.ProviderNumber4Note,
      },
    ];
  };

  render() {
    const { fileReviewInfo, setFieldValue, toggleCheckbox } = store;
    const { Doctor, Customer, PCustomer, Patient, SubCustomer } = fileReviewInfo;

    return (
      <Form name="action-required" className="grid-style">
        {!!fileReviewInfo.Id && api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 1
                <button className="field-actions" onClick={() => store.openModalHistory('PostFileReview')}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
              optionFilterProp="children"
              value={fileReviewInfo.AssignedStaffId || undefined}
              allowClear
              onChange={setFieldValue('AssignedStaffId')}
            >
              {store.staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <React.Fragment>
              Request Date
              {api.isAdminOrMagStaffOrAccounting() && (
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="File Review request confirmation will be sent to CMs (MAIL-85) and specialists (MAIL-86)"
                  position="top center"
                />
              )}
            </React.Fragment>
          }
        >
          <DatePicker
            size="large"
            format="DD/MM/YYYY"
            value={fileReviewInfo.RequestDate}
            onChange={setFieldValue('RequestDate')}
            disabled={fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None}
          />
        </Form.Item>
        <Divider className="fluid-field" />
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <HeaderWithoutCheck
                title="Assessing Specialist"
                text={
                  store.selectedDoctor
                    ? `${store.selectedDoctor.Title} ${store.selectedDoctor.FullName} - ${store.selectedDoctor.Specialty}`
                    : ''
                }
              />
            }
          >
            <Form.Item
              required={true}
              validateStatus={!fileReviewInfo.DoctorId ? 'error' : null}
              help={!fileReviewInfo.DoctorId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Specialist
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => this.addEditDoctor(0)}>Add Specialist</Menu.Item>
                          {fileReviewInfo.DoctorId && (
                            <Menu.Item onClick={() => this.addEditDoctor(store.selectedDoctor)}>
                              Edit Specialist
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">Add/Edit</button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                size="large"
                filterOption={false}
                disabled={store.isLockedPaperword}
                placeholder="Select a specialist"
                value={fileReviewInfo.DoctorId || undefined}
                onSearch={this.handleSearch('doctorsKeyword')}
                onPopupScroll={this.handleScroll('doctorsPerPage')}
                onChange={setFieldValue('DoctorId')}
              >
                {Doctor && !store.doctors.find(({ Id }) => Id === Doctor.Id) && (
                  <Select.Option style={{ display: 'none ' }} value={Doctor.Id}>
                    {Doctor.Id} - {Doctor.Title} {Doctor.FullName}{' '}
                    {api.isAdminOrMagStaffOrAccounting() ? '-' + Doctor.BusinessEmail : ''}
                  </Select.Option>
                )}
                {store.doctors.map(({ Id, FullName, Title, BusinessEmail }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {Title} {FullName} {api.isAdminOrMagStaffOrAccounting() ? '-' + BusinessEmail : ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Provider No">
              <Select
                size="large"
                value={fileReviewInfo.SelectedDoctorProviderNoIdx || null}
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={setFieldValue('SelectedDoctorProviderNoIdx')}
                disabled={!!fileReviewInfo.CaseNo || fileReviewInfo.isLocked || !fileReviewInfo.DoctorId}
              >
                {this.renderDoctorProviderNote(store.selectedDoctor).map(({ no, note }, idx) =>
                  no ? (
                    <Select.Option key={no} value={idx + 1}>
                      {no} {note ? ` - ${note}` : ''}
                    </Select.Option>
                  ) : null,
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Claimant Status">
              <Radio.Group
                value={fileReviewInfo.ClaimantStatus}
                disabled={store.isLockedPaperword}
                onChange={setFieldValue('ClaimantStatus')}
                className="clinic-type-radio-group"
              >
                <Radio value="0">Old Claimant</Radio>
                <Radio value="1">New Claimant</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Dictation and Report Typing by">
              <Radio.Group
                value={fileReviewInfo.DictationOption}
                onChange={setFieldValue('DictationOption')}
                disabled={store.isLockedReport}
                className="clinic-type-radio-group"
              >
                <Radio value={0}>Medicolegal Provider</Radio>
                <Radio value={1}>Specialist</Radio>
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <HeaderWithoutCheck
                title="Booking Client and CM"
                statuses={[
                  {
                    key: 'fr-bc',
                    text: store.selectedCustomer?.Name ? `Client: ${store.selectedCustomer.Name}` : '',
                  },
                  {
                    key: 'fr-bcm',
                    text: store.selectedCM?.FullName ? `Booking CM: ${store.selectedCM.FullName}` : '',
                  },
                ]}
              />
            }
          >
            <Form.Item
              required
              validateStatus={!store.selectedCustomer ? 'error' : null}
              help={!store.selectedCustomer ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking Client
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => this.addEditClient(0, 'bookingClient')}>Add Client</Menu.Item>
                          {fileReviewInfo.CustomerId && (
                            <Menu.Item onClick={() => this.addEditClient(store.selectedCustomer, 'bookingClient')}>
                              Edit Client
                            </Menu.Item>
                          )}
                          <Menu.Item onClick={() => store.openModalCompanyHistory()}>Recent Changes</Menu.Item>
                        </Menu>
                      }
                    >
                      <button className="field-actions">Add/Edit</button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                size="large"
                filterOption={false}
                placeholder="Select a client"
                value={fileReviewInfo.CustomerId || undefined}
                onSearch={this.handleSearch('companiesKeyword')}
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() || fileReviewInfo.CaseFlow > api.frStatusEnum.REPORT_COMPLETED
                }
                onPopupScroll={this.handleScroll('companiesPerPage')}
                onChange={setFieldValue('CustomerId')}
                allowClear
              >
                {Customer && !store.companies.find(({ Id }) => Id === Customer.Id) && (
                  <Select.Option style={{ display: 'none' }} value={Customer.Id}>
                    {Customer.Id} - {Customer.Name} - {Customer.StateName}
                  </Select.Option>
                )}
                {store.companies.map(({ Id, Name, StateName }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {Name} - {StateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              validateStatus={!store.selectedCM ? 'error' : null}
              help={!store.selectedCM ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking CM
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => this.addEditCM(0, 'bookingCaseManager')}>
                            Add Case Manager
                          </Menu.Item>
                          {fileReviewInfo.CaseManagerId && (
                            <Menu.Item onClick={() => this.addEditCM(store.selectedCM, 'bookingCaseManager')}>
                              Edit Case Manager
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">Add/Edit</button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                placeholder="Select a case manager"
                onSearch={this.handleSearch('companyCMsKeyword')}
                onPopupScroll={this.handleScroll('companyCMsPerPage')}
                value={fileReviewInfo.CaseManagerId || undefined}
                onChange={setFieldValue('CaseManagerId')}
                allowClear
                disabled={
                  !api.isAdminOrMagStaffOrAccounting() || fileReviewInfo.CaseFlow > api.frStatusEnum.REPORT_COMPLETED
                }
              >
                {store.companyCMs.map(({ Id, FullName, Email }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Booking Solicitor">
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                placeholder="Select a solicitor"
                disabled={fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None || api.isDoctor() || api.isTypist()}
                value={fileReviewInfo.BookingSolicitorId || undefined}
                onChange={setFieldValue('BookingSolicitorId')}
                onSearch={this.handleSearch('companySolicitorKeyword')}
                onPopupScroll={this.handleScroll('companySolicitorPerPage')}
                allowClear
              >
                {store.companySolicitor.map(({ Id, FullName, Email }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label=""></Form.Item>
            {api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() ? (
              <React.Fragment>
                <Form.Item label="Sub-Booking Client">
                  <Select
                    showSearch
                    size="large"
                    filterOption={false}
                    placeholder="Select a client"
                    value={fileReviewInfo.SubCustomerId || undefined}
                    onSearch={this.handleSearch('subCompaniesKeyword')}
                    onPopupScroll={this.handleScroll('subCompaniesPerPage')}
                    onChange={setFieldValue('SubCustomerId')}
                    allowClear
                    disabled={api.isCaseManager()}
                  >
                    {SubCustomer && !store.companies.find(({ Id }) => Id === SubCustomer.Id) && (
                      <Select.Option style={{ display: 'none' }} value={SubCustomer.Id}>
                        {SubCustomer.Id} - {SubCustomer.Name} - {SubCustomer.StateName}
                      </Select.Option>
                    )}
                    {store.companies.map(({ Id, Name, StateName }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Sub-Booking CM / Solicitor">
                  <Select
                    showSearch
                    mode="multiple"
                    showArrow
                    disabled={
                      fileReviewInfo.isLocked ||
                      api.isCaseManager() ||
                      fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                    }
                    size="large"
                    optionFilterProp="children"
                    placeholder="Select a case manager"
                    value={toJS(fileReviewInfo.SubCaseManagerIds)}
                    onChange={setFieldValue('SubCaseManagerIds')}
                    allowClear
                  >
                    {store.subCMs.map(({ Id, FullName, Email }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {FullName} - {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </React.Fragment>
            ) : (
              <div />
            )}
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <span>
                      <span>
                        Paying Client
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item onClick={() => this.addEditClient(0, 'payingClient')}>Add Client</Menu.Item>
                                {fileReviewInfo.PCustomerId && (
                                  <Menu.Item
                                    onClick={() => this.addEditClient(store.selectedPCustomer, 'payingClient')}
                                  >
                                    Edit Client
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <button className="field-actions">Add/Edit</button>
                          </Dropdown>
                        )}
                        {':'}
                      </span>
                      <Checkbox
                        className="ml-1 special-checkbox"
                        checked={fileReviewInfo.PCSameBookingClient}
                        onChange={toggleCheckbox('PCSameBookingClient')}
                      >
                        Same as Booking Client
                      </Checkbox>
                    </span>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    filterOption={false}
                    placeholder="Select a client"
                    value={fileReviewInfo.PCustomerId || undefined}
                    onSearch={this.handleSearch('companiesKeyword')}
                    onPopupScroll={this.handleScroll('companiesPerPage')}
                    disabled={
                      fileReviewInfo.isLocked ||
                      api.isCaseManager() ||
                      fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                      fileReviewInfo.PCSameBookingClient
                    }
                    onChange={setFieldValue('PCustomerId')}
                    allowClear
                  >
                    {PCustomer && !store.companies.find(({ Id }) => Id === PCustomer.Id) && (
                      <Select.Option style={{ display: 'none' }} value={PCustomer.Id}>
                        {PCustomer.Id} - {PCustomer.Name}
                      </Select.Option>
                    )}
                    {store.companies.map(({ Id, Name, StateName }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="posstion-IsTypist-label"
                  label={
                    <React.Fragment>
                      <span style={{ marginRight: '10px' }}>
                        Paying CM
                        {api.isAdminOrMagStaffOrAccounting() && (
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item onClick={() => this.addEditCM(0, 'payingCaseManager')}>
                                  Add Case Manager
                                </Menu.Item>
                                {fileReviewInfo.PCaseManagerId && (
                                  <Menu.Item onClick={() => this.addEditCM(store.selectedPCM, 'payingCaseManager')}>
                                    Edit Case Manager
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <button className="field-actions">Add/Edit</button>
                          </Dropdown>
                        )}
                        {':'}
                      </span>
                      <Checkbox
                        className="ml-1 special-checkbox"
                        checked={fileReviewInfo.PMSameBookingCM}
                        onChange={toggleCheckbox('PMSameBookingCM')}
                      >
                        Same as Booking CM
                      </Checkbox>
                    </React.Fragment>
                  }
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    placeholder="Select a case manager"
                    value={fileReviewInfo.PCaseManagerId || undefined}
                    onChange={setFieldValue('PCaseManagerId')}
                    allowClear
                    disabled={
                      fileReviewInfo.isLocked ||
                      api.isCaseManager() ||
                      fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                      fileReviewInfo.PMSameBookingCM
                    }
                  >
                    {store.itemToArray(store.selectedPCM, store.pCompanyCMs, 'Id').map(({ Id, FullName, Email }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id} - {FullName} - {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item label="Purchase Order No">
              <Input
                size="large"
                value={fileReviewInfo.PurchaseOrderNo}
                disabled={
                  !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  api.isTypist()
                }
                onChange={setFieldValue('PurchaseOrderNo')}
              />
            </Form.Item>
            <Form.Item label="Authorised Purchase Amount">
              <InputNumber
                size="large"
                disabled={
                  !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None ||
                  api.isTypist()
                }
                allowClear
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={fileReviewInfo.AuthorisedPurchaseAmount}
                onChange={setFieldValue('AuthorisedPurchaseAmount')}
              />
            </Form.Item>{' '}
            <Form.Item
              className="posstion-IsTypist-label"
              label={
                <React.Fragment>
                  Report Receiving Contact 1:
                  {!api.isDoctor() && (
                    <Checkbox
                      className="ml-1 special-checkbox"
                      checked={fileReviewInfo.F_RDCSameBookingCM}
                      onChange={toggleCheckbox('F_RDCSameBookingCM')}
                    >
                      Same as Booking CM
                    </Checkbox>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                placeholder="Select a case manager"
                value={fileReviewInfo.F_RDCCaseManagerId || undefined}
                onChange={setFieldValue('F_RDCCaseManagerId')}
                disabled={
                  store.isLockReportCompleted_CM ||
                  fileReviewInfo.F_RDCSameBookingCM ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
                allowClear
              >
                {store.companyCMs.map(({ Id, FullName, Email }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Report Receiving Contact 2">
              <Select
                showSearch
                size="large"
                filterOption={false}
                placeholder="Select a case manager"
                disabled={store.isLockReportCompleted_CM}
                value={fileReviewInfo.S_RDCCaseManagerId || undefined}
                onSearch={this.handleSearch('CMsKeyword')}
                onPopupScroll={this.handleScroll('CMsPerPage')}
                onChange={setFieldValue('S_RDCCaseManagerId')}
                allowClear
              >
                {store.CMs.map(({ Id, FullName, Email }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <Form.Item label="Additional Service Supplier">
                <Select
                  showSearch
                  size="large"
                  optionFilterProp="children"
                  placeholder="Select a supplier"
                  value={fileReviewInfo.AdditionalServiceSupplierId || undefined}
                  onChange={setFieldValue('AdditionalServiceSupplierId')}
                  onSearch={this.handleSearch('supplierKeyword')}
                  onPopupScroll={this.handleScroll('supplierPerPage')}
                  allowClear
                  disabled={
                    fileReviewInfo.isLocked ||
                    api.isCaseManager() ||
                    fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                  }
                >
                  {store.itemToArray(store.AdditionalServiceSupplierObj, store.suppliers, 'Id').map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.Title} {i.FullName} - {i.BusinessEmail}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <HeaderWithoutCheck
                title="Claimant Info"
                text={
                  store.selectedPatient
                    ? `${store.selectedPatient.FullName} - DoB: ${renderDate(
                        store.selectedPatient.DOB ?? '',
                        false,
                        false,
                      )}`
                    : ''
                }
              />
            }
          >
            <Form.Item
              required
              validateStatus={!store.selectedPatient ? 'error' : null}
              help={!store.selectedPatient ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Claimant
                  {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => this.addEditClaimant(0)}>Add Claimant</Menu.Item>
                          {fileReviewInfo.PatientId && (
                            <Menu.Item onClick={() => this.addEditClaimant(store.selectedPatient)}>
                              Edit Claimant
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">Add/Edit</button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                showSearch
                size="large"
                filterOption={false}
                placeholder="Select a claimant"
                value={fileReviewInfo.PatientId || undefined}
                onSearch={this.handleSearch('claimantsKeyword')}
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
                onPopupScroll={this.handleScroll('claimantsPerPage')}
                onChange={setFieldValue('PatientId')}
                allowClear
              >
                {Patient && !store.claimants.find(({ Id }) => Id === Patient.Id) && (
                  <Select.Option style={{ display: 'none' }} value={Patient.Id}>
                    {Patient.Id} - {Patient.Title} {Patient.FullName} - DOB:{' '}
                    {Patient.DOB && Patient.DOB.format('DD/MM/YYYY')}
                  </Select.Option>
                )}
                {store.claimants.map(({ Id, Title, FullName, DOB }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {Title} {FullName} - {DOB && DOB.format('DD/MM/YYYY')}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Birth">
              <Input size="large" disabled value={renderDate(store.selectedPatient?.DOB ?? '', false, false)} />
            </Form.Item>
            <Form.Item label="Mobile phone">
              <Input size="large" disabled value={store.selectedPatient?.Telephone ?? ''} />
            </Form.Item>
            <Form.Item label="Email">
              <Input size="large" disabled value={store.selectedPatient?.Email ?? ''} />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            header={
              <HeaderWithoutCheck
                title="Claim No - Claim Type"
                text={fileReviewInfo.ClaimantNo?.length > 0 ? `Claim No: ${fileReviewInfo.ClaimantNo}` : ''}
              />
            }
          >
            <Form.Item
              label="Reference/Claim No"
              required
              validateStatus={!fileReviewInfo.ClaimantNo ? 'error' : null}
              help={!fileReviewInfo.ClaimantNo ? 'This field is required' : ''}
            >
              <Input
                size="large"
                value={fileReviewInfo.ClaimantNo || ''}
                onChange={setFieldValue('ClaimantNo')}
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
              />
            </Form.Item>
            <Form.Item label="Client File No">
              <Input
                size="large"
                disabled={
                  (fileReviewInfo.isLocked || fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None) &&
                  !!fileReviewInfo.Id
                }
                value={fileReviewInfo.ClientRequestNo}
                onChange={setFieldValue('ClientRequestNo')}
              />
            </Form.Item>
            <Form.Item
              required
              label="Type of Claim"
              validateStatus={!fileReviewInfo.ClaimType?.Id ? 'error' : null}
              help={!fileReviewInfo.ClaimType?.Id ? 'This field is required' : ''}
            >
              <Select
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
                showSearch
                size="large"
                optionFilterProp="children"
                allowClear
                placeholder="Select a claim type"
                value={fileReviewInfo.ClaimType?.Id || undefined}
                onChange={(value, event) => this.handleChangeObj(value, event, 'ClaimType')}
              >
                {store.itemToArray(fileReviewInfo.ClaimType, store.claimTypes, 'Id').map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              label="Type of Report"
              validateStatus={!fileReviewInfo.ReportTypeId ? 'error' : null}
              help={!fileReviewInfo.ReportTypeId ? 'This field is required' : ''}
            >
              <Select
                showSearch
                size="large"
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
                allowClear
                optionFilterProp="children"
                placeholder="Select a report type"
                value={fileReviewInfo.ReportTypeId || undefined}
                onChange={setFieldValue('ReportTypeId')}
              >
                {store.itemToArray(fileReviewInfo.ReportType, store.reportTypes, 'Id').map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Name}
                  </Select.Option>
                ))}
                {/* {store.reportTypes.map(({ Id, Name }) => (
                  <Select.Option key={Id} value={Id}>
                    {Name}
                  </Select.Option>
                ))} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date of Injury"
              validateStatus={
                store.compareDate(fileReviewInfo.InjuriedDate, !!fileReviewInfo.InjuriedDate ? moment() : null)
                  ? 'error'
                  : null
              }
              help={
                store.compareDate(fileReviewInfo.InjuriedDate, !!fileReviewInfo.InjuriedDate ? moment() : null)
                  ? 'Date of Injury cannot be a future date'
                  : ''
              }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={fileReviewInfo.InjuriedDate}
                onChange={setFieldValue('InjuriedDate')}
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
              />
            </Form.Item>
            <Form.Item label="Injury Description" className="fluid-field">
              <Input.TextArea
                rows={3}
                value={fileReviewInfo.InjuryDescription}
                onChange={setFieldValue('InjuryDescription')}
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
              />
            </Form.Item>
            <Form.Item label="Employer Of Claimant">
              <Input
                size="large"
                disabled={
                  (fileReviewInfo.isLocked && fileReviewInfo.Id > 0) ||
                  fileReviewInfo.CaseStatus !== CONSTANTS.CaseStatus_None
                }
                value={fileReviewInfo.EmployerOfClaimant}
                onChange={setFieldValue('EmployerOfClaimant')}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="5"
            header={
              <Header
                title="IRO/ILARS"
                isChecked={fileReviewInfo.WifroIlars}
                text={
                  <React.Fragment>
                    IRO Funded{' '}
                    {fileReviewInfo.IROFundingApproved && (
                      <span>
                        {' '}
                        - <i aria-hidden="true" className="green check icon"></i> Funding Approved
                      </span>
                    )}
                  </React.Fragment>
                }
                isRequired={false}
              />
            }
          >
            <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={fileReviewInfo.WifroIlars}
                onChange={toggleCheckbox('WifroIlars')}
                className="checkbox-session"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  fileReviewInfo.CaseFlow === 11 ||
                  fileReviewInfo.CaseFlow === 12 ||
                  fileReviewInfo.CaseFlow === 13
                }
              >
                Report Funded by IRO/ILARS
              </Checkbox>
            </div>
            <Form.Item>
              <Checkbox
                style={{ marginTop: '13px' }}
                checked={fileReviewInfo.IROFundingApproved}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !fileReviewInfo.WifroIlars}
                onChange={toggleCheckbox('IROFundingApproved')}
              >
                IRO Funding Approved
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Documents will not be sent to specialist until IRO funding approved (Mail-168)"
                  position="top center"
                />{' '}
                {!!fileReviewInfo.IROFundingApprovedDate && fileReviewInfo.IROFundingApproved && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {fileReviewInfo.IROFundingApprovedDate
                        ? renderDate(fileReviewInfo.IROFundingApprovedDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {fileReviewInfo.IROFundingApprovedBy ? ` - By ${fileReviewInfo.IROFundingApprovedBy}` : ''}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item label={'IRO Funding Approval Date'} required={fileReviewInfo.IROFundingApproved}>
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={fileReviewInfo.IROFundingApprovalDate}
                onChange={setFieldValue('IROFundingApprovalDate')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !fileReviewInfo.WifroIlars}
              />
            </Form.Item>
            <Form.Item label="Attach Funding Approval (Only 1 document allowed)" className="add-file-container">
              <Upload
                multiple
                type={18}
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  fileReviewInfo.CaseFlow === 11 ||
                  fileReviewInfo.CaseFlow === 12 ||
                  fileReviewInfo.CaseFlow === 13 ||
                  !fileReviewInfo.WifroIlars
                }
                disabledDelete={fileReviewInfo.isLocked}
              />
            </Form.Item>
            <Form.Item label="ILARS Ref">
              <Input
                size="large"
                value={fileReviewInfo.IlarsRef}
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  fileReviewInfo.CaseFlow === 11 ||
                  fileReviewInfo.CaseFlow === 12 ||
                  fileReviewInfo.CaseFlow === 13 ||
                  !fileReviewInfo.WifroIlars
                }
                onChange={setFieldValue('IlarsRef')}
              />
            </Form.Item>
            <Form.Item label="Billing Item Code">
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                value={fileReviewInfo.IMSApprovalCode || undefined}
                onChange={setFieldValue('IMSApprovalCode')}
                allowClear
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  fileReviewInfo.CaseFlow === 11 ||
                  fileReviewInfo.CaseFlow === 12 ||
                  fileReviewInfo.CaseFlow === 13 ||
                  !fileReviewInfo.WifroIlars
                }
              >
                {store.imsCodes.map(({ Id, PaymentClassificationCode, ServiceDescription }) => (
                  <Select.Option key={Id} value={PaymentClassificationCode}>
                    {PaymentClassificationCode} - {ServiceDescription}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="IRO Principal Lawyer">
              <Select
                showSearch
                size="large"
                optionFilterProp="children"
                value={fileReviewInfo.IlarsWiroSolicitor || undefined}
                onChange={setFieldValue('IlarsWiroSolicitor')}
                allowClear
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  fileReviewInfo.CaseFlow === 11 ||
                  fileReviewInfo.CaseFlow === 12 ||
                  fileReviewInfo.CaseFlow === 13 ||
                  !fileReviewInfo.WifroIlars
                }
              >
                {fileReviewInfo.IlarsWiroSolicitorObj &&
                  !(store.lawyers || []).find(i => i.Id == fileReviewInfo.IlarsWiroSolicitorObj.Id) && (
                    <Select.Option
                      key={fileReviewInfo.IlarsWiroSolicitorObj.Id}
                      value={fileReviewInfo.IlarsWiroSolicitorObj.Id}
                      style={{ display: 'none' }}
                    >
                      {fileReviewInfo.IlarsWiroSolicitorObj.Id} - {fileReviewInfo.IlarsWiroSolicitorObj.FullName}
                    </Select.Option>
                  )}
                {store.lawyers.map(({ Id, FullName }) => (
                  <Select.Option key={Id} value={Id}>
                    {Id} - {FullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Staff Booking Notes
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Only staff can see/update staff notes."
                  position="top center"
                />
              </React.Fragment>
            }
          >
            <Input.TextArea
              rows={3}
              onChange={setFieldValue('ClaimReqBookingComment')}
              value={fileReviewInfo.ClaimReqBookingComment}
              disabled={api.isTypist()}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager() || api.isDoctor()) && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                CM Booking Notes to Staff/Specialist
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Booking CM can add notes to staff/specialist. Specialist can view notes."
                  position="top center"
                />
              </React.Fragment>
            }
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              rows={3}
              disabled={api.isDoctor()}
              value={fileReviewInfo.CMNotes}
              onChange={setFieldValue('CMNotes')}
            />
          </Form.Item>
        )}
        {(api.isAdminOrMagStaffOrAccounting() || api.isDoctor()) && (
          <Form.Item
            className="fluid-field"
            label={
              <React.Fragment>
                Specialist Notes to Staff
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Specialist can add/update notes. Only staff view specialist notes."
                  position="top center"
                />
              </React.Fragment>
            }
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              rows={3}
              value={fileReviewInfo.SpecialistNotes}
              onChange={setFieldValue('SpecialistNotes')}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default ClaimDetailsForm;
