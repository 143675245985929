import React from 'react';
import { Form, Radio, Input, Checkbox, DatePicker } from 'antd';

class RequestCancellationStatus extends React.Component {
  render() {
    return (
      <Form name="request-cancellation-status">
        <Form.Item label="Case Status" className="fluid-field">
          <Radio.Group>
            <Radio>None</Radio>
            <Radio>Cancelled No Fee</Radio>
            <Radio>Cancelled With Fee</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Reasons" className="fluid-field">
          <Radio.Group>
            <Radio>The request of the Case Manager</Radio>
            <Radio>Doctor being unable to undertake assessment</Radio>
          </Radio.Group>
        </Form.Item>
        <div className="fluid-field checkbox-field">
          <Checkbox>Notify case cancellation to booking CM</Checkbox>
        </div>
        <Form.Item label="Date of Cancellation">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Comments" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    );
  }
}

export default RequestCancellationStatus;
