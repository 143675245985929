import customFetch from '../../../utils/customFetch';

export const getAllData = (
  keyword = '',
  curPage = 1,
  numItemPerPage = 20,
  businessType = 0,
  state = 0,
  category = 0,
  clientId = null,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/TalkingPoint/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      BusinessType: businessType,
      State: state,
      Category: category,
      SortBy: sortBy,
      SortDirection: sortDirection,
      ClientId: clientId,
    }),
  });
};

export const getCities = () => {
  return customFetch('/Company/City_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      countryId: 16,
    }),
  });
};

export const getAllCategories = () => {
  return customFetch('/TalkingPointCategory/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: '',
      CurPage: 1,
      NumItemPerPage: 1000,
    }),
  });
};
