import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Dropdown, Form, Input, Segment } from 'semantic-ui-react';

import DoctorDetailsStore from './DoctorDetailsStore';

@observer
class Address extends React.Component {
  componentDidMount() {
    this.fetchCities(DoctorDetailsStore.dataDoctor.CountryId);
    this.fetchMailingCities(DoctorDetailsStore.dataDoctor.CorrespondenceCountryId);
  }

  fetchCities = (countryId = 16) => {
    DoctorDetailsStore.fetchCity(countryId).then(
      action(res => {
        if (res.status === 'success') {
          DoctorDetailsStore.dataCity = res.itemList;
        } else {
          DoctorDetailsStore.dataCity = [];
        }
      }),
    );
  };
  fetchMailingCities = (countryId = 16) => {
    DoctorDetailsStore.fetchCity(countryId).then(
      action(res => {
        if (res.status === 'success') {
          DoctorDetailsStore.dataMailingCity = res.itemList;
        } else {
          DoctorDetailsStore.dataMailingCity = [];
        }
      }),
    );
  };

  @action setCountryId = (_, { value }) => {
    DoctorDetailsStore.dataDoctor.CountryId = value;
    DoctorDetailsStore.dataDoctor.CityId = 0;
    this.fetchCities(value);
  };

  @action setMailingCountryId = (_, { value }) => {
    DoctorDetailsStore.dataDoctor.CorrespondenceCountryId = value;
    DoctorDetailsStore.dataDoctor.CorrespondenceCityId = 0;
    this.fetchMailingCities(value);
  };

  @action toggleSameAsOfficeAddress = (_, { checked }) => {
    const { Address, District, Postcode, CityId, CountryId } = DoctorDetailsStore.dataDoctor;
    this.setState({ isSameAsOfficeAddress: checked });
    if (checked) {
      DoctorDetailsStore.dataDoctor.CorrespondenceAddress = Address;
      DoctorDetailsStore.dataDoctor.CorrespondenceDistrict = District;
      DoctorDetailsStore.dataDoctor.CorrespondencePostcode = Postcode;
      DoctorDetailsStore.dataDoctor.CorrespondenceCityId = CityId;
      DoctorDetailsStore.dataDoctor.CorrespondenceCountryId = CountryId;
      this.fetchMailingCities(CountryId);
    }
  };

  render() {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    return (
      <Form>
        <Segment className="Segment-View">
          <Form.Field>
            <label className="Form-Group-label">Office Address</label>
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label className="Form-Field-label">Street address</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.Address || 'None'}</span>
                </div>
              ) : (
                <Input name="Address" value={dataDoctor.Address || ''} onChange={setFieldValue} />
              )}
            </Form.Field>
            <Form.Field>
              <label className="Form-Field-label">Suburb</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.District || 'None'}</span>
                </div>
              ) : (
                <Input name="District" value={dataDoctor.District || ''} onChange={setFieldValue} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label className="Form-Field-label">Postcode</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.Postcode || 'None'}</span>
                </div>
              ) : (
                <Input name="Postcode" value={dataDoctor.Postcode || ''} onChange={setFieldValue} />
              )}
            </Form.Field>
            <Form.Field>
              <label className="Form-Field-label">State</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{DoctorDetailsStore.selectedCity || 'None'}</span>
                </div>
              ) : (
                <Dropdown
                  placeholder="State"
                  search
                  selection
                  name="CityId"
                  options={DoctorDetailsStore.getCityList}
                  value={dataDoctor.CityId}
                  onChange={setFieldValue}
                />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width="8">
              <label className="Form-Field-label">Country</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{DoctorDetailsStore.selectedCountry || 'None'}</span>
                </div>
              ) : (
                <Dropdown
                  placeholder="Country"
                  search
                  selection
                  name="CountryId"
                  options={DoctorDetailsStore.getCountryList}
                  value={dataDoctor.CountryId}
                  onChange={this.setCountryId}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Segment>
        <Segment className="Segment-View">
          <Form.Field>
            <label className="Form-Group-label">Mailing Address</label>
          </Form.Field>
          {isEditing && (
            <Form.Checkbox
              label="Same as office address"
              name="isSameAsOfficeAddress"
              onChange={this.toggleSameAsOfficeAddress}
            />
          )}
          <Form.Group>
            <Form.Field width="8">
              <label className="Form-Field-label">PO Box</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.POBox || 'None'}</span>
                </div>
              ) : (
                <Input name="POBox" value={dataDoctor.POBox || ''} onChange={setFieldValue} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label className="Form-Field-label">Street address</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.CorrespondenceAddress || 'None'}</span>
                </div>
              ) : (
                <Input
                  name="CorrespondenceAddress"
                  value={dataDoctor.CorrespondenceAddress || ''}
                  onChange={setFieldValue}
                />
              )}
            </Form.Field>
            <Form.Field>
              <label className="Form-Field-label">Suburb</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.CorrespondenceDistrict || 'None'}</span>
                </div>
              ) : (
                <Input
                  name="CorrespondenceDistrict"
                  value={dataDoctor.CorrespondenceDistrict || ''}
                  onChange={setFieldValue}
                />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label className="Form-Field-label">Postcode</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{dataDoctor.CorrespondencePostcode || 'None'}</span>
                </div>
              ) : (
                <Input
                  name="CorrespondencePostcode"
                  value={dataDoctor.CorrespondencePostcode || ''}
                  onChange={setFieldValue}
                />
              )}
            </Form.Field>
            <Form.Field>
              <label className="Form-Field-label">State</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{DoctorDetailsStore.selectedMailingCity || 'None'}</span>
                </div>
              ) : (
                <Dropdown
                  placeholder="State"
                  search
                  selection
                  name="CorrespondenceCityId"
                  options={DoctorDetailsStore.getMailingCityList}
                  value={dataDoctor.CorrespondenceCityId}
                  onChange={setFieldValue}
                />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width="8">
              <label className="Form-Field-label">Country</label>
              {!isEditing ? (
                <div>
                  <span className="Label-item">{DoctorDetailsStore.selectedMailingCountry || 'None'}</span>
                </div>
              ) : (
                <Dropdown
                  placeholder="Country"
                  search
                  selection
                  name="CountryId"
                  options={DoctorDetailsStore.getCountryList}
                  value={dataDoctor.CorrespondenceCountryId}
                  onChange={this.setMailingCountryId}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Segment>
      </Form>
    );
  }
}

export default Address;
