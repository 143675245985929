import { observer } from 'mobx-react';
import { action } from 'mobx';
import React from 'react';

import TableToolbar from '../../shared/tableToolbar';
import AssignDoctorStore from './AssignDoctorStore';
import customFetch from '../../../utils/customFetch';

const taskLabels = [
  { text: `All`, value: `All` },
  {
    text: `Selected Doctors`,
    value: `selected`,
  },
];

@observer
class ModalAssignDoctorToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: AssignDoctorStore.doctors ? AssignDoctorStore.doctors : [],
    };
  }

  componentDidMount() {
    if (!AssignDoctorStore.loading && AssignDoctorStore.doctors) {
      this.setState({
        doctors: AssignDoctorStore.doctors,
      });
    }
  }

  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  fetchDoctorByList = () => {
    const doctors = AssignDoctorStore.doctors ? AssignDoctorStore.doctors : [];
    customFetch('/MedicalDoctor/GetDoctorByIdList', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(doctors.map(i => i)),
    }).then(
      action(data =>
        AssignDoctorStore.setFieldsValue({
          data,
          loading: false,
          totalPage: data.sumPage || AssignDoctorStore.totalPage,
          curPage: 1,
        }),
      ),
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    AssignDoctorStore.refetch({ curPage: 1, filter: `All` }, true);
  };

  handleFilter = (_, { value }) => {
    AssignDoctorStore.setFieldsValue({
      loading: true,
      filter: value,
    });
    if (value === `All`) {
      AssignDoctorStore.refetch({ curPage: 1 }, true);
    } else {
      this.fetchDoctorByList();
    }
  };

  handleReset = e => {
    e.preventDefault();
    if (this.state.doctors === 0) {
      AssignDoctorStore.resetStore();
      AssignDoctorStore.refetch({}, true);
    } else {
      this.fetchDoctorByList();
      AssignDoctorStore.setFieldsValue({ filter: `selected`, keyword: `` });
    }
  };

  render() {
    const { keyword, loading, filter } = AssignDoctorStore;
    return (
      <TableToolbar.Container>
        <TableToolbar.Left onSubmit={this.handleSubmit}>
          <TableToolbar.Filter
            disabled={loading}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
            label={`Display`}
          />
          <TableToolbar.Input
            placeholder={'Search'}
            value={keyword}
            onChange={AssignDoctorStore.handleSearchChange}
            disabled={loading}
          />
          <TableToolbar.HighLightButton className="blue" disabled={loading}>
            Search
          </TableToolbar.HighLightButton>
        </TableToolbar.Left>
        <TableToolbar.Right>
          <TableToolbar.HighLightButton
            className="negative"
            disabled={loading}
            onClick={this.handleReset}
            style={{ marginLeft: '5px' }}
          >
            Clear
          </TableToolbar.HighLightButton>
        </TableToolbar.Right>
      </TableToolbar.Container>
    );
  }
}

export default ModalAssignDoctorToolbar;
