export const ERROR_FIELDNAME = {
  Name: 'Centre name',
  Telephone: 'Telephone',
  Email: 'Email address',
  AccountingEmail: 'Accounting email address',
  CityId: 'State',
  Fax: 'Fax',
  EmailReceiveDailyBookings: 'EmailReceiveDailyBookings',
};

export const ERROR_MESSAGES = {
  required: 'is required',
  invalid: 'is invalid',
  maxdigits: 'must be less than or equal to 10 digits',
  mindigits: 'must be more than or equal to 6 digits',
};
