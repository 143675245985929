import React from 'react';
// import { , Form, , DatePicker, Input, Radio } from 'antd';
import { Checkbox, Form, Select, Spin } from 'antd';
import { Popup, Icon, Button, List } from 'semantic-ui-react';

import './ProgressAction.scss';

import RecentChangeForm from './RecentChangeForm';
import InvoiceHistory from './InvoiceHistory';

export const ProgressActionFormAppoinmentRequested = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Notify CM about Appointment Inquiry</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div>
            <span>Remind staff to book appointment.</span>
            <Popup
              trigger={<Icon name="info circle" className="action-notes-icon" />}
              content="Daily after client's due date to
                book an appointment"
              position="top left"
            />
          </div>
          <div className="action-notes">
            <label>Action notes</label>
            <ul>
              <li>All inquiries should be replied within 48 hours</li>
              <li>Inquiries should be moved to appointment confirmed or cancelled if no booking needed</li>
            </ul>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormAppoinmentConfirmed = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Notify CM about Appointment Confirmed</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div>
            <span>Remind staff to book appointment.</span>
            <Popup trigger={<Icon name="info circle" className="action-notes-icon" />}>
              <Popup.Content>
                <List style={{ width: '30em' }}>
                  <List.Item>- 7 days before appointment date</List.Item>
                  <List.Item>- 3 days before appointment date</List.Item>
                  <List.Item>
                    - Immediately if an appointment's date/time/clinic changes or is cancelled and appointment date is 2
                    days or less ahead
                  </List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </div>
          <div>
            <span>Remind staff to book appointment.</span>
            <Popup trigger={<Icon name="info circle" className="action-notes-icon" />}>
              <Popup.Content>
                <List>
                  <List.Item>- 14 and 7 days reminder before appointment date</List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </div>
          <div>
            <span>Remind staff to book appointment.</span>
            <Popup trigger={<Icon name="info circle" className="action-notes-icon" />}>
              <Popup.Content>
                <List>
                  <List.Item>- 2 hours after the appointment end time and 12PM the next day</List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </div>
          <div className="action-notes">
            <label>Action notes</label>
            <ul>
              <li>Obtain case paperwork at least 7 days before appointment date</li>
              <li>Update attendance within 24 hours of appointment date</li>
              <li>2 hours after the appointment end time and 12PM the next day</li>
            </ul>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormPaperworkSendToSpecialist = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Allow specialist to view paperwork</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} disabled />
          </div>
          <div className="action-notes">
            <label>Action notes</label>
            <ul>
              <li>Remember to update attendance within 24 hours of appointment date</li>
            </ul>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormAttended = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Remind specialist of report completion deadline</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div>
            <span>Remind specialist to send dictations (Dictation by Medicolegal Provider)</span>
            <Popup
              trigger={<Icon name="info circle" className="action-notes-icon" />}
              content="Every 2 days after appointment date"
              position="top left"
            />
          </div>
          <div>
            <span>Remind specialist to send completed report (Dictation by Booking Client)</span>
            <Popup
              trigger={<Icon name="info circle" className="action-notes-icon" />}
              content="3, 5 and 7 days after appointment date"
              position="top left"
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormNotAttended = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Notify "Non-attendance" case to booking CM</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormDictationReceived = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>
              Remind staff to send draft report to specialist (Dictation by MAG)
            </label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
            <Popup
              trigger={<Icon name="info circle" className="action-notes-icon" />}
              content="Daily after 2 days of dictation received date"
              position="top left"
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormReportDraftSendToSpecialist = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div>
            <span>Remind staff to contact specialist for completed report (Dictation by MAG)</span>
            <Popup
              trigger={<Icon name="info circle" className="action-notes-icon" />}
              content="Daily after 2 days of draft report completed date"
              position="top left"
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormReportSentToCMs = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Allow CMs to view completed reports</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormCanceledNoFee = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Notify appointment cancellation to booking CM</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div>
            <span>Send updated daysheets to docto</span>
            <Popup trigger={<Icon name="info circle" className="action-notes-icon" />}>
              <Popup.Content>
                <List style={{ width: '30em' }}>
                  <List.Item>- 7 days before appointment date</List.Item>
                  <List.Item>- 3 days before appointment date</List.Item>
                  <List.Item>
                    - Immediately if an appointment's date/time/clinic changes or is cancelled and appointment date is 2
                    days or less ahead
                  </List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormCanceledWithFee = ({ ...props }) => {
  const [showRecentChange, setShowRecentChange] = React.useState(false);
  const showAndHideRecentChange = () => {
    setShowRecentChange(!showRecentChange);
  };
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <Form name="progress-action" className="progress-action">
          <Form.Item label="Next PIC" required>
            <Select
              size="large"
              value={props.picId || null}
              showSearch
              optionFilterProp="children"
              onChange={props.handleChangeField}
            >
              {props.staffs.itemList &&
                props.staffs.itemList.length &&
                props.staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className="checkbox-field">
            <label style={{ marginRight: '5px' }}>Notify appointment cancellation to booking CM</label>
            <Checkbox checked={props.checkedCheckbox} onChange={props.handleChangeCheckBox} />
          </div>
          <div>
            <span>Send updated daysheets to docto</span>
            <Popup trigger={<Icon name="info circle" className="action-notes-icon" />}>
              <Popup.Content>
                <List style={{ width: '30em' }}>
                  <List.Item>- 7 days before appointment date</List.Item>
                  <List.Item>- 3 days before appointment date</List.Item>
                  <List.Item>
                    - Immediately if an appointment's date/time/clinic changes or is cancelled and appointment date is 2
                    days or less ahead
                  </List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button className="blue" onClick={showAndHideRecentChange}>
              {`${showRecentChange ? 'Hide Recent Change PIC' : 'Show Recent Change PIC'}`}
              <Icon name={`${showRecentChange ? 'minus' : 'plus'}`} className="action-notes-icon" />
            </Button>
          </div>
        </Form>
      )}
      {showRecentChange && (
        <RecentChangeForm
          dataRecentChange={props.recentChangeData.dataRecentChange}
          columns={props.recentChangeData.columns}
          fieldNameStaffRecentChange={props.recentChangeData.fieldNameStaffRecentChange}
        />
      )}
    </div>
  );
};

export const ProgressActionFormServiceBilled = ({ ...props }) => {
  return (
    <div className={`form-progress-action${props.loading ? '-loading' : '-container'}`}>
      {props.loading ? (
        <Spin size="large" />
      ) : (
        <InvoiceHistory dataInvoice={props.invoiceData.invoiceData} columns={props.invoiceData.columns} />
      )}
    </div>
  );
};
