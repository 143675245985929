import { observer } from 'mobx-react';
import React from 'react';
import { toJS } from 'mobx';

import TableToolbar from '../../shared/tableToolbar';
import MedicalCentresStore from './MedicalCentresStore';
import dashboard from '../../../stores/dashboard';
import * as api from '../../../../src/stores/api';

const taskLabels = [
  { text: `All`, value: `All` },
  { text: `Active`, value: `active` },
  { text: `Inactive`, value: `inactive` },
];

@observer
class MedicalCentresToolbar extends React.Component {
  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.value,
    }));
  };

  renderFilterText = value => {
    let labels = taskLabels.filter(opt => opt.value === value).map(opt => opt.text);
    return labels[0];
  };

  handleSubmit = event => {
    event.preventDefault();
    MedicalCentresStore.refetch({ curPage: 1 }, true);
  };

  handleFilter = (_, { value }) => {
    MedicalCentresStore.refetch({ filter: value, curPage: 1 }, true);
  };

  handleReset = () => {
    MedicalCentresStore.resetStore();
    MedicalCentresStore.refetch({}, true);
  };

  openFormCreateClinic = () => {
    localStorage.setItem(`ClinicName`, '');
    dashboard.close(`/view/add-edit-clinic-2`, true);
    setTimeout(() => {
      dashboard.open(`/view/add-edit-clinic-2?action=create`);
    });
  };

  handleChangeSearchDropdown = e => {
    MedicalCentresStore.refetchSearch({
      keyword: e.target.value,
      keywordSearch: e.target.value,
      numberSearch: 30,
    });
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(MedicalCentresStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ ID: 'All', Name: ``, FullAddress: `` });
    return array.map((opt, index) => ({
      text: `${opt.ID === `All` ? `All` : `Name: ${opt.Name} || FullAddress: ${opt.FullAddress}`}`,
      value: JSON.stringify({
        id: opt.ID,
        value: `${opt.Name || ''}`,
        key: `${opt.FullAddress} ${index} ${opt.Id}`,
      }),
    }));
  };

  checkIsJSON = value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleFilterAction = (_, { value }) => {
    const clinic = !this.checkIsJSON(value) ? value : JSON.parse(value);
    if (clinic.id === `All` || value === `All`) {
      MedicalCentresStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      MedicalCentresStore.resetSearch();
    } else {
      MedicalCentresStore.refetch(
        {
          keyword: clinic.id,
          curPage: 1,
          keywordSearch: clinic.id,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  handleScroll = event => {
    const target = event.target;
    const number = MedicalCentresStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      MedicalCentresStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  renderSearchText = value => {
    const array = [];
    const data = toJS(MedicalCentresStore.dataSearch.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ ID: 'All', Name: ``, Email: `` });
    let labels = array.filter(opt => opt.ID === value).map(opt => `${opt.Name}`);
    if (labels[0]) return labels[0];
    return value.toString();
  };

  handleScroll = event => {
    const target = event.target;
    const number = MedicalCentresStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      MedicalCentresStore.refetchSearch({ numberSearch: number + 20 });
    }
  };

  handleRefresh = () => {
    MedicalCentresStore.refetch(
      {
        dataSearch: null,
        data: null,
      },
      true,
    );
  };

  renderActionPICOptions = () => {
    const array = [];
    const data = toJS(MedicalCentresStore.dataActionPIC?.itemList);
    if (data) {
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: 'All', Count: 0 });
      return array.map(opt => {
        if (opt.FullName === 'All') {
          return {
            text: opt.FullName,
            value: opt.Id,
          };
        } else {
          return {
            text: `${opt.FullName} (${opt.Count})`,
            value: opt.Id,
          };
        }
      });
    }
  };

  renderActionPICText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(MedicalCentresStore.dataActionPIC?.itemList);
      if (data) {
        for (var i = 0; i < data.length; i++) {
          array.push(data[i]);
        }
        array.unshift({ Id: '', FullName: 'All', Count: 0 });
        let labels = array
          .filter(opt => opt.Id === value)
          .map(opt => {
            if (opt.FullName === 'All') {
              return opt.FullName;
            } else {
              return `${opt.FullName} (${opt.Count})`;
            }
          });
        return labels[0];
      }
      return null;
    }
  };

  handleFilterActionPIC = (_, { value }) => {
    MedicalCentresStore.refetch({ ActionRequiredById: value, curPage: 1 }, true);
  };

  render() {
    const { keyword, loading, filter, loadingSearch, ActionRequiredById, totalItems } = MedicalCentresStore;
    return (
      <>
        <TableToolbar.Container>
          <TableToolbar.Left>
            <TableToolbar.SearchDropdown
              loading={loadingSearch}
              search
              allowAdditions
              onScroll={this.handleScroll}
              additionLabel=""
              value={this.renderSearchText(keyword)}
              selectOnNavigation={false}
              onSearchChange={this.handleChangeSearchDropdown}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              className="Toolbar-input"
            />
            <TableToolbar.SubmitButton className="blue" disabled={loading} onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              disabled={loading}
              onClick={this.handleRefresh}
              style={{ marginLeft: '5px' }}
            >
              Refresh
            </TableToolbar.HighLightButton>
            {!api.isOnlySearch() &&<TableToolbar.HighLightButton
              className="positive"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={this.openFormCreateClinic}
            >
              Add New Centre
            </TableToolbar.HighLightButton>}
            <label style={{ margin: '0 5px 0 10px', fontWeight: 'bold' }}>Total Records:</label>{' '}
            <span>{totalItems}</span>
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container style={{ justifyContent: 'flex-start', marginBottom: '0' }}>
          <TableToolbar.Filter
            disabled={loading}
            label={`Centre Status`}
            value={this.renderFilterText(filter)}
            options={this.renderFilterOptions()}
            onChange={this.handleFilter}
          />
          <TableToolbar.Filter
            label="Action PIC"
            value={this.renderActionPICText(ActionRequiredById)}
            options={this.renderActionPICOptions()}
            onChange={this.handleFilterActionPIC}
            disabled={loading}
          />
        </TableToolbar.Container>
      </>
    );
  }
}

export default MedicalCentresToolbar;
