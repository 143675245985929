import './DoctorDetails.scss';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import Validator from 'validatorjs';
import cx from 'classnames';
import React from 'react';
import { Grid, Form, Segment, Input, Popup, Icon, Checkbox, Dropdown, Button } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Select, InputNumber } from 'antd';
import * as api from '@stores/api';
import DoctorDetailsStore from './DoctorDetailsStore';
import TextArea from './TextArea';

@observer
class ServiceInformation extends React.Component {
  renderPopup = message => (
    <Popup trigger={<Icon name="info circle" />} content={message} position="top center" wide="very" />
  );
  renderProviderNo = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [
      dataDoctor.ProviderNumber,
      dataDoctor.ProviderNumber2,
      dataDoctor.ProviderNumber3,
      dataDoctor.ProviderNumber4,
    ].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {i}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input
          name="ProviderNumber"
          className="mb-1"
          value={dataDoctor.ProviderNumber || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber2"
          className="mb-1"
          value={dataDoctor.ProviderNumber2 || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber3"
          className="mb-1"
          value={dataDoctor.ProviderNumber3 || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber4"
          className="mb-1"
          value={dataDoctor.ProviderNumber4 || ''}
          onChange={setFieldValue}
        />
      </React.Fragment>
    );
  };
  renderMAGChargeRate = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [dataDoctor.MAGChargeRate, dataDoctor.MAGChargeRate2].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {`${i}%`}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input name="MAGChargeRate" className="mb-1" value={dataDoctor.MAGChargeRate || ''} onChange={setFieldValue} />
        <Input
          name="MAGChargeRate2"
          className="mb-1"
          value={dataDoctor.MAGChargeRate2 || ''}
          onChange={setFieldValue}
        />
      </React.Fragment>
    );
  };
  renderNONMAGChargeRate = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [dataDoctor.ChargeRate, dataDoctor.ChargeRate2].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {`${i}%`}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input name="ChargeRate" className="mb-1" value={dataDoctor.ChargeRate || ''} onChange={setFieldValue} />
        <Input name="ChargeRate2" className="mb-1" value={dataDoctor.ChargeRate2 || ''} onChange={setFieldValue} />
      </React.Fragment>
    );
  };

  validateEmail = emailStr => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    }
    return null;
  };

  validateFromDate = (isActive, fromDate) => {
    if (isActive) {
      if (!fromDate) return 'This field is required';
      return;
    }
    return;
  };

  handleTypeOfReport = () =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      const { arrDoctorRates, arrTypeOfReports } = DoctorDetailsStore;
      var newArr = [];
      if (value && value.length > 0) {
        if (arrDoctorRates && arrDoctorRates.length > 0) {
          value.forEach(id => {
            var reportObj = arrTypeOfReports.find(r => r.Id === id);
            var currentItemIME = arrDoctorRates.find(i => i.idx === id && i.ServiceType === 'IME');
            var currentItemFR = arrDoctorRates.find(i => i.idx === id && i.ServiceType === 'File Review');
            if (currentItemIME && currentItemFR) {
              newArr.push(currentItemIME, currentItemFR);
            } else {
              newArr.push(
                {
                  Id: 0,
                  idx: id,
                  ServiceType: 'IME',
                  Name: reportObj.Name,
                  RateFrom: null,
                  RateTo: null,
                  MinRate: null,
                  MaxRate: null,
                  AvgRate: null,
                  ApplyGazettedRate: true,
                  ServiceItems: null,
                },
                {
                  Id: 0,
                  idx: id,
                  ServiceType: 'File Review',
                  Name: reportObj.Name,
                  RateFrom: null,
                  RateTo: null,
                  MinRate: null,
                  MaxRate: null,
                  AvgRate: null,
                  ApplyGazettedRate: true,
                  ServiceItems: null,
                },
              );
            }
          });
        } else {
          var reportObj = arrTypeOfReports.find(r => r.Id === value[0]);
          newArr.push(
            {
              Id: 0,
              idx: value[0],
              ServiceType: 'IME',
              Name: reportObj.Name,
              RateFrom: null,
              RateTo: null,
              MinRate: null,
              MaxRate: null,
              AvgRate: null,
              ApplyGazettedRate: true,
              ServiceItems: null,
            },
            {
              Id: 0,
              idx: value[0],
              ServiceType: 'File Review',
              Name: reportObj.Name,
              RateFrom: null,
              RateTo: null,
              MinRate: null,
              MaxRate: null,
              AvgRate: null,
              ApplyGazettedRate: true,
              ServiceItems: null,
            },
          );
        }
      }
      DoctorDetailsStore.setDoctorRates(newArr);
      DoctorDetailsStore.setFieldNamAndValue('TypeOfReports', value);
    });

  setFieldRateValue = (idx, serviceType, name) =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      var arrDoctorRates = [...DoctorDetailsStore.arrDoctorRates];
      var currentItem = arrDoctorRates.find(i => i.idx === idx && i.ServiceType === serviceType);
      if (currentItem) {
        currentItem[name] = value;
      }
      DoctorDetailsStore.setDoctorRates(arrDoctorRates);
    });

  @action toggleRateCheckbox = (idx, serviceType, name, checked) => {
    var arrDoctorRates = [...DoctorDetailsStore.arrDoctorRates];
    var currentItem = arrDoctorRates.find(i => i.idx === idx && i.ServiceType === serviceType);
    if (currentItem) {
      currentItem[name] = !checked;
    }
    DoctorDetailsStore.setDoctorRates(arrDoctorRates);
  };

  formatNumber = value => {
    return !!value ? '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ 0';
  };

  render() {
    const {
      dataDoctor,
      isEditing,
      setFieldValue,
      toggleCheckbox,
      arrTypeOfReports,
      arrDoctorRates,
    } = DoctorDetailsStore;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Payment Info</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Provider No. 1</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber" value={dataDoctor.ProviderNumber || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumberNote || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumberNote" maxLength="1000" />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Provider No. 2</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber2 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber2" value={dataDoctor.ProviderNumber2 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber2Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber2Note" maxLength="1000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Provider No. 3</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber3 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber3" value={dataDoctor.ProviderNumber3 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber3Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber3Note" maxLength="1000" />
                    )}
                  </Form.Field>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Provider No. 4</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber4 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber4" value={dataDoctor.ProviderNumber4 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber4Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber4Note" maxLength="1000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">ABN</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ABN || 'None'}</span>
                    ) : (
                      <Input name="ABN" value={dataDoctor.ABN || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Name of bank</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.BankName || 'None'}</span>
                    ) : (
                      <Input name="BankName" value={dataDoctor.BankName || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Account name</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountName || 'None'}</span>
                    ) : (
                      <Input name="AccountName" value={dataDoctor.AccountName || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">BSB</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.BSB || 'None'}</span>
                    ) : (
                      <Input name="BSB" value={dataDoctor.BSB || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Account number</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountNumber || 'None'}</span>
                    ) : (
                      <Input name="AccountNumber" value={dataDoctor.AccountNumber || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Accounting email 1</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountingEmail1 || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="AccountingEmail1"
                          value={dataDoctor.AccountingEmail1 || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.AccountingEmail1) && '-error'}`,
                          )}
                        />
                        {this.validateEmail(dataDoctor.AccountingEmail1) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.AccountingEmail1)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Accounting email 2</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountingEmail2 || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="AccountingEmail2"
                          value={dataDoctor.AccountingEmail2 || ''}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.AccountingEmail2) && '-error'}`,
                          )}
                          onChange={setFieldValue}
                        />
                        {this.validateEmail(dataDoctor.AccountingEmail2) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.AccountingEmail2)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Medicolegal Provider Standard Rate 1 (%){' '}
                      {this.renderPopup(
                        'Rates applied when the Medicolegal Provider provides extra services such as typing, clinic rooms',
                      )}
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRate || 'None'}</span>
                    ) : (
                      <Input name="MAGChargeRate" value={dataDoctor.MAGChargeRate || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Effective from
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Rates will apply based on effective period and appointment date (IME cases) or request date (FR cases)"
                        position="top center"
                        wide="very"
                      />
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRateFrom || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="MAGChargeRateFrom"
                          animation=""
                          duration={0}
                          dateFormat="DD/MM/YYYY"
                          clearable
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.MAGChargeRateFrom || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.MAGChargeRate,
                              dataDoctor.MAGChargeRateFrom,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.MAGChargeRate, dataDoctor.MAGChargeRateFrom) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.MAGChargeRate, dataDoctor.MAGChargeRateFrom)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRateTo || 'None'}</span>
                    ) : (
                      <DateInput
                        dateFormat="DD/MM/YYYY"
                        name="MAGChargeRateTo"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.MAGChargeRateTo || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field disabled={!dataDoctor.MAGChargeRate && isEditing}>
                    <label className="Form-Field-label">
                      Medicolegal Provider Standard Rate 2 (%){' '}
                      {this.renderPopup(
                        'Rates applied when the Medicolegal Provider provides extra services such as typing, clinic rooms',
                      )}
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRate2 || 'None'}</span>
                    ) : (
                      <Input name="MAGChargeRate2" value={dataDoctor.MAGChargeRate2 || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.MAGChargeRate && isEditing}>
                    <label className="Form-Field-label">
                      Effective from
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Rates will apply based on effective period and appointment date (IME cases) or request date (FR cases)"
                        position="top center"
                        wide="very"
                      />
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRate2From || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="MAGChargeRate2From"
                          animation=""
                          dateFormat="DD/MM/YYYY"
                          duration={0}
                          clearable
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.MAGChargeRate2From || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.MAGChargeRate2,
                              dataDoctor.MAGChargeRate2From,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.MAGChargeRate, dataDoctor.MAGChargeRateFrom) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.MAGChargeRate2, dataDoctor.MAGChargeRate2From)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.MAGChargeRate && isEditing}>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.MAGChargeRate2To || 'None'}</span>
                    ) : (
                      <DateInput
                        name="MAGChargeRate2To"
                        animation=""
                        duration={0}
                        clearable
                        dateFormat="DD/MM/YYYY"
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.MAGChargeRate2To || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">
                      Medicolegal Provider Add-On Rate 1 (%){' '}
                      {this.renderPopup(
                        'Rates applied when the Medicolegal Provider does not provide extra services such as typing, clinic rooms',
                      )}
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRate || 'None'}</span>
                    ) : (
                      <Input name="ChargeRate" value={dataDoctor.ChargeRate || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Effective from</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRateFrom || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="ChargeRateFrom"
                          animation=""
                          dateFormat="DD/MM/YYYY"
                          duration={0}
                          clearable
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.ChargeRateFrom || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.ChargeRate,
                              dataDoctor.ChargeRateFrom,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.ChargeRate, dataDoctor.ChargeRateFrom) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.ChargeRate, dataDoctor.ChargeRateFrom)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRateTo || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ChargeRateTo"
                        animation=""
                        duration={0}
                        dateFormat="DD/MM/YYYY"
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ChargeRateTo || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field disabled={!dataDoctor.ChargeRate && isEditing}>
                    <label className="Form-Field-label">
                      Medicolegal Provider Add-On Rate 2 (%){' '}
                      {this.renderPopup(
                        'Rates applied when the Medicolegal Provider does not provide extra services such as typing, clinic rooms',
                      )}
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRate2 || 'None'}</span>
                    ) : (
                      <Input name="ChargeRate2" value={dataDoctor.ChargeRate2 || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.ChargeRate && isEditing}>
                    <label className="Form-Field-label">
                      Effective from
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Rates will apply based on effective period and appointment date (IME cases) or request date (FR cases)"
                        position="top center"
                        wide="very"
                      />
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRate2From || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="ChargeRate2From"
                          animation=""
                          duration={0}
                          dateFormat="DD/MM/YYYY"
                          clearable
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.ChargeRate2From || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.ChargeRate2,
                              dataDoctor.ChargeRate2From,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.ChargeRate2, dataDoctor.ChargeRate2From) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.ChargeRate2, dataDoctor.ChargeRate2From)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.ChargeRate && isEditing}>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ChargeRate2To || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ChargeRate2To"
                        dateFormat="DD/MM/YYYY"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ChargeRate2To || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Gross Margin COT 1 (%)</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTop || 'None'}</span>
                    ) : (
                      <Input name="CommissionOnTop" value={dataDoctor.CommissionOnTop || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">
                      Effective from
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Rates will apply based on effective period and appointment date (IME cases) or request date (FR cases)"
                        position="top center"
                        wide="very"
                      />
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTopFrom || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="CommissionOnTopFrom"
                          animation=""
                          duration={0}
                          clearable
                          dateFormat="DD/MM/YYYY"
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.CommissionOnTopFrom || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.CommissionOnTop,
                              dataDoctor.CommissionOnTopFrom,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.CommissionOnTop, dataDoctor.CommissionOnTopFrom) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.CommissionOnTop, dataDoctor.CommissionOnTopFrom)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTopTo || 'None'}</span>
                    ) : (
                      <DateInput
                        name="CommissionOnTopTo"
                        animation=""
                        dateFormat="DD/MM/YYYY"
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.CommissionOnTopTo || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field disabled={!dataDoctor.CommissionOnTop && isEditing}>
                    <label className="Form-Field-label">Gross Margin COT 2 (%)</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTop2 || 'None'}</span>
                    ) : (
                      <Input
                        name="CommissionOnTop2"
                        value={dataDoctor.CommissionOnTop2 || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.CommissionOnTop && isEditing}>
                    <label className="Form-Field-label">
                      Effective from
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Rates will apply based on effective period and appointment date (IME cases) or request date (FR cases)"
                        position="top center"
                        wide="very"
                      />
                    </label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTop2From || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <DateInput
                          name="CommissionOnTop2From"
                          animation=""
                          dateFormat="DD/MM/YYYY"
                          duration={0}
                          clearable
                          placeholder="dd/mm/yyyy"
                          value={dataDoctor.CommissionOnTop2From || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateFromDate(
                              dataDoctor.CommissionOnTop2,
                              dataDoctor.CommissionOnTop2From,
                            ) && '-error'}`,
                          )}
                        />
                        {this.validateFromDate(dataDoctor.CommissionOnTop2, dataDoctor.CommissionOnTop2From) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateFromDate(dataDoctor.CommissionOnTop2, dataDoctor.CommissionOnTop2From)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field disabled={!dataDoctor.CommissionOnTop && isEditing}>
                    <label className="Form-Field-label">Effective to</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.CommissionOnTop2To || 'None'}</span>
                    ) : (
                      <DateInput
                        name="CommissionOnTop2To"
                        animation=""
                        dateFormat="DD/MM/YYYY"
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.CommissionOnTop2To || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label className="Form-Field-label">Fixed Provider Payment Amount (excl GST)</label>
                  {!isEditing ? (
                    <span className="Label-item">{dataDoctor.MAGChargeRateFixedAmount || 'None'}</span>
                  ) : (
                    <Input
                      name="MAGChargeRateFixedAmount"
                      value={dataDoctor.MAGChargeRateFixedAmount || ''}
                      onChange={setFieldValue}
                    />
                  )}
                </Form.Field>
                <Checkbox
                  label="Approved Prepayment"
                  className="Form-field-radio"
                  disabled={api.isDoctor() || !isEditing}
                  name="ApprovedPrepayment"
                  checked={dataDoctor.ApprovedPrepayment}
                  onChange={toggleCheckbox}
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Valid until</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ValidUntil || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ValidUntil"
                        animation=""
                        dateFormat="DD/MM/YYYY"
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ValidUntil || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Approved date</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedDate || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ApprovedDate"
                        animation=""
                        dateFormat="DD/MM/YYYY"
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ApprovedDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Approved Amount (Max)</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedAmountMax || 'None'}</span>
                    ) : (
                      <Input
                        name="ApprovedAmountMax"
                        value={dataDoctor.ApprovedAmountMax || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Approved by</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedName || 'None'}</span>
                    ) : (
                      <Dropdown
                        search
                        selection
                        name="ApprovedBy"
                        options={DoctorDetailsStore.dataStaffs}
                        value={dataDoctor.ApprovedBy}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Report Fee Rates</label>
                  </Form.Field>
                </Form.Group>
                {isEditing && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="Form-Field-label">Select Type of Report</label>
                      <Select
                        className="search-rate"
                        showSearch
                        mode="multiple"
                        showArrow
                        size="large"
                        optionFilterProp="children"
                        placeholder="Select type of reports"
                        value={toJS(dataDoctor.TypeOfReports)}
                        onChange={this.handleTypeOfReport()}
                        allowClear
                      >
                        {arrTypeOfReports &&
                          arrTypeOfReports.map(({ Id, Name }) => (
                            <Select.Option key={Id} value={Id}>
                              {Name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Field>
                  </Form.Group>
                )}
                {!isEditing ? (
                  <table className="ui celled sortable striped table">
                    <thead>
                      <tr>
                        <th>Service Type</th>
                        <th>Type Of Report</th>
                        <th>Rate from (excl GST)</th>
                        <th>Rate to (excl GST)</th>
                        <th>
                          Past Rates to Client (excl GST)
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content="Past rates refer to charging rates to clients"
                            position="top center"
                            wide="very"
                          />
                        </th>
                        <th>Apply Gazetted Rate</th>
                        <th>Service Items</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!toJS(arrDoctorRates) &&
                        toJS(arrDoctorRates).map(item => {
                          return (
                            <tr key={'item-r1-' + item.idx + item.ServiceType.replace(' ', '')}>
                              <td>{item.ServiceType}</td>
                              <td>{item.Name}</td>
                              <td>{this.formatNumber(item.RateFrom)}</td>
                              <td>{this.formatNumber(item.RateTo)}</td>
                              <td>
                                Min: {item.MinRate === null ? 'N/A' : this.formatNumber(item.MinRate)} - Max:{' '}
                                {item.MaxRate === null ? 'N/A' : this.formatNumber(item.MaxRate)} - Average:{' '}
                                {item.AvgRate === null ? 'N/A' : this.formatNumber(item.AvgRate)}
                              </td>
                              <td>{item.ApplyGazettedRate ? 'Yes' : 'No'}</td>
                              <td>{item.ServiceItems ? item.ServiceItems.split(',').map(i => <div>{i}</div>) : ''}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <table className="ui celled sortable striped table">
                    <thead>
                      <tr>
                        <th>Service Type</th>
                        <th>Type Of Report</th>
                        <th>Rate from (excl GST)</th>
                        <th>Rate to (excl GST)</th>
                        <th>
                          Past Rates to Client (excl GST)
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content="Past rates refer to charging rates to clients"
                            position="top center"
                            wide="very"
                          />
                        </th>
                        <th>Accept Gazetted Rates</th>
                        <th>Service Items</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!toJS(arrDoctorRates) &&
                        toJS(arrDoctorRates).map(item => {
                          return (
                            <React.Fragment key={'item-r-r2-' + item.idx + item.ServiceType.replace(' ', '')}>
                              <tr key={'item-r2-' + item.idx + item.ServiceType.replace(' ', '')}>
                                <td>{item.ServiceType}</td>
                                <td>{item.Name}</td>
                                <td>
                                  <InputNumber
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.RateFrom}
                                    onChange={this.setFieldRateValue(item.idx, item.ServiceType, 'RateFrom')}
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    addonBefore="$"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={item.RateTo}
                                    onChange={this.setFieldRateValue(item.idx, item.ServiceType, 'RateTo')}
                                  />
                                  {/* <Input
                                disabled={!isEditing}
                                name="RateTo"
                                value={item.RateTo || ''}
                                onChange={this.setFieldRateValue(item.idx, 'RateTo')}
                              /> */}
                                </td>
                                <td>
                                  <div style={{ width: 'auto', float: 'left', lineHeight: '40px' }}>
                                    Min: {item.MinRate === null ? 'N/A' : this.formatNumber(item.MinRate)} - Max:{' '}
                                    {item.MaxRate === null ? 'N/A' : this.formatNumber(item.MaxRate)} - Average:{' '}
                                    {item.AvgRate === null ? 'N/A' : this.formatNumber(item.AvgRate)}
                                    {'   '}{' '}
                                  </div>
                                  {/* <Button
                                    style={{ float: 'right' }}
                                    className="blue"
                                    onClick={() => DoctorDetailsStore.onCheckPassRates(item.idx, item.ServiceType)}
                                  >
                                    Check
                                  </Button> */}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox
                                    label="Yes"
                                    disabled={!isEditing}
                                    name={item.idx + '_ApplyGazettedRate'}
                                    checked={item.ApplyGazettedRate}
                                    onChange={() =>
                                      this.toggleRateCheckbox(
                                        item.idx,
                                        item.ServiceType,
                                        'ApplyGazettedRate',
                                        item.ApplyGazettedRate,
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {item.ServiceItems ? item.ServiceItems.split(',').map(i => <div>{i}</div>) : ''}
                                </td>
                              </tr>
                              {/* <div>{item.Name}</div> */}
                              {/* <Form.Group widths="equal">
                            <Form.Field>
                              <label className="Form-Field-label">Rate from</label>
                              <Input
                                disabled={!isEditing}
                                name="RateFrom"
                                value={item.RateFrom || ''}
                                onChange={this.setFieldRateValue(item.idx, 'RateFrom')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label className="Form-Field-label">Rate to</label>
                              <Input
                                disabled={!isEditing}
                                name="RateTo"
                                value={item.RateTo || ''}
                                onChange={this.setFieldRateValue(item.idx, 'RateTo')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label className="Form-Field-label">Past Rate</label>
                              <Input
                                disabled={!isEditing}
                                name="PastRate"
                                value={item.PastRate || ''}
                                onChange={this.setFieldRateValue(item.idx, 'PastRate')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Checkbox
                                label="Apply Gazetted Rate"
                                disabled={!isEditing}
                                name={item.idx + "_ApplyGazettedRate"}
                                checked={item.ApplyGazettedRate}
                                onChange={() => this.toggleRateCheckbox(item.idx, 'ApplyGazettedRate', item.ApplyGazettedRate)}
                              />
                            </Form.Field>
                          </Form.Group> */}
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default ServiceInformation;
