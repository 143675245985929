import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import * as api from '../../../stores/api';
import AddEditStore from './Store';
import { IME_STATUS_LOGS } from './type';
import ProgressStatus from '../../shared/ProgressStatus/ProgressStatus';
import _ from 'lodash';
import moment from 'moment';

const statusGroups = [
  {
    name: 'Booking & Paperwork',
  },
  {
    name: 'Attendance',
    type: 'radio',
  },
  {
    name: 'Reporting',
  },
  {
    name: 'Billing',
  },
];

@observer
class Progress extends React.Component {
  state = {
    isExpanding: false,
  };

  handleOpenByStep = stepInfo => {
    const assessmentInfo = toJS(AddEditStore.assessmentInfo);
    if (assessmentInfo.Id !== 0) {
      return AddEditStore.toggleModal(true, {
        modalType: 'confirm',
        onOk: () => AddEditStore.handleChangeStatusIME(assessmentInfo.Id, stepInfo.key),
        message: 'Are you sure to change case status?',
      })();
    }
  };

  checkStepIsNotPerformed = stepKey => {
    const assessmentStatus = toJS(AddEditStore.caseStatus);
    if (stepKey === 'notAttended') {
      return assessmentStatus.status === 'Did Not Attend' && !assessmentStatus.notAttended;
    } else if (stepKey === 'isAppointmentConfirmed') {
      return assessmentStatus.status === 'Appointment Confirmed' && !assessmentStatus.isAppointmentConfirmed;
    } else if (stepKey === 'paperworkReceived') {
      return assessmentStatus.status === 'Paperwork Sent to Specialist' && !assessmentStatus.isAppointmentConfirmed;
    } else if (stepKey === 'attended') {
      return assessmentStatus.status === 'Appointment Attended' && !assessmentStatus.attended;
    } else if (stepKey === 'dictationReceived') {
      return assessmentStatus.status === 'Dictation Received' && !assessmentStatus.dictationReceived;
    } else if (stepKey === 'reportReturnedFromDoctor') {
      return (
        assessmentStatus.status === 'Corrected Report Returned from Specialist' &&
        !assessmentStatus.reportReturnedFromDoctor
      );
    } else if (stepKey === 'reportCompleted') {
      return assessmentStatus.status === 'Report Sent to CMs' && !assessmentStatus.reportCompleted;
    } else if (stepKey === 'isCancelledNoFee') {
      return assessmentStatus.status === 'Cancelled-No Fee' && !assessmentStatus.isCancelledNoFee;
    } else if (stepKey === 'isCancelledLcf') {
      return assessmentStatus.status === 'Late Cancellation Fee' && !assessmentStatus.isCancelledLcf;
    } else if (stepKey === 'clientInvoiced') {
      return assessmentStatus.status === 'Client Invoiced' && !assessmentStatus.clientInvoiced;
    } else if (stepKey === 'doctorInvoiced') {
      return assessmentStatus.status === 'Specialist Invoiced' && !assessmentStatus.doctorInvoiced;
    } else {
      return false;
    }
  };

  toggleExpand = () => {
    this.setState({
      isExpanding: !this.state.isExpanding,
    });
  };

  /**
   * When user click on check box in status group section
   */
  toggleStatusTask = stepInfo => {
    if (api.isAdminOrMagStaffOrAccounting()) {
      this.handleOpenByStep(stepInfo);
    }
  };

  render() {
    const assessmentStatusLogs = toJS(AddEditStore.statusLogs);
    const itemModel = toJS(AddEditStore.assessmentInfo);
    const caseStatus = IME_STATUS_LOGS.map(item => {
      var status = _.last(assessmentStatusLogs.itemList.filter(i => i.Status === item.key));
      return {
        ...item,
        changeByUser: status?.UserName,
        statusDate: status?.StatusDate,
        createdDate: moment(status?.CreatedDate).unix(),
        label: api.GetIMEStatusText(item.key),
        checked: status != null,
        isCurrent: itemModel.Status === item.id,
      };
    });

    statusGroups.forEach(group => {
      var groupTasks = caseStatus.filter(i => i.group === group.name);
      group.completed = false;
      group.inprogress = false;
      group.totalTask = group.type === 'radio' ? 1 : groupTasks.length;
      group.completedTaskCount = 0;
      group.active = groupTasks.some(i => i.key === itemModel.Status);
      group.inprogress = groupTasks.some(i => i.checked) || group.active;

      if (group.type === 'radio') {
        var checkedTasks = groupTasks.filter(i => i.checked);
        if (checkedTasks.length > 1) {
          checkedTasks.map(item => {
            item.checked = false;
            return item;
          });
          var latestStatus = _.maxBy(checkedTasks, i => i.createdDate);
          latestStatus.checked = true;
        }

        if (groupTasks.some(i => i.checked)) {
          group.completed = true;
          group.completedTaskCount = 1;
        }
      } else {
        if (groupTasks.every(i => i.checked)) {
          group.completed = true;
        }
        group.completedTaskCount = groupTasks.filter(i => i.checked).length;
      }
    });
    return (
      <ProgressStatus
        statusGroups={statusGroups}
        statuses={caseStatus}
        onClickStatus={this.toggleStatusTask}
        description={() => (
          <div className="case-info">
            Case Info: <b>{`${AddEditStore.assessmentInfo.AssessmentNumber} `}</b>
            {AddEditStore.assessmentInfo.Customer && (
              <span>
                - Client: <b>{`${AddEditStore.assessmentInfo.Customer.Name} `}</b>
              </span>
            )}
            {AddEditStore.bookingCaseManager && (
              <span>
                - Booking CM: <b>{`${AddEditStore.bookingCaseManager.FullName} `}</b>
              </span>
            )}
            {AddEditStore.claimant ? (
              <span>
                - Claimant: <b>{`${AddEditStore.claimant.FirstName} ${AddEditStore.claimant.LastName} `}</b>
              </span>
            ) : (
              ''
            )}
            {AddEditStore.specialist ? (
              <span>
                - Specialist: <b>{`${AddEditStore.specialist.FullName}`}</b>
              </span>
            ) : (
              ''
            )}
            {AddEditStore.assessmentInfo.DateFrom && (
              <span>
                - Appointment Date: <b>{`${moment(AddEditStore.assessmentInfo.DateFrom).format('DD/MM/YYYY')} `}</b>
              </span>
            )}
          </div>
        )}
      />
    );
  }
}

export default Progress;
