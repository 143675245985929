import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';
import moment from 'moment';

class DoctorReportsStore {
  @observable tabIsActive = 'specialistSalesCall';

  @observable loadingDoctor = true;
  @observable loadingDoctorReports = true;

  @observable dataDoctor = null;
  @observable dataDoctorReports = null;
  @observable DoctorId = 0;
  @observable keyword = ``;
  @observable fromTo = ``;
  @observable from = ``;
  @observable checkFrom = ``;
  @observable to = ``;
  @observable checkTo = ``;

  @observable sortKey = 'clientId';
  @observable sortColumn = 'Client';
  @observable sortDirection = 'descending';

  @observable open = false;
  @observable modalParams = {};
  @observable sumItem = 0;

  @observable loadingUpload = false;

  //Model export status
  @observable loadingDoctorStatus = true;
  @observable lstSpecialistStatus = [];

  @observable verifyAccessCode = '';
  @observable exportUrl = '';
  @observable exportQueryParams = '';

  getDateRange = () => {
    const [fromDate, toDate] = this.fromTo.split(' - ');

    return {
      fromDate: fromDate ? fromDate : null,
      toDate: toDate ? toDate : null,
    };
  };

  @action setLoadingUpload = status => {
    this.loadingUpload = status;
  };

  @action fetchDoctor = () => {
    this.loadingDoctor = true;
    customFetch('/MedicalDoctor/GetAllDoctorFullName', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        this.dataDoctor = data.itemList;
        this.loadingDoctor = false;
      }),
    );
  };

  @action fetchDoctorReports = async () => {
    this.loadingDoctorReports = true;
    const { fromDate, toDate } = this.getDateRange();
    try {
      await customFetch('/Report/GetDoctorReport', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          DoctorId: this.DoctorId,
          From: fromDate
            ? moment(fromDate, 'DD/MM/YYYY')
                .subtract(1, 'd')
                .format('YYYY-MM-DD')
            : '',
          To: toDate
            ? moment(toDate, 'DD/MM/YYYY')
                .subtract(1, 'd')
                .format('YYYY-MM-DD')
            : '',
          sortBy: this.sortKey,
          sortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        }),
      }).then(
        action(data => {
          this.checkFrom = this.from;
          this.checkTo = this.to;
          this.dataDoctorReports = data;
          if (data && !!data.sumItem) this.sumItem = data.sumItem;
          else this.sumItem = 0;
          this.loadingDoctorReports = false;
        }),
      );
    } catch (error) {
      throw error;
    }
  };

  @action fetchSearchSepecialistStatus = (_, { searchQuery }) => {
    if (searchQuery.length < 3) {
      return;
    }

    customFetch('/MedicalDoctor/GetAllWithoutNCI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        IsSelectionList: true,
        RecruitedOrContractedOnly: true,
        Showing: 'active',
        curPage: 1,
        keyword: searchQuery,
        numItemPerPage: 300,
      }),
    }).then(
      action(data => {
        this.lstSpecialistStatus = data.itemList;
      }),
    );
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchDoctorReports();
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action refetch = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchDoctorReports();
  };

  @action refetchDoctor = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchDoctor();
  };

  @action resetSearch = () => {
    this.DoctorId = 0;

    this.fetchDoctorReports();
  };
}

export default new DoctorReportsStore();
