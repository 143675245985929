import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import debounce from 'lodash/debounce';

import ClientsStore from './ClientsStore';
import ClientsTable from './ClientsTable';
import ClientsToolbar from './ClientsToolbar';
import ClientsModal from './ClientsModal';
import './index.scss';
import ui from '../../../stores/dashboard';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

class Clients extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/clients-2', true);
      return;
    }
    this._searchDebounced();
    ui.on('open(/view/clients-2)', this._searchDebounced);
  }

  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return params;
  };

  _searchDebounced = debounce(() => {
    const { sortBy, sortDirection } = this.getRouteParams();
    if (sortBy && sortDirection) {
      ClientsStore.refetch(
        {
          sortBy: sortBy,
          sortDirection: sortDirection,
        },
        true,
      );
      return router.history.replace({ ...router.location.search, search: '' });
    } else return ClientsStore.fetchClients(true);
  }, 500);

  render() {
    const { loading, data, open, loadingSearch, dataSearch } = ClientsStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingSearch && !dataSearch) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingSearch && !dataSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <ClientsToolbar />
            <ClientsTable />
            {open && <ClientsModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(Clients);
