import React from 'react';
import { observer } from 'mobx-react';

import AddEditStore from './Store';
import Modal from '../../shared/Modal';
import { toJS } from 'mobx';

import RecentCompanyChangeForm from '../Shared/CompanyRecentChange/RecentCompanyChangeForm';

@observer
class ModalCompanyRecentChange extends React.Component {
  render() {
    const { open } = AddEditStore;
    const { dataRecentChange, columns, showDateTime = false } = AddEditStore.modalParams;
    return (
      <Modal visible={open} width={900} onCancel={AddEditStore.toggleModal(false)} title="Recent Change History">
        <RecentCompanyChangeForm
          dataRecentChange={toJS(dataRecentChange)}
          columns={toJS(columns)}
          showDateTime={showDateTime}
        />
      </Modal>
    );
  }
}

export default ModalCompanyRecentChange;
