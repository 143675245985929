import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';
import { getAllData, getCities, getAllCategories } from './Service';

class Store {
  @observable loading = true;
  @observable data = null;
  @observable filter = 'TalkingPoint';
  @observable filterCaseStage = -1;
  @observable sortDirection = 'ascending';
  @observable keyword = '';
  @observable sortColumn = 'Id';
  @observable sortKey = 'Id';
  @observable totalItems = 0;
  @observable businessType = 0;
  @observable Cities = [];
  @observable state = 0;
  @observable Categories = [];
  @observable category = 0;
  @observable clientId = null;

  @observable curPage = 1;
  @observable pageSize = 20;
  @observable totalPage = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  @action fetchAll = (setTotalPage = false) => {
    this.loading = true;
    getAllData(this.keyword, this.curPage, this.pageSize, this.businessType, this.state, this.category, this.clientId).then(
      action(data => {
        this.loading = false;
        //console.log(1234);
        this.data = data;
        this.totalPage = data.sumPage;
        this.totalItems = data.sumItem;
      }),
    );
  };

  @action loadStates = () => {
    getCities().then(resp => {
      this.setFieldsValue({ Cities: resp.itemList });
    });
  };

  @action loadCategories = () => {
    getAllCategories().then(resp => {
      this.setFieldsValue({ Categories: resp.itemList });
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchAll();
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchAll(setTotalPage);
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action resetStore = () => {
    this.keyword = '';
    this.sortColumn = 'Id';
    this.sortKey = 'Id';
    this.sortDirection = 'descending';
    this.curPage = 1;
    this.totalPage = 0;
    this.businessType = 0;
    this.state = 0;
    this.clientId = null;
  };
}

export default new Store();
