import * as api from '../../stores/api';
import isIE from '../../utils/isIE';

const logout = () => {
  api.logout.post(
    null,
    () => {
      window.location.href = '/Account/LogOff';
      if (!isIE()) {
        setTimeout(() => {
          window.location.reload();
        });
      }
    },
    () => {
      window.location.href = '/Account/LogOff';
    },
  );
};

export default logout;
