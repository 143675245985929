import { action, observable } from 'mobx';

import customFetch from '../../../utils/customFetch';

class LoginUserStore {
  @observable loading = true;
  @observable data = null;

  @observable keyword = '';
  @observable filter = 'All';
  @observable role = 'All';

  @observable sortKey = 'Id';
  @observable sortDirection = 'ascending';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItem = 0;

  @observable open = false;
  @observable modalParams = {};

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action fetchLoginUser = (setTotalPage = false) => {
    this.loading = true;

    customFetch('/Manager/ManageUser_LoadList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ActionType: 'NONE',
        Keyword: this.keyword,
        CurPage: this.curPage,
        Showing: this.filter,
        Role: this.role,
        NumItemPerPage: this.pageSize,
        SortBy: this.sortKey,
        SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
        SearchClaimantOnly: true,
        NextPicId: null,
        ActionRequired: false,
        UrgentReport: false,
        ActionRequiredById: '',
      }),
    }).then(
      action(data => {
        this.data = data;
        if (data.curPage <= 1) {
          this.totalPage = data.sumPage;
          this.totalItems = data.sumItem;
        }
        this.loading = false;
      }),
    );
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };

  @action refetch = (data, setTotalPage) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchLoginUser(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.filter = 'All';
    this.sortKey = 'Id';
    this.role = 'All';
    this.sortDirection = 'ascending';

    this.curPage = 1;
    this.totalPage = 0;
  };
}

export default new LoginUserStore();
