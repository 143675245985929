import React from 'react';
import { Form, Checkbox, Select, DatePicker, Input, Divider, notification, Menu, Dropdown, Icon, Radio } from 'antd';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import moment from 'moment';
import { Popup, Icon as IconSemantic, Button } from 'semantic-ui-react';
import Validator from 'validatorjs';

import FieldUpload from './FieldUpload';
import { DEFAULT_COLUMNS_RECENT_CHANGE, DEFAULT_FIELD_NAME_RECENT_CHANGE, CONSTANTS, LOCAL_CONSTANT } from './type';
import { getStaffRecentChangeHistory, sendCMReminder, viewHistoryReminder, reSendUrgentReportRequest } from './service';

import store from './Store';
import * as api from '../../../stores/api';
import customFetch from '@utils/customFetch';
import { renderDate, commonInlineStyles } from '../Shared';

var momentBusinessDays = require('moment-business-days');

const styles = {
  emailPaperworkReceivedDate: {
    ...commonInlineStyles.inlineBlock,
    ...commonInlineStyles.highlightColor,
    ...commonInlineStyles.width300,
  },
};

@observer
class AppointmentDetailsAndCaseDocuments extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );

  itemToArray = (item = {}, arr = [], key) => {
    if (key && item && item[key] && !!arr.filter(i => i[key]).length) {
      if (Object.keys(item).length > 1) {
        const arr_ = arr.filter(i => i[key] !== item[key]);
        const item_ = toJS(item);
        return [...arr_, item_];
      } else {
        return arr;
      }
    } else if (key && item && item[key] && !!!arr.filter(i => i[key]).length) return [toJS(item)];
    else return arr;
  };

  handleChangeCheckBox = fieldName =>
    action(event => {
      const value = event.target.checked;
      const itemModel = toJS(store.assessmentInfo);

      if (fieldName === 'AllowDoctorToViewPaperwork') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
        };
        store.onAllowDoctorToViewPaperworkChange();
        return;
      }
      if (fieldName === 'NotifyPaperworkReadinessToStaff' && value && api.isCaseManager()) {
        if (
          !store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork).length &&
          !itemModel.PaperworkExLink
        ) {
          notification.error({
            message: 'Error',
            description: 'You need to attach LOI, paperwork or share download links before sending to Specialist.',
          });
        } else {
          store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value,
          };
        }
        return;
      }
      if (fieldName === 'ConsentFormReceived' && value) {
        return (store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          ConsentFormReceivedDate: moment(),
        });
      }

      if (fieldName === 'PaperWorkReceived') {
        store.assessmentInfo = {
          ...itemModel,
          [fieldName]: value,
          OtherMethodSendingDate: value ? moment() : null,
          //PaperworkReceivedDate: value ? moment() : null,
        };
        return store.onPaperworkReceivedChange();
      }

      if (fieldName === 'IsInvoiceUrgentReport') {
        if (value) {
          return store.toggleModal(true, {
            modalType: 'confirm',
            message: 'Specialist may charge a higher rate for urgent reports. Do you want to continue?',
            onOk: () => this.handleUrgentReport(fieldName),
          })();
        }
      }
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  @action handleUrgentReport = fieldName => {
    const itemModel = toJS(store.assessmentInfo);
    var dfExpectedCompletionDate = !!itemModel.DateFrom
      ? momentBusinessDays(itemModel.DateFrom.clone()).businessAdd(7)
      : null;
    //console.log(dfCompletionDate);
    store.assessmentInfo = {
      ...itemModel,
      [fieldName]: true,
      UrgentReportConfirmedByCM: true,
      RequestAcceptedBySpecialist: true,
      RequestExpectedCompletionDate: !!itemModel.RequestExpectedCompletionDate
        ? itemModel.RequestExpectedCompletionDate
        : dfExpectedCompletionDate,
    };
  };

  handleChangeField = fieldName =>
    action(event => {
      const itemModel = toJS(store.assessmentInfo);
      const value = event ? (event.target ? event.target.value : event) : event;
      if (fieldName === 'UrgentReportType' && value === 1) {
        var dfCompletionDate = !!itemModel.DateFrom
          ? momentBusinessDays(itemModel.DateFrom.clone()).businessAdd(2)
          : null;
        this.handleChangeValue('UrgentReportReportCompletionDate', dfCompletionDate);
      }
      store.handleChangeValue(fieldName, value);
    });

  @action handleChangeValue = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };
  @action ChangeAssDate = () => {
    const itemModel = toJS(store.assessmentInfo);
    const hourList = store.generateTimeRange(24);
    const minuteList = store.generateTimeRange(60);
    if (itemModel.DateFrom) {
      if (!(itemModel.DateFromHour && itemModel.DateFromHour.value > 0)) {
        this.handleChangeValue('DateFromHour', hourList[9]);
        this.handleChangeValue('DateFromMinute', minuteList[0]);
        this.handleChangeValue('DateToHour', hourList[9]);
        this.handleChangeValue('DateToMinute', minuteList[0]);
      }
      if (
        !itemModel.RequestExpectedCompletionDate &&
        itemModel.Attendance !== CONSTANTS.Attendance_LCF &&
        itemModel.Attendance !== CONSTANTS.Attendance_Cancelled
      ) {
        this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(3, 'days'));
        const day = itemModel.RequestExpectedCompletionDate.day();
        if (day === 6) {
          this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(5, 'days'));
        } else if (day === 0) {
          this.handleChangeValue('RequestExpectedCompletionDate', moment(itemModel.DateFrom).add(4, 'days'));
        }
      }
    }
  };

  validateLinkIsValid = link => {
    const v = new Validator({ link }, { link: 'url' });
    return v.passes();
  };

  @action handleChangeDatePicker = (fieldName, value) => {
    const itemModel = toJS(store.assessmentInfo);
    if (fieldName === 'DateFrom') {
      const hourList = store.generateTimeRange(24);
      const minuteList = store.generateTimeRange(60);
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
        DateFromHour: hourList[value.hour()],
        DateFromMinute: minuteList[value.minutes()],
      };
      return this.ChangeAssDate();
    }
    return (store.assessmentInfo = {
      ...itemModel,
      [fieldName]: value,
    });
  };

  @action handleChangePIC = (_, event, fieldName) => {
    const { data } = event.props;
    const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
    return (store[fieldName] = {
      picId: data_.Id,
      picName: data_.FullName,
      inChargeOf: 0,
    });
  };

  handleRecentChange = async (record, actionName, columns, fieldNameStaffRecentChange) => {
    try {
      const response = await getStaffRecentChangeHistory(record.Id, actionName);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };
  handleViewHistoryReminder = async () => {
    try {
      const columns = [
        { title: 'Receiver' },
        { title: 'Email/SMS' },
        { title: 'Sent Date/Time' },
        { title: 'Sent By' },
      ];
      const fieldNameStaffRecentChange = ['Receivers', 'ServiceType', 'SentDate', 'StaffSent'];
      const itemModel = toJS(store.assessmentInfo);
      const response = await viewHistoryReminder(itemModel.Id);
      const dataRecentChange = response.itemList;
      if (response.status === 'success') {
        return store.toggleModal(true, {
          modalType: 'recentChange',
          dataRecentChange,
          columns,
          fieldNameStaffRecentChange,
        })();
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
          duration: 5,
        });
      }
    } catch (e) {
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
        duration: 5,
      });
    }
  };

  handleSearch = fieldName => value => {
    if (fieldName === 'specialist') {
      return value
        ? store.handleRefetchSpecialist({ keywordAllWithoutNCI: value })
        : store.handleRefetchSpecialist({
            keywordAllWithoutNCI: store.specialist?.Id || '',
            numItemPerPageAllWithoutNCI: 30,
          });
    }
    if (fieldName === 'clinic') {
      return value
        ? store.handleRefetchClinic({ keywordClinicList: value })
        : store.handleRefetchClinic({
            keywordClinicList: store.clinic?.Id || '',
            numItemPerPageClinicList: 30,
          });
    }
    if (fieldName === 'clinicAddress') {
      return store.handleRefetchClinicAddress(value);
    }
    if (fieldName === 'clientAddress') {
      return store.handleRefetchClientAddress(value);
    }
  };

  handleChangeTextInput = fieldName =>
    action(event => {
      const value = event.target.value;
      const itemModel = toJS(store.assessmentInfo);
      if (fieldName === 'PaperworkExLink' || fieldName === 'PaperworkOtherExLink') {
        store.validateInput(value, 'url', fieldName);
      }
      if (fieldName === 'EassessClaimantPhone' || fieldName === 'EassessDoctorPhone') {
        const value_ = value.replace(/\D/g, '').replace(/[^\d]/g, '');
        if (value_.length <= 10) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value_,
          });
        } else return;
      }
      if (fieldName === 'InterpreterMobile' || fieldName === 'InterpreterPhone') {
        const value_ = value.replace(/\D/g, '').replace(/[^\d]/g, '');
        if (value_.length <= 12) {
          return (store.assessmentInfo = {
            ...itemModel,
            [fieldName]: value_,
          });
        } else return;
      }
      return (store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      });
    });

  @action handleChangeCheckBoxGroup = checkedValues => {
    const itemModel = toJS(store.assessmentInfo);

    if (!itemModel.IsAppointmentConfirmed && (api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())) {
      notification.error({
        message: 'Error',
        description:
          "Paperwork can't be sent to specialist before appointment is confirmed. Please check appointment status.",
      });
      return;
    }
    if (!!checkedValues.length && api.isAdminOrMagStaffOrAccounting()) {
      store.handleChangeValue('PaperWorkReceived', true);
      if (!itemModel.OtherMethodSendingDate) {
        store.handleChangeValue('OtherMethodSendingDate', moment());
      }
    } else {
      store.handleChangeValue('PaperWorkReceived', false);
    }
    store.handleChangeValue('SelectedPaperworkToDoctorBies', checkedValues);
    store.onPaperworkReceivedChange();
  };

  handleSendReminder = () => {
    const itemModel = toJS(store.assessmentInfo);
    if (!store.specialist && !store.specialist?.Id) {
      return store.showNotificationError(
        'You do not select a specialist or there is no specialist information. Please check again.',
      );
    }
    store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to send a paperwork reminder to the CM?',
      onOk: () => {
        store.toggleModal(false, {})();
        sendCMReminder(itemModel.Id)
          .then(res => {
            if (res.status === 'success') {
              notification.destroy();
              notification.success({
                message: 'Success',
                description: 'Reminder sent.',
              });
            }
          })
          .catch(() => {
            notification.destroy();
            notification.error({
              message: 'Error',
              description: 'Fail to send reminder',
            });
          });
      },
    })();
  };

  handleResendPaperwork = () => {
    const itemModel = toJS(store.assessmentInfo);
    const assessmentAttachment = [
      ...store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork),
      ...store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_PaperworkOther),
    ];
    customFetch('/MedicalService/ResendPaperwork', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemModel.Id, assessmentAttachment }),
    })
      .then(res => {
        if (res.status === 'success') {
          store.handleChangeValue('EmailToPaperworkReceivedRecent', moment());
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Resend Paperwork',
          });
        }
      })
      .catch(() => {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Resend Paperwork',
        });
      });
  };
  openPaperworkLink = link => {
    window.open(link, '_blank');
  };

  handleNotifyUrgentReport = type => {
    const itemModel = toJS(store.assessmentInfo);
    reSendUrgentReportRequest('IME', itemModel.Id, type).then(resp => {
      if (resp.status === 'success') {
        if (type === 1) {
          store.handleChangeValue('NotifyUrgentReportToStaffDate', moment());
          store.handleChangeValue('NotifyUrgentReportToStaffBy', resp.resendBy);
        } else if (type === 2) {
          store.handleChangeValue('NotifyUrgentReportToSpecialistDate', moment());
          store.handleChangeValue('NotifyUrgentReportToSpecialistBy', resp.resendBy);
        } else if (type === 3) {
          store.handleChangeValue('NotifyUrgentReportToCMDate', moment());
          store.handleChangeValue('NotifyUrgentReportToCMBy', resp.resendBy);
        }
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Resend Urgent Report Request',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'Resend Urgent Report Request',
        });
      }
    });
  };

  @action handleChangeObj = (_, event, fieldName) => {
    if (event && event.props) {
      const { data } = event.props;
      notification.destroy();
      const data_ = store.checkIsJSON(data) ? JSON.parse(data) : {};
      return (store[fieldName] = data_);
    } else {
      return (store[fieldName] = {});
    }
  };

  handleScroll = fieldName => event => {
    const target = event.target;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      if (fieldName === 'companyInsurer') {
        const number = store.numberCompanyInsurerPerPage;
        return store.handleRefetchCompanyInsurer({
          numberCompanyInsurerPerPage: number + 30,
        });
      }
    }
  };

  handleChangeDropdownObj = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : event;
      const itemModel = toJS(store.assessmentInfo);
      store.assessmentInfo = {
        ...itemModel,
        [fieldName]: value,
      };
      return;
    });

  render() {
    const itemModel = toJS(store.assessmentInfo);
    const pic_3 = toJS(store.PIC_3);
    const staffs = toJS(store.listStaff);
    const { lockTypist, oldPaperworkReceived } = store.handleLockReportCompleteCM(itemModel);
    const specificLookupConfigOrderNoByType = toJS(store.listspecificLookupConfigOrderNoByType);
    const pages = toJS(store.totalPages);
    const insurerCompanies = toJS(store.listCompanyInsurer);
    const reasonReferral = toJS(store.listReasonReferral);
    const listSpecialist = toJS(store.listSpecialist);
    const listSupplier = toJS(store.listSupplier);
    const icareLookupList = toJS(store.icareLookupList);
    //console.log(itemModel);
    //--
    return (
      <Form name="appointment-details-and-case-documents">
        {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 3{/* dont save PIC */}
                <button
                  className="field-actions"
                  onClick={() =>
                    this.handleRecentChange(
                      itemModel,
                      'Status_PaperworkReceived',
                      DEFAULT_COLUMNS_RECENT_CHANGE,
                      DEFAULT_FIELD_NAME_RECENT_CHANGE,
                    )
                  }
                >
                  Recent changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              size="large"
              value={pic_3?.picId || undefined}
              showSearch
              optionFilterProp="children"
              onChange={(value, event) => this.handleChangePIC(value, event, 'PIC_3')}
            >
              {staffs.itemList &&
                staffs.itemList.length &&
                staffs.itemList.map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.FullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Paperwork Received Date">
          <DatePicker
            size="large"
            format="DD/MM/YYYY"
            value={renderDate(itemModel.PaperworkReceivedDate)}
            disabled={itemModel.isLocked || !itemModel.PaperWorkReceived || api.isCaseManager()}
            onChange={value => this.handleChangeDatePicker('PaperworkReceivedDate', value)}
          />
        </Form.Item>

        {api.isAdminOrMagStaffOrAccounting() && (
          <div className="fluid-field field-action action-group" style={commonInlineStyles.flexCenter}>
            <button className="btn-action" onClick={this.handleSendReminder}>
              Send CM reminders{' '}
              <Popup
                trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                content="Email booking CM reminders to send paperwork (MAIL-02, MAIL-03)"
                position="top center"
                wide="very"
              />
            </button>
            <button className="btn-action" onClick={this.handleViewHistoryReminder}>
              View Reminder history
            </button>
          </div>
        )}
        <div className="fluid-field checkbox-field" style={commonInlineStyles.flexCenter}>
          <Checkbox
            checked={itemModel.PaperWorkReceived}
            onChange={this.handleChangeCheckBox('PaperWorkReceived')}
            className="checkbox-session"
            disabled={
              !api.isAdminOrMagStaffOrAccounting()
              // || (itemModel.WifroIlars && !itemModel.IROFundingApproved)
            }
          >
            Paperwork Sent to Specialist
          </Checkbox>
        </div>
        <Form.Item label="Paperwork Notes" className="fluid-field">
          <Input.TextArea
            rows={3}
            value={itemModel.SpecialistPaperworkNotes}
            disabled={true}
          />
        </Form.Item>
        <Form.Item label="Paperwork Sent Date">
          <DatePicker
            size="large"
            format="DD/MM/YYYY"
            value={renderDate(itemModel.OtherMethodSendingDate)}
            disabled
            onChange={value => this.handleChangeDatePicker('OtherMethodSendingDate', value)}
          />
        </Form.Item>
        <Form.Item label="Standard Completion Date">
          <DatePicker
            size="large"
            format="DD/MM/YYYY"
            value={renderDate(itemModel.RequestExpectedCompletionDate)}
            disabled={
              (!api.isAdminOrMagStaffOrAccounting() &&
                (oldPaperworkReceived ||
                  api.isDoctor() ||
                  itemModel.ReportCompleted ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Status === 'Billed')) ||
              api.isCaseManager()
            }
            onChange={value => this.handleChangeDatePicker('RequestExpectedCompletionDate', value)}
          />
        </Form.Item>
        <FieldUpload
          uploadLoading={store.loadingUpload}
          className="add-file-container posstion-IsTypist-label"
          label={<span>Attached Letter of Instruction (LOI): (Only 1 document allowed)</span>}
          attachments={store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_Paperwork)}
          reportType={LOCAL_CONSTANT.ReportType_Paperwork}
          multiUpload={false}
          uploadTitle={'1 PDF file only'}
          disabled={
            (!api.isAdminOrMagStaffOrAccounting() &&
              (api.isDoctor() ||
                itemModel.ReportCompleted ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Status === 'Billed')) ||
            (api.isCaseManager() && !!itemModel.DateFrom && moment().isAfter(itemModel.DateFrom)) ||
            (api.isCaseManager() &&
              (itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP)) ||
            lockTypist
          }
          disabledDelete={
            !api.isAdminOrMagStaffOrAccounting() &&
            (oldPaperworkReceived ||
              api.isDoctor() ||
              itemModel.ReportCompleted ||
              itemModel.Attendance === CONSTANTS.Attendance_LCF ||
              itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
              itemModel.Status === 'Billed' ||
              (api.isCaseManager() &&
                (itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP)) ||
              lockTypist)
          }
          disableDragDrop={true}
        />
        <Form.Item label="Special Request">
          <React.Fragment>
            <Checkbox
              style={{ marginTop: '8px' }}
              checked={itemModel.IsLongPaperwork}
              disabled={api.isCaseManager() || lockTypist}
              onChange={this.handleChangeCheckBox('IsLongPaperwork')}
            >
              Over 20 pages
            </Checkbox>
            <div className={'ant-checkbox-wrapper'}>Total pages: {pages}</div>
            <br />
            <Checkbox
              style={{ marginTop: '13px' }}
              checked={itemModel.IsInvoiceUrgentReport}
              disabled={lockTypist}
              onChange={this.handleChangeCheckBox('IsInvoiceUrgentReport')}
            >
              Urgent Report Completion{' '}
              {!!itemModel.IsInvoiceUrgentReportDate && (
                <>
                  <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                    {itemModel.IsInvoiceUrgentReportDate
                      ? renderDate(itemModel.IsInvoiceUrgentReportDate, false, true)
                      : ''}
                  </span>
                  <span style={{ color: '#107fc9' }}>
                    {itemModel.IsInvoiceUrgentReportBy ? ` - By ${itemModel.IsInvoiceUrgentReportBy}` : ''}
                  </span>
                </>
              )}
            </Checkbox>
            <br />
            {itemModel.IsInvoiceUrgentReport && (
              <Radio.Group
                style={{ marginTop: '13px' }}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                value={itemModel.UrgentReportType}
                onChange={this.handleChangeField('UrgentReportType')}
              >
                <Radio value={1} style={styles.radioBlockBottom}>
                  VWA Report{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                    content="Urgent reports between 2-7 business days of appointment date, additional fees apply"
                    position="top center"
                    wide="very"
                  />
                </Radio>
                <Radio value={2} style={styles.radioBlockBottom}>
                  Other Report
                  <Popup
                    trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                    content="Urgent reports between 2-7 business days of appointment date, no additional fees apply"
                    position="top center"
                    wide="very"
                  />
                </Radio>
              </Radio.Group>
            )}
            {itemModel.IsInvoiceUrgentReport && <br />}
            {itemModel.IsInvoiceUrgentReport && (
              <Form.Item label="Requested Completion Date Before" style={{ marginTop: '13px', marginBottom: '0' }}>
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={renderDate(itemModel.UrgentReportReportCompletionDate)}
                  disabled={
                    !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())
                  }
                  onChange={value => this.handleChangeDatePicker('UrgentReportReportCompletionDate', value)}
                />
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={itemModel.UrgentReportConfirmedByCM}
                  disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                  onChange={this.handleChangeCheckBox('UrgentReportConfirmedByCM')}
                >
                  Request Authorised by CM{' '}
                </Checkbox>
                {!!itemModel.UrgentReportConfirmedByCMDate && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.UrgentReportConfirmedByCMDate
                        ? renderDate(itemModel.UrgentReportConfirmedByCMDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.UrgentReportConfirmedByCMBy ? ` - By ${itemModel.UrgentReportConfirmedByCMBy}` : ''}
                    </span>
                  </>
                )}
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={itemModel.RequestAcceptedBySpecialist}
                  disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isDoctor())}
                  onChange={this.handleChangeCheckBox('RequestAcceptedBySpecialist')}
                >
                  Request Accepted by Specialist{' '}
                </Checkbox>
                {!!itemModel.RequestAcceptedBySpecialistDate && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.RequestAcceptedBySpecialistDate
                        ? renderDate(itemModel.RequestAcceptedBySpecialistDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.RequestAcceptedBySpecialistBy
                        ? ` - By ${itemModel.RequestAcceptedBySpecialistBy}`
                        : ''}
                    </span>
                  </>
                )}

                {false && (
                  <div style={{ width: '100%', marginTop: '13px' }}>
                    <FieldUpload
                      multiUpload={true}
                      uploadLoading={store.loadingUpload}
                      className="add-file-container add-files-urgent-report"
                      label={<span>Attached CM Authorisation</span>}
                      attachments={store.renderAttachment(
                        itemModel.AssessmentAttachment,
                        LOCAL_CONSTANT.UrgentReportCMAuthorisation,
                      )}
                      reportType={LOCAL_CONSTANT.UrgentReportCMAuthorisation}
                      disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disabledDelete={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                      disableDragDrop={true}
                    />
                  </div>
                )}
              </Form.Item>
            )}
            {false && itemModel.IsInvoiceUrgentReport && <br />}
            {false && itemModel.IsInvoiceUrgentReport && (
              <div>
                <div>
                  <label>
                    <b>Send Urgent Report Request to{' :'}</b>
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Dropdown
                    disabled={
                      (!itemModel.NotifyUrgentReportToStaffDate &&
                        !itemModel.NotifyUrgentReportToSpecialistDate &&
                        !itemModel.NotifyUrgentReportToCMDate) ||
                      itemModel.isLocked
                    }
                    overlay={
                      <Menu>
                        <Menu.Item
                          disabled={!itemModel.NotifyUrgentReportToStaffDate || itemModel.isLocked}
                          onClick={() => this.handleNotifyUrgentReport(1)}
                        >
                          MLP Staff{' '}
                        </Menu.Item>
                        <Menu.Item
                          disabled={!itemModel.NotifyUrgentReportToCMDate || itemModel.isLocked}
                          onClick={() => this.handleNotifyUrgentReport(3)}
                        >
                          Case Manager{' '}
                        </Menu.Item>
                        <Menu.Item
                          disabled={!itemModel.NotifyUrgentReportToSpecialistDate || itemModel.isLocked}
                          onClick={() => this.handleNotifyUrgentReport(2)}
                        >
                          Specialist{' '}
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button className="blue">Resend Urgent Report Request</Button>
                  </Dropdown>
                </div>
                <Checkbox
                  style={{ marginTop: '8px' }}
                  checked={itemModel.NotifyUrgentReportToStaff}
                  disabled={
                    !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                    !!itemModel.NotifyUrgentReportToStaffDate
                  }
                  onChange={this.handleChangeCheckBox('NotifyUrgentReportToStaff')}
                >
                  MLP Staff{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                    content="Urgent Report Request will be sent (Mail-163)"
                    position="top center"
                    wide="very"
                  />
                </Checkbox>
                {!!itemModel.NotifyUrgentReportToStaffDate && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.NotifyUrgentReportToStaffDate
                        ? renderDate(itemModel.NotifyUrgentReportToStaffDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.NotifyUrgentReportToStaffBy ? ` - By ${itemModel.NotifyUrgentReportToStaffBy}` : ''}
                    </span>
                  </>
                )}
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={itemModel.NotifyUrgentReportToCM}
                  disabled={
                    !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                    !!itemModel.NotifyUrgentReportToCMDate
                  }
                  onChange={this.handleChangeCheckBox('NotifyUrgentReportToCM')}
                >
                  Case Manager{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                    content="Urgent Report Request will be sent (Mail-165)"
                    position="top center"
                    wide="very"
                  />
                </Checkbox>
                {!!itemModel.NotifyUrgentReportToCMDate && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.NotifyUrgentReportToCMDate
                        ? renderDate(itemModel.NotifyUrgentReportToCMDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.NotifyUrgentReportToCMBy ? ` - By ${itemModel.NotifyUrgentReportToCMBy}` : ''}
                    </span>
                  </>
                )}
                <br />
                <Checkbox
                  style={{ marginTop: '13px' }}
                  checked={itemModel.NotifyUrgentReportToSpecialist}
                  disabled={
                    !(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) ||
                    !!itemModel.NotifyUrgentReportToSpecialistDate
                  }
                  onChange={this.handleChangeCheckBox('NotifyUrgentReportToSpecialist')}
                >
                  Specialist{' '}
                  <Popup
                    trigger={<IconSemantic name="info circle" style={commonInlineStyles.marginLeft5} />}
                    content="Urgent Report Request will be sent (Mail-164)"
                    position="top center"
                    wide="very"
                  />
                </Checkbox>
                {!!itemModel.NotifyUrgentReportToSpecialistDate && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.NotifyUrgentReportToSpecialistDate
                        ? renderDate(itemModel.NotifyUrgentReportToSpecialistDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.NotifyUrgentReportToSpecialistBy
                        ? ` - By ${itemModel.NotifyUrgentReportToSpecialistBy}`
                        : ''}
                    </span>
                  </>
                )}
              </div>
            )}
          </React.Fragment>
        </Form.Item>
        <Form.Item
          className="fluid-field"
          validateStatus={!store.validateField(itemModel.PaperworkExLink, 'url') ? 'error' : null}
          help={!store.validateField(itemModel.PaperworkExLink, 'url') ? 'Download link is not valid' : ''}
          label={
            <>
              Or Share Download Link
              <button
                className={this.validateLinkIsValid(itemModel.PaperworkExLink) ? 'field-actions' : 'btn-disbled'}
                onClick={() => {
                  !(!this.validateLinkIsValid(itemModel.PaperworkExLink) || !itemModel.PaperworkExLink) &&
                    this.openPaperworkLink(itemModel.PaperworkExLink);
                }}
                disabled={!this.validateLinkIsValid(itemModel.PaperworkExLink) || !itemModel.PaperworkExLink}
              >
                Open Link
              </button>
            </>
          }
        >
          <Input.TextArea
            rows={3}
            value={itemModel.PaperworkExLink}
            onChange={this.handleChangeTextInput('PaperworkExLink')}
            disabled={
              (!api.isAdminOrMagStaffOrAccounting() &&
                (api.isDoctor() ||
                  itemModel.ReportCompleted ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Status === 'Billed')) ||
              lockTypist
            }
          />
        </Form.Item>
        <Form.Item label="Share Password 1" className="fluid-field">
          <Input
            size="large"
            disabled={
              (!api.isAdminOrMagStaffOrAccounting() &&
                (api.isDoctor() ||
                  itemModel.ReportCompleted ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Status === 'Billed')) ||
              lockTypist
            }
            value={itemModel.SharePassword1}
            onChange={this.handleChangeTextInput('SharePassword1')}
          />
        </Form.Item>
        <FieldUpload
          uploadLoading={store.loadingUpload}
          label="Attached Other Documents"
          attachments={store.renderAttachment(itemModel.AssessmentAttachment, LOCAL_CONSTANT.ReportType_PaperworkOther)}
          reportType={LOCAL_CONSTANT.ReportType_PaperworkOther}
          multiUpload={true}
          disabledDelete={
            (!api.isAdminOrMagStaffOrAccounting() &&
              (oldPaperworkReceived ||
                api.isDoctor() ||
                itemModel.ReportCompleted ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Status === 'Billed')) ||
            (api.isCaseManager() &&
              (itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP)) ||
            lockTypist
          }
          disabled={
            (!api.isAdminOrMagStaffOrAccounting() &&
              (api.isDoctor() ||
                itemModel.ReportCompleted ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Status === 'Billed')) ||
            (api.isCaseManager() && !!itemModel.DateFrom && moment().isAfter(itemModel.DateFrom)) ||
            (api.isCaseManager() &&
              (itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                itemModel.Attendance === CONSTANTS.CancelledSpecialistTBP)) ||
            api.isTypist()
          }
        />
        <Form.Item
          className="fluid-field"
          validateStatus={!store.validateField(itemModel.PaperworkOtherExLink, 'url') ? 'error' : null}
          help={!store.validateField(itemModel.PaperworkOtherExLink, 'url') ? 'Download link is not valid' : ''}
          label={
            <>
              Or Share Download Link
              <button
                className={this.validateLinkIsValid(itemModel.PaperworkOtherExLink) ? 'field-actions' : 'btn-disbled'}
                onClick={() => {
                  !(!this.validateLinkIsValid(itemModel.PaperworkOtherExLink) || !itemModel.PaperworkOtherExLink) &&
                    this.openPaperworkLink(itemModel.PaperworkOtherExLink);
                }}
                disabled={!this.validateLinkIsValid(itemModel.PaperworkOtherExLink) || !itemModel.PaperworkOtherExLink}
              >
                Open Link
              </button>
            </>
          }
        >
          <Input.TextArea
            rows={3}
            value={itemModel.PaperworkOtherExLink}
            onChange={this.handleChangeTextInput('PaperworkOtherExLink')}
            disabled={
              (!api.isAdminOrMagStaffOrAccounting() &&
                (api.isDoctor() ||
                  itemModel.ReportCompleted ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Status === 'Billed')) ||
              lockTypist
            }
          />
        </Form.Item>
        <Form.Item label="Share Password 2" className="fluid-field">
          <Input
            size="large"
            disabled={
              (!api.isAdminOrMagStaffOrAccounting() &&
                (api.isDoctor() ||
                  itemModel.ReportCompleted ||
                  itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled ||
                  itemModel.Status === 'Billed')) ||
              lockTypist
            }
            value={itemModel.SharePassword2}
            onChange={this.handleChangeTextInput('SharePassword2')}
          />
        </Form.Item>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item className="fluid-field" label="Comments">
            <Input.TextArea
              rows={3}
              value={itemModel.PaperworkComment}
              disabled={api.isCaseManager() || lockTypist}
              onChange={this.handleChangeTextInput('PaperworkComment')}
            />
          </Form.Item>
        )}
        <Divider className="fluid-field" />
        <div className="fluid-field checkbox-field" style={{ marginBottom: '6px' }}>
          <Checkbox
            checked={itemModel.NotifyPaperworkReadinessToStaff}
            disabled={
              itemModel.SentDateNotifyPaperworkReadinessToStaff ||
              lockTypist ||
              api.isDoctor() ||
              (api.isCaseManager &&
                (itemModel.Attendance === CONSTANTS.Attendance_LCF ||
                  itemModel.Attendance === CONSTANTS.Attendance_Cancelled))
            }
            onChange={this.handleChangeCheckBox('NotifyPaperworkReadinessToStaff')}
          >
            <h4 style={commonInlineStyles.inlineBlock}>Send Paperwork Readiness to MLP Staff</h4>
            {this.renderPopup(
              'A notice will be sent to staff to review paperwork before sending to specialist (MAIL-PAPERWORK-READINESS)',
            )}
          </Checkbox>
          {itemModel.SentDateNotifyPaperworkReadinessToStaff && (
            <>
              <span style={styles.emailPaperworkReceivedDate}>
                {itemModel.SentDateNotifyPaperworkReadinessToStaff
                  ? renderDate(itemModel.SentDateNotifyPaperworkReadinessToStaff, false, true)
                  : ''}
                {' - By ' + store.renderStaffName(itemModel.SentByNotifyPaperworkReadinessToStaff)}
              </span>
              {itemModel.SentDateNotifyPaperworkReadinessToStaff && (
                <Button
                  className="blue"
                  disabled={!itemModel.NotifyPaperworkReadinessToStaff}
                  onClick={() => {
                    store.toggleModal(true, {
                      message: 'Do you want to resend Paperwork readiness to Staff?',
                      modalType: 'confirm',
                      onOk: () => {
                        store.handleResendPaperworkReadiness();
                      },
                    })();
                  }}
                >
                  Resend Paperwork Readiness
                </Button>
              )}
            </>
          )}
          {/* <Button
            disabled={
              !itemModel.AllowDoctorToViewPaperwork ||
              (itemModel.AllowDoctorToViewPaperwork && !itemModel.EmailToPaperworkReceivedRecent) ||
              lockTypist
            }
            className="blue"
            onClick={() => {
              !(
                !itemModel.AllowDoctorToViewPaperwork ||
                (itemModel.AllowDoctorToViewPaperwork && !itemModel.EmailToPaperworkReceivedRecent) ||
                lockTypist
              ) && this.handleResendPaperwork();
            }}
          >
            Resend Paperwork
          </Button> */}
        </div>
        <Form.Item className="fluid-field">
          <div>
            <React.Fragment>
              <Checkbox
                className="checkbox-IsTypist"
                style={{ marginTop: '1px' }}
                checked={itemModel.IcareClaim}
                disabled={api.isTypist() || api.isDoctor()}
                onChange={this.handleChangeCheckBox('IcareClaim')}
              >
                <b>
                  This is iCare Claim{' '}
                  {this.renderPopup('Send iCare reminders to Staff (MAIL-170), Specialist (MAIL-169)')}
                </b>{' '}
                {itemModel.IcareClaim && itemModel.IcareClaimDate && (
                  <span style={styles.emailPaperworkReceivedDate}>
                    {itemModel.IcareClaimDate ? renderDate(itemModel.IcareClaimDate, false, true) : ''}
                    {' - By ' + store.renderStaffName(itemModel.IcareClaimBy)}
                  </span>
                )}
              </Checkbox>
              {api.isAdminOrMagStaffOrAccounting() && (
                <Dropdown
                  //disabled={(!itemModel.SentDateNotifyCMAppointmentInquiry && !itemModel.SentDateNotifyDoctorAppointmentInquiry) || lockTypist}
                  overlay={
                    <Menu>
                      <Menu.Item
                        disabled={!itemModel.IcareClaim}
                        onClick={() => store.handlSendICareCompletionRequest(1)}
                      >
                        To Specialist{' '}
                      </Menu.Item>
                      <Menu.Item
                        disabled={!itemModel.IcareClaim}
                        onClick={() => store.handlSendICareCompletionRequest(2)}
                      >
                        To Staff{' '}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button className="blue">
                    Send iCare Reminder <Icon type="down" />
                  </Button>
                </Dropdown>
              )}
            </React.Fragment>
          </div>
        </Form.Item>
        {itemModel.IcareClaim && (
          <>
            <Form.Item label="Insurer">
              <Select
                size="large"
                value={
                  store.companyInsurer
                    ? store.companyInsurer.Id !== 0
                      ? store.companyInsurer?.Id
                      : undefined
                    : undefined
                }
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={(value, event) => this.handleChangeObj(value, event, 'companyInsurer')}
                onSearch={this.handleSearch('companyInsurer')}
                onPopupScroll={this.handleScroll('companyInsurer')}
                disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
              >
                {insurerCompanies.itemList &&
                  this.itemToArray(store.companyInsurer, insurerCompanies.itemList, 'Id').map(i => (
                    <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                      {i.Id} - {i.Name} - {i.StateName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reason for Referral"
              // required={itemModel.IcareClaim}
              // validateStatus={!!store.reasonReferral?.Id || !itemModel.IcareClaim ? null : 'error'}
              // help={!!store.reasonReferral?.Id || !itemModel.IcareClaim ? '' : 'This field is required'}
            >
              <Select
                size="large"
                disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
                value={store.reasonReferral?.Id || undefined}
                onChange={(value, event) => this.handleChangeObj(value, event, 'reasonReferral')}
                showSearch
                optionFilterProp="children"
              >
                {(reasonReferral?.reasonReferralItemList || []).map(i => (
                  <Select.Option key={i.Id} value={i.Id} data={JSON.stringify(i)}>
                    {i.Label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Service Type">
              <Select
                size="large"
                disabled={api.isTypist() || api.isDoctor() || !itemModel.IcareClaim}
                value={itemModel.IcareServiceTypeId || undefined}
                onChange={this.handleChangeDropdownObj('IcareServiceTypeId')}
                showSearch
                optionFilterProp="children"
              >
                {icareLookupList &&
                  icareLookupList.itemList &&
                  icareLookupList.itemList.length &&
                  icareLookupList.itemList[7] &&
                  icareLookupList.itemList[7].length > 0 &&
                  icareLookupList.itemList[7].map(i => (
                    <Select.Option key={i.Id} value={i.Id}>
                      {i.Label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item></Form.Item>
          </>
        )}
        <div className="fluid-field checkbox-field">
          <Checkbox
            checked={itemModel.AllowDoctorToViewPaperwork}
            disabled={itemModel.EmailToPaperworkReceivedRecent || lockTypist || !api.isAdminOrMagStaffOrAccounting()}
            onChange={this.handleChangeCheckBox('AllowDoctorToViewPaperwork')}
          >
            <h4 style={commonInlineStyles.inlineBlock}>Send Paperwork to Specialist</h4>
            {this.renderPopup('Case documents will be sent to Specialist (MAIL-31, MAIL-31-Resend)')}
          </Checkbox>
          {itemModel.EmailToPaperworkReceivedRecent && (
            <>
              <span style={styles.emailPaperworkReceivedDate}>
                {itemModel.EmailToPaperworkReceivedRecent
                  ? renderDate(itemModel.EmailToPaperworkReceivedRecent, false, true)
                  : ''}
                {' - By ' + store.renderStaffName(itemModel.EmailToPaperworkReceivedRecentByUser)}
              </span>
              {itemModel.EmailToPaperworkReceivedRecent && (
                <Button
                  className="blue"
                  disabled={
                    api.isDoctor() ||
                    (!api.isAdminOrMagStaffOrAccounting() && itemModel.ChargeStatus === 0) ||
                    !itemModel.AllowDoctorToViewPaperwork ||
                    api.isTypist() ||
                    api.isCaseManager()
                  }
                  onClick={() => {
                    store.toggleModal(true, {
                      message: 'Do you want to resend Paperwork to Specialist?',
                      modalType: 'confirm',
                      onOk: () => {
                        this.handleResendPaperwork();
                      },
                    })();
                  }}
                >
                  Resend Paperwork
                </Button>
              )}
            </>
          )}
          {/* <Button
            disabled={
              !itemModel.AllowDoctorToViewPaperwork ||
              (itemModel.AllowDoctorToViewPaperwork && !itemModel.EmailToPaperworkReceivedRecent) ||
              lockTypist
            }
            className="blue"
            onClick={() => {
              !(
                !itemModel.AllowDoctorToViewPaperwork ||
                (itemModel.AllowDoctorToViewPaperwork && !itemModel.EmailToPaperworkReceivedRecent) ||
                lockTypist
              ) && this.handleResendPaperwork();
            }}
          >
            Resend Paperwork
          </Button> */}
        </div>
        <Divider className="fluid-field" />
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item className="fluid-field" label="Send Paperwork by Other Methods">
            <Checkbox.Group value={itemModel.SelectedPaperworkToDoctorBies} onChange={this.handleChangeCheckBoxGroup}>
              {specificLookupConfigOrderNoByType &&
                specificLookupConfigOrderNoByType.itemList &&
                specificLookupConfigOrderNoByType.itemList.map(i => (
                  <Checkbox key={i.Id} value={i.Id}>
                    {i.Label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default AppointmentDetailsAndCaseDocuments;
