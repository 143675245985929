import { toJS } from 'mobx';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../../../shared/Modal';

function ModalConfirm({ open, onCancel, message, onOk, ...props }) {
  const renderConfirmMessage = () => {
    if (Array.isArray(toJS(message))) {
      return (
        <div className="modal-confirm-message d-flex">
          <Icon name="warning sign" color="yellow" />
          <div>
            {message.map((m, idx) => (
              <p key={idx}>{m}</p>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className="modal-confirm-message">
        {!props.withoutIcon && <Icon name="warning sign" color="yellow" />}
        {message}
      </div>
    );
  };

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          {onOk && (
            <Button color="blue" onClick={onOk}>
              <Icon name="check" /> Yes
            </Button>
          )}
          <Button color="red" onClick={onCancel}>
            <Icon name="close" /> {props.cancelText || 'No'}
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalConfirm;
