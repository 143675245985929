import { Spin } from 'antd';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React from 'react';

import LMQCriteriasModal from './LMQCriteriasModal';
import LMQCriteriasStore from './LMQCriteriasStore';
import LMQCriteriasTable from './LMQCriteriasTable';
import LMQCriteriasToolbar from './LMQCriteriasToolbar';
import ui from '../../../stores/dashboard';
import dashboardStore from '@stores/dashboard';
import * as api from '@stores/api';

@observer
class LMQCriterias extends React.Component {
  componentDidMount() {
    if (!api.isAdminOrMagStaffOrAccounting()) {
      dashboardStore.close('/view/lmq-criteria', true);
      return;
    }
    LMQCriteriasStore.fetchFiles(true);
    ui.on('open(/view/types-of-claims-2)', this._searchDebounced);
  }

  _searchDebounced = debounce(() => {
    LMQCriteriasStore.fetchFiles(true);
  }, 500);
  render() {
    const { loading, data, open } = LMQCriteriasStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <LMQCriteriasToolbar />
            <LMQCriteriasTable />
            {open && <LMQCriteriasModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default LMQCriterias;
