import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import './index.scss';

import SessionAvailabilityModal from './SessionAvailabilityModal';
import SessionAvailabilityStore from './SessionAvailabilityStore';
import SessionAvailabilityTable from './SessionAvailabilityTable';
import SessionAvailabilityToolbar from './SessionAvailabilityToolbar';

@observer
class SessionAvailability extends React.Component {
  render() {
    const { loading, data, open } = SessionAvailabilityStore;
    return (
      <div className={`Table-${loading && !data ? 'loading' : 'container'}`}>
        {loading && !data ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <SessionAvailabilityToolbar />
            <SessionAvailabilityTable />
            {open && <SessionAvailabilityModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SessionAvailability;
