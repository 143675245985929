import './DoctorDetails.scss';
import { observer } from 'mobx-react';
import Validator from 'validatorjs';
import cx from 'classnames';
import React from 'react';
import { Grid, Form, Segment, Input, Checkbox, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import DoctorDetailsStore from './DoctorDetailsStore';
import TextArea from './TextArea';

@observer
class ServiceInformation extends React.Component {
  renderProviderNo = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [
      dataDoctor.ProviderNumber,
      dataDoctor.ProviderNumber2,
      dataDoctor.ProviderNumber3,
      dataDoctor.ProviderNumber4,
    ].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {i}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input
          name="ProviderNumber"
          className="mb-1"
          value={dataDoctor.ProviderNumber || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber2"
          className="mb-1"
          value={dataDoctor.ProviderNumber2 || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber3"
          className="mb-1"
          value={dataDoctor.ProviderNumber3 || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ProviderNumber4"
          className="mb-1"
          value={dataDoctor.ProviderNumber4 || ''}
          onChange={setFieldValue}
        />
      </React.Fragment>
    );
  };
  renderMAGChargeRate = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [dataDoctor.MAGChargeRate, dataDoctor.MAGChargeRate2].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {`${i}%`}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input
          name="MAGChargeRate"
          className="mb-1"
          value={dataDoctor.MAGChargeRate || ''}
          disabled
          onChange={setFieldValue}
        />
        <Input
          name="MAGChargeRate2"
          className="mb-1"
          disabled
          value={dataDoctor.MAGChargeRate2 || ''}
          onChange={setFieldValue}
        />
      </React.Fragment>
    );
  };
  renderNONMAGChargeRate = () => {
    const { dataDoctor, isEditing, setFieldValue } = DoctorDetailsStore;
    const array = [dataDoctor.ChargeRate, dataDoctor.ChargeRate2].filter(i => i !== null);
    return !isEditing ? (
      <div>
        <span className="Label-item">
          {array.map((i, idx) => (
            <React.Fragment key={idx}>
              {`${i}%`}
              {idx < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      </div>
    ) : (
      <React.Fragment>
        <Input
          name="ChargeRate"
          className="mb-1"
          disabled
          value={dataDoctor.ChargeRate || ''}
          onChange={setFieldValue}
        />
        <Input
          name="ChargeRate2"
          className="mb-1"
          value={dataDoctor.ChargeRate2 || ''}
          onChange={setFieldValue}
          disabled
        />
      </React.Fragment>
    );
  };

  validateEmail = emailStr => {
    if (emailStr) {
      const validation = new Validator({ email: emailStr }, { email: 'email' }, 'Invalid email address');
      if (validation.passes()) {
        return null;
      }
      return 'Invalid email address';
    }
    return null;
  };

  validateFromDate = (isActive, fromDate) => {
    if (isActive) {
      if (!fromDate) return 'This field is required';
      return;
    }
    return;
  };

  render() {
    const { dataDoctor, isEditing, setFieldValue, toggleCheckbox } = DoctorDetailsStore;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className="Segment-View">
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Group-label">Payment Info</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Provider No. 1</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber" value={dataDoctor.ProviderNumber || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumberNote || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumberNote" maxLength="1000" />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Provider No. 2</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber2 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber2" value={dataDoctor.ProviderNumber2 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber2Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber2Note" maxLength="1000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Provider No. 3</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber3 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber3" value={dataDoctor.ProviderNumber3 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber3Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber3Note" maxLength="1000" />
                    )}
                  </Form.Field>
                  <Form.Field width="8">
                    <label className="Form-Field-label">Provider No. 4</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber4 || 'None'}</span>
                    ) : (
                      <Input name="ProviderNumber4" value={dataDoctor.ProviderNumber4 || ''} onChange={setFieldValue} />
                    )}
                    <label className="Form-Field-label">Notes</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ProviderNumber4Note || 'None'}</span>
                    ) : (
                      <TextArea name="ProviderNumber4Note" maxLength="1000" />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">ABN</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ABN || 'None'}</span>
                    ) : (
                      <Input name="ABN" value={dataDoctor.ABN || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Name of bank</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.BankName || 'None'}</span>
                    ) : (
                      <Input name="BankName" value={dataDoctor.BankName || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Account name</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountName || 'None'}</span>
                    ) : (
                      <Input name="AccountName" value={dataDoctor.AccountName || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">BSB</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.BSB || 'None'}</span>
                    ) : (
                      <Input name="BSB" value={dataDoctor.BSB || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Account number</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountNumber || 'None'}</span>
                    ) : (
                      <Input name="AccountNumber" value={dataDoctor.AccountNumber || ''} onChange={setFieldValue} />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Account email 1</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountingEmail1 || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="AccountingEmail1"
                          value={dataDoctor.AccountingEmail1 || ''}
                          onChange={setFieldValue}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.AccountingEmail1) && '-error'}`,
                          )}
                        />
                        {this.validateEmail(dataDoctor.AccountingEmail1) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.AccountingEmail1)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Account email 2</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.AccountingEmail2 || 'None'}</span>
                    ) : (
                      <React.Fragment>
                        <Input
                          name="AccountingEmail2"
                          value={dataDoctor.AccountingEmail2 || ''}
                          className={cx(
                            `input-doctor-details-edit${this.validateEmail(dataDoctor.AccountingEmail2) && '-error'}`,
                          )}
                          onChange={setFieldValue}
                        />
                        {this.validateEmail(dataDoctor.AccountingEmail2) && (
                          <span className="invalid-label-eror-doctor-details">
                            {this.validateEmail(dataDoctor.AccountingEmail2)}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label className="Form-Field-label">
                    Approved Prepayment
                    <Checkbox
                      className="Form-field-radio ml-1"
                      label={<label style={{ marginTop: 5 }} />}
                      name="ApprovedPrepayment"
                      checked={dataDoctor.ApprovedPrepayment}
                      onChange={toggleCheckbox}
                      disabled
                    />
                  </label>
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Valid until</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ValidUntil || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ValidUntil"
                        animation=""
                        duration={0}
                        clearable
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ValidUntil || ''}
                        onChange={setFieldValue}
                        disabled
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Approved date</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedDate || 'None'}</span>
                    ) : (
                      <DateInput
                        name="ApprovedDate"
                        animation=""
                        duration={0}
                        clearable
                        disabled
                        placeholder="dd/mm/yyyy"
                        value={dataDoctor.ApprovedDate || ''}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label className="Form-Field-label">Approved Amount (Max)</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedAmountMax || 'None'}</span>
                    ) : (
                      <Input
                        name="ApprovedAmountMax"
                        value={dataDoctor.ApprovedAmountMax || ''}
                        disabled
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="Form-Field-label">Approved by</label>
                    {!isEditing ? (
                      <span className="Label-item">{dataDoctor.ApprovedName || 'None'}</span>
                    ) : (
                      <Dropdown
                        search
                        selection
                        name="ApprovedBy"
                        disabled
                        options={DoctorDetailsStore.dataStaffs}
                        value={dataDoctor.ApprovedBy}
                        onChange={setFieldValue}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default ServiceInformation;
