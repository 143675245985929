import { Dropdown, Menu, notification, Form } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader } from '../../shared/table';
import customFetch from '../../../utils/customFetch';
import ConversationModalForm from './ConversationModalForm';
import store from './store';
import * as api from '@stores/api';

import { Input } from '@components/shared/tableToolbar/ToolbarComponents';

const columns = [
  {},
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Caller/Sender' },
  { title: 'Receiver Type' },
  { title: 'Receiver' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

const columns1 = [
  { title: 'Date/Time' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Caller/Sender Type' },
  { title: 'Receiver Type' },
  { title: 'Created By' },
  { title: 'Correspondence Type' },
];

@observer
class CaseCorrespondenceForm extends React.Component {
  state = {
    visible: false,
    conversation: null,
  };

  toggleModal = (visible, conversation) => () => {
    this.setState({
      visible,
      conversation: toJS(conversation),
    });
  };

  handleDelete = id => {
    customFetch('/FileReview/DeleteConversationLog', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        store.fetchConversations();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: `
            Error occured while delete data.
            Please report this to system administrator and try again later.
          `,
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  handleAllow = (id, type) => {
    customFetch('/FileReview/UpdateDoctorAndCMsView', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, type }),
    }).then(res => {
      notification.destroy();
      if (res.status === 'success') {
        store.fetchConversations();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      } else {
        notification.error({
          description: `
            Error occured while delete data.
            Please report this to system administrator and try again later.
          `,
          message: 'Fail',
          duration: 5,
        });
      }
    });
  };

  renderDropdownMenu = c => (
    <Menu>
      <Menu.Item
        onClick={() => {
          store.toggleModalConfirm(true, {
            message: `
              Are you sure to
              ${!c.AllowDoctorToView ? 'allow' : 'disallow'}
              CMs to view this entry?
            `,
            onOk: () => this.handleAllow(c.Id, 0),
          });
        }}
      >
        {!c.AllowCMToView ? 'Allow' : 'Disallow'} CMs to view
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          store.toggleModalConfirm(true, {
            message: `
              Are you sure to
              ${!c.AllowDoctorToView ? 'allow' : 'disallow'}
              Specialists/Clinics to view this entry?
            `,
            onOk: () => this.handleAllow(c.Id, 1),
          });
        }}
      >
        {!c.AllowDoctorToView ? 'Allow' : 'Disallow'} Specialists/Clinics to view
      </Menu.Item>
      {!c.IsAutoEmail && <Menu.Item onClick={this.toggleModal(true, c)}>Edit</Menu.Item>}
      <Menu.Item
        onClick={() => {
          store.toggleModalConfirm(true, {
            message: 'Are you sure to delete this entry?',
            onOk: () => this.handleDelete(c.Id),
          });
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  tripHtmlFromBody = htmlString => {
    const tmp = document.createElement('div');
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || '';
  };

  renderTableBody = () => {
    return store.conversations.map(c => {
      return (
        <Table.Row key={c.Id}>
          {api.isAdminOrMagStaffOrAccounting() && (
            <Table.Cell>
              <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(c)}>
                <Icon name="ellipsis vertical" />
              </Dropdown>
            </Table.Cell>
          )}
          <Table.Cell>{c.SentDate.format('DD/MM/YYYY HH:mm')}</Table.Cell>
          <Table.Cell>
            <div className="cell-correspondence-content-body">{c.Subject}</div>
            {c.Subject && (
              <div className="Cell-link">
                <Link to="#" onClick={this.toggleModal(true, c)}>
                  View more
                </Link>
              </div>
            )}
          </Table.Cell>
          <Table.Cell>
            <div className="cell-correspondence-content-body">{this.tripHtmlFromBody(c.Content)}</div>
            {c.Content && (
              <div className="Cell-link">
                <Link to="#" onClick={this.toggleModal(true, c)}>
                  View more
                </Link>
              </div>
            )}
          </Table.Cell>
          <Table.Cell>
            {c.CallerSenderType === 'CaseManager'
              ? 'Case Manager'
              : c.CallerSenderType === 'Staff'
              ? 'Staff'
              : c.CallerSenderType}
          </Table.Cell>
          {api.isAdminOrMagStaffOrAccounting() && <Table.Cell>{c.CallerSender}</Table.Cell>}
          <Table.Cell>
            {c.ReceiverType === 'CaseManager' ? 'Case Manager' : c.ReceiverType === 'Staff' ? 'Staff' : c.ReceiverType}
          </Table.Cell>
          {api.isAdminOrMagStaffOrAccounting() && <Table.Cell>{c.Receiver}</Table.Cell>}
          <Table.Cell>{c.UserName}</Table.Cell>
          <Table.Cell>{c.TypeLog}</Table.Cell>
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <div className="fluid-field">
        <div className="fluid-field" style={{ marginBottom: '1em', display: '-webkit-inline-box' }}>
          {!api.isOnlySearch() && <Button color="blue" onClick={this.toggleModal(true)}>
            Add New
          </Button>}
          <Button
            color="blue"
            onClick={() => {
              const { Id } = store.fileReviewInfo;
              window.open('/FileReview/ConversationLog_Export?Id=' + Id, '_blank');
            }}
          >
            Export Case Correspondence
          </Button>
          <Form
            onKeyPress={event => {
              if (event.key === 'Enter') {
                store.fetchConversations(event.target.value);
              }
            }}
          >
            <Input
              fluid
              loading={store.loadingConversation}
              icon="search"
              placeholder="Search any keyword"
              iconPosition="left"
              size={'small'}
            />
          </Form>
        </div>
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={api.isAdminOrMagStaffOrAccounting() ? columns : columns1} />
            <Table.Body>
              {store.conversations.length ? (
                this.renderTableBody()
              ) : (
                <TableEmptyMessage colSpan={(api.isAdminOrMagStaffOrAccounting() ? columns : columns1).length} />
              )}
            </Table.Body>
          </Table>
        </div>
        {this.state.visible && (
          <ConversationModalForm
            onCancel={this.toggleModal(false)}
            visible={this.state.visible}
            data={this.state.conversation}
          />
        )}
      </div>
    );
  }
}

export default CaseCorrespondenceForm;
