import './DoctorDetails.scss';
import React from 'react';
import { Menu as MenuAntd, Dropdown, notification } from 'antd';
import { Grid, Card, Icon } from 'semantic-ui-react';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';

import DoctorDetailsStore from './DoctorDetailsStore';
import customFetch from '../../../../utils/customFetch';
import dashboardStore from '../../../../stores/dashboard';

@observer
class MedicalCentreTab extends React.Component {
  openMedicalCentreModal = () => {
    return DoctorDetailsStore.toggleModal(true, {
      modalType: 'addEditClinic',
    })();
  };
  openCaseDetails = (record, action) => {
    localStorage.setItem(`ClinicName`, record.Name);
    dashboardStore.close('/view/add-edit-clinic-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinic-2?id=${record.ID}&action=${action}`);
    });
  };
  @action disableClinic = async clinicId => {
    const { dataDoctor, clinicListId } = DoctorDetailsStore;
    const clinics =
      toJS(clinicListId.itemList).length !== 0 ? toJS(clinicListId.itemList).filter(i => i !== clinicId) : null;
    const res = await customFetch('/MedicalDoctor/UpdateDoctorClinic', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        clinics,
        doctorId: dataDoctor.Id,
      }),
    });
    if (res.status === 'success') {
      notification.destroy();
      notification.success({
        message: 'Data has been saved successfully.',
      });
      DoctorDetailsStore.fetchListClinicDoctor(dataDoctor.Id);
      DoctorDetailsStore.fetchSelectedClinicByDoctorId(dataDoctor.Id);
    }
  };

  renderDropdownMenu = record => {
    return (
      <Dropdown
        overlay={
          <MenuAntd style={{ marginTop: '14px', float: 'left' }}>
            <MenuAntd.Item onClick={() => this.openCaseDetails(record, 'edit')}>Edit</MenuAntd.Item>
            <MenuAntd.Item onClick={() => this.disableClinic(record.ID)}>Remove</MenuAntd.Item>
          </MenuAntd>
        }
        placement="bottomLeft"
        arrow={true}
        trigger={['click']}
      >
        <Icon name="ellipsis vertical" className="Medical-centre-Image-icon" />
      </Dropdown>
    );
  };
  openMap = address => {
    window.open(`https://www.google.com/maps?q=${address.replace(/[^\d\w]+/g, '+')}`);
  };
  openMail = emailAddress => {
    if (emailAddress) {
      window.open('mailto:' + emailAddress);
    } else {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'This Clinic does not have an email.',
      })();
    }
  };
  openCall = phone => {
    if (phone) {
      window.open(`tel:${phone}`);
    } else {
      DoctorDetailsStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'This Clinic does not have a phone number.',
      })();
    }
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid relaxed columns={4}>
              <Grid.Column>
                <Card as="div" className="Medical-centre-Add-New" onClick={this.openMedicalCentreModal}>
                  <div className="Medical-centre-content">
                    <div>
                      <Icon name="plus" className="Medical-centre-Add-New-icon" />
                    </div>
                    <br />
                    <div>
                      <span className="Medical-centre-Add-New-text">Add Centre</span>
                    </div>
                  </div>
                </Card>
              </Grid.Column>
              {DoctorDetailsStore.clinicList.itemList.map(i => (
                <Grid.Column key={i.ID}>
                  <Card as="div" className="Medical-centre-card">
                    <div className="Medical-centre-Image">{this.renderDropdownMenu(i)}</div>
                    <Card.Content>
                      <Card.Header>
                        <p className="Medical-centre-Name">{i.Name}</p>
                      </Card.Header>
                      <Card.Meta>
                        <p className="Medical-centre-Address">{i.FullAddress}</p>
                      </Card.Meta>
                      <Card.Meta className="Medical-centre-box-icon">
                        <Icon
                          name="phone"
                          className="Medical-centre-Icon"
                          style={{ opacity: `${i.Telephone ? 1 : 0.2}` }}
                          onClick={() => this.openCall(i.Telephone)}
                        />
                        <Icon
                          name="mail"
                          className="Medical-centre-Icon"
                          style={{ opacity: `${i.Email ? 1 : 0.2}` }}
                          onClick={() => this.openMail(i.Email)}
                        />
                        <Icon
                          name="globe"
                          className="Medical-centre-Icon"
                          onClick={() => this.openMap(i.FullAddress)}
                        />
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              ))}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default MedicalCentreTab;
