import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Header } from 'semantic-ui-react';
import { notification } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import Modal from '@components/shared/Modal/';
import * as api from '@stores/api';
import customFetch from '@utils/customFetch';
import { getItemTextByKey } from '@utils/functions';
import { CKEditorConfig, RoleOptions } from '@utils/constants';
import NotificationStore from './NotificationStore';

function ModalAddOrView({ record, type }) {
  const [data, setData] = useState(record);

  useEffect(() => {
    if (type === 'view' || type === 'duplicate') {
      customFetch('/Notification/Get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: record.Id }),
      }).then(data => {
        if (data.status === 'success') {
          const { result } = data;

          if (type === 'duplicate') {
            result.Id = 0;
          }

          setData(result);
        }
      });
    }
  }, [type, record]);

  const handleOnChangeReceipientRole = (e, { value }) => {
    setData({ ...data, ReceipientRole: value });
  };

  const handleOnChangeSubject = (e, { value }) => {
    setData({ ...data, Summary: value });
  };

  const handleOnChangeContent = e => {
    var html = e.editor.getData();
    setData({ ...data, Details: html });
  };

  const handleOnSubmit = async () => {
    NotificationStore.setFieldsValue({ loading: true });

    const res = await customFetch('/Notification/Send', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data),
    });

    handleOnClose();
    NotificationStore.fetchNotifications(true);

    notification.destroy();
    if (res.status === 'success') {
      notification.success({
        message: 'Notification Sent Successfully',
        description: `Notification sent to ${res.sentTotal} users.`,
      });
    } else {
      notification.error({
        message: 'Notification Failed to Send',
        description: 'Please check logs for details.',
      });
    }
  };

  const handleOnClose = () => {
    NotificationStore.setFieldsValue({
      modalParams: {
        record: null,
        isOpen: false,
        type: 'view',
      },
    });
  };

  const renderAddForm = () => (
    <div className="ant-modal-addOrEdit">
      <Form size={'small'}>
        <Form.Field>
          <Header size="small">Notification</Header>
          <Divider />
        </Form.Field>
        <Form.Input
          required
          fluid
          label="Subject"
          placeholder="Subject"
          value={data.Summary}
          onChange={handleOnChangeSubject}
        />
        <Form.Dropdown
          required
          fluid
          label="Receipient Role"
          placeholder="Receipient"
          selection
          value={data.ReceipientRole}
          onChange={handleOnChangeReceipientRole}
          options={RoleOptions}
        />
        <Form.Field required>
          <label>Content</label>
          <CKEditor config={CKEditorConfig} initData={data.Details} onChange={handleOnChangeContent} />
        </Form.Field>
      </Form>
    </div>
  );

  const renderViewForm = () => (
    <div className="ant-modal-addOrEdit">
      <Form size={'small'}>
        <Form.Field>
          <Header size="small">Notification</Header>
          <Divider />
        </Form.Field>
        <Form.Field>
          <label>Subject</label>
          <div>{data.Summary}</div>
        </Form.Field>
        <Form.Field>
          <label>Receipient</label>
          <div>{getItemTextByKey(RoleOptions, data.ReceipientRole)}</div>
        </Form.Field>
        <Form.Field>
          <label>Content</label>
          <div dangerouslySetInnerHTML={{ __html: data.Details }} />
        </Form.Field>
      </Form>
    </div>
  );

  return (
    <Modal
      visible={true}
      width={600}
      onCancel={handleOnClose}
      footer={
        <React.Fragment>
          {api.isAdmin() && type !== 'view' && (
            <Button color="blue" onClick={handleOnSubmit}>
              Send
            </Button>
          )}
          <Button className="negative" onClick={handleOnClose}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      {type === 'view' ? renderViewForm() : renderAddForm()}
    </Modal>
  );
}

export default ModalAddOrView;
