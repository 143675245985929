import React from 'react';
import {
  Form,
  DatePicker,
  Select,
  Divider,
  Input,
  Radio,
  InputNumber,
  Menu,
  Icon,
  Dropdown,
  Checkbox,
  Collapse,
} from 'antd';
import { toJS, action } from 'mobx';
import { observer } from 'mobx-react';
import { Popup, Icon as IconSemantic } from 'semantic-ui-react';

import FieldUpload from './FieldUpload';
import store from './store';

import * as api from '@stores/api';
import { Header, HeaderWithoutCheck } from '../../shared/Collapse/Panel';
import { renderDate } from '../Shared';

@observer
class ClaimDetailsForm extends React.Component {
  renderPopup = (popupMessage = '') => (
    <Popup trigger={<IconSemantic name="info circle" />} content={popupMessage} position="top center" wide="very" />
  );
  @action handleChangeModeMultipleObj = (_, event, fieldName, lengthLimit) => {
    const data = event.map(i => i.props.data);
    const data_ = data.map(i => (store.checkIsJSON(i) ? JSON.parse(i) : null)).filter(i => i.Id);
    if (lengthLimit) {
      if (data_.length <= lengthLimit) {
        if (fieldName === 'SubBookingCMId') {
          return store.handleChangeCRValue({ SubBookingCM: data_ });
        }
        return (store[fieldName] = data_);
      } else return;
    }
    return (store[fieldName] = data_);
  };
  render() {
    const {
      clinicalNoteInfo,
      bookingClients,
      bookingClientSelected,
      bookingCMSelected,
      bookingCMs,
      deliveryContactFirst,
      deliveryContactFirstSelected,
      deliveryContactSecondSelected,
      deliveryContactsSecond,
      subBookingCMs,
      payingCMs,
      payingCMSelected,
      payingClients,
      payingClientSelected,
      claimantSelected,
      claimants,
    } = store;
    const {
      handleChangeDatePicker,
      itemToArray,
      handleSearch,
      handleScroll,
      handleChangeFieldSelect,
      handleChangeFieldInput,
      handleChangeFieldInputNumber,
      handleChangeFieldCheckbox,
      arrayToArray,
      handleChangeFieldRadio,
      handleChangeRangePicker,
      handleOpenAddEdit,
    } = store;
    const itemModel = toJS(clinicalNoteInfo);
    return (
      <Form name="action-required" className="grid-style">
        {api.isAdminOrMagStaffOrAccounting() && !!itemModel.Id && (
          <Form.Item
            label={
              <React.Fragment>
                PIC 1
                <button className="field-actions" onClick={store.openModalHistory}>
                  Recent Changes
                </button>
              </React.Fragment>
            }
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="children"
              value={itemModel.AssignedStaffId || undefined}
              onChange={handleChangeFieldSelect('AssignedStaffId')}
            >
              {store.staffs.map(({ Id, FullName }) => (
                <Select.Option key={Id} value={Id}>
                  {FullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <>
              Request Date{' '}
              {api.isAdminOrMagStaffOrAccounting() &&
                this.renderPopup('CR request confirmation will be sent to staff (MAG-79)')}
            </>
          }
        >
          <DatePicker
            size="large"
            placeholder="Select Request Date"
            value={renderDate(itemModel.CreatedDate)}
            disabled={!api.isAdminOrMagStaffOrAccounting()}
            onChange={handleChangeDatePicker('CreatedDate')}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Divider className="fluid-field" />
        <Collapse bordered={false}>
          <Collapse.Panel
            key="1"
            header={
              <HeaderWithoutCheck
                title="Booking Client and CM"
                statuses={[
                  {
                    key: 'cr-bc',
                    text: bookingClientSelected ? `Client: ${bookingClientSelected.Name}` : '',
                  },
                  {
                    key: 'cr-bcm',
                    text: bookingCMSelected ? `Booking CM: ${bookingCMSelected.FullName}` : '',
                  },
                ]}
              />
            }
          >
            <Form.Item
              required
              validateStatus={!itemModel.CustomerId ? 'error' : null}
              help={!itemModel.CustomerId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking Client
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'CustomerId')}>Add Client</Menu.Item>
                          {!!bookingClientSelected && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'CustomerId')}>Edit Client</Menu.Item>
                          )}
                          <Menu.Item onClick={store.openModalCompanyHistory}>Recent Changes</Menu.Item>
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.CustomerId || undefined}
                onChange={handleChangeFieldSelect('CustomerId')}
                disabled={!api.isAdminOrMagStaffOrAccounting()} // || itemModel.CaseStatus > api.crStatusEnum.CN_Files_Received
                allowClear
                onSearch={handleSearch('bookingClientsSearchOpt')}
                onPopupScroll={handleScroll('bookingClientsSearchOpt')}
                showSearch
                filterOption="children"
                placeholder="Select a client"
              >
                {itemToArray(bookingClientSelected, bookingClients, 'Id').map(({ Id, Name, StateName }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {Name} - {StateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              validateStatus={!itemModel.CaseManagerId ? 'error' : null}
              help={!itemModel.CaseManagerId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Booking CM
                  {api.isAdminOrMagStaffOrAccounting() && (
                    <Dropdown
                      disabled={!itemModel.CustomerId}
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'CaseManagerId')}>Add CM</Menu.Item>
                          {!!bookingCMSelected && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'CustomerId')}>Edit CM</Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.CaseManagerId || undefined}
                onChange={handleChangeFieldSelect('CaseManagerId')}
                disabled={!api.isAdminOrMagStaffOrAccounting()} // || itemModel.CaseStatus > api.crStatusEnum.CN_Files_Received
                onSearch={handleSearch('bookingCMsSearchOpt')}
                onPopupScroll={handleScroll('bookingCMsSearchOpt')}
                showSearch
                allowClear
                filterOption="children"
                placeholder="Select a case manager"
              >
                {itemToArray(bookingCMSelected, bookingCMs, 'Id').map(({ Id, FullName, Email }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {FullName} - {Email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {api.isAdminOrMagStaffOrAccounting() && (
              <>
                <Form.Item label="Sub-Booking Case Manager / Solicitor">
                  <Select
                    size="large"
                    value={itemModel.SubBookingCM.map(i => i.Id)}
                    mode="multiple"
                    showArrow
                    allowClear
                    onChange={(value, event) => this.handleChangeModeMultipleObj(value, event, 'SubBookingCMId', 3)}
                    disabled={itemModel.IsLocked}
                    onSearch={handleSearch('searchOptSubBookingCMs')}
                    onPopupScroll={handleScroll('searchOptSubBookingCMs')}
                    showSearch
                    placeholder="Select a case manager"
                    filterOption="children"
                    autoClearSearchValue
                  >
                    {arrayToArray(
                      [itemModel.SubCaseManager1, itemModel.SubCaseManager3, itemModel.SubCaseManager2].filter(i => i),
                      subBookingCMs,
                      'Id',
                    ).map(i => (
                      <Select.Option value={i.Id} key={i.Id} data={JSON.stringify(i)}>
                        {i.Id} - {i.FullName} - {i.Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div />
                <Form.Item
                  label={
                    <React.Fragment>
                      Paying Client
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Dropdown
                          disabled={api.isCaseManager()}
                          overlay={
                            <Menu>
                              <Menu.Item onClick={() => handleOpenAddEdit('add', 'PCustomerId')}>
                                Add Paying Client
                              </Menu.Item>
                              {!!payingClientSelected && (
                                <Menu.Item onClick={() => handleOpenAddEdit('edit', 'PCustomerId')}>
                                  Edit Paying Client
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <button className="field-actions">
                            Add/Edit <Icon type="down" />
                          </button>
                        </Dropdown>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    value={itemModel.PCustomerId || undefined}
                    onChange={handleChangeFieldSelect('PCustomerId')}
                    disabled={itemModel.IsLocked || api.isCaseManager()}
                    onSearch={handleSearch('payingClientsSearchOpt')}
                    onPopupScroll={handleScroll('payingClientsSearchOpt')}
                    showSearch
                    filterOption="children"
                    allowClear
                    placeholder="Select a client"
                  >
                    {itemToArray(payingClientSelected, payingClients, 'Id').map(({ Id, Name, StateName }) => (
                      <Select.Option value={Id} key={Id}>
                        {Id} - {Name} - {StateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <React.Fragment>
                      Paying CM
                      {api.isAdminOrMagStaffOrAccounting() && (
                        <Dropdown
                          disabled={!itemModel.PCustomerId || api.isCaseManager()}
                          overlay={
                            <Menu>
                              <Menu.Item onClick={() => handleOpenAddEdit('add', 'PCaseManagerId')}>
                                Add Paying CM
                              </Menu.Item>
                              {!!payingCMSelected && (
                                <Menu.Item onClick={() => handleOpenAddEdit('add', 'PCaseManagerId')}>
                                  Edit Paying CM
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <button className="field-actions">
                            Add/Edit <Icon type="down" />
                          </button>
                        </Dropdown>
                      )}
                    </React.Fragment>
                  }
                >
                  <Select
                    size="large"
                    value={itemModel.PCaseManagerId || undefined}
                    onChange={handleChangeFieldSelect('PCaseManagerId')}
                    disabled={itemModel.IsLocked || api.isCaseManager()}
                    onSearch={handleSearch('payingCMsSearchOpt')}
                    onPopupScroll={handleScroll('payingCMsSearchOpt')}
                    showSearch
                    allowClear
                    placeholder="Select a case manager"
                    filterOption="children"
                  >
                    {itemToArray(payingCMSelected, payingCMs, 'Id').map(({ Id, FullName, Email }) => (
                      <Select.Option value={Id} key={Id}>
                        {Id} - {FullName} - {Email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {itemModel.OriginalMAGInfo === '6' ? (
              <Form.Item label="">
                <Input.TextArea
                  rows={3}
                  disabled={itemModel.IsLocked}
                  value={itemModel.OriginalMAGInfoTxt}
                  onChange={handleChangeFieldInput('OriginalMAGInfoTxt')}
                />
              </Form.Item>
            ) : (
              <div />
            )}
            {itemModel.OriginalMAGInfo2 === '4' ? (
              <Form.Item label="">
                <Input.TextArea
                  rows={3}
                  disabled={itemModel.IsLocked}
                  value={itemModel.OriginalMAGInfoTxt2}
                  onChange={handleChangeFieldInput('OriginalMAGInfoTxt2')}
                />
              </Form.Item>
            ) : (
              <div />
            )}
            <Form.Item label="Purchase Order No">
              <Input
                size="large"
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                value={itemModel.PurchaseOrderNo}
                onChange={handleChangeFieldInput('PurchaseOrderNo')}
              />
            </Form.Item>
            <Form.Item label="Authorised Purchase Amount">
              <InputNumber
                size="large"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager())}
                value={itemModel.AuthorisedPurchaseAmount}
                onChange={handleChangeFieldInputNumber('AuthorisedPurchaseAmount')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className="posstion-IsTypist-label"
              label={
                <span>
                  <span style={{ marginRight: '10px' }}>Client Receiving Contact 1:</span>
                  <Checkbox
                    checked={itemModel.F_RDCSameBookingCM}
                    className="checkbox-IsTypist"
                    onChange={handleChangeFieldCheckbox('F_RDCSameBookingCM')}
                    disabled={!api.isAdminOrMagStaffOrAccounting() && !bookingCMSelected}
                  >
                    Send to Booking CM
                  </Checkbox>
                </span>
              }
            >
              <Select
                size="large"
                value={itemModel.F_RDCCaseManagerId || undefined}
                onChange={handleChangeFieldSelect('F_RDCCaseManagerId')}
                disabled={itemModel.IsLocked || itemModel.F_RDCSameBookingCM}
                onSearch={handleSearch('deliveryContactsFirstSearchOpt')}
                onPopupScroll={handleScroll('deliveryContactsFirstSearchOpt')}
                showSearch
                allowClear
                placeholder="Select a case manager"
                filterOption="children"
              >
                {itemToArray(deliveryContactFirstSelected, deliveryContactFirst, 'Id').map(
                  ({ Id, FullName, Email }) => (
                    <Select.Option value={Id} key={Id}>
                      {Id} - {FullName} - {Email}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Client Receiving Contact 2">
              <Select
                size="large"
                value={itemModel.S_RDCCaseManagerId || undefined}
                onChange={handleChangeFieldSelect('S_RDCCaseManagerId')}
                onSearch={handleSearch('deliveryContactsSecondSearchOpt')}
                onPopupScroll={handleScroll('deliveryContactsSecondSearchOpt')}
                showSearch
                allowClear
                placeholder="Select a case manager"
                filterOption="children"
              >
                {itemToArray(deliveryContactSecondSelected, deliveryContactsSecond, 'Id').map(
                  ({ Id, FullName, Email }) => (
                    <Select.Option value={Id} key={Id}>
                      {Id} - {FullName} - {Email}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <HeaderWithoutCheck
                title="Claimant Info"
                text={
                  claimantSelected
                    ? `${claimantSelected.FullName} - DoB: ${renderDate(claimantSelected.DOB || '', false, false)}`
                    : ''
                }
              />
            }
          >
            <Form.Item
              required
              validateStatus={!itemModel.PatientId ? 'error' : null}
              help={!itemModel.PatientId ? 'This field is required' : ''}
              label={
                <React.Fragment>
                  Claimant
                  {(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenAddEdit('add', 'PatientId')}>Add Claimant</Menu.Item>
                          {!!claimantSelected && (
                            <Menu.Item onClick={() => handleOpenAddEdit('edit', 'PatientId')}>Edit Claimant</Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <button className="field-actions">
                        Add/Edit <Icon type="down" />
                      </button>
                    </Dropdown>
                  )}
                </React.Fragment>
              }
            >
              <Select
                size="large"
                value={itemModel.PatientId || undefined}
                onChange={handleChangeFieldSelect('PatientId')}
                disabled={itemModel.IsLocked}
                onSearch={handleSearch('claimantsSearchOpt')}
                onPopupScroll={handleScroll('claimantsSearchOpt')}
                showSearch
                allowClear
                placeholder="Select a claimant"
                filterOption="children"
              >
                {itemToArray(claimantSelected, claimants, 'Id').map(({ Id, FullName, Title }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {Title} {FullName} - {renderDate(claimantSelected?.DOB || '', false, false)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Birth">
              <Input size="large" disabled value={renderDate(claimantSelected?.DOB || '', false, false)} />
            </Form.Item>
            <Form.Item label="Mobile phone">
              <Input size="large" disabled value={claimantSelected?.Telephone} />
            </Form.Item>
            <Form.Item label="Email">
              <Input size="large" disabled value={claimantSelected?.Email} />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <HeaderWithoutCheck
                title="Claim No - Claim Type"
                text={itemModel.ClaimantNo?.length > 0 ? `Claim No: ${itemModel.ClaimantNo}` : ''}
              />
            }
          >
            <Form.Item
              label="Reference/Claim No"
              required
              validateStatus={!itemModel.ClaimantNo ? 'error' : null}
              help={!itemModel.ClaimantNo ? 'This field is required' : ''}
            >
              <Input
                size="large"
                value={itemModel.ClaimantNo}
                disabled={itemModel.IsLocked}
                onChange={handleChangeFieldInput('ClaimantNo')}
              />
            </Form.Item>
            <Form.Item label="Client File No">
              <Input
                size="large"
                value={itemModel.ClientFileNo}
                disabled={itemModel.IsLocked}
                onChange={handleChangeFieldInput('ClientFileNo')}
              />
            </Form.Item>
            <Form.Item
              required
              validateStatus={!itemModel.ReportId ? 'error' : null}
              help={!itemModel.ReportId ? 'This field is required' : ''}
              label="Type of Claim"
            >
              <Select
                size="large"
                value={itemModel.ReportId || undefined}
                onChange={handleChangeFieldSelect('ReportId')}
                disabled={itemModel.IsLocked}
                showSearch
                allowClear
                filterOption="children"
                placeholder="Select a type of claim"
              >
                {store.claims && itemModel.ReportId && !store.claims.find(i => i.Id === itemModel.ReportId) && (
                  <Select.Option key={itemModel.ReportId} value={itemModel.ReportId}>
                    {itemModel.ClaimType}
                  </Select.Option>
                )}
                {store.claims.map(({ Id, Name }) => (
                  <Select.Option value={Id} key={Id}>
                    {Id} - {Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Date of Injury">
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={renderDate(itemModel.InjuriedDate)}
                disabled={itemModel.IsLocked}
                onChange={handleChangeDatePicker('InjuriedDate')}
              />
            </Form.Item>
            <Form.Item label="Injury Description" className="fluid-field">
              <Input.TextArea
                rows={3}
                disabled={itemModel.IsLocked}
                value={itemModel.InjuryDescription}
                onChange={handleChangeFieldInput('InjuryDescription')}
              />
            </Form.Item>
            <Form.Item label="Requested CR">
              <Radio.Group
                value={itemModel.RequestedCRRange}
                disabled={itemModel.IsLocked}
                onChange={handleChangeFieldRadio('RequestedCRRange')}
              >
                <Radio value={0}>Full History</Radio>
                <Radio value={1}>Selective History</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Requested CR Range"
              required={itemModel.RequestedCRRange === 1}
              validateStatus={itemModel.RequestedCRRange === 1 && !itemModel.RequestedCRRangeFrom ? 'error' : null}
              help={itemModel.RequestedCRRange === 1 && !itemModel.RequestedCRRangeFrom ? 'This field is required' : ''}
            >
              <DatePicker.RangePicker
                size="large"
                disabled={itemModel.IsLocked}
                value={[renderDate(itemModel.RequestedCRRangeFrom), renderDate(itemModel.RequestedCRRangeTo)]}
                onChange={handleChangeRangePicker('RequestedCRRangeFrom', 'RequestedCRRangeTo')}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item label="Employer Of Claimant">
              <Input
                size="large"
                disabled={itemModel.IsLocked}
                value={itemModel.EmployerOfClaimant}
                onChange={handleChangeFieldInput('EmployerOfClaimant')}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            header={<Header title="IRO/ILARS" isChecked={itemModel.WiroIlars} text="IRO Funded" isRequired={false} />}
          >
            <div className="fluid-field checkbox-field" style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={itemModel.WiroIlars}
                onChange={handleChangeFieldCheckbox('WiroIlars')}
                className="checkbox-session"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.Status === 13 ||
                  itemModel.Status === 14
                }
              >
                Report Funded by IRO/ILARS
              </Checkbox>
            </div>{' '}
            <Form.Item>
              <Checkbox
                style={{ marginTop: '13px' }}
                checked={itemModel.IROFundingApproved}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WiroIlars}
                onChange={handleChangeFieldCheckbox('IROFundingApproved')}
              >
                IRO Funding Approved
                {this.renderPopup(
                  'Documents will not be sent to specialist until IRO funding approved (Mail-168)',
                )}{' '}
                {!!itemModel.IROFundingApprovedDate && itemModel.IROFundingApproved && (
                  <>
                    <span style={{ color: '#107fc9', marginLeft: '5px' }}>
                      {itemModel.IROFundingApprovedDate
                        ? renderDate(itemModel.IROFundingApprovedDate, false, true)
                        : ''}
                    </span>
                    <span style={{ color: '#107fc9' }}>
                      {itemModel.IROFundingApprovedBy ? ` - By ${itemModel.IROFundingApprovedBy}` : ''}
                    </span>
                  </>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item label={'IRO Funding Approval Date'} required={itemModel.IROFundingApproved}>
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                value={itemModel.IROFundingApprovalDate}
                onChange={handleChangeDatePicker('IROFundingApprovalDate')}
                disabled={!(api.isAdminOrMagStaffOrAccounting() || api.isCaseManager()) || !itemModel.WiroIlars}
              />
            </Form.Item>
            <FieldUpload
              label="Attach Funding Approval"
              multiple={true}
              type={8}
              disabled={
                api.isDoctor() ||
                api.isTypist() ||
                itemModel.CaseStatus === 12 ||
                itemModel.Status === 13 ||
                itemModel.Status === 14
              }
              disabledDelete={itemModel.IsLocked}
            />
            <Form.Item label="ILARS Ref">
              <Input
                size="large"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.Status === 13 ||
                  itemModel.Status === 14
                }
                value={itemModel.IlarsRef}
                onChange={handleChangeFieldInput('IlarsRef')}
              />
            </Form.Item>
            <Form.Item label="Billing Item Code">
              <Select
                size="large"
                value={itemModel.ImsApprovalCode || undefined}
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.Status === 13 ||
                  itemModel.Status === 14
                }
                onChange={handleChangeFieldSelect('ImsApprovalCode')}
                allowClear
              >
                {store.imsCodes.map(({ Id, PaymentClassificationCode, ServiceDescription }) => (
                  <Select.Option key={Id} value={PaymentClassificationCode}>
                    {PaymentClassificationCode} - {ServiceDescription}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="IRO Principal Lawyer">
              <Select
                size="large"
                disabled={
                  api.isDoctor() ||
                  api.isTypist() ||
                  itemModel.CaseStatus === 12 ||
                  itemModel.Status === 13 ||
                  itemModel.Status === 14
                }
                value={itemModel.IlarsWiroSolicitor || undefined}
                onChange={handleChangeFieldSelect('IlarsWiroSolicitor')}
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {itemModel.IlarsWiroSolicitorObj &&
                  !(store.wiroPrincipleLawyers || []).find(i => i.Id == itemModel.IlarsWiroSolicitorObj.Id) && (
                    <Select.Option
                      key={itemModel.IlarsWiroSolicitorObj.Id}
                      value={itemModel.IlarsWiroSolicitorObj.Id}
                      style={{ display: 'none' }}
                    >
                      {itemModel.IlarsWiroSolicitorObj.Id} - {itemModel.IlarsWiroSolicitorObj.FullName}
                    </Select.Option>
                  )}
                {store.wiroPrincipleLawyers.map(i => (
                  <Select.Option key={i.Id} value={i.Id}>
                    {i.Id} - {i.FullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        {api.isAdminOrMagStaffOrAccounting() && (
          <Form.Item className="fluid-field" label="Booking Comment">
            <Input.TextArea
              rows={3}
              onChange={handleChangeFieldInput('ClaimReqBookingComment')}
              value={itemModel.ClaimReqBookingComment}
              disabled={api.isTypist()}
            />
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default ClaimDetailsForm;
