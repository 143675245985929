import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import fileReviewStore from './fileReviewStore';
import ModalActionNotes from './ModalActionNotes';
import ModalCaseProgress from './ModalCaseProgress';
import ModalViewSuppRequest from './ModalViewSuppRequest';
import ModalConfirmNewUI from './ModalConfirmNewUI';

function FileReviewModal() {
  const { modalType } = fileReviewStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...fileReviewStore} onCancel={fileReviewStore.toggleModal(false)} />;
  } else if (modalType === 'viewSuppRequest') {
    return <ModalViewSuppRequest {...fileReviewStore} onCancel={fileReviewStore.toggleModal(false)} />;
  } else if (modalType === 'notes') {
    return <ModalActionNotes />;
  } else if (modalType === 'newUI') {
    return <ModalConfirmNewUI onCancel={fileReviewStore.toggleModal(false)} />;
  } else {
    return <ModalCaseProgress />;
  }
}

export default FileReviewModal;
