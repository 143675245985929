import React from 'react';
import { Form, Input, InputNumber, Select, Divider } from 'antd';
import { Message } from 'semantic-ui-react';

class MedNegProcessing extends React.Component {
  render() {
    return (
      <Form name="med-neg-processing">
        <h3 className="form-subtitle fluid-field">Case Documents</h3>
        <Form.Item
          label={
            <React.Fragment>
              PIC 2<button className="field-actions">Add/Edit</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">PO Documents</h3>
        <Form.Item label="Attached Precis (max 2 pages)" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Other Documents" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>

        <Divider className="fluid-field" />
        <h3 className="form-subtitle fluid-field">EO/Full Report Documents</h3>
        <Form.Item label="Attached Letter of Instruction (LOI)" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Or Share Download Link" className="fluid-field">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Other Documents" className="fluid-field">
          <Message className="upload-box">
            <div className="upload-file">
              <input type="file" /> Click here to upload
            </div>
          </Message>
        </Form.Item>
        <Form.Item label="Or Share Download Links" className="fluid-field">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item label="Full File Volume (pages)">
          <InputNumber size="large" />
        </Form.Item>
        <Form.Item label="Comments" className="fluid-field">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider className="fluid-field" />
        <Form.Item
          label={
            <React.Fragment>
              Requested Specialty
              <button className="field-actions">Insert Specialty</button>
            </React.Fragment>
          }
        >
          <Select size="large" value="1">
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default MedNegProcessing;
