import React from 'react';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { DatePicker, Spin } from 'antd';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';

import Modal from '../../shared/Modal/';
import CMReportStore from './CMReportStore';
import customFetch from '../../../utils/customFetch';
import ModalVerifyAccessCode from './ModalVerifyAccessCode';

const datePickerFormat = 'DD/MM/YYYY';

class ModalExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpenAccessCodeModal: false,
      states: [],
      arrClient: [],
      clientsuburb: [],
      clientpostcode: [],
      staffsuburb: [],
      staffpostcode: [],
      clientTypes1: '',
      clientTypes2: '',
      clientTypes3: '',
      clientTypes4: '',
      clientTypes5: '',
      clientTypes6: '',
      clientCityId: [],
      clientSuburb: '',
      clientPostcode: '',
      clientIds: [],
      clientIncludePromotionsUnsubscribedClients: false,
      MAGDirect: false,
      caseManagerCityId: '',
      caseManagerCreatedDate: null,
      caseManagerPostcode: '',
      caseManagerState: [],
      caseManagerSuburb: '',
      caseManagerUnsubscribed: false,
      caseManagerUnverified: false,
      IsNonNookingContact: false,
      IsExcluding: false,
      IsOnboarding: true,
      IsRecruiting: true,
      IsOnboarded: true,
      Active: true,
      InActive: false,
    };
  }

  onCancel = () => {
    this.props.onCancel();
  };

  renderStateOptionsClient = () => {
    return toJS(this.state.states).map(opt => ({
      text: opt.name,
      value: opt.id,
    }));
  };

  renderStateOptionsCMs = () => {
    return toJS(this.state.states).map(opt => ({
      text: opt.name,
      value: opt.id,
    }));
  };

  renderClientOptions = () => {
    return toJS(this.state.arrClient || []).map(opt => ({
      text: opt.Name,
      value: opt.Id,
    }));
  };

  renderClientSuburbOptions = () => {
    return toJS(this.state.clientsuburb).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderClientPostcodeOptions = () => {
    return toJS(this.state.clientpostcode).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderStaffSuburbOptions = () => {
    return toJS(this.state.staffsuburb).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  renderStaffPostcodeOptions = () => {
    return toJS(this.state.staffpostcode).map(opt => ({
      text: opt,
      value: opt,
    }));
  };

  handleChangeType = value => {
    this.setState({
      clientTypes: value,
    });
  };

  handleChangeCityId = (e, data) => {
    console.log(data.value);
    this.setState({
      clientCityId: data.value,
    });
  };

  handleClientSuburb = (e, data) => {
    this.setState({
      clientSuburb: data.value,
    });
  };

  handleClientPostcode = (e, data) => {
    this.setState({
      clientPostcode: data.value,
    });
  };

  handleCMsSuburb = (e, data) => {
    this.setState({
      caseManagerSuburb: data.value,
    });
  };

  handleCMsPostcode = (e, data) => {
    this.setState({
      caseManagerPostcode: data.value,
    });
  };

  onChangeCheckBoxFalse = name => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  onChangeCheckBox = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onChangeCMStatus = name => {
    if (name === 'IsExcluding') {
      this.setState({
        ...this.state,
        IsExcluding: !this.state.IsExcluding,
      });
    } else if (name === 'IsOnboarding') {
      this.setState({
        ...this.state,
        IsOnboarding: !this.state.IsOnboarding,
      });
    } else if (name === 'IsRecruiting') {
      this.setState({
        ...this.state,
        IsRecruiting: !this.state.IsRecruiting,
      });
    } else if (name === 'IsOnboarded') {
      this.setState({
        ...this.state,
        IsOnboarded: !this.state.IsOnboarded,
      });
    } else if (name === 'InActive') {
      this.setState({
        ...this.state,
        InActive: !this.state.InActive,
      });
    } else if (name === 'Active') {
      this.setState({
        ...this.state,
        Active: !this.state.Active,
      });
    } else if (name === 'IsNonNookingContact') {
      this.setState({
        ...this.state,
        IsNonNookingContact: !this.state.IsNonNookingContact,
      });
    }
  };

  handlePickerChange = momentValue => {
    this.setState({
      ...this.state,
      caseManagerCreatedDate: momentValue ? momentValue.format('DDMMMMYYYY') : null,
    });
  };

  handleCMsState = (e, data) => {
    console.log(data.value);
    this.setState({
      caseManagerState: data.value,
    });
  };

  @action handleChangeClient = (_, { value }) => {
    this.setState({
      clientIds: value,
    });
  };

  handleExport = () => {
    const clientTypes = [];
    if (this.state.clientTypes1) {
      clientTypes.push(this.state.clientTypes1);
    }
    if (this.state.clientTypes2) {
      clientTypes.push(this.state.clientTypes2);
    }
    if (this.state.clientTypes3) {
      clientTypes.push(this.state.clientTypes3);
    }
    if (this.state.clientTypes4) {
      clientTypes.push(this.state.clientTypes4);
    }
    if (this.state.clientTypes5) {
      clientTypes.push(this.state.clientTypes5);
    }
    if (this.state.clientTypes6) {
      clientTypes.push(this.state.clientTypes6);
    }
    const searchParams = Object.assign(
      {},
      {
        clientCityId: (this.state.clientCityId && this.state.clientCityId.length > 0) ? JSON.stringify(this.state.clientCityId) : '',
        clientTypes: clientTypes,
        clientSuburb: this.state.clientSuburb,
        clientPostcode: this.state.clientPostcode,
        clientIncludePromotionsUnsubscribedClients: this.state.clientIncludePromotionsUnsubscribedClients,
        MAGDirect: this.state.MAGDirect,
        caseManagerCreatedDate: this.state.caseManagerCreatedDate,
        caseManagerPostcode: this.state.caseManagerPostcode,
        caseManagerState: (this.state.caseManagerState && this.state.caseManagerState.length > 0) ? JSON.stringify(this.state.caseManagerState) : '',
        caseManagerSuburb: this.state.caseManagerSuburb,
        caseManagerCityId: this.state.caseManagerCityId,
        caseManagerUnsubscribed: this.state.caseManagerUnsubscribed,
        isRecruiting: this.state.IsRecruiting,
        isOnboarding: this.state.IsOnboarding,
        isNonNookingContact: this.state.IsNonNookingContact,
        isExcluding: this.state.IsExcluding,
        isOnboarded: this.state.IsOnboarded,
        active: this.state.Active,
        inActive: this.state.InActive,
        caseManagerUnverified: this.state.caseManagerUnverified,
        clientIds: this.state.clientIds,
      },
    );
    var qs = Object.entries(searchParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    var qs1 = clientTypes.map(val => `clientTypes=${val}`).join('&');

    var queryParams = qs + '&' + qs1 + '&forDownloading=true&isCMExport=true';
    CMReportStore.setFieldsValue({ exportUrl: '/Report/ExportClientAndCm', exportQueryParams: queryParams });

    this.setState({ ...this.state, isOpenAccessCodeModal: true });
    //window.open('/Report/ExportClientAndCm?' + qs + '&' + qs1 + '&forDownloading=true', '_blank');
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const statesPromise = customFetch('/api/city', {
        method: 'GET',
      });

      const clientsuburbPromise = customFetch('/api/clientsuburb', {
        method: 'GET',
      });

      const clientpostcodePromise = customFetch('/api/clientpostcode', {
        method: 'GET',
      });

      const staffsuburbPromise = customFetch('/api/staffsuburb', {
        method: 'GET',
      });

      const staffpostcodePromise = customFetch('/api/staffpostcode', {
        method: 'GET',
      });

      const clientPromise = customFetch('/Company/GetAll', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          ActionRequired: false,
          NumItemPerPage: 3000,
          Showing: this.showing,
          curPage: 1,
          IsSelectionList: true,
        }),
      });

      const [states, clientsuburb, clientpostcode, staffsuburb, staffpostcode, clients] = await Promise.all([
        statesPromise,
        clientsuburbPromise,
        clientpostcodePromise,
        staffsuburbPromise,
        staffpostcodePromise,
        clientPromise,
      ]);
      this.setState({
        states: states,
        clientsuburb: clientsuburb,
        clientpostcode: clientpostcode,
        staffsuburb: staffsuburb,
        staffpostcode: staffpostcode,
        arrClient: clients && clients.itemList ? clients.itemList : [],
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const { toggleModal, open } = CMReportStore;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={1200}
          onCancel={toggleModal(false)}
          title={`Export Clients and Case Managers`}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleExport} disabled={loading}>
                Export
              </Button>
              <Button className="negative" onClick={this.onCancel}>
                Close
              </Button>
            </React.Fragment>
          }
        >
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="Wrap-export-modal">
              <h2 className="Title-export">Fliter Clients by:</h2>
              <div className="Suburb-clients">
                <div className="Title">Clients</div>
                <Dropdown
                  clearable
                  placeholder="Select clients"
                  multiple
                  fluid
                  search
                  selection
                  value={this.state.clientIds}
                  onChange={this.handleChangeClient}
                  options={this.renderClientOptions()}
                  className="Dropdown-client"
                />
              </div>
              <div className="Type-clients">
                <div className="Title" style={{ width: '80px' }}>
                  Type
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    label="Insurer"
                    onChange={
                      this.state.clientTypes1
                        ? () => this.onChangeCheckBoxFalse('clientTypes1')
                        : () => this.onChangeCheckBox('clientTypes1', '1')
                    }
                  />
                  <Checkbox
                    label="Self Insurer"
                    onChange={
                      this.state.clientTypes2
                        ? () => this.onChangeCheckBoxFalse('clientTypes2')
                        : () => this.onChangeCheckBox('clientTypes2', '6')
                    }
                  />
                  <Checkbox
                    label="Law Defendant"
                    onChange={
                      this.state.clientTypes3
                        ? () => this.onChangeCheckBoxFalse('clientTypes3')
                        : () => this.onChangeCheckBox('clientTypes3', '2')
                    }
                  />
                  <Checkbox
                    label="Law Plaintiff"
                    onChange={
                      this.state.clientTypes4
                        ? () => this.onChangeCheckBoxFalse('clientTypes4')
                        : () => this.onChangeCheckBox('clientTypes4', '3')
                    }
                  />
                  <Checkbox
                    label="Law Practice"
                    onChange={
                      this.state.clientTypes5
                        ? () => this.onChangeCheckBoxFalse('clientTypes5')
                        : () => this.onChangeCheckBox('clientTypes5', '4')
                    }
                  />
                  <Checkbox
                    label="Other"
                    onChange={
                      this.state.clientTypes6
                        ? () => this.onChangeCheckBoxFalse('clientTypes6')
                        : () => this.onChangeCheckBox('clientTypes5', '5')
                    }
                  />
                </div>
              </div>

              <div className="Direct-clients">
                <div className="Title" style={{ width: '80px' }}>
                  MAG Direct
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.MAGDirect
                        ? () => this.onChangeCheckBoxFalse('MAGDirect')
                        : () => this.onChangeCheckBox('MAGDirect', true)
                    }
                  />
                </div>
              </div>

              <div className="Suburb-clients">
                <div className="Title">Suburb</div>
                <Dropdown
                  clearable
                  placeholder="Suburb"
                  fluid
                  search
                  selection
                  value={this.state.clientSuburb}
                  className="Dropdown-suburb"
                  onChange={this.handleClientSuburb}
                  options={this.renderClientSuburbOptions()}
                />
              </div>

              <div className="Postcode-clients">
                <div className="Title">Postcode</div>
                <Dropdown
                  clearable
                  placeholder="Postcode"
                  fluid
                  search
                  selection
                  value={this.state.clientPostcode}
                  onChange={this.handleClientPostcode}
                  options={this.renderClientPostcodeOptions()}
                  className="Dropdown-postcode"
                />
              </div>

              <div className="State-clients">
                <div className="Title">State</div>
                <Dropdown
                  clearable
                  placeholder="State"
                  fluid
                  multiple
                  search
                  selection
                  onChange={this.handleChangeCityId}
                  className="Dropdown-state"
                  options={this.renderStateOptionsClient()}
                />
              </div>

              <div className="Unsub-clients">
                <div className="Title">Include Unsubscribed Clients</div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.clientIncludePromotionsUnsubscribedClients
                        ? () => this.onChangeCheckBoxFalse('clientIncludePromotionsUnsubscribedClients')
                        : () => this.onChangeCheckBox('clientIncludePromotionsUnsubscribedClients', true)
                    }
                  />
                </div>
              </div>

              <h2 className="Title-export">Filter Case Managers by:</h2>
              <div className="Data-cms">
                <div className="Title">Created Date From</div>
                <DatePicker
                  className="Date-picker-cms"
                  allowClear={true}
                  format={datePickerFormat}
                  onChange={this.handlePickerChange}
                />
              </div>

              <div className="Suburb-clients">
                <div className="Title">Suburb</div>
                <Dropdown
                  clearable
                  placeholder="Suburb"
                  fluid
                  search
                  selection
                  value={this.state.caseManagerSuburb}
                  onChange={this.handleCMsSuburb}
                  options={this.renderStaffSuburbOptions()}
                  className="Dropdown-suburb"
                />
              </div>

              <div className="Postcode-clients">
                <div className="Title">Postcode</div>
                <Dropdown
                  clearable
                  placeholder="Postcode"
                  fluid
                  search
                  selection
                  value={this.state.caseManagerPostcode}
                  onChange={this.handleCMsPostcode}
                  options={this.renderStaffPostcodeOptions()}
                  className="Dropdown-postcode"
                />
              </div>

              <div className="State-clients">
                <div className="Title">State</div>
                <Dropdown
                  clearable
                  placeholder="State"
                  fluid
                  search
                  selection
                  multiple
                  value={this.state.caseManagerState}
                  onChange={this.handleCMsState}
                  options={this.renderStateOptionsCMs()}
                  className="Dropdown-state"
                  disabled={this.state.clientCityId.length > 0}
                />
              </div>

              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Unsubscribed CMs
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.caseManagerUnsubscribed
                        ? () => this.onChangeCheckBoxFalse('caseManagerUnsubscribed')
                        : () => this.onChangeCheckBox('caseManagerUnsubscribed', true)
                    }
                  />
                </div>
                <div className="Title" style={{ width: '180px', marginLeft: '140px' }}>
                  Include Unverified CMs
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    onChange={
                      this.state.caseManagerUnverified
                        ? () => this.onChangeCheckBoxFalse('caseManagerUnverified')
                        : () => this.onChangeCheckBox('caseManagerUnverified', true)
                    }
                  />
                </div>
              </div>

              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Recruitment Status
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    style={{ width: '95px' }}
                    label="Recruiting"
                    checked={this.state.IsRecruiting}
                    onChange={() => this.onChangeCMStatus('IsRecruiting')}
                  />
                  <Checkbox
                    label="Onboarding"
                    checked={this.state.IsOnboarding}
                    onChange={() => this.onChangeCMStatus('IsOnboarding')}
                  />
                  <Checkbox
                    label="Onboarded"
                    checked={this.state.IsOnboarded}
                    onChange={() => this.onChangeCMStatus('IsOnboarded')}
                  />
                  <Checkbox
                    label="Excluded"
                    checked={this.state.IsExcluding}
                    onChange={() => this.onChangeCMStatus('IsExcluding')}
                  />
                  <Checkbox
                    label="Non-Booking Contact"
                    checked={this.state.IsNonNookingContact}
                    onChange={() => this.onChangeCMStatus('IsNonNookingContact')}
                  />
                </div>
              </div>
              <div className="Unsub-clients">
                <div className="Title" style={{ width: '180px' }}>
                  Include Account Status
                </div>
                <div className="Checkbox-clients ui checkbox">
                  <Checkbox
                    style={{ width: '95px' }}
                    label="Active"
                    checked={this.state.Active}
                    onChange={() => this.onChangeCMStatus('Active')}
                  />
                  <Checkbox
                    label="Inactive"
                    checked={this.state.InActive}
                    onChange={() => this.onChangeCMStatus('InActive')}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
        {this.state.isOpenAccessCodeModal && (
          <ModalVerifyAccessCode {...CMReportStore} onCancel={CMReportStore.toggleModal(false)} />
        )}
      </React.Fragment>
    );
  }
}

export default observer(ModalExport);
