import customFetch from '../../../utils/customFetch';

export const getListCampaignScheduleHistory = (CampaignId = null, CurPage = 1, NumItemOfPage = 20) => {
  return customFetch('/ManagerCampaign/GetScheduleHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      campaignId: CampaignId,
      curPage: CurPage,
      numItemOfPage: NumItemOfPage,
    }),
  });
};
