import React from 'react';
import { Button } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

export default class ModalLogic extends React.Component {
  onOpenConfirm = () => {
    this.props.onCancel();
  };
  //   convertStringToPixel = subject => {
  //     let canvas = document.createElement('canvas');
  //     let ctx = canvas.getContext('2d');
  //     ctx.font = '25px Arial';
  //     let width = Math.ceil(ctx.measureText(subject).width);
  //     if (width < 500) {
  //       return 500;
  //     } else if (width > 1500) {
  //       return 1500;
  //     }
  //     return width;
  //   };
  render() {
    const { open } = this.props;
    const { Logic, TemplateKey } = this.props.modalParams;
    return (
      <Modal
        visible={open}
        width={900}
        onCancel={this.onOpenConfirm}
        title={TemplateKey}
        footer={
          <React.Fragment>
            <Button className="negative" onClick={this.onOpenConfirm}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <span style={{ fontSize: '16px' }}>{Logic}</span>
        </div>
      </Modal>
    );
  }
}
