import { action, observable } from 'mobx';
import moment from 'moment';

import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';

class IMEStore {
  @observable loading = true;

  @observable data = null;
  @observable loadingClaimant = true;
  @observable loadingOverDue = true;
  @observable loadingCase = true;
  @observable loadingActionPIC = true;
  @observable loadingPrepayment = true;
  @observable loadingStaff = true;
  @observable dataStaff = null;
  @observable dataPrepayment = null;
  @observable dataOverdue = null;
  @observable dataCase = null;
  @observable dataActionPIC = null;
  @observable dataSuggestClaimant = null;
  @observable dataTypeOfReports = [];
  @observable dataTypeOfClaims = [];

  @observable keyword = '';
  @observable showBookingToday = false;
  @observable keywordClaimant = ``;
  @observable FilterOverdue = 'All';
  @observable FilterType = 'All';
  @observable FilterStaff = 'All';
  @observable IMEType = 'All';
  @observable PrepaymentType = 0;
  @observable InterpreterRequired = 'All';
  @observable searchClaimantOnly = false;
  @observable status = 0;
  @observable claimant = { Id: ``, FullName: `` };
  @observable FilterTypeOfReport = 0;
  @observable FilterTypeOfClaim = 0;

  @observable NextPicId = null;

  @observable sortKey = api.isCaseManager() ? 'InquiryDate' : 'StatusDate';
  @observable numberSearch = 30;
  @observable sortColumn = 'Name';
  @observable sortDirection = 'DESC';

  @observable curPage = 1;
  @observable totalPage = 0;
  @observable totalItems = 0;

  @observable ActionRequiredById = '';
  @observable ActionRequired = false;
  @observable fromToRequest = ``;
  @observable fromToAppointmentDate = '';
  @observable fromToStatus = `${moment()
    .startOf('month')
    .format('DD/MM/YYYY')} - ${moment()
    .endOf('month')
    .format('DD/MM/YYYY')}`;

  @observable open = false;
  @observable modalParams = {};

  //Modal Supp request
  @observable loadingSuppRequest = true;
  @observable dataSuppRequest = null;
  @observable totalSuppRequest = 0;

  @action fetchSuppRequest = caseId => {
    this.loadingSuppRequest = true;
    customFetch(`/MedicalService/GetSuppRequests?caseId=${caseId}&type=IME`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(
      action(data => {
        this.totalSuppRequest = data.itemList.length;
        this.dataSuppRequest = data;
        this.loadingSuppRequest = false;
      }),
    );
  };
  //

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleSort = ({ sortKey }, { column, direction }) => {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortKey = sortKey;
    this.fetchMain();
  };

  @action fetchMain = (setTotalPage = false) => {
    this.loading = true;
    const { fromDate, toDate } = this.getDateRange();
    const { fromStatusDate, toStatusDate } = this.getStatusDateRange();
    const { fromAppointmentDate, toAppointmentDate } = this.getAppointmentDateRange();

    let from = '';
    if (fromDate) {
      from = this.showBookingToday ? fromDate.format() : fromDate.toISOString();
    }

    if (api.isAdminOrMagStaffOrAccounting()) {
      return customFetch('/MedicalService/Assessment_LoadList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ActionRequired: this.ActionRequired,
          ActionRequiredById: this.ActionRequiredById,
          ActionType: 'NONE',
          Claimant: this.claimant,
          ClaimantId: this.claimant.Id,
          CurPage: this.curPage,
          FilterOverdue: this.FilterOverdue,
          FilterType: this.FilterType,
          From: from,
          FromAppointmentDate: fromAppointmentDate,
          IMEType: this.IMEType,
          FilterPrepayment: this.PrepaymentType,
          InterpreterRequired: this.InterpreterRequired,
          Keyword: this.keyword,
          NextPicId: this.FilterStaff === 'All' ? this.NextPicId : this.FilterStaff,
          SearchClaimantOnly: this.searchClaimantOnly,
          SortBy: this.sortKey,
          SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
          To: toDate,
          ToAppointmentDate: toAppointmentDate,
          NumItemPerPage: this.pageSize,
          UrgentReport: false,
          FilterTypeOfReport: this.FilterTypeOfReport,
          FilterTypeOfClaim: this.FilterTypeOfClaim,
          StatusFrom: fromStatusDate,
          StatusTo: toStatusDate,
        }),
      }).then(
        action(data => {
          this.data = data;
          if (data.curPage <= 1) {
            this.totalPage = data.sumPage;
            this.totalItems = data.sumItem;
          }
          this.loading = false;
          this.showBookingToday = false;
        }),
      );
    } else if (api.isCaseManager() || api.isTypist()) {
      const { CaseManagerId, ClientId, TypistId } = api.currentUser.data;
      return customFetch('/CMReports/GetCMReports', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          Keyword: this.keyword,
          CurPage: this.curPage,
          FilterType: this.FilterType,
          NumItemPerPage: this.pageSize,
          FilterOverdue: this.FilterOverdue,
          SortBy: this.sortKey,
          SortDirection: this.sortDirection.replace('ending', '').toUpperCase(),
          SearchClaimantOnly: false,
          CaseMngId: CaseManagerId ?? TypistId,
          ClientId: ClientId,
          From: fromDate,
          To: toDate,
          ToAppointmentDate: toAppointmentDate,
          FromAppointmentDate: fromAppointmentDate,
          StatusFrom: fromStatusDate,
          StatusTo: toStatusDate,
        }),
      }).then(
        action(data => {
          this.data = data;
          if (data.curPage <= 1) {
            this.totalPage = data.sumPage;
            this.totalItems = data.sumItem;
          }
          this.loading = false;
        }),
      );
    }
  };

  @action fetchPicStaff = () => {
    this.loadingStaff = true;
    customFetch('/MedicalService/GetNextPicStaffs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataStaff = data;
        this.loadingStaff = false;
      }),
    );
  };

  @action fetchOverdue = () => {
    this.loadingOverDue = true;
    customFetch('/MedicalService/GetDynamicOverDueAssessmentStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataOverdue = data;
        this.loadingOverDue = false;
      }),
    );
  };

  @action fetchCase = () => {
    this.loadingCase = true;
    customFetch('/MedicalService/GetDynamicAssessmentStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataCase = data;
        this.loadingCase = false;
      }),
    );
  };

  @action fetchActionPIC = () => {
    this.loadingActionPIC = true;
    customFetch('/MedicalService/GetStaffsAssignedActionRequired', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataActionPIC = data;
        this.loadingActionPIC = false;
      }),
    );
  };

  @action fetchPrepayment = () => {
    this.loadingPrepayment = true;
    customFetch('/MedicalService/GetDynamicAssessmentPrepayment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    }).then(
      action(data => {
        this.dataPrepayment = data;
        this.loadingPrepayment = false;
      }),
    );
  };

  @action fetchGetSuggestClaimant = () => {
    this.loadingClaimant = true;
    customFetch('/MedicalService/GetSuggestClaimant', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        keyword: this.keywordClaimant,
        Showing: 'active',
        numItemPerPage: this.numberSearch,
        IsSelectionList: true,
        curPage: 1,
      }),
    }).then(
      action(data => {
        this.dataSuggestClaimant = data;
        this.loadingClaimant = false;
      }),
    );
  };

  @action fetchTypeOfReport = () => {
    this.loadingClaimant = true;
    customFetch('/Default/GetActiveReportTypeList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataTypeOfReports = data.itemList;
        this.dataTypeOfReports.unshift({ Id: 0, Name: 'All' });
        this.loadingClaimant = false;
      }),
    );
  };

  @action fetchTypeOfClaim = () => {
    this.loadingClaimant = true;
    customFetch('/Default/GetActiveClaimTypeList', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(
      action(data => {
        this.dataTypeOfClaims = data.itemList;
        this.dataTypeOfClaims.unshift({ Id: 0, Name: 'All' });
        this.loadingClaimant = false;
      }),
    );
  };

  @action toggleModal2 = (isOpen, params = {}) => {
    this.open = isOpen;
    this.modalParams = params;
  };

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };

  getDateRange = () => {
    const [fromDate, toDate] = this.fromToRequest.split(' - ');
    return {
      fromDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  getStatusDateRange = () => {
    const [fromDate, toDate] = this.fromToStatus.split(' - ');
    return {
      fromStatusDate: fromDate ? moment(fromDate, 'DD/MM/YYYY') : '',
      toStatusDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  getAppointmentDateRange = () => {
    const [fromDate, toDate] = this.fromToAppointmentDate.split(' - ');
    return {
      fromAppointmentDate: fromDate ? moment(fromDate, 'DD/MM/YYYY').toISOString() : '',
      toAppointmentDate: toDate ? moment(toDate, 'DD/MM/YYYY').toISOString() : '',
    };
  };

  serviceItemCreate = (service = {}) => {
    return action(() => {
      this.serviceItem = service;
    });
  };

  @action handleSearchChange = ({ target }) => {
    this.keyword = target.value;
  };
  @action handleSearchChangeClinic = ({ target }) => {
    this.keywordClinic = target.value;
  };

  @action refetch = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchMain(setTotalPage);
  };

  @action refetchStaffs = (data = {}) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchStaffs();
  };

  @action refetchClaimant = (data = {}, setTotalPage = false) => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    this.fetchGetSuggestClaimant(setTotalPage);
  };

  @action resetStore = () => {
    this.keyword = '';
    this.keywordClaimant = '';
    this.FilterOverdue = 'All';
    this.FilterType = 'All';
    this.FilterStaff = 'All';
    this.NextPicId = null;
    this.IMEType = 'All';
    this.claimant = { Id: ``, FullName: `` };
    this.ActionRequiredById = '';
    this.searchClaimantOnly = false;
    this.fromToRequest = '';
    this.fromToAppointmentDate = '';
    this.fromToStatus = '';
    this.sortColumn = 'Name';
    this.sortKey = api.isCaseManager() ? 'InquiryDate' : 'StatusDate';
    this.ActionRequired = false;
    this.numberSearch = 30;
    this.PrepaymentType = 0;
    this.FilterTypeOfReport = 0;
    this.FilterTypeOfClaim = 0;
    this.curPage = 1;
    this.totalPage = 0;
  };
  @action resetSearch = () => {
    this.numberSearch = 30;
    this.keywordClaimant = '';

    this.refetchClaimant({}, true);
  };
}

export default new IMEStore();
