import './ModalActionNotes.scss';
import { Spin, DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { Checkbox, Dropdown, TextArea, Radio, Divider, Button } from 'semantic-ui-react';

import { action } from 'mobx';

import { formatDate } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal';
import ImagingStore from './ImagingStore';

class ModalActionNotes extends React.Component {
  dateFormat = 'DD/MM/YYYY';
  state = {
    loading: true,
    actions: `none`,
    actionComplete: false,
    reportComplete: false,
    actionRequired: {
      actionDueDate: null,
      actionPIC: ``,
      actionRequiredNote: ``,
      actionCompleteDate: null,
    },
    urgentReport: {
      urgentReportDueDate: null,
      urgentReportPIC: ``,
      urgentReportNote: ``,
      urgentReportCompleteDate: null,
      urgentRequiredDate: null,
    },
    actionNotesData: null,
  };

  async componentDidMount() {
    await customFetch('/MRI/GetActionNotes', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        itemId: ImagingStore.modalParams.id,
      }),
    }).then(
      action(record => {
        if (record.actionNotes.ActionRequired) {
          this.setState({
            actions: `IsActionRequired`,
          });
        } else if (record.actionNotes.UrgentReport) {
          this.setState({
            actions: `IsUrgentReport`,
          });
        } else {
          this.setState({
            actions: `none`,
          });
        }
        this.setState({
          urgentReport: {
            urgentReportDueDate: record.actionNotes.UrgentReportDueDate
              ? new Date(formatDate(record.actionNotes.UrgentReportDueDate))
              : null,
            urgentReportPIC: record.actionNotes.UrgentReportStaffId || ``,
            urgentReportNote: record.actionNotes.UrgentReportNotes || ``,
            urgentReportCompleteDate: record.actionNotes.UrgentReportCompletedDate
              ? new Date(formatDate(record.actionNotes.UrgentReportCompletedDate))
              : null,
            urgentRequiredDate: record.actionNotes.ReportRequiredDate
              ? new Date(formatDate(record.actionNotes.ReportRequiredDate))
              : new Date(),
          },
          reportComplete: record.actionNotes.UrgentReportCompleted,
        });
        this.setState({
          actionRequired: {
            actionDueDate: record.actionNotes.ActionRequiredDueDate
              ? new Date(formatDate(record.actionNotes.ActionRequiredDueDate))
              : null,
            actionPIC: record.actionNotes.StaffId || ``,
            actionRequiredNote: record.actionNotes.ActionNotes || ``,
            actionCompleteDate: record.actionNotes.CompletedDate
              ? new Date(formatDate(record.actionNotes.CompletedDate))
              : null,
          },
          actionComplete: record.actionNotes.ActionCompleted,
        });
        this.setState({
          loading: false,
          actionNotesData: record.actionNotes,
        });
      }),
    );
  }

  handleChange = (_, { value }) => this.setState({ actions: value });
  renderStaffOption = () => {
    const dataStaff = ImagingStore.dataStaff.itemList;
    return dataStaff.map(opt => ({
      text: opt.FullName,
      value: opt.Id,
    }));
  };

  handleFormatDate = date => {
    var d = moment(date, this.dateFormat);
    return date && d.isValid() ? d : null;
  };

  handleCheckboxChange = field => () => {
    if (field === `actionComplete`) {
      this.setState(state => (state.actionComplete = !state.actionComplete));
      this.setState(state => (state.actionRequired.actionCompleteDate = new Date()));
    } else {
      this.setState(state => (state.reportComplete = !state.reportComplete));
      this.setState(state => (state.urgentReport.urgentReportCompleteDate = new Date()));
    }
  };

  handleOnChangeActionPIC = (_, { value }) => this.setState(state => (state.actionRequired.actionPIC = value));
  handleActionDueDate = (_, dateString) => {
    if (dateString !== ``) {
      this.setState(state => (state.actionRequired.actionDueDate = dateString));
    }
  };
  handleActionNoteChange = (_, { value }) => {
    this.setState(state => (state.actionRequired.actionRequiredNote = value));
  };
  handleActionCompleteDate = (_, dateString) => {
    if (dateString !== ``) {
      this.setState(state => (state.actionRequired.actionCompleteDate = dateString));
    }
  };

  handleOnChangeUrgentPIC = (_, { value }) => this.setState(state => (state.urgentReport.urgentReportPIC = value));
  handleUrgentDueDate = (_, dateString) => {
    if (dateString !== ``) {
      this.setState(state => (state.urgentReport.urgentReportDueDate = dateString));
    }
  };
  handleUrgentNoteChange = (_, { value }) => {
    this.setState(state => (state.urgentReport.urgentReportNote = value));
  };
  handleUrgentCompleteDate = (_, dateString) => {
    if (dateString !== ``) {
      this.setState(state => (state.urgentReport.urgentReportCompleteDate = dateString));
    }
  };
  handleUrgentReportRequireDate = (_, dateString) => {
    this.setState(
      state => (state.urgentReport.urgentRequiredDate = dateString ? dateString : moment().format(`DD/MM/YYYY`)),
    );
  };

  renderActionForm = actionType => {
    const { actionComplete, reportComplete, actionRequired, urgentReport, actions } = this.state;
    return (
      <div>
        {actionType === `IsUrgentReport` && (
          <div className="form-group Input">
            <label className="Input-Label">Required Date for Report</label>
            <div className="datetime-picker">
              <DatePicker
                value={this.handleFormatDate(urgentReport.urgentRequiredDate)}
                style={{ margin: '2.5px' }}
                disabled={actions !== `IsUrgentReport`}
                iconPosition="right"
                format={'DD/MM/YYYY'}
                placeholder={'dd/mm/yyyy'}
                onChange={this.handleUrgentReportRequireDate}
              />
            </div>
          </div>
        )}
        <div className="form-group Input">
          <label className="Input-Label">Due Date</label>
          <div className="datetime-picker">
            <DatePicker
              value={
                actionType === `IsActionRequired`
                  ? this.handleFormatDate(actionRequired.actionDueDate)
                  : this.handleFormatDate(urgentReport.urgentReportDueDate)
              }
              style={{ margin: '2.5px' }}
              disabled={
                actionType === `IsActionRequired` ? actions !== `IsActionRequired` : actions !== `IsUrgentReport`
              }
              iconPosition="right"
              format={'DD/MM/YYYY'}
              placeholder={'dd/mm/yyyy'}
              onChange={actionType === `IsActionRequired` ? this.handleActionDueDate : this.handleUrgentDueDate}
            />
          </div>
        </div>
        <div className="form-group Input">
          <label className="Input-Labe">PIC</label>
          <Dropdown
            fluid
            search
            disabled={actionType === `IsActionRequired` ? actions !== `IsActionRequired` : actions !== `IsUrgentReport`}
            selection
            options={this.renderStaffOption()}
            value={actionType === `IsActionRequired` ? actionRequired.actionPIC : urgentReport.urgentReportPIC}
            className="input--overridden-style"
            onChange={actionType === `IsActionRequired` ? this.handleOnChangeActionPIC : this.handleOnChangeUrgentPIC}
          />
        </div>
        <div className="form-group Input">
          <label className="Input-Label">Action Notes</label>
          <TextArea
            className="TextArea"
            disabled={actionType === `IsActionRequired` ? actions !== `IsActionRequired` : actions !== `IsUrgentReport`}
            value={
              actionType === `IsActionRequired` ? actionRequired.actionRequiredNote : urgentReport.urgentReportNote
            }
            onChange={actionType === `IsActionRequired` ? this.handleActionNoteChange : this.handleUrgentNoteChange}
          />
        </div>
        <div className="form-group Checkbox">
          <Checkbox
            label="Action completed"
            disabled={actionType === `IsActionRequired` ? actions !== `IsActionRequired` : actions !== `IsUrgentReport`}
            checked={actionType === `IsActionRequired` ? actionComplete : reportComplete}
            onChange={
              actionType === `IsActionRequired`
                ? this.handleCheckboxChange(`actionComplete`)
                : this.handleCheckboxChange(`reportComplete`)
            }
          />
        </div>
        {actionType === `IsActionRequired` ? (
          <div>
            {actionComplete && (
              <div className="form-group Input">
                <label className="Input-Label">Completed Date</label>
                <div className="datetime-picker date-picker">
                  <DatePicker
                    value={this.handleFormatDate(actionRequired.actionCompleteDate)}
                    disabled={actions !== `IsActionRequired`}
                    style={{ margin: '2.5px' }}
                    iconPosition="right"
                    format={'DD/MM/YYYY'}
                    placeholder={'dd/mm/yyyy'}
                    onChange={this.handleActionCompleteDate}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {reportComplete && (
              <div className="form-group Input">
                <label className="Input-Label">Completed Date</label>
                <div className="datetime-picker date-picker">
                  <DatePicker
                    value={this.handleFormatDate(urgentReport.urgentReportCompleteDate)}
                    disabled={actions !== `IsUrgentReport`}
                    style={{ margin: '2.5px' }}
                    iconPosition="right"
                    format={'DD/MM/YYYY'}
                    placeholder={'dd/mm/yyyy'}
                    onChange={this.handleUrgentCompleteDate}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  renderNoteForm = () => {
    const { actions } = this.state;
    return (
      <div className="form-action-note-imaging-service">
        <div className="form-group Input">
          <label className="Input-Label">Action Type</label>
          <div className="Radio">
            <Radio
              className="Radio"
              label={'Action Required'}
              value="IsActionRequired"
              checked={actions === `IsActionRequired`}
              onChange={this.handleChange}
            />
            <Radio
              className="Radio"
              label={'Urgent Report'}
              checked={actions === `IsUrgentReport`}
              value="IsUrgentReport"
              onChange={this.handleChange}
            />
            <Radio
              className="Radio"
              label={'None'}
              value="none"
              checked={actions === `none`}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <Divider />
        {this.renderActionForm(`IsActionRequired`)}
        <Divider />
        {this.renderActionForm(`IsUrgentReport`)}
      </div>
    );
  };

  renderActionTypeValue = action => {
    switch (action) {
      case 'none':
        return 0;
      case 'IsUrgentReport':
        return 1;
      case 'IsActionRequired':
        return 2;
      default:
        return 0;
    }
  };

  handleFormatDateSubmit = date => {
    if (date) return moment().format('YYYY-MM-DD') + 'T00:00:00.000Z';
    return null;
  };

  handleSubmit = async () => {
    const state = this.state;
    const dataNote = {
      ActionCompleted: state.actionComplete,
      ActionNotes: state.actionRequired.actionRequiredNote || null,
      ActionRequired: state.actions === `IsActionRequired`,
      ActionRequiredDueDate: this.handleFormatDateSubmit(state.actionRequired.actionDueDate),
      ActionType: this.renderActionTypeValue(state.actions),
      CompletedDate: this.handleFormatDateSubmit(state.actionComplete ? state.actionRequired.actionCompleteDate : null),
      Id: state.actionNotesData.Id,
      ReportRequiredDate: this.handleFormatDateSubmit(state.urgentReport.urgentRequiredDate),
      StaffId: state.actionRequired.actionPIC,
      UrgentReport: state.actions === `IsUrgentReport`,
      UrgentReportCompleted: state.reportComplete,
      UrgentReportCompletedDate: this.handleFormatDateSubmit(
        state.reportComplete ? state.urgentReport.urgentReportCompleteDate : null,
      ),
      UrgentReportDueDate: this.handleFormatDateSubmit(state.urgentReport.urgentReportDueDate),
      UrgentReportNotes: state.urgentReport.urgentReportNote || null,
      UrgentReportStaffId: state.urgentReport.urgentReportPIC,
    };
    try {
      await customFetch('/MRI/PostActionNotes_Submit', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          id: dataNote.Id,
          ActionType: dataNote.ActionType,
          UrgentReportCompleted: dataNote.UrgentReportCompleted,
          UrgentReportNotes: dataNote.UrgentReportNotes,
          UrgentReport: dataNote.UrgentReport,
          UrgentReportDueDate: dataNote.UrgentReportDueDate,
          CompletedDate: dataNote.CompletedDate,
          UrgentReportStaffId: dataNote.UrgentReportStaffId,
          UrgentReportCompletedDate: dataNote.UrgentReportCompletedDate,
          ActionCompleted: dataNote.ActionCompleted,
          ActionNotes: dataNote.ActionNotes,
          ActionRequired: dataNote.ActionRequired,
          ActionRequiredDueDate: dataNote.ActionRequiredDueDate,
          StaffId: dataNote.StaffId,
          SessionId: state.actionNotesData.SessionId,
          InquiryDate: state.actionNotesData.InquiryDate,
          InquiryReceived: state.actionNotesData.InquiryReceived,
        }),
      });
      await ImagingStore.refetch({}, true);
    } finally {
      ImagingStore.setFieldsValue({ loading: false });
      this.props.onCancel();
    }
  };
  render() {
    const { open, toggleModal, modalParams, loadingStaff, dataStaff } = ImagingStore;

    const title = 'Action Notes: ' + modalParams.caseNo;
    const { loading } = this.state;

    return (
      <Modal
        visible={open}
        width={900}
        onCancel={this.props.onCancel}
        title={title}
        footer={
          <React.Fragment>
            <Button color="blue" onClick={this.handleSubmit}>
              Save
            </Button>
            <Button className="negative" onClick={toggleModal(false)}>
              Close
            </Button>
          </React.Fragment>
        }
      >
        {(loadingStaff && !dataStaff.itemList.length) || loading ? (
          <Spin className="modal-spin" />
        ) : (
          <div className="ant-modal-addOrEdit">{this.renderNoteForm()}</div>
        )}
      </Modal>
    );
  }
}

export default ModalActionNotes;
