import customFetch from '../../../utils/customFetch';

export const getM4ModifierExclusion = (
  keyword = '',
  curPage = 1,
  numItemPerPage = 0,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/M4ModifierExclusion/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getM4ModifierExclusionInfo = (id = 0) => {
  return customFetch(`/M4ModifierExclusion/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveM4ModifierExclusion = param => {
  return customFetch('/M4ModifierExclusion/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteM4ModifierExclusion = id => {
  return customFetch('/M4ModifierExclusion/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchM4ModifierExclusion = param => {
  return customFetch('/M4ModifierExclusion/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const searchAMAAnaesthesia = keyword => {
  return customFetch(
    '/MAGService/SearchAMACode?keyword=' + keyword + '&onlyProfessionalAttendance=false&onlyAnaesthesia=true',
    {
      method: 'GET',
    },
  );
};
