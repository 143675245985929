import Modal from '@components/shared/Modal/';
import FileUpload from '@components/shared/FileUpload';
import { notification } from 'antd';
import React, { useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { CKEditorConfig } from '@utils/constants';
import { CKEditor } from 'ckeditor4-react';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import customFetch from '@utils/customFetch';
import './ForwardMailModal.scss';

function ForwardMailModal({ selectedMail, onClose }) {
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState('FW: ' + selectedMail.subject);
  const [content, setContent] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleOnSubmit = async () => {
    const data = {
      EntryId: selectedMail.outlookEntryId,
      Sender: selectedMail.receiver,
      Receivers: emails,
      BodyPrefix: content,
      Subject: subject,
      Attachments: fileList
        .filter(item => item.response && item.response.name)
        .map(item => {
          return item.response.name;
        }),
    };

    try {
      const res = await customFetch('/OutlookMail/Forward', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      });

      handleOnClose();
      notification.destroy();
      if (res.status === 'success') {
        notification.success({
          message: 'Forward Successfully',
        });
      } else {
        notification.error({
          message: 'Email Failed to Send',
          description: 'Please check logs for details.',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Email Failed to Send',
        description: 'Please check logs for details.',
      });
    }
  };

  const handleEmailChange = items => {
    setEmails(items);
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };

  const handleOnChangeContent = e => {
    var html = e.editor.getData();
    setContent(html);
  };

  const handleFileAttachChange = fileList => {
    setFileList(fileList);
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal
      visible={true}
      width={600}
      onCancel={handleOnClose}
      footer={
        <React.Fragment>
          <Button color="blue" onClick={handleOnSubmit}>
            Send
          </Button>
          <Button className="negative" onClick={handleOnClose}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      <Form size={'small'}>
        <Form.Field required>
          <label>To Emails</label>
          <ReactTagInput
            tags={emails}
            placeholder="Press enter after each email"
            maxTags={10}
            editable={true}
            readOnly={false}
            removeOnBackspace={true}
            onChange={newTags => handleEmailChange(newTags)}
            validator={value => {
              // Don't actually validate e-mails this way
              const isEmail = value.indexOf('@') !== -1;
              if (!isEmail) {
                alert('Please enter a valid email address');
              }
              // Return boolean to indicate validity
              return isEmail;
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label>Subject</label>
          <Input value={subject} onChange={handleChangeSubject} />
        </Form.Field>
        <Form.Field required>
          <label>Content</label>
          <CKEditor config={CKEditorConfig} initData={content} onChange={handleOnChangeContent} />
        </Form.Field>
        <Form.Field>
          <FileUpload label="Attach files" multiUpload fileList={fileList} onFileChange={handleFileAttachChange} />
        </Form.Field>
      </Form>
    </Modal>
  );
}

export default ForwardMailModal;
