import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import ImagingStore from './ImagingStore';
import ModalCaseProgress from './ModalCaseProgress';
import ModalActionNotes from './ModalActionNotes';

function ImagingModal() {
  const { modalType } = ImagingStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...ImagingStore} onCancel={ImagingStore.toggleModal(false)} />;
  } else if (modalType === `notes`) {
    return <ModalActionNotes onCancel={ImagingStore.toggleModal(false)} />;
  } else {
    return <ModalCaseProgress />;
  }
}

export default ImagingModal;
