import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import * as api from '../../stores/api';
import router from '../../stores/router';
import apiResetStore from '../../utils/apiResetStore';
import isIE from '../../utils/isIE';
// import loginAsTourist from '../anonymous/loginAsTourist';
import Form from '../shared/form/Form';
import ReportStatus from './ReportStatus';
import './anonymous-routes.scss';
import { notification } from 'antd';

@observer
class Login extends React.Component {
  onValidSubmit = data => {
    api.login.post(data, res => {
      if (!!res?.error) {
        notification.error({
          message: 'Login Error',
          description: 'Invalid username or password!',
          duration: 6,
        });
      }
      if (router.location.pathname === '/view/login') {
        router.history.push('/');
      }
      if (isIE()) {
        window.location.reload();
      }
      apiResetStore();
    });
  };

  compareDates = (d1, d2) => {
    let date1 = d1.getTime();
    let date2 = d2.getTime();
    console.log(date1 < date2)
    return date1 < date2;
  };

  isSiteInvoiceCheck = () => {
    let hotName = window.location.hostname;
    console.log(hotName);
    if(hotName.includes('invoicecheck.kawaconn.com')){
      return true;
    }
    return false;
  }

  render() {
    return (
      <React.Fragment>
        <div style={{width: "100%", textAlign: 'center', color: "red"}}>
          {this.compareDates(new Date(), new Date(2024, 3, 8)) && <div>
            MAG Connect site will be moved to <a style={{textDecoration: "none", color: "#1fc0da", fontSize: "18px"}} href="https://mag.kawaconn.com" target="_blank">https://mag.kawaconn.com</a> on 8th April 2024
          </div>}
          {!this.compareDates(new Date(), new Date(2024, 3, 8)) && <div>
            MAG Connect site has now moved to <a style={{textDecoration: "none", color: "#1fc0da", fontSize: "18px"}} href="https://mag.kawaconn.com" target="_blank">https://mag.kawaconn.com</a>
          </div>}
        </div>
        <br/><br/>
        <Tabs>
          <TabList>
            <div>
              <Tab>LOGIN</Tab>
              <Tab>Check Report Status</Tab>
            </div>
          </TabList>
          <TabPanel>
            <Form
              id="login-form"
              onValidSubmit={this.onValidSubmit}
              fields={[
                {
                  name: 'Username',
                  rule: 'required',
                  label: 'Username',
                },
                {
                  name: 'Password',
                  rule: 'required',
                  type: 'PasswordInput',
                  label: 'Password',
                  autoComplete: 'current-password',
                },
                {
                  name: 'Remember',
                  type: 'Checkbox',
                  label: 'Remember me',
                  defaultChecked: true,
                },
              ]}
              submit={{ loading: api.login.loading }}
              button={{ loading: api.login.loading }}
            />
            <Divider />
            {this.isSiteInvoiceCheck() && <div>
              <Link to="/view/forgot" className="link">
                Forgot password
              </Link>
            </div>}
            {this.compareDates(new Date(), new Date(2024, 3, 8)) && <React.Fragment>
              <div>
                <Link to="/view/register" className="link">
                  Register Account
                </Link>
              </div>
              <div>
                <Link to="/view/register-doctor" className="link">
                  Register Account (Specialists)
                </Link>
              </div>
            </React.Fragment>}
          </TabPanel>
          <TabPanel>
            <ReportStatus />
          </TabPanel>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default Login;
