import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form, Input, Select, Checkbox, Spin } from 'antd';
import { Message, Icon } from 'semantic-ui-react';
import moment from 'moment';

import ClinicStore from './store';
import RoomForm from './RoomForm';
import OfficeMailingForm from './OfficeMailingForm';
import { convertPhoneFaxNumber } from '@utils/functions';
import dashboardStore from '@stores/dashboard';

@observer
class GeneralInfoForm extends React.Component {
  getClinicServiceOptions = () => {
    const { pageGetter } = this.props.state;
    if (pageGetter) {
      ClinicStore.clinicServices.forEach(i => {
        if (pageGetter) {
          i.Disabled = true;
        } else {
          i.Disabled = false;
        }
        i.Checked = false;
        if (pageGetter === `addEditIME` && i.Label === `IME`) {
          i.Disabled = false;
        } else if (pageGetter === `addEditCR` && i.Label === `Clinical Record Retrieval`) {
          i.Disabled = false;
        }
      });
    }
    return ClinicStore.clinicServices.map(({ Label, Id, Disabled, Checked }) => ({
      label: Label,
      value: Id,
      disabled: Disabled,
    }));
  };

  handleUpload = event => {
    const files = event.target.files;
    if (!files || !files.length) return;

    ClinicStore.handleUploadFile(files[0]);
  };

  handleDownloadFile = () => {
    window.open(`${window.location.origin}/UserFile/ClinicAttachment/${ClinicStore.clinicInfo.LOAFileName}`, '_blank');
  };

  handleOpenCalendarTab = () => {
    const clinicId = ClinicStore.clinicInfo.ID;
    const doctorSession = {
      clinicId: clinicId || 0,
      doctorId: null,
      selectedDate: moment(),
    };
    localStorage.setItem('Data:DoctorSessionDateSelected', JSON.stringify(doctorSession));
    dashboardStore.close('/view/calendar-2');

    setTimeout(() => {
      dashboardStore.open(`/view/calendar-2`);
    });
  };

  render() {
    return (
      <React.Fragment>
        <button
          className="clinic-calendar"
          onClick={this.handleOpenCalendarTab}
          disabled={!ClinicStore.clinicInfo.ID}
          style={{ marginTop: '-15px', marginBottom: '10px' }}
        >
          View this Clinic's calendar
        </button>
        <Form name="general-information">
          <Form.Item label="Centre ID">
            <Input size="large" value={ClinicStore.clinicInfo.ID} disabled />
          </Form.Item>
          <Form.Item label="Centre Status">
            <Input size="large" value={ClinicStore.clinicInfo.IsActive ? 'Active' : 'Inactive'} disabled />
          </Form.Item>
          <Form.Item
            required
            validateStatus={ClinicStore.errors['Name'] ? 'error' : null}
            help={ClinicStore.errors['Name']}
            label={
              <React.Fragment>
                Centre/Provider Name
                <button
                  disabled={ClinicStore.duplicateLoading}
                  className="check-duplicate"
                  onClick={ClinicStore.handleCheckDuplicate('Name')}
                >
                  Check duplicate
                </button>
              </React.Fragment>
            }
          >
            <Input size="large" value={ClinicStore.clinicInfo.Name} onChange={ClinicStore.handleFieldChange('Name')} />
          </Form.Item>
          <Form.Item
            label="Phone"
            validateStatus={ClinicStore.errors['Telephone'] ? 'error' : null}
            help={ClinicStore.errors['Telephone']}
          >
            <Input
              size="large"
              value={convertPhoneFaxNumber(ClinicStore.clinicInfo.Telephone)}
              onChange={ClinicStore.handleFieldChange('Telephone')}
            />
          </Form.Item>
          <Form.Item
            label="Fax"
            validateStatus={ClinicStore.errors['Fax'] ? 'error' : null}
            help={ClinicStore.errors['Fax']}
          >
            <Input
              size="large"
              value={convertPhoneFaxNumber(ClinicStore.clinicInfo.Fax)}
              onChange={ClinicStore.handleFieldChange('Fax')}
            />
          </Form.Item>
          <Form.Item
            validateStatus={ClinicStore.errors['Email'] ? 'error' : null}
            help={ClinicStore.errors['Email']}
            label={
              <React.Fragment>
                Business Email
                <button
                  disabled={ClinicStore.duplicateLoading}
                  className="check-duplicate"
                  onClick={ClinicStore.handleCheckDuplicate('Email')}
                >
                  Check duplicate
                </button>
              </React.Fragment>
            }
          >
            <Input
              size="large"
              value={ClinicStore.clinicInfo.Email}
              onChange={ClinicStore.handleFieldChange('Email')}
            />
          </Form.Item>
          <Form.Item
            label="Accounting Email"
            validateStatus={ClinicStore.errors['AccountingEmail'] ? 'error' : null}
            help={ClinicStore.errors['AccountingEmail']}
          >
            <Input
              size="large"
              value={ClinicStore.clinicInfo.AccountingEmail}
              onChange={ClinicStore.handleFieldChange('AccountingEmail')}
            />
          </Form.Item>
          <Form.Item label="Provider No">
            <Input
              size="large"
              value={ClinicStore.clinicInfo.ProviderNo}
              onChange={ClinicStore.handleFieldChange('ProviderNo')}
            />
          </Form.Item>
          <Form.Item label="Centre Type">
            <Select size="large" value={ClinicStore.clinicInfo.Type} onChange={ClinicStore.handleFieldChange('Type')}>
              <Select.Option value="mag">Medicolegal Provider - Owned Clinic</Select.Option>
              <Select.Option value="non-mag">Specialist - Owned Clinic</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Services Provided" className="fluid-field">
            <Checkbox.Group
              options={this.getClinicServiceOptions()}
              value={toJS(ClinicStore.clinicInfo.ClinicServiceTypes)}
              onChange={ClinicStore.handleFieldChange('ClinicServiceTypes')}
            />
          </Form.Item>
          <Form.Item label="Clinic LOA Template for CR Bookings" className="fluid-field">
            <Message className="upload-box">
              {!ClinicStore.clinicInfo.LOAFileName ? (
                <div className="upload-file">
                  {ClinicStore.uploadLoading && <Spin />}
                  <input type="file" onChange={this.handleUpload} />
                  Click here to upload
                </div>
              ) : (
                <div className="file-info">
                  <div className="file-info-inner">
                    <Icon name="file alternate" />
                    <div className="file-name">{ClinicStore.clinicInfo.LOAFileTitle}</div>
                  </div>
                  <div className="file-actions">
                    <Icon name="download" onClick={this.handleDownloadFile} />
                    <Icon name="delete" color="red" onClick={ClinicStore.handleDeleteFile} />
                  </div>
                </div>
              )}
            </Message>
            <label style={{ marginRight: '5px' }}>Show Clinic LoA in CR Bookings</label>
            <Checkbox
              onChange={ClinicStore.handleFieldChange('IsCNViewLOATemplate')}
              checked={ClinicStore.clinicInfo.IsCNViewLOATemplate}
            ></Checkbox>
          </Form.Item>
          <Form.Item label="Description" className="fluid-field">
            <Input.TextArea
              rows={3}
              value={ClinicStore.clinicInfo.Description}
              onChange={ClinicStore.handleFieldChange('Description')}
            />
          </Form.Item>
        </Form>
        <OfficeMailingForm isRerender={this.props.isRerender} />
        {!this.props.state.pageGetter && (
          <Form style={{ marginTop: '30px' }}>
            <Form.Item label="Room List" required className="fluid-field">
              <RoomForm />
            </Form.Item>
          </Form>
        )}
      </React.Fragment>
    );
  }
}

export default GeneralInfoForm;
