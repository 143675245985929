export const CKEditorConfig = {
  extraPlugins: 'justify,font,colorbutton,colordialog',
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    {
      name: 'undo',
      groups: ['undo'],
    },
    {
      name: 'redo',
      groups: ['redo'],
    },
    '/',
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    '/',
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
  ],
  removeButtons:
    'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting',
  fontSize_sizes: '6/6px;8/8px;10/10px;12/12px;16/16px;24/24px;48/48px;',
  font_names: 'Arial/Arial, Helvetica, sans-serif;Times New Roman/Times New Roman, Times, serif;Verdana',
  allowedContent: true,
};

export const RoleOptions = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'magstaff', text: 'MAG Staff', value: 'magstaff' },
  { key: 'casemng', text: 'Case Manager', value: 'casemng' },
  { key: 'doctor', text: 'Doctor', value: 'doctor' },
  { key: 'accounting', text: 'Accounting', value: 'accounting' },
  { key: 'typist', text: 'Typist', value: 'typist' },
];

export const PubSubTopics = {
  SelectCaseNo: 'SelectCaseNo',
};

export const PRODUCTION_HOST = 'magconnect.com.au';

export const timezoneList = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Asia/Saigon',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
];

export const SERVER_TIME_ZONE = 'Australia/Sydney';

export const TRANSCRIPT_AUDIO_FILE_EXTENSION = ['mp3', 'dss', 'm4a', 'aac', 'wav'];

export const AUDIO_FILE_EXTENSION = ['mp3', 'dss', 'm4a', 'aac', 'wav', 'ds2'];

export const FILE_EXTENSION = [
  'jpg',
  'pdf',
  'jpeg',
  'gif',
  'png',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'htm',
  'html',
  'zip',
  'rar',
  'txt',
  'rtf',
  'tif',
  'tiff',
  'mp4',
  'xml',
  'bmp',
  ...AUDIO_FILE_EXTENSION,
];

export const AllowedFileExtensions = `image/jpg,
image/png,
image/jpeg,
image/gif,
image/bmp,
image/tif,
image/tiff,
application/pdf,
application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,
application/vnd.ms-excel,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
application/vnd.ms-powerpoint,
application/x-zip-compressed, multipart/x-zip,
application/x-rar-compressed, application/octet-stream,
application/x-rtf, text/rtf,
text/xml,
text/htm,
text/html,
text/plain,
video/mp4,${AUDIO_FILE_EXTENSION.map(i => `.${i}`).join(',')}`;

export const SpecRelationshipActivityResults = [
  { Name: 'Neutral result - 1st time reaching out', Id: 18, Visible: true },
  { Name: 'Neutral result - No response yet', Id: 19, Visible: true },
  { Name: 'Positive result - Agree to onboard', Id: 10, Visible: true },
  { Name: 'Positive result - Book meeting to discuss onboarding', Id: 11, Visible: true },
  { Name: 'Positive result - Referred colleague for onboarding', Id: 12, Visible: true },
  { Name: 'Positive result - Interested but not now – set follow up', Id: 13, Visible: true },
  { Name: 'Positive result - Provided availability', Id: 20, Visible: true },
  { Name: 'Positive result - Others', Id: 21, Visible: true },
  { Name: 'Negative result - Do not do medicolegal', Id: 14, Visible: true },
  { Name: 'Negative result - No capacity at this time – set follow up', Id: 15, Visible: true },
  { Name: 'Negative result - Bad experience with MLP – set follow up / explore', Id: 16, Visible: true },
  { Name: 'Negative result - Work with other MLPs, not interested to change', Id: 17, Visible: true },
];

export const CMRelationshipActivityResults = [
  { Name: 'Neutral result – No answer', Id: 1, Visible: true },
  { Name: 'Neutral result – No response yet', Id: 20, Visible: true },
  { Name: 'Negative result – Unsatisfactory experience with service/specialist', Id: 2, Visible: true },
  { Name: 'Negative result – Not receptive to relationship activity', Id: 21, Visible: true },
  { Name: 'Negative result – Unsubscribed request', Id: 22, Visible: true },
  { Name: 'Negative result - Bad Experience', Id: 2, Visible: false },
  { Name: 'Negative result - Service/Specialist N/A', Id: 4, Visible: false },
  { Name: 'Negative result – CM has left firm', Id: 5, Visible: true },
  { Name: 'Negative result – General feedback', Id: 23, Visible: true },
  { Name: 'Positive result – New CM', Id: 24, Visible: true },
  { Name: 'Positive result – Agreed to use platform login', Id: 3, Visible: true },
  { Name: 'Positive result – Made a booking', Id: 7, Visible: true },
  { Name: 'Positive result – Updated client team members or BU', Id: 6, Visible: true },
  { Name: 'Positive result - Made referral', Id: 8, Visible: false },
  { Name: 'Positive result – General feedback', Id: 9, Visible: true },
  { Name: 'Neutral result - 1st time reaching out', Id: 18, Visible: false },
  { Name: 'Neutral result - No response yet', Id: 19, Visible: false },
  { Name: 'Positive result – Received specialist recommendations', Id: 25, Visible: true },
];

export const CCServicesType = [
  { Name: 'All', Id: 1, Visible: true },
  { Name: 'IME', Id: 2, Visible: true },
  { Name: 'MEDNEG', Id: 3, Visible: true },
  { Name: 'MR', Id: 4, Visible: true },
  { Name: 'OT', Id: 5, Visible: true },
  { Name: 'Disbursement', Id: 6, Visible: true },
  { Name: 'Other', Id: 99, Visible: true },
];

export const ActivityTypeOptions = [
  { value: 0, text: 'All' },
  { value: 1, text: 'Email' },
  { value: 2, text: 'Call' },
  { value: 3, text: 'Meeting' },
];

export const ActivityOutcomeOptions = [
  { value: 0, text: 'All' },
  { value: 1, text: 'Successful Outcome' },
  { value: 2, text: 'Unsuccessful Outcome' },
];
