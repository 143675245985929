import { showUnexpectedError } from '@utils/errors';
import { notification } from 'antd';
import { action, observable } from 'mobx';
import Validator from 'validatorjs';

import {
  preApprovedFundingTaken,
  getCheckPreApprovedDisbursment,
  getSpecificLookupConfigByType,
  getDisbursementType,
} from './service';

class AddEditStore {
  @observable legalCompanyName = '';
  @observable position = 1595;
  @observable contactName = '';
  @observable email = '';
  @observable phone = '';
  @observable mobile = '';
  @observable error = {};
  @observable loading = false;
  @observable data = {};
  @observable DisbursementAttachment = [];
  @observable positions = [];
  @observable TypeOfDisbursementId = null;
  @observable OtherDisbursementType = '';
  @observable disbursementType = [];
  @observable isFromMainMenu = false;
  @observable caseId = '';
  @observable caseNo = '';
  @observable fromService = '';
  @observable claimantId = '';
  @observable AdditionalInfo = '';
  @observable claimantName = '';

  @action setFieldsValue = (obj = {}) => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
  };

  showFormErrorNoti = (description = 'Invalid form') => {
    notification.error({
      message: 'Error: ',
      description,
    });
  };

  handleChangeValue = (_, { name, value }) => {
    if (name === 'email') {
      this.handleValidate(value, 'email', name);
    }
    if (name === 'phone' || name === 'mobile') {
      const value_ = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      this.handleValidate(value_, 'max:10', name);
      this.setFieldsValue({ [name]: value_ });
      return;
    }
    if (name === 'TypeOfDisbursementId') {
      this.setFieldsValue({ OtherDisbursementType: '' });
    }
    this.setFieldsValue({ [name]: value });
  };

  validateErrorForm = (value, type) => {
    const v = new Validator({ value }, { value: type });
    if (v.passes()) {
      return null;
    } else {
      return type;
    }
  };

  handleValidate = (value, type, fieldName) => {
    if (fieldName && type) {
      const validateResult = this.validateErrorForm(value, type);
      if (validateResult) {
        const err = this.error;
        if (fieldName === 'email') {
          this.setFieldsValue({ error: { ...err, [fieldName]: true } });
        }
        if (fieldName === 'mobile' || fieldName === 'phone') {
          this.setFieldsValue({ error: { ...err, [fieldName]: true } });
        }
      } else {
        const err = this.error;
        delete err[fieldName];
        this.setFieldsValue({ error: err });
      }
    }
  };

  moveObjToLast = (disbursementTypes = []) => {
    const items = disbursementTypes.map(i => (i.Id === 2758 || i.Id === 2757 ? i : null)).filter(i => !!i);
    return [...disbursementTypes.filter(i => (i.Id !== 2758 && i.Id !== 2757 ? i : null)).filter(i => !!i), ...items];
  };

  fetchData = async ({ clientId = 0, isManual = false }) => {
    const [resPositions, resData, resDisbursementType] = await Promise.all([
      getSpecificLookupConfigByType(),
      getCheckPreApprovedDisbursment(clientId, isManual),
      getDisbursementType(),
    ]);
    const { model } = resData;
    this.moveObjToLast(resDisbursementType.itemList);
    this.setFieldsValue({
      positions: resPositions.itemList,
      legalCompanyName: model?.LegalCompanyName,
      position: model?.Position || 1595,
      contactName: model?.ContactName,
      email: model?.Email,
      phone: model?.Phone,
      mobile: model?.Mobile,
      data: model,
      loading: false,
      disbursementType: this.moveObjToLast(resDisbursementType.itemList),
    });
  };

  checkRequired = () => {
    const requireFields = ['legalCompanyName', 'position', 'contactName', 'email', 'phone'];
    const notPass = requireFields.filter(i => !this[i]);
    if (!this.legalCompanyName) {
      this.showFormErrorNoti('Legal company name is required');
    }
    if (
      !this.TypeOfDisbursementId &&
      this.isFromMainMenu &&
      (this.data.IsDisbursementFunding || this.data.PreApprovedDisbursementFunding)
    ) {
      this.showFormErrorNoti('Type of Disbursement is required');
      return true;
    }
    if (
      (this.TypeOfDisbursementId === 2758 || this.TypeOfDisbursementId === 2757) &&
      !this.OtherDisbursementType &&
      this.isFromMainMenu &&
      (this.data.IsDisbursementFunding || this.data.PreApprovedDisbursementFunding)
    ) {
      this.showFormErrorNoti(`Other ${this.TypeOfDisbursementId === 2758 ? 'Disbursement' : 'Report'} is required`);
      return true;
    }
    if (!this.position) {
      this.showFormErrorNoti('Position is required');
    }
    if (!this.contactName) {
      this.showFormErrorNoti('Contact full name is required');
    }
    if (!this.email) {
      this.showFormErrorNoti('Email is required');
    }

    if (!this.phone) {
      this.showFormErrorNoti('Phone is required');
    }

    return !!notPass.length;
  };

  sendRequestNonService = callback => {
    const { error } = this;
    if (error.email) {
      return this.showFormErrorNoti('Invalid email address');
    }
    if (error.phone) {
      return this.showFormErrorNoti('Phone must be less than or equal to 10 digits');
    }
    if (error.mobile) {
      return this.showFormErrorNoti('Mobile must be less than or equal to 10 digits');
    }
    if (this.checkRequired()) {
      return;
    }
    const params = {
      legalCompanyName: this.legalCompanyName,
      position: this.position,
      contactName: this.contactName,
      email: this.email,
      phone: this.phone,
      mobile: this.mobile,
      DisbursementAttachment: this.DisbursementAttachment,
      TypeOfDisbursementId: this.TypeOfDisbursementId,
      OtherDisbursementType: this.OtherDisbursementType,
      AdditionalInfo: this.AdditionalInfo,
    };
    preApprovedFundingTaken(params).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Save Successfully',
          description: 'Thank you for sending funding request. We will reach out to your nominated contact shortly.',
          duration: 3,
        });
        callback && callback();
      } else {
        showUnexpectedError();
      }
    });
  };

  sendRequestWithService = callback => {
    const { error } = this;
    if (error.email) {
      return this.showFormErrorNoti('Invalid email address');
    }
    if (error.phone) {
      return this.showFormErrorNoti('Phone must be less than or equal to 10 digits');
    }
    if (error.mobile) {
      return this.showFormErrorNoti('Mobile must be less than or equal to 10 digits');
    }
    if (this.checkRequired()) {
      return;
    }
    const params = {
      legalCompanyName: this.legalCompanyName,
      position: this.position,
      contactName: this.contactName,
      email: this.email,
      phone: this.phone,
      mobile: this.mobile,
      caseId: this.caseId,
      caseNo: this.caseNo,
      fromService: this.fromService,
      claimantId: this.claimantId,
      claimantName: this.claimantName,
      AdditionalInfo: this.AdditionalInfo,
    };
    preApprovedFundingTaken(params).then(res => {
      if (res.status === 'success') {
        notification.destroy();
        notification.success({
          message: 'Save Successfully',
          description: 'Thank you for sending funding request. We will reach out to your nominated contact shortly.',
          duration: 3,
        });
        callback && callback();
      } else {
        showUnexpectedError();
      }
    });
  };
}

export default new AddEditStore();
