import cx from 'classnames';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import Modal from '../../shared/Modal/';
import { TableEmptyMessage, TableHeader } from '../../shared/table';
import Store from './clinicalNoteStore';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import { Link } from 'react-router-dom';
import moment from 'moment';
import dashboardStore from '@stores/dashboard';

const columns = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Specialist' },
  { title: 'Case Status' },
  { title: 'Status Date' },
];

@observer
class ModalSuppRequest extends React.Component {
  componentDidMount() {
    const { id, type } = this.props.modalParams;
    if (type === 'FR') {
      Store.fetchSuppRequestFR(id);
    } else {
      Store.fetchSuppRequestIME(id);
    }
  }
  renderCaseStatus = caseStatus => {
    switch (caseStatus) {
      case 0:
        return 'Case Created';
      case 1:
        return 'Request Received';
      case 2:
        return 'Request Sent to Provider';
      case 3:
        return 'Dication Received';
      case 4:
        return 'Report Draft Sent to Provider';
      case 5:
        return 'Corrected Report Returned from Provider';
      case 6:
        return 'Clarification No Fee';
      case 7:
        return 'Reports Sent to CMs';
      case 8:
        return 'Cancelled No Fee';
      case 9:
        return 'Cancelled With Fee';
      case 10:
        return 'Service Billed';
      case 11:
        return 'Service Paid';
      default:
        return '';
    }
  };
  openCaseDetails = record => e => {
    e.preventDefault();
    localStorage.setItem('SupplementaryCaseId', `Case ${record.CaseNo}`);
    const id = record.Id;
    dashboardStore.close('/view/add-edit-supplementary-report-2');
    setTimeout(() => dashboardStore.open(`/view/add-edit-supplementary-report-2?id=${id}`));
    Store.toggleModal(false);
  };
  renderTableHistory = () =>
    Store.dataSuppRequest.itemList.map(({ ...record }, index) => (
      <Table.Row key={index}>
        <Table.Cell
          style={{ maxWidth: '200px' }}
          selectable
          onClick={this.openCaseDetails(record)}
          className="Cell-link"
        >
          <Link to="#" onClick={this.openCaseDetails(record)}>
            {record.CaseNo}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell style={{ maxWidth: '300px' }}>
          <h5 className="ma-0" style={{ whiteSpace: 'nowrap' }}>
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ''} {record.Doctor}{' '}
            {record.DoctorId > 0 ? `${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}` : ``}
          </h5>
          <div style={{ whiteSpace: 'nowrap' }}>{record.DoctorId > 0 ? `Specialty:` + record.DoctorSepcialty : ``}</div>
          <div>
            {record.DoctorId > 0 ? (
              <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
            ) : (
              ``
            )}
          </div>
          <div className={cx(record.DoctorContractReady ? 'text-success' : 'text-danger')}>
            {record.DoctorId > 0 ? `Contract Ready: ${!record.DoctorContractReady ? 'No' : 'Yes'}` : ``}
          </div>
        </Table.Cell>
        <Table.Cell>{this.renderCaseStatus(record.CaseStatus)}</Table.Cell>
        <Table.Cell>
          {record.StatusDate
            ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    ));

  render() {
    const { loadingSuppRequest, toggleModal, open, totalSuppRequest, dataSuppRequest } = Store;
    const { record } = Store.modalParams;
    const colSpan = columns.length;

    return (
      <Modal
        visible={open}
        width={1400}
        onCancel={toggleModal(false)}
        title={`Supp Request History - ${record.AssessmentNumber ||
          record.magRefNo ||
          record.AssessmentNumber ||
          record.MagRefNo} (${totalSuppRequest})`}
      >
        {loadingSuppRequest && !dataSuppRequest ? (
          <div className={`Table-loading`}>
            <Spin size="large" />
          </div>
        ) : (
          <Table sortable striped celled className={cx({ disabled: loadingSuppRequest })}>
            <TableHeader columns={columns} />
            <Table.Body>
              {dataSuppRequest.itemList.length ? this.renderTableHistory() : <TableEmptyMessage colSpan={colSpan} />}
            </Table.Body>
          </Table>
        )}
      </Modal>
    );
  }
}

export default ModalSuppRequest;
