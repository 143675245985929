import { notification } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import customFetch from '../../../utils/customFetch';
import Modal from '../../shared/Modal/';
import AddEditStore from './store';

const roles = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'magstaff', text: 'Staff', value: 'magstaff' },
  { key: 'casemng', text: 'Case Manager', value: 'casemng' },
  { key: 'doctor', text: 'Specialist', value: 'doctor' },
  { key: 'accounting', text: 'Accounting', value: 'accounting' },
  { key: 'typist', text: 'Typist', value: 'typist' },
];
@observer
class ModalEditUser extends React.Component {
  state = {
    UserName: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.UserName : '',
    Email: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.Email : '',
    Roles: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.Roles.map(m => m) : [],
    FirstName: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.FirstName || '' : '',
    LastName: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.LastName : '',
    Id: AddEditStore.dataUser.User ? AddEditStore.dataUser.User.Id : '',
    errorUserName: AddEditStore.dataUser.User?.UserName ? '' : 'Please enter username with at least 6 characters.',
    errorEmail: AddEditStore.dataUser.User?.Email ? '' : 'Please enter email with at least 6 characters.',
  };

  handleOnChangeDropDown = (e, { value }) => {
    this.setState({ ...this.state, Roles: value });
  };

  @action handleUpdateUserName = () => {
    customFetch('/Manager/Save', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        modUserParam: {
          ...this.state,
          UserName: this.state.UserName,
          Email: this.state.Email,
          Roles: this.state.Roles.map(i => i),
        },
      }),
    }).then(
      action(respone => {
        notification.destroy();
        if (respone.status === 'success') {
          AddEditStore.handleChangeCMValue('Username', this.state.UserName);
          notification.success({
            message: 'Success',
            description: `Edit successful`,
            duration: 5,
          });
        } else {
          notification.error({
            message: 'Error',
            description: `Edit failed`,
            duration: 5,
          });
        }
        this.props.onCancel();
      }),
    );
  };

  handleOnChangeUserName = (e, { value }) => {
    const errorUserName = value ? '' : `Please enter username with at least 6 characters.`;
    this.setState({
      UserName: value,
      errorUserName,
    });
  };

  handleOnChangeEmail = (e, { value }) => {
    const errorEmail = value ? '' : `Please enter email with at least 6 characters.`;
    this.setState({
      Email: value,
      errorEmail,
    });
  };

  render() {
    const { open } = AddEditStore;
    const { UserName, Email } = this.state;
    return (
      <React.Fragment>
        <Modal
          visible={open}
          width={600}
          onCancel={this.props.onCancel}
          footer={
            <React.Fragment>
              <Button color="blue" onClick={this.handleUpdateUserName}>
                <Icon name="save" /> Save
              </Button>
              <Button color="red" onClick={this.props.onCancel}>
                <Icon name="close" /> Close
              </Button>
            </React.Fragment>
          }
          title="User Info"
        >
          <Form>
            <Form.Field
              required
              control={Input}
              label="Username"
              fluid
              error={
                this.state.errorUserName
                  ? {
                      content: this.state.errorUserName,
                    }
                  : null
              }
              placeholder="Username"
              value={UserName}
              onChange={this.handleOnChangeUserName}
            />
            <Form.Field
              required
              control={Input}
              label="Email"
              fluid
              error={
                this.state.errorEmail
                  ? {
                      content: this.state.errorEmail,
                    }
                  : null
              }
              placeholder="Email"
              value={Email}
              onChange={this.handleOnChangeEmail}
              disabled
            />
            <Form.Dropdown
              required
              error={
                this.state.Roles.length === 0
                  ? {
                      content: 'Please select at least one role for this user',
                    }
                  : null
              }
              fluid
              label="Role"
              placeholder="Role"
              id="form-input-first-name"
              multiple
              search
              selection
              value={this.state.Roles.map(role => role)}
              onChange={this.handleOnChangeDropDown}
              options={roles}
            />
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditUser;
