import React from 'react';
import { Menu, Dropdown, notification } from 'antd';
import { observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import moment from 'moment';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import { formatDate } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';
import * as api from '@stores/api';
import store from './Store';

const columns = [
  {},
  { title: 'Action Subject' },
  { title: 'Due Date' },
  { title: 'Action Notes' },
  { title: 'PIC' },
  { title: 'Action Complete' },
  { title: 'Completed Date' },
];

@observer
class ActionRequired extends React.Component {
  state = {
    visible: false,
    dataProps: null,
  };

  toggleModal = () => {
    return store.toggleModalAction(true);
  };

  handleDisableActionNote = async id => {
    try {
      const response = await customFetch('/Manager/ActionRequired_Disable', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      if (response.status === 'success') {
        await store.refetchActionRequired();
        notification.destroy();
        notification.success({
          message: 'Success',
          description: 'Data has been deleted successfully.',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Error',
          description: 'An error occurred, please try again',
        });
      }
    } catch (e) {
      console.log(e);
      notification.destroy();
      notification.error({
        message: 'Error',
        description: 'An error occurred, please try again',
      });
    } finally {
    }
  };

  renderDate = (datestr, fieldName) => {
    if (datestr) {
      if (fieldName === 'DueDate') {
        const date = formatDate(datestr, true);
        return moment(date, 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm');
      } else {
        const date = formatDate(datestr);
        return moment(date, 'DD MMM, YYYY').format('DD/MM/YYYY');
      }
    }
    return '';
  };

  handleOpenConfirm = data => {
    return store.toggleModal(true, {
      modalType: 'confirm',
      message: 'Do you want to delete this action note',
      onOk: () => this.handleDisableActionNote(data.Id),
    })();
  };

  renderDropdownMenu = data => (
    <Menu>
      <Menu.Item onClick={this.toggleModal}>Edit</Menu.Item>
      <Menu.Item onClick={() => this.handleOpenConfirm(data)}>Delete</Menu.Item>
    </Menu>
  );

  renderTableBody = data => {
    return data.map((i, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>
          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(i)}>
            <Icon name="ellipsis vertical" />
          </Dropdown>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link to="#" onClick={this.toggleModal}>
            {i.Subject}
          </Link>
        </Table.Cell>
        <Table.Cell>{this.renderDate(i.DueDate, 'DueDate')}</Table.Cell>
        <Table.Cell>{i.ActionNote}</Table.Cell>
        <Table.Cell>{`${i.StaffFirstName || ''} ${i.StaffLastName || ''}`}</Table.Cell>
        <Table.Cell>
          <p style={{ color: `${i.Completed ? 'green' : 'red'}` }}>{i.Completed ? 'Completed' : 'Not Completed'}</p>
        </Table.Cell>
        <Table.Cell>{this.renderDate(i.CompletedDate)}</Table.Cell>
      </Table.Row>
    ));
  };

  handleChangeRadio = fieldName =>
    action(event => {
      const value = event.target ? (event.target.value ? event.target.value : '') : event;
      const itemModel = toJS(store.fileReviewInfo);
      store.fileReviewInfo = {
        ...itemModel,
        [fieldName]: value === 1 ? true : false,
      };
    });

  render() {
    const data = toJS(store.actionRequiredList);
    return (
      <div className="fluid-field">
        <div className="fluid-field" style={{ marginBottom: '1em' }}>
          {!api.isOnlySearch() && <Button color="blue" onClick={this.toggleModal}>
            Add Action Required
          </Button>}
        </div>
        <div className="table-case-correspondence">
          <Table sortable striped celled>
            <TableHeader columns={columns} />
            <Table.Body>
              {data && data.length && data.length !== 0 ? (
                this.renderTableBody(data)
              ) : (
                <TableEmptyMessage colSpan={columns.length} />
              )}
            </Table.Body>
            <TableFooterPagination
              colSpan={columns.length}
              totalItems={data && data.length && data.length !== 0 ? data.length : 0}
              currentPage={data && data.length && data.length !== 0 ? 1 : 0}
              totalPage={data && data.length && data.length !== 0 ? 1 : 0}
              onPageClick={() => {}}
            />
          </Table>
        </div>
      </div>
    );
  }
}

export default ActionRequired;
