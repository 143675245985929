import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import TypeOfClaimStore from './LMQStore';

function TypesOfReportModal() {
  const { modalType } = TypeOfClaimStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...TypeOfClaimStore} onCancel={TypeOfClaimStore.toggleModal(false)} />;
  }
}

export default TypesOfReportModal;
