import customFetch from '../../../utils/customFetch';

export const getAssessmentInfo = id => {
  return customFetch('/MedicalService/PostAssessmentInfo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getStaffGetAll = (keyword = '', numItemPerPage = 1000, companyId = 0, IsOnboarded = true) => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword,
      numItemPerPage: numItemPerPage,
      companyId,
      IsOnboarded,
    }),
  });
};

export const getWaitingList = calendarId => {
  return customFetch('/Calendar/GetWaitingList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ calendarId }),
  });
};

export const getIMSApprovalCode = () => {
  return customFetch('/MedicalService/GetIMSApprovalCode', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getWIROPrincipleLawyer = () => {
  return customFetch('/MedicalService/GetWIROPrincipleLawyer', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getRooms = (clinicId = 0) => {
  return customFetch('/Clinic/GetRooms', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clinicId }),
  });
};

export const getClinicList = (doctorId = 0, numItemPerPage = 30, keyword = '') => {
  return customFetch('/Clinic/GetClinicList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      curPage: 1,
      doctorId: doctorId,
      numItemPerPage: numItemPerPage,
      type: 'doctor',
      keyword: keyword,
    }),
  });
};

export const getClinicListByType = (clinicType, numItemPerPage = 30, keyword = '') => {
  return customFetch('/Clinic/GetClinicList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      curPage: 1,
      doctorId: null,
      numItemPerPage: numItemPerPage,
      type: clinicType,
      keyword: keyword,
    }),
  });
};

export const getConversationList = (AssessmentId = 0) => {
  return customFetch('/MedicalService/ConversationLog_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ AssessmentId }),
  });
};

export const getAllWithoutNCI = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/MedicalDoctor/GetAllWithoutNCI', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      RecruitedOrContractedOnly: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
    }),
  });
};

export const getActiveClaimTypeList = () => {
  return customFetch('/Default/GetActiveClaimTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getPatientList = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/PatientInfo/GetPatientList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
    }),
  });
};

export const preApprovedFundingTaken = model => {
  return customFetch('/Company/PreApprovedFundingTaken', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      model,
    }),
  });
};

export const getCompanyList = (keyword = '', numItemPerPage = 30, isGetDeliveryContact = false) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
      IsGetReportDeliveryContact: isGetDeliveryContact,
    }),
  });
};

export const getCompanyInsuer = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
      IcareInsurer: true, //Insurer
    }),
  });
};

export const getStaffList = () => {
  return customFetch('/Default/GetStaffs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getTypists = () => {
  return customFetch('/Default/GetTypists', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getLanguageList = () => {
  return customFetch('/Default/Language_GetList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getClinicInterpretationService = () => {
  return customFetch('/MedicalService/GetClinicInterpretationService', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getLookupConfigByTypes = (types = ['spe']) => {
  return customFetch('/Default/GetLookupConfigByTypes', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ types: types }),
  });
};

export const getLookUpConfig = (type = '') => {
  return customFetch('/Default/GetLookUpConfig', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type }),
  });
};
export const getSpecificLookupConfigOrderNoByType = (types = 'PapToDoc') => {
  return customFetch('/Default/GetSpecificLookupConfigOrderNoByType', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: types }),
  });
};

export const getAllByStatus = (NumItemPerPage = 500, Showing = 'active') => {
  return customFetch('/MAGService/GetAllByStatus', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ NumItemPerPage: NumItemPerPage, Showing: Showing }),
  });
};

export const getAssessmentStatus = (id = 0) => {
  return customFetch(`/api/AssessmentStatus/${id}`, {
    method: 'GET',
  });
};

export const getAssessmentLogs = assessmentId => {
  return customFetch('/MedicalService/GetAssessmentLogs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ assessmentId }),
  });
};

export const getActionRequired = (id = 0) => {
  return customFetch('/MedicalService/ActionRequired_LoadList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ AssessmentId: id }),
  });
};

export const getListInvoiceID = (objectId = 0) => {
  return customFetch('/MedicalDoctor/GetListInvoiceID', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectId: objectId, type: 'Assessment' }),
  });
};

export const getActiveReportTypeList = () => {
  return customFetch('/Default/GetActiveReportTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getLookupConfigByParents = (parents = []) => {
  return customFetch('/MedicalDoctor/GetLookupConfigByParents', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ parents }),
  });
};

export const getLookupConfigByParentId = (parentId = 0) => {
  return customFetch('/MedicalDoctor/GetLookupConfigByParentId?parentId=' + parentId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getStaffRecentChangeHistory = (id, actionName) => {
  return customFetch('/Staff/GetAssignedStaffHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ actionName, id }),
  });
};

export const getCompanyRecentChangeHistory = (id, actionName) => {
  return customFetch('/Default/GetAssignedCompanyHistory', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ actionName, id }),
  });
};

export const getCustomerEmail = id => {
  return customFetch('/MedicalService/GetCustomerEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getAssessmentInvoices = id => {
  return customFetch(`/medicalservice/GetAssessmentInvoices?assessmentId=${id}&orderAsc=true&orderBy=InvoiceDate`, {
    method: 'GET',
  });
};

export const uploadFile = body => {
  return customFetch('/File/UploadFileTemp', {
    method: 'POST',
    body,
  });
};

export const saveAssessment = body => {
  return customFetch('/MedicalService/PostAssessment_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const getAssessmentData = (sessionId, isConfirm = false) => {
  return customFetch('/Calendar/GetCalendar', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ calendarId: sessionId, isConfirm: isConfirm }),
  });
};

export const getDoctorRate = (dId, reportId) => {
  return customFetch('/MedicalDoctor/GetInfoTypeOfReport', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ doctorId: dId, typeOfReportId: reportId }),
  });
};

export const getConfirmDoctorRate = (dId, reportId) => {
  return customFetch('/MedicalService/GetGazettedRateOfDoctorRate?doctorId=' + dId + '&reportTypeId=' + reportId, {
    method: 'GET',
  });
};

export const checkCurrentRate = doctorId => {
  return customFetch(`/medicaldoctor/getdoctordetails?id=${doctorId}`, {
    method: 'GET',
  });
};

export const sendCMReminder = assessmentId => {
  return customFetch('/MedicalService/SendReminder', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ assessmentId }),
  });
};

export const viewHistoryReminder = assessmentId => {
  return customFetch('/MedicalService/AppointmentReminderHistory', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ id: assessmentId, recordType: 2 }),
  });
};

export const getDoctorInfo = doctorId => {
  return customFetch(`/api/doctorinfo/${doctorId}`, {
    method: 'GET',
  });
};

export const getInterProvider = () => {
  return customFetch('/MedicalService/GetInterpreterProvider', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};

export const getInterProviderCMs = (ClientId = 0) => {
  return customFetch('/MedicalService/GetCMsInterpreterProvider', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ClientId }),
  });
};

export const submitConversation = data => {
  return customFetch('/MedicalService/ConversationLog_Submit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};

export const sendAttendanceRemider = id => {
  return customFetch('/MedicalService/SendAttendanceRemider', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const getAllBlockedClients = doctorId => {
  return customFetch('/Company/GetAllBlockedClients', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId }),
  });
};

export const checkExcludeTypeOfService = (dId, cId, rId) => {
  return customFetch(
    '/MedicalDoctor/CheckExcludeTypeOfService?doctorId=' + dId + '&typeOfClaimId=' + cId + '&typeOfReportId=' + rId,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const getFullNameInCC = (type, keyword) => {
  return customFetch('/MedicalService/GetFullNameInCC?type=' + type + '&keyword=' + keyword, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const reSendUrgentReportRequest = (type, caseId, resendType) => {
  return customFetch('/MedicalService/ReSendUrgentReportRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: type, objId: caseId, resendType: resendType }),
  });
};

export const getUserUploadInfo = id => {
  return customFetch('/Manager/GetUserById', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: id }),
  });
};

export const getLookupIcareReport = (
  types = [
    'IMEReportCapacity',
    'IMEReportDiagnosis',
    'IMEReportLiability',
    'IMEReportTreatment',
    'IMEReportWPI',
    'IMEReportPsyWPI',
    'IMEReportIMC',
    'IcareServiceType',
  ],
) => {
  return customFetch('/Default/GetLookupConfigByTypes2', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ types: types }),
  });
};

export const sendICareCompletionRequest = (id, type) => {
  return customFetch('/MedicalService/SendICareCompletionRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id, type: type }),
  });
};

export const resendMultiAppointmentRequest = id => {
  return customFetch('/MedicalService/ResendMultiAppointmentRequest', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id }),
  });
};

export const viewFieldChangeHistory = (imeId, fieldName, startIndex = 1, maxRecords = 20) => {
  return customFetch(
    '/MedicalService/GetFieldUpdateHistory?assessmentId=' +
      imeId +
      '&fieldName=' +
      fieldName +
      '&startIndex=' +
      startIndex +
      '&maxRecords=' +
      maxRecords,
    {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    },
  );
};
