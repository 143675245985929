import { Modal } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Table, Button } from 'semantic-ui-react';

import { TableEmptyMessage, TableHeader } from '../../shared/table';
import store from './store';

@observer
class ModalCompanyHistory extends React.Component {
  columns = [
    { title: '#' },
    { title: 'Booking Client' },
    { title: 'Case Manager' },
    { title: 'Updated By' },
    { title: 'Updated Date' },
  ];

  @action handleClose = () => {
    store.isOpenModalCompanyHistory = false;
  };

  checkIsDateString = datesString => {
    const format = 'DD/MM/YYYY HH:mm';
    if (datesString) {
      if (datesString.startsWith('/Date')) {
        return moment(datesString).format(format);
      } else {
        return moment(datesString, 'DD/MM/YYYY HH:mm').format(format);
      }
    } else {
      return 'Invalid date';
    }
  };

  renderTableBody = () => {
    return store.assignCompanyHistory.map((assignment, idx) => (
      <Table.Row key={assignment.Id}>
        <Table.Cell>{idx + 1}</Table.Cell>
        <Table.Cell>{assignment.CompanyName}</Table.Cell>
        <Table.Cell>{assignment.CMFullName}</Table.Cell>
        <Table.Cell>{assignment.CreatedByName}</Table.Cell>
        <Table.Cell>{this.checkIsDateString(assignment.CreatedDate)}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { isOpenModalCompanyHistory } = store;

    return (
      <Modal
        title={'PIC Assignment History'}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered
        width={900}
        visible={isOpenModalCompanyHistory}
        footer={
          <Button className="negative" onClick={this.handleClose}>
            Close
          </Button>
        }
      >
        <div className="Modal-History">
          <Table sortable striped celled>
            <TableHeader columns={this.columns} />
            <Table.Body>
              {store.assignCompanyHistory.length ? (
                this.renderTableBody()
              ) : (
                <TableEmptyMessage colSpan={this.columns.length} />
              )}
            </Table.Body>
          </Table>
        </div>
      </Modal>
    );
  }
}

export default ModalCompanyHistory;
