import React from 'react';
import { Dropdown, Menu, notification } from 'antd';
import { Link } from 'react-router-dom';
import { Divider, Icon, Table } from 'semantic-ui-react';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { formatDate } from '../../../utils/functions';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../../shared/table';
import * as api from '../../../stores/api';
import ShortcutTable from './ShortcutTable';
import Store from './clinicalNoteStore';

import customFetch from '../../../utils/customFetch';
import { toJS } from 'mobx';
import moment from 'moment';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '@utils/constants';
import router from '@stores/router';
import dashboardStore from '@stores/dashboard';

const shortcutOptions = [
  {
    key: 0,
    title: 'Update Request Details',
    type: 'CaseDetails',
  },
  {
    key: 1,
    title: 'Update LOA',
    type: 'Paperwork',
  },
  {
    key: 2,
    title: 'View Retrieved Case Files',
    type: 'Paperwork',
  },
  {
    key: 3,
    title: 'View Invoices',
    type: 'Invoices',
  },
  {
    key: 4,
    title: 'View Case Correspondence',
    type: 'Corespondence',
  },
  {
    key: 5,
    title: 'Request Additional Records',
    type: '',
  },
];

class TablesIME extends React.Component {
  openCaseDetail = (id, caseNo, step = '') => () => {
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    //if (api.isAdminOrMagStaffOrAccounting()) {
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}&step=${step}`);
    });
    //}
    // iframe.forceOpenTab('AddEditClinicalNote', `?id=${id}&view=AddEditClinicalNote#${step}`, {
    //   id,
    //   view: 'AddEditClinicalNote',
    // });
  };

  handleDuplicate = clinicalNoteId => async () => {
    try {
      await customFetch('/ClinicalNote/DuplicateRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ clinicalNoteId }),
      });
      this.props.refetchTable();
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Data has been saved successfully.`,
        duration: 2,
      });
    } catch (e) {}
  };

  renderShortCutArray = record => {
    const options = shortcutOptions.map(i => {
      if (i.key === 5) {
        return {
          key: i.key,
          title: i.title,
          onClickShortCut: Store.toggleModal(true, {
            modalType: 'confirm',
            message:
              'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
            onOk: this.handleDuplicate(record.Id),
          }),
        };
      }
      return {
        key: i.key,
        title: i.title,
        onClickShortCut: this.openCaseDetail(record.Id, record.ClinicalNoteNumber, i.type),
      };
    });
    return options;
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  serviceAction = [5, 6, 13, 14];

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=CN&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'CN',
      view: 'ViewInvoice',
    });
  };

  openViewInvoice = id => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    localStorage.setItem('InvoiceCaseId', caseNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${id}&view=ViewInvoice`, {
      id,
      view: 'ViewInvoice',
    });
  };

  openModal = options => {
    return Store.toggleModal(true, options)();
  };

  handleDuplicate = clinicalNoteId => async () => {
    try {
      await customFetch('/ClinicalNote/DuplicateRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ clinicalNoteId }),
      });
      this.props.refetchTable();
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Data has been saved successfully.`,
        duration: 2,
      });
    } catch (e) {}
  };

  handleDelete = (id, record) => {
    if (record.CaseStatus === api.crStatusEnum.Cancelled_No_Charge) {
      Store.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to delete this clinical note?',
        onOk: async () => {
          await customFetch(`/api/ClinicalNote/${id}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'DELETE',
          });
          this.props.refetchTable();
        },
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  onInsertCaseNoToImsHandler = caseNo => () => {
    PubSub.publish(PubSubTopics.SelectCaseNo, caseNo);
    router.history.push('/view/ims');
  };

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(id, record.ClinicalNoteNumber, 0)}>Update Claim Details</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.ClinicalNoteNumber, 1)}>Update Case Documents</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.ClinicalNoteNumber, 1)}>
            Update Retrieved Clinical Records
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.ClinicalNoteNumber, 3)}>Update Provider Payment</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, record.ClinicalNoteNumber, 4)}>Update Correspondence</Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={this.openPrintCase(`/ClinicalNote/PrintLetterOfConfirmation?clinicalNoteId=${id}&printNow=true`)}
          >
            Print Service Request
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {/* {this.serviceAction.indexOf(record.CaseStatus) === -1 && ( */}
          <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>
            <Icon name="plus" size="small" />
            Generate Invoice
          </Menu.Item>
          {/* )} */}
          {!!record.InvoiceId && (
            <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
          )}
          {!!record.InvoiceId && <Menu.Item onClick={this.openViewInvoice(record.InvoiceId)}>View Invoice</Menu.Item>}
          {!!record.InvoiceId && (
            <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print Invoice
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              this.openModal({
                modalType: 'confirm',
                message:
                  'This will add a new booking with the same Client and Claimant information. Do you want to proceed?',
                onOk: this.handleDuplicate(id),
              })
            }
          >
            Rebook this Case
          </Menu.Item>
          {api.isAdmin() && <Menu.Item onClick={() => this.handleDelete(id, record)}>Delete this Case</Menu.Item>}
          {api.isAdminOrMagStaffOrAccounting() && (
            <Menu.Item onClick={this.onInsertCaseNoToImsHandler(record.ClinicalNoteNumber)}>
              Link Inquiry to this Case
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  handleOpenCaseProgress = (id, caseNo) => {
    return Store.toggleModal(true, {
      modalType: 'crCaseProgress',
      id,
      caseNo,
    })();
  };

  render() {
    const { data, curPage, totalPage, sumItem, searchAttachment } = this.props;

    var columns = [
      { title: '' },
      { title: 'Case No' },
      { title: 'Request Date' },
      { title: 'Claim No' },
      { title: 'Type Of Claim' },
      { title: 'Claimant' },
      { title: 'Client' },
      { title: 'Case Manager' },
      { title: 'CR Provider 1' },
      { title: 'CR Provider 2' },
      { title: 'Case Progress' },
      { title: 'Status Date' },
      { title: 'Created By' },
    ];

    if (searchAttachment) {
      columns = [
        ...columns,
        {
          title: 'Case Documents',
          style: { minWidth: '500px', maxWidth: '500px' },
        },
        {
          title: 'Attachment Type',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
        {
          title: 'Uploaded Date',
          style: { minWidth: '250px', maxWidth: '250px' },
        },
      ];
    }

    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {data.length ? (
            data.map(record => (
              <Table.Row key={record.Id}>
                <Table.Cell className="Cell-action">
                  {(api.isDoctor() || api.isCaseManager() || api.isTypist()) && (
                    <ShortcutTable shortcutOptions={this.renderShortCutArray(record)} />
                  )}
                  {api.isAdminOrMagStaffOrAccounting() && this.renderTableActions(record.Id, record)}
                </Table.Cell>
                <Table.Cell className="Cell-nowrap Cell-link">
                  <Link to="#" onClick={this.openCaseDetail(record.Id, record.ClinicalNoteNumber)}>
                    {record.ClinicalNoteNumber}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {record.RequestDate
                    ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                <Table.Cell>{record.ClaimaNo}</Table.Cell>
                <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
                <Table.Cell>{record.Claimant}</Table.Cell>
                <Table.Cell>{record.Client}</Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">
                    {record.CaseManagerId > 0 ? `(${record.CaseManagerId}) ` : ''}
                    {record.CaseManager}
                  </h5>
                  <div>{record.CMEmail}</div>
                  <div>{record.CMMobilePhone}</div>
                </Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">
                    {record.DoctorId > 0 ? `(${record.DoctorId}) ` : ``}
                    {record.ClinicName}
                  </h5>
                  <div>{record.ClinicEmail}</div>
                  <div>{record.ClinicPhone}</div>
                </Table.Cell>
                <Table.Cell>
                  <h5 className="ma-0">{record.DoctorName}</h5>
                  <div>{record.DoctorEmail}</div>
                  <div>{record.DoctorPhone}</div>
                </Table.Cell>
                <Table.Cell
                  selectable
                  onClick={() => this.handleOpenCaseProgress(record.Id, record.ClinicalNoteNumber)}
                  className="Cell-link"
                >
                  <Link onClick={() => this.handleOpenCaseProgress(record.Id, record.ClinicalNoteNumber)} to="#">
                    {record.StatusText}
                  </Link>
                </Table.Cell>

                <Table.Cell>
                  {record.StatusDate
                    ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                    : 'N/A'}
                </Table.Cell>
                <Table.Cell>{record.CreatedByName}</Table.Cell>
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell className="Cell-link">
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx} style={{ minWidth: '500px', maxWidth: '500px' }}>
                        <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                          {item.FileTitle}
                        </a>
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.ReportTypeName}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
                {searchAttachment && Array.isArray(toJS(record.ListCaseAttachment)) ? (
                  <Table.Cell>
                    {record.ListCaseAttachment.map((item, idx) => (
                      <div key={idx}>
                        {item.UplodatedDate
                          ? moment(formatDate(item.UplodatedDate, true), 'DD MMM YYYY, HH:mm').format(
                              'DD/MM/YYYY - HH:mm',
                            )
                          : 'N/A'}
                        {idx < record.ListCaseAttachment.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage || 1}
          totalPage={totalPage || 1}
          onPageClick={this.handlePageClick}
          totalItems={sumItem || 0}
        />
      </Table>
    );
  }
}

export default TablesIME;
