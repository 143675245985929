import './index.scss';
import { observer } from 'mobx-react';
import React from 'react';
import moment from 'moment';
import { toJS } from 'mobx';
import { Checkbox, Icon } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import TableToolbar from '../../shared/tableToolbar';
import ImagingStore from './ImagingStore';
import iframe from '../../dashboard-routes/Iframe/iframeData';

const taskLabels = [
  { text: `All` },
  { text: `Cancelled No Fee` },
  { text: `Imaging Requested` },
  { text: `Report Completed` },
  { text: `Service Billed` },
];

@observer
class ImagingToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ``,
    };
  }

  openCaseCreate = () => () => {
    var id = 0;
    iframe.forceOpenTab('PostMRI', `?id=${id}&view=PostMRI`, {
      id,
      view: 'PostMRI',
    });
  };

  componentDidMount() {
    ImagingStore.fetchMRIStaff(true);
  }

  handleSearchTodayBooking = () => {
    ImagingStore.refetch(
      {
        fromTo: moment().format('DD/MM/YYYY') + ' - ',
        showBookingToday: true,
        curPage: 1,
      },
      true,
    );
  };

  renderClaimantOptions = () => {
    const array = [];
    const data = toJS(ImagingStore.dataClaimant.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: '', FullName: `` });
    return array
      .filter(opt => opt.FullName)
      .map(opt => ({
        text: `${opt.FullName}`,
        value: `${opt.Id} Keyword:${opt.FullName}`,
      }));
  };
  renderClaimantText = value => {
    if (value === '') {
      return '';
    } else {
      const array = [];
      const data = toJS(ImagingStore.dataClaimant.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: '', FullName: `` });
      let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName}`);
      if (labels[0]) return labels[0];
      return `All`;
    }
  };

  handleValueToKeyword = value => {
    let index = value.indexOf(`Keyword:`) + 8;
    return value.slice(index, value.length);
  };

  handleValueToId = value => {
    let index = value.indexOf(`Keyword:`) - 1;
    return value.slice(0, index);
  };

  handleFilterClaimant = (_, { value }) => {
    if (value === ``) {
      ImagingStore.refetch({ keyword: ``, claimantId: ``, curPage: 1 }, true);
      this.setState({ searchText: `` });
    } else {
      this.setState({ searchText: this.handleValueToKeyword(value) });
      ImagingStore.refetch(
        {
          keyword: this.handleValueToKeyword(value),
          claimantId: this.handleValueToId(value),
          curPage: 1,
        },
        true,
      );
    }
  };

  onSearchChange = e => {
    this.setState({ searchText: e.target.value });
    ImagingStore.refetchClaimt({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
    });
  };

  onActiveSearchClaimant = () => {
    const { searchClaimantOnly, keyword } = ImagingStore;
    if (!keyword) {
      ImagingStore.setFieldsValue({
        searchClaimantOnly: !searchClaimantOnly,
        keyword: ``,
      });
      ImagingStore.refetch({}, true);
    } else {
      ImagingStore.setFieldsValue({
        searchClaimantOnly: !searchClaimantOnly,
        keyword: ``,
      });
    }
  };

  handleReset = () => {
    ImagingStore.resetStore();
    ImagingStore.refetch({}, true);
  };

  handleSubmit = event => {
    event.preventDefault();
    const { keyword } = ImagingStore;
    ImagingStore.refetch({ claimant: { isFreeText: true, FullName: keyword }, curPage: 1 }, true);
  };

  formatDate = date => {
    if (!date) return ``;
    return moment(date).format('YYYY-MM-DD');
  };

  handleChangeSearchDropdown = e => {
    ImagingStore.refetchClaimt({
      keywordClaimant: e.target.value,
      keyword: e.target.value,
      numberSearch: 30,
    });
  };

  renderSearch = () => {
    const array = [];
    const data = toJS(ImagingStore.dataClaimant.itemList);
    for (var i = 0; i < data.length; i++) {
      if (data[i].FullName) {
        array.push(data[i]);
      }
    }
    array.unshift({ Id: 'All', FullName: `` });
    return array.map((opt, index) => ({
      text: `${opt.Id === `All` ? `All` : opt.FullName}`,
      value: JSON.stringify({
        id: opt.Id,
        value: `${opt.FullName}`,
        key: `${opt.Id}${index}${opt.FullName}`,
      }),
    }));
  };

  checkIsJSON = json => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleSearchList = (_, { value }) => {
    const claim = this.checkIsJSON(value) ? JSON.parse(value) : value;
    if (claim.id === `All`) {
      ImagingStore.refetch(
        {
          keyword: ``,
          curPage: 1,
        },
        true,
      );
      ImagingStore.resetSearch();
    } else {
      ImagingStore.refetch(
        {
          keyword: claim.value,
          curPage: 1,
          numberSearch: 30,
        },
        true,
      );
    }
  };

  openModal = options => {
    return ImagingStore.toggleModal(true, options);
  };

  renderFilterOptions = () => {
    return taskLabels.map(opt => ({
      text: opt.text,
      value: opt.text,
    }));
  };

  handleFilter = (_, { value }) => {
    ImagingStore.refetch({ filter: value, curPage: 1 }, true);
  };

  renderActionOptions = () => {
    const array = [];
    const data = toJS(ImagingStore.dataStaff.itemList);
    for (var i = 0; i < data.length; i++) {
      array.push(data[i]);
    }
    array.unshift({ Id: 'All', FullName: `All` });
    return array.map(opt => ({
      text: `${opt.FullName}`,
      value: opt.Id,
    }));
  };
  renderActionText = value => {
    if (value === '') {
      return 'All';
    } else {
      const array = [];
      const data = toJS(ImagingStore.dataStaff.itemList);
      for (var i = 0; i < data.length; i++) {
        array.push(data[i]);
      }
      array.unshift({ Id: 'All', FullName: `All` });
      let labels = array.filter(opt => opt.Id === value).map(opt => `${opt.FullName}`);
      if (labels[0]) return labels[0];
      return `All`;
    }
  };

  handleFilterAction = (_, { value }) => {
    if (value === `All`) {
      ImagingStore.refetch({ actionRequiredById: ``, curPage: 1 }, true);
    } else {
      ImagingStore.refetch({ actionRequiredById: value, curPage: 1 }, true);
    }
  };

  handleExportSearchResult = () => {
    const { fromDate, toDate } = ImagingStore.getDateRange();
    const { actionRequiredById, filter } = ImagingStore;
    const from = fromDate ? moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : null;
    const to = toDate ? moment(toDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : null;
    const searchParams = Object.assign(
      {},
      {
        ActionType: 'NONE',
        CurPage: ImagingStore.curPage,
        FilterType: ImagingStore.filter,
        From: from,
        Keyword: ImagingStore.keyword,
        NumItemPerPage: ImagingStore.pageSize,
        SearchClaimantOnly: ImagingStore.searchClaimantOnly,
        SortBy: ImagingStore.sortKey,
        SortDirection: ImagingStore.sortDirection.replace('ending', '').toUpperCase(),
        To: to,
      },
      {
        GetAllRecords: true,
      },
    );
    var qs = Object.entries(searchParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    if (searchParams.Keyword || from || filter !== `All` || actionRequiredById) {
      window.open('/MRI/ExportSearchResult?' + qs, '_blank');
    } else {
      ImagingStore.toggleModal(true, {
        modalType: `confirm`,
        message: `You need to search or filter first before exporting results.`,
      })();
    }
  };

  handleReset = () => {
    ImagingStore.resetStore();
    ImagingStore.refetch({}, true);
  };

  handleRefresh = () => {
    ImagingStore.refetch({}, true);
  };

  handleChangeFromTo = (_, { value }) => {
    ImagingStore.setFieldsValue({ fromTo: value, showBookingToday: false });
  };

  handleScroll = event => {
    const target = event.target;
    const number = ImagingStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      ImagingStore.refetchClaimt({ numberSearch: number + 20 });
    }
  };

  render() {
    const {
      loading,
      filter,
      searchClaimantOnly,
      keyword,
      fromTo,
      totalItems,
      actionRequiredById,
      loadingClaimant,
    } = ImagingStore;

    return (
      <form className="file-review-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            {/* <Checkbox
              label={`Search Claimant Only`}
              checked={searchClaimantOnly}
              onChange={this.onActiveSearchClaimant}
              disabled={loading}
            /> */}
            {!searchClaimantOnly ? (
              <TableToolbar.Input
                placeholder={'Search case manager'}
                value={keyword}
                onChange={ImagingStore.handleSearchChange}
                disabled={loading}
                style={{ border: '1px solid black', marginLeft: '5px' }}
              />
            ) : (
              <TableToolbar.SearchDropdown
                loading={loadingClaimant}
                allowAdditions
                additionLabel=""
                onScroll={this.handleScroll}
                selectOnNavigation={false}
                value={keyword}
                style={{ marginLeft: '5px', border: '1px solid black' }}
                onSearchChange={this.handleChangeSearchDropdown}
                options={this.renderSearch()}
                onChange={this.handleSearchList}
                className="Toolbar-input"
                disabled={loading}
                clearable
                icon={!keyword ? <Icon name="dropdown" /> : <Icon name="delete" />}
              />
            )}
            <TableToolbar.RangePicker
              value={fromTo}
              onChange={this.handleChangeFromTo}
              className="Toolbar-range-picker"
              disabled={loading}
              style={{ border: '1px solid black' }}
            />
            <TableToolbar.SubmitButton
              className="Toolbar-highlight-button px-2"
              onClick={this.handleSubmit}
              disabled={loading}
            >
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative"
              disabled={loading}
              onClick={this.handleReset}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue"
              style={{ marginLeft: 5 }}
              onClick={this.handleRefresh}
              disabled={loading}
            >
              Refresh
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <span style={{ marginRight: `10px` }} className="nowrap">
              <p>
                <b>Total Cases: {totalItems}</b>
              </p>
            </span>
            <TableToolbar.Filter
              label={`Cases by Current Status`}
              disabled={loading}
              value={filter}
              options={this.renderFilterOptions()}
              onChange={this.handleFilter}
              style={{ border: '1px solid black' }}
            />
            <TableToolbar.Filter
              disabled={loading}
              label={`Action Required`}
              value={this.renderActionText(actionRequiredById)}
              options={this.renderActionOptions()}
              onChange={this.handleFilterAction}
              style={{ border: '1px solid black' }}
            />
          </TableToolbar.Left>
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Container>
            <TableToolbar.Left as="div">
              {!api.isOnlySearch() && <TableToolbar.HighLightButton
                className="positive"
                disabled={loading}
                style={{ marginLeft: '5px' }}
                onClick={this.openCaseCreate()}
              >
                New Case
              </TableToolbar.HighLightButton>}

              {api.isAdminOrMagStaffOrAccounting() && (
                <TableToolbar.HighLightButton
                  className="positive"
                  disabled={loading}
                  onClick={this.handleExportSearchResult}
                  style={{ marginLeft: '5px' }}
                >
                  Export Searches
                </TableToolbar.HighLightButton>
              )}
              <TableToolbar.HighLightButton
                disabled={loading}
                className="blue"
                onClick={this.handleSearchTodayBooking}
                style={{ marginLeft: '5px' }}
              >
                Search Today's Booking
              </TableToolbar.HighLightButton>
            </TableToolbar.Left>
            <TableToolbar.Right />
          </TableToolbar.Container>
        </TableToolbar.Container>
      </form>
    );
  }
}

export default ImagingToolbar;
