import { Dropdown, Menu, notification } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table, Divider } from 'semantic-ui-react';

import dashboardStore from '@stores/dashboard';
import { formatDate, OpenCaseDetails } from '../../../utils/functions';
import customFetch from '../../../utils/customFetch';
import ClinicalRecordStore from './ClinicalRecordStore';
import * as api from '../../../stores/api';
import { TableEmptyMessage, TableFooterPagination, TableHeader } from '../../shared/table';
import FormatPhoneNumber from '../../shared/FormatPhoneNumber';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import moment from 'moment';

const columns = [
  { title: '' },
  { title: 'Case No', sortKey: 'ClinicalNoteNumber' },
  { title: 'Case Status', sortKey: 'CaseStatus' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Action Required' },
  { title: 'Claim No', sortKey: 'ClaimaNo' },
  { title: 'Type of Claim', sortKey: 'TypeOfClaimnt' },
  { title: 'CR Provider', sortKey: 'ClinicName' },
  { title: 'Claimant Name', sortKey: 'ClinicName' },
  { title: 'Client', sortKey: 'Client' },
  { title: 'Booking CM', sortKey: 'CaseManager' },
  // { title: 'CR Provider 2', sortKey: 'DoctorName' },
  { title: 'Last Call', sortKey: 'LastPhoneCorrespondence' },
  { title: 'Next Task' },
  { title: 'Next Task PIC' },
  { title: 'Created By', sortKey: 'CreatedBy' },
];

const columnsCM = [
  { title: '' },
  { title: 'Case No', sortKey: 'ClinicalNoteNumber' },
  { title: 'Case Progress' },
  { title: 'Status Date', sortKey: 'StatusDate' },
  { title: 'Request Date', sortKey: 'RequestDate' },
  { title: 'Claim No', sortKey: 'ClaimaNo' },
  { title: 'Type Of Claim' },
  { title: 'Claimant', sortKey: 'Claimant' },
  { title: 'Client', sortKey: 'ClientId' },
  { title: 'Case Manager', sortKey: 'CaseManagerId' },
  { title: 'CR Provider', sortKey: 'ClinicEmail' },
  // { title: 'CR Provider 2', sortKey: 'DoctorEmail' },
];

@observer
class ClinicalRecordTable extends React.Component {
  openModal = options => {
    return ClinicalRecordStore.toggleModal(true, options);
  };

  handlePageClick = page => {
    ClinicalRecordStore.refetch({ curPage: page });
  };

  getPICName = ({ CaseStatus, ...record }) => {
    if (record.CancellationStatus !== 1 && record.CancellationStatus !== 2) {
      switch (CaseStatus) {
        case 0:
          return record.PICRequestSentProvider;
        case 1:
          return record.PICPrepaymentRequired;
        case 2:
          return record.PICPrepaymentPaid;
        case 3:
          return record.PICReceivedFromProvider;
        case 4:
          return record.PICServiceBilled;
        default:
          return '';
      }
    } else {
      if (record.CancellationStatus === 2) {
        return record.PICCancelled;
      }
    }
    return '';
  };

  serviceAction = [6, 5, 13, 14];

  renderStepForNewUI = type => {
    switch (type) {
      case 'CaseDetails':
        return 0;
      case 'Paperwork':
        return 1;
      case 'Cancellation':
        return 2;

      case 'Invoices':
      case 'ServicePrepaymentSection':
        return 3;
      case 'Corespondence':
        return 4;
      case 'ActionRequired':
        return 0;
      default:
        return typeof type === 'string' ? 0 : type;
    }
  };

  openCaseUpdate = (id, name, type) => () => {
    const caseNo = `Case ${name}`;
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}&step=${this.renderStepForNewUI(type)}`);
    });
  };

  convertEmailCreatedAt = email => {
    return email.replace(/^(.+)@(.+)$/g, '$1');
  };

  renderTableBody = () => {
    return ClinicalRecordStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActions(Id, record)}</Table.Cell>
        <Table.Cell
          className="Cell-nowrap Cell-link"
          onClick={this.openCaseUpdate(Id, record.ClinicalNoteNumber, 'CaseDetails')}
          selectable
        >
          <Link to="#" onClick={this.openCaseUpdate(Id, record.ClinicalNoteNumber, 'CaseDetails')}>
            {record.ClinicalNoteNumber}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.ClinicalNoteNumber,
              modalType: 'caseProgress',
              status: record.CaseProgressText,
            })}
          >
            {record.StatusText}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate
            ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell
          className={cx(`${record.ActionRequiredType === 1 ? `Cell-link Cell-Action-Required` : `Cell-link`}`)}
        >
          <Link
            to="#"
            onClick={this.openModal({
              modalType: 'notes',
              id: Id,
              caseNo: record.ClinicalNoteNumber,
            })}
            style={{ color: `red` }}
          >
            {record.ActionRequired === 0 ? (
              <span className="link-add-action">(Add Action)</span>
            ) : (
              <span className={`${record.ActionRequiredType === 1 ? 'text-danger' : 'text-success'}`}>
                {record.ActionRequiredType === 0
                  ? '(Add Action)'
                  : record.ActionRequiredType === 1
                  ? '(Action Required)'
                  : '(View Actions)'}
              </span>
            )}
          </Link>
          {record.ActionOverview && (
            <div>
              {record.ActionOverview}
              <br />
              <b>{this.convertEmailCreatedAt(record.ActionRequiredUserName)}</b>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>{record.ClaimaNo}</Table.Cell>
        <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          {record.ClinicId && (
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'clinic',
                  id: record.ClinicId,
                  localStorageName: 'ClinicName',
                  localStorageValue: record.ClinicName,
                })
              }
            >
              ({record.ClinicId}) {record.ClinicName} <br></br>
            </Link>
          )}
          {record.ClinicEmail}
          <br />
          <FormatPhoneNumber phoneNumber={record.ClinicPhone} />
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'claimant',
                id: record.ClaimantId,
                localStorageName: 'ClaimantName',
                localStorageValue: record.Claimant,
              })
            }
          >
            ({record.ClaimantId}) {record.Claimant}
          </Link>
          <br /> DOB:{' '}
          {record.ClaimantDOB ? moment(formatDate(record.ClaimantDOB), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
          <br />
          <FormatPhoneNumber
            officePhoneNumber={record.ClaimantHomePhone}
            mobilePhoneNumber={record.ClaimantTelePhone}
          />
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className="Cell-link Cell-nowrap">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'cm',
                id: record.CaseManagerId,
                localStorageName: 'CmName',
                localStorageValue: record.CaseManager,
              })
            }
          >
            {record.CaseManagerId > 0 ? `(${record.CaseManagerId})` : ``} {record.CaseManager}
          </Link>
          {record.CMEmail && <br />}
          {record.CMEmail}
          {record.CMMobilePhone && <br />}
          <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
        </Table.Cell>

        {/* <Table.Cell className="Cell-nowrap Cell-link">
          <Link
            to="#"
            onClick={() =>
              OpenCaseDetails({
                tabName: 'specialist',
                id: record.DoctorId,
                localStorageName: 'DoctorName',
                localStorageValue: record.DoctorName,
              })
            }
          >
            {record.DoctorId > 0 ? `(${record.DoctorId})` : ``} {record.DoctorName}{' '}
            {`${record.DoctorRegisterOption ? `(${record.DoctorRegisterOption})` : ''}`}
          </Link>
          {record.DoctorEmail && <br />}
          {record.DoctorEmail}
          {record.DoctorPhone && <br />}
          <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
        </Table.Cell> */}
        <Table.Cell className="Cell-nowrap">
          {record.LastPhoneCorrespondence
            ? moment(formatDate(record.LastPhoneCorrespondence), 'DD MMM YYYY').format('DD/MM/YYYY')
            : 'N/A'}
        </Table.Cell>
        <Table.Cell className="Cell-nowrap">{record.NextStatusText}</Table.Cell>
        <Table.Cell>{this.getPICName(record)}</Table.Cell>
        <Table.Cell>{this.convertEmailCreatedAt(record.CreatedBy)}</Table.Cell>
      </Table.Row>
    ));
  };

  openCaseDetail = (id, step, caseNo) => () => {
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    dashboardStore.close('/view/add-edit-clinical-notes-2');
    setTimeout(() => {
      dashboardStore.open(`/view/add-edit-clinical-notes-2?id=${id}&step=${this.renderStepForNewUI(step)}`);
    });
  };

  renderTableBodyCM = () => {
    return ClinicalRecordStore.data.itemList.map(({ Id, ...record }) => (
      <Table.Row key={Id}>
        <Table.Cell className="Cell-actions">{this.renderTableActionsCM(Id, record)}</Table.Cell>
        <Table.Cell className="Cell-nowrap Cell-link">
          <Link to="#" onClick={this.openCaseDetail(Id, '', record.ClinicalNoteNumber)}>
            {record.ClinicalNoteNumber}
          </Link>
        </Table.Cell>
        <Table.Cell className="Cell-link">
          <Link
            to="#"
            onClick={this.openModal({
              id: Id,
              caseNo: record.ClinicalNoteNumber,
              modalType: 'caseProgress',
            })}
          >
            {record.StatusText}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {record.StatusDate ? moment(formatDate(record.StatusDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>
          {record.RequestDate ? moment(formatDate(record.RequestDate), 'DD MMM YYYY').format('DD/MM/YYYY') : 'N/A'}
        </Table.Cell>
        <Table.Cell>{record.ClaimaNo}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>{record.TypeOfClaimnt}</Table.Cell>
        <Table.Cell style={{ minWidth: `9em` }}>
          ({record.ClaimantId}) {record.Claimant}
        </Table.Cell>
        <Table.Cell className={cx(`${record.Client && `Cell-Client-with-mail`}`)}>
          <div className="Cell-link Cell-nowrap">
            <Link
              to="#"
              onClick={() =>
                OpenCaseDetails({
                  tabName: 'client',
                  id: record.ClientId,
                  localStorageName: 'CompanyName',
                  localStorageValue: record.Client,
                })
              }
            >
              ({record.ClientId}) {record.Client}
            </Link>
            {record.BusinessType && <div>{record.BusinessType}</div>}
            {record.ClientState && <div>{record.ClientState}</div>}
          </div>
        </Table.Cell>
        <Table.Cell className={cx(`${record.CMEmail && `Cell-Client-with-mail`}`)}>
          <h5 className="ma-0">{record.CaseManager}</h5>
          <div>{record.CMEmail}</div>
          <div>
            <FormatPhoneNumber phoneNumber={record.CMMobilePhone} />
          </div>
        </Table.Cell>
        <Table.Cell>
          <h5 className="ma-0">{record.ClinicName}</h5>
          <div>{record.ClinicEmail}</div>
          <div>
            <FormatPhoneNumber phoneNumber={record.ClinicPhone} />
          </div>
        </Table.Cell>
        {/* <Table.Cell>
          <h5 className="ma-0">{record.DoctorName}</h5>
          <div>{record.DoctorEmail}</div>
          <div>
            <FormatPhoneNumber officePhoneNumber={record.DoctorOfficePhone} mobilePhoneNumber={record.DoctorPhone} />
          </div>
        </Table.Cell> */}
      </Table.Row>
    ));
  };

  handleAddCopyInvoice = (id, invoiceId) => () => {
    const caseNo = `INV-${(id + '').padStart(6, 0)}`;
    if (invoiceId) {
      localStorage.setItem('InvoiceCaseId', caseNo);
    } else {
      localStorage.removeItem(`InvoiceCaseId`);
    }
    iframe.forceOpenTab('ViewInvoice', `?id=${invoiceId}&mId=${id}&type=CN&view=ViewInvoice`, {
      id: invoiceId,
      mId: id,
      type: 'CN',
      view: 'ViewInvoice',
    });
  };

  handleDuplicate = clinicalNoteId => async () => {
    ClinicalRecordStore.setFieldsValue({ loading: true });
    try {
      await customFetch('/ClinicalNote/DuplicateRecord', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ clinicalNoteId }),
      });
      await ClinicalRecordStore.refetch({}, true);
      notification.destroy();
      notification.success({
        message: 'Success',
        description: `Data has been saved successfully.`,
        duration: 2,
      });
    } finally {
      ClinicalRecordStore.setFieldsValue({ loading: false });
    }
  };

  handleDelete = (id, record) => {
    if (record.CaseStatus === api.crStatusEnum.Cancelled_No_Charge) {
      ClinicalRecordStore.toggleModal(true, {
        modalType: 'confirm',
        message: 'Do you want to delete this clinical note?',
        onOk: async () => {
          try {
            ClinicalRecordStore.setFieldsValue({ loading: true });
            const response = await customFetch(`/api/ClinicalNote/${id}`, {
              headers: { 'Content-Type': 'application/json' },
              method: 'DELETE',
            });
            if (response.status === 'success') {
              ClinicalRecordStore.refetch({}, true);
            }
          } catch (e) {
          } finally {
            ClinicalRecordStore.setFieldsValue({ loading: false });
          }
        },
      })();
    } else {
      notification.error({
        message: 'Error',
        description: 'Please cancel (no-fee) this case before deleting.',
      });
    }
  };

  openViewInvoice = record => () => {
    localStorage.setItem('InvoiceCaseId', 'INV-' + record.InvoiceNo);
    iframe.forceOpenTab('ViewInvoice', `?id=${record.InvoiceId}&view=ViewInvoice`, {
      id: record.InvoiceId,
      view: 'ViewInvoice',
    });
  };

  renderTableActionsCM = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseDetail(id, `CaseDetails`, record.ClinicalNoteNumber)}>
            Update Request Details
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record.ClinicalNoteNumber)}>Update LOA</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, `Paperwork`, record.ClinicalNoteNumber)}>
            View Retrieved Case Files
          </Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, `Invoices`, record.ClinicalNoteNumber)}>View Invoices</Menu.Item>
          <Menu.Item onClick={this.openCaseDetail(id, `Corespondence`, record.ClinicalNoteNumber)}>
            View Case Correspondence
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message:
                'Duplicate a case will copy claim details(client, booking CM, claimant and claim no) to a new case. Do you want to continue?',
              onOk: this.handleDuplicate(id),
            })}
          >
            Request Additional Records
          </Menu.Item>
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  renderTableActions = (id, record) => (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={this.openCaseUpdate(id, record.ClinicalNoteNumber, 'CaseDetails')}>
            Update Claim Details
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUpdate(id, record.ClinicalNoteNumber, 'Paperwork')}>
            Update Case Documents
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUpdate(id, record.ClinicalNoteNumber, 'Paperwork')}>
            Update Retrieved Clinical Records
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUpdate(id, record.ClinicalNoteNumber, 'ServicePrepaymentSection')}>
            Update Provider Payment
          </Menu.Item>
          <Menu.Item onClick={this.openCaseUpdate(id, record.ClinicalNoteNumber, 'Corespondence')}>
            Update Correspondence
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={this.openPrintCase(`/ClinicalNote/PrintLetterOfConfirmation?clinicalNoteId=${id}&printNow=true`)}
          >
            Print Service Request
          </Menu.Item>
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {!api.isCaseManager() && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to booking CM?',
                onOk: this.handleSendStatusUpdate(id, 0),
              })}
            >
              Send Status Update (CMs) (MAIL-105)
            </Menu.Item>
          )}
          {!api.isCaseManager() && record.DoctorName && (
            <Menu.Item
              onClick={this.openModal({
                modalType: 'confirm',
                message: 'Do you want to send report status update to assessing specialist?',
                onOk: this.handleSendStatusUpdate(id, 1),
              })}
            >
              Send Status Update (Specialists) (MAIL-106)
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          {/* {this.serviceAction.indexOf(record.CaseStatus) === -1 && ( */}
          <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>
            <Icon name="plus" size="small" />
            Generate Invoice
          </Menu.Item>
          {/* )} */}
          {!!record.InvoiceId && (
            <Menu.Item onClick={this.handleAddCopyInvoice(id, record.InvoiceId)}>Copy Invoice</Menu.Item>
          )}
          {!!record.InvoiceId && <Menu.Item onClick={this.openViewInvoice(record)}>View Invoice</Menu.Item>}
          {!!record.InvoiceId && (
            <Menu.Item onClick={this.openPrintCase(`/Invoice/ViewBilling?invoiceId=${record.InvoiceId}`)}>
              Print Invoice
            </Menu.Item>
          )}
          <Menu.Item style={{ pointerEvents: 'none' }}>
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
          <Menu.Item
            onClick={this.openModal({
              modalType: 'confirm',
              message:
                'This will add a new booking with the same Client and Claimant information. Do you want to proceed?',
              onOk: this.handleDuplicate(id),
            })}
          >
            Request Additional Records
          </Menu.Item>
          {/* {api.isAdmin() && <Menu.Item onClick={() => this.handleDelete(id, record)}>Delete this Case</Menu.Item>} */}
        </Menu>
      }
    >
      <Icon name="ellipsis vertical" />
    </Dropdown>
  );

  handleSendStatusUpdate = (id, sentTo) => async () => {
    try {
      await customFetch('/ClinicalNote/SendReportStatusAsync', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ id, type: 6, sentTo }),
      }).then(res => {
        if (res.status === 'success') {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: `Report was sent`,
            duration: 2,
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Fail',
            description: `Report can not sent`,
            duration: 2,
          });
        }
      });
    } finally {
    }
  };

  openPrintCase = uri => () => {
    window.open(window.location.origin + uri, '_blank');
  };

  handleUpdateProviderPayment = (id, name) => () => {
    const type = `ServicePrepaymentSection`;
    const caseNo = `Case ${name}`;
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    iframe.forceOpenTab('AddEditClinicalNote', `?id=${id}&view=AddEditClinicalNote#${type}`, {
      id,
      view: 'AddEditClinicalNote',
    });
  };

  handleViewAddCorrespondence = (id, name) => () => {
    const type = `Corespondence`;
    const caseNo = `Case ${name}`;
    localStorage.setItem('ClinicalRecordCaseId', caseNo);
    iframe.forceOpenTab('AddEditClinicalNote', `?id=${id}&view=AddEditClinicalNote#${type}`, {
      id,
      view: 'AddEditClinicalNote',
    });
  };

  render() {
    const { loading, data } = ClinicalRecordStore;
    const colSpan = api.isAdmin ? columns.length : columnsCM.length;

    return (
      <Table sortable striped celled className={cx({ disabled: loading })} style={{ marginTop: '0' }}>
        <TableHeader
          columns={api.isAdminOrMagStaffOrAccounting() ? columns : columnsCM}
          sortColumn={ClinicalRecordStore.sortColumn}
          sortDirection={ClinicalRecordStore.sortDirection}
          onSort={ClinicalRecordStore.handleSort}
        />
        <Table.Body>
          {data.itemList.length ? (
            api.isAdminOrMagStaffOrAccounting() ? (
              this.renderTableBody()
            ) : (
              this.renderTableBodyCM()
            )
          ) : (
            <TableEmptyMessage colSpan={colSpan} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={colSpan}
          currentPage={ClinicalRecordStore.curPage}
          totalPage={ClinicalRecordStore.totalPage}
          totalItems={ClinicalRecordStore.totalItems}
          onPageClick={this.handlePageClick}
        />
      </Table>
    );
  }
}

export default ClinicalRecordTable;
