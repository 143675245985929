import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Modal from '../../shared/Modal/';

function ModalAlert({ open, onCancel, modalParams }) {
  const { message } = modalParams;

  const renderConfirmMessage = () => (
    <div className="modal-confirm-message">
      <Icon name="warning sign" color="yellow" />
      {message}
    </div>
  );

  return (
    <Modal
      visible={open}
      width={600}
      onCancel={onCancel}
      footer={
        <React.Fragment>
          <Button color="red" onClick={onCancel}>
            Close
          </Button>
        </React.Fragment>
      }
    >
      {renderConfirmMessage()}
    </Modal>
  );
}

export default ModalAlert;
