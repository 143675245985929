import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import TableToolbar from '../../shared/tableToolbar';
import ClientReportStore from './ClientReportStore';

@observer
class Toolbar extends React.Component {
  handleChangeTab = (_, { name }) => {
    return ClientReportStore.setFieldsValue({
      tabIsActive: name,
      type: name === 'ime' ? 1 : name === 'fr' ? 2 : name === 'supp' ? 3 : name === 'cr' ? 4 : 5,
    });
  };
  render() {
    const { tabIsActive } = ClientReportStore;
    return (
      <form className="CM-report-toolbar">
        <TableToolbar.Container>
          <TableToolbar.Container>
            <div style={{ width: 'calc(16.667% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'ime' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="ime"
              >
                View IME Reports
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16.667% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'fr' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="fr"
              >
                View File Reviews
              </TableToolbar.HighLightButton>
            </div>

            <div style={{ width: 'calc(16.667% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'supp' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="supp"
              >
                View Supp Reports
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16.667% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'cr' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="cr"
              >
                View CR Reports
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16.667% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'mn' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="mn"
              >
                View Med Neg Reports
              </TableToolbar.HighLightButton>
            </div>
            <div style={{ width: 'calc(16% - 5px)' }}>
              <TableToolbar.HighLightButton
                className={cx({ blue: tabIsActive === 'export' })}
                style={{ width: '100%' }}
                onClick={this.handleChangeTab}
                name="export"
              >
                Export Clients - Booking Sources - Overdues
              </TableToolbar.HighLightButton>
            </div>
          </TableToolbar.Container>
        </TableToolbar.Container>
      </form>
    );
  }
}

export default Toolbar;
