import './SidebarMenus.scss';

import { observer } from 'mobx-react';
import React from 'react';
import { Label } from 'semantic-ui-react';

import * as api from '../../../stores/api';
import dashboard from '../../../stores/dashboard';
import router from '../../../stores/router';
import customFetch from '../../../utils/customFetch';
import Tooltip from '../../shared/Tooltip';
import detailsStore from '../../admin-routes/MyDetail/store';
import { MenuGroup, MenuItem } from './Menu';
import ModalDisbursementFunding from '@components/shared/ModalDisbursementFunding';

@observer
class SidebarMenus extends React.Component {
  state = {
    isOpenModal: false,
    modalParams: {},
  };
  showConfirm = e => {
    e.preventDefault();
    const r = window.confirm(
      'Requesting Non-MAG Supplementary Reports refer to cases where original assessments were not handled by MAG. If this is not the case, place a request from the original case within either IME Services or File Reviews. Do you want to proceed with Non-MAG case request?',
    );

    if (r === false) {
      return false;
    }
    router.history.push('/view/new-supplementary-report');
  };

  componentDidMount() {
    detailsStore.countActionRequiredIncomplete();
  }

  functionalCapacityAssessmentsClick = e => {
    e.preventDefault();
    return false;
  };

  showConfirmRequestRefund = e => {
    e.preventDefault();
    this.setState({
      isOpenModal: true,
      modalParams: { clientId: api.currentUser.data.ClientId, isManual: true, isFromMainMenu: true },
    });
    dashboard.open('/view/dashboard');
  };

  handleRevokeImpersonation = () => {
    customFetch('/account/RevertImpersonation', {
      method: 'POST',
    }).then(() => {
      var url = window.location.protocol + '//' + window.location.host + '/view/dashboard';
      window.location = url;
    });
  };

  renderDoctorMenu = () => !api.isSiteOnlyInvoiceCheck() ? (
    <React.Fragment>
      <MenuItem path="/view/dashboard" title="DASHBOARD" materialIcon />
      <MenuItem path="/view/universal-search" title="FIND MY CASES" materialIcon />
      <MenuItem path="/view/calendar-2" title="MY CLINIC CALENDAR" materialIcon />
      <MenuGroup title="My Cases" name="service-center" materialIcon>
        <MenuItem path="/view/doctor-ime" noImage />
        <MenuItem path="/view/doctor-ime-2" noImage />
        <MenuItem path="/view/doctor-file-reviews" title="File Reviews (F)" noImage />
        <MenuItem path="/view/doctor-supplementary" title="Supplementary Reports (S)" noImage />
      </MenuGroup>
      <MenuItem
        path="http://specialistsupport.kawaconn.com/"
        title="HELP BY KAWACONN"
        target="_blank"
        name="help"
        materialIcon
      />
      <MenuItem path="/view/send-feedback-2" title="SEND FEEDBACK" materialIcon />
    </React.Fragment>
  ) : <div></div>;

  renderAdminMenu = () => (
    <React.Fragment>
      <MenuItem
        path="/view/admin-account-2"
        title={api.currentUser.data?.FullName}
        materialIcon
        showBubble={!!detailsStore.actionRequiredCount}
        bubbleText={`${detailsStore.actionRequiredCount}`}
        bubbleClick={() => detailsStore.changeField('activeIndex', 3)}
      />
      <MenuItem path="/view/dashboard" title="DASHBOARD" materialIcon />
      {!api.isSiteOnlyInvoiceCheck() && <MenuGroup title="SEARCH OPTIONS" hidden name="search" materialIcon>
        <MenuItem path="/view/universal-search" title="Case Search" noImage />
        <MenuItem path="/view/docs-search" title="Document Search" noImage />
        <MenuItem path="/view/action-required-search" title="Action Required & PIC Search" noImage />
        <MenuItem path="/view/smart-search" title="Appointment Search" noImage />
      </MenuGroup>}
      {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/ims" name="calendar" title="INQUIRY CENTRE" materialIcon />}
      {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/calendar-2" title="CLINIC CALENDAR" materialIcon />}
      {!api.isSiteOnlyInvoiceCheck() && <MenuGroup title="USER ACCOUNTS" hidden name="account-center" materialIcon>
        <MenuItem path="/view/clients-2" noImage />
        <MenuItem path="/view/case-managers-2" noImage />
        <MenuItem path="/view/typist" noImage />
        <MenuItem path="/view/specialists-2" noImage />
        <MenuItem path="/view/medical-centres-2" noImage />
        <MenuItem path="/view/claimants-2" noImage />
        <MenuItem path="/view/login-users-2" noImage />
      </MenuGroup>}
      <MenuGroup title="BOOKING CENTRE" name="service-center" materialIcon>
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/ime-assessments-2" title="IME Assessments (A)" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/ime-assessments-3" title="Fitness for Duty (A)" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/admin-file-reviews" title="File Reviews (F)" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/supplementary-reports-2" title="Supplementary Reports (S)" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/clinical-notes-2" title="Clinical Records Retrievals (C)" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem title="Medical Negligence Triage (MN)" path="/view/admin-med-neg" noImage />}
        {api.isAdminOrMagStaffOrAccounting() && <MenuItem path="/view/estimate" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="https://scopevideo.com.au" target="_blank" noImage />}
      </MenuGroup>
      {!api.isSiteOnlyInvoiceCheck() && <MenuGroup title="Facility Disbursement" materialIcon name="billing" hidden>
        <MenuItem path="/view/funding-request" noImage />
        <MenuItem path="/view/funding-agreement" noImage />
        <MenuItem path="/view/funding-report" noImage />
      </MenuGroup>}
      {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/invoices-2" title="BILLING" materialIcon />}
      {!api.isSiteOnlyInvoiceCheck() && <MenuGroup title="REPORT CENTRE" materialIcon name={'report-centre'} hidden>
        <MenuItem title="Client Reports" path="/view/client-reports-2" noImage />
        <MenuItem title="Case Manager Reports" path="/view/cm-reports" noImage />
        <MenuItem title="Specialist Reports" path="/view/doctor-reports-2" noImage />
        <MenuItem path="/view/marketing-campaign" title="Marketing Campaigns" noImage />
        {/* <MenuItem path="/view/campaign-schedule-history" title="Campaign Schedule Histories" noImage /> */}
        <MenuItem title="Export History" path="/view/export-history" noImage />
        {/* <MenuItem title="Login History" path="/view/login-history-2" noImage /> */}
      </MenuGroup>}
      {!api.isSiteOnlyInvoiceCheck() && <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
        <MenuItem path="/view/casemanager-recruitment-report" title="Client & CM Recruitment" noImage />
        <MenuItem path="/view/specialist-recruitment-report" title="Specialist Recruitment" noImage />
        <MenuItem path="/view/ime-operational-analysis-report" title="IME Operational Analysis" noImage />
        <MenuItem path="/view/ime-forecast-billing" title="Revenue & Forecast" noImage />
        <MenuItem path="/view/booking-account-report" title="Booking By Account" noImage />
        <MenuItem path="/view/correspondence-analysis-report" title="Correspondence Analysis" noImage />
        <MenuItem path="/view/bam-report" title="Staff KPI Report" noImage />
        <MenuItem path="/view/staff-dashboard-report" title="Billing KPI" noImage />
      </MenuGroup>}
      <MenuGroup title="VALUE SETTINGS" hidden name="value-settings" materialIcon>
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/greeting-title" noImage />}
        {api.isAdmin() && !api.isSiteOnlyInvoiceCheck()  && <MenuItem title="Job Titles" path="/view/job-title-2" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/manage-spe-acc" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/domain-exclusion" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/types-of-claims-2" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/types-of-report-2" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/industry-segments-2" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/ims-approval-2" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/service-fee-2" noImage />}
        {api.isMAG() && <MenuItem path="/view/ama-calculation-rule" noImage />}
        {api.isMAG() && <MenuItem path="/view/ama-service-fee" noImage />}
        {api.isMAG() && <MenuItem path="/view/mbs-code" noImage />}
        {api.isMAG() && <MenuItem path="/view/atercare-rule" noImage />}
        {api.isMAG() && <MenuItem path="/view/time-unit" noImage />}
        {api.isMAG() && <MenuItem path="/view/modify-unit" noImage />}
        {api.isMAG() && <MenuItem path="/view/m4-modifier" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/type-of-work" noImage />}
        <MenuItem path="/view/body-part" noImage />
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/talking-point-category" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/talking-point" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/module-type-template" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/message-template" title="Mail Templates" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/sale-target" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/target-audience" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/lmq-criteria" noImage />}
        {!api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/language" noImage />}
        <MenuItem path="/view/MZ900-calculations-settings" noImage />
        {api.isAdmin() && !api.isSiteOnlyInvoiceCheck() && (
          <React.Fragment>
            <MenuItem path="/view/system-config" title="System Configurations" noImage />
            <MenuItem path="/hangfire" target="_blank" noImage />
          </React.Fragment>
        )}
      </MenuGroup>
      {api.isAdminOrMagStaffOrAccounting() && !api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/notification" title="NOTIFICATIONS" materialIcon />}
      <MenuItem
        path="/Content/documents/MAGDirectory.pdf"
        title="MAG SPECIALIST PANEL"
        target="_blank"
        name="mag-directory"
        materialIcon
      />
      <MenuItem
        path="https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos"
        title="MAG SEMINARS"
        target="_blank"
        name="help"
        materialIcon
      />
      <MenuItem
        path="https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7"
        title="EDUCATION CHANNEL"
        target="_blank"
        name="help"
        materialIcon
      />
      <MenuItem
        path="https://cmsupport.kawaconn.com/"
        title="HELP BY KAWACONN"
        target="_blank"
        name="help"
        materialIcon
      />
      {api.isAdminOrMagStaffOrAccounting() && (
        <>
          <MenuItem path="/view/send-feedback-2" title="SEND FEEDBACK" materialIcon />
          <MenuItem path="/view/chatgpt" title="ASK ChatGPT" materialIcon />
        </>
      )}
    </React.Fragment>
  );

  renderMenu = clientType => (
    <React.Fragment>
      {api.isTypist() && <MenuItem path="/view/tp-detail" title={api.currentUser.data?.FullName} materialIcon />}
      <MenuItem path="/view/dashboard" title="DASHBOARD" materialIcon />
      {!api.isTypist() && !api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && <MenuItem path="/view/calendar-2" title="CLINIC CALENDAR" materialIcon />}
      {!api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && <MenuGroup title="SEARCH OPTIONS" hidden name="search" materialIcon>
        <MenuItem path="/view/universal-search" title="Case Search" noImage />
        {!api.isTypist() && (
          <MenuItem path="/view/smart-search" name="search" title="Appointment Session Search" noImage />
        )}
      </MenuGroup>}
      <MenuGroup title="My Cases" name="service-center" materialIcon>
        {!api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && <React.Fragment>
          <MenuItem title="IME Reports (A)" path="/view/ime-assessments-2" noImage />
          <MenuItem title="Fitness for Duty (A)" path="/view/ime-assessments-3" noImage />
          {!api.isTypist() && (
            <Tooltip appear="Contact us for more information regarding this service.">
              <div>
                <MenuItem
                  path="/FunctionalCapacityAssessments"
                  onClick={this.functionalCapacityAssessmentsClick}
                  title="Functional Capacity Assessments"
                  noImage
                />
              </div>
            </Tooltip>
          )}
          <MenuItem title="File Reviews (F)" path="/view/admin-file-reviews" noImage />
          <MenuItem title="Supplementary Reports (S)" path="/view/supplementary-reports-2" noImage />
          {!api.isTypist() && (
            <MenuItem
              path="/view/clinical-notes-2"
              title={
                <React.Fragment>
                  Clinical Record Retrievals (C)
                  <Label style={{ color: 'red', background: 'none' }}>
                    $100 per Request <br />
                    (No charge IRO with ILARS)
                  </Label>
                </React.Fragment>
              }
              noImage
            />
          )}
          {api.isCaseManager() && (
            <MenuItem
              title={
                <React.Fragment>
                  Medical Negligence Triage (MN)
                  <Label style={{ color: 'red', background: 'none' }}>
                    Request Free
                    <br />
                    Preliminary Opinions and Teleconf
                  </Label>
                </React.Fragment>
              }
              path="/view/admin-med-neg"
              noImage
            />
          )}
        </React.Fragment>}
        {api.isCaseManager() && api.currentUser.data.SubscribeSpecialistInvoiceCheck && (
          <MenuItem path="/view/estimate" noImage />
        )}
      </MenuGroup>
      {api.isCaseManager() && !api.isCMInvoiceCheck() && !api.isSiteOnlyInvoiceCheck() && 
      clientType === 3 && ( //clientType = LawPlaintiff
          <MenuItem path="/view/request-disbursement-funding" onClick={this.showConfirmRequestRefund} materialIcon />
        )}
      {!api.isTypist() && (
        <MenuItem
          path="/Content/documents/MAGDirectory.pdf"
          title="MAG SPECIALIST PANEL"
          target="_blank"
          name="mag-directory"
          materialIcon
        />
      )}
      <MenuItem
        path="https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos"
        title="MAG SEMINARS"
        target="_blank"
        name="help"
        materialIcon
      />
      <MenuItem
        path="https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7"
        title="EDUCATION CHANNEL"
        target="_blank"
        name="help"
        materialIcon
      />
      <MenuItem
        path="https://cmsupport.kawaconn.com/"
        title="HELP BY KAWACONN"
        target="_blank"
        name="help"
        materialIcon
      />
      {!api.isTypist() && <MenuItem path="/view/send-feedback-2" title="SEND FEEDBACK" materialIcon />}
    </React.Fragment>
  );

  render() {
    const { ClientType } = api.currentUser.data;
    return (
      <div className="SidebarMenus">
        {!api.isAdminOrMagStaffOrAccounting() && !api.isTypist() && (
          <MenuItem
            path={api.isDoctor() ? '/view/dr-account-2' : '/view/cm-account-2'}
            title={api.currentUser.data?.FullName}
            materialIcon
          />
        )}

        {api.isDoctor() ? this.renderDoctorMenu()
          : api.isAdminOrMagStaffOrAccounting() ? this.renderAdminMenu()
          : this.renderMenu(ClientType)}

        <MenuItem path="/view/logout" title="LOGOUT" materialIcon />
        {this.state.isOpenModal && (
          <ModalDisbursementFunding
            {...this.state.modalParams}
            open={this.state.isOpenModal}
            onCancel={() => this.setState({ isOpenModal: false, modalParams: {} })}
          />
        )}
        {api.isImpersonated() && (
          <MenuItem
            path="/account/RevertImpersonation"
            title="REVOKE"
            onClick={this.handleRevokeImpersonation}
            materialIcon
          />
        )}
      </div>
    );
  }
}

export default SidebarMenus;
