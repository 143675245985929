import { notification } from 'antd';
import { action, observable } from 'mobx';
import moment from 'moment';
import Validator from 'validatorjs';
import { formatDate } from '../../../utils/functions';
import * as api from '../../../stores/api';
import customFetch from '../../../utils/customFetch';
import {
  getCMInfo,
  getClient,
  getSpecificLookupConfigByType,
  getStaffAll,
  getStaffs,
  getConversationLog,
  deleteConversationLog,
  allowCMViewConversationLog,
  saveConversationLog,
  fetchGreetingTitlesByType,
} from './service';

const INIT_CM = {
  AssignedDate: '',
  AssignedStaffId: '',
  Comments: null,
  CompanyAddress: '',
  CompanyBusinessUnit: [],
  CompanyId: null,
  CompanyName: '',
  CompanyState: null,
  CreateFreeBookingByName: null,
  DateCheckedFreeBooking: null,
  Email: '',
  EmailConfirmed: false,
  EnableLogin: true,
  EnableLoginBy: '',
  EnableLoginDate: null,
  FirstName: '',
  FreeBookingApprovedBy: '',
  FreeBookingApprovedByName: null,
  FreeBookingFrom: null,
  FreeBookingTo: null,
  FullName: null,
  Id: 0,
  IsFreeBooking: false,
  LastName: '',
  LoginRequired: true,
  NumberOfFreeBookings: null,
  OfficePhone: '',
  Position: null,
  Postcode: '',
  State: '',
  Suburb: '',
  Telephone: null,
  Title: 'Mr',
  Unsubscribed: false,
  UnsubscribedDate: null,
  Username: '',
  IsTypist: false,
  HourlyRate1: null,
  HourlyRate1From: null,
  HourlyRate1To: null,
  HourlyRate2: null,
  HourlyRate2From: null,
  HourlyRate2To: null,
  HourlyRate3: null,
  HourlyRate3From: null,
  HourlyRate3To: null,
  IsExcluding: false,
  IsOnboarding: true,
  IsRecruiting: false,
  IsOnboarded: true,
  ExcludingDate: null,
  OnboardingDate: moment(),
  RecruitingDate: null,
  OnboardedDate: moment(),
  ABN: null,
  BusinessABN: null,
};
const BODY_CONVERSATIONLOG = {
  CallerSender: '',
  CallerSenderName: '',
  CallerSenderType: 1,
  CaseManagerId: 0,
  Content: '',
  ConversationType: 1,
  Id: 0,
  Receiver: '',
  ReceiverName: '',
  ReceiverType: 1,
  SentDate: moment(),
  Subject: '',
  allowCMToView: false,
  allowDoctorToView: false,
  callerSenderId: '',
  hour: 0,
  minute: 0,
  receiverId: 0,
};

const VALIDATE_RULES = {
  FreeBookingApprovedBy: 'required',
  Email: ['email', 'required'],
  OfficePhone: 'max:10',
  Telephone: 'max:10',
  IsRecruiting: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsOnboarding: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsOnboarded: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
  IsExcluding: [
    'required_without:IsRecruiting',
    'required_without:IsOnboarding',
    'required_without:IsOnboarded',
    'required_without:IsExcluding',
  ],
};

const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
  required_without: {
    string: 'Must select at least one recruitment status',
  },
};

class AddEditStore {
  @observable loading = true;
  @observable loadingConversation = false;
  @observable CMInfo = INIT_CM;

  @observable dataUser = null;
  @observable userId = null;

  @observable CMInfo_ = INIT_CM;
  @observable isChanged = {};

  @observable conversationParam = BODY_CONVERSATIONLOG;

  @observable conversationSubReceiver = '';
  @observable conversationSubSender = '';

  //data
  @observable businessUnitsList = null;
  @observable staffList = null;
  @observable staffAllList = null;
  @observable specificlookupList = null;
  @observable clientList = null;
  @observable conversationList = null;
  @observable cmCampaign = null;

  //Search
  @observable keywordClient = '';
  @observable numberItemClient = 30;
  @observable loadingSearchClient = false;

  @observable keywordCM = '';
  @observable numberItemCM = 30;
  @observable loadingSearchCM = false;

  //Save
  @observable loadingSave = false;

  //Check duplicate
  @observable loadingCheckDuplicate = false;

  //Error
  @observable errors = {};

  //status histories
  @observable loadingHistory = false;
  @observable dataHistory = null;

  //Modal
  @observable open = false;
  @observable modalParams = {};

  toggleModal = (isOpen, params = {}) => {
    return action(() => {
      this.open = isOpen;
      this.modalParams = params;
    });
  };
  //

  @action fetchHistory = id => {
    this.loadingHistory = true;
    customFetch('/Typist/GetStatusHistory?type=1&itemId=' + id, {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    }).then(
      action(data => {
        this.dataHistory = data;
        this.loadingHistory = false;
      }),
    );
  };

  @action handleReloadConversation = cmId => {
    this.loadingConversation = true;
    Promise.all([getConversationLog(cmId)]).then(
      action(([list]) => {
        this.conversationList = list;
        this.loadingConversation = false;
      }),
    );
  };

  @action handleDelete = id => {
    this.loadingConversation = true;
    deleteConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.CMInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Delete Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAllow = id => {
    this.loadingConversation = true;
    allowCMViewConversationLog(id).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.CMInfo.Id);
        notification.destroy();
        notification.success({
          description: 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleAdd = body => {
    this.loadingConversation = true;
    saveConversationLog(body).then(res => {
      if (res.status === 'success') {
        this.handleReloadConversation(this.CMInfo.Id);
        notification.destroy();
        notification.success({
          description: body.Id === 0 ? 'Add Conversation Log successfully!' : 'Update Conversation Log successfully!',
          message: 'Success',
          duration: 5,
        });
      }
    });
  };

  @action handleResetBody = () => {
    this.conversationParam = {
      ...BODY_CONVERSATIONLOG,
      CallerSender: this.CMInfo.Id,
      CallerSenderName: this.CMInfo.FirstName + ' ' + this.CMInfo.LastName,
      Receiver: this.CMInfo.Id,
      ReceiverName: this.CMInfo.FirstName + ' ' + this.CMInfo.LastName,
    };
  };

  @action refetchSearchClient = data => {
    this.loadingSearchClient = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getClient(this.keywordClient, this.numberItemClient)]).then(
      action(([client]) => {
        this.clientList = client;
        this.loadingSearchClient = false;
      }),
    );
  };

  @action refetchSearchCM = data => {
    this.loadingSearchCM = true;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
    Promise.all([getStaffAll(this.keywordCM, this.numberItemCM)]).then(
      action(([cm]) => {
        this.staffAllList = cm;
        this.loadingSearchCM = false;
      }),
    );
  };

  @action handleAddError = (fieldName, errorMessage) => {
    this.errors = { ...this.errors, [fieldName]: errorMessage };
  };

  @action handleUpdateCMInfo = (key, value) => {
    const updatedCMInfo = { ...this.CMInfo };
    updatedCMInfo[key] = value;
    this.CMInfo = updatedCMInfo;
  };

  @action setFieldsValue = data => {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  };

  @action handleValidate = (field, value) => {
    if (field === 'Email') {
      const emailRequired = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][1] }, ERROR_MESSAGES);
      const emailValidation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field][0] }, ERROR_MESSAGES);
      if (this.CMInfo.EnableLogin) {
        if (emailRequired.passes()) {
          delete this.errors[field];
          if (emailValidation.passes()) {
            delete this.errors[field];
          } else {
            this.errors[field] = emailValidation.errors.first(field);
          }
        } else {
          this.errors[field] = emailRequired.errors.first(field);
        }
      } else {
        if (emailValidation.passes()) {
          delete this.errors[field];
        } else {
          this.errors[field] = emailValidation.errors.first(field);
        }
      }
    } else {
      const validation = new Validator({ [field]: value }, { [field]: VALIDATE_RULES[field] }, ERROR_MESSAGES);

      if (validation.passes()) {
        delete this.errors[field];
      } else {
        this.errors[field] = validation.errors.first(field);
      }
    }
  };
  @observable greetingTitle = [];
  @action fetchCMData = id => {
    Promise.all([
      getCMInfo(id),
      getSpecificLookupConfigByType(),
      getStaffs(),
      getConversationLog(id),
      fetchGreetingTitlesByType('typist'),
    ]).then(
      action(([info, specificlookup, staff, conversation, greetingTitle]) => {
        Promise.all([getClient(info.CompanyId), getStaffAll()]).then(
          action(([client, staffAll]) => {
            if (!!info.Id) {
              this.loading = false;
              this.clientList = client;
              this.conversationList = conversation;
              this.specificlookupList = specificlookup;
              this.staffAllList = staffAll;
              this.staffList = staff;
              this.CMInfo = info;
              this.CMInfo_ = info;
              this.greetingTitle = greetingTitle.itemList;
            } else {
              notification.error({
                message: 'Error',
                description: 'This case manager does not exist or has been deleted',
              });
              return this.initCMData();
            }
          }),
        );
      }),
    );
  };

  @action initCMData = (companyId = 0) => {
    this.loading = true;
    if (!api.isTypist()) {
      this.CMInfo = INIT_CM;
      this.CMInfo_ = INIT_CM;
      Promise.all([
        getClient(),
        getSpecificLookupConfigByType(),
        getStaffs(),
        getStaffAll(),
        fetchGreetingTitlesByType('typist'),
      ]).then(
        action(([client, specificlookup, staff, staffAll, greetingTitle]) => {
          this.CMInfo.CompanyId = companyId !== '0' ? parseInt(companyId) : null;
          this.clientList = client;
          this.specificlookupList = specificlookup;
          this.staffAllList = staffAll;
          this.staffList = staff;
          this.greetingTitle = greetingTitle.itemList;
          this.loading = false;
        }),
      );
    } else {
      var currentUser = api.currentUser;
      getCMInfo(currentUser.data.TypistId).then(res => {
        this.setCMINFOR(res);
      });
      this.loading = false;
      //this.fetchCMData(currentUser.data.TypistId)
    }
  };

  @action setCMINFOR = cmInfo => {
    this.CMInfo = cmInfo;
  };

  @action handleEnableCase = id => {
    customFetch('/Typist/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(resp => {
      this.handleUpdateCMInfo('IsActive', true);
    });
  };

  @action handleDisableCase = id => {
    customFetch('/Typist/Authorize', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(resp => {
      this.handleUpdateCMInfo('IsActive', false);
    });
  };

  @action handleChecked = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      if (fieldName === 'EnableLogin') {
        if (!this.CMInfo.Email && value) {
          this.handleAddError('Email', 'This field is required');
        } else {
          if (this.errors.Email === 'This field is required') {
            delete this.errors.Email;
          }
          if (this.errors['duplicateEmail']) {
            delete this.errors['duplicateEmail'];
          }
        }
      }
      if (fieldName === 'IsRecruiting') {
        if (value) {
          this.CMInfo.RecruitingDate = moment();
        } else {
          this.CMInfo.RecruitingDate = null;
        }
      }
      if (fieldName === 'IsOnboarding') {
        if (value) {
          this.CMInfo.OnboardingDate = moment();
        } else {
          this.CMInfo.OnboardingDate = null;
        }
      }

      if (fieldName === 'IsExcluding') {
        if (value) {
          this.CMInfo.ExcludingDate = moment();
        } else {
          this.CMInfo.ExcludingDate = null;
        }
      }
      if (fieldName === 'IsOnboarded') {
        if (value) {
          this.CMInfo.OnboardedDate = moment();
        } else {
          this.CMInfo.OnboardedDate = null;
        }
      }
      if (fieldName === 'Unsubscribed') {
        if (this.CMInfo.UnsubscribedDate) {
          this.CMInfo.UnsubscribedDate = null;
          return (this.CMInfo.Unsubscribed = false);
        } else {
          this.CMInfo.UnsubscribedDate = moment().toDate();
          return (this.CMInfo.Unsubscribed = true);
        }
      }

      this.CMInfo[fieldName] = value;

      if (
        fieldName === 'IsRecruiting' ||
        fieldName === 'IsOnboarding' ||
        fieldName === 'IsOnboarded' ||
        fieldName === 'IsExcluding'
      ) {
        const validation = new Validator(
          {
            IsRecruiting: this.CMInfo.IsRecruiting || '',
            IsOnboarding: this.CMInfo.IsOnboarding || '',
            IsOnboarded: this.CMInfo.IsOnboarded || '',
            IsExcluding: this.CMInfo.IsExcluding || '',
          },
          {
            IsRecruiting: VALIDATE_RULES.IsRecruiting,
            IsOnboarding: VALIDATE_RULES.IsOnboarding,
            IsOnboarded: VALIDATE_RULES.IsOnboarded,
            IsExcluding: VALIDATE_RULES.IsExcluding,
          },
          ERROR_MESSAGES,
        );
        validation.passes();
        const { errors } = validation;
        const errorList = Object.entries(errors.errors);
        if (errorList.length === 4) {
          this.errors.RecruitmentStatus = validation.errors.first(fieldName);
        } else {
          delete this.errors.RecruitmentStatus;
        }
      }
    });

  @action handleCheckedConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.checked : event) : false;
      this.conversationParam[fieldName] = value;
    });

  handleFieldChangeConversation = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'ConversationType') {
        this.conversationParam[fieldName] = event;
      } else if (fieldName === 'Receiver') {
        this.conversationParam.Receiver = value.key;
        this.conversationParam.ReceiverName = value.label;
      } else if (fieldName === 'CallerSender') {
        this.conversationParam.CallerSender = value.key;
        this.conversationParam.CallerSenderName = value.label;
      } else {
        if (fieldName === 'CallerSenderType') {
          if (value === 1) {
            this.conversationParam.CallerSender = this.CMInfo.Id;
            this.conversationParam.CallerSenderName = this.CMInfo.FirstName + ' ' + this.CMInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.CallerSenderName = api.currentUser.data.FullName;
            this.conversationParam.CallerSender = api.currentUser.data.id;
          }
        }
        if (fieldName === 'ReceiverType') {
          if (value === 1) {
            this.conversationParam.Receiver = this.CMInfo.Id;
            this.conversationParam.ReceiverName = this.CMInfo.FirstName + ' ' + this.CMInfo.LastName;
          }
          if (value === 3) {
            this.conversationParam.ReceiverName = api.currentUser.data.FullName;
            this.conversationParam.Receiver = api.currentUser.data.id;
          }
        }
        this.conversationParam[fieldName] = value;
      }
    });

  @action handleFieldChangePW = (fieldName, value) => {
    this.CMInfo[fieldName] = value;
  };

  handleChangeFieldForPW = fieldName =>
    action(event => {
      const value = event.target.value;
      this.CMInfo = { ...this.CMInfo, [fieldName]: value };
    });

  createDate = date => {
    const datearr = date.split('/');
    const d = new Date(datearr[2], Number(datearr[1]) - 1, datearr[0]);
    return d;
  };

  showErrorEffectiveDate = message => {
    this.toggleModal(true, {
      modalType: 'confirm',
      message,
    })();
  };

  checkDateString = dateStr => {
    if (typeof dateStr === 'string') {
      const date = formatDate(dateStr);
      return moment(date, 'DD MMM,YYYY').format('DD/MM/YYYY');
    } else {
      return dateStr.format('DD/MM/YYYY');
    }
  };

  checkValidEffectiveFromTo = (dateFrom, dateTo) => {
    if (dateTo) {
      if (dateFrom) {
        const dateFrom_ = this.checkDateString(dateFrom);
        const dateTo_ = this.checkDateString(dateTo);
        const from = this.createDate(dateFrom_).getTime();
        const to = this.createDate(dateTo_).getTime();
        if (to < from) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  validationHourlyFromTo = (fieldName, value) => {
    if (!value) {
      return (this.CMInfo[fieldName] = value);
    } else {
      const date = this.createDate(value.format('DD/MM/YYYY'));
      if (fieldName === 'HourlyRate2From') {
        if (!this.CMInfo.HourlyRate1To && !this.CMInfo.HourlyRate1From) {
          return this.showErrorEffectiveDate('Please enter effective period of Hourly Rate 1');
        } else {
          if (this.CMInfo.HourlyRate1To) {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate1To));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 1.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          } else {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate1From));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 1.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          }
        }
      } else if (fieldName === 'HourlyRate3From') {
        if (!this.CMInfo.HourlyRate2To && !this.CMInfo.HourlyRate2From) {
          return this.showErrorEffectiveDate('Please enter effective period of Hourly Rate 2');
        } else {
          if (this.CMInfo.HourlyRate2To) {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate2To));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 2.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          } else {
            const period = this.createDate(this.checkDateString(this.CMInfo.HourlyRate2From));
            if (date.getTime() < period.getTime()) {
              return this.showErrorEffectiveDate(
                'Effective date of Hourly Rate 2 must be after effective period of Hourly Rate 2.',
              );
            } else {
              return (this.CMInfo[fieldName] = value);
            }
          }
        }
      }
    }
  };

  handleFieldChange = fieldName =>
    action(event => {
      const value = event ? (event.target ? event.target.value : event) : null;
      if (fieldName === 'AssignedStaffId') {
        this.CMInfo.AssignedDate = moment().format('DD/MM/YYYY');
        this.CMInfo[fieldName] = value;
      } else if (fieldName === 'HourlyRate3From' || fieldName === 'HourlyRate2From') {
        this.validationHourlyFromTo(fieldName, value, 'checkForHandleChange');
      } else if (
        fieldName === 'Telephone' ||
        fieldName === 'OfficePhone' ||
        fieldName === 'HourlyRate1' ||
        fieldName === 'HourlyRate2' ||
        fieldName === 'HourlyRate3'
      ) {
        this.CMInfo[fieldName] = value.replace(/\D/g, '').replace(/[^\d]/g, '');
      } else if (fieldName === 'HourlyRate1To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate1From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'HourlyRate2To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate2From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'HourlyRate3To') {
        const isValid = this.checkValidEffectiveFromTo(this.CMInfo.HourlyRate3From, value);
        if (isValid) {
          this.CMInfo[fieldName] = value;
        } else {
          this.showErrorEffectiveDate('Invalid effective period');
        }
      } else if (fieldName === 'Position') {
        const exist = this.specificlookupList.itemList.find(i => i.Id === value);
        this.CMInfo[fieldName] = exist;
      } else {
        this.CMInfo[fieldName] = value;
      }

      if (VALIDATE_RULES[fieldName]) {
        this.handleValidate(fieldName, this.CMInfo[fieldName]);
      }
    });

  @action resetStore = () => {
    this.loading = true;
    this.CMInfo = INIT_CM;
    this.CMInfo_ = INIT_CM;
    this.errors = {};
  };

  @action handleSaveStaff = () => {
    this.loadingSave = true;
  };
}

export default new AddEditStore();
