import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { formatDate } from '../../../utils/functions';
import iframe from '../../dashboard-routes/Iframe/iframeData';
import { TableEmptyMessage, TableHeader, TableFooterPagination } from '../table';
import dashboardStore from '@stores/dashboard';

const columnsIME = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Subject', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Content', style: { minWidth: '500px', maxWidth: '500px' } },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client ' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Clinic Centre' },
  { title: 'Appointment Date' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
  { title: 'Report Type', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded By', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded Date', style: { minWidth: '250px', maxWidth: '250px' } },
];

const columnsFR = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Subject', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Content', style: { minWidth: '500px', maxWidth: '500px' } },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
  { title: 'Report Type', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded By', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded Date', style: { minWidth: '250px', maxWidth: '250px' } },
];

const columnsSUPP = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Subject', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Content', style: { minWidth: '500px', maxWidth: '500px' } },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Specialist' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
  { title: 'Report Type', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded By', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded Date', style: { minWidth: '250px', maxWidth: '250px' } },
];

const columnsCR = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Claim No' },
  { title: 'Type Of Claim' },
  { title: 'Claimant' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'CR Provider 1' },
  { title: 'CR Provider 2' },
  { title: 'Case Progress' },
  { title: 'Status Date' },
  { title: 'Subject', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Content', style: { minWidth: '500px', maxWidth: '500px' } },
  { title: 'Report Type', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded Date', style: { minWidth: '250px', maxWidth: '250px' } },
];

const columnsMN = [
  { title: 'Case No' },
  { title: 'Request Date' },
  { title: 'Case Status' },
  // { title: 'Specialist 1' },
  // { title: 'Status 1' },
  // { title: 'Specialist 2' },
  // { title: 'Status 2' },
  // { title: 'Specialist 3' },
  // { title: 'Status 3' },
  { title: 'Claim No' },
  { title: 'Type of Claim' },
  { title: 'Claimant Name' },
  { title: 'Client' },
  { title: 'Case Manager' },
  { title: 'Subject', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Content', style: { minWidth: '500px', maxWidth: '500px' } },
  { title: 'Report Type', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded By', style: { minWidth: '250px', maxWidth: '250px' } },
  { title: 'Uploaded Date', style: { minWidth: '250px', maxWidth: '250px' } },
];

const columnsINV = [
  { title: 'Case No' },
  { title: 'Subject' },
  { title: 'Content' },
  { title: 'Uploaded By' },
  { title: 'Uploaded Date' },
];
class TablesDoc extends React.Component {
  openCaseDetails = record => {
    const { title } = this.props;
    let caseNo = `Case ${record.CaseNo}`;
    let stograge = '';
    let tabName = '';
    if (title === 'IME Reports') {
      stograge = 'AssessmentCaseId';
      tabName = 'add-edit-ime-assessment-2';
    }
    if (title === 'File Reviews') {
      stograge = 'FileReviewCaseId';
      tabName = 'add-edit-file-review-2';
    }
    if (title === 'Supplementary Reports') {
      stograge = 'SupplementaryCaseId';
      tabName = 'add-edit-supplementary-report-2';
    }
    if (title === 'Clinical Records') {
      stograge = 'ClinicalRecordCaseId';
      tabName = 'add-edit-clinical-notes-2';
    }
    if (title === 'Med Negs') {
      stograge = 'MedNegCaseId';
      tabName = 'PostMedNeg';
    }
    if (title === 'Invoices') {
      stograge = 'InvoiceCaseId';
      tabName = 'ViewInvoice';
    }

    localStorage.setItem(`${stograge}`, caseNo);

    if (title !== 'Invoices' && title !== 'Med Negs') {
      dashboardStore.close(`/view/${tabName}`);
      return setTimeout(() => {
        dashboardStore.open(`/view/${tabName}?id=${record.CaseId}&step=0`);
      });
    } else {
      return iframe.forceOpenTab(`${tabName}`, `?id=${record.CaseId}&view=${tabName}`, {
        id: record.CaseId,
        view: `${tabName}`,
      });
    }
  };

  handlePageClick = page => {
    this.props.handlePageClick(page);
  };

  handleSort = (data, sortObj) => {
    this.props.handleSort(data, sortObj);
  };

  renderTableIME = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell colSpan="2">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                {item.FileTitle} ({item.KeywordMatchCount})
              </a>
            </Table.Cell>
            <Table.Cell style={{ minWidth: '500px', maxWidth: '500px' }}>
              <div dangerouslySetInnerHTML={{ __html: item.Content }} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
      <Table.Cell>{record.TypeOfClaimLabel}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        <div>{record.DoctorBusinessEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        {record.DateFrom
          ? moment(formatDate(record.DateFrom, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.StatusStaffText}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell colSpan="3">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.ReportTypeText}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.CreatedBy}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              {item.CreatedDate
                ? moment(formatDate(item.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableFR = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimantNo}</Table.Cell>
      <Table.Cell colSpan="2">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                {item.FileTitle} ({item.KeywordMatchCount})
              </a>
            </Table.Cell>
            <Table.Cell style={{ minWidth: '500px', maxWidth: '500px' }}>
              <div dangerouslySetInnerHTML={{ __html: item.Content }} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell colSpan="3">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.ReportTypeText}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.CreatedBy}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              {item.CreatedDate
                ? moment(formatDate(item.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableSUPP = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.DateRequest
          ? moment(formatDate(record.DateRequest, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimNo}</Table.Cell>
      <Table.Cell colSpan="2">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                {item.FileTitle} ({item.KeywordMatchCount})
              </a>
            </Table.Cell>
            <Table.Cell style={{ minWidth: '500px', maxWidth: '500px' }}>
              <div dangerouslySetInnerHTML={{ __html: item.Content }} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
      <Table.Cell>{record.TypeOfClaim}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.AssessedDoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell> {record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell colSpan="3">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.ReportTypeText}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.CreatedBy}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              {item.CreatedDate
                ? moment(formatDate(item.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableCR = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {record.RequestDate
          ? moment(formatDate(record.RequestDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.ClaimaNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.ClinicName}</h5>
        <div>{record.ClinicEmail}</div>
        <div>{record.ClinicPhone}</div>
      </Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.DoctorName}</h5>
        <div>{record.DoctorEmail}</div>
        <div>{record.DoctorPhone}</div>
      </Table.Cell>
      <Table.Cell>{record.Status}</Table.Cell>
      <Table.Cell>
        {record.StatusDate
          ? moment(formatDate(record.StatusDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell colSpan="4">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                {item.FileTitle} ({item.KeywordMatchCount})
              </a>
            </Table.Cell>
            <Table.Cell style={{ minWidth: '500px', maxWidth: '500px' }}>
              <div dangerouslySetInnerHTML={{ __html: item.Content }} />
            </Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.ReportTypeText}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              {item.CreatedDate
                ? moment(formatDate(item.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableMN = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>

      <Table.Cell>
        {record.RequestReceivedDate
          ? moment(formatDate(record.RequestReceivedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{record.StatusText}</Table.Cell>
      {/* <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty1 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status1}</Table.Cell>
      <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty2 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status2} </Table.Cell>
      <Table.Cell style={{ minWidth: 250 }}>
        <div dangerouslySetInnerHTML={{ __html: record.Specialty3 }} />
      </Table.Cell>
      <Table.Cell style={{ minWidth: 150 }}>{record.Status3}</Table.Cell> */}
      <Table.Cell>{record.ClaimantNo}</Table.Cell>
      <Table.Cell>{record.TypeOfClaimnt}</Table.Cell>
      <Table.Cell>{record.Claimant}</Table.Cell>
      <Table.Cell style={{ minWidth: 200 }}>{record.Client}</Table.Cell>
      <Table.Cell>
        <h5 className="ma-0">{record.CaseManager}</h5>
        <div>{record.CMEmail}</div>
        <div>{record.CMMobilePhone}</div>
      </Table.Cell>
      <Table.Cell colSpan="5">
        {record.listAttachment.map(item => (
          <Table.Row>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              <a href={item.PhysicalPath} target="_blank" download={item.FileTitle} rel="noopener noreferrer">
                {item.FileTitle} ({item.KeywordMatchCount})
              </a>
            </Table.Cell>
            <Table.Cell style={{ minWidth: '500px', maxWidth: '500px' }}>
              <div dangerouslySetInnerHTML={{ __html: item.Content }} />
            </Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.ReportTypeText}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>{item.CreatedBy}</Table.Cell>
            <Table.Cell style={{ minWidth: '250px', maxWidth: '250px' }}>
              {item.CreatedDate
                ? moment(formatDate(item.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Cell>
    </React.Fragment>
  );

  renderTableINV = record => (
    <React.Fragment>
      <Table.Cell className="Cell-link" selectable onClick={() => this.openCaseDetails(record)}>
        <Link to="#" onClick={() => this.openCaseDetails(record)}>
          {record.CaseNo}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <a href={record.PhysicalPath} target="_blank" download={record.FileTitle} rel="noopener noreferrer">
          {record.FileTitle}
        </a>
      </Table.Cell>
      <Table.Cell>{record.Content}</Table.Cell>
      <Table.Cell>{record.CreatedUserName}</Table.Cell>
      <Table.Cell>
        {record.CreatedDate
          ? moment(formatDate(record.CreatedDate, true), 'DD MMM YYYY, HH:mm').format('DD/MM/YYYY')
          : 'N/A'}
      </Table.Cell>
    </React.Fragment>
  );

  renderTable = record => {
    switch (this.props.title) {
      case 'IME Reports':
        return this.renderTableIME(record);
      case 'File Reviews':
        return this.renderTableFR(record);
      case 'Supplementary Reports':
        return this.renderTableSUPP(record);
      case 'Clinical Records':
        return this.renderTableCR(record);
      case 'Med Negs':
        return this.renderTableMN(record);
      default:
        return this.renderTableINV(record);
    }
  };

  renderHeader = () => {
    switch (this.props.title) {
      case 'IME Reports':
        return (
          <TableHeader
            columns={columnsIME}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'File Reviews':
        return (
          <TableHeader
            columns={columnsFR}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Supplementary Reports':
        return (
          <TableHeader
            columns={columnsSUPP}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Clinical Records':
        return (
          <TableHeader
            columns={columnsCR}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      case 'Med Negs':
        return (
          <TableHeader
            columns={columnsMN}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
      default:
        return (
          <TableHeader
            columns={columnsINV}
            sortColumn={this.props.sortColumn}
            sortDirection={this.props.sortDirection}
            onSort={this.handleSort}
          />
        );
    }
  };

  renderCols = () => {
    switch (this.props.title) {
      case 'IME Reports':
        return columnsIME;
      case 'File Reviews':
        return columnsFR;
      case 'Supplementary Reports':
        return columnsSUPP;
      case 'Clinical Records':
        return columnsCR;
      case 'Med Negs':
        return columnsMN;
      default:
        return columnsINV;
    }
  };

  render() {
    const { data, curPage, totalPage, sumItem } = this.props;
    const columns = this.renderCols();
    return (
      <Table striped celled sortable>
        {this.renderHeader()}
        <Table.Body>
          {data.length ? (
            data.map(record => <Table.Row key={record.id || record.Id}>{this.renderTable(record)}</Table.Row>)
          ) : (
            <TableEmptyMessage colSpan={columns.length} />
          )}
        </Table.Body>
        <TableFooterPagination
          colSpan={columns.length}
          currentPage={curPage}
          totalPage={totalPage}
          onPageClick={this.handlePageClick}
          totalItems={sumItem}
        />
      </Table>
    );
  }
}

export default TablesDoc;
