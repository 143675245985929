import { action, observable } from 'mobx';
import customFetch from '../../../utils/customFetch';

const FILE_OPTS = {
  maxFileSize: 100000000,
  allowedExtensions: [
    'jpg',
    'pdf',
    'jpeg',
    'gif',
    'png',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'htm',
    'html',
    'zip',
    'rar',
    'txt',
    'ds2',
    'rtf',
    'tif',
    'tiff',
    'dss',
    'wav',
    'mp3',
    'mp4',
    'xml',
    'bmp',
    'm4a',
  ],
};

const validateFile = (file, options = {}) => {
  const maxFileSize = options.maxFileSize || FILE_OPTS.maxFileSize;
  const validExtensions = options.allowedExtensions || FILE_OPTS.allowedExtensions;

  if (file.size > maxFileSize) {
    return {
      modalType: 'alert',
      message: `File size too large. File should be <= ${maxFileSize / 1000000}MB.`,
    };
  }

  const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
  const isValidExt = validExtensions.includes(extension);
  if (!isValidExt) {
    return {
      modalType: 'alert',
      message: `Sorry, ${file.name}' is invalid.
      Allowed extensions are: ${validExtensions.join(', ')}.`,
    };
  }

  return null;
};

class BaseProfileUploadStore {
  @observable profilePictureAttachments = [];
  @observable oldProfilePictureAttachments = [];
  @observable open = false;

  constructor() {
    this.uploadFile = this.uploadFile.bind(this);
    this.uploadFileFromBase64 = this.uploadFileFromBase64.bind(this);
  }

  uploadFile(responseHandler, options, errorHandler) {
    return e => {
      let errors = [];
      const { files } = e.target;
      const formData = new FormData();
      files.forEach(file => {
        const err = validateFile(file, options);
        if (err != null) {
          errors = [].concat(err);
          return;
        }
        formData.append(file.name, file);
      });

      if (errors.length) {
        return errorHandler && errorHandler(errors);
      }

      return customFetch('/File/UploadFileTemp', {
        method: 'POST',
        body: formData,
      }).then(responseHandler);
    };
  }

  uploadFileFromBase64(base64String, attachmentType) {
    customFetch('/File/UploadFileFromBase64', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        model: [
          {
            fileName: 'avatar.jpg',
            data: base64String,
          },
        ],
      }),
    }).then(
      action(data => {
        const avatar = data.fileList[0];
        this.profilePictureAttachments = [
          {
            Id: 0,
            FileDisplayName: avatar.title,
            PhysicalFileName: avatar.name,
            AttachmentType: attachmentType,
            NumberOfPages: avatar.numberOfPages,
          },
        ];
        this.open = false;
      }),
    );
  }
}

export default BaseProfileUploadStore;
