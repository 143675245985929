import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { action, toJS } from 'mobx';

import SalesCallReportStore from './SalesCallReportStore';
import TableToolbar from '../../shared/tableToolbar';
import { ActivityOutcomeOptions, ActivityTypeOptions } from '@utils/constants';
import _ from 'lodash';

@observer
class SalesCallReportToolbar extends React.Component {
  onChangeDateRange = (_, { value }) => {
    action(() => {
      SalesCallReportStore.dateRange = value;
    })();
    // SalesCallReportStore.refetch(
    //   {
    //     dateRange: value,
    //   },
    //   true,
    // );
  };
  renderClientOfficer = () => {
    let array = _.sortBy(toJS(SalesCallReportStore.dataClient?.itemList) || [], 'FullName');
    array.unshift({
      Id: '',
      FullName: 'All',
      Count: SalesCallReportStore.totalItem,
    });
    return array.map(i => ({
      value: i.Id,
      text: i.FullName + ' (' + i.Count + ')',
    }));
  };
  renderRecruitment = () => {
    let array = toJS(SalesCallReportStore.dataRecruitment?.itemList) || [];
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.map(i => ({
      value: i.Id,
      text: i.FullName + ' (' + i.Count + ')',
    }));
  };
  handleFilterClient = (_, { value, name }) => {
    if (name === 'clientOfficer') {
      SalesCallReportStore.refetch(
        {
          staffId: value ? value : '',
        },
        true,
      );
    }
    if (name === 'callById') {
      SalesCallReportStore.refetch(
        {
          callById: value ? value : '',
        },
        true,
      );
    }

    if (name === 'activityType') {
      SalesCallReportStore.refetch(
        {
          activityType: value,
        },
        true,
      );
    }

    if (name === 'activityOutcome') {
      SalesCallReportStore.refetch(
        {
          activityOutcome: value,
        },
        true,
      );
    }
  };
  renderClientOfficerText = value => {
    let array = toJS(SalesCallReportStore.dataClient?.itemList) || [];
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderRecruitmentText = value => {
    let array = toJS(SalesCallReportStore.dataRecruitment?.itemList) || [];
    array.unshift({
      Id: '',
      FullName: 'All',
    });
    return array.filter(i => i.Id === value).map(i => i.FullName)[0];
  };
  renderOptDropdown = () => {
    const options = SalesCallReportStore.dataStaff;
    return options.map((i, idx) => ({
      value: i.Id,
      text: `${i.FullName} - ${i.Email2}`,
      key: idx,
    }));
  };

  renderActivityTypeText = value => {
    return ActivityTypeOptions.find(i => i.value === value)?.text;
  };

  renderActivityOutcomeText = value => {
    return ActivityOutcomeOptions.find(i => i.value === value)?.text;
  };

  handleSearchChange = (_, { searchQuery }) => {
    SalesCallReportStore.refetchSearch({ keywordSearch: searchQuery });
  };
  handleChangeDropdown = (_, { value }) => {
    SalesCallReportStore.refetch(
      {
        id: value,
        keywordSearch: '',
      },
      true,
    );
  };
  handleScroll = event => {
    const target = event.target;
    const number = SalesCallReportStore.numberSearch;
    const scrollNumber = target.scrollHeight - target.scrollTop;
    const clientHeight = target.clientHeight;
    if (scrollNumber <= clientHeight + 5 && scrollNumber >= clientHeight - 5) {
      SalesCallReportStore.refetchSearch({ numberSearch: number + 20 });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    SalesCallReportStore.fetchReport(true);
  };
  handleRefresh = () => {
    SalesCallReportStore.refetch({ data: null }, true);
  };

  handleClear = () => {
    SalesCallReportStore.resetStore();
  };

  render() {
    const {
      dateRange,
      loadingStaff,
      keywordSearch,
      loading,
      loadingClient,
      staffId,
      callById,
      totalItem,
      searchValue,
      activityType,
      activityOutcome,
    } = SalesCallReportStore;
    return (
      <form>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <TableToolbar.SearchDropdown
              loading={loadingStaff}
              searchQuery={keywordSearch}
              closeOnChange
              onScroll={this.handleScroll}
              allowAdditions
              additionLabel=""
              selectOnNavigation={false}
              className="Toolbar-input"
              disabled={loadingStaff}
              onSearchChange={this.handleSearchChange}
              options={this.renderOptDropdown()}
              value={searchValue ? `${searchValue?.FullName} - ${searchValue?.Email2}` : ''}
              onChange={this.handleChangeDropdown}
              clearable
              icon={!searchValue ? <Icon name="dropdown" /> : <Icon name="delete" />}
            />
            <TableToolbar.RangePicker
              placeholder="From Activity Date - To Date"
              value={dateRange}
              onChange={this.onChangeDateRange}
            />
            <TableToolbar.SubmitButton className="px-2" onClick={this.handleSubmit}>
              Search
            </TableToolbar.SubmitButton>
            <TableToolbar.HighLightButton
              className="negative px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleClear}
            >
              Clear
            </TableToolbar.HighLightButton>
            <TableToolbar.HighLightButton
              className="blue px-2"
              style={{ marginLeft: '5px' }}
              onClick={this.handleRefresh}
            >
              Refresh
            </TableToolbar.HighLightButton>
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
        <TableToolbar.Container>
          <TableToolbar.Left as="div">
            <span style={{ marginRight: `10px` }} className="nowrap">
              <p>
                <b>Total Cases: {totalItem}</b>
              </p>
            </span>
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={callById}
              name="callById"
              options={this.renderClientOfficer()}
              text={`Activity PIC: ${this.renderClientOfficerText(callById)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={staffId}
              name="clientOfficer"
              options={this.renderRecruitment()}
              text={`Account Manager: ${this.renderRecruitmentText(staffId)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={activityType}
              name="activityType"
              options={ActivityTypeOptions}
              text={`Activity Type: ${this.renderActivityTypeText(activityType)}`}
              onChange={this.handleFilterClient}
            />
            <TableToolbar.SearchDropdown
              disabled={loading}
              loading={loadingClient}
              selectOnNavigation={false}
              value={activityOutcome}
              name="activityOutcome"
              options={ActivityOutcomeOptions}
              text={`Activity Outcome: ${this.renderActivityOutcomeText(activityOutcome)}`}
              onChange={this.handleFilterClient}
            />
          </TableToolbar.Left>
          <TableToolbar.Right />
        </TableToolbar.Container>
      </form>
    );
  }
}

export default SalesCallReportToolbar;
