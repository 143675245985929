import customFetch from '../../../../utils/customFetch';

export const checkDuplicateBusinessEmail = (id, businessEmail) => {
  return customFetch('/MedicalDoctor/CheckDuplicateBusinessEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, businessEmail }),
  });
};

export const checkDuplicatePersonalEmail = (id, personalEmail) => {
  return customFetch('/MedicalDoctor/CheckDuplicatePersonalEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, personalEmail }),
  });
};

export const getCMsWaitingNotificationsOfDoctor = doctorId => {
  return customFetch('/CMsWaitingNotificationsOfDoctor/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId }),
  });
};

export const saveCMsWaitingNotificationsOfDoctor = (doctorId, lstItems) => {
  return customFetch('/CMsWaitingNotificationsOfDoctor/Save?doctorId=' + doctorId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(lstItems),
  });
};

export const getCampaignAll = (keyword = '', numberItem = 30) => {
  return customFetch('/ManagerCampaign/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      IsSelectionList: true,
      Showing: 'active',
      curPage: 1,
      keyword: keyword,
      numItemPerPage: numberItem,
      CampaignStatus: null, //Status: Processing
      IsCurrent: true,
    }),
  });
};

export const getAllBlockedClients = doctorId => {
  return customFetch('/Company/GetAllBlockedClients', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId }),
  });
};

export const getCompanies = (keyword = '', numItemPerPage = 30) => {
  return customFetch('/Company/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      curPage: 1,
      IsSelectionList: true,
      keyword: keyword,
      numItemPerPage: numItemPerPage,
      Showing: 'active',
    }),
  });
};

export const saveBlockedClientsOfDoctor = (doctorId, lstItems) => {
  return customFetch('/Company/SaveBlockedClients?doctorId=' + doctorId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(lstItems),
  });
};

export const getCMsOfClientList = clientId => {
  return customFetch('/Staff/GetStaffOnboarded', {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      IsOnboarded: true,
      IsSelectionList: true,
      Showing: 'active',
      companyId: clientId,
      curPage: 1,
      keyword: '',
      numItemPerPage: 1000,
    }),
  });
};

export const sendOnboardedNoticeToCMs = dId => {
  return customFetch('/MedicalDoctor/SendOnboardedNoticeToCMs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ doctorId: dId }),
  });
};

export const getTypeOfClaim = () => {
  return customFetch('/Default/GetActiveClaimTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getTypeOfReport = () => {
  return customFetch('/Default/GetActiveReportTypeList', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getTableFieldChangeHistory = (tableName, objectId, fieldName, startIndex = 1, maxRecords = 20) => {
  return customFetch(
    '/Default/GetTableFieldUpdateHistory?tableName=' +
      tableName +
      '&objectId=' + 
      objectId +
      '&fieldName=' +
      fieldName +
      '&startIndex=' +
      startIndex +
      '&maxRecords=' +
      maxRecords,
    {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    },
  );
};