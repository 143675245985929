import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/main.scss';
import './stores/newUI';

import { createBrowserHistory } from 'history';
import { configure } from 'mobx';
import { syncHistoryWithStore } from 'mobx-react-router';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { render } from 'react-dom';
import { Router } from 'react-router';

import App from './App';
import client from './stores/apolloClient';
import router from './stores/router';
import { message } from 'antd';

configure({ enforceActions: 'always' });

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, router);

const app = (
  <ApolloProvider client={client}>
    <Router history={history}>
      <App />
    </Router>
  </ApolloProvider>
);

// CIR-1778. Remove this code after 20 June 2021
if (window.location.host === 'magcirrus.com.au') {
  message.info(
    <span>
      MAG online portal has moved to <a href="https://magconnect.com.au">magconnect.com.au</a>. Please bookmark the new
      site address. The address <a href="https://magcirrus.com.au">magcirrus.com.au</a> will be shut down by 30 June
      2021. You are now redirected to the new address.
    </span>,
    5,
  );

  setTimeout(() => {
    window.location = 'https://magconnect.com.au';
  }, 5000);
}

render(app, document.getElementById('root'));
