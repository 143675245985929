import customFetch from '../../../utils/customFetch';

export const getAMATimeUnitSetting = (
  keyword = '',
  curPage = 1,
  numItemPerPage = 0,
  sortBy = '',
  sortDirection = '',
) => {
  return customFetch('/AMATimeUnit/GetAll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Keyword: keyword,
      CurPage: curPage,
      NumItemPerPage: numItemPerPage,
      SortBy: sortBy,
      SortDirection: sortDirection,
    }),
  });
};
export const getAMATimeUnitSettingInfo = (id = 0) => {
  return customFetch(`/AMATimeUnit/GetItemInfo?Id=${id}`, {
    method: 'GET',
  });
};

export const saveAMATimeUnitSetting = param => {
  return customFetch('/AMATimeUnit/SaveData', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param),
  });
};

export const deleteAMATimeUnitSetting = id => {
  return customFetch('/AMATimeUnit/Delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const searchAMATimeUnitSetting = param => {
  return customFetch('/AMATimeUnit/GetAll', {
    method: 'POST',
    body: JSON.stringify(param),
  });
};
