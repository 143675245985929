import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import AMATimeUnitSettingStore from './Store';
import ModalCreate from './ModalCreate';

function AMATimeUnitSettingModal() {
  const { modalType } = AMATimeUnitSettingStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...AMATimeUnitSettingStore} onCancel={AMATimeUnitSettingStore.toggleModal(false)} />;
  }
  if (modalType === 'create') {
    return <ModalCreate {...AMATimeUnitSettingStore} onCancel={AMATimeUnitSettingStore.toggleModal(false)} />;
  }
}

export default AMATimeUnitSettingModal;
