import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { debounce } from 'lodash';

import DocAndConsToolbar from './DocAndConsToolbar';
import DocAndConsStore from './DocAndConsStore';
import DocAndConsTable from './DocAndConsTable';
import DocAndConsModal from './DocAndConsModal';
import ui from '../../../stores/dashboard';
import router from '@stores/router';

@observer
class DocAndCons extends React.Component {
  componentDidMount() {
    this._searchDebounced();
    ui.on('open(/view/specialists-2)', this._searchDebounced);
  }
  getRouteParams = () => {
    const search = new URLSearchParams(router.location.search);
    const params = {};

    for (let p of search.entries()) {
      params[p[0]] = p[1];
    }
    return params;
  };
  _searchDebounced = debounce(() => {
    const { sortBy, sortDirection } = this.getRouteParams();
    DocAndConsStore.fetchFiles(true);
    DocAndConsStore.fetchPIC();
    if (sortBy && sortDirection) {
      DocAndConsStore.refetch(
        {
          sortKey: sortBy,
          sortDirection: sortDirection,
        },
        true,
      );
      return router.history.replace({ ...router.location.search, search: '' });
    } else return DocAndConsStore.fetchMain(true);
  }, 500);
  render() {
    const { loading, data, open, dataSearch, loadingSearch } = DocAndConsStore;
    return (
      <div className={`Table-${(loading && !data) || (loadingSearch && !dataSearch) ? 'loading' : 'container'}`}>
        {(loading && !data) || (loadingSearch && !dataSearch) ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            <DocAndConsToolbar />
            <DocAndConsTable />
            {open && <DocAndConsModal />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DocAndCons;
