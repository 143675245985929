import React from 'react';

import ModalConfirm from '../../shared/Modal/ModalConfirm';
import InvoicesStore from './InvoicesStore';
import ModalActionNotes from './ModalActionNotes';
import ModalDelete from './ModalDelete';
import ModalHistory from './ModalHistory';
import ModalXero from './ModalXero';
import ModalUpload from './ModalUploadClient';

function InvoicesModal() {
  const { modalType } = InvoicesStore.modalParams;
  if (modalType === 'confirm') {
    return <ModalConfirm {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
  if (modalType === 'notes') {
    return <ModalActionNotes {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
  if (modalType === 'cancel') {
    return <ModalDelete {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
  if (modalType === 'history') {
    return <ModalHistory {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
  if (modalType === 'xero') {
    return <ModalXero {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
  if (modalType === 'uploadClient') {
    return <ModalUpload {...InvoicesStore} onCancel={InvoicesStore.toggleModal(false)} />;
  }
}

export default InvoicesModal;
