import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

import AddEditStore from './store';
import Modal from '../../shared/Modal';
import { TableHeader } from '../../shared/table';

const columns = [{ title: 'Name' }, { title: 'Address' }, { title: 'Suburb' }, { title: 'State' }];

@observer
class ModalDuplicate extends React.Component {
  renderModalTitle = dataDuplicate => {
    if (dataDuplicate) {
      if (!dataDuplicate.length) {
        return 'Duplicate message';
      }
      return 'List of similar companies';
    }
    return 'Duplicate message';
  };

  renderModalBody = dataDuplicate => {
    if (!dataDuplicate.length) {
      return 'No similar companies are found';
    }
    return (
      <Table striped celled>
        <TableHeader columns={columns} />
        <Table.Body>
          {dataDuplicate.map((record, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{record.Name}</Table.Cell>
              <Table.Cell>{record.Address}</Table.Cell>
              <Table.Cell>{record.Address}</Table.Cell>
              <Table.Cell>{record.State}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  renderTableEmty = () => {
    return 'No similar companies are found';
  };

  render() {
    const { open } = AddEditStore;
    const { dataDuplicate } = AddEditStore.modalParams;
    return (
      <Modal
        visible={open}
        width={700}
        onCancel={AddEditStore.toggleModal(false)}
        title={this.renderModalTitle(dataDuplicate)}
      >
        {dataDuplicate ? this.renderModalBody(dataDuplicate) : this.renderTableEmty()}
      </Modal>
    );
  }
}

export default ModalDuplicate;
