import './TabMore.scss';

import cx from 'classnames';
import { observe } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';

import ui from '../../../stores/dashboard';
import routes from '../../dashboard-routes';
import Icon8 from '../../shared/Icon8';
import { dropdownRoot } from '../../shared/portals';
import { TabCloseIcon } from './Tab';

class TabMore extends React.Component {
  componentDidMount() {
    // Manually update the dropdown popper position when sidebar get auto-hide
    this.clearObserve = observe(ui.sidebar, 'autohide', () => {
      if (this.updatePopperPosition) {
        requestAnimationFrame(this.updatePopperPosition);
      }
    });
  }
  componentWillUnmount() {
    this.clearObserve();
  }

  renderReference = ({ ref }) => {
    const { openTabDropdown } = ui;
    return (
      <div ref={ref} className="Tab TabMore" onClick={openTabDropdown}>
        <Observer render={this.renderReferenceObserver} />
      </div>
    );
  };
  renderReferenceObserver = () => {
    const { tabs, maxTabs } = ui;
    const count = tabs.length - maxTabs;
    return (
      <React.Fragment>
        <div className="Tab-Background" />
        <div className="Tab-Content">
          ... <span className="TabMore-Count">{count}</span>
        </div>
      </React.Fragment>
    );
  };

  renderPopper = ({ ref, style, placement, scheduleUpdate }) => {
    this.updatePopperPosition = scheduleUpdate;
    return (
      <div ref={ref} style={style} data-placement={placement}>
        <Observer render={this.renderPopperObserver} />
      </div>
    );
  };
  renderPopperObserver = () => {
    const { tabs, maxTabs, tabDropdownActive: active } = ui;
    return <div className={cx('TabMore-Dropdown', { active })}>{tabs.slice(maxTabs).map(this.renderDropdownItem)}</div>;
  };
  renderDropdownItem = ({ path, absUrl }) => {
    const { closureCloseDropdownTab } = ui;
    const { title, icon } = routes[path];
    return (
      <Link key={path} to={absUrl} className="MenuItem" title={title}>
        <Icon8 name={icon} title={title} />
        {title}
        <div className="Tab-Close" onClick={closureCloseDropdownTab(path)}>
          <TabCloseIcon />
        </div>
      </Link>
    );
  };

  render() {
    return (
      <Manager>
        <Reference children={this.renderReference} />
        <Portal node={dropdownRoot}>
          <Popper placement="bottom-end" children={this.renderPopper} />
        </Portal>
      </Manager>
    );
  }
}

export default TabMore;
